import React from 'react';

import { Box } from '@material-ui/core';

import { CadastrarDadosIntegracaoAPIFileField } from './CadastrarDadosIntegracaoAPIFileField';

const permitedFileExtensionCertificado = ['pem', 'p12', 'crt'];

export const CertificadoAPIFileField = ({ PermitedFileExtension = permitedFileExtensionCertificado }) => (
	<Box>
		<CadastrarDadosIntegracaoAPIFileField
			label='Certificado da API'
			source='certificado_api'
			PermitedFileExtension={PermitedFileExtension}
		/>
	</Box>
);
