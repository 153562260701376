import React, { useState, createContext, useContext, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ButtonCancel } from '../common/buttons/ButtonCancel';

export const ModalSizeEditableContext = createContext();

export const ModalSizeEditableContextProvider = ({
	Context = ModalSizeEditableContext,
	children,
	customDialogProps: { minWidth = '61vw', position = 'relative', overflowY, ...props } = {},
}) => {
	const [modalValue, setModalValue] = useState({
		open: false,
		dialogBody: <></>,
	});

	const [minWidthModel, setMinWidthModel] = useState(minWidth);

	const modalProviderValue = useMemo(
		() => ({ modalValue, setModalValue, setMinWidthModel }),
		[modalValue, setModalValue, setMinWidthModel]
	);

	return (
		<Context.Provider value={modalProviderValue}>
			{children}
			<CustomDialogSizeEditable
				{...props}
				overflowY={overflowY}
				PaperProps={{
					style: {
						minWidth: minWidthModel,
						maxWidth: '94vw',
						position: position,
						width: minWidthModel,
					},
				}}
			/>
		</Context.Provider>
	);
};

export const CustomDialogSizeEditable = ({ Context = ModalSizeEditableContext, ...props }) => {
	const { modalValue, setModalValue } = useContext(Context);

	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<Dialog open={modalValue.open} onClose={handleClose} fullWidth {...props}>
			{modalValue.dialogBody}
		</Dialog>
	);
};

export const CustomDialogBodySizeEditable = ({
	Context = ModalSizeEditableContext,
	customStyleTilte = undefined,
	title = '',
	customActions,
	remove = false,
	text = '',
	form,
	DialogContentCustomStyle = {},
	onCloseAction = undefined,
	customSubmitLabel = '',
	closeInSubmit = true,
	DialogContentCustomStyleActions = {},
}) => {
	const { setModalValue } = useContext(Context);
	const [loading, setLoading] = React.useState(false);

	const handleClose = () => {
		onCloseAction && onCloseAction();
		setModalValue((v) => ({ ...v, open: false }));
	};

	const handleSubmit = () => {
		setLoading(true);
		form.handleSubmit();
		if (closeInSubmit) handleClose();
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	return (
		<>
			<DialogTitle style={customStyleTilte}>{title}</DialogTitle>
			<DialogContent style={DialogContentCustomStyle}>
				{text && <Typography>{text}</Typography>}
				{form && form.component}
			</DialogContent>
			<DialogActions style={DialogContentCustomStyleActions}>
				{customActions ? (
					customActions
				) : form ? (
					<>
						<Button
							disabled={!form.valid || loading}
							onClick={handleSubmit}
							id='button-submit-custom-dialog'
						>
							{customSubmitLabel ? customSubmitLabel : remove ? 'Remover' : 'Salvar'}
						</Button>
						<ButtonCancel onClick={handleClose} id='button-close-custom-dialog' />
					</>
				) : (
					<Button onClick={handleClose} id='button-ok-custom-dialog'>
						Ok
					</Button>
				)}
			</DialogActions>
		</>
	);
};
