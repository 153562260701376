import React from 'react';

import { InstrucoesRemessaSemValorFields } from '../../commons/InstrucoesRemessaFields';

const emptyProps = {};

const ProtestoFields = ({ propsTipo = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaSemValorFields
		sourcePrincipal='protesto'
		propsTipo={{
			source: 'protesto_tipo',
			label: 'Protesto',
			...propsTipo,
		}}
		propsDias={{
			source: 'protesto_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoProtesto = ({ sources: { sourceDias } = emptyProps, optionsIsencoesTipo }) => ({
	sources: { sourceDias },
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoProtesto =
	({ sources: { sourceDias } = {}, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);

		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) sourceDias && change(sourceDias, '');
	};

export default ProtestoFields;
