import React, { memo, useEffect, useState } from 'react';

import { Box, TextField, Dialog } from '@material-ui/core';

import { DateRangePicker } from 'materialui-daterange-picker-pt';

import { format } from 'date-fns';

const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const PeriodoPicker = memo(
	({
		open,
		setOpen,
		setDateRange,
	}: {
		open: boolean;
		setOpen: (open: boolean) => void;
		setDateRange: (range: any) => void;
	}) => {
		const toggle = () => setOpen(!open);
		return (
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DateRangePicker
					open={true}
					toggle={toggle}
					onChange={(range) => {
						setDateRange(range);
						toggle();
					}}
					minDate={new Date(MINDATE)}
					initialDateRange={{
						startDate: new Date(),
						endDate: new Date(),
					}}
					definedRanges={[]}
				/>
			</Dialog>
		);
	}
);

type PeriodoSelectProps = {
	setDatasAfter: (date: string) => void;
	setDatasBefore: (date: string) => void;
};

const PeriodoSelect = memo(({ setDatasAfter, setDatasBefore }: PeriodoSelectProps) => {
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});

	const handleRangeChange = () => {
		setDatasAfter(format(dateRange.startDate, 'yyyy-MM-dd'));
		setDatasBefore(format(dateRange.endDate, 'yyyy-MM-dd'));
	};

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextField
				onClick={() => setOpen(!open)}
				fullWidth
				margin='dense'
				label='Selecionar Período'
				inputProps={{
					value: `de ${format(dateRange?.startDate, 'dd/MM/yyyy')} até ${format(
						dateRange?.endDate,
						'dd/MM/yyyy'
					)}`,
				}}
			/>
			<PeriodoPicker {...{ open, setOpen, setDateRange }} />
		</>
	);
});

const BoxIntervalo = ({ setDatasAfter, setDatasBefore }: PeriodoSelectProps) => {
	return (
		<Box>
			<PeriodoSelect {...{ setDatasAfter, setDatasBefore }} />
		</Box>
	);
};

export default BoxIntervalo;
