import React, { useContext } from 'react';

import { BotaoSalvar } from '../../../common/buttons/BotaoSalvar';
import { ButtonCancel } from '../../../common/buttons/ButtonCancel';
import { CustomDialogBodySizeEditable } from '../../../common/ModalSizeEditableContext';

import ModalConfirmacao from 'components/common/ModalConfirmacao';

import { FormEditarParcelaContext, FormEditarParcelaContextProvider } from './FormEditarParcelaContext';
import FormEditarParcela from './FormEditarParcela';
import { FormParcelamentoContext, FormParcelamentoContextProvider } from './FormParcelamentoContext';
import FormParcelamento from './FormParcelamento';
import { ListParcelasContext } from './ListParcelasContext';

const CustomActions = ({ context }) => {
	const { closeModal, handleSave, linhaOuCodigo } = useContext(context);
	const [disabledButtonSave, setDisabledButtonSave] = React.useState(false);
	React.useEffect(() => {
		linhaOuCodigo?.length < 44 && linhaOuCodigo != '' ? setDisabledButtonSave(true) : setDisabledButtonSave(false);
	}, [linhaOuCodigo]);
	return (
		<>
			<ButtonCancel onClick={closeModal} />
			<BotaoSalvar disabled={disabledButtonSave} onClick={() => handleSave()} />
		</>
	);
};

export const ModalParcelar = ({ context }) => {
	return (
		<FormParcelamentoContextProvider context={context}>
			<CustomDialogBodySizeEditable
				title='Parcelamento'
				form={{ component: <FormParcelamento /> }}
				customActions={<CustomActions context={FormParcelamentoContext} />}
			/>
		</FormParcelamentoContextProvider>
	);
};

export const ModalEditarParcela = ({ context, record }) => {
	return (
		<FormEditarParcelaContextProvider context={context} record={record}>
			<CustomDialogBodySizeEditable
				title='Editar Parcela'
				form={{ component: <FormEditarParcela /> }}
				customActions={<CustomActions context={FormEditarParcelaContext} />}
			/>
		</FormEditarParcelaContextProvider>
	);
};

export const ModalExcluirParcela = ({ record, context }) => {
	const { UpdateValorParcelasByExclusao } = useContext(ListParcelasContext);
	const { setModalValue } = useContext(context);
	return (
		<ModalConfirmacao
			Context={context}
			title='Tem certeza que deseja excluir esta parcela?'
			handleSubmit={() => {
				UpdateValorParcelasByExclusao(record.numero_parcela, setModalValue);
			}}
		/>
	);
};

export const ModalConfimarRecalculo = ({ onClick, context }) => {
	const { setModalValue } = useContext(context);
	return (
		<ModalConfirmacao
			Context={context}
			title='Tem certeza que deseja calcular as parcelas? Os documentos anexados às parcelas serão perdidos.'
			handleSubmit={() => {
				setModalValue((v) => ({ ...v, open: false }));
				onClick();
			}}
		/>
	);
};
