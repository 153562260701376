import React, { useContext, useState, useEffect, useRef, createContext, useMemo, useCallback } from 'react';
import lodashMemoize from 'lodash/memoize';
import { AutocompleteInput, useNotify, useDataProvider, useInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';

import { TooltipIconButton } from './TooltipIconButton';
import { TooltipIconButtonCancel } from './buttons/ButtonCancel';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from './ModalContext';

import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { BotaoAtualizar } from './buttons/BotaoAtualizar';

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

const ModalConfirmacao = (props) => {
	const { selected, previous } = useContext(TiposContext);
	const { setModalValue } = useContext(ModalContext);
	const [opcao, setOpcao] = useState('');
	const notify = useNotify();
	const dP = useDataProvider();
	const handleSubmit = () => {
		if (opcao) {
			const tipo_anterior = opcao === 'todas' ? null : previous;
			dP.update('unidades', {
				id: 'atualizar_tipo',
				data: {
					novo_tipo: selected,
					id_condominio: props?.condominio,
					tipo: tipo_anterior,
				},
			}).then((r) => {
				notify('Registros alterados com sucesso');
			});
		}
	};
	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};
	return (
		<CustomDialogBody
			title={
				<Box alignItems='center' alignContent='center' gridGap='20px'>
					<Box>Foi alterado o tipo padrão das unidades, deseja:</Box>
				</Box>
			}
			text={
				<Box display='flex' alignItems='center'>
					<FormControl component='fieldset'>
						<RadioGroup value={opcao} onChange={(e) => setOpcao(e.target.value)}>
							<FormControlLabel
								value='todas'
								control={<Radio />}
								label='Atualizar o tipo de todas as unidades'
							/>
							<FormControlLabel
								value='tipo'
								control={<Radio />}
								label='Atualizar somente as unidades que tem o tipo igual ao padrão anterior'
							/>
							<FormControlLabel
								value=''
								control={<Radio />}
								label='Não atualizar o tipo de nenhuma unidade'
							/>
						</RadioGroup>
					</FormControl>
				</Box>
			}
			customActions={
				<>
					<BotaoAtualizar
						onClick={() => {
							handleSubmit();
							handleClose();
						}}
						size='small'
					/>
				</>
			}
		/>
	);
};

const TiposContext = createContext(null);

const textosPadroes = {
	registroCadastradoComSucesso: 'Tipo cadastrado com sucesso',
	registroAlteradoComSucesso: 'Tipo alterado com sucesso',
	registroRemovidoComSucesso: 'Tipo removido com sucesso',
	erroRegistroEmUso: 'Tipo em uso, não é possível remover',
	tituloModal: ({ label }) => `Editar Tipos${label.split('Tipo')[1]}`,
	campoBuscaLabel: 'Buscar',
	campoNomeLabel: 'Nome do novo tipo',
	botaoCadastrarLabel: 'Cadastrar novo tipo',
	naoEncontrado: 'Nenhum tipo encontrado',
	botaoEditarLabel: 'Editar tipos',
};

const textosParaClassificacaoOcorrencia = {
	...textosPadroes,
	registroCadastradoComSucesso: 'Classificação cadastrada com sucesso',
	registroAlteradoComSucesso: 'Classificação alterada com sucesso',
	registroRemovidoComSucesso: 'Classificação removida com sucesso',
	erroRegistroEmUso: 'Classificação em uso, não é possível remover',
	tituloModal: () => 'Editar Classificações',
	campoNomeLabel: 'Nome da nova classificação',
	botaoCadastrarLabel: 'Cadastrar nova classificação',
	naoEncontrado: 'Nenhuma classificação encontrada',
	botaoEditarLabel: 'Editar classificações',
};

const textosPorTipoRegistro = {
	[undefined]: textosPadroes,
	[null]: textosPadroes,
	classificacao: textosParaClassificacaoOcorrencia,
};

const TiposContextProvider = React.memo(({ children, source, tipoRegistro }) => {
	const [iniciado, setIniciado] = useState(false);
	const [previous, setPrevious] = useState();
	const dataProvider = useDataProvider();
	const [filter, setFilter] = useState('');
	const [tipos, setTipos] = useState();
	const [unfiltered, setUnfiltered] = useState();
	const [selected, setSelected] = useState();
	const notify = useNotify();
	const { modalValue } = useContext(ModalContext);

	const textosDoTipoRegistro = React.useMemo(
		() => textosPorTipoRegistro[tipoRegistro] ?? textosPadroes,
		[tipoRegistro]
	);

	const { registroCadastradoComSucesso, registroAlteradoComSucesso, registroRemovidoComSucesso, erroRegistroEmUso } =
		textosDoTipoRegistro;

	useEffect(() => {
		if (!modalValue?.open) {
			setFilter('');
		}
	}, [modalValue]);

	const create = useCallback(
		(reqData) =>
			memoize(() => {
				dataProvider
					.create(source, { data: { ...reqData } })
					.then((response) => {
						const data = response?.data;
						if (data) {
							setUnfiltered((t) => ({ ...t, [data.id]: data }));
							notify(registroCadastradoComSucesso);
						}
					})
					.catch((e) =>
						[401, 403].includes(e?.response?.status)
							? Promise.reject(e)
							: notify('Erro ao cadastrar', 'warning')
					);
			}),
		[dataProvider, notify, source]
	);
	const update = useCallback(
		(id, reqData) =>
			memoize(() => {
				dataProvider
					.update(source, { id, data: { ...reqData } })
					.then((response) => {
						const data = response?.data;
						if (data) {
							setUnfiltered((t) => ({ ...t, [data.id]: data }));
							notify(registroAlteradoComSucesso);
						}
					})
					.catch((e) =>
						[401, 403].includes(e?.response?.status)
							? Promise.reject(e)
							: notify('Erro ao atualizar', 'warning')
					);
			}),
		[dataProvider, notify, source]
	);
	const deleteOne = useCallback(
		(id) =>
			memoize(() => {
				dataProvider
					.delete(source, { id })
					.then(() => {
						const { [id]: _, ...rest } = tipos;
						setUnfiltered(rest);
						notify(registroRemovidoComSucesso);
						setSelected(null);
					})
					.catch((e) =>
						[401, 403].includes(e?.response?.status)
							? Promise.reject(e)
							: notify(erroRegistroEmUso, 'warning')
					);
			}),
		[dataProvider, tipos, notify, source]
	);

	const init = memoize(() => {
		if (source) {
			dataProvider
				.getList(source, {
					pagination: { page: 1, perPage: 10000 },
					sort: {},
					filter: { descricao: filter },
				})
				.then((response) => {
					const data = response?.data?.filter?.((item) => item?.id);
					if (data) {
						const tipos = {};
						Object.keys(data).forEach((key) => (tipos[data[key].id] = data[key]));
						setUnfiltered(tipos);
						setTipos(tipos);
					}
				})
				.catch((e) => {
					console.log(e);
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				})
				.finally(() => {
					setIniciado(true);
				});
		}
	});

	useEffect(init, []);
	useEffect(() => {
		if (unfiltered) {
			if (filter) {
				const filtered = {};
				Object.keys(unfiltered).forEach((key) => {
					if (unfiltered[key].descricao.toLowerCase().includes(filter.toLowerCase())) {
						filtered[key] = unfiltered[key];
					}
				});
				setTipos(filtered);
			} else {
				setTipos(unfiltered);
			}
		}
	}, [unfiltered, filter]);

	const tiposProviderValue = useMemo(
		() => ({
			dataProvider,
			filter,
			setFilter,
			tipos,
			setTipos,
			create,
			update,
			deleteOne,
			selected,
			setSelected,
			iniciado,
			setIniciado,
			previous,
			setPrevious,
			textosDoTipoRegistro,
		}),
		[
			dataProvider,
			filter,
			setFilter,
			tipos,
			setTipos,
			create,
			update,
			deleteOne,
			selected,
			setSelected,
			iniciado,
			setIniciado,
			previous,
			setPrevious,
			textosDoTipoRegistro,
		]
	);

	return <TiposContext.Provider value={tiposProviderValue}>{children}</TiposContext.Provider>;
});

const TipoRow = React.memo(({ id }) => {
	const { update, deleteOne, tipos, selected, setSelected, setPrevious } = useContext(TiposContext);

	const inputRef = useRef(null);
	const [descricao, setDescricao] = useState();
	const [editMode, setEditMode] = useState(false);
	const [visibility, setVisibility] = useState('hidden');
	useEffect(() => {
		if (editMode) inputRef.current.focus();
	}, [editMode]);
	useEffect(() => {
		if (tipos && tipos[id]) setDescricao(tipos[id].descricao);
	}, [tipos, id]);
	return (
		<Box
			display='flex'
			justifyContent='space-between'
			width='calc(100% - 4px)'
			alignItems='center'
			css={{
				cursor: 'pointer',
				borderRadius: 5,
				'&:hover': {
					backgroundColor: selected === tipos[id]?.id ? 'rgba(0,0,0,.275)' : 'rgba(0,0,0,.275)',
				},

				backgroundColor: selected === tipos[id]?.id ? 'rgba(0,0,0,.275)' : 'transparent',
				marginBottom: '5px',
			}}
			onClick={() => {
				setPrevious(selected);
				setSelected(tipos[id]?.id);
			}}
			onMouseEnter={() => setVisibility('visible')}
			onMouseLeave={() => setVisibility('hidden')}
		>
			{editMode ? (
				<>
					<TextField
						inputRef={inputRef}
						value={descricao}
						onChange={(e) => setDescricao(e.target.value)}
						fullWidth
						margin='dense'
					/>

					<Box display='flex'>
						<TooltipIconButton
							color='primary'
							title='Salvar'
							onClick={() => {
								!descricao ? setDescricao(tipos[id].descricao) : update(id, { descricao })();
								setEditMode(false);
							}}
						>
							<CheckIcon />
						</TooltipIconButton>
						<TooltipIconButtonCancel
							onClick={() => {
								setDescricao(tipos[id].descricao);
								setEditMode(false);
							}}
						/>
					</Box>
				</>
			) : (
				<>
					<Typography style={{ padding: 14 }}>{descricao}</Typography>
					<Box
						display='flex'
						css={{
							visibility: visibility,
						}}
					>
						<TooltipIconButton
							title='Renomear'
							onClick={() => {
								setEditMode(true);
							}}
						>
							<EditIcon />
						</TooltipIconButton>
						<TooltipIconButtonCancel
							title='Remover'
							onClick={() => {
								deleteOne(id)();
							}}
						>
							<DeleteOutlineIcon />
						</TooltipIconButtonCancel>
					</Box>
				</>
			)}
		</Box>
	);
});

const ModalTipoInput = React.memo((props) => {
	const inputRef = useRef(null);
	const { setModalValue } = useContext(ModalContext);
	const [addMode, setAddMode] = useState(false);
	const { tipos, filter, setFilter, create, textosDoTipoRegistro } = useContext(TiposContext);

	const handleCreate = () => {
		create({ descricao: filter })();
		inputRef.current.value = '';
		setFilter('');
		setAddMode(false);
	};

	return (
		<CustomDialogBody
			title={textosDoTipoRegistro.tituloModal(props)}
			customActions={
				<Button
					onClick={() => {
						setFilter('');
						setModalValue((v) => ({ ...v, open: false }));
					}}
					size='small'
					startIcon={<CloseIcon />}
				>
					Fechar
				</Button>
			}
			form={{
				component: (
					<>
						<Box display='flex' alignItems='center'>
							<TextField
								inputRef={inputRef}
								label={addMode ? textosDoTipoRegistro.campoNomeLabel : 'Buscar'}
								fullWidth
								margin='dense'
								onKeyPress={(e) => e.key === 'Enter' && addMode && handleCreate()}
								onChange={(e) => {
									setFilter(e.target.value);
								}}
							/>
							{addMode ? (
								<>
									<Box display='flex'>
										<TooltipIconButton
											disabled={inputRef.current.value === ''}
											color='primary'
											title='Salvar'
											onClick={handleCreate}
										>
											<CheckIcon />
										</TooltipIconButton>
										<TooltipIconButtonCancel onClick={() => setAddMode(false)}>
											<ClearIcon />
										</TooltipIconButtonCancel>
									</Box>
								</>
							) : (
								<TooltipIconButton
									title={textosDoTipoRegistro.botaoCadastrarLabel}
									onClick={() => {
										setAddMode(true);
										inputRef.current.focus();
									}}
								>
									<AddCircleOutlineIcon />
								</TooltipIconButton>
							)}
						</Box>
						<Box mx={2} mt={2} position='relative' height={350} css={{ overflowY: 'auto' }}>
							{Object.keys(tipos).length > 0 ? (
								Object.keys(tipos).map((key) => <TipoRow id={key} key={key} />)
							) : (
								<Typography>{textosDoTipoRegistro.naoEncontrado}</Typography>
							)}
						</Box>
					</>
				),
			}}
		/>
	);
});

const TipoInput = ({ defaultValue, watchDefaultValue, ...props }) => {
	const { setModalValue, modalValue } = useContext(ModalContext);
	const { tipos, selected, setSelected, iniciado, textosDoTipoRegistro } = useContext(TiposContext);
	const [choices, setChoices] = useState(new Array(0));
	const { input } = useInput(watchDefaultValue ? { defaultValue, ...props } : { ...props });
	const { setPrevious } = useContext(TiposContext);
	const abrirConfirmacao = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmacao {...props} />,
		}));
	};

	const configuraChoices = memoize(() => {
		if (tipos) {
			const choices = [];
			Object.keys(tipos).forEach((key) => choices.push({ id: tipos[key].id, name: tipos[key].descricao }));
			setChoices(choices);
		}
	});

	const setSelectedFromRecord = () => setSelected(props?.record?.[props.source]);

	useEffect(setSelectedFromRecord, []);

	const setPreviousFromRecord = () => setPrevious(props?.record?.['tipo_padrao_unidade']);

	useEffect(setPreviousFromRecord, []);

	useEffect(configuraChoices, [tipos]);
	const handleSelect = () => {
		if (iniciado === true) {
			if (props.source === 'tipo_padrao_unidade') abrirConfirmacao();
		}
		if (modalValue.open) {
			input.onChange(selected);
		}
	};
	useEffect(handleSelect, [selected]);

	const handleDefaultValueChange = () => {
		if (watchDefaultValue) {
			input.onChange(defaultValue);
		}
	};

	useEffect(handleDefaultValueChange, [defaultValue]);

	return (
		<AutocompleteInput
			{...(watchDefaultValue ? { defaultValue } : {})}
			id='select-tipo-unidade'
			menuId='select-menu-tipo-unidade'
			labelId='select-label-tipo-unidade'
			inputId='select-input-tipo-unidade'
			getItemId={(index) => `select-item-${index}-tipo-unidade`}
			label={props.label}
			source={props.source}
			choices={choices}
			options={{
				InputProps: {
					endAdornment: (
						<TooltipIconButton
							title={textosDoTipoRegistro.botaoEditarLabel}
							size='small'
							onClick={() =>
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody: <ModalTipoInput {...props} />,
								}))
							}
							disabled={props.disabled}
						>
							<EditIcon />
						</TooltipIconButton>
					),
				},
			}}
			{...props}
			{...input}
			onChange={(v) => {
				setPrevious(selected);
				setSelected(v);
			}}
		/>
	);
};

export const TipoModalInput = (props) => {
	return (
		<ModalContextProvider>
			<TiposContextProvider
				source={props.resource ? props.resource : props.source}
				tipoRegistro={props.tipoRegistro}
			>
				<TipoInput {...props} />
				<CustomDialog />
			</TiposContextProvider>
		</ModalContextProvider>
	);
};
