import React, { Children, cloneElement, isValidElement, useState, useEffect, useContext } from 'react';

import { useGetIdentity } from 'ra-core';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { AdminContext } from '../common/AdminContextProvider';
const defaultIcon = <AccountCircle />;

const useStyles = makeStyles(
	(theme) => ({
		user: {},
		userButton: {
			textTransform: 'none',
		},
		avatar: {
			width: theme.spacing(4),
			height: theme.spacing(4),
		},
	}),
	{ name: 'RaUserMenu' }
);

const TransformOrigin = {
	vertical: 'top',
	horizontal: 'right',
};

const UserMenu = (props) => {
	const { dadosAdministradora, setSituacaoIrregular } = useContext(AdminContext);
	const [menuTop, setMenuTop] = useState(0);
	const [open, setOpen] = useState(false);
	const { loaded, identity } = useGetIdentity();
	const classes = useStyles(props);
	const { children, icon = defaultIcon, logout } = props;

	if (!logout && !children) return null;

	useEffect(() => {
		if (
			(dadosAdministradora.limite_condominio < dadosAdministradora.quantidade_condominios_ativos &&
				dadosAdministradora.limite_condominio !== 0) ||
			(dadosAdministradora.limite_condominio_kondomynos <
				dadosAdministradora.licencas_condominio_kondomynos_utilizadas_no_mes &&
				dadosAdministradora.limite_condominio_kondomynos !== 0)
		) {
			setOpen(true);
			setSituacaoIrregular(true);
		} else setSituacaoIrregular(false);
	}, [dadosAdministradora]);

	const handleMenu = (event) => {
		setMenuTop(event.currentTarget.offsetHeight);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	return (
		<div className={classes.user}>
			{loaded && identity?.fullName ? (
				<Button
					aria-label='Menu'
					className={classes.userButton}
					color='inherit'
					startIcon={
						identity.avatar ? (
							<Avatar className={classes.avatar} src={identity.avatar} alt={identity.fullName} />
						) : (
							icon
						)
					}
					onClick={handleMenu}
				>
					{identity.fullName}
				</Button>
			) : (
				<Tooltip title='Menu'>
					<IconButton
						aria-label='Menu'
						aria-owns={open ? 'menu-appbar' : null}
						aria-haspopup={true}
						color='inherit'
						onClick={handleMenu}
					>
						{icon}
					</IconButton>
				</Tooltip>
			)}
			<Menu
				id='menu-appbar'
				PaperProps={{
					style: {
						left: 'auto',
						right: 0,
						top: menuTop,
					},
				}}
				disableScrollLock
				anchorReference='none'
				transformOrigin={TransformOrigin}
				open={open}
				onClose={handleClose}
			>
				{Children.map(children, (menuItem) =>
					isValidElement(menuItem)
						? cloneElement(menuItem, {
								onClick: handleClose,
						  })
						: null
				)}
				{logout}
			</Menu>
		</div>
	);
};

export default UserMenu;
