import React from 'react';
import AreaClienteIcon from '../../common/icons/AreaClienteIcon';
import UserMenuItem from '../../common/UserMenuItem';

const MenuItemAreaCliente = ({ url }) => (
	<UserMenuItem
		menuItemProps={{
			href: url,
			rel: 'noopener',
			target: '_blank',
		}}
		label='Área do Cliente'
		icon={<AreaClienteIcon />}
	/>
);
export default MenuItemAreaCliente;
