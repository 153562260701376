import React, { useState, useEffect, useContext, cloneElement, useMemo, createContext } from 'react';
import {
	TextField as RATextField,
	Pagination,
	useListContext,
	sanitizeListRestProps,
	TopToolbar,
	useNotify,
	ResourceContextProvider,
	List,
	Button as RAButton,
	useDataProvider,
} from 'react-admin';

import { situacaoOpcoes } from '../../../fieldControl/situacao';
import { Tabela, TabelaRowContext } from '../../common/Tabela';
import { CurrencyField } from '../../common/CurrencyInput';
import { Padrao } from '../../common/filtros/Padrao';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Decimal } from '../../common/filtros/Decimal';
import { Data } from '../../common/filtros/DataV2';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import FluxoModalNovaContaBancaria from './FluxoModalNovaContaBancaria';
import ModalEditaContaBancaria from './ModalEditaContaBancaria';
import ValorDisplayField from '../../common/ValorDisplayField';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export const ContasBancariasListContext = createContext();

const ListActions = ({ record: { id, situacao }, ...props }) => {
	const { className, filters, exporter, maxResults, ...rest } = props;
	const { resource, displayedFilters, filterValues, showFilter } = useListContext();
	const { setModalValue } = useContext(ModalSizeEditableContext);

	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)} style={{ alignItems: 'center' }}>
			{filters &&
				cloneElement(filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: 'button',
				})}

			<RAButton
				label='Nova Conta Bancária'
				id='criar_nova_conta_bancaria'
				disabled={situacao !== 'A'}
				onClick={() => {
					if (situacao === 'A') {
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <FluxoModalNovaContaBancaria id_condominio={id} />,
						}));
					}
				}}
			>
				<AddCircleOutlineIcon />
			</RAButton>
		</TopToolbar>
	);
};

const tipoContaLong = {
	A: 'Aplicação',
	C: 'Caixa',
	CC: 'Conta Corrente',
	CT: 'Cotas',
	F: 'Fundo',
	P: 'Conta Poupança',
};

const TipoContaField = ({ record }) => {
	const tipoConta = tipoContaLong[record?.tipo_conta_expandido];
	return <span>{tipoConta || ''}</span>;
};

const SituacaoField = ({ record }) => {
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);

	const update = () => {
		if (data && data[rowRecord.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	useEffect(update, [data]);

	return <span>{record.situacao === 'A' ? 'Ativo' : 'Inativo'}</span>;
};

const ImprimirSaldoRelatoriosField = ({ record }) => <span>{record.imprimir_saldo_relatorios ? 'Sim' : 'Não'}</span>;

const SaldoInicialNumberField = ({ record, source, ...props }) => {
	return (
		<ValorDisplayField {...props} minimumFractionDigits={2} value={record[source]} id={`${source}${record.id}`} />
	);
};

const DataField = ({ record, source }) => {
	const dataSaldoInicial = new Date(`${record[source]}T10:00`);
	return (
		<span>
			{`${dataSaldoInicial}` !== 'Invalid Date'
				? dataSaldoInicial.toLocaleDateString('pt-br', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
				  })
				: ''}
		</span>
	);
};

const TabelaContasBancarias = ({ condominioRecord, ...props }) => {
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dP = useDataProvider();
	return (
		<Tabela
			{...props}
			clickRowCustom={({ record }) =>
				() => {
					const authJSON = JSON.parse(localStorage.getItem('auth'));

					Promise.allSettled([
						dP.getSimple('conta_condominio', {
							id: record.id,
						}),
						dP.getSimple('dados_bancarios', {
							id: 'opcoes',
						}),
						dP.getOne('administradora', {
							id: authJSON.tenant.schema_name,
						}),
					])
						.then((responses) => {
							const firstRejectedResponse = responses.find((r) => r?.status === 'rejected');
							if (firstRejectedResponse) return Promise.reject(firstRejectedResponse.reason);
							const dadosConta = responses[0].value?.data;
							const opcoesDadosBancarios = responses[1].value?.data;
							const administradoraDados = responses[2].value?.data;
							dadosConta.conta_condominio.saldo_inicial = parseFloat(
								dadosConta.conta_condominio.saldo_inicial
							);
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: (
									<ModalEditaContaBancaria
										condominioRecord={condominioRecord}
										dadosAdministradora={administradoraDados}
										dadosConta={dadosConta}
										opcoesDadosBancarios={opcoesDadosBancarios}
									/>
								),
							}));
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							notify(`Erro ao tentar abrir modal de edição de conta bancária: ${e}`, 'warning');
						});
				}}
		>
			<RATextField source='nome_conta' label='Conta' filtro={<Padrao />} />
			<TipoContaField
				source='tipo_conta_expandido'
				label='Tipo'
				filtro={<Autocompletar opcoes={Object.entries(tipoContaLong).map(([id, name]) => ({ id, name }))} />}
			/>
			<ImprimirSaldoRelatoriosField
				source='imprimir_saldo_relatorios'
				label='Imprimir'
				filtro={
					<Autocompletar
						opcoes={[
							{ id: 'Sim', name: 'Sim' },
							{ id: 'Nao', name: 'Não' },
						]}
					/>
				}
			/>
			<SaldoInicialNumberField
				source='saldo_inicial'
				label='Saldo Inicial'
				align='right'
				filtro={<Decimal decimalScale={2} />}
			/>
			<DataField source='data_saldo_inicial' label='Data do Saldo Inicial' filtro={<Data />} />
			<SituacaoField source='situacao' label='Situação' filtro={<Autocompletar opcoes={situacaoOpcoes} />} />
		</Tabela>
	);
};

const ContasBancariasList = () => {
	const { condominioRecord, props, reloadFilter } = useContext(ContasBancariasListContext);
	return (
		<ResourceContextProvider resource='conta_condominio'>
			<List
				{...props}
				basePath='/conta_condominio'
				resource='conta_condominio'
				bulkActionButtons={false}
				empty={false}
				perPage={10}
				pagination={
					<Pagination
						rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
						labelRowsPerPage='Contas bancárias por página'
					/>
				}
				actions={
					<ModalSizeEditableContextProvider
						customDialogProps={{
							disableBackdropClick: true,
							disableEscapeKeyDown: true,
							minWidth: '61vw',
						}}
					>
						<ListActions record={condominioRecord} />
					</ModalSizeEditableContextProvider>
				}
				filters={false}
				filter={{ id_condominio: props.id, reloadFilter }}
			>
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
						minWidth: '61vw',
					}}
				>
					<TabelaContasBancarias condominioRecord={condominioRecord} />
				</ModalSizeEditableContextProvider>
			</List>
		</ResourceContextProvider>
	);
};

const ContasBancariasListContextProvider = ({ condominioRecord, props }) => {
	const [reloadFilter, setReloadFilter] = useState(true);

	const novaContaBancariaProviderValue = useMemo(
		() => ({
			condominioRecord,
			props,
			reloadFilter,
			setReloadFilter,
		}),
		[condominioRecord, props, reloadFilter, setReloadFilter]
	);

	return (
		<ContasBancariasListContext.Provider value={novaContaBancariaProviderValue}>
			<ContasBancariasList />
		</ContasBancariasListContext.Provider>
	);
};

export default ContasBancariasListContextProvider;
