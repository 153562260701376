import { Box, Typography, TextField, Button } from '@material-ui/core';
import { ModalContextProvider, CustomDialogBody, CustomDialog } from 'components/common/ModalContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ButtonClose } from 'components/common/buttons/ButtonClose';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { ButtonRemove } from 'components/common/buttons/ButtonRemove';
import { ButtonCancel } from 'components/common/commonComponentsTSX';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useState, useEffect, createContext } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import { useTheme } from '@material-ui/core/styles';

const ModalRemocaoContext = createContext(undefined);

const respOpcoes = [
	{ id: 'Z', name: 'Padrão' },
	{ id: 'P', name: 'Proprietário' },
	{ id: 'I', name: 'Inquilino' },
	{
		id: 'D',
		name: 'Inquilino (Ordinárias) e Proprietário (Extraordinárias)',
	},
];

const getVencimentoOptions = () => {
	let options = [{ id: 0, name: 'Padrão' }];
	for (let i = 1; i < 31; i++) {
		options.push({ id: i, name: String(i) });
	}
	return options;
};

const ModalConfirmacaoRemocao = ({ handleSubmit, identificacaoSelecionada }) => {
	const { setModalValue } = useContext(ModalRemocaoContext);
	const {
		palette: { syndikosRed },
	} = useTheme();

	if (identificacaoSelecionada.usado_em_arrecadacao || identificacaoSelecionada.usado_em_recebimento) {
		return (
			<CustomDialogBody
				title='Esta identificação não pode ser removida'
				text={
					<Box display='flex' alignItems='center' gridGap='20px'>
						<Box>
							<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
						</Box>
						<Box>
							<Typography style={{ fontWeight: 'bold' }}>
								<ul>
									{
										<>
											{identificacaoSelecionada.usado_em_arrecadacao && (
												<li>Esta identificação está vinculada a ao menos uma arrecadação</li>
											)}
											{identificacaoSelecionada.usado_em_recebimento && (
												<li>
													Esta identificação está vinculado a ao menos uma conta a receber
												</li>
											)}
										</>
									}
								</ul>
							</Typography>
						</Box>
					</Box>
				}
				customActions={
					<>
						<ButtonClose
							onClick={() => {
								setModalValue({ open: false });
							}}
							size='small'
						>
							cancelar
						</ButtonClose>
						<ButtonRemove disabled={true} size='small' />
					</>
				}
			/>
		);
	} else {
		return (
			<CustomDialogBody
				Context={ModalRemocaoContext}
				title={`Tem certeza que deseja remover "${identificacaoSelecionada.nome}"?`}
				text={
					<Box display='flex' alignItems='center' gridGap='20px'>
						<Box>
							<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
						</Box>
						<Box>
							<Typography
								style={{
									fontWeight: 'bold',
									color: syndikosRed.main,
								}}
							>
								Essa ação é irreversível.
								<br />
								Todo o histórico será excluído.
							</Typography>
						</Box>
					</Box>
				}
				remove
				form={{ handleSubmit, valid: true }}
			/>
		);
	}
};

const SelectIdentComp = ({ identData, label, onChange, setUpdatedValue, updatedValue }) => {
	return identData && identData.length > 0 ? (
		<Autocomplete
			options={identData}
			renderInput={(params) => <TextField {...params} margin='dense' fullWidth label={label} />}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option?.nome || ''}
			onChange={(_, v) => {
				setUpdatedValue(v);
				onChange(v ? v.id : null);
			}}
			value={identData.find((item) => item.id === updatedValue?.id) || null}
			fullWidth
			disableClearable
			style={{ flex: 2 }}
		/>
	) : (
		<TextField margin='dense' fullWidth style={{ flex: 2 }} disabled label='Nenhum resultado encontrado' />
	);
};

const BtnRemoverIdent = ({ remover, disabled, identificacaoSelecionada }) => {
	const { setModalValue } = useContext(ModalRemocaoContext);
	return (
		<ButtonRemove
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalConfirmacaoRemocao
							handleSubmit={remover}
							identificacaoSelecionada={identificacaoSelecionada}
						/>
					),
				}));
			}}
			disabled={disabled}
		/>
	);
};

const ModalConfiguraIdentificacao = ({
	condominioSelecionado,
	onChange,
	value,
	setIdentificacoes,
	identificacoes,
	setIdentificacoesAtivas,
	ModalContext,
}) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const { setModalValue } = useContext(ModalContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const [nome, setNome] = useState('');
	const [vencimento, setVencimento] = useState(null);
	const [responsavel, setResponsavel] = useState(null);
	const [mode, setMode] = useState(identificacoes.length === 0 ? 'cadastrar' : 'visualizar');
	const [valid, setValid] = useState(false);
	const [updatedValue, setUpdatedValue] = useState(null);
	const [updatedIdentificacoes, setUpdatedIdentificacoes] = useState(null);
	useEffect(() => {
		setUpdatedValue(identificacoes.find((item) => item.id === value) || null);
		setUpdatedIdentificacoes(identificacoes);
	}, []);

	const vencimentoOptions = getVencimentoOptions();

	const refreshFields = () => {
		setTimeout(() => {
			setVencimento(null);
			setResponsavel(null);
			if (updatedIdentificacoes) {
				setVencimento(
					mode === 'cadastrar' ? 0 : updatedIdentificacoes.find((d) => d.id === updatedValue.id).vencimento
				);
				setResponsavel(
					mode === 'cadastrar' ? 'Z' : updatedIdentificacoes.find((d) => d.id === updatedValue.id).responsavel
				);
			}
		}, 50);
	};

	useEffect(refreshFields, [value, updatedValue]);

	const validate = () => {
		setTimeout(
			() =>
				setValid(
					vencimento >= 0 &&
						responsavel &&
						nome &&
						(mode === 'cadastrar'
							? !updatedIdentificacoes.find((d) => nome === d.nome)
							: !updatedIdentificacoes.find((d) => nome === d.nome && updatedValue?.id !== d.id))
				),
			50
		);
	};

	useEffect(validate, [vencimento, nome, responsavel, mode]);

	const cadastrar = () => {
		dP.create('identificacao_arrecadacao', {
			data: {
				nome,
				vencimento,
				responsavel,
				id_condominio: condominioSelecionadoId,
			},
		})
			.then((response) => {
				const data = response?.data;
				if (data && data.id && data.nome && (data.vencimento || data?.vencimento === 0) && data.responsavel) {
					setIdentificacoesAtivas((i) => [...i, data]);
					setUpdatedIdentificacoes((i) => [...i, data]);
					setIdentificacoes((i) => [...i, data]);
					onChange(data.id);
					setUpdatedValue(data);
					refreshFields();
				}
				setMode('visualizar');
				notify('Identificação cadastrada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const editar = () => {
		dP.update('identificacao_arrecadacao', {
			id: updatedValue.id,
			data: {
				nome,
				vencimento,
				responsavel,
			},
		})
			.then((response) => {
				const updatedData = response?.data;

				const updatedList = updatedIdentificacoes.map((i) =>
					i?.id === updatedData?.id ? { ...i, ...updatedData } : i
				);

				setMode('visualizar');
				setIdentificacoes(updatedList);
				setUpdatedValue(updatedData);
				setUpdatedIdentificacoes(updatedList);
				onChange(updatedData.id);
				notify('Identificação alterada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const remover = () => {
		dP.delete('identificacao_arrecadacao', {
			id: updatedValue.id,
		})
			.then(() => {
				setMode('visualizar');
				const updatedList = updatedIdentificacoes.filter((i) => i?.id !== updatedValue.id);
				setIdentificacoes(updatedList);
				setUpdatedIdentificacoes(updatedList);
				setUpdatedValue(updatedIdentificacoes[0]);
				onChange(updatedIdentificacoes[0].id);

				refreshFields();
				notify('Identificação removida com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const inativar = () => {
		dP.update('identificacao_arrecadacao', {
			id: updatedValue.id,
			data: { situacao: 'I' },
		})
			.then(() => {
				setMode('visualizar');
				setUpdatedValue((prevValue) => ({
					...prevValue,
					situacao: 'I',
				}));
				onChange((prevValue) => ({
					...prevValue,
					situacao: 'I',
				}));
				notify('Identificação inativada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const reativar = () => {
		dP.update('identificacao_arrecadacao', {
			id: updatedValue.id,
			data: { situacao: 'A' },
		})
			.then(() => {
				setMode('visualizar');
				setUpdatedValue((prevValue) => ({
					...prevValue,
					situacao: 'A',
				}));
				onChange((prevValue) => ({
					...prevValue,
					situacao: 'A',
				}));
				notify('Identificação reativada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const btnCancelar = (
		<ButtonCancel
			onClick={() => {
				setMode('visualizar');
				refreshFields();
			}}
		/>
	);
	const acoes = () => {
		switch (mode) {
			case 'visualizar':
				return (
					<>
						{updatedValue &&
							(updatedValue?.situacao === 'I' ? (
								<BtnRemoverIdent
									{...{ remover }}
									disabled={updatedValue.nome === 'Principal'}
									identificacaoSelecionada={updatedValue}
								/>
							) : (
								<ButtonRemove
									onClick={() => {
										inativar();
									}}
									disabled={updatedValue.nome === 'Principal'}
								>
									Inativar
								</ButtonRemove>
							))}
						{updatedValue &&
							(updatedValue?.situacao === 'I' ? (
								<ButtonConfirm onClick={reativar}>Reativar</ButtonConfirm>
							) : (
								<Button
									onClick={() => {
										setMode('editar');
										setNome(updatedIdentificacoes?.find((d) => d?.id === updatedValue.id)?.nome);
									}}
									disabled={updatedValue?.nome === 'Principal'}
								>
									Editar
								</Button>
							))}
						<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
					</>
				);
			case 'editar':
				return (
					<>
						{btnCancelar}
						<ButtonConfirm onClick={editar} disabled={!valid}>
							Salvar
						</ButtonConfirm>
					</>
				);
			case 'cadastrar':
				return (
					<>
						{btnCancelar}
						<ButtonConfirm onClick={cadastrar} disabled={!valid}>
							Salvar
						</ButtonConfirm>
					</>
				);
			default:
				return <></>;
		}
	};

	return (
		<ModalContextProvider Context={ModalRemocaoContext}>
			<CustomDialogBody
				title={
					<Box display='flex' justifyContent='space-between' alignItems='center'>
						<span>Configurar Identificações</span>
						<TooltipIconButton
							title='Cadastrar Identificação'
							onClick={() => {
								setNome('');
								setMode('cadastrar');
								refreshFields();
							}}
							disabled={mode !== 'visualizar'}
							size='small'
						>
							<AddCircleOutlineIcon color={mode !== 'visualizar' ? 'disabled' : 'primary'} />
						</TooltipIconButton>
					</Box>
				}
				customActions={acoes()}
				form={{
					validate,
					component: (
						<Box display='grid' gridRowGap='15px'>
							{mode === 'visualizar' ? (
								<SelectIdentComp
									updatedValue={updatedValue}
									setUpdatedValue={setUpdatedValue}
									identData={updatedIdentificacoes}
									label='Identificação'
									onChange={onChange}
								/>
							) : (
								<TextField
									margin='dense'
									fullWidth
									label='Identificação'
									defaultValue={
										mode === 'cadastrar'
											? ''
											: updatedIdentificacoes.find((d) => d.id === updatedValue.id).nome
									}
									onChange={(e) => setNome(e.target.value)}
								/>
							)}
							{(vencimento !== null || mode == 'cadastrar') && (
								<Autocomplete
									options={vencimentoOptions || []}
									renderInput={(params) => (
										<TextField {...params} margin='dense' fullWidth label='Vencimento' />
									)}
									renderOption={(option) => option.name}
									getOptionLabel={(option) => (option.name ? option.name : '')}
									onChange={(_, v) => {
										setVencimento(v.id);
									}}
									defaultValue={
										mode === 'cadastrar'
											? 0
											: vencimentoOptions.find(
													(v) =>
														v.id ===
														(updatedIdentificacoes?.find((d) => d.id === updatedValue.id)
															?.vencimento || 0)
											  )
									}
									fullWidth
									disableClearable
									disabled={mode === 'visualizar'}
								/>
							)}

							{responsavel !== null && (
								<Autocomplete
									options={respOpcoes}
									renderInput={(params) => (
										<TextField
											{...params}
											margin='dense'
											fullWidth
											label='Responsável pela cobrança'
										/>
									)}
									renderOption={(option) => option.name}
									getOptionLabel={(option) => (option.name ? option.name : '')}
									onChange={(_, v) => {
										setResponsavel(v.id);
									}}
									defaultValue={
										mode === 'cadastrar'
											? 'Z'
											: respOpcoes.find(
													(v) =>
														v.id ===
														(updatedIdentificacoes?.find((d) => d.id === updatedValue?.id)
															?.responsavel || '')
											  )
									}
									disabled={mode === 'visualizar'}
									fullWidth
									readOnly
								/>
							)}
						</Box>
					),
				}}
			/>
			<CustomDialog Context={ModalRemocaoContext} />
		</ModalContextProvider>
	);
};

export default ModalConfiguraIdentificacao;
