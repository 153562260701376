import React, { useState, useCallback, useContext, useEffect } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import { isAfter } from 'date-fns';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { TooltipIconButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { TooltipVoltarIconButton } from 'components/common/buttons/BotaoVoltar';
import VinculosDatePicker from '../common/VinculosDatePicker';
import formatDateISO from '../common/formatDateISO';
import DropZone from './DropZone';
import ImportUnidadesContext from './ImportUnidadesContext';
import OpcoesImportacaoUnidadesGaragensModal from './OpcoesImportacaoUnidadesGaragensModal';

import { CondominioSelectUnidades } from '../common/CondominioSelect';
import { GrupoSelectUnidades } from '../common/GrupoSelect';

const useStylesGridTemplate = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gap: '1rem',
	},
}));

const DropZoneImportUnidadesModal = () => {
	const classes = useStylesGridTemplate();
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const {
		condominio,
		dataEntradaResponsaveis,
		grupo,
		files,
		setDataEntradaResponsaveis,
		SetErrosNoArquivo,
		setFiles,
		setModalStep,
		setResultadoImport,
	} = useContext(ImportUnidadesContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [valid, setValid] = useState(false);
	const [processing, setProcessing] = useState(false);

	const validate = useCallback(() => {
		setValid(
			condominio?.id &&
				(grupo?.id ? grupo?.id_condominio === condominio?.id : true) &&
				dataEntradaResponsaveis &&
				dataEntradaResponsaveis.toString() !== 'Invalid Date' &&
				isAfter(new Date(), dataEntradaResponsaveis) &&
				!!files.length
		);
	}, [condominio, grupo, dataEntradaResponsaveis, files]);

	useEffect(validate, [condominio, grupo, dataEntradaResponsaveis, files.length]);

	const processarArquivos = useCallback(() => {
		setProcessing(true);
		const formData = new FormData();
		formData.append('id_condominio_id', condominio.id);
		if (grupo?.id) formData.append('id_grupo_unidade_id', grupo.id);
		formData.append('data_entrada_responsaveis', formatDateISO(dataEntradaResponsaveis));
		formData.append('csv', Array.from(files)[0].file);
		dataProvider
			.processarArquivosRetorno('unidades/importar', {
				data: formData,
			})
			.then((response) => {
				setProcessing(false);
				const data = response?.data;
				if (data) {
					setResultadoImport(data?.result || []);
					SetErrosNoArquivo(data?.erros_no_arquivo || []);
				}
				setModalStep('2');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setResultadoImport([]);
				SetErrosNoArquivo([]);
				setProcessing(false);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
			});
	}, [
		setProcessing,
		condominio,
		dataEntradaResponsaveis,
		grupo,
		files,
		dataProvider,
		setResultadoImport,
		SetErrosNoArquivo,
		notify,
		setModalStep,
	]);

	return (
		<CustomDialogBodySizeEditable
			title='Importação de Unidades'
			customActions={[
				<TooltipVoltarIconButton
					onClick={() => {
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <OpcoesImportacaoUnidadesGaragensModal />,
						}));
					}}
				/>,
				<TooltipIconButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />,
				<TooltipIconButtonConfirm
					title='Processar Arquivos'
					id='process_remessas'
					disabled={!valid || processing}
					onClick={() => {
						setResultadoImport([]);
						SetErrosNoArquivo([]);
						processarArquivos();
					}}
				/>,
			]}
			form={{
				component: processing ? (
					<Box display='flex' justifyContent='center'>
						<CircularProgress />
					</Box>
				) : (
					<>
						<Box className={classes.container}>
							<CondominioSelectUnidades disabled={processing} />
							<GrupoSelectUnidades disabled={processing} />
							<VinculosDatePicker
								label='Data de Entrada dos Responsáveis'
								maxDate={new Date()}
								maxDateMessage='Data de entrada não pode ser posterior ao dia de hoje'
								value={dataEntradaResponsaveis}
								onChange={(v) => setDataEntradaResponsaveis(v)}
							/>
						</Box>
						<DropZone filesSelected={files} setFilesSelected={setFiles} />
					</>
				),
			}}
		/>
	);
};

export default DropZoneImportUnidadesModal;
