import React from 'react';

import { Box } from '@material-ui/core';

import LinhaField from './LinhaField';

const LinhaBoxBase = (props) => (
	<Box flexDirection='row' display='flex'>
		<Box flex={1}>
			<LinhaField {...props} />
		</Box>
	</Box>
);

export { LinhaField, LinhaBoxBase };

const TabInstrucoesCobrancaBoleto = () => (
	<Box>
		<LinhaBoxBase num={1} />
		<LinhaBoxBase num={2} />
		<LinhaBoxBase num={3} />
		<LinhaBoxBase num={4} />
		<LinhaBoxBase num={5} />
	</Box>
);

export default TabInstrucoesCobrancaBoleto;
