import React from 'react';
import { ConfiguracaoDeCorrecaoMonetariaContext } from './contexts';
import Create from './Create';
import Edit from './Edit';
import List from './List';
import { ModalContextProvider, CustomDialog } from 'components/common/ModalContext';

const ConfigCorrecaoMonetariaBody: React.FC = () => {
	const { screen } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);

	if (screen.screen === 'edit') {
		return (
			<ModalContextProvider>
				<Edit />
				<CustomDialog />
			</ModalContextProvider>
		);
	}

	if (screen.screen === 'create') {
		return (
			<>
				<Create />
			</>
		);
	}

	return <List />;
};

export default ConfigCorrecaoMonetariaBody;
