import React, { useContext } from 'react';

import TextField from '@material-ui/core/TextField';

import LeiturasListContext from '../../LeiturasListContext';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../../common/CurrencyInput';

const ValorConsumoTotalField = () => {
	const { valorConsumoTotal, setValorConsumoTotal, leituraSituacao } = useContext(LeiturasListContext);
	return (
		<TextField
			align='right'
			disabled={leituraSituacao !== 'A'}
			inputProps={{
				...sanitizeListNumberFormatBRLProps({
					decimalScale: 2,
					prefix: 'R$ ',
				}),
				style: { textAlign: 'right' },
			}}
			InputProps={{ inputComponent: NumberFormatBRL }}
			label='Valor de Consumo Total'
			margin='dense'
			onChange={(event) => {
				if (event?.target) {
					let newValue = event.target.value || 0;
					newValue = newValue >= 100000000.0 ? 999999999.99 : newValue;
					event.target.value = newValue;
					setValorConsumoTotal(newValue);
				} else {
					setValorConsumoTotal(0);
				}
			}}
			size='small'
			value={valorConsumoTotal}
		/>
	);
};

export default ValorConsumoTotalField;
