import React from 'react';

import { InstrucoesRemessaSemDiasFields } from '../../../../../commons/InstrucoesRemessaFields';

type ValoresProps = {
	sources: {
		sourceValor?: any;
	};
	values: any;
	optionsIsencoesTipo: any;
};

const emptyProps = {};

const JurosFields = ({ propsTipo = emptyProps, propsValor = emptyProps }) => (
	<InstrucoesRemessaSemDiasFields
		sourcePrincipal='juros'
		propsTipo={{
			source: 'juros_tipo',
			label: 'Juros',
			...propsTipo,
		}}
		propsValor={{
			source: 'juros_valor',
			decimalScale: 2,
			...propsValor,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoJuros = ({
	sources: { sourceValor } = emptyProps,
	values,
	optionsIsencoesTipo,
}: ValoresProps) => ({
	values: { [sourceValor]: values[sourceValor] },
	sources: { sourceValor },
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoJuros =
	({ sources: { sourceValor } = emptyProps, optionsIsencoesTipo }: ValoresProps, change: any) =>
	(_: any, tipoValue: any, input: any) => {
		input.onChange(tipoValue);
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			return;
		}
	};

export default JurosFields;
