import React, { useContext } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import { VinculosContext } from './../../VinculosContext';

const AddImobiliaria = ({ setImob, checked, setChecked, displaySwitch = true, ...rest }) => {
	const { imobiliarias: data } = useContext(VinculosContext);

	return (
		<>
			{displaySwitch && (
				<FormControlLabel
					control={
						<Switch color='primary' checked={checked} onChange={(e) => setChecked(e.target.checked)} />
					}
					label='Adicionar Imobiliária'
					style={{ margin: '4px 0 8px' }}
				/>
			)}

			<Autocomplete
				clearText='Limpar'
				noOptionsText='Nenhum resultado encontrado'
				options={Object.keys(data).map((key) => data[key])}
				getOptionLabel={(o) => o.nome || ''}
				getOptionSelected={(option, v) => v?.id === option.id}
				renderInput={(params) => {
					if (!checked && params.inputProps.ref.current && params.inputProps.value) {
						params.inputProps.ref.current.nextElementSibling.children[0].click();
					}
					return <TextField {...params} label='Selecionar Imobiliária' margin='dense' />;
				}}
				fullWidth
				onChange={(_, v) => setImob(v)}
				style={
					checked && data
						? {
								transition: 'all 200ms ease',
								maxHeight: 500,
								opacity: 1,
						  }
						: {
								transition: 'all 200ms ease',
								maxHeight: 0,
								opacity: 0,
								pointerEvents: 'none',
						  }
				}
				{...rest}
			/>
		</>
	);
};

export default AddImobiliaria;
