import React from 'react';

import AutorenewIcon from '@material-ui/icons/Autorenew';

import { useListContext } from 'react-admin';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../../common/ModalSizeEditableContext';
import { TooltipIconButton } from '../../../common/TooltipIconButton';
import ModalConfirmarSincronizacao from './ModalConfirmacaoSincronizacao';

const TooltipButtonSincronizarEnderecoDosResponsaveisComPessoas = () => {
	const { selectedIds } = useListContext();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	const abrirModalConfirmarSincronizacao = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmarSincronizacao ids={selectedIds} />,
		}));
	};

	return (
		<TooltipIconButton
			color='primary'
			title='Sincronizar Endereços com Pessoas'
			onClick={abrirModalConfirmarSincronizacao}
			disabled={!selectedIds?.length}
		>
			<AutorenewIcon />
		</TooltipIconButton>
	);
};

const BulkActions = () => (
	<>
		<ModalSizeEditableContextProvider
			customDialogProps={{
				disableBackdropClick: true,
				disableEscapeKeyDown: true,
			}}
		>
			<TooltipButtonSincronizarEnderecoDosResponsaveisComPessoas />
		</ModalSizeEditableContextProvider>
	</>
);

export default BulkActions;
