import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelect, periodoOptions } from '../dateUtils';
import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesContasReceitaCheckboxGroup = ({
	imprimirContasTitulosReceitas,
	imprimirContasAnaliticasReceitas,
	destacarNegritoContasAnaliticasReceitas,
	destacarItalicoContasAnaliticasReceitas,
	separarReferenciaReceitas,
	handleChange,
}) => {
	return (
		<>
			<FormLabel component='legend'>Contas de Receitas</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirContasTitulosReceitas}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_contas_titulos_receitas');
								handleChange(checked, 'imprimir_contas_analiticas_receitas');
								if (!checked) {
									handleChange(false, 'destacar_negrito_contas_analiticas_receitas');
									handleChange(false, 'destacar_italico_contas_analiticas_receitas');
									handleChange(false, 'imprimir_data_movimentacao_receitas');
									handleChange(false, 'imprimir_transacao_movimentacao_receitas');
									handleChange(false, 'imprimir_historico_movimentacao_receitas');
									handleChange(false, 'imprimir_valor_movimentacao_receitas');
									handleChange(false, 'destacar_italico_movimentacao_receitas');
									handleChange(false, 'separar_referencia');
								}
							}}
							name='imprimir_contas_titulos_receitas'
						/>
					}
					label='Imprimir contas títulos'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirContasAnaliticasReceitas}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_contas_analiticas_receitas');
								if (!checked) {
									handleChange(false, 'destacar_negrito_contas_analiticas_receitas');
									handleChange(false, 'destacar_italico_contas_analiticas_receitas');
									handleChange(false, 'imprimir_data_movimentacao_receitas');
									handleChange(false, 'imprimir_transacao_movimentacao_receitas');
									handleChange(false, 'imprimir_historico_movimentacao_receitas');
									handleChange(false, 'imprimir_valor_movimentacao_receitas');
									handleChange(false, 'destacar_italico_movimentacao_receitas');
								}
							}}
							name='imprimir_contas_analiticas_receitas'
						/>
					}
					label='Imprimir contas analíticas'
					disabled={!imprimirContasTitulosReceitas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={destacarNegritoContasAnaliticasReceitas}
							onChange={(e, checked) =>
								handleChange(checked, 'destacar_negrito_contas_analiticas_receitas')
							}
							name='destacar_negrito_contas_analiticas_receitas'
						/>
					}
					label='Destacar contas analíticas em negrito'
					disabled={!imprimirContasAnaliticasReceitas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={destacarItalicoContasAnaliticasReceitas}
							onChange={(e, checked) =>
								handleChange(checked, 'destacar_italico_contas_analiticas_receitas')
							}
							name='destacar_italico_contas_analiticas_receitas'
						/>
					}
					label='Destacar contas analíticas em itálico'
					disabled={!imprimirContasAnaliticasReceitas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={separarReferenciaReceitas}
							onChange={(e, checked) => handleChange(checked, 'separar_referencia')}
							name='separar_referencia'
						/>
					}
					label='Separar referência das receitas'
					disabled={!imprimirContasAnaliticasReceitas}
				/>
			</FormGroup>
		</>
	);
};

const OpcoesContasDespesaCheckboxGroup = ({
	imprimirContasTitulosDespesas,
	imprimirContasAnaliticasDespesas,
	destacarNegritoContasAnaliticasDespesas,
	destacarItalicoContasAnaliticasDespesas,
	handleChange,
}) => (
	<>
		<FormLabel component='legend'>Contas de Despesas</FormLabel>
		<FormGroup>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirContasTitulosDespesas}
						onChange={(e, checked) => {
							handleChange(checked, 'imprimir_contas_titulos_despesas');
							handleChange(checked, 'imprimir_contas_analiticas_despesas');
							if (!checked) {
								handleChange(false, 'destacar_negrito_contas_analiticas_despesas');
								handleChange(false, 'destacar_italico_contas_analiticas_despesas');
								handleChange(false, 'imprimir_data_movimentacao_despesas');
								handleChange(false, 'imprimir_transacao_movimentacao_despesas');
								handleChange(false, 'imprimir_historico_movimentacao_despesas');
								handleChange(false, 'imprimir_valor_movimentacao_despesas');
								handleChange(false, 'destacar_italico_movimentacao_despesas');
							}
						}}
						name='imprimir_contas_titulos_despesas'
					/>
				}
				label='Imprimir contas título'
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirContasAnaliticasDespesas}
						onChange={(e, checked) => {
							handleChange(e);
							handleChange(checked, 'imprimir_contas_analiticas_despesas');
							if (!checked) {
								handleChange(false, 'destacar_negrito_contas_analiticas_despesas');
								handleChange(false, 'destacar_italico_contas_analiticas_despesas');
								handleChange(false, 'imprimir_data_movimentacao_despesas');
								handleChange(false, 'imprimir_transacao_movimentacao_despesas');
								handleChange(false, 'imprimir_historico_movimentacao_despesas');
								handleChange(false, 'imprimir_valor_movimentacao_despesas');
								handleChange(false, 'destacar_italico_movimentacao_despesas');
							}
						}}
						name='imprimir_contas_analiticas_despesas'
					/>
				}
				label='Imprimir contas analíticas'
				disabled={!imprimirContasTitulosDespesas}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={destacarNegritoContasAnaliticasDespesas}
						onChange={(e, checked) => {
							handleChange(checked, 'destacar_negrito_contas_analiticas_despesas');
						}}
						name='destacar_negrito_contas_analiticas_despesas'
					/>
				}
				label='Destacar contas analíticas em negrito'
				disabled={!imprimirContasAnaliticasDespesas}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={destacarItalicoContasAnaliticasDespesas}
						onChange={(e, checked) => {
							handleChange(checked, 'destacar_italico_contas_analiticas_despesas');
						}}
						name='destacar_italico_contas_analiticas_despesas'
					/>
				}
				label='Destacar contas analíticas em itálico'
				disabled={!imprimirContasAnaliticasDespesas}
			/>
		</FormGroup>
	</>
);

const OpcoesMovimentacoesReceitaCheckboxGroup = ({
	imprimirDataMovimentacaoReceitas,
	imprimirTransacaoMovimentacaoReceitas,
	imprimirHistoricoMovimentacaoReceitas,
	imprimirValorMovimentacaoReceitas,
	destacarItalicoMovimentacaoReceitas,
	handleChange,
	disabledByContas,
}) => {
	return (
		<>
			<FormLabel component='legend'>Movimentações de Receitas</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirDataMovimentacaoReceitas}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_data_movimentacao_receitas');
							}}
							name='imprimir_data_movimentacao_receitas'
						/>
					}
					label='Imprimir data das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTransacaoMovimentacaoReceitas}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_transacao_movimentacao_receitas');
							}}
							name='imprimir_transacao_movimentacao_receitas'
						/>
					}
					label='Imprimir nº da transação das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirHistoricoMovimentacaoReceitas}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_historico_movimentacao_receitas');
							}}
							name='imprimir_historico_movimentacao_receitas'
						/>
					}
					label='Imprimir descrição/histórico das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirValorMovimentacaoReceitas}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_valor_movimentacao_receitas');
							}}
							name='imprimir_valor_movimentacao_receitas'
						/>
					}
					label='Imprimir valor das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={destacarItalicoMovimentacaoReceitas}
							onChange={(e, checked) => {
								handleChange(checked, 'destacar_italico_movimentacao_receitas');
							}}
							name='destacar_italico_movimentacao_receitas'
						/>
					}
					label='Destacar as movimentações em itálico'
					disabled={disabledByContas}
				/>
			</FormGroup>
		</>
	);
};

const OpcoesMovimentacoesDespesaCheckboxGroup = ({
	imprimirDataMovimentacaoDespesas,
	imprimirTransacaoMovimentacaoDespesas,
	imprimirHistoricoMovimentacaoDespesas,
	imprimirValorMovimentacaoDespesas,
	destacarItalicoMovimentacaoDespesas,
	handleChange,
	disabledByContas,
	imprimirDocumentosAnexados,
}) => {
	useEffect(() => {
		if (
			disabledByContas ||
			(!imprimirDataMovimentacaoDespesas &&
				!imprimirTransacaoMovimentacaoDespesas &&
				!imprimirHistoricoMovimentacaoDespesas &&
				!imprimirValorMovimentacaoDespesas &&
				!imprimirDocumentosAnexados)
		) {
			handleChange(false, 'destacar_italico_movimentacao_despesas');
		}
	}, [
		disabledByContas ||
			(!imprimirDataMovimentacaoDespesas &&
				!imprimirTransacaoMovimentacaoDespesas &&
				!imprimirHistoricoMovimentacaoDespesas &&
				!imprimirValorMovimentacaoDespesas &&
				!imprimirDocumentosAnexados),
	]);
	return (
		<>
			<FormLabel component='legend'>Movimentações de Despesas</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirDataMovimentacaoDespesas}
							onChange={(e, checked) => handleChange(checked, 'imprimir_data_movimentacao_despesas')}
							name='imprimir_data_movimentacao_despesas'
						/>
					}
					label='Imprimir data das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTransacaoMovimentacaoDespesas}
							onChange={(e, checked) => handleChange(checked, 'imprimir_transacao_movimentacao_despesas')}
							name='imprimir_transacao_movimentacao_despesas'
						/>
					}
					label='Imprimir nº da transação das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirHistoricoMovimentacaoDespesas}
							onChange={(e, checked) => handleChange(checked, 'imprimir_historico_movimentacao_despesas')}
							name='imprimir_historico_movimentacao_despesas'
						/>
					}
					label='Imprimir descrição/histórico das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirValorMovimentacaoDespesas}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_valor_movimentacao_despesas');
							}}
							name='imprimir_valor_movimentacao_despesas'
						/>
					}
					label='Imprimir valor das movimentações'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={destacarItalicoMovimentacaoDespesas}
							onChange={(e, checked) => handleChange(checked, 'destacar_italico_movimentacao_despesas')}
							name='destacar_italico_movimentacao_despesas'
						/>
					}
					label='Destacar as movimentações em itálico'
					disabled={
						disabledByContas ||
						(!imprimirDataMovimentacaoDespesas &&
							!imprimirTransacaoMovimentacaoDespesas &&
							!imprimirHistoricoMovimentacaoDespesas &&
							!imprimirValorMovimentacaoDespesas &&
							!imprimirDocumentosAnexados)
					}
				/>
			</FormGroup>
		</>
	);
};

const OpcoesResumoContasBancariasCheckboxGroup = ({
	imprimirResumoContasBancarias,
	imprimirTotalResumoContasBancarias,
	separarContaByTipo,
	pularPaginaResumo,
	handleChange,
}) => {
	return (
		<>
			<FormLabel component='legend'>Opções de Resumo das Contas Bancárias</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirResumoContasBancarias}
							onChange={(e, checked) => {
								if (checked) {
									handleChange(checked, 'imprimir_resumo_contas_bancarias');
									handleChange(true, 'imprimir_total_resumo_contas_bancarias');
								} else {
									handleChange(false, 'imprimir_resumo_contas_bancarias');
									handleChange(false, 'imprimir_total_resumo_contas_bancarias');
									handleChange(false, 'separar_conta_by_tipo');
									handleChange(false, 'pular_pagina_resumo');
								}
							}}
							name='imprimir_resumo_contas_bancarias'
						/>
					}
					label='Imprimir resumo das contas'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTotalResumoContasBancarias}
							onChange={(e, checked) => handleChange(checked, 'imprimir_total_resumo_contas_bancarias')}
							name='imprimir_total_resumo_contas_bancarias'
						/>
					}
					label='Imprimir total das contas no resumo'
					disabled={!imprimirResumoContasBancarias}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={separarContaByTipo}
							onChange={(e, checked) => handleChange(checked, 'separar_conta_by_tipo')}
							name='separar_conta_by_tipo'
						/>
					}
					label='Separar contas por tipo'
					disabled={!imprimirResumoContasBancarias}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={pularPaginaResumo}
							onChange={(e, checked) => handleChange(checked, 'pular_pagina_resumo')}
							name='pular_pagina_resumo'
						/>
					}
					label='Pular página para o resumo das contas'
					disabled={!imprimirResumoContasBancarias}
				/>
			</FormGroup>
		</>
	);
};

const optionsImpressaoInadimplentes = [
	{ id: '0', nome: 'Não imprimir' },
	{ id: '1', nome: 'Apenas Totais' },
	{ id: '2', nome: 'Devedores Simplificado' },
	{ id: '3', nome: 'Devedores Detalhado' },
];

const SelectImpressaoInadimplentes = ({ setImpressaoInadimplentes, impressaoInadimplentes }) => (
	<Box flexDirection='column' display='flex' gridGap='0.5rem'>
		<FormLabel component='legend'>Inadimplentes</FormLabel>
		<Autocomplete
			options={optionsImpressaoInadimplentes}
			value={impressaoInadimplentes}
			onChange={(_, newValue) => setImpressaoInadimplentes(newValue)}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option.nome}
			getOptionSelected={(option, newValue) => option.id === newValue.id}
			renderInput={(params) => (
				<TextField
					{...params}
					fullWidth
					label='Selecione a forma de impressão dos inadimplentes'
					margin='dense'
					size='small'
				/>
			)}
			disableClearable
			fullWidth
		/>
	</Box>
);

const OpcoesImpressaoGeralCheckboxGroup = ({
	imprimirResumoPrestacao,
	imprimir_percentual_receita_despesa,
	imprimirPontilhado,
	destacarContasTituloMaiusculo,
	imprimirDocumentosAnexados,
	imprimirAssinatura,
	handleChange,
	setImpressaoInadimplentes,
	impressaoInadimplentes,
	disabledByContas,
}) => {
	useEffect(() => {
		if (disabledByContas) {
			handleChange(false, 'destacar_contas_titulo_maiusculo');
			handleChange(false, 'imprimir_documentos_anexados');
			handleChange(false, 'imprimir_percentual_receita_despesa');
		}
	}, [disabledByContas]);

	return (
		<>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirResumoPrestacao}
							onChange={(e, checked) => handleChange(checked, 'imprimir_resumo_prestacao')}
							name='imprimir_resumo_prestacao'
						/>
					}
					label='Imprimir resumo da prestação'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_percentual_receita_despesa}
							onChange={(e, checked) => handleChange(checked, 'imprimir_percentual_receita_despesa')}
							name='imprimir_percentual_receita_despesa'
						/>
					}
					label='Imprimir percentual de contas'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirPontilhado}
							onChange={(e, checked) => handleChange(checked, 'imprimir_pontilhado')}
							name='imprimir_pontilhado'
						/>
					}
					label='Imprimir pontilhado'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={destacarContasTituloMaiusculo}
							onChange={(e, checked) => handleChange(checked, 'destacar_contas_titulo_maiusculo')}
							name='destacar_contas_titulo_maiusculo'
						/>
					}
					label='Destacar contas título em maiúsculo'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirDocumentosAnexados}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_documentos_anexados');
							}}
							name='imprimir_documentos_anexados'
						/>
					}
					label='Imprimir documentos anexados aos lançamentos de despesas'
					disabled={disabledByContas}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</FormGroup>
			<SelectImpressaoInadimplentes {...{ setImpressaoInadimplentes, impressaoInadimplentes }} />
		</>
	);
};

const OpcoesImpressaoCheckboxGroup = ({ setImpressaoInadimplentes, impressaoInadimplentes }) => {
	const {
		opcoesImpressao: {
			imprimir_contas_titulos_receitas,
			imprimir_contas_analiticas_receitas,
			destacar_negrito_contas_analiticas_receitas,
			destacar_italico_contas_analiticas_receitas,
			separar_referencia,
			imprimir_data_movimentacao_receitas,
			imprimir_transacao_movimentacao_receitas,
			imprimir_historico_movimentacao_receitas,
			imprimir_valor_movimentacao_receitas,
			destacar_italico_movimentacao_receitas,
			imprimir_contas_titulos_despesas,
			imprimir_contas_analiticas_despesas,
			imprimir_data_movimentacao_despesas,
			imprimir_transacao_movimentacao_despesas,
			imprimir_historico_movimentacao_despesas,
			imprimir_valor_movimentacao_despesas,
			destacar_italico_movimentacao_despesas,
			destacar_negrito_contas_analiticas_despesas,
			destacar_italico_contas_analiticas_despesas,
			imprimir_resumo_contas_bancarias,
			imprimir_total_resumo_contas_bancarias,
			separar_conta_by_tipo,
			pular_pagina_resumo,
			imprimir_resumo_prestacao,
			imprimir_percentual_receita_despesa,
			imprimir_pontilhado,
			destacar_contas_titulo_maiusculo,
			imprimir_documentos_anexados,
			imprimir_assinatura,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);
	return (
		<Box display='flex'>
			<Box flex={1} mr='1em'>
				<Box display='flex' mb='1em'>
					<Box flex={1} mr='1em'>
						<OpcoesContasReceitaCheckboxGroup
							imprimirContasTitulosReceitas={imprimir_contas_titulos_receitas}
							imprimirContasAnaliticasReceitas={imprimir_contas_analiticas_receitas}
							destacarNegritoContasAnaliticasReceitas={destacar_negrito_contas_analiticas_receitas}
							destacarItalicoContasAnaliticasReceitas={destacar_italico_contas_analiticas_receitas}
							separarReferenciaReceitas={separar_referencia}
							handleChange={handleChangeOpcoesImpressao}
						/>
					</Box>
					<Box flex={1} mr='1em'>
						<OpcoesContasDespesaCheckboxGroup
							imprimirContasTitulosDespesas={imprimir_contas_titulos_despesas}
							imprimirContasAnaliticasDespesas={imprimir_contas_analiticas_despesas}
							destacarNegritoContasAnaliticasDespesas={destacar_negrito_contas_analiticas_despesas}
							destacarItalicoContasAnaliticasDespesas={destacar_italico_contas_analiticas_despesas}
							handleChange={handleChangeOpcoesImpressao}
						/>
					</Box>
					<Box flex={1}>
						<OpcoesResumoContasBancariasCheckboxGroup
							imprimirResumoContasBancarias={imprimir_resumo_contas_bancarias}
							imprimirTotalResumoContasBancarias={imprimir_total_resumo_contas_bancarias}
							separarContaByTipo={separar_conta_by_tipo}
							pularPaginaResumo={pular_pagina_resumo}
							handleChange={handleChangeOpcoesImpressao}
						/>
					</Box>
				</Box>
				<Box display='flex' mb='1em'>
					<Box flex={1} mr='1em'>
						<OpcoesMovimentacoesReceitaCheckboxGroup
							imprimirDataMovimentacaoReceitas={imprimir_data_movimentacao_receitas}
							imprimirTransacaoMovimentacaoReceitas={imprimir_transacao_movimentacao_receitas}
							imprimirHistoricoMovimentacaoReceitas={imprimir_historico_movimentacao_receitas}
							imprimirValorMovimentacaoReceitas={imprimir_valor_movimentacao_receitas}
							destacarItalicoMovimentacaoReceitas={destacar_italico_movimentacao_receitas}
							handleChange={handleChangeOpcoesImpressao}
							disabledByContas={!imprimir_contas_analiticas_receitas}
						/>
					</Box>
					<Box flex={1} mr='1em'>
						<OpcoesMovimentacoesDespesaCheckboxGroup
							imprimirDataMovimentacaoDespesas={imprimir_data_movimentacao_despesas}
							imprimirTransacaoMovimentacaoDespesas={imprimir_transacao_movimentacao_despesas}
							imprimirHistoricoMovimentacaoDespesas={imprimir_historico_movimentacao_despesas}
							imprimirValorMovimentacaoDespesas={imprimir_valor_movimentacao_despesas}
							destacarItalicoMovimentacaoDespesas={destacar_italico_movimentacao_despesas}
							imprimirDocumentosAnexados={imprimir_documentos_anexados}
							handleChange={handleChangeOpcoesImpressao}
							disabledByContas={!imprimir_contas_analiticas_despesas}
						/>
					</Box>
					<Box flex={1}>
						<OpcoesImpressaoGeralCheckboxGroup
							imprimir_percentual_receita_despesa={imprimir_percentual_receita_despesa}
							imprimirResumoPrestacao={imprimir_resumo_prestacao}
							imprimirPontilhado={imprimir_pontilhado}
							destacarContasTituloMaiusculo={destacar_contas_titulo_maiusculo}
							imprimirDocumentosAnexados={imprimir_documentos_anexados}
							imprimirAssinatura={imprimir_assinatura}
							handleChange={handleChangeOpcoesImpressao}
							setImpressaoInadimplentes={setImpressaoInadimplentes}
							impressaoInadimplentes={impressaoInadimplentes}
							disabledByContas={!(imprimir_contas_titulos_receitas || imprimir_contas_titulos_despesas)}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const optionsTamanhoFonte = [
	{ id: '2', nome: 'Grande' },
	{ id: '1', nome: 'Normal' },
	{ id: '0', nome: 'Pequeno' },
];

const SelectTamanhoFonte = ({ setTamanhoFonte, tamanhoFonte }) => (
	<Box flexDirection='row' display='flex'>
		<Autocomplete
			options={optionsTamanhoFonte}
			value={tamanhoFonte}
			onChange={(_, newValue) => setTamanhoFonte(newValue)}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option.nome}
			getOptionSelected={(option, newValue) => option.id === newValue.id}
			renderInput={(params) => (
				<TextField {...params} size='small' fullWidth label='Selecione o tamanho da fonte' />
			)}
			disableClearable
			fullWidth
		/>
	</Box>
);

const PrestacaoDeContasDivisor = ({ idCondominio, tipoRelatorio }) => {
	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const [dataInicial, setDataInicial] = useState();
	const [dataFinal, setDataFinal] = useState();
	const [tamanhoFonte, setTamanhoFonte] = useState(optionsTamanhoFonte[1]);
	const [impressaoInadimplentes, setImpressaoInadimplentes] = useState(optionsImpressaoInadimplentes[0]);
	const {
		opcoesImpressao: {
			imprimir_contas_titulos_receitas,
			imprimir_contas_analiticas_receitas,
			destacar_negrito_contas_analiticas_receitas,
			destacar_italico_contas_analiticas_receitas,
			separar_referencia,
			imprimir_data_movimentacao_receitas,
			imprimir_transacao_movimentacao_receitas,
			imprimir_historico_movimentacao_receitas,
			imprimir_valor_movimentacao_receitas,
			destacar_italico_movimentacao_receitas,
			imprimir_contas_titulos_despesas,
			imprimir_contas_analiticas_despesas,
			imprimir_data_movimentacao_despesas,
			imprimir_transacao_movimentacao_despesas,
			imprimir_historico_movimentacao_despesas,
			imprimir_valor_movimentacao_despesas,
			destacar_italico_movimentacao_despesas,
			destacar_negrito_contas_analiticas_despesas,
			destacar_italico_contas_analiticas_despesas,
			imprimir_resumo_contas_bancarias,
			imprimir_total_resumo_contas_bancarias,
			separar_conta_by_tipo,
			pular_pagina_resumo,
			imprimir_resumo_prestacao,
			imprimir_pontilhado,
			destacar_contas_titulo_maiusculo,
			imprimir_documentos_anexados,
			imprimir_assinatura,
			imprimir_percentual_receita_despesa,
		},
	} = React.useContext(CheckboxRelatorioContext);
	const requestData = {
		id_condominio: idCondominio,
		data_inicial: dataInicial,
		data_final: dataFinal,
		tamanhoFonte: tamanhoFonte?.id,
		tipo_impressao_devedores: impressaoInadimplentes.id,
		nome_relatorio: tipoRelatorio.action,
		imprimir_contas_titulos_receitas: imprimir_contas_titulos_receitas,
		imprimir_contas_analiticas_receitas: imprimir_contas_analiticas_receitas,
		destacar_negrito_contas_analiticas_receitas: destacar_negrito_contas_analiticas_receitas,
		destacar_italico_contas_analiticas_receitas: destacar_italico_contas_analiticas_receitas,
		separar_referencia: separar_referencia,
		imprimir_data_movimentacao_receitas: imprimir_data_movimentacao_receitas,
		imprimir_transacao_movimentacao_receitas: imprimir_transacao_movimentacao_receitas,
		imprimir_historico_movimentacao_receitas: imprimir_historico_movimentacao_receitas,
		imprimir_valor_movimentacao_receitas: imprimir_valor_movimentacao_receitas,
		destacar_italico_movimentacao_receitas: destacar_italico_movimentacao_receitas,
		imprimir_contas_titulos_despesas: imprimir_contas_titulos_despesas,
		imprimir_contas_analiticas_despesas: imprimir_contas_analiticas_despesas,
		imprimir_data_movimentacao_despesas: imprimir_data_movimentacao_despesas,
		imprimir_transacao_movimentacao_despesas: imprimir_transacao_movimentacao_despesas,
		imprimir_historico_movimentacao_despesas: imprimir_historico_movimentacao_despesas,
		imprimir_valor_movimentacao_despesas: imprimir_valor_movimentacao_despesas,
		destacar_italico_movimentacao_despesas: destacar_italico_movimentacao_despesas,
		destacar_negrito_contas_analiticas_despesas: destacar_negrito_contas_analiticas_despesas,
		destacar_italico_contas_analiticas_despesas: destacar_italico_contas_analiticas_despesas,
		imprimir_resumo_contas_bancarias: imprimir_resumo_contas_bancarias,
		imprimir_total_resumo_contas_bancarias: imprimir_total_resumo_contas_bancarias,
		separar_conta_by_tipo: separar_conta_by_tipo,
		pular_pagina_resumo: pular_pagina_resumo,
		imprimir_resumo_prestacao: imprimir_resumo_prestacao,
		imprimir_pontilhado: imprimir_pontilhado,
		destacar_contas_titulo_maiusculo: destacar_contas_titulo_maiusculo,
		imprimir_documentos_anexados: imprimir_documentos_anexados,
		imprimir_assinatura: imprimir_assinatura,
		imprimir_percentual_receita_despesa: imprimir_percentual_receita_despesa,
	};
	return (
		<BoxDivisor titulo='Prestação de Contas'>
			<Box display='flex' mb='1em' gridGap='1rem'>
				<Box flex={1}>
					<PeriodoSelect
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={dataInicial}
						setDataInicial={setDataInicial}
						setDataFinal={setDataFinal}
						dataFinal={dataFinal}
						label='Selecionar Período'
					/>
				</Box>
				<Box flex={1}>
					<SelectTamanhoFonte tamanhoFonte={tamanhoFonte} setTamanhoFonte={setTamanhoFonte} />
				</Box>
			</Box>
			<OpcoesImpressaoCheckboxGroup
				setImpressaoInadimplentes={setImpressaoInadimplentes}
				impressaoInadimplentes={impressaoInadimplentes}
			/>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};

export default PrestacaoDeContasDivisor;
