import React, { FormEvent, memo, useEffect, useState } from 'react';
import { TextInput, useDataProvider, useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import { Typography } from '@material-ui/core';

import { CustomDataProvider } from 'types/tpyesGlobal';
import { emailValidacao } from 'fieldControl/email';
import { CheckButton, CancelButton2 } from 'components/common/commonComponentsTSX';
import { InputDocumento } from 'components/common/InputDocumento';
import { useGestaoContext } from './contexts/GestaoContext';
import { BoxAddPessoaProps, BoxPessoaSelectProps, BoxSelectPessoaProps } from './types';
import { useGestaoModal } from './hooks';
// import { ModalOrdernarAssinatura } from './modals/OrdenarAssinatura';
import { CustomDialog /*,ModalContext*/, ModalContextProvider } from 'components/common/ModalContext';
// import { useFiliacoesContext } from './contexts/FiliacoesContext';

export const BoxSelectPessoa: React.FC<BoxSelectPessoaProps> = ({ modoAdd, setModoAdd }) => {
	// const { setModalValue, modalValue } = React.useContext(ModalContext);
	// const { Filiacoes } = useFiliacoesContext();

	// const handleSetModalOrdernarAssinatura = () => {
	// 	setModalValue((v: typeof modalValue) => ({
	// 		...v,
	// 		open: true,
	// 		dialogBody: <ModalOrdernarAssinatura />,
	// 	}));
	// };

	return (
		<Box
			display='flex'
			alignItems='center'
			style={
				modoAdd
					? {
							transition: 'all 200ms ease',
							pointerEvents: 'none',
							opacity: 0.3,
							marginBottom: 16,
					  }
					: { transition: 'all 200ms ease' }
			}
		>
			<Box display='flex' width='100%' justifyContent='space-between' alignItems='center' mt={2}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Incluir Membros da Gestão do Condomínio
					</Typography>
				</Box>
				<Box>
					{/* <IconButton
						disabled={Filiacoes.length < 2 && !Filiacoes.filter((i) => i.assinar_relatorio).length}
						color='primary'
						onClick={handleSetModalOrdernarAssinatura}
						style={
							Filiacoes.length >= 2 && Filiacoes.filter((i) => i.assinar_relatorio).length
								? {}
								: { opacity: 0.2 }
						}
					>
						<MenuIcon />
						<Typography variant='button' noWrap>
							Ordernar Assinatura
						</Typography>
					</IconButton> */}
					<Button
						color='primary'
						onClick={() => setModoAdd(true)}
						disabled={modoAdd}
						style={{ flex: 1, margin: '8px 0 4px' }}
						variant='outlined'
					>
						Nova Pessoa
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export const BoxAddPessoa: React.FC<BoxAddPessoaProps> = memo(({ modoAdd, setModoAdd }) => {
	const { condominioRecord, reloadPessoa } = useGestaoContext();
	const { handleAddSelectsMembro } = useGestaoModal();

	return (
		<Box
			display='flex'
			flexDirection='column'
			style={
				modoAdd
					? {
							opacity: 1,
							maxHeight: 200,
							transition: 'all 200ms ease',
					  }
					: {
							maxHeight: 0,
							opacity: 0,
							pointerEvents: 'none',
							transition: 'all 200ms ease',
					  }
			}
		>
			<Box display='flex'>
				<Box flex={1} mr='0.5em'>
					<Form
						onSubmit={(e) => console.log('')}
						render={({ values, form }) => {
							const dp: CustomDataProvider = useDataProvider();
							const notify = useNotify();

							const handleCancel = () => {
								setModoAdd(false);
								form.reset({
									tipo_pessoa: 'F',
								});
							};

							const handleCreate = (e: FormEvent) => {
								e.preventDefault();
								dp.create('pessoas', {
									data: values,
								})
									.then((res) => {
										reloadPessoa();
										handleCancel();
										handleAddSelectsMembro(res?.data?.id as number);
										const pessoa = res?.data;

										const mensagem =
											pessoa?.documento == null && pessoa?.email_cobranca == null
												? 'nem um documento nem um email de cobrança cadastrado.'
												: pessoa?.documento == null
												? 'um documento cadastrado.'
												: pessoa?.email_cobranca == null
												? 'um email de cobrança cadastrado.'
												: null;

										if (mensagem !== null) {
											return notify(`Pessoa ${pessoa.nome} não possui ${mensagem}`, {
												type: 'error',
											});
										}
									})
									.catch((e) => {
										if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
										return notify('Ocorreu um erro ao cadastrar a nova pessoa.', { type: 'error' });
									});
							};
							return (
								<>
									<Field
										name='nome'
										render={({ input }) => (
											<Box marginBottom='0.5em'>
												<TextField
													label='Nome'
													name={input.name}
													fullWidth
													margin='dense'
													value={input.value}
													onChange={input.onChange}
												/>
											</Box>
										)}
									/>
									<Box display='flex'>
										<Box flex={1}>
											<InputDocumento
												flexDirection='row'
												resource='pessoas'
												source='documento'
												sourceTipo='tipo_pessoa'
												disabled={condominioRecord.situacao === 'I'}
												variant='outlined'
												fullWidth
											/>
										</Box>
										<Box>
											<CancelButton2 onClick={handleCancel} />
											<CheckButton onClick={handleCreate} disabled={!values?.nome} />
										</Box>
									</Box>
									<Field
										name='email_cobranca'
										render={({ input }) => (
											<TextInput
												name={input.name}
												source='email'
												label='Email'
												validate={emailValidacao}
												onChange={input.onChange}
												variant='outlined'
												fullWidth
											/>
										)}
									/>
								</>
							);
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
});

const BoxPessoaSelect: React.FC<BoxPessoaSelectProps> = ({ handleSetValid }) => {
	const [modoAdd, setModoAdd] = useState(false);

	useEffect(() => {
		if (modoAdd) {
			handleSetValid();
		}
	}, [modoAdd, handleSetValid]);

	return (
		<ModalContextProvider>
			<CustomDialog />
			<Box mb={2}>
				<BoxSelectPessoa modoAdd={modoAdd} setModoAdd={setModoAdd} />
				<BoxAddPessoa modoAdd={modoAdd} setModoAdd={setModoAdd} />
			</Box>
		</ModalContextProvider>
	);
};

export default BoxPessoaSelect;
