import React, { useContext } from 'react';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { format, isSameDay, parse, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { CurrencyField } from '../../../common/CurrencyInput';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../../common/ModalSizeEditableContext';
import { TooltipIconButton } from '../../../common/TooltipIconButton';
import TooltipBar from '../../../common/TooltipBar';

import { isFeriado, isDomingo, isSabado } from '../ajusteDomingosFeriados';
import { LancamentoContaPagarContext } from '../LancamentoContaPagarContext';
import { ListParcelasContextProvider } from './ListParcelasContext';
import { ModalParcelar, ModalEditarParcela, ModalExcluirParcela } from './modaisParcelas';

const SituacaoChip = ({ value, textColor, ...rest }) => (
	<Chip
		label={
			<Typography variant='caption' style={{ color: textColor }}>
				{value}
			</Typography>
		}
		size='small'
		clickable={false}
		style={{ background: '#f1f1f1' }}
		{...rest}
	/>
);

const SituacaoField = ({ situacao, data_vencimento }) => {
	const theme = useTheme();
	let situacaoParcela = situacao;
	if (['N', 'C'].includes(situacaoParcela)) {
		situacaoParcela = 'PG';
	} else if (situacaoParcela === 'P') {
		const hoje = parseISO(format(new Date(), 'yyyy-MM-dd'));
		const dataVencimento = parseISO(data_vencimento);
		if (dataVencimento > hoje) situacaoParcela = 'EA';
		if (dataVencimento < hoje) situacaoParcela = 'VN';
		if (isSameDay(dataVencimento, hoje)) situacaoParcela = 'VH';
	}
	switch (situacaoParcela) {
		case 'EA':
			return <SituacaoChip value='Em aberto' textColor={theme.palette.success.dark} />;
		case 'VH':
			return <SituacaoChip value='Vencendo hoje' textColor={theme.palette.warning.dark} />;
		case 'VN':
			return <SituacaoChip value='Vencida' textColor={theme.palette.syndikosRed.dark} />;
		case 'PG':
			return <SituacaoChip value='Paga' textColor={theme.palette.primary.dark} />;
		default:
			return <SituacaoChip value={situacao} />;
	}
};

const TooltipButtonParcelar = () => {
	const { erros, parcelas } = useContext(LancamentoContaPagarContext);
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	return (
		<TooltipIconButton
			size='small'
			title='Parcelar'
			disabled={!!erros?.parcelas || parcelas.some((objeto) => objeto.linha_digitavel)}
			onClick={() => {
				setMinWidthModel('80vw');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalParcelar context={ModalSizeEditableContext} />,
				}));
			}}
		>
			<AddCircleOutlineIcon />
		</TooltipIconButton>
	);
};

const TooltipButtonEditarParcela = (record) => {
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	return (
		<TooltipIconButton
			size='small'
			title='Editar Parcela'
			onClick={() => {
				setMinWidthModel('65vw');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalEditarParcela context={ModalSizeEditableContext} record={record} />,
				}));
			}}
		>
			<EditIcon />
		</TooltipIconButton>
	);
};

const TooltipButtonExcluirParcela = (record) => {
	const { erros } = useContext(LancamentoContaPagarContext);
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	return (
		<TooltipIconButton
			size='small'
			title='Excluir Parcela'
			disabled={!!erros?.parcelas}
			onClick={() => {
				setMinWidthModel('55vw');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalExcluirParcela record={record} context={ModalSizeEditableContext} />,
				}));
			}}
		>
			<DeleteOutlineIcon />
		</TooltipIconButton>
	);
};

const InfoParcela = ({
	numero_parcela,
	situacao,
	data_vencimento,
	data_previsao,
	valor,
	valor_pendente,
	porcentagem,
	editada,
	...rest
}) => {
	const { parcelas, record } = useContext(LancamentoContaPagarContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const parsedDataVencimento = parse(data_vencimento, 'yyyy-MM-dd', new Date());
	const parsedDataPrevisao = parse(data_previsao, 'yyyy-MM-dd', new Date());
	return (
		<TableRow>
			<TableCell
				align='center'
				style={{ paddingLeft: 0, width: '1%', whiteSpace: 'nowrap', textTransform: 'none' }}
			>
				{parcelas.length > 1
					? `${numero_parcela}`.padStart(3, '0') + `/${`${parcelas.length}`.padStart(3, '0')}`
					: 'Única'}
			</TableCell>
			<TableCell align='right' style={{ paddingLeft: 0, textTransform: 'none' }}>
				<SituacaoField {...{ situacao, data_vencimento }} />
			</TableCell>
			<TableCell
				align='right'
				style={
					isFeriado(parsedDataPrevisao) || isDomingo(parsedDataPrevisao) || isSabado(parsedDataPrevisao)
						? { color: syndikosRed.main, paddingLeft: 0, textTransform: 'none' }
						: { paddingLeft: 0, textTransform: 'none' }
				}
			>
				{format(parsedDataPrevisao, 'dd/MM/yyyy eeeeee', { locale: pt })}
			</TableCell>
			<TableCell
				align='right'
				style={
					isFeriado(parsedDataVencimento) || isDomingo(parsedDataVencimento) || isSabado(parsedDataVencimento)
						? { color: syndikosRed.main, paddingLeft: 0, textTransform: 'none' }
						: { paddingLeft: 0, textTransform: 'none' }
				}
			>
				{format(parsedDataVencimento, 'dd/MM/yyyy eeeeee', { locale: pt })}
			</TableCell>
			<TableCell align='right' style={{ paddingLeft: 0, textTransform: 'none' }}>
				{situacao !== 'P' && (
					<CurrencyField
						prefix='R$ '
						minimumFractionDigits={2}
						value={valor}
						id={`valor-${numero_parcela}`}
					/>
				)}
			</TableCell>
			<TableCell align='right' style={{ paddingLeft: 0, textTransform: 'none' }}>
				<CurrencyField
					prefix='R$ '
					minimumFractionDigits={2}
					value={valor_pendente || 0}
					id={`valor-${numero_parcela}`}
				/>
			</TableCell>
			<TableCell align='right' style={{ paddingLeft: 0, textTransform: 'none' }}>
				<CurrencyField
					suffix='%'
					minimumFractionDigits={2}
					value={porcentagem || 0}
					id={`porcentagem-${numero_parcela}`}
				/>
			</TableCell>
			<TableCell align='right' style={{ paddingLeft: 0, textTransform: 'none' }}>
				{situacao === 'P' && !record.retencao_imposto && (
					<TooltipBar>
						<TooltipButtonEditarParcela
							{...{
								numero_parcela,
								situacao,
								data_vencimento,
								data_previsao,
								valor_pendente,
								porcentagem,
								...rest,
							}}
							key='edita-button'
						/>
						<TooltipButtonExcluirParcela {...{ numero_parcela }} key='exclui-button' />
					</TooltipBar>
				)}
			</TableCell>
		</TableRow>
	);
};

const TabelaParcelas = () => {
	const { selectedCondominio, despesas, parcelas, record } = useContext(LancamentoContaPagarContext);
	return (
		<Box>
			{selectedCondominio &&
				despesas.length > 0 &&
				parcelas.find((p) => p.situacao === 'P') &&
				!record.retencao_imposto && <TooltipButtonParcelar />}
			<Table size='small' padding='default'>
				<TableHead>
					<TableRow>
						<TableCell align='center' style={{ width: '10%', whiteSpace: 'nowrap' }}>
							Parcela
						</TableCell>
						<TableCell
							align='right'
							style={{
								paddingLeft: 0,
								width: '10%',
								whiteSpace: 'nowrap',
							}}
						>
							Situação
						</TableCell>
						<TableCell
							align='right'
							style={{
								paddingLeft: 0,
								width: '15%',
								whiteSpace: 'nowrap',
							}}
						>
							Previsão Pagamento
						</TableCell>
						<TableCell
							align='right'
							style={{
								paddingLeft: 0,
								width: '15%',
								whiteSpace: 'nowrap',
							}}
						>
							Vencimento
						</TableCell>
						<TableCell
							align='right'
							style={{
								paddingLeft: 0,
								width: '15%',
								whiteSpace: 'nowrap',
							}}
						>
							Pagamento
						</TableCell>
						<TableCell
							align='right'
							style={{
								paddingLeft: 0,
								width: '15%',
								whiteSpace: 'nowrap',
							}}
						>
							Valor
						</TableCell>
						<TableCell
							align='right'
							style={{
								paddingLeft: 0,
								width: '10%',
								whiteSpace: 'nowrap',
							}}
						>
							%
						</TableCell>
						<TableCell
							align='right'
							style={{
								padding: 0,
								width: '10%',
								whiteSpace: 'nowrap',
							}}
						></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{parcelas?.length > 0 &&
						parcelas?.map((parcela, index) => <InfoParcela key={`parcela-${index}`} {...parcela} />)}
				</TableBody>
			</Table>
		</Box>
	);
};

const ListParcelas = () => (
	<ListParcelasContextProvider>
		<ModalSizeEditableContextProvider
			customDialogProps={{
				disableBackdropClick: true,
				disableEscapeKeyDown: true,
				minWidth: '80vw',
			}}
		>
			<TabelaParcelas />
		</ModalSizeEditableContextProvider>
	</ListParcelasContextProvider>
);

export default ListParcelas;
