import React from 'react';

import { Tooltip, IconButton, Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import { RelatorioInputDate } from 'components/resources/relatorios/dateUtils';
import { useSegundaViaContext } from '../abas/ContasReceberRemessas/contextos/SegundaViaContext';
import { ArrecadacoesContext } from '../ArrecadacoesContext';

interface CustomTheme extends Theme {
	orange: {
		[key: number]: string;
	};
}
const useStyles = makeStyles((theme: CustomTheme) => ({ font_orange: { color: theme.orange[300] } }));

const BoxConfigSegundaVia: React.FC = () => {
	const {
		checkboxes,
		corrigirAteData,
		setCorrigirAteData,
		novaDataVencimento,
		setNovaDataVencimento,
		handleCheckboxChange,
	} = useSegundaViaContext();
	const classes = useStyles();
	const { identificacaoInstituicaoTipoIntegracao } = React.useContext(ArrecadacoesContext);

	return (
		<Box>
			<Box display='flex' alignItems='center'>
				<h3>Encargos do Boleto</h3>
				<Tooltip title='Todos os encargos selecionados, serão calculados conforme a tabela de configuração de juros, multa e correção monetária habilitada neste condomínio.'>
					<IconButton>
						<InfoIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<Box>
				<RelatorioInputDate
					label='Corrigir até dia'
					handleChange={(_: any, newValue: string) => {
						setCorrigirAteData(newValue);
					}}
					value={corrigirAteData}
					fullWidth
				/>
			</Box>
			<Box mt={'1em'} mb={'0.5em'}>
				<RelatorioInputDate
					label='Nova data de vencimento'
					handleChange={(_: any, newValue: string) => {
						setNovaDataVencimento(newValue);
					}}
					value={novaDataVencimento}
					fullWidth
				/>
			</Box>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={checkboxes.calcular_multa}
							onChange={handleCheckboxChange}
							name='calcular_multa'
						/>
					}
					label='Calcular Multa'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={checkboxes.calcular_juros}
							onChange={handleCheckboxChange}
							name='calcular_juros'
						/>
					}
					label='Calcular Juros'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={checkboxes.calcular_correcao_monetaria}
							onChange={handleCheckboxChange}
							name='calcular_correcao_monetaria'
						/>
					}
					label='Calcular Correção Monetária'
				/>
			</FormGroup>
			<Box display='flex' alignItems='center' mt={4}>
				{identificacaoInstituicaoTipoIntegracao.split('-')[1] != '0' ? (
					<Typography className={classes.font_orange}>
						<strong>Aviso: </strong>Após realizar as configurações para emissão da 2º via do boleto, você
						deverá importar o arquivo de remessa no seu banco para registro dos novos boletos.
					</Typography>
				) : (
					<Typography className={classes.font_orange}>
						<strong>Aviso: </strong>Após concluir as configurações e confirmar a geração da 2ª via do
						boleto, o registro será efetuado automaticamente em sua instituição bancária.
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default BoxConfigSegundaVia;
