import React from 'react';

import { InstrucoesRemessaSemDiasFields } from '../../../../../commons/InstrucoesRemessaFields';

const emptyProps = {};

const JurosFields = ({ propsTipo = emptyProps, propsValor = emptyProps }) => (
	<InstrucoesRemessaSemDiasFields
		sourcePrincipal='juros'
		propsTipo={{
			source: 'juros_tipo',
			label: 'Juros',
			...propsTipo,
		}}
		propsValor={{
			source: 'juros_valor',
			decimalScale: 3,
			...propsValor,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoJuros = ({
	sources: { sourceValor } = emptyProps,
	values,
	optionsIsencoesTipo,
}) => ({
	values: { [sourceValor]: values[sourceValor] },
	sources: { sourceValor },
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoJuros =
	({ sources: { sourceValor } = emptyProps, values, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			return;
		}

		const valorValue = values[sourceValor];
		if (tipoValue.id === 'JTX') {
			(!valorValue || valorValue > 0.33) && change(sourceValor, 0.33);
			return;
		}

		![undefined, null].includes(valorValue) && change(sourceValor, undefined);
	};

export default JurosFields;
