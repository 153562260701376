import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import UserMenuItem from 'components/common/UserMenuItem';
import ConsultaLicencasList from '../../resources/licencas/controleLicencas';
import { StatusLicencasContextProvider } from '../../resources/licencas/StatusLicencasProvider';
import { CondominiosContextProvider } from 'context/CondominioContextProvider';
import { AdminContext } from 'components/common/AdminContextProvider';
import { Ballot } from '@material-ui/icons';

interface MenuControleLicencasProps {
	url: string;
}

function MenuControleLicencas({ url }: MenuControleLicencasProps) {
	const { situacaoIrregular } = useContext(AdminContext);
	const [abreControleLicencas, setAbreControleLicencas] = useState(false);

	function handleOpenModal() {
		setAbreControleLicencas(true);
	}

	useEffect(() => {
		if (situacaoIrregular) handleOpenModal();
	}, [situacaoIrregular]);

	return (
		<>
			<UserMenuItem
				menuItemProps={{ onClick: handleOpenModal }}
				label={'Controle de Licenças'}
				icon={<Ballot />}
			/>
			<CondominiosContextProvider>
				<StatusLicencasContextProvider>
					{abreControleLicencas && (
						<ConsultaLicencasList
							url={url}
							abreControleLicencas={abreControleLicencas}
							setAbreControleLicencas={setAbreControleLicencas}
						/>
					)}
				</StatusLicencasContextProvider>
			</CondominiosContextProvider>
		</>
	);
}
export default MenuControleLicencas;
