import React from 'react';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

const FormEdicaoConfiguracaoLeitura = ({ initialValues = {}, render }) => (
	<Form
		onSubmit={(e) => {
			e.preventDefault();
		}}
		mutators={{ ...arrayMutators }}
		initialValues={initialValues}
		render={render}
	/>
);

export default FormEdicaoConfiguracaoLeitura;
