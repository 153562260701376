import React from 'react';

import { Create } from 'react-admin';

import { TarefasManutencoesFormulario } from './form';

export const TarefasManutencoesCreate = (props) => (
	<Create title='Cadastrar Tarefa' undoable={false} {...props}>
		<TarefasManutencoesFormulario {...props} />
	</Create>
);
