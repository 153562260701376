import React from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { RadioButtonGroupProps } from './types';

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ value, setValue, label, id, options }) => {
	const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	return (
		<RadioGroup
			id={id}
			style={{ flexDirection: 'row' }}
			name={label || 'radio-group'}
			value={value}
			onChange={handleChangeRadio}
		>
			{options.map((option) => (
				<FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.nome} />
			))}
		</RadioGroup>
	);
};

export default RadioButtonGroup;
