import React from 'react';
import { connect } from 'react-redux';
import { userLogout } from 'react-admin';
import LogoutIcon from '../../common/icons/LogoutIcon';
import UserMenuItem from '../../common/UserMenuItem';

const MenuItemLogout = ({ userLogout, ...rest }) => (
	<UserMenuItem
		label={'Sair'}
		icon={<LogoutIcon />}
		menuItemProps={{
			onClick: userLogout,
			to: '/login/',
			...rest,
		}}
	/>
);

const redirectTo = '/login/';
const myCustomUserLogout = () => userLogout(redirectTo);
export default connect(undefined, { userLogout: myCustomUserLogout })(MenuItemLogout);
