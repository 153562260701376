import React from 'react';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
	LimitePagamentoFields,
	ProtestoFields,
} from '../../tabs/TabInstrucoesCobrancaRemessa';

import {
	formataPropsMaxValuePorLimitePagamentoDias,
	getDadosFormattingContaCorrente,
	formatCedentePattern2,
	parseCedentePattern2,
} from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';

import DefaultContaCorrenteProps, {
	defaultComponentesProps,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesProtesto,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	getValidateQuandoEmitindoBoletos,
	getValidacoesLimitePagamento,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	mensagensErrosPadroes,
} from '../default/ContaCorrenteProps';

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	nomeCampoLegivel: 'limite de pagamento',
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamentoComSemLimiteComoOpcao(),
});

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	msgErroVazio: mensagensErrosPadroes.vazioProtesto(),
	minValue: 2,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto({ minValue: 2 }),
	maxValue: 90,
	msgErroMaxValue: mensagensErrosPadroes.maxValueProtesto({ maxValue: 90 }),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesLimitePagamento.tipo,
	validacoesLimitePagamento.dias,

	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesLimitePagamento({ values }),
	...formataPayloadValuesProtesto({ values }),
});

const TabInstrucoesCobrancaRemessa = (props) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<LimitePagamentoFields />, <ProtestoFields />]}
	/>
);

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '104',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(8),
		},
		conta_cedente: {
			parse: parseCedentePattern2(7, 1),
			format: formatCedentePattern2(7, 1),
		},
		limite_pagamento_tipo: {
			label: 'Data Limite Pagamento',
		},
		protesto_dias: { min: 2, max: 90 },
	},
	getOptions: getFormatarOptions('104', { agencia: 'agencias_caixa' }),
	validacoes: [
		...DefaultContaCorrenteProps.validacoes,
		getValidateQuandoEmitindoBoletos(validacoesInstrucoesCobrancaRemessa),
	],
	formatadoresPayloadValues: {
		...defaultFormatadoresPayloadValues,
		cobranca_config: getFormatadorPayloadCobrancaConfig({
			formatadores: [
				...defaultFormatadoresPayloadValuesCobrancaConfig,

				// Dados de Tab Conta
				formataPayloadValuesTabContaCobrancaConfig,

				// Dados de Tab Instruções de Cobrança Remessa
				formataPayloadValuesTabInstrucoesCobrancaRemessa,
			],
		}),
	},
	tabContents: {
		...DefaultContaCorrenteProps.tabContents,
		TabInstrucoesCobrancaRemessa,
	},
};

export default ContaCorrenteProps;
