import React from 'react';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	LimitePagamentoFields,
	ProtestoFields,
} from '../../../../../tabs/TabInstrucoesCobrancaRemessa';

import DescontoFields from '../../../../../tabs/TabInstrucoesCobrancaRemessa/DescontoFields';
import MultaFields from './MultaFields';
import JurosFields from './JurosFields';

import { ValuesProps } from '../../../../../types';

const TabInstrucoesCobrancaRemessaAutomatico = (props: ValuesProps) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<LimitePagamentoFields />, <ProtestoFields />]}
	/>
);

export default TabInstrucoesCobrancaRemessaAutomatico;
