import React from 'react';

import { useTheme, Box, TextField, Typography } from '@material-ui/core';

import { CancelButton } from 'components/common/commonComponentsTSX';
import { ButtonEmitirCobrancasComRelatorio } from '../botoes';

import { BoletosContext } from '../../ContasReceberBoletos/BoletosContextProvider';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';

import { ThemePaletteProps } from 'theme/types';
import { IModalValue } from 'types/tpyesGlobal';

type NomeBancoType = {
	nomeBanco: string;
};

const BoxAvisoESolicitacaoSenha: React.FC<NomeBancoType> = ({ nomeBanco }) => {
	const {
		palette: { syndikosRed },
	} = useTheme<ThemePaletteProps>();
	const { senha, setSenha } = React.useContext(BoletosContext);

	return (
		<Box gridRowGap='0.5rem' display='grid'>
			<Typography
				style={{
					fontWeight: 'bold',
					color: syndikosRed.main,
				}}
			>
				{`A cobrança selecionada já foi registrada no ${nomeBanco}, o reenvio da cobrança fará com que o boleto anterior seja cancelado!`}
			</Typography>
			<Typography
				style={{
					fontWeight: 'bold',
					color: syndikosRed.main,
				}}
			>
				Para confirmar, informe sua senha para gerar os boletos novamente:
			</Typography>
			<TextField
				name='password'
				fullWidth
				type='password'
				label='Senha do usuário'
				value={senha}
				variant='outlined'
				onChange={(e) => setSenha(e.target.value)}
				size='small'
			/>
		</Box>
	);
};

export const ModalConfirmarGerarBoletoAutomatico: React.FC<NomeBancoType> = ({ nomeBanco }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	return (
		<CustomDialogBodySizeEditable
			title={`Emissão de Cobrança Por API do ${nomeBanco}`}
			form={{
				component: <BoxAvisoESolicitacaoSenha nomeBanco={nomeBanco} />,
			}}
			customActions={
				<>
					<CancelButton
						size='small'
						onClick={() => setModalValue((v: IModalValue) => ({ ...v, open: false, body: <></> }))}
					/>
					<ButtonEmitirCobrancasComRelatorio
						erroSemRecebimentoTitle={`Boletos só podem ser emitidos pela API do ${nomeBanco} caso eles não estejam já vencidos`}
					/>
				</>
			}
		/>
	);
};

export default ModalConfirmarGerarBoletoAutomatico;
