import {
	formataPayloadValuesDesconto,
	formataPayloadValuesInstrucoesComTipoValorDias,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
} from '../../default/ContaCorrenteProps/getFormataPayloadValues';

import {
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
} from '../../default/ContaCorrenteProps/getFormataPayloadValues';
import { ValuesProps } from '../../../types';

const formataPayloadValuesMulta = (props: ValuesProps) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'multa',
		valorIsensao: 'ISE',
	});

const formataPayloadValuesJuros = (props: ValuesProps) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'juros',
		valorIsensao: 'JIS',
	});

const formataPayloadValuesDeOption = ({ values, field }: any) => {
	const valor_tipo = values[field]?.id ?? null;
	return { [field]: valor_tipo ?? undefined };
};

const formataPayloadValuesInstrucoesComDias = ({ values, field, tipo_field, opcoesDadosBancarios }: any) => {
	const optionsIsencoesPorId = opcoesDadosBancarios.integracoes[237].optionsIsencoes[tipo_field] || {};
	const valor_tipo = values[tipo_field]?.id ?? null;
	return {
		[field]: optionsIsencoesPorId[valor_tipo] ? undefined : values[field],
	};
};

const formataPayloadValuesSacadorAvalista = () => ({ sacador_avalista: undefined });

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values, ...rest }: ValuesProps) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesDeOption({ values, field: 'instrucao_1' }),
	...formataPayloadValuesInstrucoesComDias({
		...rest,
		values,
		field: 'instrucao_dias',
		tipo_field: 'instrucao_1',
	}),
});

const formatadoresPayloadValues = {
	...defaultFormatadoresPayloadValues,

	cobranca_config: getFormatadorPayloadCobrancaConfig({
		formatadores: [
			...defaultFormatadoresPayloadValuesCobrancaConfig,
			formataPayloadValuesTabContaCobrancaConfig,
			formataPayloadValuesTabInstrucoesCobrancaRemessa,
			formataPayloadValuesSacadorAvalista,
		],
	}),
};

export default formatadoresPayloadValues;
