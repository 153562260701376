import api from './services/api';

export default {
	login: ({ username, password, is_suporte = false, senha_do_dia, schema_name = null }) => {
		try {
			return api.get('/api/v1/token').then(async () => {
				const res = await api.post('/login/submit/', {
					username,
					password,
					is_suporte,
					senha_do_dia,
					schema_name,
				});
				const data = res?.data || {};

				if (res.status === 200) {
					localStorage.setItem('auth', JSON.stringify(data.auth));
					window.dispatchEvent(new Event('authStorage'));
					return Promise.resolve({ msg: data.msg, msgType: data.msg_type });
				} else {
					throw new Error(res.statusText);
				}
			});
		} catch {
			localStorage.removeItem('auth');
			return Promise.reject();
		}
	},
	logout: () => {
		try {
			api.get('/api/v1/logout');
			localStorage.removeItem('auth');
			return Promise.resolve();
		} catch {
			return Promise.reject();
		}
	},
	checkError: (error) => {
		if (
			error &&
			error.response &&
			error.response.status &&
			(error.response.status === 401 || error.response.status === 403)
		) {
			localStorage.removeItem('auth');
			return Promise.reject();
		}
		return Promise.resolve();
	},
	checkAuth: () =>
		localStorage.getItem('auth') ||
		(document.URL || '').match(/admin\.(sci)?syndkos(.+)?(\.com\.br)?\/#\/login_suporte(\/)?$/)
			? Promise.resolve()
			: Promise.reject(),
	getPermissions: () => {
		try {
			const { group_permissions } = JSON.parse(localStorage.getItem('auth'));
			return group_permissions ? Promise.resolve(group_permissions) : Promise.reject();
		} catch (error) {
			return (document.URL || '').match(/admin\.(sci)?syndkos(.+)?(\.com\.br)?\/#\/login_suporte(\/)?$/)
				? Promise.resolve({ id: 3, name: 'Suporte' })
				: Promise.reject(error);
		}
	},
	getIdentity: () => {
		try {
			const { nome, username, email, group_permissions, tenant, system } = JSON.parse(
				localStorage.getItem('auth')
			);
			return Promise.resolve({ nome, username, email, group_permissions, tenant, system });
		} catch (error) {
			return Promise.reject(error);
		}
	},
	reGetIdentity: () => {
		try {
			return api
				.get('/api/v1/identity')
				.then(async (response) => {
					const auth = response?.data?.auth || {};
					localStorage.setItem('auth', JSON.stringify(auth));
					const { nome, username, email, group_permissions, tenant, system } = auth;
					return Promise.resolve({ data: { nome, username, email, group_permissions, tenant, system } });
				})
				.catch((error) => {
					if (
						error &&
						error.response &&
						error.response.status &&
						(error.response.status === 401 || error.response.status === 403)
					) {
						localStorage.removeItem('auth');
						return Promise.reject(error);
					}
					const { nome, username, email, group_permissions, tenant, system } = JSON.parse(
						localStorage.getItem('auth')
					);
					return Promise.resolve({ data: { nome, username, email, group_permissions, tenant, system } });
				});
		} catch (e) {
			return Promise.reject(e);
		}
	},
};
