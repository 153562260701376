import React from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MenuItem from '@material-ui/core/MenuItem';

import { format, parseISO } from 'date-fns';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';

import { ModalConfiguracaoImpressao, ModalBodyFeedbackBoletosNaoEnviados } from '../modais';

export const ButtonEnviarBoletos = ({
	idsRegistrosSelecionados,
	disabled,
	setLoadingRequest,
	label = 'Enviar por E-mail',
	configurar,
	configuracao = {},
}) => {
	const notify = useNotify();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const dataProvider = useDataProvider();
	const refresh = useRefresh();

	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	const handleEnviar = () => {
		if (!configuracao.mensagem_json.length || !configuracao.mensagem_json?.[0]?.text || !configuracao.assunto) {
			return notify('Preencha o campo Assunto e Conteúdo para enviar o e-mail.', 'warning');
		}
		setLoadingRequest(true);
		dataProvider
			.simplePost('boletos/enviar_boletos', {
				data: {
					...configuracao,
					list_ids_conta_a_receber: idsRegistrosSelecionados,
					referencia: format(parseISO(referencia), 'MM-yyyy'),
					id_identificacao: id_identificacao_arrecadacao,
					id_conta_bancaria_provisionada: contaBDestinoId,
					id_condominio: condominioSelecionado?.id,
				},
			})
			.then(({ data }) => {
				setLoadingRequest(false);
				const { message = '', num_boletos_enviados = [], boletos_nao_enviados = [] } = data ?? {};
				if (num_boletos_enviados) notify(message || '');
				if (boletos_nao_enviados?.length) {
					setModalValue((v) => ({
						...v,
						dialogBody: <ModalBodyFeedbackBoletosNaoEnviados boletos_nao_enviados={boletos_nao_enviados} />,
					}));
					return;
				}
				refresh();
				handleClose();
			})
			.catch((e) => {
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				setLoadingRequest(false);
				handleClose();
				notify(erroMsg, 'warning');
			});
	};

	return configurar ? (
		<MenuItem
			disabled={!idsRegistrosSelecionados.length || disabled}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalConfiguracaoImpressao
							idsRegistrosSelecionados={idsRegistrosSelecionados}
							referencia={referencia}
							action='enviar'
						/>
					),
				}));
			}}
		>
			{label}
		</MenuItem>
	) : (
		<Button
			size='small'
			disabled={!idsRegistrosSelecionados.length || disabled}
			variant='contained'
			startIcon={<EmailOutlinedIcon />}
			onClick={() => {
				handleEnviar();
			}}
			color='primary'
		>
			{label}
		</Button>
	);
};

export default ButtonEnviarBoletos;
