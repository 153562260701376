import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import memoize from '../../../../common/memoize';

export const formatAgenciaObject = ({ num, dv, nome }) => ({
	id: dv ? `${num}-${dv}` : num,
	text: dv ? `${num}-${dv} ${nome}` : `${num} ${nome}`,
});

export const nomeContaParser = memoize((value) => {
	if (value) return value.substring(0, 60);
});

export const filterOptions = createFilterOptions({
	limit: 20,
	stringify: (option) => option.text,
});

export const agenciaFormatacao = (value) => {
	if (!value) return '';
	let values = (typeof value === 'object' ? value.id : value).split('-');
	return values.length > 1 ? `${values[0]}-${values[1]}` : values[0];
};

export const agenciaParser = (value) => {
	if (value) {
		value = value.replace(/[^\d, -]/, '');
		if (value.length < 11 && !value.match(/-/)) return value;
		return value
			.replace(/^(\d{0,10})((\d+)?-(\d{0,2})(\d+)?)?/, '$1-$3$4')
			.replace(/(\d+)-(\d{0,2})(\d+)?/, '$1-$2');
	}
};

const parseCedentePattern1 = (max) => memoize((value) => value.replace(/[^\d]/, '').substring(0, max));

const formatCedentePattern1 = (max) => memoize((value) => (value ? value.replace(/[^\d]/, '').substring(0, max) : ''));

const parseCedentePattern2 = memoize((max1, max2) => {
	const regex1 = RegExp(`^(\\d{0,${max1}})((\\d+)?-(\\d{0,${max2}})(.+)?)?`);
	const regex2 = RegExp(`(\\d+)-(\\d{0,${max2}})(.+)?`);
	return (value) => {
		if (!value) return '';
		if (value.length <= max1 && !value.match(/-/)) return value;
		return value.replace(regex1, '$1-$3$4').replace(regex2, '$1-$2');
	};
});

const formatCedentePattern2 = memoize((max1, max2) => {
	const regex1 = RegExp(`^(\\d{0,${max1}})((\\d+)?-(\\d{0,${max2}})(.+)?)?`);
	const regex2 = RegExp(`(\\d+)-(\\d{0,${max2}})(.+)?`);
	return (value) => {
		if (!value) return '';
		if (value.length <= max1 && !value.match(/-/)) return value;
		return value.replace(regex1, '$1-$3$4').replace(regex2, '$1-$2');
	};
});

export { parseCedentePattern1, formatCedentePattern1, parseCedentePattern2, formatCedentePattern2 };

export const getFormatParseContaCorrente = ({ maxLength, regex0, regex1, regex2 }) => ({
	format: (value) => {
		if (!value) return '';
		if (value.length < maxLength && !value.match(/-/)) return value;
		return value.replace(regex1, '$1-$3$4').replace(regex2, '$1-$2');
	},
	parse: (value) => {
		if (value) {
			value = value.replace(regex0, '');
			if (value.length < maxLength && !value.match(/-/)) return value;
			return value.replace(regex1, '$1-$3$4').replace(regex2, '$1-$2').toUpperCase();
		}
	},
});

// Dicionário/Objects úteis para formatação, parsing e montagem de campos
export const nomeTipoContaParser = {
	CC: 'Conta Corrente',
};

export const tipoContaDicionario = {
	C: 'Caixa',
	CC: 'ContaCorrente',
	P: 'ContaNaoCorrente',
	F: 'ContaNaoCorrente',
	A: 'ContaNaoCorrente',
	CT: 'ContaNaoCorrente',
};

export const getDadosFormattingNumeroComDV = (maxLength, maxLengthDV = 1) => ({
	maxLength,
	regex0: RegExp('[^\\d, -]'),
	regex1: RegExp(`^(\\d{0,${maxLength - maxLengthDV}})((\\d+)?-(\\d{0,${maxLengthDV}})(.+)?)?`, ''),
	regex2: RegExp(`(\\d+)-(\\d{0,${maxLengthDV}})(.+)?`, ''),
});

export const getDadosFormattingNumeroComDVComX = (maxLength, maxLengthDV = 1) => ({
	maxLength,
	regex0: RegExp('[^\\d,Xx -]'),
	regex1: RegExp(`^(\\d{0,${maxLength - maxLengthDV}})((\\d+)?-((?:[Xx]+)|\\d{0,${maxLengthDV}})(.+)?)?`, ''),
	regex2: RegExp(`(\\d+)-((?:[Xx]+)|\\d{0,${maxLengthDV}})(.+)?`, ''),
});

export const getDadosFormattingContaCorrente = (maxLength, maxLengthDV = 1) => {
	const dados = getDadosFormattingNumeroComDV(maxLength, maxLengthDV);
	return {
		...dados,
		...getFormatParseContaCorrente(dados),
	};
};

// Object para coletar os regex para o format do campo da conta corrente
export const formataPropsMaxValuePorLimitePagamentoDias = ({
	props,
	errors,
	values,
	extras: { optionsIsencoes = {} } = {},
}) => {
	const condicional =
		!!values.limite_pagamento_tipo &&
		!optionsIsencoes[values.limite_pagamento_tipo?.id] &&
		!errors.limite_pagamento_dias &&
		typeof values.limite_pagamento_dias === 'number';
	const value = condicional ? { maxValue: values.limite_pagamento_dias - 1 } : { maxValue: undefined };
	return Object.assign(props, value);
};
