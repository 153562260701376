import React from 'react';

import InputFile from '../../../../../common/InputFile';
import CadastrarDadosIntegracaoAPIContext from '../../contexts/CadastrarDadosIntegracaoAPIContext';

export const CadastrarDadosIntegracaoAPIFileField = ({ label = '', source = '', PermitedFileExtension }) => {
	const { values, saving, updateValue } = React.useContext(CadastrarDadosIntegracaoAPIContext);
	const [value, setValue] = React.useState(values[source] ?? null);

	return (
		<InputFile
			label={label}
			onCallBack={(_, dataUrl) => {
				updateValue({ source, value: dataUrl });
				setValue(dataUrl);
			}}
			fileDataUrl={value}
			PermitedFileExtension={PermitedFileExtension}
			disabled={saving}
		/>
	);
};
