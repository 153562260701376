import React from 'react';
import { useTheme, Chip, Typography } from '@material-ui/core';

export const SituacaoChip = ({ value, textColor, ...rest }) => (
	<Chip
		label={
			<Typography variant='caption' style={{ color: textColor }}>
				{value}
			</Typography>
		}
		size='small'
		clickable={false}
		style={{ background: '#f1f1f1' }}
		{...rest}
	/>
);

export const Situacao = ({ situacao }) => {
	const theme = useTheme();
	switch (situacao) {
		case 'N':
			return <SituacaoChip value='Não Conciliado' textColor={theme.palette.error.dark} />;
		case 'C':
			return <SituacaoChip value='Conciliado' textColor={theme.palette.primary.dark} />;
		case 'P':
			return <SituacaoChip value='Previsto' textColor={theme.palette.success.dark} />;
		default:
			return <SituacaoChip value={situacao} />;
	}
};
