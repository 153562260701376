import React from 'react';
import { DeleteListContextValue, DeleteListProviderProps } from './types';
import { Box, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TooltipIconButtonCancel } from 'components/common/buttons/ButtonCancel';
import ClearIcon from '@material-ui/icons/Clear';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDataProvider, useNotify } from 'react-admin';
import { CustomDataProvider } from 'types/tpyesGlobal';
import useStyles from './style';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { DeleteButton } from '..';

export const DeleteListContext = React.createContext<DeleteListContextValue>({
	idsSelecionadas: [],
	requesting: false,
	setIdSelecionadas() {},
	deleteList() {},
});

export const DeleteListProvider: React.FC<DeleteListProviderProps> = ({
	children,
	source,
	objetoAlterado,
	setObjetoAlterado,
}) => {
	const [idsSelecionadas, setIdSelecionadas] = React.useState<number[]>([]);
	const dataProvider: CustomDataProvider = useDataProvider();
	const notify = useNotify();
	const [requesting, setRequesting] = React.useState(false);
	const { setModalValue, modalValue } = React.useContext(ModalContext);

	const deleteList = React.useCallback(() => {
		setRequesting(true);
		return dataProvider
			.deleteMany(source, {
				ids: idsSelecionadas,
			})
			.then(() => {
				notify('Exclusão realizada com sucesso', {
					type: 'success',
				});
				setIdSelecionadas([]);
				setModalValue((v: typeof modalValue) => ({
					...v,
					open: false,
				}));
				if (objetoAlterado && setObjetoAlterado) {
					const novosObjetos = objetoAlterado.filter((obj) => !idsSelecionadas.includes(obj.id));
					setObjetoAlterado(novosObjetos);
				}
			})
			.catch(() => {
				return notify('Erro ao realizar a exclusão', { type: 'error' });
			})
			.finally(() => {
				setRequesting(false);
			});
	}, [requesting, dataProvider, notify, idsSelecionadas]);

	const valueProvider = React.useMemo(
		() => ({
			idsSelecionadas,
			setIdSelecionadas,
			deleteList,
			requesting,
		}),
		[idsSelecionadas, setIdSelecionadas, deleteList]
	);

	return <DeleteListContext.Provider value={valueProvider}>{children}</DeleteListContext.Provider>;
};

export const ToolbarDeleteList = () => {
	const { idsSelecionadas, deleteList, setIdSelecionadas } = React.useContext(DeleteListContext);
	const { setModalValue, modalValue } = React.useContext(ModalContext);

	const selecionados = idsSelecionadas.length > 1 ? 'selecionados' : 'selecionado';
	const classes = useStyles({ ids: idsSelecionadas.length });

	const handleCancel = () => {
		setIdSelecionadas([]);
	};
	const handleDelete = () => {
		setModalValue((v: typeof modalValue) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBody
					title={
						idsSelecionadas.length > 1
							? 'Deseja realmente excluir os itens selecionados?'
							: 'Deseja realmente excluir o item selecionado?'
					}
					customActions={
						<>
							<Button
								onClick={() => {
									setModalValue((v: typeof modalValue) => ({ ...v, open: false }));
								}}
								size='small'
								startIcon={<CloseIcon />}
							>
								Cancelar
							</Button>
							<DeleteButton onClick={deleteList}>Excluir</DeleteButton>
						</>
					}
				/>
			),
		}));
	};

	return idsSelecionadas.length ? (
		<Box className={classes.ToolbarDeleteList}>
			<Box>
				<TooltipIconButtonCancel onClick={handleCancel} title='Cancelar' className={null}>
					<ClearIcon />
				</TooltipIconButtonCancel>
				{idsSelecionadas.length} {selecionados}
			</Box>

			<Box>
				<TooltipIconButton title='Remover' onClick={handleDelete}>
					<DeleteOutlineIcon color='secondary' />
				</TooltipIconButton>
			</Box>
		</Box>
	) : (
		<></>
	);
};

const useDeleteListContext = () => React.useContext(DeleteListContext);
export default useDeleteListContext;
