import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';
import ExcluirLeituraButton from '../../componentes/ExcluirLeituraButton';
import InativarLeituraButton from '../../componentes/InativarLeituraButton';
import ReativarLeituraButton from '../../componentes/ReativarLeituraButton';
import SalvarConfigButton from '../../componentes/SalvarConfigButton';

export const DialogActionsPadrao = ({
	dadosConfigEdicao,
	invalid,
	faixas_leitura,
	leituras_para_calculo,
	nao_cobrar_taxa,
	acesso_app,
	percentual,
}) => {
	const { dadosConfig, id_leitura, handleClose, referencia, situacao } = useContext(EditaConfiguracaoLeituraContext);
	return (
		<DialogActions>
			{
				<>
					<Button onClick={handleClose} size='small' startIcon={<CloseIcon />} variant='text'>
						Cancelar
					</Button>
					{situacao === 'I' ? (
						<ReativarLeituraButton leituraId={id_leitura}>Reativar Leitura</ReativarLeituraButton>
					) : (
						<InativarLeituraButton isProvisionado={dadosConfig.provisionada} leituraId={id_leitura}>
							Inativar Leitura
						</InativarLeituraButton>
					)}
					{situacao === 'A' ? (
						<SalvarConfigButton
							dadosConfig={dadosConfig}
							dadosConfigEdicao={{
								...dadosConfigEdicao,
								faixas_leitura,
								leituras_para_calculo,
								nao_cobrar_taxa,
								acesso_app,
								percentual,
								referencia: referencia.toISOString().split('T')[0],
							}}
							disabled={invalid}
						/>
					) : (
						<ExcluirLeituraButton isProvisionado={dadosConfig.provisionada} leituraId={id_leitura}>
							Excluir Leitura
						</ExcluirLeituraButton>
					)}
				</>
			}
		</DialogActions>
	);
};

export const DialogActionsSemSalvar = () => {
	const { dadosConfig, id_leitura, handleClose, situacao } = useContext(EditaConfiguracaoLeituraContext);
	return (
		<DialogActions>
			{
				<>
					<Button size='small' startIcon={<CloseIcon />} onClick={handleClose} variant='text'>
						Cancelar
					</Button>
					{situacao === 'I' ? (
						<ReativarLeituraButton leituraId={id_leitura}>Reativar Leitura</ReativarLeituraButton>
					) : (
						<InativarLeituraButton leituraId={id_leitura} isProvisionado={dadosConfig.provisionada}>
							Inativar Leitura
						</InativarLeituraButton>
					)}
					{situacao === 'I' && (
						<ExcluirLeituraButton leituraId={id_leitura} isProvisionado={dadosConfig.provisionada}>
							Excluir Leitura
						</ExcluirLeituraButton>
					)}
				</>
			}
		</DialogActions>
	);
};
