import React from 'react';

import { Pagination as RAPagination, PaginationProps as RAPaginationProps } from 'react-admin';

interface PaginationProps extends RAPaginationProps {
	labelRowsPerPage: string;
}

const Pagination = RAPagination as React.ExoticComponent<PaginationProps>;

export default Pagination;
