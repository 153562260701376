import React from 'react';

import { useNotify } from 'react-admin';

import {
	Box,
	TextField,
	MenuItem,
	Button,
	FormGroup,
	Checkbox,
	FormControlLabel,
	makeStyles,
	Typography,
} from '@material-ui/core';

import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../ModalSizeEditableContext';

import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

import MUIRichTextEditor from 'mui-rte';
import { useDataProvider } from 'react-admin';
import { CustomDataProvider, SelectTemplates, ModalValue } from './types';

import { ModalCreateTemplateBox, ModalEditTemplateBox } from './Modais/index';
import { BoletosContext } from 'components/resources/arrecadacao/abas/ContasReceberBoletos/BoletosContextProvider';
import { CondominiosContext } from 'context/CondominioContextProvider';

type MenuItemAddNewTemplateProps = {
	setReload: React.Dispatch<React.SetStateAction<number>>;
};

type ButtonEditTemplateProps = {
	templateSelected: SelectTemplates;
	setReload: React.Dispatch<React.SetStateAction<number>>;
};

const templateSelectedInitial = {
	id: null,
	nome_modelo: '',
	comunicado_json: {
		blocks: [],
	},
	comunicado_text: '',
	condominio: null,
};

const useStyles = makeStyles({
	editor: {
		maxHeight: '9rem',
		overflowY: 'auto',
	},
	root: {
		minHeight: '6.25rem',
	},
});

const MenuItemAddNewTemplate: React.FC<MenuItemAddNewTemplateProps> = ({ setReload }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	return (
		<MenuItem
			onClick={() => {
				setModalValue((v: ModalValue) => ({
					...v,
					open: true,
					dialogBody: <ModalCreateTemplateBox setReload={setReload} />,
				}));
			}}
		>
			Criar novo modelo de comunicado
		</MenuItem>
	);
};

const ButtonEditTemplate: React.FC<ButtonEditTemplateProps> = ({ templateSelected, setReload }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	return (
		<Button
			style={{ marginLeft: '10px' }}
			color='primary'
			size='small'
			variant='contained'
			startIcon={<EditIcon />}
			onClick={() => {
				setModalValue((v: ModalValue) => ({
					...v,
					open: true,
					dialogBody: <ModalEditTemplateBox template={templateSelected} setReload={setReload} />,
				}));
			}}
		>
			Editar
		</Button>
	);
};

const ComunicadoTemplate: React.FC = () => {
	const {
		setComunicado,
		comunicadoId,
		setComunicadoId,
		handleChangeOpcoesImpressao,
		opcoesImpressao: { imprimir_comunicado },
	} = React.useContext(BoletosContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const [templateSelected, setTemplateSelected] = React.useState<SelectTemplates>(templateSelectedInitial);
	const [templates, setTemplates] = React.useState<SelectTemplates[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [reload, setReload] = React.useState(0);
	const [errorMessage, setErrorMessage] = React.useState('');
	const dP = useDataProvider() as CustomDataProvider;
	const notify = useNotify();
	const classes = useStyles();

	const handleChangeSelectedTemplate = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const selectedTemplateName = event.target.value;
		if (!selectedTemplateName) return;

		const selectedTemplate: SelectTemplates =
			templates.find((template) => template.nome_modelo === selectedTemplateName) ?? templateSelectedInitial;
		setTemplateSelected(selectedTemplate);
		setComunicado(selectedTemplate.comunicado_text);
		setComunicadoId(selectedTemplate.id);
		setErrorMessage('');
	};

	const handleCheckBoxChange = (e: any, checked: boolean) => {
		if (checked && !templateSelected.id) setErrorMessage('Você não selecionou nenhum modelo de Comunicado');
		else setErrorMessage('');
		handleChangeOpcoesImpressao(checked, 'imprimir_comunicado');
	};

	const getTemplatesDB = () => {
		setLoading(true);
		dP.getSimple('comunicado_template', {
			filter: {
				condominio: condominioSelecionado?.id as number,
			},
		})
			.then(({ data: { results } }: { data: { results: SelectTemplates[] } }) => {
				const comunicadoInicial = results.find((value): any => value?.id === comunicadoId);
				setTemplates(results);
				if (comunicadoInicial) {
					setTemplateSelected(comunicadoInicial);
					setComunicado(comunicadoInicial.comunicado_text);
				} else setTemplateSelected(templateSelectedInitial);
			})
			.catch(() => {
				notify('Erro ao coletar templates do servidor', 'warning');
			})
			.finally(() => setLoading(false));
	};
	React.useEffect(getTemplatesDB, [reload]);

	return (
		<ModalSizeEditableContextProvider
			customDialogProps={{
				minWidth: '61vw',
				position: 'static',
				overflowY: 'auto',
			}}
		>
			<Box>
				<h3>Comunicado</h3>
				<Box
					style={{
						marginBottom: '15px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box flex={1}>
						<TextField
							id='outlined-select-currency'
							size='small'
							select
							label={loading ? 'Carregando...' : 'Selecionar modelo de comunicado personalizado:'}
							value={templateSelected.nome_modelo}
							disabled={loading}
							onChange={handleChangeSelectedTemplate}
							variant='outlined'
							style={{ width: '100%' }}
						>
							<MenuItemAddNewTemplate setReload={setReload} />
							{templates.map((template) => (
								<MenuItem key={template.id} value={template.nome_modelo}>
									{template.nome_modelo}
								</MenuItem>
							))}
						</TextField>
					</Box>
					<Button
						style={{ marginLeft: '10px' }}
						onClick={() => {
							setTemplateSelected(templateSelectedInitial);
							setComunicado('');
							setComunicadoId(null);
						}}
						color='primary'
						size='small'
						variant='contained'
						startIcon={<ClearIcon />}
						disabled={!templateSelected?.id}
					>
						Limpar
					</Button>
					{templateSelected?.id && (
						<ButtonEditTemplate templateSelected={templateSelected} setReload={setReload} />
					)}
				</Box>
				{templateSelected?.id && !loading && (
					<>
						<Box
							style={{
								backgroundColor: 'white',
								color: 'black',
							}}
							border={1}
							borderRadius='borderRadius'
						>
							<MUIRichTextEditor
								defaultValue={JSON.stringify(templateSelected.comunicado_json) ?? ''}
								toolbar={false}
								readOnly={true}
								classes={{
									root: classes.root,
									editor: classes.editor,
								}}
							/>
						</Box>
					</>
				)}
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimir_comunicado}
								onChange={handleCheckBoxChange}
								name='imprimir_comunicado'
							/>
						}
						label='Apresentar Comunicado no Boleto'
					/>
				</FormGroup>
				{errorMessage && <Typography color='error'>{errorMessage}</Typography>}
			</Box>
		</ModalSizeEditableContextProvider>
	);
};

export default ComunicadoTemplate;
