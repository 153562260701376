import React, { useEffect, useMemo } from 'react';

import { Box, Typography } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

import { ConfigRelatorioContext } from '../ConfigRelatorioContextProvider';

import { useDataProvider, useNotify } from 'react-admin';
import { ButtonRemove } from 'components/common/buttons/ButtonRemove';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { ModalContext } from 'components/common/ModalContext';
import ModalConfirmacao from 'components/common/ModalConfirmacao';

export const ButtonAction = () => {
	const {
		opcoesImpressao,
		loading,
		setLoading,
		fetchConfigs,
		condominiosDestinados,
		tamanhoFonte,
		setOpenCreateNewTemplate,
	} = React.useContext(ConfigRelatorioContext);
	const notify = useNotify();
	const dp = useDataProvider();
	const { modalValue, setModalValue } = React.useContext(ModalContext);

	function updateScreen(action) {
		setOpenCreateNewTemplate(false);
		fetchConfigs();
		setLoading(false);
		return notify(`Modelo de configuração ${action} com sucesso!`, 'success');
	}

	function deleteTemplateRelatorios() {
		dp.delete('template_relatorios', {
			id: opcoesImpressao.id,
		})
			.then(() => updateScreen('deletado'))
			.catch((e) => {
				notify(e, 'warning');
				setLoading(false);
			});
	}

	function handleDeleteTemplateEmail() {
		if (opcoesImpressao.nome === 'Template Padrão') {
			return notify('Não é possível excluir o template padrão', 'warning');
		}

		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmacaoConfiguracao callBackOnSuccess={updateScreen} />,
		}));
	}

	function handleUpdateTemplateEmail() {
		if (opcoesImpressao.template_padrao) return handleSaveTemplatePadrao();

		setLoading(true);

		const idsCondominios = condominiosDestinados.map((cond) => cond.id);
		const updateTemplate = {
			...opcoesImpressao,
			condominios: idsCondominios,
			tamanho_fonte: tamanhoFonte,
		};

		dp.update('template_relatorios', {
			id: opcoesImpressao.id,
			data: updateTemplate,
		})
			.then(() => updateScreen('atualizado'))
			.catch((e) => notify(e, 'warning'))
			.finally(() => setLoading(false));
	}

	function handleSaveTemplatePadrao() {
		setLoading(true);

		delete opcoesImpressao.id;
		const idsCondominios = condominiosDestinados.map((cond) => cond.id);
		const createTemplatePadrao = {
			...opcoesImpressao,
			condominios: idsCondominios,
			tamanho_fonte: tamanhoFonte,
		};

		dp.create('template_relatorios', {
			data: createTemplatePadrao,
		})
			.then(() => updateScreen('criado'))
			.catch((e) => notify(e, 'warning'))
			.finally(() => setLoading(false));
	}

	return (
		<Box style={{ display: 'flex', gap: '5px' }}>
			<ButtonConfirm
				color='primary'
				size='small'
				variant='outline'
				startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
				disabled={loading}
				onClick={handleUpdateTemplateEmail}
			>
				Salvar
			</ButtonConfirm>
			{!opcoesImpressao?.template_padrao && (
				<ButtonRemove variant='contained' disabled={loading} onClick={handleDeleteTemplateEmail}>
					Excluir
				</ButtonRemove>
			)}
		</Box>
	);
};

const ModalConfirmacaoConfiguracao = ({ callBackOnSuccess }) => {
	const { setModalValue } = React.useContext(ModalContext);
	const { opcoesImpressao, setLoading } = React.useContext(ConfigRelatorioContext);
	const notify = useNotify();
	const dp = useDataProvider();

	const [loadingDelete, setLoadingDelete] = React.useState(false);

	function deleteTemplateRelatorios() {
		dp.delete('template_relatorios', {
			id: opcoesImpressao.id,
		})
			.then(() => callBackOnSuccess('deletado'))
			.catch((e) => {
				notify(e, 'warning');
				setLoadingDelete(false);
				setLoading(false);
			});
	}

	return (
		<ModalConfirmacao
			handleSubmit={() => {
				if (!loadingDelete) {
					setLoading(!loadingDelete);
					setLoadingDelete(!loadingDelete);
					return deleteTemplateRelatorios();
				}
			}}
			title='Tem certeza que deseja remover essa configuração?'
			setModalValue={setModalValue}
		/>
	);
};
