import React from 'react';

import { Button, ExportButton, TopToolbar } from 'react-admin';

import AddIcon from '@material-ui/icons/Add';

import { GaragensUnidadeContext } from '../../context';

const ActionsList = ({ total, resource, currentSort, filterValues, exporter, ...props }) => {
	const { setContexto } = React.useContext(GaragensUnidadeContext);

	const handleClick = () => {
		setContexto({ openModalCreateGaragem: true });
	};

	return (
		<TopToolbar>
			<Button
				label='CADASTRAR GARAGEM'
				id='criar_nova_garagem'
				disabled={props.record.situacao !== 'A'}
				onClick={handleClick}
			>
				<AddIcon />
			</Button>

			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
		</TopToolbar>
	);
};

export default ActionsList;
