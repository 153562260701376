import React, { useEffect, useState, useRef, createContext, useMemo, useCallback } from 'react';

import { useListContext, Datagrid, DatagridBody, useTranslate, useExpanded, ExpandRowButton } from 'react-admin';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

const computeNbColumns = (expand, children, hasBulkActions) =>
	expand
		? 1 + // apresenta o botão de expandir
		  (hasBulkActions ? 1 : 0) + // coluna do checkbox
		  React.Children.toArray(children).filter((child) => !!child).length // children não é null
		: 0; // não precisamos computar colunas se não houver um painel para expandir

const listaDeEstilizacoesParaColunas = [
	{
		nomeProp: 'minWidth',
		nomeEstilizacao: 'min-width',
	},
	{
		nomeProp: 'maxWidth',
		nomeEstilizacao: 'max-width',
	},
];

const geraraEstiloParaColuna = (props) => {
	if (!props || typeof props !== 'object') return {};
	const objectStyle = listaDeEstilizacoesParaColunas.reduce((objectStyle, { nomeProp, nomeEstilizacao }) => {
		const valorProp = props?.[nomeProp];
		if (valorProp) objectStyle[nomeEstilizacao] = valorProp;
		return objectStyle;
	}, {});

	return objectStyle;
};

const LinhaFiltro = ({ id, children, hasBulkActions, hasExpandCell }) => {
	const [temFiltro, setTemFiltro] = useState(false);

	useEffect(() => {
		for (let field of children) {
			if (field.props.filtro) {
				setTemFiltro(true);
				return;
			}
		}
	}, [children]);
	return (
		<TableRow key={`${id}-filter`} style={{ display: temFiltro ? 'table-row' : 'none' }}>
			{hasExpandCell && <TableCell key={`${id}-expand-filter`} />}
			{hasBulkActions && <TableCell key={`${id}-checkbox-filter`} />}
			{React.Children.map(children, (field) => (
				<TableCell
					key={`${id}-${field.props.source}-filter`}
					style={geraraEstiloParaColuna(field.props)}
					align={field?.props?.align ? field.props.align : undefined}
				>
					{field.props.filtro &&
						React.cloneElement(field.props.filtro, {
							source: field.props.source,
						})}
				</TableCell>
			))}
		</TableRow>
	);
};

const CelulaRegistro = ({
	id,
	field,
	history,
	i,
	disableClickRow,
	clickRowCustom,
	record,
	basePath,
	resource,
	minWidth,
	styleProps = {},
	hasBulkActions,
	selectable,
	checkboxRef,
}) => (
	<TableCell
		key={`${id}-${field.props.source}-${i}`}
		onClick={
			disableClickRow
				? () => {
						if (selectable && hasBulkActions && checkboxRef.current) checkboxRef.current.click();
				  }
				: clickRowCustom
				? clickRowCustom({ record, resource, id, basePath, history })
				: () => history.push(`${resource}/${id}`)
		}
		style={geraraEstiloParaColuna(styleProps)}
		align={field?.props?.align ? field.props.align : undefined}
	>
		{React.cloneElement(field, {
			record,
			basePath,
			resource,
		})}
	</TableCell>
);

const LinhaExpandida = ({ resource, record, id, basePath, expandable, expand, nbColumns }) => {
	const [expanded] = useExpanded(resource, id);

	if (!(expandable && expanded)) return null;

	return (
		<TableRow key={`${id}-expand`} id={`${id}-expand`}>
			<TableCell colSpan={nbColumns}>
				{React.isValidElement(expand)
					? React.cloneElement(expand, {
							record,
							basePath,
							resource,
							id: String(id),
					  })
					: React.createElement(expand, {
							record,
							basePath,
							resource,
							id: String(id),
					  })}
			</TableCell>
		</TableRow>
	);
};

const LinhaRegistro = ({
	record,
	resource,
	id,
	children,
	basePath,
	history,
	disableClickRow,
	clickRowCustom,
	hasBulkActions,
	classes,
	selected,
	selectable,
	onToggleItem,
	expand,
	expandable,
}) => {
	const [expanded, toggleExpanded] = useExpanded(resource, id);

	const handleToggleExpand = useCallback(
		(event) => {
			toggleExpanded();
			event.stopPropagation();
		},
		[toggleExpanded]
	);

	const handleToggleSelection = useCallback(
		(event) => {
			if (!selectable) return;
			onToggleItem(id, event);
			event.stopPropagation();
		},
		[id, onToggleItem, selectable]
	);
	const checkboxRef = useRef();

	return (
		<TableRow key={id} hover={true} style={{ cursor: 'pointer' }}>
			{expand && (
				<TableCell padding='none' className={classes.expandIconCell}>
					{expandable && (
						<ExpandRowButton
							classes={classes}
							expanded={expanded}
							onClick={handleToggleExpand}
							expandContentId={`${id}-expand`}
						/>
					)}
				</TableCell>
			)}
			{hasBulkActions && (
				<TableCell padding='checkbox'>
					{selectable && (
						<Checkbox
							color='primary'
							className={`select-item ${classes.checkbox}`}
							ref={checkboxRef}
							checked={selected}
							onClick={handleToggleSelection}
						/>
					)}
				</TableCell>
			)}
			{React.Children.map(children, (field, i) => (
				<CelulaRegistro
					{...{
						id,
						field,
						history,
						i,
						disableClickRow,
						clickRowCustom,
						record,
						basePath,
						resource,
						minWidth: field.props?.minWidth,
						styleProps: field.props,
						hasBulkActions,
						selectable: field.props?.selectable !== undefined ? field.props.selectable : selectable,
						checkboxRef,
					}}
				/>
			))}
		</TableRow>
	);
};

const LinhaFooter = ({ id, children, resource, hasBulkActions, hasExpandCell }) => {
	const translate = useTranslate();
	return (
		<TableRow key={`${id}-footer`} style={{ position: 'sticky', bottom: '-60px' }}>
			{hasExpandCell && (
				<TableCell
					style={{
						fontWeight: 'bold',
						position: 'sticky',
						bottom: 0,
						background: '#fff',
					}}
				/>
			)}
			{hasBulkActions && (
				<TableCell
					style={{
						fontWeight: 'bold',
						position: 'sticky',
						bottom: 0,
						background: '#fff',
					}}
				/>
			)}
			{React.Children.map(children, (field) => (
				<TableCell
					style={{
						fontWeight: 'bold',
						position: 'sticky',
						bottom: 0,
						background: '#fff',
						...geraraEstiloParaColuna(field.props),
					}}
					align={field?.props?.align ? field.props.align : undefined}
				>
					{field.props.label || translate(`resources.${resource}.fields.${field.props.source}`)}
				</TableCell>
			))}
		</TableRow>
	);
};

export const TabelaRowContext = createContext();

const TabelaRowContextProvider = ({
	record,
	resource,
	id,
	children,
	basePath,
	disableClickRow,
	clickRowCustom,
	hasBulkActions,
	classes,
	selected,
	selectable,
	onToggleItem,
	updateRowRecord,
	listControllerProps,
	expand,
	isRowExpandable,
}) => {
	const { ids } = useListContext(listControllerProps);
	const history = useHistory();
	const [rowRecord, setRowRecord] = useState(record);

	const expandable = (!isRowExpandable || isRowExpandable(record)) && !!expand;

	const nbColumns = React.useMemo(() => {
		// Campos podem ser escondidos dinâmicamente baseado em permissões;
		// O painel expandivel deve cobrir as colunas remanecentes
		// Então nós devemos recomputar o número de colunas a serem cobertas
		const newNbColumns = computeNbColumns(expandable, children, hasBulkActions);
		return newNbColumns;
	}, [expandable, children, hasBulkActions]);

	const TabelaRowProviderValue = useMemo(() => ({ rowRecord, setRowRecord }), [rowRecord, setRowRecord]);

	const updateRecord = useCallback(() => {
		if (updateRowRecord) setRowRecord(record);
	}, [updateRowRecord, setRowRecord, record]);
	useEffect(updateRecord, [record]);

	return (
		<TabelaRowContext.Provider value={TabelaRowProviderValue}>
			{(ids[0] === id || !ids) && <LinhaFiltro {...{ id, children, hasBulkActions, hasExpandCell: !!expand }} />}

			{ids[0] !== 0 && (
				<>
					<LinhaRegistro
						{...{
							record: rowRecord,
							resource,
							id,
							children,
							basePath,
							history,
							disableClickRow,
							clickRowCustom,
							hasBulkActions,
							classes,
							selected,
							selectable,
							onToggleItem,
							expand,
							expandable,
							nbColumns,
						}}
					/>
					<LinhaExpandida {...{ resource, record, id, basePath, expandable, expand, nbColumns }} />
					{ids[ids.length - 1] === id && (
						<LinhaFooter {...{ id, children, resource, hasBulkActions, hasExpandCell: !!expand }} />
					)}
				</>
			)}
		</TabelaRowContext.Provider>
	);
};

const TabelaRow = ({
	record,
	resource,
	id,
	children,
	basePath,
	disableClickRow,
	clickRowCustom,
	hasBulkActions,
	classes,
	selected,
	selectable,
	onToggleItem,
	updateRowRecord,
	listControllerProps,
	expand,
}) => {
	return (
		<TabelaRowContextProvider
			{...{
				record,
				resource,
				id,
				children,
				basePath,
				disableClickRow,
				clickRowCustom,
				hasBulkActions,
				classes,
				selected,
				selectable,
				onToggleItem,
				updateRowRecord,
				listControllerProps,
				expand,
			}}
		/>
	);
};

const useStyles = makeStyles({
	headerCell: {
		fontWeight: 'bold',
		flexDirection: 'row',
		textAlign: 'left',
	},
});

const TabelaBody = ({ disableClickRow, clickRowCustom, updateRowRecord, listControllerProps, ...props }) => (
	<DatagridBody
		{...props}
		row={
			<TabelaRow
				{...{
					listControllerProps,
					disableClickRow,
					clickRowCustom,
					hasBulkActions: props.hasBulkAction,
					updateRowRecord,
				}}
			/>
		}
	/>
);

export const Tabela = ({
	disableClickRow = false,
	clickRowCustom = undefined,
	updateRowRecord = undefined,
	...props
}) => {
	const classes = useStyles();
	return (
		<Datagrid
			{...props}
			body={
				<TabelaBody
					listControllerProps={props}
					disableClickRow={disableClickRow}
					clickRowCustom={clickRowCustom}
					updateRowRecord={updateRowRecord}
				/>
			}
			classes={{ headerCell: classes.headerCell }}
		/>
	);
};
