import React, { useEffect, useReducer, useState } from 'react';

import { Box } from '@material-ui/core';
import { useRequestOptionsContas } from 'components/common/Selects/hooks';

import { IAction, IConta, IRelacaoTipoConta, IRelatorioProps, IState } from './types';
import MultiSelect from 'components/common/Selects';
import { MonthPicker } from '../MonthPicker';
import RelatorioToolbar from '../RelatorioToolbar';

const traducao_grupo_campo_conta: {
	[key: string]: string;
} = {
	Receita: 'contas_rec',
	Despesa: 'contas_desp',
};
const functionReducer = (state: IState, action: IAction) => {
	switch (action.state) {
		case 'desmarcarGrupo':
			// eslint-disable-next-line no-case-declarations
			const campo_conta_para_desmarcar = traducao_grupo_campo_conta[action.value.group];
			state.contas = action.value.contas.filter((v: IRelacaoTipoConta) => v.tipoConta !== action.value.group);
			state[campo_conta_para_desmarcar] = [];

			break;
		case 'marcarGrupo':
			// eslint-disable-next-line no-case-declarations
			const campo_conta_para_marcar = traducao_grupo_campo_conta[action.value.group];
			state.contas = [...state.contas, ...action.value._group];
			state[campo_conta_para_marcar] = action.value._group.map((c: IConta) => c.id);
			break;
		case 'id_condominio':
			state.id_condominio = action.value;
			break;
		case 'date_range':
			state.data_inicial = action.value.data_inicial;
			state.data_final = action.value.data_final;
			break;
		case 'marcarContasIndividualmente':
			state.contas = action.value;
			// eslint-disable-next-line no-case-declarations
			const { contas_rec, contas_desp } = action.value.reduce(
				(
					listas_contas: {
						[key: string]: [number];
					},
					{
						tipoConta,
						id,
					}: {
						tipoConta: string;
						id: number;
					}
				) => {
					const campo_conta = traducao_grupo_campo_conta[tipoConta];
					const lista_conta = listas_contas[campo_conta];

					if (lista_conta) lista_conta.push(id);

					return listas_contas;
				},
				{ contas_rec: [], contas_desp: [] }
			);
			state.contas_rec = contas_rec;
			state.contas_desp = contas_desp;
			break;
		case 'zerarContasSelecionadas':
			state.contas = action.value;
			state.contas_rec = action.value;
			state.contas_desp = action.value;
			break;
		default:
			break;
	}
	return { ...state };
};

export default function PrevisoesOrcamentariasDivisor({ idCondominio, tipoRelatorio }: IRelatorioProps) {
	const { options: optionsContas } = useRequestOptionsContas(idCondominio);
	const [referenciaInicial, setReferenciaInicial] = useState<string>(new Date().toLocaleDateString('pt-BR').slice(3));
	const [referenciaFinal, setReferenciaFinal] = useState<string>(new Date().toLocaleDateString('pt-BR').slice(3));
	const [requestData, dispatch] = useReducer(functionReducer, {
		id_condominio: idCondominio,
		contas: [],
		contas_rec: [],
		contas_desp: [],
		data_inicial: referenciaInicial,
		data_final: referenciaFinal,
	});

	useEffect(() => {
		dispatch({
			state: 'id_condominio',
			value: idCondominio,
		});
		if (requestData.contas.length) {
			dispatch({
				state: 'zerarContasSelecionadas',
				value: [],
			});
		}
	}, [idCondominio]);

	const handleSetContas = (contas: IConta[], state = '', group = '', _group = []) => {
		if (state) {
			dispatch({
				state: state,
				value: {
					contas: contas,
					group: group,
					_group: _group,
				},
			});
		} else {
			dispatch({
				state: 'marcarContasIndividualmente',
				value: contas,
			});
		}
	};

	return (
		<Box>
			<Box display='flex' gridGap={'1em'}>
				<MonthPicker
					label='Início'
					handleChange={(newValue: any) => {
						if (referenciaFinal <= newValue) return;
						if (newValue)
							if (newValue.toString() !== 'Invalid Date') {
								newValue = newValue.toLocaleDateString('pt-BR').slice(3);
								dispatch({
									state: 'date_range',
									value: {
										data_inicial: newValue,
										data_final: referenciaFinal,
									},
								});
							}
						setReferenciaInicial(newValue);
					}}
					value={
						referenciaInicial && referenciaInicial.toString() !== 'Invalid Date' ? referenciaInicial : ''
					}
				></MonthPicker>
				<MonthPicker
					label='Término'
					handleChange={(newValue: any) => {
						if (referenciaInicial >= newValue) return;
						if (newValue)
							if (newValue.toString() !== 'Invalid Date') {
								newValue = newValue.toLocaleDateString('pt-BR').slice(3);
								dispatch({
									state: 'date_range',
									value: {
										data_inicial: referenciaInicial,
										data_final: newValue,
									},
								});
							}

						setReferenciaFinal(newValue);
					}}
					value={referenciaFinal && referenciaFinal.toString() !== 'Invalid Date' ? referenciaFinal : ''}
				></MonthPicker>
			</Box>
			<Box>
				<MultiSelect
					variant={'formatsContas'}
					options={optionsContas}
					setValue={handleSetContas}
					value={requestData?.contas}
				/>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</Box>
	);
}
