import React from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';

type Props = {
	id: number;
};

type GerarRelatorioParams = {
	id: number;
	tipo_saida: string;
	tipoRelatorio: string;
};

type CustomDataProvider = {
	gerarRelatorio: (resource: string, params: GerarRelatorioParams) => Promise<void>;
} & ReturnType<typeof useDataProvider>;

const BotaoGerarRelatiorio = ({ id }: Props) => {
	const notify = useNotify();
	const dp = useDataProvider() as CustomDataProvider;

	const geraRelatorio = (): void => {
		dp.gerarRelatorio('relatorios', {
			id: id,
			tipo_saida: 'visualizar',
			tipoRelatorio: 'plano_de_contas',
		})
			.then(() => notify('Relatório gerado com sucesso!'))
			.catch((e) => {
				if (e?.response?.data instanceof Blob) {
					e.response.data.text().then((text: string) => {
						const data_obj = JSON.parse(text);
						notify(
							`${Object.values(data_obj || {})[0]}` || 'Erro inesperado, tente recarregar a página',
							'warning'
						);
					});
				} else {
					notify(
						`${Object.values(e?.response?.data || {})[0]}` || 'Erro inesperado, tente recarregar a página',
						'warning'
					);
				}
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			});
	};

	return (
		<Button size='small' color='primary' onClick={geraRelatorio}>
			gerar relatório
		</Button>
	);
};

export default BotaoGerarRelatiorio;
