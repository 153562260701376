import React from 'react';
import Button from '@material-ui/core/Button';
import { TooltipIconButton } from '../TooltipIconButton';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

const defaultIcon = <CheckIcon />;

export const ButtonConfirm = React.forwardRef(function ButtonConfirm(
	{ startIcon = defaultIcon, children, loading = false, ...rest },
	ref
) {
	return (
		<Button
			{...rest}
			color='primary'
			startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
			ref={ref}
			disabled={rest.disabled || loading}
		>
			{children || 'Salvar'}
		</Button>
	);
});

export const TooltipIconButtonConfirm = ({ title, children, ...rest }) => {
	return (
		<TooltipIconButton {...rest} title={title || 'Salvar'} color='primary'>
			{children || <CheckIcon />}
		</TooltipIconButton>
	);
};
