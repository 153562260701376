import React, { useState, useContext } from 'react';

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';

import SyndkosTabPanel from '../../../common/TabPanel/SyndkosTabPanel';
import ContasViewSimplificado from '../ContasViewSimplificado';
import FluxoImportacaoPlanoContext from './FluxoImportacaoPlanoContext';

const a11yProps = (index) => ({
	id: `simple-tab-${index}`,
	'aria-controls': `simple-tabpanel-${index}`,
});

const TabContas = () => {
	const {
		id,
		dadosContasReceitasDespesas: {
			despesas: { contasRaizes: contasRaizesDespesas },
			receitas: { contasRaizes: contasRaizesReceitas },
		},
	} = useContext(FluxoImportacaoPlanoContext);
	const [aba, setAba] = useState('0');

	return (
		<TabContext value={aba}>
			<Box width={1}>
				<Box display='flex'>
					<TabList value={aba} onChange={(_, newValue) => setAba(newValue)} aria-label=''>
						<Tab {...a11yProps(0)} value='0' label='Despesas' />
						<Tab {...a11yProps(1)} value='1' label='Receitas' />
					</TabList>
				</Box>
				<SyndkosTabPanel value={aba} index='0'>
					<Box>
						<ContasViewSimplificado id={id} tipo='despesas' contasRaizes={contasRaizesDespesas} />
					</Box>
				</SyndkosTabPanel>
				<SyndkosTabPanel value={aba} index='1'>
					<Box>
						<ContasViewSimplificado id={id} tipo='receitas' contasRaizes={contasRaizesReceitas} />
					</Box>
				</SyndkosTabPanel>
			</Box>
		</TabContext>
	);
};

export default TabContas;
