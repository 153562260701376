import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { format, parseISO } from 'date-fns';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import {
	makeStyles,
	Box,
	Divider,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	CircularProgress,
} from '@material-ui/core';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { SelectHorarios } from '../inputs/SelectHorarios';
import { LoadingButton } from '../buttons/LoadingButton';

import { groupedHoursToObject } from './CardReservasEBloqueios/utils';

import { CustomDataProvider } from 'types/tpyesGlobal';
import { ThemePaletteProps, IConsultaState } from './types';

const useStyles = makeStyles<ThemePaletteProps>((theme) => ({
	cancelarButton: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.main,
			color: '#fff',
		},
	},
	editarButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
	},
	styledInfo: {
		'align-self': 'baseline',
		display: 'flex',
		'flex-direction': 'column',
		gap: '5px',
		'& > div > span': {
			display: 'flex',
			gap: '10px',
			'align-items': 'center',
		},
	},
}));

const DIAS_DA_SEMANA: { [key: string]: string } = {
	SEG: 'Segunda-feira',
	TER: 'Terça-feira',
	QUA: 'Quarta-feira',
	QUI: 'Quinta-feira',
	SEX: 'Sexta-feira',
	SAB: 'Sábado',
	DOM: 'Domingo',
};

const TIPO_RESERVA: { [key: string]: string } = {
	HH: 'Hora em hora',
	MP: 'Meio período',
	DI: 'Dia inteiro',
};

export const ModalEdicaoBloqueio = () => {
	const {
		consulta: {
			bloqueiosNoDia,
			dadosCardBloqueio,
			showModalEdicaoBloqueio,
			condominioSelecionado,
			ambienteSelecionado,
			diaSelecionado,
		},
		actionCallBackReloadReserva,
		setConsulta,
	} = React.useContext<any>(ConsultaDeReservasContext);

	const [configEdicaoBloqueio, setConfigEdicaoBloqueio] = React.useState<any>();
	const [bloqueioSelecionado, setBloqueioSelecionado] = React.useState<any>();
	const [selectedHorarios, setSelectedHorarios] = React.useState([]);
	const [loadingEditar, setLoadingEditar] = React.useState(false);
	const [loadingInfos, setLoadingInfos] = React.useState(false);
	const [text, setText] = React.useState(bloqueioSelecionado?.motivo_bloqueio || '');

	const dp = useDataProvider() as CustomDataProvider;
	const classes = useStyles();
	const notify = useNotify();

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newText = e.target.value;

		if (newText.length <= 30) {
			setText(newText);
		}
	};

	React.useEffect(() => {
		setText(bloqueioSelecionado?.motivo_bloqueio || '');
	}, [bloqueioSelecionado]);

	const diaBloqueado = bloqueioSelecionado && format(parseISO(bloqueioSelecionado?.dia_bloqueado), 'dd/MM/yyyy');

	const onSubmit = () => {
		setLoadingEditar(true);
		const bloqueiosIntervalosTempo = groupedHoursToObject(selectedHorarios);

		dp.update('bloqueio_reservas_syndkos', {
			id: configEdicaoBloqueio.id,
			data: {
				bloqueios_intervalos_tempo: bloqueiosIntervalosTempo,
				motivo_bloqueio: text,
				dia_bloqueado: format(diaSelecionado, 'yyyy-MM-dd'),
				ambiente: ambienteSelecionado?.id,
			},
		})
			.then(() => {
				notify('Bloqueio editado com sucesso!', { type: 'success' });
				setConfigEdicaoBloqueio(undefined);
				actionCallBackReloadReserva();
			})
			.catch((error) => {
				if ([401, 403].includes(error?.response?.status)) return Promise.reject(error);
				const errorMessage = error?.response?.data || null;

				if (!errorMessage) {
					return notify('Falha ao editar bloqueio', 'warning');
				}

				Object.keys(errorMessage).forEach((keyName) => {
					notify(errorMessage[keyName][0], 'warning');
				});
			})
			.finally(() => setLoadingEditar(false));
	};

	function fetchDataCard() {
		if (!dadosCardBloqueio?.id) return;
		setLoadingInfos(true);
		Promise.allSettled([
			dp.getOne('bloqueio_reservas_syndkos', { id: dadosCardBloqueio.id }),
			dp.getSimple('reserva_agendada_syndkos', {
				filter: {
					dia_reservado: format(diaSelecionado, 'yyyy-MM-dd'),
					condominio_id: condominioSelecionado?.id,
					ambiente_id: ambienteSelecionado?.id,
				},
			}),
		])
			.then(([responseBloqueio, responseReservas]: any) => {
				const bloqueioCard = responseBloqueio.value?.data ?? {};
				bloqueioCard.outras_reservas = [];
				if (responseReservas.value?.data?.results.length > 0) {
					const reservas = responseReservas.value?.data?.results.reduce((intervalos: any, reservas: any) => {
						if (reservas.reservas_intervalos_tempo && reservas.reservas_intervalos_tempo.length > 0) {
							intervalos = intervalos.concat(reservas.reservas_intervalos_tempo);
						}
						return intervalos;
					}, []);
					bloqueioCard.outras_reservas = [...reservas] ?? [];
				}
				bloqueioCard.outras_reservas = [...bloqueioCard.outros_bloqueios, ...bloqueioCard.outras_reservas];
				bloqueioCard.reservas_intervalos_tempo = bloqueioCard.bloqueios_intervalos_tempo;
				setConfigEdicaoBloqueio(bloqueioCard);
				if (bloqueiosNoDia) {
					const [bloqueioFiltered] = bloqueiosNoDia.filter(
						(bloqueio: any) => bloqueioCard.id === bloqueio.id
					);
					setBloqueioSelecionado(bloqueioFiltered);
				}
				setLoadingInfos(false);
			})
			.catch(() => {
				notify('Falha ao coletar informação de bloqueio.', 'warning');
				setLoadingInfos(false);
			});
	}

	React.useEffect(fetchDataCard, [dadosCardBloqueio]);

	return (
		<Dialog
			open={showModalEdicaoBloqueio}
			onClose={() =>
				setConsulta((prevState: IConsultaState) => ({
					...prevState,
					showModalEdicaoBloqueio: false,
				}))
			}
			fullWidth
			PaperProps={{
				style: {
					minHeight: '27rem',
					minWidth: '61vw',
					position: 'relative',
				},
			}}
		>
			<DialogTitle>Edição de Bloqueio</DialogTitle>
			{loadingInfos ? (
				<DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress size={40} />
				</DialogContent>
			) : (
				<DialogContent>
					<Box className={classes.styledInfo}>
						<div>
							<span role='img' aria-label='Data do bloqueio'>
								<CalendarTodayIcon /> Data do bloqueio:
								<strong>
									{diaBloqueado},{' '}
									{bloqueioSelecionado?.dia_semana && DIAS_DA_SEMANA[bloqueioSelecionado.dia_semana]}
								</strong>
							</span>
						</div>

						<div>
							<span role='img' aria-label='Tipo do bloqueio'>
								<LocalOfferIcon /> Tipo do bloqueio:
								<strong>
									{bloqueioSelecionado?.fracao && TIPO_RESERVA[bloqueioSelecionado.fracao]}
								</strong>
							</span>
						</div>
					</Box>
					<Divider style={{ margin: '10px 10px' }} />
					<Box>
						{configEdicaoBloqueio && (
							<SelectHorarios
								configEdicaoReserva={configEdicaoBloqueio}
								setSelectedHorarios={setSelectedHorarios}
								selectedHorarios={selectedHorarios}
								configCriarReserva={undefined}
							/>
						)}
					</Box>
					<Divider style={{ margin: '10px 10px' }} />
				</DialogContent>
			)}
			<DialogTitle>Motivo do Bloqueio</DialogTitle>
			{loadingInfos ? (
				<DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress size={40} />
				</DialogContent>
			) : (
				<DialogContent>
					<TextField
						fullWidth
						size='small'
						variant='outlined'
						placeholder='Escreva o motivo do bloqueio...'
						value={text || ''}
						onChange={handleTextChange}
					></TextField>
					<Box margin={1}>{text.length}/30 caracteres</Box>
				</DialogContent>
			)}
			<DialogActions>
				<Button
					onClick={() =>
						setConsulta((prevState: IConsultaState) => ({
							...prevState,
							showModalEdicaoBloqueio: false,
						}))
					}
					className={classes.cancelarButton}
					variant='contained'
				>
					Cancelar
				</Button>
				<LoadingButton
					onClick={onSubmit}
					className={classes.editarButton}
					disabled={!selectedHorarios.length}
					size='medium'
					loading={loadingEditar}
				>
					Editar bloqueio
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
