import React from 'react';

import { FormEditContextProvider } from '../contexts';
import Form from './Form';

const ConfigCorrecaoMonetariaEdit: React.FC = () => {
	return (
		<FormEditContextProvider>
			<Form />
		</FormEditContextProvider>
	);
};

export default ConfigCorrecaoMonetariaEdit;
