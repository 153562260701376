import React from 'react';

import { FormControl, Checkbox, FormLabel, FormGroup, FormControlLabel } from '@material-ui/core';

import { OpcoesJurosCheckboxGroupProps } from '../../types';

export const OpcoesJurosCheckboxGroup: React.FC<OpcoesJurosCheckboxGroupProps> = ({ feesOptions, setFeesOptions }) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.persist();
		setFeesOptions((prevState) => ({
			...prevState,
			option_monthly_fees: false,
			option_daily_fees: false,
			[e.target.name]: e.target.checked,
		}));
	};
	const { option_monthly_fees, option_daily_fees } = feesOptions;
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Juros</FormLabel>
			<FormGroup row>
				<FormControlLabel
					control={
						<Checkbox checked={option_monthly_fees} onChange={handleChange} name='option_monthly_fees' />
					}
					label='Juros Mensal'
				/>
				<FormControlLabel
					control={<Checkbox checked={option_daily_fees} onChange={handleChange} name='option_daily_fees' />}
					label='Juros Diário'
				/>
			</FormGroup>
		</FormControl>
	);
};

export default OpcoesJurosCheckboxGroup;
