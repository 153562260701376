import {
	validatesFieldsRequeridosQuandoEmitindoBoletos,
	validateTipoIntegracao,
	validateCarteira,
	validateEspecieTitulo,
	validateMoeda,
	validateEmissaoBoleto,
	validateDistribuicaoBoleto,
	validateNossoNumero,
	validateSequencialArquivo,
	validateLocalPagamento,
	createValidateLinhas,
	validacoesFieldsTabInformacao,
	validateAgencia,
} from '../../utils/validadoes';
import {
	getValidacoesMulta,
	mensagensErrosPadroes,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidateQuandoEmitindoBoletos,
} from '../default/ContaCorrenteProps';
import { getValidacoesInstrucao } from '../default/ContaCorrenteProps/getValidate';

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	maxValue: undefined,
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	maxValue: undefined,
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesInstrucoes = getValidacoesInstrucao({ tipo_field: 'instrucao_1' } as any);

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesInstrucoes.tipo,
	validacoesInstrucoes.dias,
];

const listaValidacoesPadroesQuandoEmitindoBoletos = [
	...validatesFieldsRequeridosQuandoEmitindoBoletos,
	validateTipoIntegracao,
	validateCarteira,
	validateEspecieTitulo,
	validateMoeda,
	validateEmissaoBoleto,
	validateDistribuicaoBoleto,
	validateNossoNumero,
	validateSequencialArquivo,
	validateLocalPagamento,
	createValidateLinhas({ numeroLinhas: 5, maxValue: 80 }),
];

const validateQuandoEmitindoBoletos = getValidateQuandoEmitindoBoletos([
	...validacoesInstrucoesCobrancaRemessa,
	...listaValidacoesPadroesQuandoEmitindoBoletos,
]);

const validacoes = [...validacoesFieldsTabInformacao, validateAgencia, validateQuandoEmitindoBoletos];

export default validacoes;
