import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { SyndikosMuiPickersUtilsProvider } from 'components/common/InputDate';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export const MonthPicker: React.FC<{
	handleChange: (date: MaterialUiPickersDate, newValue: string | null | undefined) => void;
	value: string | undefined;
	label: string;
}> = ({ handleChange, value, label }) => {
	return (
		<SyndikosMuiPickersUtilsProvider>
			<KeyboardDatePicker
				margin='normal'
				fullWidth
				autoOk={true}
				views={['year', 'month']}
				format='MM/yyyy'
				label={label}
				mask='__/____'
				size='small'
				variant='inline'
				inputVariant='outlined'
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				invalidDateMessage='Formato de data inválido'
				maxDateMessage='A data não deve ser maior que a data máxima'
				minDateMessage='A data não deve ser menor que a data mínima'
				onChange={handleChange}
				value={value}
				inputValue={value}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};
