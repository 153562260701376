import React from 'react';

import memoize from 'components/common/memoize';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
	LimitePagamentoFields,
	ProtestoFields,
	getPropsOnChangeTipoPadraoProtesto,
	createOnChangeTipoPadraoProtesto,
	getPropsOnChangeTipoPadraoLimitePagamento,
	createOnChangeTipoPadraoLimitePagamento,
} from '../../../tabs/TabInstrucoesCobrancaRemessa';

import {
	formataPropsMaxValuePorLimitePagamentoDias,
	formatCedentePattern1,
	formatCedentePattern2,
	getDadosFormattingContaCorrente,
	parseCedentePattern1,
	parseCedentePattern2,
} from '../../../utils/formaters_parses';
import { getFormatarOptions } from '../../../utils/functions';

import DefaultContaCorrenteProps, {
	defaultComponentesProps,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesProtesto,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	getValidateQuandoEmitindoBoletos,
	getValidacoesLimitePagamento,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	mensagensErrosPadroes,
} from '../../default/ContaCorrenteProps';

import getFormataPayloadValues from './getFormataPayloadValues';
import formatadoresPayloadValues from './formatadoresPayloadValues';
import { formatarInitialValuesAutomaticoOuManual as formatarInitialValues } from './formatarInitialValues';
import validacoes from './validacoes';
import getValidate from './getValidate';

import {
	createOnChangeTipoPadraoMulta,
	getPropsOnChangeTipoPadraoMulta,
} from './componentes/TabInstrucoesCobrancaRemessa/MultaFields';
import {
	createOnChangeTipoPadraoJuros,
	getPropsOnChangeTipoPadraoJuros,
} from './componentes/TabInstrucoesCobrancaRemessa/JurosFields';
import {
	createOnChangeTipoPadraoDesconto,
	getPropsOnChangeTipoPadraoDesconto,
} from './componentes/TabInstrucoesCobrancaRemessa/DescontoFields';

import ModalCadastrarDadosIntegracaoAPI from './componentes/TabConta/ModalCadastrarDadosIntegracaoAPI';
import TabInformacao from '../../../tabs/TabInformacao';
import TabEmissaoBoletos from '../../../tabs/TabEmissaoBoletos';
import TabConta from './componentes/TabConta';
import TabInstrucoesCobrancaRemessaAutomatico from './componentes/TabInstrucoesCobrancaRemessa';
import TabInstrucoesCobrancaBoleto from './componentes/TabInstrucoesCobrancaBoleto';

import { ValuesProps, InicialDadosProps } from '../../../types';

const getExtras = ({
	props,
	instituicaoCodigo,
	initialDados: {
		opcoesDadosBancarios: { integracoes },
	},
}: any) => ({
	optionsIsencoes: integracoes[instituicaoCodigo].optionsIsencoes,
	...props,
});

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	nomeCampoLegivel: 'limite de pagamento',
	msgErroVazio: mensagensErrosPadroes.vazioLimitePagamento(),
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamentoBaixaDevolver(),
});

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	msgErroVazio: mensagensErrosPadroes.vazioProtesto(),
	minValue: 0,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto(),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesLimitePagamento.tipo,
	validacoesLimitePagamento.dias,

	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }: ValuesProps) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesLimitePagamento({ values }),
	...formataPayloadValuesProtesto({ values }),
});

const TabInstrucoesCobrancaRemessa = (props: ValuesProps) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<LimitePagamentoFields />, <ProtestoFields />]}
	/>
);

const getDisabledTabs = memoize(({ values: { emitir_boletos } }: ValuesProps) => {
	return {
		disabled0: false,
		disabled1: false,
		disabled2: !emitir_boletos,
		disabled3: !emitir_boletos,
		disabled4: !emitir_boletos,
	};
});

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '756',
	getOptions: getFormatarOptions('756', { agencia: 'cooperativas_sicoob' }),
	getDisabledTabs,
	getValidate,
	tabContents: {
		0: {
			TabInformacao,
			TabEmissaoBoletos,
			TabConta,
			TabInstrucoesCobrancaRemessa: TabInstrucoesCobrancaRemessaAutomatico,
			TabInstrucoesCobrancaBoleto,
		},
		1: { ...DefaultContaCorrenteProps.tabContents, TabInstrucoesCobrancaRemessa },
	},
	validacoes: {
		0: validacoes,
		1: [
			...DefaultContaCorrenteProps.validacoes,
			getValidateQuandoEmitindoBoletos(validacoesInstrucoesCobrancaRemessa),
		],
	},
	props: {
		0: {
			tipo_conta: {
				source: 'tipo_conta_bancaria',
				name: 'tipo_conta_bancaria',
				id: 'tipo_conta_bancaria',
				choices: [{ id: 'CC', text: 'Conta Corrente' }],
			},
			conta_corrente: {
				...getDadosFormattingContaCorrente(13),
			},
			conta_cedente: {
				parse: parseCedentePattern1(10),
				format: formatCedentePattern1(10),
			},
			multa: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoMulta,
				createOnChangeTipo: createOnChangeTipoPadraoMulta,
			},
			juros: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoJuros,
				createOnChangeTipo: createOnChangeTipoPadraoJuros,
			},
			desconto: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoDesconto,
				createOnChangeTipo: createOnChangeTipoPadraoDesconto,
			},
			limite_pagamento_dias: {
				min: 0,
				max: 60,
			},
			limite_pagamento: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoLimitePagamento,
				createOnChangeTipo: createOnChangeTipoPadraoLimitePagamento,
			},
			protesto: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoProtesto,
				createOnChangeTipo: createOnChangeTipoPadraoProtesto,
			},
			CadastrarDadosIntegracaoAPIButton: {
				ModalCadastrarDadosIntegracaoAPI,
				getInicialCallback: ({ errorsMsg, ...inicialDados }: InicialDadosProps) => ({
					...inicialDados,
					errorsMsg,
					initialValues: {
						client_id: '',
					},
					values: {
						client_id: '',
					},
					errors: {
						client_id: errorsMsg.client_id.required,
					},
				}),
			},
		},
		1: {
			...defaultComponentesProps,
			conta_corrente: {
				...getDadosFormattingContaCorrente(13),
			},
			conta_cedente: {
				parse: parseCedentePattern2(7, 1),
				format: formatCedentePattern2(7, 1),
			},
			limite_pagamento_tipo: { label: 'Data Limite Pagamento' },
		},
	},
	getFormataPayloadValues,
	formatarInitialValues,
	formatadoresPayloadValues: {
		0: { ...formatadoresPayloadValues },
		1: {
			...defaultFormatadoresPayloadValues,
			cobranca_config: getFormatadorPayloadCobrancaConfig({
				formatadores: [
					...defaultFormatadoresPayloadValuesCobrancaConfig,

					// Dados de Tab Conta
					formataPayloadValuesTabContaCobrancaConfig,

					// Dados de Tab Instruções de Cobrança Remessa
					formataPayloadValuesTabInstrucoesCobrancaRemessa,
				],
			}),
		},
	},
	getExtras,
};

export default ContaCorrenteProps;
