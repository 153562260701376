import React, { useCallback, useContext, useEffect, useState } from 'react';

import MultiSelect from '../../../../../common/Selects/index';

import { useGetList, useDataProvider } from 'react-admin';

import {
	Box,
	Typography,
	TextField,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	makeStyles,
	Table,
	TableContainer,
	TableRow,
	TableBody,
	TableCell,
	TableHead,
	Checkbox,
	TableSortLabel,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';

import ApartmentIcon from '@material-ui/icons/Apartment';
import HomeIcon from '@material-ui/icons/Home';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GroupIcon from '@material-ui/icons/Group';
import LowPriority from '@material-ui/icons/LowPriority';
import WhereToVote from '@material-ui/icons/WhereToVote';
import CreditCard from '@material-ui/icons/CreditCard';

import { ContasContextProvider } from '../../../../planosCondominio/contexts/ContasContext';
import { SelectContaPlano } from '../../../../planosCondominio/modais';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { CondominiosContext } from '../../../../../../context/CondominioContextProvider';
import { Link } from 'react-router-dom';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../../../common/CurrencyInput';
import { Autocomplete } from '@material-ui/lab';
import { SelectMultiContasParaCalculoWithActions } from '../../../../../common/SelectConta.js';
import { format, isBefore, parseISO } from 'date-fns';
import BoxButton from '../../../../../common/buttons/BoxButton';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';

export const BoxAddConta = ({ contaReceita, setContaReceita, tipo }) => {
	const { idPlano } = useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const filterValues = () => {
		switch (tipo) {
			case 'receita':
				return ['E', 'O'];
			case 'leitura':
				return ['L'];
			case 'fundo':
				return ['F'];
			case 'desconto':
				return ['E', 'O'];
			default:
				return [];
		}
	};
	return idPlano ? (
		<ContasContextProvider id={idPlano}>
			<Box display='grid'>
				<SelectContaPlano
					tipo='receita'
					selectedConta={contaReceita}
					setSelectedConta={setContaReceita}
					filter={{ key: 'tipo', values: filterValues() }}
				/>
			</Box>
		</ContasContextProvider>
	) : (
		<Typography>
			Nenhum plano de condomínio vinculado. Adicione um nas{' '}
			<Link to={`condominios/${condominioSelecionado?.id}`} target='_blank'>
				configurações do condomínio
			</Link>
			.
		</Typography>
	);
};

export const BoxAddLeitura = ({ contaReceita, setContaReceita }) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { referencia } = useContext(ArrecadacoesContext);
	const { data, ids } = useGetList(
		'contas_receita',
		{ page: 1, perPage: 1000 },
		{ field: 'nome' },
		{
			id_plano_condominio__condominios: condominioSelecionado?.id,
			tem_config_no_cond: 's',
			tem_arrecadacao: 's',
			id_condominio_id: condominioSelecionado?.id,
			referencia: referencia,
			deve_ter: '',
		}
	);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setOptions(ids.map((id) => data[id]));
	}, [data, ids]);

	return options.length > 0 ? (
		<Autocomplete
			id='select-add-leitura-arrecadacao'
			itemID='select-item-id-leitura-arrecadacao'
			disabled={!options.length}
			options={options}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Leitura' />}
			getOptionLabel={(option) => option && (option.nome || options.find((v) => v.id === option).nome)}
			noOptionsText='Nenhum resultado encontrado'
			getOptionSelected={(option, v) => v === option.id}
			value={contaReceita}
			onChange={(_, v) => setContaReceita(v)}
			clearText='Limpar'
		/>
	) : (
		<Typography>Não há leituras disponíveis</Typography>
	);
};

export const BoxValorDesc = ({ valor, setValor, desc, setDesc, percentual, arrecPara }) => {
	return (
		<Box display='grid' gridGap='15px'>
			{!['5', 5].includes(arrecPara) && (
				<TextField
					label={`Valor${percentual ? ' Percentual' : ''}`}
					margin='dense'
					fullWidth
					value={valor}
					onChange={(e) => setValor(e.target.value)}
					inputProps={sanitizeListNumberFormatBRLProps({
						prefix: !percentual ? 'R$ ' : '',
						suffix: percentual ? ' %' : '',
						allowNegative: false,
					})}
					InputProps={{
						inputComponent: NumberFormatBRL,
					}}
					id='valor-boleto'
				/>
			)}
			<TextField
				id='desc-boleto'
				label='Descrição do Boleto'
				value={desc}
				onChange={(e) => setDesc(e.target.value)}
				margin='dense'
			/>
		</Box>
	);
};

export const BoxPeriodicidade = ({ nextStep, setCurrentStep, setPeriodicidade }) => (
	<Box display='grid' gridGap='15px'>
		<Typography align='center' variant='h6'>
			Selecione o tipo de arrecadação
		</Typography>
		<Box display='flex' justifyContent='center' gridGap='15px'>
			<BoxButton
				id='tipo-arrecadacao-recorrente'
				text='Recorrente'
				icon={<LowPriority />}
				onClick={() => {
					setPeriodicidade('0');
					setCurrentStep(nextStep);
				}}
			/>
			<BoxButton
				id='tipo-arrecadacao-unica'
				text='Única'
				icon={<WhereToVote />}
				onClick={() => {
					setPeriodicidade('1');
					setCurrentStep(nextStep);
				}}
			/>
			<BoxButton
				id='tipo-arrecadacao-parcelada'
				text='Parcelada'
				icon={<CreditCard />}
				onClick={() => {
					setPeriodicidade('2');
					setCurrentStep('D');
				}}
			/>
		</Box>
	</Box>
);

export const BoxParcelas = ({ parcelas, setParcelas }) => {
	return (
		<Box display='grid' gridGap='15px'>
			<TextField
				id='quantidade-parcelas'
				label={'Quantidade de parcelas'}
				margin='dense'
				fullWidth
				value={parcelas}
				error={!parcelas || isNaN(parseInt(parcelas)) || parseInt(parcelas) < 2}
				helperText={
					!parcelas || isNaN(parseInt(parcelas)) || parseInt(parcelas) < 2
						? 'Um minímo de duas parcelas são requeridas'
						: false
				}
				onChange={(e) => {
					setParcelas(e.target.value);
				}}
				inputProps={sanitizeListNumberFormatBRLProps({
					decimalScale: 0,
					isNumericString: true,
					allowNegative: false,
					defaultValue: '2',
				})}
				InputProps={{
					inputComponent: NumberFormatBRL,
				}}
			/>
		</Box>
	);
};

export const BoxArrecPara = ({
	setArrecPara,
	setCurrentStep,
	resetFields,
	hideUnidade,
	hideListaUnidade,
	titulo = 'Ratear Receita entre',
}) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { ids: unidadesIds } = useGetList(
		'unidades',
		{ perPage: 2, page: 1 },
		{ order: 'ASC', field: 'unidade' },
		{ id_condominio: condominioSelecionado?.id, situacao: 'A' }
	);
	const { ids: gruposIds } = useGetList(
		'grupo_unidade_condominio',
		{ perPage: 1, page: 1 },
		{},
		{ id_condominio: condominioSelecionado?.id }
	);
	const { ids: tiposIds } = useGetList(
		'tipo_unidade',
		{ perPage: 2, page: 1 },
		{},
		{ unidades__id_condominio: condominioSelecionado?.id }
	);

	useEffect(resetFields, []);

	return (
		<Box display='grid' gridGap='15px'>
			<Typography align='center' variant='h6'>
				{titulo}
			</Typography>
			<Box display='flex' justifyContent='center' gridGap='15px'>
				<BoxButton
					id='add-arrecadacao-para-condominio'
					text='Condomínio'
					icon={<ApartmentIcon />}
					onClick={() => {
						setArrecPara(0);
						setCurrentStep((s) => s + 1);
					}}
				/>
				<BoxButton
					id='add-arrecadacao-para-unidade'
					text='Unidade'
					display={hideUnidade && 'none'}
					disabled={unidadesIds.length < 2}
					icon={<HomeIcon />}
					onClick={() => {
						setArrecPara(2);
						setCurrentStep((s) => s + 2);
					}}
				/>
				<BoxButton
					id='add-arrecadacao-para-lista'
					text='Lista'
					display={(hideUnidade || hideListaUnidade) && 'none'}
					disabled={unidadesIds.length < 2}
					icon={<FormatListBulletedIcon />}
					onClick={() => {
						setArrecPara(5);
						setCurrentStep('ListaUnidade');
					}}
				/>
				<BoxButton
					id='add-arrecadacao-para-tipos'
					text='Tipos'
					disabled={tiposIds.length < 2}
					icon={<GroupIcon />}
					onClick={() => {
						setArrecPara(4);
						setCurrentStep((s) => s + 3);
					}}
				/>
				<BoxButton
					id='add-arrecadacao-para-grupos'
					text='Grupos'
					disabled={gruposIds.length === 0}
					icon={<GroupIcon />}
					onClick={() => {
						setArrecPara(1);
						setCurrentStep((s) => s + 4);
					}}
				/>
			</Box>
		</Box>
	);
};

export const BoxDivisao = ({ divisao, setDivisao, unidades }) => (
	<FormControl color='primary'>
		<FormLabel>Selecione a forma de rateio</FormLabel>
		<RadioGroup id='forma-rateio-radio-group' value={divisao} onChange={(e) => setDivisao(e.target.value)}>
			<FormControlLabel
				id='forma-rateio-dividir-coeficiente'
				value='0'
				control={<Radio color='primary' />}
				label='Dividir conforme coeficiente de cada unidade'
				disabled={unidades?.length === 1}
			/>
			<FormControlLabel
				id='forma-rateio-dividir-unidades'
				value='1'
				control={<Radio color='primary' />}
				label='Dividir pelo número de unidades'
				disabled={unidades?.length === 1}
			/>
			<FormControlLabel
				id='forma-rateio-lancar-igual'
				value='2'
				control={<Radio color='primary' />}
				label='Lançar valor igual para todas as unidades'
			/>
		</RadioGroup>
	</FormControl>
);

export const BoxUnidade = ({ unidades, setUnidades }) => {
	const { options } = useRequestOptionsUnidades();
	return (
		<Box>
			<MultiSelect variant='formatsUnidades' value={unidades} options={options} setValue={setUnidades} />
		</Box>
	);
};

export const BoxTipos = ({ tipos, setTipos }) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { data, ids } = useGetList(
		'tipo_unidade',
		{ page: 1, perPage: 10000 },
		{ field: 'nome', order: 'ASC' },
		{ unidades__id_condominio: condominioSelecionado?.id }
	);
	const [options, setOptions] = useState([]);
	useEffect(() => {
		if (data && ids) {
			setOptions(ids.map((id) => data[id]));
		}
	}, [data, ids]);
	return (
		<Autocomplete
			disabled={!options.length}
			options={options}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Tipos' />}
			getOptionLabel={(option) =>
				option && (option.descricao || options.find((v) => v.id === option)?.descricao || '')
			}
			multiple
			noOptionsText='Nenhum resultado encontrado'
			getOptionSelected={(option, v) => v?.id === option.id}
			value={tipos}
			onChange={(_, v) => setTipos(v)}
			clearText='Limpar'
			id='select-tipos-unidades'
			itemID='select-tipos-unidades-root'
		/>
	);
};

export const BoxGrupos = ({ grupos, setGrupos }) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { data, ids } = useGetList(
		'grupo_unidade_condominio',
		{ page: 1, perPage: 10000 },
		{ field: 'nome', order: 'ASC' },
		{ id_condominio: condominioSelecionado?.id }
	);
	const [options, setOptions] = useState([]);
	useEffect(() => {
		if (data && ids) {
			setOptions(ids.map((id) => data[id]));
		}
	}, [data, ids]);
	return (
		<Autocomplete
			disabled={!options.length}
			options={options}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Grupos' />}
			getOptionLabel={(option) => option && (option.nome || options.find((v) => v.id === option)?.nome || '')}
			multiple
			noOptionsText='Nenhum resultado encontrado'
			getOptionSelected={(option, v) => v?.id === option.id}
			value={grupos}
			onChange={(_, v) => setGrupos(v)}
			clearText='Limpar'
			id='select-grupos-unidades'
			itemID='select-grupos-unidades-root'
		/>
	);
};

export const BoxTipoLancamento = ({
	tipoLancamento,
	setTipoLancamento,
	disabledFixo = false,
	disabledPercentual = false,
}) => (
	<FormControl color='primary'>
		<FormLabel>Selecione o tipo de valor</FormLabel>
		<RadioGroup value={tipoLancamento} onChange={(e) => setTipoLancamento(e.target.value)}>
			<FormControlLabel
				id='radio-tipo-lancamento-valor-fixo'
				value='VLF'
				control={<Radio color='primary' />}
				label='Valor Fixo'
				disabled={disabledFixo}
			/>
			<FormControlLabel
				id='radio-tipo-lancamento-percentual'
				value='PRC'
				control={<Radio color='primary' />}
				label='Percentual'
				disabled={disabledPercentual}
			/>
		</RadioGroup>
	</FormControl>
);

export const BoxTipoFundo = ({ tipoFundo, setTipoFundo }) => (
	<FormControl color='primary'>
		<FormLabel>Selecione o tipo da conta de fundo</FormLabel>
		<RadioGroup value={tipoFundo} onChange={(e) => setTipoFundo(e.target.value)}>
			<FormControlLabel
				id='radio-tipo-conta-fundo-ordinaria'
				value='O'
				control={<Radio color='primary' />}
				label='Ordinária'
			/>
			<FormControlLabel
				id='radio-tipo-conta-fundo-extraordinaria'
				value='E'
				control={<Radio color='primary' />}
				label='Extraordinária'
			/>
		</RadioGroup>
	</FormControl>
);

const columnsLancDesp = [
	{ field: 'nome_conta_pai', headerName: 'Conta Pai', width: '15vw' },
	{ field: 'nome_conta_despesa', headerName: 'Conta', width: '15vw' },
	{ field: 'tipo_conta_despesa', headerName: 'Tipo da Conta', width: '10vw' },
	{ field: 'historico', headerName: 'Histórico', width: '20vw' },
	{
		field: 'data_movimentacao',
		headerName: 'Data Movimentação',
		type: 'date',
		width: '10vw',
	},

	{ field: 'valor', headerName: 'Valor', type: 'number', width: '5vw' },
];

const columnsLancDespNaoPaga = [
	{ field: 'nome_conta_pai', headerName: 'Conta Pai', width: '15vw' },
	{ field: 'nome_conta_despesa', headerName: 'Conta', width: '15vw' },
	{ field: 'tipo_conta_despesa', headerName: 'Tipo da Conta', width: '10vw' },
	{ field: 'valor', headerName: 'Valor', type: 'number', width: '5vw' },
];

const getTipo = (tipo) => {
	switch (tipo) {
		case 'O':
			return 'Ordinária';
		case 'L':
			return 'Leitura';
		case 'F':
			return 'Fundo';
		case 'E':
			return 'Extraordinária';
		default:
			return '';
	}
};

const useDespesasTableStyle = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '20% 78%',
		gridTemplateRows: 'fit-content(100%)',
		columnGap: '1rem',
	},
	agrupamentoSwitch: {
		gridColumn: '1/2',
		gridRow: '1',
		marginLeft: '0rem',
	},
	pagination: {
		gridColumn: '2/3',
		gridRow: '1',
	},
}));

export const BoxSelectLancDesp = ({
	lancDesp,
	setLancDesp,
	contaBDesp,
	datasAfter,
	datasBefore,
	agrupar,
	setAgrupar,
	imprimirApenasNomeConta,
	setImprimirApenasNomeConta,
}) => {
	const dP = useDataProvider();
	const classes = useDespesasTableStyle();
	const { condominioSelecionado } = useContext(CondominiosContext);
	useEffect(() => {
		setLancDesp([]);
	}, [setLancDesp]);
	const {
		data: dataLD,
		ids: idsLD,
		loading: loadingLD,
	} = useGetList(
		'detalhamento_lancamento_despesa',
		{ page: 1, perPage: 10000 },
		{},
		{
			condominio: condominioSelecionado?.id,
			id_conta_bancaria: contaBDesp,
			datas_after: datasAfter,
			datas_before: datasBefore,
			para_arrecadacao: true,
		}
	);

	const {
		data: dataBC,
		ids: idsBC,
		loading: loadingBC,
	} = useGetList(
		'parcela_despesa',
		{ page: 1, perPage: 10000 },
		{},
		{
			condominio: condominioSelecionado?.id,
			id_conta_bancaria: contaBDesp,
			datas_after: datasAfter,
			datas_before: datasBefore,
			para_arrecadacao: true,
			nao_paga: 0,
		}
	);

	const [{ dataLBD, idsLBD, loadingLBD }, setDadosLDB] = useState({ dataLBD: {}, idsLBD: [], loadingLBD: true });

	const getLDB = () => {
		dP.getList('lancamento_baixa_despesa', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: {
				condominio: condominioSelecionado?.id,
				id_conta_bancaria: contaBDesp,
				datas_after: datasAfter,
				datas_before: datasBefore,
				para_arrecadacao: true,
			},
		}).then((response) => {
			const data = response?.data;
			if (data) {
				const dados = data.reduce((resultado, item) => {
					resultado[item.id] = item;
					return resultado;
				}, {});
				setDadosLDB({ dataLBD: dados, idsLBD: data.map((ids) => ids.id), loadingLBD: false });
			}
		});
	};

	useEffect(getLDB, []);

	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);
	const configRows = useCallback(() => {
		if (!loadingLD && !loadingBC && !loadingLBD) {
			const newRows = [
				...(idsLD || []).map((id) => (dataLD || {})[id]).filter((d) => d.id !== 0),
				...(idsBC || []).map((id) => (dataBC || {})[id]).filter((d) => d.id !== 0),
				...(idsLBD || []).map((id) => (dataLBD || {})[id]).filter((d) => d.id !== 0),
			];
			setTotal(newRows.length);
			setRows(newRows);
			setLancDesp(newRows);
		}
	}, [loadingLD, loadingBC, loadingLBD, idsLBD, dataLBD, idsLD, dataLD, idsBC, dataBC, setRows, setLancDesp]);
	useEffect(configRows, [loadingLD, loadingBC, loadingLBD]);
	const [sort, setSort] = useState({
		field: 'data_movimentacao',
		order: 'asc',
	});

	const handleSortClick = (field) => {
		const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
		setSort({ field, order: newOrder });
		setRows((r) =>
			r.sort((a, b) => {
				if (field === 'valor') {
					a[field] = Number(a[field]);
					b[field] = Number(b[field]);
				}
				if (
					field === 'data_movimentacao'
						? isBefore(parseISO(a[field]), parseISO(b[field]))
						: a[field] < b[field]
				) {
					return newOrder === 'asc' ? -1 : 1;
				}
				if (
					field === 'data_movimentacao'
						? isBefore(parseISO(b[field]), parseISO(a[field]))
						: a[field] > b[field]
				) {
					return newOrder === 'asc' ? 1 : -1;
				}
				return 0;
			})
		);
	};

	const handleSelectAll = () => {
		lancDesp?.some((l) => rows.includes(l)) ? setLancDesp([]) : setLancDesp(rows);
	};

	const handleRowClick = (e, row, isItemSelected) => {
		if (isItemSelected) {
			setLancDesp((r) => r.filter((d) => d !== row));
		} else {
			setLancDesp((r) => [...r, row]);
		}
	};

	return !loadingLD && !loadingBC && !loadingLBD ? (
		<TableContainer>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<TableCell padding='checkbox'>
							<Checkbox
								id='select-all-rows-table'
								indeterminate={lancDesp?.length !== rows.length && lancDesp?.length !== 0}
								checked={lancDesp?.length === rows.length}
								onChange={handleSelectAll}
							/>
						</TableCell>
						{columnsLancDesp.map((c, i) => (
							<TableCell
								key={c.field}
								sortDirection={sort.field === c.field ? sort.order : false}
								style={i === 0 ? { paddingLeft: 0 } : {}}
							>
								<TableSortLabel
									id={c.field}
									active={sort.field === c.field}
									direction={sort.field === c.field ? sort.order : 'asc'}
									onClick={() => handleSortClick(c.field)}
								>
									{c.headerName}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.slice((page - 1) * perPage, page * perPage).map((row, index) => {
						const isItemSelected = Boolean(
							lancDesp?.find((l) => l.id === row.id && l.origem === row.origem)
						);

						return (
							<TableRow
								hover
								onClick={(e) => handleRowClick(e, row, isItemSelected)}
								role='checkbox'
								aria-checked={isItemSelected}
								tabIndex={-1}
								key={row.id}
								selected={isItemSelected}
								id={`table-row-${index}`}
							>
								<TableCell padding='checkbox'>
									<Checkbox
										id={`checkbox-row-${index}`}
										checked={isItemSelected}
										inputProps={{
											'aria-labelledby': row.id,
										}}
									/>
								</TableCell>
								<TableCell component='th' id={row.id} scope='row' padding='none'>
									{row.nome_conta_pai}
								</TableCell>
								<TableCell>{row.nome_conta_despesa}</TableCell>
								<TableCell>{getTipo(row.tipo_conta_despesa)}</TableCell>
								<TableCell>{row.historico}</TableCell>
								<TableCell>{format(parseISO(row.data_movimentacao), 'dd/MM/yyyy')}</TableCell>
								<TableCell align='right'>
									<NumberFormatBRL
										displayType='text'
										value={row.valor}
										fixedDecimalScale
										onChange={() => {}}
										decimalScale={2}
										prefix='R$ '
									/>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<Box className={classes.container}>
				<FormControlLabel
					control={
						<Switch
							id='agrupar-despesas'
							color='primary'
							checked={agrupar}
							onChange={(e) => {
								setAgrupar(!!e?.target?.checked);
							}}
							name='agrupar'
						/>
					}
					label='Agrupar Despesas'
					labelPlacement='end'
					className={classes.agrupamentoSwitch}
				/>
				<SyndkosTablePagination
					{...{
						handlePageChange,
						page,
						perPage,
						total,
						setPerPage,
					}}
					className={classes.pagination}
					labelRowsPerPage='Lançamentos de Despesa por página'
				/>
			</Box>
			<FormControlLabel
				control={
					<Switch
						id='imprimir_apenas_nome_conta'
						color='primary'
						checked={imprimirApenasNomeConta}
						onChange={(e) => {
							setImprimirApenasNomeConta(!!e?.target?.checked);
						}}
						name='imprimirApenasNomeConta'
					/>
				}
				label='Imprimir apenas nome da conta'
				labelPlacement='end'
				className={classes.agrupamentoSwitch}
			/>
		</TableContainer>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

export const BoxSelectLancDespNaoPaga = ({
	lancDesp,
	setLancDesp,
	contaBDesp,
	datasAfter,
	datasBefore,
	agrupar,
	setAgrupar,
	imprimirApenasNomeConta,
	setImprimirApenasNomeConta,
}) => {
	const classes = useDespesasTableStyle();
	const { condominioSelecionado } = useContext(CondominiosContext);
	useEffect(() => {
		setLancDesp([]);
	}, [setLancDesp]);

	const {
		data: dataBC,
		ids: idsBC,
		loading: loadingBC,
	} = useGetList(
		'parcela_despesa',
		{ page: 1, perPage: 10000 },
		{},
		{
			condominio: condominioSelecionado?.id,
			id_conta_bancaria: contaBDesp,
			datas_after: datasAfter,
			datas_before: datasBefore,
			para_arrecadacao: true,
			nao_paga: 1,
		}
	);

	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);
	const configRows = useCallback(() => {
		if (!loadingBC) {
			const newRows = [...(idsBC || []).map((id) => (dataBC || {})[id]).filter((d) => d.id !== 0)];
			setTotal(newRows.length);
			setRows(newRows);
			setLancDesp(newRows);
		}
	}, [loadingBC, idsBC, dataBC, setRows, setLancDesp]);
	useEffect(configRows, [loadingBC]);
	const [sort, setSort] = useState({
		field: 'valor',
		order: 'asc',
	});

	const handleSortClick = (field) => {
		const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
		setSort({ field, order: newOrder });
		setRows((r) =>
			r.sort((a, b) => {
				if (field === 'valor') {
					a[field] = Number(a[field]);
					b[field] = Number(b[field]);
				}
				return 0;
			})
		);
	};

	const handleSelectAll = () => {
		lancDesp?.some((l) => rows.includes(l)) ? setLancDesp([]) : setLancDesp(rows);
	};

	const handleRowClick = (e, row, isItemSelected) => {
		if (isItemSelected) {
			setLancDesp((r) => r.filter((d) => d !== row));
		} else {
			setLancDesp((r) => [...r, row]);
		}
	};

	return !loadingBC ? (
		<TableContainer>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<TableCell padding='checkbox'>
							<Checkbox
								id='select-all-rows-table'
								indeterminate={lancDesp?.length !== rows.length && lancDesp?.length !== 0}
								checked={lancDesp?.length === rows.length}
								onChange={handleSelectAll}
							/>
						</TableCell>
						{columnsLancDespNaoPaga.map((c, i) => (
							<TableCell
								key={c.field}
								sortDirection={sort.field === c.field ? sort.order : false}
								style={i === 0 ? { paddingLeft: 0 } : {}}
							>
								<TableSortLabel
									id={c.field}
									active={sort.field === c.field}
									direction={sort.field === c.field ? sort.order : 'asc'}
									onClick={() => handleSortClick(c.field)}
								>
									{c.headerName}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.slice((page - 1) * perPage, page * perPage).map((row, index) => {
						const isItemSelected = Boolean(
							lancDesp?.find((l) => l.id === row.id && l.origem === row.origem)
						);

						return (
							<TableRow
								hover
								onClick={(e) => handleRowClick(e, row, isItemSelected)}
								role='checkbox'
								aria-checked={isItemSelected}
								tabIndex={-1}
								key={row.id}
								selected={isItemSelected}
								id={`table-row-${index}`}
							>
								<TableCell padding='checkbox'>
									<Checkbox
										id={`checkbox-row-${index}`}
										checked={isItemSelected}
										inputProps={{
											'aria-labelledby': row.id,
										}}
									/>
								</TableCell>
								<TableCell component='th' id={row.id} scope='row' padding='none'>
									{row.nome_conta_pai}
								</TableCell>
								<TableCell>{row.nome_conta_despesa}</TableCell>
								<TableCell>{getTipo(row.tipo_conta_despesa)}</TableCell>
								<TableCell align='right'>
									<NumberFormatBRL
										displayType='text'
										value={row.valor}
										fixedDecimalScale
										onChange={() => {}}
										decimalScale={2}
										prefix='R$ '
									/>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<Box className={classes.container}>
				<FormControlLabel
					control={
						<Switch
							id='agrupar-despesas'
							color='primary'
							checked={agrupar}
							onChange={(e) => {
								setAgrupar(!!e?.target?.checked);
							}}
							name='agrupar'
						/>
					}
					label='Agrupar Despesas'
					labelPlacement='end'
					className={classes.agrupamentoSwitch}
				/>
				<SyndkosTablePagination
					{...{
						handlePageChange,
						page,
						perPage,
						total,
						setPerPage,
					}}
					className={classes.pagination}
					labelRowsPerPage='Lançamentos de Despesa por página'
				/>
			</Box>
			<FormControlLabel
				control={
					<Switch
						id='imprimir_apenas_nome_conta'
						color='primary'
						checked={imprimirApenasNomeConta}
						onChange={(e) => {
							setImprimirApenasNomeConta(!!e?.target?.checked);
						}}
						name='imprimirApenasNomeConta'
					/>
				}
				label='Imprimir apenas nome da conta'
				labelPlacement='end'
				className={classes.agrupamentoSwitch}
			/>
		</TableContainer>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

export const BoxAddContasCalc = ({
	contaReceita,
	contasDespCalc,
	setContasDespCalc,
	contasRecCalc,
	setContasRecCalc,
	arrecadacao_id,
}) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { referencia, id_identificacao_arrecadacao } = useContext(ArrecadacoesContext);

	const { data: recData, ids: recIds } = useGetList(
		'contas_receita',
		{ page: 1, perPage: 10000 },
		{ field: 'tree_id, lft', order: 'ASC' },
		{
			id__not_in: contaReceita.id,
			id_plano_condominio__condominios: condominioSelecionado?.id,
			id_condominio_id: condominioSelecionado?.id,
			id_identificacao_arrecadacao_id: id_identificacao_arrecadacao,
			referencia: referencia,
			classe_conta: 'A',
			...(arrecadacao_id ? { arrecadacao_id } : {}),
		}
	);

	const { data: despData, ids: despIds } = useGetList(
		'contas_despesa',
		{ page: 1, perPage: 10000 },
		{ field: 'tree_id, lft', order: 'ASC' },
		{
			id_plano_condominio__condominios: condominioSelecionado?.id,
			id_condominio_id: condominioSelecionado?.id,
			id_identificacao_arrecadacao_id: id_identificacao_arrecadacao,
			referencia: referencia,
			classe_conta: 'A',
			...(arrecadacao_id ? { arrecadacao_id } : {}),
		}
	);

	const [options, setOptions] = useState([]);

	const configOptions = () => {
		if (recData && recIds && despData && despIds) {
			setOptions([
				...despIds.map((id) => ({
					...despData[id],
					tipoConta: 'Despesa',
				})),
				...recIds.map((id) => ({
					...recData[id],
					tipoConta: 'Receita',
				})),
			]);
		}
	};
	const checkGroup = (option) => {
		const groupLength = options.filter((c) => c.tipoConta === option.group).length;
		if (option.group === 'Despesa') return groupLength === contasDespCalc.length;

		if (option.group === 'Receita') return groupLength === contasRecCalc.length;
	};

	const selectGroup = (option) => {
		const groupedOptions = options.filter((c) => c.tipoConta === option.group);

		if (option.group === 'Despesa') {
			if (contasDespCalc.length > 0) {
				return setContasDespCalc([]);
			}
			return setContasDespCalc(groupedOptions);
		}

		if (option.group === 'Receita') {
			if (contasRecCalc.length > 0) {
				return setContasRecCalc([]);
			}
			return setContasRecCalc(groupedOptions);
		}
	};

	useEffect(configOptions, [recData, recIds, despData, despIds]);
	return (
		<SelectMultiContasParaCalculoWithActions
			setValues={[setContasDespCalc, setContasRecCalc]}
			values={[...contasRecCalc, ...contasDespCalc]}
			options={options}
			disabled={Boolean(despData.length || recData.length)}
			tooltipAddTitle='Selecione todas as Contas para arrecadação'
			tooltipRemoveTitle='Remover todas as Contas para arrecadação'
			renderGroup={(params) => (
				<>
					<Checkbox
						id={`select-group-${params.group}`}
						key={params.tipoConta}
						checked={checkGroup(params)}
						onChange={() => selectGroup(params)}
					/>
					<span>{params.group}</span>
					{params.children}
				</>
			)}
		/>
	);
};
