import React from 'react';

import { TCampoFinePercentageProps } from '../../types';

import NumberFormatPercent from './NumberFormatPercent';

export const CampoFinePercentage: React.FC<TCampoFinePercentageProps> = ({ setFinePercentage, finePercentage }) => {
	return (
		<NumberFormatPercent
			label='Percentual de Multa'
			value={finePercentage}
			onValueChange={({ value }: { value: string }) => {
				value = value.substring(0, 2) + ',' + value.substring(2);
				return setFinePercentage(value);
			}}
			fullWidth
		/>
	);
};

export default CampoFinePercentage;
