import React from 'react';

import { BRLNumberField } from 'components/common/BRLNumberField';
import { Padrao } from 'components/common/filtros/Padrao';
import { ModalContextProvider, CustomDialog } from 'components/common/ModalContext';
import { Tabela } from 'components/common/Tabela';
import { TextField } from 'react-admin';

interface LancamentoLeituraNumberFieldProps {
	source: string;
	align: string;
	label: string;
}

const LancamentoLeituraNumberField: React.FC<LancamentoLeituraNumberFieldProps> = (props) => (
	<BRLNumberField
		options={{
			minimumFractionDigits: 2,
		}}
		{...props}
	/>
);

interface TextFieldProps {
	source: string;
	label: string;
	filtro: React.ReactNode;
	children?: React.ReactNode;
}

const TextFieldDescricao: TextFieldProps = {
	source: 'descricao_tipo',
	label: 'Descrição',
	filtro: <Padrao />,
};
const TextFieldGrupoUnidade: TextFieldProps = {
	source: 'nome_grupo_unidade',
	label: 'Grupo',
	filtro: <Padrao />,
};
const TextFieldUnidade: TextFieldProps = {
	source: 'unidade',
	label: 'Unidade',
	filtro: <Padrao />,
};

const TabelaLancamentosLeituraPercentual: React.FC = (props: object) => {
	return (
		<ModalContextProvider>
			<Tabela disableClickRow {...props}>
				<TextField {...TextFieldDescricao} />
				<TextField {...TextFieldGrupoUnidade} />
				<TextField {...TextFieldUnidade} />
				<LancamentoLeituraNumberField align='right' source='valor_da_leitura' label='Valor' />
			</Tabela>
			<CustomDialog />
		</ModalContextProvider>
	);
};

export default TabelaLancamentosLeituraPercentual;
