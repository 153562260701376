import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import BoxButtonDecision from './BoxButtonDecision';
import NovaLeituraContext from './NovaLeituraContext';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';

const ModalDecidirConverterFatorNovaLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setModalStep } = useContext(NovaLeituraContext);
	const handleBack = () => {
		setModalStep('5F');
	};
	const handleNextSim = () => {
		setModalStep('7FK');
	};
	const handleNextNao = () => {
		if ('2' === dadosNovaLeitura.dados_config.metodo_calculo) setModalStep('8FS');
		if (['1', '3'].includes(dadosNovaLeitura.dados_config.metodo_calculo)) setModalStep('9FC');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Deseja transformar o consumo de m³ para Kg?'
			form={{
				component: (
					<Box display='flex' justifyContent='center'>
						<BoxButtonDecision onclick={handleNextSim}>Sim</BoxButtonDecision>
						<BoxButtonDecision onclick={handleNextNao}>Não</BoxButtonDecision>
					</Box>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
				</>
			}
		/>
	);
};

export default ModalDecidirConverterFatorNovaLeitura;
