import { AdvogadoList } from './list';
import { AdvogadoEdit } from './edit';
import { AdvogadoCreate } from './create';

const resource = {
	list: AdvogadoList,
	edit: AdvogadoEdit,
	create: AdvogadoCreate,
};

export default resource;
