import React from 'react';

import { Field } from 'react-final-form';

import EditaContaBancariaTextInput from '../../commons/EditaContaBancariaTextInput';

const LinhaField = ({ num, maxLength = 110 }) => (
	<Field name={`linha_${num}`} id={`linha_${num}`}>
		{({ input }) => (
			<EditaContaBancariaTextInput
				source={input.name}
				name={input.name}
				id={input.name}
				label={`Linha ${num}`}
				helperText={`${(input.value || '').length}/${maxLength}`}
				parse={(value) => value || ''}
			/>
		)}
	</Field>
);

export default LinhaField;
