import React, { useState, useEffect } from 'react';
import { useListContext } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const PadraoField = ({ id, handleChange, value, ...props }) => (
	<TextField
		variant='standard'
		id={id}
		onChange={(e) => handleChange(e.target.value)}
		value={value || ''}
		label='Filtrar'
		style={{ width: '100%' }}
		InputProps={{
			endAdornment: (
				<InputAdornment position='end' style={{ position: 'absolute', right: 0 }}>
					<IconButton aria-label='toggle password visibility' onClick={() => handleChange('')} edge='end'>
						{value && <CloseIcon />}
					</IconButton>
				</InputAdornment>
			),
		}}
		{...props}
	/>
);

export const Padrao = ({ source = undefined, custom = undefined, listControllerProps = undefined }) => {
	const { setFilters, filterValues } = useListContext(listControllerProps);
	const [value, setValue] = useState('');
	const [requestTimeout, setRequestTimeout] = useState(null);

	const updateValue = () => {
		setValue(filterValues[custom || source]);
	};
	useEffect(updateValue, []);

	const handleChange = (newValue) => {
		setValue(newValue);

		if (requestTimeout) clearTimeout(requestTimeout);

		setRequestTimeout(
			setTimeout(() => {
				setFilters({ ...filterValues, [custom || source]: newValue });
			}, 1250)
		);
	};

	return <PadraoField id={`${custom || source}-filtro-padrao`} handleChange={handleChange} value={value} />;
};
