import React, { createContext, useState, useCallback, useContext, useEffect, useMemo } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Box, Typography, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import SyncAlt from '@material-ui/icons/SyncAlt';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../common/ModalSizeEditableContext';
import { TooltipIconButtonCancel } from '../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../common/buttons/ButtonConfirm';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import DropZone from './DropZone';

const BaixaAutomaticaContext = createContext();

export const BaixaAutomaticaContextProvider = ({ children }) => {
	const [invalidFiles, setInvalidFiles] = useState([]);
	const [recebimentosGerados, setRecebimentosGerados] = useState([]);
	const [boletosQuitados, setBoletosQuitados] = useState([]);
	const [lotesNaoUsados, setLotesNaoUsados] = useState([]);
	const [contasDespesasSemVinculo, setContasDespesasSemVinculo] = useState([]);
	const [showResultados, setShowResultados] = useState();

	const baixaAutomaticaValue = useMemo(
		() => ({
			invalidFiles,
			setInvalidFiles,
			recebimentosGerados,
			setRecebimentosGerados,
			boletosQuitados,
			setBoletosQuitados,
			lotesNaoUsados,
			setLotesNaoUsados,
			contasDespesasSemVinculo,
			setContasDespesasSemVinculo,
			showResultados,
			setShowResultados,
		}),
		[
			invalidFiles,
			setInvalidFiles,
			recebimentosGerados,
			setRecebimentosGerados,
			boletosQuitados,
			setBoletosQuitados,
			lotesNaoUsados,
			setLotesNaoUsados,
			contasDespesasSemVinculo,
			setContasDespesasSemVinculo,
			showResultados,
			setShowResultados,
		]
	);
	return <BaixaAutomaticaContext.Provider value={baixaAutomaticaValue}>{children}</BaixaAutomaticaContext.Provider>;
};

export const ResultadosBox = () => {
	const {
		invalidFiles,
		recebimentosGerados,
		boletosQuitados,
		lotesNaoUsados,
		contasDespesasSemVinculo,
		showResultados,
	} = useContext(BaixaAutomaticaContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	return showResultados ? (
		<Paper style={{ padding: '1em' }}>
			<Box display='flex'>
				{!!(recebimentosGerados.length || boletosQuitados.length) && (
					<Box flex={1} mr='1em'>
						{!!recebimentosGerados.length && (
							<Box flex={1} mr='1em'>
								<Typography variant='h6'>
									Recebimentos processados com sucesso - {recebimentosGerados.length}
								</Typography>
								{recebimentosGerados.map((recebimento) => (
									<Typography variant='subtitle1' color='secondary'>
										{recebimento}
									</Typography>
								))}
							</Box>
						)}
						{!!boletosQuitados.length && (
							<Box flex={1} mr='1em'>
								<br />
								<Typography variant='h6'>
									Recebimentos já efetuados - {boletosQuitados.length}
								</Typography>
								{boletosQuitados.map((baixas) => (
									<Typography variant='subtitle1' color='secondary'>
										{baixas}
									</Typography>
								))}
							</Box>
						)}
					</Box>
				)}

				{!!lotesNaoUsados.length && (
					<Box flex={1} mr='1em'>
						<Box flex={1} mr='1em'>
							<Typography variant='h6'>Registros não usados</Typography>
							<br />
							{lotesNaoUsados.map((arquivo) => (
								<Typography variant='subtitle1'>
									{arquivo.file_name} - Nº de registros não usados: {arquivo.num_lotes}
								</Typography>
							))}
							{!!contasDespesasSemVinculo.length && (
								<>
									<br />
									<Typography variant='h6'>
										Erro, as seguintes contas de despesa não possuem vínculo:
									</Typography>
									<br />
									{contasDespesasSemVinculo.map((arquivo) => (
										<Typography variant='subtitle1'>
											{arquivo.nome_condominio} / {arquivo.descricao_plano_condominio} /{' '}
											{arquivo.nome_conta_despesa}
										</Typography>
									))}
								</>
							)}
						</Box>
					</Box>
				)}
				{!!Object.keys(invalidFiles).length && (
					<Box flex={1} mr='1em'>
						<Box flex={1} mr='1em'>
							<Typography variant='h6'>Arquivos invalidados</Typography>
							<br />
							{Object.keys(invalidFiles).map((fileName) => (
								<>
									<Typography variant='subtitle1' style={{ color: syndikosRed.dark }}>
										{fileName}
									</Typography>
									{invalidFiles[fileName]?.map((mensagem) => (
										<Typography
											variant='subtitle2'
											style={{
												color: syndikosRed.main,
											}}
										>
											{mensagem}
										</Typography>
									))}
								</>
							))}
						</Box>
					</Box>
				)}
			</Box>
		</Paper>
	) : (
		<></>
	);
};

const ModalDropZoneExtratoBancaria = () => {
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const {
		setInvalidFiles,
		setRecebimentosGerados,
		setBoletosQuitados,
		setLotesNaoUsados,
		setContasDespesasSemVinculo,
		setShowResultados,
	} = useContext(BaixaAutomaticaContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [filesSelected, setFilesSelected] = useState([]);
	const [valid, setValid] = useState(false);
	const [processing, setProcessing] = useState(false);

	const validate = useCallback(() => {
		setValid(!!filesSelected.length);
	}, [filesSelected]);

	useEffect(validate, [filesSelected.length]);

	const processarArquivos = useCallback(() => {
		setProcessing(true);
		const formData = new FormData();
		Array.from(filesSelected).forEach(({ file }) => formData.append(file?.name, file));
		dataProvider
			.processarArquivosRetorno('arquivos_retorno/processar_arquivos', {
				data: formData,
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					setRecebimentosGerados(data?.lista_recebimentos_gerados);
					setBoletosQuitados(data?.lista_boletos_quitados);
					!!data?.lista_recebimentos_gerados?.length && refresh();
					setLotesNaoUsados(data?.lista_num_lotes_nao_usados_por_arquivo);
					setInvalidFiles(data?.arquivos_invalidos || []);
					setContasDespesasSemVinculo(data?.contas_despesas_sem_vinculo);
					setShowResultados(true);
					setModalValue((v) => ({ ...v, open: false }));
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setRecebimentosGerados([]);
				setBoletosQuitados([]);
				setLotesNaoUsados([]);
				setInvalidFiles([]);
				setContasDespesasSemVinculo([]);
				setShowResultados(false);
				setProcessing(false);
				notify('Erro inesperado, contate o suporte', 'warning');
			});
	}, [
		setProcessing,
		filesSelected,
		dataProvider,
		setRecebimentosGerados,
		setBoletosQuitados,
		setLotesNaoUsados,
		setInvalidFiles,
		setContasDespesasSemVinculo,
		setShowResultados,
		notify,
		refresh,
		setModalValue,
	]);

	return (
		<CustomDialogBodySizeEditable
			title='Importação de Retorno'
			customActions={[
				<TooltipIconButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />,
				<TooltipIconButtonConfirm
					title='Processar Arquivos'
					id='process_remessas'
					disabled={!valid || processing}
					onClick={() => {
						setInvalidFiles([]);
						setRecebimentosGerados([]);
						setBoletosQuitados([]);
						setLotesNaoUsados([]);
						setContasDespesasSemVinculo([]);
						processarArquivos();
					}}
				/>,
			]}
			form={{
				component: (
					<>
						<DropZone {...{ filesSelected, setFilesSelected }} />
					</>
				),
			}}
		/>
	);
};

export const ToolTipButtonsArquivosRetorno = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<>
			<Box display='flex' alignItems='center' mb='1em'>
				<TooltipIconButton
					title='Importar Retorno'
					id='importar_remessas'
					color='primary'
					onClick={() => {
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <ModalDropZoneExtratoBancaria />,
						}));
					}}
				>
					<SwapVerticalCircleOutlinedIcon />
				</TooltipIconButton>
			</Box>
		</>
	);
};

export const ToolTipButtonsAutoSyncBaixas = () => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;

	const processaSincronizacao = () => {
		dataProvider
			.processaSincronizacaoContas('arquivos_retorno/sincronizar_contas', {
				data: { condominio_id: condominioSelecionadoId },
			})
			.then((response) => {
				if (response?.data?.message) notify(response?.data?.message);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) {
					return Promise.reject(e);
				}
				const msg = e?.response?.data?.message ?? 'Erro inesperado, tente recarregar a página.';
				notify(msg, 'warning');
			});
	};

	return (
		<>
			<Box display='flex' alignItems='center' mb='1em'>
				<TooltipIconButton
					title='Sincronizar'
					id='sincronizar_contas_a_receber_tooltipo_icon_button'
					color='primary'
					onClick={processaSincronizacao}
				>
					<SyncAlt />
				</TooltipIconButton>
			</Box>
		</>
	);
};
