import React, { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { TextField, Box, Paper } from '@material-ui/core';

import { DeleteButton, BotaoSalvar } from 'components/common/commonComponentsTSX';

import { PrevisaoOrcamentariaContext } from '../contexts/PrevisoesOrcamentariasContextProvider';
import { MonthPicker } from '../periodoPicker';
import { ContasTable } from '../arvoreContas';
import { ModalConfirmacao } from './DeletadorModelo';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TreeTableContext } from '../contexts/TreeTableContextProvider';
import { IPrevisaoOrcamentariaWithDadosContaNode, IPrevisaoOrcamentaria, CustomDataProvider } from '../types';

const reducerDatasInicialEFinal = (
	state: { dataInicial: Date; dataFinal: Date; errors: { dataInicial: boolean; dataFinal: boolean } },
	action: { dataInicialAlterada?: boolean; dataFinalAlterada?: boolean; value: Date; dataParaComparar: Date }
) => {
	if (action.dataInicialAlterada) {
		state.dataInicial = action.value;
		state.errors = {
			...state.errors,
			dataInicial: action.dataParaComparar.setHours(0, 0, 0, 0) < state.dataInicial.setHours(0, 0, 0, 0),
		};
	}
	if (action.dataFinalAlterada) {
		state.dataFinal = action.value;
		state.errors = {
			...state.errors,
			dataFinal: state.dataFinal.setHours(0, 0, 0, 0) < action.dataParaComparar.setHours(0, 0, 0, 0),
		};
	}
	const referenciaInicialUnix = state.dataInicial.setHours(0, 0, 0, 0);
	const referenciaFinalUnix = state.dataFinal.setHours(0, 0, 0, 0);
	if (referenciaInicialUnix <= referenciaFinalUnix) {
		state.errors = {
			...state.errors,
			dataInicial: false,
			dataFinal: false,
		};
	}

	return { ...state };
};

const FormModeloPrevisao: React.FC = () => {
	const dp = useDataProvider() as CustomDataProvider;
	const notify = useNotify();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const {
		previsaoOrcamentariaTree,
		openChildren,
		valoresTotais,
		setValoresTotais,
		referenciaInicial,
		referenciaFinal,
		setReferenciaInicial,
		setReferenciaFinal,
		modeloSelecionado,
	} = React.useContext(PrevisaoOrcamentariaContext);
	const [propriedadesContasView, setPropriedadesContasView] = useState({
		openChildren: openChildren,
	});

	const [{ errors }, dispatch] = React.useReducer(reducerDatasInicialEFinal, {
		dataInicial: referenciaInicial,
		dataFinal: referenciaFinal,
		errors: {
			dataInicial: false,
			dataFinal: false,
		},
	});

	const processTreeBottomToTop = (node: IPrevisaoOrcamentariaWithDadosContaNode, contasAnaliticas: any[]) => {
		if (node.children && node.children.length > 0)
			for (const childNode of node.children) processTreeBottomToTop(childNode, contasAnaliticas);

		if (node.classe_conta === 'A') {
			const tipoConta = node.id_concatenado.includes('CR')
				? { conta_receita_id: node.id }
				: { conta_despesa_id: node.id };
			contasAnaliticas.push({
				id: node.previsao_orcamentaria_id,
				valor_periodo: node.valorPeriodo,
				valor_mensal: node.valorMensal,
				modelo_previsao_orcamentaria_id: modeloSelecionado?.id,
				...tipoConta,
			});
		}
	};

	const handleSubmit = () => {
		const contasAnaliticas = [] as any[];
		for (const node of previsaoOrcamentariaTree) processTreeBottomToTop(node, contasAnaliticas);
		const previsoes_orcamentarias = contasAnaliticas.map((d: IPrevisaoOrcamentaria) => ({
			id: d?.id,
			valor_periodo: d?.valor_periodo ? (d?.valor_periodo / 100).toFixed(2) : 0,
			valor_mensal: d?.valor_mensal ? (d?.valor_mensal / 100).toFixed(2) : 0,
			modelo_previsao_orcamentaria: modeloSelecionado?.id,
			conta_receita: d?.conta_receita_id,
			conta_despesa: d?.conta_despesa_id,
		}));
		dp.fullUpdate('modelo_previsao_orcamentaria', {
			id: modeloSelecionado?.id,
			data: {
				modelo_previsao_orcamentaria_id: modeloSelecionado?.id,
				previsoes_orcamentarias: previsoes_orcamentarias,
				inicio: modeloSelecionado?.inicio?.toISOString().slice(0, 10),
				termino: modeloSelecionado?.termino?.toISOString().slice(0, 10),
				periodo: modeloSelecionado?.periodo,
			},
		})
			.then((response: any) => {
				notify('Modelo atualizado com sucesso!');
			})
			.catch((e: any) => {
				const errorData: Array<string> = Object.values(e?.response?.data);
				notify(errorData[0] as string, 'error');
			});
	};

	const setTree = (arvore: IPrevisaoOrcamentariaWithDadosContaNode[], property: string) => {
		setPropriedadesContasView((v) => ({
			...v,
			[property]: arvore,
		}));
	};

	const updateTree = () => {
		setTree(previsaoOrcamentariaTree, 'treeData');
		setValoresTotais((v) => ({
			...v,
			...previsaoOrcamentariaTree.reduce(
				({ valorMensal, valorPeriodo }, previsaoOrcamentariaNode: IPrevisaoOrcamentariaWithDadosContaNode) => {
					valorMensal += previsaoOrcamentariaNode.valorMensal;
					valorPeriodo += previsaoOrcamentariaNode.valorPeriodo;
					return { valorMensal, valorPeriodo };
				},
				{ valorMensal: 0, valorPeriodo: 0 }
			),
		}));
	};
	useEffect(updateTree, [previsaoOrcamentariaTree]);

	const providerValue = useMemo(() => {
		return {
			propriedadesContasView: {
				openChildren: propriedadesContasView.openChildren,
				...valoresTotais,
				percentual: 100,
			},
			setPropriedadesContasView,
		};
	}, [propriedadesContasView, setPropriedadesContasView, setTree, valoresTotais]);

	if (!previsaoOrcamentariaTree) return <div />;

	if (!modeloSelecionado.id) return <></>;
	return (
		<Box>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					gap: '1em',
				}}
			>
				<MonthPicker
					label='Início'
					handleChange={(newValue: any) => {
						setReferenciaInicial(newValue);
						dispatch({ dataInicialAlterada: true, value: newValue, dataParaComparar: referenciaFinal });
					}}
					value={
						referenciaInicial && referenciaInicial.toString() !== 'Invalid Date'
							? referenciaInicial.toLocaleDateString('pt-BR').slice(3)
							: undefined
					}
					error={errors.dataInicial}
					helperText={errors.dataInicial ? 'Data inicial não pode ser maior que final' : ''}
				></MonthPicker>
				<MonthPicker
					label='Término'
					handleChange={(newValue: any) => {
						newValue.setDate(new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0).getDate());
						setReferenciaFinal(newValue);
						dispatch({ dataFinalAlterada: true, value: newValue, dataParaComparar: referenciaInicial });
					}}
					value={
						referenciaFinal && referenciaFinal.toString() !== 'Invalid Date'
							? referenciaFinal.toLocaleDateString('pt-BR').slice(3)
							: undefined
					}
					error={errors.dataFinal}
					helperText={errors.dataFinal ? 'Data final não pode ser menor que inicial' : ''}
				></MonthPicker>
				<TextField
					id='diferenca-meses-inicio-termino'
					size='small'
					label='Período'
					disabled={true}
					variant='outlined'
					fullWidth
					margin='normal'
					value={
						modeloSelecionado?.periodo
							? modeloSelecionado?.periodo > 1
								? modeloSelecionado?.periodo.toString() + ' meses'
								: modeloSelecionado?.periodo > 0
								? modeloSelecionado?.periodo.toString() + ' mês'
								: 'Período'
							: 'Período'
					}
				></TextField>
			</Box>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1em',
					maxHeight: '60vh',
					overflowY: 'scroll',
				}}
			>
				<TreeTableContext.Provider value={providerValue}>
					<div
						style={{
							margin: '15px 10px 0',
							width: '98%',
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							transition: 'all 200ms ease',
						}}
					>
						<Paper style={{ width: '100%', height: '100%' }}>
							<ContasTable />
						</Paper>
					</div>
				</TreeTableContext.Provider>
			</Box>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					gap: '1em',
					marginTop: '1em',
				}}
			>
				<DeleteButton
					onClick={() => {
						setModalValue((v: any) => ({ ...v, open: true, dialogBody: <ModalConfirmacao /> }));
					}}
				>
					Excluir
				</DeleteButton>
				<BotaoSalvar disabled={false} onClick={handleSubmit}></BotaoSalvar>
			</Box>
		</Box>
	);
};

export default FormModeloPrevisao;
