import React, { useContext, useState } from 'react';

import { Box, Typography, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import WarningIcon from '@material-ui/icons/Warning';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';

import { useNotify, useDelete, Button as RAButton } from 'react-admin';

import { CustomDialog, ModalContextProvider } from '../../../../common/ModalContext';
import {
	ModalSizeEditableContext,
	ModalSizeEditableContextProvider,
	CustomDialogBodySizeEditable,
} from '../../../../common/ModalSizeEditableContext';
import { NumberFormatBRL } from '../../../../common/CurrencyInput';
import { TooltipIconButton } from '../../../../common/TooltipIconButton';
import { ButtonRemove } from '../../../../common/buttons/ButtonRemove';
import { TooltipRemoveIconButton } from '../../../../common/buttons/BotaoRemover';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';

import {
	AmbienteReservaContext,
	AmbienteReservaContextProvider,
	AmbientesContext,
	AmbientesContextProvider,
} from './context';
import AmbienteReservaModal from './modal';
import { useDataProvider } from 'react-admin';

const ActionButtonsHover = () => {
	const notify = useNotify();
	const { setAmbientes, idCondominio } = useContext(AmbientesContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { ambiente, setAmbiente } = useContext(AmbienteReservaContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const [settingsOpen, setSettingsOpen] = useState(false);
	const dp = useDataProvider();
	const [deleteGrupo] = useDelete('ambiente_reserva', ambiente.id, ambiente, {
		onSuccess: () => {
			notify('ra.notification.deleted', 'info', undefined, true, 5000);
			setAmbientes((data) => data.filter((v) => v.id !== ambiente.id));
			handleClose();
		},
		onFailure: (error) => {
			if ([401, 403].includes(error?.response?.status)) return Promise.reject(error);
			const erroMsg = Object.values(error?.response?.data || {})[0] || [
				'Erro inesperado, tente recarregar a página',
			];
			notify(erroMsg, 'warning');
			handleClose();
		},
	});

	const callbackPatchAmbiente = (patchedAmbiente) => {
		setAmbiente(patchedAmbiente);
	};

	const openModal = async () => {
		const results = await Promise.allSettled([
			dp
				.getSimple('grupo_unidade_condominio', {
					pagination: { perPage: 10000, page: 1 },
					sort: { field: 'id', order: 'ASC' },
					filter: { id_condominio: idCondominio },
				})
				.then(({ data }) => {
					const grupos = data?.results.map((x) => ({ id: x.id, name: x.nome }));
					return grupos;
				})
				.catch((e) => {
					notify('Erro ao coletar grupos de unidades.', 'warning');
					return [];
				}),
			ambiente.conta_receita
				? dp
						.getSimple('contas_receita', {
							id: ambiente.conta_receita,
						})
						.then(({ data }) => {
							const contaReceitaInicial = data;
							return contaReceitaInicial;
						})
						.catch((e) => {
							notify('Erro ao coletar a conta de receita do ambiente.', 'warning');
							return null;
						})
				: Promise.resolve(null),
		]);

		const grupos = results[0].status === 'fulfilled' ? results[0].value : [];
		const contaReceitaInicial = results[1].status === 'fulfilled' ? results[1].value : null;

		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<AmbienteReservaModal
					grupos={grupos}
					ambiente={ambiente}
					callbackActions={callbackPatchAmbiente}
					contaReceitaInicial={contaReceitaInicial}
				/>
			),
		}));
		setSettingsOpen(false);
	};

	const handleClose = () => setModalValue((v) => ({ ...v, open: false }));

	const Actions = () => (
		<>
			<TooltipIconButton title='Editar' onClick={openModal} ml='0.5em'>
				<EditIcon />
			</TooltipIconButton>
			<TooltipRemoveIconButton
				title='Excluir'
				onClick={() => {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<CustomDialogBodySizeEditable
								title='Tem certeza que deseja remover esse Ambiente de Reserva?'
								text={
									<Box display='flex' alignItems='center' gridGap='20px'>
										<Box>
											<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
										</Box>
										<Box>
											<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
												Essa ação é irreversível.
												<br />
												Todo o histórico será excluído.
											</Typography>
										</Box>
									</Box>
								}
								customActions={
									<>
										<ButtonCancel onClick={handleClose} />
										<ButtonRemove size='small' onClick={deleteGrupo} />
									</>
								}
							/>
						),
					}));
				}}
			/>
		</>
	);

	return (
		<div onMouseLeave={() => setSettingsOpen(false)}>
			<span style={{ position: 'relative' }}>
				<TooltipIconButton title='Opções' onMouseEnter={() => setSettingsOpen(true)}>
					<SettingsIcon />
				</TooltipIconButton>
				<Paper
					style={{
						display: settingsOpen ? 'flex' : 'none',
						justifyContent: 'center',
						alignItems: 'center',
						width: 100,
						position: 'absolute',
						top: '-18px',
						borderRadius: 10,
					}}
				>
					<Actions onClick={() => setSettingsOpen(false)} />
				</Paper>
			</span>
		</div>
	);
};

const AmbienteReservaBoxContent = () => {
	const { ambiente } = useContext(AmbienteReservaContext);
	const [mostrarAmbientes, setMostrarAmbientes] = useState(false);
	const theme = useTheme();

	const diasHabilitados = ambiente?.dias?.filter((dia) => dia.habilitado) || [];
	return (
		<>
			<Box display='flex' alignItems='center'>
				<TooltipIconButton
					title={!mostrarAmbientes ? 'Mostrar Ambiente' : 'Ocultar Ambiente'}
					onClick={() => setMostrarAmbientes(!mostrarAmbientes)}
				>
					<ArrowForwardIosIcon
						fontSize='medium'
						style={{
							transition: 'all 200ms !important',
							transform: mostrarAmbientes ? 'rotate(90deg)' : 'rotate(0deg)',
						}}
					/>
				</TooltipIconButton>
				<Typography variant='h6' style={{ 'margin-right': '10px' }}>
					{ambiente.nome}
				</Typography>
				<ActionButtonsHover />
			</Box>
			{mostrarAmbientes && (
				<>
					<Box display='flex'>
						<Box flexGrow={1} ml='3em'>
							<Typography variant='body1'>
								<SubdirectoryArrowRightIcon
									fontSize='small'
									style={{ height: '15px', color: theme.palette.primary.dark }}
								/>
								Capacidade de pessoas:{' '}
								<b style={{ color: theme.palette.primary.dark }}>{ambiente?.capacidade_pessoas || 0}</b>
							</Typography>
						</Box>
					</Box>
					{diasHabilitados.length > 0 && ambiente.taxa_por_uso && (
						<Box display='flex' flexDirection='column' ml='3em'>
							<Typography variant='body1'>
								<SubdirectoryArrowRightIcon
									fontSize='small'
									style={{ height: '15px', color: theme.palette.primary.dark }}
								/>
								Taxa para reserva:
							</Typography>
							{diasHabilitados.map((dia) => (
								<Box display='flex' ml='2em'>
									<Typography key={dia.id} variant='body1'>
										<b style={{ color: theme.palette.primary.dark }}>{dia.dia} : </b>
										<b style={{ color: theme.palette.primary.dark }}>
											R${' '}
											<NumberFormatBRL
												displayType='text'
												value={dia.valor}
												fixedDecimalScale
												onChange={() => {}}
												decimalScale={2}
												allowNegative={true}
											/>
										</b>
									</Typography>
								</Box>
							))}
						</Box>
					)}
				</>
			)}
			<hr style={{ marginRight: '1em', borderTop: '#d3d3d3' }} />
		</>
	);
};

const AmbienteReservaBoxHeader = () => {
	const { ambientes, setAmbientes, idCondominio } = useContext(AmbientesContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const dp = useDataProvider();

	const callbackAddAmbiente = (newAmbiente) => {
		setAmbientes((oldAmbientes) => [...oldAmbientes, newAmbiente]);
	};

	const openModal = async () => {
		const grupos = await dp
			.getSimple('grupo_unidade_condominio', {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'id', order: 'ASC' },
				filter: { id_condominio: idCondominio },
			})
			.then(({ data }) => {
				const grupos = data?.results.map((x) => ({ id: x.id, name: x.nome }));
				return grupos;
			})
			.catch((e) => {
				notify('Erro ao coletar grupos de unidades.', 'warning');
				return [];
			});
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <AmbienteReservaModal grupos={grupos} callbackActions={callbackAddAmbiente} />,
		}));
	};

	return (
		<>
			<Box display='flex'>
				<Box flexGrow={1} mr='1em'>
					<Typography variant='h5' gutterBottom>
						Ambientes de Reservas
					</Typography>
				</Box>
				<Box align='right' mr='1em'>
					<RAButton label='Cadastrar' variant='text' color='primary' onClick={openModal}>
						<AddCircleOutlineIcon />
					</RAButton>
				</Box>
			</Box>

			<hr style={{ marginRight: '1em', borderTop: '#d3d3d3' }} />

			{ambientes
				.filter((v) => v.id !== 0)
				.map((ambiente) => (
					<ModalContextProvider key={ambiente.id}>
						<AmbienteReservaContextProvider ambiente={ambiente}>
							<AmbienteReservaBoxContent />
							<CustomDialog disableBackdropClick={true} />
						</AmbienteReservaContextProvider>
					</ModalContextProvider>
				))}
		</>
	);
};

const AmbienteReservaBox = (props) => {
	return (
		<Box width={1}>
			<AmbientesContextProvider {...props}>
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
						minWidth: '61vw',
					}}
				>
					<AmbienteReservaBoxHeader />
				</ModalSizeEditableContextProvider>
			</AmbientesContextProvider>
		</Box>
	);
};

export default AmbienteReservaBox;
