import React, { ElementType } from 'react';
import { Box, Button, FormControlLabel, Switch, Tooltip, Typography } from '@material-ui/core';
import { IconButton as IconButtonMui } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
	CommonButtonProps,
	BooleanInputProps,
	SaveButtonProps,
	CloseButtonProps,
	ConfirmButtonProps,
	CancelButtonProps,
	TooltipIconButtonPros,
	CustomTitleProps,
	DeleteButtonProps,
} from './types';
import { useStylesFormsButton } from './styles';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { alpha, makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { ButtonCancel as ButtonCancelV1 } from '../buttons/ButtonCancel';
import { BotaoSalvar as BotaoSalvarV1 } from '../buttons/BotaoSalvar';

const defaultIcon = <CheckIcon />;

const useStyles = makeStyles(
	(theme) => ({
		cancelButton: {
			'&:hover': {
				backgroundColor: alpha(theme.palette.primary.main, 0.05),

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
		customTitle: {
			marginBottom: theme.spacing(3),
			borderBottom: `1px solid ${theme.palette.divider}`,
			paddingBottom: theme.spacing(1),
		},
	}),
	{ name: 'SyndikosCancelButton' }
);

export const CustomTitle: React.FC<CustomTitleProps> = ({
	children,
	style = null,
	variant = 'h6',
	component = 'h6',
}) => {
	const classes = useStyles();

	return (
		<Typography
			{...(style ? { style: { ...style } } : {})}
			variant={variant}
			component={component as ElementType}
			className={classes.customTitle}
		>
			{children}
		</Typography>
	);
};

export const IconButton: React.FC<DeleteButtonProps> = ({ disabled = false, children, onClick, size }) => {
	const classes = useStylesFormsButton();
	return (
		<Box
			onClick={onClick}
			className={classes.deleteButton}
			display={'flex'}
			justifyContent={'center'}
			borderRadius={'4px'}
		>
			{children}
		</Box>
	);
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({ disabled = false, children, onClick, size, startIcon }) => {
	const classes = useStylesFormsButton();
	return (
		<Button
			disabled={disabled}
			size={size}
			onClick={onClick}
			startIcon={startIcon || <DeleteIcon />}
			color='primary'
			variant='text'
			className={classes.deleteButton}
		>
			{children}
		</Button>
	);
};

export const CloseButton: React.FC<CloseButtonProps> = ({ disabled, children, onClick, size }) => {
	return (
		<Button disabled={disabled} size={size} onClick={onClick} color='default' startIcon={<CloseIcon />}>
			{children || 'Fechar'}
		</Button>
	);
};

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({ children, onClick, loading = false, size, ...rest }) => {
	return (
		<Button
			{...rest}
			color='primary'
			startIcon={loading ? <CircularProgress size={20} /> : <CheckIcon />}
			disabled={rest.disabled || loading}
			onClick={onClick}
			size={size}
		>
			{children || 'Salvar'}
		</Button>
	);
};
export const NextButton: React.FC<CommonButtonProps> = ({ children, onClick, disabled }) => {
	return (
		<Button color='primary' onClick={onClick} disabled={disabled}>
			{children || 'Próximo'}
			<NavigateNextIcon />
		</Button>
	);
};

export const GoBackButton: React.FC<CommonButtonProps> = ({ onClick, children, disabled }) => {
	return (
		<Button color='primary' onClick={onClick} disabled={disabled}>
			<NavigateBeforeIcon />
			{children || 'Voltar'}
		</Button>
	);
};

export const CancelButton: React.FC<CancelButtonProps> = ({ children, ...rest }) => {
	const classes = useStyles();
	return (
		<Button {...rest} startIcon={<CloseIcon />} className={classnames([classes.cancelButton])}>
			{children || 'Cancelar'}
		</Button>
	);
};
export const CancelButton2: React.FC<CommonButtonProps> = ({ onClick, children, disabled }) => {
	return (
		<span>
			<IconButtonMui color='primary' onClick={onClick} disabled={disabled}>
				{children}
				<CloseIcon />
			</IconButtonMui>
		</span>
	);
};
export const SaveButton: React.FC<SaveButtonProps> = ({ onClick, children, disabled, size }) => {
	const classes = useStylesFormsButton();
	return (
		<Button
			size={size}
			className={classes.saveButton}
			variant='text'
			color='primary'
			disabled={disabled}
			startIcon={<SaveIcon />}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};
export const CheckButton: React.FC<CommonButtonProps> = ({ onClick, children, disabled }) => {
	return (
		<span>
			<IconButtonMui color='primary' onClick={onClick} disabled={disabled}>
				{children}
				<CheckIcon />
			</IconButtonMui>
		</span>
	);
};
export const CreateButton: React.FC<CommonButtonProps> = ({ onClick, children, disabled, variant, circleIcon }) => {
	return (
		<Button
			variant={variant}
			color='primary'
			startIcon={circleIcon ? <AddCircleOutlineIcon /> : <AddIcon />}
			onClick={onClick}
			disabled={disabled}
		>
			{children || 'Adicionar'}
		</Button>
	);
};

export const ExportButton: React.FC<CommonButtonProps> = ({ onClick, children, disabled }) => {
	return (
		<Button color='primary' startIcon={<GetAppIcon />} onClick={onClick} disabled={disabled}>
			{children || 'Exportar'}
		</Button>
	);
};

export const BooleanInput: React.FC<BooleanInputProps> = ({ label, value, setState, title }) => {
	const handleChange = () => {
		setState(!value);
	};

	return (
		<FormControlLabel
			control={
				<Tooltip title={title || 'Coloque algo aqui'}>
					<Switch color='primary' checked={value} onChange={handleChange} />
				</Tooltip>
			}
			label={label || 'Coloque algo aqui'}
			style={{ width: '100%' }}
		/>
	);
};

export const BoxButtonDecision: React.FC<CommonButtonProps> = ({ children, onClick, disabled }) => (
	<Box mt={1} mr={1} width='80px' height='80px' display='flex' justifyContent='center'>
		<Button variant='text' style={{ width: '100%' }} disabled={disabled} onClick={onClick}>
			{children}
		</Button>
	</Box>
);

export const TooltipIconButton: React.FC<TooltipIconButtonPros> = ({ id, key, children, title, onClick, ...rest }) => (
	<Tooltip title={title as string} id={id} key={key} itemID={`${id}-item`}>
		<span>
			<IconButtonMui onClick={onClick} {...rest}>
				{children}
			</IconButtonMui>
		</span>
	</Tooltip>
);

interface IButtonCancelV2Props extends Omit<React.ComponentProps<typeof ButtonCancelV1>, 'children'> {
	children?: React.ComponentProps<typeof ButtonCancelV1>['children'];
}

export const ButtonCancel = ButtonCancelV1 as unknown as React.FC<IButtonCancelV2Props>;

interface IBotaoSalvarV2Props extends Omit<React.ComponentProps<typeof BotaoSalvarV1>, 'children'> {
	children?: React.ComponentProps<typeof BotaoSalvarV1>['children'];
}

export const BotaoSalvar = BotaoSalvarV1 as unknown as React.FC<IBotaoSalvarV2Props>;
