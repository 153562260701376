import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { SaveButton } from 'components/common/commonComponentsTSX';
import { TypePropsModalConfirmEnvioEmail } from '../types';

const ModalConfirmEnvioDeEmail = ({ text, handleSubmitWithRedirect, ...props }: TypePropsModalConfirmEnvioEmail) => {
	const { values } = useFormState();
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [handleSave, setHandleSave] = React.useState<boolean>(true);

	React.useEffect(() => {
		if (values.titulo && values.participantes && text != '') {
			setHandleSave(false);
		} else {
			setHandleSave(true);
		}
	}, [values, text]);

	const handleClickOpen = () => {
		return setOpen(true);
	};

	const handleClose = () => {
		return setOpen(false);
	};

	const handleCreateComunicado = (sendEmail = true) => {
		if (loading) return;
		values['enviar_email'] = sendEmail;
		setLoading(true);
		handleSubmitWithRedirect(props.basePath);
	};

	return (
		<>
			<SaveButton onClick={handleClickOpen} disabled={handleSave} size='small'>
				Salvar
			</SaveButton>
			{open === true ? (
				<>
					<Dialog open={open} disableBackdropClick={true}>
						<DialogTitle id='alert-dialog-title'>{'Envio de e-mail'}</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>
								Deseja enviar este comunicado por e-mail ?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancelar</Button>
							<Button onClick={() => handleCreateComunicado(false)} color='primary'>
								Não Enviar
							</Button>
							<Button onClick={() => handleCreateComunicado(true)} color='primary' autoFocus>
								Enviar
							</Button>
						</DialogActions>
					</Dialog>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default ModalConfirmEnvioDeEmail;
