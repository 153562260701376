import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImportFornecedoresContext from './ImportFornecedoresContext';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButtonCancel } from 'components/common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import DropZone from './DropZone';

const DropZoneImportFornecedoresModal = () => {
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const { files, SetErrosNoArquivo, setFiles, setModalStep, setResultadoImport } =
		useContext(ImportFornecedoresContext);

	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [valid, setValid] = useState(false);
	const [processing, setProcessing] = useState(false);

	const validate = useCallback(() => {
		setValid(!!files.length);
	}, [files]);

	useEffect(validate, [files.length]);

	const processarArquivos = useCallback(() => {
		setProcessing(true);
		const formData = new FormData();
		formData.append('csv', Array.from(files)[0].file);
		dataProvider
			.processarArquivosRetorno('fornecedores/importar', {
				data: formData,
			})
			.then((response) => {
				setProcessing(false);
				const data = response?.data;
				if (data) {
					setResultadoImport(data?.result || []);
					SetErrosNoArquivo(data?.erros_no_arquivo || []);
				}
				setModalStep('2');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setResultadoImport([]);
				SetErrosNoArquivo([]);
				setProcessing(false);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
			});
	}, [setProcessing, files, dataProvider, setResultadoImport, SetErrosNoArquivo, notify, setModalStep]);

	return (
		<CustomDialogBodySizeEditable
			title='Importação de fornecedores'
			customActions={[
				<TooltipIconButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />,
				<TooltipIconButtonConfirm
					title='Processar Arquivos'
					id='process_remessas'
					disabled={!valid || processing}
					onClick={() => {
						setResultadoImport([]);
						SetErrosNoArquivo([]);
						processarArquivos();
					}}
				/>,
			]}
			form={{
				component: processing ? (
					<Box display='flex' justifyContent='center'>
						<CircularProgress />
					</Box>
				) : (
					<>
						<DropZone filesSelected={files} setFilesSelected={setFiles} />
					</>
				),
			}}
		/>
	);
};

export default DropZoneImportFornecedoresModal;
