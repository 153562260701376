import React, { memo, useCallback, useContext, useState, useEffect, createContext } from 'react';
import { ListBase, useDataProvider, useNotify } from 'react-admin';
import { Box, Paper, Typography, TextField, Button } from '@material-ui/core';
import { Autocomplete, TabContext, TabList, TabPanel } from '@material-ui/lab';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTheme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';

import { ArrecadacoesContext, ArrecadaçõesContextProvider } from './ArrecadacoesContext';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { FormlessInputDateReferencia } from '../../common/InputDate';
import { parseISO, format } from 'date-fns';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from '../../common/ModalContext';
import { ButtonRemove } from '../../common/buttons/ButtonRemove';
import { ButtonClose } from '../../common/buttons/ButtonClose';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ArrecadacoesBaseList } from './abas/Arrecadacoes/list';
import ProvisionamentoPanel from './abas/ProvisionamentoPanel';
import ContasReceberRemessasList from './abas/ContasReceberRemessas/list';
import { NumberFormatBRL } from '../../common/CurrencyInput';
import { ArrecadacaoUnidadeList } from './abas/ArrecadacaoUnidades/list';
import ContasReceberBoletosList from './abas/ContasReceberBoletos/list';
import { useMaisElevadoBulkActions, useHideBulkActions } from '../../common/useCustomListStyle';
import ContasBancariasContextProvider from './ContasBancariasContextProvider';
import {
	TabListComponentArrecadacao,
	TabListComponentArrecadacaoGerarRemessaEBoletos,
	TabListComponentArrecadacaoGerarEnviarBoletos,
} from './titulosParaTabs';

const FilterBox = memo(({ titulo, children, boxProps = {}, paperStyle = {} }) => (
	<Box {...boxProps}>
		<Paper
			style={{
				padding: 15,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: 'calc(100% - 30px)',
				...paperStyle,
			}}
		>
			<Typography variant='h6'>{titulo}</Typography>
			<div>{children}</div>
		</Paper>
	</Box>
));

const CondominioSelect = memo(() => {
	const {
		condominios: options,
		condominioSelecionado,
		setCondominioPorId,
		setReload,
	} = useContext(CondominiosContext);
	const { setUpdateIdent } = useContext(ArrecadacoesContext);

	useEffect(setReload, []);

	const handleCondominioChange = (_, option) => {
		if (option) {
			setCondominioPorId(option.id);
		} else {
			setUpdateIdent({});
		}
	};

	const disabled = !options?.length;
	const label = disabled ? 'Nenhum resultado encontrado' : null;

	return (
		<FilterBox titulo='Selecionar Condomínio' boxProps={{ flex: 1 }}>
			<Autocomplete
				options={options}
				renderInput={(params) => <TextField {...params} margin='dense' label={label} fullWidth />}
				disabled={disabled}
				renderOption={(option) => option?.nome}
				getOptionLabel={(option) => (option?.nome ? option.nome : condominioSelecionado?.nome) || ''}
				onChange={handleCondominioChange}
				value={condominioSelecionado}
				defaultValue={null}
				fullWidth
				disableClearable
			/>
		</FilterBox>
	);
});

const ReferenciaSelect = memo(() => {
	const { set_referencia, referencia } = useContext(ArrecadacoesContext);
	return (
		<FilterBox titulo='Selecionar Período' boxProps={{ flex: 1 }}>
			<FormlessInputDateReferencia
				source='referencia'
				onChange={(v) => v && v.toString() !== 'Invalid Date' && set_referencia(format(v, 'yyyy-MM-dd'))}
				value={referencia && parseISO(referencia).toString() !== 'Invalid Date' ? parseISO(referencia) : null}
				defaultValue={new Date().setDate(1)}
				fullWidth
			/>
		</FilterBox>
	);
});

const getVencimentoOptions = () => {
	let options = [{ id: 0, name: 'Padrão' }];
	for (let i = 1; i < 31; i++) {
		options.push({ id: i, name: String(i) });
	}
	return options;
};

const respOpcoes = [
	{ id: 'Z', name: 'Padrão' },
	{ id: 'P', name: 'Proprietário' },
	{ id: 'I', name: 'Inquilino' },
	{
		id: 'D',
		name: 'Inquilino (Ordinárias) e Proprietário (Extraordinárias)',
	},
];

const ModalRemocaoContext = createContext();

const ModalConfirmacaoRemocao = ({ handleSubmit, identificacaoSelecionada }) => {
	const { setModalValue } = useContext(ModalRemocaoContext);
	const {
		palette: { syndikosRed },
	} = useTheme();

	if (identificacaoSelecionada.usado_em_arrecadacao || identificacaoSelecionada.usado_em_recebimento) {
		return (
			<CustomDialogBody
				title='Esta identificação não pode ser removida'
				text={
					<Box display='flex' alignItems='center' gridGap='20px'>
						<Box>
							<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
						</Box>
						<Box>
							<Typography style={{ fontWeight: 'bold' }}>
								<ul>
									{
										<>
											{identificacaoSelecionada.usado_em_arrecadacao && (
												<li>Esta identificação está vinculada a ao menos uma arrecadação</li>
											)}
											{identificacaoSelecionada.usado_em_recebimento && (
												<li>
													Esta identificação está vinculado a ao menos uma conta a receber
												</li>
											)}
										</>
									}
								</ul>
							</Typography>
						</Box>
					</Box>
				}
				customActions={
					<>
						<ButtonClose
							onClick={() => {
								setModalValue({ open: false });
							}}
							size='small'
						>
							cancelar
						</ButtonClose>
						<ButtonRemove disabled={true} size='small' />
					</>
				}
			/>
		);
	} else {
		return (
			<CustomDialogBody
				Context={ModalRemocaoContext}
				title={`Tem certeza que deseja remover "${identificacaoSelecionada.nome}"?`}
				text={
					<Box display='flex' alignItems='center' gridGap='20px'>
						<Box>
							<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
						</Box>
						<Box>
							<Typography
								style={{
									fontWeight: 'bold',
									color: syndikosRed.main,
								}}
							>
								Essa ação é irreversível.
								<br />
								Todo o histórico será excluído.
							</Typography>
						</Box>
					</Box>
				}
				remove
				form={{ handleSubmit, valid: true }}
			/>
		);
	}
};

const BtnRemoverIdent = ({ remover, disabled, identificacaoSelecionada }) => {
	const { setModalValue } = useContext(ModalRemocaoContext);
	return (
		<ButtonRemove
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalConfirmacaoRemocao
							handleSubmit={remover}
							identificacaoSelecionada={identificacaoSelecionada}
						/>
					),
				}));
			}}
			disabled={disabled}
		/>
	);
};

const ModalConfiguraIdentificacao = ({ selectIdentComp, reload }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const { setModalValue } = useContext(ModalContext);
	const vencimentoOptions = getVencimentoOptions();
	const { condominioSelecionado } = useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const {
		id_identificacao_arrecadacao,
		set_id_identificacao_arrecadacao,
		identData,
		setIdentAtivasData,
		setIdentData,
		setUpdateIdent,
	} = useContext(ArrecadacoesContext);
	const [nome, setNome] = useState('');
	const [vencimento, setVencimento] = useState();
	const [responsavel, setResponsavel] = useState();
	const [mode, setMode] = useState(identData.length === 0 ? 'cadastrar' : 'visualizar');
	const [valid, setValid] = useState(false);

	const [idIdentificacaoArrecadacao, setIdIdentificacaoArrecadacao] = useState(id_identificacao_arrecadacao);
	const [identificacaoSelecionada, setIdentificacaoSelecionada] = useState(
		identData.find((i) => i?.id === id_identificacao_arrecadacao)
	);

	const handleIdentificacaoChange = (_, option) => {
		if (option) {
			setIdIdentificacaoArrecadacao(option.id);
			if (option?.situacao === 'A') set_id_identificacao_arrecadacao(option.id);
		}
	};

	const updateIdentificacaoSelecionada = useCallback(() => {
		if (identData) setIdentificacaoSelecionada(identData.find((i) => i?.id === idIdentificacaoArrecadacao));
	}, [identData, setIdentificacaoSelecionada, idIdentificacaoArrecadacao]);
	useEffect(updateIdentificacaoSelecionada, [idIdentificacaoArrecadacao, identData]);

	const validate = () => {
		setTimeout(
			() =>
				setValid(
					vencimento >= 0 &&
						responsavel &&
						nome &&
						(mode === 'cadastrar'
							? !identData.find((d) => nome === d.nome)
							: !identData.find((d) => nome === d.nome && idIdentificacaoArrecadacao !== d.id))
				),
			50
		);
	};

	useEffect(validate, [vencimento, nome, responsavel, mode]);

	const cadastrar = () => {
		dP.create('identificacao_arrecadacao', {
			data: {
				nome,
				vencimento,
				responsavel,
				id_condominio: condominioSelecionadoId,
			},
		})
			.then((response) => {
				const data = response?.data;
				if (data && data.id && data.nome && (data.vencimento || data?.vencimento === 0) && data.responsavel) {
					setIdentAtivasData((i) => [...i, data]);
					setIdentData((i) => [...i, data]);
					setIdIdentificacaoArrecadacao(data.id);
					set_id_identificacao_arrecadacao(data.id);
					refreshFields();
					reload();
				}
				setMode('visualizar');
				notify('Identificação cadastrada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const editar = () => {
		dP.update('identificacao_arrecadacao', {
			id: idIdentificacaoArrecadacao,
			data: {
				nome,
				vencimento,
				responsavel,
			},
		})
			.then(() => {
				setMode('visualizar');
				setUpdateIdent({});
				notify('Identificação alterada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const remover = () => {
		dP.delete('identificacao_arrecadacao', {
			id: idIdentificacaoArrecadacao,
		})
			.then(() => {
				setMode('visualizar');
				setIdIdentificacaoArrecadacao(identData[0].id);
				if (id_identificacao_arrecadacao === idIdentificacaoArrecadacao)
					set_id_identificacao_arrecadacao(identData[0].id);
				setUpdateIdent({});
				reload();
				refreshFields();
				notify('Identificação removida com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const inativar = () => {
		dP.update('identificacao_arrecadacao', {
			id: idIdentificacaoArrecadacao,
			data: { situacao: 'I' },
		})
			.then(() => {
				setMode('visualizar');
				set_id_identificacao_arrecadacao(identData[0].id);
				setUpdateIdent({});
				reload();
				notify('Identificação inativada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const reativar = () => {
		dP.update('identificacao_arrecadacao', {
			id: idIdentificacaoArrecadacao,
			data: { situacao: 'A' },
		})
			.then(() => {
				setMode('visualizar');
				set_id_identificacao_arrecadacao(idIdentificacaoArrecadacao);
				setUpdateIdent({});
				reload();
				notify('Identificação reativada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const refreshFields = () => {
		setTimeout(() => {
			setVencimento(null);
			setResponsavel(null);
			if (identData) {
				setVencimento(
					mode === 'cadastrar' ? 0 : identData.find((d) => d.id === idIdentificacaoArrecadacao).vencimento
				);
				setResponsavel(
					mode === 'cadastrar' ? 'Z' : identData.find((d) => d.id === idIdentificacaoArrecadacao).responsavel
				);
			}
		}, 50);
	};

	useEffect(refreshFields, [idIdentificacaoArrecadacao]);

	const btnCancelar = (
		<ButtonCancel
			onClick={() => {
				setMode('visualizar');
				refreshFields();
			}}
		/>
	);

	const acoes = () => {
		switch (mode) {
			case 'visualizar':
				return (
					<>
						{identificacaoSelecionada &&
							(identificacaoSelecionada?.situacao === 'I' ? (
								<BtnRemoverIdent
									{...{ remover }}
									disabled={identificacaoSelecionada.nome === 'Principal'}
									identificacaoSelecionada={identificacaoSelecionada}
								/>
							) : (
								<ButtonRemove
									onClick={() => {
										inativar();
									}}
									disabled={identificacaoSelecionada.nome === 'Principal'}
								>
									Inativar
								</ButtonRemove>
							))}
						{identificacaoSelecionada &&
							(identificacaoSelecionada?.situacao === 'I' ? (
								<ButtonConfirm onClick={reativar}>Reativar</ButtonConfirm>
							) : (
								<Button
									onClick={() => {
										setMode('editar');
										setNome(identData.find((d) => d.id === idIdentificacaoArrecadacao).nome);
									}}
									disabled={identificacaoSelecionada.nome === 'Principal'}
								>
									Editar
								</Button>
							))}
						<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
					</>
				);
			case 'editar':
				return (
					<>
						{btnCancelar}
						<ButtonConfirm onClick={editar} disabled={!valid}>
							Salvar
						</ButtonConfirm>
					</>
				);
			case 'cadastrar':
				return (
					<>
						{btnCancelar}
						<ButtonConfirm onClick={cadastrar} disabled={!valid}>
							Salvar
						</ButtonConfirm>
					</>
				);
			default:
				return;
		}
	};

	return (
		<ModalContextProvider Context={ModalRemocaoContext}>
			<CustomDialogBody
				title={
					<Box display='flex' justifyContent='space-between' alignItems='center'>
						<span>Configurar Identificações</span>
						<TooltipIconButton
							title='Cadastrar Identificação'
							onClick={() => {
								setNome('');
								setMode('cadastrar');
								refreshFields();
							}}
							disabled={mode !== 'visualizar'}
							size='small'
						>
							<AddCircleOutlineIcon color={mode !== 'visualizar' ? 'disabled' : 'primary'} />
						</TooltipIconButton>
					</Box>
				}
				customActions={acoes()}
				form={{
					validate,
					component: (
						<Box display='grid' gridRowGap='15px'>
							{mode === 'visualizar' ? (
								selectIdentComp(
									idIdentificacaoArrecadacao,
									identData,
									'Identificação',
									handleIdentificacaoChange
								)
							) : (
								<TextField
									margin='dense'
									fullWidth
									label='Identificação'
									defaultValue={
										mode === 'cadastrar'
											? ''
											: identData.find((d) => d.id === idIdentificacaoArrecadacao).nome
									}
									onChange={(e) => setNome(e.target.value)}
								/>
							)}
							{vencimento !== null && (
								<Autocomplete
									options={vencimentoOptions}
									renderInput={(params) => (
										<TextField {...params} margin='dense' fullWidth label='Vencimento' />
									)}
									renderOption={(option) => option.name}
									getOptionLabel={(option) => (option.name ? option.name : '')}
									onChange={(_, v) => {
										setVencimento(v.id);
									}}
									defaultValue={
										mode === 'cadastrar'
											? 0
											: vencimentoOptions.find(
													(v) =>
														v.id ===
														identData.find((d) => d.id === idIdentificacaoArrecadacao)
															.vencimento
											  )
									}
									fullWidth
									disableClearable
									disabled={mode === 'visualizar'}
								/>
							)}
							{responsavel !== null && (
								<Autocomplete
									options={respOpcoes}
									renderInput={(params) => (
										<TextField
											{...params}
											margin='dense'
											fullWidth
											label='Responsável pela cobrança'
										/>
									)}
									renderOption={(option) => option.name}
									getOptionLabel={(option) => (option.name ? option.name : '')}
									onChange={(_, v) => {
										setResponsavel(v.id);
									}}
									defaultValue={
										mode === 'cadastrar'
											? 'Z'
											: respOpcoes.find(
													(v) =>
														v.id ===
														identData.find((d) => d.id === idIdentificacaoArrecadacao)
															.responsavel
											  )
									}
									fullWidth
									disableClearable
									disabled={mode === 'visualizar'}
								/>
							)}
						</Box>
					),
				}}
			/>
			<CustomDialog Context={ModalRemocaoContext} />
		</ModalContextProvider>
	);
};

const IdentSelect = memo(() => {
	const { setModalValue } = useContext(ModalContext);
	const { id_identificacao_arrecadacao, set_id_identificacao_arrecadacao, identAtivasData } =
		useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const [renderComp, setRenderComp] = useState(true);

	const handleIdentificacaoChange = (_, option) => {
		if (option) {
			set_id_identificacao_arrecadacao(option.id);
		}
	};

	const reload = () => {
		setRenderComp(false);
		setTimeout(() => setRenderComp(true), 200);
	};

	const selectIdentComp = (id_identificacao_arrecadacao, identData, label, onChange = handleIdentificacaoChange) =>
		identData && identData.length > 0 && renderComp ? (
			<Autocomplete
				options={identData}
				renderInput={(params) => <TextField {...params} margin='dense' fullWidth label={label} />}
				renderOption={(option) => option.nome}
				getOptionLabel={(option) =>
					option && option.nome
						? option.nome
						: id_identificacao_arrecadacao && identData.find((d) => d.id === id_identificacao_arrecadacao)
						? identData.find((d) => d.id === id_identificacao_arrecadacao).nome
						: reload()
				}
				onChange={onChange}
				getOptionSelected={(o, v) => o.id === v}
				value={id_identificacao_arrecadacao}
				fullWidth
				disableClearable
				style={{ flex: 2 }}
			/>
		) : (
			<TextField
				margin='dense'
				fullWidth
				style={{ flex: 2 }}
				disabled
				label={renderComp ? 'Nenhum resultado encontrado' : ''}
			/>
		);

	const infoIdent =
		id_identificacao_arrecadacao &&
		identAtivasData &&
		identAtivasData.length > 0 &&
		identAtivasData.find((d) => d.id === id_identificacao_arrecadacao) &&
		renderComp ? (
			<Typography style={{ flex: 1, marginLeft: 5 }} variant='subtitle2'>
				Vencimento:{' '}
				<strong>
					{identAtivasData.find((d) => d.id === id_identificacao_arrecadacao)?.vencimento || 'Padrão'}
				</strong>
			</Typography>
		) : (
			<Box flex={1} />
		);

	return (
		<FilterBox
			titulo={
				<Box display='flex' justifyContent='space-between'>
					<span>Selecionar Identificação</span>
					<TooltipIconButton
						title='Configurar'
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <ModalConfiguraIdentificacao {...{ selectIdentComp, reload }} />,
							}));
						}}
						disabled={!(identAtivasData && identAtivasData.length) || !condominioSelecionado}
						size='small'
					>
						<SettingsIcon />
					</TooltipIconButton>
				</Box>
			}
		>
			<Box display='flex' alignItems='center' gridGap='10px'>
				{[selectIdentComp(id_identificacao_arrecadacao, identAtivasData), infoIdent]}
				<Box display='flex' justifyContent='flex-end' alignItems='center' mb='4px'></Box>
			</Box>
		</FilterBox>
	);
});

const CurrencyFormat = ({ value }) => (
	<NumberFormatBRL
		displayType='text'
		value={value}
		fixedDecimalScale
		onChange={() => {}}
		decimalScale={2}
		allowNegative={true}
	/>
);

const BoxInfo = ({ title, number, currency = true, color = 'rgba(33, 150, 243, 0.1)' }) => {
	return (
		<Box mb='10px'>
			<Paper
				style={{
					padding: '5px 10px',
					backgroundColor: color,
				}}
			>
				<Typography variant='overline' color='textSecondary' style={{ fontWeight: 'bold' }}>
					{title}
				</Typography>
				<Typography
					variant='h5'
					color='textSecondary'
					style={{
						fontWeight: 'bold',
						fontSize: '15pt',
					}}
				>
					{currency ? (
						<div style={{ textAlign: 'right' }}>
							R$ <CurrencyFormat value={number} />
						</div>
					) : (
						number || 0
					)}
				</Typography>
			</Paper>
		</Box>
	);
};

const ListComponent = () => {
	const theme = useTheme();
	const [tabValue, setTabValue] = useState('1');
	const { id_identificacao_arrecadacao, referencia, valores, contaBDestinoId } = useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const handleChangeTab = (_, newValue) => {
		setTabValue(newValue);
	};
	const useMaisElevadoBulkActionsStyle = useMaisElevadoBulkActions();
	const styleList = useHideBulkActions();
	return (
		<Box>
			<ModalContextProvider>
				<Box
					display='grid'
					justifyContent='space-between'
					gridGap='15px'
					mt='15px'
					mb='15px'
					position='relative'
					gridTemplateColumns='1fr 1fr 1fr'
				>
					<CondominioSelect />
					<ReferenciaSelect />
					<IdentSelect />
				</Box>
				<CustomDialog />
			</ModalContextProvider>
			<Box display='grid' gridTemplateColumns='repeat(7, 1fr)' gridGap='15px'>
				<BoxInfo title='Total de contas' number={valores?.totalContas} currency={false} />
				<BoxInfo title='Ordinárias' number={valores.ord} />
				<BoxInfo title='Descontos' number={valores.desconto} />
				<BoxInfo title='Leituras' number={valores.leituras} />
				<BoxInfo title='Extraordinárias' number={valores.ext} />
				<BoxInfo title='Fundos' number={valores.fundos} />
				<BoxInfo title='Total' number={valores.total} />
			</Box>
			<TabContext value={tabValue} style={{ width: '100%' }}>
				<TabList onChange={handleChangeTab} style={{ marginTop: '15px', color: theme.font[100] }}>
					<TabListComponentArrecadacao
						label={
							<span>
								Adicionar Contas
								<br />
								para rateio
							</span>
						}
						value='1'
					/>
					<TabListComponentArrecadacao
						label={
							<span>
								Conferir totais
								<br />
								por unidade
							</span>
						}
						value='2'
					/>
					<TabListComponentArrecadacao
						label={
							<span>
								Provisionar
								<br />
								recebimentos
							</span>
						}
						value='3'
					/>
					<TabListComponentArrecadacaoGerarRemessaEBoletos value='4' />
					<TabListComponentArrecadacaoGerarEnviarBoletos value='5' />
				</TabList>
				<TabPanel value='1' style={{ padding: 0 }}>
					<ArrecadacoesBaseList
						basePath='/arrecadacao'
						resource='arrecadacao'
						filter={{
							id_identificacao_arrecadacao,
							referencia,
							id_condominio: condominioSelecionadoId,
						}}
						className={useMaisElevadoBulkActionsStyle.root}
						title='Arrecadações'
					/>
				</TabPanel>
				<TabPanel value='2' style={{ padding: 0 }}>
					<ArrecadacaoUnidadeList
						basePath='/conferencia_arrecadacao'
						resource='conferencia_arrecadacao'
						filter={{
							identificacao_id: id_identificacao_arrecadacao,
							referencia,
							condominio_id: condominioSelecionadoId,
							get_dados_tabela_conferencia: true,
						}}
						title='Arrecadações'
					/>
				</TabPanel>
				<TabPanel value='3' style={{ padding: 0 }}>
					<ProvisionamentoPanel title='Arrecadações' />
				</TabPanel>
				<TabPanel value='4' style={{ padding: 0 }}>
					<ContasReceberRemessasList className={styleList.root} title='Arrecadações' />
				</TabPanel>
				<TabPanel value='5' style={{ padding: 0 }}>
					<ContasReceberBoletosList
						filter={{
							condominio_id: condominioSelecionadoId,
							identificacao_id: id_identificacao_arrecadacao,
							referencia__year: referencia?.split('-')[0] || '',
							referencia__month: referencia?.split('-')[1] || '',
							conta_b_destino_id: contaBDestinoId,
						}}
						className={styleList.root}
						title='Arrecadações'
					/>
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export const ArrecadacoesList = (props) => {
	return (
		<ListBase {...props}>
			<ContasBancariasContextProvider>
				<ArrecadaçõesContextProvider>
					<ListComponent />
				</ArrecadaçõesContextProvider>
			</ContasBancariasContextProvider>
		</ListBase>
	);
};
