import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { Box, Button, Checkbox, FormLabel, FormControl, FormGroup, FormControlLabel } from '@material-ui/core';
import { format } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import MultiSelect from 'components/common/Selects';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import RelatorioToolbar from '../RelatorioToolbar';
import { RelatorioInputDate, TODAY } from '../dateUtils';
import {
	CustomDataProvider,
	IBotaoToolbarProps,
	IErrorPromise,
	IRelatorioProps,
	IDataBase,
	IOpcoesListaAssinaturas,
	IOpcoesImpressaoListaAssinaturas,
	ITituloRelatorio,
} from './types';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';
import TituloModalField from 'components/common/TituloModalField';

const BotaoToolbar: React.FC<IBotaoToolbarProps> = ({ handleClick, label, style }) => (
	<Button color='primary' variant='outlined' style={style} onClick={() => handleClick(label)}>
		{label}
	</Button>
);

const OpcoesImpressaoListaAssinaturas: React.FC<IOpcoesImpressaoListaAssinaturas> = ({
	handleChange,
	proprietario,
	inquilino,
	ambos,
	responsavelPelaCobranca,
	imprmirAssinatura,
}) => {
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row>
				<Box>
					<FormControlLabel
						control={
							<Checkbox
								checked={proprietario}
								onChange={(e, checked) => {
									handleChange(checked, 'proprietario');
									handleChange(false, 'inquilino');
									handleChange(false, 'ambos');
									handleChange(false, 'responsavelPelaCobranca');
								}}
								name='proprietario'
							/>
						}
						label='Proprietário'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={inquilino}
								onChange={(e, checked) => {
									handleChange(checked, 'inquilino');
									handleChange(false, 'proprietario');
									handleChange(false, 'ambos');
									handleChange(false, 'responsavelPelaCobranca');
								}}
								name='inquilino'
							/>
						}
						label='Inquilino'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={ambos}
								onChange={(e, checked) => {
									handleChange(checked, 'ambos');
									handleChange(false, 'proprietario');
									handleChange(false, 'inquilino');
									handleChange(false, 'responsavelPelaCobranca');
								}}
								name='ambos'
							/>
						}
						label='Ambos'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={responsavelPelaCobranca}
								onChange={(e, checked) => {
									handleChange(checked, 'responsavelPelaCobranca');
									handleChange(false, 'proprietario');
									handleChange(false, 'ambos');
									handleChange(false, 'inquilino');
								}}
								name='responsavelPelaCobranca'
							/>
						}
						label='Responsável pela cobrança'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprmirAssinatura}
								onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
								name='imprimir_assinatura'
							/>
						}
						label='Imprimir assinaturas'
					/>
				</Box>
			</FormGroup>
		</FormControl>
	);
};

const DataBase: React.FC<IDataBase> = ({ dataBase, setDataBase, imprimirDataBase, label }) => {
	return (
		<Box marginTop='0.5em' minWidth='200px'>
			<RelatorioInputDate
				disabled={!imprimirDataBase}
				label={label}
				handleChange={(_, newValue) => setDataBase(newValue)}
				value={dataBase}
				fullWidth
			/>
		</Box>
	);
};

const ListaAssinaturasDivisor: React.FC<IRelatorioProps> = (props) => {
	const dataProvider = useDataProvider() as CustomDataProvider;
	const notify = useNotify();
	const [unidades, setUnidades] = useState([]);
	const { options: optionsUnidades } = useRequestOptionsUnidades();
	const {
		opcoesImpressao: {
			proprietario,
			inquilino,
			ambos,
			responsavelPelaCobranca,
			imprimir_assinatura,
			data_base_checkbox,
			titulo: tituloRelatorio,
			data_base_inadimplente_checkbox,
		},
		handleChangeOpcoesImpressao,
		loading,
		setOpcoesImpressao,
	} = React.useContext<IOpcoesListaAssinaturas>(CheckboxRelatorioContext);

	const [dataBaseEvento, setDataBaseEvento] = useState(format(TODAY, 'dd/MM/yyyy'));
	const [dataBaseInadimplente, setDataBaseInadimplente] = useState(format(TODAY, 'dd/MM/yyyy'));

	const setTituloRelatorio = React.useCallback(
		(value: null | ITituloRelatorio | React.SetStateAction<null | ITituloRelatorio>) => {
			setOpcoesImpressao((previous) => ({
				...previous,
				titulo: (typeof value === 'function' ? value(previous.titulo) : value) ?? null,
			}));
		},
		[setOpcoesImpressao]
	);

	React.useEffect(() => {
		if (unidades.length) {
			setUnidades([]);
		}
	}, [props?.idCondominio]);

	const requestData = {
		id_condominio: props.idCondominio,
		unidades: unidades.map((v: { id: number }) => v.id),
		proprietario: proprietario,
		inquilino: inquilino,
		ambos: ambos,
		responsavelPelaCobranca: responsavelPelaCobranca,
		imprimir_assinatura: imprimir_assinatura,
		data_base_checkbox: data_base_checkbox,
		data_base: data_base_checkbox ? dataBaseEvento : null,
		nome_relatorio: props.tipoRelatorio.action,
		titulo: tituloRelatorio,
		data_base_inadimplente_checkbox: data_base_inadimplente_checkbox,
		data_base_inadimplente: data_base_inadimplente_checkbox ? dataBaseInadimplente : null,
	};
	const handleClick = (e: any, tipoSaida: string) => {
		if (data_base_checkbox && !(dataBaseEvento && dataBaseEvento.match(/\d{2}\/\d{2}\/\d{4}/)))
			return notify('Data Base inválida', 'warning');
		if (
			data_base_inadimplente_checkbox &&
			!(dataBaseInadimplente && dataBaseInadimplente.match(/\d{2}\/\d{2}\/\d{4}/))
		)
			return notify('Data Base inválida', 'warning');
		dataProvider
			.gerarRelatorio('relatorios', {
				...requestData,
				tipo_saida: tipoSaida,
				tipoRelatorio: props.tipoRelatorio.action,
			})
			.then(({ data }: { data: { success: string } }) =>
				notify(tipoSaida === 'enviar' ? data?.success : 'Relatório gerado com sucesso!')
			)
			.catch((e: IErrorPromise) => {
				if (e?.response?.data instanceof Blob) {
					e.response.data.text().then((text) => {
						const data_obj = JSON.parse(text) as object;
						notify(
							Object.values(data_obj || {})[0] || ['Erro inesperado, tente recarregar a página'],
							'warning'
						);
					});
				} else {
					notify(
						Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
						'warning'
					);
				}
				if ([400, 401, 403].includes(e?.response?.status)) return Promise.reject(e);
			});
	};

	return (
		<BoxDivisor titulo={props.tipoRelatorio.name}>
			<Box flex={1} ml='1em'>
				<OpcoesImpressaoListaAssinaturas
					handleChange={handleChangeOpcoesImpressao}
					proprietario={proprietario}
					inquilino={inquilino}
					ambos={ambos}
					responsavelPelaCobranca={responsavelPelaCobranca}
					imprmirAssinatura={imprimir_assinatura}
				/>
			</Box>
			<Box paddingX={'15px'}>
				<TituloModalField
					label='Editar Título'
					variant='outlined'
					fullWidth
					setTituloRelatorio={setTituloRelatorio}
					allowEmpty={true}
					tituloRelatorio={tituloRelatorio}
					loading={loading}
				/>
			</Box>
			<Box paddingX={'15px'}>
				<MultiSelect
					variant='formatsUnidades'
					options={optionsUnidades}
					value={unidades}
					setValue={setUnidades}
					size='small'
				/>
			</Box>
			<Box display='flex' width='80%' mb='1em' paddingX={'15px'}>
				<Box width={'auto'}>
					<FormControlLabel
						control={
							<Checkbox
								checked={data_base_checkbox}
								onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'data_base_checkbox')}
								name='data_base_checkbox'
							/>
						}
						label='Data do evento'
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<DataBase
						label='Data Evento'
						imprimirDataBase={data_base_checkbox}
						dataBase={dataBaseEvento}
						setDataBase={setDataBaseEvento}
					/>
				</Box>
				<Box width={'auto'}>
					<FormControlLabel
						control={
							<Checkbox
								checked={data_base_inadimplente_checkbox}
								onChange={(e, checked) =>
									handleChangeOpcoesImpressao(checked, 'data_base_inadimplente_checkbox')
								}
								name='data_base_inadimplente_checkbox'
							/>
						}
						label='Não imprimir inadimplente'
					/>
				</Box>
				<Box flex={1}>
					<DataBase
						label='Data Base'
						imprimirDataBase={data_base_inadimplente_checkbox}
						dataBase={dataBaseInadimplente}
						setDataBase={setDataBaseInadimplente}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar
					data={requestData}
					tipoRelatorio={props.tipoRelatorio}
					buttons={[
						<BotaoToolbar
							handleClick={(e) => handleClick(e, 'visualizar')}
							label='Visualizar'
							style={{ marginRight: '1em' }}
						/>,
						<BotaoToolbar
							handleClick={(e) => handleClick(e, 'pdf')}
							label='Baixar PDF'
							style={{ marginRight: '1em' }}
						/>,
					]}
				/>
			</Box>
		</BoxDivisor>
	);
};

export default ListaAssinaturasDivisor;
