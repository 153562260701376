import React, { useContext, useEffect } from 'react';

import { ResourceContextProvider, List, Pagination, useListContext } from 'react-admin';

import { format, parseISO } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

import { CurrencyField } from '../../common/CurrencyInput';
import { TabelaRowContext } from '../../common/Tabela';

const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
}));

export const ContaReceberNumberField = ({ record = {}, source, ...props }) => (
	<CurrencyField
		{...props}
		prefix='R$ '
		minimumFractionDigits={2}
		value={record[source]}
		id={`${source}${record.id}`}
	/>
);

export const DateField = ({ record = {}, source }) => {
	let formatada = '';
	try {
		formatada = format(parseISO(record[source]), 'dd/MM/yyyy');
	} catch (e) {}
	return <span>{formatada}</span>;
};

export const TipoResponsavelField = ({ record, listControllerProps }) => {
	const { data } = useListContext(listControllerProps);
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);

	const update = () => {
		if (data && data[rowRecord.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	useEffect(update, [data]);

	switch (record?.tipo_responsavel) {
		case 'P':
			return <span>Proprietário</span>;
		case 'I':
			return <span>Inquilino</span>;
		default:
			return <span></span>;
	}
};

export const ResponsavelField = ({ record = {} }) => {
	const classes = useStyles();
	return record?.pagador_id ? (
		<span>{record.pagador_nome}</span>
	) : (
		<span className={classes.warningColor}>Nenhum Responsável Registrado Nessa Data</span>
	);
};

const ContasReceberArrecadacoesList = ({ BulkActions, Table, labelRowsPerPage = '', resource, ...props }) => (
	<ResourceContextProvider resource={resource}>
		<List
			actions={false}
			{...props}
			basePath={`/${resource}`}
			resource={resource}
			bulkActionButtons={BulkActions}
			pagination={
				<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage={labelRowsPerPage} />
			}
			perPage={100}
			empty={false}
			filters={undefined}
		>
			{Table}
		</List>
	</ResourceContextProvider>
);

export default ContasReceberArrecadacoesList;
