import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	Paper,
	Checkbox,
	Typography,
} from '@material-ui/core';

import { CurrencyField } from 'components/common/CurrencyInput';

import { ModalSelecionaRetencaoImpostoProps } from '../types';

const ModalSelecionaRetencaoImposto: React.FC<ModalSelecionaRetencaoImpostoProps> = ({
	periodo,
	tipo,
	setMinWidthModel,
	impostos,
	setImpostosRetencao,
}) => {
	const dataInicial = periodo.startDate;
	const dataFinal = periodo.endDate;
	const [impostosSelecionados, setImpostosSelecionados] = useState<Array<{ id: number }>>([]);
	const [selectAll, setSelectAll] = useState(false);

	useEffect(() => {
		setMinWidthModel('90vw');
	}, [tipo]);

	useEffect(() => {
		const allSelected = impostos.length > 0 && impostosSelecionados.length === impostos.length;
		setSelectAll(allSelected);
	}, [impostosSelecionados]);

	useEffect(() => {
		setImpostosRetencao(impostosSelecionados);
	}, [impostosSelecionados]);

	const handleCheckboxChange = (imposto: { id: number }) => {
		setImpostosSelecionados((prevSelectedItems: Array<{ id: number }>) => {
			const isSelected = prevSelectedItems.find((item) => item.id === imposto.id);
			if (isSelected) {
				return prevSelectedItems.filter((item) => item.id !== imposto.id);
			} else {
				const impostoComTipo = { ...imposto, tipo };
				return [...prevSelectedItems, impostoComTipo];
			}
		});
	};

	const handleSelectAllChange = () => {
		if (!selectAll) {
			const impostosComTipo = impostos.map((imposto) => ({
				...imposto,
				tipo,
			}));
			setImpostosSelecionados(impostosComTipo);
		} else {
			setImpostosSelecionados([]);
		}
		setSelectAll(!selectAll);
	};

	const isSelected = (id: number) => impostosSelecionados.some((item: { id: number }) => item.id === id);

	const formatNumeroParcela = (numeroParcela: number) => {
		return numeroParcela.toString().padStart(3, '0');
	};

	return (
		<>
			<Typography variant='h6' style={{ fontWeight: 'bold' }}>
				Detalhes dos lançamentos de impostos
			</Typography>
			<Typography style={{ marginTop: '20px' }}>Tipo: {tipo}</Typography>
			<Typography>
				Período: {dataInicial.toLocaleDateString()} até {dataFinal.toLocaleDateString()}
			</Typography>
			<Typography>Total de impostos: {impostos.length}</Typography>
			<TableContainer component={Paper}>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell>
								<Checkbox checked={selectAll} onChange={handleSelectAllChange} />
							</TableCell>
							<TableCell>Condomínio</TableCell>
							<TableCell>Fornecedor</TableCell>
							<TableCell>Nota fiscal</TableCell>
							<TableCell>Data de {tipo}</TableCell>
							<TableCell>Nº Parcela</TableCell>
							<TableCell>Imposto</TableCell>
							<TableCell>Alíquota</TableCell>
							<TableCell align='right'>Valor do Imposto</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{impostos.map((row) => (
							<TableRow key={row.id}>
								<TableCell>
									<Checkbox checked={isSelected(row.id)} onChange={() => handleCheckboxChange(row)} />
								</TableCell>
								<TableCell style={{ textTransform: 'none' }}>{row.condominio}</TableCell>
								<TableCell style={{ textTransform: 'none' }}>{row.fornecedor}</TableCell>
								<TableCell style={{ textTransform: 'none' }}>{row.nota_fiscal}</TableCell>
								<TableCell style={{ textTransform: 'none' }}>
									{format(parseISO(row.data), 'dd/MM/yyyy')}
								</TableCell>
								<TableCell style={{ textTransform: 'none' }}>
									{formatNumeroParcela(row.numero_parcela)}/
									{formatNumeroParcela(row.quantidade_parcelas)}
								</TableCell>
								<TableCell style={{ textTransform: 'none' }}>{row.imposto_nome}</TableCell>
								<TableCell style={{ textTransform: 'none' }}>{row.aliquota} %</TableCell>
								<TableCell align='right'>
									<CurrencyField
										prefix='R$ '
										minimumFractionDigits={2}
										value={parseFloat(row.valor).toFixed(2)}
										id={'valor'}
										name={undefined}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default ModalSelecionaRetencaoImposto;
