import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormGroup, Checkbox } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';

const BoxConfigResumoRateio = () => {
	const {
		opcoesImpressao: { imprimir_resumo_rateio },
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	return (
		<>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_resumo_rateio}
							name='imprimir_resumo_rateio'
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_resumo_rateio')}
						/>
					}
					label='Imprimir resumo do rateio'
				/>
			</FormGroup>
		</>
	);
};

export default BoxConfigResumoRateio;
