import React, { useContext } from 'react';

import { Field, useForm } from 'react-final-form';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import { filterOptions } from '../utils/formaters_parses';
import { createGetPropsValidas } from '../utils/functions';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const propNamesField = ['name', 'id'];

const getPropsValidasField = createGetPropsValidas(propNamesField);

const propNamesAutocomplete = [
	'autoHighlight',
	'clearText',
	'disableClearable',
	'disableCloseOnSelect',
	'filterOptions',
	'getOptionLabel',
	'getOptionSelected',
	'groupBy',
	'multiple',
	'noOptionsText',
	'renderInput',
	'renderOption',
	'translateChoice',
];

const getPropsValidasAutocomplete = createGetPropsValidas(propNamesAutocomplete);

const propNamesTextField = ['disabled', 'fullWidth', 'label', 'margin', 'variant'];

const getPropsValidasTextField = createGetPropsValidas(propNamesTextField);

const EditaContaBancariaAutocomplete = ({
	source,
	helperText: standardHelperText = '',
	disabled: standardDisabled = false,
	onChange: standardOnChange,
	options: standardOptions,
	...standardProps
}) => {
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { options, props } = React.useContext(DadosPorTipoIntegracaoContext);
	const { disabled = false, helperText = false, onChange, ...sourceProps } = props[source] || {};
	const form = useForm();
	const { dadosAdministradora } = useContext(EditaContaBancariaContext);
	return (
		<Field {...getPropsValidasField({ ...standardProps, ...sourceProps })}>
			{({ input, meta }) => {
				return (
					<Autocomplete
						disabled={isInativo || disabled || standardDisabled}
						options={options[source]?.choices || standardOptions || []}
						value={input.value}
						name={input.name}
						onChange={(e, value) => {
							if (!(standardOnChange || onChange)) {
								if (value.text === 'Administradora') {
									const { nosso_numero, sequencial_arquivo } = dadosAdministradora;
									form.change('nosso_numero', nosso_numero != null ? nosso_numero : 0);
									form.change(
										'sequencial_arquivo',
										sequencial_arquivo != null ? sequencial_arquivo : 0
									);
								}
								input.onChange(value);
							}
							if (standardOnChange) standardOnChange(e, value, input);
							if (onChange) onChange(e, value, input);
						}}
						disableClearable
						filterOptions={filterOptions}
						renderOption={(option) => option?.text || ''}
						getOptionLabel={(option) => option?.text || ''}
						noOptionsText='Nenhum resultado'
						getOptionSelected={(option, newValue) => {
							return newValue.id === option.id;
						}}
						renderInput={({ options, ...params }) => (
							<TextField
								{...params}
								label='Tipo de integração'
								variant='outlined'
								margin='dense'
								{...getPropsValidasTextField({ ...standardProps, ...sourceProps })}
								error={!!meta.error}
								helperText={
									(!!meta && (meta.error ? meta.error : false)) ||
									helperText ||
									standardHelperText ||
									''
								}
								fullWidth
							/>
						)}
						{...getPropsValidasAutocomplete({ ...standardProps, ...sourceProps })}
					/>
				);
			}}
		</Field>
	);
};

export default EditaContaBancariaAutocomplete;
