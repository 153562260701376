import { Paper } from '@material-ui/core';
import { styled } from '@material-ui/core';

export const ReservasWrapper = styled('div')({
	'min-height': '200px',

	display: 'grid',
	'grid-template-columns': '1fr 1fr 1fr',
	'column-gap': '5px',
	'grid-template-rows': 'auto',
	'row-gap': '15px',
});

export const StyledCard = styled(Paper)({
	'align-self': 'baseline',

	display: 'flex',
	'flex-direction': 'column',
	gap: '5px',
	padding: '1rem',
	width: '25rem',
	'min-height': '16.7rem',

	'& > button': {
		'border-radius': '0px',
	},

	'& > div:last-child > ul': {
		marginTop: 0,
	},

	'&:hover': {
		filter: 'brightness(95%)',
	},

	'& > div > span': {
		display: 'flex',
		gap: '10px',
		'align-items': 'center',
	},
});
