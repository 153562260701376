import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';

import { useGetList } from 'react-admin';

import ImportUnidadesContext from './ImportUnidadesContext';
import DropZoneImportUnidadesModal from './DropZoneImportUnidadesModal';
import ResultadosImportUnidadesModal from './ResultadosImportUnidadesModal';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

const minWidthDosModais = {
	OpcoesImportacao: '95vw',
};

const ImportUnidadesContextProvider = () => {
	const [opcoesModal] = useState('OpcoesImportacao');
	const { setMinWidthModel } = useContext(ModalSizeEditableContext);
	const updateMinWidhtModal = () => {
		setMinWidthModel(minWidthDosModais[opcoesModal]);
	};
	useEffect(updateMinWidhtModal, [opcoesModal]);

	// Configurações e dados gerais
	const [condominios, setCondominios] = useState([]);
	const [condominio, setCondominio] = useState(null);
	const [gruposLista, setGruposLista] = useState([]);
	const [grupo, setGrupo] = useState(null);
	const [dataEntradaResponsaveis, setDataEntradaResponsaveis] = useState(null);

	// Dados do import
	const [files, setFiles] = useState([]);
	const [resultadoImport, setResultadoImport] = useState([]);
	const [errosNoArquivo, SetErrosNoArquivo] = useState([]);

	const {
		data: condominiosData,
		ids: condominiosIds,
		loading: condominiosLoading,
	} = useGetList('condominios', { perPage: 10000, page: 1 }, { field: 'nome', order: 'ASC' }, { situacao: 'A' });

	const {
		data: gruposData,
		ids: gruposIds,
		loading: gruposLoading,
	} = useGetList(
		'grupo_unidade_condominio',
		{ perPage: 10000, page: 1 },
		{ field: 'nome', order: 'ASC' },
		{ id_condominio: condominio?.id }
	);

	const updateCondominios = useCallback(() => {
		if (!condominiosLoading) {
			const options = (condominiosIds || []).filter((id) => !!id).map((id) => condominiosData[id]);
			setCondominios(options);
			if (!condominio || !condominiosIds.includes(parseInt(condominio?.id)));
		}
	}, [condominiosLoading, condominiosData, condominiosIds, setCondominios, condominio, setCondominio]);
	useEffect(updateCondominios, [condominiosLoading]);

	const changeCondominio = useCallback(() => {
		setGrupo(null);
		setGruposLista([]);
	}, [setGrupo, setGruposLista]);
	useEffect(changeCondominio, [condominio]);

	const updateGrupos = useCallback(() => {
		if (!gruposLoading) {
			setGruposLista((gruposIds || []).map((id) => gruposData?.[id]).filter((g) => !!g));
		}
	}, [gruposData, gruposIds, setGruposLista, gruposLoading]);
	useEffect(updateGrupos, [gruposLoading]);

	const [modalStep, setModalStep] = useState('1');
	const [stepComponents] = useState({
		1: <DropZoneImportUnidadesModal />,
		2: <ResultadosImportUnidadesModal />,
	});

	const providerValue = useMemo(
		() => ({
			condominio,
			condominios,
			dataEntradaResponsaveis,
			files,
			grupo,
			gruposLista,
			setCondominio,
			setDataEntradaResponsaveis,
			setFiles,
			setGrupo,
			setModalStep,
			resultadoImport,
			setResultadoImport,
			errosNoArquivo,
			SetErrosNoArquivo,
		}),
		[
			condominio,
			condominios,
			dataEntradaResponsaveis,
			files,
			grupo,
			gruposLista,
			setCondominio,
			setDataEntradaResponsaveis,
			setFiles,
			setGrupo,
			setModalStep,
			resultadoImport,
			setResultadoImport,
			errosNoArquivo,
			SetErrosNoArquivo,
		]
	);

	return (
		<ImportUnidadesContext.Provider value={providerValue}>
			{stepComponents[modalStep]}
		</ImportUnidadesContext.Provider>
	);
};

export default ImportUnidadesContextProvider;
