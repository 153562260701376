import React from 'react';

import { isMatch } from 'date-fns';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import useTratarResposta from './useTratarResposta';
import useBaixarRemessas from './useBaixarRemessas';

export const useGerarBoletosSemRelatorios = ({
	condominioSelecionadoId,
	idsRegistrosSelecionados,
	referencia,
	id_identificacao_arrecadacao,
	contaBDestinoId,
	boletoConfigIsValid,
	setValid,
	ButtonGerarRemessas,
}) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	const tratarResposta = useTratarResposta({
		setModalValue,
		ButtonGerarRemessas,
	});

	const baixarRemessas = useBaixarRemessas({
		idsRegistrosSelecionados,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		onSuccessCallback: tratarResposta,
	});

	React.useEffect(() => {
		setValid(
			idsRegistrosSelecionados &&
				idsRegistrosSelecionados.length &&
				id_identificacao_arrecadacao &&
				contaBDestinoId &&
				condominioSelecionadoId &&
				referencia &&
				isMatch(referencia, 'yyyy-MM-dd') &&
				boletoConfigIsValid
		);
	}, [
		setValid,
		idsRegistrosSelecionados,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		referencia,
		boletoConfigIsValid,
	]);

	return baixarRemessas;
};

export default useGerarBoletosSemRelatorios;
