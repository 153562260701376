import { defaultFormatarValuesAgencia, defaultFormatarValuesContaCorrente } from '../../../utils/functions';

// Funções e variáveis para formatar os dados para o payload do request,
// na hora de salvar as alterações no banco de dados

// Valores Fixos
const formataPayloadValuesFixos = ({ conta_condominio }) => ({
	id: conta_condominio.id,
	id_condominio: conta_condominio.id_condominio,
	situacao: conta_condominio.situacao,
	tipo_conta: conta_condominio.tipo_conta,
	tipo_conta_bancaria: conta_condominio.tipo_conta_bancaria,
});

const formataPayloadValuesIdContaBancaria = ({ conta_condominio }) => ({ id_conta_bancaria: conta_condominio.id });

// Valores da Tab Informação
const formataPayloadValuesTabInformacao = ({
	values: {
		nome_conta,
		apelido_conta_bancaria,
		data_saldo_inicial,
		saldo_inicial,
		imprimir_saldo_relatorios,
		id_inst_financeira,
	},
}) => ({
	nome_conta,
	apelido_conta_bancaria,
	data_saldo_inicial,
	saldo_inicial,
	imprimir_saldo_relatorios,
	id_inst_financeira: id_inst_financeira?.id,
});

const formataPayloadValuesTabPagarBoletosViaSistema = ({
	values: { tipo_integracao_pagamento_via_sistema, pagamento_via_sistema },
}) => ({
	tipo_integracao_pagamento_via_sistema: tipo_integracao_pagamento_via_sistema.id,
	pagamento_via_sistema,
});

// Valores da Tab Emissão de Boletos
const formataPayloadValuesBeneficiario = ({
	values: {
		beneficiario,
		beneficiario_nome,
		beneficiario_tipo_documento,
		beneficiario_documento,
		beneficiario_cep,
		beneficiario_tipo_logradouro,
		beneficiario_endereco,
		beneficiario_numero,
		beneficiario_bairro,
		beneficiario_cidade,
		beneficiario_uf,
		beneficiario_complemento_endereco,
	},
}) => ({
	beneficiario: beneficiario?.id,
	beneficiario_nome,
	beneficiario_tipo_documento,
	beneficiario_documento,
	beneficiario_cep,
	beneficiario_tipo_logradouro,
	beneficiario_endereco,
	beneficiario_numero: beneficiario_numero || null,
	beneficiario_bairro,
	beneficiario_cidade,
	beneficiario_uf,
	beneficiario_complemento_endereco,
});

const formataPayloadValuesTabEmissaoBoletos = ({ values: { emitir_boletos, tipo_integracao } }) => ({
	emitir_boletos,
	tipo_integracao: tipo_integracao?.id,
});

// Valores da Tab Instruções de Cobrança Remessa
const formataPayloadValuesInstrucoesComTipoValorDias = ({ values, field, valorIsensao }) => {
	const valor_tipo = values[`${field}_tipo`]?.id ?? null;
	return {
		[`${field}_tipo`]: valor_tipo ?? undefined,
		[`${field}_valor`]: valor_tipo !== valorIsensao ? values[`${field}_valor`] : undefined,
		[`${field}_dias`]: valor_tipo !== valorIsensao ? values[`${field}_dias`] : undefined,
	};
};

const formataPayloadValuesInstrucoesComTipoDias = ({ values, field, valorIsensao }) => {
	const valor_tipo = values[`${field}_tipo`]?.id ?? null;
	return {
		[`${field}_tipo`]: valor_tipo ?? undefined,
		[`${field}_dias`]: valor_tipo && valor_tipo !== valorIsensao ? values[`${field}_dias`] : undefined,
	};
};

const formataPayloadValuesInstrucoesComTipoValor = ({ values, field, valorIsensao }) => {
	const valor_tipo = values[`${field}_tipo`]?.id ?? null;
	return {
		[`${field}_tipo`]: valor_tipo ?? undefined,
		[`${field}_valor`]: valor_tipo && valor_tipo !== valorIsensao ? values[`${field}_valor`] : undefined,
	};
};

const formataPayloadValuesMulta = (props) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'multa',
		valorIsensao: 'ISE',
	});

const formataPayloadValuesJuros = (props) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'juros',
		valorIsensao: 'JIS',
	});

const formataPayloadValuesDesconto = (props) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'desconto',
		valorIsensao: 'DEI',
	});

const formataPayloadValuesProtesto = (props) =>
	formataPayloadValuesInstrucoesComTipoDias({
		...props,
		field: 'protesto',
		valorIsensao: 'NP',
	});

const formataPayloadValuesLimitePagamento = (props) =>
	formataPayloadValuesInstrucoesComTipoDias({
		valorIsensao: 'SDL',
		...props,
		field: 'limite_pagamento',
	});

const formataPayloadValuesNegativacao = (props) =>
	formataPayloadValuesInstrucoesComTipoDias({
		...props,
		field: 'negativacao',
		valorIsensao: 'NN',
	});

const formataPayloadValuesSacadorAvalista = ({ values: { sacador_avalista } }) => ({ sacador_avalista });

const getPadraoPayloadValuesInstrucoesRemessa = () => ({
	multa_tipo: undefined,
	multa_valor: undefined,
	multa_dias: undefined,

	juros_tipo: undefined,
	juros_valor: undefined,
	juros_dias: undefined,

	desconto_tipo: undefined,
	desconto_valor: undefined,
	desconto_dias: undefined,

	protesto_tipo: undefined,
	protesto_dias: undefined,

	limite_pagamento_dias: undefined,
	limite_pagamento_tipo: undefined,

	negativacao_tipo: undefined,
	negativacao_dias: undefined,

	instrucao_1: undefined,
	instrucao_2: undefined,
	instrucao_dias: undefined,
});

// Valores da Tab Instruções de Cobrança Boleto
const createformataPayloadValuesLinhas =
	({ numeroLinhas = 5 } = {}) =>
	({ values }) =>
		Array.from({ length: numeroLinhas }, (_, i) => i + 1).reduce(
			(payload, numeroLinha) =>
				Object.assign(payload, { [`linha_${numeroLinha}`]: values[`linha_${numeroLinha}`] || '' }),
			{}
		);

// Conjuntos de formatadores padrões e utilitários
const getFormatadorPayloadCobrancaConfig =
	({ formatadores = [] }) =>
	({ payload, values, ...props }) =>
		values.emitir_boletos
			? {
					cobranca_config: formatadores.reduce((payloadCobrancaConfig, formatador) => {
						Object.assign(payloadCobrancaConfig, formatador({ ...props, values }));
						return payloadCobrancaConfig;
					}, {}),
			  }
			: null;

const formataPayloadValuesTabContaCobrancaConfig = ({
	values: {
		aceite,
		moeda,
		conta_cedente,
		carteira,
		especie_titulo,
		emissao_boleto,
		distribuicao_boleto,
		nosso_numero,
		sequencial_arquivo,
		local_pagamento,
	},
	cobranca_config: { nosso_numero: initialNossoNumero },
}) => {
	const payloadValues = {
		aceite: aceite?.id === '1',
		moeda: moeda?.id,
		conta_cedente,
		carteira: carteira?.id,
		especie_titulo: especie_titulo?.id,
		emissao_boleto: emissao_boleto?.id,
		distribuicao_boleto: distribuicao_boleto?.id,
		sequencial_arquivo,
		local_pagamento,
	};

	if (initialNossoNumero !== nosso_numero) {
		payloadValues['nosso_numero'] = nosso_numero;
	}

	return payloadValues;
};

const defaultFormatadoresPayloadValuesCobrancaConfig = [
	// Dados Fixos
	formataPayloadValuesIdContaBancaria,

	// Dados de Tab Emissão Boletos
	formataPayloadValuesBeneficiario,

	// Dados de Tab Instruções de Cobrança Remessa
	formataPayloadValuesSacadorAvalista,

	// Dados de Tab Instruções de Cobrança Boleto
	createformataPayloadValuesLinhas(),
];

const defaultFormatadoresPayloadValues = {
	// Dados de Tab Informação
	tab_informacao: formataPayloadValuesTabInformacao,

	// Dados de Tab Emissão Boletos
	tab_emissao_boletos: formataPayloadValuesTabEmissaoBoletos,

	// Dados de Tab Conta
	agencia: defaultFormatarValuesAgencia,
	conta_corrente: defaultFormatarValuesContaCorrente,

	cobranca_config: getFormatadorPayloadCobrancaConfig({
		formatadores: [
			...defaultFormatadoresPayloadValuesCobrancaConfig,
			// Dados de Tab Conta
			formataPayloadValuesTabContaCobrancaConfig,
		],
	}),
};

const getFormataPayloadValues =
	({ conta_condominio, instituicaoProps: { formatadoresPayloadValues, ...instituicaoProps }, ...props }) =>
	(values) => {
		const payload = {};
		Object.assign(payload, formataPayloadValuesFixos({ conta_condominio }));
		for (const formatador of Object.values(formatadoresPayloadValues)) {
			Object.assign(payload, formatador({ ...props, conta_condominio, instituicaoProps, values, payload }));
		}
		return payload;
	};

export {
	formataPayloadValuesFixos,
	formataPayloadValuesIdContaBancaria,
	formataPayloadValuesTabInformacao,
	formataPayloadValuesBeneficiario,
	formataPayloadValuesTabEmissaoBoletos,
	formataPayloadValuesInstrucoesComTipoValorDias,
	formataPayloadValuesInstrucoesComTipoDias,
	formataPayloadValuesInstrucoesComTipoValor,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesProtesto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesNegativacao,
	formataPayloadValuesSacadorAvalista,
	formataPayloadValuesTabPagarBoletosViaSistema,
	getPadraoPayloadValuesInstrucoesRemessa,
	createformataPayloadValuesLinhas,
	getFormatadorPayloadCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	defaultFormatadoresPayloadValues,
};

export default getFormataPayloadValues;
