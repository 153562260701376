import React from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import MenuItem from '@material-ui/core/MenuItem';

import { format, parseISO } from 'date-fns';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

import ModalConfiguracaoImpressao from '../modais/ModalConfiguracaoImpressao';

export const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
	baixaBoletosButton: {
		color: theme.palette.syndikosRed.contrastText,
		backgroundColor: theme.palette.syndikosRed.main,
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.dark,
		},
	},
}));

export const ButtonBaixarBoletos = ({
	idsRegistrosSelecionados,
	disabled,
	setLoadingRequest,
	label = 'Baixar Selecionados',
	configurar,
	configuracao = {},
}) => {
	const classes = useStyles();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	const handleBaixar = () => {
		const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
			const byteCharacters = atob(b64Data);
			const byteArrays = [];

			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);

				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const blob = new Blob(byteArrays, {
				type: contentType,
			});
			return blob;
		};
		setLoadingRequest(true);
		dataProvider
			.simplePost('boletos/baixar_boletos', {
				data: {
					...configuracao,
					list_ids_conta_a_receber: idsRegistrosSelecionados,
					referencia: format(parseISO(referencia), 'MM-yyyy'),
					id_identificacao: id_identificacao_arrecadacao,
					id_conta_bancaria_provisionada: contaBDestinoId,
					id_condominio: condominioSelecionado?.id,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					const downloadLink = document.createElement('a');
					const blob = b64toBlob(data?.file, 'text/plain,charset=us-ascii');
					downloadLink.href = URL.createObjectURL(blob);
					downloadLink.download = configuracao.arquivo_unico
						? `${data?.filename}.pdf`
						: `${data?.filename}.zip`;
					downloadLink.click();
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			})
			.finally(() => {
				setLoadingRequest(false);
				handleClose();
			});
	};
	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return configurar ? (
		<MenuItem
			disabled={!idsRegistrosSelecionados.length || disabled}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalConfiguracaoImpressao
							idsRegistrosSelecionados={idsRegistrosSelecionados}
							action='baixar'
						/>
					),
				}));
			}}
		>
			{label}
		</MenuItem>
	) : (
		<Button
			size='small'
			disabled={!idsRegistrosSelecionados.length || disabled}
			variant='contained'
			startIcon={<GetAppIcon />}
			className={classes.baixaBoletosButton}
			onClick={() => {
				handleBaixar();
			}}
			color='primary'
		>
			{label}
		</Button>
	);
};

export default ButtonBaixarBoletos;
