import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import React from 'react';
import { useInput } from 'react-admin';

interface Item {
	dia: string;
}

interface CheckBoxNotificacaoProps {
	source: string;
	initialValue?: object;
	props: {
		record?: {
			id?: number;
			notificacoes_previsao_de_entrega?: Array<{ dia: string }>;
			notificacoes_vencimento?: Array<{ dia: string }>;
			notificacoes_apos_vencimento?: string;
		};
	};
}

interface Notificacao {
	dia?: string;
	tarefa_manutencao?: number;
	dia_da_semana?: string;
}

const options = [
	{ label: 'No dia', value: 'DIA' },
	{ label: '1 Dia antes', value: '1DIA' },
	{ label: '2 Dias antes', value: '2DIA' },
	{ label: '3 Dias antes', value: '3DIA' },
	{ label: '5 Dias antes', value: '5DIA' },
	{ label: '15 Dias antes', value: '15DIA' },
	{ label: '30 Dias antes', value: '30DIA' },
];
const optionsDiaDaSemana = [
	{ label: 'Segunda', value: 'SEG' },
	{ label: 'Terça', value: 'TER' },
	{ label: 'Quarta', value: 'QUA' },
	{ label: 'Quinta', value: 'QUI' },
	{ label: 'Sexta', value: 'SEX' },
	{ label: 'Sábado', value: 'SAB' },
	{ label: 'Domingo', value: 'DOM' },
];

function transformarDia(lista: Item[]) {
	return lista?.reduce((acc, item) => {
		acc[item?.dia] = true;
		return acc;
	}, {} as Record<string, boolean>);
}

export const CheckBoxPrevisaoEntrega: React.FC<CheckBoxNotificacaoProps> = ({ source, props }) => {
	const [checkedItemsPrevisao, setCheckedItemsPrevisao] = React.useState<Record<string, boolean>>({
		DIA: true,
		'1DIA': true,
		'2DIA': true,
		'3DIA': false,
		'5DIA': true,
		'15DIA': false,
		'30DIA': false,
	});

	const {
		input: { onChange },
	} = useInput({ source });
	const handleChangePrevisao = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedItemsPrevisao({
			...checkedItemsPrevisao,
			[event.target.name]: event.target.checked,
		});
	};
	React.useEffect(() => {
		onChange(
			Object.keys(checkedItemsPrevisao).reduce<Notificacao[]>((notificacoes_ent, dia) => {
				if (!checkedItemsPrevisao[dia as keyof typeof checkedItemsPrevisao]) return notificacoes_ent;
				notificacoes_ent.push({
					dia: dia,
					tarefa_manutencao: props?.record?.id,
				});
				return notificacoes_ent;
			}, [])
		);
	}, [checkedItemsPrevisao]);

	React.useEffect(() => {
		if (props?.record?.notificacoes_previsao_de_entrega) {
			setCheckedItemsPrevisao(transformarDia(props.record.notificacoes_previsao_de_entrega));
		}
	}, [props?.record?.notificacoes_previsao_de_entrega]);

	return (
		<FormGroup row>
			{options.map((option) => (
				<FormControlLabel
					key={option.value}
					control={
						<Checkbox
							checked={checkedItemsPrevisao[option.value] || false}
							onChange={handleChangePrevisao}
							name={option.value}
							color='primary'
						/>
					}
					label={option.label}
				/>
			))}
		</FormGroup>
	);
};

export const CheckBoxEnviarNotificacaoVencimento: React.FC<CheckBoxNotificacaoProps> = ({ source, props }) => {
	const [checkedItemsVencimento, setCheckedItemsVencimento] = React.useState<Record<string, boolean>>({
		DIA: true,
		'1DIA': true,
		'2DIA': true,
		'3DIA': false,
		'5DIA': true,
		'15DIA': false,
		'30DIA': false,
	});
	const {
		input: { onChange },
	} = useInput({ source });
	const handleChangeVencimento = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedItemsVencimento({
			...checkedItemsVencimento,
			[event.target.name]: event.target.checked,
		});
	};
	React.useEffect(() => {
		onChange(
			Object.keys(checkedItemsVencimento).reduce<Notificacao[]>((notificacoes_venc, dia) => {
				if (!checkedItemsVencimento[dia as keyof typeof checkedItemsVencimento]) return notificacoes_venc;
				notificacoes_venc.push({
					dia: dia,
					tarefa_manutencao: props?.record?.id,
				});
				return notificacoes_venc;
			}, [])
		);
	}, [checkedItemsVencimento]);
	React.useEffect(() => {
		if (props?.record?.notificacoes_vencimento) {
			setCheckedItemsVencimento(transformarDia(props.record.notificacoes_vencimento));
		}
	}, [props?.record?.notificacoes_vencimento]);

	return (
		<FormGroup row>
			{options.map((option) => (
				<FormControlLabel
					key={option.value}
					control={
						<Checkbox
							checked={checkedItemsVencimento[option.value] || false}
							onChange={handleChangeVencimento}
							name={option.value}
							color='primary'
						/>
					}
					label={option.label}
				/>
			))}
		</FormGroup>
	);
};

export const CheckBoxNotificacaoAposVencimento: React.FC<CheckBoxNotificacaoProps> = ({ source, props }) => {
	const [checkedItemsNotificacao, setCheckedItemsNotificacao] = React.useState<Record<string, boolean>>({
		DOM: false,
		SEG: true,
		TER: false,
		QUA: false,
		QUI: false,
		SEX: false,
		SAB: false,
	});
	const {
		input: { onChange },
	} = useInput({ source });
	const handleChangeNotificacao = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedItemsNotificacao({
			...checkedItemsNotificacao,
			[event.target.name]: event.target.checked,
		});
	};
	React.useEffect(() => {
		onChange(
			Object.keys(checkedItemsNotificacao).reduce<Notificacao[]>((notificacoes, dia_da_semana) => {
				if (!checkedItemsNotificacao[dia_da_semana as keyof typeof checkedItemsNotificacao])
					return notificacoes;
				notificacoes.push({
					dia_da_semana: dia_da_semana,
					tarefa_manutencao: props?.record?.id,
				});
				return notificacoes;
			}, [])
		);
	}, [checkedItemsNotificacao]);

	React.useEffect(() => {
		if (props?.record?.notificacoes_apos_vencimento && Array.isArray(props.record.notificacoes_apos_vencimento)) {
			const init_dia_da_semana = props?.record?.notificacoes_apos_vencimento?.reduce((acc, item) => {
				acc[item.dia_da_semana] = true;
				return acc;
			}, {});
			if (init_dia_da_semana) {
				setCheckedItemsNotificacao(init_dia_da_semana);
			}
		}
	}, [props?.record?.notificacoes_apos_vencimento]);

	return (
		<>
			<Box>
				<Typography variant='body1' style={{ marginRight: '1em' }}>
					Quais dias da semana você deseja que o Syndkos sempre envie notificação após o vencimento:
				</Typography>
			</Box>
			<Box>
				<FormGroup row>
					{optionsDiaDaSemana.map((option) => (
						<FormControlLabel
							key={option.value}
							control={
								<Checkbox
									checked={checkedItemsNotificacao[option.value] || false}
									onChange={handleChangeNotificacao}
									name={option.value}
									color='primary'
								/>
							}
							label={option.label}
						/>
					))}
				</FormGroup>
			</Box>
		</>
	);
};
