import React, { useContext } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { PrevisaoOrcamentariaContext } from '../contexts/PrevisoesOrcamentariasContextProvider';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { CloseButton, DeleteButton } from 'components/common/commonComponentsTSX';
import { CustomDataProvider } from '../types';

export const ModalConfirmacao: React.FC = () => {
	const { modeloSelecionado, setModeloSelecionado, getModelosPrevisoesOrcamentarias } =
		useContext(PrevisaoOrcamentariaContext);
	const notify = useNotify();
	const dp = useDataProvider() as CustomDataProvider;
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	const handleDeleteModelo = () => {
		dp.delete('modelo_previsao_orcamentaria', {
			id: modeloSelecionado.id as number,
		})
			.then(() => {
				notify('Modelo excluído com sucesso!');
				setModalValue((v: any) => ({ ...v, open: false, dialogBody: <></> }));
				getModelosPrevisoesOrcamentarias();
				setModeloSelecionado({
					id: null,
					nome: '',
					condominio: null,
					plano_condominio: null,
					periodo: null,
					inicio: null,
					termino: null,
				});
			})
			.catch(() => {
				return notify('Não foi possível excluir o modelo.', { type: 'error' });
			});
	};
	return (
		<CustomDialogBodySizeEditable
			title={'Tem certeza que deseja deletar esse modelo de previsões orçamentárias?'}
			text='Essa ação é irreversível. Esse modelo será removido.'
			customActions={
				<>
					<CloseButton
						onClick={() => {
							setModalValue({ open: false, dialogBody: <></> });
						}}
						size='small'
					>
						cancelar
					</CloseButton>
					<DeleteButton size='small' onClick={handleDeleteModelo}>
						Excluir
					</DeleteButton>
				</>
			}
			customStyleTilte={undefined}
			remove={undefined}
			form={undefined}
			onCloseAction={undefined}
			customSubmitLabel={undefined}
		/>
	);
};
