import React from 'react';

import TextField from '@material-ui/core/TextField';

import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator='.'
			decimalSeparator=','
			decimalScale={8}
			fixedDecimalScale={true}
			allowNegative={false}
			isNumericString
			{...other}
		/>
	);
}

function InputCoeficienteGaragem({ value, setValue, ...props }) {
	function handleChangeCoeficienteInput(e) {
		setValue(e.target.value);
	}
	return (
		<TextField
			size='small'
			margin='normal'
			label='Valor do coeficiente:'
			onChange={handleChangeCoeficienteInput}
			value={value}
			name='coeficiente-garagem'
			id='coeficiente-garagem-id'
			InputProps={{
				inputComponent: NumberFormatCustom,
			}}
			fullWidth
			{...props}
		/>
	);
}

export default InputCoeficienteGaragem;
