import React, { useContext, useCallback } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { CustomDialogBody, ModalContext } from '../../../../../common/ModalContext';
import { MovimentacoesContext } from '../../../MovimentacoesContext';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

const TipoMovimentcaoEndpoints = {
	LR: 'lancamento_receita',
	LD: 'lancamento_despesa',
	TF: 'transferencia',
	RM: 'recebimento_manual',
	BM: 'parcela',
};

export const ConfirmacaoConciliacao = ({ index, movimentacao }) => {
	const dp = useDataProvider();
	const notify = useNotify();
	const { setModalValue } = useContext(ModalContext);
	const { setForceRefresh } = useContext(MovimentacoesContext);
	const { setRegistrosConciliaveisSelecionados, setRegistros } = useContext(FluxoImportacaoExtratoBancarioContext);

	const handleSubmit = useCallback(() => {
		const endpoint = TipoMovimentcaoEndpoints[movimentacao.tipo_movimentacao];
		if (endpoint) {
			dp.update(endpoint, { id: movimentacao.id })
				.then(() => {
					notify('Lançamento financeiro conciliado com sucesso');
					setRegistrosConciliaveisSelecionados([]);
					setRegistros((v) => {
						v[index].situacao = 'C';
						v[index].movimentacoesCompativeis.compatibilidadeTotalConciliado[movimentacao.id] =
							movimentacao;
						delete v[index].movimentacoesCompativeis.compatibilidadeTotal[movimentacao.id];
						return Object.values(v).reduce((dict, r) => {
							if (r.index !== index) {
								delete r.movimentacoesCompativeis.compatibilidadeTotal[movimentacao.id];
								if (
									r.situacao !== 'C' &&
									!Object.values(r.movimentacoesCompativeis.compatibilidadeTotal).length
								)
									r.situacao = 'I';
							}
							dict[r.index] = r;
							return dict;
						}, {});
					});
					setForceRefresh(true);
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || [])[0] || [
						'Erro inesperado, tente recarregar a página',
					];
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setModalValue((v) => ({ ...v, open: false }));
				});
		}
	}, [
		index,
		movimentacao,
		dp,
		notify,
		setRegistrosConciliaveisSelecionados,
		setRegistros,
		setForceRefresh,
		setModalValue,
	]);

	return (
		<CustomDialogBody
			title={'Tem certeza que deseja conciliar esse lançamento?'}
			form={{ valid: true, handleSubmit }}
			customSubmitLabel='Conciliar'
		/>
	);
};
