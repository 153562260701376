import React, { useState, useContext, useMemo, useCallback, useEffect } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import ModalConfirmacaoArrecadacoesRecorrentes from './ModalConfirmacaoArrecadacoesRecorrentes';
import ProvisionamentoContext from './ProvisionamentoContext';
import ModalConfirmaRecalculo from './ModalConfirmaRecalculo';
import ModalFeedbackProvisionamentoRecalculo from './ModalFeedBackProvisionamentoRecalculo';
import { CondominiosContext } from 'context/CondominioContextProvider';

const separarArrecadacoes = (arrecadacoes) => {
	return Object.values(arrecadacoes).reduce(
		(
			{ arrecadacoesSimples, arrecadacoesFundoPercentualPrimeiroNivel, arrecadacoesFundoPercentualSegundoNivel },
			arrecadacao
		) => {
			if (arrecadacao.tipo_lancamento !== 'PRC') {
				arrecadacoesSimples.push(arrecadacao);
			} else if (!arrecadacao.is_arrecadacao_fundo_percentual_secundaria) {
				arrecadacoesFundoPercentualPrimeiroNivel.push(arrecadacao);
			} else if (arrecadacao.is_arrecadacao_fundo_percentual_secundaria) {
				arrecadacoesFundoPercentualSegundoNivel.push(arrecadacao);
			}
			return {
				arrecadacoesSimples,
				arrecadacoesFundoPercentualPrimeiroNivel,
				arrecadacoesFundoPercentualSegundoNivel,
			};
		},
		{
			arrecadacoesSimples: [],
			arrecadacoesFundoPercentualPrimeiroNivel: [],
			arrecadacoesFundoPercentualSegundoNivel: [],
		}
	);
};

const emitirRequestsRecalculoArrecadacoesSimples = ({ arrecadacoes = [], arrecadacoesPorId = {}, dP }) => {
	return arrecadacoes.map((arrecadacao) =>
		dP
			.updateWithPut('arrecadacao', { id: `${arrecadacao.id}/recalcular` })
			.then((response) => {
				const arrecadacaoRecalculada = response.data;
				arrecadacoesPorId[arrecadacaoRecalculada.id] = arrecadacaoRecalculada;
				return Promise.resolve(response);
			})
			.catch((e) => {
				return Promise.reject({ e, dados_arrecadacao: arrecadacao });
			})
	);
};

const emitirRequestsRecalculoArrecadacoesFundoPercentual = ({ arrecadacoes = [], dP }) => {
	return arrecadacoes.map((arrecadacao) =>
		dP.updateWithPut('arrecadacao', { id: `${arrecadacao.id}/recalcular` }).catch((e) => {
			return Promise.reject({ e, dados_arrecadacao: arrecadacao });
		})
	);
};

const emitirRequestRecalculoArrecadacoes = ({ arrecadacoes, tipo }, arrecadacoesPorId, dP) => {
	const funcaoEmissoraRequestRecalculo =
		{ simples: emitirRequestsRecalculoArrecadacoesSimples }[tipo] ??
		emitirRequestsRecalculoArrecadacoesFundoPercentual;
	return funcaoEmissoraRequestRecalculo({ arrecadacoes, arrecadacoesPorId, dP });
};

const ProvisionamentoContextProvider = () => {
	const dP = useDataProvider();
	const notify = useNotify();
	const { condominioSelecionado } = useContext(CondominiosContext);
	const {
		setDadosProvisionamento,
		carregaValoresCB,
		referencia,
		id_identificacao_arrecadacao: identificacao,
		contaBDestinoId: conta_b_destino_id,
	} = useContext(ArrecadacoesContext);
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);

	const [modalStep, setModalStep] = useState('1');
	const [loadingArrecadacoes, setLoadingArrecadacoes] = useState(true);
	const [arrecadacoes, setArrecadacoes] = useState({});
	const [responsesRecalculosSucedidos, setResponseRecalculosSucedidos] = useState();
	const [responsesRecalculosFalhados, setResponseRecalculosFalhados] = useState();
	const [realizandoProvisionamento, setRealizandoProvisionamento] = useState(false);
	const [realizandoRecalculo, setRealizandoRecalculo] = useState(false);

	const condominio = condominioSelecionado?.id;

	const updateArrecadacoes = useCallback(() => {
		setLoadingArrecadacoes(true);
		dP.getSimple('arrecadacao_recorrente', {
			filter: {
				id_condominio: condominio,
				id_identificacao_arrecadacao: identificacao,
				referencia: referencia,
				get_futuras_recorrentes: referencia,
			},
			pagination: {
				page: 1,
				perPage: 10000,
			},
		})
			.then((response) => {
				const arrecadacoes = response?.data || [];
				if (arrecadacoes.length) {
					setArrecadacoes(
						arrecadacoes.reduce((dict, arrecadacao) => {
							if (arrecadacao?.id) dict[arrecadacao.id] = arrecadacao;
							return dict;
						}, {})
					);
				} else {
					setArrecadacoes({});
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] ||
						'Um erro inesperado aconteceu, tente recarregar a página',
					'warning'
				);
				setArrecadacoes({});
			})
			.finally(() => {
				setLoadingArrecadacoes(false);
			});
	}, [dP, condominio, identificacao, referencia, setArrecadacoes, notify, setLoadingArrecadacoes]);

	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false, dialogBody: <></> }));
	}, [setModalValue]);

	useEffect(updateArrecadacoes, [condominio, identificacao, referencia]);

	const executaRecalculo = useCallback(() => {
		if (realizandoRecalculo) return Promise.resolve();
		setRealizandoRecalculo(true);
		const {
			arrecadacoesSimples,
			arrecadacoesFundoPercentualPrimeiroNivel,
			arrecadacoesFundoPercentualSegundoNivel,
		} = separarArrecadacoes(arrecadacoes);

		const gruposDeArrecadacoes = [
			{ arrecadacoes: arrecadacoesSimples, tipo: 'simples' },
			{ arrecadacoes: arrecadacoesFundoPercentualPrimeiroNivel, tipo: 'fundoPercentualPrimeiroNivel' },
			{ arrecadacoes: arrecadacoesFundoPercentualSegundoNivel, tipo: 'fundoPercentualSegundoNivel' },
		].filter(({ arrecadacoes }) => Boolean(arrecadacoes.length));

		gruposDeArrecadacoes
			.reduce(async (correnteDePromises, conjuntoArrecadacoes) => {
				const responses = await correnteDePromises;
				const novasResponses = await Promise.allSettled(
					emitirRequestRecalculoArrecadacoes(conjuntoArrecadacoes, arrecadacoes, dP)
				);
				return Promise.resolve([...responses, ...novasResponses]);
			}, Promise.resolve([]))
			.then((responses) => {
				const RejectedLoggoutResponse = responses.find(
					(r) => r?.status === 'rejected' && [401, 403].includes(r.reason?.e?.response?.status)
				);
				if (RejectedLoggoutResponse) return Promise.reject(RejectedLoggoutResponse.reason?.e);
				setResponseRecalculosSucedidos(responses.filter((r) => r?.status === 'fulfilled'));
				setResponseRecalculosFalhados(responses.filter((r) => r?.status === 'rejected'));
				// setArrecadacoes(arrecadacoes);
				setModalStep('2');
				carregaValoresCB();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify('Erro inesperado, tente recarregar a página', 'warning');
				setModalValue((v) => ({ ...v, open: false }));
			})
			.finally(() => {
				setRealizandoRecalculo(false);
			});
	}, [
		arrecadacoes,
		dP,
		setResponseRecalculosFalhados,
		setResponseRecalculosSucedidos,
		setModalValue,
		notify,
		carregaValoresCB,
	]);

	const provisionar = useCallback(() => {
		if (realizandoProvisionamento) return Promise.resolve();
		setRealizandoProvisionamento(true);

		return dP
			.safeCreate('cld_recebimento_manual/create_bulk', {
				data: {
					identificacao_id: identificacao,
					condominio_id: condominio,
					referencia: referencia,
					id_conta_bancaria: conta_b_destino_id,
				},
			})
			.then(() => {
				setDadosProvisionamento({
					tem_arrecadacao_provisionada: true,
					conta_b_destino_id,
					tem_recebimento_pago: false,
				});
				notify('Arrecadações provisionadas com sucesso!');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const data = e?.response?.data || {};
				if (Array.isArray(data)) {
					notify(data?.[0]?.non_field_errors?.[0], 'warning');
				} else {
					notify(
						Object.values(data)[0] || 'Um erro inesperado aconteceu, tente recarregar a página',
						'warning'
					);
				}
			})
			.finally(() => {
				setRealizandoProvisionamento(false);
			});
	}, [
		dP,
		identificacao,
		condominio,
		referencia,
		conta_b_destino_id,
		setDadosProvisionamento,
		notify,
		realizandoProvisionamento,
	]);

	const handleProvisionar = useCallback(async () => {
		const arrecadacoesRecorrentes = Object.values(arrecadacoes).filter((a) => a.recorrente);
		if (arrecadacoesRecorrentes.length) {
			setModalValue((v) => ({
				...v,
				open: true,
				dialogBody: (
					<ModalConfirmacaoArrecadacoesRecorrentes
						arrecadacoesRecorrentes={arrecadacoesRecorrentes}
						provisionar={provisionar}
					/>
				),
			}));
		} else {
			await provisionar();
			closeModal();
		}
	}, [arrecadacoes, setModalValue, provisionar, closeModal]);

	useEffect(() => {
		if (['1', '2', '3', '4', '5F', '6FK'].includes(modalStep)) setMinWidthModel('40vw');
		if (['5P', '6P', '8FS', '9FC'].includes(modalStep)) setMinWidthModel('61vw');
	}, [setMinWidthModel, modalStep]);

	const [stepComponents] = useState({
		1: <ModalConfirmaRecalculo />,
		2: <ModalFeedbackProvisionamentoRecalculo />,
	});

	const ProvisionamentoProviderValue = useMemo(
		() => ({
			modalStep,
			setModalStep,
			closeModal,
			executaRecalculo,
			responsesRecalculosSucedidos,
			responsesRecalculosFalhados,
			handleProvisionar,
			loadingArrecadacoes,
			realizandoRecalculo,
			realizandoProvisionamento,
		}),
		[
			modalStep,
			setModalStep,
			closeModal,
			executaRecalculo,
			responsesRecalculosSucedidos,
			responsesRecalculosFalhados,
			handleProvisionar,
			loadingArrecadacoes,
			realizandoRecalculo,
			realizandoProvisionamento,
		]
	);

	return (
		<ProvisionamentoContext.Provider value={ProvisionamentoProviderValue}>
			{stepComponents[modalStep]}
		</ProvisionamentoContext.Provider>
	);
};

const FluxoModalProvisionamento = ({ condominio, referencia, identificacao, conta_b_destino_id }) => (
	<ProvisionamentoContextProvider {...{ condominio, referencia, identificacao, conta_b_destino_id }} />
);

export default FluxoModalProvisionamento;
