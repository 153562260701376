import React, { useContext, createContext, useEffect, useState, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { ModalContext } from '../../common/ModalContext';

export const GruposContext = createContext();

export const GruposContextProvider = ({ children, ...rest }) => {
	const { setModalValue } = useContext(ModalContext);
	const [data, setData] = useState([]);
	const idCondominio = rest.id;
	const dP = useDataProvider();

	const carrega = () => {
		dP.getList('grupo_unidade_condominio', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'posicao_reordem', order: 'ASC' },
			filter: { id_condominio: idCondominio },
		}).then((response) => {
			const data = response?.data;
			if (data) setData(data);
		});
	};

	useEffect(carrega, []);

	const gruposProviderValue = useMemo(
		() => ({ setModalValue, data, setData, idCondominio, dP }),
		[setModalValue, data, setData, idCondominio, dP]
	);
	return <GruposContext.Provider value={gruposProviderValue}>{children}</GruposContext.Provider>;
};

export const GrupoUnidadeContext = createContext();

export const GrupoUnidadeContextProvider = ({ children, ...rest }) => {
	const { setModalValue } = useContext(ModalContext);
	const [grupo, setGrupo] = useState(rest.grupo);
	const [renameMode, setRenameMode] = useState(false);

	const grupoProviderValue = useMemo(
		() => ({ setModalValue, grupo, setGrupo, renameMode, setRenameMode }),
		[setModalValue, grupo, setGrupo, renameMode, setRenameMode]
	);
	return <GrupoUnidadeContext.Provider value={grupoProviderValue}>{children}</GrupoUnidadeContext.Provider>;
};
