import React from 'react';
import { TextInput, maxLength, required } from 'react-admin';
import { Field } from 'react-final-form';

import { Box } from '@material-ui/core';

import VeryHighLimitReferenceInput from 'components/common/VeryHighLimitReferenceInput';
import InputFile from 'components/common/InputFile';
import { InputTime } from 'components/common/InputTime';
import { SyndikosSelect } from 'components/common/SyndikosSelect';
import { SyndikosKeyboardDatePicker, SyndikosMuiPickersUtilsProvider } from 'components/common/InputDate';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { FormularioBox, BoxDivisor, TextInputSyndikos } from '../../common/Formulario';

import { AssembleiaFormProps } from './types';

const opcoesParticipantes = [
	{ id: 'P', name: 'Proprietário' },
	{ id: 'I', name: 'Inquilino' },
	{ id: 'A', name: 'Ambos' },
	{ id: 'R', name: 'Responsável pela Cobrança' },
];

const AssembleiaForm: React.FC<AssembleiaFormProps> = ({ visualizar, ...props }) => {
	const { condominioSelecionado, setCondominioPorId } = React.useContext(CondominiosContext);

	return (
		<FormularioBox
			customBackButton={undefined}
			customSaveButton={undefined}
			{...props}
			nome={undefined}
			onClickBotaoRemover={undefined}
			dictLabels={undefined}
			getChipClassName={undefined}
			onlyExcluir={undefined}
			noActionToolbar={visualizar}
		>
			<Box flex={1} mr='1em'>
				<BoxDivisor titulo={'Assembleia Virtual'}>
					<Box display='flex' flex={1} gridGap='2em' ml={1}>
						<VeryHighLimitReferenceInput
							source='condominio'
							reference='condominios'
							label='Condomínio'
							fullWidth
							variant='outlined'
							validate={required()}
							onChange={(value: number) => {
								setCondominioPorId(value);
							}}
							filter={{ situacao: 'A' }}
							initialValue={condominioSelecionado?.id}
							disabled={visualizar}
						>
							<SyndikosSelect optionText='nome' style={{ marginBottom: 24 }} disableClearable />
						</VeryHighLimitReferenceInput>
						<SyndikosSelect
							required
							source='participantes'
							label='Participantes'
							validate={required()}
							choices={opcoesParticipantes}
							disabled={visualizar}
							delay={0}
							fullWidth
						/>
					</Box>
				</BoxDivisor>
				<Box display='flex' flex={1} gridGap='2em' ml={1}>
					<SyndikosMuiPickersUtilsProvider>
						<SyndikosKeyboardDatePicker
							fullWidth
							source='data_inicio'
							label='Data Início'
							required
							format='dd/MM/yyyy'
							id='date-picker-inline'
							mask='__/__/____'
							parse={(value: Date) => {
								try {
									return value.toISOString().split('T')[0];
								} catch {
									return value;
								}
							}}
							validate={required('Obrigatório')}
							disabled={visualizar}
							maxDate={undefined}
							maxDateMessage={undefined}
							handleChange={undefined}
							{...props}
						/>
					</SyndikosMuiPickersUtilsProvider>
					<SyndikosMuiPickersUtilsProvider>
						<SyndikosKeyboardDatePicker
							fullWidth
							source='data_fim'
							label='Data Fim'
							required
							format='dd/MM/yyyy'
							id='date-picker-inline'
							mask='__/__/____'
							parse={(value: Date) => {
								try {
									return value.toISOString().split('T')[0];
								} catch {
									return value;
								}
							}}
							validate={required('Obrigatório')}
							disabled={visualizar}
							maxDate={undefined}
							maxDateMessage={undefined}
							handleChange={undefined}
							{...props}
						/>
					</SyndikosMuiPickersUtilsProvider>
					<InputTime
						label='Horário Início'
						source='horario_inicio'
						size='small'
						variant='outlined'
						defaultValue='12:00'
						margin='dense'
						fullWidth
						validate={required()}
						disabled={visualizar}
						{...props}
					/>
					<InputTime
						label='Horário Final'
						source='horario_final'
						size='small'
						variant='outlined'
						defaultValue='12:00'
						margin='dense'
						fullWidth
						validate={required()}
						disabled={visualizar}
						{...props}
					/>
				</Box>
				<Box display='flex' flex={1} ml={1}>
					<TextInput
						label='Link para vídeo chamada'
						source='link_assembleia'
						variant='outlined'
						size='small'
						fullWidth
						disabled={visualizar}
						validate={maxLength(100, 'Máximo de 100 caractéres')}
					/>
				</Box>
				<BoxDivisor titulo='Descrição' mt={2}>
					<Box display='flex' flex={1} ml={1} mt={2}>
						<TextInput
							label='Assunto'
							source='assunto'
							variant='outlined'
							size='small'
							fullWidth
							disabled={visualizar}
							validate={[required(), maxLength(100, 'Máximo de 100 caractéres')]}
						/>
					</Box>
					<Box display='flex' flex={1} ml={1} mb={2}>
						<TextInputSyndikos
							label='Descrição'
							source='descricao'
							variant='outlined'
							multiline
							resettable
							fullWidth
							rows={8}
							disabled={visualizar}
							validate={[required(), maxLength(1000, 'Máximo de 1000 caractéres')]}
							defaultValue=''
						/>
					</Box>
					<Box display='flex' flex={0.5} ml={1}>
						<Field name='write_only_anexo_file' id='write_only_anexo_file'>
							{({ input }) => (
								<InputFile
									source='write_only_anexo_file'
									label='Arquivo em anexo'
									variant='outlined'
									onCallBack={(e: any, dataUrl: string) => {
										input.onChange(dataUrl);
									}}
									fileDataUrl={props.record?.write_only_anexo_file}
									disabled={visualizar}
									desativateButtonAlterarAnexo={visualizar}
									desativateButtonRemoverAnexo={visualizar}
								/>
							)}
						</Field>
					</Box>
				</BoxDivisor>
			</Box>
		</FormularioBox>
	);
};

export default AssembleiaForm;
