import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { TooltipIconButtonCancel } from '../../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../../common/buttons/ButtonConfirm';
import { TooltipVoltarIconButton } from '../../../common/buttons/BotaoVoltar';
import { CustomDialogBodySizeEditable } from '../../../common/ModalSizeEditableContext';
import { ContasContext } from '../contexts/ContasContext';
import FluxoImportacaoPlanoContext from './FluxoImportacaoPlanoContext';
import TabContas from './TabContas';

const ImportPlanoDeCSVModalBody = () => {
	const dP = useDataProvider();
	const notify = useNotify();
	const { contas } = useContext(ContasContext);
	const {
		planoImportando: { id },
		setStepComponent,
		dadosContasReceitasDespesas: {
			receitas: { contasRaizes: contasRaizesReceitas },
			despesas: { contasRaizes: contasRaizesDespesas },
		},
		handleClose,
	} = useContext(FluxoImportacaoPlanoContext);
	const [processando, setProcessando] = useState(false);

	const importarContas = () => {
		if (processando) return;

		setProcessando(true);
		if (!contasRaizesReceitas.length && !contasRaizesDespesas.length) {
			notify('Não há nenhuma conta para importar', 'warning');
			setProcessando(false);
			return;
		}

		dP.importarContas('importar_contas', {
			data: {
				id_plano: id,
				contas_receitas_raizes: contasRaizesReceitas,
				contas_despesas_raizes: contasRaizesDespesas,
			},
		})
			.then(() => {
				contas.contaRequest('refresh', {}, 'contas_despesa');
				contas.contaRequest('refresh', {}, 'contas_receita');
				notify('Importação concluída');
				handleClose();
				setProcessando(false);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				if ([500].includes(e?.response?.status))
					notify('Erro ao realizar a importação, contate o suporte', 'warning');

				const msgErro = e?.response?.data || 'Erro ao realizar a importação';
				notify(msgErro, 'warning');
				setProcessando(false);
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title='Importação de Plano de Contas'
			customActions={[
				<TooltipVoltarIconButton
					key='botao_voltar'
					onClick={() => {
						setStepComponent('ImportacaoPorCSV-DropZone');
					}}
					disabled={processando}
				/>,
				<TooltipIconButtonConfirm
					key='botao_importar'
					title='Importar contas'
					onClick={importarContas}
					disabled={processando}
				/>,
				<TooltipIconButtonCancel key='botao_cancelar' onClick={handleClose} disabled={processando} />,
			]}
			form={{
				component: <TabContas />,
			}}
		/>
	);
};

export default ImportPlanoDeCSVModalBody;
