import React, { useContext, useState } from 'react';

import { Collapse, Box, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import { BoletosContext } from './abas/ContasReceberBoletos/BoletosContextProvider';

import ComunicadoTemplate from 'components/common/ComunicadoTemplate';
import BoxConfigDevedores from './ConfiguracoesBoleto/BoxConfigDevedores';
import BoxConfigDemonstrativoArrecadacao from './ConfiguracoesBoleto/BoxConfigDemonstrativoArrecadacao';
import BoxConfigResumoRateioValorTotal from './ConfiguracoesBoleto/BoxConfigResumoRateioValorTotal';
import BoxConfigValorUnidadeSubordinada from './ConfiguracoesBoleto/BoxConfigValorUnidadeSubordinada';
import BoxConfigResumoRateio from './ConfiguracoesBoleto/BoxConfigResumoRateio';
import BoxConfigDemonstrativoMensal from './ConfiguracoesBoleto/BoxConfigDemonstrativoMensal';
import BoxConfigPrestacaoDeContas from './ConfiguracoesBoleto/BoxConfigPrestacaoDeContas';
import BoxConfigMarcaDAgua from './ConfiguracoesBoleto/BoxConfigMarcaDAgua';
import BoxConfigProprietarioEInquilino from './ConfiguracoesBoleto/BoxConfigProprietarioEInquilino';

const optionsModeloDemonstrativo = [
	{ id: 'DA', name: 'Demonstrativo de Arrecadação' },
	{ id: 'PC', name: 'Prestação de Contas' },
	{ id: 'DP', name: 'Demonstrativo de Arrecadação e Prestação de Contas' },
	{ id: 'DM', name: 'Demonstrativo Mensal' },
	{ id: 'AM', name: 'Demonstrativo de Arrecadação e Demonstrativo Mensal' },
];

const BoxModeloDemonstrativo = () => {
	const { modeloDemonstrativo, setModeloDemonstrativo } = useContext(BoletosContext);
	return (
		<Box display='flex' mb='1em' mt='0.5em'>
			<Autocomplete
				value={modeloDemonstrativo || optionsModeloDemonstrativo[0]}
				options={optionsModeloDemonstrativo}
				getOptionLabel={(o) => o.name}
				onChange={(_, newValue) => setModeloDemonstrativo(newValue)}
				renderInput={(params) => <TextField {...params} variant='outlined' label='Modelo de Demonstrativo' />}
				clearText='Limpar'
				autoHighlight
				noOptionsText='Nenhum modelo disponível'
				size='small'
				fullWidth
			/>
		</Box>
	);
};

const BoxConfigGeracaoBoleto = () => {
	const { modeloDemonstrativo, loading } = useContext(BoletosContext);
	const [expandedArrecadacao, setExpandedArrecadacao] = useState(true);
	const [expandedPresConta, setExpandedPresConta] = useState(true);
	const [expandedComunicado, setExpandedComunicado] = useState(false);
	const [expandedDemonsMensal, setExpandedDemonsMensal] = useState(true);
	const [expandedConfiguracaoBoleto, setExpandedConfiguracaoBoleto] = useState(false);

	if (loading)
		return (
			<Box style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress size={40} />
			</Box>
		);

	return (
		<Box>
			<BoxModeloDemonstrativo />
			{[
				optionsModeloDemonstrativo[0].id,
				optionsModeloDemonstrativo[2].id,
				optionsModeloDemonstrativo[4].id,
			].includes(modeloDemonstrativo.id) && (
				<Box style={{ marginTop: '0.5em' }}>
					<Button
						onClick={() => {
							setExpandedArrecadacao(!expandedArrecadacao);
						}}
						variant='outlined'
						color='primary'
						fullWidth
						size='medium'
						startIcon={!expandedArrecadacao ? <ChevronRightIcon /> : <ExpandMoreIcon />}
					>
						Demonstrativo de Arrecadação
					</Button>
					<Collapse in={expandedArrecadacao} style={{ margin: '0.5em' }}>
						<BoxConfigDemonstrativoArrecadacao />
						<BoxConfigValorUnidadeSubordinada />
						<BoxConfigResumoRateio />
						<BoxConfigResumoRateioValorTotal />
						<BoxConfigDevedores />
					</Collapse>
				</Box>
			)}
			{[optionsModeloDemonstrativo[1].id, optionsModeloDemonstrativo[2].id].includes(modeloDemonstrativo.id) && (
				<Box>
					<Button
						onClick={() => {
							setExpandedPresConta(!expandedPresConta);
						}}
						variant='outlined'
						color='primary'
						fullWidth
						size='medium'
						startIcon={!expandedPresConta ? <ChevronRightIcon /> : <ExpandMoreIcon />}
					>
						Prestação de Contas
					</Button>
					<Collapse in={expandedPresConta} style={{ margin: '0.5em' }}>
						<BoxConfigPrestacaoDeContas />
					</Collapse>
				</Box>
			)}
			{[optionsModeloDemonstrativo[3].id, optionsModeloDemonstrativo[4].id].includes(modeloDemonstrativo.id) && (
				<Box>
					<Button
						onClick={() => {
							setExpandedDemonsMensal(!expandedDemonsMensal);
						}}
						variant='outlined'
						color='primary'
						fullWidth
						size='medium'
						startIcon={!expandedDemonsMensal ? <ChevronRightIcon /> : <ExpandMoreIcon />}
					>
						Demonstrativo Mensal
					</Button>
					<Collapse in={expandedDemonsMensal} style={{ margin: '0.5em' }}>
						<BoxConfigDemonstrativoMensal />
					</Collapse>
				</Box>
			)}
			<Box>
				<Button
					onClick={() => {
						setExpandedComunicado(!expandedComunicado);
					}}
					variant='outlined'
					color='primary'
					fullWidth
					size='medium'
					startIcon={!expandedComunicado ? <ChevronRightIcon /> : <ExpandMoreIcon />}
				>
					Configurações do Comunicado
				</Button>
				<Collapse in={expandedComunicado} style={{ margin: '0.5em' }}>
					<ComunicadoTemplate />
				</Collapse>
			</Box>
			<Box>
				<Button
					onClick={() => {
						setExpandedConfiguracaoBoleto(!expandedConfiguracaoBoleto);
					}}
					variant='outlined'
					color='primary'
					fullWidth
					size='medium'
					startIcon={!expandedConfiguracaoBoleto ? <ChevronRightIcon /> : <ExpandMoreIcon />}
				>
					Configurações Gerais do Boleto
				</Button>
				<Collapse in={expandedConfiguracaoBoleto} style={{ margin: '0.5em' }}>
					<BoxConfigMarcaDAgua />
					<BoxConfigProprietarioEInquilino />
				</Collapse>
			</Box>
		</Box>
	);
};

export default BoxConfigGeracaoBoleto;
