import React from 'react';
import { List, Pagination, TextField } from 'react-admin';
import brExporter from '../../../utils/exporter';
import { tipoOpcoes } from '../../../fieldControl/tipoPessoa';
import { situacaoOpcoes } from '../../../fieldControl/situacao';
import { Tabela } from '../../common/Tabela';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Padrao } from '../../common/filtros/Padrao';
import { cpfFormatacao, cnpjFormatacao } from '../../../fieldControl/documento';
import { AdvogadoFieldProps, CustomPaginationProps, CustomTextFieldProps, DocumentoAdvogadoFieldProps } from './types';

const TipoField: React.FC<AdvogadoFieldProps> = ({ record }) => (
	<span>{record?.tipo_pessoa === 'J' ? 'Jurídica' : 'Física'}</span>
);

const SituacaoField: React.FC<AdvogadoFieldProps> = ({ record }) => (
	<span>{record?.situacao === 'A' ? 'Ativo' : 'Inativo'}</span>
);

const DocumentoField: React.FC<DocumentoAdvogadoFieldProps> = ({ record }) =>
	record?.documento && record?.documento.length === 11 ? (
		<span>{cpfFormatacao(record?.documento)}</span>
	) : (
		<span>{cnpjFormatacao(record?.documento)}</span>
	);

const CustomTextField: React.FC<CustomTextFieldProps> = (props: object) => <TextField {...props} />;

const CustomPagination: React.FC<CustomPaginationProps> = (props: object) => (
	<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} {...props} />
);

const TabelaAdvogados = (props: object) => (
	<Tabela rowClick='edit' {...props}>
		<TipoField
			source='tipo_pessoa'
			label='Tipo'
			filtro={
				<Autocompletar
					opcoes={tipoOpcoes}
					id={undefined}
					label={undefined}
					source={undefined}
					refResource={undefined}
					refName={undefined}
					mostrarInativos={undefined}
				/>
			}
		/>
		<CustomTextField source='nome' label='Nome' filtro={<Padrao />} />
		<DocumentoField source='documento' label='CNPJ/CPF' filtro={<Padrao />} style={{ width: 100 }} />
		<CustomTextField source='numero_oab' label='Número OAB' filtro={<Padrao />} />
		<SituacaoField
			source='situacao'
			label='Situação'
			filtro={
				<Autocompletar
					opcoes={situacaoOpcoes}
					id={undefined}
					label={undefined}
					source={undefined}
					refResource={undefined}
					refName={undefined}
					mostrarInativos={undefined}
				/>
			}
		/>
	</Tabela>
);

export const AdvogadoList = (props: object) => (
	<List
		{...props}
		exporter={brExporter}
		empty={false}
		bulkActionButtons={false}
		title='Advogados'
		filterDefaultValues={{ situacao: 'A' }}
		sort={{ field: 'nome', order: 'ASC' }}
		perPage={10}
		pagination={<CustomPagination labelRowsPerPage='Advogados por página' />}
	>
		<TabelaAdvogados />
	</List>
);
