import OcorrenciaCreate from './OccurrenceCreate';
import OcorrenciaEdit from './OccurrenceEdit';
import OccurrenceList from './OccurrenceList';

const resource = {
	list: OccurrenceList,
	edit: OcorrenciaEdit,
	create: OcorrenciaCreate,
};

export default resource;
