import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = makeStyles({
	root: {
		backgroundColor: 'rgba(0,0,0,.8)',
		position: 'fixed',
		zIndex: '99999',
		width: '100vw',
		height: '100vh',
		top: '0px',
		left: '0px',

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

function LoadingOverlay() {
	const classes = styles();
	return (
		<div className={classes.root}>
			<CircularProgress size={72.5} />
		</div>
	);
}

export default LoadingOverlay;
