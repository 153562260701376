import React from 'react';

import Typography from '@material-ui/core/Typography';

import { CustomDialogBody } from './ModalContext';

const ModalConfirmacao = ({ Context, title, handleSubmit }) => (
	<CustomDialogBody
		Context={Context}
		title={
			<>
				<Typography>{title}</Typography>
			</>
		}
		customSubmitLabel='Confirmar'
		form={{ handleSubmit, valid: true }}
	/>
);

export default ModalConfirmacao;
