/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Edit, TextInput, maxLength, required, useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin';
import { Button } from '@material-ui/core';
import { CustomDataProvider, OccurrenceEditFormProps, OccurrenceProps, OccurrenceResponse, Unity } from './types';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { BoxDivisor, FormularioBox, TextInputSyndikos } from 'components/common/Formulario';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Grid, Tab, TextField } from '@material-ui/core';
import VeryHighLimitReferenceInput from 'components/common/VeryHighLimitReferenceInput';
import { SyndikosSelect } from 'components/common/SyndikosSelect';
import { SyndikosKeyboardDatePicker, SyndikosMuiPickersUtilsProvider } from 'components/common/InputDate';
import InputFile from 'components/common/InputFile';
import { TipoModalInput } from 'components/common/TipoModalInput';
import { Field } from 'react-final-form';
import { MessagesHistory } from './MessageHistory/OccurrenceMessageHistory';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-final-form';
import { Pessoa } from 'types/tpyesGlobal';
import FinalizarOcorrenciaModal from './modals/FinalizarOcorrenciaModal';

const typeOptions = [
	{ id: 'OC', name: 'Ocorrência no Condomínio' },
	{ id: 'DN', name: 'Denúncia' },
];

const dictLabels = {
	A: 'Em Andamento',
	F: 'Finalizada',
	E: 'Excluída',
	I: 'Inativa',
};

const UnityField = ({
	setSelectedUnidadeId,
	disabled,
}: {
	setSelectedUnidadeId: (id: number | null) => void;
	disabled: boolean;
}) => {
	const { change, getState } = useForm();

	const {
		values: { unidade, condominio },
	} = getState();

	const [{ unidades, unidadesPorId }, setUnidades] = useState<any>({ unidades: [], unidadesPorId: {} });

	const dataProvider: CustomDataProvider = useDataProvider();

	useEffect(() => {
		if (!condominio) {
			change('unidade', null);
			setSelectedUnidadeId(null);
			return setUnidades({ unidades: [], unidadesPorId: {} });
		}
		if (unidade && unidades.length > 0 && condominio !== unidadesPorId[unidade].condominio_id) {
			change('unidade', null);
			setSelectedUnidadeId(null);
		}
		const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
		dataProvider
			.getSimple('unidades', {
				pagination: { perPage: 100000, page: 1 },
				sort: { field: 'nome_unidade_com_extras', order: 'ASC' },
				filter: { id_condominio: condominio },
				cancelToken,
			})
			.then((response: any) => {
				response.data.results.forEach((unidade: Unity) => {
					unidade.unidade = unidade.nome_grupo
						? `${unidade.nome_grupo} - ${unidade.tipo_unidade} ${unidade.unidade}`
						: `${unidade.tipo_unidade} ${unidade.unidade}`;
				});
				setUnidades({
					unidades: response.data.results,
					unidadesPorId: response.data.results.reduce(
						(unidadesPorId: { [key: number]: Unity }, unidade: Unity) => {
							unidadesPorId[unidade.id] = unidade;
							return unidadesPorId;
						},
						{}
					),
				});
			})
			.catch((e: any) => {
				if (axios.isCancel(e)) return;
			});
		return () => {
			cancelRequest();
		};
	}, [condominio]);

	return (
		<Field name='unidade' validate={required()}>
			{({ input, meta: { touched, error } }) => (
				<Autocomplete
					fullWidth
					style={{ marginBottom: 5 }}
					options={unidades}
					value={unidadesPorId[input.value] || null}
					onChange={(_, newValue) => {
						input.onChange(newValue.id);
						setSelectedUnidadeId(newValue.id);
					}}
					renderOption={(option) => option.unidade}
					getOptionLabel={(option) => option.unidade}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='dense'
							fullWidth
							label={'Unidade'}
							required={true}
							error={!!(touched && error)}
							helperText={touched && error}
						/>
					)}
					disableClearable
					noOptionsText='Nenhum resultado'
					disableListWrap
					disabled={disabled}
				/>
			)}
		</Field>
	);
};

const typeResponsible: { [key: string]: string } = {
	P: 'Proprietário(a)',
	I: 'Inquilino(a)',
	D: 'Dependente',
};

const ResponsibleField = ({ selectedUnidadeId, disabled }: { selectedUnidadeId: number | null; disabled: boolean }) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	const { change, getState } = useForm();

	const {
		values: { responsavel },
	} = getState();

	const [{ pessoas, pessoasPorId }, setPessoas] = useState<any>({ pessoas: [], pessoasPorId: {} });

	const dataProvider: CustomDataProvider = useDataProvider();

	useEffect(() => {
		if (!condominioSelecionado?.id || !selectedUnidadeId) {
			change('responsavel', null);
			return setPessoas({ pessoas: [], pessoasPorId: {} });
		}
		if (responsavel && pessoas.length > 0 && selectedUnidadeId !== pessoasPorId[responsavel].id_unidade) {
			change('responsavel', null);
		}
		const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
		dataProvider
			.getSimple('pessoa_unidade', {
				pagination: { perPage: 100000, page: 1 },
				filter: { id_unidade: selectedUnidadeId, data_saida_after_today: true, situacao_pessoa_ativa: true },
				cancelToken,
			})
			.then((response: any) => {
				setPessoas({
					pessoas: response.data.results,
					pessoasPorId: response.data.results.reduce(
						(pessoasPorId: { [key: number]: Pessoa }, pessoa: Pessoa) => {
							pessoasPorId[pessoa.id] = pessoa;
							return pessoasPorId;
						},
						{}
					),
				});
			})
			.catch((e: any) => {
				if (axios.isCancel(e)) return;
			});
		return () => {
			cancelRequest();
		};
	}, [selectedUnidadeId]);

	return (
		<Field name='responsavel' validate={required()}>
			{({ input, meta: { touched, error } }) => (
				<Autocomplete
					fullWidth
					style={{ marginBottom: 5 }}
					options={pessoas}
					value={pessoasPorId[input.value] || null}
					onChange={(_, newValue) => {
						input.onChange(newValue.id);
					}}
					renderOption={(option: { tipo: string; nome_responsavel: string; id: number }) =>
						`${typeResponsible[option.tipo]} - ${option.nome_responsavel}`
					}
					getOptionLabel={(option) => `${typeResponsible[option.tipo]} - ${option.nome_responsavel}`}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='dense'
							fullWidth
							label={'Responsável'}
							required={true}
							error={!!(touched && error)}
							helperText={touched && error}
						/>
					)}
					disabled={disabled || !selectedUnidadeId}
					disableClearable
					noOptionsText='Nenhum resultado'
					disableListWrap
				/>
			)}
		</Field>
	);
};

const OccurrenceEditForm: React.FC<OccurrenceEditFormProps> = ({ ...props }) => {
	const { condominioSelecionado, setCondominioPorId } = React.useContext(CondominiosContext);
	const [selectedUnidadeId, setSelectedUnidadeId] = useState<number | null>(props.record?.unidade ?? null);
	const [isFinalizarOcorrenciaModalOpen, setIsFinalizarOcorrenciaModalOpen] = useState(false);

	const [aba, setAba] = useState('0');

	return (
		<FormularioBox
			customBackButton={undefined}
			noActionToolbar={undefined}
			customSaveButton={undefined}
			{...props}
			nome={undefined}
			onClickBotaoRemover={undefined}
			dictLabels={dictLabels}
			getChipClassName={undefined}
			onlyExcluir={undefined}
			onlyInactivate={true}
			edit
		>
			<TabContext value={aba}>
				<Box flex={1} mr='1em'>
					<Box display='flex'>
						<TabList
							value={aba}
							onChange={(_, newValue) => {
								setAba(newValue);
							}}
							aria-label='simple tabs example'
						>
							<Tab label='Informações da Ocorrência' value='0' />
							<Tab label='Histórico de Mensagens' value='1' />
						</TabList>
					</Box>
					<TabPanel value={aba} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Box display={aba === '0' ? 'block' : 'none'}>
							<BoxDivisor titulo={'Dados Cadastrais'}>
								<Box display='flex' flex={1} gridGap='2em' ml={1}>
									<Box width={'50%'}>
										<VeryHighLimitReferenceInput
											source='condominio'
											reference='condominios'
											label='Condomínio'
											fullWidth
											variant='outlined'
											validate={required()}
											onChange={(value: number) => {
												setCondominioPorId(value);
												setSelectedUnidadeId(null);
											}}
											filter={{ situacao: 'A' }}
											disabled={true}
											initialValue={condominioSelecionado?.id}
										>
											<SyndikosSelect
												optionText='nome'
												style={{ marginBottom: 24 }}
												disableClearable
												disabled={true}
											/>
										</VeryHighLimitReferenceInput>
									</Box>
									<Box width={'50%'} display={'flex'} gridGap={'2em'}>
										<UnityField setSelectedUnidadeId={setSelectedUnidadeId} disabled={true} />
									</Box>
								</Box>
							</BoxDivisor>
							<Box display='flex' flex={1} gridGap='2em' ml={1}>
								<ResponsibleField selectedUnidadeId={selectedUnidadeId} disabled={true} />
								<SyndikosMuiPickersUtilsProvider>
									<SyndikosKeyboardDatePicker
										fullWidth
										source='data'
										label='Data'
										required
										format='dd/MM/yyyy'
										id='date-picker-inline'
										mask='__/__/____'
										parse={(value: Date) => {
											try {
												return value.toISOString().split('T')[0];
											} catch {
												return value;
											}
										}}
										validate={required('Obrigatório')}
										maxDate={undefined}
										maxDateMessage={undefined}
										handleChange={undefined}
										{...props}
										disabled={true}
									/>
								</SyndikosMuiPickersUtilsProvider>
							</Box>
							<Box display='flex' flex={1} ml={1} mt={2}>
								<TextInput
									label='Assunto'
									source='assunto'
									variant='outlined'
									size='small'
									fullWidth
									disabled={true}
									validate={[required(), maxLength(100, 'Máximo de 100 caractéres')]}
								/>
							</Box>
							<Box display='flex' flex={1} ml={1} mb={1.5}>
								<Field name={'descricao'}>
									{({ input, meta }) => (
										<TextInputSyndikos
											label='Descrição'
											source='descricao'
											variant='outlined'
											multiline
											fullWidth
											rows={8}
											validate={[required(), maxLength(500, 'Máximo de 500 caractéres')]}
											helperText={
												meta?.error ? meta?.error : `${(input?.value || '').length}/500`
											}
											defaultValue=''
											disabled={true}
										/>
									)}
								</Field>
							</Box>
							<Box display='flex' flex={0.5} ml={1}>
								<Field name='write_only_anexo' id='write_only_anexo'>
									{({ input }) => (
										<InputFile
											source='write_only_anexo'
											label='Arquivo em anexo'
											variant='outlined'
											onCallBack={(e: any, dataUrl: string) => {
												input.onChange(dataUrl);
											}}
											fileDataUrl={props.record?.write_only_anexo}
											onlyVisualizar={
												(Boolean(props.record?.write_only_anexo) &&
													props.record?.situacao === 'F') ||
												condominioSelecionado?.situacao === 'I'
											}
											disabled={true}
										/>
									)}
								</Field>
							</Box>
							<BoxDivisor titulo='Configuração' mt={5}>
								<Grid container spacing={4} alignItems='flex-start'>
									<Grid item xs={6}>
										<Box height='100%' marginLeft={2}>
											<SyndikosSelect
												source='tipo'
												label='Tipo'
												choices={typeOptions}
												validate={required()}
												fullWidth
												delay={0}
												disabled={
													(props.record?.situacao &&
														['F', 'I'].includes(props.record.situacao)) ||
													condominioSelecionado?.situacao === 'I'
												}
											/>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box height='calc(100% + 20px)' marginRight={1}>
											<TipoModalInput
												source='classificacao'
												resource='classificacao_ocorrencia'
												label='Classificação'
												variant='outlined'
												tipoRegistro='classificacao'
												fullWidth
												validate={required()}
												disabled={
													(props.record?.situacao &&
														['F', 'I'].includes(props.record.situacao)) ||
													condominioSelecionado?.situacao === 'I'
												}
											/>
										</Box>
									</Grid>
								</Grid>
								{props.record!.situacao == 'A' && (
									<Box display={'flex'} justifyContent={'center'} mt={5}>
										<Button
											onClick={() => setIsFinalizarOcorrenciaModalOpen(true)}
											color='primary'
											variant='contained'
											style={{
												textTransform: 'capitalize',
											}}
										>
											Finalizar ocorrência
										</Button>
									</Box>
								)}
								{isFinalizarOcorrenciaModalOpen ? (
									<FinalizarOcorrenciaModal
										closeFinalizarOcorrenciaModal={() => setIsFinalizarOcorrenciaModalOpen(false)}
										ocorrenciaId={props.record!.id}
									/>
								) : null}
							</BoxDivisor>
						</Box>
						<Box
							display={aba === '1' ? 'flex' : 'none'}
							sx={
								(aba !== '1' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									zIndex: -1000,
								}) || { width: '100%' }
							}
						>
							<MessagesHistory ocorrencia={props.record!} />
						</Box>
					</TabPanel>
				</Box>
			</TabContext>
		</FormularioBox>
	);
};

const OccurrenceEdit: React.FC<OccurrenceProps> = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response: OccurrenceResponse) => {
		const data = response?.data;
		if (data) {
			notify('Ocorrencia alterada com sucesso');
			redirect('list', props.basePath);
			refresh();
		}
	};

	return (
		<Edit component='div' title={'Editar Ocorrência'} onSuccess={onSuccess} mutationMode='pessimistic' {...props}>
			<OccurrenceEditForm record={undefined} {...props} />
		</Edit>
	);
};

export default OccurrenceEdit;
