import React from 'react';
import { CustomDialogBody } from '../../../common/ModalContext';

const ModalConfirmarLeituraAtualLTLeituraAnterior = ({ functionLancarLeitura }) => {
	const handleSubmit = () => {
		functionLancarLeitura();
	};

	return (
		<CustomDialogBody
			title='Lançamento de leitura atual menor que a leitura anterior'
			text='Ao lançar uma leitura atual a menor que a leitura anterior o consumo será igual a leitura atual. Confirmar esse lançamento?'
			form={{ handleSubmit, valid: true }}
			customSubmitLabel='Sim'
		/>
	);
};

export default ModalConfirmarLeituraAtualLTLeituraAnterior;
