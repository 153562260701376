import React, { useContext, useState } from 'react';

import EditIcon from '@material-ui/icons/Edit';

import { TooltipIconButton } from '../../../common/TooltipIconButton';
import { TreeTableContext } from '../contexts/TreeTableContext';
import MenuTipoConta, { menuItemsPorTipoContas } from './MenuTipoConta';
import RowContext from '../contexts/RowContext';

const ActionsCellRenderer = () => {
	const {
		value: { tipo: tipoModel },
	} = useContext(TreeTableContext);
	const { tipo, atualizaTipo } = useContext(RowContext);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickItem = (novoTipo) => {
		if (tipo !== novoTipo) {
			atualizaTipo(novoTipo);
			handleClose();
		}
	};

	return (
		<>
			<TooltipIconButton onClick={handleClick} title='Editar Tipo'>
				<EditIcon />
			</TooltipIconButton>
			<MenuTipoConta
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				handleClose={handleClose}
				handleClickItem={handleClickItem}
				disabledItem={tipo}
				items={menuItemsPorTipoContas[tipoModel] || []}
			/>
		</>
	);
};

export default ActionsCellRenderer;
