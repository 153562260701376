import React from 'react';
import UserMenuItem from 'components/common/UserMenuItem';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

type MenuTvSciProps = {
	url: string;
};

const MenuTvSciSyndkos: React.FC<MenuTvSciProps> = ({ url }) => (
	<UserMenuItem
		menuItemProps={{
			href: url,
			rel: 'noopener',
			target: '_blank',
		}}
		label='TV SCI Syndkos'
		icon={<OndemandVideoIcon />}
	/>
);

export default MenuTvSciSyndkos;
