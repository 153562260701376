import React, { useContext } from 'react';

import { FinalFormCurrencyInput } from '../../../../../common/CurrencyInput';

import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';

const FatorConversaoField = ({ unidade_medida, fator_convercao }) => {
	const { setDadosConfig } = useContext(EditaConfiguracaoLeituraContext);
	return (
		<FinalFormCurrencyInput
			decimalScale={2}
			disabled={unidade_medida !== 'm'}
			fullWidth
			id='fator_convercao'
			label='Fator de Conversão'
			margin='dense'
			name='fator_convercao'
			onChange={(event) => {
				if (!event.target.value) event.target.value = 0;
				if (event.target.value > 999999999.999999) event.target.value = 999999999.999999;
				setDadosConfig((v) => ({
					...v,
					fator_convercao: event.target.value,
				}));
			}}
			value={fator_convercao}
		/>
	);
};

export default FatorConversaoField;
