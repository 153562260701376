import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';

import TextField from '@material-ui/core/TextField';

export const optionsTipoLancamento = [
	{ name: 'Condomínio', id: 'C' },
	{ name: 'Grupo', id: 'G' },
	{ name: 'Unidade', id: 'U' },
];

const SelectTipoLancamento = ({
	setValue,
	value,
	unidade,
	grupo,
	setGrupo,
	setUnidade,
	operacao,
	disabled,
	gruposData,
	unidadesData,
	...rest
}) => {
	const [optionsExtra, setOptionsExtra] = useState([]);
	const [renderReady, setRenderReady] = useState(false);

	const setOptions = (v) => {
		if ((v || value) === 'G' && gruposData) {
			setOptionsExtra(gruposData);
		} else if ((v || value) === 'U' && unidadesData) {
			setOptionsExtra(unidadesData);
		}
	};

	useEffect(setOptions, [gruposData, unidadesData]);

	const delayRender = () => {
		setTimeout(() => setRenderReady(true), 100);
	};

	useEffect(delayRender, []);

	return (
		<>
			<Autocomplete
				options={optionsTipoLancamento}
				renderInput={(params) => <TextField {...params} margin='dense' label='Tipo de Lançamento' />}
				getOptionLabel={(option) =>
					option && (option.name || optionsTipoLancamento.filter((v) => v.id === option)[0].name)
				}
				noOptionsText='Nenhum resultado encontrado'
				getOptionSelected={(option, v) => v === option.id}
				value={value}
				onChange={(_, v) => {
					setValue(v ? v.id : null);
					setOptions(v.id);
				}}
				clearText='Limpar'
				getOptionDisabled={(option) => option?.id === 'U' && operacao !== 'receita'}
				{...rest}
			/>
			{(value === 'U' || value === 'G') && optionsExtra && renderReady && (
				<Autocomplete
					options={optionsExtra}
					renderInput={(params) => (
						<TextField {...params} margin='dense' label={value === 'U' ? 'Unidade' : 'Grupo'} />
					)}
					noOptionsText='Nenhum resultado encontrado'
					value={(value === 'U' ? unidade : grupo) || null}
					getOptionSelected={(option, v) => v === option.id}
					getOptionLabel={(option) =>
						optionsExtra.filter((v) => v.id === option)[0]?.[value === 'U' ? 'unidade' : 'nome'] ||
						(option && option?.[value === 'U' ? 'unidade' : 'nome']) ||
						''
					}
					onChange={(_, v) => (value === 'U' ? setUnidade(v ? v.id : null) : setGrupo(v ? v.id : null))}
					clearText='Limpar'
				/>
			)}
		</>
	);
};

export default SelectTipoLancamento;
