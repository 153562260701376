import React from 'react';

import { cepFormatacao } from '../../fieldControl/cep';

export const tipoLogradouroAbrevFormatador = (abrev) => (abrev ? `${abrev}.` : '');

export const enderecoFormatador = (endereco, tipoLogradouroAbrev) =>
	[tipoLogradouroAbrevFormatador(tipoLogradouroAbrev), endereco].filter((part) => Boolean(part)).join(' ');

const PainelExpandivelEnderecoResponsavel = ({ record }) => {
	if (!record) return null;

	const { cep, tipo_logradouro = {}, endereco, numero, bairro, cidade, uf, complemento_endereco } = record || {};
	const { abrev = '' } = tipo_logradouro || {};

	const enderecoString = [
		[enderecoFormatador(endereco, abrev), numero].filter((part) => Boolean(part)).join(', '),
		[bairro, cidade].filter((part) => Boolean(part)).join(', '),
		[uf, cepFormatacao(cep)].filter((part) => Boolean(part)).join(', '),
		complemento_endereco,
	]
		.filter((part) => Boolean(part))
		.join(' - ');

	if (!enderecoString) return null;

	return <span>Endereço: {enderecoString}</span>;
};

export default PainelExpandivelEnderecoResponsavel;
