import { isBefore, parse } from 'date-fns';

// Facilitadores
const tryOrNull = (fn, props) => {
	try {
		return fn(props);
	} catch {
		return null;
	}
};

export const iterArrayValidacoes = ({ validacoes = [], values, options, errors, ...props }) => {
	for (let validate of validacoes) {
		Object.assign(errors, tryOrNull(validate, { values, options, errors, ...props }));
	}
	return errors;
};

// Validações Genericas
export const validateRequiredField = ({ values, field, msg }) => (!values[field] ? { [field]: msg } : null);

export const validateValidValue = ({ values, options, field, msg = 'Valor inválido' }) => {
	const objects = options[field]?.objects;
	const value = values[field];
	if (value && objects && !objects[value?.id ?? value]) return { [field]: msg };
};

export const validateValidDateValue = ({ values, field, msg = 'Data inválida' }) => {
	const value = values[field];
	try {
		(typeof value !== 'string' ? value : new Date(value)).toISOString();
	} catch {
		return { [field]: msg };
	}
};

export const validateRequiredValidValue = ({
	values,
	options,
	field,
	requiredMsg = 'Forneça um valor',
	invalidMsg = 'Forneça um valor válido',
}) => {
	const objects = options[field]?.objects;
	if (objects) {
		const value = values[field];
		if (!value) return { [field]: requiredMsg };
		if (!objects[value?.id ?? value]) return { [field]: invalidMsg };
	}
};

export const validateRequiredNumberField = ({ values, field, msg }) => {
	const value = values[field];
	return isNaN(value) || (typeof value !== 'number' && !value) ? { [field]: msg } : null;
};

export const validateMinValueNumberField = ({ values, field, minValue = 0, msg }) =>
	(typeof values[field] === 'number' && values[field]) < minValue ? { [field]: msg } : null;

export const validateMaxLengthField = ({ values, field, maxValue = 0, msg }) =>
	(values[field]?.length || 0) > maxValue ? { [field]: msg } : null;

export const validateRequiredAndMinValueNumberField = ({
	values,
	errors,
	field,
	requiredMsg = '',
	minValueMsg = '',
	minValue = 0,
}) =>
	validateRequiredNumberField({
		values,
		errors,
		field,
		msg: requiredMsg,
	}) ??
	validateMinValueNumberField({
		values,
		errors,
		field,
		minValue,
		msg: minValueMsg,
	});

// Validação usada em todos os tipos de conta bancária
const validateNomeConta = ({ values }) =>
	validateRequiredField({
		values,
		field: 'nome_conta',
		msg: 'Nome da Conta é um campo obrigatório',
	}) ??
	validateMaxLengthField({
		values,
		field: 'nome_conta',
		maxValue: 60,
		msg: 'Não pode conter mais de 60 caracteres',
	});

const validateTipoConta = ({ values, conta_condominio }) =>
	validateRequiredField({
		values,
		field: 'tipo_conta',
		msg: 'Tipo da Conta é um campo obrigatório',
	}) ?? (values.tipo_conta !== conta_condominio.tipo_conta ? 'Não é possível mudar o tipo da conta' : null);

const validateDataSaldoInicial = ({ values }) =>
	validateRequiredField({
		values,
		field: 'data_saldo_inicial',
		msg: 'Data do Saldo Inicial é um campo obrigatório',
	}) ??
	validateValidDateValue({
		values,
		field: 'data_saldo_inicial',
		msg: 'Data inválida para Saldo Inicial',
	});

const valitadeSaldoInicial = ({ values }) =>
	validateRequiredNumberField({ values, field: 'saldo_inicial', msg: 'Saldo Inicial é um campo obrigatório' });

export const validacoesFieldsTabInformacao = [
	validateNomeConta,
	validateTipoConta,
	validateDataSaldoInicial,
	valitadeSaldoInicial,
];

// Validação usada em todas as instituições que possuem agência
export const validateAgencia = ({ values, options }) =>
	validateValidValue({
		values: { agencia: values.agencia?.id },
		field: 'agencia',
		msg: 'Selecione uma agência pertencente para a instituição bancária selecionada',
		options,
	});

// Validações da aba Emissão de Boletos
export const validateTipoIntegracao = ({ values, errors, options }) =>
	validateRequiredValidValue({
		values,
		errors,
		options,
		field: 'tipo_integracao',
		requiredMsg: 'Selecione um tipo de integração válido para esta instituição',
		invalidMsg: 'Selecione um tipo de integração válido para esta instituição',
	});

// Validações da aba Conta
export const validatesFieldsRequeridosQuandoEmitindoBoletos = [
	{ field: 'agencia', msg: 'Para emitir boletos, é requerido o número da agência' },
	{ field: 'conta_corrente', msg: 'Para emitir boletos, é requerido o número da conta corrente' },
	{ field: 'conta_cedente', msg: 'Para emitir boletos, é requerido o número da conta cedente' },
	{ field: 'aceite', msg: 'Para emitir boletos, é requerido a especificação do aceite' },
].map(
	({ field, msg }) =>
		({ values, errors }) =>
			validateRequiredField({ values, errors, field, msg })
);

export const [
	validateRequiredAgencia,
	validateRequiredContaCorrente,
	validateRequiredContaCedente,
	validateRequiredAceite,
] = validatesFieldsRequeridosQuandoEmitindoBoletos;

export const validateCarteira = ({ values, errors, options }) =>
	validateRequiredValidValue({
		values,
		errors,
		options,
		field: 'carteira',
		requiredMsg: 'Para emitir boletos, é requerido a seleção de uma opção de carteira',
		invalidMsg: 'Selecione uma opção de carteira válida',
	});

export const validateEspecieTitulo = ({ values, errors, options }) =>
	validateRequiredValidValue({
		values,
		errors,
		options,
		field: 'especie_titulo',
		requiredMsg: 'Para emitir boletos, é requerido a seleção de uma opção de espécie de título',
		invalidMsg: 'Selecione uma opção de espécie de título válida',
	});

export const validateMoeda = ({ values, errors, options }) =>
	validateRequiredValidValue({
		values,
		errors,
		options,
		field: 'moeda',
		requiredMsg: 'Para emitir boletos, é requerido a seleção de uma opção de moeda',
		invalidMsg: 'Selecione uma opção de moeda válida',
	});

export const validateEmissaoBoleto = ({ values, errors, options }) =>
	validateRequiredValidValue({
		values,
		errors,
		options,
		field: 'emissao_boleto',
		requiredMsg: 'Para emitir boletos, é requerido a seleção de uma opção de emissão do boleto',
		invalidMsg: 'Selecione uma opção de emissão do boleto válida',
	});

export const validateDistribuicaoBoleto = ({ values, errors, options }) =>
	validateRequiredValidValue({
		values,
		errors,
		options,
		field: 'distribuicao_boleto',
		requiredMsg: 'Para emitir boletos, é requerido a seleção de uma opção de distribuição do boleto',
		invalidMsg: 'Selecione uma opção de distribuição do boleto válida',
	});

export const validateNossoNumero = ({ values, errors }) =>
	validateRequiredAndMinValueNumberField({
		values,
		errors,
		field: 'nosso_numero',
		minValue: 0,
		requiredMsg: 'Para emitir boletos, o nosso número é requerido',
		minValueMsg: 'O nosso número não pode ser menor do que 0/zero',
	});

export const validateSequencialArquivo = ({ values, errors }) =>
	validateRequiredAndMinValueNumberField({
		values,
		errors,
		field: 'sequencial_arquivo',
		minValue: 0,
		requiredMsg: 'Para emitir boletos, o sequencial de arquivo é requerido',
		minValueMsg: 'O sequencial de arquivo não pode ser menor do que 0/zero',
	});

export const validateLocalPagamento = ({ values, errors }) =>
	validateRequiredField({
		values,
		errors,
		field: 'local_pagamento',
		msg: 'Para emitir boletos, é requerido o local de pagamento',
	});

// Validações da aba Instruções Cobrança Remessa
export const validateSacadorAvalista = ({ values, errors, options }) =>
	validateRequiredValidValue({
		values,
		errors,
		options,
		field: 'sacador_avalista',
		requiredMsg: 'Para emitir boletos, é requerido a seleção de uma opção de sacador/avalista',
		invalidMsg: 'Selecione uma opção de sacador/avalista válida',
	});

// Validações da aba Instruções Cobrança Boleto
export const validateLinha = ({ values, numeroLinha, maxValue = 0 }) => {
	const value = values[`linha_${numeroLinha}`] ?? '';
	return value
		? validateMaxLengthField({
				values,
				maxValue: maxValue,
				field: `linha_${numeroLinha}`,
				msg: `Linha ${numeroLinha}: Máximo excedido: ${value.length}/${maxValue}`,
		  })
		: null;
};

export const createValidateLinhas =
	({ numeroLinhas = 5, maxValue = 110 } = {}) =>
	({ values }) =>
		Array.from({ length: numeroLinhas }, (_, i) => i + 1).reduce(
			(errors, numeroLinha) => Object.assign(errors, validateLinha({ values, numeroLinha, maxValue })),
			{}
		);

const formataInvalidTipoMsg = (nomeCampoLegivel) =>
	`Opção de ${nomeCampoLegivel} inválida para essa instituição financeira`;

export const validateTipo =
	({ field, msg, tipo_field, nomeCampoLegivel = '' }) =>
	({ values, errors, options }) => {
		const campo = tipo_field ?? `${field}_tipo`;
		if (errors[campo]) return null;
		if (!options[campo]?.objects[values[campo]?.id]) {
			return { [campo]: msg ?? formataInvalidTipoMsg(nomeCampoLegivel) };
		}
	};

export const validateValor =
	({ field, valorIsensao, valoresIsensao, msg, minValor, msgErroMinValor, maxValor, msgErroMaxValor }) =>
	({ errors, values }) => {
		const campo = `${field}_valor`;
		if (errors[campo]) return null;

		const tipo = values[`${field}_tipo`];
		if (valorIsensao && tipo?.id === valorIsensao) return null;
		if (valoresIsensao?.length && valoresIsensao.includes(tipo?.id)) return null;

		const value = values[campo];
		if (typeof value !== 'number' && !value) return { [campo]: msg };

		if (typeof value === 'number' && (msgErroMinValor || msgErroMaxValor)) {
			if (msgErroMinValor && value < minValor) return { [campo]: msgErroMinValor };

			if (msgErroMaxValor && value > maxValor) {
				return { [campo]: msgErroMaxValor };
			}
		}

		return null;
	};

export const validateDias =
	({ funcFormataPropsDias = ({ props }) => props, ...props }) =>
	({ errors, values, ...rest }) => {
		const {
			field,
			tipo_field,
			dias_field,
			valorIsensao,
			valoresIsensao,
			minValue = 0,
			maxValue,
			msgErroVazio,
			msgErroMinValue,
			msgErroMaxValue,
		} = funcFormataPropsDias({ ...rest, props, errors, values });

		const campo = dias_field ?? `${field}_dias`;
		if (errors[campo]) return null;

		const value = values[campo];
		const tipo = values[tipo_field ?? `${field}_tipo`];
		if (valorIsensao && tipo?.id === valorIsensao) return null;
		if (valoresIsensao?.length && valoresIsensao.includes(tipo?.id)) return null;

		if (typeof value !== 'number' && !value) return { [campo]: msgErroVazio };

		if (typeof value === 'number') {
			if (value < minValue) return { [campo]: msgErroMinValue };

			if (maxValue !== undefined && value > maxValue) {
				return { [campo]: msgErroMaxValue };
			}
		}

		return null;
	};

export const createValidateDadosIntegracaoAPI =
	({
		campo = 'dados_integracao_api_expires_at',
		msgRequired = 'Os dados da integração de API são necessários para a emissão de boletos',
		msgInvalid = 'Os dados para a integração com a API expiraram',
	} = {}) =>
	({ values }) => {
		let requiredError = validateRequiredField({ values, field: campo, msg: msgRequired });
		if (requiredError) return requiredError;
		const finalDeHoje = parse('24:00:00', 'kk:mm:ss', new Date());
		const expiresAtDate = parse(values[campo], 'yyyy-MM-dd', new Date());
		const isInvalid = !isBefore(finalDeHoje, expiresAtDate);
		if (isInvalid) return { [campo]: msgInvalid };
		return null;
	};
