import React, { useContext } from 'react';

import { NumberInput } from 'react-admin';

import { useForm } from 'react-final-form';

import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import { createGetPropsValidas } from '../utils/functions';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const propNames = [
	'source',
	'name',
	'defaultValue',
	'disabled',
	'fullWidth',
	'helperText',
	'label',
	'parse',
	'variant',
	'margin',
	'min',
	'max',
	'step',
];

const getPropsValidas = createGetPropsValidas(propNames);

const EditaContaBancariaNumberInput = ({
	source,
	disabled: standardDisabled = false,
	helperText: standardHelperText = false,
	...standardProps
}) => {
	const { getFieldState } = useForm();
	const state = getFieldState(source);
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { props } = useContext(DadosPorTipoIntegracaoContext);
	const { disabled = false, helperText = false, ...sourceProps } = props[source] || {};

	return (
		<NumberInput
			source={source}
			disabled={isInativo || disabled || standardDisabled}
			error={!!state && !!state.error}
			helperText={(!!state && (state.error ? state.error : false)) || helperText || standardHelperText}
			variant='outlined'
			step={1}
			fullWidth
			{...getPropsValidas({ ...standardProps, ...sourceProps })}
		/>
	);
};

export default EditaContaBancariaNumberInput;
