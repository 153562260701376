import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormGroup, Checkbox } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';

const BoxConfigResumoRateioValorTotal = () => {
	const {
		opcoesImpressao: { imprimir_valor_total },
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	return (
		<>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_valor_total}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_valor_total')}
							name='imprimir_valor_total'
						/>
					}
					label='Imprimir valor total'
				/>
			</FormGroup>
		</>
	);
};

export default BoxConfigResumoRateioValorTotal;
