import { CondominiosContext } from 'context/CondominioContextProvider';
import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { CustomDataProvider, TStatusCondominios } from './types';
import { AdminContext } from 'components/common/AdminContextProvider';

export type TStatusLicencasContext = {
	condominiosAtivos: number;
	setCondominiosAtivos: React.Dispatch<React.SetStateAction<number>>;
	appsAtivos: number;
	setAppsAtivos: React.Dispatch<React.SetStateAction<number>>;
	statusCondominios: TStatusCondominios;
	setStatusCondominios: React.Dispatch<React.SetStateAction<TStatusCondominios>>;
	limiteCondominios: number;
	setLimiteCondominios: React.Dispatch<React.SetStateAction<number>>;
	limiteApps: number;
	setLimiteApps: React.Dispatch<React.SetStateAction<number>>;
	atualizarDadosCondominio: (recordId: string, situacao: string, disponivelParaKondomynos: boolean) => void;
};

export const StatusLicencasContext = createContext<TStatusLicencasContext>({
	condominiosAtivos: 0,
	setCondominiosAtivos: () => {},
	appsAtivos: 0,
	setAppsAtivos: () => {},
	statusCondominios: {},
	setStatusCondominios: () => {},
	limiteCondominios: 0,
	setLimiteCondominios: () => {},
	limiteApps: 0,
	setLimiteApps: () => {},
	atualizarDadosCondominio: () => {},
});

export const StatusLicencasContextProvider = ({ children }: { children: React.ReactNode }) => {
	const { dadosAdministradora } = useContext(AdminContext);
	const [condominiosAtivos, setCondominiosAtivos] = useState(0);
	const [appsAtivos, setAppsAtivos] = useState(0);
	const [statusCondominios, setStatusCondominios] = useState<TStatusCondominios>({});

	const [limiteCondominios, setLimiteCondominios] = useState(0);
	const [limiteApps, setLimiteApps] = useState(0);

	const dp = useDataProvider() as CustomDataProvider;
	const [todosOsCondominios, setTodosOsCondominios] = useState<any[]>([]);
	const { condominios } = useContext(CondominiosContext);
	useEffect(() => {
		dp.getSimple('condominios', {
			pagination: { page: 1, perPage: 1000 },
			sort: { field: 'nome', order: 'ASC' },
		}).then((result: { data: { results: any } }) => {
			setTodosOsCondominios(result.data.results);
		});
	}, [condominios]);

	useEffect(() => {
		for (const condominio of todosOsCondominios) {
			setStatusCondominios((prevStatusCondominios) => ({
				...prevStatusCondominios,
				[condominio['id']]: {
					situacao: condominio.situacao,
					disponivelParaKondomynos: condominio.disponivel_para_kondomynos,
				},
			}));
		}
	}, [todosOsCondominios]);

	useEffect(() => {
		let condominiosAtivos = 0;
		let appsAtivos = 0;
		for (const condominio in statusCondominios) {
			if (statusCondominios[condominio].situacao === 'A') condominiosAtivos++;
			if (statusCondominios[condominio].disponivelParaKondomynos) appsAtivos++;
		}
		setCondominiosAtivos(condominiosAtivos);
		setAppsAtivos(appsAtivos);
	}, [statusCondominios]);

	useEffect(() => {
		if (dadosAdministradora.limite_condominio === 0) setLimiteCondominios(Infinity);
		else setLimiteCondominios(dadosAdministradora.limite_condominio);

		if (dadosAdministradora.limite_condominio_kondomynos === 0) setLimiteApps(Infinity);
		else setLimiteApps(dadosAdministradora.limite_condominio_kondomynos);
	}, [dadosAdministradora]);

	const atualizarDadosCondominio = useCallback(
		(recordId, situacao, disponivelParaKondomynos) => {
			const dadosCondominio = statusCondominios[recordId];
			setStatusCondominios((prevStatusCondominios: TStatusCondominios) => ({
				...prevStatusCondominios,
				[recordId]: { situacao, disponivelParaKondomynos },
			}));
			if (dadosCondominio?.situacao !== situacao)
				setCondominiosAtivos((numeroCondominiosAtivos) => {
					if (situacao === 'A') return numeroCondominiosAtivos + 1;
					if (dadosCondominio) return numeroCondominiosAtivos - 1;
					return numeroCondominiosAtivos;
				});
			if (dadosCondominio?.disponivelParaKondomynos !== disponivelParaKondomynos)
				setAppsAtivos((numeroCondominiosDisponiveisParaKondomynos) => {
					if (disponivelParaKondomynos) return numeroCondominiosDisponiveisParaKondomynos + 1;
					if (dadosCondominio) return numeroCondominiosDisponiveisParaKondomynos - 1;
					return numeroCondominiosDisponiveisParaKondomynos;
				});
		},
		[statusCondominios]
	);

	const value = useMemo(() => {
		return {
			condominiosAtivos,
			setCondominiosAtivos,
			appsAtivos,
			setAppsAtivos,
			statusCondominios,
			setStatusCondominios,
			limiteCondominios,
			setLimiteCondominios,
			limiteApps,
			setLimiteApps,
			atualizarDadosCondominio,
		};
	}, [
		condominiosAtivos,
		setCondominiosAtivos,
		appsAtivos,
		setAppsAtivos,
		statusCondominios,
		setStatusCondominios,
		limiteCondominios,
		setLimiteCondominios,
		limiteApps,
		setLimiteApps,
		atualizarDadosCondominio,
	]);

	return <StatusLicencasContext.Provider value={value}>{children}</StatusLicencasContext.Provider>;
};
