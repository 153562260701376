import React from 'react';

import { List, Pagination, TextField, useDataProvider, useNotify } from 'react-admin';

import { Decimal } from 'components/common/filtros/Decimal';
import { Padrao } from 'components/common/filtros/Padrao';
import { Tabela } from 'components/common/Tabela';

import { GaragensUnidadeContext } from '../../context';

import ActionsList from '../ActionsList';
import ActionsBulkList from '../ActionsBulkList';
import ValorField from './components/ValorDecimalField';
import LabelField from './components/LabelChip';

function TabelaGaragens(props) {
	const { setContexto } = React.useContext(GaragensUnidadeContext);
	const dP = useDataProvider();
	const notify = useNotify();
	return (
		<Tabela
			{...props}
			clickRowCustom={({ record: { id } }) =>
				() => {
					dP.getOne('garagens_unidades', {
						id: id,
					})
						.then(({ data }) => {
							setContexto({ dadosEditGaragem: data, openModalEditarGaragem: true });
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							notify(`Erro ao tentar abrir modal de edição de garagem: ${e}`, 'warning');
						});
				}}
			disableClickRow={props.record.situacao !== 'A'}
			updateRowRecord
		>
			<TextField label='Nome' source='nome' filtro={<Padrao />} />
			<TextField label='Número' source='numero' filtro={<Padrao />} />
			<TextField label='Marca' source='marca_veiculo' filtro={<Padrao />} />
			<TextField label='Modelo' source='modelo_veiculo' filtro={<Padrao />} />
			<TextField label='Ano' source='ano_veiculo' filtro={<Padrao />} />
			<TextField label='Placa' source='placa_veiculo' filtro={<Padrao />} />
			<TextField label='Cor' source='cor_veiculo' filtro={<Padrao />} />
			<ValorField label='Coeficiente' source='coeficiente' filtro={<Decimal />} align='right' />
		</Tabela>
	);
}

function GaragensList(props) {
	return (
		<List
			{...props}
			empty={false}
			bulkActionButtons={<ActionsBulkList />}
			actions={<ActionsList {...props} />}
			basePath='/garagens_unidades'
			resource='garagens_unidades'
			filter={{
				condominio: props.record.id_condominio,
				unidade: props.record.id,
			}}
			title=' '
			sort={{ field: 'nome', order: 'ASC' }}
			perPage={10}
			pagination={
				<Pagination
					rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
					labelRowsPerPage='Garagens por página'
				/>
			}
		>
			<TabelaGaragens {...props} />
		</List>
	);
}

export default GaragensList;
