import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useListContext } from 'react-admin';
import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';
import { ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';

const EditaConfiguracaoLeituraContextProvider = ({ children, config_leitura = {} }) => {
	const {
		filterValues: { referencia: filterReferencia },
	} = useListContext();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [dadosConfig, setDadosConfig] = useState(config_leitura);
	const [nomeLeitura, setNomeLeitura] = useState(config_leitura.nome_leitura);
	const [id_leitura] = useState(config_leitura.id_leitura);
	const [referencia, setReferencia] = useState(
		new Date(
			`${
				config_leitura.metodo_calculo === 6 && filterReferencia
					? filterReferencia.split('T')[0]
					: config_leitura.referencia
			}T10:00`
		)
	);
	const [situacao, setSituacao] = useState(config_leitura.situacao_leitura);

	const handleClose = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);
	const providerValue = useMemo(
		() => ({
			dadosConfig,
			id_leitura,
			handleClose,
			referencia,
			setDadosConfig,
			setReferencia,
			setSituacao,
			situacao,
			nomeLeitura,
			setNomeLeitura,
		}),
		[
			dadosConfig,
			id_leitura,
			handleClose,
			referencia,
			setDadosConfig,
			setReferencia,
			setSituacao,
			situacao,
			nomeLeitura,
			setNomeLeitura,
		]
	);

	return (
		<EditaConfiguracaoLeituraContext.Provider value={providerValue}>
			{children}
		</EditaConfiguracaoLeituraContext.Provider>
	);
};

export default EditaConfiguracaoLeituraContextProvider;
