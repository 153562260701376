import React from 'react';
import Box from '@material-ui/core/Box';

import EditaContaBancariaAutocomplete from 'components/resources/condominios/ModalEditaContaBancaria/commons/EditaContaBancariaAutocomplete';

const InstrucaoField = ({ numeroInstrucao }) => {
	const source = `instrucao_${numeroInstrucao}`;
	return (
		<Box sx={{ 'max-width': '97%' }}>
			<EditaContaBancariaAutocomplete
				source={source}
				name={source}
				id={source}
				label={`Instrução ${numeroInstrucao}`}
			/>
		</Box>
	);
};

export default InstrucaoField;
