export const tipoResponder = [
	{ id: 'I', name: 'Inquilino' },
	{ id: 'P', name: 'Proprietário' },
	{ id: 'A', name: 'Ambos' },
];

export const enqueteSituacaoOpcoes = [
	{ id: 'E', name: 'Em andamento' },
	{ id: 'F', name: 'Encerrada' },
];
