import { getDadosFormattingContaCorrente } from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';
import DefaultContaNaoCorrenteProps, { defaultComponentesProps } from '../default/ContaNaoCorrenteProps';

const ContaNaoCorrenteProps = {
	...DefaultContaNaoCorrenteProps,
	instituicaoCodigo: '136',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(10),
		},
	},
	getOptions: getFormatarOptions('136', { agencia: 'agencias_unicred' }),
};

export default ContaNaoCorrenteProps;
