import React from 'react';

import PadraoTabInstrucoesCobrancaRemessaBase from '../../../../../tabs/TabInstrucoesCobrancaRemessa';

import DescontoFields from '../../../../../tabs/TabInstrucoesCobrancaRemessa/DescontoFields';
import MultaFields from './MultaFields';
import JurosFields from './JurosFields';

import { ValuesProps } from '../../../../../types';

const TabInstrucoesCobrancaRemessa = (props: ValuesProps) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[]}
	/>
);

export default TabInstrucoesCobrancaRemessa;
