import React from 'react';

import { format } from 'date-fns';

import { useDataProvider, useNotify } from 'react-admin';

import { useForm } from 'react-final-form';

import { TooltipSalvarIconButton } from '../../../../../common/buttons/BotaoSalvar';
import { TooltipIconButtonCancel } from '../../../../../common/buttons/ButtonCancel';
import ModalConfirmacao from '../../../../../common/ModalConfirmacao';
import { CustomDialog, ModalContext, ModalContextProvider } from '../../../../../common/ModalContext';
import { ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';
import CadastrarDadosIntegracaoAPIContext from '../../contexts/CadastrarDadosIntegracaoAPIContext';
import EditaContaBancariaContext from '../../contexts/EditaContaBancariaContext';

const CancelarButton = ({ callback = () => {} }) => {
	const { saving, pristine } = React.useContext(CadastrarDadosIntegracaoAPIContext);
	const { setModalValue } = React.useContext(ModalContext);

	return (
		<TooltipIconButtonCancel
			disabled={saving}
			onClick={() => {
				if (pristine) {
					callback();
					return;
				}
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalConfirmacao
							Context={ModalContext}
							title='Tem certeza que deseja cancelar o registro dos dados? Os dados fornecidos agora serão perdidos.'
							handleSubmit={() => {
								setModalValue((v) => ({ ...v, open: false }));
								callback();
							}}
						/>
					),
				}));
			}}
		/>
	);
};

const CadastrarButton = ({ callback = () => {} }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { change } = useForm();
	const { initialDados: { conta_condominio: { id } = {} } = {}, TipoContaContext } =
		React.useContext(EditaContaBancariaContext);
	const { instituicaoCodigo } = React.useContext(TipoContaContext);
	const { saving, setSaving, valid, values } = React.useContext(CadastrarDadosIntegracaoAPIContext);

	return (
		<TooltipSalvarIconButton
			title='Cadastrar'
			disabled={!id || !valid || saving}
			onClick={() => {
				setSaving(true);
				dataProvider
					.fullUpdate('dados_integracao_api_conta_bancaria', {
						id,
						data: { ...values, codigo_instituicao_bancaria: instituicaoCodigo },
					})
					.then((response) => {
						change(
							'dados_integracao_api_expires_at',
							response?.data?.dados_integracao_api_expires_at || format(new Date(), 'yyyy-MM-dd')
						);
						notify('Dados da Integração da API cadastrados com sucesso');
						callback();
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);

						setSaving(false);
						if ([500].includes(e?.response?.status))
							return notify('Erro inesperado, tente recarregar a página', 'warning');
						const erroMsg = Object.values(e?.response?.data || {})[0] || [
							'Erro inesperado, tente recarregar a página',
						];
						notify(erroMsg, 'warning');
					});
			}}
		/>
	);
};

const Actions = () => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const closeModal = () => setModalValue((v) => ({ ...v, open: false, dialogBody: <></> }));

	return (
		<ModalContextProvider>
			<CustomDialog />
			<CancelarButton callback={closeModal} />
			<CadastrarButton callback={closeModal} />
		</ModalContextProvider>
	);
};

export { Actions, CancelarButton, CadastrarButton };
