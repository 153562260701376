import { createContext } from 'react';
import { ITreeProps } from '../types';
export const TreeTableContext = createContext<{
	propriedadesContasView: ITreeProps;
	setPropriedadesContasView: (data: ITreeProps) => void;
}>({
	propriedadesContasView: {
		openChildren: [],
		valorMensal: 0,
		valorPeriodo: 0,
		percentual: 0,
	},
	setPropriedadesContasView: () => {},
});
