import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { TextField, List, Pagination, ReferenceField, useListContext, TopToolbar, Button } from 'react-admin';

import { useSelector } from 'react-redux';

import { Box, TextField as TextFieldMUI } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { sub, add, format, parseISO, set } from 'date-fns';

import { DateRangePicker, DefinedRange, DateRange } from 'materialui-daterange-picker-pt';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { CurrencyField } from '../../common/CurrencyInput';
import { Decimal } from '../../common/filtros/DecimalV2';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import { ModalContextProvider, CustomDialog } from 'components/common/ModalContext';
import ModalLancamentoRetencao from './modais/ModalLancamentoRetencao';
import { Padrao } from '../../common/filtros/PadraoV2';
import { Tabela } from '../../common/Tabela';
import {
	ListRetencoesImpostosContext,
	ListRetencoesImpostosContextProvider,
} from './context/ListRetencoesImpostosContext';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { ModalPeriodoPickerWrapper } from '../../common/PeriodoSelect';
import ButtonRemoverRetencaoImposto from './components/ButtonRemoverRetencaoImposto';

import {
	DateRangeProps,
	PeriodoPickerProps,
	PeriodoFilterProps,
	DateFieldProps,
	ValorFieldProps,
	TabelaRetencoesImpostosProps,
	BulkActionsProps,
	CustomPaginationProps,
	SearchFieldsProps,
	ReferenceFieldV2Props,
	ModalValue,
	ListComponentProps,
	RetencoesImpostosListProps,
} from './types';

const TODAY = new Date();
TODAY.setHours(10, 0, 0);

const MINDATE = new Date(1990, 0, 1);
MINDATE.setHours(10, 0, 0);

const formatDate = (date: Date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const CustomTextField = (props: SearchFieldsProps) => <TextField {...props} />;

const ReferenceFieldV2: React.FC<ReferenceFieldV2Props> = ({ children, ...rest }) => {
	return (
		<ReferenceField {...rest} link={false}>
			{children}
		</ReferenceField>
	);
};

const CustomPagination: React.FC<CustomPaginationProps> = (props: object) => (
	<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} {...props} />
);

const periodoOptions = [
	{
		label: 'Limpar',
		startDate: undefined,
		endDate: undefined,
	},
	{
		label: 'Mês atual',
		startDate: set(TODAY, { date: 1 }),
		endDate: sub(add(set(TODAY, { date: 1 }), { months: 1 }), { days: 1 }),
	},
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const PeriodoPicker: React.FC<PeriodoPickerProps> = memo(({ dateRange, open, setOpen, setDateRange }) => {
	const toggle = () => setOpen(!open);
	return (
		<ModalPeriodoPickerWrapper {...{ open, setOpen }}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range: DateRange) => setDateRange(range as DateRangeProps)}
				initialDateRange={periodoOptions[0]}
				minDate={MINDATE}
				definedRanges={periodoOptions as DefinedRange[]}
			/>
		</ModalPeriodoPickerWrapper>
	);
});

const PeriodoFilter: React.FC<PeriodoFilterProps> = memo(({ source }: { source: string }) => {
	const { setFilters, filterValues } = useListContext();
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState(
		filterValues && filterValues[`${source}_after`] && filterValues[`${source}_before`]
			? {
					startDate: parseISO(filterValues[`${source}_after`]).setHours(10, 0, 0),
					endDate: parseISO(filterValues[`${source}_before`]).setHours(10, 0, 0),
			  }
			: periodoOptions[0]
	);

	const handleRangeChange = useCallback(() => {
		const startDate = dateRange?.startDate ? format(dateRange.startDate, 'yyyy-MM-dd') : null;
		const endDate = dateRange?.endDate ? format(dateRange.endDate, 'yyyy-MM-dd') : null;
		if (filterValues[`${source}_after`] !== startDate || filterValues[`${source}_before`] !== endDate) {
			setFilters(
				{
					...filterValues,
					[`${source}_after`]: startDate,
					[`${source}_before`]: endDate,
				},
				false,
				false
			);
		}
	}, [setFilters, filterValues, source, dateRange]);

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextFieldMUI
				onClick={() => setOpen(true)}
				fullWidth
				inputProps={{
					value: `${dateRange?.endDate ? `de ${formatDate(new Date(dateRange?.startDate))}` : ''} ${
						dateRange?.endDate && typeof dateRange.endDate !== 'number'
							? `até ${formatDate(dateRange.endDate)}`
							: ''
					}`,
				}}
				variant='standard'
				margin='normal'
				style={{ marginBottom: 0 }}
			/>
			<PeriodoPicker {...{ dateRange: dateRange as DateRangeProps, open, setOpen, setDateRange }} />
		</>
	);
});

const DateField: React.FC<DateFieldProps> = (props: DateFieldProps) => (
	<span>{props?.record?.[props?.source] ? format(parseISO(props?.record[props?.source]), 'dd/MM/yyyy') : ''}</span>
);

const DateReferenceField: React.FC<DateFieldProps> = (props: DateFieldProps) => (
	<span>{props?.record?.[props?.source] ? format(parseISO(props?.record[props?.source]), 'MM/yyyy') : ''}</span>
);

const ValorField: React.FC<ValorFieldProps> = ({ record, source, ...props }: ValorFieldProps) => {
	if (!record) {
		return null;
	}
	return (
		<CurrencyField
			name='currencyField'
			{...props}
			prefix='R$ '
			minimumFractionDigits={2}
			value={record[source]}
			id={`${source}${record.id}`}
		/>
	);
};

const BulkActions: React.FC<BulkActionsProps> = () => {
	return (
		<>
			<ModalContextProvider>
				<ButtonRemoverRetencaoImposto />
				<CustomDialog />
			</ModalContextProvider>
		</>
	);
};

const TabelaRetencoesImpostos: React.FC<TabelaRetencoesImpostosProps> = (props) => {
	const {
		basePath,
		filterValues: { condominio_id },
		onUnselectItems,
		selectedIds: listSelectedIds,
	}: TabelaRetencoesImpostosProps = useListContext();
	const sidebarOpen = useSelector((state: { admin: { ui: { sidebarOpen: boolean } } }) => state.admin.ui.sidebarOpen);
	const { setCondominioPorId } = useContext(CondominiosContext);
	const { setSelected } = useContext(ListRetencoesImpostosContext);

	useEffect(onUnselectItems, [basePath]);

	const updateLastCondominioId = useCallback(() => {
		if (condominio_id) {
			setCondominioPorId(condominio_id);
		}
	}, [condominio_id, setCondominioPorId]);
	useEffect(updateLastCondominioId, [condominio_id]);

	useEffect(() => {
		setSelected(listSelectedIds);
	}, [listSelectedIds, setSelected]);

	return (
		<Box
			style={{
				overflowY: 'hidden',
				overflowX: 'scroll',
				width: 'auto',
				maxWidth: `calc(100vw - ${sidebarOpen ? '300px' : '95px'})`,
			}}
		>
			<Tabela {...props} disableClickRow>
				<ReferenceFieldV2
					source='condominio'
					reference='condominios'
					label='Condomínio'
					link={false}
					sortBy='condominio_nome'
					filtro={
						<Autocompletar
							refName='nome'
							refResource='condominios'
							mostrarInativos={false}
							id={undefined}
							label={undefined}
							source={undefined}
							opcoes={undefined}
						/>
					}
					minWidth='200px'
				>
					<TextField source='nome' />
				</ReferenceFieldV2>
				<CustomTextField source='tipo_periodo' label='Tipo período' filtro={<Padrao />} minWidth='150px' />
				<DateReferenceField
					source='referencia'
					label='Referência'
					filtro={<PeriodoFilter source={''} />}
					minWidth='150px'
				>
					<DateField source='referencia' />
				</DateReferenceField>
				<ReferenceFieldV2
					source='fornecedor'
					reference='fornecedores'
					label='Fornecedor'
					link={false}
					sortBy='fornecedor_nome'
					filtro={<Padrao />}
					minWidth='200px'
				>
					<TextField source='nome' />
				</ReferenceFieldV2>
				<DateField
					source='vencimento'
					label='Vencimento'
					filtro={<PeriodoFilter source={''} />}
					minWidth='200px'
				/>
				<CustomTextField source='nome' label='Imposto' filtro={<Padrao />} minWidth='150px' />
				<ValorField source='valor' align='right' label='Valor' filtro={<Decimal />} minWidth='150px' />
			</Tabela>
		</Box>
	);
};

export const ListComponent: React.FC<ListComponentProps> = (props) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	return (
		<List
			{...props}
			empty={false}
			bulkActionButtons={<BulkActions />}
			actions={
				<TopToolbar>
					<Button
						label='Adicionar'
						onClick={() => {
							setModalValue((v: ModalValue) => ({
								...v,
								open: true,
								dialogBody: <ModalLancamentoRetencao context={ModalSizeEditableContext} />,
							}));
						}}
						startIcon={<AddIcon />}
					/>
				</TopToolbar>
			}
			perPage={10}
			pagination={<CustomPagination labelRowsPerPage='Retenções por página' />}
			filterDefaultValues={{
				data_vencimento_after: periodoOptions[1].startDate
					? format(periodoOptions[1].startDate, 'yyyy-MM-dd')
					: '',
				data_vencimento_before: periodoOptions[1].endDate
					? format(periodoOptions[1].endDate, 'yyyy-MM-dd')
					: '',
			}}
		>
			<TabelaRetencoesImpostos />
		</List>
	);
};

export const RetencoesImpostosList: React.FC<RetencoesImpostosListProps> = (props) => {
	return (
		<ListRetencoesImpostosContextProvider>
			<ModalSizeEditableContextProvider>
				<ListComponent
					resource='retencoes_impostos'
					basePath='/retencoes_impostos'
					match={{
						...props?.match,
						path: '/retencoes_impostos',
						url: '/retencoes_impostos',
					}}
				/>
			</ModalSizeEditableContextProvider>
		</ListRetencoesImpostosContextProvider>
	);
};
