import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { periodoOptions } from 'components/common/PeriodoSelect/PeriodoSelectV2';

import { TCondominio } from 'types/condominio';
import { CustomDataProvider } from 'types/tpyesGlobal';
import { IExportacaoContabilContext } from '../types';

export const ExportacaoContabilContext = React.createContext<IExportacaoContabilContext>({
	condominio: null,
	setCondominio: () => null,
	optionsCondominio: [],
	setOptionsCondominio: () => null,
	valueTipoMovimento: { id: 'CR', nome: 'Contas a Receber' },
	setValueTipoMovimento: () => null,
	valueTipoExportacao: 'ambos',
	setValueTipoExportacao: () => null,
	dateRange: periodoOptions[0],
	setDateRange: () => null,
});

export const ExportacaoContabilContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [condominio, setCondominio] = React.useState<TCondominio | null>(null);
	const [optionsCondominio, setOptionsCondominio] = React.useState<TCondominio[]>([]);
	const [valueTipoMovimento, setValueTipoMovimento] = React.useState({ id: 'CR', nome: 'Contas a Receber' });
	const [valueTipoExportacao, setValueTipoExportacao] = React.useState('ambos');
	const [dateRange, setDateRange] = React.useState(periodoOptions[0]);

	const dp: CustomDataProvider = useDataProvider();
	const notify = useNotify();

	const updateCondominios = () => {
		dp.getSimple('condominios', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: { situacao: 'A' },
		})
			.then(({ data: { results = [] } = {} }) =>
				results?.length ? results : Promise.reject('Sem condominios cadastrados.')
			)
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				notify('Sem condominios cadastrados.', 'warning');
				return [];
			})
			.then((condominiosColetados: TCondominio[]) => {
				setOptionsCondominio(condominiosColetados);
				setCondominio(condominiosColetados[0] ?? null);
			});
	};
	React.useEffect(updateCondominios, []);

	const value = React.useMemo(
		() => ({
			condominio,
			setCondominio,
			optionsCondominio,
			setOptionsCondominio,
			valueTipoMovimento,
			setValueTipoMovimento,
			valueTipoExportacao,
			setValueTipoExportacao,
			dateRange,
			setDateRange,
		}),
		[
			condominio,
			setCondominio,
			optionsCondominio,
			setOptionsCondominio,
			valueTipoMovimento,
			setValueTipoMovimento,
			valueTipoExportacao,
			setValueTipoExportacao,
			dateRange,
			setDateRange,
		]
	);

	return <ExportacaoContabilContext.Provider value={value}>{children}</ExportacaoContabilContext.Provider>;
};
