import React, { useContext, useEffect, createContext, useMemo, useCallback } from 'react';

import { useForm } from 'react-final-form';

import { Box } from '@material-ui/core';

import memoize from '../../../../common/memoize';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaBancariaAutocomplete from './EditaContaBancariaAutocomplete';
import EditaContaBancariaCurrencyInput from './EditaContaBancariaCurrencyInput';
import EditaContaBancariaNumberInput from './EditaContaBancariaNumberInput';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const getPropsOnChangeTipoPadrao = ({ sources, optionsIsencoesTipo }) => ({ sources, optionsIsencoesTipo });

const createOnChangeTipoPadrao =
	({ sources: { sourceValor, sourceDias } = {}, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			change(sourceValor, undefined);
			change(sourceDias, undefined);
		}
	};

const optionsEmpty = {
	choices: [],
	objects: {},
};

const InstrucaoRemessaFieldsContext = createContext();

const getParseDiasPadrao =
	({ disabled, min }) =>
	(value) =>
		(typeof value !== 'number' && !value) || isNaN(value) ? (disabled ? '' : min) : parseInt(value);

const createGetValueTipo =
	({ field, value, options }) =>
	() => {
		const { objects = {}, choices = [] } = options[field] || {};
		if (choices.length && !objects[value?.id]) return choices[0];
		return value;
	};

const memoizePropsOnChangeTipo = memoize((props) => props);

const InstrucaoRemessaFieldsProvider = ({ sources, children }) => {
	const { TipoContaContext } = useContext(EditaContaBancariaContext);
	const { optionsIsencoes } = useContext(TipoContaContext);
	const { options, props } = React.useContext(DadosPorTipoIntegracaoContext);
	const { getState, batch, change } = useForm();
	const { pristine, values } = getState();

	const optionsIsencoesTipo = optionsIsencoes[sources.sourceTipo] || optionsEmpty.objects;
	const optionsTipo = options[sources.sourceTipo] || optionsEmpty;
	const tipoValue = values[sources.sourceTipo];
	const disabled = !!(!tipoValue || optionsIsencoesTipo[tipoValue?.id]);
	const getPropsOnChangeTipo = props[sources.sourcePrincipal]?.getPropsOnChangeTipo || getPropsOnChangeTipoPadrao;
	const createOnChangeTipo = props[sources.sourcePrincipal]?.createOnChangeTipo || createOnChangeTipoPadrao;

	const propsOnChangeTipo = memoizePropsOnChangeTipo(
		getPropsOnChangeTipo({
			values,
			pristine,
			sources,
			optionsIsencoesTipo,
			props,
		})
	);

	const OnChangeTipo = useCallback(
		(_, tipoValue, input) => {
			const onChangeTipo = createOnChangeTipo(propsOnChangeTipo, change);
			batch(() => onChangeTipo(_, tipoValue, input));
		},
		[batch, change, createOnChangeTipo, propsOnChangeTipo]
	);

	const resetValueTipo = () => {
		const getValueTipo = createGetValueTipo({
			field: sources.sourceTipo,
			value: tipoValue,
			options,
		});
		const input = {
			onChange: (value) => change(sources.sourceTipo, value),
			value: getValueTipo(),
		};
		const onChangeTipo = createOnChangeTipo(propsOnChangeTipo, change);
		batch(() => onChangeTipo(null, input.value, input));
	};

	useEffect(resetValueTipo, []);

	const value = useMemo(
		() => ({
			disabled,
			optionsIsencoesTipo,
			optionsTipo,
			tipoValue,
			OnChangeTipo,
		}),
		[disabled, optionsIsencoesTipo, optionsTipo, tipoValue, OnChangeTipo]
	);

	return <InstrucaoRemessaFieldsContext.Provider value={value}>{children}</InstrucaoRemessaFieldsContext.Provider>;
};

const TipoField = ({ source, ...props }) => {
	const { OnChangeTipo } = useContext(InstrucaoRemessaFieldsContext);
	return (
		<EditaContaBancariaAutocomplete
			source={source}
			name={source}
			id={source}
			translateChoice={false}
			onChange={OnChangeTipo}
			{...props}
		/>
	);
};

const ValorField = ({ source, ...props }) => {
	const {
		disabled,
		optionsTipo: { objects },
		tipoValue,
	} = useContext(InstrucaoRemessaFieldsContext);
	return (
		<EditaContaBancariaCurrencyInput
			source={source}
			name={source}
			id={source}
			disabled={disabled}
			onChange={(event) => {
				if (!objects[tipoValue?.id] && (!event.target.value || event.target.value < 0.01))
					event.target.value = 0.01;
			}}
			label='Valor/Percentual'
			{...props}
		/>
	);
};

const DiasField = ({ source, min: standardMin = 0, ...standardProps }) => {
	const { props } = useContext(DadosPorTipoIntegracaoContext);
	const { disabled } = useContext(InstrucaoRemessaFieldsContext);

	const { getParseDias = getParseDiasPadrao, min = standardMin } = props[source] || {};

	return (
		<EditaContaBancariaNumberInput
			source={source}
			name={source}
			id={source}
			disabled={disabled}
			parse={getParseDias({ disabled, min })}
			min={min}
			label='Dias'
			{...standardProps}
		/>
	);
};

const BoxRowInstrucaoRemessaFields = ({ children, ...props }) => (
	<Box display='grid' gridTemplateColumns='50% 34% 13%' gridColumnGap='0.5rem' {...props}>
		{children}
	</Box>
);

const InstrucoesRemessaFields = ({
	sourcePrincipal,
	propsTipo: { source: sourceTipo, ...propsTipo },
	propsValor: { source: sourceValor, ...propsValor },
	propsDias: { source: sourceDias, ...propsDias },
}) => (
	<InstrucaoRemessaFieldsProvider
		sources={{
			sourcePrincipal,
			sourceTipo,
			sourceValor,
			sourceDias,
		}}
	>
		<BoxRowInstrucaoRemessaFields>
			<Box gridColumn='1'>
				<TipoField source={sourceTipo} {...propsTipo} />
			</Box>
			<Box gridColumn='2'>
				<ValorField source={sourceValor} {...propsValor} />
			</Box>
			<Box gridColumn='3'>
				<DiasField source={sourceDias} {...propsDias} />
			</Box>
		</BoxRowInstrucaoRemessaFields>
	</InstrucaoRemessaFieldsProvider>
);

const InstrucoesRemessaSemValorFields = ({
	sourcePrincipal,
	propsTipo: { source: sourceTipo, ...propsTipo },
	propsDias: { source: sourceDias, ...propsDias },
}) => (
	<InstrucaoRemessaFieldsProvider
		sources={{
			sourcePrincipal,
			sourceTipo,
			sourceDias,
		}}
	>
		<BoxRowInstrucaoRemessaFields gridTemplateColumns='50% 13% 34%'>
			<Box gridColumn='1'>
				<TipoField source={sourceTipo} {...propsTipo} />
			</Box>
			<Box gridColumn='2/3'>
				<DiasField source={sourceDias} {...propsDias} />
			</Box>
		</BoxRowInstrucaoRemessaFields>
	</InstrucaoRemessaFieldsProvider>
);

const InstrucoesRemessaSemDiasFields = ({
	sourcePrincipal,
	propsTipo: { source: sourceTipo, ...propsTipo },
	propsValor: { source: sourceValor, ...propsValor },
}) => (
	<InstrucaoRemessaFieldsProvider
		sources={{
			sourcePrincipal,
			sourceTipo,
			sourceValor,
		}}
	>
		<BoxRowInstrucaoRemessaFields>
			<Box gridColumn='1'>
				<TipoField source={sourceTipo} {...propsTipo} />
			</Box>
			<Box gridColumn='2/4'>
				<ValorField source={sourceValor} {...propsValor} />
			</Box>
		</BoxRowInstrucaoRemessaFields>
	</InstrucaoRemessaFieldsProvider>
);

export { InstrucoesRemessaSemDiasFields, InstrucoesRemessaSemValorFields };

export default InstrucoesRemessaFields;
