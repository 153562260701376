import React, { useContext } from 'react';

import CurrencyInputWithAdornment from 'components/common/CurrencyInputWithAdornment.js';
import EditaContaBancariaContext from '../../contexts/EditaContaBancariaContext.js';
import DadosPorTipoIntegracaoContext from '../../contexts/DadosPorTipoIntegracaoContext';

const CurrencyInputSaldoInicial = ({
	source = 'saldo_inicial',
	disabled: standardDisabled = false,
	...standardProps
}) => {
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { props } = useContext(DadosPorTipoIntegracaoContext);
	const { disabled = false, ...sourceProps } = props[source] || {};

	return (
		<CurrencyInputWithAdornment
			source={source}
			label='Saldo Inicial'
			name='saldo_inicial'
			disabled={isInativo || disabled || standardDisabled}
			margin='dense'
			decimalScale={2}
			fullWidth
			onChange={(event) => {
				if (!event.target.value) event.target.value = 0;
				if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
				if (event.target.value < -999999999999.99) event.target.value = -999999999999.99;
			}}
			{...standardProps}
			{...sourceProps}
		/>
	);
};

export default CurrencyInputSaldoInicial;
