import React from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton } from 'components/common/commonComponentsTSX';
import { TypePropsModalConfirmEnvioEmail } from '../types';

const ConfirmUpdatePersonalizado = ({ text, handleSubmitWithRedirect, ...props }: TypePropsModalConfirmEnvioEmail) => {
	const { values } = useFormState();
	const [handleSave, setHandleSave] = React.useState<boolean>(true);
	const [textEditorModify, setTextEditorModify] = React.useState<boolean[]>([false]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		const areEqual = JSON.stringify(props?.record) === JSON.stringify(values);
		if (values.titulo == undefined || values.participantes == undefined || text == '') {
			setHandleSave(true);
		} else if (props?.record?.id) {
			setTextEditorModify([areEqual, JSON.parse(props?.record?.mensagem_json)['blocks'][0]['text'] === text]);
		}
	}, [values, text]);

	React.useEffect(() => {
		setHandleSave(textEditorModify.every((v) => v === true));
	}, [textEditorModify]);

	const handleClickOpen = () => {
		if (loading) return;
		setHandleSave(true);
		setLoading(true);
		handleSubmitWithRedirect(props.basePath);
	};

	return (
		<>
			<SaveButton onClick={handleClickOpen} disabled={handleSave} size='small'>
				Salvar
			</SaveButton>
		</>
	);
};
export default ConfirmUpdatePersonalizado;
