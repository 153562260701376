import React, { memo, useContext, useEffect, useState } from 'react';

import { useGetList } from 'react-admin';

import { Box, TextField, Dialog } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { DateRangePicker } from 'materialui-daterange-picker-pt';

import { format } from 'date-fns';

import { CondominiosContext } from '../../../../../../context/CondominioContextProvider';
import '../../../../../common/css/datePickerFix.css';

const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const PeriodoPicker = memo(({ open, setOpen, setDateRange }) => {
	const toggle = () => setOpen(!open);
	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => {
					setDateRange(range);
					toggle();
				}}
				minDate={MINDATE}
				initialDateRange={{
					startDate: new Date(),
					endDate: new Date(),
				}}
				definedRanges={[]}
			/>
		</Dialog>
	);
});

const PeriodoSelect = memo(({ setDatasAfter, setDatasBefore }) => {
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
	});

	const handleRangeChange = () => {
		setDatasAfter(format(dateRange.startDate, 'yyyy-MM-dd'));
		setDatasBefore(format(dateRange.endDate, 'yyyy-MM-dd'));
	};

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextField
				onClick={() => setOpen(!open)}
				fullWidth
				margin='dense'
				label='Selecionar Período'
				inputProps={{
					value: `de ${format(dateRange?.startDate, 'dd/MM/yyyy')} até ${format(
						dateRange?.endDate,
						'dd/MM/yyyy'
					)}`,
				}}
			/>
			<PeriodoPicker {...{ open, setOpen, setDateRange }} />
		</>
	);
});

const BoxIntervaloConta = ({ conta, setConta, setDatasAfter, setDatasBefore }) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { data, ids } = useGetList(
		'conta_condominio',
		{ page: 1, perPage: 10000 },
		{ field: 'nome_conta', order: 'ASC' },
		{ id_condominio: condominioSelecionado?.id }
	);
	const [optionsConta, setOptionsConta] = useState([]);
	useEffect(() => {
		if (data && ids) {
			setOptionsConta(ids.map((id) => data[id]));
		}
	}, [data, ids]);

	return (
		<Box>
			<PeriodoSelect {...{ setDatasAfter, setDatasBefore }} />
			{optionsConta && (
				<Autocomplete
					id='select-multi-contas'
					options={optionsConta}
					renderInput={(params) => (
						<TextField {...params} margin='dense' label='Selecionar Conta' style={{ marginTop: 15 }} />
					)}
					getOptionLabel={(option) =>
						option && (option.nome_conta || optionsConta?.find((v) => v.id === option)?.nome_conta)
					}
					noOptionsText='Nenhum resultado encontrado'
					getOptionSelected={(option, v) => v === option.id}
					value={conta}
					onChange={(_, v) => setConta(v ? v.id : null)}
					clearText='Limpar'
				/>
			)}
		</Box>
	);
};

export default BoxIntervaloConta;
