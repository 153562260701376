import React, { useState, useContext } from 'react';

import { useNotify } from 'react-admin';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import { VinculosContext } from './../VinculosContext';
import AddImobiliaria from './common/AddImobiliaria';

export const ModalTrocaImobiliaria = ({ imobiliaria, id }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [imob, setImob] = useState(imobiliaria);
	const { dataProvider, setResponsaveis, edit } = useContext(VinculosContext);
	const notify = useNotify();
	const [processando, setProcessando] = useState(false);

	const handleSubmit = () => {
		setProcessando(true);

		const resetImoFunc = () => {
			setResponsaveis((v) =>
				v.map((p) => {
					if (p.tipo === 'I') {
						p.id_imobiliaria = imob.id;
						p.imobiliaria = imob;
					}
					return p;
				})
			);
			setModalValue((v) => ({ ...v, open: false }));
		};

		if (edit) {
			dataProvider
				.update('pessoa_unidade', {
					id,
					data: { id_imobiliaria: imob.id },
				})
				.then(() => {
					resetImoFunc();
					notify('Imobiliária vinculada com sucesso', 'info');
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const errorMsg = Object.values(e?.response?.data || {})[0] || 'Erro ao vincular a imobiliaria';
					notify(errorMsg, 'warning');
					setProcessando(false);
				});
		} else {
			resetImoFunc();
		}
	};

	return (
		<CustomDialogBodySizeEditable
			title='Trocar Imobiliária de Inquilino'
			closeInSubmit={false}
			form={{
				handleSubmit,
				valid: processando && imob && imob.id !== imobiliaria?.id,
				component: (
					<AddImobiliaria
						setImob={setImob}
						checked
						setChecked={() => {}}
						displaySwitch={false}
						defaultValue={imobiliaria}
					/>
				),
			}}
		/>
	);
};
