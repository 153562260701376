import React, { useContext } from 'react';

import { ResourceContextProvider, List, Pagination } from 'react-admin';

import { VinculosContext } from '../VinculosContext';
import BulkActions from './BulkActionButtons';
import TabelaHistorico from './TabelaHistorico';

const ListaPessoaUnidade = (props) => {
	const { reload } = useContext(VinculosContext);
	return (
		<ResourceContextProvider resource='pessoa_unidade'>
			<List
				{...props}
				basePath='/pessoa_unidade'
				resource='pessoa_unidade'
				title=' '
				bulkActionButtons={<BulkActions />}
				empty={false}
				perPage={10}
				pagination={
					<Pagination
						rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
						labelRowsPerPage='Pessoas por página'
					/>
				}
				actions={null}
				filters={null}
				filter={props.edit ? { id_unidade: props.id, reload } : { data_vigencia_before: '1900-01-01' }}
				sort={{ field: 'data_entrada', order: 'DESC' }}
			>
				<TabelaHistorico {...props} disableClickRow />
			</List>
		</ResourceContextProvider>
	);
};

export default ListaPessoaUnidade;
