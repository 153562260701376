import React from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { CancelButton, ConfirmButton } from 'components/common/commonComponentsTSX';
import { KSurveyExcludeModalProps } from './types';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';

const ExcludeSurveyModal: React.FC<KSurveyExcludeModalProps> = ({ surveyId }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const dp = useDataProvider();
	const [loading, setLoading] = React.useState(false);
	const { setModalValue } = React.useContext(ModalContext);

	function handleExclude() {
		setLoading(true);
		dp.delete('enquetes', {
			id: surveyId,
		})
			.then(() => {
				notify('Enquete deletada com sucesso!', 'success');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(e, 'warning');
			})
			.finally(() => setLoading(false));
	}

	const actions = (
		<>
			<CancelButton
				onClick={() => {
					if (loading) return;
					setModalValue((v: any) => ({ ...v, open: false }));
				}}
			>
				{(loading && <CircularProgress size={20} />) || 'Cancelar'}
			</CancelButton>
			<ConfirmButton onClick={handleExclude} size='small' disabled={loading}>
				{(loading && <CircularProgress size={20} />) || 'Confirmar'}
			</ConfirmButton>
		</>
	);

	return (
		<CustomDialogBody
			title={'Excluir'}
			customActions={actions}
			form={{
				component: <>Deseja realmente excluir esta enquete?</>,
			}}
		/>
	);
};

export default ExcludeSurveyModal;
