import React, { useCallback, useContext, createContext, useEffect, useState, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { ModalContext } from '../../../../common/ModalContext';
import useProviders from '../../../../../useProviders';

export const UnidadePrincipalContext = createContext();

export const UnidadePrincipalContextProvider = ({ children, record: r = {} }) => {
	const { setModalValue } = useContext(ModalContext);
	const [unidadesSubordinadas, setUnidadesSubordinadas] = useState(r?.unidades_subordinadas || []);
	const [record] = useState(r);
	const dP = useDataProvider();

	const updateUnidadesSubordinadas = () => {
		setUnidadesSubordinadas(r?.unidades_subordinadas || []);
	};
	useEffect(updateUnidadesSubordinadas, [r.unidades_subordinadas]);

	const value = useMemo(
		() => ({ record, dP, setModalValue, unidadesSubordinadas, setUnidadesSubordinadas }),
		[record, dP, setModalValue, unidadesSubordinadas, setUnidadesSubordinadas]
	);
	return <UnidadePrincipalContext.Provider value={value}>{children}</UnidadePrincipalContext.Provider>;
};

export const UnidadesSubordinadasContext = createContext();

export const UnidadesSubordinadasContextProvider = ({ children, record }) => {
	const [unidadesSubordinaveis, setUnidadesSubordinaveis] = useState([]);
	const idCondominio = record?.id_condominio;
	const id = record?.id;
	const { loading, fetchData } = useProviders();

	const carregarUnidadesSubordinaveis = useCallback(() => {
		if (idCondominio && !loading) {
			fetchData('getSimple', {
				resource: 'unidades',
				params: {
					pagination: { perPage: 10000, page: 1 },
					sort: { field: 'unidade', order: 'ASC' },
					filter: {
						id_condominio: idCondominio,
						disponivel_para_subordinacao_de: id,
						situacao: 'A',
					},
				},
			}).then((response) => {
				const data = (response?.data?.results || []).filter((u) => !!u?.id);
				setUnidadesSubordinaveis(data);
			});
		}
	}, [idCondominio, loading, fetchData, id, setUnidadesSubordinaveis]);

	useEffect(carregarUnidadesSubordinaveis, []);

	const value = useMemo(
		() => ({ unidadesSubordinaveis, carregarUnidadesSubordinaveis }),
		[unidadesSubordinaveis, carregarUnidadesSubordinaveis]
	);
	return <UnidadesSubordinadasContext.Provider value={value}>{children}</UnidadesSubordinadasContext.Provider>;
};
