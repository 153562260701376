import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ImportCondominiosContext from './ImportCondominiosContext';
import DropZoneImportCondominiosModal from './DropZoneImportCondominiosModal';
import ResultadosImportCondominiosModal from './ResultadosImportCondominiosModal';

const ImportCondominiosContextProvider = () => {
	const [files, setFiles] = useState([]);
	const [resultadoImport, setResultadoImport] = useState([]);
	const [errosNoArquivo, SetErrosNoArquivo] = useState([]);
	const [modalStep, setModalStep] = useState(1);
	const [stepComponents] = useState({
		1: <DropZoneImportCondominiosModal />,
		2: <ResultadosImportCondominiosModal />,
	});

	const providerValue = useMemo(
		() => ({
			files,
			setFiles,
			setModalStep,
			resultadoImport,
			setResultadoImport,
			errosNoArquivo,
			SetErrosNoArquivo,
		}),
		[files, setFiles, setModalStep, resultadoImport, setResultadoImport, errosNoArquivo, SetErrosNoArquivo]
	);

	return (
		<ImportCondominiosContext.Provider value={providerValue}>
			{stepComponents[modalStep]}
		</ImportCondominiosContext.Provider>
	);
};

export default ImportCondominiosContextProvider;
