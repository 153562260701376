import React from 'react';

import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export const CustomDialogBodySizeEditableBase = ({ customStyleTitle, title, text, Component, Actions }) => (
	<>
		<DialogTitle style={customStyleTitle}>{title}</DialogTitle>
		<DialogContent>
			{text && <Typography>{text}</Typography>}
			{Component}
		</DialogContent>
		<DialogActions>{Actions}</DialogActions>
	</>
);
