import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useGetList } from 'react-admin';

// import { ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';
import { MovimentacoesContext } from '../../MovimentacoesContext';
import FluxoImportacaoExtratoBancarioContext, {
	fuctionsRowIsNotCheckable,
} from './FluxoImportacaoExtratoBancarioContext';
import ModalDropZoneExtratoBancaria from './modais/ModalDropZoneExtratoBancaria';
import ModalTabelaExtratoBancario from './modais/ModalTabelaExtratoBancario';

const FluxoImportacaoExtratoBancarioContextProvider = () => {
	const {
		condominio: condominio_id,
		contaBancaria: contaBancariaIncial,
		condominiosData,
		condominiosIds,
		contasBancarias: contasBancariasIniciais,
	} = useContext(MovimentacoesContext);

	// Configurações e dados gerais
	const [fRowIsNotCheckable, setFRowIsNotCheckable] = useState(() => fuctionsRowIsNotCheckable.vazio);
	const [condominios, setCondominios] = useState(
		condominiosIds.map((id) => condominiosData[id]).filter((id) => !!id)
	);
	const [condominio, setCondominio] = useState(condominio_id || null);
	const [idPlanoCondominio, setIdPlanoCondominio] = useState(null);
	const [contaBancaria, setContaBancaria] = useState(contaBancariaIncial);
	const [contasBancarias, setContasBancarias] = useState(contasBancariasIniciais || []);
	const [gruposLista, setGruposLista] = useState([]);
	const [grupo, setGrupo] = useState(null);
	const [unidadesLista, setUnidadesLista] = useState([]);
	const [unidade, setUnidade] = useState(null);
	const [datas_after, setDatas_after] = useState();
	const [datas_before, setDatas_before] = useState();
	const [detalhamentosLancamentosDespesa, setDetalhamentosLancamentosDespesa] = React.useState([]);
	const [valor, setValor] = React.useState();
	const [contaBancariaOrigem, setContaBancariaOrigem] = useState(contaBancariaIncial);
	const [contaBancariaDestino, setContaBancariaDestino] = useState(null);
	const [tipoLancamento, setTipoLancamento] = useState('C');
	const [movimentacoesParaConciliacao, setMovimentacoesParaConciliacao] = useState({});
	// Configurações para Lançamentos de Receita
	const [contaReceita, setContaReceita] = useState();

	// Configurações para Lançamentos de Despesa
	const [contaDespesa, setContaDespesa] = useState();

	// Dados dos extratos
	const [files, setFiles] = useState([]);
	const [registros, setRegistros] = useState({});
	const [registrosLancaveisSelecionados, setRegistrosLancaveisSelecionados] = useState([]);
	const [registrosConciliaveisSelecionados, setRegistrosConciliaveisSelecionados] = useState([]);
	const [ordenacaoRegistros, setOrdenacaoRegistros] = useState({
		field: 'data_movimentacao',
		order: 'asc',
	});
	const [filtragemRegistros, setFiltragemRegistros] = useState({
		data_movimentacao_after: null,
		data_movimentacao_before: null,
		historico: '',
		valor: '',
		situacao: null,
	});

	const {
		data: contasBancariasData,
		ids: contasBancariasIds,
		loading: contasBancariasLoading,
	} = useGetList(
		'conta_condominio_movimentacoes',
		{ perPage: 10000, page: 1 },
		{ field: 'nome_conta', order: 'ASC' },
		{
			id_condominio: condominio || 0,
			situacao: 'A',
		}
	);
	const { data: gruposData, ids: gruposIds } = useGetList(
		'grupo_unidade_condominio',
		{ perPage: 10000, page: 1 },
		{ field: 'nome', order: 'ASC' },
		{ id_condominio: condominio }
	);
	const { data: unidadesData, ids: unidadesIds } = useGetList(
		'unidades',
		{ perPage: 10000, page: 1 },
		{ field: 'unidade', order: 'ASC' },
		{ id_condominio: condominio, situacao: 'A' }
	);

	const updateCondominios = useCallback(() => {
		const ids = condominiosIds || [];
		setCondominios(ids.filter((id) => !!id).map((id) => condominiosData[id]));
		if (!condominio || !ids.includes(parseInt(condominio))) setCondominio(ids[0] || null);
	}, [condominiosData, condominiosIds, setCondominios, condominio, setCondominio]);
	useEffect(updateCondominios, [condominiosIds]);

	const changeCondominio = useCallback(() => {
		if (contasBancarias && contasBancarias[0] && `${contasBancarias[0]?.id_condominio}` !== `${condominio}`) {
			setContaBancaria(null);
			setContasBancarias([]);
		}
	}, [condominio, contasBancarias, setContaBancaria]);
	useEffect(changeCondominio, [condominio]);

	const updateIdPlanoCondominio = useCallback(() => {
		setIdPlanoCondominio((condominiosData || {})[condominio]?.id_plano_condominio || null);
	}, [condominiosData, condominio, setIdPlanoCondominio]);
	useEffect(updateIdPlanoCondominio, [condominio]);

	const updateContasBancarias = useCallback(() => {
		if (!contasBancariasLoading) {
			const ids = contasBancariasIds || [];
			setContasBancarias(ids.map((id) => contasBancariasData[id]).filter((id) => !!id));
		}
	}, [contasBancariasData, contasBancariasIds, setContasBancarias, contasBancariasLoading]);
	useEffect(updateContasBancarias, [contasBancariasLoading]);

	const updateGruposLista = useCallback(() => {
		const ids = gruposIds || [];
		setGruposLista(ids.filter((id) => !!id).map((id) => gruposData[id]));
	}, [gruposData, gruposIds, setGruposLista]);
	useEffect(updateGruposLista, [gruposIds]);

	const updateUnidadesLista = useCallback(() => {
		const ids = unidadesIds || [];
		setUnidadesLista(ids.filter((id) => !!id).map((id) => unidadesData[id]));
	}, [setUnidadesLista, unidadesData, unidadesIds]);
	useEffect(updateUnidadesLista, [unidadesIds]);

	const [modalStep, setModalStep] = useState('1');
	const [stepComponents] = useState({
		1: <ModalDropZoneExtratoBancaria />,
		2: <ModalTabelaExtratoBancario />,
	});

	const providerValue = useMemo(
		() => ({
			condominio,
			condominios,
			contaBancaria,
			contaBancariaDestino,
			contaBancariaOrigem,
			contaDespesa,
			contaReceita,
			contasBancarias,
			contasBancariasData: contasBancariasData || {},
			datas_after,
			datas_before,
			detalhamentosLancamentosDespesa,
			valor,
			files,
			filtragemRegistros,
			fRowIsNotCheckable,
			grupo,
			gruposLista,
			idPlanoCondominio,
			movimentacoesParaConciliacao,
			ordenacaoRegistros,
			registros,
			registrosConciliaveisSelecionados,
			registrosLancaveisSelecionados,
			setCondominio,
			setContaBancaria,
			setContaBancariaDestino,
			setContaBancariaOrigem,
			setContaDespesa,
			setContaReceita,
			setDatas_after,
			setDatas_before,
			setDetalhamentosLancamentosDespesa,
			setValor,
			setFiles,
			setFiltragemRegistros,
			setFRowIsNotCheckable,
			setGrupo,
			setModalStep,
			setMovimentacoesParaConciliacao,
			setOrdenacaoRegistros,
			setRegistros,
			setRegistrosConciliaveisSelecionados,
			setRegistrosLancaveisSelecionados,
			setUnidade,
			setTipoLancamento,
			unidade,
			unidadesLista,
			tipoLancamento,
		}),
		[
			condominio,
			condominios,
			contaBancaria,
			contaBancariaDestino,
			contaBancariaOrigem,
			contaDespesa,
			contaReceita,
			contasBancarias,
			contasBancariasData,
			datas_after,
			datas_before,
			detalhamentosLancamentosDespesa,
			valor,
			files,
			filtragemRegistros,
			fRowIsNotCheckable,
			grupo,
			gruposLista,
			idPlanoCondominio,
			movimentacoesParaConciliacao,
			ordenacaoRegistros,
			registros,
			registrosConciliaveisSelecionados,
			registrosLancaveisSelecionados,
			setCondominio,
			setContaBancaria,
			setContaBancariaDestino,
			setContaBancariaOrigem,
			setContaDespesa,
			setContaReceita,
			setDatas_after,
			setDatas_before,
			setDetalhamentosLancamentosDespesa,
			setValor,
			setFiles,
			setFiltragemRegistros,
			setFRowIsNotCheckable,
			setGrupo,
			setModalStep,
			setMovimentacoesParaConciliacao,
			setOrdenacaoRegistros,
			setRegistros,
			setRegistrosConciliaveisSelecionados,
			setRegistrosLancaveisSelecionados,
			setUnidade,
			setTipoLancamento,
			unidade,
			unidadesLista,
			tipoLancamento,
		]
	);

	return (
		<FluxoImportacaoExtratoBancarioContext.Provider value={providerValue}>
			{stepComponents[modalStep]}
		</FluxoImportacaoExtratoBancarioContext.Provider>
	);
};

export default FluxoImportacaoExtratoBancarioContextProvider;
