import React from 'react';

import { Box, Typography } from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';

import { useDataProvider, useNotify } from 'react-admin';

import { DeleteButton, CloseButton } from 'components/common/commonComponentsTSX';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { ConfiguracaoDeCorrecaoMonetariaContext } from '../contexts';
import { CustomDataProvider, ProviderValueContext } from '../types';

export const ModalDeleteConfirmation: React.FC = () => {
	const notify = useNotify();
	const dp = useDataProvider() as CustomDataProvider;
	const { screen, handleSetRedirectContext } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);

	const handleSubmitDeleteConfigCorrecaoMonetaria = () => {
		dp.delete('correcao_monetaria', {
			id: screen.editId as number,
		})
			.then(() => {
				notify('Configuração editada com sucesso!');
				return handleSetRedirectContext('list', null);
			})
			.catch(() => {
				return notify('Não foi possível excluir a configuração!', { type: 'error' });
			});
	};
	return (
		<CustomDialogBody
			title={'Tem certeza que deseja deletar esta configuração?'}
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: '#CA3433' }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold', color: '#CA3433' }}>
							Essa ação é irreversível.
							<br />A configuração será removida.
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<CloseButton
						onClick={() => {
							setModalValue({ open: false, dialogBody: <></> });
						}}
						size='small'
					>
						cancelar
					</CloseButton>
					<DeleteButton size='small' onClick={handleSubmitDeleteConfigCorrecaoMonetaria}>
						Excluir
					</DeleteButton>
				</>
			}
		/>
	);
};

export default ModalDeleteConfirmation;
