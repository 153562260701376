import React from 'react';

import { EmailField, useListContext } from 'react-admin';

import LinearProgress from '@material-ui/core/LinearProgress';

import { Tabela } from 'components/common/Tabela';
import { DateField } from '../../../../../ContasReceberArrecadacoesList';
import TipoSituacaoField from '../TipoSituacaoField';

export const TabelaHistMail = (props) => {
	const { loading } = useListContext();

	return !loading ? (
		<Tabela {...props} disableClickRow>
			<TipoSituacaoField source='tipo_situacao' label='Situação' />
			<DateField source='data_envio' label='Data de envio' />
			<EmailField source='email' label='E-mail' />
		</Tabela>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

export default TabelaHistMail;
