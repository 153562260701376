/*
 * Arquivo responsável por gerar um documento a partir de um texto exportando ele do sistema,
 * como a geração de uma Remessa ou a exportação de um arquivo contábil.
 * o texto é convertido em um arquivo blob e é feito o download do arquivo.
 */
export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export const generateDocument = (text: string, fileName: string) => {
	const downloadLink = document.createElement('a');
	const blob = b64toBlob(text, 'text/plain,charset=us-ascii');
	downloadLink.href = URL.createObjectURL(blob);
	downloadLink.download = fileName;
	downloadLink.click();
};
