import React from 'react';

import {
	TooltipBarTabelaHistoricoProprietario,
	TooltipBarTabelaHistoricoInquilino,
} from '../actionsButtonsResponsaveis';

const AcoesField = ({ record }) => {
	if (record && record?.is_last && record?.id_pessoa) {
		switch (record.tipo) {
			case 'P':
				return <TooltipBarTabelaHistoricoProprietario record={record} />;
			case 'I':
				return <TooltipBarTabelaHistoricoInquilino record={record} />;
			default:
				return <></>;
		}
	} else {
		return <></>;
	}
};

export default AcoesField;
