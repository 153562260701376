import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import ConfiguracaoDeImpostosBody from './ConfiguracaoDosImpostosBody/index';
import React from 'react';

const ConfiguracaoDeImpostos: React.FC = () => (
	<ModalSizeEditableContextProvider>
		<ConfiguracaoDeImpostosBody />
	</ModalSizeEditableContextProvider>
);

export default ConfiguracaoDeImpostos;
