import React, { useContext } from 'react';

import { DialogContent, Box } from '@material-ui/core';
import ContaReceitaField from './ContaReceitaField';
import { DialogActionsPadrao } from './dialogActions';
import DialogTitleEditaConfiguracaoLeitura from './DialogTitleEditaConfiguracaoLeitura';
import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';
import InputReferencia from './InputReferencia';
import FatorConversaoField from './FatorConversaoField';
import FormEdicaoConfiguracaoLeitura from './FormEdicaoConfiguracaoLeitura';
import MetodoCalculoField from './MetodoCalculoField';
import UnidadeMedidaSelect from './UnidadeMedidaSelect';
import FaixasLeitura from '../../componentes/FaixasLeitura';
import NomeLeituraField from './InputNome';
import MultiSelect from 'components/common/Selects';
import { useDataProvider } from 'react-admin';

const FormPorFaixa = () => {
	const { dadosConfig, situacao, setDadosConfig } = useContext(EditaConfiguracaoLeituraContext);
	const [unidades, setUnidades] = React.useState([]);
	const [unidadesData, setUnidadesData] = React.useState([]);
	const dp = useDataProvider();

	const handleConfigSave = () => {
		setDadosConfig((v) => {
			v.unidades = (unidades || []).map((i) => i.id);
			return v;
		});
	};
	React.useEffect(() => {
		dp.getList('unidades', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'unidade', order: 'ASC' },
			filter: {
				id_condominio: dadosConfig.id_condominio,
				situacao: 'A',
			},
		}).then((response) => {
			const data = response?.data;
			if (data) {
				const dataDict = data.reduce((dict, item) => {
					dict[item.id] = item;
					return dict;
				}, {});
				setUnidadesData(data);
				if (dadosConfig.unidades)
					setUnidades(
						dadosConfig.unidades.reduce((list, item) => {
							const unidade = dataDict[item];
							if (unidade) list.push(unidade);
							return list;
						}, [])
					);
			}
		});
	}, [dadosConfig.id_condominio, dp, dadosConfig.unidades]);

	return (
		<FormEdicaoConfiguracaoLeitura
			initialValues={{
				nome_leitura: dadosConfig.nome_leitura,
				faixas_leitura: dadosConfig.faixas_leitura,
				fator_convercao: dadosConfig.fator_convercao,
				leituras_para_calculo: dadosConfig.leituras_para_calculo,
				nao_cobrar_taxa: dadosConfig.nao_cobrar_taxa,
				acesso_app: dadosConfig.acesso_app,
				percentual: dadosConfig.percentual,
				unidade_medida: dadosConfig.unidade_medida,
				unidades_ids: dadosConfig.unidades,
			}}
			render={({
				invalid,
				values: {
					faixas_leitura,
					fator_convercao,
					leituras_para_calculo,
					nao_cobrar_taxa,
					acesso_app,
					percentual,
					unidade_medida,
					...dadosConfigEdicao
				},
			}) => (
				<>
					<DialogTitleEditaConfiguracaoLeitura />
					<DialogContent>
						<Box style={{ 'margin-top': '1%' }} display='flex'>
							<InputReferencia />
							<NomeLeituraField />
							<ContaReceitaField />
						</Box>
						<Box style={{ 'margin-top': '1%' }} display='flex'>
							<MetodoCalculoField />
							<UnidadeMedidaSelect {...{ unidade_medida, fator_convercao }} />
							<FatorConversaoField {...{ unidade_medida, fator_convercao }} />
						</Box>
						<FaixasLeitura
							disabled={situacao !== 'A'}
							faixas_leitura={faixas_leitura}
							nao_cobrar_taxa={nao_cobrar_taxa}
							acesso_app={acesso_app}
						/>
						<MultiSelect
							variant={'formatsUnidades'}
							options={unidadesData}
							setValue={setUnidades}
							value={unidades}
							onBlur={handleConfigSave}
							withButtonAction={false}
						/>
					</DialogContent>
					<DialogActionsPadrao
						{...{
							dadosConfigEdicao,
							invalid,
							faixas_leitura,
							leituras_para_calculo,
							nao_cobrar_taxa,
							acesso_app,
							percentual,
						}}
					/>
				</>
			)}
		/>
	);
};

export default FormPorFaixa;
