import React, { createContext, useCallback, useContext, useEffect } from 'react';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import Box from '@material-ui/core/Box';

import { CustomDialog, ModalContextProvider } from '../../../../../common/ModalContext';
import SelectContaBancaria from '../../SelectContaBancaria';
import BoxSelectContaPlano from '../../BoxSelectContaPlano';
import SelectTipoLancamento from './SelectTipoLancamento';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';
import { TextField } from '@material-ui/core';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from 'components/common/CurrencyInput';
import { BoxDetalhamentosLancamentoDespesa } from '../modais/BoxDetalhamentosLancamentosDespesa';

export const ModalDetailContext = createContext();

const ConfiguracoesMultiplosLancamentosDespesas = () => {
	const {
		contaBancariaOrigem,
		contasBancarias,
		contasBancariasData,
		grupo,
		gruposLista,
		setContaBancariaOrigem,
		setGrupo,
		setUnidade,
		setTipoLancamento,
		unidade,
		unidadesLista,
		tipoLancamento,
		detalhamentosLancamentosDespesa,
		setDetalhamentosLancamentosDespesa,
	} = useContext(FluxoImportacaoExtratoBancarioContext);

	return (
		<ModalContextProvider Context={ModalDetailContext}>
			<Box display='flex' flexDirection='column' gridGap='0.5rem'>
				<SelectTipoLancamento
					value={tipoLancamento}
					setValue={setTipoLancamento}
					operacao='despesa'
					unidade={unidade}
					setUnidade={setUnidade}
					grupo={grupo}
					setGrupo={setGrupo}
					gruposData={gruposLista}
					unidadesData={unidadesLista}
				/>
				<BoxSelectContaPlano
					selectedConta={detalhamentosLancamentosDespesa[0]?.conta_despesa}
					setSelectedConta={(contaDespesa) =>
						setDetalhamentosLancamentosDespesa(([previousValue]) => [
							{ ...previousValue, conta_despesa: contaDespesa },
						])
					}
					operacao={{ tipo: 'despesa' }}
					ModalDetailContext={ModalDetailContext}
				/>
				<SelectContaBancaria
					label='Pagar com'
					setValue={setContaBancariaOrigem}
					value={contaBancariaOrigem}
					options={contasBancarias}
					contasData={contasBancariasData}
				/>
			</Box>

			<CustomDialog Context={ModalDetailContext} />
		</ModalContextProvider>
	);
};

const ConfiguracoesLancamentoDespesaIndividual = () => {
	const {
		contaBancariaOrigem,
		contasBancarias,
		contasBancariasData,
		detalhamentosLancamentosDespesa,
		grupo,
		gruposLista,
		setContaBancariaOrigem,
		setDetalhamentosLancamentosDespesa,
		setGrupo,
		setUnidade,
		setTipoLancamento,
		unidade,
		unidadesLista,
		tipoLancamento,
		registrosLancaveisSelecionados,
	} = useContext(FluxoImportacaoExtratoBancarioContext);
	const valorTotal = registrosLancaveisSelecionados[0].valor;

	const init = useCallback(() => {
		if (tipoLancamento === 'U') setTipoLancamento('C');
		setDetalhamentosLancamentosDespesa([
			{
				conta_despesa: null,
				valor: registrosLancaveisSelecionados[0].valor,
			},
		]);
	}, [tipoLancamento, setTipoLancamento]);

	useEffect(init, []);

	const initialValues = React.useMemo(() => {
		return {
			detalhamentos_lancamentos_despesa: [
				{
					conta_despesa: undefined,
					valor: registrosLancaveisSelecionados[0].valor,
				},
			],
		};
	}, [registrosLancaveisSelecionados[0].valor]);

	return (
		<ModalContextProvider Context={ModalDetailContext}>
			<>
				<Box display='flex' flexDirection='column' gridGap='0.5rem'>
					<SelectTipoLancamento
						value={tipoLancamento}
						setValue={setTipoLancamento}
						operacao='despesa'
						unidade={unidade}
						setUnidade={setUnidade}
						grupo={grupo}
						setGrupo={setGrupo}
						gruposData={gruposLista}
						unidadesData={unidadesLista}
					/>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
						}}
						mutators={{ ...arrayMutators }}
						initialValues={initialValues}
						render={({ values: { detalhamentos_lancamentos_despesa }, invalid }) => (
							<>
								<BoxDetalhamentosLancamentoDespesa
									origem={'EB'}
									valorTotal={valorTotal}
									detalhamentosLancamentosDespesa={detalhamentosLancamentosDespesa}
									setDetalhamentosLancamentosDespesa={setDetalhamentosLancamentosDespesa}
									ModalDetailContext={ModalDetailContext}
								/>
							</>
						)}
					/>
					<Box display='flex' alignItems='center'>
						<SelectContaBancaria
							label='Pagar com'
							setValue={setContaBancariaOrigem}
							value={contaBancariaOrigem}
							options={contasBancarias}
							contasData={contasBancariasData}
							style={{ width: '25.2rem' }}
						/>
						<TextField
							label='Total'
							size='small'
							disabled={true}
							value={valorTotal}
							style={{ marginTop: '4px', marginLeft: '5px', width: '10.8rem' }}
							prefix='R$ '
							decimalScale={2}
							inputProps={sanitizeListNumberFormatBRLProps({
								thousandsGroupStyle: 'thousand',
								decimalScale: 2,
								prefix: 'R$ ',
							})}
							InputProps={{
								inputComponent: NumberFormatBRL,
							}}
						/>
					</Box>
				</Box>

				<CustomDialog Context={ModalDetailContext} />
			</>
		</ModalContextProvider>
	);
};

const ConfiguracoesLancamentosDespesas = () => {
	const { setTipoLancamento, tipoLancamento, registrosLancaveisSelecionados } = useContext(
		FluxoImportacaoExtratoBancarioContext
	);

	const init = useCallback(() => {
		if (tipoLancamento === 'U') setTipoLancamento('C');
	}, [tipoLancamento, setTipoLancamento]);

	useEffect(init, []);

	if (registrosLancaveisSelecionados.length === 1) return <ConfiguracoesLancamentoDespesaIndividual />;
	return <ConfiguracoesMultiplosLancamentosDespesas />;
};

export default ConfiguracoesLancamentosDespesas;
