import React from 'react';

import {
	FinalFormCurrencyInput,
	NumberFormatBRL,
	sanitizeListNumberFormatBRLProps,
} from 'components/common/CurrencyInput';

const CamposMetodoPercentual = ({ percentual, disabled }) => {
	return (
		<>
			<FinalFormCurrencyInput
				// fullWidth
				name='percentual'
				id='percentual'
				label='Percentual %'
				value={percentual}
				disabled={disabled}
				style={{ marginTop: '8px', marginRight: '1em' }}
				margin='dense'
				onChange={(event) => {
					if (!event.target.value) event.target.value = 0;
					if (event.target.value > 999.99) event.target.value = 999.99;
				}}
				inputProps={sanitizeListNumberFormatBRLProps({
					decimalScale: 2,
					suffix: '%',
				})}
				InputProps={{
					inputComponent: NumberFormatBRL,
				}}
			/>
		</>
	);
};

export default CamposMetodoPercentual;
