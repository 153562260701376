import React, { useContext } from 'react';

import { useNotify } from 'react-admin';

import { parseISO } from 'date-fns';

import { Typography } from '@material-ui/core';

import { CustomDialogBodySizeEditable } from '../../../../common/ModalSizeEditableContext';
import { VinculosContext } from '../../VinculosContext';

const ModalDesvincular = ({ id_pessoa, nome, imobiliaria, tipo, id, reloadTable }) => {
	const notify = useNotify();
	const { dataProvider, setResponsaveis, edit, recordValue, choices, setReload } = useContext(VinculosContext);

	const handleSubmit = () => {
		if (edit) {
			imobiliaria
				? dataProvider
						.update('pessoa_unidade', {
							id,
							data: { id_imobiliaria: null },
						})
						.then(() => {
							setResponsaveis((v) =>
								v.map((v) => {
									if (v.id_pessoa === id_pessoa) {
										v.imobiliaria = null;
										v.id_imobiliaria = null;
									}
									return v;
								})
							);
							notify('Vínculo removido com sucesso', 'info');
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							notify('Erro ao remover o vínculo', 'warning');
						})
				: dataProvider
						.delete('pessoa_unidade', { id })
						.then(() => {
							dataProvider
								.getSimple('pessoa_unidade', {
									pagination: { page: 1, perPage: 1 },
									sort: { order: 'DESC', field: 'data_saida' },
									filter: { tipo: tipo, id_unidade: recordValue.id },
								})
								.then((response) => {
									const data = response?.data;
									const responsavel_anterior = (data?.results || [])[0];
									const dataSaida = responsavel_anterior?.data_saida
										? parseISO(responsavel_anterior.data_saida)
										: undefined;
									const pessoa_responsavel = responsavel_anterior
										? choices.find((p) => p.id === responsavel_anterior.id_pessoa)
										: undefined;

									if (pessoa_responsavel && (!dataSaida || (dataSaida && dataSaida >= new Date()))) {
										const { nome_responsavel, ...novo_ultimo_responsavel } = responsavel_anterior;
										setResponsaveis((v) =>
											v.map((r) =>
												r.tipo === tipo
													? { ...novo_ultimo_responsavel, ...pessoa_responsavel }
													: r
											)
										);
									} else {
										setResponsaveis((v) => v.filter((r) => r.id_pessoa !== id_pessoa));
									}
									if (reloadTable) setReload((v) => v + 1);
									notify('Vínculo removido com sucesso', 'info');
								})
								.catch((e) => {
									if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
									notify(
										'Vínculo removido com sucesso, mas ocorreu um erro ao atualizar a tela',
										'warning'
									);
								});
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							const errorMsg = Object.values(e?.response?.data || {})[0] || 'Erro ao remover o vínculo';
							notify(errorMsg, 'warning');
						});
		} else {
			setResponsaveis((v) =>
				imobiliaria
					? v.map((v) => {
							if (v.id_pessoa === id_pessoa) {
								v.imobiliaria = null;
								v.id_imobiliaria = null;
							}
							return v;
					  })
					: v.filter((v) => v.id_pessoa !== id_pessoa)
			);
		}
	};

	return (
		<CustomDialogBodySizeEditable
			title={`Tem certeza que deseja desvincular ${
				imobiliaria ? 'a imobiliária' : tipo === 'P' ? 'o proprietário' : 'o inquilino'
			} ${imobiliaria ? imobiliaria.nome : nome} desta unidade?`}
			customSubmitLabel='Remover Vínculo'
			form={{
				valid: true,
				handleSubmit,
				component: (
					<>
						{edit && !imobiliaria && (
							<Typography>
								<strong>Atenção!</strong>
								<br />
								Esta ação é irreversível.
							</Typography>
						)}

						{edit && tipo === 'P' && (
							<>
								<Typography>
									<br />
									Você só deve remover o vínculo de proprietário da unidade caso tenha cometido um
									erro na edição ou cadastro. Para qualquer outra situação, é recomendada a{' '}
									<strong>troca de proprietário</strong>. Lembre-se que uma unidade não pode ficar sem
									proprietário.
								</Typography>
							</>
						)}
					</>
				),
			}}
		/>
	);
};

export default ModalDesvincular;
