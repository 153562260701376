import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../../common/buttons/ButtonConfirm';
import { VinculosContext } from '../VinculosContext';

const ModalConfirmarSincronizacao = ({ ids }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const {
		palette: { syndikosRed },
	} = useTheme();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { setReload, setResponsaveis } = useContext(VinculosContext);
	const [processando, setProcessando] = useState(false);

	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	const handleSubmit = () => {
		setProcessando(true);
		dP.updateOnAction('pessoa_unidade', {
			data: { ids, action: 'sincronizar_enderecos_cobranca' },
		})
			.then((responses) => {
				const registrosAtualizados = responses?.data?.registros_atualizados || [];
				const numRegistrosAtualizados = registrosAtualizados.length;
				const registrosAtualizadosObject = Object.fromEntries(registrosAtualizados.map((r) => [r.id, r]));

				if (numRegistrosAtualizados) {
					setResponsaveis((responsaveisAtuais) =>
						responsaveisAtuais.map((responsavel) => {
							const dadosAtualizados = registrosAtualizadosObject[responsavel.id];
							if (!dadosAtualizados) return responsavel;

							return {
								...responsavel,
								...dadosAtualizados,
							};
						})
					);
				}

				notify(
					numRegistrosAtualizados > 1
						? `Dados de endereço sincronizados em ${numRegistrosAtualizados} responsáveis`
						: 'Dados de endereço sincronizados em 1 responsável'
				);
				setReload((v) => v + 1);
				handleClose();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setProcessando(false);
				notify('Erro inesperado, tente recarregar a página', 'warning');
				handleClose();
			});
	};

	return (
		<CustomDialogBodySizeEditable
			{...(processando
				? {
						form: {
							component: (
								<Box display='flex' justifyContent='center'>
									<CircularProgress />
								</Box>
							),
						},
						customActions: <></>,
				  }
				: {
						closeInSubmit: false,
						customActions: (
							<>
								<ButtonCancel
									onClick={() => {
										setModalValue((v) => ({
											...v,
											open: false,
										}));
									}}
								/>
								<ButtonConfirm size='small' onClick={handleSubmit} variant='text'>
									Confirmar
								</ButtonConfirm>
							</>
						),
						text: (
							<Box display='flex' alignItems='center' gridGap='20px'>
								<Box>
									<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
								</Box>
								<Box>
									<Typography
										style={{
											fontWeight: 'bold',
											color: syndikosRed.main,
										}}
									>
										{`${(ids || []).length} ${
											(ids || []).length > 1
												? 'responsáveis selecionados'
												: 'responsável selecionado'
										}`}
										<br />
										Essa ação é irreversível,
										<br />e irá sincronizar os dados de endereço{' '}
										{(ids || []).length > 1 ? 'dos responsáveis' : 'do responsável'}
										<br />
										{(ids || []).length > 1
											? 'com os dados de endereço de suas pessoas selecionadas'
											: 'com os dados de endereço da pessoa selecionada'}
									</Typography>
								</Box>
							</Box>
						),
						title: `Tem certeza que deseja sincronizar os endereços de cobrança ${
							(ids || []).length > 1 ? 'dos responsáveis' : 'do responsável'
						}?`,
				  })}
		/>
	);
};

export default ModalConfirmarSincronizacao;
