import React from 'react';

import { useForm } from 'react-final-form';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
	LimitePagamentoFields,
	ProtestoFields,
} from 'components/resources/condominios/ModalEditaContaBancaria/tabs/TabInstrucoesCobrancaRemessa';

import InstrucaoField from './InstrucaoField';

const ColunaDireitaComponentes = () => [<MultaFields />, <JurosFields />, <DescontoFields />];
const ColunaEsquerdaComponentesCNAB240 = () => [<LimitePagamentoFields />, <ProtestoFields />];
const ColunaEsquerdaComponentesCNAB400 = () => [
	<InstrucaoField numeroInstrucao='1' />,
	<InstrucaoField numeroInstrucao='2' />,
];
const ColunaEsquerdaComponentes = () => {
	const { getState } = useForm();
	const {
		values: { tipo_integracao },
	} = getState();
	const tipoIntegracaoId = tipo_integracao?.id ?? null;

	const Componente =
		{
			1: ColunaEsquerdaComponentesCNAB240,
			2: ColunaEsquerdaComponentesCNAB400,
			[null]: [],
		}[tipoIntegracaoId] ?? null;

	return <Componente />;
};

const TabInstrucoesCobrancaRemessa = () => {
	return (
		<PadraoTabInstrucoesCobrancaRemessaBase
			ColunaDireitaComponentes={<ColunaDireitaComponentes />}
			ColunaEsquerdaComponentes={<ColunaEsquerdaComponentes />}
		/>
	);
};

export default TabInstrucoesCobrancaRemessa;
