import React, { useState, memo } from 'react';

import { Box, TextField } from '@material-ui/core';

import { format, sub, add } from 'date-fns';

import { DateRangePicker } from 'materialui-daterange-picker-pt';

import { TPeriodoSelect, TPeriodoPicker, DateRange, TPeriodoOptions } from './types';

const TODAY = new Date().setHours(10, 0, 0);

const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const formatDate = (date: any) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

export const periodoOptions: TPeriodoOptions[] = [
	{
		label: 'Hoje',
		startDate: new Date(TODAY),
		endDate: new Date(TODAY),
	},
	{
		label: 'Ontem',
		startDate: sub(new Date(TODAY), { days: 1 }),
		endDate: sub(new Date(TODAY), { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(new Date(TODAY), { days: 3 }),
		endDate: new Date(TODAY),
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(new Date(TODAY), { days: 5 }),
		endDate: new Date(TODAY),
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(new Date(TODAY), { days: 7 }),
		endDate: new Date(TODAY),
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(new Date(TODAY), { days: 15 }),
		endDate: new Date(TODAY),
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(new Date(TODAY), { days: 30 }),
		endDate: new Date(TODAY),
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(new Date(TODAY), { days: 60 }),
		endDate: new Date(TODAY),
	},
	{
		label: 'Próximos 15 dias',
		startDate: new Date(TODAY),
		endDate: add(new Date(TODAY), { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: new Date(TODAY),
		endDate: add(new Date(TODAY), { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: new Date(TODAY),
		endDate: add(new Date(TODAY), { days: 60 }),
	},
];

const PeriodoPicker: React.FC<TPeriodoPicker> = memo(({ open, setOpen, setDateRange, minDate = new Date(MINDATE) }) => {
	const toggle = () => setOpen(!open);

	return (
		<Box
			className='periodo-date-range-picker'
			position='absolute'
			style={
				open
					? {
							zIndex: 3,
							maxHeight: 'calc(90vh - 200px)',
							left: 'calc(50% - 300px)',
					  }
					: { zIndex: -1, opacity: 0, pointerEvents: 'none', display: 'none' }
			}
		>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range: DateRange) => setDateRange(range)}
				initialDateRange={periodoOptions[0]}
				definedRanges={periodoOptions}
				minDate={minDate}
			/>
		</Box>
	);
});

const PeriodoSelectV2 = memo<TPeriodoSelect>(({ dateRange, setDateRange, label }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<TextField
				label={label}
				onClick={() => setOpen(true)}
				fullWidth
				inputProps={{
					value: `De ${formatDate(dateRange?.startDate)} até ${formatDate(dateRange?.endDate)}`,
				}}
				size='small'
			/>
			<PeriodoPicker {...{ open, setOpen, setDateRange }} />
		</>
	);
});

export default PeriodoSelectV2;
