import React, { useContext, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useNotify, useRefresh } from 'react-admin';
import dataProvider from 'dataProvider';
import { ButtonCancelV2 } from '../../../common/buttons/ButtonCancelV2';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';
import { BotaoProximo } from 'components/common/buttons/BotaoProximo';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import ModalSelecionaRetencaoImposto from '../modais/ModalSelecionaRetencaoImposto';
import BoxFiltraRetencao from '../components/BoxFiltraRetencao';

import { ModalLancamentoRetencaoProps, CondominioProps, ResponseProps, ModalValue, ImpostoProps } from '../types';

const ModalLancamentoRetencao: React.FC<ModalLancamentoRetencaoProps> = () => {
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	const [currentStep, setCurrentStep] = useState(0);
	const [condominios, setCondominios] = useState([]);
	const [impostos, setImpostos] = useState<ImpostoProps[]>([]);
	const [formValid, setFormValid] = useState(false);
	const [tipo, setTipo] = useState('');
	const [periodo, setPeriodo] = useState({ label: '', startDate: new Date(), endDate: new Date() });
	const [impostosRetencao, setImpostosRetencao] = useState([]);
	const dP = dataProvider;

	const idsCondominios = condominios.map((condominio: CondominioProps) => condominio.id);

	useEffect(() => {
		if (currentStep === 0) {
			setMinWidthModel('45vw');
		}
	}, [currentStep]);

	const handleValidationChange = (isValid: boolean) => {
		setFormValid(isValid);
	};

	const url = tipo === 'Emissão' ? 'imposto_parcelado_emissao' : 'imposto_parcelado_pagamento';

	const fetchData = async () => {
		const filters = {
			condominios_ids: idsCondominios,
			data_range: `${periodo.startDate.toISOString().split('T')[0]},${
				periodo.endDate.toISOString().split('T')[0]
			}`,
		};

		try {
			const response = await dP.getList(url, {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'numero_parcela', order: 'ASC' },
				filter: filters,
			});
			setImpostos([...response.data]);

			if (response.data.length === 1 && response.data[0].id === 0) {
				return false;
			} else {
				return true;
			}
		} catch (error) {
			notify('Erro ao buscar os impostos', { type: 'warning' });
			return false;
		}
	};

	const handleNextStep = async () => {
		if (formValid) {
			const hasData = await fetchData();

			if (hasData) {
				notify('Registros encontrados com sucesso', { type: 'info' });
				setCurrentStep(stepComponents()[currentStep].nextStep as number);
			} else {
				notify('Nenhum registro encontrado com os filtros selecionados', { type: 'warning' });
			}
		}
	};

	const stepComponents = (): {
		[key: number]: {
			nextStep?: number;
			prevStep?: number;
			component: React.ReactElement;
			valid: boolean;
			end?: boolean;
		};
	} => {
		return {
			0: {
				nextStep: 1,
				component: (
					<BoxFiltraRetencao
						setCondominios={setCondominios}
						setPeriodo={setPeriodo}
						setTipo={setTipo}
						onValidationChange={handleValidationChange}
						condominios={condominios}
						periodo={periodo}
						tipo={tipo}
					/>
				),
				valid: formValid,
			},
			1: {
				prevStep: 0,
				component: (
					<ModalSelecionaRetencaoImposto
						condominios={condominios}
						periodo={periodo}
						tipo={tipo}
						setMinWidthModel={setMinWidthModel}
						impostos={impostos}
						setImpostosRetencao={setImpostosRetencao}
					/>
				),
				valid: true,
				end: true,
			},
		};
	};

	const handleSubmit = () => {
		if (impostosRetencao.length === 0) {
			notify('Nenhum imposto selecionado', { type: 'warning' });
			return;
		}

		dP.create('retencoes_impostos', { data: impostosRetencao })
			.then(() => {
				notify('Impostos salvos com sucesso');
				setModalValue((v: ModalValue) => ({ ...v, open: false }));
				refresh();
			})
			.catch((error: ResponseProps) => {
				if (error?.response?.data?.error) {
					const errors = error?.response?.data?.error;
					notify(errors, { type: 'warning' });
				} else {
					notify('Erro inesperado', { type: 'warning' });
				}
			});
	};

	const acoes = (
		<>
			<ButtonCancelV2 onClick={() => setModalValue((v: ModalValue) => ({ ...v, open: false }))} />
			<BotaoVoltar
				disabled={currentStep === 0}
				onClick={() => setCurrentStep(stepComponents()[currentStep].prevStep as number)}
			/>
			{stepComponents()[currentStep].end ? (
				<ButtonConfirmV2 onClick={handleSubmit} disabled={impostosRetencao.length === 0} />
			) : (
				<BotaoProximo disabled={!formValid} onClick={handleNextStep} />
			)}
		</>
	);

	return (
		<CustomDialogBodySizeEditable
			customActions={acoes}
			form={{
				handleSubmit: () => {},
				component: (
					<Box
						style={{
							minHeight: '60px',
							display: 'grid',
							alignItems: 'center',
							width: '100%',
							transition: 'all 200ms ease',
						}}
					>
						{stepComponents()[currentStep].component}
					</Box>
				),
			}}
		/>
	);
};

export default ModalLancamentoRetencao;
