import React from 'react';

import { useDataProvider, useListContext, useNotify, useRefresh } from 'react-admin';

import { Dialog, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';

import { DeleteButton, CloseButton } from 'components/common/commonComponentsTSX';

type ModalConfirmarExclusaoGaragensProps = {
	open: boolean;
	onClose: (event: React.SyntheticEvent<boolean>, reason: 'backdropClick' | 'escapeKeyDown') => void;
};

const ModalConfirmarExclusaoGaragens: React.FC<ModalConfirmarExclusaoGaragensProps> = ({ open, onClose }) => {
	const [loading, setLoading] = React.useState(false);
	const dp = useDataProvider();
	const { data, selectedIds, onUnselectItems } = useListContext();
	const notify = useNotify();
	const refresh = useRefresh();

	const handleDelete = () => {
		setLoading(true);
		const ids = selectedIds.filter((id) => data[id]);
		dp.deleteMany('garagens_unidades', { ids })
			.catch((e) => notify('Inconsistencia na exclusão das garagens'))
			.finally(() => {
				setLoading(false);
				refresh(true);
				onUnselectItems();
			});
	};
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '30vw',
					position: 'relative',
				},
			}}
		>
			<DialogContent>Deseja realmente fazer a exclusão da garagem?</DialogContent>
			<DialogActions>
				<CloseButton onClick={onClose} size='small'>
					{(loading && <CircularProgress size={20} />) || 'Cancelar'}
				</CloseButton>
				<DeleteButton onClick={handleDelete} size='small' disabled={loading}>
					{(loading && <CircularProgress size={20} />) || 'Excluir'}
				</DeleteButton>
			</DialogActions>
		</Dialog>
	);
};

export default ModalConfirmarExclusaoGaragens;
