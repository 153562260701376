import React, { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { ContasContextProvider } from 'components/resources/planosCondominio/contexts/ContasContext';
import { SelectContaPlano } from 'components/resources/planosCondominio/modais';
import { FormDespesaContext } from './modaisDespesas';
import MensagemLinkadaCondominioSemPlano from './MensagemLinkadaCondominioSemPlano';
import { ErroItem } from './types';

export const BoxAddContaDespesa: React.FC = () => {
	const { selectedCondominio, contaDespesa, setContaDespesa, erros, setErros } = useContext(FormDespesaContext);
	const idPlano = selectedCondominio?.id_plano_condominio;

	const validate = () => {
		if (!contaDespesa) {
			setErros((e: ErroItem) => ({
				...e,
				conta_despesa: 'Selecione uma conta de despesa',
			}));
		} else if (erros?.conta_despesa) {
			setErros((e: ErroItem) => {
				const { conta_despesa, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [contaDespesa]);

	return !idPlano ? (
		<MensagemLinkadaCondominioSemPlano />
	) : (
		<ContasContextProvider id={idPlano}>
			<Box display='grid'>
				<SelectContaPlano
					tipo='despesa'
					selectedConta={contaDespesa}
					setSelectedConta={setContaDespesa}
					disabled={false}
					filter={undefined}
					msgErro={undefined}
				/>
			</Box>
		</ContasContextProvider>
	);
};
