import React, { useContext } from 'react';

import AutorenewIcon from '@material-ui/icons/Autorenew';

import ModalConfirmacaoRecalculo from './ModalConfirmacaoRecalculo';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import { TooltipIconButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';

const ButtonRecalcularArrecadacoes = ({ registrosSelecionados, ids }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmacaoRecalculo {...{ registrosSelecionados, ids }} />,
		}));
	};
	return (
		<TooltipIconButtonConfirm
			disabled={tem_arrecadacao_provisionada}
			onClick={handleClick}
			size='small'
			title={(ids || []).length > 1 ? 'Recalcular Arrecadações' : 'Recalcular Arrecadação'}
		>
			<AutorenewIcon />
		</TooltipIconButtonConfirm>
	);
};

export default ButtonRecalcularArrecadacoes;
