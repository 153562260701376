import React, { memo } from 'react';

import { DateRangePicker as DateRangePickerOriginal } from 'materialui-daterange-picker-pt';
import { ModalPeriodoPickerWrapper } from 'components/common/PeriodoSelect';

import { IPeriodoOption, DateRangePickerProps, IPeriodoPickerProps } from './types';
import periodoOptions from './periodoOptions';

const MINDATE = new Date(1990, 0, 1);
MINDATE.setHours(10, 0, 0);

const DateRangePicker = DateRangePickerOriginal as React.FC<DateRangePickerProps>;

const validateDate = (date: Date | number | undefined): boolean =>
	Boolean(date && typeof date !== 'number' && date.toString() === 'Invalid Date');

const PeriodoPicker: React.FC<IPeriodoPickerProps> = memo(({ dateRange, open, setOpen, setDateRange }) => {
	const toggle = () => setOpen(!open);
	return (
		<ModalPeriodoPickerWrapper {...{ open, setOpen }}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => setDateRange(range)}
				initialDateRange={
					validateDate(dateRange?.startDate) && validateDate(dateRange?.endDate)
						? ({
								label: 'custom',
								startDate: (dateRange.startDate as Date).setHours(10, 0, 0) && dateRange.startDate,
								endDate: (dateRange.endDate as Date).setHours(10, 0, 0) && dateRange.endDate,
						  } as IPeriodoOption)
						: dateRange
				}
				minDate={MINDATE}
				definedRanges={periodoOptions}
			/>
		</ModalPeriodoPickerWrapper>
	);
});

export default PeriodoPicker;
