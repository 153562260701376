import React, { useContext } from 'react';

import { Box, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

import { ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import ModalVinculos from '../modais/common/ModalVinculos';
import { VinculosContext } from '../VinculosContext';
import InfoPessoa from './InfoPessoa';
import InfoImobiliaria from './InfoImobiliaria';

const VinculosInputBody = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { responsaveis = [], disabledCondition } = useContext(VinculosContext);
	const owner = responsaveis.find((v) => v.tipo === 'P');

	const handleIncluir = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalVinculos owner={owner} />,
		}));
	};

	return (
		<Box>
			{responsaveis.length < 2 && (
				<Button
					onClick={handleIncluir}
					color='primary'
					variant='outlined'
					disabled={disabledCondition}
					id='button-vincular-pessoa'
				>
					Incluir {owner ? 'Inquilino' : 'Proprietário'}
				</Button>
			)}
			{responsaveis.length > 0 && (
				<Table size='small' padding='default'>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '1%', whiteSpace: 'nowrap' }}></TableCell>
							<TableCell style={{ paddingLeft: 0 }}>Nome</TableCell>
							<TableCell
								style={{
									paddingLeft: 0,
									width: '1%',
									whiteSpace: 'nowrap',
								}}
							>
								Tipo
							</TableCell>
							<TableCell style={{ paddingLeft: 0, width: 130 }}>CPF/CNPJ</TableCell>
							<TableCell style={{ paddingLeft: 0 }}>Contato</TableCell>
							<TableCell style={{ paddingLeft: 0, width: 120 }}>Período</TableCell>
							<TableCell style={{ width: 90, padding: 0 }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{responsaveis.reduce((rows, pessoa) => {
							rows.push(<InfoPessoa key={`pessoa-${pessoa.tipo}`} {...pessoa} />);
							if (pessoa.imobiliaria)
								rows.push(<InfoImobiliaria key={`imobiliaria-${pessoa.imobiliaria.id}`} {...pessoa} />);
							return rows;
						}, [])}
					</TableBody>
				</Table>
			)}
		</Box>
	);
};

export default VinculosInputBody;
