import React from 'react';
import { Edit } from 'react-admin';
import { EscritorioFormulario } from './form';

export const EscritorioEdit = (props) => {
	const auth = JSON.parse(localStorage.getItem('auth'));

	const onSuccess = (response) => {
		const apelidoAdministradora = response.data.apelido_administradora;
		if (apelidoAdministradora !== auth.tenant.apelido) {
			auth.tenant.apelido = apelidoAdministradora;
			localStorage.setItem('auth', JSON.stringify(auth));
			window.dispatchEvent(new Event('storage'));
		}
	};

	if (['Administrador', 'Suporte'].includes(auth.group_permissions.name)) {
		return (
			<Edit
				{...props}
				id={auth.tenant.schema_name}
				hasEdit={true}
				component='div'
				title={'Editar Escritório'}
				undoable={false}
				onSuccess={onSuccess}
			>
				<EscritorioFormulario edit {...props} />
			</Edit>
		);
	}
};
