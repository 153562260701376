import React from 'react';

import {
	formatCedentePattern1,
	formataPropsMaxValuePorLimitePagamentoDias,
	getDadosFormattingContaCorrente,
	parseCedentePattern1,
} from '../../../utils/formaters_parses';
import { formataOptionsPadrao } from '../../../utils/functions';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
	LimitePagamentoFields,
	ProtestoFields,
} from '../../../tabs/TabInstrucoesCobrancaRemessa';

import DefaultContaCorrenteProps, {
	defaultComponentesProps,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	defaultFormatadoresPayloadValues,
	getFormatadorPayloadCobrancaConfig,
	getValidacoesDesconto,
	getValidacoesJuros,
	getValidacoesLimitePagamento,
	getValidacoesMulta,
	getValidacoesProtesto,
	getValidateQuandoEmitindoBoletos,
	mensagensErrosPadroes,
	formataPayloadValuesTabContaCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesProtesto,
} from '../../default/ContaCorrenteProps';
import formatarInitialValues from '../../default/ContaCorrenteProps/formatarInitialValues';

import formatadoresPayloadValues from './formatadoresPayloadValues';
import TabInstrucoesCobrancaRemessaCNAB400 from './TabInstrucoesCobrancaRemessa';
import TabInstrucoesCobrancaBoleto from './TabInstrucoesCobrancaBoleto';
import validacoes from './validacoes';
import {
	getPropsOnChangeTipoPadraoInstrucao,
	createOnChangeTipoPadraoInstrucao,
} from './TabInstrucoesCobrancaRemessa/InstrucaoField';
import getValidate from './getValidate';
import getFormataPayloadValues from './getFormataPayloadValues';

import { ValuesProps } from '../../../types';

const getOptions = ({ opcoesDadosBancarios, optionsDefault = {} }: any, state: any) => {
	const options = formataOptionsPadrao({
		state,
		instituicaoCodigo: '237',
		opcoesDadosBancarios,
		optionsDefault,
		agencia: 'agencias_bradesco',
	});
	options['2'].instrucao_1 = options['2'].instrucao;
	return options;
};

const getExtras = ({
	props,
	instituicaoCodigo,
	initialDados: {
		opcoesDadosBancarios: { integracoes },
	},
}: any) => ({
	optionsIsencoes: integracoes[instituicaoCodigo].optionsIsencoes,
	...props,
});

const TabInstrucoesCobrancaRemessa = (props: ValuesProps) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<LimitePagamentoFields />, <ProtestoFields />]}
	/>
);

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }: ValuesProps) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesLimitePagamento({ values }),
	...formataPayloadValuesProtesto({ values }),
});

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	nomeCampoLegivel: 'limite de pagamento',
	msgErroVazio: mensagensErrosPadroes.vazioLimitePagamento(),
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamentoBaixaDevolver(),
});

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias,
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias,
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	msgErroVazio: mensagensErrosPadroes.vazioProtesto(),
	minValue: 2,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto(),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesLimitePagamento.tipo,
	validacoesLimitePagamento.dias,

	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '237',
	getOptions,
	getValidate,
	tabContents: {
		1: { ...DefaultContaCorrenteProps.tabContents, TabInstrucoesCobrancaRemessa, TabInstrucoesCobrancaBoleto },
		2: {
			...DefaultContaCorrenteProps.tabContents,
			TabInstrucoesCobrancaRemessa: TabInstrucoesCobrancaRemessaCNAB400,
			TabInstrucoesCobrancaBoleto,
		},
	},
	validacoes: {
		1: [
			...DefaultContaCorrenteProps.validacoes,
			getValidateQuandoEmitindoBoletos(validacoesInstrucoesCobrancaRemessa),
		],
		2: validacoes,
	},
	props: {
		1: {
			...defaultComponentesProps,
			conta_corrente: {
				...getDadosFormattingContaCorrente(8),
			},
			conta_cedente: {
				parse: parseCedentePattern1(7),
				format: formatCedentePattern1(7),
			},
			limite_pagamento_tipo: { label: 'Data Limite Pagamento' },
			protesto_dias: { min: 2, max: 90 },
		},
		2: {
			...defaultComponentesProps,
			conta_corrente: {
				...getDadosFormattingContaCorrente(8),
			},
			conta_cedente: {
				parse: parseCedentePattern1(7),
				format: formatCedentePattern1(7),
			},
			instrucao_1: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoInstrucao,
				createOnChangeTipo: createOnChangeTipoPadraoInstrucao,
			},
		},
	},
	getFormataPayloadValues,
	formatarInitialValues,
	formatadoresPayloadValues: {
		1: {
			...defaultFormatadoresPayloadValues,
			cobranca_config: getFormatadorPayloadCobrancaConfig({
				formatadores: [
					...defaultFormatadoresPayloadValuesCobrancaConfig,

					// Dados de Tab Conta
					formataPayloadValuesTabContaCobrancaConfig,

					// Dados de Tab Instruções de Cobrança Remessa
					formataPayloadValuesTabInstrucoesCobrancaRemessa,
				],
			}),
		},
		2: { ...formatadoresPayloadValues },
	},
	getExtras,
};

export default ContaCorrenteProps;
