import React from 'react';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';

import { ArrecadacoesContext } from '../../../../ArrecadacoesContext';
import { RemessasContext } from '../../contextos';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ModalConfiguracaoImpressaoSegundaVia } from '../../modais';
import { ThemePaletteProps, Registro } from './types';
import { IModalValue } from 'types/tpyesGlobal';

const useStyles = makeStyles<ThemePaletteProps>((theme) => ({
	baixaBoletosButton: {
		marginRight: '0.5rem',
		color: theme.palette.syndikosRed.contrastText,
		backgroundColor: theme.palette.syndikosRed.main,
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.dark,
		},
	},
}));

const useDadosPorInstituicaoTipoIntegracaoPadrao = () => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	const onClick = React.useCallback(() => {
		setModalValue((v: IModalValue) => ({
			...v,
			open: true,
			dialogBody: <ModalConfiguracaoImpressaoSegundaVia />,
		}));
	}, [setModalValue]);

	return React.useMemo(
		() => ({
			onClick,
		}),
		[onClick]
	);
};

const useGetHookDadosPorInstituicaoTipoIntegracao = () => {
	const { identificacaoInstituicaoTipoIntegracao } = React.useContext(ArrecadacoesContext);

	const useDadosPorInstituicaoTipoIntegracao = React.useMemo(
		() => useDadosPorInstituicaoTipoIntegracaoPadrao,
		[identificacaoInstituicaoTipoIntegracao]
	);

	return useDadosPorInstituicaoTipoIntegracao;
};

const ButtonGerarSegundaViaRemessas = () => {
	const { contaBDestinoIsValid, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados, registrosSelecionados } = React.useContext(RemessasContext);
	const useDadosPorInstituicaoTipoIntegracao: any = useGetHookDadosPorInstituicaoTipoIntegracao();
	const [loadingRequest, setLoadingRequest] = React.useState(false);
	const [valid, setValid] = React.useState(true);
	const classes = useStyles();

	React.useEffect(() => {
		const registrosArray = Object.values(registrosSelecionados) as Registro[];
		const temNossoNumeroEDataValida = registrosArray.every((registro) => {
			const nossoNumeroValido = registro.nosso_numero && registro.nosso_numero.trim() !== '';
			const dataVencimento = new Date(registro.data_vencimento + 'T00:00:00');
			const hoje = new Date();
			hoje.setHours(0, 0, 0, 0);
			const dataVencimentoValida = dataVencimento < hoje;
			return nossoNumeroValido && dataVencimentoValida;
		});
		setValid(temNossoNumeroEDataValida);
	}, [registrosSelecionados]);

	const { onClick } = useDadosPorInstituicaoTipoIntegracao({ setLoadingRequest, setValid });

	return (
		<Button
			size='small'
			disabled={
				!contaBDestinoId ||
				!contaBDestinoIsValid ||
				!idsRegistrosSelecionados.length ||
				!valid ||
				loadingRequest
			}
			variant='contained'
			startIcon={<GetAppIcon />}
			className={classes.baixaBoletosButton}
			onClick={onClick}
		>
			Gerar 2ª via de boletos
		</Button>
	);
};

export default ButtonGerarSegundaViaRemessas;
