import React, { useMemo, useRef, useState, useContext } from 'react';

import { CondominiosContext } from '../../../context/CondominioContextProvider';

import { List, Pagination } from 'react-admin';

import LeiturasListContext from './LeiturasListContext';
import TabelaFilters from './TabelaFilters';
import TabelaLancamentosLeituraPadrao from './TabelaLancamentosLeituraPadrao';
import TabelaLancamentosLeituraQTDMoradores from './TabelaLancamentosLeituraQTDMoradores';
import TabelaLancamentosLeituraPercentual from './TabelaLancamentosLeituraPercentual';
import { ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import ListActionsContextProvider from './listActions/components/ListActionsContextProvider';

const LeiturasListContextProvider = (props) => {
	const [leituraSituacao, setLeituraSituacao] = useState(undefined);
	const [leituraMetodoCalculo, setLeituraMetodoCalculo] = useState(undefined);
	const [qtdMoradoresTotal, setQtdMoradoresTotal] = useState(0);
	const [valorConsumoTotal, setValorConsumoTotal] = useState(0);
	const dataLeituraParaLancamentoLeitura = useRef(new Date());
	const { condominioSelecionado } = useContext(CondominiosContext);

	const providerValue = useMemo(
		() => ({
			leituraSituacao,
			setLeituraSituacao,
			leituraMetodoCalculo,
			setLeituraMetodoCalculo,
			dataLeituraParaLancamentoLeitura,
			qtdMoradoresTotal,
			setQtdMoradoresTotal,
			valorConsumoTotal,
			setValorConsumoTotal,
		}),
		[
			leituraSituacao,
			setLeituraSituacao,
			leituraMetodoCalculo,
			setLeituraMetodoCalculo,
			dataLeituraParaLancamentoLeitura,
			qtdMoradoresTotal,
			setQtdMoradoresTotal,
			valorConsumoTotal,
			setValorConsumoTotal,
		]
	);

	const TabelaLancamentosLeitura = useMemo(
		() =>
			({
				6: TabelaLancamentosLeituraQTDMoradores,
				4: TabelaLancamentosLeituraPercentual,
			}[leituraMetodoCalculo] ?? TabelaLancamentosLeituraPadrao),
		[leituraMetodoCalculo]
	);

	return (
		<LeiturasListContext.Provider value={providerValue}>
			<List
				filterDefaultValues={{
					id_condominio_id: condominioSelecionado?.id,
				}}
				bulkActionButtons={false}
				title='Leituras'
				{...props}
				empty={false}
				perPage={10}
				pagination={
					<Pagination
						rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
						labelRowsPerPage='Lançamentos de leitura por página'
					/>
				}
				actions={false}
				sort={{ field: 'unidade', order: 'ASC' }}
				filters={
					<ModalSizeEditableContextProvider
						customDialogProps={{
							disableBackdropClick: true,
							disableEscapeKeyDown: true,
							minWidth: '61vw',
						}}
					>
						<ListActionsContextProvider>
							<TabelaFilters />
						</ListActionsContextProvider>
					</ModalSizeEditableContextProvider>
				}
			>
				<TabelaLancamentosLeitura />
			</List>
		</LeiturasListContext.Provider>
	);
};

export default LeiturasListContextProvider;
