import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { format, parseISO } from 'date-fns';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import { SelectHorariosReserva } from '../inputs/SelectHorariosReserva';

import {
	makeStyles,
	Box,
	Divider,
	FormControlLabel,
	Checkbox,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	TextField,
} from '@material-ui/core';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { LoadingButton } from '../buttons/LoadingButton';
import JSTreeFromXML from './CardReservasEBloqueios/utils/JSTreeFromXML';
import { formatarHorariosDisponiveisEBloqueadosParaEdicao } from './CardReservasEBloqueios/utils';
import { useHorariosBloqueadosParaUnidade } from './CardReservasEBloqueios/hooks';

import { DIAS_DA_SEMANA, groupedHoursToObject, TIPO_RESERVA } from './CardReservasEBloqueios/utils';
import { formatFracoesThenGetInterval } from './CardReservasEBloqueios/utils';

const useStyles = makeStyles((theme) => ({
	cancelarButton: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.main,
			color: '#fff',
		},
	},
	editarButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
	},
	styledInfo: {
		'align-self': 'baseline',
		display: 'flex',
		'flex-direction': 'column',
		gap: '5px',
		'& > div > span': {
			display: 'flex',
			gap: '10px',
			'align-items': 'center',
		},
	},
}));

export const ModalEdicaoAgendamento = () => {
	const {
		consulta: {
			ambienteSelecionado,
			consultasNoDia,
			dadosCard,
			showModalEdicaoReservas,
			diaSelecionado,
			condominioSelecionado,
		},
		actionCallBackReloadReserva,
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);

	const [configEdicaoReserva, setConfigEdicaoReserva] = React.useState();
	const [reservaSelecionada, setReservaSelecionada] = React.useState();
	const [selectedHorarios, setSelectedHorarios] = React.useState([]);
	const [loadingEditar, setLoadingEditar] = React.useState(false);
	const [loadingInfos, setLoadingInfos] = React.useState(false);
	const [aceitouTermos, setAceitouTermos] = React.useState(false);
	const [observacoes, setObservacoes] = React.useState('');

	const dp = useDataProvider();
	const classes = useStyles();
	const notify = useNotify();

	const diaReservado = reservaSelecionada && format(parseISO(reservaSelecionada?.dia_reservado), 'dd/MM/yyyy');

	const custoAmbiente = reservaSelecionada?.taxa_por_uso
		? parseFloat(reservaSelecionada?.valor_cobrado).toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
		  })
		: 'Isento';

	const handleObservacoesChange = (e) => {
		if (e.target.value.length <= 500) {
			setObservacoes(e.target.value);
		}
	};

	React.useEffect(() => {
		setObservacoes(reservaSelecionada?.observacoes || '');
	}, [reservaSelecionada]);

	const onSubmit = () => {
		setLoadingEditar(true);
		const reservasIntervalosTempo = groupedHoursToObject(selectedHorarios);

		dp.update('reserva_agendada_syndkos', {
			id: configEdicaoReserva.id,
			data: {
				reservas_intervalos_tempo: reservasIntervalosTempo,
				dia_reservado: format(diaSelecionado, 'yyyy-MM-dd'),
				ambiente: ambienteSelecionado?.id,
				observacoes: observacoes,
			},
		})
			.then(() => {
				notify('Reserva editada com sucesso!', { type: 'success' });
				setConfigEdicaoReserva(null);
				actionCallBackReloadReserva();
			})
			.catch((error) => {
				if ([401, 403].includes(error?.response?.status)) return Promise.reject(error);
				const errorMessage = error?.response?.data || null;

				if (!errorMessage) {
					return notify('Falha ao editar reserva', 'warning');
				}

				Object.keys(errorMessage).forEach((keyName) => {
					notify(errorMessage[keyName][0], 'warning');
				});
			})
			.finally(() => setLoadingEditar(false));
	};

	function fetchDataCard() {
		if (!dadosCard?.id) return;
		setLoadingInfos(true);
		Promise.allSettled([
			dp.getOne('reserva_agendada_syndkos', { id: dadosCard.id }),
			dp.getSimple('bloqueio_reservas_syndkos', {
				filter: {
					dia_bloqueado: format(diaSelecionado, 'yyyy-MM-dd'),
					condominio_id: condominioSelecionado?.id,
					ambiente_id: ambienteSelecionado?.id,
				},
			}),
		])
			.then(([responseReservas, responseBloqueios]) => {
				const reservaCard = responseReservas.value?.data ?? {};
				const { configuracao: reservaDia, reservas_intervalos_tempo, outras_reservas } = reservaCard;
				const { fracao } = reservaDia;
				if (consultasNoDia) {
					const reservaFiltered = consultasNoDia.find((reserva) => reservaCard.id === reserva.id);
					setReservaSelecionada(reservaFiltered);
				}
				setSelectedHorarios(
					reservas_intervalos_tempo.length
						? formatFracoesThenGetInterval[fracao](reservas_intervalos_tempo)
						: []
				);

				const { horarios, horariosBloqueados, horariosUsadosPorUnidade } =
					formatarHorariosDisponiveisEBloqueadosParaEdicao(
						ambienteSelecionado,
						reservaDia,
						responseBloqueios,
						outras_reservas
					);

				reservaCard.horarios = horarios;
				reservaCard.horariosBloqueados = horariosBloqueados;
				reservaCard.horariosUsadosPorUnidade = horariosUsadosPorUnidade;

				setConfigEdicaoReserva(reservaCard);
				setLoadingInfos(false);
			})
			.catch(() => {
				notify('Falha ao coletar informação de reserva.', 'warning');
				setLoadingInfos(false);
			});
	}

	const horariosBloqueadosParaUnidade = useHorariosBloqueadosParaUnidade(
		configEdicaoReserva?.horariosBloqueados,
		configEdicaoReserva?.horariosUsadosPorUnidade,
		reservaSelecionada?.unidade_dados
	);
	React.useEffect(fetchDataCard, [dadosCard]);

	return (
		<Dialog
			open={showModalEdicaoReservas}
			onClose={() =>
				setConsulta((prevState) => ({
					...prevState,
					showModalEdicaoReservas: false,
				}))
			}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '61vw',
					position: 'relative',
				},
			}}
		>
			<DialogTitle>Edição da Reserva Agendada</DialogTitle>
			{loadingInfos ? (
				<DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress size={40} />
				</DialogContent>
			) : (
				<DialogContent>
					<Box className={classes.styledInfo}>
						<div>
							<span role='img' aria-label='Data da reserva'>
								<CalendarTodayIcon /> Data da reserva:
								<strong>
									{diaReservado}, {DIAS_DA_SEMANA[reservaSelecionada?.dia_semana]}
								</strong>
							</span>
						</div>
						<div>
							<span role='img' aria-label='Unidade'>
								<HomeIcon /> Unidade:
								<strong>
									{reservaSelecionada?.unidade_dados.nome_unidade}
									{reservaSelecionada?.unidade_dados.grupo_nome
										? `, ${reservaSelecionada?.unidade_dados.grupo_nome}`
										: null}
								</strong>
							</span>
						</div>

						<div>
							<span role='img' aria-label='Tipo da reserva'>
								<LocalOfferIcon /> Tipo da reserva:
								<strong>{TIPO_RESERVA[reservaSelecionada?.fracao]}</strong>
							</span>
						</div>
						<div>
							<span role='img' aria-label='Custo da reserva'>
								<PaymentIcon /> Custo da reserva:
								<strong>{custoAmbiente}</strong>
							</span>
						</div>
					</Box>
					<Divider style={{ margin: '10px 10px' }} />
					<Box>
						{configEdicaoReserva && (
							<SelectHorariosReserva
								horarios={configEdicaoReserva.horarios}
								horariosBloqueados={horariosBloqueadosParaUnidade}
								setSelectedHorarios={setSelectedHorarios}
								selectedHorarios={selectedHorarios}
							/>
						)}
					</Box>
					<Divider style={{ margin: '10px 10px' }} />
					<Box>
						<h3>Descrição</h3>
						<span>
							<JSTreeFromXML
								XMLString={ambienteSelecionado?.descricao || '<p>Nenhuma descrição disponível</p>'}
							/>
						</span>
						<Divider style={{ margin: '10px 10px' }} />
						<h3>Regulamento</h3>
						<span>
							<JSTreeFromXML
								XMLString={ambienteSelecionado?.regulamento || '<p>Nenhum regulamento disponível</p>'}
							/>
						</span>
						<Divider style={{ margin: '20px 10px 10px 10px' }} />
						<div>
							<h3>Observações</h3>
							<TextField
								fullWidth
								size='small'
								variant='outlined'
								placeholder='Observações'
								value={observacoes || ''}
								multiline
								onChange={handleObservacoesChange}
							></TextField>
							<Box margin={1}>{observacoes.length}/500 caracteres</Box>
						</div>
						<Divider style={{ margin: '20px 10px 10px 10px' }} />
						<div>
							<FormControlLabel
								label='Aceito os termos de uso desse
                                        ambiente'
								control={<Checkbox />}
								value={aceitouTermos}
								onChange={(_, c) => setAceitouTermos(c)}
							/>
						</div>
					</Box>
				</DialogContent>
			)}
			<DialogActions>
				<Button
					onClick={() =>
						setConsulta((prevState) => ({
							...prevState,
							showModalEdicaoReservas: false,
						}))
					}
					className={classes.cancelarButton}
					variant='contained'
				>
					Cancelar
				</Button>
				<LoadingButton
					onClick={onSubmit}
					className={classes.editarButton}
					loading={loadingEditar}
					disabled={!aceitouTermos || !selectedHorarios.length}
					size='medium'
				>
					Editar reserva
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
