import React, { memo, useContext, useState, useEffect } from 'react';

import { Box, TextField } from '@material-ui/core';

import { DateRangePicker } from 'materialui-daterange-picker-pt';

import { format, sub, add } from 'date-fns';

import { BoletosContext } from '../../abas/ContasReceberBoletos/BoletosContextProvider';

type PeriodoSelectProps = {
	label: string;
	size?: 'small';
};

type PeriodoPickerProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	setDateRange: (open: number) => void;
	minDate?: Date | string | undefined;
};

type CSSProps = {
	[key: string]: string | number;
};

const TODAY = new Date().setHours(10, 0, 0);
const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const formatDate = (date: Date | number) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const periodoOptions = [
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const PeriodoPicker: React.FC<PeriodoPickerProps> = memo(({ open, setOpen, setDateRange, minDate = MINDATE }) => {
	const toggle = () => setOpen(!open);
	const boxStyles: CSSProps = open
		? {
				zIndex: 3,
				left: 'calc(50% - 300px)',
				bottom: '1vh',
		  }
		: {
				zIndex: -1,
				opacity: 0,
				pointerEvents: 'none',
				bottom: '1vh',
		  };

	return (
		<Box className='periodo-date-range-picker' position='fixed' css={boxStyles}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => setDateRange(range as number)}
				initialDateRange={periodoOptions[0] as object}
				definedRanges={periodoOptions as any}
				minDate={minDate as string}
			/>
		</Box>
	);
});

const PeriodoSelect: React.FC<PeriodoSelectProps> = ({ label, size = 'small' }) => {
	const { dateRange, setDateRange, setDatasPrestacao, setDatasDemonstrativoMensal } = useContext(BoletosContext);
	const [open, setOpen] = useState(false);

	const handleRangeChange = () => {
		setDatasDemonstrativoMensal({
			data_inicial: format(dateRange.startDate, 'yyyy-MM-dd'),
			data_final: format(dateRange.endDate, 'yyyy-MM-dd'),
		});
		setDatasPrestacao({
			data_inicial: format(dateRange.startDate, 'yyyy-MM-dd'),
			data_final: format(dateRange.endDate, 'yyyy-MM-dd'),
		});
	};

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextField
				onClick={() => setOpen(true)}
				label={label}
				fullWidth
				inputProps={{
					value: `De ${formatDate(dateRange?.startDate)} até ${formatDate(dateRange?.endDate)}`,
				}}
				size={size}
			/>
			<PeriodoPicker {...{ open, setOpen, setDateRange }} />
		</>
	);
};

export default PeriodoSelect;
