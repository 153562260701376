import React from 'react';

import { RawKBDatePicker } from '../../../../common/InputDate';

const VinculosDatePicker = ({ label, value, onChange, fullWidth = true, ...rest }) => (
	<RawKBDatePicker
		format='dd/MM/yyyy'
		fullWidth={fullWidth}
		margin='dense'
		variant='inline'
		autoOk
		invalidDateMessage='Data inválida'
		{...rest}
		{...{ label, value, onChange }}
	/>
);

export default VinculosDatePicker;
