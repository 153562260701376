import React, { useContext } from 'react';

import { useRefresh } from 'react-admin';

import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import ModalFeedback from './ModalFeedback';

const ModalFeedbackRecalculo = ({ fulfilledResponses, rejectedResponses }) => {
	const refresh = useRefresh();
	const {
		palette: { syndikosRed },
	} = useTheme();
	const { carregaValoresCB } = useContext(ArrecadacoesContext);
	const onClose = () => {
		refresh();
		try {
			carregaValoresCB()();
		} catch (e) {}
	};

	return (
		<ModalFeedback
			component={
				<>
					{!!(fulfilledResponses || []).length && (
						<Typography>
							{(fulfilledResponses || []).length > 1
								? `${(fulfilledResponses || []).length} arrecadações recalculadas com sucesso!`
								: (rejectedResponses || []).length
								? '1 arrecadação recalculada com sucesso!'
								: 'Arrecadação recalculada com sucesso!'}
						</Typography>
					)}
					{(rejectedResponses || []).map(({ reason }) => (
						<Typography style={{ color: syndikosRed.main }}>
							{`${reason?.dados_arrecadacao?.complemento || ''}: ${
								Object.values(reason?.e?.response?.data || {})[0] || ''
							}`}
						</Typography>
					))}
				</>
			}
			onClose={onClose}
			title='Recálculo finalizado'
		/>
	);
};

export default ModalFeedbackRecalculo;
