import React from 'react';

import { Box } from '@material-ui/core';

import CadastrarDadosIntegracaoAPIField from '../../../../../commons/CadastrarDadosIntegracaoAPIField';
import EspecieTituloField from '../../../../../tabs/TabConta/EspecieTituloField';
import ContaCorrenteField from '../../../../../tabs/TabConta/ContaCorrenteField';
import ContaCedenteField from '../../../../../tabs/TabConta/ContaCedenteField';
import ImprimirPixField from '../../../../../tabs/TabConta/ImprimirPixField';

const TabConta = () => (
	<Box>
		<Box>
			<Box display='flex' flexDirection='row'>
				<Box>
					<ContaCorrenteField />
				</Box>
				<ContaCedenteField />
				<EspecieTituloField />
			</Box>
			<Box>
				<ImprimirPixField nomeBanco='Sicoob' />
			</Box>
			<Box gridColumnGap='0.5rem' display='flex' alignSelf='baseline' marginTop='6px' marginBottom='4px'>
				<CadastrarDadosIntegracaoAPIField />
			</Box>
		</Box>
	</Box>
);

export default TabConta;
