import React, { useContext, useEffect, useRef, useState } from 'react';
import { TooltipIconButtonCancel } from '../../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../../common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import MUIRichTextEditor, { TMUIRichTextEditorRef } from 'mui-rte';
import InfoIcon from '@material-ui/icons/Info';
import { Box } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';
import { tags } from './Tags';
import { ShowTags } from './ShowTags';

const SettingsModal = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dataProvider = useDataProvider();
	const [defaultBaixa, setDefaultBaixa] = useState('');
	const [anchorEditor, setAnchorEditor] = useState<HTMLElement | null>(null);
	const [saving, setSaving] = useState(false);
	const ref = useRef<TMUIRichTextEditorRef>(null);
	const notify = useNotify();

	useEffect(() => {
		saving && ref.current?.save();
	}, [saving]);

	useEffect(() => {
		const auth = JSON.parse(localStorage.getItem('auth') || '');
		dataProvider
			.getOne('administradora', {
				id: auth.tenant.schema_name,
			})
			.then((response) =>
				setDefaultBaixa(
					JSON.stringify({
						blocks: [
							{
								data: {},
								depth: 0,
								entityRanges: [],
								inlineStyleRanges: [],
								key: '2u8pb',
								text: response.data.descricao_baixa,
								type: 'unstyled',
							},
						],
						entityMap: {},
					})
				)
			);
	}, []);

	const TagsEditor = () => {
		return (
			<Box
				style={{
					backgroundColor: 'white',
					color: 'black',
				}}
				border={1}
				borderRadius='borderRadius'
			>
				{anchorEditor && (
					<ShowTags open={Boolean(anchorEditor)} anchor={anchorEditor} setAnchorEditor={setAnchorEditor} />
				)}
				<MUIRichTextEditor
					label="Digite '@' para utilizar as tags..."
					defaultValue={defaultBaixa}
					toolbarButtonSize='small'
					ref={ref}
					onSave={(data) => {
						saving
							? save(data)
							: setDefaultBaixa(
									JSON.stringify({
										blocks: [
											{
												data: {},
												depth: 0,
												entityRanges: [],
												inlineStyleRanges: [],
												key: '2u8pb',
												text: JSON.parse(data).blocks[0].text,
												type: 'unstyled',
											},
										],
										entityMap: {},
									})
							  );
					}}
					maxLength={500}
					controls={['tags']}
					customControls={[
						{
							name: 'tags',
							icon: <InfoIcon />,
							type: 'callback',
							onClick: (editorState, name, anchor) => {
								ref.current?.save();
								setAnchorEditor(anchor);
							},
						},
					]}
					autocomplete={{
						strategies: [
							{
								items: tags,
								triggerChar: '@',
							},
						],
					}}
				/>
			</Box>
		);
	};

	const save = (data: string) => {
		const auth = JSON.parse(localStorage.getItem('auth') || '');
		dataProvider
			.update('administradora', {
				id: auth.tenant.schema_name,
				data: { descricao_baixa: JSON.parse(data).blocks[0].text },
				previousData: { id: '' },
			})
			.then(() => {
				setModalValue((v: object) => ({ ...v, open: false }));
				notify('Descrição de baixa atualizada com sucesso');
			})
			.catch(() => {
				notify('Não foi possível atualizar a descrição de baixa');
				setSaving(false);
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title='Observação da Baixa para Relatórios'
			customActions={[
				<TooltipIconButtonCancel
					onClick={() => setModalValue((v: object) => ({ ...v, open: false }))}
					title={undefined}
					className={undefined}
				>
					{}
				</TooltipIconButtonCancel>,
				<TooltipIconButtonConfirm
					title='Salvar'
					disabled={saving}
					onClick={() => {
						setSaving(true);
						ref.current?.save();
					}}
				>
					{}
				</TooltipIconButtonConfirm>,
			]}
			form={{
				component: <TagsEditor />,
			}}
		/>
	);
};

export default SettingsModal;
