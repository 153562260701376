import React, { useContext } from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Toolbar, SaveButton, ListButton, useUpdate, useNotify, useTranslate } from 'react-admin';
import inflection from 'inflection';
import classnames from 'classnames';
import { makeStyles, Button, alpha, Typography, Tooltip } from '@material-ui/core';
import { BotaoRemover } from '../common/buttons/BotaoRemover';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from './ModalContext';

const useStylesForm = makeStyles({
	label: {
		padding: 0,
	},
});

const useStylesSaveButton = makeStyles(
	(theme) => ({
		saveButton: {
			color: theme.palette.primary.main,
			transition: 'all 100ms ease',
			'&:hover': {
				backgroundColor: alpha(theme.palette.primary.main, 0.95),
				color: '#f5f5f5',

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'SyndikosSaveButton' }
);

const ModalConfirmacao = ({ title, acao, record, resource }) => {
	const notify = useNotify();
	const translate = useTranslate();

	const translateOptions = {
		name: translate(`resources.${resource}.forcedCaseName`, {
			smart_count: 1,
			_: inflection.humanize(
				translate(`resources.${resource}.name`, {
					smart_count: 1,
					_: inflection.singularize(resource),
				}),
				true
			),
		}),
		id: record.id,
	};

	const handleSubmit = () => {
		notify(`Cadastro ${record.situacao === 'A' ? 'inativado' : 'ativado'} com sucesso`);
		acao();
	};

	return (
		<CustomDialogBody
			title={
				<>
					<Typography>
						{translate(title, {
							_: title,
							...translateOptions,
						})}
					</Typography>
				</>
			}
			customSubmitLabel='Confirmar'
			form={{ handleSubmit, valid: true }}
		/>
	);
};

const TooltipButtonRemover = (props) => (
	<Tooltip title={props.record.situacao_condominio === 'I' ? 'Condomínio inativo' : ''}>
		<span>
			<BotaoRemover
				{...props}
				style={{ marginLeft: 15 }}
				id='botao-remover'
				confirmTitle={`Tem certeza que deseja remover ${props?.recordName}?`}
				confirmContent=''
				disabled={props.record?.situacao_condominio === 'I' ? true : false}
				onClick={props?.onClickBotaoRemover}
			/>
		</span>
	</Tooltip>
);

const getRecordName = (props) =>
	({
		[true]: () => props.record?.nome,
		[props.resource === 'unidades']: () => props.resourceName + ' ' + props.record?.unidade,
		[props.resource === 'tarefas_manutencoes']: () => props.record?.nome_tarefa,
		[props.resource === 'ocorrencias']: () => 'a ocorrência',
		[props.resource === 'enquetes']: () => 'a enquete',
	}[true]());

const AcaoSituacao = (props) => {
	const notify = useNotify();
	const ativo = 'A';
	const inativo = 'I';
	const [inativar] = useUpdate(props.resource, props.record.id, {
		situacao: inativo,
		email: props.record?.email,
	});
	const [reativar] = useUpdate(props.resource, props.record.id, {
		situacao: ativo,
		email: props.record?.email,
	});
	const { setModalValue } = useContext(ModalContext);
	const resourceName = props.resource === 'unidades' ? props.record?.tipo_descricao?.toLowerCase() : '%{name}';
	const recordName = getRecordName({ ...props, resourceName });
	const ativarOuInativar = props?.record?.situacao === ativo ? 'inativar' : 'ativar';
	const onlyExcluir = props?.onlyExcluir;
	const handleClick = () => {
		if (props?.record?.situacao === 'A' && props?.record?.boleto_para === ('Amb' || 'Inq')) {
			notify('Para inativar essa unidade você precisa alterar o Responsável pela Cobrança para Proprietário');
		} else {
			setModalValue((v) => ({
				...v,
				open: true,
				dialogBody: (
					<ModalConfirmacao
						{...props}
						record={props.record}
						title={`Tem certeza que deseja ${ativarOuInativar} ${recordName}?`}
						acao={props?.record?.situacao === ativo ? inativar : reativar}
					/>
				),
			}));
		}
	};

	return (
		<>
			{!onlyExcluir && props.edit && props.record.situacao === ativo && (
				<Button style={{ color: '#CA3433' }} size='small' onClick={handleClick}>
					Inativar
				</Button>
			)}
			{props.edit &&
				((props.record.situacao === inativo && [
					<div>
						<Tooltip title={props.record.situacao_condominio === 'I' ? 'Condomínio inativo' : ''}>
							<span>
								<Button
									color='primary'
									size='small'
									onClick={handleClick}
									disabled={props.record.situacao_condominio === 'I' ? true : false}
								>
									Ativar
								</Button>
							</span>
						</Tooltip>
						{!props.onlyInactivate && <TooltipButtonRemover recordName={recordName} {...props} />}
					</div>,
				]) ||
					(onlyExcluir && <TooltipButtonRemover recordName={recordName} {...props} />))}
		</>
	);
};

export const AcoesForm = ({
	id,
	className,
	onlyExcluir,
	onClickBotaoRemover,
	customSaveButton,
	customBackButton,
	onlyInactivate = false,
	...props
}) => {
	const notify = useNotify();
	const classes = useStylesForm();
	const classesButton = useStylesSaveButton();
	return (
		<ModalContextProvider>
			<Toolbar {...props} style={{ justifyContent: 'space-between' }}>
				<div>
					{customSaveButton ? (
						React.cloneElement(customSaveButton, {
							...props,
							className: classnames(classesButton.saveButton, className),
							id: 'botao-salvar',
							size: 'small',
							variant: 'text',
							label: props.saveLabel,
							icon: props.saveIcon,
							disabled: props.pristine || props.invalid,
							redirect: 'list',
							onFailure: (e) =>
								notify(
									Object.values(e?.response?.data || {})[0] || [
										'Erro inesperado, tente recarregar a página',
									],
									'warning'
								),
						})
					) : (
						<SaveButton
							{...props}
							className={classnames(classesButton.saveButton, className)}
							id='botao-salvar'
							size='small'
							variant='text'
							label={props.saveLabel}
							icon={props.saveIcon}
							disabled={props.pristine || props.invalid}
							redirect='list'
							onFailure={(e) =>
								notify(
									Object.values(e?.response?.data || {})[0] || [
										'Erro inesperado, tente recarregar a página',
									],
									'warning'
								)
							}
						/>
					)}
					{!props.noBackButton &&
						(customBackButton ? (
							React.cloneElement(customBackButton, {
								style: { marginLeft: 20 },
								label: 'Voltar',
								variant: 'text',
								color: 'default',
								...props,
								classes: { label: classes.label },
								size: 'small',
								id: 'botao-voltar',
								icon: <NavigateBeforeIcon />,
							})
						) : (
							<ListButton
								style={{ marginLeft: 20 }}
								label='Voltar'
								variant='text'
								color='default'
								{...props}
								classes={{ label: classes.label }}
								size='small'
								id='botao-voltar'
								icon={<NavigateBeforeIcon />}
							/>
						))}
				</div>

				<AcaoSituacao
					onlyExcluir={onlyExcluir}
					onClickBotaoRemover={onClickBotaoRemover}
					onlyInactivate={onlyInactivate}
					{...props}
				/>
			</Toolbar>
			<CustomDialog />
		</ModalContextProvider>
	);
};
