import React, { useCallback, useContext, useMemo, useState } from 'react';

import { TreeTableContext } from '../contexts/TreeTableContext';
import RowContext from '../contexts/RowContext';
import RowRaizRender from './RowRaizRender';
import RowFilhoRender from './RowFilhoRender';

const getContaOriginal = (contaRaiz, contaBase) => {
	if (contaRaiz.id === contaBase.id) return contaRaiz;
	let contaOriginal = contaRaiz;
	while (contaOriginal) {
		contaOriginal = contaOriginal.children.find((c) => c.lft <= contaBase.lft && c.rght >= contaBase.rght);
		if (contaOriginal?.id === contaBase.id) break;
	}
	return contaOriginal;
};

const atualizarTipoDaInteiraArvore = (contaRaiz, novoTipo) => {
	contaRaiz.tipo = novoTipo;
	if (contaRaiz.id_pai) contaRaiz.id_pai.tipo = novoTipo;
	contaRaiz.children.map((c) => atualizarTipoDaInteiraArvore(c, novoTipo));
	return contaRaiz;
};

const RowContextProvider = ({ row }) => {
	const { tipo: tipoModel, setValue: setContasRaizes } = useContext(TreeTableContext);
	const [openChildren, setOpenChildren] = useState(row.openChildren);
	const [tipo, setTipo] = useState(row.tipo);

	const handleExpand = useCallback(() => {
		if (row.children.length > 0) {
			setOpenChildren((op) => {
				setContasRaizes((contasRaizes) => {
					const contaOriginal = getContaOriginal(contasRaizes.treeData[row.tree_id], row);
					if (contaOriginal) contaOriginal.openChildren = !op;
					return contasRaizes;
				});
				return !op;
			});
		}
	}, [row, setOpenChildren, setContasRaizes]);

	const atualizaTipo = useCallback(
		(novoTipo) => {
			if (row.raiz || row.level === 0) {
				setTipo(novoTipo);
				setContasRaizes((contasRaizes) => {
					const contaRaiz = contasRaizes.treeData[row.tree_id];
					atualizarTipoDaInteiraArvore(contaRaiz, novoTipo);
					return contasRaizes;
				});
			}
		},
		[row, setTipo, setContasRaizes]
	);

	const RowRender = useMemo(() => (row.raiz ? RowRaizRender : RowFilhoRender), [row.raiz]);

	const value = useMemo(
		() => ({
			openChildren,
			handleExpand,
			tipo,
			atualizaTipo,
		}),
		[openChildren, handleExpand, tipo, atualizaTipo]
	);

	return (
		<RowContext.Provider value={value}>
			<RowRender row={row} />
			{row.children.length > 0 && openChildren ? (
				<RowsRenderer key={tipoModel + row.id + 'children'} rows={row.children} />
			) : (
				<></>
			)}
		</RowContext.Provider>
	);
};

const RowsRenderer = ({ rows }) =>
	rows.map((row) => (
		<React.Fragment key={row.id}>
			<RowContextProvider row={row} />
		</React.Fragment>
	));

export default RowsRenderer;
