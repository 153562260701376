const getImageDimensions = (imageSrc) =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve([img.width, img.height]);
		img.onerror = reject;
		img.src = imageSrc;
	});

export const validateDimensions = async (
	width,
	height,
	inputValue,
	message = `Dimensões da imagem precisam ser ${width}x${height}`
) => {
	try {
		const [imgWidth, imgHeight] = await getImageDimensions(inputValue);
		if (imgWidth === width && imgHeight === height) return undefined;
	} catch (e) {
		console.log('Erro', e);
	}

	return message;
};

export const validateDimensionsMarcaDAgua = async (
	width,
	height,
	inputValue,
	message = `Dimensões da imagem precisam ser ${width}x${height}`
) => {
	try {
		const [imgWidth, imgHeight] = await getImageDimensions(inputValue);
		if (imgWidth === width && imgHeight === height) return undefined;
	} catch (e) {
		console.log('Erro', e);
	}
	return message;
};
