import React, { useContext, useState } from 'react';
import UserMenuItem from '../../common/UserMenuItem';

import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';

import { UserThemeContext } from '../../../theme/context';
import LoadingOverlay from '../../layout/Overlay';

function MenuItemToggleTheme() {
	const { theme, setTheme } = useContext(UserThemeContext);
	const [loadingChangeTheme, setLoadingChangeTheme] = useState(false);

	function isThemeChanged() {
		return new Promise((resolve) => {
			setTimeout(() => resolve(), 1500);
		});
	}

	function handleThemeToggle() {
		setLoadingChangeTheme(true);
		isThemeChanged()
			.then(() => {
				setTheme(theme === 'dark' ? 'light' : 'dark');
			})
			.finally(() => {
				setLoadingChangeTheme(false);
			});
	}

	return (
		<>
			<UserMenuItem
				menuItemProps={{ onClick: handleThemeToggle }}
				icon={theme === 'dark' ? <WbSunnyIcon /> : <Brightness3Icon />}
				label={theme === 'dark' ? 'Modo claro' : 'Modo escuro'}
			/>
			{loadingChangeTheme ? <LoadingOverlay /> : <></>}
		</>
	);
}

export default MenuItemToggleTheme;
