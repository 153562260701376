import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import EditaContaBancariaRadioGroup from '../../commons/EditaContaBancariaRadioGroup';
import DadosPorTipoIntegracaoContext from '../../contexts/DadosPorTipoIntegracaoContext';

const createSetValueTipo =
	({ field, value, options, change }) =>
	() => {
		const { objects = {}, choices = [] } = options[field] || {};
		if (choices.length && !objects[value]) change(field, choices[0].id);
	};

const SacadorAvalistaField = () => {
	const { options } = React.useContext(DadosPorTipoIntegracaoContext);
	const { getState, change } = useForm();
	const {
		values: { sacador_avalista },
	} = getState();

	const setValueTipo = createSetValueTipo({
		field: 'sacador_avalista',
		value: sacador_avalista,
		options,
		change,
	});

	useEffect(setValueTipo, []);

	return <EditaContaBancariaRadioGroup source='sacador_avalista' name='sacador_avalista' id='sacador_avalista' />;
};

export default SacadorAvalistaField;
