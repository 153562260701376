import { ImobiliariaCreate } from './create';
import { ImobiliariaEdit } from './edit';
import { ImobiliariaList } from './list';

const resource = {
	list: ImobiliariaList,
	create: ImobiliariaCreate,
	edit: ImobiliariaEdit,
};

export default resource;
