import React, { useContext } from 'react';

import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { ContaAReceberActionsContext } from './ContaAReceberActionsContext';
import {
	ModalConfirmarDesprovisionamentoPadrao,
	modaisConfirmarDesprovisionamentoPorInstituicaoTipoIntegracao,
} from './modaisConfirmarDesprovisionamento';

const BotaoAlterarSituacaoProvisionamento = ({ record }) => {
	const { tem_arrecadacao_provisionada, identificacaoInstituicaoTipoIntegracao } = useContext(ArrecadacoesContext);
	const { loading, provisionar } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);

	const handleClick = () => {
		if (record) {
			if (record.provisionada) {
				const ModalConfirmarDesprovisionamento =
					modaisConfirmarDesprovisionamentoPorInstituicaoTipoIntegracao[
						identificacaoInstituicaoTipoIntegracao
					] || ModalConfirmarDesprovisionamentoPadrao;
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalConfirmarDesprovisionamento />,
				}));
			} else {
				provisionar();
			}
		}
	};

	return (
		<TooltipIconButton
			disabled={!tem_arrecadacao_provisionada || loading || !(parseFloat(record?.valor_pendente) >= 0)}
			size='small'
			title={record?.provisionada ? 'Desprovisionar' : 'Provisionar'}
			onClick={handleClick}
		>
			{record?.provisionada ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
		</TooltipIconButton>
	);
};

export default BotaoAlterarSituacaoProvisionamento;
