import React from 'react';

import { FieldTitle, InputHelperText, ResettableTextField } from 'react-admin';

import CadastrarDadosIntegracaoAPIContext from '../../contexts/CadastrarDadosIntegracaoAPIContext';

export const CadastrarDadosIntegracaoAPITextField = ({ label = '', source = '', ...props }) => {
	const { values, touched, errors, saving, updateValue } = React.useContext(CadastrarDadosIntegracaoAPIContext);
	const [value, setValue] = React.useState(values[source] ?? '');
	const error = errors[source];

	return (
		<ResettableTextField
			id={source}
			disabled={saving}
			label={<FieldTitle label={label} isRequired={true} />}
			error={!!(touched[source] && error)}
			helperText={!!(touched[source] && error) && <InputHelperText touched={touched[source]} error={error} />}
			value={value}
			onChange={(e) => {
				const value = e.target.value;
				updateValue({ source, value });
				setValue(value);
			}}
			fullWidth
			margin='dense'
			variant='outlined'
			{...props}
		/>
	);
};
