import { IGetValidacoesDescontoProps } from '../../../types';

import {
	validacoesFieldsTabInformacao,
	createValidateLinhas,
	validateTipoIntegracao,
	createValidateDadosIntegracaoAPI,
} from '../../../utils/validadoes';

import {
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	mensagensErrosPadroes,
} from '../../default/ContaCorrenteProps';

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	maxValue: undefined,
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	maxValue: undefined,
});

type TGetValidacoesDesconto = (props: IGetValidacoesDescontoProps) => ReturnType<typeof getValidacoesDesconto>;

const validacoesDesconto = (getValidacoesDesconto as TGetValidacoesDesconto)({
	tipo: true,
	valor: true,
	dias: true,
	minValor: 0.01,
	msgErroMinValor: 'O valor do desconto à ser aplicado não pode ser menor que 0.01',
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,
];

const validateDadosIntegracaoAPI = createValidateDadosIntegracaoAPI();

const validateLinhas = createValidateLinhas({ numeroLinhas: 5, maxValue: 78 });

const validateQuandoEmitindoBoletos = getValidateQuandoEmitindoBoletos([
	validateTipoIntegracao,
	validateDadosIntegracaoAPI,
	...validacoesInstrucoesCobrancaRemessa,
	validateLinhas,
]);

const validacoes = [...validacoesFieldsTabInformacao, validateQuandoEmitindoBoletos];

export default validacoes;
