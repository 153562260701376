import React from 'react';

import { Box } from '@material-ui/core';

import memoize from '../../../../../common/memoize';
import { validacoesFieldsTabInformacao, validateAgencia, iterArrayValidacoes } from '../../utils/validadoes';
import { defaultFormatarValuesAgencia, defaultFormatarValuesContaCorrente } from '../../utils/functions';
import TabInformacao from '../../tabs/TabInformacao';
import { AgenciaField, ContaCorrenteField, TabContaBase } from '../../tabs/TabConta';

export const getValidate =
	({ instituicaoProps: { validacoes = [], ...instituicaoProps }, ...props }) =>
	(values) =>
		iterArrayValidacoes({
			...props,
			errors: {},
			instituicaoProps,
			values,
			validacoes,
		});

export const formatarInitialValues = ({
	conta_condominio,
	options: {
		agencia: { objects: agenciaObjs },
		id_inst_financeira: { objects: instituicoesObjs },
	},
}) => {
	const agenciaCodigo = conta_condominio.agencia_cv
		? `${conta_condominio.agencia}-${conta_condominio.agencia_cv}`
		: conta_condominio.agencia;
	const conta_corrente = conta_condominio.conta_corrente_cv
		? `${conta_condominio.conta_corrente}-${conta_condominio.conta_corrente_cv}`
		: conta_condominio.conta_corrente;
	return {
		...conta_condominio,
		id_inst_financeira: instituicoesObjs[conta_condominio.id_inst_financeira],
		agencia: agenciaObjs[agenciaCodigo],
		conta_corrente: conta_corrente,
	};
};

export const getFormataPayloadValues =
	({
		conta_condominio,
		instituicaoProps: {
			formatarValuesAgencia = defaultFormatarValuesAgencia,
			formatarValuesContaCorrente = defaultFormatarValuesContaCorrente,
			...instituicaoProps
		},
		...props
	}) =>
	({
		imprimir_saldo_relatorios,
		nome_conta,
		data_saldo_inicial,
		saldo_inicial,
		id_inst_financeira,
		apelido_conta_bancaria,
		...values
	}) => ({
		nome_conta,
		data_saldo_inicial,
		saldo_inicial,
		imprimir_saldo_relatorios,
		id_inst_financeira: id_inst_financeira?.id,
		...formatarValuesAgencia({ ...props, instituicaoProps, values }),
		...formatarValuesContaCorrente({ ...props, instituicaoProps, values }),
		id: conta_condominio.id,
		id_condominio: conta_condominio.id_condominio,
		situacao: conta_condominio.situacao,
		tipo_conta: conta_condominio.tipo_conta,
		tipo_conta_bancaria: conta_condominio.tipo_conta_bancaria,
		apelido_conta_bancaria,
	});

export const defaultComponentesProps = {
	tipo_conta: {
		source: 'tipo_conta_bancaria',
		name: 'tipo_conta_bancaria',
		id: 'tipo_conta_bancaria',
		choices: [
			{
				id: 'CT',
				text: 'Cotas',
			},
			{
				id: 'P',
				text: 'Conta Poupança',
			},
			{
				id: 'A',
				text: 'Aplicação',
			},
			{
				id: 'F',
				text: 'Fundo',
			},
		],
	},
};

const TabConta = () => (
	<TabContaBase>
		<Box flexDirection='row' display='flex'>
			<AgenciaField />
			<Box pl={1} sx={{ 'max-width': '15%' }} flex={1}>
				<ContaCorrenteField />
			</Box>
		</Box>
	</TabContaBase>
);

const ContaNaoCorrenteProps = {
	instituicaoCodigo: 'default',
	getDisabledTabs: memoize(() => ({ disabled0: false, disabled2: false })),
	tabContents: {
		TabInformacao,
		TabConta,
	},
	getFormataPayloadValues,
	getValidate,
	formatarInitialValues,
	validacoes: [...validacoesFieldsTabInformacao, validateAgencia],
};

export default ContaNaoCorrenteProps;
