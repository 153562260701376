import React, { memo, useCallback, useEffect, useState } from 'react';

import { useListContext } from 'react-admin';

import { TextField as TextFieldMUI } from '@material-ui/core';

import { sub, add, format, parseISO, set } from 'date-fns';

import { DateRangePicker } from 'materialui-daterange-picker-pt';
import { ModalPeriodoPickerWrapper } from '../../common/PeriodoSelect';

const TODAY = new Date();
TODAY.setHours(10, 0, 0);
const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const formatDate = (date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const periodoOptions = [
	{
		label: 'Limpar',
		startDate: undefined,
		endDate: undefined,
	},
	{
		label: 'Mês atual',
		startDate: set(TODAY, { date: 1 }),
		endDate: sub(add(set(TODAY, { date: 1 }), { months: 1 }), { days: 1 }),
	},
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const PeriodoPicker = memo(({ dateRange, open, setOpen, setDateRange }) => {
	const toggle = () => setOpen(!open);
	return (
		<ModalPeriodoPickerWrapper {...{ open, setOpen }}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => setDateRange(range)}
				initialDateRange={
					dateRange?.startDate &&
					typeof dateRange.startDate !== 'number' &&
					dateRange.startDate.toString() === 'Invalid Date'
						? {
								label: 'custom',
								startDate: dateRange.startDate.setHours(10, 0, 0) && dateRange.startDate,
								endDate: dateRange.endDate.setHours(10, 0, 0) && dateRange.endDate,
						  }
						: dateRange
				}
				minDate={MINDATE}
				definedRanges={periodoOptions}
			/>
		</ModalPeriodoPickerWrapper>
	);
});

const DataFiltro = memo(({ source }) => {
	const { setFilters, filterValues } = useListContext();
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState(
		filterValues && filterValues[`${source}_range_after`] && filterValues[`${source}_range_before`]
			? {
					label: 'custom',
					startDate: parseISO(filterValues[`${source}_range_after`]).setHours(10, 0, 0),
					endDate: parseISO(filterValues[`${source}_range_before`]).setHours(10, 0, 0),
			  }
			: periodoOptions[0]
	);

	const handleRangeChange = useCallback(() => {
		const startDate = dateRange?.startDate ? format(dateRange.startDate, 'yyyy-MM-dd') : undefined;
		const endDate = dateRange?.endDate ? format(dateRange.endDate, 'yyyy-MM-dd') : undefined;
		if (filterValues[`${source}_range_after`] !== startDate || filterValues[`${source}_range_before`] !== endDate) {
			setFilters({
				...filterValues,
				[`${source}_range_after`]: startDate,
				[`${source}_range_before`]: endDate,
			});
		}
	}, [setFilters, filterValues, source, dateRange]);

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextFieldMUI
				onClick={() => setOpen(true)}
				fullWidth
				inputProps={{
					value: `${dateRange?.endDate ? `de ${formatDate(dateRange?.startDate)}` : ''} ${
						dateRange?.endDate ? `até ${formatDate(dateRange?.endDate)}` : ''
					}`,
				}}
				variant='standard'
				margin='normal'
				style={{ marginBottom: 0 }}
			/>
			<PeriodoPicker {...{ dateRange, open, setOpen, setDateRange }} />
		</>
	);
});

export default DataFiltro;
