import React from 'react';

import { FormControl, Typography, Grid, TextField, Box, Checkbox, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNotify } from 'react-admin';

import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContextProvider,
} from 'components/common/ModalSizeEditableContext';
import ToolInputSelect, { ToolInput } from 'components/common/commonComponentsTSX/inputs/Toolinput';
import { RawKBDatePicker } from 'components/common/InputDate';
import { Pessoa } from 'types/tpyesGlobal';

import BoxPessoaSelect from '../Pessoa';
import { FiliacaoData, Funcao } from '../types';
import { useGestaoContext } from '../contexts/GestaoContext';
import { SelectMembroProps, idsSelecionadasType } from './types';
import { useGestaoModal } from '../hooks';
import { CancelButton2 } from 'components/common/commonComponentsTSX';
import SelectsMembrosProvider, { useSelectsMembrosContext } from '../contexts/SelectsMembrosContex';
import FiliacoesProvider from '../contexts/FiliacoesContext';
import { isBefore, isValid } from 'date-fns';

export const SelectMembro: React.FC<SelectMembroProps> = ({
	setFiliacoes,
	setValidP,
	indice,
	handleRemoveBoxSelect,
	defaultValue,
}) => {
	const notify = useNotify();
	const { pessoas } = useGestaoContext();
	const { selectsMembros } = useSelectsMembrosContext();
	const [idsSelecionadas, setIdsSelecionadas] = React.useState<FiliacaoData>(defaultValue as FiliacaoData);

	const defaultPessoa: Pessoa = (pessoas.find((pessoa) => pessoa.id === idsSelecionadas?.pessoa) as Pessoa) || null;

	const valid = !!(idsSelecionadas?.funcao && idsSelecionadas?.pessoa);

	const handleSetPessoaId = (pessoa: Pessoa) => {
		if (pessoa?.id) {
			setIdsSelecionadas(
				(prev) => ({ ...prev, pessoa: pessoa.id, nome_pessoa: pessoa?.nome } as idsSelecionadasType)
			);
		} else {
			setValidP(false, indice);
		}
	};

	const handleSetFuncaoId = (funcao: Funcao) => {
		if (funcao?.id) {
			setIdsSelecionadas(
				(prev) => ({ ...prev, funcao: funcao.id, funcao_descricao: funcao?.descricao } as idsSelecionadasType)
			);
		}
	};
	const handleSetAssinarRealtorio = () => {
		setIdsSelecionadas((prev) => ({ ...prev, assinar_relatorio: !prev?.assinar_relatorio } as idsSelecionadasType));
	};
	const handleSetGestaoApp = () => {
		setIdsSelecionadas((prev) => ({ ...prev, gestao_app: !prev?.gestao_app } as idsSelecionadasType));
	};

	const handleRemoveBox = () => {
		handleRemoveBoxSelect(indice);
	};

	React.useEffect(() => {
		setIdsSelecionadas(defaultValue as FiliacaoData);
	}, [JSON.stringify(defaultValue)]);

	React.useEffect(() => {
		if (valid) {
			setFiliacoes((prev: FiliacaoData[]) => {
				const newFiliacaoData: FiliacaoData[] = [...prev];
				newFiliacaoData[indice as number] = idsSelecionadas as FiliacaoData;
				return newFiliacaoData as FiliacaoData[];
			});

			setValidP(true, indice);
		}
	}, [idsSelecionadas, valid]);

	return (
		<Grid container spacing={2} alignItems='center'>
			<Grid item xs={6}>
				<Autocomplete
					noOptionsText='Nenhum resultado encontrado'
					disableClearable={true}
					id='pessoas-autocomplete'
					options={pessoas}
					disabled={!pessoas?.length}
					getOptionLabel={(pessoa: Pessoa) => pessoa.nome}
					getOptionSelected={(option: Pessoa, v: Pessoa) => v?.id === option.id}
					onChange={(_, p) => {
						const mensagem =
							p.documento == null && p.email_cobranca == null
								? 'nem um documento nem um email de cobrança cadastrado.'
								: p.documento == null
								? 'um documento cadastrado.'
								: p.email_cobranca == null
								? 'um email de cobrança cadastrado.'
								: null;

						if (mensagem !== null) {
							notify(`Pessoa ${p.nome} não possui ${mensagem}`, {
								type: 'error',
							});
						}
						return handleSetPessoaId(p as Pessoa);
					}}
					renderInput={(params) => (
						<TextField {...params} label='Selecionar Pessoa Existente' margin='dense' />
					)}
					value={defaultPessoa}
					fullWidth
				/>
			</Grid>
			<Grid item xs={6}>
				<Box style={{ display: 'flex' }}>
					<Box flex={1}>
						<ToolInputSelect
							ToolInput={
								<ToolInput
									getOptionsSelected={handleSetFuncaoId}
									optionText='descricao'
									label='função'
									TextFieldProps={{ fullWidth: true, size: 'small' }}
									defaultValueId={idsSelecionadas?.funcao}
								></ToolInput>
							}
							optionText='descricao'
							source='funcao_gestao'
						/>
					</Box>
					<Box style={{ display: 'flex', alignItems: 'center' }} mr={2}>
						<Checkbox
							checked={idsSelecionadas?.assinar_relatorio as boolean}
							onClick={handleSetAssinarRealtorio}
						/>
						<Typography style={{ whiteSpace: 'nowrap' }}>Assinar Relatório</Typography>
					</Box>
					<Box style={{ display: 'flex', alignItems: 'center' }} mr={2}>
						<Checkbox checked={idsSelecionadas?.gestao_app as boolean} onClick={handleSetGestaoApp} />
						<Typography style={{ whiteSpace: 'nowrap' }}>Gestão APP</Typography>
					</Box>
					<CancelButton2 onClick={handleRemoveBox} disabled={selectsMembros.length < 2} />
				</Box>
			</Grid>
		</Grid>
	);
};

const ModalBodyGestao: React.FC = React.memo(() => {
	const {
		handleSubmitNewGestion,
		handleEditGestion,
		selectsMembros,
		handleAddSelectsMembro,
		handleSetNome,
		inicioMandato,
		setInicioMandato,
		fimMandato,
		setFimMandato,
		handleSetValid,
		valid,
		validSelectsMembro,
		modGestao,
		nome,
	} = useGestaoModal();

	return (
		<>
			<CustomDialogBodySizeEditable
				title={modGestao === 'create' ? 'Cadastrar Gestão' : 'Editar Gestão'}
				closeInSubmit={false}
				DialogContentCustomStyle={{ overflow: 'auto' }}
				form={{
					valid:
						valid &&
						validSelectsMembro &&
						isValid(fimMandato) &&
						isValid(inicioMandato) &&
						!isBefore(fimMandato as Date, inicioMandato),
					handleSubmit: () => (modGestao === 'create' ? handleSubmitNewGestion() : handleEditGestion()),
					component: (
						<>
							<FormControl fullWidth>
								<TextField
									size='small'
									label='Nome da Gestão'
									style={{ height: '60px' }}
									onChange={handleSetNome}
									value={nome}
								/>
							</FormControl>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<RawKBDatePicker
										format='dd/MM/yyyy'
										fullWidth
										margin='dense'
										variant='inline'
										autoOk
										label='Início do Mandato *'
										value={inicioMandato}
										onChange={setInicioMandato}
										disableFuture
									/>
								</Grid>
								<Grid item xs={6}>
									<RawKBDatePicker
										format='dd/MM/yyyy'
										fullWidth
										margin='dense'
										variant='inline'
										label='Fim do Mandato *'
										value={fimMandato}
										onChange={setFimMandato}
									/>
								</Grid>
							</Grid>
							<ModalSizeEditableContextProvider>
								<BoxPessoaSelect handleSetValid={handleSetValid} />
							</ModalSizeEditableContextProvider>

							{selectsMembros?.map((item) => item.component)}

							<Button
								color='primary'
								onClick={() => handleAddSelectsMembro()}
								style={{
									textTransform: 'uppercase',
									fontSize: '1rem',
									cursor: 'pointer',
								}}
								disabled={!validSelectsMembro}
							>
								ADICIONAR NOVO MEMBRO
							</Button>
						</>
					),
				}}
				customActions={null}
			/>
		</>
	);
});

const ModalGestao: React.FC = () => (
	<SelectsMembrosProvider>
		<FiliacoesProvider>
			<ModalBodyGestao />
		</FiliacoesProvider>
	</SelectsMembrosProvider>
);

export default ModalGestao;
