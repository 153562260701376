import React from 'react';

import { Menu, MenuItem } from '@material-ui/core';

const MenuItemTipoConta = ({ disabled, text, tipo, handleClickItem }) => (
	<MenuItem disabled={disabled} onClick={() => handleClickItem(tipo)}>
		{text}
	</MenuItem>
);

export const menuItemsContasDespesas = [
	{ tipo: 'O', text: 'Ordinária' },
	{ tipo: 'E', text: 'Extraordinária' },
];

export const menuItemsContasReceitas = [
	{ tipo: 'O', text: 'Ordinária' },
	{ tipo: 'E', text: 'Extraordinária' },
	{ tipo: 'L', text: 'Leitura' },
	{ tipo: 'F', text: 'Fundo' },
];

export const menuItemsPorTipoContas = {
	despesas: menuItemsContasDespesas,
	receitas: menuItemsContasReceitas,
};

const MenuTipoConta = ({
	anchorEl,
	handleClose,
	anchorOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	handleClickItem,
	items = [],
	disabledItem,
}) => (
	<Menu
		anchorEl={anchorEl}
		open={!!anchorEl}
		onClose={handleClose}
		anchorOrigin={anchorOrigin}
		transformOrigin={transformOrigin}
	>
		{items.map((mi) => (
			<MenuItemTipoConta handleClickItem={handleClickItem} disabled={disabledItem === mi.tipo} {...mi} />
		))}
	</Menu>
);

export default MenuTipoConta;
