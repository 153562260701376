import React, { useContext } from 'react';

import { TextInput } from 'react-admin';

import { Box } from '@material-ui/core';

import EditaContaBancariaContext from '../../contexts/EditaContaBancariaContext';
import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';
import { agenciaFormatacao, agenciaParser } from '../../utils/formaters_parses';
import DadosPorTipoIntegracaoContext from '../../contexts/DadosPorTipoIntegracaoContext';

export const AgenciaTextField = ({ input, meta }) => {
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const {
		props: { agenciaBackup: { defaultDisabled = false, ...agenciaProps } = {} },
	} = useContext(DadosPorTipoIntegracaoContext);
	return (
		<TextInput
			value={typeof input.value === 'object' ? input.value.id : input.value}
			name='agencia'
			error={!!meta.error}
			helperText={meta.error ? meta.error : false}
			fullWidth
			disabled={isInativo || defaultDisabled}
			label='Agência'
			variant='outlined'
			format={agenciaFormatacao}
			parse={agenciaParser}
			{...agenciaProps}
		/>
	);
};

const AgenciaField = () => (
	<Box sx={{ 'max-width': '25%' }} flex={1}>
		<EditaContaBancariaAutocomplete source='agencia' name='agencia' id='agencia' label='Agência' />
	</Box>
);

export default AgenciaField;
