import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import VencimentoField from '../VencimentoField';

import { FormParcelamentoContext } from './FormParcelamentoContext';

const ParcelamentoVencimentoField = () => {
	const {
		dataVencimentoToken: value,
		setDataVencimentoToken: setValue,
		erros,
		setErros,
	} = useContext(FormParcelamentoContext);

	return (
		<VencimentoField
			{...{
				value,
				setValue,
				erros,
				setErros,
				erroField: 'data_vencimento',
				fullWidth: false,
			}}
		/>
	);
};

const SmallRadio = (props) => <Radio {...props} size='small' />;

const SmallTypography = ({ style, ...props }) => <Typography {...props} style={{ ...style, 'font-size': 'small' }} />;

const ModificadorVencimentoField = () => {
	const { modificadorVencimentoToken, setModificadorVencimentoToken, tipoParcelamentoToken } =
		useContext(FormParcelamentoContext);
	return (
		<FormControl component='fieldset'>
			<RadioGroup
				aria-label='modificadorVencimento'
				name='modificadorVencimentoToken'
				value={tipoParcelamentoToken === 'S' ? 'M' : modificadorVencimentoToken}
				onChange={(_, newValue) => {
					setModificadorVencimentoToken(newValue);
				}}
				row
			>
				<FormControlLabel
					value='M'
					control={<SmallRadio />}
					label={<SmallTypography>Manter Vencimento</SmallTypography>}
					disabled={tipoParcelamentoToken === 'S'}
				/>
				<FormControlLabel
					value='A'
					control={<SmallRadio />}
					label={<SmallTypography>Antecipar ou</SmallTypography>}
					disabled={tipoParcelamentoToken === 'S'}
				/>
				<FormControlLabel
					value='P'
					control={<SmallRadio />}
					label={<SmallTypography>Postergar Sábados, Domingos e Feriados Nacionais</SmallTypography>}
					disabled={tipoParcelamentoToken === 'S'}
				/>
			</RadioGroup>
		</FormControl>
	);
};

const BoxVencimento = () => (
	<Box display='flex'>
		<Box flex={1.5} alignSelf='center' mr='1em'>
			<SmallTypography>
				Estas parcelas serão geradas com referência na seguinte data de vencimento:
			</SmallTypography>
		</Box>
		<Box flex={1}>
			<ParcelamentoVencimentoField />
		</Box>
		<Box flex={2}>
			<ModificadorVencimentoField />
		</Box>
	</Box>
);

export default BoxVencimento;
