import React from 'react';

import MUIRichTextEditor from 'mui-rte';

import { tagsEditorText } from '../../mentions/mentions';

import { useStyle } from './StyleTextInput';

const TextInputMention = ({ assuntoText = '', handleSaveAssunto = () => {}, ...props }) => {
	const classes = useStyle(props);
	const [focus, setFocus] = React.useState(false);
	const [editorContent, setEditorContent] = React.useState([]);
	const textRef = React.useRef(null);
	const [firstRow] = editorContent;
	const digitouAlgo = !!firstRow?.text;

	return (
		<div className={`${classes.root} ${focus ? classes.borderFocus : ''}`}>
			<label className={digitouAlgo || focus ? classes.focus : ''} style={{ borderRadius: '10px' }}>
				<legend className={classes.legend}>
					<span>Digite o assunto do e-mail:</span>
				</legend>
			</label>
			<MUIRichTextEditor
				defaultValue={assuntoText}
				maxLength={200}
				editor={classes.editor}
				id='textInput'
				ref={textRef}
				onSave={(data) => {
					handleSaveAssunto(data);
					setEditorContent(JSON.parse(data).blocks);
				}}
				onChange={() => textRef.current.save()}
				onFocus={() => {
					textRef.current.save();
					setFocus(true);
				}}
				onBlur={() => {
					textRef.current.save();
					setFocus(false);
				}}
				toolbar={false}
				inlineToolbar={false}
				autocomplete={{
					strategies: [
						{
							items: tagsEditorText,
							triggerChar: '@',
						},
					],
				}}
			/>
		</div>
	);
};

export default TextInputMention;
