import React, { useState, useEffect } from 'react';

import { useGetList } from 'react-admin';

import { Box, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelect, periodoOptions } from '../dateUtils';
import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesTotaisCheckboxGroup = ({
	imprimirTotaisDiarios,
	imprimirTotalConta,
	nonImprimirSaldoByMovimentacao,
	handleChange,
	disabled,
}) => (
	<FormGroup>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirTotaisDiarios}
					onChange={(e, checked) => handleChange(checked, 'imprimir_totais_diarios')}
					name='imprimir_totais_diarios'
				/>
			}
			label='Imprimir Totais Diários'
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirTotalConta}
					onChange={(e, checked) => handleChange(checked, 'imprimir_total_conta')}
					name='imprimir_total_conta'
				/>
			}
			label='Imprimir Total da Conta'
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={nonImprimirSaldoByMovimentacao}
					onChange={(e, checked) => handleChange(checked, 'non_imprimir_saldo_by_movimentacao')}
					name='non_imprimir_saldo_by_movimentacao'
				/>
			}
			label='Não imprimir saldo por movimentação'
			disabled={disabled}
		/>
	</FormGroup>
);

const OpcoesResumoContasCheckboxGroup = ({
	imprimirResumoConta,
	imprimirTotaisResumoConta,
	separarPorTipoResumoContas,
	imprimirResumoSeparado,
	handleChange,
	disabled,
}) => (
	<FormGroup>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirResumoConta}
					onChange={(e, checked) => handleChange(checked, 'imprimir_resumo_conta')}
					name='imprimir_resumo_conta'
				/>
			}
			label='Imprimir resumo das contas'
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirTotaisResumoConta}
					onChange={(e, checked) => handleChange(checked, 'imprimir_totais_resumo_conta')}
					name='imprimir_totais_resumo_conta'
				/>
			}
			label='Imprimir total das contas no resumo'
			disabled={disabled}
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={separarPorTipoResumoContas}
					onChange={(e, checked) => handleChange(checked, 'seprarar_por_tipo_resumo_contas')}
					name='seprarar_por_tipo_resumo_contas'
				/>
			}
			label='Separar contas por tipo'
			disabled={disabled}
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirResumoSeparado}
					onChange={(e, checked) => handleChange(checked, 'imprimir_resumo_separado')}
					name='imprimir_resumo_separado'
				/>
			}
			label='Pular página para o resumo'
			disabled={disabled}
		/>
	</FormGroup>
);

const OpcoesImpressaoGeralCheckboxGroup = ({
	imprimirNumeroTransacao,
	imprimirPontilhado,
	pularPaginaEntreContas,
	imprimirAssinatura,
	handleChange,
}) => (
	<FormGroup>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirNumeroTransacao}
					onChange={(e, checked) => handleChange(checked, 'imprimir_numero_transacao')}
					name='imprimir_numero_transacao'
				/>
			}
			label='Imprimir número da transação'
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirPontilhado}
					onChange={(e, checked) => handleChange(checked, 'imprimir_pontilhado')}
					name='imprimir_pontilhado'
				/>
			}
			label='Imprimir pontilhado'
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={pularPaginaEntreContas}
					onChange={(e, checked) => handleChange(checked, 'pular_pagina_entre_contas')}
					name='pular_pagina_entre_contas'
				/>
			}
			label='Pular página entre contas'
		/>
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimirAssinatura}
					onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
					name='imprimir_assinatura'
				/>
			}
			label='Imprimir assinaturas'
		/>
	</FormGroup>
);

const OpcoesImpressaoFMBCheckboxGroup = () => {
	const {
		opcoesImpressao: {
			imprimir_totais_diarios,
			imprimir_total_conta,
			non_imprimir_saldo_by_movimentacao,
			imprimir_resumo_conta,
			imprimir_totais_resumo_conta,
			seprarar_por_tipo_resumo_contas,
			imprimir_resumo_separado,
			imprimir_numero_transacao,
			imprimir_pontilhado,
			pular_pagina_entre_contas,
			imprimir_assinatura,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);
	let disableNonImprimirSaldo = [imprimir_totais_diarios, imprimir_total_conta].every((v) => v === false);
	useEffect(() => {
		if (disableNonImprimirSaldo) handleChangeOpcoesImpressao(false, 'non_imprimir_saldo_by_movimentacao');
	}, [disableNonImprimirSaldo]);
	useEffect(() => {
		if (!imprimir_resumo_conta) {
			handleChangeOpcoesImpressao(false, 'imprimir_totais_resumo_conta');
			handleChangeOpcoesImpressao(false, 'seprarar_por_tipo_resumo_contas');
			handleChangeOpcoesImpressao(false, 'imprimir_resumo_separado');
		}
	}, [imprimir_resumo_conta]);
	return (
		<>
			<Box flex={1} mr='1em'>
				<FormLabel component='legend'>Opções de Totais</FormLabel>
				<OpcoesTotaisCheckboxGroup
					imprimirTotaisDiarios={imprimir_totais_diarios}
					imprimirTotalConta={imprimir_total_conta}
					nonImprimirSaldoByMovimentacao={non_imprimir_saldo_by_movimentacao}
					handleChange={handleChangeOpcoesImpressao}
					disabled={disableNonImprimirSaldo}
				/>
			</Box>
			<Box flex={1} mr='1em'>
				<FormLabel component='legend'>Opções de Resumo das Contas</FormLabel>
				<OpcoesResumoContasCheckboxGroup
					imprimirResumoConta={imprimir_resumo_conta}
					imprimirTotaisResumoConta={imprimir_totais_resumo_conta}
					separarPorTipoResumoContas={seprarar_por_tipo_resumo_contas}
					imprimirResumoSeparado={imprimir_resumo_separado}
					handleChange={handleChangeOpcoesImpressao}
					disabled={!imprimir_resumo_conta}
				/>
			</Box>
			<Box flex={1} mr='1em'>
				<FormLabel component='legend'>Opções de Impressão</FormLabel>
				<OpcoesImpressaoGeralCheckboxGroup
					imprimirNumeroTransacao={imprimir_numero_transacao}
					imprimirPontilhado={imprimir_pontilhado}
					pularPaginaEntreContas={pular_pagina_entre_contas}
					imprimirAssinatura={imprimir_assinatura}
					handleChange={handleChangeOpcoesImpressao}
				/>
			</Box>
		</>
	);
};

const FluxoMovimentacoesBancariasDivisor = ({ idCondominio, tipoRelatorio }) => {
	const {
		opcoesImpressao: {
			imprimir_totais_diarios,
			imprimir_total_conta,
			non_imprimir_saldo_by_movimentacao,
			imprimir_resumo_conta,
			imprimir_totais_resumo_conta,
			seprarar_por_tipo_resumo_contas,
			imprimir_resumo_separado,
			imprimir_numero_transacao,
			imprimir_pontilhado,
			pular_pagina_entre_contas,
			imprimir_assinatura,
		},
	} = React.useContext(CheckboxRelatorioContext);

	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const [dataInicial, setDataInicial] = useState();
	const [dataFinal, setDataFinal] = useState();
	const [contas, setContas] = useState([]);
	const { data: contasData } = useGetList(
		'conta_condominio_movimentacoes',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ id_condominio: idCondominio, situacao: 'A' }
	);
	const requestData = {
		id_condominio: idCondominio,
		data_inicial: dataInicial,
		data_final: dataFinal,
		contas_bancarias: contas.map((v) => v.id),
		nome_relatorio: tipoRelatorio.action,
		imprimir_totais_diarios: imprimir_totais_diarios,
		imprimir_total_conta: imprimir_total_conta,
		non_imprimir_saldo_by_movimentacao: non_imprimir_saldo_by_movimentacao,
		imprimir_resumo_conta: imprimir_resumo_conta,
		imprimir_totais_resumo_conta: imprimir_totais_resumo_conta,
		seprarar_por_tipo_resumo_contas: seprarar_por_tipo_resumo_contas,
		imprimir_resumo_separado: imprimir_resumo_separado,
		imprimir_numero_transacao: imprimir_numero_transacao,
		imprimir_pontilhado: imprimir_pontilhado,
		pular_pagina_entre_contas: pular_pagina_entre_contas,
		imprimir_assinatura: imprimir_assinatura,
	};

	useEffect(() => {
		if (contas.length) setContas([]);
	}, [idCondominio, setContas]);
	return (
		<BoxDivisor titulo='Fluxo de Movimentações Bancárias'>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<PeriodoSelect
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={dataInicial}
						setDataInicial={setDataInicial}
						setDataFinal={setDataFinal}
						dataFinal={dataFinal}
						label='Selecionar Período'
					/>
				</Box>
				<Box flex={1}>
					<Autocomplete
						value={contas}
						onChange={(_, newValue) => setContas(newValue)}
						options={Object.values(contasData).filter((v) => v.id !== 0)}
						getOptionLabel={(o) => o.nome_conta}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Contas'
								variant='outlined'
								helperText='Se vazio, todas as contas do condomínio serão selecionadas'
							/>
						)}
						clearText='Limpar'
						multiple
						autoHighlight
						noOptionsText='Nenhuma conta disponível'
						size='small'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<OpcoesImpressaoFMBCheckboxGroup />
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};

export default FluxoMovimentacoesBancariasDivisor;
