import React, { useContext } from 'react';

import { useForm } from 'react-final-form';

import { SyndikosSelect } from '../../../../common/SyndikosSelect';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const EditaContaBancariaSelect = ({ source, ...props }) => {
	const { getFieldState } = useForm();
	const state = getFieldState(source);
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { options, props: standardProps } = React.useContext(DadosPorTipoIntegracaoContext);
	return (
		<SyndikosSelect
			disabled={isInativo}
			{...{
				choices: options[source]?.choices,
				...(standardProps[source] || {}),
			}}
			{...props}
			error={!!state && !!state.error}
			helperText={!!state && (state.error ? state.error : false)}
			fullWidth
		/>
	);
};

export default EditaContaBancariaSelect;
