import { makeStyles } from '@material-ui/core/styles';

export type ThemePaletteProps = {
	orange: {
		200: string;
	};
	font: {
		400: string;
	};
	border: {
		50: string;
	};
	blue: {
		50: string;
		300: string;
	};
};

export const OccurrenceStyles = makeStyles<ThemePaletteProps>((theme) => ({
	AnnouncementIcon: {
		color: theme.orange[200],
	},
	Line: {
		backgroundColor: theme.orange[200],
		height: 1,
		alignItems: 'center',
		width: 180,
	},
	BoxChat: {
		backgroundColor: theme.font[400],
		height: '30vh',
		overflow: 'auto',
		border: `1px solid ${theme.border[50]}`,
		borderRadius: 10,
		maxHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	AttachFileIcon: {
		marginLeft: 10,
		marginTop: 10,
		color: theme.blue[300],
	},
	LinkAttachment: {
		color: theme.blue[300],
		marginLeft: 10,
		marginRight: 10,
		marginTop: 15,
		cursor: 'pointer',
		fontWeight: 'bold',
		fontSize: 14,
	},
	ButtonSendMessage: {
		textTransform: 'capitalize',
		color: theme.font[400],
	},
	IconSendMessage: {
		marginLeft: 10,
		fontSize: 18,
		color: theme.font[400],
	},
}));
