import React, { useState } from 'react';
import { AcordosParcelasFluxoModaisContext } from './AcordosParcelasFluxoModaisContext';

export const AcordosParcelasFluxoModaisContextProvider = ({ children }) => {
	const [condominioSelecionado, setCondominioSelecionado] = useState(null);
	const [unidadeSelecionada, setUnidadeSelecionada] = useState(null);
	const [dataAcordo, setDataAcordo] = useState(new Date());
	const [dataEncargos, setDataEncargos] = useState(new Date());
	const [selectedArrecadacoesVencidas, setSelectedArrecadacoesVencidas] = useState([]);
	const [juros, setJuros] = useState(false);
	const [multa, setMulta] = useState(false);
	const [correcaoMonetaria, setCorrecaoMonetaria] = useState(false);
	const [detalhamentoAcrescimosEDescontos, setDetalhamentoAcrescimosEDescontos] = useState({
		acrescimos_e_descontos: [{ tipo: '' }],
	});
	const [tipoJuros, setTipoJuros] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [valoresTotais, setValoresTotais] = useState({
		arrecadacoes: 0,
		correcaoMonetaria: 0,
		juros: 0,
		multa: 0,
		acrescimos: 0,
		descontos: 0,
		total: 0,
	});
	const [valorIndice, setValorIndice] = useState();
	const resetAllStates = () => {
		setCondominioSelecionado(null);
		setUnidadeSelecionada(null);
		setDataAcordo(new Date());
		setDataEncargos(new Date());
		setSelectedArrecadacoesVencidas([]);
		setJuros(false);
		setMulta(false);
		setCorrecaoMonetaria(false);
		setDetalhamentoAcrescimosEDescontos({
			descontos: 0,
			acrescimos: 0,
		});
		setValorIndice(null);
		setTipoJuros(false);
		setSelectedRow([]);
		setValoresTotais({
			arrecadacoes: 0,
			correcaoMonetaria: 0,
			juros: 0,
			multa: 0,
			acrescimos: 0,
			descontos: 0,
			total: 0,
		});
	};

	return (
		<AcordosParcelasFluxoModaisContext.Provider
			value={{
				condominioSelecionado,
				setCondominioSelecionado,
				unidadeSelecionada,
				setUnidadeSelecionada,
				dataAcordo,
				setDataAcordo,
				dataEncargos,
				setDataEncargos,
				selectedArrecadacoesVencidas,
				setSelectedArrecadacoesVencidas,
				juros,
				setJuros,
				multa,
				setMulta,
				correcaoMonetaria,
				setCorrecaoMonetaria,
				setDetalhamentoAcrescimosEDescontos,
				detalhamentoAcrescimosEDescontos,
				setValoresTotais,
				valoresTotais,
				valorIndice,
				setValorIndice,
				selectedRow,
				setSelectedRow,
				setTipoJuros,
				tipoJuros,
				resetAllStates,
			}}
		>
			{children}
		</AcordosParcelasFluxoModaisContext.Provider>
	);
};
