import React, { useCallback, useContext, useEffect, useState } from 'react';

import { TextField, List, Pagination, TopToolbar, useListContext } from 'react-admin';

import { Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import BotaoExcluirArrecadacoesLote from './ButtonExcluirArrecadacoesLote';
import ButtonRecalcularArrecadacoes from './ButtonRecalcularArrecadacoes';
import MenuAdicionarArrecadacao from './MenuAdicionarArrecadacao';
import { AdicionarArrecadacao, EditarArrecadacao, ModalConfirmacaoAtualizacao } from './modais';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import { CondominiosContext } from '../../../../../context/CondominioContextProvider';
import { NumberFormatBRL } from '../../../../common/CurrencyInput';
import { Autocompletar } from '../../../../common/filtros/Autocompletar';
import { Padrao } from '../../../../common/filtros/Padrao';
import { CustomDialog, ModalContext, ModalContextProvider } from '../../../../common/ModalContext';
import { ModalSizeEditableContextProvider } from '../../../../common/ModalSizeEditableContext';
import { Tabela } from '../../../../common/Tabela';
import { TooltipIconButton } from '../../../../common/TooltipIconButton';

const RateioField = ({ record }) => {
	switch (record?.rateio) {
		case '0':
			return <>Divisão por coeficiente</>;
		case '1':
			return <>Divisão pela quantidade de unidades</>;
		case '2':
			return <>Igualmente para todas as unidades</>;
		case '3':
			return <>Valor personalizado para cada unidade</>;
		case '-1':
			return <>Calculados sobre os consumos individuais</>;
		case '-2':
			return (
				<>
					Para {(record?.tipo_unidade || '').toLowerCase()} {record?.nome_unidade || ''}
				</>
			);
		default:
			return <></>;
	}
};

const RateadoField = ({ record }) => {
	switch (record?.rateado) {
		case '1':
			return <>Grupo</>;
		case '2':
			return <>Unidade</>;
		case '4':
			return <>Tipo</>;
		case '5':
			return <>Para várias unidades</>;
		case '6':
			return <>Lista</>;
		default:
			return <>Condomínio</>;
	}
};

const ParcelaField = ({ record }) => {
	if (record?.periodicidade !== undefined && record?.periodicidade !== null) {
		switch (record?.periodicidade) {
			case 2:
				return (
					<>
						{record?.parcela_atual}/{record?.parcelas}
					</>
				);
			case 0:
				return <>Recorrente</>;
			default:
				return <> </>;
		}
	}
	if (record?.periodicidade_string) {
		switch (record?.periodicidade_string) {
			case 'Mensal':
				return (
					<>
						{record?.parcela_atual}/{record?.parcelas}
					</>
				);
			case 'Recorrente':
				return <>Recorrente</>;
			default:
				return <> </>;
		}
	} else {
		return <> </>;
	}
};

const TipoContaField = ({ record }) => {
	switch (record?.tipo_conta) {
		case 'L':
			return <>Leitura</>;
		case 'O':
			return <>Ordinária</>;
		case 'E':
			return <>Extraordinária</>;
		case 'F':
			return <>Fundo</>;
		default:
			return <> </>;
	}
};

const ValorField = ({ record, prefix = 'R$ ', suffix }) => (
	<NumberFormatBRL
		prefix={`${prefix}${(record?.valor || 0) < 0 ? '-' : ''}`}
		suffix={suffix}
		displayType='text'
		value={record?.valor || 0}
		fixedDecimalScale
		onChange={() => {}}
		decimalScale={2}
	/>
);

const ValorArrecadacaoTabelaField = ({ record }) => (
	<ValorField
		record={record}
		prefix={record?.tipo_lancamento === 'PRC' ? '' : 'R$ '}
		suffix={record?.tipo_lancamento === 'PRC' ? '%' : ''}
	/>
);

const BotaoAtualizarArrec = ({ record }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmacaoAtualizacao {...{ record }} />,
		}));
	};
	return (
		<TooltipIconButton
			id='botao-atualizar-arrecadacao'
			disabled={tem_arrecadacao_provisionada}
			size='small'
			title='Atualizar'
			onClick={handleClick}
		>
			<AutorenewIcon />
		</TooltipIconButton>
	);
};

const BotaoEditarArrec = ({ record }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <EditarArrecadacao {...{ record }} />,
		}));
	};
	return (
		<TooltipIconButton
			id='botao-editar-arrecadacao'
			disabled={tem_arrecadacao_provisionada}
			size='small'
			title='Editar'
			onClick={handleClick}
		>
			<EditIcon />
		</TooltipIconButton>
	);
};

const AcoesField = ({ record }) =>
	record?.acordo_criador_id == null && (
		<ModalContextProvider>
			{record?.tipo_conta === 'L' ? (
				<BotaoAtualizarArrec {...{ record }} />
			) : (
				<>
					<BotaoEditarArrec {...{ record }} />
					{record?.tipo_lancamento === 'PRC' && <BotaoAtualizarArrec {...{ record }} />}
				</>
			)}
			<CustomDialog onClose={() => {}} />
		</ModalContextProvider>
	);

const TabelaArrecadacoesSimples = (props) => {
	const { loading } = useListContext();

	return !loading ? (
		<Tabela {...props} disableClickRow>
			<TextField source='nome_conta_pai' label='Conta Título' />
			<TextField source='complemento' label='Descrição Boleto' />
			<TipoContaField source='tipo_conta' label='Tipo da Conta' />
			<RateadoField source='rateado' label='Rateado' />
			<ValorField source='valor' label='Valor' align='right' />
			<ParcelaField source='parcela' label='Parcela' align='right' />
			<RateioField source='rateio' label='Rateio' />
		</Tabela>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

const TabelaArrecadacoes = (props) => (
	<Tabela {...props} disableClickRow isRowSelectable={(record) => record?.acordo_criador_id == null}>
		<AcoesField sortable={false} selectable={false} label='Ações' />
		<TextField
			id='table-arrecadacao-nome-conta-titulo'
			source='nome_conta_pai'
			label='Conta Título'
			filtro={<Padrao id='table-filtro-nome-conta-titulo' />}
		/>
		<TextField
			id='table-arrecadacao-complemento'
			source='complemento'
			label='Descrição Boleto'
			filtro={<Padrao id='table-filtro-complemento' />}
		/>
		<TipoContaField
			id='table-arrecadacao-tipo_conta'
			source='tipo_conta'
			label='Tipo'
			filtro={
				<Autocompletar
					id='filtro-arrecadacao-tipo_conta'
					opcoes={[
						{ id: 'L', name: 'Leitura' },
						{ id: 'O', name: 'Ordinária' },
						{ id: 'E', name: 'Extraordinária' },
						{ id: 'F', name: 'Fundo' },
					]}
				/>
			}
		/>
		<RateadoField
			id='table-arrecadacao-rateado'
			source='rateado'
			label='Rateado'
			filtro={
				<Autocompletar
					id='filtro-arrecadacao-rateado'
					opcoes={[
						{ id: '1', name: 'Grupo' },
						{ id: '2', name: 'Unidade' },
						{ id: '0', name: 'Condomínio' },
					]}
				/>
			}
		/>
		<ValorArrecadacaoTabelaField
			id='table-arrecadacao-valor'
			source='valor'
			label='Valor'
			align='right'
			filtro={<Padrao id='table-filtro-valor' />}
		/>
		<ParcelaField
			id='table-arrecadacao-parcelas'
			source='parcelas'
			label='Parcela'
			filtro={<Padrao id='table-filtro-parcelas' />}
		/>
		<RateioField source='rateio' label='Rateio' filtro={<Padrao id='table-filtro-rateio' />} />
	</Tabela>
);

const Actions = () => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClickItem = (tipo) => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <AdicionarArrecadacao {...{ tipo }} />,
		}));
		handleClose();
	};
	return (
		<TopToolbar style={{ padding: 0, alignSelf: 'flex-end' }}>
			<Button
				id='botao-menu-add-arrecadacao'
				disabled={tem_arrecadacao_provisionada}
				onClick={handleClick}
				color='primary'
				startIcon={<AddIcon />}
				size='small'
			>
				Adicionar
			</Button>
			<MenuAdicionarArrecadacao
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				handleClose={handleClose}
				handleClickItem={handleClickItem}
			/>
		</TopToolbar>
	);
};

export const ArrecadacoesSimpleList = (props) => (
	<List
		{...props}
		id='table-arrecadacoes-simple-list'
		empty={false}
		actions={null}
		bulkActionButtons={false}
		filterDefaultValues={{ situacao: 'A' }}
		sort={{ field: 'valor', order: 'ASC' }}
		title=' '
		perPage={10}
		pagination={
			<Pagination
				rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
				labelRowsPerPage='Arrecadações por página'
			/>
		}
	>
		<TabelaArrecadacoesSimples />
	</List>
);

const BulkActions = () => {
	const { id_identificacao_arrecadacao, referencia } = useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { data, onUnselectItems, selectedIds } = useListContext();
	const [registrosSelecionados, setRegistrosSelecionados] = useState({});

	const updateRegistrosSelecionados = useCallback(() => {
		if (selectedIds.length && selectedIds[0]) {
			setRegistrosSelecionados((v) => {
				const registros = selectedIds.reduce((registros, id) => {
					const registro = data[id] || v[id];
					if (registro?.id) registros[id] = registro;
					return registros;
				}, {});
				return registros;
			});
		} else {
			setRegistrosSelecionados({});
			onUnselectItems();
		}
	}, [selectedIds, data, setRegistrosSelecionados, onUnselectItems]);
	useEffect(updateRegistrosSelecionados, [selectedIds, data]);

	useEffect(onUnselectItems, [id_identificacao_arrecadacao, referencia, condominioSelecionado]);

	return (
		<>
			<ModalSizeEditableContextProvider
				customDialogProps={{
					disableBackdropClick: true,
					disableEscapeKeyDown: true,
				}}
			>
				<ButtonRecalcularArrecadacoes ids={selectedIds} registrosSelecionados={registrosSelecionados} />
			</ModalSizeEditableContextProvider>
			<ModalContextProvider>
				<BotaoExcluirArrecadacoesLote ids={selectedIds} />
				<CustomDialog />
			</ModalContextProvider>
		</>
	);
};

export const ArrecadacoesBaseList = ({ showActions = true, ...props }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	return (
		<List
			{...props}
			empty={false}
			actions={
				showActions ? (
					<ModalContextProvider>
						<Actions />
						<CustomDialog onClose={() => {}} />
					</ModalContextProvider>
				) : (
					<div />
				)
			}
			bulkActionButtons={tem_arrecadacao_provisionada ? false : <BulkActions />}
			filterDefaultValues={{ situacao: 'A' }}
			sort={{ field: 'valor', order: 'ASC' }}
			perPage={10}
			pagination={
				<Pagination
					id='table-pagination-arrecadacao'
					rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
					labelRowsPerPage='Arrecadações por página'
				/>
			}
			style={{ marginTop: '-63px' }}
		>
			<TabelaArrecadacoes />
		</List>
	);
};
