import React from 'react';

import EditaContaBancariaTextInput from '../../commons/EditaContaBancariaTextInput';

const LocalPagamentoField = () => (
	<EditaContaBancariaTextInput
		source='local_pagamento'
		name='local_pagamento'
		id='local_pagamento'
		label='Local de Pagamento'
		parse={(value) => (value || '').substring(0, 100)}
	/>
);

export default LocalPagamentoField;
