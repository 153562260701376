import React from 'react';

import { alpha, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';

import classnames from 'classnames';

const defaultIcon = <CheckIcon />;

const useStyles = makeStyles(
	(theme) => ({
		saveButton: {
			color: theme.palette.primary.main,
			transition: 'all 100ms ease',
			'&:hover': {
				backgroundColor: alpha(theme.palette.primary.main, 0.8),
				color: '#f5f5f5',

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'SyndikosSaveButton' }
);

export const BotaoSalvar = ({ startIcon = defaultIcon, children, ...rest }) => {
	const classes = useStyles();
	return (
		<Button
			variant='text'
			key='button'
			size='small'
			startIcon={startIcon}
			color='primary'
			{...rest}
			className={classnames(classes.saveButton)}
		>
			{children || 'Salvar'}
		</Button>
	);
};

export const TooltipSalvarIconButton = ({ title, icon = defaultIcon, className, ...rest }) => {
	const classes = useStyles();
	return (
		<Tooltip title={title}>
			<span>
				<IconButton {...rest} className={classnames(classes.saveButton, className)}>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	);
};
