import React, { useState, useMemo } from 'react';

import { TreeTableContext } from '../contexts/TreeTableContext';

import DataView from './DataView';

const ContasViewSimplificado = ({ id, tipo, contasRaizes }) => {
	const [value, setValue] = useState({
		id,
		tipo,
		treeData: contasRaizes,
	});

	const providerValue = useMemo(() => ({ value, setValue }), [value, setValue]);

	return (
		<TreeTableContext.Provider value={providerValue}>
			<DataView />
		</TreeTableContext.Provider>
	);
};

export default ContasViewSimplificado;
