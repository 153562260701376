import { FormControl, TextField, Tooltip } from '@material-ui/core';
import InputFile from 'components/common/InputFile';
import React from 'react';
import { DocumentsFileProps } from './types';
import { CustomDataProviderDocuments, FileInfoType, RequestDataCreateFile } from '../types';
import { useDataProvider, useNotify } from 'react-admin';
import { ProviderValueContext } from '../folder/type';
import { ModalContext } from 'components/common/ModalContext';

export const onUploadFile = (e: React.FormEvent, fileRequest: RequestDataCreateFile): void => {
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const notify = useNotify();
	const dp = useDataProvider() as CustomDataProviderDocuments;

	e.preventDefault();

	dp.safeCreate('documento_arquivo', {
		data: fileRequest,
	})
		.then(() => {
			notify('Arquivo importado com sucesso');
			setModalValue((v) => ({ ...v, open: false }));
		})
		.catch((e) => {
			if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			return notify('Ocorreu um problema ao adicionar a  Arquivo!', { type: 'error' });
		});
};

const ImportFileModal: React.FC<DocumentsFileProps> = React.memo(
	({ setFile, setHasFile, customFileName, setCustomFileName }) => {
		const PermitedFileExtension: string[] = [
			'txt',
			'pdf',
			'jpg',
			'jpeg',
			'png',
			'doc',
			'docx',
			'xls',
			'xlsx',
			'ppt',
			'pptx',
			'csv',
		];

		const handleClick = (base64File: string, rest: FileInfoType) => {
			setFile({
				file: base64File,
				file_name: rest.name,
				size: rest.size,
				type: rest.type,
			});
			setHasFile(true);
		};

		return (
			<FormControl>
				<TextField
					variant='outlined'
					autoFocus
					size='small'
					onChange={(e) => setCustomFileName(e.target.value)}
					value={customFileName}
					label={'Nome do Arquivo'}
				/>
				<br />
				<Tooltip
					title={
						'Apenas extensões dos tipos "txt, pdf, jpg, jpeg, png, doc, docx, xls, xlsx, ppt, pptx, csv" são permitidas!'
					}
				>
					<div>
						<InputFile
							PermitedFileExtension={PermitedFileExtension}
							label={'Arquivo em anexo'}
							onCallBack={(_: unknown, base64File: string, rest: FileInfoType) =>
								handleClick(base64File, rest)
							}
							fileDataUrl={undefined}
						/>
					</div>
				</Tooltip>
			</FormControl>
		);
	}
);

export default ImportFileModal;
