import React from 'react';

import { Box, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';
import PeriodoSelect from './SelecionarPeriodo/PeriodoSelect';

const BoxConfigPrestacaoDeContas = () => {
	const {
		opcoesImpressao: {
			imprimir_contas_analiticas,
			imprimir_resumo_contas_bancarias,
			imprimir_posicao_prestacao_contas_direita,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);

	return (
		<Box style={{ marginTop: '0.5em' }}>
			<PeriodoSelect label='Data da Prestação de Contas' />
			<br />
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_contas_analiticas}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_contas_analiticas')
							}
							name='imprimir_contas_analiticas'
						/>
					}
					label='Imprimir contas analíticas'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_resumo_contas_bancarias}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_resumo_contas_bancarias')
							}
							name='imprimir_resumo_contas_bancarias'
						/>
					}
					label='Imprimir resumo das contas bancárias'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_posicao_prestacao_contas_direita}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_posicao_prestacao_contas_direita')
							}
							name='imprimir_posicao_prestacao_contas_direita'
						/>
					}
					label='Imprimir a Prestação de Contas à direita'
				/>
			</FormGroup>
		</Box>
	);
};

export default BoxConfigPrestacaoDeContas;
