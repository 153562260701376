import React, { useContext } from 'react';

import { Box, TextField } from '@material-ui/core';

import { LancamentoContaPagarContext } from './LancamentoContaPagarContext';

const BoxObservacoes = () => {
	const { observacoes, setObservacoes } = useContext(LancamentoContaPagarContext);
	return (
		<Box>
			<Box display='flex'>
				<TextField
					label='Obervações'
					variant='outlined'
					rows={5}
					value={observacoes}
					onChange={(event) => {
						setObservacoes((event.target.value || '').slice(0, 500));
					}}
					helperText={`${observacoes?.length || 0}/500`}
					multiline
					fullWidth
				/>
			</Box>
		</Box>
	);
};

export default BoxObservacoes;
