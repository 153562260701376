import React from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, CircularProgress } from '@material-ui/core';

import { GaragensUnidadeContext } from '../../../context';

import FormDeGaragens from '../../form';

import { CloseButton, SaveButton, DeleteButton } from 'components/common/commonComponentsTSX';

import ModalConfirmarExclusaoGaragemUnica from '../delete/ModalExcluirGaragem';

const generateDefaultErroMessage = () => 'Erro inesperado, tente recarregar a página';

const objectGenerateErrorMessages = {
	numero: () => 'Garagem deve ter um número.',
	nome: () => 'Garagem deve ter um nome.',
	ano_veiculo: () => 'Ano do veículo deve ter 4 dígitos.',
	garagem: (error) => error?.[1] || 'Já existe uma garagem com esse nome em uso',
};

function ModalEditarGaragem({ record: { id_condominio }, id: id_unidade }) {
	const {
		contexto: { openModalEditarGaragem, dadosEditGaragem },
		setContexto,
	} = React.useContext(GaragensUnidadeContext);

	const [coeficienteGaragem, setCoeficienteGaragem] = React.useState(dadosEditGaragem.coeficiente);
	const [descricaoGaragem, setDescricaoGaragem] = React.useState(dadosEditGaragem.descricao);
	const [numeroGaragem, setNumeroGaragem] = React.useState(dadosEditGaragem.numero);
	const [modeloVeiculo, setModeloVeiculo] = React.useState(dadosEditGaragem.modelo_veiculo);
	const [marcaVeiculo, setMarcaVeiculo] = React.useState(dadosEditGaragem.marca_veiculo);
	const [placaVeiculo, setPlacaVeiculo] = React.useState(dadosEditGaragem.placa_veiculo);
	const [nomeGaragem, setNomeGaragem] = React.useState(dadosEditGaragem.nome);
	const [anoVeiculo, setAnoVeiculo] = React.useState(dadosEditGaragem.ano_veiculo);
	const [corVeiculo, setCorVeiculo] = React.useState(dadosEditGaragem.cor_veiculo);

	const [loading, setLoading] = React.useState(false);
	const [openModalExcluir, setOpenModalExcluir] = React.useState(false);

	const dp = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();

	function onSubmit() {
		if (!String(numeroGaragem || '')) return notify('Garagem deve ter um número.', 'warning');
		if (numeroGaragem.length > 50)
			return notify('Número da garagem não pode ter mais de 50 digitos/caracteres.', 'warning');
		if (!String(nomeGaragem ?? '')) return notify('Garagem deve ter um nome.', 'warning');
		if (nomeGaragem.length > 250) return notify('Nome da garagem não pode ter mais de 250 caracteres.', 'warning');
		if (anoVeiculo.length >= 1 && anoVeiculo.length < 4)
			return notify('Ano do veículo deve ter 4 dígitos', 'warning');

		setLoading(true);
		const updateObject = {
			id: dadosEditGaragem.id,
			data: {
				numero: numeroGaragem,
				nome: nomeGaragem,
				condominio: id_condominio,
				unidade: id_unidade,
				coeficiente: coeficienteGaragem || 0.0,
				descricao: descricaoGaragem || '',
				modelo_veiculo: modeloVeiculo || '',
				placa_veiculo: placaVeiculo || '',
				ano_veiculo: anoVeiculo || '',
				marca_veiculo: marcaVeiculo || '',
				cor_veiculo: corVeiculo || '',
			},
		};
		dp.update('garagens_unidades', updateObject)
			.then(() => {
				notify('Garagem atualizada com sucesso!', 'success');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const firstError = Object.entries(e.response.data)[0];
				const chaveError = firstError[0];

				const erroMsg = (objectGenerateErrorMessages[chaveError] || generateDefaultErroMessage)(firstError);
				notify(erroMsg, 'warning');
			})
			.finally(() => setLoading(false));
	}

	function handleClose() {
		setContexto({ openModalEditarGaragem: false });
	}

	return (
		<Dialog
			open={openModalEditarGaragem}
			onClose={handleClose}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '45vw',
					position: 'relative',
				},
			}}
		>
			<DialogTitle style={{ paddingBottom: 0 }}>
				<h1 style={{ margin: 0, padding: 0 }}>Alteração de Garagem</h1>
			</DialogTitle>
			<DialogContent style={{ 'overflow-y': 'hidden' }}>
				{!dadosEditGaragem.id ? (
					<LinearProgress />
				) : (
					<FormDeGaragens
						{...{
							nomeGaragem,
							setNomeGaragem,
							numeroGaragem,
							setNumeroGaragem,
							descricaoGaragem,
							setDescricaoGaragem,
							coeficienteGaragem,
							setCoeficienteGaragem,
							marcaVeiculo,
							setMarcaVeiculo,
							modeloVeiculo,
							setModeloVeiculo,
							anoVeiculo,
							setAnoVeiculo,
							placaVeiculo,
							setPlacaVeiculo,
							corVeiculo,
							setCorVeiculo,
						}}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<CloseButton size='small' onClick={handleClose}>
					{(loading && <CircularProgress size={20} />) || 'Cancelar'}
				</CloseButton>
				<DeleteButton disabled={loading} size='small' onClick={() => setOpenModalExcluir(true)}>
					{(loading && <CircularProgress size={20} />) || 'Excluir'}
				</DeleteButton>
				<SaveButton size='small' onClick={onSubmit} disabled={loading}>
					{(loading && <CircularProgress size={20} />) || 'Salvar'}
				</SaveButton>
			</DialogActions>
			<ModalConfirmarExclusaoGaragemUnica
				idGaragem={dadosEditGaragem.id}
				open={openModalExcluir}
				onClose={() => setOpenModalExcluir(false)}
			/>
		</Dialog>
	);
}

export default ModalEditarGaragem;
