import React from 'react';

import InstrucoesRemessaFields from '../../../../../commons/InstrucoesRemessaFields';

import { GetOnChangeProps, ChangeProps, IChoiceOption, inputProps } from '../../../../../types';

const emptyProps = {} as any;

const JurosFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaFields
		sourcePrincipal='juros'
		propsTipo={{
			source: 'juros_tipo',
			label: 'Juros',
			...propsTipo,
		}}
		propsValor={{
			source: 'juros_valor',
			decimalScale: 2,
			...propsValor,
		}}
		propsDias={{
			source: 'juros_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoJuros = ({
	sources: { sourceValor, sourceDias } = emptyProps,
	values,
	pristine,
	optionsIsencoesTipo,
}: GetOnChangeProps) => ({
	values: {
		[sourceValor]: values[sourceValor],
		[sourceDias]: values[sourceDias],
	},
	sources: {
		sourceValor,
		sourceDias,
	},
	pristine,
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoJuros =
	(
		{ sources: { sourceValor, sourceDias } = emptyProps, values, pristine, optionsIsencoesTipo }: GetOnChangeProps,
		change: ChangeProps
	) =>
	(_: any, tipoValue: IChoiceOption, input: inputProps) => {
		input.onChange(tipoValue);

		const valorValue = values[sourceValor],
			diasValue = values[sourceDias];
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
		} else {
			if (tipoValue.id === 'JTX') {
				!valorValue && change(sourceValor, 1);
			} else {
				!pristine && !valorValue && change(sourceValor, undefined);
			}
			if (sourceDias && !diasValue) change(sourceDias, 1);
		}
	};

export default JurosFields;
