import { UnidadeCreate } from './create';
import { UnidadeEdit } from './edit';
import { UnidadesList } from './list';

const resource = {
	list: UnidadesList,
	create: UnidadeCreate,
	edit: UnidadeEdit,
};

export default resource;
