import { IPrevisaoOrcamentariaWithDadosContaNode, IModeloPrevisaoOrcamentaria, ITreeProps } from './types';

function findNodeById(
	id_pai: number,
	node: IPrevisaoOrcamentariaWithDadosContaNode | null,
	tipo: 'CR' | 'CD'
): IPrevisaoOrcamentariaWithDadosContaNode | null {
	if (!node) return null;

	if (node.id === id_pai && node.id_concatenado.includes(tipo)) return node;

	if (node.children && node.children.length > 0)
		for (const child of node.children) {
			const foundNode = findNodeById(id_pai, child, tipo);
			if (foundNode) {
				return foundNode;
			}
		}

	return null;
}

function truncateDecimal(decimalNumber: number, decimalPlaces: number): number {
	const multiplier = 10 ** decimalPlaces;
	return Math.floor(decimalNumber * multiplier) / multiplier;
}

export const calculaValoresMensal = (
	previsaoOrcamentariaTree: IPrevisaoOrcamentariaWithDadosContaNode[],
	modeloPrevisaoSelecionado: IModeloPrevisaoOrcamentaria,
	valor: number,
	row: any,
	setArvore: any,
	alteraPeriodo = false
) => {
	const periodo: number = modeloPrevisaoSelecionado.periodo
		? modeloPrevisaoSelecionado.periodo > 0
			? modeloPrevisaoSelecionado.periodo
			: 1
		: 1;
	const tipoConta: 'CR' | 'CD' = row.id_concatenado.split('-')[0];
	const nodeInicial = tipoConta === 'CR' ? previsaoOrcamentariaTree[0] : previsaoOrcamentariaTree[1];

	const zeraValorAntigoPais = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		if (node.id_pai) {
			const parent = findNodeById(node.id_pai, nodeInicial, tipoConta);
			if (parent) {
				if (alteraPeriodo) parent.valorPeriodo = 0;
				parent.valorMensal = 0;
				zeraValorAntigoPais(parent);
			}
		}
	};

	const recalculaValorAtualPais = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		if (node.id_pai) {
			const parent = findNodeById(node.id_pai, nodeInicial, tipoConta);
			if (parent) {
				for (const child of parent.children) {
					if (alteraPeriodo) parent.valorPeriodo += child.valorPeriodo;
					parent.valorMensal += truncateDecimal(child.valorMensal, 2);
				}
				recalculaValorAtualPais(parent);
			}
		}
	};

	const recalculaPaiDireto = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		if (node.classe_conta === 'A' && node.id_pai) {
			const parent = findNodeById(node.id_pai, nodeInicial, tipoConta);
			if (parent) {
				for (const child of parent.children) {
					if (alteraPeriodo) parent.valorPeriodo += child.valorPeriodo;
					parent.valorMensal += truncateDecimal(child.valorMensal, 2);
				}
			}
			return parent;
		}
	};

	const atualizaPorcentagens = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		for (const child of node.children) {
			child.percentual = (child.valorPeriodo / node.valorPeriodo) * 100;
			atualizaPorcentagens(child);
		}
	};

	zeraValorAntigoPais(row);
	row.valorMensal = truncateDecimal(valor, 2);
	if (alteraPeriodo) row.valorPeriodo = row.valorMensal * periodo;

	const pai = recalculaPaiDireto(row);
	recalculaValorAtualPais(pai ?? row);
	atualizaPorcentagens(nodeInicial);

	setArvore(JSON.parse(JSON.stringify(previsaoOrcamentariaTree)));
};

export const calculaValoresPeriodo = (
	previsaoOrcamentariaTree: IPrevisaoOrcamentariaWithDadosContaNode[],
	modeloPrevisaoSelecionado: IModeloPrevisaoOrcamentaria,
	valor: number,
	row: any,
	setArvore: any
) => {
	const periodo: number = modeloPrevisaoSelecionado.periodo
		? modeloPrevisaoSelecionado.periodo > 0
			? modeloPrevisaoSelecionado.periodo
			: 1
		: 1;
	const tipoConta: 'CR' | 'CD' = row.id_concatenado.split('-')[0];
	const nodeInicial = tipoConta === 'CR' ? previsaoOrcamentariaTree[0] : previsaoOrcamentariaTree[1];

	const zeraValorAntigoPais = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		if (node.id_pai) {
			const parent = findNodeById(node.id_pai, nodeInicial, tipoConta);
			if (parent) {
				parent.valorPeriodo = 0;
				parent.valorMensal = 0;
				zeraValorAntigoPais(parent);
			}
		}
	};

	const recalculaValorAtualPais = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		if (node.id_pai) {
			const parent = findNodeById(node.id_pai, nodeInicial, tipoConta);
			if (parent) {
				for (const child of parent.children) {
					parent.valorPeriodo += child.valorPeriodo;
					parent.valorMensal += child.valorMensal;
				}
				recalculaValorAtualPais(parent);
			}
		}
	};

	const recalculaPaiDireto = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		if (node.classe_conta === 'A' && node.id_pai) {
			const parent = findNodeById(node.id_pai, nodeInicial, tipoConta);
			if (parent) {
				for (const child of parent.children) {
					parent.valorPeriodo += child.valorPeriodo;
					parent.valorMensal += child.valorMensal;
				}
			}
			return parent;
		}
	};

	const atualizaPorcentagens = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		for (const child of node.children) {
			child.percentual = (child.valorMensal / node.valorMensal) * 100;
			atualizaPorcentagens(child);
		}
	};

	zeraValorAntigoPais(row);
	row.valorPeriodo = valor;
	row.valorMensal = valor / periodo;

	const pai = recalculaPaiDireto(row);
	recalculaValorAtualPais(pai ?? row);
	atualizaPorcentagens(nodeInicial);

	setArvore(JSON.parse(JSON.stringify(previsaoOrcamentariaTree)));
};

export const processTreeBottomToTop = (
	previsaoOrcamentariaTree: IPrevisaoOrcamentariaWithDadosContaNode[],
	node: IPrevisaoOrcamentariaWithDadosContaNode,
	propriedadesContasView: ITreeProps,
	modeloPrevisaoSelecionado: IModeloPrevisaoOrcamentaria,
	setArvore: any
) => {
	if (node.children && node.children.length > 0)
		for (const childNode of node.children)
			processTreeBottomToTop(
				previsaoOrcamentariaTree,
				childNode,
				propriedadesContasView,
				modeloPrevisaoSelecionado,
				setArvore
			);

	calculaValoresPeriodo(previsaoOrcamentariaTree, modeloPrevisaoSelecionado, node.valorPeriodo, node, setArvore);
	calculaValoresMensal(previsaoOrcamentariaTree, modeloPrevisaoSelecionado, node.valorMensal, node, setArvore);
};
