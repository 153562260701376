import React from 'react';
import { Box, TextField, FormControlLabel, Checkbox, Button, Collapse } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import variants from './variants';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function MultiSelectDropdown({ variant, value, setValue, options, ...props }) {
	const { formats } = variants[variant];
	const { formatGroupBy, formatOption, checkGroup, selectGroup, label } = formats(variant);
	const disabled = !options.length;
	const [expandedGroup, setExpandedGroup] = React.useState(true);
	const [categoriesOpened, setCategoriesOpened] = React.useState([]);

	const handleShowHideChildrensGroup = (group) => {
		if (categoriesOpened.includes(group)) {
			setCategoriesOpened(categoriesOpened.filter((string) => string !== group));
		} else {
			setCategoriesOpened([...categoriesOpened, group]);
		}
	};

	return (
		<FormControlLabel
			style={{ display: 'flex', margin: '0px 0px 0px 0px' }}
			control={
				<Box flex={1}>
					<Autocomplete
						disableCloseOnSelect
						options={options}
						groupBy={(option) => formatGroupBy(option)}
						renderInput={(params) => <TextField {...params} margin='dense' label={label} />}
						renderOption={(option, { selected }) => {
							return (
								<Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
									<span>
										<Checkbox checked={selected} />
										{formatOption(options?.find((v) => v.id === option)) || formatOption(option)}
									</span>
								</Box>
							);
						}}
						getOptionSelected={(option, value) => option.id === value.id}
						renderGroup={(params) => {
							return (
								<Box style={{ display: 'flex', flexDirection: 'column' }}>
									<Box style={{ display: 'flex', width: '100%' }}>
										<Button
											open={categoriesOpened.includes(params.group)}
											onClick={() => {
												handleShowHideChildrensGroup(params.group);
											}}
											endIcon={!expandedGroup ? <ChevronRightIcon /> : <ExpandMoreIcon />}
											size='small'
											style={{ display: 'flex', justifyContent: 'flex-start' }}
											fullWidth
										>
											<Checkbox
												key={params.group}
												checked={checkGroup(options, params.group, value)}
												onChange={() => selectGroup(options, params.group, value, setValue)}
											/>
											<span>{params.group}</span>
										</Button>
									</Box>
									<Collapse in={categoriesOpened.includes(params.group)} timeout='auto'>
										{expandedGroup ? params.children : null}
									</Collapse>
								</Box>
							);
						}}
						getOptionLabel={(option) => {
							return (
								option && (formatOption(options.find((v) => v.id === option)) || formatOption(option))
							);
						}}
						noOptionsText='Nenhum resultado encontrado'
						onChange={(_, v) => {
							setValue(v);
						}}
						disabled={disabled}
						autoHighlight
						value={value}
						clearText='Limpar'
						multiple
						{...props}
					/>
				</Box>
			}
		/>
	);
}
