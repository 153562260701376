import { useState, useCallback, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

function useProviders({
	// startLoading?: boolean;
	startLoading = false,
} = {}) {
	const [response, setResponse] = useState(); // <AxiosResponse>
	const [error, setError] = useState(); // <AxiosError>
	const [loading, setLoading] = useState(startLoading); // <boolean>
	const dp = useDataProvider();

	const fetchData = useCallback(
		// AxiosRequestConfig
		(typeProvider = '', requestParams = {}) => {
			setLoading(true);
			const provider = dp[typeProvider];
			if (provider) {
				return provider(requestParams.resource, requestParams.params)
					.then((response) => {
						setResponse(response);
						return response;
					})
					.catch((e) => {
						setError(e);
						return Promise.reject(e);
					});
			} else {
				return Promise.reject({ typeProvider: 'Não existente tipo de provider' });
			}
		},
		[setLoading, dp]
	);

	useEffect(() => {
		if (!response && !error) return;
		setLoading(false);
	}, [response, error]);

	return { response, error, loading, fetchData };
}

export default useProviders;
