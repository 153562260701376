import React, { useState, useContext } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoProximo } from '../../../../../common/buttons/BotaoProximo';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';

const ModalNomeNovaLeitura = () => {
	const { closeModal, dadosNovaLeitura, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const [nomeLeitura, setNomeLeitura] = useState(dadosNovaLeitura.nome || '');
	const handleNext = () => {
		setDadosNovaLeitura((v) => ({ ...v, nome: nomeLeitura }));
		setModalStep('2');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Qual o nome da leitura?'
			form={{
				component: (
					<TextField
						fullWidth
						label='Nome da Leitura'
						value={nomeLeitura}
						onChange={(e) => setNomeLeitura((e.target.value || '').substr(0, 40))}
						style={{ marginTop: '8px' }}
						margin='dense'
					/>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={closeModal} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoProximo onClick={handleNext} disabled={!nomeLeitura.length} />
				</>
			}
		/>
	);
};

export default ModalNomeNovaLeitura;
