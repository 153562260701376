import React, { useContext } from 'react';

import { Field } from 'react-final-form';

import { FormControlLabel, Switch } from '@material-ui/core';

import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import { createGetPropsValidas } from '../utils/functions';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const propNamesField = ['name', 'id', 'type'];

const getPropsFieldValidas = createGetPropsValidas(propNamesField);

const propNamesFormControlLabel = ['value', 'control', 'label', 'labelPlacement'];

const getPropsFormControlLabelValidas = createGetPropsValidas(propNamesFormControlLabel);

const propNamesSwitch = ['color'];

const getPropsValidasSwitch = createGetPropsValidas(propNamesSwitch);

const EditaContaBancariaSwitch = ({ source, disabled: standardDisabled = false, ...standardProps }) => {
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { props } = useContext(DadosPorTipoIntegracaoContext);
	const {
		fieldProps = {},
		formControlLabelProps = {},
		switchProps = {},
		disabled = false,
		...sourceProps
	} = props[source] ?? {};
	return (
		<Field
			disabled={isInativo || disabled || standardDisabled}
			type='checkbox'
			{...getPropsFieldValidas({
				...standardProps,
				...sourceProps,
				...fieldProps,
			})}
		>
			{({ input }) => (
				<FormControlLabel
					control={
						<Switch
							checked={input.checked}
							color='primary'
							disabled={isInativo || disabled || standardDisabled}
							name={input.name}
							onChange={(e) => {
								input.onChange(e);
							}}
							{...getPropsValidasSwitch({
								...standardProps,
								...sourceProps,
								...switchProps,
							})}
						/>
					}
					{...getPropsFormControlLabelValidas({
						...standardProps,
						...sourceProps,
						...formControlLabelProps,
					})}
				/>
			)}
		</Field>
	);
};

export default EditaContaBancariaSwitch;
