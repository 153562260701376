import React, { useContext } from 'react';

import EditIcon from '@material-ui/icons/Edit';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ArrecadacoesContext } from '../../../../ArrecadacoesContext';
import { ContaAReceberActionsContext } from '../ContaAReceberActionsContext';
import ModalArrecadacaoUnidadesTable from './ModalArrecadacaoUnidadesTable';

const BotaoEditarArrecadacoesUnidades = ({ record }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { loading } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalArrecadacaoUnidadesTable {...{ record }} />,
		}));
	};

	return (
		<TooltipIconButton
			disabled={!tem_arrecadacao_provisionada || loading || record?.provisionado}
			size='small'
			title='Editar'
			onClick={handleClick}
		>
			<EditIcon />
		</TooltipIconButton>
	);
};

export default BotaoEditarArrecadacoesUnidades;
