import React from 'react';
import { TablePagination, useMediaQuery, Theme } from '@material-ui/core';
import { sanitizeListRestProps } from 'ra-core';
import { useTranslate } from 'react-admin';

interface SyndkosTablePaginationProps {
	rowsPerPageOptions?: number[];
	handlePageChange: (event: unknown, newPage: number) => void;
	page: number;
	perPage: number;
	total: number;
	setPerPage: (perPage: number) => void;
	labelRowsPerPage: string;
}

const SyndkosTablePagination: React.FC<SyndkosTablePaginationProps> = (props) => {
	const { rowsPerPageOptions, ...rest } = props;
	const { handlePageChange, page, perPage, total, setPerPage } = props;
	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const translate = useTranslate();

	const handlePerPageChange = React.useCallback(
		(event) => {
			setPerPage(event.target.value);
		},
		[setPerPage]
	);

	const labelDisplayedRows = React.useCallback(
		({ from, to, count }) =>
			translate('ra.navigation.page_range_info', {
				offsetBegin: from,
				offsetEnd: to,
				total: count,
			}),
		[translate]
	);

	if (isSmall) {
		return (
			<TablePagination
				count={total}
				rowsPerPage={perPage}
				page={page - 1}
				onPageChange={handlePageChange}
				rowsPerPageOptions={[]}
				component='span'
				labelDisplayedRows={labelDisplayedRows}
				{...sanitizeListRestProps(rest)}
			/>
		);
	}

	return (
		<TablePagination
			count={total}
			rowsPerPage={perPage}
			page={page - 1}
			onPageChange={handlePageChange}
			onRowsPerPageChange={handlePerPageChange}
			component='span'
			rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25, 50, 100, 200, 500]}
			labelDisplayedRows={labelDisplayedRows}
			{...sanitizeListRestProps(rest)}
		/>
	);
};

export default SyndkosTablePagination;
