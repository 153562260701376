import React from 'react';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
	LimitePagamentoFields,
	ProtestoFields,
} from '../../tabs/TabInstrucoesCobrancaRemessa';

import {
	formataPropsMaxValuePorLimitePagamentoDias,
	formatCedentePattern2,
	getDadosFormattingContaCorrente,
	parseCedentePattern2,
} from '../../utils/formaters_parses';

import formatarInitialValues from '../default/ContaCorrenteProps/formatarInitialValues';

import DefaultContaCorrenteProps, {
	defaultComponentesProps,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesProtesto,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	getValidacoesLimitePagamento,
	mensagensErrosPadroes,
} from '../default/ContaCorrenteProps';

import getFormataPayloadValues from './getFormataPayloadValues';
import formatadoresPayloadValues from './formatadoresPayloadValues';
import { formataOptionsPadrao } from '../../utils/functions';
import validacoes from './validacoes';
import getValidate from './getValidate';
import {
	getPropsOnChangeTipoPadraoInstrucao,
	createOnChangeTipoPadraoInstrucao,
} from '../bradesco_237/ContaCorrenteProps/TabInstrucoesCobrancaRemessa/InstrucaoField';
import TabInstrucoesCobrancaBoleto from './TabInstrucoesCobrancaBoleto';
import TabInstrucoesCobrancaRemessaCNAB400 from './Components/TabInstrucoesCobrancaRemessa';

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	msgErroVazio: mensagensErrosPadroes.vazioLimitePagamento(),
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamento(),
});

const validacoesMulta = getValidacoesMulta({
	msgErroVazio: mensagensErrosPadroes.vazioSemOpcaoIsensao({ nomeCampoLegivel: 'multa', genero: 'a' }),
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	msgErroVazio: mensagensErrosPadroes.vazioProtesto(),
	minValue: 0,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto(),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesLimitePagamento.tipo,
	validacoesLimitePagamento.dias,

	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesLimitePagamento({ values }),
	...formataPayloadValuesProtesto({ values }),
});

const TabInstrucoesCobrancaRemessa = (props) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<LimitePagamentoFields />, <ProtestoFields />]}
	/>
);

const getOptions = ({ opcoesDadosBancarios, optionsDefault = {} }, state) => {
	const options = formataOptionsPadrao({
		state,
		instituicaoCodigo: '041',
		opcoesDadosBancarios,
		optionsDefault,
		agencia: 'agencias_banrisul',
	});
	options['2'].instrucao_1 = options['2'].instrucao;
	return options;
};

const getExtras = ({
	props,
	instituicaoCodigo,
	initialDados: {
		opcoesDadosBancarios: { integracoes },
	},
}) => ({
	optionsIsencoes: integracoes[instituicaoCodigo].optionsIsencoes,
	...props,
});

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '041',
	getOptions,
	getValidate,
	props: {
		1: {
			...defaultComponentesProps,
			conta_corrente: {
				...getDadosFormattingContaCorrente(13, 2),
			},
			conta_cedente: {
				parse: parseCedentePattern2(11, 2),
				format: formatCedentePattern2(11, 2),
			},
			limite_pagamento_dias: { min: 1 },
			protesto: {
				...defaultComponentesProps.protesto,
				initialDias: 30,
			},
		},
		2: {
			...defaultComponentesProps,
			conta_corrente: {
				...getDadosFormattingContaCorrente(9),
			},
			conta_cedente: {
				parse: parseCedentePattern2(11, 2),
				format: formatCedentePattern2(11, 2),
			},
			instrucao_1: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoInstrucao,
				createOnChangeTipo: createOnChangeTipoPadraoInstrucao,
			},
		},
	},
	validacoes: {
		1: [
			...DefaultContaCorrenteProps.validacoes,
			getValidateQuandoEmitindoBoletos(validacoesInstrucoesCobrancaRemessa),
		],
		2: validacoes,
	},
	formatadoresPayloadValues: {
		1: {
			...defaultFormatadoresPayloadValues,
			cobranca_config: getFormatadorPayloadCobrancaConfig({
				formatadores: [
					...defaultFormatadoresPayloadValuesCobrancaConfig,

					// Dados de Tab Conta
					formataPayloadValuesTabContaCobrancaConfig,

					// Dados de Tab Instruções de Cobrança Remessa
					formataPayloadValuesTabInstrucoesCobrancaRemessa,
				],
			}),
		},
		2: { ...formatadoresPayloadValues },
	},
	getFormataPayloadValues,
	formatarInitialValues,
	tabContents: {
		1: {
			...DefaultContaCorrenteProps.tabContents,
			TabInstrucoesCobrancaRemessa,
		},
		2: {
			...DefaultContaCorrenteProps.tabContents,
			TabInstrucoesCobrancaRemessa: TabInstrucoesCobrancaRemessaCNAB400,
			TabInstrucoesCobrancaBoleto,
		},
	},
	getExtras,
};

export default ContaCorrenteProps;
