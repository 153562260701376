import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import SituacaoChip from './SituacaoChip';

export const WarningChip = ({ value }) => {
	const theme = useTheme();
	return <SituacaoChip value={value} textColor={theme.palette.warning.dark} />;
};

export default WarningChip;
