import React, { useContext } from 'react';

import { Button, useDataProvider, useListContext, useNotify, useRefresh } from 'react-admin';

import LeiturasListContext from '../../LeiturasListContext';
import ListActionsContext from './ListActionsContext';

const LancarLeiturasButton = () => {
	const notify = useNotify();
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const { filterValues } = useListContext();
	const { valorConsumoTotal } = useContext(LeiturasListContext);
	const { dataLeitura, disabledSalvarDataLeituras } = useContext(ListActionsContext);
	return (
		<Button
			disabled={disabledSalvarDataLeituras}
			label='Lançar'
			onClick={() => {
				dataProvider
					.simplePost('lancamento_leitura/lancar_por_qtd_moradores', {
						data: {
							id_leitura: filterValues.leitura_id,
							valor_total_consumo: valorConsumoTotal,
							referencia: filterValues.referencia.split('T')[0],
							data_leitura: dataLeitura.toISOString().split('T')[0],
						},
					})
					.then(() => {
						notify('Leituras lançadas com sucesso!');
						refresh();
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						const erroMsg = Object.values(e?.response?.data || {})[0] || [
							'Erro inesperado, tente recarregar a página',
						];
						notify(erroMsg, 'warning');
					});
			}}
		/>
	);
};

export default LancarLeiturasButton;
