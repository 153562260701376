import React from 'react';

import { Box } from '@material-ui/core';

import EditaContaBancariaSwitch from '../../commons/EditaContaBancariaSwitch';

const EmitirBoletosField = () => (
	<Box flex={1} sx={{ 'max-width': '50%' }}>
		<EditaContaBancariaSwitch
			name='emitir_boletos'
			id='emitir_boletos'
			source='emitir_boletos'
			label='Desejo emitir boletos com essa conta bancária'
			labelPlacement='end'
		/>
	</Box>
);

export default EmitirBoletosField;
