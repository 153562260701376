import React from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import TreeView from '../../../common/TreeView/TreeView';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { CustomDialogBody } from '../../../common/ModalContext';
import { TooltipIconButtonCancel } from '../../../common/buttons/ButtonCancel';
import { dataToTree } from '../../planosCondominio/helpers/dataToTree';
import { ContasContext } from '../../planosCondominio/contexts/ContasContext';
import { ContasContextProvider } from 'components/resources/planosCondominio/contexts/ContasContext';

import RenderTreeAdicionaConta from '../../../common/TreeView/RenderTreeAdicionaConta';

const useStyles = makeStyles((theme) => ({
	fundo: {
		backgroundColor: theme.fundoMenu[100],
	},
}));

const ModalAddConta = ({ setSelectedConta, operacao = {}, ModalDetailContext }) => {
	const [newConta, setNewConta] = React.useState();
	const { contas, dispatch } = React.useContext(ContasContext);
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [treeData, setTreeData] = React.useState([]);

	const init = () => {
		if (contas[operacao?.tipo]) {
			setTreeData(dataToTree(Object.keys(contas[operacao?.tipo]).map((key) => contas[operacao?.tipo][key])));
		}
	};

	React.useEffect(init, [contas[operacao?.tipo]]);

	const handleSubmit = () => {
		if (newConta?.id) {
			setSelectedConta(newConta);
		} else {
			dataProvider
				.createMany(`contas_${operacao?.tipo}`, {
					data: { ...newConta, nomes: [newConta.nome] },
				})
				.then((response) => {
					const data = response?.data;
					if (data) {
						setSelectedConta(data[0]);
						notify('Conta cadastrada com sucesso');
						dispatch({
							type: 'addOne',
							tipoConta: operacao?.tipo,
							data: data[0],
						});
					}
				});
		}
	};

	return (
		<CustomDialogBody
			Context={ModalDetailContext}
			title={`Selecionar conta de ${operacao?.tipo}`}
			form={{
				valid: newConta && newConta.nome,
				handleSubmit,
				component: (
					<Box>
						<Box>
							{newConta?.id_pai ? (
								<>
									<Typography variant='body2'>
										Na conta título <strong>{newConta?.nome_pai}</strong>
									</Typography>
									<Box display='flex' alignItems='center'>
										<TextField
											fullWidth
											margin='dense'
											label='Nome da Conta'
											onChange={(e) => {
												const value = e.target.value;
												setNewConta((v) => ({
													...v,
													nome: value,
												}));
											}}
										/>
										<TooltipIconButtonCancel onClick={() => setNewConta({})} />
									</Box>
								</>
							) : (
								<Box maxHeight='300px' minHeight='200px' mb='15px' css={{ overflowY: 'auto' }}>
									<TreeView
										treeData={treeData}
										newConta={newConta}
										setNewConta={setNewConta}
										RenderTree={RenderTreeAdicionaConta}
									/>
								</Box>
							)}
						</Box>
					</Box>
				),
			}}
		/>
	);
};

const BoxSelectContaPlano = ({
	style = null,
	selectedConta,
	setSelectedConta,
	operacao = {},
	disabled,
	ModalDetailContext,
}) => {
	const { setModalValue } = React.useContext(ModalDetailContext);
	const classes = useStyles();

	return (
		<FormControl style={style}>
			<InputLabel
				htmlFor='botao-selecionar-conta'
				variant='outlined'
				shrink
				style={{
					margin: '0 -6px -8px',
					borderRadius: '6px',
					top: 8,
				}}
				disabled={disabled}
				className={classes.fundo}
			>
				Conta de {operacao?.tipo}
			</InputLabel>
			<Button
				id='botao-selecionar-conta'
				variant='outlined'
				color='primary'
				fullWidth
				style={{ textTransform: 'none', margin: '8px 0 4px' }}
				onClick={() =>
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalAddConta
								{...{
									operacao,
									selectedConta,
									setSelectedConta,
									ModalDetailContext,
								}}
							/>
						),
					}))
				}
				disabled={disabled}
			>
				{selectedConta?.nome || 'Selecionar Conta'}
			</Button>
		</FormControl>
	);
};

export default BoxSelectContaPlano;

export const BoxSelectContaPlanoV2 = ({
	selectedConta,
	setSelectedConta,
	operacao = {},
	disabled,
	ModalDetailContext,
	id,
	style = null,
	filtroTipoContaReceita = null,
}) => {
	const { setModalValue } = React.useContext(ModalDetailContext);

	const handleClick = () => {
		setModalValue((prevValue) => ({
			...prevValue,
			open: true,
			dialogBody: (
				<ContasContextProvider id={id} filtroTipoContaReceita={filtroTipoContaReceita}>
					<ModalAddConta
						operacao={operacao}
						selectedConta={selectedConta}
						setSelectedConta={setSelectedConta}
						ModalDetailContext={ModalDetailContext}
						filtroTipoContaReceita={filtroTipoContaReceita}
					/>
				</ContasContextProvider>
			),
		}));
	};

	return (
		<Button
			id='botao-selecionar-conta'
			variant='outlined'
			color='primary'
			fullWidth
			style={style || { textTransform: 'none', margin: '8px 0 4px' }}
			onClick={handleClick}
			disabled={disabled}
		>
			{selectedConta?.nome || 'Selecionar Conta'}
		</Button>
	);
};
