import React from 'react';

import { List } from 'react-admin';

import TabelaHistMail from './TabelaHistMail';

export const ListHistMail = ({ idContaAReceber }) => (
	<List
		resource='email_status'
		basePath='/email_status'
		filter={{ conta_a_receber: idContaAReceber }}
		actions={null}
		bulkActionButtons={false}
	>
		<TabelaHistMail disableClickRow />
	</List>
);

export default ListHistMail;
