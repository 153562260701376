import React from 'react';

import { ArrecadacoesContext } from 'components/resources/arrecadacao/ArrecadacoesContext';
import {
	ModalConfirmaDesprovisionamentoPadrao,
	ModalConfirmaDesprovisionamentoAutomatico,
	ModalConfirmaDesprovisionamentoBancoInterAutomatico,
	ModalConfirmaDesprovisionamentoBancoDoBrasilAutomatico,
} from '../modais';

export const ModaisConfirmaDesprovisionamentoPorInstituicaoFinanceiraTipoIntegracao = {
	[undefined]: ModalConfirmaDesprovisionamentoPadrao,
	[null]: ModalConfirmaDesprovisionamentoPadrao,
	['364-0']: ModalConfirmaDesprovisionamentoAutomatico,
	['077-0']: ModalConfirmaDesprovisionamentoBancoInterAutomatico,
	['748-0']: ModalConfirmaDesprovisionamentoAutomatico,
	['756-0']: ModalConfirmaDesprovisionamentoAutomatico,
	['133-0']: ModalConfirmaDesprovisionamentoAutomatico,
	['450-0']: ModalConfirmaDesprovisionamentoAutomatico,
	['332-0']: ModalConfirmaDesprovisionamentoAutomatico,
	['001-0']: ModalConfirmaDesprovisionamentoBancoDoBrasilAutomatico,
};

export const useModalConfirmaDesprovisionamento = () => {
	const { identificacaoInstituicaoTipoIntegracao } = React.useContext(ArrecadacoesContext);
	const ModalConfirmaDesprovisionamento = React.useMemo(
		() =>
			ModaisConfirmaDesprovisionamentoPorInstituicaoFinanceiraTipoIntegracao[
				identificacaoInstituicaoTipoIntegracao
			] || ModalConfirmaDesprovisionamentoPadrao,
		[identificacaoInstituicaoTipoIntegracao]
	);

	return ModalConfirmaDesprovisionamento;
};

export default useModalConfirmaDesprovisionamento;
