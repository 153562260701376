import React from 'react';

import { Field } from 'react-final-form';

import { TextInput, required, SelectInput, ArrayInput } from 'react-admin';

import { Box, Button, Typography, Checkbox } from '@material-ui/core';

import { BoxDivisor, FormularioBox, TextInputSyndikos } from '../../common/Formulario';
import { SyndikosKeyboardDatePicker, SyndikosMuiPickersUtilsProvider } from '../../common/InputDate';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import InputFile from '../../common/InputFile';
import SimpleFormIteratorV1 from 'components/common/SimpleFormIteratorV1';
import { CustomRemoveButton } from 'components/common/buttons/BotaoRemover';

const dictLabels = {
	E: 'Em Andamento',
	F: 'Encerrada',
};

const getChipClassName = ({ styles, situacao }) =>
	styles[{ E: 'ativo', F: 'inativo', C: 'inativo' }[situacao] || 'inativo'];

const quemPodeResponderOpcoes = [
	{ id: 'P', name: 'Proprietário' },
	{ id: 'I', name: 'Inquilino' },
	{ id: 'A', name: 'Ambos' },
	{ id: 'R', name: 'Responsável pela Cobrança' },
];

const QuestionComponent = ({ source, isEncerrada, surveyIsAlreadyVoted }) => {
	return (
		<Box flex={1} ml='1em'>
			<TextInputSyndikos
				disabled={isEncerrada || surveyIsAlreadyVoted}
				validate={[required()]}
				fullWidth
				source={source}
				multiline
				rows={4}
				label='Crie sua pergunta nesse espaço'
			/>

			<BoxDivisor disabled={surveyIsAlreadyVoted} flex={1} titulo='Opções de Respostas' mb='0.5'>
				<Box flex={1} ml='1em'>
					<ArrayInput
						disabled={isEncerrada || surveyIsAlreadyVoted}
						source={`${source}_answer_options`}
						label=''
						variant='outlined'
						validate={(defaultValue) => {
							if (!defaultValue || defaultValue.length === 0) {
								return 'Pelo menos uma Opção de Resposta é obrigatória';
							}
							return undefined;
						}}
						onFocus={(e) => e.target.select()}
					>
						<SimpleFormIteratorV1
							removeButton={<CustomRemoveButton />}
							addButton={
								<Button
									disabled={isEncerrada || surveyIsAlreadyVoted}
									variant='filled'
									color='primary'
									style={{ color: '#3f51b5' }}
								>
									Adicionar nova Resposta
								</Button>
							}
						>
							<TextInputSyndikos
								disabled={isEncerrada || surveyIsAlreadyVoted}
								validate={[required()]}
								fullWidth
								source={'descricao'}
								label='Crie sua opção de resposta nesse espaço'
							/>
						</SimpleFormIteratorV1>
					</ArrayInput>
				</Box>
			</BoxDivisor>
		</Box>
	);
};

export const EnqueteFormulario = (props) => {
	const { condominioSelecionado, setCondominioPorId } = React.useContext(CondominiosContext);
	const isCreatingNew = !props.record.id;
	const isEncerrada = props.record.situacao === 'F';
	const surveyIsAlreadyVoted = React.useMemo(() => {
		if (!props.record.questions) return false;

		return Boolean(
			props.record.questions.some((question) => {
				return question.question_answer_options.some((answer) => {
					return answer.answer_votes !== 0;
				});
			})
		);
	}, [props.record.questions]);

	return (
		<FormularioBox
			{...props}
			dictLabels={dictLabels}
			getChipClassName={getChipClassName}
			onlyExcluir={true}
			recordName='enquetes'
		>
			<Box flex={1} mr='1em'>
				<Box display='flex' flex={1}>
					<Box flex={1} mr='1em'>
						<Typography variant='h5' gutterBottom>
							Cadastrar Enquete
						</Typography>
						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>
						<Box display='flex' flex={1}>
							<Box flex={1} mr='1em'>
								<VeryHighLimitReferenceInput
									source='condominio'
									reference='condominios'
									label='Condomínio'
									fullWidth
									variant='outlined'
									validate={required()}
									onChange={(value) => {
										setCondominioPorId(value);
									}}
									filter={{ situacao: 'A' }}
									disabled={props.edit}
									initialValue={!props.edit && condominioSelecionado?.id}
								>
									<SyndikosSelect optionText='nome' disableClearable />
								</VeryHighLimitReferenceInput>
							</Box>
							<Box mr='1em' flex={1}>
								<SyndikosMuiPickersUtilsProvider>
									<SyndikosKeyboardDatePicker
										style={{ width: '100%' }}
										source='inicia_em'
										label='Iniciar em'
										{...props}
										required
										format='dd/MM/yyyy'
										id='date-picker-inline'
										mask='__/__/____'
										parse={(value) => {
											try {
												return value.toISOString().split('T')[0];
											} catch {
												return value;
											}
										}}
										initialValue={new Date().toISOString().split('T')[0]}
										disabled={isEncerrada}
									/>
								</SyndikosMuiPickersUtilsProvider>
							</Box>
							<Box flex={1}>
								<SyndikosMuiPickersUtilsProvider>
									<SyndikosKeyboardDatePicker
										style={{ width: '100%' }}
										validate={required('Preenchimento obrigatório.')}
										source='finaliza_em'
										label='Finalizar em'
										{...props}
										required={required}
										format='dd/MM/yyyy'
										id='date-picker-inline'
										mask='__/__/____'
										parse={(value) => {
											try {
												return value.toISOString().split('T')[0];
											} catch {
												return value;
											}
										}}
										disabled={isEncerrada}
									/>
								</SyndikosMuiPickersUtilsProvider>
							</Box>
						</Box>
						<Box display='flex' mb='1em' flex={1}>
							<Box flexGrow={1} flexBasis={'0%'} flexShrink={1} mr='0.6em' id='OPA'>
								<Field name='quem_pode_responder' id='quem_pode_responder'>
									{({ input }) => (
										<SelectInput
											required
											style={{ width: '99%' }}
											defaultValue={'P'}
											source='quem_pode_responder'
											label='Quem pode responder?'
											fullWidth
											variant='outlined'
											choices={quemPodeResponderOpcoes}
											{...input}
											disabled={isEncerrada}
										/>
									)}
								</Field>
							</Box>
							<Box display='flex' flex={1}>
								<Field name='ver_resultado_apos_votar' id='ver_resultado_apos_votar'>
									{({ input }) => (
										<SelectInput
											source='ver_resultado_apos_votar'
											label='Usuário pode ver resultado após votar?'
											style={{ width: '99%' }}
											fullWidth
											variant='outlined'
											{...input}
											choices={[
												{ id: true, name: 'Sim' },
												{ id: false, name: 'Não' },
											]}
											defaultValue={'true'}
											optionText='name'
											optionValue='id'
											validate={required('Preenchimento obrigatório.')}
											disabled={isEncerrada}
										/>
									)}
								</Field>
							</Box>

							<Box flex={1}>
								<Field name='permitir_voto_inadimplente' type='checkbox'>
									{({ input }) => (
										<React.Fragment>
											<Checkbox
												source='permitir_voto_inadimplente'
												checked={input.checked === false ? false : true}
												onChange={(e) => {
													input.onChange(e);
												}}
												name={input.name}
												disabled={isEncerrada}
											/>
											Permitir voto de inadimplente
										</React.Fragment>
									)}
								</Field>
							</Box>
						</Box>
						<Box display='flex' mb='1em' flex={1}>
							<BoxDivisor titulo='Descrição' flex={1}>
								<TextInput
									disabled={isEncerrada}
									validate={required('Preenchimento obrigatório.')}
									source='titulo'
									label='Título'
									variant='outlined'
									fullWidth
								/>
							</BoxDivisor>
						</Box>
						<Box display='flex' mb='1em' flex={1}>
							<Field name='write_only_anexo_enquete' id='write_only_anexo_enquete'>
								{({ input }) => (
									<InputFile
										source='write_only_anexo_enquete'
										label='Arquivo em anexo'
										variant='outlined'
										PermitedFileExtension={[
											'doc',
											'docx',
											'pdf',
											'xls',
											'xlsx',
											'jpg',
											'jpeg',
											'png',
										]}
										fullWidth
										onCallBack={(e, dataUrl) => {
											input.onChange(dataUrl);
										}}
										fileDataUrl={props.record.write_only_anexo_enquete}
										disabled={isEncerrada}
										desativateButtonAlterarAnexo={true}
										desativateButtonRemoverAnexo={true}
									/>
								)}
							</Field>
						</Box>
						<Box display='flex' flex={1}>
							<BoxDivisor disabled={surveyIsAlreadyVoted} flex={1} titulo='Perguntas' mb='0.5'>
								<ArrayInput
									disabled={isEncerrada || surveyIsAlreadyVoted}
									source='questions'
									label=''
									validate={(defaultValue) => {
										if (!defaultValue || defaultValue.length === 0) {
											return 'Pelo menos uma Opção de Resposta é obrigatória';
										}
										return undefined;
									}}
									defaultValue={
										isCreatingNew
											? [
													{
														question: 'Pergunta 1',
														question_answer_options: [
															{ descricao: 'Opção 1' },
															{ descricao: 'Opção 2' },
														],
													},
											  ]
											: undefined
									}
									onFocus={(e) => e.target.select()}
								>
									<SimpleFormIteratorV1
										removeButton={<CustomRemoveButton />}
										addButton={
											<Button
												disabled={isEncerrada || surveyIsAlreadyVoted}
												variant='filled'
												color='primary'
												style={{ color: '#3f51b5' }}
											>
												Adicionar nova Pergunta
											</Button>
										}
									>
										<QuestionComponent
											isEncerrada={isEncerrada}
											surveyIsAlreadyVoted={surveyIsAlreadyVoted}
											isCreatingNew={isCreatingNew}
											source={'question'}
										/>
									</SimpleFormIteratorV1>
								</ArrayInput>
							</BoxDivisor>
						</Box>
					</Box>
				</Box>
			</Box>
		</FormularioBox>
	);
};
