import React, { useContext } from 'react';
import { Button, useDelete, useNotify, useRedirect } from 'react-admin';

import { alpha } from '@material-ui/core/styles/colorManipulator';
import { useTheme } from '@material-ui/core/styles';
import ActionDelete from '@material-ui/icons/Delete';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import { makeStyles, Typography, Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';

import classnames from 'classnames';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from '../ModalContext';
import { ButtonRemove } from './ButtonRemove';
import { ButtonClose } from './ButtonClose';

const defaultIcon = <ActionDelete />;

export const ModalConfirmacao = (props) => {
	const { confirmTitle = 'ra.message.delete_title', record, resource } = props;
	const redirect = useRedirect();
	const notify = useNotify();
	const [deleteOne] = useDelete(resource, record.id, record, {
		onSuccess: () => {
			notify('ra.notification.deleted', 'info', undefined, true, 5000);
			redirect('list', props.basePath);
		},
		onFailure: (error) => {
			notify(error.message, 'warning', undefined, false, 5000);
		},
	});
	const {
		palette: { syndikosRed },
	} = useTheme();
	const { setModalValue } = useContext(ModalContext);
	return (
		<CustomDialogBody
			title={confirmTitle}
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
							Essa ação é irreversível.
							<br />
							Todo o histórico será excluído.
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
						}}
						size='small'
					>
						cancelar
					</ButtonClose>
					<ButtonRemove onClick={deleteOne} size='small' />
				</>
			}
		/>
	);
};

const RemoverBotao = (props) => {
	const {
		basePath,
		classes: classesOverride,
		className,
		icon = defaultIcon,
		label = 'ra.action.delete',
		record,
		onSuccess,
		onFailure,
		resource,
		onClick,
		...rest
	} = props;
	const classes = useStyles(props);
	const { setModalValue } = useContext(ModalContext);
	const onClickCallback = () => {
		if (onClick) {
			onClick(props, setModalValue);
		} else {
			setModalValue((v) => ({ ...v, open: true, dialogBody: <ModalConfirmacao {...props} /> }));
		}
	};
	return (
		<Button
			onClick={onClickCallback}
			label={label}
			className={classnames('ra-delete-button', classes.deleteButton, className)}
			key='button'
			{...rest}
		>
			{icon}
		</Button>
	);
};

const useStyles = makeStyles(
	(theme) => ({
		deleteButton: {
			color: theme.palette.syndikosRed.main,
			transition: 'all 200ms ease',
			'&:hover': {
				backgroundColor: alpha(theme.palette.syndikosRed.main, 0.95),
				color: '#f5f5f5',

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'RaDeleteWithConfirmButton' }
);

export const BotaoRemover = (props) => {
	return (
		<ModalContextProvider>
			<RemoverBotao {...props} />
			<CustomDialog />
		</ModalContextProvider>
	);
};

export const CustomRemoveButton = (props) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return <Button {...props}>{<DeleteOutline style={{ fontSize: 24, color: syndikosRed.main }} />}</Button>;
};

export const TooltipRemoveIconButton = ({ title, ...rest }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<Tooltip title={title}>
			<span>
				<IconButton {...rest}>
					<DeleteOutline style={{ color: syndikosRed.main }} />
				</IconButton>
			</span>
		</Tooltip>
	);
};
