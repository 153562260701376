import React, { useContext } from 'react';

import { useDataProvider } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import ClearIcon from '@material-ui/icons/Clear';
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import EditIcon from '@material-ui/icons/Edit';
import CallMadeIcon from '@material-ui/icons/CallMade';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import LinearProgress from '@material-ui/core/LinearProgress';

import { camposEndereco } from '../../common/EnderecoV2';
import TooltipBar from '../../common/TooltipBar';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import { ModalSizeEditableContext } from '../../common/ModalSizeEditableContext';
import { VinculosContext } from './VinculosContext';
import ModalDesvincular from './modais/common/ModalDesvincular';
import { ModalEditaProprietario } from './modais/proprietario';
import { ModalTrocaInquilino, ModalEditaInquilino, ModalDefinirDataFimInquilino } from './modais/inquilino';
import { ModalTrocaImobiliaria } from './modais/imobiliaria';

const TooltipButtonRemoveVinculo = ({ onClick }) => {
	const { disabledCondition } = useContext(VinculosContext);
	return (
		<TooltipIconButton title='Remover Vínculo' size='small' disabled={disabledCondition} onClick={onClick}>
			<ClearIcon />
		</TooltipIconButton>
	);
};

export const TooltipButtonRemoveVinculoResponsavel = ({ id_pessoa, nome, id, tipo, reloadTable }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipButtonRemoveVinculo
			onClick={() =>
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalDesvincular {...{ id, id_pessoa, nome, tipo, reloadTable }} />,
				}))
			}
		/>
	);
};

export const TooltipButtonRemoveVinculoImobiliaria = ({ id_pessoa, nome, id, imobiliaria }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipButtonRemoveVinculo
			onClick={() =>
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalDesvincular {...{ id_pessoa, nome, imobiliaria, id }} />,
				}))
			}
		/>
	);
};

const TooltipButtonTroca = ({ title, onClick, Icon = SwapHorizIcon }) => {
	const { disabledCondition } = useContext(VinculosContext);
	return (
		<TooltipIconButton size='small' disabled={disabledCondition} title={title} onClick={onClick}>
			<Icon />
		</TooltipIconButton>
	);
};

export const TooltipButtonTrocaProprietario = ({ openTroca }) => (
	<TooltipButtonTroca title='Trocar Proprietário' onClick={openTroca} />
);

export const TooltipButtonTrocaInquilino = ({
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	data_saida,
	id_pessoa,
	imobiliaria,
	id,
	reloadTable,
	enderecoDados,
}) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipButtonTroca
			title='Trocar Inquilino'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalTrocaInquilino
							{...{
								nome,
								tipo_pessoa,
								documento,
								data_entrada,
								data_saida,
								id_pessoa,
								imobiliaria,
								id,
								reloadTable,
								enderecoDados,
							}}
						/>
					),
				}));
			}}
		/>
	);
};

export const TooltipButtonTrocaImobiliaria = ({ imobiliaria, id }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipButtonTroca
			title='Trocar Imobiliária'
			Icon={StoreRoundedIcon}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalTrocaImobiliaria {...{ imobiliaria, id }} />,
				}));
			}}
		/>
	);
};

const TooltipButtonEdita = ({ title, onClick, Icon = EditIcon }) => {
	const { disabledCondition } = useContext(VinculosContext);
	return (
		<TooltipIconButton size='small' disabled={disabledCondition} title={title} onClick={onClick}>
			<Icon />
		</TooltipIconButton>
	);
};

export const TooltipButtonEditaProprietario = ({
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	telefones,
	email_cobranca,
	data_entrada,
	id,
	reloadTable,
	enderecoDados,
}) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipButtonEdita
			title='Editar Proprietário'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalEditaProprietario
							{...{
								id_pessoa,
								nome,
								tipo_pessoa,
								documento,
								telefones,
								email_cobranca,
								data_entrada,
								id,
								reloadTable,
								enderecoDados,
							}}
						/>
					),
				}));
			}}
		/>
	);
};

export const TooltipButtonEditaInquilino = ({
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	data_saida,
	motivo_saida,
	id_pessoa,
	imobiliaria,
	telefones,
	id,
	reloadTable,
	enderecoDados,
}) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipButtonEdita
			title='Editar Inquilino'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalEditaInquilino
							{...{
								nome,
								tipo_pessoa,
								documento,
								telefones,
								data_entrada,
								data_saida,
								motivo_saida,
								id_pessoa,
								imobiliaria,
								id,
								reloadTable,
								enderecoDados,
							}}
						/>
					),
				}));
			}}
		/>
	);
};

export const TooltipButtonDefinirDataFimInquilino = ({
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	data_saida,
	id_pessoa,
	imobiliaria,
	telefones,
	id,
	enderecoDados,
}) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipButtonEdita
			title='Definir data fim'
			Icon={KeyboardTabIcon}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalDefinirDataFimInquilino
							{...{
								nome,
								tipo_pessoa,
								documento,
								telefones,
								data_entrada,
								data_saida,
								id_pessoa,
								imobiliaria,
								id,
								enderecoDados,
							}}
						/>
					),
				}));
			}}
		/>
	);
};

export const TooltipButtonOpenEdicaoTela = ({ href, title = 'Editar Pessoa' }) => {
	const { disabledCondition } = useContext(VinculosContext);
	return (
		<a href={href} target='_blank' rel='noopener noreferrer'>
			<TooltipIconButton size='small' title={title} disabled={disabledCondition}>
				<CallMadeIcon />
			</TooltipIconButton>
		</a>
	);
};

const useStylesTabelaHistorico = makeStyles(
	() => ({
		gearButton: {
			padding: 0,
		},
	}),
	{ name: 'GearButtonTabelaHistorico' }
);

export const TooltipBarTabelaHistoricoProprietario = ({ record }) => {
	const { id_pessoa, id, tipo, data_entrada, is_first, ...rest } = record || {};
	const [proprietario, setProprietario] = React.useState(null);
	const [loadingProprietario, setLoadingProprietario] = React.useState(true);
	const classes = useStylesTabelaHistorico();
	const dp = useDataProvider();

	React.useEffect(() => {
		if (!id_pessoa) return setLoadingProprietario(false);
		setLoadingProprietario(true);
		dp.getOne('pessoas', { id: id_pessoa })
			.then(({ data }) => setProprietario(data))
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				console.log(e);
			})
			.finally(() => {
				setLoadingProprietario(false);
			});
	}, [id_pessoa, dp]);

	if (loadingProprietario)
		return (
			<div style={{ marginTop: 25 }}>
				<LinearProgress />
			</div>
		);

	if (!proprietario) return <></>;

	return (
		<TooltipBar classNameIconButton={classes.gearButton}>
			{!is_first && (
				<TooltipButtonRemoveVinculoResponsavel
					key='action0'
					{...{
						id,
						id_pessoa,
						tipo,
						nome: proprietario.nome,
					}}
					reloadTable={true}
				/>
			)}
			<TooltipButtonEditaProprietario
				key='action1'
				{...{
					id,
					id_pessoa,
					data_entrada,
					nome: proprietario.nome,
					documento: proprietario.documento,
					tipo_pessoa: proprietario.tipo_pessoa,
					email_cobranca: proprietario.email_cobranca,
					telefones: proprietario.telefones,
					enderecoDados: Object.fromEntries(camposEndereco.map((campo) => [campo, rest[campo]])),
				}}
				reloadTable={true}
			/>
		</TooltipBar>
	);
};

export const TooltipBarTabelaHistoricoInquilino = ({ record }) => {
	const { id, data_entrada, data_saida, motivo_saida, id_pessoa, id_imobiliaria, tipo, ...rest } = record || {};
	const classes = useStylesTabelaHistorico();
	const [imobiliaria, setImobiliaria] = React.useState(null);
	const [loadingImobiliaria, setLoadingImobiliaria] = React.useState(true);
	const [inquilino, setInquilino] = React.useState(null);
	const [loadingInquilino, setLoadingInquilino] = React.useState(true);
	const dp = useDataProvider();

	React.useEffect(() => {
		if (!id_pessoa) return setLoadingInquilino(false);

		setLoadingInquilino(true);
		dp.getOne('pessoas', { id: id_pessoa })
			.then(({ data }) => {
				return setInquilino(data);
			})
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				console.log(e);
			})
			.finally(() => setLoadingInquilino(false));
	}, [id_pessoa, dp]);

	React.useEffect(() => {
		if (!id_imobiliaria) return setLoadingImobiliaria(false);

		setLoadingImobiliaria(true);
		dp.getOne('imobiliarias', { id: id_imobiliaria })
			.then(({ data }) => {
				return setImobiliaria(data);
			})
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				console.log(e);
			})
			.finally(() => setLoadingImobiliaria(false));
	}, [id_imobiliaria, dp]);

	if (loadingInquilino || loadingImobiliaria)
		return (
			<div style={{ marginTop: 25 }}>
				<LinearProgress />
			</div>
		);

	if (!inquilino) return <></>;

	return (
		<TooltipBar classNameIconButton={classes.gearButton}>
			<TooltipButtonRemoveVinculoResponsavel
				key='action0'
				{...{
					id,
					id_pessoa,
					tipo,
					nome: inquilino.nome,
				}}
				reloadTable={true}
			/>
			<TooltipButtonEditaInquilino
				key='action1'
				{...{
					id,
					id_pessoa,
					imobiliaria,
					data_entrada,
					data_saida,
					motivo_saida,
					documento: inquilino.documento,
					email_cobranca: inquilino.email_cobranca,
					nome: inquilino.nome,
					telefones: inquilino.telefones,
					tipo_pessoa: inquilino.tipo_pessoa,
					enderecoDados: Object.fromEntries(camposEndereco.map((campo) => [campo, rest[campo]])),
				}}
				reloadTable={true}
			/>
			<TooltipButtonTrocaInquilino
				key='action2'
				{...{
					id,
					id_pessoa,
					imobiliaria,
					data_entrada,
					data_saida,
					documento: inquilino.documento,
					nome: inquilino.nome,
					tipo_pessoa: inquilino.tipo_pessoa,
					enderecoDados: Object.fromEntries(camposEndereco.map((campo) => [campo, rest[campo]])),
				}}
				reloadTable={true}
			/>
		</TooltipBar>
	);
};
