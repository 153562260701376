import React, { useContext } from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { SyndikosMuiPickersUtilsProvider } from '../../../../common/InputDate';
import ListActionsContext from './ListActionsContext';

const InputDateDataLeituras = (props) => {
	const { dataLeitura, setDataLeitura } = useContext(ListActionsContext);
	return (
		<SyndikosMuiPickersUtilsProvider>
			<KeyboardDatePicker
				{...props}
				error={!dataLeitura || dataLeitura.toString() === 'Invalid Date'}
				format='dd/MM/yyyy'
				helperText={
					!dataLeitura ? null : dataLeitura.toString() === 'Invalid Date' ? 'Formato de data inválido' : null
				}
				id='date-picker-inline-data-leituras'
				initialFocusedDate={dataLeitura}
				inputVariant='outlined'
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				label='Data das leituras'
				onChange={(newvalue) => setDataLeitura(newvalue)}
				margin='dense'
				mask='__/__/____'
				value={dataLeitura}
				variant='inline'
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};

export default InputDateDataLeituras;
