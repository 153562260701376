import React from 'react';
import { useDataProvider } from 'react-admin';
import type { CustomDataProvider, Pessoa } from 'types/tpyesGlobal';

const defaultPessoaContextValue = {
	pessoas: [],
	setPessoa: () => {
		return;
	},
	pessoaSelecionada: {} as Pessoa,
	setPessoaSelecionada: () => {
		return;
	},
	reloadPessoas: () => {
		return;
	},
};

export type PessoaContext = {
	pessoas: Pessoa[] | never[];
	setPessoa: React.Dispatch<React.SetStateAction<PessoaContext>>;
	pessoaSelecionada: Pessoa | undefined;
	setPessoaSelecionada: React.Dispatch<React.SetStateAction<Pessoa | undefined>>;
	reloadPessoas: () => void;
};

export const PessoasContext = React.createContext<PessoaContext>(defaultPessoaContextValue);

export const PessoasContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [pessoa, setPessoa] = React.useState<PessoaContext>(defaultPessoaContextValue);
	const [pessoaSelecionada, setPessoaSelecionada] = React.useState<Pessoa>();
	const currentPath = window.location.href;
	const dataProvider = useDataProvider() as CustomDataProvider;

	const reloadPessoas = React.useCallback(() => {
		if (currentPath.includes('login') || currentPath === 'http://admin.syndkos/') return;
		dataProvider
			.getSimple('pessoas', {
				pagination: { page: 1, perPage: 100000 },
				sort: { field: 'nome', order: 'ASC' },
				filter: { situacao: 'A' },
			})
			.then(({ data: { results = [] } = {} }) => {
				setPessoa((_pessoa) => ({
					..._pessoa,
					pessoas: results as Pessoa[],
				}));
			});
	}, [pessoa, currentPath, setPessoa]);

	const setPessoaContextValue = React.useCallback(
		(pessoaChanged: any) => {
			setPessoa((prevState) => ({
				...prevState,
				pessoaSelecionada: pessoaChanged ?? null,
			}));
		},
		[setPessoa]
	);

	const providerValue = React.useMemo(
		() => ({
			pessoas: pessoa.pessoas,
			setPessoa: setPessoaContextValue,
			pessoaSelecionada,
			setPessoaSelecionada,
			reloadPessoas,
		}),
		[pessoa, setPessoaContextValue, pessoaSelecionada, setPessoaSelecionada, reloadPessoas]
	);

	React.useEffect(reloadPessoas, []);

	return <PessoasContext.Provider value={providerValue}>{children}</PessoasContext.Provider>;
};
