import React, { useContext } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { ButtonRemove } from '../../common/buttons/ButtonRemove';
import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContext,
	ModalSizeEditableContextProvider,
} from '../../common/ModalSizeEditableContext';

import { LancamentoContaPagarContext } from './LancamentoContaPagarContext';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import WarningIcon from '@material-ui/icons/Warning';

const ModalRemoverContaAPagar = () => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	const { closeModal, record } = useContext(LancamentoContaPagarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dp = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();

	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const handleDelete = () => {
		dp.delete('contaapagar', { id: record.id })
			.then(() => {
				notify('Conta a pagar removida com sucesso');
				closeModal();
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(Object.values(e?.response?.data || {})[0] || 'Erro inesperado', 'warning');
				setModalValue((v) => ({
					...v,
					open: false,
				}));
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title='Tem certeza que deseja remover esta Conta a Pagar?'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Essa ação é irreversível.
							<br />
							Todo o histórico será excluído.
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonCancel onClick={handleClose} />
					<ButtonRemove size='small' onClick={handleDelete} />
				</>
			}
		/>
	);
};

const ButtonRemover = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<ButtonRemove
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalRemoverContaAPagar />,
				}));
			}}
		>
			Remover
		</ButtonRemove>
	);
};

const ButtonRemoverContaAPagar = () => {
	return (
		<ModalSizeEditableContextProvider
			customDialogProps={{
				disableBackdropClick: true,
				disableEscapeKeyDown: true,
			}}
		>
			<ButtonRemover />
		</ModalSizeEditableContextProvider>
	);
};

export default ButtonRemoverContaAPagar;
