import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

import { ExportButton, TopToolbar } from 'react-admin';

import { ConfiguracaoDeCorrecaoMonetariaContext } from '../../contexts';

interface SortPayload {
	field: string;
	order: string;
}
interface FilterPayload {
	[k: string]: any;
}

export interface IListActionsProps {
	total: number;
	resource: string;
	currentSort: SortPayload;
	filterValues: FilterPayload;
	exporter: () => void;
}

const ListActions: React.FC<Partial<IListActionsProps>> = ({
	total,
	resource,
	currentSort,
	filterValues,
	exporter,
}) => {
	const { handleSetRedirectContext } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);

	const handleClick = () => {
		handleSetRedirectContext('create', null);
	};

	return (
		<TopToolbar>
			<Button variant='text' startIcon={<AddIcon />} onClick={handleClick} size='small'>
				Cadastrar
			</Button>
			<ExportButton
				disabled={Boolean(total === 0)}
				resource={resource}
				sort={currentSort}
				filterValues={filterValues}
				exporter={exporter}
			/>
		</TopToolbar>
	);
};

export default ListActions;
