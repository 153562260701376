import React, { createContext, useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../../ArrecadacoesContext';
import { ProvisionamentoPanelContext } from '../../ProvisionamentoPanelContext';

export const ModalArrecadacaoUnidadesEditarContext = createContext();

const convercaoTipoResponsavel = {
	P: 'I',
	I: 'P',
};

export const ModalArrecadacaoUnidadesEditarContextProvider = ({ record, children }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const { carregaValoresCB } = useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { refreshContas } = useContext(ProvisionamentoPanelContext);
	const [data, setData] = useState({});
	const [ids, setIds] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [requesting, setRequesting] = useState(false);
	const [conta, setConta] = useState(record);

	const closeModal = useCallback(() => {
		refreshContas();
		setModalValue((v) => ({ ...v, open: false }));
	}, [refreshContas, setModalValue]);

	const fetchData = useCallback(() => {
		setLoading(true);
		if (record.id) {
			dP.getSimple('arrecadacao_unidades', {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'complemento', order: 'ASC' },
				filter: { conta_a_receber_id: record.id },
			})
				.then((response) => {
					const data = response?.data?.results || [];
					if (!data.length) {
						closeModal();
					} else {
						const results = data.reduce((results, item) => {
							results[item?.id] = item;
							return results;
						}, {});
						setData(results);
						setIds(Object.keys(results));
						setLoading(false);
					}
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					setData({});
					setIds([]);
					setLoading(false);
					notify('Não foi encontrada nenhuma arrecadação para esse lançamento de conta a receber');
					closeModal();
				});
		} else {
			setData({});
			setIds([]);
			setLoading(false);
			notify('Não foi encontrada nenhuma arrecadação para esse lançamento de conta a receber');
			closeModal();
		}
	}, [setLoading, record.id, dP, setData, setIds, notify, closeModal]);

	useEffect(fetchData, [record.id]);

	const atualizarValor = useCallback(
		(id) => {
			setRequesting(true);
			return dP
				.update('arrecadacao_unidades', {
					id,
					data: {
						update_field: 'atualizar',
					},
				})
				.then(() => {
					fetchData();
					notify('Valor alterado com sucesso');
					carregaValoresCB();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || {})[0] || [
						'Erro inesperado, tente recarregar a página',
					];
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setRequesting(false);
				});
		},
		[setRequesting, dP, fetchData, carregaValoresCB, notify]
	);

	const editarValor = useCallback(
		(id, valor, closeModal) => {
			setRequesting(true);
			dP.update('arrecadacao_unidades', {
				id,
				data: {
					update_field: 'valor',
					valor,
				},
			})
				.then(() => {
					fetchData();
					notify('Valor alterado com sucesso');
					carregaValoresCB();
					closeModal();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || {})[0] || [
						'Erro inesperado, tente recarregar a página',
					];
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setRequesting(false);
				});
		},
		[dP, fetchData, carregaValoresCB, notify]
	);

	const trocarTipoResponsavel = useCallback(
		(responsavel) => {
			setRequesting(true);
			return dP
				.updateOnAction('arrecadacao_unidades', {
					data: {
						responsavel: convercaoTipoResponsavel[responsavel] || 'P',
						arrecadacoes_unidades_ids: selectedRows.map((r) => r.id),
						action: 'trocar_responsaveis',
					},
				})
				.then((response) => {
					notify('Responsáveis alteradas com sucesso');
					const {
						contas_a_receber_originais_para_deletar_ids,
						contas_a_receber_trocar_tipo_responsavel_ids,
						responsavel: tipo_responsavel,
					} = response?.data || {};
					if ((contas_a_receber_trocar_tipo_responsavel_ids || []).includes(conta.id))
						return setConta({ ...conta, tipo_responsavel });
					if ((contas_a_receber_originais_para_deletar_ids || []).includes(conta.id)) return closeModal();
					fetchData();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || {})[0] || [
						'Erro inesperado, tente recarregar a página',
					];
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setRequesting(false);
				});
		},
		[selectedRows, setRequesting, dP, notify, conta, setConta, closeModal, fetchData]
	);

	const excluir = useCallback(() => {
		setRequesting(true);
		return dP
			.deleteMany('arrecadacao_unidades', {
				ids: selectedRows.map((r) => r.id),
				data: {},
			})
			.then((responsesData) => {
				const responses = responsesData?.data || [];
				const ExclusoesSucedidas = responses.filter((r) => r?.status === 'fulfilled');
				if (ExclusoesSucedidas?.length) {
					const msg =
						ExclusoesSucedidas.length > 1
							? `${ExclusoesSucedidas.length} arrecadações excluídas com sucesso`
							: '1 arrecadação excluída com sucesso';
					carregaValoresCB();
					fetchData();
					notify(msg);
				}
				const firstRejectedResponse = responses.find((r) => r?.status === 'rejected');
				if (firstRejectedResponse) return Promise.reject(firstRejectedResponse.reason);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			})
			.finally(() => {
				setRequesting(false);
			});
	}, [selectedRows, setRequesting, dP, fetchData, carregaValoresCB, notify]);

	const adicionar = useCallback(
		(data, closeModal) => {
			setRequesting(true);
			dP.safeCreate('arrecadacao_unidades', { data })
				.then(() => {
					fetchData();
					notify('Arrecadação cadastrada com sucesso');
					carregaValoresCB();
					closeModal();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || {})[0] || [
						'Erro inesperado, tente recarregar a página',
					];
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setRequesting(false);
				});
		},
		[setRequesting, dP, fetchData, carregaValoresCB, notify]
	);

	const ProviderValue = useMemo(
		() => ({
			adicionar,
			atualizarValor,
			closeModal,
			conta,
			data,
			editarValor,
			excluir,
			fetchData,
			ids,
			loading,
			requesting,
			selectedRows,
			setSelectedRows,
			trocarTipoResponsavel,
		}),
		[
			adicionar,
			atualizarValor,
			closeModal,
			conta,
			data,
			editarValor,
			excluir,
			fetchData,
			ids,
			loading,
			requesting,
			selectedRows,
			setSelectedRows,
			trocarTipoResponsavel,
		]
	);

	return (
		<ModalArrecadacaoUnidadesEditarContext.Provider value={ProviderValue}>
			{children}
		</ModalArrecadacaoUnidadesEditarContext.Provider>
	);
};
