import React from 'react';

const DataField = ({ record, source }) => {
	const data = new Date(`${record && record[source]}T10:00`);
	return (
		<span>
			{`${data}` !== 'Invalid Date'
				? data.toLocaleDateString('pt-br', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
				  })
				: ''}
		</span>
	);
};

export default DataField;
