import React, { useContext } from 'react';

import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';

const ModalFeedback = ({ component, onClose, title, closeSizeEditable = true }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const handleClose = () => {
		if (closeSizeEditable) setModalValue((v) => ({ ...v, open: false }));
		if (onClose) onClose();
	};

	return (
		<CustomDialogBodySizeEditable
			customActions={
				<>
					<ButtonCancel onClick={handleClose}>Fechar</ButtonCancel>
				</>
			}
			form={{ component: component }}
			title={title}
		/>
	);
};

export default ModalFeedback;
