import React from 'react';

type TRedirectScreen = {
	editId: number | null;
	screen: 'list' | 'edit' | 'create';
};

type TRedirectContext = {
	handleSetRedirectContext: (screen: 'list' | 'edit' | 'create', editId?: number | null) => void;
	screen: TRedirectScreen;
};

export const ConfiguracaoDeCorrecaoMonetariaContext = React.createContext<TRedirectContext>({
	screen: {
		screen: 'list',
		editId: null,
	},
	handleSetRedirectContext: () => {},
});

export const ConfiguracaoDeCorrecaoMonetariaContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [redirectScreen, setRedirectScreen] = React.useState<TRedirectScreen>({
		screen: 'list',
		editId: null,
	});

	const handleSetRedirectContext = (screen: 'list' | 'edit' | 'create', editId: number | null = null) => {
		if (screen === 'edit') {
			return setRedirectScreen({
				screen: 'edit',
				editId: editId,
			});
		}
		return setRedirectScreen({
			editId: null,
			screen: screen,
		});
	};
	const feesConfigProviderValue = React.useMemo(
		() => ({
			handleSetRedirectContext: handleSetRedirectContext,
			screen: redirectScreen,
		}),
		[setRedirectScreen, redirectScreen]
	);

	return (
		<ConfiguracaoDeCorrecaoMonetariaContext.Provider value={feesConfigProviderValue}>
			{children}
		</ConfiguracaoDeCorrecaoMonetariaContext.Provider>
	);
};

export default ConfiguracaoDeCorrecaoMonetariaContext;
