import React, { useState } from 'react';
import MultiSelect from '../../../../../common/Selects/index';
import { Box } from '@material-ui/core';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';

const InputUnidades = () => {
	const [unidades, setUnidades] = useState([]);
	const { options } = useRequestOptionsUnidades();
	return (
		<Box>
			<MultiSelect variant={'formatsUnidades'} setValue={setUnidades} value={unidades} options={options} />
		</Box>
	);
};

export default InputUnidades;
