import * as React from 'react';
import { Create, useNotify, useRedirect, useRefresh } from 'react-admin';

import { AdvogadoFormulario } from './form';
import { AdvogadoCreateProps, AdvogadoProps, AdvogadoResponse } from './types';

export const AdvogadoCreate = (props: AdvogadoProps) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response: AdvogadoResponse) => {
		const data = response?.data;
		if (data) {
			notify(`Advogado(a) ${data.nome} cadastrado(a) com sucesso`);
			redirect('list', props.basePath);
			refresh();
		}
	};

	const CreateV2 = Create as unknown as React.FC<AdvogadoCreateProps>;

	return (
		<CreateV2 component='div' title={'Cadastrar Advogado'} undoable={false} onSuccess={onSuccess} {...props}>
			<AdvogadoFormulario {...props} />
		</CreateV2>
	);
};
