import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';

import { cepFormatacao } from '../../../../../fieldControl/cep';
import { cnpjFormatacao, cpfFormatacao } from '../../../../../fieldControl/documento';
import { idToNameUf } from '../../../../common/EnderecoV2';

function isValidDate(date) {
	return date instanceof Date && !isNaN(date);
}

const BoxResponsavelInfo = ({ entrada, saida, selectedPessoa }) => (
	<Box gridArea='ResponsavelInfo' flex={1}>
		<Typography>
			<strong>Nome: </strong>
			{selectedPessoa?.nome}
		</Typography>
		<Typography>
			<strong>Tipo: </strong>
			{selectedPessoa?.tipo_pessoa === 'F' ? 'Física' : 'Jurídica'}
		</Typography>
		<Typography>
			{selectedPessoa?.documento &&
				(selectedPessoa?.tipo_pessoa === 'F' ? (
					<>
						<strong>CPF: </strong>
						{cpfFormatacao(selectedPessoa?.documento)}
					</>
				) : (
					<>
						<strong>CNPJ: </strong>
						{cnpjFormatacao(selectedPessoa?.documento)}
					</>
				))}
		</Typography>
		{isValidDate(entrada) && entrada.toLocaleDateString && (
			<Typography>
				<strong>Entrada: </strong>
				{entrada.toLocaleDateString('pt-BR')}
			</Typography>
		)}
		{isValidDate(saida) && saida.toLocaleDateString && (
			<Typography>
				<strong>Saída: </strong>
				{saida.toLocaleDateString('pt-BR')}
			</Typography>
		)}
	</Box>
);

const BoxEnderecoInfo = ({ enderecoDados }) => (
	<Box gridArea='EnderecoInfo' flex={1}>
		<Box display='flex'>
			<Typography>
				<strong>CEP: </strong>
				{cepFormatacao(enderecoDados?.cep || '')}
			</Typography>
		</Box>
		<Box display='flex'>
			<Typography>
				<strong>Tipo de Logradouro: </strong>
				{enderecoDados?.tipo_logradouro?.descricao || ''}
			</Typography>
		</Box>
		<Box display='flex'>
			<Typography>
				<strong>Endereço: </strong>
				{enderecoDados?.endereco || ''}
			</Typography>
		</Box>
		<Box display='flex'>
			<Typography>
				<strong>Número: </strong>
				{enderecoDados?.numero || ''}
			</Typography>
		</Box>
		<Box display='flex'>
			<Typography>
				<strong>Bairro: </strong>
				{enderecoDados?.bairro || ''}
			</Typography>
		</Box>
		<Box display='flex'>
			<Typography>
				<strong>Cidade: </strong>
				{enderecoDados?.cidade || ''}
			</Typography>
		</Box>
		<Box display='flex'>
			<Typography>
				<strong>UF: </strong>
				{idToNameUf[enderecoDados?.uf] || ''}
			</Typography>
		</Box>
		<Box display='flex'>
			<Typography>
				<strong>Complemento: </strong>
				{enderecoDados?.complemento_endereco || ''}
			</Typography>
		</Box>
	</Box>
);

const BoxImobiliariaInfo = ({ selectedPessoa }) => (
	<Box gridArea='ImobiliariaInfo' flex={1}>
		{selectedPessoa && selectedPessoa.imobiliaria && (
			<Typography>
				<strong>Imobiliária: </strong>
				{selectedPessoa.imobiliaria.nome}
			</Typography>
		)}
	</Box>
);

const BoxInfoContainer = styled(Box)(({ selectedPessoa }) => ({
	transition: 'all 200ms ease',
	...(selectedPessoa && selectedPessoa.nome
		? { maxHeight: 700, opacity: 1 }
		: {
				maxHeight: 0,
				opacity: 0,
				pointerEvents: 'none',
				transform: 'scaleY(0)',
		  }),
	display: 'grid',
	'grid-row-gap': '1em',
	'grid-column-gap': '1em',
	'grid-template':
		selectedPessoa && selectedPessoa.imobiliaria
			? '"ResponsavelInfo EnderecoInfo" auto "ImobiliariaInfo ImobiliariaInfo" auto / calc(50% - 0.5em) calc(50% - 0.5em)'
			: '"ResponsavelInfo EnderecoInfo" auto / calc(50% - 0.5em) calc(50% - 0.5em)',
	marginTop: 2,
}));

const BoxInfo = ({ entrada, saida, selectedPessoa, enderecoDados }) => (
	<BoxInfoContainer selectedPessoa={selectedPessoa}>
		<BoxResponsavelInfo {...{ entrada, saida, selectedPessoa }} />
		<BoxEnderecoInfo enderecoDados={enderecoDados} />
		<BoxImobiliariaInfo selectedPessoa={selectedPessoa} />
	</BoxInfoContainer>
);

export default BoxInfo;
