import { addHours, eachMinuteOfInterval, format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import weekDayToString from '../utils/weekDaysToString';

const parseHourToDate = (hour) => parse(hour, 'H:mm:ss', new Date());

const dateToHourString = (date) => format(date, 'H:mm', { locale: ptBR });

export const isDateInPast = (groupedHour, date) => {
	try {
		/**
		 * Corta a string e pega o primeiro horario
		 * adiciona ':00' para tornar uma hora válida para conversão
		 * **/
		const firstHour = groupedHour.split(' - ')[0] + ':00';
		const _date = parse(firstHour, 'H:mm:ss', date);
		if (_date < new Date()) return true;

		return false;
	} catch {
		return false;
	}
};

export const getTaxaDeUsoAmbiente = (ambienteObject, diaSelecionado) => {
	const diaSemana = weekDayToString(diaSelecionado);

	if (!ambienteObject) return '';

	if (!ambienteObject.taxa_por_uso) return 'Isento';

	const dia = ambienteObject.dias.find((dia) => dia.dia === diaSemana);
	if (!dia || !dia.valor) return 'Isento';

	const valor = parseFloat(dia.valor);
	if (isNaN(valor)) return 'Isento';

	return (valor || 'R$ 0,00').toLocaleString('pt-br', {
		style: 'currency',
		currency: 'BRL',
	});
};

export const groupedHoursToObject = (horarios) => {
	return horarios.map((horario) => {
		if (horario.includes('/')) {
			const [inicio, fim] = horario.split(' / ')[0].split(' - ');
			return {
				inicio: `${inicio}:00`,
				fim: `${fim}:00`,
			};
		}
		const [inicio, fim] = horario.split(' - ');
		return {
			inicio: `${inicio}:00`,
			fim: `${fim}:00`,
		};
	});
};

export const fracaoToInterval = {
	HH: (hours) => _getGroupedIntervalFromHours(hours),
	DI: (hours) => _transformHoursToGroupedHour(hours),
	MP: (hours) => _transformHoursToGroupedHours(hours),
};

function _transformHoursToGroupedHours(hours) {
	if (hours.length === 2) {
		return [`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])}`];
	}

	return [
		`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])}`,
		`${dateToHourString(hours[2])} - ${dateToHourString(hours[3])}`,
	];
}

function _transformHoursToGroupedHour(hours) {
	if (hours.length === 2) {
		return [`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])}`];
	}

	return [
		`${dateToHourString(hours[0])} - ${dateToHourString(hours[1])} / ${dateToHourString(
			hours[2]
		)} - ${dateToHourString(hours[3])}`,
	];
}

function _getGroupedIntervalFromHours(hours) {
	const primeiraConjuntoDeHoras = eachMinuteOfInterval(
		{
			start: hours[0],
			end: hours[1],
		},
		{ step: 60 }
	).map((hora) => {
		return `${dateToHourString(hora)} - ${addHoursThenFormatToHourString(hora)}`;
	});

	primeiraConjuntoDeHoras.pop();

	if (hours.length === 2) {
		return primeiraConjuntoDeHoras;
	}

	const segundoConjuntoDeHoras = eachMinuteOfInterval(
		{
			start: hours[2],
			end: hours[3],
		},
		{ step: 60 }
	).map((hora) => {
		return `${dateToHourString(hora)} - ${addHoursThenFormatToHourString(hora)}`;
	});

	segundoConjuntoDeHoras.pop();

	return [...primeiraConjuntoDeHoras, ...segundoConjuntoDeHoras];
}

function formatFracoes(datas) {
	return datas.reduce((previousValue, currentValue) => {
		return [...previousValue, parseHourToDate(currentValue.inicio), parseHourToDate(currentValue.fim)];
	}, []);
}

function formatHHReservados(datas) {
	return datas.reduce((previousValue, currentValue) => {
		return [
			...previousValue,
			`${dateToHourString(parseHourToDate(currentValue.inicio))} - ${dateToHourString(
				parseHourToDate(currentValue.fim)
			)}`,
		];
	}, []);
}

const addHoursThenFormatToHourString = (date, amountToAdd = 1) =>
	format(addHours(date, amountToAdd), 'H:mm', { locale: ptBR });

export const formatFracoesThenGetInterval = {
	HH: (datas) => {
		return formatHHReservados(datas);
	},
	MP: (datas) => {
		return fracaoToInterval['MP'](formatFracoes(datas));
	},
	DI: (datas) => {
		return fracaoToInterval['DI'](formatFracoes(datas));
	},
};

export const hourToDateObject = (horas) => horas.filter((e) => e !== null).map(parseHourToDate);

export const formatarHorarios = (reservaDia) => {
	const { hora_1, hora_2, hora_3, hora_4, fracao } = reservaDia;
	const _horarios = hourToDateObject([hora_1, hora_2, hora_3, hora_4]);
	return fracaoToInterval[fracao](_horarios);
};

export const coletarBloqueiosManuais = (responseBloqueios) => {
	const results = responseBloqueios.value?.data?.results;
	if (!results.length) return [];

	return results.reduce((intervalos, bloqueio) => {
		if (!bloqueio?.bloqueios_intervalos_tempo?.length) return intervalos;
		return intervalos.concat(bloqueio.bloqueios_intervalos_tempo);
	}, []);
};

export const coletarPeriodosBloqueadosPorReservasParaAgendamento = (reservas) =>
	reservas.reduce(
		({ mapPeriodosReservados, mapPeriodosUsadosPorUnidades }, { reservas_intervalos_tempo, unidade_id }) => {
			const periodosUsados = reservas_intervalos_tempo.map((tempo) => {
				const inicio = dateToHourString(parseHourToDate(tempo.inicio));
				const fim = dateToHourString(parseHourToDate(tempo.fim));
				const chave = `${inicio} - ${fim}`;
				if (!mapPeriodosReservados.has(chave)) {
					mapPeriodosReservados.set(chave, {
						quantidadeReservas: 1,
						unidades_ids: [unidade_id],
						periodo: tempo,
					});
					return tempo;
				}
				const periodoReservado = mapPeriodosReservados.get(chave);
				periodoReservado.quantidadeReservas += 1;
				periodoReservado.unidades_ids.push(unidade_id);
				return tempo;
			});
			const periodosUsadosPelaUnidade = mapPeriodosUsadosPorUnidades.get(unidade_id) || [];
			mapPeriodosUsadosPorUnidades.set(unidade_id, periodosUsadosPelaUnidade.concat(periodosUsados));
			return {
				mapPeriodosReservados,
				mapPeriodosUsadosPorUnidades,
			};
		},
		{ mapPeriodosReservados: new Map(), mapPeriodosUsadosPorUnidades: new Map() }
	);

export const formatarHorariosDisponiveisEBloqueadosParaCadastro = (
	ambienteSelecionado,
	reservaDia,
	responseBloqueios
) => {
	const { fracao, reservas } = reservaDia;

	const horarios = formatarHorarios(reservaDia);
	const bloqueios = coletarBloqueiosManuais(responseBloqueios);

	const { mapPeriodosReservados, mapPeriodosUsadosPorUnidades } =
		coletarPeriodosBloqueadosPorReservasParaAgendamento(reservas);

	const valoresBloqueados = bloqueios.concat(
		[...mapPeriodosReservados.values()]
			.filter(
				(periodoReservado) =>
					periodoReservado.quantidadeReservas >= ambienteSelecionado.maxima_quantidade_unidades_por_periodo
			)
			.map((periodoReservado) => periodoReservado.periodo)
	);

	const horariosBloqueados = [
		valoresBloqueados.length > 0 ? formatFracoesThenGetInterval[fracao](valoresBloqueados) : null,
	];

	const horariosUsadosPorUnidade = new Map(
		mapPeriodosUsadosPorUnidades.entries().map(([unidade_id, periodosUsadosPorUnidade]) => {
			return [unidade_id, formatFracoesThenGetInterval[fracao](periodosUsadosPorUnidade)];
		})
	);
	return {
		horarios,
		horariosBloqueados,
		horariosUsadosPorUnidade,
	};
};

export const coletarPeriodosBloqueadosPorReservasParaEdicao = (outras_reservas) =>
	outras_reservas.reduce(
		({ mapPeriodosReservados, mapPeriodosUsadosPorUnidades }, tempo) => {
			const { unidade_id } = tempo;
			const inicio = dateToHourString(parseHourToDate(tempo.inicio));
			const fim = dateToHourString(parseHourToDate(tempo.fim));
			const chave = `${inicio} - ${fim}`;
			if (!mapPeriodosReservados.has(chave)) {
				mapPeriodosReservados.set(chave, {
					periodo: { inicio: tempo.inicio, fim: tempo.fim },
					quantidadeReservas: 1,
					unidades_ids: [unidade_id],
				});
			} else {
				const periodoReservado = mapPeriodosReservados.get(chave);
				periodoReservado.quantidadeReservas += 1;
				periodoReservado.unidades_ids.push(unidade_id);
			}

			const periodosUsadosPelaUnidade = mapPeriodosUsadosPorUnidades.get(unidade_id) || [];
			periodosUsadosPelaUnidade.push(tempo);
			mapPeriodosUsadosPorUnidades.set(unidade_id, periodosUsadosPelaUnidade);

			return {
				mapPeriodosReservados,
				mapPeriodosUsadosPorUnidades,
			};
		},
		{ mapPeriodosReservados: new Map(), mapPeriodosUsadosPorUnidades: new Map() }
	);

export const formatarHorariosDisponiveisEBloqueadosParaEdicao = (
	ambienteSelecionado,
	reservaDia,
	responseBloqueios,
	outras_reservas
) => {
	const { fracao } = reservaDia;
	const horarios = formatarHorarios(reservaDia);
	const bloqueios = coletarBloqueiosManuais(responseBloqueios);
	const { mapPeriodosReservados, mapPeriodosUsadosPorUnidades } =
		coletarPeriodosBloqueadosPorReservasParaEdicao(outras_reservas);

	const valoresBloqueados = bloqueios.concat(
		[...mapPeriodosReservados.values()]
			.filter(
				(periodoReservado) =>
					periodoReservado.quantidadeReservas >= ambienteSelecionado.maxima_quantidade_unidades_por_periodo
			)
			.map((periodoReservado) => periodoReservado.periodo)
	);

	const horariosBloqueados = [
		valoresBloqueados.length > 0 ? formatFracoesThenGetInterval[fracao](valoresBloqueados) : null,
	];

	const horariosUsadosPorUnidade = new Map(
		mapPeriodosUsadosPorUnidades.entries().map(([unidade_id, periodosUsadosPorUnidade]) => {
			return [unidade_id, formatFracoesThenGetInterval[fracao](periodosUsadosPorUnidade)];
		})
	);
	return {
		horarios,
		horariosBloqueados,
		horariosUsadosPorUnidade,
	};
};
export const DIAS_DA_SEMANA = {
	SEG: 'Segunda-feira',
	TER: 'Terça-feita',
	QUA: 'Quarta-feira',
	QUI: 'Quinta-feira',
	SEX: 'Sexta-feira',
	SAB: 'Sábado',
	DOM: 'Domingo',
};

export const TIPO_RESERVA = {
	HH: 'Hora em hora',
	MP: 'Meio período',
	DI: 'Dia inteiro',
};
