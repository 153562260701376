import React from 'react';

import { AxiosError } from 'axios';

import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useDataProvider } from 'react-admin';

import NovaLeituraContext from './NovaLeituraContext';

import { GetSimpleResult, TGetSimple } from 'dataProvider.n';
import {
	TLeituraParaCalculo,
	TDadoSelectLeiturasParaCalculo,
	TSelectLeiturasParaCalculoProps,
} from './tipagens/TSelectLeiturasParaCalculo';

const SelectLeiturasParaCalculo = ({
	leituraParaCalculoId,
	setLeituraParaCalculoId,
}: TSelectLeiturasParaCalculoProps) => {
	const dP = useDataProvider();
	const { dadosNovaLeitura } = React.useContext(NovaLeituraContext);
	const [{ loading, options, data }, setDados] = React.useState<TDadoSelectLeiturasParaCalculo>({
		loading: true,
		options: [],
		data: {},
	});
	const condominio_id = dadosNovaLeitura.id_condominio;

	React.useEffect((): void => {
		(dP.getSimple as TGetSimple)('leitura', {
			pagination: { page: 1, perPage: 10000 },
			filter: {
				id_condominio_id: condominio_id,
				exclui_situacao: 'E',
			},
		})
			.then((response: GetSimpleResult<TLeituraParaCalculo>) => {
				const options = (response?.data?.results || []).filter((leitura) => Boolean(leitura?.id));
				const data = Object.fromEntries(options.map((leitura) => [leitura.id, leitura]));
				setDados({ loading: false, options, data });
			})
			.catch((e: AxiosError): void | Promise<never> => {
				if ([401, 403].includes(e?.response?.status as number)) return Promise.reject(e);
				setDados({ loading: false, options: [], data: {} });
			});
	}, [condominio_id]);

	if (loading)
		return (
			<div style={{ marginTop: 25 }}>
				<LinearProgress />
			</div>
		);

	return (
		<Autocomplete
			value={data[leituraParaCalculoId as number] ?? null}
			onChange={(_, novaLeituraParaCalculo) => {
				setLeituraParaCalculoId(novaLeituraParaCalculo?.id ?? null);
			}}
			options={options}
			getOptionLabel={(option) => option?.nome || ''}
			renderOption={(option) => option?.nome || ''}
			renderInput={(params) => <TextField {...params} margin='dense' label='Leituras para cálculo' fullWidth />}
			noOptionsText='Nenhum resultado encontrado'
			getOptionSelected={(option, value) => option.id === value.id}
			autoHighlight
			clearText='Limpar'
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						maxWidth: '320px',
						minWidth: '180px',
					}}
				/>
			)}
			fullWidth
		/>
	);
};

export default SelectLeiturasParaCalculo;
