import React from 'react';

import { Box, Button, TextField, Tooltip } from '@material-ui/core/';
import { Autocomplete } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';

import { CreateButton } from 'components/common/commonComponentsTSX';
import { CondominiosContext } from 'context/CondominioContextProvider';

import { Folder as FolderIcon, FolderOpen as FolderOpenIcon } from '@material-ui/icons';
import { Settings as GearIcon } from '@material-ui/icons';

import { TCondominio } from 'types/condominio';
import AdicionarPasta, { Permissions } from '../modals/Modais';
import { ModalContext } from 'components/common/ModalContext';
import { ProviderValueContext } from './type';
import FilterBox from '../../../common/FilterSelect/FilterBox';

import { SaveButton, DeleteButton } from 'components/common/commonComponentsTSX';
import { DocumentsContext } from '../contexts/DocumentsContext';
import DocumentsFolderEdit from './DocumentsFolderEdit';
import DocumentsFolderExclude from './DocumentsFolderExclude';
import { UserThemeContext } from 'theme/context';
import { ThemeHandler } from 'types/tpyesGlobal';

const useDocumentsFolderBasicsMethodsHook = () => {
	const { screen, handleSetRedirectContext } = React.useContext(DocumentsContext);
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const handleEraseFolderById = (id: number) => {
		return setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <DocumentsFolderExclude id={id} />,
		}));
	};
	const handleClickEntryFolder = (id: number) => {
		screen.screen === 'folder' && handleSetRedirectContext('file', id);
	};

	const handleClickCreateFolder = () => {
		return setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <AdicionarPasta />,
		}));
	};

	const handleClickEditFolder = (id: number) => {
		return setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <DocumentsFolderEdit idFolder={id} />,
		}));
	};

	const handleClickEditFolderPermissions = (
		id: number,
		funcoes: [],
		permissoes: { [key: string]: boolean },
		ocupacoes_gestao_com_acesso_liberado: number[]
	) => {
		return setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<Permissions
					idFolder={id}
					funcoes={funcoes}
					permissoes={permissoes}
					ocupacoes_gestao_com_acesso_liberado={ocupacoes_gestao_com_acesso_liberado}
				/>
			),
		}));
	};

	return {
		handleClickEntryFolder,
		handleEraseFolderById,
		handleClickCreateFolder,
		handleClickEditFolder,
		handleClickEditFolderPermissions,
	};
};

const CondominioSelect: React.FC = () => {
	const { condominios, condominioSelecionado, setCondominioPorId } = React.useContext(CondominiosContext);

	const disabled = !condominios?.length;
	const label = disabled ? 'Nenhum resultado encontrado' : null;

	return (
		<FilterBox titulo='Selecionar Condomínio' boxProps={{ flex: 1 }}>
			<Autocomplete
				disabled={disabled}
				disableClearable
				options={condominios}
				onChange={(_, value: TCondominio) => {
					setCondominioPorId(value.id);
				}}
				getOptionLabel={(option: TCondominio) => option.nome}
				renderOption={(option) => option?.nome}
				renderInput={(params) => <TextField {...params} label={label} margin='dense' fullWidth />}
				value={condominioSelecionado as TCondominio}
				fullWidth
			/>
		</FilterBox>
	);
};

const FilterFolderName: React.FC = React.memo(() => {
	const { folderName, setFolderName } = React.useContext(DocumentsContext);

	return (
		<FilterBox titulo='Nome de Pasta' boxProps={{ flex: 1 }}>
			<TextField
				onChange={(e) => setFolderName(e.target.value)}
				value={folderName}
				placeholder='Filtrar pelo Nome da Pasta'
				variant='outlined'
				size='small'
				fullWidth
			/>
		</FilterBox>
	);
});

const FiltersSelect: React.FC = React.memo(() => {
	return (
		<Box display={'flex'} style={{ gap: 30 }}>
			<CondominioSelect />
			<FilterFolderName />
		</Box>
	);
});

const DocumentsFolderList: React.FC = React.memo(() => {
	const theme = React.useContext(UserThemeContext);
	const colours = useTheme<ThemeHandler<typeof theme>>();
	const { folders } = React.useContext(DocumentsContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const { handleClickCreateFolder } = useDocumentsFolderBasicsMethodsHook();

	return (
		<Box mt={2}>
			<FiltersSelect />
			<Box display={'flex'} justifyContent={'flex-end'} mt={2}>
				<CreateButton onClick={handleClickCreateFolder} disabled={!condominioSelecionado}>
					Nova Pasta
				</CreateButton>
			</Box>
			{folders.length ? (
				<Box
					height={'70vh'}
					overflow={'auto'}
					style={{
						backgroundColor: colours.fundoMenu[100],
						color: colours.font[100],
						gap: '10px',
					}}
					boxShadow={
						'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
					}
					borderRadius={'4px'}
					boxSizing={'border-box'}
					padding={'10px'}
					display={'grid'}
					gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'}
					gridTemplateRows={'1fr 1fr 1fr 1fr 1fr'}
					key={'box_table'}
				>
					{folders.map((folder) => {
						return <FolderCard folder={folder} />;
					})}
				</Box>
			) : (
				<Box
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					style={{
						backgroundColor: colours.fundoMenu[100],
						color: colours.font[100],
						gap: '10px',
					}}
					boxShadow={
						'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
					}
					borderRadius={'4px'}
					boxSizing={'border-box'}
					padding={2}
				>
					Nenhuma pasta criada!
				</Box>
			)}
		</Box>
	);
});

export default DocumentsFolderList;

type FolderCardProps = {
	folder: Record<string, any>;
};

const FolderCard: React.FC<FolderCardProps> = React.memo(({ folder }) => {
	const { theme } = React.useContext(UserThemeContext);
	const colours = useTheme<ThemeHandler<typeof theme>>();
	const { handleClickEntryFolder, handleEraseFolderById, handleClickEditFolder, handleClickEditFolderPermissions } =
		useDocumentsFolderBasicsMethodsHook();
	return (
		<Box
			style={{ backgroundColor: colours.fundoMenu[100] }}
			display={'flex'}
			borderRadius={'4px'}
			width={'100%'}
			flexDirection={'column'}
			height={150}
			boxShadow={
				'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
			}
		>
			<Box display={'flex'} justifyContent={'flex-end'} alignItems={'end'}>
				<Button
					style={{
						cursor: 'pointer',
					}}
					onClick={() =>
						handleClickEditFolderPermissions(
							folder.id,
							folder.funcoes,
							folder.permissoes,
							folder.ocupacoes_gestao_com_acesso_liberado
						)
					}
				>
					<GearIcon />
				</Button>
			</Box>
			<Tooltip title={'Clique aqui para visualizar o conteúdo da pasta'}>
				<Box
					onClick={() => handleClickEntryFolder(folder.id)}
					flex={1}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'flex-end'}
					style={{ cursor: 'pointer', marginTop: '-20px' }}
					width={'100%'}
				>
					{folder.has_files ? (
						<FolderIcon style={{ color: colours.font[200], width: '3em', height: '3em' }} />
					) : (
						<FolderOpenIcon style={{ color: colours.font[200], width: '3em', height: '3em' }} />
					)}
				</Box>
			</Tooltip>
			<Box
				style={{ cursor: 'pointer' }}
				onClick={() => handleClickEntryFolder(folder.id)}
				flex={1}
				textAlign={'center'}
			>
				{folder.nome}
			</Box>
			<Box flex={1} style={{ columnGap: '0.5rem' }} display={'flex'} justifyContent={'center'}>
				<DeleteButton onClick={() => handleEraseFolderById(folder.id)}>Excluir</DeleteButton>
				<SaveButton disabled={false} onClick={() => handleClickEditFolder(folder.id as number)}>
					Editar
				</SaveButton>
			</Box>
		</Box>
	);
});
