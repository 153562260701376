import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useDataProvider } from 'react-admin';

import { Button } from '@material-ui/core';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import { ContasContext } from '../contexts/ContasContext';
import FluxoImportacaoPlanoContext, { createDadosContasVazio } from './FluxoImportacaoPlanoContext';
import ImportPlanoDePlanoCadastradoModalBody from './ImportPlanoDePlanoCadastradoModalBody';
import ImportPlanoDeCSVDropZoneModalBody from './ImportPlanoDeCSVDropZoneModalBody';
import ImportPlanoDeCSVModalBody from './ImportPlanoDeCSVModalBody';
import OpcoesImportacaoPlanoModal from './OpcoesImportacaoPlanoModal';

const StepComponentInvalido = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	return (
		<CustomDialogBodySizeEditable
			title='Um erro tem ocorrido no fluxo de importação'
			text='Saída do fluxo e tente novamente'
			customActions={<Button onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</Button>}
		/>
	);
};

const stepComponents = {
	OpcoesImportacao: OpcoesImportacaoPlanoModal,
	ImportacaoPorPlanoCadastrado: ImportPlanoDePlanoCadastradoModalBody,
	'ImportacaoPorCSV-DropZone': ImportPlanoDeCSVDropZoneModalBody,
	ImportacaoPorCSV: ImportPlanoDeCSVModalBody,
};

const minWidthDosModais = {
	OpcoesImportacao: '61vw',
	ImportacaoPorPlanoCadastrado: '61vw',
	'ImportacaoPorCSV-DropZone': '61vw',
	ImportacaoPorCSV: '90vw',
};

const FluxoImportacaoPlanoContextProvider = ({ descricao, id }) => {
	const dP = useDataProvider();
	const { contas } = useContext(ContasContext);
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);

	const [podeImportar, setPodeImportar] = useState(false);
	const [planosDisponiveisParaImportacao, setPlanosDisponiveisParaImportacao] = useState([]);
	const [planoParaImportacaoSelecionado, setPlanoParaImportacaoSelecionado] = useState(null);
	const [planoImportando] = useState({ descricao, id });
	const [stepComponent, setStepComponent] = useState('OpcoesImportacao');
	const [dadosContasReceitasDespesas, setDadosContasReceitasDespesas] = useState({
		receitas: createDadosContasVazio(),
		despesas: createDadosContasVazio(),
	});

	const handleClose = useCallback(() => setModalValue((v) => ({ ...v, open: false })), [setModalValue]);

	const getPlanosDisponiveis = () => {
		dP.getSimple('plano_condominio', {
			pagination: { page: 1, perPage: 1000 },
		})
			.then((response) => {
				const planos = (response?.data?.results || []).filter(
					(plano) => plano?.id && `${plano?.id}` !== `${id}`
				);
				if (planos.length) {
					setPlanosDisponiveisParaImportacao(planos);
					setPlanoParaImportacaoSelecionado((plano) => planos[0] ?? null);
				} else {
					setPlanosDisponiveisParaImportacao([]);
					setPlanoParaImportacaoSelecionado(null);
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setPlanosDisponiveisParaImportacao([]);
				setPlanoParaImportacaoSelecionado(null);
			});
	};

	useEffect(getPlanosDisponiveis, []);

	useEffect(() => {
		setPodeImportar(Object.keys(contas.receita).length === 0 && Object.keys(contas.despesa).length === 0);
	}, [contas]);

	const updateMinWidhtModal = () => {
		setMinWidthModel(minWidthDosModais[stepComponent] || '61vw');
	};
	useEffect(updateMinWidhtModal, [stepComponent]);

	const StepComponent = useMemo(() => stepComponents[stepComponent] ?? StepComponentInvalido, [stepComponent]);

	const value = useMemo(
		() => ({
			descricao,
			id,
			podeImportar,
			planosDisponiveisParaImportacao,
			planoImportando,
			setStepComponent,
			planoParaImportacaoSelecionado,
			setPlanoParaImportacaoSelecionado,
			handleClose,
			dadosContasReceitasDespesas,
			setDadosContasReceitasDespesas,
		}),
		[
			descricao,
			id,
			podeImportar,
			planosDisponiveisParaImportacao,
			planoImportando,
			setStepComponent,
			planoParaImportacaoSelecionado,
			setPlanoParaImportacaoSelecionado,
			handleClose,
			dadosContasReceitasDespesas,
			setDadosContasReceitasDespesas,
		]
	);

	return (
		<FluxoImportacaoPlanoContext.Provider value={value}>
			<StepComponent />
		</FluxoImportacaoPlanoContext.Provider>
	);
};

export default FluxoImportacaoPlanoContextProvider;
