import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Title } from 'react-admin';
import { getConsultaComponent } from './consultaDisponiveis';
import SelectConsulta from './SelectConsulta';

function ConsultasList() {
	const [selectedConsulta, setSelectedConsulta] = React.useState(null);
	const { id: componentId } = selectedConsulta ?? {};
	const ConsultaComponent = getConsultaComponent(componentId);

	return (
		<>
			<Title title='Consultas' />
			<Box display='flex' m='1em'>
				<Box flex={1}>
					<Paper style={{ padding: '1em' }}>
						<Box flexDirection='column' display='flex' style={{ gap: 10 }}>
							<Typography variant='h6'>Consultas</Typography>
							<hr
								style={{
									marginRight: '1em',
									borderTop: '#d3d3d3',
									width: '100%',
								}}
							/>
							<SelectConsulta onChange={setSelectedConsulta} />
							{ConsultaComponent}
						</Box>
					</Paper>
				</Box>
			</Box>
		</>
	);
}

export default ConsultasList;
