import { makeStyles } from '@material-ui/core';

export const useMaisElevadoBulkActions = makeStyles(() => ({
	root: {
		'& div[data-test="bulk-actions-toolbar"]': {
			zIndex: 5,
		},
	},
}));

export const useHideBulkActions = makeStyles(() => ({
	root: {
		'& .MuiPaper-root': {
			marginTop: '0px !important',
		},
		'& div[data-test="bulk-actions-toolbar"]': {
			display: 'none',
		},
	},
}));
