import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { Button, TextField, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { CustomDialogBody, ModalContext } from '../../common/ModalContext';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import { CondominiosContext } from 'context/CondominioContextProvider';

const ModalDisponibilizarRelatorioCondomino = ({ data, tipoRelatorio }) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const temAcessoApp = Boolean(condominioSelecionado?.disponivel_para_kondomynos);
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { setModalValue } = useContext(ModalContext);
	const { data_final, data_inicial, mes_inicial, mes_final } = data;
	const [dataInicial, dataFinal] = [data_inicial || mes_inicial, data_final || mes_final].map((data) =>
		data.split('-').reverse().join('/')
	);
	const [inputValue, setInputValue] = useState(`${tipoRelatorio.name} - ${dataInicial} | ${dataFinal}`);
	const [checboxValue, setCheboxValue] = useState(temAcessoApp);
	const [loading, setLoading] = useState(false);

	const closeModal = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const disponibilizar = () => {
		setLoading(true);
		dataProvider
			.gerarRelatorio('relatorios', {
				...data,
				notificar: checboxValue,
				nomeRelatorio: inputValue.length > 255 ? inputValue.substring(0, 255) : inputValue,
				tipo_saida: 'disponibilizar',
				tipoRelatorio: tipoRelatorio.action,
				nome_condominio: condominioSelecionado.nome,
			})
			.then(() => notify('Relatório disponibilizado com sucesso!'))
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('');
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	return (
		<CustomDialogBody
			title={`Disponibilizar Relatório ${tipoRelatorio.name}`}
			form={{
				component: (
					<>
						<TextField
							margin='dense'
							label='Nome do relatório'
							fullWidth
							inputProps={{ maxLength: 255 }}
							value={inputValue}
							onChange={({ target: { value } }) => setInputValue(value)}
						/>
						<Tooltip title={temAcessoApp ? '' : 'É necessário que o condominio tenha acesso ao aplicativo'}>
							<div>
								<FormControlLabel
									disabled={!temAcessoApp}
									control={
										<Checkbox
											onChange={(_, checked) => setCheboxValue(checked)}
											checked={checboxValue}
										/>
									}
									label='Notificar no Aplicativo'
								/>
							</div>
						</Tooltip>
					</>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={closeModal} />
					<ButtonConfirm onClick={disponibilizar} loading={loading}>
						Confirmar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

const DisponibilizarRelatorioCondomino = (props) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const temAcessoApp = Boolean(condominioSelecionado?.disponivel_para_kondomynos);
	const { setModalValue } = useContext(ModalContext);
	const openModal = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalDisponibilizarRelatorioCondomino {...props} />,
		}));
	};

	return (
		<Tooltip title={temAcessoApp ? '' : 'É necessário que o condominio tenha acesso ao aplicativo'}>
			<div>
				<Button color='primary' variant='outlined' onClick={openModal} disabled={!temAcessoApp}>
					Disponibilizar relatório
				</Button>
			</div>
		</Tooltip>
	);
};

export default DisponibilizarRelatorioCondomino;
