import React, { useContext } from 'react';

import { useListContext, Button, useDataProvider, useNotify } from 'react-admin';

import ListActionsContext from './ListActionsContext';

const DateDataLeiturasSaveButton = () => {
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const { filterValues } = useListContext();
	const { dataLeitura, disabledSalvarDataLeituras } = useContext(ListActionsContext);
	return (
		<Button
			disabled={disabledSalvarDataLeituras}
			label='Salvar'
			onClick={() => {
				dataProvider
					.updateMany('atualizar_data_leitura', {
						data: {
							id_leitura: filterValues.leitura_id,
							data_referencia: filterValues.referencia.split('T')[0],
							data_leitura: dataLeitura.toISOString().split('T')[0],
						},
					})
					.then((response) => {
						const data = response?.data;
						if (data)
							notify(
								data.length
									? 'Data das leituras alteradas com sucesso!'
									: 'Nenhuma leitura foi lançada nesse período'
							);
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						notify('Erro ao salvar a data das leituras, tente recarregar a página', 'warning');
					});
			}}
			style={{ 'line-height': '3.5' }}
		/>
	);
};

export default DateDataLeiturasSaveButton;
