import { iterArrayValidacoes } from 'components/resources/condominios/ModalEditaContaBancaria/utils/validadoes';
import validacoesInstrucoesCobrancaRemessaCNAB240 from './validacoesInstrucoesCobrancaRemessaCNAB240';
import validacoesInstrucoesCobrancaRemessaCNAB400 from './validacoesInstrucoesCobrancaRemessaCNAB400';

const objectValidacoesInstrucoesCobrancaRemessaPorTipoIntegracao = {
	1: validacoesInstrucoesCobrancaRemessaCNAB240,
	2: validacoesInstrucoesCobrancaRemessaCNAB400,
};

const validateSeEmitindoBoletosEDependendoDoTipoIntegracaoId = ({ values, errors, ...props }) => {
	if (!values.emitir_boletos) return null;
	const tipoIntegracaoId = values?.tipo_integracao?.id;
	const validacoes = objectValidacoesInstrucoesCobrancaRemessaPorTipoIntegracao[tipoIntegracaoId];
	if (!validacoes) return null;
	return iterArrayValidacoes({ ...props, errors, values, validacoes });
};

export default validateSeEmitindoBoletosEDependendoDoTipoIntegracaoId;
