import {
	validacoesFieldsTabInformacao,
	createValidateLinhas,
	validateTipoIntegracao,
	createValidateDadosIntegracaoAPI,
} from '../../../utils/validadoes';
import {
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
} from '../../default/ContaCorrenteProps';

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	maxValor: 10,
	msgErroMaxValor: 'O valor da multa à ser aplicada não pode ser maior que 10%',
	valoresIsensao: ['ISE', 'CDB'],
	dias: false,
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	maxValor: 0.33,
	msgErroMaxValor: 'O valor dos juros à serem aplicados não pode ser maior que 0.33%',
	valoresIsensao: ['JIS', 'CDB'],
	dias: false,
});

const validacoesDesconto = getValidacoesDesconto({
	minValor: 0.01,
	msgErroMinValor: 'O valor do desconto à ser aplicado não pode ser menor que 0.01',
});

const validacaoDescontoDias = (props) => {
	const { field, values = {} } = props;
	if (!values[field]) return null;

	return validacoesDesconto.dias(props);
};

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,

	validacoesJuros.tipo,
	validacoesJuros.valor,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacaoDescontoDias,
];

const validacoes = [
	...validacoesFieldsTabInformacao,
	getValidateQuandoEmitindoBoletos([
		validateTipoIntegracao,
		createValidateDadosIntegracaoAPI(),
		...validacoesInstrucoesCobrancaRemessa,
		createValidateLinhas({ numeroLinhas: 4, maxValue: 100 }),
	]),
];

export default validacoes;
