import React, { useEffect, useContext, Dispatch, SetStateAction, useState } from 'react';
import {
	Box,
	Button,
	TextField,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputBaseComponentProps,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useDataProvider, useNotify } from 'react-admin';
import { cleanForm, getFornecedores, getCondominios, getImpostos } from '../utils/index';
import { CreateButton } from 'components/common/commonComponentsTSX';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { BoxSelectContaPlanoV2 } from 'components/resources/movimentacoes/components/BoxSelectContaPlano';
import { Imposto, Fornecedor, ContaDespesa, Condominio, FormValues } from '../Interfaces/interaces';
import { NumberFormatBRL } from 'components/common/CurrencyInput';
import { SimplesSyndikosSelectV2 } from 'components/common/SimplesSyndikosSelectV2';
import { CustomDataProvider } from 'types/tpyesGlobal';
import { sanitizeListNumberFormatBRLPropsV2 } from 'components/common/filtros/DecimalV2';

const opcoesImposto = [
	{ id: '1', name: 'PIS', aliquota: 0.65 },
	{ id: '2', name: 'Cofins', aliquota: 3 },
	{ id: '3', name: 'CSLL', aliquota: 1 },
	{ id: '4', name: 'PIS/Cofins/CSLL', aliquota: 4.65 },
	{ id: '5', name: 'INSS' },
	{ id: '6', name: 'ISS' },
];
const opcoesDiaVencimento = Array.from({ length: 31 }, (_, i) => ({ id: i + 1, name: `${i + 1}` }));

const validationSchema = Yup.object({
	nome: Yup.string().required('Nome é obrigatório'),
	aliquota: Yup.mixed()
		.required('Aliquota é obrigatória')
		.test('min-max-value', 'Aliquota deve ser no minimo 0 e no maximo 100', (value) => {
			return value >= 0 && value <= 100;
		}),
	dia_vencimento: Yup.number().required('Vencimento é obrigatório'),
	fornecedor: Yup.string().required('Fornecedor é obrigatório'),
	conta_despesa: Yup.number().required('Conta despesa é obrigatória'),
});

const ConfiguracaoDeImpostosBody: React.FC = () => {
	const dp: CustomDataProvider = useDataProvider();
	const notify = useNotify();
	const {
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors },
	} = useForm<FormValues>({ resolver: yupResolver(validationSchema) });

	const [impostos, setImpostos] = useState<Imposto[]>([]);
	const [fornecedores, setFornecedores] = useState<Fornecedor[]>([]);
	const [contaDespesaSelecionada, setContaDespesaSelecionada] = useState<ContaDespesa | null>(null);
	const [condominios, setCondominios] = useState<Condominio[]>([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [openModal, setOpenModal] = useState(false);
	const condominioSelecionado = watch('condominio');
	const nomeImpostoSelecionado = watch('nome');
	const { setModalValue, modalValue } = useContext(ModalSizeEditableContext);
	const [modalEdit, setModalEdit] = useState(false);
	const [isAliquotaDisabled, setIsAliquotaDisabled] = useState(false);
	const [aliquotaForEdit, setAliquotaForEdit] = useState(0);

	useEffect(() => {
		getFornecedores(dp, setFornecedores);
		getCondominios(dp, setCondominios);
	}, [dp]);

	useEffect(() => {
		setImpostos([]);
		return getImpostos(dp, setImpostos, condominioSelecionado);
	}, [condominioSelecionado]);

	useEffect(() => {
		if (contaDespesaSelecionada) {
			setValue('conta_despesa', contaDespesaSelecionada.id);
		}
	}, [contaDespesaSelecionada]);

	useEffect(() => {
		const impostoSelecionado = opcoesImposto.find((imposto) => imposto?.id == nomeImpostoSelecionado);
		if (impostoSelecionado && impostoSelecionado.aliquota !== undefined) {
			setValue('aliquota', Number(impostoSelecionado.aliquota));
			setIsAliquotaDisabled(true);
		} else if (aliquotaForEdit) {
			setValue('aliquota', Number(aliquotaForEdit));
			setIsAliquotaDisabled(false);
		} else {
			setValue('aliquota', '');
			setIsAliquotaDisabled(false);
		}
	}, [nomeImpostoSelecionado, setValue]);

	const removerImposto = async (id: number, index: number) => {
		try {
			await dp.delete('template_imposto', { id });
			notify('Configuração de imposto removida com sucesso', 'success');
		} catch (error) {
			console.error('Error removing tax configuration:', error);
			return notify('Erro ao remover configuração de imposto', 'error');
		}

		const novosImpostos = [...impostos];
		novosImpostos.splice(index, 1);

		setImpostos(novosImpostos);
	};

	const onSubmit = async (data: FormValues) => {
		if (data.aliquota == '') {
			return notify('Aliquota deve estar preenchida', 'error');
		}
		try {
			if (data.id) {
				const response = await dp.update('template_imposto', { id: data.id, data });
				const updatedImpostos = impostos.map((imposto) =>
					imposto.id === response.data.id ? { ...imposto, ...response.data } : imposto
				);

				setImpostos(updatedImpostos);
				notify('Configuração de imposto atualizado com sucesso', 'success');
			} else {
				const response = await dp.create('template_imposto', { data });
				setImpostos([...impostos, response.data as Imposto]);
				notify('Configuração de imposto cadastrado com sucesso', 'success');
			}
		} catch (error) {
			console.error('Error submitting form:', error);
			return notify('Erro ao cadastrar/atualizar configuração de imposto', 'error');
		} finally {
			cleanForm(setValue, setContaDespesaSelecionada as Dispatch<SetStateAction<ContaDespesa>>);
			setOpenModal(false);
		}
	};

	const handlePageChange = React.useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);

	function getPlanoCondominioById(id: string) {
		const objeto = condominios.find((item) => item.id === id);
		return objeto ? objeto.id_plano_condominio : null;
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name={'condominio'}
					control={control}
					defaultValue={''}
					render={({ field }) => (
						<SimplesSyndikosSelectV2
							{...field}
							label='Condominio'
							variant='outlined'
							choices={condominios}
						/>
					)}
				/>
				{!!condominioSelecionado && (
					<Box flex={1}>
						<Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<Typography variant='h6' gutterBottom>
								Cadastro de Impostos para Retenção
							</Typography>
							<CreateButton
								onClick={() => {
									cleanForm(
										setValue,
										setContaDespesaSelecionada as Dispatch<SetStateAction<ContaDespesa>>
									);
									setOpenModal(true);
								}}
							>
								Adicionar Novo Imposto
							</CreateButton>
						</Box>
						<hr style={{ marginRight: '1em', borderTop: '1px solid #d3d3d3' }} />
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ width: '10%', fontWeight: 'bold' }}>Ações</TableCell>
										<TableCell style={{ width: '15%', fontWeight: 'bold' }}>Imposto</TableCell>
										<TableCell style={{ width: '10%', fontWeight: 'bold' }}>Aliquota</TableCell>
										<TableCell style={{ width: '15%', fontWeight: 'bold' }}>Vencimento</TableCell>
										<TableCell style={{ width: '20%', fontWeight: 'bold' }}>
											Conta Despesa
										</TableCell>
										<TableCell style={{ width: '25%', fontWeight: 'bold' }}>Fornecedor</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{impostos
										.slice((page - 1) * rowsPerPage, page * rowsPerPage)
										.map((imposto, index) => (
											<TableRow key={index}>
												<TableCell style={{ width: '1rem', margin: '0', padding: '0' }}>
													<Button
														startIcon={<CloseIcon />}
														onClick={() => {
															setModalValue((v: typeof modalValue) => ({
																...v,
																open: true,
																dialogBody: (
																	<CustomDialogBodySizeEditable
																		form={false}
																		title='Atenção'
																		text='Você tem certeza que deseja remover a Configuração de Imposto? Essa ação é irreversível.'
																		customActions={
																			<>
																				<Button
																					onClick={() => {
																						setModalValue(
																							(v: typeof modalValue) => ({
																								...v,
																								open: false,
																							})
																						);
																						removerImposto(
																							imposto.id,
																							index
																						);
																					}}
																					size='small'
																					startIcon={<CloseIcon />}
																				>
																					Remover
																				</Button>
																				<Button
																					onClick={() =>
																						setModalValue(
																							(v: typeof modalValue) => ({
																								...v,
																								open: false,
																							})
																						)
																					}
																					size='small'
																				>
																					Cancelar
																				</Button>
																			</>
																		}
																	/>
																),
															}));
														}}
													/>
													<Button
														startIcon={<EditIcon />}
														onClick={() => {
															setValue('id', imposto.id);
															setValue('nome', imposto.nome);
															setAliquotaForEdit(imposto.aliquota);
															setValue('dia_vencimento', imposto.dia_vencimento);
															setContaDespesaSelecionada({
																id: imposto.conta_despesa,
																nome: imposto.conta_despesa_nome,
															});
															setValue('conta_despesa', imposto.conta_despesa);
															setValue('fornecedor', imposto.fornecedor);

															const impostoSelecionado = opcoesImposto.find(
																(i) => i.id == imposto.nome
															);
															if (
																impostoSelecionado &&
																impostoSelecionado.aliquota !== undefined
															) {
																setIsAliquotaDisabled(true);
															} else {
																setIsAliquotaDisabled(false);
															}

															setOpenModal(true);
															setModalEdit(true);
														}}
													/>
												</TableCell>
												<TableCell>
													<span style={{ textTransform: 'none' }}>
														{imposto.nome_display}
													</span>
												</TableCell>
												<TableCell>{imposto.aliquota}%</TableCell>
												<TableCell>
													<span style={{ textTransform: 'none' }}>
														Dia {imposto.dia_vencimento}
													</span>
												</TableCell>
												<TableCell>{imposto.conta_despesa_nome}</TableCell>
												<TableCell>{imposto.fornecedor_nome}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
							<SyndkosTablePagination
								{...{
									handlePageChange: handlePageChange,
									page,
									perPage: rowsPerPage,
									total: impostos.length,
									setPerPage: setRowsPerPage,
								}}
								labelRowsPerPage='Configuração de impostos por página'
							/>
						</TableContainer>
					</Box>
				)}
			</form>

			<Dialog
				open={openModal}
				onClose={() => {
					setOpenModal(false);
					cleanForm(setValue, setContaDespesaSelecionada as Dispatch<SetStateAction<ContaDespesa>>);
					setModalEdit(false);
					setAliquotaForEdit(0);
				}}
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle>
					{modalEdit ? 'Editar Configuração de Imposto' : 'Cadastro de Configuração de Imposto'}
				</DialogTitle>
				<DialogContent>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							name={'nome'}
							control={control}
							defaultValue={''}
							render={({ field }) => (
								<SimplesSyndikosSelectV2
									{...field}
									touched
									label='Imposto'
									choices={opcoesImposto}
									variant='outlined'
									margin='dense'
									error={!!errors.nome}
									helperText={errors?.nome?.message}
								/>
							)}
						/>
						<Controller
							name={'aliquota'}
							control={control}
							defaultValue={0}
							render={({ field }) => (
								<TextField
									{...field}
									type='number'
									label='Aliquota'
									variant='outlined'
									size='small'
									fullWidth
									margin='dense'
									error={!!errors.aliquota}
									helperText={errors?.aliquota?.message}
									inputProps={
										sanitizeListNumberFormatBRLPropsV2({
											prefix: '',
											suffix: ' %',
											allowNegative: false,
										}) as InputBaseComponentProps
									}
									InputProps={{
										inputComponent: NumberFormatBRL,
									}}
									disabled={isAliquotaDisabled}
								/>
							)}
						/>
						<Controller
							name={'dia_vencimento'}
							control={control}
							defaultValue={0}
							render={({ field }) => (
								<SimplesSyndikosSelectV2
									{...field}
									touched
									label='Vencimento'
									choices={opcoesDiaVencimento}
									variant='outlined'
									margin='dense'
									error={!!errors.dia_vencimento}
									helperText={errors?.dia_vencimento?.message}
								/>
							)}
						/>
						<Controller
							name={'conta_despesa'}
							control={control}
							defaultValue={0}
							render={({ field }) => (
								<BoxSelectContaPlanoV2
									id={getPlanoCondominioById(condominioSelecionado as string)}
									selectedConta={contaDespesaSelecionada}
									setSelectedConta={setContaDespesaSelecionada}
									operacao={{ tipo: 'despesa' }}
									ModalDetailContext={ModalSizeEditableContext}
									disabled={false}
								/>
							)}
						/>
						<Controller
							name={'fornecedor'}
							control={control}
							defaultValue=''
							render={({ field }) => (
								<SimplesSyndikosSelectV2
									{...field}
									touched
									label='Fornecedor'
									variant='outlined'
									choices={fornecedores}
									margin='dense'
									error={!!errors.fornecedor}
									helperText={errors?.fornecedor?.message}
								/>
							)}
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenModal(false);
							cleanForm(setValue, setContaDespesaSelecionada as Dispatch<SetStateAction<ContaDespesa>>);
							setModalEdit(false);
							setAliquotaForEdit(0);
						}}
						color='primary'
					>
						Cancelar
					</Button>
					<Button onClick={handleSubmit(onSubmit)} color='primary'>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ConfiguracaoDeImpostosBody;
