import * as React from 'react';
import { List, Pagination, TextField, DateField, ReferenceField } from 'react-admin';
import { Tabela } from '../../common/Tabela';
import { Padrao } from '../../common/filtros/Padrao';
import { CurrencyField } from '../../common/CurrencyInput';
import { opcoesSituacao, SituacaoField } from '../contasReceber/list';
import { Decimal } from '../../common/filtros/Decimal';
import { Data } from '../../common/filtros/DataV2';
import { format, parseISO } from 'date-fns';
import { Autocompletar } from '../../common/filtros/Autocompletar';

const ResponsavelField = ({ record }) => {
	switch (record?.tipo_responsavel) {
		case 'P':
			return 'Proprietário';
		case 'I':
			return 'Inquilino';
		default:
			return '';
	}
};

const ValorField = ({ record, source, ...props }) => (
	<CurrencyField
		{...props}
		prefix='R$ '
		minimumFractionDigits={2}
		value={record[source]}
		id={`${source}${record.id}`}
	/>
);

const ReferenciaField = ({ record }) => (record?.referencia ? format(parseISO(record.referencia), 'MM/yyyy') : '');

const TabelaHistorico = ({ condominio, ...rest }) => (
	<Tabela disableClickRow {...rest}>
		<ReferenciaField
			source='referencia'
			label='Referência'
			filtro={<Data format='MM/yyyy' mask='__/____' views={['year', 'month']} />}
		/>
		<ReferenceField
			link={false}
			source='identificacao'
			label='Identificação'
			filtro={
				<Autocompletar
					refName='nome'
					refResource='identificacao_arrecadacao'
					filtroOpcoes={{ id_condominio: condominio }}
				/>
			}
			reference='identificacao_arrecadacao'
		>
			<TextField source='nome' />
		</ReferenceField>
		<SituacaoField source='situacao' label='Situação' filtro={<Autocompletar opcoes={opcoesSituacao} />} />
		<ResponsavelField
			source='tipo_responsavel'
			label='Responsável'
			filtro={
				<Autocompletar
					opcoes={[
						{ id: 'P', name: 'Proprietário' },
						{ id: 'I', name: 'Inquilino' },
					]}
				/>
			}
		/>
		<TextField label='Pessoa' source='responsavel' filtro={<Padrao />} />
		<DateField source='data_vencimento' label='Vencimento' filtro={<Data />} />
		<DateField source='data_baixa' label='Pagamento' filtro={<Data />} />
		<ValorField source='valor_base' label='Valor' filtro={<Decimal />} align='right' />
		<ValorField source='valor_recebido' label='Pago' filtro={<Decimal />} align='right' />
	</Tabela>
);

export const HistoricoCobrancaList = ({ condominio, ...rest }) => (
	<List
		{...rest}
		empty={false}
		bulkActionButtons={false}
		actions={false}
		title=' '
		sort={{ field: 'referencia', order: 'DESC' }}
		perPage={10}
		pagination={
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Cobranças por página' />
		}
	>
		<TabelaHistorico {...{ condominio }} />
	</List>
);
