import React from 'react';
import { BoxDivisor } from 'components/common/Formulario';
import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import GestaoBody from './GestaoBody';
import { Box } from '@material-ui/core';
import ListWithDelete from 'components/common/commonComponentsTSX/DeleteList';

const Gestao: React.FC = () => (
	<Box width={'100%'}>
		<BoxDivisor titulo='Incluir Pessoas Para Gestão'>
			<ModalSizeEditableContextProvider
				customDialogProps={{ minWidth: '84vw', overflowY: 'auto', position: 'relative' }}
			>
				<ListWithDelete source='gestao'>
					<GestaoBody />
				</ListWithDelete>
			</ModalSizeEditableContextProvider>
		</BoxDivisor>
	</Box>
);

export default Gestao;
