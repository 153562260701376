import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import VinculosDatePicker from './VinculosDatePicker';

const BoxDateOptional = ({
	addDate,
	propsCheckBox: { checked, checkBoxOnChange, checkBoxLabel = 'Adicionar Data', ...propsCheckBox },
	propsDatePicker: { datePickerLabel = 'Data de Saída', datePickerValue, datePickerOnChange, ...propsDatePicker },
}) => (
	<>
		<FormControlLabel
			control={<Switch color='primary' checked={checked} onChange={checkBoxOnChange} {...propsCheckBox} />}
			label={checkBoxLabel}
			style={{ margin: '4px 0 8px' }}
		/>
		<div
			style={
				addDate
					? {
							transition: 'all 200ms ease',
							maxHeight: 500,
					  }
					: {
							transition: 'all 200ms ease',
							maxHeight: 0,
							opacity: 0,
							pointerEvents: 'none',
					  }
			}
		>
			<VinculosDatePicker
				label={datePickerLabel}
				value={datePickerValue}
				onChange={datePickerOnChange}
				{...propsDatePicker}
			/>
		</div>
	</>
);

export default BoxDateOptional;
