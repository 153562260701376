import React from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { Box, Button, Checkbox, FormLabel, FormControl, FormGroup, FormControlLabel } from '@material-ui/core';

import { BoxDivisor } from '../../../common/Formulario';
import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const BotaoToolbar = ({ handleClick, label, ...rest }) => (
	<Button color='primary' variant='outlined' onClick={handleClick} {...rest}>
		{label}
	</Button>
);

const OpcoesImpressaoCoeficienteDaUnidade = ({
	handleChange,
	totalCondominio,
	totalGrupo,
	imprimirAssinatura,
	coeficienteGaragem,
}) => {
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<Box>
					<FormControlLabel
						control={
							<Checkbox
								checked={coeficienteGaragem}
								onChange={(e, checked) => handleChange(checked, 'coeficiente_garagem')}
								name='coeficiente_garagem'
							/>
						}
						label='Imprimir coeficiente de garagem'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={totalGrupo}
								onChange={(e, checked) => handleChange(checked, 'total_grupo')}
								name='total_grupo'
							/>
						}
						label='Total por grupo'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={totalCondominio}
								onChange={(e, checked) => handleChange(checked, 'total_condominio')}
								name='total_condominio'
							/>
						}
						label='Total por condomínio'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirAssinatura}
								onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
								name='imprimir_assinatura'
							/>
						}
						label='Imprimir assinaturas'
					/>
				</Box>
			</FormGroup>
		</FormControl>
	);
};

const CoeficienteDaUnidadeDivisor = (props) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const {
		opcoesImpressao: { total_grupo, total_condominio, imprimir_assinatura, coeficiente_garagem },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	const requestData = {
		id_condominio: props.idCondominio,
		total_grupo: total_grupo,
		total_condominio: total_condominio,
		coeficiente_garagem: coeficiente_garagem,
		imprimir_assinatura: imprimir_assinatura,
		nome_relatorio: props.tipoRelatorio.action,
	};

	const handleClick = (e, tipoSaida) => {
		dataProvider
			.gerarRelatorio('relatorios', {
				...requestData,
				tipo_saida: tipoSaida,
				tipoRelatorio: props.tipoRelatorio.action,
			})
			.then(({ data }) => notify(tipoSaida === 'enviar' ? data?.success : 'Relatório gerado com sucesso!'))
			.catch((e) => {
				if (e?.response?.data instanceof Blob) {
					e.response.data.text().then((text) => {
						const data_obj = JSON.parse(text);
						notify(
							Object.values(data_obj || {})[0] || ['Erro inesperado, tente recarregar a página'],
							'warning'
						);
					});
				} else {
					notify(
						Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
						'warning'
					);
				}
				if ([400, 401, 403].includes(e?.response?.status)) return Promise.reject(e);
			});
	};

	return (
		<BoxDivisor titulo={props.tipoRelatorio.name}>
			<Box display='flex' gridGap='1em' mb='1em'>
				<Box flex={1} ml='1em'>
					<OpcoesImpressaoCoeficienteDaUnidade
						handleChange={handleChangeOpcoesImpressao}
						totalCondominio={total_condominio}
						totalGrupo={total_grupo}
						imprimirAssinatura={imprimir_assinatura}
						coeficienteGaragem={coeficiente_garagem}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar
					data={requestData}
					tipoRelatorio={props.tipoRelatorio}
					buttons={[
						<BotaoToolbar
							handleClick={(e) => handleClick(e, 'visualizar')}
							label='Visualizar'
							style={{ marginRight: '1em' }}
						/>,
						<BotaoToolbar
							handleClick={(e) => handleClick(e, 'pdf')}
							label='Baixar PDF'
							style={{ marginRight: '1em' }}
						/>,
					]}
				/>
			</Box>
		</BoxDivisor>
	);
};

export default CoeficienteDaUnidadeDivisor;
