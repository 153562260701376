import React, { useContext } from 'react';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { ArrecadacoesContext } from 'components/resources/arrecadacao/ArrecadacoesContext';
import { CondominiosContext } from 'context/CondominioContextProvider';
import FluxoModalProvisionamento from '../../FluxoModalProvisionamento';

export const ButtonProvisionar = () => {
	const {
		valores: { totalContas },
		id_identificacao_arrecadacao,
		referencia,
		contaBDestinoId,
	} = useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { condominioSelecionado } = useContext(CondominiosContext);

	return (
		<ButtonConfirm
			disabled={!totalContas || !contaBDestinoId}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<FluxoModalProvisionamento
							{...{
								condominio: condominioSelecionado?.id,
								identificacao: id_identificacao_arrecadacao,
								referencia,
								contaBDestinoId,
							}}
						/>
					),
				}));
			}}
		>
			{!totalContas ? 'Não há arrecadações nessa referência para provisionar' : 'Provisionar'}
		</ButtonConfirm>
	);
};

export default ButtonProvisionar;
