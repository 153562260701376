import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import ModalFeedbackRecalculo from './ModalFeedbackRecalculo';

const separarArrecadacoes = (arrecadacoes) => {
	return Object.values(arrecadacoes).reduce(
		(
			{ arrecadacoesSimples, arrecadacoesFundoPercentualPrimeiroNivel, arrecadacoesFundoPercentualSegundoNivel },
			arrecadacao
		) => {
			if (arrecadacao.tipo_lancamento !== 'PRC') {
				arrecadacoesSimples.push(arrecadacao);
			} else if (!arrecadacao.is_arrecadacao_fundo_percentual_secundaria) {
				arrecadacoesFundoPercentualPrimeiroNivel.push(arrecadacao);
			} else if (arrecadacao.is_arrecadacao_fundo_percentual_secundaria) {
				arrecadacoesFundoPercentualSegundoNivel.push(arrecadacao);
			}
			return {
				arrecadacoesSimples,
				arrecadacoesFundoPercentualPrimeiroNivel,
				arrecadacoesFundoPercentualSegundoNivel,
			};
		},
		{
			arrecadacoesSimples: [],
			arrecadacoesFundoPercentualPrimeiroNivel: [],
			arrecadacoesFundoPercentualSegundoNivel: [],
		}
	);
};

const emitirRequestRecalculoArrecadacoes = (arrecadacoes, dP) => {
	return arrecadacoes.map((arrecadacao) =>
		dP.updateWithPut('arrecadacao', { id: `${arrecadacao.id}/recalcular` }).catch((e) => {
			return Promise.reject({ e, dados_arrecadacao: arrecadacao });
		})
	);
};

const ModalConfirmacaoRecalculo = ({ registrosSelecionados, ids }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const {
		palette: { syndikosRed },
	} = useTheme();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [recalculando, setRecalculando] = useState(false);

	const handleSubmit = () => {
		if (recalculando) return Promise.resolve();
		setRecalculando(true);
		const {
			arrecadacoesSimples,
			arrecadacoesFundoPercentualPrimeiroNivel,
			arrecadacoesFundoPercentualSegundoNivel,
		} = separarArrecadacoes(registrosSelecionados);

		const gruposDeArrecadacoes = [
			arrecadacoesSimples,
			arrecadacoesFundoPercentualPrimeiroNivel,
			arrecadacoesFundoPercentualSegundoNivel,
		].filter((arrecadacoes) => Boolean(arrecadacoes.length));

		gruposDeArrecadacoes
			.reduce(async (correnteDePromises, arrecadacoes) => {
				const responses = await correnteDePromises;
				const novasResponses = await Promise.allSettled(
					emitirRequestRecalculoArrecadacoes(arrecadacoes, dataProvider)
				);
				return Promise.resolve([...responses, ...novasResponses]);
			}, Promise.resolve([]))
			.then((responses) => {
				const RejectedLoggoutResponse = responses.find(
					(r) => r?.status === 'rejected' && [401, 403].includes(r.reason?.e?.response?.status)
				);
				if (RejectedLoggoutResponse) return Promise.reject(RejectedLoggoutResponse.reason?.e);
				const fulfilledResponses = responses.filter((r) => r?.status === 'fulfilled');
				const rejectedResponses = responses.filter((r) => r?.status === 'rejected');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalFeedbackRecalculo
							fulfilledResponses={fulfilledResponses}
							rejectedResponses={rejectedResponses}
						/>
					),
				}));
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setRecalculando(false);
				notify('Erro inesperado, tente recarregar a página', 'warning');
				setModalValue((v) => ({ ...v, open: false }));
			});
	};

	return (
		<CustomDialogBodySizeEditable
			{...(recalculando
				? {
						form: {
							component: (
								<Box display='flex' justifyContent='center'>
									<CircularProgress />
								</Box>
							),
						},
						customActions: <></>,
				  }
				: {
						closeInSubmit: false,
						customActions: (
							<>
								<ButtonCancel
									onClick={() => {
										setModalValue((v) => ({ ...v, open: false }));
									}}
								/>
								<ButtonConfirm size='small' onClick={handleSubmit} variant='text'>
									Confirmar
								</ButtonConfirm>
							</>
						),
						text: (
							<Box display='flex' alignItems='center' gridGap='20px'>
								<Box>
									<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
								</Box>
								<Box>
									<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
										{`${(ids || []).length} ${
											(ids || []).length > 1
												? 'arrecadações selecionadas'
												: 'arrecadação selecionada'
										}`}
										<br />
										Essa ação é irreversível,
										<br />
										e poderá ocasionar em mudanças de valor para as unidades
										<br />e para o responsável pela cobrança.
									</Typography>
								</Box>
							</Box>
						),
						title: `Tem certeza que deseja recalcular ${
							(ids || []).length > 1 ? 'as arrecadações' : 'a arrecadação'
						}?`,
				  })}
		/>
	);
};

export default ModalConfirmacaoRecalculo;
