import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from 'react-final-form';

const NomeLeituraField = () => {
	return (
		<Field name='nome_leitura' id='nome_leitura'>
			{({ input }) => (
				<TextField
					value={input.value}
					onChange={input.onChange}
					name={input.name}
					label='Nome da Leitura'
					style={{ marginRight: '0.5em' }}
					margin='dense'
				/>
			)}
		</Field>
	);
};

export default NomeLeituraField;
