import React, { useContext } from 'react';

import { format, parse } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { ButtonConfirm } from '../../../common/buttons/ButtonConfirm';
import { CurrencyField } from '../../../common/CurrencyInput';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../../common/ModalSizeEditableContext';

import { isFeriado, isDomingo, isSabado } from '../ajusteDomingosFeriados';
import BoxVencimento from './BoxVencimento';
import BoxDadosCalculoVencimento from './BoxDadosCalculoVencimento';
import { FormParcelamentoContext } from './FormParcelamentoContext';
import { ModalConfimarRecalculo } from './modaisParcelas';

const InstrucaoTypography = () => (
	<Box display='flex' align='left'>
		<Typography fontWeight='fontWeightBold' variant='h6'>
			Selecione a forma que foi parcelada esta conta
		</Typography>
	</Box>
);

const CalcularButton = () => {
	const { calcular, erros, recalculado, setRecalculado } = useContext(FormParcelamentoContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const confirmarRecalculo = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<ModalConfimarRecalculo
					context={ModalSizeEditableContext}
					onClick={() => {
						calcular();
						setRecalculado(true);
					}}
				/>
			),
		}));
	};

	return (
		<Box display='flex' align='left' alignItems='center'>
			<Typography fontWeight='fontWeightBold' variant='body1' flex={1}>
				Previsão das Parcelas
			</Typography>
			<Typography fontWeight='fontWeightBold' variant='caption' flex={1}>
				(clique no botão de calcular para validar as parcelas)
			</Typography>
			<Box flex={1}>
				<ButtonConfirm
					onClick={recalculado ? calcular : confirmarRecalculo}
					startIcon={null}
					disabled={erros?.quantidade_parcelas === 'A quantidade de parcelas não pode exceder 120'}
				>
					Calcular
				</ButtonConfirm>
			</Box>
		</Box>
	);
};

const InfoParcela = ({ numero_parcela, data_previsao, data_vencimento, valor_pendente }) => {
	const { parcelasToken } = useContext(FormParcelamentoContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const parsedDataPrevisao = parse(data_previsao, 'yyyy-MM-dd', new Date());
	const parsedDataVencimento = parse(data_vencimento, 'yyyy-MM-dd', new Date());
	return (
		<TableRow>
			<TableCell
				align='center'
				style={{
					padding: 0,
					width: '20%',
					whiteSpace: 'nowrap',
					textTransform: 'none',
				}}
			>
				{`${numero_parcela}`.padStart(3, '0') +
					(parcelasToken.length > 1 ? `/${`${parcelasToken.length}`.padStart(3, '0')}` : '')}
			</TableCell>
			<TableCell
				align='center'
				style={{
					padding: 0,
					width: '20%',
					whiteSpace: 'nowrap',
					textTransform: 'none',
					...(isFeriado(parsedDataPrevisao) || isDomingo(parsedDataPrevisao) || isSabado(parsedDataPrevisao)
						? { color: syndikosRed.main }
						: {}),
				}}
			>
				{format(parsedDataPrevisao, 'dd/MM/yyyy eeeeee', { locale: pt })}
			</TableCell>
			<TableCell
				align='center'
				style={{
					padding: 0,
					width: '20%',
					whiteSpace: 'nowrap',
					textTransform: 'none',
					...(isFeriado(parsedDataVencimento) ||
					isDomingo(parsedDataVencimento) ||
					isSabado(parsedDataVencimento)
						? { color: syndikosRed.main }
						: {}),
				}}
			>
				{format(parsedDataVencimento, 'dd/MM/yyyy eeeeee', { locale: pt })}
			</TableCell>
			<TableCell
				align='right'
				style={{
					padding: 0,
					width: '20%',
					whiteSpace: 'nowrap',
					textTransform: 'none',
				}}
			>
				<CurrencyField
					prefix='R$ '
					minimumFractionDigits={2}
					value={valor_pendente || 0}
					id={`valor-${numero_parcela}`}
				/>
			</TableCell>
		</TableRow>
	);
};

const TabelaParcelas = () => {
	const { parcelasToken } = useContext(FormParcelamentoContext);
	return (
		<Box display='flex'>
			<Table size='small' padding='default'>
				<TableHead>
					<TableRow>
						<TableCell
							align='center'
							style={{
								padding: 0,
								width: '20%',
								whiteSpace: 'nowrap',
							}}
						>
							Parcela
						</TableCell>
						<TableCell
							align='center'
							style={{
								padding: 0,
								width: '20%',
								whiteSpace: 'nowrap',
							}}
						>
							Previsão Pagamento
						</TableCell>
						<TableCell
							align='center'
							style={{
								padding: 0,
								width: '20%',
								whiteSpace: 'nowrap',
							}}
						>
							Vencimento
						</TableCell>
						<TableCell
							align='right'
							style={{
								padding: 0,
								width: '20%',
								whiteSpace: 'nowrap',
							}}
						>
							Valor
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{parcelasToken?.length > 0 &&
						parcelasToken?.map((parcela, index) => <InfoParcela key={`parcela-${index}`} {...parcela} />)}
				</TableBody>
			</Table>
		</Box>
	);
};

const FormParcelamento = () => (
	<Box display='flex'>
		<Box flex={1}>
			<InstrucaoTypography />
			<BoxVencimento />
			<BoxDadosCalculoVencimento />
			<ModalSizeEditableContextProvider
				customDialogProps={{
					disableBackdropClick: true,
					disableEscapeKeyDown: true,
					minWidth: '61vw',
				}}
			>
				<CalcularButton />
			</ModalSizeEditableContextProvider>
			<TabelaParcelas />
		</Box>
	</Box>
);

export default FormParcelamento;
