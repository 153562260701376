import {
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	mensagensErrosPadroes,
} from '../../../default/ContaCorrenteProps';

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	maxValue: undefined,
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	maxValue: undefined,
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesInstrucoesCobrancaRemessaCNAB400 = [
	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,
];

export default validacoesInstrucoesCobrancaRemessaCNAB400;
