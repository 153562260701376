import React, { useContext, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import { format, parse } from 'date-fns';

import { CustomDialog, ModalContext, ModalContextProvider } from '../../../../../common/ModalContext';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { ButtonCancel } from '../../../../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../../../../common/buttons/ButtonConfirm';
import { TooltipIconButton } from '../../../../../common/TooltipIconButton';
import CondominioSelect from '../common/CondominioSelect';
import ContaBancariaSelect from '../common/ContaBancariaSelect';
import TabelaExtratoBancario from '../common/TabelaExtratoBancario';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';
import ModalLancamentoExtratoBancario from './ModalLancamentoExtratoBancario';
import { ConfirmacaoConciliacao } from './modaisConciliacao';

const Span = ({ children, ...props }) => (
	<Typography variant='span' {...props}>
		{children}
	</Typography>
);

const TopBar = ({ periodo, setTabelaConciliacaoExpandido, tabelaConciliacaoExpandido }) => (
	<div
		style={{
			width: 'calc(100% - 1rem)',
			justifyContent: 'space-between',
			alignItems: 'center',
		}}
	>
		<div
			style={{
				display: 'grid',
				justifyContent: 'left',
				gridTemplateColumns: 'repeat(3, 1fr)',
				gap: '1rem',
				minWidth: '90%',
			}}
		>
			<CondominioSelect disabled={true} label='Condomínio' />
			<ContaBancariaSelect disabled={true} label='Conta Bnacária' />
			<TextField disabled={true} inputProps={{ value: periodo }} fullWidth label='Período' margin='dense' />
		</div>
		{false && (
			<div style={{ textAlign: 'end' }}>
				<Span>
					<TooltipIconButton
						title={
							tabelaConciliacaoExpandido
								? 'Fechar Tabela para Conciliação'
								: 'Mostrar Tabela para Conciliação'
						}
						onClick={() => setTabelaConciliacaoExpandido(!tabelaConciliacaoExpandido)}
						disabled={true}
					>
						<AspectRatioIcon />
					</TooltipIconButton>
				</Span>
			</div>
		)}
	</div>
);

const TabelaExtratoBancarioView = ({ tabelaConciliacaoExpandido }) => (
	<div
		style={{
			margin: '0rem 1rem 0rem 0rem',
			width: tabelaConciliacaoExpandido ? 'calc(50% - 1.8rem)' : '100%',
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			transition: 'all 200ms ease',
		}}
	>
		<div
			style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				position: 'relative',
			}}
		>
			<Paper style={{ width: '100%', height: '100%' }}>
				<TabelaExtratoBancario />
			</Paper>
		</div>
	</div>
);

const BotaoLancar = () => {
	const { setModalValue } = useContext(ModalContext);
	const { registrosConciliaveisSelecionados, registrosLancaveisSelecionados } = useContext(
		FluxoImportacaoExtratoBancarioContext
	);
	return (
		<ButtonConfirm
			disabled={!registrosLancaveisSelecionados.length || !!registrosConciliaveisSelecionados.length}
			size='small'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalLancamentoExtratoBancario />,
				}));
			}}
		>
			Lançar
		</ButtonConfirm>
	);
};

const BotaoConciliar = () => {
	const { setModalValue } = useContext(ModalContext);
	const { registrosConciliaveisSelecionados, registrosLancaveisSelecionados } = useContext(
		FluxoImportacaoExtratoBancarioContext
	);
	return (
		<ButtonConfirm
			disabled={
				!registrosConciliaveisSelecionados.length ||
				registrosConciliaveisSelecionados.length > 1 ||
				!!registrosLancaveisSelecionados.length
			}
			size='small'
			onClick={() => {
				const registro = registrosConciliaveisSelecionados[0];
				const movimentacao = Object.values(registro.movimentacoesCompativeis.compatibilidadeTotal)[0];
				if (registro && movimentacao) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <ConfirmacaoConciliacao index={registro.index} movimentacao={movimentacao} />,
					}));
				}
			}}
		>
			Conciliar
		</ButtonConfirm>
	);
};

const formataData = (data, formato = 'dd/MM/yyyy') => {
	try {
		return format(data, formato);
	} catch (e) {
		console.log(e);
		return 'Invalid Date';
	}
};

const parseDate = (data, formatoEsperado = 'yyyy-MM-dd') => {
	try {
		return parse(data, formatoEsperado, new Date());
	} catch (e) {
		console.log(e);
		return 'Invalid Date';
	}
};

export const ModalTabelaExtratoBancario = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { datas_after, datas_before, setModalStep } = useContext(FluxoImportacaoExtratoBancarioContext);
	const [tabelaConciliacaoExpandido, setTabelaConciliacaoExpandido] = useState(false);
	const [periodo] = useState(`de ${formataData(parseDate(datas_after))} até ${formataData(parseDate(datas_before))}`);

	const acoes = [
		<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonCancel>,
		<BotaoVoltar
			onClick={() => {
				setModalStep('1');
			}}
		/>,
		<ModalContextProvider>
			<BotaoLancar />
			<BotaoConciliar />
			<CustomDialog disableBackdropClick={true} disableEscapeKeyDown={true} />
		</ModalContextProvider>,
	];

	return (
		<CustomDialogBodySizeEditable
			title='Importação de Extrato Bancário'
			customActions={acoes}
			form={{
				component: (
					<div>
						<TopBar
							{...{
								periodo,
								setTabelaConciliacaoExpandido,
								tabelaConciliacaoExpandido,
							}}
						/>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								width: '100%',
							}}
						>
							<TabelaExtratoBancarioView tabelaConciliacaoExpandido={tabelaConciliacaoExpandido} />
						</div>
					</div>
				),
			}}
		/>
	);
};

export default ModalTabelaExtratoBancario;
