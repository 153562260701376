import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';

import memoize from '../../../../common/memoize';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaCorrenteContext from '../contexts/EditaContaCorrenteContext';
import { ContaCorrentePropsByInstituicao } from '../bancos';
import { getFunctionsFormatarProps, formatarInstituicoesOptions } from '../utils/functions';

const { getInit, getReducer } = getFunctionsFormatarProps(ContaCorrentePropsByInstituicao);

const getinitial = memoize(({ instituicaoCodigo }) => ({
	instituicaoCodigo,
	optionsPorInstituicao: {},
}));

const EditaContaCorrenteContextProvider = ({ children }) => {
	const { initialDados, condominioRecord, dadosAdministradora } = useContext(EditaContaBancariaContext);
	const [instituicoesOptions] = useState(
		formatarInstituicoesOptions(
			initialDados.opcoesDadosBancarios.instituicao_financeira,
			initialDados.opcoesDadosBancarios.integracoes
		)
	);

	const reducer = useCallback(
		getReducer({
			...initialDados,
			optionsDefault: {
				id_inst_financeira: instituicoesOptions,
			},
			condominioRecord,
			dadosAdministradora,
		}),
		[initialDados, instituicoesOptions, condominioRecord, dadosAdministradora]
	);
	const init = useCallback(
		getInit({
			...initialDados,
			optionsDefault: {
				id_inst_financeira: instituicoesOptions,
			},
			condominioRecord,
			dadosAdministradora,
		}),
		[initialDados, instituicoesOptions, condominioRecord, dadosAdministradora]
	);

	const [
		{
			instituicaoCodigo,
			getDisabledTabs,
			tabContents,
			formatarValues,
			validate,
			initialValues,
			options,
			props,
			optionsIsencoes,
		},
		atualizarDadosInstituicao,
	] = useReducer(
		reducer,
		getinitial({
			instituicaoCodigo: instituicoesOptions.objects[initialDados.conta_condominio.id_inst_financeira]?.codigo,
		}),
		init
	);

	const value = useMemo(
		() => ({
			getDisabledTabs,
			tabContents,
			formatarValues,
			validate,
			initialValues,
			options,
			props,
			atualizarDadosInstituicao,
			instituicaoCodigo,
			optionsIsencoes,
		}),
		[
			getDisabledTabs,
			tabContents,
			formatarValues,
			validate,
			initialValues,
			options,
			props,
			atualizarDadosInstituicao,
			instituicaoCodigo,
			optionsIsencoes,
		]
	);

	return <EditaContaCorrenteContext.Provider value={value}>{children}</EditaContaCorrenteContext.Provider>;
};

export default EditaContaCorrenteContextProvider;
