import React, { useCallback, useContext, useEffect, useState } from 'react';

import { isMatch } from 'date-fns';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { BoletosContext } from '../../ContasReceberBoletos/BoletosContextProvider';
import { RemessasContext } from '../contextos';
import { useBaixarRemessas, useTratarResposta } from '../hooks';

import ButtonGerarRemessas from './ButtonGerarRemessas';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';
import LoadingPageBoletos from './LoadingBoletos';

type ButtonEmitirCobrancasComRelatorioProps = {
	text?: string;
	erroSemRecebimentoTitle?: string;
	errorsValidacaoTitle?: string;
};

export const ButtonEmitirCobrancasComRelatorio: React.FC<ButtonEmitirCobrancasComRelatorioProps> = ({
	text = 'Gerar',
	erroSemRecebimentoTitle = 'Boletos só podem ser emitidos pela API do Sicredi caso eles não estejam já vencidos',
	errorsValidacaoTitle = 'Inconsistências na Geração de Boletos',
}) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados } = useContext(RemessasContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		boletoConfigIsValid,
		opcoesImpressao,
		datasPrestacao,
		dataEnvioBackendDevedores,
		modeloDemonstrativo,
		comunicado,
		datasDemonstrativoMensal,
		comunicadoId,
		senha,
	} = useContext(BoletosContext);
	const modeloDemonstrativoId = modeloDemonstrativo;
	const parametrosPayloadExtras = React.useMemo(
		() => ({
			...opcoesImpressao,
			...datasPrestacao,
			...dataEnvioBackendDevedores,
			...datasDemonstrativoMensal,
			modelo_demonstrativo: modeloDemonstrativoId,
			comunicado_text: comunicado,
			comunicado_id: comunicadoId,
			pwd: senha,
		}),
		[
			modeloDemonstrativoId,
			opcoesImpressao,
			datasPrestacao,
			dataEnvioBackendDevedores,
			comunicado,
			datasDemonstrativoMensal,
			comunicadoId,
			senha,
		]
	);

	const [loadingRequest, setLoadingRequest] = useState(false);
	const [valid, setValid] = useState(true);
	const tratarResposta = useTratarResposta({
		setModalValue,
		ButtonGerarRemessas: ButtonGerarRemessas,
		erroSemRecebimentoTitle,
		errorsValidacaoTitle,
	});
	const condominioSelecionadoId = condominioSelecionado?.id;

	const baixarRemessas = useBaixarRemessas({
		idsRegistrosSelecionados,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		onSuccessCallback: tratarResposta,
		parametrosPayloadExtras,
	});

	useEffect(() => {
		setValid(
			Boolean(
				idsRegistrosSelecionados &&
					idsRegistrosSelecionados.length &&
					id_identificacao_arrecadacao &&
					contaBDestinoId &&
					condominioSelecionadoId &&
					referencia &&
					isMatch(referencia, 'yyyy-MM-dd') &&
					boletoConfigIsValid &&
					senha
			)
		);
	}, [
		setValid,
		idsRegistrosSelecionados,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		referencia,
		boletoConfigIsValid,
		senha,
	]);

	const handleClick = useCallback(async () => {
		setLoadingRequest(true);
		await baixarRemessas();
		setLoadingRequest(false);
	}, [setLoadingRequest, baixarRemessas]);

	return (
		<>
			<ButtonConfirmV2 onClick={handleClick} disabled={loadingRequest || !valid}>
				{text}
			</ButtonConfirmV2>
			<LoadingPageBoletos loading={loadingRequest} />
		</>
	);
};
