import React from 'react';
import { CustomDialog, ModalContextProvider } from '../ModalContext';

import TituloSelect from './components/TituloSelect';
import { ITituloModalFieldProps } from './types';
import TitulosContextProvider from './components/TituloModalContextProvider';
import { Box, LinearProgress } from '@material-ui/core';

const TituloModalField: React.FC<ITituloModalFieldProps> = ({
	setTituloRelatorio,
	tituloRelatorio,
	loading = false,
	...props
}) => {
	if (loading)
		return (
			<Box mb={5}>
				<LinearProgress />
			</Box>
		);

	return (
		<ModalContextProvider>
			<TitulosContextProvider setTituloRelatorio={setTituloRelatorio} tituloRelatorio={tituloRelatorio}>
				<TituloSelect {...props} />
				<CustomDialog />
			</TitulosContextProvider>
		</ModalContextProvider>
	);
};

export default TituloModalField;
