import React, { useCallback, useContext, useEffect } from 'react';

import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';
import InputDateReferencia from '../../componentes/InputDateReferencia';
import dataProvider from '../../../../../../dataProvider';

const InputReferencia = ({ disabled, style = {} }) => {
	const { id_leitura, referencia, setDadosConfig, setReferencia } = useContext(EditaConfiguracaoLeituraContext);
	const fetchConfigLeitura = useCallback(() => {
		dataProvider
			.getList('config-leitura', {
				pagination: {
					perPage: 1,
					page: 1,
				},
				sort: { order: 'DESC', field: 'referencia' },
				filter: {
					id_leitura: id_leitura,
					referencia_config: referencia.toISOString().split('T')[0],
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data?.length && data[0].id) setDadosConfig(data[0]);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return undefined;
			});
	}, [id_leitura, referencia, setDadosConfig]);
	useEffect(fetchConfigLeitura, [referencia]);

	const handleChangeRef = useCallback(
		(newvalue) => {
			if (
				newvalue &&
				newvalue.toString() !== 'Invalid Date' &&
				referencia.toISOString() !== newvalue.toISOString()
			)
				setReferencia(newvalue);
		},
		[referencia, setReferencia]
	);

	return (
		<InputDateReferencia
			disabled={disabled}
			handleChange={handleChangeRef}
			initialFocusedDate={referencia}
			label='Referência'
			style={{ marginRight: '0.5em', ...style }}
			value={referencia}
		/>
	);
};

export default InputReferencia;
