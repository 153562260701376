import { styled } from '@material-ui/core/styles';

export const CalendarioWrapper = styled('div')(({ theme }) => ({
	position: 'relative',
	minHeight: '100%',
	gap: '5px',
	userSelect: 'none',
	width: 'unset !important',
	maxWidth: 'unset !important',
	maxHeight: 'unset !important',
	textTransform: 'capitalize',

	// Retirando transition do componente quando alterado o mes.
	'& *': {
		transition: 'unset !important',
	},

	/* Linha de seleção mês */
	'& > div:first-child': {
		backgroundColor: theme.secondary[300],
		borderRadius: '5px 5px 0 0',
		margin: '0px !important',
		padding: '10px 0px 10px 0px !important',
		maxHeight: 'unset',

		'& > p': {
			fontSize: 20,
		},
		// Mes/Ano e button troca mes
		'& div:first-child ': {
			margin: '15px 0px 15px 0px',
			maxHeight: 'unset !important',
			'& div': {
				padding: '5px',
				'& > p': {
					fontSize: '20px',
					color: theme.font[400],
				},
			},
			'& button': {
				backgroundColor: 'unset',
				fill: 'none',
				margin: '0px 25px 0px 25px',
				'& > span > svg': {
					color: theme.font[400],
				},
			},
		},
		// Dias da semana
		'& div:last-child ': {
			maxHeight: 'unset !important',
			'& span': {
				flex: 1,
				color: theme.font[400],
				fontSize: 14,
				margin: 0,
				padding: 0,
			},
		},
	},
	/* ! Linha de seleção mês */

	/* Linhas de dias do calendario */
	'& > div:last-child': {
		backgroundColor: theme.fundoMenu[100],
		margin: 0,

		'& div': {
			position: 'unset !important',

			'& div *': {
				flex: 1,
				display: 'flex',
			},
		},
	},
	/* ! Linhas de dias do calendario */
}));
