import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import NovaLeituraContext from './NovaLeituraContext';

const MetodoCalculoField = () => {
	const {
		dadosNovaLeitura: {
			dados_config: { metodo_calculo },
		},
	} = useContext(NovaLeituraContext);

	return (
		<TextField
			disabled
			fullWidth
			label='Método utilizado para o cálculo'
			margin='dense'
			style={{ marginRight: '0.5em' }}
			value={metodo_calculo == 4 ? 'Percentual' : ''}
		/>
	);
};

export default MetodoCalculoField;
