import React from 'react';
import { useGetOne } from 'react-admin';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { NumberFormatBRL } from 'components/common/CurrencyInput';
import { CustomDialogBody } from 'components/common/ModalContext';

const ModalDetalhamentosLancamentosDespesaArrecadacao = (props) => {
	const { data: DadosTeste } = useGetOne('lancamento_despesa', props?.id);

	return (
		<CustomDialogBody
			title={<span>Detalhe dos Lançamentos</span>}
			form={{
				component: (
					<TableContainer>
						<Table size='small'>
							<TableHead>
								<TableRow>
									<TableCell>Conta Despesa</TableCell>
									<TableCell>Descrição</TableCell>
									<TableCell>Valor</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{DadosTeste?.detalhamentos_lancamentos_despesa?.map((detalhamento) => {
									return (
										<TableRow hover tabIndex={-1}>
											<TableCell>{detalhamento?.conta_despesa?.nome}</TableCell>
											<TableCell>{detalhamento?.conta_despesa?.descricao}</TableCell>
											<TableCell>
												<NumberFormatBRL
													displayType='text'
													value={detalhamento?.valor}
													fixedDecimalScale
													onChange={() => {}}
													decimalScale={2}
													prefix='R$'
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				),
			}}
		/>
	);
};

export default ModalDetalhamentosLancamentosDespesaArrecadacao;
