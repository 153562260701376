import React, { useContext } from 'react';
import NovaLeituraContext from './NovaLeituraContext';
import TextField from '@material-ui/core/TextField';

const CampoReceitaField = () => {
	const { dadosNovaLeitura } = useContext(NovaLeituraContext);
	return (
		<TextField
			disabled
			fullWidth
			label='Conta da Receita'
			value={dadosNovaLeitura.nome}
			style={{ float: 'right', width: '70%' }}
			margin='dense'
		/>
	);
};

export default CampoReceitaField;
