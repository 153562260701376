import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

import { Form } from 'react-final-form';

import { Box, Tab } from '@material-ui/core';

import { TabContext, TabList } from '@material-ui/lab';

import { CustomDialogBodySizeEditable } from '../../../common/ModalSizeEditableContext';
import SyndkosTabPanel from '../../../common/TabPanel/SyndkosTabPanel';
import EditaContaBancariaButtonsContextProvider from './providers/ButtonsProvider';
import ActionButtons from './buttons';
import EditaContaBancariaContext from './contexts/EditaContaBancariaContext';
import DadosPorTipoIntegracaoContextProvider from './providers/DadosPorTipoIntegracaoContextProvider';
import DadosPorTipoIntegracaoContext from './contexts/DadosPorTipoIntegracaoContext';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const EditaContaBancariaTabListContext = createContext();

const EditaContaBancariaTabListProvider = ({ values, children }) => {
	const { TipoContaContext } = useContext(EditaContaBancariaContext);
	const { getDisabledTabs } = useContext(TipoContaContext);

	const reducer = useCallback((_, values) => getDisabledTabs({ values }), [getDisabledTabs]);

	const [
		{
			disabled0 = true,
			disabledPagamentoViaSistema = true,
			disabled1 = true,
			disabled2 = true,
			disabled3 = true,
			disabled4 = true,
		},
		dipatch,
	] = useReducer(reducer, getDisabledTabs({ values }));

	React.useEffect(() => dipatch(values), [values]);

	const [tabValue, setTabValue] = React.useState('0');
	const handleChange = useCallback((_, newValue) => {
		setTabValue(newValue);
	}, []);

	const value = useMemo(
		() => ({
			value: tabValue,
			handleChange,
			disabled0,
			disabledPagamentoViaSistema,
			disabled1,
			disabled2,
			disabled3,
			disabled4,
		}),
		[tabValue, handleChange, disabled0, disabledPagamentoViaSistema, disabled1, disabled2, disabled3, disabled4]
	);

	return (
		<EditaContaBancariaTabListContext.Provider value={value}>{children}</EditaContaBancariaTabListContext.Provider>
	);
};

const EditaContaBancariaTabList = () => {
	const {
		tabContents: {
			TabInformacao,
			TabEmissaoBoletos,
			TabConta,
			TabInstrucoesCobrancaRemessa,
			TabInstrucoesCobrancaBoleto,
			TabPagarBoletosViaSistema,
		},
	} = useContext(DadosPorTipoIntegracaoContext);
	const {
		value,
		handleChange,
		disabled0 = true,
		disabledPagamentoViaSistema = true,
		disabled1 = true,
		disabled2 = true,
		disabled3 = true,
		disabled4 = true,
	} = useContext(EditaContaBancariaTabListContext);

	return (
		<TabContext value={value}>
			<Box width={1} display='grid' gridRowGap='0.5rem'>
				<Box display='flex'>
					<TabList value={value} onChange={handleChange} aria-label=''>
						<Tab {...a11yProps(0)} value='0' label='Informações' disabled={disabled0} />
						<Tab
							{...a11yProps(5)}
							value='5'
							label='Pagamentos via Sistema'
							disabled={disabledPagamentoViaSistema}
						/>
						<Tab {...a11yProps(1)} value='1' label='Emissão de Boletos' disabled={disabled1} />
						<Tab {...a11yProps(2)} value='2' label='Conta' disabled={disabled2} />
						<Tab {...a11yProps(3)} value='3' label='Instruções de cobrança remessa' disabled={disabled3} />
						<Tab {...a11yProps(4)} value='4' label='Instruções de cobrança boleto' disabled={disabled4} />
					</TabList>
				</Box>
				<SyndkosTabPanel value={value} index={'0'}>
					{!disabled0 && TabInformacao && <TabInformacao />}
				</SyndkosTabPanel>
				<SyndkosTabPanel value={value} index={'1'}>
					{!disabled1 && TabEmissaoBoletos && <TabEmissaoBoletos />}
				</SyndkosTabPanel>
				<SyndkosTabPanel value={value} index={'2'}>
					{!disabled2 && TabConta && <TabConta />}
				</SyndkosTabPanel>
				<SyndkosTabPanel value={value} index={'3'}>
					{!disabled3 && TabInstrucoesCobrancaRemessa && <TabInstrucoesCobrancaRemessa />}
				</SyndkosTabPanel>
				<SyndkosTabPanel value={value} index={'4'}>
					{!disabled4 && TabInstrucoesCobrancaBoleto && <TabInstrucoesCobrancaBoleto />}
				</SyndkosTabPanel>
				<SyndkosTabPanel value={value} index={'5'}>
					{!disabledPagamentoViaSistema && TabPagarBoletosViaSistema && <TabPagarBoletosViaSistema />}
				</SyndkosTabPanel>
			</Box>
		</TabContext>
	);
};

const EditaContaBancariaFormulario = () => {
	const { TipoContaContext } = useContext(EditaContaBancariaContext);
	const { initialValues, validate } = useContext(TipoContaContext);
	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			initialValues={initialValues}
			validate={validate}
			render={({ values }) => (
				<CustomDialogBodySizeEditable
					title='Alteração de Conta Bancária'
					form={{
						component: (
							<DadosPorTipoIntegracaoContextProvider>
								<EditaContaBancariaTabListProvider values={values}>
									<EditaContaBancariaTabList />
								</EditaContaBancariaTabListProvider>
							</DadosPorTipoIntegracaoContextProvider>
						),
					}}
					customActions={
						<EditaContaBancariaButtonsContextProvider>
							<ActionButtons />
						</EditaContaBancariaButtonsContextProvider>
					}
				/>
			)}
		/>
	);
};

export default EditaContaBancariaFormulario;
