import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

export const CheckboxRelatorioContext = React.createContext();

export const RelatoriosCheckbox = {
	//Pagamentos
	imprimir_total_geral: false,
	imprimir_total_by_unidade: false,
	imprimir_assinatura: false,
};

export const CheckboxRelatorioProvider = ({ children, tipoRelatorio }) => {
	const [opcoesImpressao, setOpcoesImpressao] = React.useState(RelatoriosCheckbox);
	const [loading, setLoading] = React.useState(false);

	const dp = useDataProvider();
	const notify = useNotify();

	const handleChangeOpcoesImpressao = React.useCallback(
		(checked, nameOpcao) => {
			setOpcoesImpressao((prevConfig) => ({
				...prevConfig,
				[nameOpcao]: checked,
			}));
		},
		[opcoesImpressao, setOpcoesImpressao]
	);

	const providerValue = React.useMemo(
		() => ({
			handleChangeOpcoesImpressao,
			loading,
			setLoading,
			opcoesImpressao,
			setOpcoesImpressao,
		}),
		[handleChangeOpcoesImpressao, loading, setLoading, opcoesImpressao, setOpcoesImpressao]
	);

	React.useEffect(() => {
		fetchConfigsCondominio();
	}, [tipoRelatorio]);

	function fetchConfigsCondominio() {
		const nomeRelatorio = tipoRelatorio;
		if (!nomeRelatorio) {
			setOpcoesImpressao(RelatoriosCheckbox);
			return;
		}
		setLoading(true);
		dp.getSimple('configuracoes_relatorios', {
			filter: { nome_relatorio: nomeRelatorio },
		})
			.then(({ data: { results } }) => {
				if (results.length > 0) {
					const configuracaoResultado = results[0].configuracoes_checkbox;
					const configuracaoRelatorio = { ...RelatoriosCheckbox, ...configuracaoResultado };
					setOpcoesImpressao(configuracaoRelatorio);
				} else {
					setOpcoesImpressao(RelatoriosCheckbox);
					notify('Ainda não há configurações cadastradas', 'info');
				}
			})
			.catch((e) => notify('Ainda não há configurações cadastradas', 'info'))
			.finally(() => setLoading(false));
	}

	return <CheckboxRelatorioContext.Provider value={providerValue}>{children}</CheckboxRelatorioContext.Provider>;
};
