import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Box, Typography } from '@material-ui/core';
import { useDataProvider, useGetList, useGetOne, useNotify, useUpdate } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import WarningIcon from '@material-ui/icons/Warning';
import { format, isBefore, isAfter, parseISO } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';

import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from '../../common/ModalContext';
import { MovimentacoesContext } from './MovimentacoesContext';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import { ContasContextProvider } from '../planosCondominio/contexts/ContasContext';
import { RawKBDatePicker } from '../../common/InputDate';
import InputFile from '../../common/InputFile';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../common/CurrencyInput';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ButtonRemove } from '../../common/buttons/ButtonRemove';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import { BotaoSalvar } from '../../common/buttons/BotaoSalvar';
import { ButtonClose } from '../../common/buttons/ButtonClose';
import BoxSelectContaPlano from './components/BoxSelectContaPlano';
import SelectContaBancaria from './components/SelectContaBancaria';
import { Situacao } from './Situacao';
import { createFormatsOptionsAutoCompleteComponent } from 'components/common/Selects/formats';

import InputAdornment from '@material-ui/core/InputAdornment';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { BoxDetalhamentosLancamentoDespesa } from './components/fluxoImportacaoExtratoBancario/modais/BoxDetalhamentosLancamentosDespesa';

const optionsTipoLancamento = [
	{ name: 'Condomínio', id: 'C' },
	{ name: 'Grupo', id: 'G' },
	{ name: 'Unidade', id: 'U' },
];

const formatsParaUnidades = createFormatsOptionsAutoCompleteComponent('formatsUnidades');
export const ModalDetailContext = createContext();

const SelectTipoLancamento = ({
	setValue,
	value,
	unidade,
	grupo,
	setGrupo,
	setUnidade,
	operacao,
	disabled,
	...rest
}) => {
	const { condominio } = useContext(MovimentacoesContext);
	const { data: unidadesData, ids: unidadesIds } = useGetList(
		'unidades',
		{ perPage: 10000, page: 1 },
		{ field: 'unidade', order: 'ASC' },
		{ id_condominio: condominio, situacao: 'A' }
	);
	const { data: gruposData, ids: gruposIds } = useGetList(
		'grupo_unidade_condominio',
		{ perPage: 10000, page: 1 },
		{ field: 'nome', order: 'ASC' },
		{ id_condominio: condominio }
	);
	const [optionsExtra, setOptionsExtra] = useState([]);
	const extraInput = useRef();
	const [renderReady, setRenderReady] = useState(false);

	const setOptions = () => {
		if (value === 'G' && gruposData) {
			setOptionsExtra(gruposIds.map((id) => gruposData[id]));
		} else if (value === 'U' && unidadesData) {
			setOptionsExtra(unidadesIds.map((id) => unidadesData[id]).filter((v) => v.situacao === 'A'));
		}
	};

	useEffect(setOptions, [gruposData, unidadesData, value, grupo, unidade]);

	const delayRender = () => {
		setTimeout(() => setRenderReady(true), 100);
	};

	useEffect(delayRender, []);

	return (
		<>
			<Autocomplete
				options={optionsTipoLancamento}
				renderInput={(params) => <TextField {...params} margin='dense' label='Tipo de Lançamento' />}
				getOptionLabel={(option) =>
					option && (option.name || optionsTipoLancamento.filter((v) => v.id === option)[0].name)
				}
				noOptionsText='Nenhum resultado encontrado'
				getOptionSelected={(option, v) => v === option.id}
				value={value}
				onChange={(_, v) => setValue(v ? v.id : null)}
				clearText='Limpar'
				getOptionDisabled={(option) => option?.id === 'U' && operacao?.tipo !== 'receita'}
				disabled={disabled}
				{...rest}
			/>
			{(value === 'U' || value === 'G') && optionsExtra && renderReady && (
				<Autocomplete
					options={optionsExtra}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='dense'
							label={value === 'U' ? 'Unidade' : 'Grupo'}
							inputRef={extraInput}
						/>
					)}
					noOptionsText='Nenhum resultado encontrado'
					value={(value === 'U' ? unidade : grupo) || null}
					getOptionSelected={(option, v) => v === option.id}
					getOptionLabel={(option) => {
						const op =
							option && option?.[value === 'U' ? 'unidade' : 'nome']
								? option
								: optionsExtra.find((v) => v.id === option);
						return (value === 'U' ? formatsParaUnidades.formatOption(op) : op?.nome) || '';
					}}
					onChange={(_, v) => (value === 'U' ? setUnidade(v ? v.id : null) : setGrupo(v ? v.id : null))}
					clearText='Limpar'
					disabled={disabled}
				/>
			)}
		</>
	);
};

const FornecedorSelect = ({ fornecedorId, setFornecedorId, fornecedoresData }) =>
	fornecedoresData && (
		<Autocomplete
			variant='outlined'
			value={fornecedorId}
			onChange={(_, newValue) => {
				setFornecedorId(newValue);
			}}
			style={{ marginRight: '1em' }}
			size='small'
			options={fornecedoresData}
			getOptionLabel={(o) => o?.nome || ''}
			getOptionSelected={(option, newValue) => {
				return newValue?.id === option.id;
			}}
			renderInput={(params) => {
				return <TextField {...params} variant='outlined' label='Fornecedor' />;
			}}
			renderOption={(option) => option?.nome || ''}
			fullWidth
		/>
	);
const ConfirmacaoRemover = ({ handleSubmit, descricao }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBody
			Context={ModalDetailContext}
			title={`Tem certeza que deseja remover ${descricao}?`}
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
							Essa ação é irreversível.
							<br />
							Todo o histórico será excluído.
						</Typography>
					</Box>
				</Box>
			}
			remove
			form={{ valid: true, handleSubmit }}
		/>
	);
};

const BotaoRemoverComponent = ({ deleteMov, descricao, disabled }) => {
	const { setModalValue: setDetail } = useContext(ModalDetailContext);
	const { setModalValue } = useContext(ModalContext);
	return (
		<ButtonRemove
			size='small'
			onClick={() =>
				setDetail((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ConfirmacaoRemover
							handleSubmit={() => {
								deleteMov();
								setModalValue((v) => ({ ...v, open: false }));
							}}
							descricao={descricao}
						/>
					),
				}))
			}
			disabled={disabled}
		>
			Remover
		</ButtonRemove>
	);
};

const BotaoRemoverMov = ({ deleteMov, descricao, disabled }) => (
	<ModalContextProvider Context={ModalDetailContext}>
		<BotaoRemoverComponent {...{ deleteMov, descricao, disabled }} />
		<CustomDialog Context={ModalDetailContext} />
	</ModalContextProvider>
);

const tipoCapitalized = (operacao) => {
	return operacao?.tipo.charAt(0).toUpperCase() + operacao?.tipo.slice(1);
};

const BoxDataValor = ({
	dataLancamento,
	setDataLancamento,
	operacao,
	valorTotal,
	valorLancamento,
	setValorLancamento,
	disabled,
}) => {
	const { contasData, id_conta_bancaria } = useContext(MovimentacoesContext);
	return (
		<Box display='flex' gridGap='10px'>
			<RawKBDatePicker
				fullWidth
				label='Data de Lançamento'
				variant='inline'
				margin='dense'
				onChange={(value) => setDataLancamento(value)}
				format='dd/MM/yyyy'
				value={dataLancamento}
				invalidDateMessage='Data inválida'
				autoOk
				minDate={parseISO(contasData[id_conta_bancaria]?.data_saldo_inicial)}
				minDateMessage='Data não pode ser anterior ao Saldo Inicial'
				maxDate={new Date().setHours(10, 0, 0)}
				maxDateMessage='Data da movimentação não pode ser posterior ao dia de hoje'
				disabled={disabled}
			/>
			{operacao === 'despesa' ? (
				<TextField
					label='Total'
					margin='dense'
					fullWidth
					value={valorTotal}
					onChange={(e) => setValorLancamento(e.target.value)}
					inputProps={sanitizeListNumberFormatBRLProps({
						thousandsGroupStyle: 'thousand',
						decimalScale: 2,
						prefix: 'R$ ',
					})}
					InputProps={{
						inputComponent: NumberFormatBRL,
					}}
					disabled={true}
				/>
			) : (
				<TextField
					label='Valor'
					margin='dense'
					fullWidth
					value={valorLancamento}
					onChange={(e) => setValorLancamento(e.target.value)}
					inputProps={sanitizeListNumberFormatBRLProps({})}
					InputProps={{
						inputComponent: NumberFormatBRL,
						startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
					}}
					disabled={disabled}
				/>
			)}
		</Box>
	);
};

const DescricaoInput = ({ descricao, setDescricao, disabled }) => (
	<TextField
		margin='dense'
		fullWidth
		label='Descrição'
		value={descricao}
		InputLabelProps={{ shrink: !!descricao }}
		onChange={(e) => setDescricao(e.target.value)}
		disabled={disabled}
	/>
);

const BotaoConciliar = ({ id, situacaoLanc, tipo_movimentacao, setSituacaoLanc }) => {
	const notify = useNotify();
	const { setData } = useContext(MovimentacoesContext);
	let endpointUpdate = '';

	switch (tipo_movimentacao) {
		case 'LR':
			endpointUpdate = 'lancamento_receita';
			break;
		case 'LD':
			endpointUpdate = 'lancamento_despesa';
			break;
		case 'TF':
			endpointUpdate = 'transferencia';
			break;
		default:
			break;
	}

	const [update] = useUpdate(endpointUpdate, id);

	const alterarSituacao = () => {
		update();
		notify('Situação alterada com sucesso');
		setData((d) =>
			d.map((mov) => {
				if (mov.id === id) {
					let newSituacao = mov.situacao === 'N' ? 'C' : 'N';
					setSituacaoLanc(newSituacao);
					return { ...mov, situacao: newSituacao };
				}
				return mov;
			})
		);
	};

	return (
		<Box display='flex'>
			<TooltipIconButton style={{ padding: 2 }} title='Alterar situação' onClick={alterarSituacao}>
				<AutorenewIcon style={{ width: 20, height: 20 }} />
			</TooltipIconButton>
			<Situacao situacao={situacaoLanc} />
		</Box>
	);
};

export const FormularioLancamento = ({
	tipo_lancamento = optionsTipoLancamento[0].id,
	tipo_movimentacao,
	id,
	historico,
	data,
	valor,
	operacao = {},
	situacao,
	copia,
	fornecedor,
	anexo_nf = null,
	anexo_boleto = null,
	anexo_comprovante_pagamento = null,
	anexo_ordem_compra_servico = null,
}) => {
	const { setModalValue } = useContext(ModalContext);
	const { data: lancamentoDados } = useGetOne(`lancamento_${operacao?.tipo}`, id);
	const [tipoLancamentoValue, setTipoLancamentoValue] = useState(tipo_lancamento);
	const [contaReceita, setContaReceita] = useState();
	const [detalhamentosLancamentosDespesa, setDetalhamentosLancamentosDespesa] = React.useState([]);
	const {
		condominio,
		contaBancaria: contaBancariaInicial,
		forceRefresh,
		setForceRefresh,
		contasBancarias,
		contasData,
	} = useContext(MovimentacoesContext);
	const [contaBancaria, setContaBancaria] = useState(contaBancariaInicial);
	const { data: condominioData } = useGetOne('condominios', condominio);
	const [descricao, setDescricao] = useState(historico);
	const [dataLancamento, setDataLancamento] = useState(
		copia ? null : parseISO(data || format(new Date().setHours(10, 0, 0), 'yyyy-MM-dd'))
	);
	const [valorLancamento, setValorLancamento] = useState(valor);
	const [valorTotal, setValorTotal] = React.useState();
	const [valid, setValid] = useState(false);
	const processingRef = useRef(false);
	const [unidade, setUnidade] = useState();
	const [situacaoLanc, setSituacaoLanc] = useState(situacao);
	const [grupo, setGrupo] = useState();
	const [anexoNF, setAnexoNF] = useState(anexo_nf);
	const [anexoBoleto, setAnexoBoleto] = useState(anexo_boleto);
	const [anexoComprovantePagamento, setAnexoComprovantePagamento] = useState(anexo_comprovante_pagamento);
	const [anexoOrdemCompraServico, setAnexoOrdemCompraServico] = useState(anexo_ordem_compra_servico);
	const [initialValues, setInitialValues] = React.useState();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [somaTotal, setSomaTotal] = React.useState(false);

	const init = () => {
		if (operacao?.tipo === 'despesa') {
			dP.getList('fornecedores', {
				pagination: { perPage: 1000, page: 1 },
				sort: { field: 'id', order: 'ASC' },
				filter: { situacao: 'A' },
			}).then((response) => {
				const data = (response?.data || []).filter((f) => !!f.id);
				if (data?.length) {
					if (fornecedor) {
						setFornecedorId(data.find((f) => f.id === fornecedor) || data[0] || null);
					}
					setFornecedoresData(data);
				}
			});
		}
	};
	const dP = useDataProvider();
	const [fornecedoresData, setFornecedoresData] = useState([]);
	const [fornecedorId, setFornecedorId] = useState(null);
	useEffect(init, [dP]);

	const ResetFieldsCB = useCallback(() => {
		setValorLancamento('');
		setDescricao('');
		setContaReceita(undefined);
		setSituacaoLanc(undefined);
		setUnidade(null);
		setGrupo(null);
		setTipoLancamentoValue(optionsTipoLancamento[0].id);
		setContaBancaria(contaBancariaInicial);
		setAnexoNF(null);
		setAnexoBoleto(null);
		setAnexoComprovantePagamento(null);
		setAnexoOrdemCompraServico(null);
		setDetalhamentosLancamentosDespesa(undefined);
	}, [
		setValorLancamento,
		setDescricao,
		setContaReceita,
		setSituacaoLanc,
		setUnidade,
		setGrupo,
		setTipoLancamentoValue,
		setContaBancaria,
		setAnexoNF,
		setAnexoBoleto,
		setAnexoComprovantePagamento,
		setAnexoOrdemCompraServico,
		setDetalhamentosLancamentosDespesa,
		contaBancariaInicial,
	]);

	const setConta = () => {
		if (id) {
			dataProvider.getOne(`lancamento_${operacao?.tipo}`, { id }).then((response) => {
				const data = response?.data;
				if (data) {
					setContaReceita({
						id: data[`conta_${operacao?.tipo}`],
						nome: data[`nome_conta_${operacao?.tipo}`],
					});
					if (data.unidade) {
						setUnidade(data.unidade);
					} else if (data.grupo) {
						setGrupo(data.grupo);
					}
				}
			});
		}
	};

	useEffect(setConta, [id]);

	const setDescricaoAutomatica = () => {
		if (operacao?.acao === 'cadastrar' && contaReceita?.nome) {
			setDescricao(`Lançamento de ${tipoCapitalized(operacao)} ${contaReceita?.nome}`);
		}
		if (
			operacao?.acao === 'cadastrar' &&
			detalhamentosLancamentosDespesa?.find((detalhamento) => detalhamento.conta_despesa?.nome)
		) {
			setDescricao(
				`Lançamento de ${tipoCapitalized(operacao)} ${
					detalhamentosLancamentosDespesa[0]?.conta_despesa?.nome || ''
				}`
			);
		}
	};

	useEffect(setDescricaoAutomatica, [contaReceita, detalhamentosLancamentosDespesa]);

	React.useEffect(() => {
		const somaTotalDetalhamento = detalhamentosLancamentosDespesa?.reduce((acum, obj) => {
			return parseFloat(acum) + parseFloat(obj.valor);
		}, 0);
		lancamentoDados?.['origem'] === 'EB'
			? setValorTotal(lancamentoDados?.['valor'])
			: setValorTotal(somaTotalDetalhamento);

		setSomaTotal(
			lancamentoDados?.['origem'] === 'EB' && somaTotalDetalhamento !== parseFloat(lancamentoDados?.['valor'])
				? true
				: false
		);
	}, [detalhamentosLancamentosDespesa]);

	const validate = () => {
		if (
			!valorLancamento ||
			!tipoLancamentoValue ||
			!descricao ||
			!dataLancamento ||
			dataLancamento.toString() === 'Invalid Date' ||
			isBefore(dataLancamento, parseISO(contaBancariaInicial?.data_saldo_inicial)) ||
			isAfter(dataLancamento, new Date().setHours(23, 0, 0)) ||
			!contaBancaria?.id ||
			(operacao?.tipo === 'receita' && !contaReceita?.id) ||
			(operacao?.tipo === 'despesa' &&
				!!detalhamentosLancamentosDespesa?.find((detalhamento) => detalhamento?.conta_despesa === undefined)) ||
			(tipoLancamentoValue === 'U' && !unidade) ||
			(tipoLancamentoValue === 'G' && !grupo) ||
			(operacao?.acao === 'editar' &&
				tipoLancamentoValue === tipo_lancamento &&
				lancamentoDados?.['conta_receita'] === contaReceita?.id &&
				lancamentoDados?.['detalhamentos_lancamentos_despesa'] === detalhamentosLancamentosDespesa &&
				contaBancaria?.id === contaBancariaInicial?.id &&
				Number(valorLancamento) === Number(valor) &&
				historico === descricao &&
				data === format(dataLancamento, 'yyyy-MM-dd') &&
				(fornecedorId?.id || null) === fornecedor &&
				anexoNF === anexo_nf &&
				anexoBoleto === anexo_boleto &&
				anexoComprovantePagamento === anexo_comprovante_pagamento &&
				anexoOrdemCompraServico === anexo_ordem_compra_servico)
		) {
			setValid(false);
		} else {
			setValid(true);
		}
	};

	useEffect(validate, [
		tipoLancamentoValue,
		contaReceita,
		contaBancaria,
		descricao,
		dataLancamento,
		valorLancamento,
		unidade,
		grupo,
		data,
		historico,
		tipo_lancamento,
		valor,
		fornecedorId,
		anexoNF,
		anexo_nf,
		anexoBoleto,
		anexo_boleto,
		anexoComprovantePagamento,
		anexo_comprovante_pagamento,
		anexoOrdemCompraServico,
		anexo_ordem_compra_servico,
		detalhamentosLancamentosDespesa,
	]);

	const reqData = () => {
		let req = {
			tipo_lancamento: tipoLancamentoValue,
			valor: valorLancamento,
			historico: descricao,
			data_movimentacao: format(dataLancamento, 'yyyy-MM-dd'),
			[operacao?.tipo === 'despesa' ? 'conta_b_origem' : 'conta_b_destino']: contaBancaria?.id,
			[`conta_${operacao?.tipo}`]: contaReceita?.id,
			condominio,
			fornecedor: fornecedorId?.id,
		};
		if (tipoLancamentoValue === 'U') {
			req.unidade = unidade;
		} else if (tipoLancamentoValue === 'G') {
			req.grupo = grupo;
		}
		if (operacao?.tipo === 'despesa') {
			const get_anexo = (anexo) => (anexo ? (anexo.includes(';base64,') ? anexo : '') : null);
			req.valor = parseFloat(valorTotal).toFixed(2);
			req['detalhamentos_lancamentos_despesa'] = detalhamentosLancamentosDespesa.map((detalhamento) => ({
				conta_despesa: detalhamento.conta_despesa.id,
				descricao: detalhamento.conta_despesa.nome,
				valor: detalhamento.valor,
			}));
			req['write_only_anexo_nf'] = get_anexo(anexoNF);
			req['write_only_anexo_boleto'] = get_anexo(anexoBoleto);
			req['write_only_anexo_comprovante_pagamento'] = get_anexo(anexoComprovantePagamento);
			req['write_only_anexo_ordem_compra_servico'] = get_anexo(anexoOrdemCompraServico);
		}
		return req;
	};

	const updateMov = (modalValue) => {
		if (!processingRef.current) {
			processingRef.current = true;
			dataProvider
				.updateWithPut(`lancamento_${operacao?.tipo}`, {
					id,
					data: reqData(),
				})
				.then(() => {
					notify('Lançamento alterado com sucesso');
					setModalValue((v) => ({ ...v, open: false, ...modalValue }));
					if (modalValue) ResetFieldsCB();
					setForceRefresh(true);
				})
				.finally(() => {
					processingRef.current = false;
				});
		}
	};

	const createMov = (modalValue) => {
		if (!processingRef.current) {
			processingRef.current = true;
			dataProvider
				.create(`lancamento_${operacao?.tipo}`, {
					data: reqData(),
				})
				.then(() => {
					notify('Lançamento cadastrado com sucesso');
					setModalValue((v) => ({ ...v, open: false, ...modalValue }));
					if (modalValue) ResetFieldsCB();
					setForceRefresh(true);
					setInitialValues({
						detalhamentos_lancamentos_despesa: [],
					});
				})
				.finally(() => {
					processingRef.current = false;
				});
		}
	};

	const deleteMov = () => {
		if (!processingRef.current) {
			processingRef.current = true;
			dataProvider
				.delete(`lancamento_${operacao?.tipo}`, {
					id,
				})
				.then(() => {
					notify('Lançamento removido com sucesso');
					setForceRefresh(true);
				})
				.finally(() => {
					processingRef.current = false;
				});
		}
	};

	const acoes =
		situacaoLanc !== 'C' ? (
			[
				<ButtonCancel
					onClick={() => setModalValue((v) => ({ ...v, open: false }))}
					disabled={processingRef.current}
				>
					Cancelar
				</ButtonCancel>,
				operacao?.acao === 'editar' && (
					<BotaoRemoverMov
						{...{ deleteMov, descricao }}
						disabled={processingRef.current || situacaoLanc === 'C'}
					/>
				),
				operacao?.acao !== 'editar' && (
					<ButtonConfirm
						disabled={processingRef.current || !valid}
						size='small'
						onClick={() => {
							(operacao?.acao === 'editar' ? updateMov : createMov)({
								open: true,
								dialogBody: (
									<FormularioLancamento
										operacao={{
											tipo: operacao?.tipo,
											acao: 'cadastrar',
										}}
									/>
								),
							});
						}}
						startIcon={<AddIcon />}
					>
						Lançar e Novo
					</ButtonConfirm>
				),
				<ButtonConfirm
					disabled={processingRef.current || !valid || somaTotal}
					size='small'
					onClick={operacao?.acao === 'editar' ? updateMov : createMov}
				>
					{operacao?.acao === 'editar' ? 'Salvar' : 'Lançar'}
				</ButtonConfirm>,
			]
		) : (
			<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonClose>
		);

	React.useEffect(() => {
		operacao?.acao === 'editar' || copia
			? setInitialValues({
					detalhamentos_lancamentos_despesa: lancamentoDados?.detalhamentos_lancamentos_despesa?.map(
						(detalhamento) => ({
							conta_despesa: { id: detalhamento.conta_despesa.id, nome: detalhamento.conta_despesa.nome },
							valor: detalhamento?.valor,
						})
					),
			  })
			: setInitialValues({
					detalhamentos_lancamentos_despesa: [
						{
							conta_despesa: undefined,
							valor: undefined,
						},
					],
			  });
	}, [lancamentoDados, forceRefresh]);
	return (
		<Box width='44rem'>
			<CustomDialogBody
				title={`${operacao?.acao === 'editar' ? 'Edição' : 'Lançamento'} de ${operacao?.tipo}`}
				customActions={
					operacao?.acao === 'editar' ? (
						<Box
							width='100%'
							display='flex'
							justifyContent='space-between'
							alignItems='center'
							margin='5px'
						>
							<BotaoConciliar
								{...{
									id,
									situacaoLanc,
									tipo_movimentacao,
									setSituacaoLanc,
								}}
							/>
							<Box display='flex' gridGap='10px'>
								{acoes}
							</Box>
						</Box>
					) : (
						acoes
					)
				}
				form={{
					component: (
						<ContasContextProvider id={condominioData?.id_plano_condominio}>
							<ModalContextProvider Context={ModalDetailContext}>
								<Box display='flex' flexDirection='column' gridGap='15px'>
									<SelectTipoLancamento
										value={tipoLancamentoValue}
										setValue={setTipoLancamentoValue}
										{...{
											unidade,
											setUnidade,
											grupo,
											setGrupo,
											operacao,
										}}
										disabled={situacaoLanc === 'C'}
									/>

									{operacao?.tipo === 'despesa' && (
										<FornecedorSelect {...{ fornecedorId, fornecedoresData, setFornecedorId }} />
									)}
									{operacao?.tipo === 'despesa' ? (
										<Form
											onSubmit={(e) => {
												e.preventDefault();
											}}
											mutators={{ ...arrayMutators }}
											initialValues={initialValues}
											render={({ values: { detalhamentos_lancamentos_despesa }, invalid }) => (
												<>
													<BoxDetalhamentosLancamentoDespesa
														origem={lancamentoDados?.['origem']}
														valorTotal={valorTotal}
														detalhamentosLancamentosDespesa={
															detalhamentosLancamentosDespesa
														}
														setDetalhamentosLancamentosDespesa={
															setDetalhamentosLancamentosDespesa
														}
														ModalDetailContext={ModalDetailContext}
														disabled={situacaoLanc === 'C'}
													/>
												</>
											)}
										/>
									) : (
										<BoxSelectContaPlano
											selectedConta={contaReceita}
											setSelectedConta={setContaReceita}
											{...{ operacao }}
											disabled={situacaoLanc === 'C'}
											ModalDetailContext={ModalDetailContext}
										/>
									)}

									<SelectContaBancaria
										label={operacao?.tipo === 'despesa' ? 'Pagar com' : 'Receber em'}
										setValue={setContaBancaria}
										value={contaBancaria}
										disabled={situacaoLanc === 'C'}
										options={contasBancarias}
										contasData={contasData}
									/>
									<BoxDataValor
										{...{
											dataLancamento,
											setDataLancamento,
											operacao: operacao?.tipo,
											valorTotal,
											valorLancamento,
											setValorLancamento,
										}}
										disabled={situacaoLanc === 'C'}
									/>
									<DescricaoInput {...{ descricao, setDescricao }} disabled={situacaoLanc === 'C'} />
									{operacao?.tipo === 'despesa' && (
										<InputFile
											label='Ordem de Compra/Serviço'
											onCallBack={(e, dataUrl) => setAnexoOrdemCompraServico(dataUrl)}
											fileDataUrl={anexoOrdemCompraServico}
											disabled={situacaoLanc === 'C'}
											reusedField={true}
										/>
									)}
									{operacao?.tipo === 'despesa' && (
										<InputFile
											label='Nota Fiscal'
											onCallBack={(e, dataUrl) => setAnexoNF(dataUrl)}
											fileDataUrl={anexoNF}
											disabled={situacaoLanc === 'C'}
											reusedField={true}
										/>
									)}
									{operacao?.tipo === 'despesa' && (
										<InputFile
											label='Boleto'
											onCallBack={(e, dataUrl) => setAnexoBoleto(dataUrl)}
											fileDataUrl={anexoBoleto}
											disabled={situacaoLanc === 'C'}
											reusedField={true}
										/>
									)}
									{operacao?.tipo === 'despesa' && (
										<InputFile
											label='Comprovante de Pagamento'
											onCallBack={(e, dataUrl) => setAnexoComprovantePagamento(dataUrl)}
											fileDataUrl={anexoComprovantePagamento}
											disabled={situacaoLanc === 'C'}
											reusedField={true}
										/>
									)}
								</Box>
								<CustomDialog Context={ModalDetailContext} />
							</ModalContextProvider>
						</ContasContextProvider>
					),
				}}
			/>
		</Box>
	);
};

export const FormularioTransferencia = ({
	id,
	data: dataOriginal,
	valor: valorOriginal,
	historico,
	conta_b_origem,
	conta_b_destino,
	tipo_lancamento = optionsTipoLancamento[0].id,
	situacao,
	copia,
}) => {
	const { setModalValue } = useContext(ModalContext);
	const {
		contaBancaria: contaBancariaInicial,
		contasBancarias,
		contasData,
		condominio,
		setForceRefresh,
	} = useContext(MovimentacoesContext);
	const [origem, setOrigem] = useState((contasData || {})[conta_b_origem] || contaBancariaInicial);
	const [destino, setDestino] = useState((contasData || {})[conta_b_destino] || null);
	const [grupo, setGrupo] = useState();
	const [tipoLancamento, setTipoLancamento] = useState(tipo_lancamento);
	const [data, setData] = useState(
		copia ? null : parseISO(dataOriginal || format(new Date().setHours(10, 0, 0), 'yyyy-MM-dd'))
	);
	const [valor, setValor] = useState(valorOriginal);
	const [descricao, setDescricao] = useState(historico);
	const [situacaoLanc, setSituacaoLanc] = useState(situacao);
	const dataProvider = useDataProvider();
	const [valid, setValid] = useState(false);
	const processingRef = useRef(false);
	const notify = useNotify();

	const ResetFieldsCB = useCallback(() => {
		setSituacaoLanc(undefined);
		setValor('');
		setDescricao('');
		setTipoLancamento(optionsTipoLancamento[0].id);
		setGrupo(null);
		setDestino(null);
		setOrigem(contaBancariaInicial);
	}, [
		setSituacaoLanc,
		setValor,
		setDescricao,
		setTipoLancamento,
		setGrupo,
		setDestino,
		setOrigem,
		contaBancariaInicial,
	]);

	const setDescricaoAutomatica = () => {
		if (origem?.id && destino?.id) {
			setDescricao(`Transferência Origem: ${origem?.nome_conta || ''} - Destino: ${destino?.nome_conta || ''}`);
		}
	};

	const getGrupoFromMov = () => {
		if (id) {
			dataProvider.getOne('transferencia', { id }).then((response) => {
				const data = response?.data;
				if (data?.grupo) {
					setGrupo(data.grupo);
				}
			});
		}
	};

	useEffect(getGrupoFromMov, [id]);

	useEffect(setDescricaoAutomatica, [origem, destino]);

	const validate = () =>
		setValid(
			tipoLancamento &&
				(tipoLancamento === 'G' ? grupo : true) &&
				origem?.id &&
				destino?.id &&
				descricao &&
				valor &&
				data &&
				data?.toString() !== 'Invalid Date' &&
				!isBefore(data, parseISO(origem.data_saldo_inicial)) &&
				!isBefore(data, parseISO(destino.data_saldo_inicial)) &&
				!isAfter(data, new Date().setHours(23, 0, 0))
		);
	useEffect(validate, [origem, destino, descricao, valor, data, tipoLancamento, grupo]);

	const getReqData = () => {
		let req = {
			data_movimentacao: format(data, 'yyyy-MM-dd'),
			condominio,
			conta_b_origem: origem?.id,
			conta_b_destino: destino?.id,
			valor,
			historico: descricao,
			tipo_lancamento: tipoLancamento,
		};
		if (grupo) {
			req.grupo = grupo;
		}
		return req;
	};

	const handleSubmit = ({ remover, modalValue }) => {
		if (!processingRef.current) {
			processingRef.current = true;
			dataProvider[id ? (remover ? 'delete' : 'updateWithPut') : 'create']('transferencia', {
				data: getReqData(),
				id,
			})
				.then(() => {
					notify(`Transferência ${id ? 'excluída' : 'cadastrada'} com sucesso`);
					setModalValue((v) => ({ ...v, open: false, ...modalValue }));
					if (modalValue) ResetFieldsCB();
					setForceRefresh(true);
				})
				.finally(() => {
					processingRef.current = false;
				});
		}
	};

	return (
		<CustomDialogBody
			title='Transferência'
			customActions={
				situacaoLanc !== 'C' ? (
					[
						<ButtonCancel
							onClick={() => setModalValue((v) => ({ ...v, open: false }))}
							disabled={processingRef.current}
						>
							Cancelar
						</ButtonCancel>,
						id && (
							<BotaoRemoverMov
								{...{
									deleteMov: () => handleSubmit({ remover: true }),
									descricao,
								}}
								disabled={processingRef.current || situacaoLanc === 'C'}
							/>
						),
						!id && (
							<BotaoSalvar
								disabled={processingRef.current || !valid}
								size='small'
								onClick={() => {
									handleSubmit({
										modalValue: {
											open: true,
											dialogBody: <FormularioTransferencia id={undefined} />,
										},
									});
								}}
								startIcon={<AddIcon />}
							>
								Lançar e Novo
							</BotaoSalvar>
						),
						<ButtonConfirm disabled={processingRef.current || !valid} size='small' onClick={handleSubmit}>
							{id ? 'Salvar' : 'Lançar'}
						</ButtonConfirm>,
					]
				) : (
					<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonClose>
				)
			}
			form={{
				component: (
					<Box display='flex' flexDirection='column' gridGap='15px'>
						<SelectTipoLancamento
							{...{ grupo, setGrupo }}
							value={tipoLancamento}
							setValue={setTipoLancamento}
							disabled={situacaoLanc === 'C'}
							operacao={{ tipo: 'transferencia' }}
						/>
						<SelectContaBancaria
							value={origem}
							setValue={setOrigem}
							disabledConta={destino}
							label='Conta Origem'
							disabled={situacaoLanc === 'C'}
							options={contasBancarias}
							contasData={contasData}
						/>
						<SelectContaBancaria
							value={destino}
							setValue={setDestino}
							disabledConta={origem}
							label='Conta Destino'
							disabled={situacaoLanc === 'C'}
							options={contasBancarias}
							contasData={contasData}
						/>
						<BoxDataValor
							dataLancamento={data}
							setDataLancamento={setData}
							valorLancamento={valor}
							setValorLancamento={setValor}
							disabled={situacaoLanc === 'C'}
						/>
						<DescricaoInput {...{ descricao, setDescricao }} disabled={situacaoLanc === 'C'} />
					</Box>
				),
			}}
		/>
	);
};

export const ModalConfirmacaoExcluirLote = ({ ids }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { setForceRefresh, setIdsSelecionados } = useContext(MovimentacoesContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const handleSubmit = () => {
		dataProvider
			.deleteMany('movimentacao_bancaria', { ids: ids || [] })
			.then((responsesData) => {
				const responses = responsesData?.data || [];
				const firstRejectedResponse = responses.find((r) => r?.status === 'rejected');
				if (firstRejectedResponse) return Promise.reject(firstRejectedResponse.reason);
				const ExclusoesSucedidas = responses.filter((r) => r?.status === 'fulfilled');
				notify(
					ExclusoesSucedidas.length > 1
						? `${ExclusoesSucedidas.length} movimentações excluídas com sucesso`
						: '1 movimentação excluída com sucesso'
				);
				setIdsSelecionados([]);
				setForceRefresh(true);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || 'Erro inesperado, tente recarregar a página',
					'warning'
				);
			});
	};
	return (
		<CustomDialogBody
			customSubmitLabel='Excluir'
			form={{ handleSubmit, valid: true }}
			remove
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
							{`${(ids || []).length} ${
								(ids || []).length > 1 ? 'movimentações' : 'movimentação'
							} selecionada`}
							<br />
							Essa ação é irreversível.
							<br />
							Todo o histórico será excluído.
						</Typography>
					</Box>
				</Box>
			}
			title={`Tem certeza que deseja excluir ${(ids || []).length > 1 ? 'as movimentações' : 'a movimentação'}?`}
		/>
	);
};

export const FormularioSaldoInicial = ({ historico, data, valor: valorLancamento }) => {
	const { setModalValue } = useContext(ModalContext);
	const { condominio, contaBancaria: contaBancaria } = useContext(MovimentacoesContext);
	const { data: condominioData } = useGetOne('condominios', condominio);
	const dataLancamento = React.useMemo(
		() => parseISO(data || format(new Date().setHours(10, 0, 0), 'yyyy-MM-dd')),
		[data]
	);

	return (
		<CustomDialogBody
			title={'Edição de Saldo Inicial'}
			customActions={
				<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonClose>
			}
			form={{
				component: (
					<Box display='flex' flexDirection='column' gridGap='15px'>
						<TextField
							label='Nome da Conta Bancária'
							margin='dense'
							fullWidth
							value={contaBancaria.nome_conta}
							disabled={true}
						/>
						<BoxDataValor
							{...{
								dataLancamento,
								valorLancamento,
							}}
							disabled={true}
						/>
						<DescricaoInput {...{ historico }} disabled={true} />
					</Box>
				),
			}}
		/>
	);
};
