import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
	root: {
		position: 'relative',
		margin: '10px 0px 10px 0px',
		border: `1px solid ${theme.font[300]}`,

		'&:hover': {
			border: `1px solid ${theme.font[200]}`,
		},

		'& span': {
			'font-size': '16px',
		},

		'& > label': {
			position: 'absolute',
			top: 0,
			left: 0,
			backgroundColor: theme.overrides.MUIRichTextEditor.labelInputEmail.backgroundColor,
			padding: '0 3px',
			transform: 'translate(9px, 11px) scale(1)',
			transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',
			color: theme.font[200],
		},

		'border-radius': '5px',
		'& #textInput-root': {
			minHeight: '39px',
		},
		'& #textInput-container': {
			padding: '6px 10px 0px 10px !important',
		},
		'& #textInput-container > div:first-child': {
			padding: '0px 0px 0px 0px !important',
		},
		'& #textInput-container > div[class^="MUIRichTextEditor-editorContainer-"]': {
			display: 'none',
		},
		'& #textInput-editor-container': {
			font: 'inherit',
			color: 'currentColor',
			width: '100%',
			border: '0',
			margin: '0',
			display: 'block',
			padding: '0px 0px 0px 0px',
			'min-width': '0',
			background: 'none',
			'box-sizing': 'content-box',
			'animation-name': 'mui-auto-fill-cancel',
			'letter-spacing': 'inherit',
			'animation-duration': '10ms',
			'-webkit-tap-highlight-color': 'transparent',
		},
		'& #textInput-editor-container > div:first-child': {
			padding: '0px 0px 0px 0px !important',
		},
	},

	focus: {
		transform: 'translate(-15px, -10px) scale(0.75)!important',
		...theme.overrides.MUIRichTextEditor.labelInputEmail,
	},

	borderFocus: {
		border: `2px solid ${theme.font[100]}!important`,
	},
}));
