import { createContext } from 'react';

// type ContaT = {
//     id: number,
//     nome: string,
//     classe_conta: 'A' | 'T',
//     tipo: 'O' | 'E' | 'L' | 'F',
//     classe_conta: string,
//     id_plano_condominio: number | {[key: any]: any},
//     id_pai: number | ContaT | null,
//     tree_id: number | null,
//     level: number | null,
//     lft: number | null,
//     rght: number | null,
// };

// type PlanoDisponivelParaImportacaoT = {
//     id: number,
//     descricao: string,
//     contas: Conta[],
// }

// type PlanoImportandoT = {
//     id: str | number | undefined,
//     descricao: str | undefined,
// }

// type ContaParaImportacaoT = {
//     nome: string,
//     classe_conta: 'A' | 'T',
//     tipo: 'O' | 'E' | 'L' | 'F',
//     id_plano_condominio: number | PlanoImportandoT,
//     id_pai: ContaParaImportacaoT | null,
//     tree_id: number | null,
//     level: number | null,
//     lft: number | null,
//     rght: number | null,
//     classificacao: string,
//     raiz: boolean,
//     children: ContaT[],
//     classeContaChildren: string | null,
// };

// type DadosContasParaImportacaoT = {
//     contasArray: ContaParaImportacaoT[],
//     contasPorClassificacaoObj: {[key: string]: ContaParaImportacaoT},
//     contasRaizes: ContaParaImportacaoT[],
// };

export const createDadosContasVazio = () => ({
	contasArray: [],
	contasPorClassificacaoObj: {},
	contasRaizes: [],
});

const FluxoImportacaoPlanoContext = createContext({
	descricao: undefined, // str | undefined
	id: undefined, // str | number | undefined
	podeImportar: false, // Boolean
	planosDisponiveisParaImportacao: [], // PlanoDisponivelParaImportacaoT[]
	planoParaImportacaoSelecionado: null, // (PlanoDisponivelParaImportacaoT | null)
	setPlanoParaImportacaoSelecionado: () => {}, // void
	planoImportando: null, // (PlanoImportandoT | null),
	handleClose: () => {},
	setStepComponent: () => {}, // void
	dadosContasReceitasDespesas: {
		receitas: createDadosContasVazio(), // DadosContasParaImportacaoT
		despesas: createDadosContasVazio(), // DadosContasParaImportacaoT
	}, // {[key: string]: DadosContasParaImportacaoT}
	setDadosContasReceitasDespesas: () => {}, // void
});

export default FluxoImportacaoPlanoContext;
