import React, { useCallback, useContext } from 'react';

import { useNotify } from 'react-admin';

import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { makeStyles } from '@material-ui/core';

import { DropzoneAreaBase } from '@pandemicode/material-ui-dropzone';

import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

const useStyles = makeStyles({
	gridFileIcons: {
		justifyContent: 'center',
	},
});

const DropZone = () => {
	const notify = useNotify();
	const classes = useStyles();
	const { files, setFiles } = useContext(FluxoImportacaoExtratoBancarioContext);
	const addFiles = useCallback(
		(newFileObjects) => {
			if (newFileObjects.filter((nFO) => !(nFO.file?.name || '').match(/\.ofx$/)).length) {
				notify('Apenas arquivos com extensão .OFX são permitidos', 'warning');
			}
			setFiles(
				[
					...newFileObjects.filter(
						(nFO) =>
							!!(nFO.file?.name || '').match(/\.ofx$/) &&
							(files ? !files.find((f) => f.file.name === nFO.file.name) : true)
					),
					...files,
				].slice(0, 1)
			);
		},
		[files, setFiles, notify]
	);
	const deleteFile = useCallback(
		(_, removedFileObjIdx) => {
			setFiles(files.filter((_, i) => i !== removedFileObjIdx));
		},
		[files, setFiles]
	);
	return (
		<DropzoneAreaBase
			dropzoneText='Arraste e solte o extrato aqui, ou clique'
			fileObjects={files}
			filesLimit={1}
			getFileLimitExceedMessage={() => 'Número máximo de arquivos excedido, apenas 1 permitido'}
			previewGridClasses={{ container: classes.gridFileIcons }}
			previewGridProps={{ item: { style: { maxWidth: '10%', padding: '2rem 2.5rem 2rem 2.5rem' } } }}
			getPreviewIcon={(fileObject, classes) => (
				<Tooltip title={fileObject.file.name}>
					<AttachFileIcon className={classes.image} />
				</Tooltip>
			)}
			onAdd={addFiles}
			onDelete={deleteFile}
			showAlerts={['error']}
		/>
	);
};

export default DropZone;
