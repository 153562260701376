import React from 'react';

import { Box, Typography, Paper } from '@material-ui/core';

import { ConfigRelatorioContext } from './ConfigRelatorioContextProvider';

import { SelectTemplateConfiguracao } from './selects/SelectTemplateConfig';
import { SelectTamanhoFonte } from './selects/SelectTamanhoFonte';

import { OpcoesImpressaoCheckboxGroup } from './checkboxes/OpcoesImpressaoCheckboxGroup';

import { ButtonAction } from './buttons/ButtonAction';
import { CustomDialog, ModalContextProvider } from 'components/common/ModalContext';

export const ConfiguracaoRelatorioForm = () => {
	const { opcoesImpressao } = React.useContext(ConfigRelatorioContext);

	return (
		<ModalContextProvider>
			<Box display='flex' flexDirection='column' marginTop='1rem'>
				<Box display='flex' flexDirection='row' gridGap='1rem'>
					<Box width={opcoesImpressao ? '35%' : '100%'}>
						<SelectTemplateConfiguracao />
					</Box>
					{opcoesImpressao && (
						<>
							<Box width={'25%'}>
								<SelectTamanhoFonte />
							</Box>
						</>
					)}
				</Box>
			</Box>
			{opcoesImpressao && (
				<Box>
					<OpcoesImpressaoCheckboxGroup />
				</Box>
			)}
			{opcoesImpressao && (
				<Box marginTop={'20px'}>
					<ButtonAction />
				</Box>
			)}
			<CustomDialog />
		</ModalContextProvider>
	);
};
