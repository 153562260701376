import React, { createContext, useEffect } from 'react';
import { FuncaoContextValue } from './type';
import { TFuncao } from '../types';
import { CustomDataProvider } from 'types/tpyesGlobal';
import { useDataProvider } from 'react-admin';

export const FuncaoContext = createContext<FuncaoContextValue>({
	funcao: {} as TFuncao,
	setFuncao: () => {},
	funcoes: [],
	setFuncoes: () => {},
});

export const FuncaoProvider: React.FC = React.memo(({ children }) => {
	const dp = useDataProvider() as CustomDataProvider;
	const [funcao, setFuncao] = React.useState<TFuncao>({} as TFuncao);
	const [funcoes, setFuncoes] = React.useState<TFuncao[]>([]);

	const reloadPessoas = React.useCallback(() => {
		dp.getSimple('funcoes_funcionarios', { filter: {} }).then((res) => {
			setFuncoes((res?.data?.resulta || []) as TFuncao[]);
		});
	}, []);

	const providerValue = React.useMemo(
		() => ({
			funcao,
			setFuncao,
			funcoes,
			setFuncoes,
		}),
		[funcao, setFuncao, funcoes, setFuncoes]
	);

	useEffect(reloadPessoas, []);

	return <FuncaoContext.Provider value={providerValue}>{children}</FuncaoContext.Provider>;
});
