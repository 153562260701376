import React from 'react';

import { Box } from '@material-ui/core';

import MultaFields, { createOnChangeTipoPadraoMulta, getPropsOnChangeTipoPadraoMulta } from './MultaFields';
import JurosFields, { createOnChangeTipoPadraoJuros, getPropsOnChangeTipoPadraoJuros } from './JurosFields';
import DescontoFields, { createOnChangeTipoPadraoDesconto, getPropsOnChangeTipoPadraoDesconto } from './DescontoFields';
import LimitePagamentoFields, {
	createOnChangeTipoPadraoLimitePagamento,
	getPropsOnChangeTipoPadraoLimitePagamento,
} from './LimitePagamentoFields';
import ProtestoFields, { createOnChangeTipoPadraoProtesto, getPropsOnChangeTipoPadraoProtesto } from './ProtestoFields';
import SacadorAvalistaField from './SacadorAvalistaField';

export {
	MultaFields,
	getPropsOnChangeTipoPadraoMulta,
	createOnChangeTipoPadraoMulta,
	JurosFields,
	getPropsOnChangeTipoPadraoJuros,
	createOnChangeTipoPadraoJuros,
	DescontoFields,
	getPropsOnChangeTipoPadraoDesconto,
	createOnChangeTipoPadraoDesconto,
	LimitePagamentoFields,
	getPropsOnChangeTipoPadraoLimitePagamento,
	createOnChangeTipoPadraoLimitePagamento,
	ProtestoFields,
	getPropsOnChangeTipoPadraoProtesto,
	createOnChangeTipoPadraoProtesto,
	SacadorAvalistaField,
};

export const BoxMainGrid: React.FC = ({ children }) => (
	<Box display='grid' gridColumnGap='0.5rem' gridTemplateColumns='repeat(2, 50%)'>
		{children}
	</Box>
);

const PadraoTabInstrucoesCobrancaRemessaBase: React.FC<{
	ColunaDireitaComponentes: React.ReactElement | React.ReactElement[] | JSX.Element | JSX.Element[];
	ColunaEsquerdaComponentes: React.ReactElement | React.ReactElement[] | JSX.Element | JSX.Element[];
}> = ({ ColunaDireitaComponentes = [], ColunaEsquerdaComponentes = [] }) => (
	<Box>
		<BoxMainGrid>
			<Box>{ColunaDireitaComponentes}</Box>
			<Box>{ColunaEsquerdaComponentes}</Box>
		</BoxMainGrid>
		<Box flexDirection='row' display='flex'>
			<SacadorAvalistaField />
		</Box>
	</Box>
);

export default PadraoTabInstrucoesCobrancaRemessaBase;
