import React from 'react';

import Menu from '@material-ui/core/Menu';

import { ButtonBaixarBoletos, ButtonEnviarBoletos, ButtonDisponibilizar } from '../botoes';

export const MenuGerencianet = ({
	anchorEl,
	handleClose,
	idsRegistrosSelecionados,
	disabledDisponibilizar,
	registrosSelecionados,
	temRegistroSemBoletoSelecionado,
}) => (
	<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
		<ButtonDisponibilizar {...{ idsRegistrosSelecionados }} disabled={disabledDisponibilizar} />
		<ButtonBaixarBoletos
			{...{
				idsRegistrosSelecionados,
				registrosSelecionados,
			}}
			disabled={temRegistroSemBoletoSelecionado}
			configurar
		/>
		<ButtonEnviarBoletos {...{ idsRegistrosSelecionados }} disabled={temRegistroSemBoletoSelecionado} configurar />
	</Menu>
);

export default MenuGerencianet;
