import React from 'react';

import { TCampoFeesPercentageProps } from '../../types';
import NumberFormatPercent from './NumberFormatPercent';

export const CampoFeesPercentage: React.FC<TCampoFeesPercentageProps> = ({
	feesOptions,
	setFeesPercentage,
	feesPercentage,
}) => {
	const disableFeesPercent = React.useMemo(() => !Object.values(feesOptions).some((v) => v), [feesOptions]);
	return (
		<NumberFormatPercent
			label='Percentual de Juros'
			value={feesOptions.option_daily_fees || feesOptions.option_monthly_fees ? feesPercentage : '00,0000'}
			onValueChange={({ value }: { value: string }) => {
				value = value.substring(0, 2) + ',' + value.substring(2);
				return setFeesPercentage(value);
			}}
			disabled={disableFeesPercent}
			fullWidth
		/>
	);
};

export default CampoFeesPercentage;
