import React from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

export const ButtonEnviaEmails = ({ idsRegistrosSelecionados }) => {
	const dp = useDataProvider();
	const notify = useNotify();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const enviarEmails = React.useCallback(() => {
		dp.simplePost('contas_receber_boletos/notificacao_boleto', {
			data: { list_ids: idsRegistrosSelecionados },
		})
			.then(() => {
				notify('Notificações enviadas com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) {
					return Promise.reject(e);
				}
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Não foi possível enviar os e-mails, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	}, [dp, idsRegistrosSelecionados, notify]);
	return (
		<Button
			onClick={() => {
				enviarEmails();
				setModalValue((v) => ({
					...v,
					open: false,
				}));
			}}
		>
			Enviar
		</Button>
	);
};

export default ButtonEnviaEmails;
