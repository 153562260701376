import { required, maxLength } from 'react-admin';
import lodashMemoize from 'lodash/memoize';

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

export const nomeValidacao = [
	required('Nome é um campo obrigatório'),
	maxLength(100, 'Não pode conter mais de 100 caracteres'),
];

export const nomeParser = memoize((value) => {
	if (value) return value.substring(0, 100);
});
