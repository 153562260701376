import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ExpandIconButton from './ExpandIconButton';

const RowFilhoRender = ({ row }) => (
	<TableRow hover style={{ overflow: 'hidden' }}>
		<TableCell
			style={{
				paddingLeft: row.level * 20 + 36,
				position: 'relative',
			}}
			colSpan='3'
		>
			<ExpandIconButton level={row.level}>{row.children}</ExpandIconButton>
			<span
				style={{
					fontWeight: row.classe_conta === 'T' ? 'bold' : 'normal',
				}}
			>
				{row.nome}
			</span>
		</TableCell>
	</TableRow>
);

export default RowFilhoRender;
