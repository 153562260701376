import { required } from 'react-admin';

export const situacaoValidacao = [required('Selecione uma situação')];

export const situacaoOpcoes = [
	{ id: 'A', name: 'Ativo' },
	{ id: 'I', name: 'Inativo' },
];

export const usuarioSituacaoOpcoes = [
	{ id: 'C', name: 'Aguardando Confirmação' },
	{ id: 'A', name: 'Ativo' },
	{ id: 'I', name: 'Inativo' },
];
