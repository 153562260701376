import React, { useState, useContext, useMemo, useEffect } from 'react';

import { ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import EditaContaBancariaContext from './contexts/EditaContaBancariaContext';
import { tipoContaDicionario } from './utils/formaters_parses';
import tipoContaContextDict from './contexts';
import tipoContaContextProviderDict from './providers';
import EditaContaBancariaFormulario from './EditaContaBancariaFormulario';

const EditaContaBancariaContextProvider = ({
	condominioRecord,
	dadosConta: { conta_condominio, cobranca_config = {} },
	opcoesDadosBancarios,
	dadosAdministradora,
}) => {
	const { setMinWidthModel } = useContext(ModalSizeEditableContext);
	useEffect(() => {
		setMinWidthModel('95vw');
	}, [setMinWidthModel]);

	const [initialDados] = useState({
		conta_condominio,
		cobranca_config,
		opcoesDadosBancarios,
		isInativo: condominioRecord.situacao !== 'A' || conta_condominio.situacao !== 'A',
	});

	const tipoConta =
		tipoContaDicionario[
			conta_condominio.tipo_conta === 'C' ? conta_condominio.tipo_conta : conta_condominio.tipo_conta_bancaria
		];

	const [{ TipoContaContext, TipoContaContextProvider }] = useState({
		TipoContaContext: tipoContaContextDict[tipoConta],
		TipoContaContextProvider: tipoContaContextProviderDict[tipoConta],
	});

	const editaContaBancariaContextProviderValue = useMemo(
		() => ({
			condominioRecord,
			initialDados,
			TipoContaContext,
			dadosAdministradora,
		}),
		[condominioRecord, initialDados, TipoContaContext, dadosAdministradora]
	);

	return (
		<EditaContaBancariaContext.Provider value={editaContaBancariaContextProviderValue}>
			<TipoContaContextProvider>
				<EditaContaBancariaFormulario />
			</TipoContaContextProvider>
		</EditaContaBancariaContext.Provider>
	);
};

const ModalEditaContaBancaria = ({ condominioRecord, dadosConta, opcoesDadosBancarios, dadosAdministradora }) => (
	<EditaContaBancariaContextProvider
		condominioRecord={condominioRecord}
		dadosConta={dadosConta}
		opcoesDadosBancarios={opcoesDadosBancarios}
		dadosAdministradora={dadosAdministradora}
	/>
);

export default ModalEditaContaBancaria;
