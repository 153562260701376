import React from 'react';

import { List as RAList } from 'react-admin';

import brExporter from 'utils/exporter';

import { ActionsBulkList, Empty, ListActions, Pagination, TabelaCorrecaoMonetaria } from './components';

const List: React.FC = (props) => {
	return (
		<RAList
			{...props}
			title=' '
			basePath='/correcao_monetaria'
			resource='correcao_monetaria'
			sort={{ field: 'nome', order: 'ASC' }}
			exporter={brExporter}
			empty={<Empty />}
			actions={<ListActions />}
			bulkActionButtons={<ActionsBulkList />}
			perPage={10}
			pagination={
				<Pagination
					rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
					labelRowsPerPage='Correções Monetárias por página'
				/>
			}
		>
			<TabelaCorrecaoMonetaria {...props} />
		</RAList>
	);
};

export default List;
