import React, { useContext } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ArrecadacoesContext } from 'components/resources/arrecadacao/ArrecadacoesContext';

export const useDesprovisionarReferenciaAutomaticoPadrao = () => {
	const { id_identificacao_arrecadacao, referencia, setDadosProvisionamento } = useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const notify = useNotify();
	const dP = useDataProvider();

	const condominioSelecionadoId = condominioSelecionado?.id;
	const desprovisionar = React.useCallback(
		({ senha, callbackSuccess, callbackFail } = {}) =>
			dP
				.delete('cld_recebimento_manual', {
					id: 'destroy_bulk',
					data: {
						identificacao_id: id_identificacao_arrecadacao,
						condominio_id: condominioSelecionadoId,
						referencia: referencia,
						pwd: senha,
					},
				})
				.then(() => {
					setDadosProvisionamento({
						tem_arrecadacao_provisionada: false,
						conta_b_destino_id: null,
						tem_recebimento_pago: false,
					});
					notify('Arrecadações desprovisionadas com sucesso!');
					callbackSuccess?.();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					notify(
						Object.values(e?.response?.data || {})[0] ||
							'Um erro inesperado aconteceu, tente recarregar a página',
						'warning'
					);
					callbackFail?.();
				}),
		[dP, notify, id_identificacao_arrecadacao, condominioSelecionadoId, referencia, setDadosProvisionamento]
	);
	return desprovisionar;
};

export default useDesprovisionarReferenciaAutomaticoPadrao;
