import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ButtonClose } from 'components/common/buttons/ButtonClose';
import { ButtonRemove } from 'components/common/buttons/ButtonRemove';
import { useDesprovisionarReferenciaAutomaticoPadrao } from '../hooks';
import TabelaDadosNossoNumero from '../TabelaDadosNossoNumero';

export const ModalConfirmaDesprovisionamentoBancoDoBrasilAutomatico = ({ recebimentosNossoNumero, setLoading }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const desprovisionar = useDesprovisionarReferenciaAutomaticoPadrao();
	const [senha, setSenha] = React.useState('');
	const [desprovisionando, setDesprovisionando] = React.useState(false);

	return (
		<CustomDialogBodySizeEditable
			title='Deseja realmente excluir o provisionamento das arrecadações?'
			text={
				<Box display='flex' alignItems='flex-start' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							O Banco do Brasil solicita um período de 30 minutos da geração do boletos para executar a
							baixa do mesmo!
						</Typography>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Já existem unidades com o nosso número de cobrança gerado, ao confirmar a exclusão essa
							numeração será removida e a cobrança no banco será excluída!
						</Typography>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Por segurança, solicitamos sua senha de login novamente para cancelar os boletos.
						</Typography>
						<TextField
							name='password'
							fullWidth
							type='password'
							label='Senha do usuário'
							value={senha}
							variant='outlined'
							onChange={(e) => setSenha(e.target.value)}
							size='small'
						/>
						<TabelaDadosNossoNumero recebimentosNossoNumero={recebimentosNossoNumero} />
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
							setLoading?.(false);
							setDesprovisionando(false);
						}}
						size='small'
						disabled={desprovisionando}
					>
						cancelar
					</ButtonClose>
					<ButtonRemove
						onClick={() => {
							setDesprovisionando(true);
							desprovisionar({
								senha,
								callbackSuccess: () => {
									setLoading?.(false);
									setModalValue({ open: false });
									setDesprovisionando(false);
								},
								callbackFail: () => {
									setDesprovisionando(false);
								},
							});
						}}
						size='small'
						disabled={!senha || desprovisionando}
					/>
				</>
			}
		/>
	);
};

export default ModalConfirmaDesprovisionamentoBancoDoBrasilAutomatico;
