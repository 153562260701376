import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { CancelButton, DeleteButton } from 'components/common/commonComponentsTSX';
import React from 'react';
import { ProviderValueContext } from './type';
import { useDataProvider, useNotify } from 'react-admin';
import { CustomDataProviderDocuments } from '../types';
import { DocumentsContext } from '../contexts/DocumentsContext';
import { Box } from '@material-ui/core';
import { CondominiosContext } from 'context/CondominioContextProvider';

const DocumentsFolderExclude: React.FC<{ id: number }> = ({ id }) => {
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const { handleCollectFolders, setFolderName: setFilterFolderName } = React.useContext(DocumentsContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const notify = useNotify();

	const handleSubmitEraseFolder = (e: React.FormEvent) => {
		e.preventDefault();

		dp.delete('documento_pasta', { id: id }).then(() => {
			notify('Pasta excluída com sucesso!');
			setModalValue((v) => ({ ...v, open: false }));
			setFilterFolderName('');
			return handleCollectFolders({
				filter: { condominio: condominioSelecionado?.id },
				sort: { order: 'ASC', field: 'nome' },
				pagination: {
					perPage: 10000,
					page: 1,
				},
			});
		});
	};
	return (
		<CustomDialogBody
			title={'Excluir Pasta'}
			customActions={
				<>
					<CancelButton onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
					<DeleteButton disabled={false} onClick={handleSubmitEraseFolder}>
						Excluir
					</DeleteButton>
				</>
			}
			form={{
				component: (
					<Box
						style={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						Esta pasta pode conter arquivos. Tem certeza que deseja continuar?
					</Box>
				),
			}}
		/>
	);
};

export default DocumentsFolderExclude;
