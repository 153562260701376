import React from 'react';

import TextField from '@material-ui/core/TextField';

function InputNumeroGaragem({ value, setValue, ...props }) {
	function handleChangeInput(e) {
		setValue(e.target.value);
	}
	return (
		<TextField
			value={value}
			onChange={handleChangeInput}
			label='Digite o número da vaga:'
			size='small'
			margin='normal'
			variant='outlined'
			name='numero-garagem'
			id='numero-garagem-id'
			fullWidth
			type='text'
			required
			{...props}
		/>
	);
}

export default InputNumeroGaragem;
