import React from 'react';

import { Autocomplete } from '@material-ui/lab';

import TextField from '@material-ui/core/TextField';

const SelectContaBancaria = ({
	setValue,
	value,
	disabledConta,
	label = 'Pagar com',
	disabled,
	options = [],
	disableClearable = false,
	style = null,
}) => (
	<Autocomplete
		options={options}
		renderInput={(params) => <TextField {...params} margin='dense' label={label} />}
		getOptionLabel={(option) => option?.nome_conta || ''}
		noOptionsText='Nenhum resultado encontrado'
		getOptionSelected={(option, v) => v?.id === option.id}
		value={value || null}
		onChange={(_, v) => setValue(v)}
		clearText='Limpar'
		getOptionDisabled={(option) => option?.id === disabledConta?.id}
		disabled={disabled}
		disableClearable={disableClearable}
		style={style}
	/>
);

export default SelectContaBancaria;
