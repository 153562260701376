const formatsParaOpcaoInvalida = {
	formatOption: (option) => '',
	formatGroupBy: (option) => '',
	checkGroup: (options, group, values) => false,
	selectGroup: (options, group, values, setValues) => {},
	label: 'Selecionar',
};

const formatsParaContas = {
	formatOption: (option) => (option?.id ? `${option.tipoConta} - ${option.nome}` : ''),
	formatGroupBy: (option) => option.tipoConta,
	checkGroup: (options, group, values) => {
		const [_group, _selected] = [options, values].map((v) => v?.filter((c) => c['tipoConta'] === group));
		return _group?.length === _selected?.length;
	},
	selectGroup: (options, group, values, setValues) => {
		const [_group, _selectedGroup] = [options, values].map((v) => v?.filter((c) => c['tipoConta'] === group));
		if (_selectedGroup?.length) {
			setValues(values, 'desmarcarGrupo', group);
		} else {
			setValues(values, 'marcarGrupo', group, _group);
		}
	},
	label: 'Selecionar Contas',
};

const formatsParaUnidades = {
	formatOption: (option) =>
		option?.unidade ? `${option.tipo_unidade} - ${option.unidade} - ${option.proprietario}` : '',
	formatGroupBy: (option) => option.nome_grupo || option.tipo_unidade,
	checkGroup: (options, group, values) => {
		const [_group, _selected] = [options, values].map((v) =>
			v?.filter((c) => (c['nome_grupo'] || c['tipo_unidade']) === group)
		);
		return _group?.length === _selected?.length;
	},
	selectGroup: (options, group, values, setValues) => {
		const initialValue = values || [];
		const [_group, _selected] = [options, values].map((v) =>
			v?.filter((c) => (c['nome_grupo'] || c['tipo_unidade']) === group)
		);
		_selected?.length > 0
			? setValues(initialValue?.filter((c) => (c['nome_grupo'] || c['tipo_unidade']) !== group))
			: setValues([...initialValue, ..._group]);
	},
	label: 'Selecionar Unidades',
};

const opcoesFormatsParaRetorno = {
	undefined: formatsParaOpcaoInvalida,
	null: formatsParaOpcaoInvalida,
	formatsContas: formatsParaContas,
	formatsUnidades: formatsParaUnidades,
};

export const createFormatsOptionsAutoCompleteComponent = (formatsToReturn) => ({
	...(opcoesFormatsParaRetorno[formatsToReturn] ?? formatsParaOpcaoInvalida),
});
