import React, { useContext } from 'react';

import { SyndikosMuiPickersUtilsProvider, SyndikosKeyboardDatePicker } from '../../../../common/InputDate';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import { createGetPropsValidas } from '../utils/functions';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const propNames = [
	'source',
	'format',
	'id',
	'name',
	'label',
	'mask',
	'margin',
	'variant',
	'inputVariant',
	'parse',
	'fullWidth',
];

const getPropsValidas = createGetPropsValidas(propNames);

const EditaContaBancariaKeyboardDatePicker = ({ source, disabled: standardDisabled = false, ...standardProps }) => {
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { props } = useContext(DadosPorTipoIntegracaoContext);
	const { disabled = false, ...sourceProps } = props[source] || {};

	return (
		<SyndikosMuiPickersUtilsProvider>
			<SyndikosKeyboardDatePicker
				source={source}
				disabled={isInativo || disabled || standardDisabled}
				fullWidth
				{...getPropsValidas({ ...standardProps, ...sourceProps })}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};

export default EditaContaBancariaKeyboardDatePicker;
