import React from 'react';

import { Box } from '@material-ui/core';

import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const EspecieTituloField = () => (
	<Box pl={1} flex={1} sx={{ 'max-width': '25%' }}>
		<EditaContaBancariaAutocomplete
			source='especie_titulo'
			name='especie_titulo'
			id='especie_titulo'
			label='Espécie Título'
			translateChoice={false}
		/>
	</Box>
);

export default EspecieTituloField;
