import React, { createContext, ReactNode, useMemo, useState } from 'react';

interface ContextValueProps {
	selectedIds: Array<object>;
	setSelected: (ids: Array<object>) => void;
}

interface ProviderProps {
	children: ReactNode;
}

export const ListRetencoesImpostosContext = createContext({} as ContextValueProps);

export const ListRetencoesImpostosContextProvider: React.FC<ProviderProps> = ({ children }) => {
	const [selectedIds, setSelectedIds] = useState<Array<object>>([]);

	const setSelected = (ids: Array<object>) => {
		setSelectedIds(ids);
	};

	const contextValue = useMemo(
		() => ({
			selectedIds,
			setSelected,
		}),
		[selectedIds]
	);

	return (
		<ListRetencoesImpostosContext.Provider value={contextValue}>{children}</ListRetencoesImpostosContext.Provider>
	);
};
