import React, { useContext } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import RowContext from '../contexts/RowContext';
import { parseInitials } from '../helpers/parseInitials';
import ActionsCellRenderer from './ActionsCellRenderer';
import ExpandIconButton from './ExpandIconButton';

const RowRaizRender = ({ row }) => {
	const { tipo } = useContext(RowContext);

	return (
		<TableRow hover style={{ overflow: 'hidden' }}>
			<TableCell
				style={{
					paddingLeft: row.level * 20 + 36,
					position: 'relative',
				}}
			>
				<ExpandIconButton level={row.level}>{row.children}</ExpandIconButton>
				<span
					style={{
						fontWeight: row.classe_conta === 'T' ? 'bold' : 'normal',
					}}
				>
					{row.nome}
				</span>
			</TableCell>
			<TableCell>{!row.id_pai && parseInitials(tipo)}</TableCell>
			<TableCell style={{ position: 'relative', padding: '5px 16px' }}>
				{!row.id_pai && <ActionsCellRenderer />}
			</TableCell>
		</TableRow>
	);
};

export default RowRaizRender;
