import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { TooltipIconButton } from '../TooltipIconButton';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';

const useStyles = makeStyles(
	(theme) => ({
		cancelButton: {
			'&:hover': {
				backgroundColor: alpha(theme.palette.primary.main, 0.05),

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'SyndikosCancelButton' }
);

export const ButtonCancel = ({ id, style = {}, children, className, ...rest }) => {
	const classes = useStyles();
	return (
		<Button
			{...rest}
			startIcon={<CloseIcon />}
			size='small'
			variant='text'
			className={classnames(classes.cancelButton, className)}
			id={id}
		>
			{children || 'Cancelar'}
		</Button>
	);
};

export const TooltipIconButtonCancel = ({ title, style = {}, children, className, ...rest }) => {
	const classes = useStyles();
	return (
		<TooltipIconButton
			{...rest}
			title={title || 'Cancelar'}
			className={classnames(classes.cancelButton, className)}
		>
			{children || <CloseIcon />}
		</TooltipIconButton>
	);
};
