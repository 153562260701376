import React, { useContext } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { TreeTableContext } from '../contexts/TreeTableContext';

import RowsRenderer from './RowsRenderer';

const ContasTable = () => {
	const {
		value: { treeData, tipo, id },
	} = useContext(TreeTableContext);

	return (
		<TableContainer
			style={{
				overflowY: 'auto',
				height: '100%',
				maxHeight: '100%',
				width: '100%',
			}}
		>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell style={{ background: '#fff' }} width='60%'>
							Nome da conta
						</TableCell>
						<TableCell style={{ background: '#fff' }} width='20%'>
							Tipo
						</TableCell>
						<TableCell style={{ background: '#fff' }} width='20%'>
							Ações
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{treeData.length > 0 && treeData[0].id !== 0 ? (
						<RowsRenderer key={'root' + tipo + id} rows={treeData} />
					) : (
						<tr />
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ContasTable;
