import React from 'react';

import { TextField, Paper, LinearProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { ArrecadacoesContext } from 'components/resources/arrecadacao/ArrecadacoesContext';
import { ContasBancariasContext } from 'components/resources/arrecadacao/ContasBancariasContextProvider';

export const SelectContaBancaria = () => {
	const { options, loading } = React.useContext(ContasBancariasContext);
	const { contaBDestino, setContaBDestino, tem_arrecadacao_provisionada } = React.useContext(ArrecadacoesContext);

	if (loading)
		return (
			<div style={{ marginTop: 25 }}>
				<LinearProgress />
			</div>
		);

	return (
		<Autocomplete
			options={options}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Conta Bancária' />}
			renderOption={(option) => option.nome_conta}
			getOptionSelected={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option.nome_conta}
			noOptionsText='Nenhum resultado encontrado'
			onChange={(_, v) => {
				setContaBDestino(v ?? null);
			}}
			disabled={!options?.length || tem_arrecadacao_provisionada}
			autoHighlight
			value={contaBDestino ?? null}
			variant='outlined'
			clearText='Limpar'
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						maxWidth: '320px',
						minWidth: '180px',
					}}
				/>
			)}
			fullWidth
		/>
	);
};

export default SelectContaBancaria;
