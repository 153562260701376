import React from 'react';

import { useNotify } from 'react-admin';

import { Button, Box } from '@material-ui/core';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../ModalSizeEditableContext';
import { CloseButton } from 'components/common/commonComponentsTSX';

import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

import { useDataProvider } from 'react-admin';
import { CustomDataProvider } from './types';
import { ModalValue } from '../types';

import { CreateComunicadoTemplateBox } from './CreateComunicadoTemplateBox';

import { CondominiosContext } from 'context/CondominioContextProvider';

type ModalCreateTemplateBoxProps = {
	setReload: React.Dispatch<React.SetStateAction<number>>;
};

const ModalCreateTemplateBox: React.FC<ModalCreateTemplateBoxProps> = ({ setReload }) => {
	// Variaveis
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	const [comunicadoJson, setComunicadoJson] = React.useState('');
	const [nomeModelo, setNomeModelo] = React.useState('');

	const [loading, setLoading] = React.useState(false);
	const dp = useDataProvider() as CustomDataProvider;
	const notify = useNotify();

	// Funções Handle
	const handleSaveValueNewTemplate = (data: string, nomeModelo: string) => {
		setNomeModelo(nomeModelo);
		setComunicadoJson(data);
	};

	// Requisição Post Save Template Personalizado
	const handleSaveTemplateComunicado = () => {
		setLoading(true);

		dp.simplePost('comunicado_template', {
			data: {
				nome_modelo: nomeModelo,
				comunicado_json: JSON.parse(comunicadoJson),
				condominio: condominioSelecionado?.id as number,
			},
		})
			.then(() => {
				notify('Modelo de comunicado cadastrado com sucesso', 'success');
				setReload((v) => v + 1);
				setModalValue((v: ModalValue) => ({ ...v, open: false }));
			})
			.catch(() => {
				notify('Falha ao salvar modelo de comunicado, preencha ao menos o campo de nome do modelo.', 'warning');
				setLoading(false);
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title={'Criar Novo Modelo Personalizado'}
			closeInSubmit={false}
			form={{
				component: (
					<>
						<CreateComunicadoTemplateBox handleSaveValueNewTemplate={handleSaveValueNewTemplate} />
					</>
				),
			}}
			customActions={
				<Box display='flex' gridGap={'0.5em'}>
					<Box>
						<CloseButton
							onClick={() => setModalValue((v: ModalValue) => ({ ...v, open: false }))}
							size={'small'}
						>
							Cancelar
						</CloseButton>
					</Box>
					<Box>
						<Button
							color='primary'
							size='small'
							variant='contained'
							startIcon={loading ? <></> : <SaveIcon />}
							disabled={loading}
							onClick={handleSaveTemplateComunicado}
						>
							{(loading && <CircularProgress size={20} />) || 'Salvar'}
						</Button>
					</Box>
				</Box>
			}
		/>
	);
};

export default ModalCreateTemplateBox;
