import React from 'react';
import Typography from '@material-ui/core/Typography';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTreeItem from '@material-ui/lab/TreeItem';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(() => ({
	root: {
		'&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
			backgroundColor: 'transparent !important',
		},
	},
}));

const RenderTreeAdicionaConta = ({ setExpanded, setNewConta, newConta, ...nodes }) => {
	const classes = useStyles();
	return (
		<MaterialTreeItem
			onClick={() =>
				setExpanded((d) => {
					const newExpanded = d.includes(nodes.id) ? d.filter((i) => i !== nodes.id) : [...d, nodes.id];
					return newExpanded;
				})
			}
			id={`tree-view-item-${nodes.index}`}
			key={`key-tree-view-item-${nodes.index}`}
			nodeId={nodes.id}
			onLabelClick={(e) => {
				if (nodes.classe_conta === 'A') {
					e.preventDefault();
					setNewConta({ id: nodes.id, nome: nodes.nome });
				}
			}}
			label={
				<>
					<Typography
						style={{
							backgroundColor: newConta && newConta.id === nodes.id ? '#dee3ff' : 'rgba(0,0,0,0)',
							fontWeight:
								(newConta && newConta.id === nodes.id) || nodes?.classe_conta === 'T'
									? 'bold'
									: 'normal',
							'&:hover': {
								backgroundColor: 'rgba(0,0,0,0.2)',
							},
							minHeight: 36,
							display: 'flex',
							alignItems: 'center',
							paddingLeft: '10px',
							borderRadius: '5px',
						}}
						color={newConta && newConta.id === nodes.id ? 'primary' : 'initial'}
					>
						{nodes?.nome}
						{nodes?.classe_conta === 'T' &&
							(nodes?.children.length === 0 || nodes?.children[0].classe_conta === 'A') && (
								<TooltipIconButton
									id={`tree-view-create-button-${nodes.index}`}
									title='Criar Conta Analítica'
									color='primary'
									onClick={(e) => {
										e.preventDefault();
										setNewConta({
											nome_pai: nodes.nome,
											id_pai: nodes.id,
											classe_conta: 'A',
											tipo: nodes.tipo,
											id_plano_condominio: nodes.id_plano_condominio,
										});
									}}
								>
									<AddIcon />
								</TooltipIconButton>
							)}
					</Typography>
				</>
			}
			classes={{
				root: classes.root,
			}}
		>
			{Array.isArray(nodes.children)
				? nodes.children.map((node, index) => (
						<RenderTreeAdicionaConta
							id={`tree-view-item-${nodes.index}-children`}
							key={`key-tree-view-item-${nodes.index}-children`}
							{...{ ...node, index: index }}
							{...{ setExpanded, setNewConta, newConta }}
						/>
				  ))
				: null}
		</MaterialTreeItem>
	);
};

export default RenderTreeAdicionaConta;
