import React, { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';

import BoxButton from '../../../common/buttons/BoxButton';
import { CustomDialogBodySizeEditable } from '../../../common/ModalSizeEditableContext';
import FluxoImportacaoPlanoContext from './FluxoImportacaoPlanoContext';
import { TooltipIconButtonCancel } from '../../../common/buttons/ButtonCancel';

const OpcoesImportacaoPlanoModal = () => {
	const {
		podeImportar,
		planoImportando: { descricao = '' },
		setStepComponent,
		handleClose,
	} = useContext(FluxoImportacaoPlanoContext);

	return (
		<CustomDialogBodySizeEditable
			title={
				podeImportar
					? `Copiar outro plano para "${descricao}"`
					: 'Este plano não pode importar contas por não estar vazio'
			}
			customActions={
				<>
					<TooltipIconButtonCancel onClick={handleClose} />
				</>
			}
			form={{
				handleSubmit: () => {},
				component: (
					<Box display='grid' gridGap='15px'>
						<Typography align='center' variant='h6'>
							Selecione a forma de importação
						</Typography>
						<Box display='flex' justifyContent='center' gridGap='15px'>
							<BoxButton
								text='Copiar de plano cadastrado'
								icon={<FileCopyIcon />}
								disabled={!podeImportar}
								onClick={() => {
									setStepComponent('ImportacaoPorPlanoCadastrado');
								}}
								width='14rem'
							/>
							<BoxButton
								text='Copiar de arquivo csv'
								icon={<GetAppIcon />}
								disabled={!podeImportar}
								onClick={() => {
									setStepComponent('ImportacaoPorCSV-DropZone');
								}}
								width='14rem'
							/>
						</Box>
					</Box>
				),
			}}
		/>
	);
};

export default OpcoesImportacaoPlanoModal;
