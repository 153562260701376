import React from 'react';
import { ArrecadacoesContext } from 'components/resources/arrecadacao/ArrecadacoesContext';

const ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico = () => {
	const { contaBDestinoIsValid } = React.useContext(ArrecadacoesContext);

	return contaBDestinoIsValid ? 'Gerar Boletos' : 'Conta bancária inválida para gerar boletos';
};

export default ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico;
