import React from 'react';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const Input = ({ inputProps, label, ...params }) => (
	<TextField
		variant='outlined'
		margin='dense'
		size='small'
		label={label}
		{...params}
		inputProps={{ ...inputProps }}
	/>
);

const FormInput = ({ name, control, ...params }) => (
	<Controller
		name={name}
		control={control}
		render={({ field: { onChange, value } }) => <Input onChange={onChange} value={value} {...params} />}
	/>
);

export default FormInput;
