import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';

import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { TitulosContext } from '../components/TituloModalContextProvider';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { TooltipIconButtonCancel } from 'components/common/buttons/ButtonCancel';
import TituloRow from '../components/TituloRow';

const ModalTituloSelect = () => {
	const { setModalValue } = React.useContext(ModalContext);
	const [addMode, setAddMode] = React.useState(false);
	const { filteredOptions: options, filter, setFilter, create } = React.useContext(TitulosContext);
	const inputRef = React.useRef<null | { value: string; focus: () => void }>(null);

	const handleCreate = () => {
		create({ descricao: filter });
		if (inputRef.current) inputRef.current.value = '';
		setFilter('');
		setAddMode(false);
	};

	return (
		<CustomDialogBody
			title={'Editar Títulos'}
			customActions={
				<Button
					onClick={() => {
						setFilter('');
						setModalValue((v: any) => ({ ...v, open: false }));
					}}
					size='small'
					startIcon={<CloseIcon />}
				>
					Fechar
				</Button>
			}
			form={{
				component: (
					<>
						<Box display='flex' alignItems='center'>
							<TextField
								inputRef={inputRef}
								label={addMode ? 'Nome do novo titulo' : 'Buscar'}
								fullWidth
								margin='dense'
								onKeyPress={(e) => e.key === 'Enter' && addMode && handleCreate()}
								onChange={(e) => {
									setFilter(e.target.value);
								}}
							/>
							{addMode ? (
								<>
									<Box display='flex'>
										<TooltipIconButton
											disabled={!inputRef.current?.value}
											color='primary'
											title='Salvar'
											onClick={handleCreate}
										>
											<CheckIcon />
										</TooltipIconButton>
										<TooltipIconButtonCancel
											onClick={() => setAddMode(false)}
											title={undefined}
											className={undefined}
										>
											<ClearIcon />
										</TooltipIconButtonCancel>
									</Box>
								</>
							) : (
								<TooltipIconButton
									title='Cadastrar novo titulo'
									onClick={() => {
										setAddMode(true);
										inputRef.current?.focus();
									}}
								>
									<AddCircleOutlineIcon />
								</TooltipIconButton>
							)}
						</Box>
						<Box mx={2} mt={2} position='relative' height={350} style={{ overflowY: 'auto' }}>
							{options.length ? (
								options.map((option, index) => <TituloRow option={option} key={index} />)
							) : (
								<Typography>Nenhum titulo encontrado</Typography>
							)}
						</Box>
					</>
				),
			}}
		/>
	);
};

export default ModalTituloSelect;
