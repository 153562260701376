import React from 'react';

import { Box, Typography } from '@material-ui/core';

import PeriodoSelectV2 from 'components/common/PeriodoSelect/PeriodoSelectV2';
import SelectAutoComplete from 'components/common/SelectAutocomplete';
import RadioButtonGroup from 'components/common/RadioGroup';
import ButtonGerarArquivoContasAReceber from './buttons/ButtonGerarArquivoContasAReceber';

import { ExportacaoContabilContext } from './context/ExportacaoContabilContext';

const optionsTipoMovimento = [{ id: 'CR', nome: 'Contas a Receber' }];

const optionsTipoExportacao: { [key: string]: { id: string; nome: string }[] } = {
	CR: [
		{ id: 'provisao', nome: 'Provisão' },
		{ id: 'recebimento', nome: 'Recebimento' },
		{ id: 'ambos', nome: 'Ambos' },
	],
};

const ConsultaExportacaoContabil: React.FC = () => {
	const {
		condominio,
		setCondominio,
		optionsCondominio,
		valueTipoMovimento,
		setValueTipoMovimento,
		valueTipoExportacao,
		setValueTipoExportacao,
		dateRange,
		setDateRange,
	} = React.useContext(ExportacaoContabilContext);

	const tipoExportacaoOptions = optionsTipoExportacao[valueTipoMovimento?.id] || [];

	return (
		<Box mt='0.5em'>
			<Box>
				<SelectAutoComplete
					options={optionsCondominio}
					value={condominio}
					setValue={setCondominio}
					label={'Condomínio'}
					noOptionsText={'Nenhum condomínio disponível'}
					getOptionLabel={(o) => o.nome}
				/>
			</Box>
			<br />
			<Typography variant='h6'>Exportação Contábil</Typography>
			<br />
			<Box display='flex' flexDirection='row'>
				<Box mr={2} width='100%'>
					<SelectAutoComplete
						options={optionsTipoMovimento}
						value={valueTipoMovimento}
						setValue={setValueTipoMovimento}
						label={'Tipo de Movimento'}
						noOptionsText={'Nenhum tipo de movimentação disponível'}
						getOptionLabel={(o) => o.nome}
					/>
				</Box>
				<Box ml={2} width='100%'>
					<PeriodoSelectV2 dateRange={dateRange} setDateRange={setDateRange} label='Selecionar Período' />
				</Box>
			</Box>
			<Box mt={4}>
				<Typography>Tipo de Exportação</Typography>
				<Box display='flex'>
					<RadioButtonGroup
						value={valueTipoExportacao}
						setValue={setValueTipoExportacao}
						label='Tipo de Exportação'
						id='tipo-exportacao'
						options={tipoExportacaoOptions}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='end' mt={4}>
				<ButtonGerarArquivoContasAReceber />
			</Box>
		</Box>
	);
};

export default ConsultaExportacaoContabil;
