import React, { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import { Form } from 'react-final-form';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoProximo } from '../../../../../common/buttons/BotaoProximo';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';
import { SyndikosSelect } from '../../../../../common/SyndikosSelect';
import VeryHighLimitReferenceInput from '../../../../../common/VeryHighLimitReferenceInput';

const ModalContaLeituraNovaLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const [contaLeitura, setContaLeitura] = useState(dadosNovaLeitura.dados_config.conta_receita || null);
	const handleBack = () => {
		setDadosNovaLeitura((v) => {
			const { conta_receita, ...dados_config } = v.dados_config;
			v['dados_config'] = dados_config;
			return v;
		});
		setModalStep('1');
	};
	const handleNext = () => {
		setDadosNovaLeitura((v) => {
			v.dados_config['conta_receita'] = contaLeitura;
			return v;
		});
		setModalStep('1A');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Qual a Conta de Receita?'
			form={{
				component: (
					<Form
						onSubmit={(e) => {
							e.preventDefault();
						}}
						initialValues={{
							conta_receita: dadosNovaLeitura.dados_config.conta_receita,
						}}
						render={() => (
							<VeryHighLimitReferenceInput
								reference='contas_receita'
								source='conta_receita'
								label='Conta de Receita'
								filter={{
									id_plano_condominio__condominios: dadosNovaLeitura.id_condominio,
									tipo: 'L',
									classe_conta: 'A',
									tem_config_no_cond: 'null',
								}}
								onChange={(newValue) => {
									setContaLeitura(newValue);
								}}
								fullWidth
								style={{ marginTop: '8px' }}
							>
								<SyndikosSelect
									variant='outlined'
									optionText='nome'
									translateChoice={false}
									helperText={false}
								/>
							</VeryHighLimitReferenceInput>
						)}
					/>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo onClick={handleNext} disabled={!contaLeitura} />
				</>
			}
		/>
	);
};

export default ModalContaLeituraNovaLeitura;
