import React, { useContext } from 'react';

import AutorenewIcon from '@material-ui/icons/Autorenew';

import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ModalArrecadacaoUnidadesEditarContext } from './ModalArrecadacaoUnidadesEditarContext';

const BotaoAtualizarValor = ({ row, loading, setLoading }) => {
	const { atualizarValor, requesting } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const handleClick = () => {
		setLoading(true);
		atualizarValor(row?.id).finally(() => setLoading(false));
	};

	return (
		!!row && (
			<TooltipIconButton
				disabled={loading || requesting}
				size='small'
				title='Atualizar Valor'
				onClick={handleClick}
			>
				<AutorenewIcon />
			</TooltipIconButton>
		)
	);
};

export default BotaoAtualizarValor;
