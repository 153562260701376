import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import BoxButtonDecision from './BoxButtonDecision';
import NovaLeituraContext from './NovaLeituraContext';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';

const ModalUnidadeMedidaNovaLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setModalStep, setDadosNovaLeitura } = useContext(NovaLeituraContext);
	const handleBack = () => {
		setDadosNovaLeitura((v) => {
			const { unidade_medida, ...dados_config } = v.dados_config;
			v['dados_config'] = dados_config;
			return v;
		});
		setModalStep('4');
	};
	const handleNextM = () => {
		setDadosNovaLeitura((v) => {
			v.dados_config['unidade_medida'] = 'm';
			return v;
		});
		setModalStep('6FK');
	};
	const handleNextK = () => {
		setDadosNovaLeitura((v) => {
			v.dados_config['unidade_medida'] = 'k';
			return v;
		});
		if ('2' === dadosNovaLeitura.dados_config.metodo_calculo) setModalStep('8FS');
		if (['1', '3'].includes(dadosNovaLeitura.dados_config.metodo_calculo)) setModalStep('9FC');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Qual a unidade de medida dessa leitura?'
			form={{
				component: (
					<Box display='flex' justifyContent='center'>
						<BoxButtonDecision onclick={handleNextM}>m³</BoxButtonDecision>
						<BoxButtonDecision onclick={handleNextK}>Kw/h</BoxButtonDecision>
					</Box>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
				</>
			}
		/>
	);
};

export default ModalUnidadeMedidaNovaLeitura;
