import React, { useRef, useState, useEffect } from 'react';
import { useNotify } from 'react-admin';
import { Box, Button, Typography } from '@material-ui/core';
import Base64 from '../../utils/Base64';
import { styled } from '@material-ui/core/styles';

const StyledBox = styled(Box)(({ disabled, theme }) => ({
	display: 'flex',
	'align-items': 'stretch',

	border: `1px solid ${theme.border[100]} !important`,
	'border-radius': '5px',

	transition: 'border cubic-bezier(0.075, 0.82, 0.165, 1) !important',
	cursor: disabled ? 'default' : 'pointer',
}));

const StyledBoxForFile = styled(Box)(({ disabled, theme, onlyVisualizar }) => ({
	flex: '1',
	position: 'relative',
	display: 'flex',
	'align-items': 'center',
	'justify-content': 'space-between',
	'padding-left': '12px',
	cursor: disabled || onlyVisualizar ? 'default' : 'pointer',

	'& div:last-child()': {
		display: 'flex',
	},
	'& > label': {
		position: 'absolute',
		transform: disabled ? 'translate(-10px, -20px) scale(0.75) !important' : 'translate(-4px, -20px) !important',
		padding: '0px 5px',
		'background-color': `${theme.fundoMenu[100]} !important`,
		width: 'max-content',
		'z-index': 1,
	},
	'& > label > p': {
		...(disabled ? {} : { fontSize: '11px !important' }),
		color: `${theme.font[disabled ? 300 : 200]} !important`,
	},
	'& > div > p': {
		...(disabled ? { color: `${theme.font[300]} !important` } : {}),
	},
}));

const StyledButton = styled(Button)(({ disabled, theme }) => ({
	transition: 'filter cubic-bezier(0.075, 0.82, 0.165, 1) !important',
	'box-shadow': 'none !important',
	'border-radius': '0px !important',
	color: `${theme.blue[500]}${disabled ? '99' : ''} !important`,
	'background-color': `${theme.fundoMenu[100]}${disabled ? '99' : ''} !important`,

	'&:hover': {
		filter: 'brightness(87.25%) !important',
	},
	'&:last-of-type': {
		'border-radius': '0px 5px 5px 0px !important',
	},
}));

function truncate(string) {
	const _stringByValue = string;
	return string.length > 20 ? `${_stringByValue.substring(0, 17)}...` : string;
}

export const InputFile = ({
	label,
	onCallBack,
	fileDataUrl,
	disabled = false,
	reusedField = false,
	PermitedFileExtension = ['pdf', 'png', 'jpg', 'jpeg'],
	dontDisableVisualizar = true,
	onlyVisualizar = false,
	...rest
}) => {
	const [fileName, setFileName] = useState(null);
	const [fileObject, setFileObject] = useState(null);
	const [fileValue, setFileValue] = useState(null);
	const inputRef = useRef(null);
	const notify = useNotify(null);

	// valida o arquivo
	const isValidFile = (file) => {
		if (!file) return;
		const fileExtension = file.name.split('.').pop();
		if (!PermitedFileExtension.includes(fileExtension)) {
			notify(`Apenas arquivos com extensão ${PermitedFileExtension.join(', ')} são permitidos`, 'warning');
			return false;
		}
		return true;
	};

	// valida o arquivo e obtem o nome do mesmo
	const handleFileField = async ({ target: { files } }) => {
		if (!isValidFile(files[0])) {
			return;
		}

		if (!files[0]) {
			setFileName(null);
			return onCallBack({}, null);
		}
		const fileName = files[0].name;
		setFileName(fileName);
		setFileObject(files[0]);

		const base64File = await Base64.encode(files[0]);
		setFileValue(base64File);
		return onCallBack(Event, `${fileName};${base64File}`, files[0]);
	};

	const handleVisualization = () => {
		if (typeof fileObject === 'string') {
			return window.open(fileObject, '_blank');
		}

		const ObjectUrl = URL.createObjectURL(fileObject);
		return window.open(ObjectUrl, '_blank');
	};

	const clearInput = () => {
		inputRef.current.value = null;
		setFileName(null);
		return onCallBack({}, null);
	};

	const updateFileValue = () => {
		if (reusedField && fileName && !fileDataUrl) {
			inputRef.current.value = null;
			setFileObject(null);
			setFileValue(null);
			return setFileName(null);
		}

		if (fileDataUrl && !fileValue) {
			setFileValue(fileDataUrl);
			if (fileDataUrl.includes(';data:')) {
				return setFileName(fileDataUrl.split(';').shift());
			} else {
				setFileObject(fileDataUrl);
			}
			return setFileName(fileDataUrl.split('?')?.shift()?.split('/')?.pop());
		}
	};

	useEffect(updateFileValue, [fileDataUrl]);

	// cria o componente
	return (
		<StyledBox disabled={disabled}>
			<StyledBoxForFile
				onClick={() => !disabled && inputRef.current.click()}
				disabled={disabled}
				onlyVisualizar={onlyVisualizar}
			>
				<label
					style={{
						padding: '0px 5px',
						width: 'max-content',
						zIndex: 1,
						position: 'absolute',
						transform: disabled ? 'translate(-10px, -20px) scale(0.75)' : 'translate(-4px, -20px)',
					}}
				>
					<Typography>{label}</Typography>
				</label>
				<div>
					<Typography>{fileName ? truncate(fileName) : 'Nenhum arquivo selecionado'}</Typography>
				</div>
			</StyledBoxForFile>
			{!onlyVisualizar && (
				<StyledButton
					onClick={() => !disabled && inputRef.current.click()}
					variant='contained'
					disabled={disabled}
				>
					{fileName ? 'Alterar anexo' : 'Carregar anexo'}
				</StyledButton>
			)}
			{fileName && (
				<StyledButton onClick={handleVisualization} variant='contained' disabled={!dontDisableVisualizar}>
					Visualizar
				</StyledButton>
			)}
			{fileName && !onlyVisualizar && (
				<StyledButton onClick={clearInput} variant='contained' disabled={disabled}>
					Remover
				</StyledButton>
			)}
			{!onlyVisualizar && (
				<input
					onChange={handleFileField}
					type='file'
					style={{ display: 'none' }}
					ref={inputRef}
					disabled={disabled}
				></input>
			)}
		</StyledBox>
	);
};

export default InputFile;
