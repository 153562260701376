import React, { useContext } from 'react';

import { TableRow, TableCell, Chip } from '@material-ui/core';

import { cnpjFormatacao, cpfFormatacao } from '../../../../fieldControl/documento';
import { telefoneFormatacao } from '../../../../fieldControl/telefone';
import { VinculosContext } from '../VinculosContext';
import ActionsResponsavel from './ActionsResponsavel';

export const stringifyDate = (value) => {
	if (!value) {
		return null;
	}
	const year = value.substr(0, 4);
	const month = value.substr(5, 2);
	const day = value.substr(8, 2);

	return `${day}/${month}/${year}`;
};

const InfoPessoa = ({
	id,
	id_pessoa,
	tipo,
	nome,
	tipo_pessoa,
	documento,
	telefones,
	data_entrada,
	data_saida,
	email_cobranca,
	imobiliaria,
	cep,
	tipo_logradouro_id,
	tipo_logradouro,
	endereco,
	numero,
	complemento_endereco,
	bairro,
	cidade,
	uf,
}) => {
	const { edit } = useContext(VinculosContext);
	const enderecoDados = {
		cep,
		tipo_logradouro_id,
		tipo_logradouro,
		endereco,
		numero,
		complemento_endereco,
		bairro,
		cidade,
		uf,
	};

	return (
		<TableRow hover>
			<TableCell
				align='center'
				style={{ paddingLeft: 0, width: '1%', whiteSpace: 'nowrap', textTransform: 'none' }}
			>
				{tipo === 'P' ? (
					<Chip label='Proprietário' color='primary' size='small' />
				) : (
					<Chip label='Inquilino' color='secondary' size='small' />
				)}
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>{nome}</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>
				{tipo_pessoa === 'F' ? 'Física' : 'Jurídica'}
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>
				{documento && (tipo_pessoa === 'F' ? cpfFormatacao(documento) : cnpjFormatacao(documento))}
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>
				{telefones?.length > 0 &&
					telefones.map((telefone) => (
						<>
							{telefoneFormatacao(telefone)}
							<br />
						</>
					))}
				{email_cobranca && email_cobranca}
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>
				{data_saida ? (
					<>
						de {stringifyDate(data_entrada)}
						<br />a {stringifyDate(data_saida)}
					</>
				) : (
					`desde ${stringifyDate(data_entrada)}`
				)}
			</TableCell>
			<TableCell align='right' style={{ padding: 0, textTransform: 'none' }}>
				<ActionsResponsavel
					{...{
						edit,
						id,
						id_pessoa,
						nome,
						tipo_pessoa,
						documento,
						data_entrada,
						enderecoDados,
						telefones,
						email_cobranca,
						data_saida,
						imobiliaria,
						tipo,
					}}
				/>
			</TableCell>
		</TableRow>
	);
};

export default InfoPessoa;
