import React from 'react';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
	LimitePagamentoFields,
	ProtestoFields,
} from '../../tabs/TabInstrucoesCobrancaRemessa';

import {
	formataPropsMaxValuePorLimitePagamentoDias,
	formatCedentePattern1,
	getDadosFormattingContaCorrente,
	parseCedentePattern1,
} from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';

import DefaultContaCorrenteProps, {
	defaultComponentesProps,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesProtesto,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	getValidacoesLimitePagamento,
	mensagensErrosPadroes,
} from '../default/ContaCorrenteProps';

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	nomeCampoLegivel: 'limite de pagamento',
	valorIsensao: 'NBD',
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamentoComSemLimiteComoOpcao(),
});

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	nomeCampoLegivel: 'protesto/negativação',
	msgErroVazio: mensagensErrosPadroes.vazioProtesto({ nomeCampoLegivel: 'protesto/negativação' }),
	minValue: 5,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto({ nomeCampoLegivel: 'protesto/negativação', minValue: 5 }),
	maxValue: 15,
	msgErroMaxValue: mensagensErrosPadroes.maxValueProtesto({ nomeCampoLegivel: 'protesto/negativação', maxValue: 15 }),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesLimitePagamento.tipo,
	validacoesLimitePagamento.dias,

	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesProtesto({ values }),
	...formataPayloadValuesLimitePagamento({ values, valorIsensao: 'NBD' }),
});

const TabInstrucoesCobrancaRemessa = (props) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<LimitePagamentoFields />, <ProtestoFields />]}
	/>
);

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '085',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(8),
		},
		conta_cedente: {
			parse: parseCedentePattern1(6),
			format: formatCedentePattern1(6),
		},
		protesto_dias: { min: 1 },
		limite_pagamento_dias: { min: 5, max: 15 },
	},
	getOptions: getFormatarOptions('085', { agencia: 'agencias_ailos' }),
	validacoes: [
		...DefaultContaCorrenteProps.validacoes,
		getValidateQuandoEmitindoBoletos(validacoesInstrucoesCobrancaRemessa),
	],
	formatadoresPayloadValues: {
		...defaultFormatadoresPayloadValues,
		cobranca_config: getFormatadorPayloadCobrancaConfig({
			formatadores: [
				...defaultFormatadoresPayloadValuesCobrancaConfig,

				// Dados de Tab Conta
				formataPayloadValuesTabContaCobrancaConfig,

				// Dados de Tab Instruções de Cobrança Remessa
				formataPayloadValuesTabInstrucoesCobrancaRemessa,
			],
		}),
	},
	tabContents: {
		...DefaultContaCorrenteProps.tabContents,
		TabInstrucoesCobrancaRemessa,
	},
};

export default ContaCorrenteProps;
