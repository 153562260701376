import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { useNotify, useEditContext, useGetList } from 'react-admin';

import { add, parseISO, isSameDay, isAfter } from 'date-fns';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';

import { EnderecoBoxV2, EnderecoContext, EnderecoContextProvider } from '../../../common/EnderecoV2';
import {
	ModalSizeEditableContextProvider,
	ModalSizeEditableContext,
	CustomDialogBodySizeEditable,
} from '../../../common/ModalSizeEditableContext';
import { VinculosContext } from './../VinculosContext';
import AddImobiliaria from './common/AddImobiliaria';
import BoxDateOptional from './common/BoxDateOptional';
import BoxInfo from './common/BoxInfo';
import BoxPessoaSelect from './common/BoxPessoaSelect';
import formatDateISO from './common/formatDateISO';
import VinculosDatePicker from './common/VinculosDatePicker';

const ModalBodyTrocaInquilino = ({
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	data_saida,
	motivo_saida,
	id_pessoa,
	imobiliaria,
	id,
	reloadTable,
	enderecoDados: enderecoDadosDoAtualInquilino,
}) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const { record } = useEditContext();
	const { selectedPessoa, setSelectedPessoa, dataProvider, setResponsaveis, setReload } = useContext(VinculosContext);
	const { values: enderecoDados } = useContext(EnderecoContext);

	const [processando, setProcessando] = useState(false);
	const [valid, setValid] = useState(false);
	const [entradaPrev] = useState(parseISO(data_entrada + 'T10:00'));
	const [motivoSaida, setMotivoSaida] = useState(motivo_saida);
	const [saidaPrev, setSaidaPrev] = useState(data_saida ? parseISO(data_saida + 'T10:00') : null);
	const [entradaProx, setEntradaProx] = useState(saidaPrev ? add(saidaPrev, { days: 1 }) : null);
	const [addSaidaProx, setAddSaidaProx] = useState(false);
	const [saidaProx, setSaidaProx] = useState(addSaidaProx && entradaProx ? add(entradaProx, { days: 1 }) : null);
	const [addImobiliariaProx, setAddImobiliariaProx] = useState(Boolean(imobiliaria));
	const imobiliariaRef = useRef(imobiliaria || null);

	useEffect(() => {
		if (!saidaPrev) return setMotivoSaida('');
	}, [saidaPrev]);

	useEffect(() => {
		// reseta o selectedPessoa, para minimizar a chance de bugs com os componentes
		const imobiliaria = imobiliariaRef.current;
		setSelectedPessoa(imobiliaria ? { imobiliaria, id_imobiliaria: imobiliaria.id } : undefined);
	}, [setSelectedPessoa]);

	useEffect(() => {
		let isValid =
			enderecoDados?.cep &&
			enderecoDados?.bairro &&
			enderecoDados?.cidade &&
			enderecoDados?.endereco &&
			enderecoDados?.tipo_logradouro &&
			enderecoDados?.tipo_logradouro_id &&
			enderecoDados?.uf &&
			selectedPessoa?.nome &&
			saidaPrev &&
			saidaPrev.toString() !== 'Invalid Date' &&
			(isSameDay(saidaPrev, entradaPrev) || isAfter(saidaPrev, entradaPrev)) &&
			isAfter(new Date(), saidaPrev) &&
			entradaProx &&
			entradaProx.toString() !== 'Invalid Date' &&
			(isSameDay(new Date(), entradaProx) || isAfter(new Date(), entradaProx)) &&
			isAfter(entradaProx, saidaPrev);
		if (isValid) isValid = (addImobiliariaProx && selectedPessoa?.imobiliaria) || !addImobiliariaProx;
		if (isValid)
			isValid =
				(addSaidaProx &&
					saidaProx &&
					saidaProx.toString() !== 'Invalid Date' &&
					isAfter(saidaProx, entradaProx)) ||
				!addSaidaProx;

		setValid(isValid);
	}, [
		entradaPrev,
		selectedPessoa,
		saidaPrev,
		entradaProx,
		addImobiliariaProx,
		addSaidaProx,
		saidaProx,
		enderecoDados,
	]);

	const handleSubmit = () => {
		setProcessando(true);

		if (!record) {
			setProcessando(false);
			return;
		}

		const {
			id: id_pessoa,
			nome,
			email_cobranca,
			documento,
			tipo_pessoa,
			telefones,
			id_imobiliaria,
			imobiliaria,
		} = selectedPessoa || {};

		dataProvider
			.update('pessoa_unidade', {
				id,
				data: {
					data_saida: formatDateISO(saidaPrev),
					motivo_saida: !saidaPrev ? '' : motivoSaida,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					dataProvider
						.create('pessoa_unidade', {
							data: {
								id_unidade: data.id_unidade,
								id_pessoa,
								tipo: 'I',
								data_entrada: formatDateISO(entradaProx),
								data_saida: saidaProx ? formatDateISO(saidaProx) : saidaProx,
								id_imobiliaria: id_imobiliaria || null,
								...enderecoDados,
							},
						})
						.then((response) => {
							const data = response?.data;
							if (data) {
								const dataSaida = data?.data_saida ? parseISO(data.data_saida) : undefined;
								if (
									!dataSaida ||
									(dataSaida && (isSameDay(dataSaida, new Date()) || isAfter(dataSaida, new Date())))
								) {
									const dadosInquilino = {
										...data,
										nome,
										email_cobranca,
										documento,
										tipo_pessoa,
										telefones,
										id_imobiliaria: id_imobiliaria || null,
										imobiliaria: imobiliaria || null,
										...enderecoDados,
									};
									setResponsaveis((v) => {
										v[1] = dadosInquilino;
										return v;
									});
								} else {
									setResponsaveis((v) => v.filter((r) => r.tipo !== 'I'));
								}
								if (reloadTable) setReload((v) => v + 1);
								notify('Inquilino alterado com sucesso', 'info');
								setModalValue((v) => ({ ...v, open: false }));
							}
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							notify(
								Object.values(e?.response?.data || {})[0] || [
									'Erro inesperado, tente recarregar a página',
								],
								'warning'
							);
						});
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
				setProcessando(false);
			});
	};

	const handleDatePrevChange = (v) => {
		setSaidaPrev(v);
		const dataEntradaProx = add(v, { days: 1 });
		if (dataEntradaProx && dataEntradaProx.toString() !== 'Invalid Date') {
			setEntradaProx((v) => (!v || isAfter(dataEntradaProx, v) ? dataEntradaProx : v));
			const dataSaidaProx = dataEntradaProx;
			if (addSaidaProx) setSaidaProx((v) => (!v || isAfter(dataSaidaProx, v) ? dataSaidaProx : v));
		}
	};

	const handleDateEntradaProxChange = (v) => {
		setEntradaProx(v);
		const dataSaidaProx = v;
		if (addSaidaProx && dataSaidaProx && dataSaidaProx.toString() !== 'Invalid Date')
			setSaidaProx((v) => (!v || isAfter(dataSaidaProx, v) ? dataSaidaProx : v));
	};

	const handleDateSaidaProxChange = (v) => {
		setSaidaProx(v);
	};

	const setImobiliariaCB = useCallback(
		(v) => {
			if (v) {
				imobiliariaRef.current = v;
				setSelectedPessoa((p) => ({
					...p,
					id_imobiliaria: v.id,
					imobiliaria: v,
				}));
			} else {
				const { id_imobiliaria, imobiliaria, ...rest } = selectedPessoa;
				imobiliariaRef.current = null;
				setSelectedPessoa({ ...rest });
			}
		},
		[setSelectedPessoa, selectedPessoa]
	);

	return (
		<CustomDialogBodySizeEditable
			title='Trocar Inquilino da Unidade'
			closeInSubmit={false}
			form={{
				valid: !processando && valid,
				handleSubmit,
				component: (
					<>
						<Chip label='Inquilino Atual' size='large' style={{ margin: '8px 0' }} />
						<VinculosDatePicker
							label='Data de Saída'
							minDate={entradaPrev}
							minDateMessage='A saída não pode ser anterior à data de entrada'
							value={saidaPrev}
							onChange={handleDatePrevChange}
						/>
						<BoxInfo
							selectedPessoa={{ tipo_pessoa, nome, documento }}
							entrada={entradaPrev}
							saida={saidaPrev}
							enderecoDados={enderecoDadosDoAtualInquilino}
						/>
						<TextField
							flex={1}
							mr='0.5em'
							fullWidth
							size='small'
							variant='outlined'
							label='Motivo de saída'
							value={motivoSaida}
							onChange={(event) => setMotivoSaida(event.target.value)}
							disabled={!saidaPrev}
							inputProps={{ maxLength: 50 }}
						/>
						<hr />
						<Chip label='Novo Inquilino' size='large' color='primary' style={{ margin: '8px 0' }} />
						<ModalSizeEditableContextProvider>
							<BoxPessoaSelect owner={{ id_pessoa }} setValid={setValid} />
						</ModalSizeEditableContextProvider>
						<VinculosDatePicker
							label='Data de Entrada'
							disableFuture
							maxDateMessage='Data de entrada não pode ser posterior ao dia de hoje'
							minDate={add(saidaPrev, { days: 1 })}
							minDateMessage='A entrada do próximo inquilino deve ser posterior à data de saída do atual'
							value={entradaProx}
							onChange={handleDateEntradaProxChange}
						/>
						<BoxDateOptional
							addDate={addSaidaProx}
							propsCheckBox={{
								checked: addSaidaProx,
								checkBoxLabel: 'Adicionar Data de Saída',
								checkBoxOnChange: (e) => {
									setAddSaidaProx(e.target.checked);
									!e.target.checked && setSaidaProx(null);
								},
							}}
							propsDatePicker={{
								datePickerLabel: 'Data de Saída',
								datePickerValue: saidaProx,
								datePickerOnChange: handleDateSaidaProxChange,
								minDate: entradaProx,
								minDateMessage:
									'A saída do próximo inquilino não pode ser anterior à sua data de entrada',
							}}
						/>
						<AddImobiliaria
							setImob={setImobiliariaCB}
							checked={addImobiliariaProx}
							setChecked={setAddImobiliariaProx}
							value={imobiliariaRef.current}
						/>
						<EnderecoBoxV2 />
					</>
				),
			}}
		/>
	);
};

export const ModalTrocaInquilino = ({ enderecoDados, ...props }) => (
	<EnderecoContextProvider {...enderecoDados}>
		<ModalBodyTrocaInquilino enderecoDados={enderecoDados} {...props} />
	</EnderecoContextProvider>
);

const ModalBodyEditaInquilino = ({
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	telefones,
	email_cobranca,
	data_entrada,
	data_saida,
	motivo_saida,
	imobiliaria,
	id,
	reloadTable,
}) => {
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { record } = useEditContext();
	const { selectedPessoa, setSelectedPessoa, dataProvider, responsaveis, setResponsaveis, edit, setReload } =
		useContext(VinculosContext);
	const { values: enderecoDados } = useContext(EnderecoContext);

	const owner = responsaveis.find((v) => v.tipo === 'P');
	const [processando, setProcessando] = useState(false);
	const [valid, setValid] = useState(false);
	const [entradaData, setEntradaData] = useState(parseISO(data_entrada + 'T10:00'));
	const [saidaData, setSaidaData] = useState(data_saida ? parseISO(data_saida + 'T10:00') : null);
	const [motivoSaida, setMotivoSaida] = useState(motivo_saida);
	const [minDateProp, setMinDateProp] = useState({});
	const [addImobiliaria, setAddImobiliaria] = useState(Boolean(imobiliaria));
	const imobiliariaRef = useRef(imobiliaria || null);
	const { data, ids } = useGetList(
		'pessoa_unidade',
		{ perPage: 1, pages: 1 },
		{ order: 'ASC', field: 'data_entrada' },
		{ tipo: 'P' }
	);

	const setMinDate = () => {
		if (owner) {
			if (edit && data && data[ids[0]]?.data_entrada) {
				setMinDateProp({
					minDate: parseISO(data[ids[0]].data_entrada),
				});
			} else if (!edit) {
				setMinDateProp({ minDate: parseISO(owner.data_entrada) });
			}
		}
	};

	useEffect(setMinDate, [data]);

	useEffect(() => {
		if (!saidaData) return setMotivoSaida('');
	}, [saidaData]);

	useEffect(() => {
		// reseta o selectedPessoa, para minimizar a chance de bugs com os componentes
		const imobiliaria = imobiliariaRef.current;
		setSelectedPessoa({
			id: id_pessoa,
			nome,
			tipo_pessoa,
			documento,
			telefones,
			email_cobranca,
			...(imobiliaria ? { imobiliaria, id_imobiliaria: imobiliaria.id } : {}),
		});
	}, [setSelectedPessoa, id_pessoa, nome, tipo_pessoa, documento, telefones, email_cobranca]);

	useEffect(() => {
		let isValid =
			enderecoDados?.cep &&
			enderecoDados?.bairro &&
			enderecoDados?.cidade &&
			enderecoDados?.endereco &&
			enderecoDados?.tipo_logradouro &&
			enderecoDados?.tipo_logradouro_id &&
			enderecoDados?.uf &&
			selectedPessoa?.nome &&
			entradaData &&
			entradaData.toString() !== 'Invalid Date' &&
			(isSameDay(new Date(), entradaData) || isAfter(new Date(), entradaData)) &&
			(minDateProp.minDate ? minDateProp.minDate <= entradaData : true) &&
			(saidaData
				? saidaData.toString() !== 'Invalid Date' &&
				  (isSameDay(saidaData, entradaData) || isAfter(saidaData, entradaData))
				: true);
		if (isValid) isValid = (addImobiliaria && selectedPessoa?.imobiliaria) || !addImobiliaria;

		setValid(isValid);
	}, [entradaData, selectedPessoa, saidaData, addImobiliaria, minDateProp.minDate, enderecoDados]);

	const handleSubmit = () => {
		setProcessando(true);

		const {
			id: id_pessoa,
			nome,
			email_cobranca,
			documento,
			tipo_pessoa,
			telefones,
			id_imobiliaria,
			imobiliaria,
		} = selectedPessoa || {};

		if (!edit) {
			setResponsaveis((v) =>
				v.map((r) => {
					if (r.tipo === 'I') {
						return {
							nome,
							tipo: 'I',
							data_entrada: formatDateISO(entradaData),
							data_saida: saidaData ? formatDateISO(saidaData) : null,
							id_pessoa,
							email_cobranca,
							documento,
							tipo_pessoa,
							telefones,
							id_imobiliaria: id_imobiliaria || null,
							imobiliaria: imobiliaria || null,
							motivo_saida: motivoSaida || null,
							...enderecoDados,
						};
					}
					return r;
				})
			);
			setModalValue((v) => ({ ...v, open: false }));
			return;
		}

		if (!record) {
			setProcessando(false);
			return;
		}

		dataProvider
			.update('pessoa_unidade', {
				id,
				data: {
					id_pessoa: id_pessoa,
					data_entrada: formatDateISO(entradaData),
					data_saida: saidaData ? formatDateISO(saidaData) : null,
					id_imobiliaria: id_imobiliaria || null,
					motivo_saida: !saidaData ? '' : motivoSaida,
					...enderecoDados,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					const dataSaida = data?.data_saida ? parseISO(data.data_saida) : undefined;
					if (!dataSaida || isSameDay(dataSaida, new Date()) || isAfter(dataSaida, new Date())) {
						const dadosInquilino = {
							...data,
							nome,
							email_cobranca,
							documento,
							tipo_pessoa,
							telefones,
							id_imobiliaria: id_imobiliaria || null,
							imobiliaria: imobiliaria || null,
							...enderecoDados,
						};
						setResponsaveis((v) => {
							v[1] = dadosInquilino;
							return v;
						});
					} else {
						setResponsaveis((v) => v.filter((r) => r.tipo !== 'I'));
					}
					if (reloadTable) setReload((v) => v + 1);
					notify('Inquilino alterado com sucesso', 'info');
					setModalValue((v) => ({ ...v, open: false }));
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
				setProcessando(false);
			});
	};

	const handleEntradaDataChange = (v) => {
		setEntradaData(v);
		const dataSaida = v;
		if (dataSaida && dataSaida.toString() !== 'Invalid Date') {
			setSaidaData((v) => (v && v < dataSaida ? dataSaida : v));
		}
	};

	const handleSaidaDataChange = (v) => {
		setSaidaData(v);
	};

	const setImobiliariaCB = useCallback(
		(v) => {
			if (v) {
				imobiliariaRef.current = v;
				setSelectedPessoa((p) => ({
					...p,
					id_imobiliaria: v.id,
					imobiliaria: v,
				}));
			} else {
				const { id_imobiliaria, imobiliaria, ...rest } = selectedPessoa || {};
				imobiliariaRef.current = null;
				setSelectedPessoa({ ...rest });
			}
		},
		[setSelectedPessoa, selectedPessoa]
	);

	return (
		<CustomDialogBodySizeEditable
			title='Editar Inquilino da Unidade'
			closeInSubmit={false}
			form={{
				valid: !processando && valid,
				handleSubmit,
				component: (
					<>
						<Box display='flex' mb={2} gridColumnGap='1em'>
							<Box flex={1}>
								<VinculosDatePicker
									label='Data de Entrada*'
									value={entradaData}
									onChange={handleEntradaDataChange}
									fullWidth={true}
									disableFuture
									maxDateMessage='Data não pode ser posterior ao dia de hoje'
									{...minDateProp}
									minDateMessage={`A data de entrada do inquilino não pode ser anterior à data de entrada do primeiro proprietário: ${
										minDateProp.minDate && minDateProp.minDate.toLocaleDateString('pt-BR')
									}`}
								/>
							</Box>
							<Box flex={1}>
								<VinculosDatePicker
									label='Data de Saída'
									minDate={entradaData}
									minDateMessage='A saída não poder ser anterior à data de entrada'
									value={saidaData}
									onChange={handleSaidaDataChange}
									fullWidth={true}
								/>
							</Box>
						</Box>
						<TextField
							flex={1}
							mr='0.5em'
							fullWidth
							size='small'
							variant='outlined'
							label='Motivo de saída'
							value={motivoSaida}
							onChange={(event) => setMotivoSaida(event.target.value)}
							disabled={!saidaData}
							inputProps={{ maxLength: 50 }}
						/>
						<ModalSizeEditableContextProvider>
							<BoxPessoaSelect setValid={setValid} />
						</ModalSizeEditableContextProvider>
						<AddImobiliaria
							setImob={setImobiliariaCB}
							checked={addImobiliaria}
							setChecked={setAddImobiliaria}
							value={imobiliariaRef.current}
						/>
						<EnderecoBoxV2 />
					</>
				),
			}}
		/>
	);
};

export const ModalEditaInquilino = ({ enderecoDados, ...props }) => (
	<EnderecoContextProvider {...enderecoDados}>
		<ModalBodyEditaInquilino {...props} />
	</EnderecoContextProvider>
);

export const ModalDefinirDataFimInquilino = ({
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	telefones,
	email_cobranca,
	data_entrada,
	data_saida,
	imobiliaria,
	id,
	enderecoDados,
}) => {
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { record } = useEditContext();
	const { selectedPessoa, setSelectedPessoa, dataProvider, setResponsaveis } = useContext(VinculosContext);
	const [valid, setValid] = useState(false);
	const [processando, setProcessando] = useState(false);
	const [entradaData] = useState(parseISO(data_entrada + 'T10:00'));
	const [saidaData, setSaidaData] = useState(data_saida ? parseISO(data_saida + 'T10:00') : null);
	const [motivoSaida, setMotivoSaida] = useState('');
	const imobiliariaRef = useRef(imobiliaria || null);

	useEffect(() => {
		if (!saidaData) return setMotivoSaida('');
	}, [saidaData]);

	useEffect(() => {
		// reseta o selectedPessoa, para minimizar a chance de bugs com os componentes
		const imobiliaria = imobiliariaRef.current;
		setSelectedPessoa({
			id: id_pessoa,
			nome,
			tipo_pessoa,
			documento,
			telefones,
			email_cobranca,
			...(imobiliaria ? { imobiliaria, id_imobiliaria: imobiliaria.id } : {}),
		});
	}, [setSelectedPessoa, id_pessoa, nome, tipo_pessoa, documento, telefones, email_cobranca]);

	useEffect(() => {
		setValid(
			saidaData &&
				saidaData.toString() !== 'Invalid Date' &&
				(isSameDay(saidaData, entradaData) || isAfter(saidaData, entradaData))
		);
	}, [entradaData, saidaData]);

	const handleSubmit = () => {
		setProcessando(true);

		const {
			id: id_pessoa,
			nome,
			email_cobranca,
			documento,
			tipo_pessoa,
			telefones,
			id_imobiliaria,
			imobiliaria,
		} = selectedPessoa || {};

		if (!record) {
			setProcessando(false);
			return;
		}

		dataProvider
			.update('pessoa_unidade', {
				id,
				data: {
					data_saida: formatDateISO(saidaData),
					motivo_saida: !saidaData ? '' : motivoSaida,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					const dataSaida = data?.data_saida ? parseISO(data.data_saida) : undefined;
					if (!dataSaida || isSameDay(dataSaida, new Date()) || isAfter(dataSaida, new Date())) {
						setResponsaveis((v) =>
							v.map((r) => {
								if (r.tipo === 'I') {
									return {
										...data,
										nome,
										email_cobranca,
										documento,
										tipo_pessoa,
										telefones,
										id_imobiliaria: id_imobiliaria || null,
										imobiliaria: imobiliaria || null,
									};
								}
								return r;
							})
						);
					} else {
						setResponsaveis((v) => v.filter((r) => r.tipo !== 'I'));
					}
					notify('Inquilino alterado com sucesso', 'info');
					setModalValue((v) => ({ ...v, open: false }));
				}
				const today = new Date();
				today.setHours(0, 0, 0, 0);

				if (saidaData < today) {
					dataProvider.update('unidades', {
						id: record.id,
						data: {
							boleto_para: 'Prop',
						},
					});
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
				setProcessando(false);
			});
	};

	const handleSaidaDataChange = (v) => {
		setSaidaData(v);
	};

	return (
		<CustomDialogBodySizeEditable
			title='Definir data fim do Inquilino da Unidade'
			closeInSubmit={false}
			form={{
				valid: !processando && valid,
				handleSubmit,
				component: (
					<>
						<Box display='flex' mb={2} gridColumnGap='1em'>
							<Box flex={1}>
								<VinculosDatePicker
									label='Data de Entrada'
									value={entradaData}
									fullWidth={true}
									disableFuture
									maxDateMessage='Data não pode ser posterior ao dia de hoje'
									disabled={true}
								/>
							</Box>
							<Box flex={1}>
								<VinculosDatePicker
									label='Data de Saída*'
									minDate={entradaData}
									minDateMessage='A saída não poder ser anterior à data de entrada'
									value={saidaData}
									onChange={handleSaidaDataChange}
									fullWidth={true}
								/>
							</Box>
						</Box>
						<BoxInfo
							selectedPessoa={selectedPessoa}
							entrada={entradaData}
							saida={saidaData}
							enderecoDados={enderecoDados}
						/>
						<TextField
							flex={1}
							mr='0.5em'
							fullWidth
							size='small'
							variant='outlined'
							label='Motivo de saída'
							value={motivoSaida}
							onChange={(event) => setMotivoSaida(event.target.value)}
							disabled={!saidaData}
							inputProps={{ maxLength: 50 }}
						/>
					</>
				),
			}}
		/>
	);
};
