import { ParameterValuesProps } from '../../types';
import { formataPayloadValuesFixos } from '../default/ContaCorrenteProps/getFormataPayloadValues';

const getFormataPayloadValues =
	({ conta_condominio, instituicaoProps: { formatadoresPayloadValues, ...instituicaoProps }, ...props }: any) =>
	(values: ParameterValuesProps) => {
		const tipoIntegracaoId = values.tipo_integracao.id;
		const formatadoresPayloadValuesPorIntegracao = formatadoresPayloadValues[tipoIntegracaoId];
		const payload = {};
		Object.assign(payload, formataPayloadValuesFixos({ conta_condominio }));
		for (const formatador of Object.values(formatadoresPayloadValuesPorIntegracao) as any) {
			Object.assign(payload, formatador({ ...props, conta_condominio, instituicaoProps, values, payload }));
		}
		return payload;
	};

export default getFormataPayloadValues;
