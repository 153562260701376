import { sub, add, set } from 'date-fns';

import { IPeriodoOption } from './types';

const TODAY = new Date();
TODAY.setHours(10, 0, 0);

const periodoOptions: IPeriodoOption[] = [
	{
		label: 'Limpar',
		startDate: undefined,
		endDate: undefined,
	},
	{
		label: 'Mês atual',
		startDate: set(TODAY, { date: 1 }),
		endDate: sub(add(set(TODAY, { date: 1 }), { months: 1 }), { days: 1 }),
	},
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

export default periodoOptions;
