import React, { useContext, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { CustomDialogBody } from '../../../../common/ModalContext';
import MultiSelect from 'components/common/Selects';
import { UnidadePrincipalContext, UnidadesSubordinadasContext } from './contextsUnidadesSubordinadas';

const ModalUnidadesDisponiveis = () => {
	const notify = useNotify();
	const { dP, record, setUnidadesSubordinadas } = useContext(UnidadePrincipalContext);
	const { carregarUnidadesSubordinaveis, unidadesSubordinaveis } = useContext(UnidadesSubordinadasContext);
	const [unidades, setUnidades] = useState([]);
	const vincular = () => {
		dP.updateOnAction('unidades', {
			data: {
				unidades_subordinadas: unidades.map((v) => v.id),
				action: 'vincular_subordinadas',
				detail: true,
			},
			id: record?.id,
		})
			.then((response) => {
				const data = response?.data;
				if (data) {
					setUnidadesSubordinadas(data || []);
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
			});
	};

	useEffect(carregarUnidadesSubordinaveis, []);

	const handleSubmit = () => {
		vincular();
	};

	return (
		<CustomDialogBody
			title='Vincular Unidades'
			text='Selecione uma ou mais unidades disponíveis'
			form={{
				handleSubmit,
				valid: true,
				component: (
					<>
						<br></br>
						<MultiSelect
							variant='formatsUnidades'
							setValue={setUnidades}
							value={unidades}
							options={unidadesSubordinaveis}
							size='small'
						/>
					</>
				),
			}}
		/>
	);
};

export default ModalUnidadesDisponiveis;
