import React from 'react';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { useDataProvider, useVersion } from 'react-admin';

const NotificacaoQuantidadePendenteLeiturasAPP = () => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const [numeroNotificacoes, setNumeroNotificacoes] = React.useState(0);
	const dp = useDataProvider();
	const version = useVersion();

	const BuscaNumerosNotificacoes = React.useCallback(() => {
		dp.getSimple('leituras_cadastradas_aplicativo/get_quantidade_pendente', {
			filter: {
				id_condominio: condominioSelecionado?.id,
			},
		}).then(({ data }) => {
			setNumeroNotificacoes(data.quantidade_pendentes);
		});
	}, [condominioSelecionado]);

	React.useEffect(() => {
		BuscaNumerosNotificacoes();
	}, [BuscaNumerosNotificacoes, version]);

	if (numeroNotificacoes != 0) {
		return (
			<div
				style={{
					backgroundColor: 'red',
					color: 'white',
					fontSize: 10,
					width: 15,
					height: 15,
					borderRadius: 10,
					position: 'absolute',
					textAlign: 'center',
					marginTop: '-2.7%',
					marginLeft: '1.3%',
				}}
			>
				{numeroNotificacoes}
			</div>
		);
	}

	return null;
};

export default NotificacaoQuantidadePendenteLeiturasAPP;
