// importações
import React from 'react';
import { Edit, useNotify, useRedirect, useRefresh } from 'react-admin';
import ComunicadoGeralForm from './form';
import { ComunicadoProps, ComunicadoResponse } from './types';

export const ComunicadoGeralEdit = (props: ComunicadoProps) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response: ComunicadoResponse) => {
		const data = response?.data;
		if (data) {
			notify('Comunicado alterado com sucesso');
			redirect('list', props.basePath);
			refresh();
		}
	};

	return (
		<Edit
			component='div'
			title={'Editar Comunicado Geral'}
			onSuccess={onSuccess}
			mutationMode='pessimistic'
			{...props}
		>
			<ComunicadoGeralForm {...props} />
		</Edit>
	);
};
