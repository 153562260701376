import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { parseISO } from 'date-fns';
import { useDataProvider, useGetList, useListContext } from 'react-admin';
import { CondominiosContext } from '../../../context/CondominioContextProvider';

export const MovimentacoesContext = createContext();

export const MovimentacoesContextProvider = ({ children }) => {
	const { condominioSelecionado, setCondominio, setCondominioPorId } = useContext(CondominiosContext);
	const dataProvider = useDataProvider();
	const [condominio, setStateCondominio] = useState(condominioSelecionado?.id);
	const [datas_after, setDatas_after] = useState();
	const [datas_before, setDatas_before] = useState();
	const [contaBancaria, setContaBancaria] = useState();
	const [ocultar_previsoes, setOcultar_previsoes] = useState(false);
	const { filterValues, setFilters, loaded } = useListContext();
	const [firstLoad, setFirstLoad] = useState(true);
	const [forceRefresh, setForceRefresh] = useState(false);
	const [data, setData] = useState();
	const { data: condominiosData, ids: condominiosIds } = useGetList(
		'condominios',
		{ perPage: 10000, page: 1 },
		{ field: 'nome', order: 'ASC' },
		{ situacao: 'A' }
	);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const {
		data: contasData,
		ids: contasIds,
		loaded: contasLoaded,
	} = useGetList(
		'conta_condominio_movimentacoes',
		{ perPage: 10000, page: 1 },
		{ field: 'nome_conta', order: 'ASC' },
		{
			id_condominio: forceRefresh || (condominiosData || {})[condominio]?.id === 0 ? 0 : condominio,
			situacao: 'A',
			saldo_total: true,
		}
	);
	const contasRef = useRef(contasIds);
	const [saldoInicial, setSaldoInicial] = useState(0);
	const [entradas, setEntradas] = useState(0);
	const [saidas, setSaidas] = useState(0);
	const [saldoFinal, setSaldoFinal] = useState(0);
	const [contasBancarias, setContasBancarias] = useState([]);
	const [idsSelecionados, setIdsSelecionados] = useState([]);

	const id_conta_bancaria = contaBancaria?.id || null;

	const fetchConta = () => {
		if (contasIds !== contasRef.current && contasLoaded && contasData[contasIds[0]]?.id_condominio === condominio) {
			if (!contaBancaria?.id || !contasIds.includes(contaBancaria?.id)) {
				setContaBancaria(contasData[contasIds[0]]);
			}
			contasRef.current = contasIds;
		}
		if (
			contasLoaded &&
			// eslint-disable-next-line no-prototype-builtins
			!contasData[contasIds[0]]?.hasOwnProperty('nome_conta') &&
			!filterValues.id_conta_bancaria
		) {
			setContaBancaria(null);
		}
	};

	const fetchContas = useCallback(() => {
		if (contasIds && contasData) setContasBancarias(contasIds.map((id) => contasData[id]).filter((c) => !!c) || []);
	}, [contasIds, contasData, setContasBancarias]);

	useEffect(fetchConta, [condominio, contasIds, id_conta_bancaria]);
	useEffect(fetchContas, [contasIds]);

	const refresh = () => {
		if (forceRefresh || (condominio && datas_after && datas_before && contaBancaria?.id && !firstLoad)) {
			dataProvider
				.getList('movimentacao_bancaria', {
					pagination: { perPage: 10000, page: 1 },
					filter: {
						condominio,
						datas_after,
						datas_before,
						id_conta_bancaria: contaBancaria?.id,
						ocultar_previsoes,
					},
					sort: { field: 'data_movimentacao', order: 'ASC' },
				})
				.then((response) => {
					let data = response?.data;
					if (data) {
						data = data.sort((dA, dB) => parseISO(dA.data) - parseISO(dB.data));

						const relatorioMovimentacoes = data.reduce(
							(ac, row, index) => {
								row.valor = parseFloat(row.valor);
								if (index === 0) {
									ac.saldoInicial = row.saldo_anterior ? row.saldo_anterior[0] : 0;
									ac.saldoFinal = ac.saldoInicial;
								} else if (row?.situacao !== 'P') {
									if (row.conta_b_destino === contaBancaria?.id) {
										ac.entradas += row.valor;
										ac.saldoFinal += row.valor;
									} else {
										ac.saidas += row.valor;
										ac.saldoFinal -= row.valor;
									}
								}
								return ac;
							},
							{
								saldoInicial: 0,
								saldoFinal: 0,
								entradas: 0,
								saidas: 0,
							}
						);

						setData(data);

						setSaldoInicial(relatorioMovimentacoes.saldoInicial);
						setEntradas(relatorioMovimentacoes.entradas);
						setSaidas(relatorioMovimentacoes.saidas);
						setSaldoFinal(relatorioMovimentacoes.saldoFinal);

						forceRefresh && setForceRefresh(false);
					}
				});
			setCondominioPorId(condominio);
			setFilters({
				condominio,
				datas_after,
				datas_before,
				id_conta_bancaria: contaBancaria?.id,
				ocultar_previsoes,
			});
		}
	};

	useEffect(refresh, [
		condominio,
		condominioSelecionadoId,
		datas_after,
		datas_before,
		id_conta_bancaria,
		ocultar_previsoes,
		forceRefresh,
	]);

	const checkCondominio = () => {
		const condominiosIdsFiltrados = (condominiosIds || []).filter((id) => Boolean(id));
		if (!condominioSelecionadoId && !condominio && condominiosData && condominiosData[condominiosIdsFiltrados[0]]) {
			setStateCondominio(condominiosData[condominiosIdsFiltrados[0]].id);
			setCondominio(condominiosData[condominiosIdsFiltrados[0]]);
		}
	};

	useEffect(checkCondominio, [condominioSelecionadoId, condominio, condominiosData, condominiosIds]);

	const defaultFilters = () => {
		if (firstLoad && loaded && condominiosIds && contasIds) {
			const condominiosIdsFiltrados = (condominiosIds || []).filter((id) => Boolean(id));
			if (condominiosIdsFiltrados.find((id) => `${id}` === condominioSelecionadoId)) {
				setStateCondominio(condominioSelecionadoId);
			} else {
				setStateCondominio(parseInt(condominioSelecionadoId) || null);
			}
			if (filterValues.datas_after) {
				setDatas_after(filterValues.datas_after);
			}
			if (filterValues.datas_before) {
				setDatas_before(filterValues.datas_before);
			}
			if (filterValues.id_conta_bancaria && filterValues.condominio) {
				setContaBancaria(contasData?.[filterValues.id_conta_bancaria]);
			}
			if (filterValues.ocultar_previsoes) {
				setOcultar_previsoes(filterValues.ocultar_previsoes);
			}
			dataProvider
				.getList('movimentacao_bancaria', {
					pagination: { perPage: 10000, page: 1 },
					filter: filterValues,
					sort: { field: 'data', order: 'ASC' },
				})
				.then((response) => {
					let data = response?.data;
					if (data) {
						data = data.sort((dA, dB) => parseISO(dA.data) - parseISO(dB.data));

						const relatorioMovimentacoes = data.reduce(
							(ac, row, index) => {
								row.valor = parseFloat(row.valor);
								if (index === 0) {
									ac.saldoInicial = row.saldo_anterior ? row.saldo_anterior[0] : 0;
									ac.saldoFinal = ac.saldoInicial;
								} else {
									if (row.conta_b_destino === contaBancaria?.id) {
										ac.entradas += row.valor;
										ac.saldoFinal += row.valor;
									} else {
										ac.saidas += row.valor;
										ac.saldoFinal -= row.valor;
									}
								}
								return ac;
							},
							{
								saldoInicial: 0,
								saldoFinal: 0,
								entradas: 0,
								saidas: 0,
							}
						);

						setData(data);

						setSaldoInicial(relatorioMovimentacoes.saldoInicial);
						setEntradas(relatorioMovimentacoes.entradas);
						setSaidas(relatorioMovimentacoes.saidas);
						setSaldoFinal(relatorioMovimentacoes.saldoFinal);
					}
				});
			setFirstLoad(false);
		}
	};

	useEffect(defaultFilters, [filterValues, loaded, condominiosIds, contasIds]);

	const movValue = useMemo(
		() => ({
			condominio,
			setStateCondominio,
			setCondominio,
			datas_after,
			setDatas_after,
			datas_before,
			setDatas_before,
			id_conta_bancaria: contaBancaria?.id || null,
			data,
			setData,
			condominiosData,
			condominiosIds,
			contasBancarias,
			contaBancaria,
			contasData: contasData && Object.values(contasData)[0]?.id ? contasData : {},
			contasIds: contasIds && contasIds[0] ? contasIds : [],
			ocultar_previsoes,
			forceRefresh,
			setContaBancaria,
			setOcultar_previsoes,
			setForceRefresh,
			saldoInicial,
			entradas,
			saidas,
			saldoFinal,
			idsSelecionados,
			setIdsSelecionados,
		}),
		[
			condominio,
			setStateCondominio,
			setCondominio,
			datas_after,
			setDatas_after,
			datas_before,
			setDatas_before,
			data,
			setData,
			condominiosData,
			condominiosIds,
			contaBancaria,
			contasBancarias,
			contasData,
			contasIds,
			ocultar_previsoes,
			forceRefresh,
			setContaBancaria,
			setOcultar_previsoes,
			setForceRefresh,
			saldoInicial,
			entradas,
			saidas,
			saldoFinal,
			idsSelecionados,
			setIdsSelecionados,
		]
	);

	return <MovimentacoesContext.Provider value={movValue}>{children}</MovimentacoesContext.Provider>;
};
