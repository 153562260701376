import React from 'react';

import { Box } from '@material-ui/core';

import BeneficiarioFields from '../../commons/BeneficiarioFields';
import EmitirBoletosField from './EmitirBoletosField';
import TipoIntegracoesField from './TipoIntegracoesField';
import ExibeSeEmitirBoletosTrue from '../../commons/ExibeSeEmitirBoletosTrue';
import TipoBeneficiarioField from './TipoBeneficiarioField';

const BoxesIntegracao = () => (
	<Box flexDirection='column' display='flex'>
		<Box flexDirection='row' display='flex'>
			<TipoIntegracoesField />
			<TipoBeneficiarioField />
		</Box>
		<BeneficiarioFields />
	</Box>
);

const TabEmissaoBoletos = () => (
	<Box>
		<Box flexDirection='row' display='flex'>
			<EmitirBoletosField />
		</Box>
		<ExibeSeEmitirBoletosTrue>
			<BoxesIntegracao />
		</ExibeSeEmitirBoletosTrue>
	</Box>
);

export default TabEmissaoBoletos;
