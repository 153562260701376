import { formataOptionsPadrao } from 'components/resources/condominios/ModalEditaContaBancaria/utils/functions';

const getOptions = ({ opcoesDadosBancarios, optionsDefault = {} }, state) => {
	const options = formataOptionsPadrao({
		state,
		instituicaoCodigo: '341',
		opcoesDadosBancarios,
		optionsDefault,
		agencia: 'agencias_itau',
	});
	options['DEFAULT'].instrucao_1 = options['DEFAULT'].instrucao;
	options['DEFAULT'].instrucao_2 = options['DEFAULT'].instrucao;
	return options;
};

export default getOptions;
