import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from 'react-final-form';
import { sanitizeListNumberFormatBRLProps, NumberFormatBRL } from '../common/CurrencyInput';
import InputAdornment from '@material-ui/core/InputAdornment';

const composeValidators =
	(...validators) =>
	(value) =>
		validators.reduce((error, validator) => error || validator(value), undefined);

export const CurrencyTextFieldWithAdornment = ({
	style,
	error = false,
	helperText = null,
	value,
	name,
	id,
	label,
	onChange,
	disabled = false,
	margin,
	fullWidth = false,
	...props
}) => (
	<TextField
		style={style}
		error={error}
		helperText={helperText}
		value={value}
		name={name}
		id={id}
		label={label}
		onChange={onChange}
		disabled={Boolean(disabled)}
		margin={margin}
		inputProps={sanitizeListNumberFormatBRLProps(props)}
		InputProps={{
			inputComponent: NumberFormatBRL,
			startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
		}}
		fullWidth={fullWidth}
	/>
);

const CurrencyInputWithAdornment = ({
	id,
	name,
	disabled,
	onChange,
	label,
	validate = [],
	style,
	margin,
	fullWidth = false,
	...props
}) => (
	<Field name={name} id={id} disabled={disabled} validate={composeValidators(...validate)} fullWidth={fullWidth}>
		{({ input, meta }) => (
			<CurrencyTextFieldWithAdornment
				{...{
					...props,
					style,
					error: !!meta.error,
					helperText: meta.error,
					value: input.value,
					name: input.name,
					onChange: (event) => {
						if (onChange) onChange(event);
						input.onChange(event);
					},
					id,
					label,
					disabled,
					margin,
					fullWidth,
				}}
			/>
		)}
	</Field>
);

export default CurrencyInputWithAdornment;
