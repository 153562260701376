import React, { useContext } from 'react';

import { FinalFormCurrencyInput } from '../../../../common/CurrencyInput';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const EditaContaBancariaCurrencyInput = ({ source, disabled: standardDisabled = false, ...standardProps }) => {
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { props } = useContext(DadosPorTipoIntegracaoContext);
	const { disabled = false, ...sourceProps } = props[source] || {};

	return (
		<FinalFormCurrencyInput
			source={source}
			disabled={isInativo || disabled || standardDisabled}
			margin='dense'
			decimalScale={2}
			fullWidth
			{...standardProps}
			{...sourceProps}
		/>
	);
};

export default EditaContaBancariaCurrencyInput;
