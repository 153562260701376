import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';

import ContaReceitaField from './ContaReceitaField';
import { DialogActionsPadrao } from './dialogActions';
import DialogTitleEditaConfiguracaoLeitura from './DialogTitleEditaConfiguracaoLeitura';
import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';
import InputReferencia from './InputReferencia';
import FormEdicaoConfiguracaoLeitura from './FormEdicaoConfiguracaoLeitura';
import MetodoCalculoField from './MetodoCalculoField';
import CamposMetodoPercentual from '../../componentes/CamposMetodoPercentual';
import LeituraField from '../../componentes/InputLeitura';
import MultiSelect from 'components/common/Selects';
import { useDataProvider } from 'react-admin';

const FormPercentual = () => {
	const { dadosConfig, situacao, setDadosConfig } = useContext(EditaConfiguracaoLeituraContext);
	const [unidades, setUnidades] = React.useState([]);
	const [unidadesData, setUnidadesData] = React.useState([]);
	const dp = useDataProvider();
	const handleConfigSave = () => {
		setDadosConfig((v) => {
			v.unidades = (unidades || []).map((i) => i.id);
			return v;
		});
	};
	React.useEffect(() => {
		dp.getList('unidades', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'unidade', order: 'ASC' },
			filter: {
				id_condominio: dadosConfig.id_condominio,
				situacao: 'A',
			},
		}).then((response) => {
			const data = response?.data;
			if (data) {
				const dataDict = data.reduce((dict, item) => {
					dict[item.id] = item;
					return dict;
				}, {});
				setUnidadesData(data);
				if (dadosConfig.unidades)
					setUnidades(
						dadosConfig.unidades.reduce((list, item) => {
							const unidade = dataDict[item];
							if (unidade) list.push(unidade);
							return list;
						}, [])
					);
			}
		});
	}, [dadosConfig.id_condominio, dp, dadosConfig.unidades]);
	return (
		<FormEdicaoConfiguracaoLeitura
			initialValues={{
				faixas_leitura: dadosConfig.faixas_leitura,
				leituras_para_calculo: dadosConfig.leituras_para_calculo,
				nao_cobrar_taxa: dadosConfig.nao_cobrar_taxa,
				acesso_app: dadosConfig.acesso_app,
				percentual: dadosConfig.percentual,
			}}
			render={({
				invalid,
				values: {
					faixas_leitura,
					leituras_para_calculo,
					nao_cobrar_taxa,
					acesso_app,
					percentual,
					...dadosConfigEdicao
				},
			}) => (
				<>
					<DialogTitleEditaConfiguracaoLeitura />
					<DialogContent>
						<Box>
							<InputReferencia disabled={true} />
							<ContaReceitaField />
						</Box>
						<Box style={{ 'margin-top': '1%' }}>
							<MetodoCalculoField />
						</Box>
						<Box display='flex' style={{ 'margin-top': '1%' }}>
							<CamposMetodoPercentual
								dadosConfig={dadosConfig}
								disabled={situacao !== 'A'}
								percentual={percentual}
							/>
							<LeituraField />
						</Box>
						<Box style={{ 'margin-top': '1%' }}>
							<MultiSelect
								variant={'formatsUnidades'}
								options={unidadesData}
								setValue={setUnidades}
								value={unidades}
								onBlur={handleConfigSave}
								withButtonAction={false}
							/>
						</Box>
					</DialogContent>
					<DialogActionsPadrao
						{...{
							dadosConfigEdicao,
							invalid,
							faixas_leitura,
							leituras_para_calculo,
							nao_cobrar_taxa,
							acesso_app,
							percentual,
						}}
					/>
				</>
			)}
		/>
	);
};

export default FormPercentual;
