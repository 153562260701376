import React from 'react';

import { Box, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';

import { TTipoIndice, TSetTipoIndice } from '../../types';

export interface ISelectTipoIndiceProps {
	tipoIndice: TTipoIndice;
	setTipoIndice: TSetTipoIndice;
	desabilitaInputSeNaoForManual?: boolean;
}

interface IOptionTipoIndice {
	id: TTipoIndice;
	name: 'IGPM' | 'INPC' | 'IPCA' | 'TJSC' | 'Selic' | 'Manual';
}

type TOptionTipoIndiceById = {
	[key in TTipoIndice]: IOptionTipoIndice;
};

export const optionsTipoIndice: IOptionTipoIndice[] = [
	{ id: 'IGPM', name: 'IGPM' },
	{ id: 'INPC', name: 'INPC' },
	{ id: 'IPCA', name: 'IPCA' },
	{ id: 'TJSC', name: 'TJSC' },
	{ id: 'SELIC', name: 'Selic' },
	{ id: 'MANUAL', name: 'Manual' },
];

export const optionsTipoIndicePorId: TOptionTipoIndiceById = {
	IGPM: { id: 'IGPM', name: 'IGPM' },
	INPC: { id: 'INPC', name: 'INPC' },
	IPCA: { id: 'IPCA', name: 'IPCA' },
	TJSC: { id: 'TJSC', name: 'TJSC' },
	SELIC: { id: 'SELIC', name: 'Selic' },
	MANUAL: { id: 'MANUAL', name: 'Manual' },
};

export const listaValoresTipoIndice = optionsTipoIndice.map((option) => option.id);

export const SelectTipoIndice = ({
	tipoIndice,
	setTipoIndice,
	desabilitaInputSeNaoForManual,
}: ISelectTipoIndiceProps) => {
	return (
		<Box width='100%'>
			<Autocomplete
				id='select-indice-monetario'
				itemID='select-indice-monetario-item'
				value={optionsTipoIndicePorId[tipoIndice]}
				options={optionsTipoIndice}
				renderInput={(params) => <TextField {...params} label='Selecionar Índices' />}
				renderOption={(option) => option.name}
				getOptionLabel={(option) => option.name}
				onChange={(_, newValue) => {
					setTipoIndice(newValue.id);
				}}
				size='small'
				autoHighlight
				disableClearable
				fullWidth
				disabled={Boolean(desabilitaInputSeNaoForManual)}
			/>
		</Box>
	);
};

export default SelectTipoIndice;
