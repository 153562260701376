import React, { useState } from 'react';
import { format, add, intervalToDuration, isAfter, isEqual } from 'date-fns';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useRequestOptionsIdentificacaoArrecadacao } from 'components/common/Selects/hooks';
import { BoxDivisor } from '../../../common/Formulario';
import { RelatorioMonthPicker, TODAY } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesImpressaoPlanilhaCheckboxGroup = ({
	handleChange,
	imprimirAcumulado,
	imprimirMedia,
	imprimirAssinatura,
	disabled,
}) => {
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAcumulado}
							onChange={(e, checked) => handleChange(checked, 'imprimir_acumulado')}
							name='imprimir_acumulado'
							disabled={disabled}
						/>
					}
					label='Imprimir acumulado'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirMedia}
							onChange={(e, checked) => handleChange(checked, 'imprimir_media')}
							name='imprimir_media'
							disabled={disabled}
						/>
					}
					label='Imprimir médias'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</FormGroup>
		</FormControl>
	);
};

export const PlanilhaArrecadacaoDivisor = ({ idCondominio, tipoRelatorio }) => {
	const [mesInicial, setMesInicial] = useState(TODAY);
	const [mesFinal, setMesFinal] = useState(TODAY);
	const [identificacao, setIdentificacao] = useState({
		id: 0,
		nome: 'Todas',
	});
	const {
		opcoesImpressao: { imprimir_acumulado, imprimir_media, imprimir_assinatura },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);
	const { options: identificacoes } = useRequestOptionsIdentificacaoArrecadacao(idCondominio);
	const requestData = {
		id_condominio: idCondominio,
		mes_inicial: format(mesInicial, 'MM/yyyy'),
		mes_final: format(mesFinal, 'MM/yyyy'),
		identificacao: identificacao ? identificacao.id : 0,
		imprimir_acumulado: imprimir_acumulado,
		imprimir_media: imprimir_media,
		imprimir_assinatura: imprimir_assinatura,
		nome_relatorio: tipoRelatorio.action,
	};
	React.useEffect(() => {
		if (identificacao) {
			setIdentificacao({
				id: 0,
				nome: 'Todas',
			});
		}
	}, [idCondominio]);
	return (
		<BoxDivisor titulo='Planilha de Arrecadação'>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<Box display='flex'>
						<RelatorioMonthPicker
							style={{ marginRight: '1em' }}
							label='Mês Inicial'
							handleChange={(newValue) => {
								setMesInicial(newValue);
								if (
									intervalToDuration({
										start: newValue || mesInicial,
										end: mesFinal,
									}).years >= 1 ||
									isAfter(newValue, mesFinal)
								) {
									setMesFinal(add(newValue, { months: 11 }));
								}
							}}
							value={mesInicial}
							fullWidth
						/>
						<RelatorioMonthPicker
							label='Mês Final'
							handleChange={(newValue) => setMesFinal(newValue)}
							value={mesFinal}
							minDate={mesInicial || TODAY}
							maxDate={
								mesInicial
									? add(mesInicial, {
											months: 11,
									  })
									: add(TODAY, { months: 11 })
							}
							fullWidth
						/>
					</Box>
				</Box>
				<Box flex={1}>
					<Autocomplete
						value={identificacao}
						onChange={(_, newValue) => setIdentificacao(newValue)}
						options={[...Object.values(identificacoes).filter((v) => v.id !== 0), { id: 0, nome: 'Todas' }]}
						getOptionLabel={(o) => o.nome}
						getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
						disabled={identificacoes.length === 1}
						renderInput={(params) => (
							<TextField {...params} label='Identificação de Arrecadação' variant='outlined' />
						)}
						clearText='Limpar'
						autoHighlight
						noOptionsText='Nenhuma identificação disponível'
						size='small'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<Box flex={1}>
					<OpcoesImpressaoPlanilhaCheckboxGroup
						handleChange={handleChangeOpcoesImpressao}
						imprimirAcumulado={imprimir_acumulado}
						imprimirMedia={imprimir_media}
						imprimirAssinatura={imprimir_assinatura}
						disabled={isEqual(mesInicial, mesFinal)}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};
