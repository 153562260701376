import React from 'react';

import { PrimaryChip, WarningChip, SyndikosRedChip, SuccessChip } from 'components/common/chips';
import SituacaoField from './SituacaoField';

export const TipoSituacaoField = (props) => (
	<SituacaoField
		{...props}
		chips={{
			4: <PrimaryChip value='Na fila de envio' />,
			3: <WarningChip value='Sem E-mail' />,
			2: <SyndikosRedChip value='Falha ao Enviar' />,
			1: <PrimaryChip value='Enviado' />,
		}}
		DefaultChip={<SuccessChip value='Envio Pendente' />}
	/>
);

export default TipoSituacaoField;
