import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDataProvider } from 'ra-core';
import { GestaoContextValue, GestaoProviderProps } from './type';
import { CustomDataProvider, Pessoa } from 'types/tpyesGlobal';
import { GestaData, GestaoFiliacaoData } from '../types';
import { useNotify } from 'react-admin';
import { TCondominio } from 'types/condominio';
import { CustomGetSimpleParams } from 'components/resources/documentos/types';

const GestaoContext = createContext<GestaoContextValue>({
	condominioRecord: {} as TCondominio,
	pessoas: [],
	gestoes: [],
	modGestao: 'create',
	setModGestao() {},
	gestao: {},
	setGestao() {},
	getGestoes() {},
	reloadPessoa() {},
});

const GestaoProvider: React.FC<GestaoProviderProps> = React.memo(({ children, condominioRecord }) => {
	const [reload, setReload] = useState<number>(0);
	const [pessoas, setPessoas] = useState<Pessoa[]>([]);
	const [gestoes, setGestoes] = React.useState<GestaData[]>([]);
	const [modGestao, setModGestao] = useState<'create' | 'edit'>('create');
	const [gestao, setGestao] = React.useState<GestaoFiliacaoData>();
	const dataProvider: CustomDataProvider = useDataProvider();
	const notify = useNotify();
	const [loadingGestao, setLoadingGestao] = React.useState(false);
	const [loadingPessoa, setLoadingPessoa] = React.useState(false);

	const initPessoa = () => {
		if (loadingPessoa) return;
		setLoadingPessoa(true);
		dataProvider
			.getSimple('pessoas', {
				pagination: { page: 1, perPage: 100000 },
				sort: { field: 'nome', order: 'ASC' },
				filter: { situation: 'A' },
			})
			.then((response) => {
				setLoadingPessoa(false);
				const data: Pessoa[] = response?.data.results;
				return setPessoas(data || []);
			})
			.catch((e) => {
				setLoadingPessoa(false);
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao carregar as pessoas!', { type: 'error' });
			});
	};

	const getGestoes = useCallback((params: CustomGetSimpleParams) => {
		if (loadingGestao) return;
		setLoadingGestao(true);
		dataProvider
			.getSimple('gestao', params)
			.then((response) => {
				setLoadingGestao(false);
				const data: GestaData[] = response?.data.results;
				return setGestoes(data || []);
			})
			.catch((e) => {
				setLoadingGestao(false);
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao carregar as gestões!', { type: 'error' });
			});
	}, []);

	const reloadPessoa = () => {
		initPessoa();
	};
	useEffect(() => {
		initPessoa();
		getGestoes({
			pagination: { page: 1, perPage: 100000 },
			sort: { field: 'nome', order: 'ASC' },
			filter: { condominio: condominioRecord?.id },
		});
	}, [condominioRecord, reload]);

	const providerValue: GestaoContextValue = useMemo(
		() => ({
			condominioRecord,
			pessoas,
			setReload,
			gestoes,
			getGestoes,
			modGestao,
			setModGestao,
			gestao,
			setGestao,
			reloadPessoa,
		}),
		[
			condominioRecord,
			pessoas,
			gestoes,
			getGestoes,
			reload,
			setReload,
			modGestao,
			setModGestao,
			gestao,
			setGestao,
			reloadPessoa,
		]
	);

	return <GestaoContext.Provider value={providerValue}>{children}</GestaoContext.Provider>;
});

export const useGestaoContext = () => React.useContext<GestaoContextValue>(GestaoContext);
export default GestaoProvider;
