import React, { Dispatch, createContext } from 'react';
import { SelectsMembrosType } from '../modals/types';
import { SelectsMembrosContextValue } from './type';

const SelectsMembrosContext = createContext<SelectsMembrosContextValue>({
	selectsMembros: [],
	setSelectsMembros: () => {},
});

const SelectsMembrosProvider: React.FC = React.memo(({ children }) => {
	const [selectsMembros, setSelectsMembros] = React.useState<SelectsMembrosType[]>([]);

	const providerValue = React.useMemo(
		() => ({
			selectsMembros,
			setSelectsMembros,
		}),
		[selectsMembros, setSelectsMembros]
	);

	return <SelectsMembrosContext.Provider value={providerValue}>{children}</SelectsMembrosContext.Provider>;
});

export const useSelectsMembrosContext = () => React.useContext(SelectsMembrosContext);
export default SelectsMembrosProvider;
