import React, { useState, createContext, useContext, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { makeStyles } from '@material-ui/core';

import { ButtonCancel } from '../common/buttons/ButtonCancel';

// const classes = useStyles(props);

const useStyles = makeStyles(
	(theme) => ({
		deleteButton: {
			color: theme.palette.syndikosRed.main,
			'&:hover': {
				backgroundColor: alpha(theme.palette.syndikosRed.main, 1),
				color: alpha('#fff', 1),

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'RaDeleteWithConfirmButton' }
);

export const ModalContext = createContext();

export const ModalContextProvider = ({ Context = ModalContext, children }) => {
	const [modalValue, setModalValue] = useState({
		open: false,
		dialogBody: <></>,
	});

	const modalProviderValue = useMemo(() => ({ modalValue, setModalValue }), [modalValue, setModalValue]);

	return <Context.Provider value={modalProviderValue}>{children}</Context.Provider>;
};

export const CustomDialogBody = ({
	Context = ModalContext,
	title,
	customActions,
	remove = undefined,
	text = <></>,
	form = {},
	onCloseAction = undefined,
	customSubmitLabel = undefined,
	className = undefined,
	closeInSubmit = true,
	...props
}) => {
	const { setModalValue } = useContext(Context);

	const classes = useStyles(props);

	const handleClose = () => {
		onCloseAction && onCloseAction();
		setModalValue((v) => ({ ...v, open: false }));
	};

	const handleSubmit = () => {
		form.handleSubmit();
		if (closeInSubmit) handleClose();
	};

	return (
		<>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{text && <Typography>{text}</Typography>}
				{form && form.component}
			</DialogContent>
			<DialogActions>
				{customActions ? (
					customActions
				) : form ? (
					<>
						<ButtonCancel onClick={handleClose} />
						<Button
							id={remove ? 'botao-remover-custom-dialog-body' : 'botao-salvar-custom-dialog-body'}
							size='small'
							disabled={!form.valid}
							onClick={handleSubmit}
							className={remove ? classes.deleteButton : 'default'}
							variant='text'
							startIcon={remove ? <DeleteIcon /> : <CheckIcon />}
							color={!remove ? 'primary' : ''}
						>
							{customSubmitLabel ? customSubmitLabel : remove ? 'Remover' : 'Salvar'}
						</Button>
					</>
				) : (
					<Button
						id='botao-fechar-custom-dialog-body'
						size='small'
						onClick={handleClose}
						startIcon={<CloseIcon />}
					>
						Fechar
					</Button>
				)}
			</DialogActions>
		</>
	);
};

export const CustomDialog = ({ Context = ModalContext, backdropClick = true, ...props }) => {
	const { modalValue, setModalValue } = useContext(Context);

	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<Dialog
			open={modalValue.open}
			onClose={(e, reason) => {
				if (!backdropClick && reason === 'backdropClick') return;
				handleClose();
			}}
			{...props}
			PaperProps={{ style: { minWidth: '600px', maxWidth: '90vw' } }}
		>
			{modalValue.dialogBody}
		</Dialog>
	);
};
