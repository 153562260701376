import React, { useState, useCallback, useContext, useEffect } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { TooltipIconButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { TooltipVoltarIconButton } from 'components/common/buttons/BotaoVoltar';
import DropZone from './DropZone';
import ImportGaragensContext from './ImportGaragensContext';
import OpcoesImportacaoUnidadesGaragensModal from './OpcoesImportacaoUnidadesGaragensModal';

import { CondominioSelectGaragens } from '../common/CondominioSelect';

const useStylesGridTemplate = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gap: '1rem',
	},
}));

const DropZoneImportGaragensModal = () => {
	const classes = useStylesGridTemplate();
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const { condominio, files, SetErrosNoArquivo, setFiles, setModalStep, setResultadoImport } =
		useContext(ImportGaragensContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [valid, setValid] = useState(false);
	const [processing, setProcessing] = useState(false);

	const validate = useCallback(() => {
		setValid(condominio?.id !== undefined && !!files.length);
	}, [condominio, files]);

	useEffect(validate, [condominio, files.length]);

	const processarArquivos = useCallback(() => {
		setProcessing(true);
		const formData = new FormData();
		formData.append('id_condominio_id', condominio.id);
		formData.append('csv', Array.from(files)[0].file);
		dataProvider
			.processarArquivosRetorno('garagens_unidades/importar', {
				data: formData,
			})
			.then((response) => {
				setProcessing(false);
				const data = response?.data;
				if (data) {
					setResultadoImport(data?.result || []);
					SetErrosNoArquivo(data?.erros_no_arquivo || []);
				}
				setModalStep('2');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setResultadoImport([]);
				SetErrosNoArquivo([]);
				setProcessing(false);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
			});
	}, [setProcessing, condominio, files, dataProvider, setResultadoImport, SetErrosNoArquivo, notify, setModalStep]);

	return (
		<CustomDialogBodySizeEditable
			title='Importação de Garagens'
			customActions={[
				<TooltipVoltarIconButton
					onClick={() => {
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <OpcoesImportacaoUnidadesGaragensModal />,
						}));
					}}
				/>,
				<TooltipIconButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />,
				<TooltipIconButtonConfirm
					title='Processar Arquivos'
					id='process_remessas'
					disabled={!valid || processing}
					onClick={() => {
						setResultadoImport([]);
						SetErrosNoArquivo([]);
						processarArquivos();
					}}
				/>,
			]}
			form={{
				component: processing ? (
					<Box display='flex' justifyContent='center'>
						<CircularProgress />
					</Box>
				) : (
					<>
						<Box className={classes.container}>
							<CondominioSelectGaragens disabled={processing} />
						</Box>
						<DropZone filesSelected={files} setFilesSelected={setFiles} />
					</>
				),
			}}
		/>
	);
};

export default DropZoneImportGaragensModal;
