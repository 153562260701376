import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const TabelaDadosNossoNumero = ({ recebimentosNossoNumero }) => (
	<TableContainer component={Paper} style={{ marginTop: '1em' }}>
		<Table size='small'>
			<TableHead>
				<TableRow>
					<TableCell>Unidade</TableCell>
					<TableCell>Responsável</TableCell>
					<TableCell>Nosso Número</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{recebimentosNossoNumero.map((row) => (
					<TableRow key={row.id}>
						<TableCell>{row.nome_unidade}</TableCell>
						<TableCell>{row.responsavel}</TableCell>
						<TableCell>{row.nosso_numero}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
);

export default TabelaDadosNossoNumero;
