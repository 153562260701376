import React, {
	Children,
	cloneElement,
	createContext,
	isValidElement,
	useCallback,
	useContext,
	useMemo,
	useRef,
} from 'react';
import {
	Button,
	FormInput,
	IconButtonWithTooltip,
	ValidationError,
	useArrayInput,
	useRecordContext,
	useSimpleFormIteratorStyles,
} from 'react-admin';

import { FormHelperText, Typography, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

const getItemLabel = (index) => index + 1;

export const CustomFormIteratorContext = createContext({
	total: 0,
	add: () => {},
	remove: () => {},
	reOrder: () => {},
});

export function CustomFormIterator(props) {
	const { basePath, children, resource, source, disabled, variant, margin } = props;

	const record = useRecordContext();

	const recordRef = useRef(null);

	if (record) recordRef.current = record[source];

	const classes = useSimpleFormIteratorStyles(props);

	const { fields, meta } = useArrayInput(props);

	const { error, submitFailed } = meta;

	const nextId = useRef(fields && fields.length ? fields.length : 0);

	const ids = useRef(nextId.current > 0 ? Array.from(Array(nextId).keys()) : []);

	const addField = useCallback(
		(field) => {
			ids.current.push(nextId.current++);
			fields.push(field);
			recordRef.current.push(field);
		},
		[fields]
	);

	const removeField = useCallback(
		(index) => {
			ids.current.splice(index, 1);
			fields.remove(index);
			recordRef.current.splice(index, 1);
		},
		[fields]
	);

	const handleReOrder = useCallback(
		(origin, destination) => {
			const item = ids.current[origin];
			ids.current[origin] = ids.current[destination];
			ids.current[destination] = item;
			recordRef.current[origin] = recordRef.current[destination];
			recordRef.current[destination] = item;
			fields.move(origin, destination);
		},
		[fields]
	);

	const context = useMemo(
		() => ({
			total: fields.length || 0,
			add: addField,
			remove: removeField,
			reOrder: handleReOrder,
		}),
		[fields.length, addField, removeField, handleReOrder]
	);

	const handleAddButtonClick = () => addField();

	return (
		<CustomFormIteratorContext.Provider value={context}>
			<ul className={classes.root}>
				{submitFailed && typeof error !== 'object' && error && (
					<FormHelperText error>
						<ValidationError error={error} />
					</FormHelperText>
				)}
				{fields.map((member, index) => {
					return (
						<CustomFormIteratorItem
							basePath={basePath}
							classes={classes}
							disabled={disabled}
							fields={fields}
							getItemLabel={getItemLabel}
							index={index}
							margin={margin}
							member={member}
							meta={meta}
							onRemoveField={removeField}
							onReorder={handleReOrder}
							record={(recordRef.current && recordRef.current[index]) || {}}
							removeButton={<CustomRemoveButton />}
							reOrderButtons={<ReOrderButtons />}
							resource={resource}
							source={source}
							variant={variant}
						>
							{children}
						</CustomFormIteratorItem>
					);
				})}
				{!disabled && (
					<li className={classes.line}>
						<span className={classes.action}>
							<AddItemButton onClick={handleAddButtonClick} />
						</span>
					</li>
				)}
			</ul>
		</CustomFormIteratorContext.Provider>
	);
}

export const CustomFormIteratorItemContext = createContext({
	total: 0,
	index: 0,
	remove: () => {},
	reOrder: () => {},
});

export function CustomFormIteratorItem(props) {
	const { basePath, children, classes, disabled, getItemLabel, index, margin, member, record, resource, variant } =
		props;
	const { total, reOrder, remove } = useContext(CustomFormIteratorContext);

	const handleRemoveButtonClick = (index) => () => {
		remove(index);
	};

	const context = useMemo(
		() => ({
			index,
			total,
			reOrder: (newIndex) => reOrder(index, newIndex),
			remove: () => remove(index),
		}),
		[index, total, reOrder, remove]
	);

	return (
		<CustomFormIteratorItemContext.Provider value={context}>
			<li className={classes.line}>
				<div>
					<div className={classes.indexContainer}>
						<Typography variant='body1' className={classes.index}>
							{getItemLabel(index)}
						</Typography>
						{!disabled && (
							<ReOrderButtons index={index} max={total} reOrder={reOrder} className='button-reorder' />
						)}
					</div>
				</div>
				<section className={classes.form}>
					{Children.map(children, (Input, inputIndex) => {
						if (!isValidElement(Input)) {
							return null;
						}
						const { source, ...inputProps } = Input.props;
						return (
							<FormInput
								basePath={inputProps.basePath || basePath}
								input={cloneElement(Input, {
									source: source ? `${member}.${source}` : member,
									index: source ? undefined : inputIndex,
									label:
										typeof Input.props.label === 'undefined'
											? source
												? `resources.${resource}.fields.${source}`
												: undefined
											: Input.props.label,
									disabled,
									...inputProps,
								})}
								record={record}
								resource={resource}
								variant={variant}
								margin={margin}
							/>
						);
					})}
				</section>
				{!disabled && (
					<span className={classes.action}>
						<CustomRemoveButton onClick={handleRemoveButtonClick(index)} className='button-remove' />
					</span>
				)}
			</li>
		</CustomFormIteratorItemContext.Provider>
	);
}

export function ReOrderButtons({ className }) {
	const { index, total } = useContext(CustomFormIteratorItemContext);

	return (
		<div className={className}>
			<IconButtonWithTooltip label='ra.action.move_up' size='small' disabled={index <= 0}>
				<ArrowUpwardIcon />
			</IconButtonWithTooltip>
			<IconButtonWithTooltip
				label='ra.action.move_down'
				size='small'
				disabled={total == null || index >= total - 1}
			>
				<ArrowDownwardIcon />
			</IconButtonWithTooltip>
		</div>
	);
}

export function AddItemButton(props) {
	return (
		<Button label='ra.action.add' {...props}>
			<AddIcon />
		</Button>
	);
}

export function CustomRemoveButton(props) {
	const {
		palette: { syndikosRed },
	} = useTheme();

	return <Button {...props}>{<DeleteOutline style={{ fontSize: 24, color: syndikosRed.main }} />}</Button>;
}
