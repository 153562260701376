import React, { createContext, useEffect, useState } from 'react';

export const UserThemeContext = createContext();

function UserThemeContextProvider({ children }) {
	// const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
	const [theme, setTheme] = React.useState('light');

	useEffect(() => {
		localStorage.setItem('theme', theme);
		Array.from(document.getElementsByTagName('html')).shift().setAttribute('schema', theme);
	}, [theme]);

	return <UserThemeContext.Provider value={{ theme, setTheme }}>{children}</UserThemeContext.Provider>;
}

export default UserThemeContextProvider;
