import React from 'react';

import { CondominiosContext } from 'context/CondominioContextProvider';
import useRequestOptionsContasBancarias from './useRequestOptionsContasBancarias';

export const ContasBancariasContext = React.createContext({
	options: [],
	data: {},
	ids: [],
	loading: false,
	loaded: false,
	alterarFiltro: (filtro, valor) => ({ filtro, valor }),
	alterarFiltros: (filtros = {}) => ({ ...filtros }),
	recoletaOptions: () => {},
});

const filtrosIniciais = { situacao: 'A', id_condominio: null };

const ContasBancariasContextProvider = ({ children }) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const { options, data, ids, loading, loaded, alterarFiltro, alterarFiltros, recoletaOptions } =
		useRequestOptionsContasBancarias(filtrosIniciais);

	const condominioSelecionadoId = condominioSelecionado?.id ?? null;

	const updateFiltroCondominio = () => {
		alterarFiltro('id_condominio', condominioSelecionadoId);
	};
	React.useEffect(updateFiltroCondominio, [condominioSelecionadoId]);

	const value = React.useMemo(
		() => ({
			options,
			data,
			ids,
			loading,
			loaded,
			alterarFiltro,
			alterarFiltros,
			recoletaOptions,
		}),
		[options, data, ids, loading, loaded, alterarFiltro, alterarFiltros, recoletaOptions]
	);

	return <ContasBancariasContext.Provider value={value}>{children}</ContasBancariasContext.Provider>;
};

export default ContasBancariasContextProvider;
