import React, { useState, useEffect, useContext } from 'react';
import { useNotify, useEditContext, useGetList } from 'react-admin';
import { parseISO, isSameDay, isAfter } from 'date-fns';

import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContext,
	ModalSizeEditableContextProvider,
} from '../../../../common/ModalSizeEditableContext';
import { EnderecoBoxV2, EnderecoContext, EnderecoContextProvider } from '../../../../common/EnderecoV2';
import { VinculosContext, emptyDadosEndereco } from '../../VinculosContext';
import AddImobiliaria from './AddImobiliaria';
import BoxDateOptional from './BoxDateOptional';
import formatDateISO from './formatDateISO';
import VinculosDatePicker from './VinculosDatePicker';
import BoxPessoaSelect from './BoxPessoaSelect';

const BoxInquilino = ({ entrada, saida, addSaida, setSaida, setAddSaida }) => (
	<BoxDateOptional
		addDate={addSaida}
		propsCheckBox={{
			checked: addSaida,
			checkBoxLabel: 'Adicionar Data de Saída',
			checkBoxOnChange: (e) => {
				setAddSaida(e.target.checked);
				!e.target.checked && setSaida(null);
			},
		}}
		propsDatePicker={{
			datePickerLabel: 'Data de Saída',
			datePickerValue: saida,
			datePickerOnChange: setSaida,
			minDate: entrada,
			minDateMessage: 'Data de saída não pode ser anterior à data de entrada',
		}}
	/>
);

const ModalBodyVinculos = ({ owner }) => {
	const notify = useNotify();
	const { record } = useEditContext();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { setResponsaveis, edit, setSelectingAmb, selectedPessoa, setSelectedPessoa, dataProvider } =
		useContext(VinculosContext);
	const { values: enderecoDados } = useContext(EnderecoContext);
	const { data, ids } = useGetList(
		'pessoa_unidade',
		{ perPage: 1, pages: 1 },
		{ order: 'ASC', field: 'data_entrada' },
		owner?.id_unidade_id && { tipo: 'P', id_unidade: owner?.id_unidade_id }
	);
	const [processando, setProcessando] = useState(false);
	const [entrada, setEntrada] = useState(new Date());
	const [saida, setSaida] = useState(null);
	const [valid, setValid] = useState(false);
	const [addImob, setAddImob] = useState(false);
	const [addSaida, setAddSaida] = useState(false);
	const [minDateProp, setMinDateProp] = useState({});
	const [dataSaidaUltimoInquilino, setDataSaidaUltimoInquilino] = useState(null);

	useEffect(() => {
		if (owner && owner.id_unidade) {
			dataProvider
				.getOnePkMiddle('pessoa_unidade/inquilinos_unidade', { id: owner.id_unidade })
				.then((response) => {
					let highestDataSaida = null;

					response.data.results.forEach((entry) => {
						if (entry.data_saida) {
							const dataSaida = new Date(entry.data_saida);
							if (!highestDataSaida || dataSaida > highestDataSaida) {
								highestDataSaida = dataSaida;
							}
						}
					});

					if (highestDataSaida) {
						setDataSaidaUltimoInquilino(new Date(highestDataSaida.setDate(highestDataSaida.getDate() + 2)));
					}
				});
		}
	}, [owner?.id_unidade]);

	const setMinDate = () => {
		if (owner) {
			const primeiroId = ids?.[0];
			const primeiroResponsavel = data?.[primeiroId] || {};
			if (edit && primeiroResponsavel.data_entrada) {
				setMinDateProp({
					minDate: parseISO(primeiroResponsavel.data_entrada),
				});
			} else {
				setMinDateProp({ minDate: parseISO(owner.data_entrada) });
			}
		}
	};

	useEffect(setMinDate, [data]);

	const resetSelectedPessoa = () => {
		setSelectedPessoa(undefined);
	};
	useEffect(resetSelectedPessoa, []);

	useEffect(() => {
		let isValid =
			enderecoDados?.cep &&
			enderecoDados?.bairro &&
			enderecoDados?.cidade &&
			enderecoDados?.endereco &&
			enderecoDados?.tipo_logradouro &&
			enderecoDados?.tipo_logradouro_id &&
			enderecoDados?.uf &&
			selectedPessoa &&
			selectedPessoa.nome &&
			entrada &&
			entrada?.toString() !== 'Invalid Date' &&
			(isSameDay(new Date(), entrada) || isAfter(new Date(), entrada));
		if (
			(isValid &&
				((addImob && !selectedPessoa?.imobiliaria) ||
					(addSaida &&
						(!saida ||
							saida?.toString() === 'Invalid Date' ||
							!(isSameDay(saida, entrada) || isAfter(saida, entrada)))))) ||
			(minDateProp.minDate && minDateProp.minDate > entrada)
		) {
			isValid = false;
		}
		setValid(isValid);
	}, [selectedPessoa, entrada, addImob, addSaida, saida, minDateProp.minDate, enderecoDados]);

	const setImobiliaria = (v) => {
		if (v) {
			setSelectedPessoa((p) => ({
				...p,
				id_imobiliaria: v.id,
				imobiliaria: v,
			}));
		} else {
			const { id_imobiliaria, imobiliaria, ...rest } = selectedPessoa || {};
			setSelectedPessoa({ ...rest });
		}
	};

	const handleSubmit = () => {
		setProcessando(true);
		const {
			id: id_pessoa,
			email_cobranca,
			documento,
			tipo_pessoa,
			telefones,
			nome,
			id_imobiliaria,
			imobiliaria,
		} = selectedPessoa || {};

		if (!record) {
			setResponsaveis((v) => [
				...v,
				{
					tipo: owner ? 'I' : 'P',
					data_entrada: formatDateISO(entrada),
					data_saida: formatDateISO(saida),
					id_pessoa,
					email_cobranca,
					documento,
					tipo_pessoa,
					telefones,
					nome,
					id_imobiliaria,
					imobiliaria,
					...enderecoDados,
					is_first: true,
				},
			]);
			setModalValue((v) => ({ ...v, open: false }));
			return;
		}

		dataProvider
			.create('pessoa_unidade', {
				data: {
					tipo: owner ? 'I' : 'P',
					data_entrada: formatDateISO(entrada),
					data_saida: formatDateISO(saida),
					id_pessoa,
					id_imobiliaria,
					imobiliaria,
					...enderecoDados,
					id_unidade: record.id,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					const dataSaida = data?.data_saida ? parseISO(data.data_saida) : undefined;
					if (
						!dataSaida ||
						(dataSaida && (isSameDay(dataSaida, new Date()) || isAfter(dataSaida, new Date())))
					) {
						if (data.tipo === 'I') setSelectingAmb(true);
						setResponsaveis((v) => [
							...v,
							{
								...data,
								id_pessoa,
								email_cobranca,
								documento,
								tipo_pessoa,
								telefones,
								nome,
								imobiliaria,
								...enderecoDados,
							},
						]);
					}
					notify('Responsável cadastrado com sucesso', 'info');
					setModalValue((v) => ({ ...v, open: false }));
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const errorMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(errorMsg, 'warning');
				setProcessando(false);
			});
	};

	const formatDataSaidaUltimoInquilino = () => {
		if (dataSaidaUltimoInquilino) {
			const now = new Date();
			now.setDate(dataSaidaUltimoInquilino.getDate() - 1);
			return now.toLocaleDateString('pt-BR');
		}
	};

	return (
		<CustomDialogBodySizeEditable
			title={owner ? 'Vincular Inquilino' : 'Vincular Proprietário'}
			closeInSubmit={false}
			form={{
				valid: !processando && valid,
				handleSubmit,
				component: (
					<>
						<ModalSizeEditableContextProvider>
							<BoxPessoaSelect {...{ owner, setValid }} />
						</ModalSizeEditableContextProvider>
						<VinculosDatePicker
							label='Data de Entrada'
							value={entrada}
							onChange={setEntrada}
							disableFuture
							maxDateMessage='Data não pode ser posterior ao dia de hoje'
							minDate={dataSaidaUltimoInquilino ? dataSaidaUltimoInquilino : minDateProp.minDate}
							minDateMessage={
								dataSaidaUltimoInquilino
									? `A data de entrada do inquilino não pode ser anterior à data de saída do último inquilino: ${formatDataSaidaUltimoInquilino()}`
									: `A data de entrada do inquilino não pode ser anterior à data de entrada do primeiro proprietário: ${
											minDateProp.minDate && minDateProp.minDate.toLocaleDateString('pt-BR')
									  }`
							}
						/>
						{owner && (
							<BoxInquilino
								{...{
									addSaida,
									entrada,
									saida,
									setSaida,
									setAddSaida,
								}}
							/>
						)}
						{owner && <AddImobiliaria setImob={setImobiliaria} checked={addImob} setChecked={setAddImob} />}
						<EnderecoBoxV2 />
					</>
				),
			}}
		/>
	);
};

const ModalVinculos = ({ owner }) => (
	<EnderecoContextProvider {...emptyDadosEndereco}>
		<ModalBodyVinculos owner={owner} />
	</EnderecoContextProvider>
);

export default ModalVinculos;
