import React, { forwardRef, ReactNode } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

interface ButtonConfirmProps extends ButtonProps {
	startIcon?: ReactNode;
	children?: ReactNode;
	loading?: boolean;
	onClick?: () => void;
	disabled?: boolean;
}

const defaultIcon = <SaveIcon />;

export const ButtonConfirmV2 = forwardRef<HTMLButtonElement, ButtonConfirmProps>(function ButtonConfirm(
	{ startIcon = defaultIcon, children, loading = false, ...rest },
	ref
) {
	return (
		<Button
			size='small'
			{...rest}
			color='primary'
			startIcon={loading ? <CircularProgress size={20} /> : startIcon}
			ref={ref}
			disabled={rest.disabled || loading}
		>
			{children || 'Salvar'}
		</Button>
	);
});
