import React, { useContext } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';

const optionsUnidadeMedida = [
	{ id: 'm', name: 'm³' },
	{ id: 'k', name: 'kW/h' },
];

const UnidadeMedidaSelect = ({ unidade_medida, fator_convercao }) => {
	const { setDadosConfig } = useContext(EditaConfiguracaoLeituraContext);
	return (
		<Autocomplete
			autoHighlight
			autoSelect
			disableClearable
			getOptionLabel={(o) => {
				return o?.name || '';
			}}
			fullWidth
			noOptionsText='Nenhuma unidade de medida disponível'
			onChange={(_, newValue) => {
				setDadosConfig((v) => ({
					...v,
					unidade_medida: newValue.id,
					fator_convercao: newValue.id === 'm' ? fator_convercao : 1.0,
				}));
			}}
			options={optionsUnidadeMedida}
			renderInput={(params) => {
				return <TextField {...params} variant='outlined' label='Unidade de medida' margin='dense' />;
			}}
			style={{ marginRight: '0.5em' }}
			value={optionsUnidadeMedida.find((v) => v.id === unidade_medida)}
		/>
	);
};

export default UnidadeMedidaSelect;
