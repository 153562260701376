import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { format } from 'date-fns';

import { Button } from '@material-ui/core';
import { ExportacaoContabilContext } from '../context/ExportacaoContabilContext';

import { CustomDataProvider } from 'types/tpyesGlobal';
import { generateDocument } from '../../../../../utils/Base64/generateDocument';

const ButtonGerarArquivoContasAReceber: React.FC = () => {
	const { condominio, valueTipoMovimento, valueTipoExportacao, dateRange } =
		React.useContext(ExportacaoContabilContext);

	const dataInicial = format(dateRange.startDate, 'dd/MM/yyyy');
	const dataFinal = format(dateRange.endDate, 'dd/MM/yyyy');

	const dp = useDataProvider() as CustomDataProvider;
	const notify = useNotify();

	const handleGerarArquivoContasAReceber = async () => {
		if (valueTipoMovimento?.id === 'CR') {
			try {
				const response = await dp.safeCreate('exportacao_contabil/contas_a_receber', {
					data: {
						condominio_id: condominio?.id,
						tipo_movimento: valueTipoMovimento?.id,
						tipo_exportacao: valueTipoExportacao,
						data_inicial: dataInicial,
						data_final: dataFinal,
					},
				});
				const { data }: any = response;
				const { text, fileName }: { text: string; fileName: string } = data;
				generateDocument(text, fileName);
				notify('Arquivo gerado com sucesso', 'success');
			} catch (error: any) {
				const msgErro = error.response?.data?.message;
				notify(msgErro, 'warning');
			}
		} else {
			notify('Tipo de movimento inválido', 'warning');
		}
	};

	return (
		<Button variant='outlined' color='primary' onClick={handleGerarArquivoContasAReceber}>
			Gerar Arquivo
		</Button>
	);
};

export default ButtonGerarArquivoContasAReceber;
