import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { ContasContextProvider } from 'components/resources/planosCondominio/contexts/ContasContext';
import { SelectContaPlano } from 'components/resources/planosCondominio/modais';

export const MensagemCondominioSemPlano = () => {
	return <Typography>Nenhum plano de condomínio vinculado. Adicione um nas configurações do condomínio.</Typography>;
};

export const BoxAddConta = ({
	idPlano,
	contaReceita,
	setContaReceita,
	msgErro,
	disabled,
	tipo,
	filter,
	ComponenteMensagemCondominioSemPlano = <MensagemCondominioSemPlano />,
}) => {
	return !idPlano ? (
		ComponenteMensagemCondominioSemPlano
	) : (
		<ContasContextProvider id={idPlano}>
			<Box display='grid'>
				<SelectContaPlano
					tipo={tipo}
					selectedConta={contaReceita}
					setSelectedConta={setContaReceita}
					disabled={disabled}
					filter={filter}
					msgErro={msgErro}
				/>
			</Box>
		</ContasContextProvider>
	);
};
