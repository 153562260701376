import React from 'react';
import Button from '@material-ui/core/Button';

import { ButtonRemove } from './ButtonRemove';

type TButtonRemoveV2Props = React.ComponentProps<typeof Button> & {
	className?: string;
	loading?: boolean;
};

type IButtonRemoveV2 = React.FC<TButtonRemoveV2Props>;

export const ButtonRemoveV2 = ButtonRemove as unknown as IButtonRemoveV2;
