import React, { useEffect } from 'react';

import { parse } from 'date-fns';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { SyndikosMuiPickersUtilsProvider } from '../../common/InputDate';

const VencimentoField = ({
	fullWidth = true,
	value,
	setValue,
	erros,
	setErros,
	erroField,
	label = 'Data de Vencimento',
}) => {
	const validate = () => {
		if (!value) {
			setErros((e) => ({
				...e,
				[erroField]: 'Forneça a data de vencimento',
			}));
		} else if (parse(value, 'dd/MM/yyyy', new Date()).toString() === 'Invalid Date') {
			setErros((e) => ({
				...e,
				[erroField]: 'Forneça uma data válida para o vencimento',
			}));
		} else if (erros && erros[erroField]) {
			setErros((e) => {
				const { [erroField]: data_vencimento, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [value]);

	return (
		<SyndikosMuiPickersUtilsProvider>
			<KeyboardDatePicker
				autoOk={true}
				format='dd/MM/yyyy'
				label={label}
				mask='__/__/____'
				size='small'
				variant='inline'
				inputVariant='outlined'
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				onChange={(_, newValue) => setValue(newValue)}
				value={value}
				inputValue={value}
				initialFocusedDate={value}
				required={true}
				error={!!(erros && erros[erroField])}
				helperText={(erros && erros[erroField]) || undefined}
				style={{ marginRight: '1em' }}
				fullWidth={fullWidth}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};

export default VencimentoField;
