import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { format, parseISO } from 'date-fns';

import { formatFracoesThenGetInterval, DIAS_DA_SEMANA, TIPO_RESERVA } from './utils';

import { ConsultaDeReservasContext } from '../../context/AgendamentoContext';
import { ConfirmDialog } from '../ModalConfirm/ConfirmDialog';
import { LoadingButton } from '../../buttons/LoadingButton';
import { StyledCard } from './components/StyledCard';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	editarButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
	},
	deleteButton: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.main,
			color: '#fff',
		},
	},
	showMoreButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
		height: '1.3rem',
		width: '6rem',
		'font-size': 'x-small',
	},
	titleCard: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		padding: '0.5rem',
		'border-radius': '4px',
		display: 'flex',
		'justify-content': 'center',
	},
}));

const isOnPast = (date) => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return date < today;
};

export const CardBloqueio = ({ data }) => {
	const { actionCallBackReloadReserva, setDadoCardAndShowModalEdicaoBloqueio } =
		React.useContext(ConsultaDeReservasContext);

	const [loadingDesbloquear, setLoadingDesbloquear] = React.useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
	const [showAllHorarios, setShowAllHorarios] = React.useState(false);

	const horariosDoBloqueio = formatFracoesThenGetInterval[data.fracao](data.bloqueios_intervalos_tempo);
	const diaBloqueado = format(parseISO(data.dia_bloqueado), 'dd/MM/yyyy');
	const disabledButton = isOnPast(parseISO(data.dia_bloqueado));

	const dp = useDataProvider();
	const classes = useStyles();
	const notify = useNotify();

	const cancelarBloqueio = React.useCallback(() => {
		setLoadingDesbloquear(true);
		dp.delete('bloqueio_reservas_syndkos', { id: data.id })
			.then(() => {
				notify(`O dia ${diaBloqueado} foi desbloqueado com sucesso!`, 'success');
				actionCallBackReloadReserva();
			})
			.catch((error) => {
				const data = error?.response?.data || {};
				return notify('Erro inesperado.', 'warning');
			})
			.finally(() => setLoadingDesbloquear(false));
	}, [data.id, dp, notify, diaBloqueado, setLoadingDesbloquear, actionCallBackReloadReserva]);

	return (
		<StyledCard>
			<div className={classes.titleCard}>
				<span role='img' aria-label='Bloqueio'>
					<strong>Bloqueio</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Data do bloqueio'>
					<CalendarTodayIcon /> Data do bloqueio:
					<strong>
						{diaBloqueado}, {DIAS_DA_SEMANA[data.dia_semana]}
					</strong>
				</span>
			</div>

			<div>
				<span role='img' aria-label='Tipo do bloqueio'>
					<LocalOfferIcon /> Tipo do bloqueio:
					<strong>{TIPO_RESERVA[data.fracao]}</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Tipo do bloqueio'>
					<AnnouncementIcon /> Motivo do bloqueio:
					<strong>{data.motivo_bloqueio}</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Horarios Bloqueados'>
					<AccessTimeIcon /> Horarios Bloqueados:
					{horariosDoBloqueio.length > 2 && (
						<div>
							<Button
								variant='contained'
								className={classes.showMoreButton}
								onClick={() => setShowAllHorarios((prev) => !prev)}
							>
								{showAllHorarios ? 'VER MENOS' : 'VER MAIS'}
							</Button>
						</div>
					)}
				</span>
				<ul>
					{showAllHorarios
						? horariosDoBloqueio.map((horario, index) => (
								<li key={index}>
									<strong>{horario}</strong>
								</li>
						  ))
						: horariosDoBloqueio.slice(0, 2).map((horario, index) => (
								<li key={index}>
									<strong>{horario}</strong>
								</li>
						  ))}
				</ul>
			</div>
			<div
				style={{
					display: 'flex',
					gap: '10px',
					justifyContent: 'flex-end',
					marginTop: 'auto',
				}}
			>
				<LoadingButton
					variant='contained'
					className={classes.deleteButton}
					onClick={() => setShowConfirmDialog(true)}
					loading={loadingDesbloquear}
					disabled={disabledButton}
				>
					Desbloquear
				</LoadingButton>
				<ConfirmDialog
					classes={classes}
					onConfirm={cancelarBloqueio}
					showConfirmDialog={showConfirmDialog}
					setShowConfirmDialog={setShowConfirmDialog}
					msgText={'Essa ação irá desbloquear os horários para reserva.'}
					actionText={'Desbloquear'}
					disabled={loadingDesbloquear}
				/>
				<LoadingButton
					variant='contained'
					className={classes.editarButton}
					onClick={() => setDadoCardAndShowModalEdicaoBloqueio(data)}
					loading={loadingDesbloquear}
					disabled={disabledButton}
				>
					Editar
				</LoadingButton>
			</div>
		</StyledCard>
	);
};
