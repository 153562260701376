import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { useDataProvider, useNotify } from 'react-admin';

import { GoBackButton, SaveButton, DeleteButton } from 'components/common/commonComponentsTSX';
import { ModalContext } from 'components/common/ModalContext';
import BoxConfiguracaoMultaEJuros from '../components/BoxConfiguracaoMultaEJuros';
import BoxConfiguracaoIndiceCorrecaoMonetaria from '../components/BoxConfiguracaoIndiceCorrecaoMonetaria';
import NomeField from '../components/NomeField';
import { ConfiguracaoDeCorrecaoMonetariaContext, FormContext } from '../contexts';
import { ModalDeleteConfirmation } from '../modals/ModalDeleteConfirmation';
import { ProviderValueContext, TCustomDataProvider } from './types';
import formatRequestPayloadUpdate from './formatRequestPayloadUpdate';

const Form: React.FC = () => {
	const notify = useNotify();
	const dp = useDataProvider() as TCustomDataProvider;

	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const { screen, handleSetRedirectContext } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);

	const {
		formIsValid,
		nome,
		setNome,
		tipoIndice,
		ultimaDataAtualizacao,
		ultimoValorCalculado,
		feesOptions,
		feesPercentage,
		finePercentage,
		allowDuplicateGeneration,
		daysLimitAfterExpiration,
		setDaysLimitAfterExpiration,
		loading,
		record,
	} = React.useContext(FormContext);

	React.useEffect(() => {
		if (!allowDuplicateGeneration) {
			setDaysLimitAfterExpiration(0);
		}
	}, [allowDuplicateGeneration]);

	const handleSubmitEditConfigCorrecaoMonetaria = () => {
		if (!formIsValid || loading) return;
		dp.fullUpdate('correcao_monetaria', {
			id: screen.editId as number,
			data: formatRequestPayloadUpdate({
				nome,
				tipoIndice,
				ultimaDataAtualizacao,
				ultimoValorCalculado,
				feesOptions,
				finePercentage,
				feesPercentage,
				allowDuplicateGeneration,
				daysLimitAfterExpiration,
			}),
		})
			.then(() => {
				notify('Configuração editada com sucesso!');
				return handleSetRedirectContext('list', null);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Não foi possível editar a configuração!', { type: 'error' });
			});
	};

	const handleSubmitDeleteConfigCorrecaoMonetaria = () => {
		return setModalValue((v) => ({ ...v, open: true, dialogBody: <ModalDeleteConfirmation /> }));
	};

	return (
		<Box display='flex' flexDirection='column' gridGap='1.25rem' marginTop='1.5rem'>
			<Typography variant='h5' align='left'>
				Editar {record?.nome || 'Configuração de Correcão Monetária'}
			</Typography>
			<NomeField label='Nome da Configuração' nome={nome} setNome={setNome} />
			<BoxConfiguracaoMultaEJuros />
			<BoxConfiguracaoIndiceCorrecaoMonetaria />
			<Box display='flex' justifyContent='space-between'>
				<Box display='flex' justifyContent='flex-start' gridGap='5px'>
					<SaveButton disabled={!formIsValid || loading} onClick={handleSubmitEditConfigCorrecaoMonetaria}>
						Salvar
					</SaveButton>
					<GoBackButton onClick={() => handleSetRedirectContext('list', null)}>Voltar</GoBackButton>
				</Box>
				<Box display='flex' justifyContent='flex-end'>
					<DeleteButton disabled={loading} onClick={handleSubmitDeleteConfigCorrecaoMonetaria}>
						Excluir
					</DeleteButton>
				</Box>
			</Box>
		</Box>
	);
};

export default Form;
