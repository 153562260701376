import { AssembleiaList } from './list';
import { AssembleiaEdit } from './edit';
import { AssembleiaCreate } from './create';

const resource = {
	list: AssembleiaList,
	edit: AssembleiaEdit,
	create: AssembleiaCreate,
};

export default resource;
