import React from 'react';

import { Box, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import { ConfigRelatorioContext } from '../ConfigRelatorioContextProvider';

export const SelectTamanhoFonte = () => {
	const { tamanhoFonte, setTamanhoFonte } = React.useContext(ConfigRelatorioContext);

	const optionsTamanhoFonte = [
		{ id: 'G', nome: 'Grande' },
		{ id: 'N', nome: 'Normal' },
		{ id: 'P', nome: 'Pequeno' },
	];

	const tamanhoFonteSelected = optionsTamanhoFonte.find((op) => op.id === tamanhoFonte) || null;

	return (
		<Box flexDirection='row' display='flex'>
			<Autocomplete
				options={optionsTamanhoFonte}
				value={tamanhoFonteSelected}
				onChange={(_, newValue) => setTamanhoFonte(newValue.id)}
				renderOption={(option) => option.nome}
				getOptionLabel={(option) => option.nome}
				getOptionSelected={(option, newValue) => option.id === newValue.id}
				renderInput={(params) => (
					<TextField {...params} size='small' fullWidth label='Selecione o tamanho da fonte' />
				)}
				disableClearable
				fullWidth
			/>
		</Box>
	);
};
