import React from 'react';

import { Box } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { pt } from 'date-fns/locale';

import { TTipoIndice, TUltimaDataAtualizacao, TSetUltimaDataAtualizacao } from '../../types';

export interface IUltimaDataAtualizacaoInputProps {
	ultimaDataAtualizacao: TUltimaDataAtualizacao;
	setUltimaDataAtualizacao: TSetUltimaDataAtualizacao;
	tipoIndice: TTipoIndice;
}

export const UltimaDataAtualizacaoInput = ({
	ultimaDataAtualizacao,
	setUltimaDataAtualizacao,
	tipoIndice,
}: IUltimaDataAtualizacaoInputProps) => {
	return (
		<Box width='100%'>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
				<KeyboardDatePicker
					id='date-calc-indice'
					label='Data do Cálculo'
					size='small'
					variant='inline'
					inputVariant='outlined'
					format='dd/MM/yyyy'
					mask='__/__/____'
					value={ultimaDataAtualizacao}
					onChange={(date) => {
						setUltimaDataAtualizacao(date);
					}}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
					fullWidth
					required
					disabled={tipoIndice !== 'MANUAL'}
				/>
			</MuiPickersUtilsProvider>
		</Box>
	);
};

export default UltimaDataAtualizacaoInput;
