import React from 'react';
import { ConfiguracaoDeCorrecaoMonetariaContextProvider } from './contexts';
import ConfigCorrecaoMonetariaBody from './ConfigCorrecaoMonetariaBody';

const ConfiguracaoDeCorrecaoMonetariaV2: React.FC = () => (
	<ConfiguracaoDeCorrecaoMonetariaContextProvider>
		<ConfigCorrecaoMonetariaBody />
	</ConfiguracaoDeCorrecaoMonetariaContextProvider>
);

export default ConfiguracaoDeCorrecaoMonetariaV2;
