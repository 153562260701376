import React from 'react';

import { Box, TextField } from '@material-ui/core';

import MUIRichTextEditor from 'mui-rte';

import InfoIcon from '@material-ui/icons/Info';

import { ShowTags } from '../ShowTags/ShowTags';
import { tagsEditorText } from '../../mentions/mentions';
import TextInputMention from '../TextInputMention/TextInputMention';

export const EditEmailTemplateBox = ({ handleEdit = () => {}, template }) => {
	// Variaveis
	const textRef = React.useRef(null);
	const [assuntoModeloEdit, setAssuntoModeloEdit] = React.useState();
	const [assuntoModeloEditParse, setAssuntoModeloEditParse] = React.useState();
	const [textoModeloEdit, setTextoModeloEdit] = React.useState();
	const [nomeModeloEdit, setNomeModeloEdit] = React.useState();
	const [anchorEditor, setAnchorEditor] = React.useState(null);
	const [IDModeloEdit, setIDModeloEdit] = React.useState();

	// Funções Handle
	const handleSaveAssunto = (data) => {
		const { blocks } = JSON.parse(data);
		const assuntoText = blocks[0]?.text;
		setAssuntoModeloEditParse(assuntoText);
	};

	React.useEffect(() => {
		if (!template) return;
		const assuntoParse = {
			blocks: [
				{
					data: {},
					depth: 0,
					entityRanges: [],
					inlineStyleRanges: [],
					key: '2u8pb',
					text: template.assunto,
					type: 'unstyled',
				},
			],
			entityMap: {},
		};
		setIDModeloEdit(template.id);
		setAssuntoModeloEdit(JSON.stringify(assuntoParse));
		setTextoModeloEdit(JSON.stringify(template.mensagem_json));
		setNomeModeloEdit(template.nome);
	}, [template]);

	React.useEffect(() => {
		textRef.current?.save();
	}, [nomeModeloEdit, assuntoModeloEdit, textoModeloEdit, assuntoModeloEditParse]);

	return (
		<Box>
			<TextField
				value={nomeModeloEdit}
				size='small'
				onChange={(event) => {
					textRef.current.save();
					setNomeModeloEdit(event.target.value);
				}}
				onBlur={() => textRef.current.save()}
				onFocus={() => textRef.current.save()}
				label='Nome para o novo modelo:'
				variant='outlined'
				fullWidth
			/>
			<TextInputMention assuntoText={assuntoModeloEdit} handleSaveAssunto={handleSaveAssunto} />
			<Box
				style={{
					backgroundColor: 'white',
					color: 'black',
					marginTop: '10px',
				}}
				border={1}
				borderRadius='borderRadius'
			>
				{anchorEditor && (
					<ShowTags open={Boolean(anchorEditor)} anchor={anchorEditor} setAnchorEditor={setAnchorEditor} />
				)}
				<MUIRichTextEditor
					label='Digite algo...'
					defaultValue={textoModeloEdit}
					inlineToolbar
					toolbarButtonSize='small'
					ref={textRef}
					onSave={(data) => handleEdit(IDModeloEdit, assuntoModeloEditParse, nomeModeloEdit, data)}
					onChange={() => textRef.current.save()}
					onBlur={() => textRef.current.save()}
					onFocus={() => textRef.current.save()}
					maxLength={1600}
					controls={[
						'title',
						'bold',
						'italic',
						'underline',
						'strikethrough',
						'bulletList',
						'numberList',
						'undo',
						'redo',
						'quote',
						'code',
						'clear',
						'tags',
					]}
					customControls={[
						{
							name: 'tags',
							icon: <InfoIcon />,
							type: 'callback',
							onClick: (editorState, name, anchor) => setAnchorEditor(anchor),
						},
					]}
					autocomplete={{
						strategies: [
							{
								items: tagsEditorText,
								triggerChar: '@',
							},
						],
					}}
				/>
			</Box>
		</Box>
	);
};
