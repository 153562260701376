import React from 'react';
import ButtonMUI from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { TooltipIconButton } from '../TooltipIconButton';

const defaultIcon = <NavigateBeforeIcon />;

export const BotaoVoltar = (props) => {
	const { icon = defaultIcon, ...rest } = props;

	return (
		<>
			<ButtonMUI variant='text' color='default' key='button' size='small' startIcon={icon} {...rest}>
				Voltar
			</ButtonMUI>
		</>
	);
};

export const TooltipVoltarIconButton = ({ title, children, ...rest }) => (
	<TooltipIconButton {...rest} title={title || 'Voltar'} color='default'>
		{children || <NavigateBeforeIcon />}
	</TooltipIconButton>
);
