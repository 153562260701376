import React from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const SelectHorariosReserva = ({
	horarios,
	horariosBloqueados,
	setSelectedHorarios,
	selectedHorarios = [],
	style = {},
}) => {
	return (
		<Autocomplete
			id='select-horario-reserva'
			itemID='select-horario-reserva-item'
			disableCloseOnSelect
			options={horarios}
			value={selectedHorarios}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Horários' />}
			renderOption={(option, { selected }) => (
				<span>
					<Checkbox checked={selected} />
					{option}
				</span>
			)}
			getOptionDisabled={(option) => horariosBloqueados.flat().includes(option)}
			noOptionsText='Nenhum resultado encontrado'
			onChange={(_, v) => setSelectedHorarios(v)}
			autoHighlight
			clearText='Limpar'
			multiple
			fullWidth
			style={style}
		/>
	);
};
