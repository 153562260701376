import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DefaultIcon from '@material-ui/icons/ViewList';
import classnames from 'classnames';
import { useGetResourceLabel, getResources } from 'ra-core';

import DashboardMenuItem from './DashboardMenuItem';
import MenuItemLink from './MenuItemLink';

export const MENU_WIDTH = 260;
export const CLOSED_MENU_WIDTH = 64;

export function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

const useStyles = makeStyles(
	(theme) => ({
		main: {
			marginTop: '0.5em',
			overflowY: 'auto',
			minHeight: '6.25rem',
			[theme.breakpoints.only('xs')]: {
				marginTop: 0,
			},
			[theme.breakpoints.up('md')]: {
				marginTop: '1.5em',
			},
			zIndex: 10,
		},
		open: {
			width: lodashGet(theme, 'menu.width', MENU_WIDTH),
		},
		closed: {
			width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
		},
	}),
	{ name: 'RaMenu' }
);

const SectionTypography = ({ children, open }) =>
	open ? (
		<Typography
			variant='overline'
			color='textSecondary'
			style={{
				display: 'flex',
				userSelect: 'none',
				pointerEvents: 'none',
				marginLeft: '18px',
				lineHeight: 1,
				fontSize: '10pt',
				marginBottom: '5px',
				marginTop: '10px',
			}}
		>
			{children}
		</Typography>
	) : (
		<hr style={{ margin: '13px 18px', border: '1px solid #ccc' }} />
	);

const Menu = (props) => {
	const { classes: classesOverride, className, dense, hasDashboard, onMenuClick, logout, ...rest } = props;
	const classes = useStyles(props);
	const open = useSelector((state) => state.admin.ui.sidebarOpen);
	const resources = useSelector(getResources, shallowEqual);
	const getResourceLabel = useGetResourceLabel();

	const section = (resourceName) => {
		switch (resourceName) {
			case 'condominios':
				return <SectionTypography open={open}>Cadastros</SectionTypography>;
			case 'movimentacao_bancaria':
				return <SectionTypography open={open}>Processos</SectionTypography>;
			case 'plano_condominio':
				return <SectionTypography open={open}>Configurações</SectionTypography>;
			case 'relatorios':
				return <SectionTypography open={open}>Relatórios</SectionTypography>;
			default:
				return <></>;
		}
	};

	return (
		<div
			className={classnames(
				classes.main,
				{
					[classes.open]: open,
					[classes.closed]: !open,
				},
				className
			)}
			{...rest}
			style={{ marginTop: 10, marginBottom: 17 }}
		>
			{hasDashboard && <DashboardMenuItem dense={dense} />}
			{resources
				.filter((r) =>
					r.name === 'administradora'
						? ['Administrador', 'Suporte'].includes(
								JSON.parse(localStorage.getItem('auth')).group_permissions.name
						  )
						: r.hasList
				)
				.map((resource) => (
					<>
						{section(resource.name)}
						<MenuItemLink
							key={resource.name}
							to={{ pathname: `/${resource.name}`, state: { _scrollToTop: true } }}
							primaryText={getResourceLabel(resource.name, 2)}
							leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
							dense={dense}
						/>
					</>
				))}
		</div>
	);
};

export default Menu;
