import memoize from '../../../../../common/memoize';
import { validacoesFieldsTabInformacao } from '../../utils/validadoes';
import TabInformacao from '../../tabs/TabInformacao';
import { defaultComponentesProps, getValidate } from '../default/ContaNaoCorrenteProps';
import { getFormatarOptions } from '../../utils/functions';

const getFormataPayloadValues =
	({ conta_condominio }) =>
	({ imprimir_saldo_relatorios, nome_conta, data_saldo_inicial, saldo_inicial, id_inst_financeira }) => ({
		nome_conta,
		data_saldo_inicial,
		saldo_inicial,
		imprimir_saldo_relatorios,
		id_inst_financeira: id_inst_financeira?.id,
		agencia: '',
		agencia_cv: '',
		conta_corrente: '',
		conta_corrente_cv: '',
		id: conta_condominio.id,
		id_condominio: conta_condominio.id_condominio,
		situacao: conta_condominio.situacao,
		tipo_conta: conta_condominio.tipo_conta,
		tipo_conta_bancaria: conta_condominio.tipo_conta_bancaria,
	});

const formatarInitialValues = ({
	conta_condominio,
	options: {
		id_inst_financeira: { objects: instituicoesObjs },
	},
}) => ({
	...conta_condominio,
	id_inst_financeira: instituicoesObjs[conta_condominio.id_inst_financeira],
	agencia: null,
	conta_corrente: '',
});

const ContaNaoCorrenteProps = {
	instituicaoCodigo: '364',
	getDisabledTabs: memoize(() => ({ disabled0: false })),
	tabContents: { TabInformacao },
	getFormataPayloadValues,
	getValidate,
	formatarInitialValues,
	validacoes: [...validacoesFieldsTabInformacao],
	props: { ...defaultComponentesProps },
	getOptions: getFormatarOptions('364'),
};

export default ContaNaoCorrenteProps;
