import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { List } from 'react-admin';

import brExporter from 'utils/exporter';

import { Tabela } from 'components/common/Tabela';
import { Decimal } from 'components/common/filtros/DecimalV2';
import { Data } from '../../types';
import Pagination from '../Pagination';
import ValorCalculoField from '../ValorCalculoField';
import DateFilter from '../DateFilter';
import { DataAtualizacaoField } from '../FormatDateField';

interface IRecordProp {
	record: Data;
}

const TabelaConsultaHistorico: React.FC<IRecordProp> = ({ record }) => {
	return (
		<Tabela record={record} disableClickRow>
			<ValorCalculoField
				source='valor_calculo'
				name='valor_calculo'
				label='Valor do Cálculo'
				filtro={<Decimal decimalScale={6} />}
			/>
			<DataAtualizacaoField
				source='data_atualizacao'
				name='data_atualizacao'
				label='Data da Atualização'
				filtro={<DateFilter />}
			/>
		</Tabela>
	);
};

const ListaTabelaConsultaHistorico: React.FC<IRecordProp> = ({ record }) => {
	return (
		<Box>
			<Box style={{ margin: '0.7rem' }}>
				<Typography variant='h4'>{'Detalhamento do Histórico'}</Typography>
				<Typography variant='h5'>{`Índice ${record.nome}`}</Typography>
			</Box>
			<List
				title={`Historico do Índice ${record.nome}`}
				basePath='/historicos_correcao_monetaria'
				resource='historicos_correcao_monetaria'
				sort={{ field: 'data_atualizacao', order: 'DESC' }}
				filter={{
					correcao_monetaria: record.id,
				}}
				exporter={brExporter}
				empty={false}
				actions={false}
				bulkActionButtons={false}
				perPage={10}
				pagination={
					<Pagination
						rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
						labelRowsPerPage='Histórico por página'
					/>
				}
			>
				<TabelaConsultaHistorico record={record} />
			</List>
		</Box>
	);
};

export default ListaTabelaConsultaHistorico;
