import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';

import { ButtonDesprovisionar, ButtonProvisionar, SelectContaBancaria } from './componentes';

const BoxTypographyAvisoStatusContaBancaria = ({ children }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();

	return (
		<Box display='flex' gridGap='10px'>
			<Typography
				style={{
					color: syndikosRed.main,
				}}
			>
				{children}
			</Typography>
		</Box>
	);
};

const TextoAvisoStatusContaBancaria = () => {
	const { contaBDestinoId, tem_arrecadacao_provisionada, contaBDestinoIsValid } = useContext(ArrecadacoesContext);

	if (tem_arrecadacao_provisionada || !contaBDestinoId) return null;

	if (!contaBDestinoIsValid)
		return (
			<BoxTypographyAvisoStatusContaBancaria>
				Conta bancária inválida para geração de remessas
			</BoxTypographyAvisoStatusContaBancaria>
		);

	return null;
};

const ProvisionamentoForm = () => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);

	return (
		<Box display='flex' flexDirection='column' gridGap='10px'>
			<Box display='flex' gridGap='10px'>
				<SelectContaBancaria />
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
					}}
				>
					{tem_arrecadacao_provisionada ? <ButtonDesprovisionar /> : <ButtonProvisionar />}
				</ModalSizeEditableContextProvider>
			</Box>
			<TextoAvisoStatusContaBancaria />
		</Box>
	);
};

export default ProvisionamentoForm;
