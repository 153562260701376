import React from 'react';

import { DeleteButton } from 'components/common/commonComponentsTSX';

import ModalConfirmarExclusaoGaragens from '../modal/delete/ModalConfirmarExclusaoGaragens';

const ActionsBulkList = () => {
	const [openModalExcluir, setOpenModalExcluir] = React.useState(false);

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
			<DeleteButton size='small' onClick={() => setOpenModalExcluir(true)}>
				Excluir Selecionados
			</DeleteButton>
			<ModalConfirmarExclusaoGaragens open={openModalExcluir} onClose={() => setOpenModalExcluir(false)} />
		</div>
	);
};

export default ActionsBulkList;
