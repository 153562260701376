import React, { useContext } from 'react';

import TextField from '@material-ui/core/TextField';

import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';

const ContaReceitaField = () => {
	const { dadosConfig } = useContext(EditaConfiguracaoLeituraContext);
	return (
		<TextField
			label='Conta de Receita'
			value={dadosConfig.nome_receita}
			disabled
			style={{ float: 'right', width: '70%' }}
			margin='dense'
		/>
	);
};

export default ContaReceitaField;
