import React from 'react';

import { Box } from '@material-ui/core';

import { CadastrarDadosIntegracaoAPITextField } from './CadastrarDadosIntegracaoAPITextField';

export const ClientSecretField = ({ label, source, inputProps = {} }) => (
	<Box>
		<CadastrarDadosIntegracaoAPITextField label={label} source={source} inputProps={inputProps} />
	</Box>
);
