import React from 'react';

import { Box, TextField } from '@material-ui/core';

import NumberFormat from 'react-number-format';

import { TNumberFormatPercentProps, TTipoIndice, TUltimoValorCalculado, TSetUltimoValorCalculado } from '../../types';
import { useForceUpdate } from '../../hooks';

const NumberFormatDecimal: React.FC<TNumberFormatPercentProps> = ({ label, value, onValueChange, ...rest }) => (
	<NumberFormat
		{...rest}
		decimalSeparator=','
		decimalScale={6}
		fixedDecimalScale={true}
		allowNegative={false}
		allowEmptyFormatting={true}
		defaultValue={'0'}
		customInput={TextField}
		label={label}
		value={value}
		size='small'
		onValueChange={onValueChange}
		thousandSeparator='.'
		allowedDecimalSeparators={[',']}
	/>
);

interface IUltimoValorCalculadoInputProps {
	ultimoValorCalculado: TUltimoValorCalculado;
	setUltimoValorCalculado: TSetUltimoValorCalculado;
	tipoIndice: TTipoIndice;
}

export const UltimoValorCalculadoInput = ({
	ultimoValorCalculado,
	setUltimoValorCalculado,
	tipoIndice,
}: IUltimoValorCalculadoInputProps) => {
	const forceUpdate = useForceUpdate();
	return (
		<Box width='100%'>
			<NumberFormatDecimal
				id='valor-calc-indice'
				name='valor_calculo_indice'
				label='Valor do Índice'
				value={ultimoValorCalculado}
				onValueChange={({ value }: { value: string }) => {
					let [integerPart, floatPart = '0'] = value.split('.') as string[];
					floatPart = `${integerPart.slice(9)}${floatPart}`.slice(0, 6);
					integerPart = (integerPart || '0').slice(0, 9);
					const formattedValue = `${integerPart}.${floatPart}`;
					if (formattedValue === ultimoValorCalculado) return forceUpdate();
					return setUltimoValorCalculado(formattedValue);
				}}
				disabled={tipoIndice !== 'MANUAL'}
				isNumericString
				fullWidth
			/>
		</Box>
	);
};

export default UltimoValorCalculadoInput;
