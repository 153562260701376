import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Tooltip, IconButton } from '@material-ui/core';

const AtendimentoIcon = () => {
	return (
		<>
			<Tooltip title={'Atendimento'}>
				<IconButton
					onClick={() =>
						window.open(
							'https://wa.me/551130030707?text=Olá.%20Eu%20desejo%20falar%20com%20a%20SCI%20Sistemas'
						)
					}
				>
					<WhatsAppIcon style={{ fill: '#FFFFFF' }} />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default AtendimentoIcon;
