import React, { useState, useContext } from 'react';

import { format, parseISO } from 'date-fns';
import Button from '@material-ui/core/Button';

import {
	List,
	Pagination,
	ReferenceField,
	TextField,
	useDataProvider,
	useListContext,
	useNotify,
	useRefresh,
} from 'react-admin';

import SendIcon from '@material-ui/icons/Send';
import { Box, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import VisibilityIcon from '@material-ui/icons/Visibility';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';

import brExporter from 'utils/exporter';

import { PrimaryChip, WarningChip } from 'components/common/chips';
import { Data } from 'components/common/filtros/DataV2';
import { Padrao } from 'components/common/filtros/Padrao';
import { Autocompletar, SimplesAutocompletar } from 'components/common/filtros/Autocompletar';
import { Tabela } from 'components/common/Tabela';
import ModalConfirmarExclusaoArquivo from './ModalExcluirArquivo';
import ModalVisualizadorLogs from './ModalVisualizadorLogs';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';

const DateField = ({ record }) => <span>{format(parseISO(record.criado), 'dd/MM/yyyy')}</span>;

const NotificadoField = ({ record }) => {
	const { data } = useListContext();
	const { notificado } = data[`${record.id}`];

	if (notificado) {
		return <PrimaryChip value='Notificado' />;
	}
	return <WarningChip value='Não notificado' />;
};

const LinkField = ({ record }) => (
	<a href={record.anexo} target='blank' style={{ textDecoration: 'none' }}>
		<PrimaryChip
			value={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '5px',
						cursor: 'pointer',
					}}
				>
					Visualizar
					<LinkIcon />
				</div>
			}
		/>
	</a>
);

const TabelaConsultaRelatorio = ({ props }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	const fechaModal = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};
	return (
		<Tabela
			{...props}
			hasBulkActions
			clickRowCustom={({ record }) =>
				() => {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <ModalVisualizadorLogs arquivo={record} open={true} onClose={fechaModal} />,
					}));
				}}
		>
			<ReferenceField
				source='condominio_id'
				reference='condominios'
				label='Condomínio'
				link={false}
				sortBy='nome_condominio'
				filtro={<Autocompletar refName='nome' refResource='condominios' mostrarInativos={false} />}
				minWidth='200px'
			>
				<TextField source='nome' />
			</ReferenceField>
			<TextField source='grupo' label='Grupo da unidade' filtro={<Padrao />} />
			<TextField source='unidade' label='Unidade' filtro={<Padrao />} />
			<TextField source='nome' label='Relatório' filtro={<Padrao />} />
			<TextField
				source='tipo'
				label='Tipo'
				filtro={
					<Autocompletar
						opcoes={[
							{ id: 'boleto', name: 'Boleto' },
							{ id: 'relatorio', name: 'Relatório' },
						]}
					/>
				}
			/>
			<DateField source='criado' label='Data Geração' filtro={<Data />} />
			<NotificadoField
				source='notificado'
				label='Notificado'
				filtro={
					<SimplesAutocompletar
						opcoes={[
							{ id: false, name: 'Não notificado' },
							{ id: true, name: 'Notificado' },
						]}
					/>
				}
			/>
			<LinkField source='anexo' label='Anexo' filtro={<Padrao />} />
		</Tabela>
	);
};

const ActionMenu = ({ record }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openModalExcluir, setOpenModalExcluir] = React.useState(false);
	const [openVisualizadorLogs, setOpenVisualizadorLogs] = React.useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const openDeleteModal = () => {
		setOpenModalExcluir(true);
	};
	const handleImprimirAnexo = () => {
		window.open(record.anexo, '_blank');
	};

	const handleVisualizarLogs = () => {
		setOpenVisualizadorLogs(true);
	};

	return (
		<div>
			<IconButton onClick={handleClick}>
				<ArrowDropDownIcon />
			</IconButton>
			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={handleVisualizarLogs}>
					<Box display='flex' gridGap='0.7em'>
						<VisibilityIcon color='primary' />
						<Typography>Visualizar log</Typography>
					</Box>
				</MenuItem>
				<ModalVisualizadorLogs
					arquivo={record}
					open={openVisualizadorLogs}
					onClose={() => setOpenVisualizadorLogs(false)}
				/>
				<MenuItem onClick={handleImprimirAnexo}>
					<Box display='flex' gridGap='0.7em'>
						<InsertDriveFileIcon color='primary' />
						<Typography>Imprimir anexo</Typography>
					</Box>
				</MenuItem>
				{record.id.includes('C') ? null : (
					<MenuItem onClick={openDeleteModal}>
						<Box display='flex' gridGap='0.7em'>
							<DeleteIcon color='error' />
							<Typography>Excluir</Typography>
						</Box>
					</MenuItem>
				)}
			</Menu>
			<ModalConfirmarExclusaoArquivo
				idArquivo={record.id}
				open={openModalExcluir}
				onClose={() => setOpenModalExcluir(false)}
			/>
		</div>
	);
};

const Actions = () => {
	const dp = useDataProvider();
	const { data, selectedIds, onUnselectItems } = useListContext();
	const notify = useNotify();
	const refresh = useRefresh();
	const [loading, setLoading] = useState('');
	const selectedRows = selectedIds.reduce((acc, curr_id) => {
		const curr = data[curr_id];
		if (curr) acc.push(curr);
		return acc;
	}, []);

	const temAlgumNaoNotificado = selectedRows.some(({ notificado }) => !notificado);
	const temBoletos = selectedRows.some(({ tipo }) => tipo === 'Boleto');

	const handleDelete = () => {
		setLoading('Deletar');
		const ids = selectedRows.reduce((ids, { id }) => {
			if (id.includes('R')) ids.push(id.split('-')[1]);
			return ids;
		}, []);
		dp.deleteMany('consulta_relatorio', { ids })
			.catch((e) => notify('Inconsistencia na exclusão dos relatórios'))
			.finally(() => {
				setLoading('');
				refresh(true);
				onUnselectItems();
			});
	};

	const handleNotificar = () => {
		setLoading('Notificar');
		const ids = selectedRows
			.filter(({ notificado }) => !notificado)
			.map(({ real_id }) => ({
				id: real_id,
				data: {
					notificado: true,
				},
			}));
		dp.updateMany('consulta_relatorio', ids)
			.then(() => notify('Relatórios notificados com sucesso'))
			.catch(() => notify('Inconsistencia na notificação dos relatórios'))
			.finally(() => {
				refresh(true);
				setLoading('');
				onUnselectItems();
			});
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
			<Button
				loading={loading === 'Deletar'}
				color='secondary'
				startIcon={<DeleteIcon />}
				size='small'
				onClick={handleDelete}
				disabled={temBoletos}
			>
				Excluir selecionados
			</Button>
			{temAlgumNaoNotificado ? (
				<Button
					loading={loading === 'Notificar'}
					color='primary'
					startIcon={<SendIcon />}
					size='small'
					onClick={handleNotificar}
				>
					Notificar selecionados
				</Button>
			) : null}
		</div>
	);
};

function ConsultaRelatorioList(props) {
	return (
		<List
			{...props}
			basePath='/consulta_arquivo'
			resource='consulta_arquivo'
			exporter={brExporter}
			empty={false}
			bulkActionButtons={<Actions />}
			sort={{ field: 'criado', order: 'DESC' }}
			perPage={10}
			title=' '
			pagination={
				<Pagination
					rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
					labelRowsPerPage='Relatórios por página'
				/>
			}
		>
			<ModalSizeEditableContextProvider>
				<TabelaConsultaRelatorio {...props} />
			</ModalSizeEditableContextProvider>
		</List>
	);
}

export default ConsultaRelatorioList;
