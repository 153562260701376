import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBody } from '../../../../common/ModalContext';
import { ButtonClose } from '../../../../common/buttons/ButtonClose';
import { ButtonRemove } from '../../../../common/buttons/ButtonRemove';

const ModalFeedbackBloqueioRemover = ({ setModalValue, usado_em_arrecadacao, usado_em_lancamento }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBody
			title='Esta Unidade não pode ser removida'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>
							<ul>
								<>
									{usado_em_arrecadacao && (
										<li>Esta unidade está vinculada a ao menos uma arrecadação</li>
									)}
									{usado_em_lancamento && (
										<li>Esta unidade está vinculada a ao menos um lançamento</li>
									)}
								</>
							</ul>
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
						}}
						size='small'
					>
						cancelar
					</ButtonClose>
					<ButtonRemove disabled={true} size='small' />
				</>
			}
		/>
	);
};

export default ModalFeedbackBloqueioRemover;
