import React from 'react';

import { useTheme, Chip, Typography } from '@material-ui/core';

const SituacaoChip = ({ value, textColor, ...rest }) => (
	<Chip
		label={
			<Typography variant='caption' style={{ color: textColor }}>
				{value}
			</Typography>
		}
		size='small'
		clickable={false}
		style={{ background: '#f1f1f1' }}
		{...rest}
	/>
);

export const SituacaoField = ({ record }) => {
	const situacao = record?.situacao;
	const theme = useTheme();
	switch (situacao) {
		case 'V':
			return <SituacaoChip value='Vencida' textColor={theme.palette.error.dark} />;
		case 'A':
			return <SituacaoChip value='Em Aberto' textColor={theme.palette.primary.dark} />;
		case 'C':
			return <SituacaoChip value='Concluída' textColor={theme.palette.success.dark} />;
		case 'EA':
			return <SituacaoChip value='Em Atraso' textColor={theme.orange[200]} />;
		case 'VH':
			return <SituacaoChip value='Vencendo Hoje' textColor={theme.orange[200]} />;
		default:
			return <SituacaoChip value={situacao} />;
	}
};
