import lodashMemoize from 'lodash/memoize';
import { email } from 'react-admin';
import api from '../services/api';
import { required } from 'react-admin';

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

export const verificacao = memoize(async (value, resource, isRequired = false) => {
	if (value) {
		if (
			value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
		) {
			try {
				const res = await api.get(`api/v1/${resource}/${value.replaceAll('.', '@ponto@')}/buscar_email`);
				if (res.status === 200) return ['E-mail já cadastrado'];
			} catch {
				return null;
			}
		} else {
			return ['E-mail inválido'];
		}
	}
	if (isRequired) {
		return ['Campo obrigatório'];
	}
	return null;
});

export const emailValidacao = [email('E-mail inválido')];

export const validacaoEmailObrigatorio = [email('E-mail inválido'), required()];
