import React, { forwardRef, cloneElement, useCallback } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarVisibility } from 'ra-core';
import { MenuItem, ListItemIcon, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles, withStyles, alpha } from '@material-ui/core/styles';

const NavLinkRef = forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

const useStyles = makeStyles(
	(theme) => ({
		root: {
			color: theme.palette.text.primary,
			borderRadius: 5,
			margin: '0 10px',
			paddingLeft: '5px',
		},
		active: {
			color: theme.palette.primary.dark,
			background: alpha(theme.palette.primary.light, 0.1),
			'&:hover': {
				background: alpha(theme.palette.primary.light, 0.15),
			},
			'& svg': {
				fill: `${theme.palette.primary.dark} !important`,
			},
		},
		icon: {
			minWidth: theme.spacing(5),
		},
	}),
	{ name: 'RaMenuItemLink' }
);

const CustomTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.grey[800],
		color: '#fff',
		maxWidth: 220,
		fontSize: '10pt',
	},
	arrow: {
		color: theme.palette.grey[800],
	},
}))(Tooltip);

const MenuItemLink = forwardRef((props, ref) => {
	const {
		classes: classesOverride,
		className,
		primaryText,
		leftIcon,
		onClick,
		sidebarIsOpen,
		tooltipProps,
		...rest
	} = props;
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const open = useSelector((state) => state.admin.ui.sidebarOpen);
	const handleMenuTap = useCallback(
		(e) => {
			if (isSmall) {
				dispatch(setSidebarVisibility(false));
			}
			onClick && onClick(e);
		},
		[dispatch, isSmall, onClick]
	);

	const renderMenuItem = () => {
		return (
			<MenuItem
				className={classnames(classes.root, className)}
				activeClassName={classes.active}
				component={NavLinkRef}
				ref={ref}
				tabIndex={0}
				{...rest}
				onClick={handleMenuTap}
			>
				{leftIcon && (
					<ListItemIcon className={classes.icon}>
						{cloneElement(leftIcon, {
							titleAccess: primaryText,
						})}
					</ListItemIcon>
				)}
				{primaryText}
			</MenuItem>
		);
	};

	return open ? (
		renderMenuItem()
	) : (
		<CustomTooltip arrow title={primaryText} placement='right' {...tooltipProps}>
			{renderMenuItem()}
		</CustomTooltip>
	);
});

export default MenuItemLink;
