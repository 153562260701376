import * as React from 'react';
import { List, Pagination, TextField, ReferenceField } from 'react-admin';
import brExporter from '../../../utils/exporter';
import { situacaoOpcoes } from '../../../fieldControl/situacao';
import { Tabela } from '../../common/Tabela';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Padrao } from '../../common/filtros/Padrao';
import { cnpjFormatacao } from '../../../fieldControl/documento';
import { cepFormatacao } from '../../../fieldControl/cep';
import { CreateButton, ExportButton, TopToolbar, useListContext, Button as RAButton } from 'react-admin';
import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import ImportCondominiosContextProvider from './common/ImportCondominiosContextProvider';

const SituacaoField = ({ record }) => <span>{record?.situacao === 'A' ? 'Ativo' : 'Inativo'}</span>;

const CepField = ({ record }) => <span>{cepFormatacao(record?.cep)}</span>;

const DocumentoField = ({ record }) => <span>{cnpjFormatacao(record?.cnpj)}</span>;

const TabelaCondominios = (props) => (
	<Tabela rowClick='edit' {...props}>
		<TextField
			source='codigo'
			label='Código'
			filtro={<Padrao />}
			id='tabela-row-codigo-condominio'
			maxWidth='1px'
		/>
		<ReferenceField
			source='tipo_condominio'
			link={false}
			reference='tipo_condominio'
			label='Tipo'
			filtro={<Autocompletar refResource='tipo_condominio' refName='descricao' />}
			id='tabela-row-tipo-condominio'
		>
			<TextField source='descricao' />
		</ReferenceField>
		<TextField source='apelido' label='Apelido' filtro={<Padrao />} id='tabela-row-apelido-condominio' />
		<DocumentoField
			source='cnpj'
			label='CNPJ'
			filtro={<Padrao />}
			style={{ width: 100 }}
			id='tabela-row-cnpj-condominio'
		/>
		<TextField source='nome' label='Nome' filtro={<Padrao />} id='tabela-row-nome-condominio' />
		<TextField source='cidade' label='Cidade' filtro={<Padrao />} id='tabela-row-cidade-condominio' />
		<CepField source='cep' label='CEP' filtro={<Padrao />} style={{ width: 100 }} id='tabela-row-cep-condominio' />
		<SituacaoField
			source='situacao'
			label='Situação'
			id='tabela-row-situacao-condominio'
			filtro={<Autocompletar opcoes={situacaoOpcoes} />}
		/>
	</Tabela>
);

const ListActions = (props) => {
	const { className, basePath, total, resource, currentSort, filterValues, exporter } = props;
	return (
		<TopToolbar className={className}>
			<CreateButton basePath={basePath} />
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
			<RAButtonImport {...props} />
		</TopToolbar>
	);
};

const RAButtonImport = () => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	return (
		<RAButton
			label='Importar'
			id='importar_condominio'
			color='primary'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ImportCondominiosContextProvider />,
				}));
			}}
		>
			<SwapVerticalCircleOutlinedIcon />
		</RAButton>
	);
};

export const CondominiosList = (props) => {
	return (
		<ModalSizeEditableContextProvider
			customDialogProps={{
				disableBackdropClick: true,
				disableEscapeKeyDown: true,
				minWidth: '95vw',
			}}
		>
			<List
				{...props}
				actions={<ListActions />}
				exporter={brExporter}
				empty={false}
				bulkActionButtons={false}
				filterDefaultValues={{ situacao: 'A' }}
				sort={{ field: 'nome', order: 'ASC' }}
				perPage={10}
				pagination={
					<Pagination
						rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
						labelRowsPerPage='Condomínios por página'
					/>
				}
			>
				<TabelaCondominios />
			</List>
		</ModalSizeEditableContextProvider>
	);
};
