import React from 'react';
import { Dialog, DialogActions, CircularProgress, Typography, Box } from '@material-ui/core';
import { CloseButton, ConfirmButton } from 'components/common/commonComponentsTSX';

type ModalConfirmaEdicaoContaAPagarProps = {
	open: boolean;
	onClose: (event: React.SyntheticEvent<boolean>, reason: 'backdropClick' | 'escapeKeyDown') => void;
	onConfirm: (loading: boolean, setLoading: React.Dispatch<React.SetStateAction<boolean> | boolean>) => void;
};

const ModalConfirmaEdicaoContaAPagar: React.FC<ModalConfirmaEdicaoContaAPagarProps> = ({
	open,
	onClose,
	onConfirm,
}) => {
	const [loading, setLoading] = React.useState(false);

	const handleSave = () => {
		onConfirm(loading, setLoading);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '30vw',
					position: 'relative',
				},
			}}
		>
			<Box
				sx={{
					padding: '20px',
				}}
			>
				<Typography variant='h6'>
					Caso o valor ou o número de parcelas tenham sido alterados, o histórico entre a parcela alterada e
					sua arrecadação será perdido. Deseja continuar?
				</Typography>
			</Box>
			<DialogActions>
				<CloseButton onClick={onClose} size='small' disabled={loading}>
					{(loading && <CircularProgress size={20} />) || 'Cancelar'}
				</CloseButton>
				<ConfirmButton onClick={handleSave} size='small' disabled={loading}>
					{(loading && <CircularProgress size={20} />) || 'Confirmar'}
				</ConfirmButton>
			</DialogActions>
		</Dialog>
	);
};

export default ModalConfirmaEdicaoContaAPagar;
