import React from 'react';
import { Create } from 'react-admin';

import { FornecedorFormulario } from './form';

export const FornecedorCreate = (props) => (
	<Create title='Cadastrar Fornecedor' undoable={false} {...props}>
		<FornecedorFormulario {...props} />
	</Create>
);
