import React, { useContext } from 'react';

import SelectContaBancaria from '../../SelectContaBancaria';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

const ContaBancariaSelect = ({ disabled = false, label = 'Selecionar Conta Bancária' }) => {
	const { contaBancaria, contasBancarias, setContaBancaria } = useContext(FluxoImportacaoExtratoBancarioContext);

	return (
		<SelectContaBancaria
			label={label}
			setValue={setContaBancaria}
			value={contaBancaria}
			options={contasBancarias}
			disabled={disabled}
			disableClearable={true}
		/>
	);
};

export default ContaBancariaSelect;
