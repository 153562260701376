import React from 'react';
import { Edit, useNotify, useRedirect, useRefresh } from 'react-admin';

import { AdvogadoFormulario } from './form';
import { AdvogadoProps, AdvogadoResponse } from './types';

export const AdvogadoEdit = (props: AdvogadoProps) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response: AdvogadoResponse) => {
		const data = response?.data;
		if (data) {
			notify(`Advogado(a) ${data.nome} alterado(a) com sucesso`);
			redirect('list', props.basePath);
			refresh();
		}
	};
	return (
		<Edit component='div' title={'Editar Advogado(a)'} onSuccess={onSuccess} undoable={false} {...props}>
			<AdvogadoFormulario edit {...props} />
		</Edit>
	);
};
