import EditaContaCaixaContext from './EditaContaCaixaContext';
import EditaContaNaoCorrenteContext from './EditaContaNaoCorrenteContext';
import EditaContaCorrenteContext from './EditaContaCorrenteContext';

const tipoContaContextDict = {
	Caixa: EditaContaCaixaContext,
	ContaNaoCorrente: EditaContaNaoCorrenteContext,
	ContaCorrente: EditaContaCorrenteContext,
};

export default tipoContaContextDict;
