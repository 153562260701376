import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Arquivo, CustomDataProviderDocuments, CustomGetSimpleParams } from '../types';
import { CondominiosContext } from 'context/CondominioContextProvider';

type RedirectScreen = {
	folderId: number | null;
	screen: 'folder' | 'file';
	selectedFolderName: string;
};

export interface Pasta {
	id: number;
	nome: string;
	has_files: boolean;
	tamanho: string;
	criado: string;
	alterado: string;
	administradora: number;
	condominio: number;
}

type RedirectContext = {
	handleSetRedirectContext: (screen: 'folder' | 'file', folderId: number | null) => void;
	screen: RedirectScreen;
	folders: Pasta[];
	handleCollectFolders: (params: CustomGetSimpleParams) => void;
	handleCollectFiles: (params: CustomGetSimpleParams) => void;
	files: Arquivo[];
	folderName: string;
	setFolderName: React.Dispatch<React.SetStateAction<string>>;
};

export const DocumentsContext = React.createContext<RedirectContext>({
	screen: {
		screen: 'folder',
		folderId: null,
		selectedFolderName: '',
	},
	handleSetRedirectContext: () => {},
	folders: [],
	handleCollectFolders: () => {},
	handleCollectFiles: () => {},
	files: [],
	folderName: '',
	setFolderName: () => {},
});

const DocumentsProvider = React.memo(({ children }: { children: React.ReactNode }) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const [folderName, setFolderName] = React.useState('');
	const [redirectScreen, setRedirectScreen] = React.useState<RedirectScreen>({
		screen: 'folder',
		folderId: null,
		selectedFolderName: '',
	});
	const [folders, setFolders] = React.useState<Pasta[]>([]);
	const [files, setFiles] = React.useState<Arquivo[]>([]);
	const notify = useNotify();

	const dp = useDataProvider() as CustomDataProviderDocuments;

	const handleCollectFiles = React.useCallback((params: CustomGetSimpleParams) => {
		dp.getSimple('documento_arquivo', {
			sort: params.sort,
			pagination: params.pagination,
			filter: params.filter,
		})
			.then((response) => {
				const data: Arquivo[] = response?.data?.results;
				return setFiles(data);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Erro inesperado, tente recarregar a página!', { type: 'error' });
			});
	}, []);

	const handleCollectFolders = React.useCallback((params: CustomGetSimpleParams) => {
		dp.getSimple('documento_pasta', {
			sort: params.sort,
			pagination: params.pagination,
			filter: params.filter,
		})
			.then((response) => {
				const data: Pasta[] = response?.data?.results;
				return setFolders(data);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Erro inesperado, tente recarregar a página!', { type: 'error' });
			});
	}, []);

	React.useEffect(() => {
		const idTimeout = setTimeout(() => {
			const filter = folderName
				? { condominio: condominioSelecionado?.id, nome: folderName }
				: { condominio: condominioSelecionado?.id };
			handleCollectFolders({
				filter: filter,
				sort: { order: 'ASC', field: 'nome' },
				pagination: {
					perPage: 10000,
					page: 1,
				},
			});
		}, 1200);

		return () => {
			clearTimeout(idTimeout);
		};
	}, [condominioSelecionado?.id, folderName]);

	const handleSetRedirectContext = (screen: 'folder' | 'file', folderId: number | null) => {
		if (screen === 'folder') {
			setFiles([]);
			setRedirectScreen({
				screen: screen,
				folderId: folderId,
				selectedFolderName: folders.find((folder) => folder.id === folderId)?.nome || '',
			});
			return handleCollectFolders({
				filter: { condominio: condominioSelecionado?.id },
				sort: { order: 'ASC', field: 'nome' },
				pagination: {
					perPage: 10000,
					page: 1,
				},
			});
		}
		setRedirectScreen({
			screen: screen,
			folderId: folderId,
			selectedFolderName: folders.find((folder) => folder.id === folderId)?.nome || '',
		});
	};

	const DocumentsPoviderValue = React.useMemo(
		() => ({
			handleSetRedirectContext: handleSetRedirectContext,
			screen: redirectScreen,
			folders: folders,
			handleCollectFolders: handleCollectFolders,
			handleCollectFiles: handleCollectFiles,
			files: files,
			folderName: folderName,
			setFolderName: setFolderName,
		}),
		[setRedirectScreen, redirectScreen, folders, files, folderName, setFolderName]
	);
	return <DocumentsContext.Provider value={DocumentsPoviderValue}>{children}</DocumentsContext.Provider>;
});

export default DocumentsProvider;
