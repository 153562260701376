import { format } from 'date-fns';

import {
	TValorDecimal,
	TDateISODate,
	RequestCreateData,
	TStateData,
	TStateDataForManual,
	TStateDataForIndexes,
} from './types';

const formatDecimalValue = (decimalValue: TValorDecimal): number =>
	decimalValue === '00,0000' ? 0 : parseFloat(decimalValue.toString().replace(',', ''));

const formatRequestPayloadCreateForManual: (data: TStateDataForManual) => RequestCreateData = ({
	nome,
	tipoIndice,
	ultimaDataAtualizacao,
	ultimoValorCalculado,
	feesOptions,
	feesPercentage,
	finePercentage,
	allowDuplicateGeneration,
	daysLimitAfterExpiration,
}) => ({
	nome: nome,
	tipo_indice: tipoIndice,
	ultima_data_atualizacao: format(ultimaDataAtualizacao as Date, 'yyyy-MM-dd') as TDateISODate,
	ultimo_valor_calculo: ultimoValorCalculado ? formatDecimalValue(ultimoValorCalculado) : 0,
	fees_option: feesOptions.option_daily_fees ? 'JD' : feesOptions.option_monthly_fees ? 'JM' : null,
	fees_percentage: formatDecimalValue(feesPercentage),
	fine_percentage: formatDecimalValue(finePercentage),
	allow_duplicate_generation: allowDuplicateGeneration ? 'S' : 'N',
	days_limit_after_expiration: allowDuplicateGeneration ? daysLimitAfterExpiration : 0,
});

const formatRequestPayloadCreateForIndexes: (data: TStateDataForIndexes) => RequestCreateData = ({
	nome,
	tipoIndice,
	feesOptions,
	feesPercentage,
	finePercentage,
	allowDuplicateGeneration,
	daysLimitAfterExpiration,
}) => ({
	nome: nome,
	tipo_indice: tipoIndice,
	ultima_data_atualizacao: null,
	ultimo_valor_calculo: null,
	fees_option: feesOptions.option_daily_fees ? 'JD' : feesOptions.option_monthly_fees ? 'JM' : null,
	fees_percentage: formatDecimalValue(feesPercentage),
	fine_percentage: formatDecimalValue(finePercentage),
	allow_duplicate_generation: allowDuplicateGeneration ? 'S' : 'N',
	days_limit_after_expiration: allowDuplicateGeneration ? daysLimitAfterExpiration : 0,
});

const formatRequestPayloadCreate: (data: TStateData) => RequestCreateData = (data) => {
	return data.tipoIndice === 'MANUAL'
		? formatRequestPayloadCreateForManual(data as TStateDataForManual)
		: formatRequestPayloadCreateForIndexes(data as TStateDataForIndexes);
};

export default formatRequestPayloadCreate;
