import React from 'react';
import { ConfigRelatorioProvider } from './ConfigRelatorioContextProvider';
import { ConfiguracaoRelatorioForm } from './form';

const ConfiguracaoRelatorio = (props) => {
	return (
		<ConfigRelatorioProvider>
			<ConfiguracaoRelatorioForm {...props} />
		</ConfigRelatorioProvider>
	);
};

export default ConfiguracaoRelatorio;
