import React from 'react';

import { useNotify, useRefresh } from 'react-admin';

import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';
import { ModalErrosReportRemessas } from '../modais';

const objectDescricoesDeTiposRegistros = {
	condominio: 'O condomínio',
	conta_corrente: 'O beneficiário da conta corrente',
	unidade: 'A unidade',
	propetario: 'O proprietário',
	inquilino: 'O inquilino',
	pessoa: 'A pessoa',
	conta_a_receber: 'A conta',
	boleto: 'O boleto',
	administradora: 'O',
};

const LinhaErroComUrl = ({ tipo, nome, url, erros }) => (
	<Typography>
		{objectDescricoesDeTiposRegistros[tipo] || '?'}{' '}
		<Link to={url} target='_blank'>
			{nome}
		</Link>
		{` ${erros.join(', ')}`}.
	</Typography>
);

const LinhaErroSemUrl = ({ tipo, nome, erros }) => (
	<Typography>
		{objectDescricoesDeTiposRegistros[tipo] || '?'} {nome}
		{` ${erros.join(', ')}`}.
	</Typography>
);

const objectComponenteLinhaErroPorTipo = {
	condominio: LinhaErroComUrl,
	conta_corrente: LinhaErroComUrl,
	unidade: LinhaErroComUrl,
	propetario: LinhaErroComUrl,
	inquilino: LinhaErroComUrl,
	pessoa: LinhaErroComUrl,
	conta_a_receber: LinhaErroSemUrl,
	boleto: LinhaErroSemUrl,
	administradora: LinhaErroComUrl,
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export const useTratarResposta = ({
	setModalValue,
	ButtonGerarRemessas,
	erroSemRecebimentoTitle = 'Arquivo de remessa só pode ser emitido caso não tenha registros já vencidos',
	errorsValidacaoTitle = 'Inconsistências na Geração da Remessa e Boletos',
}) => {
	const notify = useNotify();
	const refresh = useRefresh();

	const tratarResposta = React.useCallback(
		(response) => {
			const data = response?.data;
			if (!data) {
				notify('Erro inesperado, tente recarregar a página', 'warning');
				return;
			}
			if (typeof data === 'object') {
				const { boletos_gerados_por_api_do_banco, text, fileName, erro_sem_recebimento, errors_validacao } =
					data;

				if (boletos_gerados_por_api_do_banco) {
					notify(
						'Boletos estão sendo gerados pela api do banco, por favor aguarde alguns momentos enquanto o banco os processa'
					);
					refresh();
					setModalValue((v) => ({ ...v, open: false }));
					return;
				}

				if (text) {
					const downloadLink = document.createElement('a');
					const blob = b64toBlob(text, 'text/plain,charset=us-ascii');
					downloadLink.href = URL.createObjectURL(blob);
					downloadLink.download = fileName;
					downloadLink.click();
					refresh();
					setModalValue((v) => ({ ...v, open: false }));
					return;
				}

				if (erro_sem_recebimento) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalErrosReportRemessas
								title={erroSemRecebimentoTitle}
								ButtonGerarRemessas={ButtonGerarRemessas}
							/>
						),
					}));
					return;
				}

				if (errors_validacao) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalErrosReportRemessas
								title={errorsValidacaoTitle}
								text='Verificamos que os cadastros abaixo encontram-se incompletos, verifique os campos (CPF/CNPJ, Endereço, Bairro, CEP, Unidade e UF)'
								form={{
									component: (
										<>
											{errors_validacao.map(({ tipo, ...props }) => {
												const ComponenteLinhaErro = objectComponenteLinhaErroPorTipo[tipo];
												if (!ComponenteLinhaErro) return null;
												return <ComponenteLinhaErro tipo={tipo} {...props} />;
											})}
										</>
									),
								}}
								ButtonGerarRemessas={ButtonGerarRemessas}
							/>
						),
					}));
					return;
				}
			}
		},
		[notify, refresh, setModalValue, ButtonGerarRemessas, erroSemRecebimentoTitle, errorsValidacaoTitle]
	);

	return tratarResposta;
};

export default useTratarResposta;
