import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { format } from 'date-fns';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ApartmentIcon from '@material-ui/icons/Apartment';

import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	TextField,
	makeStyles,
} from '@material-ui/core';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';

import { groupedHoursToObject } from './CardReservasEBloqueios/utils';
import weekDayToString from './CardReservasEBloqueios/utils/weekDaysToString';
import { SelectHorarios } from '../inputs/SelectHorarios';

import { CustomDataProvider } from 'types/tpyesGlobal';
import { IBloqueioReduce, IConsultaState, ThemePaletteProps } from './types';

const useStyles = makeStyles<ThemePaletteProps>((theme) => ({
	goBackButton: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.main,
			color: '#fff',
		},
	},
	saveButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
	},
}));

const ModalBloquearReserva = () => {
	const {
		consulta: { ambienteSelecionado, diaSelecionado, condominioSelecionado, showModalBloquearReservas },
		actionCallBackReloadReserva,
		setConsulta,
	} = React.useContext<any>(ConsultaDeReservasContext);

	const dp = useDataProvider() as CustomDataProvider;
	const notify = useNotify();
	const classes = useStyles();

	const [configBloquearReserva, setConfigBloquearReserva] = React.useState<string[]>();
	const [selectedHorarios, setSelectedHorarios] = React.useState();
	const [text, setText] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newText = e.target.value;

		if (newText.length <= 30) {
			setText(newText);
		}
	};

	const fetchDataSelectedDay = () => {
		if (!diaSelecionado || !ambienteSelecionado || !condominioSelecionado) return;
		Promise.allSettled([
			dp.getSimple('reserva_dia', {
				filter: {
					data: format(diaSelecionado, 'yyyy-MM-dd'),
					condominio_id: condominioSelecionado?.id,
					dia: weekDayToString(diaSelecionado),
					ambiente_id: ambienteSelecionado?.id,
				},
			}),
			dp.getSimple('bloqueio_reservas_syndkos', {
				filter: {
					dia_bloqueado: format(diaSelecionado, 'yyyy-MM-dd'),
					condominio_id: condominioSelecionado?.id,
					ambiente_id: ambienteSelecionado?.id,
				},
			}),
		])
			.then(([responsesReservasDias, responseBloqueios]: any) => {
				const [reservaDia] = responsesReservasDias.value?.data?.results ?? [];
				if (responseBloqueios.value?.data?.results.length > 0) {
					const bloqueios = responseBloqueios.value.data.results.reduce(
						(intervalos: any, bloqueio: IBloqueioReduce) => {
							if (bloqueio.bloqueios_intervalos_tempo && bloqueio.bloqueios_intervalos_tempo.length > 0) {
								intervalos = intervalos.concat(bloqueio.bloqueios_intervalos_tempo);
							}
							return intervalos;
						},
						[]
					);
					if (reservaDia.reservas.length === 0) {
						reservaDia.reservas[0] = { reservas_intervalos_tempo: [...bloqueios] };
					}
					const intervalosBloqueados =
						[...reservaDia.reservas[0].reservas_intervalos_tempo, ...bloqueios] ?? [];
					reservaDia.reservas[0].reservas_intervalos_tempo = intervalosBloqueados;
				}
				setConfigBloquearReserva(reservaDia);
			})
			.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : []));
	};

	React.useEffect(
		fetchDataSelectedDay,
		// eslint-disable-next-line
		[diaSelecionado]
	);

	const handleSaveBloqueio = () => {
		const bloqueiosIntervalosTempo = groupedHoursToObject(selectedHorarios);
		setLoading(true);
		dp.create('bloqueio_reservas_syndkos', {
			data: {
				dia_bloqueado: format(diaSelecionado, 'yyyy-MM-dd'),
				motivo_bloqueio: text,
				condominio: condominioSelecionado?.id,
				ambiente: ambienteSelecionado?.id,
				dia_semana: weekDayToString(diaSelecionado),
				bloqueios_intervalos_tempo: bloqueiosIntervalosTempo,
			},
		})
			.then(() => {
				notify('Bloqueio de reserva concluído.', { type: 'success' });
				setLoading(false);
				setConsulta((prevState: IConsultaState) => ({
					...prevState,
					showModalBloquearReservas: false,
				}));
				actionCallBackReloadReserva();
			})
			.catch(({ response }) => {
				const errorMessage = response?.data || null;
				const hasJsonResponse = response?.headers['content-type'] === 'application/json';
				setLoading(false);
				if (!errorMessage || !hasJsonResponse) {
					return notify('Tente novamente. Erro ao agendar reserva.', 'warning');
				}

				Object.keys(errorMessage).forEach((keyName) => {
					notify(`Ação inválida. ${errorMessage[keyName][0]}`, 'warning');
				});
			});
	};

	return (
		<Dialog
			open={showModalBloquearReservas}
			onClose={() =>
				setConsulta((prevState: IConsultaState) => ({
					...prevState,
					showModalBloquearReservas: false,
				}))
			}
			fullWidth
			PaperProps={{
				style: {
					minHeight: '25rem',
					minWidth: '61vw',
					position: 'relative',
				},
			}}
		>
			<DialogTitle>
				<Typography variant='h4'>
					<strong>Selecione o Período para Bloqueio</strong>
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box display='flex' gridGap='0.7em' mt={1}>
					<CalendarTodayIcon />
					<Typography>
						Data do Bloqueio: <strong>{format(diaSelecionado, 'dd/MM/yyyy')}</strong>
					</Typography>
				</Box>
				<Box display='flex' gridGap='0.7em' mt={1} mb={2}>
					<ApartmentIcon />
					<Typography>
						Condomínio: <strong>{condominioSelecionado?.nome}</strong>
					</Typography>
				</Box>
				<hr />
				<Box mt={3} mb={4}>
					{configBloquearReserva && (
						<SelectHorarios
							configCriarReserva={configBloquearReserva}
							setSelectedHorarios={setSelectedHorarios}
							selectedHorarios={selectedHorarios}
							configEdicaoReserva={undefined}
						/>
					)}
				</Box>
				<hr />
			</DialogContent>
			<DialogTitle>
				<Typography variant='h5'>
					<strong>Motivo do Bloqueio</strong>
				</Typography>
			</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					size='small'
					variant='outlined'
					placeholder='Escreva o motivo do bloqueio...'
					value={text}
					onChange={handleTextChange}
				></TextField>
				<Box margin={1}>{text.length}/30 caracteres</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleSaveBloqueio}
					disabled={loading || Boolean(!selectedHorarios)}
					className={classes.saveButton}
					variant='contained'
				>
					Salvar
				</Button>
				<Button
					onClick={() =>
						setConsulta((prevState: IConsultaState) => ({
							...prevState,
							showModalBloquearReservas: false,
						}))
					}
					className={classes.goBackButton}
					variant='contained'
				>
					Voltar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ModalBloquearReserva;
