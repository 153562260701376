import React from 'react';

import { Button } from '@material-ui/core';

import { ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';
import EditaContaBancariaContext from '../../contexts/EditaContaBancariaContext';
import DadosPorTipoIntegracaoContext from '../../contexts/DadosPorTipoIntegracaoContext';

export const CadastrarDadosIntegracaoAPIButton = () => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { isInativo } = React.useContext(EditaContaBancariaContext);
	const {
		props: {
			CadastrarDadosIntegracaoAPIButton: { ModalCadastrarDadosIntegracaoAPI },
		},
	} = React.useContext(DadosPorTipoIntegracaoContext);

	return (
		<Button
			color='primary'
			variant='text'
			size='small'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalCadastrarDadosIntegracaoAPI />,
				}));
			}}
			disabled={isInativo}
		>
			Cadastrar dados para Integração com API
		</Button>
	);
};
