import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import CondominioSelect from './CondominioSelect';
import { Title } from 'react-admin';
import { PrevisoesOrcamentariasContextProvider } from './contexts/PrevisoesOrcamentariasContextProvider';
import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import { ModeloPrevisaoOrcamentariaSelect } from './ModeloPrevisaoOrcamentariaSelect';
import FormModeloPrevisao from './formModeloPrevisao';

function ConfiguracaoPrincipalPage() {
	return (
		<Card>
			<Title title='Previsões Orçamentárias' />
			<CardContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1em',
				}}
			>
				<Box flex={1} style={{ padding: '1em' }}>
					<Box flexDirection='column' display='flex' style={{ gap: 10 }}>
						<Typography variant='h4'>Previsões Orçamentárias</Typography>
						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
								width: '100%',
							}}
						/>
					</Box>
					<Box
						style={{
							marginBottom: '1em',
						}}
					>
						<CondominioSelect />
					</Box>
					<PrevisoesOrcamentariasContextProvider>
						<ModalSizeEditableContextProvider>
							<ModeloPrevisaoOrcamentariaSelect />
							<FormModeloPrevisao />
						</ModalSizeEditableContextProvider>
					</PrevisoesOrcamentariasContextProvider>
				</Box>
			</CardContent>
		</Card>
	);
}

export default ConfiguracaoPrincipalPage;
