import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';
import { BotaoProximo } from 'components/common/buttons/BotaoProximo';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import MenuAdicionarArrecadacao, { menuItems as mI } from '../../../Arrecadacoes/MenuAdicionarArrecadacao';
import {
	BoxAddConta,
	BoxAddLeitura,
	BoxTipoFundo,
	BoxTipoLancamento,
	BoxValorDesc,
} from '../../../Arrecadacoes/modalComponents/AdicionarArrecadacaoComponents';
import { ModalArrecadacaoUnidadesEditarContext } from './ModalArrecadacaoUnidadesEditarContext';

const menuItems = mI.filter((mi) => ['receita', 'leitura', 'fundo', 'desconto'].includes(mi.tipo));

const AdicionarArrecadacaoModal = ({ tipo }) => {
	const { adicionar, conta, requesting } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);

	const [currentStep, setCurrentStep] = useState(0);
	const [valor, setValor] = useState();
	const [desc, setDesc] = useState();
	const [contaReceita, setContaReceita] = useState();
	const [tipoLancamento, setTipoLancamento] = useState('VLF');
	const [tipoFundo, setTipoFundo] = useState();

	useEffect(() => setDesc(contaReceita?.nome), [contaReceita]);

	const stepComponents = () => {
		switch (tipo) {
			case 'receita':
				return {
					0: {
						nextStep: 1,
						component: <BoxAddConta {...{ contaReceita, setContaReceita, tipo }} />,
						valid: !!contaReceita,
					},
					1: {
						prevStep: 0,
						component: <BoxValorDesc {...{ valor, setValor, desc, setDesc }} />,
						end: true,
						valid: desc && valor,
					},
				};
			case 'leitura':
				return {
					0: {
						end: true,
						component: <BoxAddLeitura {...{ contaReceita, setContaReceita }} />,
						valid: !!contaReceita && !requesting,
					},
				};
			case 'fundo':
				return {
					0: {
						nextStep: 1,
						component: <BoxAddConta {...{ contaReceita, setContaReceita, tipo }} />,
						valid: !!contaReceita,
					},
					1: {
						prevStep: 0,
						nextStep: 2,
						component: (
							<BoxTipoLancamento {...{ tipoLancamento, setTipoLancamento, disabledPercentual: true }} />
						),
						valid: !!tipoLancamento,
					},
					2: {
						prevStep: 1,
						nextStep: 3,
						component: <BoxTipoFundo {...{ tipoFundo, setTipoFundo }} />,
						valid: !!tipoFundo,
					},
					3: {
						prevStep: 2,
						component: <BoxValorDesc {...{ desc, setDesc, valor, setValor }} />,
						end: true,
						valid: desc && valor,
					},
				};
			case 'desconto':
				return {
					0: {
						nextStep: 1,
						component: <BoxAddConta {...{ contaReceita, setContaReceita, tipo }} />,
						valid: !!contaReceita,
					},
					1: {
						prevStep: 0,
						component: <BoxValorDesc {...{ desc, setDesc, valor, setValor }} />,
						end: true,
						valid: desc && valor,
					},
				};
			default:
				return {};
		}
	};

	const getSubmitData = () => {
		let data = {
			tipo_de_arrecadacao: tipo.toUpperCase(),
			conta_a_receber: conta.id,
			valor,
			conta_receita: contaReceita.id,
			complemento: desc,
		};
		return {
			[true]: () => data,
			['FUNDO' === data.tipo_de_arrecadacao && tipoLancamento === 'VLF']: () => ({
				...data,
				tipo_lancamento: tipoLancamento,
				tipo_conta_para_fundo: tipoFundo,
			}),
		}[true]();
	};

	const handleSubmit = () => {
		adicionar(getSubmitData(), () => setModalValue((v) => ({ ...v, open: false })));
	};

	const acoes = (
		<>
			<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
			<BotaoVoltar
				disabled={currentStep === 0}
				onClick={() => setCurrentStep(stepComponents()[currentStep].prevStep)}
			/>
			{stepComponents()[currentStep].end ? (
				<ButtonConfirm onClick={handleSubmit} disabled={!stepComponents()[currentStep].valid} />
			) : (
				<BotaoProximo
					disabled={!stepComponents()[currentStep].valid}
					onClick={() => setCurrentStep(stepComponents()[currentStep].nextStep)}
				/>
			)}
		</>
	);

	return (
		<CustomDialogBodySizeEditable
			title={`Adicionar ${tipo}`}
			customActions={acoes}
			form={{
				handleSubmit: () => {},
				component: (
					<Box
						css={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						{stepComponents()[currentStep].component}
					</Box>
				),
			}}
		/>
	);
};

const BotaoAdicionarArrecadacaoUnidade = () => {
	const { loading } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClickItem = (tipo) => {
		handleClose();
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <AdicionarArrecadacaoModal tipo={tipo} />,
		}));
	};

	return (
		<div>
			<MenuAdicionarArrecadacao
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				handleClose={handleClose}
				handleClickItem={handleClickItem}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				items={menuItems}
			/>
			<TooltipIconButton disabled={loading} size='small' title='Adicionar' onClick={handleClick}>
				<AddIcon />
			</TooltipIconButton>
		</div>
	);
};

export default BotaoAdicionarArrecadacaoUnidade;
