import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { getDescendants } from '../modais';

export const ContasContext = createContext();

export const ContasContextProvider = ({ children, id, filtroTipoContaReceita = null }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();

	const fixData = (data) => {
		const newData = {};
		for (let d of data) {
			const { criado, descricao_boleto, rght, ...rest } = d;
			newData[d.id] = rest;
		}
		return newData;
	};

	const contaRequest = (action, data, endpoint, idConta, nomeFilter = '') => {
		const tipoConta = endpoint.replace('contas_', '');
		const req = () => {
			let r = {};
			if (idConta) r.id = idConta;
			r.data = data;
			return r;
		};
		switch (action) {
			case 'delete':
				return dataProvider
					.delete(endpoint, { id: idConta })
					.then(() => {
						dispatch({ type: 'deleteOne', tipoConta, id: idConta, data });
						notify('Conta removida com sucesso');
					})
					.catch((e) => {
						console.log(e);
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						notify(
							Object.values(e?.response?.data || {})[0] || [
								'Erro inesperado, tente recarregar a pÃ¡gina',
							],
							'warning'
						);
					});
			case 'reorder':
				return dataProvider.reorder(endpoint, data).then(() => {
					getContas(tipoConta);
					notify('Contas alteradas com sucesso');
				});
			case 'refresh':
				return getContas(tipoConta, nomeFilter);
			default:
				return dataProvider[action](endpoint, req()).then((response) => {
					const data = response?.data;
					if (data) {
						if (Array.isArray(data) && data.length > 1) {
							dispatch({ type: 'addMany', tipoConta, data });
							notify('Contas cadastradas com sucesso');
						} else {
							dispatch({ type: 'addOne', tipoConta, data });
							notify('Conta cadastrada com sucesso');
						}
					}
				});
		}
	};

	const reducer = (state, action) => {
		switch (action.type) {
			case 'addOne': {
				const conta = Array.isArray(action.data) ? action.data[0] : action.data;
				return {
					...state,
					[action.tipoConta]: {
						...state[action.tipoConta],
						[conta.id]: conta,
					},
				};
			}
			case 'deleteOne': {
				let desc = getDescendants({ id: action.id, ...action?.data });
				let newContas = state[action.tipoConta];
				for (let id of desc) {
					delete newContas[id];
				}
				return { ...state, [action.tipoConta]: newContas };
			}
			case 'addMany': {
				const contas = state[action.tipoConta];
				action.data.forEach((d) => (contas[d.id] = d));
				return {
					...state,
					[action.tipoConta]: {
						...contas,
					},
				};
			}
			case 'updateAll':
				return { ...state, [action.tipoConta]: action.all };
			default:
				return state;
		}
	};

	const getContas = (tipoConta, nome = '', filtros = {}) => {
		const nameFilter = nome ? { in_nome_analitica: nome } : {};
		const { filtroTipoConta } = filtros;

		dataProvider
			.getList('contas_' + tipoConta, {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: 'lft,tree_id', order: 'ASC' },
				filter: { id_plano_condominio: id, ...nameFilter },
			})
			.then((response) => {
				const data = filtroTipoConta
					? response?.data?.filter?.((conta) => filtroTipoConta.includes(conta.tipo))
					: response?.data;
				if (data)
					dispatch({
						type: 'updateAll',
						tipoConta: tipoConta,
						all: fixData(data),
					});
			});
	};

	const [contas, dispatch] = useReducer(reducer, {
		dataProvider,
		contaRequest,
	});

	const init = () => {
		getContas('despesa');
		getContas('receita', '', { filtroTipoConta: filtroTipoContaReceita });
		getContas('arrecadacao');
	};

	useEffect(init, [id]);

	const contasValue = useMemo(() => ({ contas, dispatch, id }), [contas, dispatch, id]);

	return (
		<ContasContext.Provider value={contasValue}>
			{(contas.despesa || contas.receita) && children}
		</ContasContext.Provider>
	);
};
