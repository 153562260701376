import { getDadosFormattingContaCorrente } from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';
import DefaultContaNaoCorrenteProps, { defaultComponentesProps } from '../default/ContaNaoCorrenteProps';

const ContaNaoCorrenteProps = {
	...DefaultContaNaoCorrenteProps,
	instituicaoCodigo: '041',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(13, 2),
		},
	},
	getOptions: getFormatarOptions('041', { agencia: 'agencias_banrisul' }),
};

export default ContaNaoCorrenteProps;
