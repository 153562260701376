import React from 'react';

import TextField from '@material-ui/core/TextField';

function InputPlacaVeiculo({ value, setValue, ...props }) {
	function handleChangeInput(e) {
		if (e.target.value.length < 8) setValue(e.target.value);
	}
	return (
		<TextField
			value={value}
			onChange={handleChangeInput}
			label='Digite a placa do veículo:'
			size='small'
			margin='normal'
			variant='outlined'
			name='placa-veiculo'
			id='placa-veiculo-id'
			fullWidth
			type='text'
			{...props}
		/>
	);
}

export default InputPlacaVeiculo;
