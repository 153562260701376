import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

const ModalDesfazerBaixas = ({ selectedIds, source }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [loading, setLoading] = useState(false);

	const handleSim = () => {
		if (loading) return;
		setLoading(true);
		dP.updateMany(
			source || 'contas_receber',
			selectedIds.map((id) => ({ id, data: {} }))
		).then((response) => {
			const data = response.data || [];
			const requestsFalhados = [];
			const baixasDesfeitas = data.filter(
				(response) => response.status === 'fulfilled' || !requestsFalhados.push(response)
			);
			const firstDeloggedResponse = requestsFalhados.find((r) => [401, 403].includes(r.reason?.status));
			if (firstDeloggedResponse) return Promise.reject(firstDeloggedResponse.reason);
			const successMsg =
				(data.length > 1
					? `Desfeito ${baixasDesfeitas.length}${
							data.length > baixasDesfeitas.length ? ` de ${data.length}` : ''
					  } baixas`
					: 'Baixa desfeita') + ' com sucesso';
			baixasDesfeitas.length
				? notify(successMsg)
				: notify(
						Object.values(requestsFalhados[0].reason?.response?.data || {})[0] || [
							'Erro inesperado, tente recarregar a página',
						],
						'warning'
				  );
			refresh();
			setLoading(false);
		});
	};

	const handleNao = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			title={
				selectedIds.length > 1
					? 'Tem certeza que deseja desfazer essas baixas?'
					: 'Tem certeza que deseja desfazer essa baixa?'
			}
			text={
				selectedIds.length > 1
					? 'Os lançamentos de receita provenientes destas baixas serão excluídos'
					: 'O lançamento de receita proveniente desta baixa será excluído'
			}
			customActions={
				<>
					<ButtonCancel onClick={handleNao} />

					<ButtonConfirm onClick={handleSim} disabled={loading}>
						Confirmar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

export default ModalDesfazerBaixas;
