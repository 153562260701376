import React, { useEffect, useReducer, useState } from 'react';

import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelectRazaoLancamento, periodoOptions } from '../dateUtils';
import MultiSelect from 'components/common/Selects';
import RelatorioToolbar from '../RelatorioToolbar';
import { useRequestOptionsContas, useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const traducao_grupo_campo_conta = {
	Receita: 'contas_rec',
	Despesa: 'contas_desp',
};

const functionReducer = (state, action) => {
	switch (action.state) {
		case 'desmarcarGrupo':
			// eslint-disable-next-line no-case-declarations
			const campo_conta_para_desmarcar = traducao_grupo_campo_conta[action.value.group];
			state.contas = action.value.contas.filter((v) => v.tipoConta !== action.value.group);
			state[campo_conta_para_desmarcar] = [];
			break;
		case 'marcarGrupo':
			// eslint-disable-next-line no-case-declarations
			const campo_conta_para_marcar = traducao_grupo_campo_conta[action.value.group];
			state.contas = [...state.contas, ...action.value._group];
			state[campo_conta_para_marcar] = action.value._group.map((c) => c.id);
			break;
		case 'id_condominio':
			state.id_condominio = action.value;
			break;
		case 'date_range':
			state.data_inicial = action.value.data_inicial;
			state.data_final = action.value.data_final;
			break;
		case 'marcarContasIndividualmente':
			state.contas = action.value;
			// eslint-disable-next-line no-case-declarations
			const { contas_rec, contas_desp } = action.value.reduce(
				(listas_contas, { tipoConta, id }) => {
					const campo_conta = traducao_grupo_campo_conta[tipoConta];
					const lista_conta = listas_contas[campo_conta];

					if (lista_conta) lista_conta.push(id);

					return listas_contas;
				},
				{ contas_rec: [], contas_desp: [] }
			);
			state.contas_rec = contas_rec;
			state.contas_desp = contas_desp;
			break;
		case 'marcarUnidade':
			state.unidade = action.value;
			break;
		case 'zerarContasSelecionadas':
			state.contas = action.value;
			state.contas_rec = action.value;
			state.contas_desp = action.value;
			break;
		case 'imprimir_assinatura':
			state.imprimir_assinatura = action.value;
			break;

		default:
			break;
	}
	return { ...state };
};

const OpcoesImpressaoDemonstrativoCheckboxGrupo = ({ handleChange, imprimirAssinatura }) => {
	return (
		<>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</FormGroup>
		</>
	);
};

const RazaoLancamentoDivisor = ({ idCondominio, tipoRelatorio }) => {
	const [unidades, setUnidades] = useState([]);
	const { options: optionsContas } = useRequestOptionsContas(idCondominio);
	const { options: optionsUnidades } = useRequestOptionsUnidades();
	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const {
		opcoesImpressao: { imprimir_assinatura },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);
	const [requestData, dispatch] = useReducer(functionReducer, {
		id_condominio: idCondominio,
		contas: [],
		contas_rec: [],
		contas_desp: [],
		data_inicial: '',
		data_final: '',
		unidade: unidades,
		imprimir_assinatura: imprimir_assinatura,
		nome_relatorio: tipoRelatorio.action,
	});

	useEffect(() => {
		dispatch({
			state: 'id_condominio',
			value: idCondominio,
		});
		if (requestData.contas.length) {
			dispatch({
				state: 'zerarContasSelecionadas',
				value: [],
			});
		}
	}, [idCondominio]);

	useEffect(() => {
		dispatch({
			state: 'imprimir_assinatura',
			value: imprimir_assinatura,
		});
	}, [imprimir_assinatura]);

	const handleSetContas = (contas, state = '', group = '', _group = []) => {
		if (state) {
			dispatch({
				state: state,
				value: {
					contas: contas,
					group: group,
					_group: _group,
				},
			});
		} else {
			dispatch({
				state: 'marcarContasIndividualmente',
				value: contas,
			});
		}
	};

	useEffect(() => {
		dispatch({
			state: 'marcarUnidade',
			value: unidades,
		});
	}, [unidades]);

	return (
		<BoxDivisor titulo='Razão de Lançamentos'>
			<Box display='flex' mb='1em' gridGap='1rem'>
				<Box flex={1}>
					<PeriodoSelectRazaoLancamento
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={requestData.data_inicial}
						dataFinal={requestData.data_final}
						dispatch={dispatch}
						label='Selecionar Período'
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<Box width='50%' mr='1em'>
					<MultiSelect
						variant={'formatsContas'}
						options={optionsContas}
						setValue={handleSetContas}
						value={requestData?.contas}
					/>
				</Box>
				<Box width='50%' mr='1em'>
					<MultiSelect
						variant='formatsUnidades'
						options={optionsUnidades}
						setValue={setUnidades}
						value={requestData.unidade}
					/>
				</Box>
			</Box>
			<Box>
				<OpcoesImpressaoDemonstrativoCheckboxGrupo
					handleChange={handleChangeOpcoesImpressao}
					imprimirAssinatura={imprimir_assinatura}
				/>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};

export default RazaoLancamentoDivisor;
