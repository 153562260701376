import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

export const ConfirmDialog = ({
	showConfirmDialog,
	setShowConfirmDialog,
	onConfirm,
	classes,
	actionText,
	msgText,
	disabled = false,
}) => (
	<Dialog
		open={showConfirmDialog}
		onClose={() => setShowConfirmDialog(false)}
		fullWidth
		PaperProps={{
			style: {
				width: '20%',
				position: 'relative',
			},
		}}
	>
		<DialogTitle>Você tem certeza?</DialogTitle>
		<DialogContent>{msgText}</DialogContent>
		<DialogActions>
			<Button
				onClick={() => setShowConfirmDialog(false)}
				variant='contained'
				size='small'
				className={classes.editarButton}
			>
				Voltar
			</Button>
			<Button
				onClick={onConfirm}
				className={classes.deleteButton}
				variant='contained'
				size='small'
				disabled={disabled}
			>
				{actionText}
			</Button>
		</DialogActions>
	</Dialog>
);
