import React from 'react';

import { TextField } from 'react-admin';

export const NomeFieldColumn = TextField as React.FC<
	React.ComponentProps<typeof TextField> & {
		name: string;
		reference: string;
		link: boolean;
		filtro: React.ReactElement;
		minWidth: string;
	}
>;

export default NomeFieldColumn;
