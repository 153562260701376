import React, { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { CustomDialogBody } from '../../common/ModalContext';
import { GruposContext, GrupoUnidadeContext } from './contextsGrupoUnidade';
import MultiSelect from '../../common/Selects/index';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';

export const ModalUnidadesDisponiveis = () => {
	const { dP } = useContext(GruposContext);
	const { grupo, setGrupo } = useContext(GrupoUnidadeContext);
	const filterRequisicao = { situacao: 'A', id_condominio: grupo.id_condominio, sem_grupo: true };
	const { options } = useRequestOptionsUnidades(filterRequisicao);
	const [unidades, setUnidades] = useState([]);
	const valid = true;

	const vincular = () => {
		dP.update('grupo_unidade_condominio', {
			data: {
				unidades: unidades.map((v) => v.id),
			},
			id: grupo.id,
		}).then((response) => {
			const data = response?.data;
			if (data) {
				setGrupo((grupo) => ({
					...grupo,
					unidades: data.unidades,
					soma_coeficientes: data.soma_coeficientes,
				}));
			}
		});
	};

	const handleSubmit = () => {
		if (valid) {
			vincular();
		}
	};

	return (
		<CustomDialogBody
			title='Vincular Unidades'
			text='Selecione uma ou mais unidades disponíveis'
			form={{
				handleSubmit,
				valid,
				component: (
					<MultiSelect variant='formatsUnidades' options={options} setValue={setUnidades} value={unidades} />
				),
			}}
		/>
	);
};

export const ModalCadastrarGrupo = () => {
	const { idCondominio, setData, setModalValue, dP } = useContext(GruposContext);
	const [nome, setNome] = useState('');
	const [valid, setValid] = useState(true);
	const [touched, setTouched] = useState(false);

	const create = () => {
		dP.create('grupo_unidade_condominio', {
			data: {
				id_condominio: idCondominio,
				nome: nome,
			},
		}).then((response) => {
			const createData = response?.data;
			if (createData) setData((data) => [...data, createData]);
		});
	};
	const handleSubmit = () => {
		if (valid) create();
	};
	useEffect(() => setValid(nome.length >= 3), [nome]);
	return (
		<CustomDialogBody
			title='Cadastrar um Grupo'
			text='Digite o nome do grupo a ser cadastrado'
			form={{
				valid,
				handleSubmit,
				component: (
					<TextField
						label='Nome'
						fullWidth
						margin='dense'
						value={nome}
						error={!valid && touched}
						helperText={valid && touched ? '' : 'Nome deve conter 3 ou mais caracteres'}
						onChange={(e) => {
							setNome(e.target.value);
							setTouched(true);
						}}
						onKeyPress={(e) => {
							if (e.key === 'Enter' && valid) {
								e.stopPropagation();
								handleSubmit();
								setModalValue &&
									setModalValue((v) => ({
										...v,
										open: false,
									}));
							}
						}}
					/>
				),
			}}
		/>
	);
};
