import React from 'react';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonGerarBoletos } from '../../botoes';

export const ModalErrosReportBoletos = ({ title, text, form, idsRegistrosSelecionados }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const [loadingRequest, setLoadingRequest] = React.useState(false);
	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			title={title}
			text={text}
			form={form}
			customActions={
				<>
					<ButtonCancel disabled={loadingRequest} onClick={handleClose} />
					<ButtonGerarBoletos
						label='Configurar Novamente'
						{...{ idsRegistrosSelecionados }}
						configurar
						disabled={loadingRequest}
					/>
					<ButtonGerarBoletos
						label='Gerar Novamente'
						{...{
							idsRegistrosSelecionados,
							setLoadingRequest,
						}}
						disabled={loadingRequest}
					/>
				</>
			}
		/>
	);
};

export default ModalErrosReportBoletos;
