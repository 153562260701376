import React, { useState, useEffect } from 'react';
import { TextInput, useDataProvider, useInput, useGetList, useNotify } from 'react-admin';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';

import VeryHighLimitReferenceInput from '../common/VeryHighLimitReferenceInput';
import { numeroParser } from '../../fieldControl/numero';
import { BoxDivisor } from './Formulario';
import { InputCep } from './InputCep';
import { SyndikosSelect } from './SyndikosSelect';

export const opcoesUf = [
	{ id: 'AC', name: 'Acre' },
	{ id: 'AL', name: 'Alagoas' },
	{ id: 'AP', name: 'Amapá' },
	{ id: 'AM', name: 'Amazonas' },
	{ id: 'BA', name: 'Bahia' },
	{ id: 'CE', name: 'Ceará' },
	{ id: 'DF', name: 'Distrito Federal' },
	{ id: 'ES', name: 'Espírito Santo' },
	{ id: 'GO', name: 'Goiás' },
	{ id: 'MA', name: 'Maranhão' },
	{ id: 'MT', name: 'Mato Grosso' },
	{ id: 'MS', name: 'Mato Grosso do Sul' },
	{ id: 'MG', name: 'Minas Gerais' },
	{ id: 'PA', name: 'Pará' },
	{ id: 'PB', name: 'Paraíba' },
	{ id: 'PR', name: 'Paraná' },
	{ id: 'PE', name: 'Pernambuco' },
	{ id: 'PI', name: 'Piauí' },
	{ id: 'RJ', name: 'Rio de Janeiro' },
	{ id: 'RN', name: 'Rio Grande do Norte' },
	{ id: 'RS', name: 'Rio Grande do Sul' },
	{ id: 'RO', name: 'Rondônia' },
	{ id: 'RR', name: 'Roraima' },
	{ id: 'SC', name: 'Santa Catarina' },
	{ id: 'SP', name: 'São Paulo' },
	{ id: 'SE', name: 'Sergipe' },
	{ id: 'TO', name: 'Tocantins' },
];

const GenericInput = ({ newValue, Component, ...rest }) => {
	const { input } = useInput(rest);

	const handleValueChange = () => {
		input.onChange(newValue?.value);
	};

	useEffect(handleValueChange, [newValue]);

	return <Component {...rest} {...input} />;
};

export const useGetEndereco = ({ ...props } = {}) => {
	const dataProvider = useDataProvider();
	const { values, initialValues, visited } = useFormState(props);
	const { data: tiposLog } = useGetList(
		'tipo_logradouro',
		{ perPage: 10000, page: 1 },
		{ field: 'descricao', order: 'ASC' },
		{}
	);
	const notify = useNotify();

	const [tipoLogradouro, setTipoLogradouro] = useState({
		value:
			initialValues?.tipo_logradouro_id ??
			(typeof initialValues?.tipo_logradouro === 'object' && initialValues?.tipo_logradouro !== null
				? initialValues.tipo_logradouro.id
				: initialValues?.tipo_logradouro) ??
			null,
	});
	const [endereco, setEndereco] = useState({ value: initialValues?.endereco ?? '' });
	const [bairro, setBairro] = useState({ value: initialValues?.bairro ?? '' });
	const [cidade, setCidade] = useState({ value: initialValues?.cidade ?? '' });
	const [uf, setUf] = useState({ value: initialValues?.uf ?? null });
	const [tiposOrdenados, setTiposOrdenados] = useState([]);

	useEffect(() => {
		if (uf.value !== null && !opcoesUf.some((opcaoUf) => opcaoUf.id === uf.value)) {
			setUf({ value: null });
		}
	}, [uf, opcoesUf]);

	const ordenaTipos = () => {
		if (tiposLog) {
			const idsOrdenados = Object.keys(tiposLog).sort((a, b) => tiposLog[a].id - tiposLog[b].id);
			setTiposOrdenados(idsOrdenados.map((id) => tiposLog[id]));
		}
	};

	useEffect(ordenaTipos, [tiposLog]);

	const handleCepChange = () => {
		if ((values['cep'] || values['beneficiario_cep'])?.match(/[0-9]{8}/) && tiposOrdenados && (visited || {})) {
			dataProvider
				.getEndereco('', { cep: values['cep'] || values['beneficiario_cep'] })
				.then(({ data }) => {
					const resBairro = data.bairro || data.neighborhood;
					const resCidade = data.localidade || data.city;
					const resEndereco = data.logradouro || data.street;
					const resUf = data.uf || data.state;

					if (values['cep'] !== initialValues['cep']) {
						if (!resBairro || !resEndereco) {
							notify('Este CEP não possui todos os dados de endereço');
						} else {
							notify('Endereço encontrado');
						}
					}
					if (resEndereco) {
						for (const tipo of tiposOrdenados) {
							if (resEndereco.toLowerCase().includes(tipo.descricao.toLowerCase())) {
								setTipoLogradouro({ value: tipo.id });
								if (endereco['value']) continue;
								setEndereco({
									value: resEndereco.replace(tipo.descricao + ' ', ''),
								});
								break;
							}
						}
					}

					if (resBairro && !bairro['value']) setBairro({ value: resBairro });
					if (resCidade) setCidade({ value: resCidade });
					if (resUf) setUf({ value: resUf });
				})
				.catch(() => notify('CEP não encontrado', 'warning'));
		}
	};

	useEffect(handleCepChange, [values['cep'], values['beneficiario_cep'], tiposOrdenados]);

	const handleEnderecoChange = () => {
		if (values['cep'] !== initialValues['cep']) {
			setEndereco({ value: '' });
			setBairro({ value: '' });
		}
	};

	useEffect(handleEnderecoChange, [values['cep']]);

	return { tipoLogradouro, endereco, bairro, uf, cidade };
};

export const EnderecoBox = ({ labelTitle = 'Endereçamento', ...props }) => {
	const { tipoLogradouro, endereco, bairro, uf, cidade } = useGetEndereco(props);
	return (
		<BoxDivisor titulo={labelTitle} flex={1} className={props.className}>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<InputCep
						sourceCep='cep'
						disabled={props.record && props.record?.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<VeryHighLimitReferenceInput
						source='tipo_logradouro'
						label='Tipo de Logradouro'
						reference='tipo_logradouro'
						disabled={props.record && props.record?.situacao === 'I'}
						allowEmpty
						variant='outlined'
						fullWidth
					>
						<GenericInput Component={SyndikosSelect} newValue={tipoLogradouro} optionText='descricao' />
					</VeryHighLimitReferenceInput>
				</Box>
				<Box flex={1.5} mr='1em'>
					<GenericInput
						Component={TextInput}
						newValue={endereco}
						source='endereco'
						label='Endereço'
						disabled={props.record && props.record?.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex'>
				<Box flex={3} mr='1em'>
					<GenericInput
						Component={TextInput}
						newValue={bairro}
						source='bairro'
						label='Bairro'
						disabled={props.record && props.record?.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<TextInput
						source='numero'
						label='Número'
						disabled={props.record && props.record?.situacao === 'I'}
						parse={numeroParser}
						variant='outlined'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<GenericInput
						Component={TextInput}
						newValue={cidade}
						source='cidade'
						label='Cidade'
						disabled={props.record && props.record?.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<GenericInput
						Component={SyndikosSelect}
						newValue={uf}
						source='uf'
						label='UF'
						choices={opcoesUf}
						allowEmpty={true}
						disabled={props.record && props.record?.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<TextInput
						source='complemento_endereco'
						label='Complemento'
						disabled={props.record && props.record?.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
			</Box>
		</BoxDivisor>
	);
};
