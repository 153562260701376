import React from 'react';

import InstrucoesRemessaFields from '../../commons/InstrucoesRemessaFields';

const emptyProps = {};

const MultaFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaFields
		sourcePrincipal='multa'
		propsTipo={{
			source: 'multa_tipo',
			label: 'Multa',
			...propsTipo,
		}}
		propsValor={{
			source: 'multa_valor',
			...propsValor,
		}}
		propsDias={{
			source: 'multa_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoMulta = ({
	sources: { sourceValor, sourceDias } = emptyProps,
	values,
	pristine,
	optionsIsencoesTipo,
}) => ({
	values: {
		[sourceValor]: values[sourceValor],
		[sourceDias]: values[sourceDias],
	},
	sources: {
		sourceValor,
		sourceDias,
	},
	pristine,
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoMulta =
	({ sources: { sourceValor, sourceDias } = emptyProps, values, pristine, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);

		const valorValue = values[sourceValor],
			diasValue = values[sourceDias];
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
		} else {
			if (tipoValue.id === 'PRC') {
				!valorValue && change(sourceValor, 2);
			} else {
				!pristine && !valorValue && change(sourceValor, undefined);
			}
			if (sourceDias && !diasValue) change(sourceDias, 1);
		}
	};

export default MultaFields;
