import { createTheme } from '@material-ui/core/styles';

export const COLORS_DARK = {
	primary: {
		100: '#8b8da3',
		200: '#585c7d',
		300: '#2f3141',
	},
	secondary: {
		100: '#a1a6c2',
		200: '#6a729b',
		300: '#4a5175',
	},
	fundo: {
		100: '#242638',
	},
	fundoMenu: {
		100: '#2f3141',
		200: '#26282b',
		300: '#040505',
	},
	labelSelectGestao: {
		100: '#2f3141',
	},
	border: {
		50: 'rgba(255,255,255,0.23)',
		100: '#242638',
		200: '#1c1e30',
	},
	font: { 100: '#ebebeb', 200: '#c2c2c2', 300: '#545454', 400: '#fff', 500: '#FAFAFA' },
	blue: {
		100: '#4aafff',
		200: '#2196f3',
		300: '#127acc',
		400: '#0f69ae',
		500: '#6070c7',
	},
	red: {
		100: '#fa9291',
		200: '#d45c5b',
		300: '#ad4342',
	},
	green: {
		100: '#85de97',
		200: '#52b866',
		300: '#3a914b',
	},
	orange: {
		100: '#ffb757',
		200: '#ff9f33',
		300: '#ff8435',
	},
	divider: {
		100: '#d3d3d3',
	},
	inputComunicado: {
		100: '#585c7d',
	},
};

export const DARK_THEME = createTheme({
	...COLORS_DARK,
	palette: {
		syndikosRed: {
			contrastText: '#fff',
			main: '#CA3433',
			dark: '#a33231',
		},
	},
	props: {
		MuiTextField: {
			variant: 'outlined',
		},
		MuiButtonBase: {
			disableRipple: false,
		},
	},
	overrides: {
		//React Admin
		RaLayout: {
			content: {
				zIndex: 1,
				paddingLeft: '18px !important',
				padding: 18,
				marginLeft: '6px',
				backgroundColor: COLORS_DARK.fundo[100],
				boxShadow:
					'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
			},
			contentWithSidebar: {
				display: 'flex',
				flexGrow: 1,
				backgroundColor: COLORS_DARK.fundo[100],
			},
		},
		RaBulkActionsToolbar: {
			toolbar: {
				backgroundColor: COLORS_DARK.primary[300],
			},
		},
		RaAppBar: {
			toolbar: {
				backgroundColor: COLORS_DARK.primary[200],
			},
		},
		RaSidebar: {
			drawerPaper: {
				backgroundColor: COLORS_DARK.primary[300],
			},
		},
		RaMenuItemLink: {
			root: {
				color: COLORS_DARK.font[100],
			},
			active: {
				color: COLORS_DARK.secondary[100],
				'& svg': {
					fill: `${COLORS_DARK.secondary[200]}!important`,
				},
			},
		},
		RaToolbar: { toolbar: { backgroundColor: COLORS_DARK.primary[300] } },
		// ! React Admin

		// Material UI
		MuiDropzoneArea: {
			root: {
				backgroundColor: COLORS_DARK.fundoMenu[100],
			},
			icon: {
				color: COLORS_DARK.font[100],
			},
		},
		MuiDropzonePreviewList: {
			image: {
				color: COLORS_DARK.font[100],
			},
		},
		MuiOutlinedInput: {
			root: {
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					borderColor: COLORS_DARK.primary[200],
				},
				'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
					borderColor: COLORS_DARK.font[300],
				},
			},
			notchedOutline: {
				borderColor: 'rgb(155,155,155)',
			},
		},
		MuiPickersDay: { day: { color: COLORS_DARK.font[100] } },
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: COLORS_DARK.font[100],
			},
			iconButton: {
				backgroundColor: COLORS_DARK.primary[300],
			},
		},
		MuiChip: {
			outlinedPrimary: {
				color: COLORS_DARK.font[200],
				border: `1px solid ${COLORS_DARK.font[200]}`,
			},
		},
		MuiRadio: {
			root: {
				color: COLORS_DARK.secondary[100],
			},
			colorPrimary: {
				'&.Mui-disabled': {
					color: COLORS_DARK.font[300],
				},
			},
			colorSecondary: {
				'&.Mui-checked': {
					color: COLORS_DARK.primary[200],
				},
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				'&.Mui-checked': {
					color: COLORS_DARK.primary[200],
				},
				'& span': {
					color: COLORS_DARK.font[100],
				},
			},
			colorPrimary: {
				'& span': {
					color: COLORS_DARK.font[100],
				},
			},
		},
		MuiFormControlLabel: {
			label: {
				'&.Mui-disabled': {
					color: COLORS_DARK.font[300],
				},
			},
		},
		MuiFormHelperText: {
			root: {
				color: COLORS_DARK.font[200],
			},
		},
		MuiListItemIcon: {
			root: {
				color: COLORS_DARK.font[200],
			},
		},
		MuiTypography: {
			root: {
				color: COLORS_DARK.font[100],
			},
			colorPrimary: {
				color: COLORS_DARK.primary[100],
			},
			colorTextPrimary: {
				color: COLORS_DARK.font[100],
			},
			colorTextSecondary: {
				color: COLORS_DARK.primary[100],
			},
		},
		MuiTablePagination: {
			root: {
				color: COLORS_DARK.font[100],
			},
			selectIcon: {
				color: COLORS_DARK.font[100],
			},
		},
		MuiTableCell: {
			root: {
				backgroundColor: `${COLORS_DARK.primary[300]}!important`,
				borderBottom: `1px solid ${COLORS_DARK.border[100]}`,
			},
			head: {
				color: COLORS_DARK.font[100],
			},
			body: {
				color: COLORS_DARK.font[100],
			},
		},
		MuiTableSortLabel: {
			root: {
				'&:hover': {
					color: COLORS_DARK.font[200],
				},
				'&.MuiTableSortLabel-active': {
					color: COLORS_DARK.font[100],
				},
			},
			icon: { color: `${COLORS_DARK.font[200]}!important` },
		},
		MuiIconButton: {
			root: {
				color: COLORS_DARK.font[200],
			},
			colorSecondary: {
				'&:hover': { backgroundColor: 'rgba(255, 255, 255, .2)' },
			},
			sizeSmall: {
				padding: '7px 7px 12px 7px',
			},
		},
		MuiStepLabel: {
			label: {
				color: COLORS_DARK.font[200],
			},
			active: {
				color: `${COLORS_DARK.primary[100]}!important`,
			},
			completed: {
				color: `${COLORS_DARK.primary[200]}!important`,
			},
		},
		MuiStepIcon: {
			root: { color: COLORS_DARK.font[300] },
			completed: {
				color: `${COLORS_DARK.primary[200]}!important`,
			},
			active: {
				color: `${COLORS_DARK.primary[200]}!important`,
			},
		},
		MuiInputBase: {
			root: {
				color: COLORS_DARK.font[100],
				'&.Mui-disabled': { color: COLORS_DARK.font[300] },
			},
		},
		MuiButton: {
			textPrimary: {
				color: COLORS_DARK.font[100],
			},
			root: {
				color: COLORS_DARK.font[100],
				'&.Mui-disabled': {
					color: COLORS_DARK.font[300],
				},
			},
			containedPrimary: {
				backgroundColor: COLORS_DARK.primary[200],
			},
			containedSecondary: {
				backgroundColor: COLORS_DARK.blue[300],
				'&:hover': {
					backgroundColor: COLORS_DARK.blue[400],
				},
			},
			outlinedPrimary: {
				color: COLORS_DARK.blue[500],
			},
		},
		MuiFormLabel: {
			root: {
				color: COLORS_DARK.font[200],
				'&.Mui-focused': {
					color: COLORS_DARK.secondary[100],
				},
				'&.Mui-disabled': { color: COLORS_DARK.font[300] },
			},
		},
		MuiPaper: {
			root: {
				backgroundColor: COLORS_DARK.primary[300],
				color: COLORS_DARK.font[100],
			},
		},
		MUIRichTextEditor: {
			root: {
				flexWrap: 'wrap',
				backgroundColor: COLORS_DARK.primary[200],
				color: COLORS_DARK.font[100],
				'& blockquote span': {
					color: COLORS_DARK.font[100],
				},
				'& div[class^="CodeBlock"]': {
					'background-color': COLORS_DARK.primary[300],
				},
				'& pre span': {
					color: COLORS_DARK.font[100],
				},
			},
			editor: {
				'font-size': '19px',
				'line-height': '1.4',
			},
			toolbar: {
				'border-bottom': '1px solid black',
			},
			container: {
				margin: '0px',
				padding: '8px 10px 0px 10px',
			},
			editorContainer: {
				margin: '0px 0px 0px 0px',
				padding: '35px 0px 35px 0px',
			},
			placeHolder: {
				position: 'initial',
				color: COLORS_DARK.font[100],
			},
			labelInputEmail: {
				backgroundColor: 'transparent',
				backgroundImage: `linear-gradient(0deg, ${COLORS_DARK.primary[200]} 50%, ${COLORS_DARK.fundoMenu[100]} 50%)`,
			},
		},
		MuiDivider: {
			light: {
				width: '98.5%',
				backgroundColor: COLORS_DARK.divider[100],
			},
		},
		MuiAutocomplete: {
			noOptions: {
				color: COLORS_DARK.font[100],
			},
		},
		// ! Material UI
		PrivateTabIndicator: {
			colorSecondary: { backgroundColor: COLORS_DARK.primary[200] },
		},
	},
});
