import React from 'react';

import { format } from 'date-fns';

interface Checkboxes {
	calcular_multa: boolean;
	calcular_juros: boolean;
	calcular_correcao_monetaria: boolean;
}

interface SegundaViaContextProps {
	checkboxes: Checkboxes;
	setCheckboxes: React.Dispatch<React.SetStateAction<Checkboxes>>;
	corrigirAteData: string;
	setCorrigirAteData: React.Dispatch<React.SetStateAction<string>>;
	novaDataVencimento: string;
	setNovaDataVencimento: React.Dispatch<React.SetStateAction<string>>;
	handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SegundaViaContextProviderProps {
	children: React.ReactNode;
}

const TODAY = new Date().setHours(10, 0, 0);

const SegundaViaContext = React.createContext<SegundaViaContextProps | undefined>(undefined);

export const SegundaViaContextProvider: React.FC<SegundaViaContextProviderProps> = ({ children }) => {
	const [checkboxes, setCheckboxes] = React.useState<Checkboxes>({
		calcular_multa: false,
		calcular_juros: false,
		calcular_correcao_monetaria: false,
	});
	const [corrigirAteData, setCorrigirAteData] = React.useState<string>(format(TODAY, 'dd/MM/yyyy'));
	const [novaDataVencimento, setNovaDataVencimento] = React.useState<string>(format(TODAY, 'dd/MM/yyyy'));
	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[name]: checked,
		}));
	};
	const segundaViaProviderValue = React.useMemo(
		() => ({
			checkboxes,
			setCheckboxes,
			corrigirAteData,
			setCorrigirAteData,
			novaDataVencimento,
			setNovaDataVencimento,
			handleCheckboxChange,
		}),
		[
			checkboxes,
			setCheckboxes,
			corrigirAteData,
			setCorrigirAteData,
			novaDataVencimento,
			setNovaDataVencimento,
			handleCheckboxChange,
		]
	);
	return <SegundaViaContext.Provider value={segundaViaProviderValue}>{children}</SegundaViaContext.Provider>;
};

export const useSegundaViaContext = (): SegundaViaContextProps => {
	const context = React.useContext(SegundaViaContext);
	if (!context) {
		throw new Error('useSegundaViaContext must be used within a SegundaViaContextProvider');
	}
	return context;
};
