import React, { memo, useContext } from 'react';

import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

import { CondominiosContext } from '../../../context/CondominioContextProvider';

const CondominioSelect = memo(() => {
	const { condominios: options, condominioSelecionado, setCondominioPorId } = useContext(CondominiosContext);
	const handleCondominioChange = (_: any, option: any) => {
		if (option) setCondominioPorId(option.id);
	};

	const disabled = !options?.length;

	return (
		<Autocomplete
			options={options}
			renderInput={(params) => <TextField {...params} margin='dense' label={'Selecionar Condomínio'} fullWidth />}
			disabled={disabled}
			getOptionLabel={(option) => option?.nome}
			getOptionSelected={(option, v) => v?.id === option?.id}
			renderOption={(option) => option?.nome}
			onChange={handleCondominioChange}
			defaultValue={condominioSelecionado || undefined}
			fullWidth
			disableClearable
		/>
	);
});
export default CondominioSelect;
