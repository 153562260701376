import React from 'react';

import { Edit } from 'react-admin';

import { TarefasManutencoesFormulario } from './form';

export const TarefasManutencoesEdit = (props) => (
	<Edit title={'Editar Tarefas e Manutenções'} undoable={false} {...props}>
		<TarefasManutencoesFormulario edit {...props} />
	</Edit>
);
