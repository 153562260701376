import React, { useContext, useState } from 'react';

import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import { Form } from 'react-final-form';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoProximo } from '../../../../../common/buttons/BotaoProximo';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';

const ModalLeiturasParaCalculoNovaLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const [leiturasParaCalculoIds, setLeiturasParaCalculoIds] = useState(
		dadosNovaLeitura.dados_config.leituras_para_calculo_ids || []
	);
	const handleBack = () => {
		setDadosNovaLeitura((v) => {
			const { leituras_para_calculo_ids, ...dados_config } = v.dados_config;
			v['dados_config'] = dados_config;
			return v;
		});
		setModalStep('4');
	};
	const handleNext = () => {
		setDadosNovaLeitura((v) => {
			v.dados_config['leituras_para_calculo_ids'] = leiturasParaCalculoIds;
			return v;
		});
		setModalStep('6P');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Sobre qual(ais) leitura(s) é calculado o valor?'
			form={{
				component: (
					<Form
						onSubmit={(e) => {
							e.preventDefault();
						}}
						initialValues={{
							leituras_para_calculo_ids: leiturasParaCalculoIds,
						}}
						render={() => (
							<ReferenceArrayInput
								reference='leitura'
								source='leituras_para_calculo_ids'
								label='Leituras para cálculo'
								filter={{
									id_condominio_id: dadosNovaLeitura.id_condominio,
									metodos_para_ignorar: ['Arrecadação', 'Percentual sobre leitura'],
									exclui_situacao: 'E',
								}}
								onChange={(e) => {
									if (e.target) setLeiturasParaCalculoIds(e.target.value);
								}}
								fullWidth
							>
								<SelectArrayInput
									name='leituras_para_calculo_ids'
									optionText='nome'
									translateChoice={false}
								/>
							</ReferenceArrayInput>
						)}
					/>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo onClick={handleNext} disabled={!leiturasParaCalculoIds.length} />
				</>
			}
		/>
	);
};

export default ModalLeiturasParaCalculoNovaLeitura;
