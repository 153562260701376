import { createFormatsOptionsAutoCompleteComponent } from '../MultiSelectDropdown/formats';

export default {
	formatsContas: {
		formats: createFormatsOptionsAutoCompleteComponent,
	},
	formatsUnidades: {
		formats: createFormatsOptionsAutoCompleteComponent,
	},
};
