import React, { createContext, useContext } from 'react';

import Box from '@material-ui/core/Box';

import { CustomDialog, ModalContextProvider } from '../../../../../common/ModalContext';
import SelectContaBancaria from '../../SelectContaBancaria';
import BoxSelectContaPlano from '../../BoxSelectContaPlano';
import SelectTipoLancamento from './SelectTipoLancamento';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

export const ModalDetailContext = createContext();

const ConfiguracoesLancamentosReceitas = () => {
	const {
		contaBancariaDestino,
		contaReceita,
		contasBancarias,
		contasBancariasData,
		grupo,
		gruposLista,
		setContaBancariaDestino,
		setContaReceita,
		setGrupo,
		setUnidade,
		setTipoLancamento,
		unidade,
		unidadesLista,
		tipoLancamento,
	} = useContext(FluxoImportacaoExtratoBancarioContext);

	return (
		<ModalContextProvider Context={ModalDetailContext}>
			<Box display='flex' flexDirection='column' gridGap='0.5rem'>
				<SelectTipoLancamento
					value={tipoLancamento}
					setValue={setTipoLancamento}
					operacao='receita'
					unidade={unidade}
					setUnidade={setUnidade}
					grupo={grupo}
					setGrupo={setGrupo}
					gruposData={gruposLista}
					unidadesData={unidadesLista}
				/>
				<BoxSelectContaPlano
					selectedConta={contaReceita}
					setSelectedConta={setContaReceita}
					operacao={{ tipo: 'receita' }}
					ModalDetailContext={ModalDetailContext}
				/>

				<SelectContaBancaria
					label='Receber em'
					setValue={setContaBancariaDestino}
					value={contaBancariaDestino}
					options={contasBancarias}
					contasData={contasBancariasData}
				/>
			</Box>

			<CustomDialog Context={ModalDetailContext} />
		</ModalContextProvider>
	);
};

export default ConfiguracoesLancamentosReceitas;
