import React, { useCallback, useContext, useMemo, useReducer } from 'react';

import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaCaixaContext from '../contexts/EditaContaCaixaContext';
import { ContaCaixaPropsByInstituicao } from '../bancos';
import { getFunctionsFormatarProps } from '../utils/functions';

const { getInit, getReducer } = getFunctionsFormatarProps(ContaCaixaPropsByInstituicao);

const initial = { instituicaoCodigo: 'default', optionsPorInstituicao: {} };

const EditaContaCaixaContextProvider = ({ children }) => {
	const { initialDados } = useContext(EditaContaBancariaContext);
	const reducer = useCallback(getReducer(initialDados), [initialDados]);
	const init = useCallback(getInit(initialDados), [initialDados]);
	const [{ getDisabledTabs, tabContents, formatarValues, validate, initialValues, options, props }] = useReducer(
		reducer,
		initial,
		init
	);

	const value = useMemo(
		() => ({
			getDisabledTabs,
			tabContents,
			formatarValues,
			validate,
			initialValues,
			options,
			props,
		}),
		[getDisabledTabs, tabContents, formatarValues, validate, initialValues, options, props]
	);

	return <EditaContaCaixaContext.Provider value={value}>{children}</EditaContaCaixaContext.Provider>;
};

export default EditaContaCaixaContextProvider;
