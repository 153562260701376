import React from 'react';
import { ArrayInput, useDataProvider, useNotify, TextInput, required, useGetList, FormDataConsumer } from 'react-admin';

import { nomeParser, nomeValidacao } from '../../../fieldControl/nome';
import { validacaoEmailObrigatorio } from '../../../fieldControl/email';
import { telefoneValidacao, telefoneFormatacao, telefoneParser } from '../../../fieldControl/telefone';
import { TipoModalInput } from '../../common/TipoModalInput';
import { InputDocumento } from '../../common/InputDocumento';
import { FormularioBox, BoxDivisor } from '../../common/Formulario';
import { EnderecoBox } from '../../common/Endereco';
import { ButtonRemove } from '../../common/buttons/ButtonRemove';
import { ButtonClose } from '../../common/buttons/ButtonClose';
import { ModalConfirmacao } from '../../common/buttons/BotaoRemover';
import { CustomDialogBody } from '../../common/ModalContext';
import { CustomFormIterator } from '../../common/CustomFormIterator';

import { Box, Button, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { Field, useForm } from 'react-final-form';
import SimpleFormIteratorV1 from 'components/common/SimpleFormIteratorV1';
import { Autocomplete } from '@material-ui/lab';
import { ThemeProvider } from '@material-ui/core/styles';

const ModalFeedbackBloqueioRemover = ({ setModalValue }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBody
			title='Este fornecedor não pode ser removido'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>
							<ul>
								<>
									<li>Este fornecedor está vinculado a ao menos uma conta a pagar</li>
								</>
							</ul>
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
						}}
						size='small'
					>
						cancelar
					</ButtonClose>
					<ButtonRemove disabled={true} size='small' />
				</>
			}
		/>
	);
};

const opcoesTipoTelefone = [
	{ id: 'M', name: 'Celular' },
	{ id: 'R', name: 'Residencial' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'P', name: 'Principal' },
	{ id: 'O', name: 'Outros' },
];

const opcoesTipoEmail = [
	{ id: 'P', name: 'Pessoal' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'CB', name: 'Cobrança' },
	{ id: 'O', name: 'Outros' },
];

const BoxObservacoes = (props) => {
	return (
		<BoxDivisor titulo='Observação'>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<Field name='observacao' id='observacao' disabled={props.record.situacao === 'I'}>
						{({ input, meta }) => (
							<TextInput
								source='observacao'
								label='Anotações'
								placeholder='Use este espaço para anotações referentes a este fornecedor'
								variant='outlined'
								validate={(value) =>
									value ? (value.length > 500 ? [`Máximo excedido: ${value.length}/500`] : '') : ''
								}
								helperText={meta?.error ? meta?.error : `${(input?.value || '').length}/500`}
								rows={8}
								multiline
								fullWidth
							/>
						)}
					</Field>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

const BoxInstituicaoFinanceira = ({ choicesInstituicoes, change, getSource, scopedFormData }) => {
	const [idInstFinanceira, setIdInstFinanceira] = React.useState(choicesInstituicoes.id || null);
	const instituicaoFinanceiraRef = React.useRef(choicesInstituicoes.find((c) => c.id === idInstFinanceira));

	React.useEffect(() => {
		if (scopedFormData?.instituicao_financeira_pagamento) {
			change(getSource('instituicao_financeira_pagamento'), scopedFormData?.instituicao_financeira_pagamento?.id);
		}
	}, []);

	return (
		<Autocomplete
			disableClearable
			value={instituicaoFinanceiraRef.current}
			name='instituicao_financeira_pagamento'
			onChange={(e, value) => {
				instituicaoFinanceiraRef.current = value;
				setIdInstFinanceira(instituicaoFinanceiraRef.current.id);
				change(getSource('instituicao_financeira_pagamento'), value.id);
			}}
			options={choicesInstituicoes}
			renderOption={(option) => {
				return option.has_integracao_api ? `${option.nome} - Integração automática - via API` : option.nome;
			}}
			getOptionLabel={(option) => {
				return option.has_integracao_api ? `${option.nome} - Integração automática - via API` : option.nome;
			}}
			noOptionsText='Nenhum resultado'
			getOptionSelected={(option, newValue) => {
				return newValue.id === option.id;
			}}
			fullWidth
			defaultValue={scopedFormData?.instituicao_financeira_pagamento}
			renderInput={({ options, ...params }) => (
				<TextField
					{...params}
					label='Instituição Financeira'
					variant='outlined'
					margin='dense'
					helperText={false}
					size='small'
					fullWidth
				/>
			)}
		/>
	);
};

const BoxContaBancariaFornecedor = ({ choicesInstituicoes }) => {
	const {
		change,
		mutators: { push },
	} = useForm();

	const formatadorContaBancaria = (value) => {
		if (value?.length > 8) {
			value = value.slice(0, 8);
		}
		if (value?.length > 7) {
			value = value.slice(0, -1) + '-' + value.slice(-1);
		}
		return value;
	};

	return (
		<Box flex={1}>
			<ArrayInput source='conta_bancaria_fornecedor_set' label='Cadastro de Conta Bancária' variant='outlined'>
				<SimpleFormIteratorV1 disableReordering={true} disableAdd={true} disableRemove={false} inline={true}>
					<FormDataConsumer>
						{({ scopedFormData, getSource }) => (
							<Box display='flex' flexDirection='column' mb='15px'>
								<BoxInstituicaoFinanceira
									choicesInstituicoes={choicesInstituicoes}
									change={change}
									getSource={getSource}
									scopedFormData={scopedFormData}
								/>
								<Box display='flex'>
									<Box flex={1} mr='.5em'>
										<TextField
											label='Agência'
											variant='outlined'
											margin='dense'
											helperText={false}
											size='small'
											fullWidth
											inputProps={{ maxLength: 50 }}
											value={scopedFormData?.agencia}
											onChange={(e) => change(getSource('agencia'), e.target.value)}
										/>
									</Box>
									<Box flex={1} mr='.5em'>
										<TextField
											label='Conta Corrente'
											variant='outlined'
											margin='dense'
											helperText={false}
											size='small'
											fullWidth
											value={scopedFormData?.conta_corrente}
											onChange={(e) =>
												change(
													getSource('conta_corrente'),
													formatadorContaBancaria(e.target.value.replace(/\D/g, ''))
												)
											}
										/>
									</Box>
									<Box flex={1}>
										<TextField
											label='Conta Cedente/Beneficiário'
											variant='outlined'
											margin='dense'
											helperText={false}
											size='small'
											fullWidth
											value={scopedFormData?.conta_corrente_cd}
											onChange={(e) =>
												change(
													getSource('conta_corrente_cd'),
													formatadorContaBancaria(e.target.value.replace(/\D/g, ''))
												)
											}
										/>
									</Box>
								</Box>
							</Box>
						)}
					</FormDataConsumer>
				</SimpleFormIteratorV1>
			</ArrayInput>
			<Button
				onClick={(e) => {
					e.preventDefault();
					push('conta_bancaria_fornecedor_set', {
						fornecedor: undefined,
						instituicao_financeira_pagamento: undefined,
						agencia: undefined,
						conta_corrente: undefined,
						conta_corrente_cd: undefined,
					});
				}}
				disabled={false}
			>
				Adicionar nova Conta
			</Button>
		</Box>
	);
};

export const FornecedorFormulario = (props) => {
	const [choicesInstituicoes, setChoicesInstituicoes] = React.useState([]);
	const dP = useDataProvider();
	const notify = useNotify();

	const { data, loaded, ids } = useGetList(
		'instituicoes_financeiras_pagamento',
		{ perPage: 10000, page: 1 },
		{ field: 'codigo', order: 'ASC' }
	);
	React.useEffect(() => {
		if (loaded) {
			setChoicesInstituicoes(
				ids.map((id) => {
					const { codigo, nome, has_integracao_api } = data[id];
					return {
						id,
						nome: `${codigo} - ${nome}`,
						has_integracao_api,
					};
				})
			);
		}
	}, [setChoicesInstituicoes, data, loaded]);

	return (
		<FormularioBox
			{...props}
			nome={
				props?.record?.ramo_descricao
					? `${props.record?.nome || ''} - ${props.record?.ramo_descricao} `
					: `${props.record?.nome || ''} `
			}
			onClickBotaoRemover={(propsOnClick, setModalValue) => {
				dP.getSimple('fornecedores', { id: props?.record?.id })
					.then((response) => {
						const data = response?.data;
						if (!data) throw new Error('Resposta do request veio vazio');
						if (data.usado_em_contas_a_pagar) {
							if (setModalValue)
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody: <ModalFeedbackBloqueioRemover setModalValue={setModalValue} />,
								}));
						} else {
							if (setModalValue)
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody: <ModalConfirmacao {...propsOnClick} setModalValue={setModalValue} />,
								}));
						}
					})
					.catch((e) => {
						console.log(e);
						const erroMsg = Object.values(e?.response?.data || {})[0] || [
							'Erro inesperado, tente recarregar a página',
						];
						notify(erroMsg, 'warning');
					});
			}}
		>
			<Box flex={1} mr='1em'>
				<Box display='flex'>
					<Box flex={1} m='1em'>
						<Typography variant='h5' gutterBottom>
							Dados Cadastrais
						</Typography>

						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>
						<Box display='flex'>
							<Box flex={1} mr='1em'>
								<TextInput
									source='nome'
									validate={nomeValidacao}
									parse={nomeParser}
									variant='outlined'
									fullWidth
									disabled={props.record.situacao === 'I'}
								/>
							</Box>
						</Box>
						<Box display='flex'>
							<Box flex={1} mr='1em'>
								<InputDocumento
									br={false}
									source='documento'
									sourceTipo='tipo_fornecedor'
									resource='fornecedores'
									customQueryValidation='documento'
									variant='outlined'
									fullWidth
									disabled={props.record.situacao === 'I'}
								/>
							</Box>
							<Box flex={1} mr='1em'>
								<ThemeProvider
									theme={(outerTheme) => ({
										...outerTheme,
										overrides: {
											...outerTheme.overrides,
											MuiIconButton: {
												sizeSmall: { padding: '7px 7px 9px 7px' },
											},
										},
									})}
								>
									<TipoModalInput
										source='tipo_ramo_fornecedor'
										label='Tipo de Ramo do Fornecedor'
										resource='tipo_fornecedor'
										disabled={props.record.situacao === 'I'}
										allowEmpty={false}
										variant='outlined'
										required
										validate={required()}
										fullWidth
										record={props.record}
										helperText={false}
									/>
								</ThemeProvider>
								<TextInput
									style={{ paddingTop: '1px' }}
									label='Apelido para Exportação Contábil'
									source='apelido'
									variant='outlined'
									inputProps={{ maxLength: 22 }}
									fullWidth
									disabled={props.record.situacao === 'I'}
								/>
							</Box>
						</Box>
					</Box>
				</Box>

				<Box mt='1em' />
				<Box m='1em'>
					<EnderecoBox {...props} />
				</Box>
			</Box>

			<Box flex={1}>
				<Box display='flex'>
					<Box flex={1} m='1em'>
						<Typography variant='h5' gutterBottom>
							Contatos
						</Typography>

						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>
						<Box display='flex'>
							<Box flex={0.5} ml='1em'>
								<ArrayInput source='telefonefornecedor_set' label='Telefones' variant='outlined'>
									<CustomFormIterator>
										<SyndikosSelect
											source='tipo'
											label='Tipo de Telefone'
											choices={opcoesTipoTelefone}
											validate={required()}
											disabled={props.record.situacao === 'I'}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='numero'
											format={telefoneFormatacao}
											parse={telefoneParser}
											validate={[required(), telefoneValidacao]}
											label='Número'
											disabled={props.record.situacao === 'I'}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
							</Box>
							<Box flex={0.5} ml='1em'>
								<ArrayInput source='emailfornecedor_set' label='Emails adicionais' variant='outlined'>
									<CustomFormIterator>
										<SyndikosSelect
											source='tipo'
											label='Tipo de Email'
											choices={opcoesTipoEmail}
											validate={required()}
											disabled={props.record.situacao === 'I'}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='email'
											label='Email'
											validate={validacaoEmailObrigatorio}
											disabled={props.record.situacao === 'I'}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
							</Box>
						</Box>
					</Box>
				</Box>
				<BoxObservacoes {...props} />
				<Box flex={1} m='1em'>
					<Typography variant='h5' gutterBottom>
						Contas Bancárias
					</Typography>
					<hr
						style={{
							marginRight: '1em',
							borderTop: '#d3d3d3',
						}}
					/>
					<BoxContaBancariaFornecedor choicesInstituicoes={choicesInstituicoes} />
				</Box>
			</Box>
		</FormularioBox>
	);
};
