import React from 'react';

import { useDataProvider } from 'react-admin';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../../../ArrecadacoesContext';
import { BoletosContext } from '../../../../ContasReceberBoletos/BoletosContextProvider';
import { RemessasContext } from '../../../contextos';

import { ButtonGerarBoletosSemRelatorios } from '../..';
import { useGerarBoletosSemRelatorios } from '../../../hooks';
import { ModalConfirmarGerarBoletoPadrao } from '../../../modais';
import ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico from '../ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico';

import { DadosPorInstituicaoProps } from '../types';
import { IModalValue } from 'types/tpyesGlobal';

const useDadosPorInstituicaoTipoIntegracaoCresolAutomatico = ({
	setLoadingRequest,
	setValid,
}: DadosPorInstituicaoProps) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const dP = useDataProvider();
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados } = React.useContext(RemessasContext);
	const { boletoConfigIsValid } = React.useContext(BoletosContext);

	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const condominioSelecionadoId = condominioSelecionado?.id;

	const gerarBoletos = useGerarBoletosSemRelatorios({
		condominioSelecionadoId,
		idsRegistrosSelecionados,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		boletoConfigIsValid,
		setValid,
		ButtonGerarRemessas: ButtonGerarBoletosSemRelatorios,
	});

	const onClick = React.useCallback(() => {
		setLoadingRequest(true);
		dP.getList('contas_receber', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'nome_unidade', order: 'ASC' },
			filter: {
				id__in: idsRegistrosSelecionados,
				condominio: condominioSelecionadoId,
				referencia: referencia,
				identificacao: id_identificacao_arrecadacao,
				possui_nosso_numero: true,
			},
		})
			.then(async (res) => {
				if (res?.data) {
					res.data = res.data.filter((v) => v.id !== 0);
					if (res.data?.length) {
						return setModalValue((v: IModalValue) => ({
							...v,
							open: true,
							dialogBody: <ModalConfirmarGerarBoletoPadrao nomeBanco={'Cresol'} />,
						}));
					}
					await gerarBoletos();
					setLoadingRequest(false);
				}
			})
			.catch((e) => {
				setLoadingRequest(false);
				return Promise.reject(e);
			});
	}, [
		dP,
		setModalValue,
		condominioSelecionadoId,
		referencia,
		id_identificacao_arrecadacao,
		idsRegistrosSelecionados,
		setLoadingRequest,
		gerarBoletos,
	]);

	return React.useMemo(
		() => ({
			Label: ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico,
			onClick,
		}),
		[onClick]
	);
};

export default useDadosPorInstituicaoTipoIntegracaoCresolAutomatico;
