import React from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Dialog, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';

import { CloseButton, DeleteButton } from 'components/common/commonComponentsTSX';

type ModalConfirmarExclusaoGaragemUnicaProps = {
	idGaragem: number;
	open: boolean;
	onClose: (event: React.SyntheticEvent<boolean>, reason: 'backdropClick' | 'escapeKeyDown') => void;
};

const ModalConfirmarExclusaoGaragemUnica: React.FC<ModalConfirmarExclusaoGaragemUnicaProps> = ({
	idGaragem,
	open,
	onClose,
}) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const dp = useDataProvider();
	const [loading, setLoading] = React.useState(false);

	function onExclude() {
		setLoading(true);
		dp.delete('garagens_unidades', {
			id: idGaragem,
		})
			.then(() => {
				notify('Garagem deletada com sucesso!', 'success');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(e, 'warning');
			})
			.finally(() => setLoading(false));
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '30vw',
					position: 'relative',
				},
			}}
		>
			<DialogContent>Deseja realmente fazer a exclusão da garagem?</DialogContent>
			<DialogActions>
				<CloseButton onClick={onClose} size='small'>
					{(loading && <CircularProgress size={20} />) || 'Cancelar'}
				</CloseButton>
				<DeleteButton onClick={onExclude} size='small' disabled={loading}>
					{(loading && <CircularProgress size={20} />) || 'Excluir'}
				</DeleteButton>
			</DialogActions>
		</Dialog>
	);
};

export default ModalConfirmarExclusaoGaragemUnica;
