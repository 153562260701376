import React from 'react';

import { Box } from '@material-ui/core';
import EditaContaBancariaSwitch from '../../commons/EditaContaBancariaSwitch';
import { useForm } from 'react-final-form';
import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

interface ExibeSePagarBoletosViaSistemaTrueProps {
	children: React.ReactNode;
}

const ExibeSePagarBoletosViaSistemaTrue: React.FC<ExibeSePagarBoletosViaSistemaTrueProps> = ({ children = null }) => {
	const { getState } = useForm();
	const {
		values: { pagamento_via_sistema },
	} = getState();
	return !pagamento_via_sistema ? null : <>{children}</>;
};

const TipoIntegracoesField = () => (
	<EditaContaBancariaAutocomplete
		source='tipo_integracao_pagamento_via_sistema'
		name='tipo_integracao_pagamento_via_sistema'
		id='tipo_integracao_pagamento_via_sistema'
		label='Tipo de integração'
		onChange={undefined}
		options={undefined}
	/>
);

const SwitchPagarBoletosViaSistema = () => (
	<EditaContaBancariaSwitch
		source='pagamento_via_sistema'
		id='pagamento_via_sistema'
		name='pagamento_via_sistema'
		label='Pagamento boletos via sistema'
		labelPlacement='end'
	/>
);

const PagamentosViaSistemaField = () => (
	<Box flexDirection='column' display='flex'>
		<SwitchPagarBoletosViaSistema />
		<ExibeSePagarBoletosViaSistemaTrue>
			<TipoIntegracoesField />
		</ExibeSePagarBoletosViaSistemaTrue>
	</Box>
);

const TabPagarBoletosViaSistema = () => {
	return (
		<Box>
			<Box flexDirection='row' display='flex'>
				<PagamentosViaSistemaField />
			</Box>
		</Box>
	);
};

export default TabPagarBoletosViaSistema;
