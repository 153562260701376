import { TFunctionValidate, TValidateRequiredValidValue, IGetValidacoesDescontoProps } from '../../../types';

import {
	validacoesFieldsTabInformacao,
	createValidateLinhas,
	validateTipoIntegracao,
	createValidateDadosIntegracaoAPI,
	validateRequiredValidValue,
	validateRequiredField,
} from '../../../utils/validadoes';

import {
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	mensagensErrosPadroes,
} from '../../default/ContaCorrenteProps';

const validateAgencia: TFunctionValidate = ({ values, options }) =>
	(validateRequiredValidValue as TValidateRequiredValidValue)({
		values: { agencia: values.agencia?.id },
		options,
		field: 'agencia',
		requiredMsg: 'Selecione uma agência pertencente para a instituição bancária selecionada',
		invalidMsg: 'Selecione uma agência pertencente para a instituição bancária selecionada',
	});

const validacoesMulta = getValidacoesMulta({
	dias: false,
});

const validacoesJuros = getValidacoesJuros({
	dias: false,
});

type TGetValidacoesDesconto = (props: IGetValidacoesDescontoProps) => ReturnType<typeof getValidacoesDesconto>;

const validacoesDesconto = (getValidacoesDesconto as TGetValidacoesDesconto)({
	tipo: true,
	valor: true,
	dias: true,
	minValor: 0.01,
	msgErroMinValor: 'O valor do desconto à ser aplicado não pode ser menor que 0.01',
});

const validateContaCedente: TFunctionValidate = ({ values }) =>
	validateRequiredField({
		values,
		field: 'conta_cedente',
		msg: 'Para emitir boletos, é requerido o número da conta cedente',
	});

const validacoesProtesto = getValidacoesProtesto({
	nomeCampoLegivel: 'protesto/negativação',
	msgErroVazio: mensagensErrosPadroes.vazioProtesto({ nomeCampoLegivel: 'protesto/negativação' }),
	minValue: 3,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto({
		nomeCampoLegivel: 'protesto/negativação',
		minValue: 3,
	}),
	maxValue: 99,
	msgErroMaxValue: mensagensErrosPadroes.maxValueProtesto({
		nomeCampoLegivel: 'protesto/negativação',
		maxValue: 99,
	}),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,

	validacoesJuros.tipo,
	validacoesJuros.valor,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const validateDadosIntegracaoAPI = createValidateDadosIntegracaoAPI();

const validateLinhas = createValidateLinhas({ numeroLinhas: 5, maxValue: 80 });

const validateQuandoEmitindoBoletos = getValidateQuandoEmitindoBoletos([
	validateAgencia,
	validateContaCedente,
	validateTipoIntegracao,
	validateDadosIntegracaoAPI,
	...validacoesInstrucoesCobrancaRemessa,
	validateLinhas,
]);

const validacoes = [...validacoesFieldsTabInformacao, validateQuandoEmitindoBoletos];

export default validacoes;
