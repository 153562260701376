import { TarefasManutencoesCreate } from './create';
import { TarefasManutencoesEdit } from './edit';
import { TarefasManutencoesList } from './list';

const resource = {
	list: TarefasManutencoesList,
	create: TarefasManutencoesCreate,
	edit: TarefasManutencoesEdit,
};

export default resource;
