import React, { useState, useEffect, useContext } from 'react';
import { TextInput, required, useNotify, useRefresh, useDataProvider } from 'react-admin';

import { Form, Field, useForm } from 'react-final-form';

import { Box, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, parseISO } from 'date-fns';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../common/ModalSizeEditableContext';
import { SyndikosMuiPickersUtilsProvider } from '../../common/InputDate';
import { FinalFormCurrencyInput, sanitizeListNumberFormatBRLProps, NumberFormatBRL } from '../../common/CurrencyInput';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import InputFile from '../../common/InputFile';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';

const useStyles = makeStyles((theme) => ({ font_orange: { color: theme.orange[300] } }));

const createHistoricoDefault = ({ nf, fornecedor_nome, quantidade_parcelas, numero_parcela, data_vencimento }) =>
	[
		`Pagamento: NF ${nf} `,
		fornecedor_nome,
		` Parcela ${numero_parcela}/${quantidade_parcelas} `,
		`- Vencimento ${format(parseISO(data_vencimento), 'dd/MM/yyyy')}`,
	].join('');

const dateValid =
	(msg = 'Data inválida') =>
	(value) => {
		if (!value || value.toString() === 'Invalid Date') return msg;
		return undefined;
	};

const maxDateLimited =
	({ maxDate = new Date(), msg = 'Data fornecida ultrapassou data máxima limite' }) =>
	(value) => {
		maxDate.setHours(0, 0, 0, 0);
		value.setHours(0, 0, 0, 0);
		if (value > maxDate) return msg;
		return undefined;
	};

const IdentificadorContaPagar = ({ numero_parcela, quantidade_parcelas, fornecedor_nome }) => (
	<>
		<Typography>Fornecedor: {fornecedor_nome}</Typography>
		<Typography>
			Parcela:{' '}
			{quantidade_parcelas > 1
				? `${numero_parcela.toString().padStart(3, '0')} de ${quantidade_parcelas.toString().padStart(3, '0')}`
				: 'Única'}
		</Typography>
	</>
);

const DataPagamentoInput = () => (
	<Field
		name='data_movimentacao'
		id='data_movimentacao'
		validate={(value, allValues, meta) => {
			let error = undefined;
			error = required('Data de pagamento requerida')(value, allValues, meta);
			if (!error) error = dateValid()(value, allValues, meta);
			if (!error)
				error = maxDateLimited({
					msg: 'A conta deve ser paga no máximo na data atual',
				})(value, allValues, meta);
			return error;
		}}
	>
		{({ input, meta: { error } }) => (
			<SyndikosMuiPickersUtilsProvider>
				<KeyboardDatePicker
					label='Pagamento'
					format='dd/MM/yyyy'
					mask='__/__/____'
					maxDate={new Date()}
					margin='dense'
					variant='inline'
					inputVariant='outlined'
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
					value={input.value}
					name={input.name}
					onChange={input.onChange}
					error={error}
					helperText={error}
					fullWidth
					disableToolbar
				/>
			</SyndikosMuiPickersUtilsProvider>
		)}
	</Field>
);

const DataVencimentoInput = () => (
	<Field name='data_vencimento' id='data_vencimento'>
		{({ input }) => (
			<SyndikosMuiPickersUtilsProvider>
				<KeyboardDatePicker
					label='Data de Vencimento'
					format='dd/MM/yyyy'
					mask='__/__/____'
					margin='dense'
					variant='inline'
					inputVariant='outlined'
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
					value={input.value ? `${input.value.split('T')[0]}T10:00` : null}
					name={input.name}
					disabled={true}
					fullWidth
					disableToolbar
				/>
			</SyndikosMuiPickersUtilsProvider>
		)}
	</Field>
);

const FieldsInformacoesPagamento = ({ lote, condominio_id, valor_pendente }) => (
	<Box display='flex' gridGap='5px'>
		<VeryHighLimitReferenceInput
			label='Conta Bancária'
			source='conta_b_origem'
			reference='conta_condominio'
			variant='outlined'
			filter={{
				id_condominio: condominio_id,
				situacao: 'A',
			}}
			validate={required('Conta Bancária é obrigatória')}
			fullWidth
		>
			<SyndikosSelect optionText='nome_conta' variant='outlined' allowEmpty={true} fullWidth />
		</VeryHighLimitReferenceInput>
		{lote ? <DataPagamentoInput /> : <DataVencimentoInput />}
		<TextField
			value={valor_pendente}
			name='valor_pendente'
			id='valor_pendente'
			label='Valor Parcela'
			margin='dense'
			inputProps={sanitizeListNumberFormatBRLProps({
				prefix: 'R$ ',
				decimalScale: 2,
			})}
			InputProps={{
				inputComponent: NumberFormatBRL,
			}}
			disabled={true}
			fullWidth
		/>
	</Box>
);

const FieldsPagamentoSingle = ({ values: { multa, juros }, valor_pendente }) => {
	const [valorPagamento, setValorPagamento] = useState(
		parseFloat((multa + juros + parseFloat(valor_pendente)).toFixed(2))
	);
	useEffect(
		() => setValorPagamento(parseFloat((multa + juros + parseFloat(valor_pendente)).toFixed(2))),
		[setValorPagamento, multa, juros, valor_pendente]
	);

	return (
		<Box display='flex' gridGap='5px'>
			<DataPagamentoInput />
			<TextField
				value={valorPagamento}
				name='valor_pagamento'
				id='valor_pagamento'
				label='Valor Pagamento'
				margin='dense'
				inputProps={sanitizeListNumberFormatBRLProps({
					prefix: 'R$ ',
					decimalScale: 2,
				})}
				InputProps={{
					inputComponent: NumberFormatBRL,
				}}
				disabled={true}
				fullWidth
			/>
		</Box>
	);
};

const FieldsDMJ = () => (
	<Box display='flex' gridGap='5px'>
		<div style={{ width: '100%' }}></div>
		<FinalFormCurrencyInput
			id='multa'
			name='multa'
			label='Multa'
			margin='dense'
			prefix='R$ '
			decimalScale={2}
			onChange={(event) => {
				if (!event.target.value) event.target.value = 0;
				if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
			}}
			fullWidth={true}
		/>
		<FinalFormCurrencyInput
			id='juros'
			name='juros'
			label='Juros'
			margin='dense'
			prefix='R$ '
			decimalScale={2}
			onChange={(event) => {
				if (!event.target.value) event.target.value = 0;
				if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
			}}
			fullWidth={true}
		/>
	</Box>
);

const InputHistorico = ({ historico_padrao }) => (
	<Field name='historico' id='historico'>
		{({ input }) => {
			return (
				<TextInput
					value={(input.value || '').slice(0, 200)}
					name={input.name}
					label='Observação de Baixa'
					variant='outlined'
					helperText={`${input.value.length}/200 - ${historico_padrao}`}
					fullWidth
				/>
			);
		}}
	</Field>
);

const FieldsAnexoBoleto = ({ anexo_boleto }) => {
	const { change } = useForm();

	return (
		<Box display='flex' gridGap='10px'>
			<Box flex={1}>
				<InputFile
					label='Boleto'
					onCallBack={(e, dataUrl) => change('anexo_boleto', dataUrl)}
					fileDataUrl={anexo_boleto}
				/>
			</Box>
		</Box>
	);
};

const FieldsAnexoComprovantePagamento = ({ anexo_comprovante_pagamento }) => {
	const { change } = useForm();

	return (
		<Box display='flex' gridGap='10px'>
			<Box flex={1}>
				<InputFile
					label='Comprovante de pagamento'
					onCallBack={(e, dataUrl) => change('anexo_comprovante_pagamento', dataUrl)}
					fileDataUrl={anexo_comprovante_pagamento}
				/>
			</Box>
		</Box>
	);
};

const FieldsPagamento = ({ lote, ...extras }) => {
	const classes = useStyles();

	return (
		<Box display='flex' flexDirection='column' gridGap='10px'>
			{!lote && <IdentificadorContaPagar {...extras} />}
			<FieldsInformacoesPagamento lote={lote} {...extras} />
			{!lote && <FieldsPagamentoSingle {...extras} />}
			{!lote && <FieldsDMJ {...extras} />}
			<InputHistorico {...extras} />
			<Typography className={classes.font_orange}>
				<strong>Aviso: </strong>Caso o campo Observação de Baixa fique vazio, será atribuida a tag automática
				informada na legenda.
			</Typography>
			{!lote && <FieldsAnexoBoleto {...extras} />}
			{!lote && <FieldsAnexoComprovantePagamento {...extras} />}
		</Box>
	);
};

export const ModalPagamentoSimples = ({ parcela }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const [id] = useState(parcela.id);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [historicoPadrao] = useState(createHistoricoDefault(parcela));
	const [loading, setLoading] = useState(false);

	const handleSubmit = ({
		data_movimentacao,
		juros,
		multa,
		historico,
		conta_b_origem,
		anexo_boleto,
		anexo_comprovante_pagamento,
	}) => {
		if (loading) return;
		setLoading(true);
		const get_anexo = (anexo, campo) =>
			anexo ? (anexo.includes(';base64,') && parcela[campo] !== anexo ? anexo : '') : null;
		dataProvider
			.updateOnAction('parcela', {
				id: id,
				data: {
					id: id,
					data_movimentacao: format(data_movimentacao, 'yyyy-MM-dd'),
					juros: juros,
					multa: multa,
					write_only_anexo_boleto: get_anexo(anexo_boleto, 'anexo_boleto'),
					write_only_anexo_comprovante_pagamento: get_anexo(
						anexo_comprovante_pagamento,
						'anexo_comprovante_pagamento'
					),
					historico: (!!historico?.length && historico) || historicoPadrao,
					conta_b_origem: conta_b_origem,
					action: 'pagar',
					detail: true,
				},
			})
			.then(() => {
				setLoading(false);
				notify('Pagamento registrado com sucesso!');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setLoading(false);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
				refresh();
			});
	};

	const closeModal = () => setModalValue((v) => ({ ...v, open: false }));

	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			initialValues={{
				conta_b_origem: parcela.conta_b_origem,
				data_movimentacao: new Date(),
				data_vencimento: parcela.data_vencimento,
				anexo_boleto: parcela.anexo_boleto,
				anexo_comprovante_pagamento: parcela.anexo_comprovante_pagamento,
				historico: '',
				juros: 0,
				multa: 0,
			}}
			keepDirtyOnReinitialize={true}
			render={({ values, invalid }) => (
				<CustomDialogBodySizeEditable
					title={'Registrar Pagamento - Conta a Pagar'}
					form={{
						component: (
							<FieldsPagamento
								{...parcela}
								values={values}
								historico_padrao={historicoPadrao}
								condominio_id={parcela.condominio}
							/>
						),
					}}
					customActions={
						<>
							<ButtonCancel onClick={closeModal} />
							<ButtonConfirm disabled={invalid || loading} onClick={() => handleSubmit(values)} />
						</>
					}
				/>
			)}
		/>
	);
};

export const ModalPagamentoLote = ({ lote }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [valorLote] = useState(
		lote.reduce((total, { valor_pendente }) => parseFloat((total + parseFloat(valor_pendente)).toFixed(2)), 0)
	);
	const [historicoPadrao] = useState(
		'Pagamento: NF <numero da nf> <nome do fornecedor> Parcela <numero da parcela>/<quantidade de parcelas> - Vencimento <data de vencimento>'
	);
	const [loading, setLoading] = useState(false);

	const handleSubmit = ({ data_movimentacao, conta_b_origem, historico }) => {
		if (loading) return;
		setLoading(true);
		Promise.allSettled(
			lote.map((parcela) =>
				dataProvider.updateOnAction('parcela', {
					id: parcela.id,
					data: {
						data_movimentacao: data_movimentacao.toISOString().split('T')[0],
						conta_b_origem: conta_b_origem,
						historico: historico?.length ? historico : createHistoricoDefault(parcela),
						multa: 0.0,
						juros: 0.0,
						action: 'pagar',
						detail: true,
						is_lote: true,
					},
				})
			)
		).then(() => {
			setLoading(false);
			notify('Pagamentos registrados com sucesso!');
			refresh();
		});
	};

	const closeModal = () => setModalValue((v) => ({ ...v, open: false }));

	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			initialValues={{
				conta_b_origem: lote[0]?.conta_b_origem,
				data_movimentacao: new Date(),
				historico: '',
			}}
			render={({ values, invalid }) => (
				<CustomDialogBodySizeEditable
					title={'Registrar Pagamento - Conta a Pagar - Lote'}
					form={{
						component: (
							<FieldsPagamento
								lote={true}
								values={values}
								condominio_id={lote[0]?.condominio_id}
								valor_pendente={valorLote}
								historico_padrao={historicoPadrao}
							/>
						),
					}}
					customActions={
						<>
							<ButtonCancel onClick={closeModal} />
							<ButtonConfirm disabled={invalid} onClick={() => handleSubmit(values)} />
						</>
					}
				/>
			)}
		/>
	);
};
