import React from 'react';

import { Box } from '@material-ui/core';

import {
	ModalCadastrarDadosIntegracaoAPI as ModalCadastrarDadosIntegracaoAPIBase,
	ClientIDField,
} from '../../../../../commons/CadastrarDadosIntegracaoAPIField';

const ModalBody = () => (
	<Box display='flex' flexDirection='column' gridRowGap='1rem'>
		<Box>
			<ClientIDField label='Cliend ID' source='client_id' />
		</Box>
	</Box>
);

const ModalCadastrarDadosIntegracaoAPI = () => <ModalCadastrarDadosIntegracaoAPIBase Component={ModalBody} />;

export default ModalCadastrarDadosIntegracaoAPI;
