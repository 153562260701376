import { styled } from '@material-ui/core/styles';

const RESERVA_COLOR = {
	filtradas: 'primary',
	realizadas: 'green',
	bloqueado: 'red',
};

export const ReservaIcon = styled('i')(({ tipoReserva, theme }) => ({
	width: 'clamp(10px, 2vw, 16px)',
	height: 'clamp(10px, 2vw, 16px)',
	borderRadius: '50%',
	backgroundColor: theme[RESERVA_COLOR[tipoReserva]]['200'],
}));
