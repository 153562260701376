import lodashMemoize from 'lodash/memoize';
import React, { useMemo } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import { Button, IconButton, LinearProgress, TextField, Typography } from '@material-ui/core';

// icones
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import { TooltipIconButtonCancel } from '../../../buttons/ButtonCancel';

import { CustomDataProvider } from 'types/tpyesGlobal';
import { TooltipIconButton } from '../..';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from '../../../ModalContext';

// types
import {
	ToolInputProviderProps,
	ToolInputSelectProps,
	ToolInputSelectContextType,
	ToolInputRowProps,
	ModalToolInputProps,
	ToolInputProps,
} from './types';

// styles
import useStyles from './style';

const memoize = (fn: () => void) => lodashMemoize(fn, (...args) => JSON.stringify(args));

const ToolInputSelectContext = React.createContext<ToolInputSelectContextType>({
	data: [],
	create() {},
	update() {},
	deleteOne() {},
	iniciado: false,
	setIniciado(value: React.SetStateAction<boolean>) {},
	previous: null,
	setPrevious(value: React.SetStateAction<number | null>) {},
	filter: '',
	setFilter(value: React.SetStateAction<string>) {},
	selected: null,
	setSelected(value: React.SetStateAction<number | null>) {},
	optionSelected: {},
	setOptionSelected(value: Record<string | number, any>) {},
});

const ToolInputSelectProvider: React.FC<ToolInputProviderProps> = React.memo(({ children, source, optionText }) => {
	const [iniciado, setIniciado] = React.useState(false);
	const [previous, setPrevious] = React.useState<number | null>(null);
	const [data, setData] = React.useState<[]>([]);
	const dataProvider: CustomDataProvider = useDataProvider();
	const notify = useNotify();
	const [filter, setFilter] = React.useState('');
	const [unfiltered, setUnfiltered] = React.useState<Record<string, any>>();
	const [selected, setSelected] = React.useState<number | null>(null);
	const [optionSelected, setOptionSelected] = React.useState<Record<string | number, any>>({});

	const { modalValue } = React.useContext(ModalContext);
	React.useEffect(() => {
		if (!modalValue?.open) {
			setFilter('');
		}
	}, [modalValue]);
	const init = memoize(() => {
		dataProvider
			.getSimple(source, {
				pagination: { page: 1, perPage: 100000 },
				sort: {},
				filter: { [optionText]: filter },
			})
			.then((response) => {
				const data = response?.data.results;
				if (data) {
					const datas: typeof data = {};
					Object.keys(data).forEach((key) => (datas[data[key].id] = data[key]));
					setUnfiltered(datas as typeof data);
					setData(datas);
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			})
			.finally(() => {
				setIniciado(true);
			});
	});

	const create = React.useCallback(
		(reqData) => {
			const memoizedFunction = memoize(() => {
				dataProvider
					.create(source, { data: { ...reqData } })
					.then((response) => {
						const data = response?.data;
						if (data) {
							setUnfiltered((t: typeof data) => ({ ...t, [data.id]: data }));
							notify('cadastrado com sucesso');
						}
					})
					.catch((e) =>
						[401, 403].includes(e?.response?.status)
							? Promise.reject(e)
							: notify('Erro ao cadastrar', 'warning')
					);
			});
			return memoizedFunction();
		},
		[dataProvider, notify, source]
	);
	const update = React.useCallback(
		(id: string | number, reqData) => {
			const memoizedFunction = memoize(() => {
				dataProvider
					.update(source, { id, data: { ...reqData } })
					.then((response) => {
						const data = response?.data;
						if (data) {
							setUnfiltered((t: typeof data) => ({ ...t, [data.id]: data }));
							notify('Tipo alterado com sucesso');
						}
					})
					.catch((e) =>
						[401, 403].includes(e?.response?.status)
							? Promise.reject(e)
							: notify('Erro ao atualizar', 'warning')
					);
			});
			return memoizedFunction();
		},
		[dataProvider, notify, source]
	);
	const deleteOne = React.useCallback(
		(id: number) => {
			const memoizedFunction = memoize(() => {
				dataProvider
					.delete(source, { id })
					.then(() => {
						const { [id]: _, ...rest } = data;
						setUnfiltered(rest as typeof data);
						notify('removido com sucesso');
						setSelected(null);
					})
					.catch((e) =>
						[401, 403].includes(e?.response?.status)
							? Promise.reject(e)
							: notify('Tipo em uso, não é possível remover', 'warning')
					);
			});
			return memoizedFunction();
		},
		[dataProvider, data, notify, source]
	);

	React.useEffect(init, []);
	React.useEffect(() => {
		if (unfiltered) {
			if (filter) {
				const filtered: Record<string, typeof data> = {};
				Object.keys(unfiltered).forEach((key) => {
					if (unfiltered[key][optionText].toLowerCase().includes(filter.toLowerCase())) {
						filtered[key] = unfiltered[key];
					}
				});
				setData(filtered as unknown as typeof data);
			} else {
				setData(unfiltered as typeof data);
			}
		}
	}, [unfiltered, filter]);

	const ToolInputSelectProviderValue = useMemo(
		() => ({
			data,
			create,
			update,
			deleteOne,
			iniciado,
			setIniciado,
			previous,
			setPrevious,
			filter,
			setFilter,
			selected,
			setSelected,
			optionSelected,
			setOptionSelected,
		}),
		[
			data,
			create,
			update,
			deleteOne,
			iniciado,
			setIniciado,
			previous,
			setPrevious,
			filter,
			setFilter,
			selected,
			setSelected,
			optionSelected,
			setOptionSelected,
		]
	);
	return (
		<ToolInputSelectContext.Provider value={ToolInputSelectProviderValue}>
			{children}
		</ToolInputSelectContext.Provider>
	);
});

const ToolInputRow: React.FC<ToolInputRowProps> = React.memo(({ id, optionText }) => {
	const { data, update, deleteOne, selected, setPrevious, setSelected, setOptionSelected } =
		React.useContext(ToolInputSelectContext);

	const inputRef = React.useRef<HTMLElement>(null);
	const [descricao, setDescricao] = React.useState('');
	const [editMode, setEditMode] = React.useState(false);
	const [visibility, setVisibility] = React.useState<'hidden' | 'visible'>('hidden');

	React.useEffect(() => {
		if (editMode) inputRef?.current?.focus();
	}, [editMode]);

	React.useEffect(() => {
		if (data && data[id as number]) setDescricao(data[id as number][optionText]);
	}, [data, id]);

	const handleSetDescricao = (value: string) => {
		setDescricao(value);
	};

	const handleClickedRow = () => {
		setPrevious(selected as number);
		setSelected((data[id as number] as { id: number })?.id - 1);
		setOptionSelected(data[id as number] as { id: number });
	};

	const classes = useStyles({
		selected: selected as number,
		dataId: ((data[id as number] as { id: number })?.id as number) - 1,
	});
	return (
		<Box
			display='flex'
			justifyContent='space-between'
			width='calc(100% - 4px)'
			alignItems='center'
			className={classes.stlyeItemBox}
			onClick={handleClickedRow}
			onMouseEnter={() => setVisibility('visible')}
			onMouseLeave={() => setVisibility('hidden')}
		>
			{editMode ? (
				<>
					<TextField
						inputRef={inputRef}
						value={descricao}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetDescricao(e.target.value)}
						fullWidth
						margin='dense'
					/>

					<Box display='flex'>
						<TooltipIconButton
							id=''
							title='Salvar'
							onClick={() => {
								!descricao ? setDescricao(data[id as number][optionText]) : update(id, { descricao });
								setEditMode(false);
							}}
							key={id}
						>
							<CheckIcon />
						</TooltipIconButton>
						<TooltipIconButtonCancel
							onClick={() => {
								setDescricao(data[id as number][optionText]);
								setEditMode(false);
							}}
							title='edit'
							className={null}
						>
							<></>
						</TooltipIconButtonCancel>
					</Box>
				</>
			) : (
				<>
					<Typography style={{ padding: 14 }}>{descricao}</Typography>
					<Box
						display='flex'
						style={{
							visibility: visibility,
						}}
					>
						<TooltipIconButton
							title='Renomear'
							onClick={() => {
								setEditMode(true);
							}}
							id='renomear-button'
							key={id}
						>
							<EditIcon />
						</TooltipIconButton>
						<TooltipIconButton
							title='Remover'
							onClick={() => {
								deleteOne(id as number);
							}}
							id='renomear-button'
						>
							<DeleteOutlineIcon />
						</TooltipIconButton>
					</Box>
				</>
			)}
		</Box>
	);
});

const ModalToolInput: React.FC<ModalToolInputProps> = React.memo(({ optionText, label }) => {
	const { data, create, setFilter, filter } = React.useContext(ToolInputSelectContext);

	const inputRef = React.useRef<HTMLInputElement>(null);
	const { setModalValue, modalValue } = React.useContext(ModalContext);
	const [addMode, setAddMode] = React.useState(false);

	const handleCreate = () => {
		create({ descricao: filter });
		if (inputRef.current) {
			inputRef.current.value = '';
		}
		setFilter('');
		setAddMode(false);
	};

	return (
		<CustomDialogBody
			title={`Editar ${label}`}
			customActions={
				<Button
					onClick={() => {
						setFilter('');
						setModalValue((v: typeof modalValue) => ({ ...v, open: false }));
					}}
					size='small'
					startIcon={<CloseIcon />}
				>
					Fechar
				</Button>
			}
			form={{
				component: (
					<>
						<Box display='flex' alignItems='center'>
							<TextField
								inputRef={inputRef}
								label={addMode ? 'Nome do novo tipo' : 'Buscar'}
								fullWidth
								margin='dense'
								onKeyPress={(e) => e.key === 'Enter' && addMode && handleCreate()}
								onChange={(e) => {
									setFilter(e.target.value);
								}}
							/>
							{addMode ? (
								<>
									<Box display='flex'>
										<IconButton
											disabled={inputRef.current != null && inputRef?.current.value === ''}
											title='Salvar'
											onClick={handleCreate}
											id='criar'
										>
											<CheckIcon />
										</IconButton>
										<TooltipIconButtonCancel
											onClick={() => setAddMode(false)}
											title='cancel'
											className={null}
										>
											<ClearIcon />
										</TooltipIconButtonCancel>
									</Box>
								</>
							) : (
								<TooltipIconButton
									title='Cadastrar novo tipo'
									id='create'
									onClick={() => {
										setAddMode(true);
										inputRef?.current?.focus();
									}}
								>
									<AddCircleOutlineIcon />
								</TooltipIconButton>
							)}
						</Box>
						<Box mx={2} mt={2} position='relative' height={350} style={{ overflowY: 'auto' }}>
							{Object.keys(data).length > 0 ? (
								Object.keys(data).map((key) => (
									<ToolInputRow id={key} key={key} optionText={optionText} />
								))
							) : (
								<Typography>Nenhum tipo encontrado</Typography>
							)}
						</Box>
					</>
				),
			}}
		/>
	);
});

export const ToolInput: React.FC<ToolInputProps> = ({
	label,
	optionText,
	getOptionsSelected,
	TextFieldProps,
	defaultValueId,
	...props
}) => {
	const { setModalValue, modalValue } = React.useContext(ModalContext);
	const { data, selected, setSelected, optionSelected, setOptionSelected } = React.useContext(ToolInputSelectContext);

	const dataOptions = typeof data === 'object' ? Object.values(data) : data;
	const defaultOptionValue = dataOptions.find((item) => item?.id === defaultValueId) as Record<string | number, any>;
	const handleOptionSelected = React.useCallback((_, optionSelectedInput: Record<string | number, any>) => {
		setSelected((optionSelectedInput?.id as number) - 1);
		setOptionSelected(optionSelectedInput);
		getOptionsSelected(optionSelectedInput);
	}, []);

	React.useEffect(() => {
		if (optionSelected) {
			getOptionsSelected(optionSelected);
		}

		if (selected) {
			return;
		} else {
			setSelected(defaultValueId - 1);
		}
	}, [optionSelected]);

	const handleSetModal = (): void => {
		setModalValue((v: typeof modalValue) => ({
			...v,
			open: true,
			dialogBody: <ModalToolInput {...{ optionText, label }} />,
		}));
	};

	if (defaultValueId && !defaultOptionValue)
		return (
			<Box mt={5}>
				<LinearProgress />
			</Box>
		);
	return (
		<Autocomplete
			{...props}
			style={{ position: 'relative' }}
			noOptionsText='Nenhum resultado encontrado'
			clearText='Limpar'
			id='pessoas-autocomplete'
			options={dataOptions}
			disabled={!dataOptions.length}
			getOptionLabel={(option) => option[optionText]}
			onChange={handleOptionSelected}
			renderInput={(params: AutocompleteRenderInputParams) => (
				<>
					<TextField {...TextFieldProps} {...params} label={`Selecionar ${label}`} margin='dense' />
					<Box style={{ position: 'absolute', top: 5, right: 0, cursor: 'pointer' }}>
						<IconButton id='edit-icon-tooltip' onClick={handleSetModal}>
							<EditIcon />
						</IconButton>
					</Box>
				</>
			)}
			fullWidth
			disableClearable
			value={(dataOptions.find((item) => item?.id === defaultValueId) as Record<string | number, any>) || null}
		/>
	);
};

/**
 * Este é um componente genérico que foi feito para ser usado em diversos casos.
 *
 * Ele fornece uma maneira conveniente de selecionar e gerenciar itens usando o componente Autocomplete
 * do Material-UI, juntamente com um diálogo modal personalizado para edição e gerenciamento de itens.
 *
 * @component
 * @example
 * // Exemplo de uso:
 * <ToolInputSelect
 *   source="items"
 *   label="Itens"
 *   optionText="name" // A propriedade que determina o campo de texto exibido para cada opção
 *   getOptionsSelected={(selectedItem) => handleSelected(selectedItem)}
 *   TextFieldProps={{ variant: "outlined" }}
 * />
 *
 * @param {string} source - O nome da fonte de dados dos itens a serem exibidos e gerenciados.
 * @param {string} label - O rótulo que descreve o tipo de item a ser selecionado.
 * @param {string} optionText - O nome do campo nos dados dos itens que será usado como texto de opção.
 * @param {Function} getOptionsSelected - Um callback que é acionado quando uma opção é selecionada.
 * @param {Object} TextFieldProps - Props adicionais para serem passados para o componente TextField dentro do Autocomplete.
 */

const ToolInputSelect: React.FC<ToolInputSelectProps> = ({
	ToolInput,
	source,
	optionText, // Importante: Esta propriedade determina o campo de texto exibido para cada opção.
}) => {
	return (
		<ModalContextProvider>
			<ToolInputSelectProvider source={source} optionText={optionText}>
				<CustomDialog />
				{ToolInput}
			</ToolInputSelectProvider>
		</ModalContextProvider>
	);
};
export default ToolInputSelect;
