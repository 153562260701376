import { FornecedorCreate } from './create';
import { FornecedorEdit } from './edit';
import { FornecedoresList } from './list';

const resource = {
	list: FornecedoresList,
	create: FornecedorCreate,
	edit: FornecedorEdit,
};

export default resource;
