import React, { useContext } from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ptBR from 'date-fns/locale/pt-BR';
import { InfoFuncionarioProps } from './types';
import useDeleteListContext, {
	DeleteListContext,
} from 'components/common/commonComponentsTSX/DeleteList/DeleteListContext';
import { CreateButton } from 'components/common/commonComponentsTSX';
import { FuncionarioContext } from './contexts/FuncionarioContext';
import { format, parse } from 'date-fns';
import { FuncaoContext } from './contexts/FuncoesContext';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ModalEditaFuncionario } from './modals/ModalEditaFuncionario';
import { ModalCriaFuncionario } from './modals/ModalCriaFuncionario';

const larguraColuna = {
	checkbox: '5%',
	nome: '30%',
	funcao: '30%',
	acessoApp: '10%',
	dataEntrada: '10%',
	dataSaida: '10%',
};

const TableRowFuncionario: React.FC<InfoFuncionarioProps> = ({ funcionario, ...rest }) => {
	const { funcionarios, setFuncionario } = useContext(FuncionarioContext);
	const { setFuncao } = useContext(FuncaoContext);
	const { id, dataEntrada, dataSaida, pessoa, funcao, acessoApp } = funcionario;
	const { idsSelecionadas, setIdSelecionadas } = useDeleteListContext();
	const isChecked = idsSelecionadas.includes(id);
	const { setModalValue, modalValue, setMinWidthModel } = React.useContext(ModalSizeEditableContext);
	const handleSelectId = (id: number) => {
		setIdSelecionadas((prev) => {
			if (idsSelecionadas.includes(id)) {
				return prev?.filter((item) => item !== id);
			}

			return [...prev, id];
		});
	};

	const handleEdit = (id: number) => {
		const funcionario = funcionarios.find((funcionario) => funcionario.id === id);
		if (funcionario) {
			setFuncionario(funcionario);
			setFuncao(funcionario.funcao);
			setMinWidthModel('55vw');
			setModalValue((v: typeof modalValue) => ({
				...v,
				open: true,
				dialogBody: <ModalEditaFuncionario {...funcionario} />,
			}));
		}
	};

	return (
		<TableRow {...rest} key={id}>
			<TableCell style={{ paddingLeft: 0, whiteSpace: 'nowrap', width: larguraColuna['checkbox'] }}>
				<Checkbox checked={isChecked} onClick={() => handleSelectId(id)} />
			</TableCell>
			<TableCell
				onClick={() => handleEdit(id)}
				style={{ paddingLeft: 0, textAlign: 'start', width: larguraColuna['nome'] }}
			>
				{pessoa.nome}
			</TableCell>
			<TableCell
				onClick={() => handleEdit(id)}
				style={{ paddingLeft: 0, textAlign: 'start', width: larguraColuna['funcao'] }}
			>
				{funcao.descricao}
			</TableCell>
			<TableCell onClick={() => handleEdit(id)} style={{ paddingLeft: 0, width: larguraColuna['acessoApp'] }}>
				{acessoApp ? 'Sim' : 'Não'}
			</TableCell>

			<TableCell
				onClick={() => handleEdit(id)}
				style={{ paddingLeft: 0, textAlign: 'start', width: larguraColuna['dataEntrada'] }}
			>
				{format(parse(dataEntrada, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy', { locale: ptBR })}
			</TableCell>
			<TableCell
				onClick={() => handleEdit(id)}
				style={{ paddingLeft: 0, textAlign: 'start', width: larguraColuna['dataSaida'] }}
			>
				{dataSaida ? format(parse(dataSaida, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy', { locale: ptBR }) : ''}
			</TableCell>
		</TableRow>
	);
};

const TableHeaders: React.FC<{ selecionaTodos: boolean | undefined; setSelecionaTodos: any }> = (props) => {
	const { idsSelecionadas, setIdSelecionadas } = React.useContext(DeleteListContext);
	const { funcionarios } = useContext(FuncionarioContext);

	const handleClickChecked = () => {
		if (idsSelecionadas.length === funcionarios.length) {
			setIdSelecionadas([]);
			props.setSelecionaTodos(false);
		} else {
			setIdSelecionadas(funcionarios.map((funcionario) => funcionario.id));
			props.setSelecionaTodos(true);
		}
	};

	return (
		<TableRow>
			<TableCell style={{ paddingLeft: 0, whiteSpace: 'nowrap', width: larguraColuna['checkbox'] }}>
				<Checkbox
					checked={props.selecionaTodos}
					onClick={() => {
						handleClickChecked();
					}}
				/>
			</TableCell>
			<TableCell style={{ paddingLeft: 0, fontWeight: 500, lineHeight: '1.5rem', width: larguraColuna['nome'] }}>
				Nome
			</TableCell>
			<TableCell
				style={{ paddingLeft: 0, fontWeight: 500, lineHeight: '1.5rem', width: larguraColuna['funcao'] }}
			>
				Função
			</TableCell>
			<TableCell
				style={{
					paddingLeft: 0,
					textAlign: 'start',
					width: larguraColuna['acessoApp'],
					fontWeight: 500,
					lineHeight: '1.5rem',
				}}
			>
				Acesso ao App
			</TableCell>
			<TableCell
				style={{
					paddingLeft: 0,
					textAlign: 'start',
					width: larguraColuna['dataEntrada'],
					fontWeight: 500,
					lineHeight: '1.5rem',
				}}
			>
				Data de Entrada
			</TableCell>
			<TableCell
				style={{
					paddingLeft: 0,
					textAlign: 'start',
					width: larguraColuna['dataSaida'],
					fontWeight: 500,
					lineHeight: '1.5rem',
				}}
			>
				Data de Saída
			</TableCell>
		</TableRow>
	);
};

const TableFuncionario: React.FC = () => {
	const { idsSelecionadas } = useContext(DeleteListContext);
	const { funcionarios } = useContext(FuncionarioContext);
	const [selecionaTodos, setSelecionaTodos] = React.useState(false);

	React.useEffect(() => {
		if (funcionarios.length === idsSelecionadas.length && funcionarios.length !== 0) {
			setSelecionaTodos(true);
		} else {
			setSelecionaTodos(false);
		}
	}, [idsSelecionadas, funcionarios]);

	return (
		<Box>
			<Table size='small' padding='default'>
				<TableHead>
					<TableHeaders selecionaTodos={selecionaTodos} setSelecionaTodos={setSelecionaTodos} />
				</TableHead>
				<TableBody>
					{funcionarios?.map((funcionario) => (
						<TableRowFuncionario
							style={{ cursor: 'pointer' }}
							key={funcionario.id}
							funcionario={funcionario}
						/>
					))}
					{funcionarios.length ? (
						<TableHeaders selecionaTodos={selecionaTodos} setSelecionaTodos={setSelecionaTodos} />
					) : (
						<></>
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

const FuncionarioBody: React.FC = () => {
	const { setModalValue, modalValue } = React.useContext(ModalSizeEditableContext);

	const handleCadastrar = () => {
		setModalValue((v: typeof modalValue) => ({
			...v,
			open: true,
			dialogBody: <ModalCriaFuncionario />,
		}));
	};

	return (
		<>
			<Box mt={2}>
				<Box display={'flex'} justifyContent={'flex-end'}>
					<CreateButton onClick={handleCadastrar} circleIcon={true}>
						Cadastrar
					</CreateButton>
				</Box>
				<Box mt={2}>
					<TableFuncionario />
				</Box>
			</Box>
		</>
	);
};

export default FuncionarioBody;
