import React from 'react';

import { Autocomplete } from '@material-ui/lab';

import { TextField } from '@material-ui/core';

import { configuracoesDisponiveis } from './configuracoesDisponiveis';

function SelectConfiguracao({ onChange, ...props }) {
	return (
		<Autocomplete
			options={configuracoesDisponiveis}
			getOptionLabel={(o) => o?.name || ''}
			onChange={(_, newValue) => onChange(newValue)}
			renderInput={(params) => <TextField {...params} label='Selecionar Configuração' variant='outlined' />}
			clearText='Limpar'
			autoHighlight
			size='small'
			fullWidth
			defaultValue={''}
			{...props}
		/>
	);
}

export default SelectConfiguracao;
