import React, { useState, useEffect, useContext } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { Box, TextField, Button, Checkbox, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';

import { compareAsc } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import RelatorioToolbar from '../RelatorioToolbar';

import { PeriodoSelect, RelatorioMonthPicker, periodoOptions, TODAY } from '../dateUtils';
import { CustomDialogBody, ModalContext } from '../../../common/ModalContext';
import { ButtonCancel } from '../../../common/buttons/ButtonCancel';
import MultiSelect from 'components/common/Selects';
import { useRequestOptionsUnidades, useRequestOptionsIdentificacaoArrecadacao } from 'components/common/Selects/hooks';

const ReferenciasInputBox = ({ dataInicial, setDataInicial, dataFinal, setDataFinal }) => {
	return (
		<Box display='flex' gridGap='1em'>
			<RelatorioMonthPicker
				label='Mês Inicial'
				handleChange={(newValue) => {
					setDataInicial(newValue);
					if (compareAsc(newValue, dataFinal) === 1) setDataFinal(newValue);
				}}
				value={dataInicial}
				fullWidth
			/>
			<RelatorioMonthPicker
				label='Mês Final'
				handleChange={(newValue) => {
					setDataFinal(newValue);
					if (compareAsc(newValue, dataInicial) === -1) setDataInicial(newValue);
				}}
				value={dataFinal}
				fullWidth
			/>
		</Box>
	);
};

const BotaoToolbar = ({ handleClick, label, ...rest }) => (
	<Button color='primary' variant='outlined' onClick={handleClick} {...rest}>
		{label}
	</Button>
);

const AutocompleteIdentificacao = ({ identificacao, setIdentificacao, identificacaoData }) =>
	identificacaoData && (
		<Autocomplete
			value={identificacao}
			onChange={(_, newValue) => setIdentificacao(newValue)}
			options={[...Object.values(identificacaoData).filter((v) => v.id !== 0), { id: 0, nome: 'Todas' }]}
			getOptionLabel={(o) => o.nome}
			getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
			renderInput={(params) => <TextField {...params} label='Identificação de Arrecadação' variant='outlined' />}
			disabled={identificacaoData.length === 1}
			clearText='Limpar'
			autoHighlight
			noOptionsText='Nenhuma identificação disponível'
			size='small'
			fullWidth
			disableClearable
		/>
	);

const ModalBaixarArquivoUnico = ({ handleSubmit }) => {
	const [baixarArquivoUnico, setBaixarArquivoUnico] = useState(false);
	const { setModalValue } = useContext(ModalContext);
	return (
		<CustomDialogBody
			title='Deseja agrupar relatórios em um único arquivo?'
			form={{
				component: (
					<Box display='flex' alignItems='center'>
						<Checkbox
							checked={baixarArquivoUnico}
							onChange={(e) => setBaixarArquivoUnico(e.target.checked)}
						/>
						<Typography>Baixar arquivo único</Typography>
					</Box>
				),
			}}
			customActions={
				<>
					<ButtonCancel
						onClick={() =>
							setModalValue((v) => ({
								...v,
								open: false,
							}))
						}
					/>
					<Button
						size='small'
						onClick={(e) => {
							handleSubmit(e, baixarArquivoUnico ? 'pdf_unico' : 'pdf');
							setModalValue((v) => ({
								...v,
								open: false,
							}));
						}}
						className='default'
						variant='text'
						startIcon={<CheckIcon />}
						color='primary'
					>
						Confirmar
					</Button>
				</>
			}
		/>
	);
};

const CertidaoNegativaDebitosDivisor = ({ idCondominio, tipoRelatorio }) => {
	const { setModalValue } = useContext(ModalContext);
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [tipoData, setTipoData] = useState('referencia');
	const [unidades, setUnidades] = React.useState([]);
	const { options: optionsUnidades } = useRequestOptionsUnidades();
	const [identificacao, setIdentificacao] = useState({
		id: 0,
		nome: 'Todas',
	});

	React.useEffect(() => {
		if (identificacao || unidades.length) {
			setIdentificacao({
				id: 0,
				nome: 'Todas',
			});
			setUnidades([]);
		}
	}, [idCondominio]);
	const { options: identificacaoData } = useRequestOptionsIdentificacaoArrecadacao(idCondominio);
	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const [dataInicial, setDataInicial] = useState(new Date(TODAY));
	const [dataFinal, setDataFinal] = useState(new Date(TODAY));
	const requestData = {
		id_condominio: idCondominio,
		data_inicial: dataInicial,
		data_final: dataFinal,
		unidades: unidades.map((v) => v.id),
		id_identificacao: identificacao?.id ?? 0,
		tipo_data: tipoData,
	};

	const handleTipoDataChange = (_, newValue) => {
		setTipoData(newValue.id);
		newValue.id === 'referencia'
			? setDataInicial(new Date(TODAY)) && setDataFinal(new Date(TODAY))
			: setDataInicial() && setDataFinal() && setDateRange(periodoOptions[0]);
	};

	const handleClick = (e, tipoSaida) => {
		dataProvider
			.gerarRelatorio('relatorios', {
				...requestData,
				tipo_saida: tipoSaida,
				tipoRelatorio: tipoRelatorio.action,
			})
			.then(({ data }) => notify(tipoSaida === 'enviar' ? data?.success : 'Relatório gerado com sucesso!'))
			.catch((e) => {
				if (e?.response?.data instanceof Blob) {
					e.response.data.text().then((text) => {
						const data_obj = JSON.parse(text);
						notify(
							Object.values(data_obj || {})[0] || ['Erro inesperado, tente recarregar a página'],
							'warning'
						);
					});
				} else {
					notify(
						Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
						'warning'
					);
				}
				if ([400, 401, 403].includes(e?.response?.status)) return Promise.reject(e);
			});
	};

	return (
		<BoxDivisor titulo={tipoRelatorio.name}>
			<Box display='flex' gridGap='1em' mb='1em'>
				<Box flex={1}>
					<Autocomplete
						onChange={handleTipoDataChange}
						options={[
							{ id: 'referencia', name: 'Referência' },
							{
								id: 'data_vencimento',
								name: 'Data de Vencimento',
							},
						]}
						defaultValue={{
							id: 'referencia',
							name: 'Referência',
						}}
						getOptionLabel={(o) => o.name}
						renderInput={(params) => <TextField {...params} label='Tipo de Data' variant='outlined' />}
						getOptionSelected={(o, v) => o.id === v.id}
						autoSelect
						autoHighlight
						disableClearable
						size='small'
						fullWidth
					/>
				</Box>
				<Box flex={3}>
					{tipoData === 'referencia' ? (
						<ReferenciasInputBox
							{...{
								dataInicial,
								setDataInicial,
								dataFinal,
								setDataFinal,
							}}
						/>
					) : (
						<PeriodoSelect
							{...{
								dataInicial,
								setDataInicial,
								dataFinal,
								setDataFinal,
								dateRange,
								setDateRange,
							}}
						/>
					)}
				</Box>
			</Box>
			<Box display='flex' gridGap='1em'>
				<Box flex={1}>
					<AutocompleteIdentificacao
						{...{
							identificacao,
							setIdentificacao,
							identificacaoData,
						}}
					/>
				</Box>
				<Box flex={2}>
					<MultiSelect
						variant='formatsUnidades'
						size={'small'}
						options={optionsUnidades}
						setValue={setUnidades}
						value={unidades}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Selecionar Unidade'
								variant='outlined'
								helperText='Se vazio, todas as unidades do condomínio serão selecionadas'
							/>
						)}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar
					data={requestData}
					tipoRelatorio={tipoRelatorio}
					buttons={[
						<BotaoToolbar
							handleClick={(e) => handleClick(e, 'visualizar')}
							label='Visualizar'
							style={{ marginRight: '1em' }}
						/>,
						<BotaoToolbar
							handleClick={(e) =>
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody: <ModalBaixarArquivoUnico handleSubmit={handleClick} />,
								}))
							}
							label='Baixar PDF'
							style={{ marginRight: '1em' }}
						/>,
						<BotaoToolbar handleClick={(e) => handleClick(e, 'enviar')} label='Enviar por e-mail' />,
					]}
				/>
			</Box>
		</BoxDivisor>
	);
};

export default CertidaoNegativaDebitosDivisor;
