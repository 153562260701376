import * as React from 'react';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { Edit, useNotify, useRedirect, useRefresh } from 'react-admin';

import { CondominioFormulario } from './form';

export const CondominioEdit = (props) => {
	const { setReload } = React.useContext(CondominiosContext);
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	// Função para substituir onSuccess do <Edit /> caso seja escolhida mensagem customizada
	const onSuccess = (response) => {
		const data = response?.data;
		if (data) {
			notify(`Condomínio "${data.nome}" alterado com sucesso`);
			redirect('list', props.basePath);
			refresh();
			setReload();
		}
	};
	return (
		<Edit component='div' title={'Editar Condomínio'} undoable={false} onSuccess={onSuccess} {...props}>
			<CondominioFormulario edit {...props} />
		</Edit>
	);
};
