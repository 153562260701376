import React, { useContext, useEffect } from 'react';
import { TextInput, useInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';

import { numeroParser } from '../../../../../fieldControl/numero';
import { InputCep } from '../../../../common/InputCep';
import { CPFInput, CNPJInput, TipoInput } from '../../../../common/InputDocumento';
import { SyndikosSelect } from '../../../../common/SyndikosSelect';
import { useGetEndereco } from '../../../../common/Endereco';
import VeryHighLimitReferenceInput from '../../../../common/VeryHighLimitReferenceInput';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';

const GenericEnderecoInput = ({ newValue, Component, ...rest }) => {
	const { input } = useInput(rest);

	const handleValueChange = () => {
		if (newValue?.value) input.onChange(newValue?.value);
	};

	useEffect(handleValueChange, [newValue]);

	return <Component {...rest} {...input} />;
};

export const BoxComMargemDireita = ({ flex = 1, mr = '1em', children, ...props }) => (
	<Box {...{ flex, mr }} {...props}>
		{children}
	</Box>
);

const InputDocumento = ({
	variant = 'outlined',
	source = 'beneficiario_documento',
	fullWidth = true,
	helperText = false,
	disabled,
}) => {
	const {
		values: { beneficiario_tipo_documento },
	} = useFormState();
	return (
		<BoxComMargemDireita>
			{beneficiario_tipo_documento === 'F' ? (
				<CPFInput
					{...{
						variant,
						source,
						fullWidth,
						helperText,
						disabled,
					}}
				/>
			) : (
				<CNPJInput
					{...{
						variant,
						source,
						fullWidth,
						helperText,
						disabled,
					}}
				/>
			)}
		</BoxComMargemDireita>
	);
};

const BeneficiarioFields = () => {
	const {
		initialDados: { isInativo, cobranca_config },
		condominioRecord,
		dadosAdministradora,
	} = useContext(EditaContaBancariaContext);

	const { getState } = useForm();
	const {
		values: { beneficiario },
	} = getState();

	const { tipoLogradouro, endereco, bairro, uf, cidade } = useGetEndereco({ cepField: 'beneficiario_cep' });

	const { input: inputNome } = useInput({ source: 'beneficiario_nome' });
	const { input: inputTipoDocumento } = useInput({
		source: 'beneficiario_tipo_documento',
	});
	const { input: inputDocumento } = useInput({
		source: 'beneficiario_documento',
	});
	const { input: inputCep } = useInput({ source: 'beneficiario_cep' });
	const { input: inputTipoLogradouro } = useInput({
		source: 'beneficiario_tipo_logradouro',
	});
	const { input: inputEndereco } = useInput({
		source: 'beneficiario_endereco',
	});
	const { input: inputBairro } = useInput({ source: 'beneficiario_bairro' });
	const { input: inputNumero } = useInput({ source: 'beneficiario_numero' });
	const { input: inputCidade } = useInput({ source: 'beneficiario_cidade' });
	const { input: inputUF } = useInput({ source: 'beneficiario_uf' });
	const { input: inputComplementoEndereco } = useInput({
		source: 'beneficiario_complemento_endereco',
	});

	const updateEnderecoFields = () => {
		if (!['BCD', 'BCA'].includes(beneficiario?.id ?? beneficiario)) return;

		const beneficiarioRecord =
			(beneficiario?.id ?? beneficiario) === 'BCA' ? dadosAdministradora : condominioRecord;
		if (inputNome?.name)
			inputNome.onChange(beneficiarioRecord?.nome || beneficiarioRecord?.razao_administradora || '');
		if (inputTipoDocumento?.name) inputTipoDocumento.onChange(beneficiarioRecord?.tipo_administradora ?? 'J');
		const beneficiarioDocumento = beneficiarioRecord?.cnpj || beneficiarioRecord?.documento || '';
		if (inputDocumento?.name && inputDocumento?.value !== beneficiarioDocumento)
			inputDocumento.onChange(beneficiarioDocumento);
		if (inputCep?.name) inputCep.onChange(beneficiarioRecord?.cep || '');
		if (inputTipoLogradouro?.name) inputTipoLogradouro.onChange(beneficiarioRecord?.tipo_logradouro);
		if (inputEndereco?.name) inputEndereco.onChange(beneficiarioRecord?.endereco);
		if (inputBairro?.name) inputBairro.onChange(beneficiarioRecord?.bairro);
		if (inputNumero?.name) inputNumero.onChange(beneficiarioRecord?.numero);
		if (inputCidade?.name) inputCidade.onChange(beneficiarioRecord?.cidade);
		if (inputUF?.name) inputUF.onChange(beneficiarioRecord?.uf || null);
		if (inputComplementoEndereco?.name) inputComplementoEndereco.onChange(beneficiarioRecord?.complemento_endereco);
	};
	useEffect(updateEnderecoFields, [beneficiario, condominioRecord, dadosAdministradora]);

	const disabled = isInativo || ['BCD', 'BCA'].includes(beneficiario?.id ?? beneficiario);

	return [
		<Box flexDirection='row' display='flex' key='row2'>
			<BoxComMargemDireita>
				<TextInput
					source='beneficiario_nome'
					label='Nome do beneficiário'
					variant='outlined'
					disabled={disabled}
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
			<BoxComMargemDireita>
				<TipoInput
					variant='outlined'
					sourceTipo='beneficiario_tipo_documento'
					label='Tipo do Documento'
					disabled={disabled}
					defaultValue={cobranca_config?.beneficiario_tipo_documento || 'F'}
					fullWidth
				/>
			</BoxComMargemDireita>
			<InputDocumento disabled={disabled} />
		</Box>,
		<Box flexDirection='row' display='flex' key='row3'>
			<BoxComMargemDireita>
				<InputCep
					sourceCep='beneficiario_cep'
					disabled={disabled}
					variant='outlined'
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
			<BoxComMargemDireita>
				<VeryHighLimitReferenceInput
					source='beneficiario_tipo_logradouro'
					label='Tipo de Logradouro'
					reference='tipo_logradouro'
					disabled={disabled}
					allowEmpty
					variant='outlined'
					fullWidth
				>
					<GenericEnderecoInput Component={SyndikosSelect} newValue={tipoLogradouro} optionText='descricao' />
				</VeryHighLimitReferenceInput>
			</BoxComMargemDireita>
			<BoxComMargemDireita>
				<GenericEnderecoInput
					Component={TextInput}
					newValue={endereco}
					source='beneficiario_endereco'
					label='Endereço'
					disabled={disabled}
					variant='outlined'
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
		</Box>,
		<Box flexDirection='row' display='flex' key='row4'>
			<BoxComMargemDireita>
				<GenericEnderecoInput
					Component={TextInput}
					newValue={bairro}
					source='beneficiario_bairro'
					label='Bairro'
					disabled={disabled}
					variant='outlined'
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
			<BoxComMargemDireita>
				<TextInput
					source='beneficiario_numero'
					label='Número'
					disabled={disabled}
					parse={numeroParser}
					variant='outlined'
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
			<BoxComMargemDireita>
				<GenericEnderecoInput
					Component={TextInput}
					newValue={cidade}
					source='beneficiario_cidade'
					label='Cidade'
					disabled={disabled}
					variant='outlined'
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
			<BoxComMargemDireita>
				<GenericEnderecoInput
					Component={TextInput}
					newValue={uf}
					source='beneficiario_uf'
					label='UF'
					disabled={disabled}
					variant='outlined'
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
		</Box>,
		<Box flexDirection='row' display='flex' key='row5'>
			<BoxComMargemDireita>
				<TextInput
					source='beneficiario_complemento_endereco'
					label='Complemento'
					disabled={disabled}
					variant='outlined'
					helperText={false}
					fullWidth
				/>
			</BoxComMargemDireita>
		</Box>,
	];
};

export default BeneficiarioFields;
