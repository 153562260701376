import React from 'react';

import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';

import { CustomDialog, ModalContext, ModalContextProvider } from 'components/common/ModalContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ConfiguracaoDeCorrecaoMonetariaContext } from '../../../contexts';
import { Data } from '../../types';
import ListaTabelaConsultaHistorico from '../TabelaConsultaHistorico';

interface IAcoesFieldProps {
	record: Data;
}

interface IColumnProps {
	sortable: boolean;
	selectable: boolean;
	label: string;
}

const AcoesFieldBase: React.FC<IAcoesFieldProps> = ({ record }) => (
	<ModalContextProvider>
		<BotaoEditarCorrecaoMonetaria {...{ record }} />
		<BotaoAbrirConsultaHistorico {...{ record }} />
		<CustomDialog />
	</ModalContextProvider>
);

const BotaoEditarCorrecaoMonetaria: React.FC<IAcoesFieldProps> = ({ record }) => {
	const { handleSetRedirectContext } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);
	const handleClick = () => {
		handleSetRedirectContext('edit', record.id);
	};
	return (
		<TooltipIconButton id='botao-editar-correcao-monetaria' size='small' title='Editar' onClick={handleClick}>
			<EditIcon />
		</TooltipIconButton>
	);
};

const BotaoAbrirConsultaHistorico: React.FC<IAcoesFieldProps> = ({ record }) => {
	const { setModalValue } = React.useContext(ModalContext);
	const handleClick = () => {
		setModalValue({
			open: true,
			dialogBody: <ListaTabelaConsultaHistorico record={record} />,
		});
	};
	return (
		<TooltipIconButton
			id='botao-consulta-correcao-monetaria'
			size='small'
			title='Consultar Histórico'
			onClick={handleClick}
		>
			<SearchIcon />
		</TooltipIconButton>
	);
};

const AcoesField = AcoesFieldBase as React.FC<Partial<IColumnProps> | IAcoesFieldProps>;

export default AcoesField;
