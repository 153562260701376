import React from 'react';

import { FieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { FinalFormCurrencyInput } from '../../../../common/CurrencyInput';

const FaixasLeitura = ({ faixas_leitura, nao_cobrar_taxa, acesso_app, disabled }) => {
	const {
		change,
		mutators: { push, pop, update },
	} = useForm();
	return (
		<>
			<Typography>Configure as Faixas</Typography>
			<FieldArray name='faixas_leitura'>
				{({ fields }) =>
					fields.value.map((faixa, index) => (
						<div key={`faixa_${index}`} style={{ 'margin-top': '1%' }}>
							<FinalFormCurrencyInput
								name={`faixas_leitura[${index}].de`}
								id={`faixa_de_${index}`}
								value={faixa.de}
								label='De'
								disabled={true}
								decimalScale={6}
								style={{
									'margin-right': '0.5%',
									'max-width': '24.5%',
									marginTop: '8px',
								}}
								margin='dense'
							/>
							<FinalFormCurrencyInput
								name={`faixas_leitura[${index}].ate`}
								id={`faixa_ate_${index}`}
								value={faixa.ate}
								label='Até'
								validate={[
									(value) =>
										value <= 999999999.999999
											? undefined
											: 'O valor de Até não deve ser igual ou maior que cem milhões',
								]}
								onChange={(event) => {
									const nextFaixa = fields.value[index + 1];
									if (!event.target.value || event.target.value <= faixa.de)
										event.target.value = parseFloat((faixa.de + 0.000001).toFixed(6));
									const value = event.target.value;
									const valorDe = parseFloat((value + 0.000001).toFixed(6));
									if (nextFaixa) {
										update('faixas_leitura', index + 1, {
											...nextFaixa,
											de: valorDe,
										});
									}
								}}
								disabled={disabled}
								decimalScale={6}
								style={{
									'margin-right': '0.5%',
									'margin-left': '0.5%',
									'max-width': '24%',
									marginTop: '8px',
								}}
								margin='dense'
							/>
							<FinalFormCurrencyInput
								name={`faixas_leitura[${index}].valor`}
								id={`faixa_valor_${index}`}
								value={faixa.valor}
								disabled={disabled}
								label='Valor'
								prefix='R$'
								validate={[
									(value) => (value >= 0 ? undefined : 'Valores negativos não são permitidos'),
								]}
								onChange={(event) => {
									if (!event.target.value) event.target.value = 0;
									if (event.target.value > 999999999.999999) event.target.value = 999999999.999999;
								}}
								decimalScale={6}
								style={{
									'margin-right': '0.5%',
									'margin-left': '0.5%',
									'max-width': '24%',
									marginTop: '8px',
								}}
								margin='dense'
							/>
							<FinalFormCurrencyInput
								name={`faixas_leitura[${index}].taxa_fixa_adicional`}
								id={`faixa_taxa_fixa_adicional_${index}`}
								value={faixa.taxa_fixa_adicional}
								label='Taxa fixa adicional'
								prefix='R$'
								validate={[
									(value) => (value >= 0 ? undefined : 'Valores negativos não são permitidos'),
								]}
								disabled={disabled}
								onChange={(event) => {
									if (!event.target.value) event.target.value = 0;
									if (event.target.value > 999999999.999999) event.target.value = 999999999.999999;
								}}
								decimalScale={6}
								style={{
									'margin-left': '0.5%',
									'max-width': '24.5%',
									marginTop: '8px',
								}}
								margin='dense'
							/>
						</div>
					))
				}
			</FieldArray>

			<Box>
				{!disabled && (
					<Button
						onClick={() => {
							const lastValorAte = faixas_leitura[faixas_leitura.length - 1].ate || 0.000001;
							push('faixas_leitura', {
								de: parseFloat((lastValorAte + 0.000001).toFixed(6)),
								ate: parseFloat((lastValorAte + 0.000002).toFixed(6)),
								valor: 0,
								taxa_fixa_adicional: 0,
							});
						}}
					>
						Adicionar Nova Faixa
					</Button>
				)}

				{!disabled && (
					<Button
						disabled={!(faixas_leitura && faixas_leitura.length > 1)}
						onClick={() => {
							if (faixas_leitura && faixas_leitura.length > 1) pop('faixas_leitura');
						}}
					>
						Remover Última Faixa
					</Button>
				)}
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<FormControlLabel
					control={
						<Switch
							name='nao_cobrar_taxa'
							color='primary'
							checked={nao_cobrar_taxa}
							onChange={(e) => {
								change('nao_cobrar_taxa', e.target.checked);
							}}
							disabled={disabled}
						/>
					}
					label='Não cobrar taxa fixa adicional quando não houver consumo'
					labelPlacement='end'
				/>
				<FormControlLabel
					control={
						<Switch
							name='acesso_app'
							color='primary'
							checked={acesso_app}
							onChange={(e) => {
								change('acesso_app', e.target.checked);
							}}
							disabled={disabled}
						/>
					}
					label='Disponibilizar leitura no Aplicativo'
					labelPlacement='end'
				/>
			</Box>
		</>
	);
};

export default FaixasLeitura;
