import React from 'react';

import EditIcon from '@material-ui/icons/Edit';
import { Autocomplete } from '@material-ui/lab';
import { Box, InputAdornment, LinearProgress, TextField } from '@material-ui/core';

import { ModalContext } from 'components/common/ModalContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { TitulosContext } from './TituloModalContextProvider';
import { ITituloRelatorio, ITituloSelectProps } from '../types';
import ModalTituloSelect from '../modais/ModalTituloSelect';

const TituloSelect: React.FC<ITituloSelectProps> = (props) => {
	const { setModalValue } = React.useContext(ModalContext);
	const { options, selected, setSelected, loading } = React.useContext(TitulosContext);

	if (loading)
		return (
			<Box mb={5}>
				<LinearProgress />
			</Box>
		);

	return (
		<Autocomplete
			value={selected}
			options={options}
			noOptionsText={'Nenhum título disponível'}
			renderInput={(params) => (
				<TextField
					{...params}
					margin='dense'
					fullWidth
					label={props.label}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<InputAdornment
								position='end'
								style={{
									right: '9px',
									position: 'absolute',
									marginRight: '-2px',
								}}
							>
								<TooltipIconButton
									title='Editar títulos'
									size='small'
									onClick={() => {
										setModalValue((v: any) => ({
											...v,
											open: true,
											dialogBody: <ModalTituloSelect />,
										}));
									}}
								>
									<EditIcon />
								</TooltipIconButton>
							</InputAdornment>
						),
					}}
				/>
			)}
			renderOption={(option: ITituloRelatorio) => <span>{option.descricao}</span>}
			getOptionSelected={(option, value) => option.id === value.id}
			clearText='Limpar'
			getOptionLabel={(option) => option.descricao}
			onChange={(_, v) => {
				setSelected(v);
			}}
		/>
	);
};

export default TituloSelect;
