import React from 'react';
import { BoxConfigNomePasta } from '../modals/Modais';
import { ProviderValueContext } from './type';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { CancelButton, SaveButton } from 'components/common/commonComponentsTSX';
import { CustomDataProviderDocuments } from '../types';
import { useDataProvider, useNotify } from 'react-admin';
import { Box } from '@material-ui/core';
import { RequestDataEditFolder } from '../types';
import { DocumentsContext } from '../contexts/DocumentsContext';
import { CondominiosContext } from 'context/CondominioContextProvider';

const DocumentsFolderEdit: React.FC<{ idFolder: number }> = ({ idFolder }) => {
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const [folderName, setFolderName] = React.useState('');
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const { handleCollectFolders, setFolderName: setFilterFolderName } = React.useContext(DocumentsContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const notify = useNotify();
	const folderRequest: RequestDataEditFolder = {
		nome: folderName,
	};

	const handleSubmitEditFolder = (e: React.FormEvent) => {
		e.preventDefault();
		dp.update('documento_pasta', {
			id: idFolder,
			data: folderRequest,
		})
			.then(() => {
				notify('Nome da pasta alterado com sucesso!', {
					type: 'success',
				});
				setModalValue((v) => ({ ...v, open: false }));
				setFilterFolderName('');
				return handleCollectFolders({
					filter: { condominio: condominioSelecionado?.id },
					sort: { order: 'ASC', field: 'nome' },
					pagination: {
						perPage: 10000,
						page: 1,
					},
				});
			})
			.catch((e) => {
				if ([400].includes(e?.response?.status)) {
					return notify(e?.response?.data.error, { type: 'error' });
				}
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao criar  pasta!', { type: 'error' });
			});
	};
	return (
		<CustomDialogBody
			title={'Renomear'}
			customActions={
				<>
					<CancelButton onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
					<SaveButton disabled={!folderName} onClick={handleSubmitEditFolder}>
						Salvar
					</SaveButton>
				</>
			}
			form={{
				component: (
					<Box
						style={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						<BoxConfigNomePasta
							title='Qual o novo nome da pasta?'
							setFolderName={setFolderName}
							value={folderName}
						/>
					</Box>
				),
			}}
		/>
	);
};

export default DocumentsFolderEdit;
