import React from 'react';
import { Edit, useNotify, useRedirect, useRefresh } from 'react-admin';

import AssembleiaForm from './form';
import { AssembleiaProps, AssembleiaResponse } from './types';

export const AssembleiaEdit: React.FC<AssembleiaProps> = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const [visualizar, setVisualizar] = React.useState(false);
	const labelTitle = visualizar ? 'Visualizar' : 'Editar';

	const onSuccess = (response: AssembleiaResponse) => {
		const data = response?.data;
		if (data) {
			notify('Assembleia alterada com sucesso');
			redirect('list', props.basePath);
			refresh();
		}
	};

	React.useEffect(() => {
		const urlAtual = window.location.href;
		if (urlAtual.includes('/visualizar')) {
			setVisualizar(true);
		}
	}, []);

	return (
		<Edit
			component='div'
			title={`${labelTitle} Assembleia Virtual`}
			onSuccess={onSuccess}
			mutationMode='pessimistic'
			{...props}
		>
			<AssembleiaForm {...props} visualizar={visualizar} />
		</Edit>
	);
};
