import React from 'react';

import { BoxComMargemDireita } from '../../commons/BeneficiarioFields';
import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const TipoBeneficiarioField = () => (
	<BoxComMargemDireita sx={{ 'max-width': '50%' }}>
		<EditaContaBancariaAutocomplete
			source='beneficiario'
			name='beneficiario'
			id='beneficiario'
			label='Dados do Beneficiário'
		/>
	</BoxComMargemDireita>
);

export default TipoBeneficiarioField;
