import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as Icon } from '../../layout/svg/LogoutIcon.svg';

const LogoutIcon = () => (
	<SvgIcon>
		<Icon />
	</SvgIcon>
);

export default LogoutIcon;
