import { createStyles, makeStyles } from '@material-ui/core';
import { labelSelectGestao } from 'theme/types';

const useStyles = makeStyles((theme: labelSelectGestao) =>
	createStyles({
		ToolbarDeleteList: ({ ids }: { ids: number }) => ({
			color: '#3f51b5',
			padding: '0 20px',
			backgroundColor: theme.labelSelectGestao[100],
			height: ids ? 64 : 0,
			opacity: ids ? 1 : 0,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			transition: ids
				? 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
				: '',
		}),
	})
);
export default useStyles;
