import React, { useState } from 'react';
import { TextInput, ArrayInput, required } from 'react-admin';
import { nomeParser, nomeValidacao } from '../../../fieldControl/nome';
import { validacaoEmailObrigatorio } from '../../../fieldControl/email';
import { telefoneFormatacao, telefoneParser, telefoneValidacao } from '../../../fieldControl/telefone';
import { InputDocumento } from '../../common/InputDocumento';
import { Typography, Box } from '@material-ui/core';
import { BoxDivisor, FormularioBox } from '../../common/Formulario';
import { InputDate } from '../../common/InputDate';
import { EnderecoBox } from '../../common/Endereco';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import { Tab } from '@material-ui/core';
import { Field } from 'react-final-form';
import { CustomRemoveButton } from '../../common/buttons/BotaoRemover';
import {
	AdvogadoFormTextInputProps,
	AdvogadoFormularioProps,
	AdvogadoFormularioSyndikosSelect,
	AdvogadoFormularioTabPanel,
	BoxObservacoesProps,
} from './types';
import { CustomFormIterator } from 'components/common/CustomFormIterator';

const opcoesTipoTelefone = [
	{ id: 'M', name: 'Celular' },
	{ id: 'R', name: 'Residencial' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'P', name: 'Principal' },
	{ id: 'O', name: 'Outros' },
];

const opcoesTipoEmail = [
	{ id: 'P', name: 'Pessoal' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'CB', name: 'Cobrança' },
	{ id: 'O', name: 'Outros' },
];

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const TextInputV2 = TextInput as unknown as React.FC<AdvogadoFormTextInputProps>;

const BoxObservacoes = (props: BoxObservacoesProps) => {
	return (
		<BoxDivisor titulo='Observação'>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<Field name='observacao' id='observacao' disabled={props.record?.situacao === 'I'}>
						{({ input, meta }) => (
							<TextInputV2
								source='observacao'
								label='Anotações'
								placeholder='Use este espaço para anotações referentes a este(a) advogado(a)'
								variant='outlined'
								validate={(value: string) =>
									value ? (value.length > 500 ? `Máximo excedido: ${value.length}/500` : '') : ''
								}
								helperText={meta?.error ? meta?.error : `${(input?.value || '').length}/500`}
								rows={8}
								multiline
								fullWidth
							/>
						)}
					</Field>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

const TabPanelV2 = TabPanel as unknown as React.FC<AdvogadoFormularioTabPanel>;
const SyndikosSelectV2 = SyndikosSelect as unknown as React.FC<AdvogadoFormularioSyndikosSelect>;

export const AdvogadoFormulario = (props: AdvogadoFormularioProps) => {
	const [aba, setAba] = useState('0');

	const handleChange = (_: any, newValue: string) => {
		setAba(newValue);
	};

	return (
		<FormularioBox
			customBackButton={undefined}
			{...props}
			noActionToolbar={undefined}
			nome={undefined}
			onClickBotaoRemover={undefined}
			dictLabels={undefined}
			getChipClassName={undefined}
			onlyExcluir={undefined}
			customSaveButton={undefined}
		>
			<Box flex={1} mr='1em'>
				<Box display='flex'>
					<Box flex={1} m='1em'>
						<Typography variant='h5' gutterBottom>
							Dados Cadastrais
						</Typography>
						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>
						<Box display='flex'>
							<Box flex={1} mr='0.5em'>
								<TextInput
									source='nome'
									validate={nomeValidacao}
									parse={nomeParser}
									disabled={props.record?.situacao === 'I'}
									variant='outlined'
									fullWidth
								/>
							</Box>
						</Box>
						<Box display='flex'>
							<Box flex={1} mr='0.5em'>
								<InputDate
									fullWidth
									source='data_nascimento'
									maxDate={new Date().setHours(10, 0, 0)}
									maxDateMessage='Só é possível cadastrar pessoas já nascidas'
									disableToolbar={false}
									parse={(value: any) => {
										try {
											return value.toISOString().substr(0, 10);
										} catch {
											return value;
										}
									}}
									validate={(value: any) => {
										if (value && typeof value !== 'string') {
											try {
												value.toISOString();
											} catch {
												return ['Data inválida'];
											}
										}
									}}
									style={{ marginBottom: 27 }}
								/>
								<TextInput
									source='numero_oab'
									label='Número OAB'
									disabled={props.record?.situacao === 'I'}
									variant='outlined'
									fullWidth
								/>
							</Box>
							<Box flex={1} mr='0.5em'>
								<InputDocumento
									resource='advogados'
									source='documento'
									sourceTipo='tipo_pessoa'
									disabled={props.record?.situacao === 'I'}
									variant='outlined'
									fullWidth
									className={undefined}
									customQueryValidation={undefined}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box mt='1em' />
				<Box m='1em'>
					<EnderecoBox labelTitle='Dados de Endereçamento' {...props} />
				</Box>
			</Box>
			<Box flex={1}>
				<Box display='flex'>
					<Box flex={1} m='1em'>
						<Typography variant='h5' gutterBottom>
							Contato
						</Typography>

						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>
						<Box display='flex'>
							<Box flex={0.5} ml='1em'>
								<ArrayInput source='telefoneadvogado_set' label='Telefones' variant='outlined'>
									<CustomFormIterator removeButton={<CustomRemoveButton />}>
										<SyndikosSelectV2
											source='tipo'
											label='Tipo de Telefone'
											validate={required()}
											choices={opcoesTipoTelefone}
											disabled={props.record?.situacao === 'I'}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='numero'
											format={telefoneFormatacao}
											parse={telefoneParser}
											validate={[required(), telefoneValidacao]}
											label='Número'
											disabled={props.record?.situacao === 'I'}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
							</Box>
							<Box flex={0.5} ml='1em'>
								<ArrayInput source='emailadvogado_set' label='Emails adicionais' variant='outlined'>
									<CustomFormIterator removeButton={<CustomRemoveButton />}>
										<SyndikosSelectV2
											source='tipo'
											label='Tipo de Email'
											choices={opcoesTipoEmail}
											validate={required()}
											disabled={props.record?.situacao === 'I'}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='email'
											label='Email'
											validate={validacaoEmailObrigatorio}
											disabled={props.record?.situacao === 'I'}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
							</Box>
						</Box>
					</Box>
				</Box>
				<BoxObservacoes {...props} />
			</Box>
		</FormularioBox>
	);
};
