import React from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import { ConfigRelatorioContext, configRelatorioCheckboxes } from '../ConfigRelatorioContextProvider';

import { ModalCreateNewTemplateConfig } from '../modal/ModalCreateNewTemplate';

import { useDataProvider, useNotify } from 'react-admin';

export const SelectTemplateConfiguracao = () => {
	const {
		opcoesImpressao,
		templates,
		loading,
		setLoading,
		setOpcoesImpressao,
		fetchConfigs,
		setTamanhoFonte,
		setCondominiosDestinados,
		condominios,
		openCreateNewTemplate,
		setOpenCreateNewTemplate,
	} = React.useContext(ConfigRelatorioContext);
	const [nomeTemplate, setNomeTemplate] = React.useState('');
	const notify = useNotify();
	const dp = useDataProvider();

	function updateScreen(action) {
		setLoading(false);
		setOpenCreateNewTemplate(false);
		setNomeTemplate('');
		fetchConfigs();
		return notify(`Modelo de configuração ${action} com sucesso!`, 'success');
	}

	function handleSaveNewTemplateConfig() {
		setLoading(true);

		delete configRelatorioCheckboxes.id;
		delete configRelatorioCheckboxes.template_padrao;

		const newTemplate = {
			...configRelatorioCheckboxes,
			nome: nomeTemplate,
		};

		dp.create('template_relatorios', {
			data: newTemplate,
		})
			.then(() => updateScreen('criado'))
			.catch((e) => notify(e, 'warning'))
			.finally(() => setLoading(false));
	}

	function verifyCondominiosDestinados(value) {
		if (!value || !condominios.length) return setCondominiosDestinados([]);
		const filteredCondominios = condominios.filter((cond) => value.condominios.includes(cond.id));
		setCondominiosDestinados(filteredCondominios);
	}

	function verifyTamanhoFonte(value) {
		if (!value) return setTamanhoFonte(null);
		const validateTamanhoFonte = value.tamanho_fonte;
		if (validateTamanhoFonte) setTamanhoFonte(validateTamanhoFonte);
	}

	function handleChangeTemplate({ target: { value } }) {
		setOpcoesImpressao(value);
		verifyTamanhoFonte(value);
		// verifyCondominiosDestinados(value);
	}

	return (
		<>
			<TextField
				id='select-config-template'
				size='small'
				select
				label={loading ? 'Carregando...' : 'Selecionar modelo de configuração personalizado:'}
				value={opcoesImpressao?.nome}
				onChange={handleChangeTemplate}
				variant='outlined'
				fullWidth
			>
				<MenuItem key='menu-item-select-config-template-create' onClick={() => setOpenCreateNewTemplate(true)}>
					Criar novo modelo de configuração
				</MenuItem>
				{templates.map((option) => (
					<MenuItem key={`menu-item-select-config-template-${option.id}`} value={option}>
						{option.nome}
					</MenuItem>
				))}
			</TextField>
			{openCreateNewTemplate && (
				<ModalCreateNewTemplateConfig
					{...{
						openCreateNewTemplate,
						setOpenCreateNewTemplate,
						nomeTemplate,
						setNomeTemplate,
						loading,
						handleSaveNewTemplateConfig,
					}}
				/>
			)}
		</>
	);
};
