import React from 'react';

import { useDataProvider } from 'react-admin';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../../../ArrecadacoesContext';
import { RemessasContext } from '../../../contextos';

import { ModalConfiguracaoImpressaoBancosAutomaticos, DataItem } from '../../../modais';
import ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico from '../ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico';

import { DadosPorInstituicaoProps } from '../types';
import { IModalValue } from 'types/tpyesGlobal';

const useDadosPorInstituicaoTipoIntegracaoInterAutomatico = ({ setLoadingRequest }: DadosPorInstituicaoProps) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const dP = useDataProvider();
	const { referencia, id_identificacao_arrecadacao } = React.useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados } = React.useContext(RemessasContext);

	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const condominioSelecionadoId = condominioSelecionado?.id;

	const onClick = React.useCallback(() => {
		setLoadingRequest(true);
		dP.getList('contas_receber', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'nome_unidade', order: 'ASC' },
			filter: {
				id__in: idsRegistrosSelecionados,
				condominio: condominioSelecionadoId,
				referencia: referencia,
				identificacao: id_identificacao_arrecadacao,
				possui_nosso_numero: true,
			},
		})
			.then(async (res) => {
				if (res?.data) {
					res.data = res.data.filter((v) => v.id !== 0);
					if (res.data?.length) {
						return setModalValue((v: IModalValue) => ({
							...v,
							open: true,
							dialogBody: (
								<ModalConfiguracaoImpressaoBancosAutomaticos
									nomeBanco={'Inter'}
									data={res?.data as DataItem[]}
								/>
							),
						}));
					}
					setModalValue((v: IModalValue) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalConfiguracaoImpressaoBancosAutomaticos nomeBanco={'Inter'} data={undefined} />
						),
					}));
				}
			})
			.catch((e) => {
				setLoadingRequest(false);
				return Promise.reject(e);
			})
			.finally(() => setLoadingRequest(false));
	}, [
		dP,
		setModalValue,
		condominioSelecionadoId,
		referencia,
		id_identificacao_arrecadacao,
		idsRegistrosSelecionados,
		setLoadingRequest,
	]);

	return React.useMemo(
		() => ({
			Label: ButtonConfigurarRemessasBoletosLabelGerencianetAutomatico,
			onClick,
		}),
		[onClick]
	);
};

export default useDadosPorInstituicaoTipoIntegracaoInterAutomatico;
