import lodashMemoize from 'lodash/memoize';
const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

export const telefoneValidacao = memoize((value) => {
	if (value) {
		if (value.length < 10) {
			return ['Telefone inválido'];
		}
	}
});

export const telefoneFormatacao = memoize((value) => {
	if (!value) return null;
	value = value.replace(/[^\d]/g, '');
	if (value.length > 6) {
		return `(${value.substring(0, 2)}) ${
			value.length > 10
				? `${value.substring(2, 7)}-${value.substring(7, 11)}`
				: `${value.substring(2, 6)}-${value.substring(6, 10)}`
		}`;
	}
	return value;
});

export const telefoneParser = memoize((value) => {
	if (value) {
		return value.replace(/[^\d]/g, '').substring(0, 11);
	}
});

export const numeroSemDDDValidacao = (value) => {
	if (value) {
		if (value.length < 8) {
			return ['Telefone inválido'];
		}
	}
};

export const numeroSemDDDFormatacao = (value) => {
	if (!value) return null;
	value = value.replace(/[^\d]/g, '');
	if (value.length > 6) {
		return `${
			value.length > 8
				? `${value.substring(0, 5)}-${value.substring(5, 9)}`
				: `${value.substring(0, 4)}-${value.substring(4, 8)}`
		}`;
	}
	return value;
};

export const numeroSemDDDParser = (value) => {
	if (value) {
		return value.replace(/[^\d]/g, '').substring(0, 9);
	}
};
