import React from 'react';

import { Box } from '@material-ui/core';

import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const DistribuicaoBoletoField = () => (
	<Box pl={1} flex={1} sx={{ 'max-width': '17%' }}>
		<EditaContaBancariaAutocomplete
			source='distribuicao_boleto'
			name='distribuicao_boleto'
			id='distribuicao_boleto'
			label='Distribuição do Boleto'
			translateChoice={false}
		/>
	</Box>
);

export default DistribuicaoBoletoField;
