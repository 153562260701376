export const parseInitials = (data) => {
	switch (data) {
		case 'O':
			return 'Ordinária';
		case 'E':
			return 'Extraordinária';
		case 'T':
			return 'Título';
		case 'A':
			return 'Analítica';
		case 'L':
			return 'Leitura';
		case 'F':
			return 'Fundo';
		default:
			return data;
	}
};
