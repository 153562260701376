export const dataToTreeV1 = (data) => {
	let list = data.sort((a, b) => {
		if (a.tree_id < b.tree_id) return -1;
		if (a.tree_id > b.tree_id) return 1;
		return 0;
	});
	list = list.sort((a, b) => {
		if (a.lft < b.lft) return -1;
		if (a.lft > b.lft) return 1;
		return 0;
	});
	let map = {},
		node,
		roots = [],
		i;

	for (i = 0; i < list.length; i += 1) {
		map[list[i].id] = i;
		list[i].children = new Array(0);
		list[i].title = list[i].nome;
		const { criado, descricao_boleto, lft, rght, tree_id, ...obj } = list[i];
		list[i] = obj;
	}

	for (i = 0; i < list.length; i += 1) {
		node = list[i];
		if (node.id_pai && list[map[node.id_pai]]) {
			list[map[node.id_pai]].children.push(node);
		} else {
			roots.push(node);
		}
	}
	return roots;
};

export const dataToTree = (data) => {
	let list = data.sort((a, b) => {
		if (a.tree_id < b.tree_id) return -1;
		if (a.tree_id > b.tree_id) return 1;
		return 0;
	});
	list = list.sort((a, b) => {
		if (a.lft < b.lft) return -1;
		if (a.lft > b.lft) return 1;
		return 0;
	});

	let map = {};
	list.map((conta) => {
		map[conta.id] = conta;
		conta.children = [];
		conta.title = conta.nome;
		const { criado, descricao_boleto, lft, rght, tree_id, ...obj } = conta;
		return obj;
	});

	return list.reduce((roots, node) => {
		const nodePai = map[node.id_pai];
		if (nodePai) {
			nodePai.children.push(node);
			return roots;
		}
		roots.push(node);
		return roots;
	}, []);
};
