import React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Tooltip, IconButton, Button, ButtonProps } from '@material-ui/core';
interface LicencaIconProps {
	url: string;
}

interface OutlinedLicencaIconProps extends ButtonProps {
	url: string;
}

const LicencaIcon: React.FC<LicencaIconProps> = ({ url }) => {
	return (
		<>
			<Tooltip title={'Contratar mais licenças'}>
				<IconButton onClick={() => window.open(url)}>
					<ShoppingCartIcon style={{ fill: '#FFFFFF' }} />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default LicencaIcon;

export const OutlinedLicencaIcon: React.FC<OutlinedLicencaIconProps> = ({ url, ...props }) => {
	return (
		<>
			<Button color='primary' variant='contained' onClick={() => window.open(url)} {...props}>
				{'Contratar Mais Licenças'}
			</Button>
		</>
	);
};
