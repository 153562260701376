import React, { useState, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import 'react-sortable-tree/style.css';

import './treeTableStyle.css';

import { TreeTableContext } from './contexts/TreeTableContext';
import { ModalContext } from '../../common/ModalContext';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import { parseInitials } from './helpers/parseInitials';
import { VinculoForm, EditForm, DeleteForm, CreateChildForm } from './modais';

export const ContasTable = () => {
	const { value, setValue } = useContext(TreeTableContext);

	return (
		value.treeData.length > 0 && (
			<TableContainer
				style={{
					overflowY: 'auto',
					height: '100%',
					maxHeight: '100%',
					width: '100%',
				}}
			>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell style={{ background: '#fff' }}>Nome da conta</TableCell>
							<TableCell style={{ background: '#fff' }}>Tipo</TableCell>
							{value.endpoint === 'contas_despesa' && (
								<TableCell style={{ background: 'red' }}>Conta de Receita</TableCell>
							)}
							<TableCell
								style={{
									background: '#fff',
								}}
							>
								<span
									style={{
										display: 'flex',
										width: '100%',
										justifyContent: 'flex-end',
									}}
								>
									<TooltipIconButton
										title={value.tableExpanded ? 'Diminuir' : 'Expandir'}
										onClick={() =>
											setValue({
												...value,
												tableExpanded: !value.tableExpanded,
											})
										}
									>
										<AspectRatioIcon />
									</TooltipIconButton>
								</span>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{value.treeData.length > 0 && value.treeData[0].id !== 0 ? (
							<RowsRenderer key={'root' + value.endpoint + value.id} treeData={value.treeData} />
						) : (
							<tr />
						)}
					</TableBody>
				</Table>
			</TableContainer>
		)
	);
};

export const RowsRenderer = ({ treeData, contaReceita }) => {
	const { value, setValue } = useContext(TreeTableContext);

	const getNextContaReceita = (row) => {
		if (value.endpoint === 'contas_despesa' && row && row.conta_receita) {
			return { nome: row.conta_receita_nome, id: row.conta_receita };
		}
		if (contaReceita) {
			return contaReceita;
		}
		return { nome: '', id: null };
	};

	const handleExpand = (row) => {
		if (row.children.length > 0) {
			setValue({
				...value,
				openChildren: {
					...value.openChildren,
					[row.id]: !value.openChildren[row.id],
				},
			});
		}
	};

	return treeData.map((row) => (
		<React.Fragment key={row.id}>
			<TableRow hover style={{ overflow: 'hidden' }}>
				<TableCell
					style={{
						paddingLeft: row.level * 20 + 36,
						position: 'relative',
					}}
				>
					{row.children.length > 0 ? (
						<IconButton
							className='tree-icon-table'
							onClick={() => handleExpand(row)}
							style={{
								padding: 10,
								position: 'absolute',
								left: row.level * 16 + 5,
								top: 'calc(50% - 20px)',
							}}
						>
							<ArrowForwardIosIcon
								style={{
									height: 18,
									width: 18,
									transition: 'all 200ms !important',
									transform: value.openChildren[row.id] ? 'rotate(90deg)' : 'rotate(0deg)',
								}}
							/>
						</IconButton>
					) : (
						<></>
					)}
					<span
						style={{
							fontWeight: row.classe_conta === 'T' ? 'bold' : 'normal',
							textTransform: 'none',
						}}
					>
						{row.nome}
					</span>
				</TableCell>
				<TableCell style={{ textTransform: 'none' }}>{!row.id_pai && parseInitials(row.tipo)}</TableCell>
				{value.endpoint === 'contas_despesa' && (
					<TableCell style={{ position: 'relative', padding: '5px 16px' }}>
						<VinculoCellRenderer row={row} contaReceita={contaReceita || getNextContaReceita(row)} />
					</TableCell>
				)}
				<TableCell style={{ position: 'relative', padding: '5px 16px' }}>
					<span
						style={{
							display: 'flex',
							width: '100%',
							justifyContent: 'flex-end',
						}}
					>
						<ActionsCellRenderer row={row} />
					</span>
				</TableCell>
			</TableRow>
			{row.children.length > 0 && value.openChildren[row.id] ? (
				<RowsRenderer
					key={value.endpoint + row.id + 'children'}
					treeData={row.children}
					contaReceita={getNextContaReceita(row)}
				/>
			) : (
				<></>
			)}
		</React.Fragment>
	));
};

export const VinculoCellRenderer = ({ row, contaReceita }) => {
	const { setModalValue } = useContext(ModalContext);

	return (
		<Tooltip title={(contaReceita && contaReceita.nome) || row.conta_receita ? 'Alterar vínculo' : 'Vincular'}>
			<Button
				color={row.conta_receita ? 'primary' : 'default'}
				size='small'
				variant='outlined'
				startIcon={<LinkIcon />}
				style={{
					maxHeight: '90%',
					textTransform: 'unset',
				}}
				onClick={() => {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <VinculoForm conta={row} contaVinculo={contaReceita} />,
					}));
				}}
			>
				{row.conta_receita_nome || (contaReceita && contaReceita.nome) ? (
					<Typography variant='body2' noWrap style={{ textTransform: 'none' }}>
						{row.conta_receita_nome || (contaReceita && contaReceita.nome)}
					</Typography>
				) : (
					<span style={{ textTransform: 'none' }}>Vincular</span>
				)}
			</Button>
		</Tooltip>
	);
};

export const ActionsCellRenderer = ({ row }) => {
	const { value } = useContext(TreeTableContext);
	const { setModalValue } = useContext(ModalContext);
	const [settingsOpen, setSettingsOpen] = useState(false);

	const handleClick = (FormComponent) => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <FormComponent conta={row} value={value} />,
		}));
		setSettingsOpen(false);
	};

	const Actions = () => (
		<>
			<TooltipIconButton title='Editar' onClick={() => handleClick(EditForm)}>
				<EditIcon />
			</TooltipIconButton>
			<TooltipIconButton title='Remover' onClick={() => handleClick(DeleteForm)}>
				<DeleteIcon />
			</TooltipIconButton>
			<TooltipIconButton
				disabled={row.classe_conta === 'A'}
				title='Cadastrar nova(s) conta(s)'
				onClick={() => handleClick(CreateChildForm)}
			>
				<AddIcon />
			</TooltipIconButton>
		</>
	);

	return (
		<div onMouseLeave={() => setSettingsOpen(false)}>
			{value.tableExpanded ? (
				<Actions />
			) : (
				<span style={{ position: 'relative' }}>
					<IconButton onMouseEnter={() => setSettingsOpen(true)}>
						<SettingsIcon />
					</IconButton>
					<Paper
						elevation={3}
						style={{
							display: settingsOpen ? 'flex' : 'none',
							justifyContent: 'center',
							alignItems: 'center',
							width: 150,
							position: 'absolute',
							right: 0,
							top: '-18px',
							borderRadius: 10,
							zIndex: 3,
							padding: 2,
						}}
					>
						<Actions onClick={() => setSettingsOpen(false)} />
					</Paper>
				</span>
			)}
		</div>
	);
};
