import React, { createContext } from 'react';
import { FuncionarioContextValue, FuncionarioProviderProps } from './type';
import { TFuncionario, TFuncionarioBackEnd } from '../types';
import { CustomDataProvider } from 'types/tpyesGlobal';
import { useDataProvider } from 'react-admin';

export const FuncionarioContext = createContext<FuncionarioContextValue>({
	funcionario: {} as TFuncionario,
	setFuncionario: () => {},
	funcionarios: [],
	setFuncionarios: () => {},
	condominioId: null,
});

export const FuncionarioProvider: React.FC<FuncionarioProviderProps> = React.memo(({ condominioRecord, children }) => {
	const dp = useDataProvider() as CustomDataProvider;
	const [funcionario, setFuncionario] = React.useState<TFuncionario>({} as TFuncionario);
	const [funcionarios, setFuncionarios] = React.useState<TFuncionario[]>([]);

	const condominioId = condominioRecord?.id as number;

	React.useEffect(() => {
		if (!condominioId) return;
		dp.getSimple('funcionarios', {
			pagination: { page: 1, perPage: 100 },
			sort: { field: 'id', order: 'ASC' },
			filter: {
				condominio: condominioId,
			},
		}).then((response) => {
			if (!response?.data?.results) setFuncionarios([]);
			setFuncionarios(
				response.data.results.map((funcionario: TFuncionarioBackEnd) => ({
					dataEntrada: funcionario.data_entrada,
					dataSaida: funcionario.data_saida ? funcionario.data_saida : '',
					funcao: funcionario.funcao,
					id: funcionario.id,
					pessoa: funcionario.pessoa,
					acessoApp: funcionario.acesso_app,
				}))
			);
		});
	}, [condominioId]);

	const providerValue = React.useMemo(
		() => ({
			funcionario,
			setFuncionario,
			funcionarios,
			setFuncionarios,
			condominioId,
		}),
		[funcionario, setFuncionario, funcionarios, setFuncionarios]
	);

	return <FuncionarioContext.Provider value={providerValue}>{children}</FuncionarioContext.Provider>;
});
