import { defaultFormatarValuesContaCorrente } from '../../../utils/functions';
import {
	createformataPayloadValuesLinhas,
	formataPayloadValuesBeneficiario,
	formataPayloadValuesDesconto,
	formataPayloadValuesIdContaBancaria,
	formataPayloadValuesTabEmissaoBoletos,
	formataPayloadValuesTabInformacao,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesProtesto,
	formataPayloadValuesInstrucoesComTipoValorDias,
} from '../../default/ContaCorrenteProps/getFormataPayloadValues';

import { ValuesProps } from '../../../types';

const formataPayloadValuesTabContaCobrancaConfig = ({
	values: { especie_titulo, conta_cedente, conta_corrente },
}: ValuesProps) => ({
	especie_titulo: especie_titulo?.id,
	conta_cedente: conta_cedente,
	conta_corrente: conta_corrente,
});

const formataPayloadValuesMulta = (props: ValuesProps) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'multa',
		valorIsensao: '',
	});

const formataPayloadValuesJuros = (props: ValuesProps) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'juros',
		valorIsensao: '',
	});

const formataPayloadValuesGerarPix = ({ values: { permite_gerar_pix } }: any) => ({ permite_gerar_pix });

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }: ValuesProps) => {
	const payload = {
		...getPadraoPayloadValuesInstrucoesRemessa(),
		...formataPayloadValuesMulta({ values }),
		...formataPayloadValuesJuros({ values }),
		...formataPayloadValuesDesconto({ values }),
		...formataPayloadValuesLimitePagamento({ values }),
		...formataPayloadValuesProtesto({ values }),
	};
	if (!payload.desconto_dias) payload.desconto_dias = undefined;
	return payload;
};

const formatadoresPayloadValues = {
	// Dados de Tab Informação
	tab_informacao: formataPayloadValuesTabInformacao,

	// Dados de Tab Emissão Boletos
	tab_emissao_boletos: formataPayloadValuesTabEmissaoBoletos,

	//Dados de Tab Conta

	conta_corrente: defaultFormatarValuesContaCorrente,
	permite_gerar_pix: formataPayloadValuesGerarPix,

	cobranca_config: getFormatadorPayloadCobrancaConfig({
		formatadores: [
			formataPayloadValuesTabContaCobrancaConfig,

			// Dados Fixos
			formataPayloadValuesIdContaBancaria,

			// Dados de Tab Emissão Boletos
			formataPayloadValuesBeneficiario,

			// Dados de Tab Instruções de Cobrança Remessa
			formataPayloadValuesTabInstrucoesCobrancaRemessa,

			// Dados de Tab Instruções de Cobrança Boleto
			createformataPayloadValuesLinhas({ numeroLinhas: 5 }),
		],
	}),
};

export default formatadoresPayloadValues;
