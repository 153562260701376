import React from 'react';

import { List, TextField } from 'react-admin';

import { Data } from 'components/common/filtros/DataV2';
import { Padrao } from 'components/common/filtros/Padrao';
import { Tabela } from 'components/common/Tabela';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { CloseButton } from 'components/common/commonComponentsTSX';
import { CustomTextFieldProps, ModalVisualizadorLogsProps } from './types';
import { SelectAutocompleteFilter } from 'components/common/filtros/AutocompletarV2';

const ModalVisualizadorLogs: React.FC<ModalVisualizadorLogsProps> = (props) => {
	const montaLinhaGrupoUnidade = (log: Record<string, any>) => {
		const grupo = log.grupo ? log.grupo + ' -' : '';

		return `${grupo} Unidade ${log.unidade}`.toUpperCase();
	};

	const montaLinhaVencimento = (nome: string) => {
		const vencimentoMatch = nome.match(/Venc\. (\d{2}\/\d{2}\/\d{4})/);
		if (vencimentoMatch) {
			const vencimento = vencimentoMatch[1];
			return `Vencimento: ${vencimento}`;
		}
	};

	const montaLinhaValor = (nome: string) => {
		const valorMatch = nome.match(/Vlr: (.*)/);
		if (valorMatch) {
			const valor = valorMatch[1];
			return `Valor: ${valor}`;
		}
	};

	return (
		<Dialog
			onClick={(e) => e.stopPropagation()}
			onKeyDown={(e) => e.stopPropagation()}
			onClose={props.onClose}
			maxWidth='lg'
			open={props.open}
		>
			{props.arquivo.tipo != 'Boleto' ? (
				<Box>
					<DialogContent>
						<Typography variant='h5'>
							CONTROLE DE VISUALIZAÇÕES - {props.arquivo.condominio_nome.toUpperCase()}
						</Typography>
						<Typography variant='h6'>Relatório de {props.arquivo.nome}</Typography>
					</DialogContent>

					<List basePath='visualiza_log' resource='visualiza_log' filter={{ relatorio: props.arquivo.id }}>
						<TabelaVisualizaLog />
					</List>
					<DialogActions>
						<CloseButton onClick={props.onClose} size='small'>
							Fechar
						</CloseButton>
					</DialogActions>
				</Box>
			) : (
				<Box>
					<DialogContent>
						<Typography variant='h5'>
							CONTROLE DE VISUALIZAÇÕES - {props.arquivo.condominio_nome.toUpperCase()}
						</Typography>
						<Typography variant='h5'>{montaLinhaGrupoUnidade(props.arquivo)}</Typography>
						<Typography variant='h6'>{montaLinhaVencimento(props.arquivo.nome)}</Typography>
						<Typography variant='h6'>{montaLinhaValor(props.arquivo.nome)}</Typography>
					</DialogContent>
					<List basePath='visualiza_log' resource='visualiza_log' filter={{ boleto: props.arquivo.id }}>
						<TabelaVisualizaLog />
					</List>
					<DialogActions>
						<CloseButton onClick={props.onClose} size='small'>
							Fechar
						</CloseButton>
					</DialogActions>
				</Box>
			)}
		</Dialog>
	);
};

const CustomTextField: React.FC<CustomTextFieldProps> = (props: object) => <TextField {...props} />;

const TabelaVisualizaLog = () => (
	<Tabela disableClickRow>
		<CustomTextField source='id' label='Protocolo' filtro={<Padrao />} />
		<CustomTextField source='data' label='Data' filtro={<Data views={['month', 'year']} format='MMMM/yyyy' />} />
		<CustomTextField source='hora' label='Hora' filtro={<Padrao />} />
		<CustomTextField
			source='vinculo'
			label='Vínculo'
			filtro={
				<SelectAutocompleteFilter
					opcoes={[
						{ id: 'Proprietario', name: 'Proprietário' },
						{ id: 'Inquilino', name: 'Inquilino' },
					]}
				/>
			}
		/>
		<CustomTextField source='grupo' label='Grupo da unidade' filtro={<Padrao />} />
		<CustomTextField source='unidade' label='Unidade' filtro={<Padrao />} />
		<CustomTextField source='nome' label='Nome' filtro={<Padrao />} />
		<CustomTextField
			source='operacao'
			label='Operação'
			filtro={
				<SelectAutocompleteFilter
					opcoes={[
						{ id: 'preview', name: 'Visualização' },
						{ id: 'request_second_copy', name: 'Solicitação de segunda via' },
						{ id: 'copy_typeable_line', name: 'Cópia da linha digitável' },
					]}
				/>
			}
		/>
	</Tabela>
);

export default ModalVisualizadorLogs;
