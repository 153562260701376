import React, { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import NovaLeituraContext from './NovaLeituraContext';
import InputDateReferencia from '../../componentes/InputDateReferencia';
import { BotaoProximo } from '../../../../../common/buttons/BotaoProximo';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';

const ModalReferenciaNovaLeitura = () => {
	const {
		handleCancel,
		setModalStep,
		setDadosNovaLeitura,
		dadosNovaLeitura: {
			dados_config: { metodo_calculo, referencia },
		},
	} = useContext(NovaLeituraContext);
	const [ref, setRef] = useState(referencia ? new Date(`${referencia}T10:00`) : new Date());
	const handleBack = () => {
		setDadosNovaLeitura((v) => {
			const { referencia, ...dados_config } = v.dados_config;
			v['dados_config'] = dados_config;
			return v;
		});
		setModalStep('3');
	};
	const handleNext = () => {
		if (ref) {
			setDadosNovaLeitura((v) => {
				v.dados_config['referencia'] = ref.toISOString().split('T')[0];
				return v;
			});
			if (metodo_calculo === '4') {
				setModalStep('2RP');
			} else {
				setModalStep('5F');
			}
		}
	};

	const handleChange = (newValue) => {
		setRef(newValue);
	};

	const { isValid = true, errorMsg = null } = React.useMemo(() => {
		if (!ref) return { isValid: false, errorMsg: 'Forneça uma data' };
		if (ref.toString() === 'Invalid Date') return { isValid: false, errorMsg: 'Formato de data inválido' };
		const threeMonthsLater = new Date();
		threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
		if (ref.getTime() > threeMonthsLater.getTime())
			return { isValid: false, errorMsg: 'Não é possível lançar leituras 3 meses após a data atual.' };
		return { isValid: true };
	}, [ref]);

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Qual a referência do método de cálculo?'
			form={{
				component: (
					<InputDateReferencia
						fullWidth
						initialFocusedDate={ref}
						label='Referência'
						value={ref}
						handleChange={handleChange}
						error={!isValid}
						helperText={errorMsg}
						style={{ marginTop: '8px' }}
					/>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} startIcon={<CloseIcon />} size='small'>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo onClick={handleNext} disabled={!isValid} />
				</>
			}
		/>
	);
};

export default ModalReferenciaNovaLeitura;
