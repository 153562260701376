import React from 'react';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import BoxConfigGeracaoBoleto from '../../../BoxConfigGeracaoBoleto';
import { ButtonGerarRemessas } from '../botoes';
import BoletosContextProvider, { BoletosContext } from '../../ContasReceberBoletos/BoletosContextProvider';
import { ButtonGerarSegundaVia } from '../botoes';

export const ModalConfiguracaoImpressao = ({ segundaVia = false }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { init } = React.useContext(BoletosContext);

	React.useEffect(init, []);

	return (
		<BoletosContextProvider>
			<CustomDialogBodySizeEditable
				title='Configurações do Boleto'
				form={{
					component: <BoxConfigGeracaoBoleto />,
				}}
				customActions={
					<>
						<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
						{segundaVia ? <ButtonGerarSegundaVia /> : <ButtonGerarRemessas />}
					</>
				}
			/>
		</BoletosContextProvider>
	);
};

export default ModalConfiguracaoImpressao;
