import React, { useContext, useCallback, useEffect, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Box from '@material-ui/core/Box';

import { format } from 'date-fns';

import { CustomDialogBody, ModalContext } from '../../../../../common/ModalContext';
import { ButtonCancel } from '../../../../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../../../../common/buttons/ButtonConfirm';
import { ContasContextProvider } from '../../../../planosCondominio/contexts/ContasContext';
import { MovimentacoesContext } from '../../../MovimentacoesContext';
import ConfiguracoesLancamentosDespesas from '../common/ConfiguracoesLancamentosDespesas';
import ConfiguracoesLancamentosReceitas from '../common/ConfiguracoesLancamentosReceitas';
import ConfiguracoesTransferencias from '../common/ConfiguracoesTransferencias';
import TiposLancamentosFinanceirosSelect from '../common/TiposLancamentosFinanceirosSelect';
import FluxoImportacaoExtratoBancarioContext, {
	fuctionsRowIsNotCheckable,
} from '../FluxoImportacaoExtratoBancarioContext';
import ModalFeedbackLancamento from './ModalFeedbackLancamento';

const optionsTipoLancamento = {
	D: 'LD',
	R: 'LR',
};

const resources = {
	LD: 'lancamento_despesa',
	LR: 'lancamento_receita',
	T: 'transferencia',
};

export const ModalLancamentoExtratoBancario = () => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { setForceRefresh } = useContext(MovimentacoesContext);
	const { setModalValue } = useContext(ModalContext);
	const {
		condominio,
		contaBancaria,
		contaBancariaDestino,
		contaBancariaOrigem,
		contaReceita,
		detalhamentosLancamentosDespesa,
		grupo,
		idPlanoCondominio,
		setFRowIsNotCheckable,
		registrosLancaveisSelecionados,
		setContaBancariaDestino,
		setContaReceita,
		setContaBancariaOrigem,
		setDetalhamentosLancamentosDespesa,
		setGrupo,
		setRegistros,
		setRegistrosLancaveisSelecionados,
		setTipoLancamento,
		setUnidade,
		tipoLancamento,
		unidade,
	} = useContext(FluxoImportacaoExtratoBancarioContext);
	const [tipoLancamentoFinanceiro, setTipoLancamentoFinanceiro] = useState(
		optionsTipoLancamento[registrosLancaveisSelecionados[0]?.tipo_lancamento] || 'T'
	);
	const [processing, setProcessing] = useState(false);
	const [valid, setValid] = useState(false);
	const [tipoLancamentoFirstRegistro, setTipoLancamentoFirstRegistro] = useState();

	const init = useCallback(() => {
		const firstRegistro = registrosLancaveisSelecionados[0];
		setTipoLancamentoFirstRegistro(firstRegistro.tipo_lancamento);
		setContaBancariaDestino(firstRegistro.tipo_lancamento === 'R' ? contaBancaria : null);
		setContaReceita(null);
		setContaBancariaOrigem(firstRegistro.tipo_lancamento === 'D' ? contaBancaria : null);
		setGrupo(null);
		setTipoLancamento('C');
		setUnidade(null);
	}, [
		registrosLancaveisSelecionados,
		contaBancaria,
		setContaBancariaDestino,
		setContaReceita,
		setContaBancariaOrigem,
		setGrupo,
		setTipoLancamento,
		setUnidade,
	]);
	useEffect(init, []);
	const validate = useCallback(() => {
		const somaValorTotalDetalhamentos = detalhamentosLancamentosDespesa.reduce(
			(valorTotalDetalhamentos, detalhamento) => valorTotalDetalhamentos + (detalhamento.valor || 0),
			0
		);
		setValid(
			!!(
				(tipoLancamentoFinanceiro === 'T' &&
					!!tipoLancamento &&
					(tipoLancamento === 'C' || (tipoLancamento === 'G' && !!grupo)) &&
					contaBancariaOrigem?.id &&
					contaBancariaDestino?.id) ||
				(tipoLancamentoFinanceiro === 'LR' &&
					!!contaReceita?.id &&
					(tipoLancamento === 'C' ||
						(tipoLancamento === 'U' && !!unidade) ||
						(tipoLancamento === 'G' && !!grupo)) &&
					contaBancariaDestino?.id) ||
				(tipoLancamentoFinanceiro === 'LD' &&
					!!tipoLancamento &&
					(tipoLancamento === 'C' || (tipoLancamento === 'G' && !!grupo)) &&
					contaBancariaOrigem?.id &&
					!!detalhamentosLancamentosDespesa?.length &&
					!detalhamentosLancamentosDespesa?.find((detalhamento) => !detalhamento?.conta_despesa?.id) &&
					(registrosLancaveisSelecionados.length === 1
						? parseFloat(somaValorTotalDetalhamentos.toFixed(2)) ===
						  parseFloat(registrosLancaveisSelecionados[0].valor)
						: true))
			)
		);
	}, [
		setValid,
		tipoLancamentoFinanceiro,
		tipoLancamento,
		grupo,
		unidade,
		detalhamentosLancamentosDespesa,
		contaReceita,
		contaBancariaOrigem,
		contaBancariaDestino,
	]);

	useEffect(validate, [
		tipoLancamentoFinanceiro,
		contaBancariaOrigem,
		contaBancariaDestino,
		grupo,
		tipoLancamento,
		contaReceita,
		unidade,
		detalhamentosLancamentosDespesa,
	]);

	const getDadosLancamento = useCallback(
		(registro) => {
			const dados = {
				condominio,
				tipo_lancamento: tipoLancamento,
				valor: registro.valor,
				historico: registro.historico,
				data_movimentacao: format(registro.data_movimentacao, 'yyyy-MM-dd'),
				situacao: 'C',
				origem: 'EB',
			};
			if (['LD', 'T'].includes(tipoLancamentoFinanceiro)) dados.conta_b_origem = contaBancariaOrigem?.id;
			if (['LR', 'T'].includes(tipoLancamentoFinanceiro)) dados.conta_b_destino = contaBancariaDestino?.id;
			if ('LD' === tipoLancamentoFinanceiro) {
				dados.detalhamentos_lancamentos_despesa = detalhamentosLancamentosDespesa.map((detalhamentos) => ({
					conta_despesa: detalhamentos.conta_despesa.id,
					descricao: detalhamentos.conta_despesa.nome,
					valor: detalhamentos.valor,
				}));
				if (registrosLancaveisSelecionados.length !== 1) {
					dados.detalhamentos_lancamentos_despesa = [
						{
							conta_despesa: detalhamentosLancamentosDespesa[0].conta_despesa?.id,
							valor: registro.valor,
						},
					];
				}
			}
			if ('LR' === tipoLancamentoFinanceiro) dados.conta_receita = contaReceita?.id;
			if (tipoLancamento === 'G') dados.grupo = grupo;
			if (tipoLancamento === 'U') dados.unidade = unidade;
			return dados;
		},
		[
			condominio,
			tipoLancamento,
			contaReceita,
			contaBancariaOrigem,
			contaBancariaDestino,
			detalhamentosLancamentosDespesa,
			grupo,
			tipoLancamentoFinanceiro,
			unidade,
		]
	);

	const handleLancamento = useCallback(() => {
		Promise.allSettled(
			registrosLancaveisSelecionados.map((registro) =>
				dataProvider
					.create(resources[tipoLancamentoFinanceiro], {
						data: getDadosLancamento(registro, tipoLancamentoFinanceiro),
					})
					.then((response) => ({
						data: response?.data,
						status: response?.status,
						dados_registro: registro,
					}))
					.catch((e) => Promise.reject({ e, dados_registro: registro }))
			)
		)
			.then((responses) => {
				const RejectedLoggoutResponse = responses.find(
					(r) => r?.status === 'rejected' && [401, 403].includes(r.reason?.e?.response?.status)
				);
				if (RejectedLoggoutResponse) return Promise.reject(RejectedLoggoutResponse.reason?.e);
				const [fulfilledResponses, rejectedResponses] = responses.reduce(
					([fulfilledResponses, rejectedResponses], response) => {
						if (response.status === 'fulfilled')
							fulfilledResponses[response.value.dados_registro.index] = response;
						if (response.status === 'rejected')
							rejectedResponses[response.reason.e.response.dados_registro.index] = response;
						return [fulfilledResponses, rejectedResponses];
					},
					[{}, {}]
				);
				setForceRefresh(true);
				setRegistrosLancaveisSelecionados((v) => {
					const newList = Object.keys(rejectedResponses).length
						? v.filter((r) => !!rejectedResponses[r.index])
						: [];
					if (!newList.length) setFRowIsNotCheckable(() => fuctionsRowIsNotCheckable.vazio);
					return newList;
				});
				if (Object.keys(fulfilledResponses).length)
					setRegistros((v) =>
						Object.values(v).reduce((dict, r) => {
							if (fulfilledResponses[r.index]) r.situacao = 'C';
							dict[r.index] = r;
							return dict;
						}, {})
					);

				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalFeedbackLancamento
							fulfilledResponses={Object.values(fulfilledResponses)}
							rejectedResponses={Object.values(rejectedResponses)}
							onClose={() => {
								setModalValue((v) => ({ ...v, open: false }));
							}}
						/>
					),
				}));
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setProcessing(false);
				notify('Erro inesperado, tente recarregar a página', 'warning');
			});
	}, [
		setRegistros,
		setRegistrosLancaveisSelecionados,
		getDadosLancamento,
		registrosLancaveisSelecionados,
		detalhamentosLancamentosDespesa,
		setProcessing,
		setModalValue,
		notify,
		tipoLancamentoFinanceiro,
		dataProvider,
		setForceRefresh,
		setFRowIsNotCheckable,
	]);

	return (
		<CustomDialogBody
			title='Lançamento Financeiro Baseado no Extrato Bancário'
			customActions={[
				<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Cancelar</ButtonCancel>,
				<ButtonConfirm size='small' onClick={handleLancamento} disabled={!valid || processing}>
					Lançar
				</ButtonConfirm>,
			]}
			form={{
				component: (
					<ContasContextProvider id={idPlanoCondominio}>
						<Box display='flex' width='41rem' flexDirection='column' gridGap='0.5rem'>
							<TiposLancamentosFinanceirosSelect
								{...{
									tipoLancamentoFinanceiro,
									setTipoLancamentoFinanceiro,
									tipoLancamentoFirstRegistro,
								}}
							/>
							{tipoLancamentoFinanceiro === 'LR' && <ConfiguracoesLancamentosReceitas />}
							{tipoLancamentoFinanceiro === 'LD' && <ConfiguracoesLancamentosDespesas />}
							{tipoLancamentoFinanceiro === 'T' && (
								<ConfiguracoesTransferencias {...{ tipoLancamentoFirstRegistro }} />
							)}
						</Box>
					</ContasContextProvider>
				),
			}}
		/>
	);
};

export default ModalLancamentoExtratoBancario;
