import * as React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useTranslate } from 'ra-core';

import MenuItemLink from './MenuItemLink';

const DashboardMenuItem = ({ locale, ...props }) => {
	const translate = useTranslate();
	return (
		<MenuItemLink
			to='/'
			primaryText={translate('ra.page.dashboard')}
			leftIcon={<DashboardIcon />}
			exact
			{...props}
		/>
	);
};

export default DashboardMenuItem;
