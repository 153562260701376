import formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB240 from './formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB240';
import formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB400 from './formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB400';

const formatadoresPorTipoIntegracaoId = {
	1: formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB240,
	2: formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB400,
};

const formatadaPayloadDependenteDoTipoIntegracaoId = ({ payload, values, ...props }) => {
	const tipoIntegracaoId = values.tipo_integracao?.id ?? null;
	const formatador = formatadoresPorTipoIntegracaoId[tipoIntegracaoId];
	if (!formatador) return null;
	return formatador({ ...props, values });
};

export default formatadaPayloadDependenteDoTipoIntegracaoId;
