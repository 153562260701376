import React from 'react';
import { Data } from '../../types';
import ValorField from './ValorField';

interface IUltimoValorCalculoField {
	record: Data;
	source: 'ultimo_valor_calculo';
}

interface IValorFieldColumn extends Omit<IUltimoValorCalculoField, 'record'> {
	name: 'ultimo_valor_calculo';
	label: string;
	filtro: React.ReactElement;
}

const UltimoValorCalculoField: React.FC<IUltimoValorCalculoField> = ({ record, source }) => {
	return <ValorField value={record?.[source] || 0.0} />;
};

export default UltimoValorCalculoField as React.FC<IValorFieldColumn | IUltimoValorCalculoField>;
