import React from 'react';

import { TextField, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { parse } from 'date-fns';
import { formatFracoesThenGetInterval, fracaoToInterval } from '../modal/CardReservasEBloqueios/utils';

const parseHourToDate = (hour) => parse(hour, 'H:mm:ss', new Date());
const hourToDateObject = (horas) => horas.filter((e) => e !== null).map(parseHourToDate);

export const SelectHorarios = ({
	configEdicaoReserva,
	configCriarReserva,
	selectedHorarios = [],
	setSelectedHorarios,
	style = {},
}) => {
	const [horariosBloqueados, setHorariosBloqueados] = React.useState([]);
	const [horarios, setHorarios] = React.useState([]);

	const formataDadosIniciaisEditarReserva = () => {
		const {
			configuracao: { hora_1, hora_2, hora_3, hora_4, fracao },
			reservas_intervalos_tempo,
			outras_reservas,
		} = configEdicaoReserva;

		const _horarios = hourToDateObject([hora_1, hora_2, hora_3, hora_4]);

		setHorarios(fracaoToInterval[fracao](_horarios));

		outras_reservas.length && setHorariosBloqueados(formatFracoesThenGetInterval[fracao](outras_reservas));

		reservas_intervalos_tempo.length &&
			setSelectedHorarios(formatFracoesThenGetInterval[fracao](reservas_intervalos_tempo));
	};

	const formataDadosIniciaisCriarReserva = () => {
		const { hora_1, hora_2, hora_3, hora_4, fracao, reservas } = configCriarReserva;

		const _horarios = hourToDateObject([hora_1, hora_2, hora_3, hora_4]);

		setHorarios(fracaoToInterval[fracao](_horarios));

		if (reservas.length) {
			const reservas_itervalo_tempo_outras_reservas = reservas
				.reduce(
					(lista_reserva_intervalo, { reservas_intervalos_tempo: tempo }) =>
						lista_reserva_intervalo.concat(tempo),
					[]
				)
				.filter(
					(reserva, index, self) =>
						index === self.findIndex((r) => r.inicio === reserva.inicio && r.fim === reserva.fim)
				);
			setHorariosBloqueados(formatFracoesThenGetInterval[fracao](reservas_itervalo_tempo_outras_reservas));
		}
	};

	React.useEffect(() => {
		if (configEdicaoReserva) return formataDadosIniciaisEditarReserva();
		if (configCriarReserva) return formataDadosIniciaisCriarReserva();
		// eslint-disable-next-line
	}, [configEdicaoReserva, configCriarReserva]);

	if (!configEdicaoReserva && !configCriarReserva) return <></>;
	return (
		<Autocomplete
			id='select-horario-reserva'
			itemID='select-horario-reserva-item'
			disableCloseOnSelect
			options={horarios}
			disabled={!configEdicaoReserva && !configCriarReserva}
			value={selectedHorarios}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Horários' />}
			renderOption={(option, { selected }) => (
				<span>
					<Checkbox checked={selected} />
					{option}
				</span>
			)}
			getOptionDisabled={(option) => !!horariosBloqueados.includes(option)}
			noOptionsText='Nenhum resultado encontrado'
			onChange={(_, v) => setSelectedHorarios(v)}
			autoHighlight
			clearText='Limpar'
			multiple
			fullWidth
			style={style}
		/>
	);
};
