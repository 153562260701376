import React, { memo, useCallback, useEffect, useState } from 'react';

import { useListContext } from 'react-admin';

import { TextField as TextFieldMUI } from '@material-ui/core';

import { format, parseISO } from 'date-fns';

import { IPeriodoOption, IDateFilterProps, IcreateCustomDateRangeFromFilterValues } from './types';
import periodoOptions from './periodoOptions';
import PeriodoPicker from './PeriodoPicker';

const formatDate = (date?: Date | 'Invalid Date') => {
	if (!date || date === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const createCustomDateRangeFromFilterValues: IcreateCustomDateRangeFromFilterValues = (source, filterValues) => {
	const startDate = parseISO(filterValues[`${source}_range_after`]);
	startDate.setHours(10, 0, 0);
	const endDate = parseISO(filterValues[`${source}_range_before`]);
	endDate.setHours(10, 0, 0);
	return {
		label: 'custom',
		startDate: startDate,
		endDate: endDate,
	};
};

const DateFilter: React.FC<IDateFilterProps> | React.FC<Partial<IDateFilterProps>> = memo(({ source }) => {
	const { setFilters, filterValues } = useListContext();
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState<IPeriodoOption>(
		filterValues && filterValues[`${source}_range_after`] && filterValues[`${source}_range_before`]
			? createCustomDateRangeFromFilterValues(source, filterValues)
			: periodoOptions[0]
	);

	const handleRangeChange = useCallback(() => {
		const startDate = dateRange?.startDate ? format(dateRange.startDate, 'yyyy-MM-dd') : undefined;
		const endDate = dateRange?.endDate ? format(dateRange.endDate, 'yyyy-MM-dd') : undefined;
		if (filterValues[`${source}_range_after`] !== startDate || filterValues[`${source}_range_before`] !== endDate) {
			setFilters(
				{
					...filterValues,
					[`${source}_range_after`]: startDate,
					[`${source}_range_before`]: endDate,
				},
				undefined
			);
		}
	}, [setFilters, filterValues, source, dateRange]);

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextFieldMUI
				onClick={() => setOpen(true)}
				fullWidth
				inputProps={{
					value: `${dateRange?.endDate ? `de ${formatDate(dateRange?.startDate)}` : ''} ${
						dateRange?.endDate ? `até ${formatDate(dateRange?.endDate)}` : ''
					}`,
				}}
				variant='standard'
				margin='normal'
				style={{ marginBottom: 0 }}
			/>
			<PeriodoPicker {...{ dateRange, open, setOpen, setDateRange }} />
		</>
	);
});

const DateFilterForColumn = DateFilter as React.FC<Partial<IDateFilterProps>>;

export default DateFilterForColumn;
