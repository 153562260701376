import React, { useContext, useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';

import BoxButton from 'components/common/buttons/BoxButton';
import { CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButtonCancel } from 'components/common/buttons/ButtonCancel';

import ImportUnidadesContextProvider from './ImportUnidadesContextProvider';
import ImportGaragensContextProvider from './ImportGaragensContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

const minWidthDosModais = {
	OpcoesImportacao: '61vw',
};

const OpcoesImportacaoUnidadesGaragensModal = (props) => {
	const [opcoesModal] = useState('OpcoesImportacao');
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	const [disabled, setDisabled] = useState(false);
	const updateMinWidhtModal = () => {
		setMinWidthModel(minWidthDosModais[opcoesModal]);
	};
	useEffect(updateMinWidhtModal, [opcoesModal]);
	const importUnidades = () => {
		setModalValue((v) => ({ ...v, open: true, dialogBody: <ImportUnidadesContextProvider /> }));
	};
	const importGaragens = () => {
		setModalValue((v) => ({ ...v, open: true, dialogBody: <ImportGaragensContextProvider /> }));
	};
	const disabledButton = () => {
		if (Object?.keys(props).length < 0 || (props.ids && props?.ids[0] === 0)) {
			setDisabled(true);
		}
	};
	useEffect(() => {
		disabledButton();
	}, [props]);

	return (
		<CustomDialogBodySizeEditable
			customActions={
				<>
					<TooltipIconButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
				</>
			}
			form={{
				component: (
					<Box display='grid' gridGap='15px'>
						<Typography align='center' variant='h6'>
							Selecione o item para importar
						</Typography>
						<Box display='flex' justifyContent='center' gridGap='15px'>
							<BoxButton
								text='Importar Unidades'
								icon={<HomeIcon />}
								disabled={false}
								onClick={importUnidades}
								width='14rem'
							/>
							<BoxButton
								text='Importar Garagens'
								icon={<EmojiTransportationIcon />}
								disabled={disabled}
								onClick={importGaragens}
								width='14rem'
							/>
						</Box>
					</Box>
				),
			}}
		/>
	);
};

export default OpcoesImportacaoUnidadesGaragensModal;
