import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, Typography } from '@material-ui/core';
import { EditorState } from 'draft-js';

import MUIRichTextEditor from 'mui-rte';
import { labelSelectGestao } from 'theme/types';

type CreateComunicadoTemplateBoxProps = {
	handleSaveValueNewTemplate?: (data: string, template: string) => void;
};

const useStyles = makeStyles((theme: labelSelectGestao) => ({
	editor: {
		maxHeight: '11.1rem',
		overflowY: 'auto',
	},
	root: {
		minHeight: '100px',
	},
	fundo: {
		backgroundColor: theme.inputComunicado[100],
	},
	font: {
		color: theme.font[100],
	},
}));

export const CreateComunicadoTemplateBox: React.FC<CreateComunicadoTemplateBoxProps> = ({
	handleSaveValueNewTemplate = () => {},
}) => {
	// Variaveis
	const textRef = React.useRef<any>();
	const [novoTemplate, setNovoTemplate] = React.useState('');
	const [contadorCaracteres, setContadorCaracteres] = React.useState(0);
	const classes = useStyles();

	const handleRichTextChange = (richTextComunicado: EditorState) => {
		textRef.current?.save();

		const texto = richTextComunicado.getCurrentContent().getPlainText();
		setContadorCaracteres(texto.length);
	};

	return (
		<Box>
			<TextField
				value={novoTemplate}
				size='small'
				onChange={(event) => {
					setNovoTemplate(event.target.value);
					textRef.current.save();
				}}
				onBlur={() => textRef.current.save()}
				label='Nome Modelo'
				variant='outlined'
				fullWidth
			/>
			<Box
				style={{
					backgroundColor: 'white',
					color: 'black',
					marginTop: '10px',
				}}
				border={1}
				borderRadius='borderRadius'
			>
				<MUIRichTextEditor
					label='Digite aqui o comunicado...'
					inlineToolbar
					toolbarButtonSize='small'
					ref={textRef}
					onSave={(data) => handleSaveValueNewTemplate(data, novoTemplate)}
					onChange={handleRichTextChange}
					onBlur={() => textRef.current.save()}
					maxLength={500}
					controls={['bold', 'italic', 'underline', 'strikethrough', 'undo', 'redo', 'clear']}
					classes={{
						root: classes.root,
						editor: classes.editor,
					}}
				/>
				<Box className={classes.fundo}>
					<Typography
						variant='caption'
						color={contadorCaracteres > 500 ? 'error' : 'inherit'}
						style={{ paddingLeft: '1em' }}
						className={classes.font}
					>
						{contadorCaracteres}/500 caracteres
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
