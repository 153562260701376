import React from 'react';
import { SimpleFormIterator } from 'react-admin';
import { CustomRemoveButton } from './buttons/BotaoRemover';

const TransitionPropsAnimation = { enter: false, exit: false };

const SimpleFormIteratorV1 = ({ children, ...props }) => (
	<SimpleFormIterator removeButton={<CustomRemoveButton />} TransitionProps={TransitionPropsAnimation} {...props}>
		{children}
	</SimpleFormIterator>
);

export default SimpleFormIteratorV1;
