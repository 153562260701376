type SourcesProps = {
	sourceValor?: any;
	sourceDias?: any;
};

type ValoresProps = {
	sources: SourcesProps;
	values: any;
	optionsIsencoesTipo: any;
};

const emptyProps = {};

export const getPropsOnChangeTipoPadraoDesconto = ({
	sources: { sourceValor, sourceDias } = emptyProps,
	values,
	optionsIsencoesTipo,
}: ValoresProps) => ({
	sources: { sourceValor, sourceDias },
	values,
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoDesconto =
	({ sources: { sourceValor, sourceDias } = emptyProps, values, optionsIsencoesTipo }: ValoresProps, change: any) =>
	(_: any, tipoValue: any, input: any) => {
		input.onChange(tipoValue);

		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
			return;
		}

		const valorDesconto = values[sourceValor];
		if (!valorDesconto || valorDesconto < 0.01) change(sourceValor, 0.01);
	};
