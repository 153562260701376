import React from 'react';
import UserMenuItem from 'components/common/UserMenuItem';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

type MenuFAQProps = {
	url: string;
};

const MenuFAQSyndkos: React.FC<MenuFAQProps> = ({ url }) => (
	<UserMenuItem
		menuItemProps={{
			href: url,
			rel: 'noopener',
			target: '_blank',
		}}
		label='FAQ SCI'
		icon={<QuestionAnswerIcon />}
	/>
);

export default MenuFAQSyndkos;
