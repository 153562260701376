import React, { useState, useContext } from 'react';
import { TextInput, ArrayInput, required } from 'react-admin';
import { nomeParser, nomeValidacao } from '../../../fieldControl/nome';
import { emailValidacao, validacaoEmailObrigatorio } from '../../../fieldControl/email';
import { telefoneFormatacao, telefoneParser, telefoneValidacao } from '../../../fieldControl/telefone';
import { useTheme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { InputDocumento } from '../../common/InputDocumento';
import { ModalConfirmacao } from '../../common/buttons/BotaoRemover';
import { Typography, Box } from '@material-ui/core';
import { BoxDivisor, FormularioBox } from '../../common/Formulario';
import { InputDate } from '../../common/InputDate';
import { EnderecoBox } from '../../common/Endereco';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import { Tab } from '@material-ui/core';
import { CustomDialog, CustomDialogBody, ModalContextProvider } from '../../common/ModalContext';
import { ListaHistoricoPessoa } from './ListaHistoricoPessoa';
import { ButtonClose } from '../../common/buttons/ButtonClose';
import { ButtonRemove } from '../../common/buttons/ButtonRemove';
import { HistoricoCobrancaPessoaList } from './historicoCobrancaPessoa';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { Field } from 'react-final-form';
import { CustomRemoveButton } from '../../common/buttons/BotaoRemover';
import PessoaSaveButton from './PessoaSaveButton';
import { CustomFormIterator } from 'components/common/CustomFormIterator';

const opcoesTipoTelefone = [
	{ id: 'M', name: 'Celular' },
	{ id: 'R', name: 'Residencial' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'P', name: 'Principal' },
	{ id: 'O', name: 'Outros' },
];

const opcoesTipoEmail = [
	{ id: 'P', name: 'Pessoal' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'CB', name: 'Cobrança' },
	{ id: 'O', name: 'Outros' },
];

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const ModalFeedbackBloqueioRemover = ({ setModalValue }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBody
			title='Esta Pessoa não pode ser removida'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>
							<ul>
								<>
									<li>Esta pessoa está vinculada a ao mínimo uma unidade</li>
								</>
							</ul>
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
						}}
						size='small'
					>
						cancelar
					</ButtonClose>
					<ButtonRemove disabled={true} size='small' />
				</>
			}
		/>
	);
};

const BoxObservacoes = (props) => {
	return (
		<BoxDivisor titulo='Observação'>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<Field name='observacao' id='observacao' disabled={props.record.situacao === 'I'}>
						{({ input, meta }) => (
							<TextInput
								source='observacao'
								label='Anotações'
								placeholder='Use este espaço para anotações referentes a esta pessoa'
								variant='outlined'
								validate={(value) =>
									value ? (value.length > 500 ? [`Máximo excedido: ${value.length}/500`] : '') : ''
								}
								helperText={meta?.error ? meta?.error : `${(input?.value || '').length}/500`}
								rows={8}
								multiline
								fullWidth
							/>
						)}
					</Field>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

export const PessoaFormulario = (props) => {
	const [aba, setAba] = useState('0');
	const { condominioSelecionado } = useContext(CondominiosContext);
	const [idCondominio] = useState(condominioSelecionado?.id || undefined);

	const handleChange = (_, newValue) => {
		setAba(newValue);
	};

	return (
		<FormularioBox
			{...props}
			onClickBotaoRemover={(_, setModalValue) => {
				if (props.record?.unidades.filter((u) => u.situacao !== 'E').length > 0) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <ModalFeedbackBloqueioRemover setModalValue={setModalValue} />,
					}));
				} else {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalConfirmacao
								{...props}
								record={props.record}
								confirmTitle={`Tem certeza que deseja remover ${props.record.nome}?`}
								setModalValue={setModalValue}
							/>
						),
					}));
				}
			}}
			customSaveButton={props.edit ? <PessoaSaveButton /> : null}
		>
			<TabContext value={aba}>
				<Box width={1}>
					<Box display='flex'>
						<TabList value={aba} onChange={handleChange} aria-label='simple tabs example'>
							<Tab label='Informações da Pessoa' {...a11yProps(0)} value='0' />
							<Tab
								label='Histórico da Pessoa'
								{...a11yProps(1)}
								value='1'
								style={!props.edit ? { display: 'none' } : {}}
							/>
							<Tab
								label='Histórico de Cobranças'
								{...a11yProps(2)}
								value='2'
								style={!props.edit ? { display: 'none' } : {}}
							/>
						</TabList>
					</Box>
					<TabPanel value={aba} index={aba}>
						<Box
							display={aba === '0' ? 'flex' : 'none'}
							width='100%'
							css={
								(aba !== '0' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}) ||
								{}
							}
						>
							<Box flex={1} mr='1em'>
								<Box display='flex'>
									<Box flex={1} m='1em'>
										<Typography variant='h5' gutterBottom>
											Dados Cadastrais
										</Typography>

										<hr
											style={{
												marginRight: '1em',
												borderTop: '#d3d3d3',
											}}
										/>

										<Box display='flex'>
											<Box flex={1} mr='0.5em'>
												<TextInput
													source='nome'
													validate={nomeValidacao}
													parse={nomeParser}
													disabled={props.record.situacao === 'I'}
													variant='outlined'
													fullWidth
												/>
											</Box>
										</Box>
										<Box display='flex'>
											<Box flex={1} mr='0.5em'>
												<InputDate
													fullWidth
													source='data_nascimento'
													maxDate={new Date().setHours(10, 0, 0)}
													maxDateMessage='Só é possível cadastrar pessoas já nascidas'
													disableToolbar={false}
													parse={(value) => {
														try {
															return value.toISOString().substr(0, 10);
														} catch {
															return value;
														}
													}}
													validate={(value) => {
														if (value && typeof value !== 'string') {
															try {
																value.toISOString();
															} catch {
																return ['Data inválida'];
															}
														}
													}}
													style={{ marginBottom: 27 }}
												/>
												<TextInput
													source='email_cobranca'
													label='Email para cobrança'
													validate={emailValidacao}
													disabled={props.record.situacao === 'I'}
													variant='outlined'
													fullWidth
												/>
											</Box>
											<Box flex={1} mr='0.5em'>
												<InputDocumento
													resource='pessoas'
													source='documento'
													sourceTipo='tipo_pessoa'
													disabled={props.record.situacao === 'I'}
													variant='outlined'
													fullWidth
												/>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box mt='1em' />
								<Box m='1em'>
									<EnderecoBox labelTitle='Dados de Endereçamento' {...props} />
								</Box>
							</Box>

							<Box flex={1}>
								<Box display='flex'>
									<Box flex={1} m='1em'>
										<Typography variant='h5' gutterBottom>
											Contato
										</Typography>

										<hr
											style={{
												marginRight: '1em',
												borderTop: '#d3d3d3',
											}}
										/>
										<Box display='flex'>
											<Box flex={0.5} ml='1em'>
												<ArrayInput
													source='telefonepessoa_set'
													label='Telefones'
													variant='outlined'
												>
													<CustomFormIterator removeButton={<CustomRemoveButton />}>
														<SyndikosSelect
															source='tipo'
															label='Tipo de Telefone'
															validate={required()}
															choices={opcoesTipoTelefone}
															disabled={props.record.situacao === 'I'}
															fullWidth
															delay={0}
														/>
														<TextInput
															source='numero'
															format={telefoneFormatacao}
															parse={telefoneParser}
															validate={[required(), telefoneValidacao]}
															label='Número'
															disabled={props.record.situacao === 'I'}
															fullWidth
														/>
													</CustomFormIterator>
												</ArrayInput>
											</Box>
											<Box flex={0.5} ml='1em'>
												<ArrayInput
													source='emailpessoa_set'
													label='Emails adicionais'
													variant='outlined'
												>
													<CustomFormIterator removeButton={<CustomRemoveButton />}>
														<SyndikosSelect
															source='tipo'
															label='Tipo de Email'
															choices={opcoesTipoEmail}
															validate={required()}
															disabled={props.record.situacao === 'I'}
															fullWidth
															delay={0}
														/>
														<TextInput
															source='email'
															label='Email'
															validate={validacaoEmailObrigatorio}
															disabled={props.record.situacao === 'I'}
															fullWidth
														/>
													</CustomFormIterator>
												</ArrayInput>
											</Box>
										</Box>
									</Box>
								</Box>
								<BoxObservacoes {...props} />
							</Box>
						</Box>
						<Box
							display={aba === '1' ? 'flex' : 'none'}
							css={
								(aba !== '1' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}) || { width: '100%' }
							}
						>
							<BoxDivisor titulo='Histórico de Unidades Vinculadas' width='100%'>
								{aba !== '1' ? (
									<></>
								) : (
									<ModalContextProvider>
										<ListaHistoricoPessoa {...props} />
										<CustomDialog
											PaperProps={{
												style: { minHeight: '50vh' },
											}}
										/>
									</ModalContextProvider>
								)}
							</BoxDivisor>
						</Box>
						<Box
							display={aba === '2' ? 'flex' : 'none'}
							css={
								(aba !== '2' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}) || { width: '100%' }
							}
						>
							<BoxDivisor titulo='Histórico de Cobranças' width='100%'>
								{aba !== '2' ? (
									<></>
								) : (
									<HistoricoCobrancaPessoaList
										basePath='/contas_receber'
										resource='contas_receber'
										condominio={idCondominio}
										filter={{ pessoa_id: props.record.id }}
										pessoa={props.record.id}
									/>
								)}
							</BoxDivisor>
						</Box>
					</TabPanel>
				</Box>
			</TabContext>
		</FormularioBox>
	);
};
