import React, { FC, useContext } from 'react';
import DocumentsFolderList from './folder/DocumentsFolderList';
import DocumentsFileList from './file/DocumentsFileList';
import { DocumentsContext } from './contexts/DocumentsContext';
// import FolderFilterProvider from './contexts/FolderFilterContext';

const DocumentsBody: FC = (): JSX.Element => {
	const { screen } = useContext(DocumentsContext);
	return <>{screen.screen === 'folder' ? <DocumentsFolderList /> : <DocumentsFileList />}</>;
};

export default DocumentsBody;
