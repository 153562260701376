import React from 'react';

import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';

import ContaReceitaField from './ContaReceitaField';
import { DialogActionsSemSalvar } from './dialogActions';
import DialogTitleEditaConfiguracaoLeitura from './DialogTitleEditaConfiguracaoLeitura';
import InputReferencia from './InputReferencia';
import InputUnidades from './InputUnidades';
import FormEdicaoConfiguracaoLeitura from './FormEdicaoConfiguracaoLeitura';
import MetodoCalculoField from './MetodoCalculoField';

const FormSimples = () => (
	<FormEdicaoConfiguracaoLeitura
		render={() => (
			<>
				<DialogTitleEditaConfiguracaoLeitura />
				<DialogContent>
					<Box>
						<InputReferencia disabled={true} style={{ width: '28%', marginRight: '0.5em' }} />
						<ContaReceitaField />
					</Box>
					<Box style={{ 'margin-top': '1%' }}>
						<MetodoCalculoField />
					</Box>
					<Box>
						<InputUnidades />
					</Box>
				</DialogContent>
				<DialogActionsSemSalvar />
			</>
		)}
	/>
);

export default FormSimples;
