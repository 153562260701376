import React from 'react';

import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { ModalConsultaReserva } from '../modal/ModalConsultaReserva';
import { CalendarioInfo } from './components/CalendarioInfo';
import { CalendarioWrapper } from './components/CalendarioWrapper';
import { ReservaIcon } from './components/ReservaIcon';
import { RenderDay } from './RenderDay';

export const StyledCalendar = () => {
	const {
		consulta: { ambienteSelecionado, dataDoCalendario },
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);

	const onCalendarDateChange = React.useCallback(
		(calendarDate) => {
			setConsulta((_consulta) => ({
				..._consulta,
				dataDoCalendario: calendarDate,
				reservasNoMes: {},
			}));
		},
		[setConsulta]
	);
	const showModalSelectedDay = React.useCallback(
		(date) => {
			setConsulta((_consulta) => ({
				..._consulta,
				diaSelecionado: date,
				showModalConsultaReservas: true,
			}));
		},
		[setConsulta]
	);

	if (!ambienteSelecionado) return <></>;
	return (
		<>
			<CalendarioInfo>
				<label>
					<ReservaIcon tipoReserva='filtradas' />
					Reservas Filtradas por Unidades Selecionadas
				</label>
				<label>
					<ReservaIcon tipoReserva='realizadas' />
					Reservas Realizadas no Ambiente
				</label>
				<label>
					<ReservaIcon tipoReserva='bloqueado' />
					Reservas Bloqueadas no Ambiente
				</label>
			</CalendarioInfo>
			<CalendarioWrapper>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
					<Calendar
						onChange={() => {}}
						date={dataDoCalendario}
						minDate={new Date(2000, 1, 1)}
						onMonthChange={onCalendarDateChange}
						renderDay={(day, selectedDate, dayInCurrentMonth) => (
							<RenderDay
								day={day}
								dayInCurrentMonth={dayInCurrentMonth}
								onDayClick={showModalSelectedDay}
							/>
						)}
					/>
				</MuiPickersUtilsProvider>
			</CalendarioWrapper>
			<ModalConsultaReserva />
		</>
	);
};
