// Reset 2024-09-23 4
import React, { useContext, useEffect } from 'react';
import { put, takeEvery } from 'redux-saga/effects';
import {
	Admin,
	Resource,
	REGISTER_RESOURCE,
	RouteWithoutLayout,
	TOGGLE_SIDEBAR,
	setSidebarVisibility,
} from 'react-admin';
import ptBrMessages from 'ra-language-pt-br';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import CustomLayout from './components/layout/CustomLayout';
import UserThemeContextProvider, { UserThemeContext } from './theme/context';
import { LIGHT_THEME } from './theme/LIGHT_MODE';
import { DARK_THEME } from './theme/DARK_MODE';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

// Ícones
import FolderIcon from '@material-ui/icons/Folder';
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AvTimer from '@material-ui/icons/AvTimer';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import GavelIcon from '@material-ui/icons/Gavel';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ChatIcon from '@material-ui/icons/Chat';
import ReportProblem from '@material-ui/icons/ReportProblem';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Description from '@material-ui/icons/Description';
// adicionar novos ícones acima dessa linha

// Resources
import DocumentsPrincipalPage from './components/resources/documentos/';
import imobiliarias from './components/resources/imobiliarias';
import pessoas from './components/resources/pessoas';
import unidades from './components/resources/unidades';
import condominios from './components/resources/condominios';
import advogados from './components/resources/advogados';
import AssembleiaVirtual from './components/resources/assembleia_virtual';

import { AcordoParcelasList } from 'components/resources/acordos/list';
import { PlanoCondominioList } from './components/resources/planosCondominio/list';
import { PlanosCondominioEdit } from './components/resources/planosCondominio/edit';
import { LancamentosleituraList } from './components/resources/leituras/list';
import { ContasReceberList } from './components/resources/contasReceber/list';
import { CondominiosContext, CondominiosContextProvider } from './context/CondominioContextProvider';
import { AdminContext, AdminContextProvider } from './components/common/AdminContextProvider';
import { ArrecadacoesList } from './components/resources/arrecadacao/list';
import { RelatoriosList } from './components/resources/relatorios/list';
import { RelatoriosMultiplosCondominiosList } from './components/resources/relatoriosMultiplosCondominios/list';
import { MovimentacoesList } from './components/resources/movimentacoes/list';
import SyndikosLoginPage from './components/login';
import fornecedores from './components/resources/fornecedores';
import tarefasManutencoes from './components/resources/tarefasManutencoes';
import { ContasPagarList } from './components/resources/contasPagar/list';
import escritorio from './components/resources/escritorio';
import MenuItemLogout from './components/layout/UserMenuItems/MenuItemLogout';
import ConsultasList from './components/resources/consultas';
import ConfiguracaoPrincipalPage from 'components/resources/configuracoes';
import enquetes from './components/resources/enquetes';
import previsaoOrcamentaria from './components/resources/previsaoOrcamentaria';
import { RetencoesImpostosList } from './components/resources/retencoes/list';
import ComunicadoGeral from './components/resources/comunicados_gerais';
import ocorrencias from './components/resources/ocorrencias';
// adicionar novos resources acima dessa linha

ptBrMessages.ra.action.create = 'Cadastrar';
ptBrMessages.ra.page.create = 'Cadastrar %{name}';
ptBrMessages.ra.message.delete_title = 'Remover %{name}';
ptBrMessages.ra.notification.created = 'Cadastro criado com sucesso';
ptBrMessages.ra.notification.updated = 'Cadastro alterado com sucesso';
ptBrMessages.ra.notification.deleted = 'Cadastro removido com sucesso';

let sidebarOpen = localStorage.getItem('sidebarOpen');

function* restoreSidebarState(action) {
	try {
		if (action.payload) {
			yield put(setSidebarVisibility(sidebarOpen));
		}
	} catch {
		return;
	}
}

function registerSidebarState() {
	if (sidebarOpen) {
		localStorage.removeItem('sidebarOpen');
	} else {
		localStorage.setItem('sidebarOpen', true);
	}
}

function* sidebarSaga() {
	yield takeEvery(TOGGLE_SIDEBAR, registerSidebarState);
	yield takeEvery(REGISTER_RESOURCE, restoreSidebarState);
}

const messages = {
	'pt-br': {
		...ptBrMessages,
		resources: {
			imobiliarias: {
				name: 'Imobiliária |||| Imobiliárias',
				fields: {
					tipo_imobiliaria: 'Tipo de Imobiliária',
					situacao: 'Situação',
					email: 'E-mail',
					documento: 'CPF/CNPJ',
				},
			},
			pessoas: {
				name: 'Pessoa |||| Pessoas',
				fields: {
					tipo_pessoa: 'Tipo de Pessoa',
					situacao: 'Situação',
				},
			},
			plano_condominio: {
				name: 'Plano de Condomínio |||| Planos de Condomínio',
				fields: {
					condominios: 'Condomínios',
					descricao: 'Nome do Plano',
				},
			},
			unidades: {
				fields: {
					tipo: 'Tipo',
					unidade: 'Unidade',
					situacao: 'Situação',
					matricula: 'Matrícula do Imóvel',
					coeficiente: 'Coeficiente',
					dia_vencimento: 'Dia do Vencimento',
					proprietario_id: 'Proprietário',
					proprietario: 'Proprietário',
					inquilino_id: 'Inquilino',
					inquilino: 'Inquilino',
					nome_grupo: 'Grupo',
				},
			},
			lancamento_leitura: {
				name: 'Leitura |||| Leituras',
			},
			contas_receber: {
				name: 'Conta a Receber |||| Contas a Receber',
			},
			condominios: {
				name: 'Condomínio |||| Condomínios',
			},
			relatorios_gerais: {
				name: 'Relatório  |||| Relatórios ',
			},
			relatorios_multiplos_condominios: {
				name: 'Relatório Geral |||| Relatórios Gerais',
			},
			conta_condominio: {
				name: '',
			},
			fornecedores: {
				name: 'Fornecedor |||| Fornecedores',
			},
			arrecadacao: {
				name: 'Arrecadação |||| Arrecadações',
			},
			movimentacao_bancaria: {
				name: 'Lançamento |||| Lançamentos',
			},
			contaapagar: {
				name: 'Conta a Pagar |||| Contas a Pagar',
			},
			tarefas_manutencoes: {
				name: 'Tarefa e Manutenção |||| Tarefas e Manutenções',
			},
			documentos: {
				name: 'Documento |||| Documentos',
			},
			previsao_orcamentaria: {
				name: 'Previsão Orçamentária |||| Previsões Orçamentárias',
			},
			comunicados_gerais: {
				name: 'Comunicado Geral |||| Comunicados Gerais',
			},
			retencoes_impostos: {
				name: 'Retenção Imposto |||| Retenções Impostos',
			},
			consultas: {
				name: 'Consultas |||| Consultas',
			},
			configuracoes: {
				name: 'Configurações |||| Configurações',
			},
			parcela: {
				name: 'Conta a Pagar |||| Contas a Pagar',
			},
			administradora: {
				name: 'Escritório',
			},
			assembleias_virtuais: {
				name: 'Assembleia Virtual',
			},
			acordos_parcelas: {
				name: 'Acordos',
			},
			ocorrencias: {
				name: 'Ocorrências',
			},
		},
	},
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'pt-br');

const SyndikosLoginSuportePage = () => <SyndikosLoginPage isSuporte />;

const AdminSyndikos = () => {
	const { midias } = useContext(AdminContext);
	const { theme } = useContext(UserThemeContext);
	const { setReload } = useContext(CondominiosContext);

	useEffect(() => {
		const favicon = document.getElementById('favicon');
		favicon.href = midias.favicon;
	}, [midias]);

	useEffect(setReload, []);

	return (
		<Admin
			loginPage={SyndikosLoginPage}
			logoutButton={MenuItemLogout}
			customRoutes={[
				<RouteWithoutLayout
					key='RouteWithoutLayout'
					exact
					path='/login_suporte'
					component={SyndikosLoginSuportePage}
				/>,
			]}
			disableTelemetry
			theme={theme === 'dark' ? DARK_THEME : LIGHT_THEME}
			customSagas={[sidebarSaga]}
			layout={CustomLayout}
			dataProvider={dataProvider}
			authProvider={authProvider}
			i18nProvider={i18nProvider}
			title='Syndikos Web'
		>
			{(permissions) =>
				permissions && [
					// Preservar a ordem dos resources baseando-se no front antigo
					<Resource name='condominios' icon={ApartmentIcon} {...condominios} key='condominios' />,
					<Resource icon={PeopleAltIcon} name='pessoas' {...pessoas} key='pessoas' />,
					<Resource name='unidades' icon={HomeIcon} {...unidades} key='unidades' />,
					<Resource icon={StoreRoundedIcon} name='imobiliarias' {...imobiliarias} key='imobiliarias' />,
					<Resource icon={ShoppingCartIcon} name='fornecedores' {...fornecedores} key='fornecedores' />,
					<Resource icon={GavelIcon} name='advogados' {...advogados} key='advogados' />,
					<Resource
						icon={AccountBoxIcon}
						name='assembleias_virtuais'
						{...AssembleiaVirtual}
						key='assembleias_virtuais'
					/>,
					<Resource icon={BallotIcon} name='enquetes' {...enquetes} key='enquetes' />,
					<Resource icon={ReportProblem} name='ocorrencias' {...ocorrencias} key='ocorrencias' />,
					<Resource
						icon={AccountBalanceIcon}
						name='movimentacao_bancaria'
						list={MovimentacoesList}
						key='movimentacao_bancaria'
					/>,
					<Resource
						icon={AvTimer}
						name='lancamento_leitura'
						list={LancamentosleituraList}
						key='lancamento_leitura'
					/>,
					<Resource name='arrecadacao' list={ArrecadacoesList} icon={AttachMoneyIcon} key='arrecadacao' />,
					<Resource
						icon={BusinessCenterIcon}
						name='contas_receber'
						list={ContasReceberList}
						key='contas_receber'
					/>,
					<Resource icon={CreditCardIcon} name='contaapagar' list={ContasPagarList} key='contaapagar' />,
					<Resource
						icon={Description}
						name='acordos_parcelas'
						list={AcordoParcelasList}
						key='acordos_parcelas'
					/>,
					<Resource
						icon={EventNoteIcon}
						name='tarefas_manutencoes'
						{...tarefasManutencoes}
						key='tarefas_manutencoes'
					/>,
					<Resource icon={FolderIcon} name='documentos' list={DocumentsPrincipalPage} key='documentos' />,
					<Resource
						icon={ChatIcon}
						name='comunicados_gerais'
						{...ComunicadoGeral}
						key='comunicados_gerais'
					/>,

					<Resource
						icon={LocalAtmIcon}
						name='previsao_orcamentaria'
						list={previsaoOrcamentaria}
						key='previsao_orcamentaria'
					/>,
					<Resource
						icon={AssignmentIcon}
						name='retencoes_impostos'
						list={RetencoesImpostosList}
						key='retencoes_impostos'
					/>,
					<Resource
						icon={PieChartOutlinedIcon}
						name='relatorios_gerais'
						list={RelatoriosList}
						key='relatorios_gerais'
					/>,
					<Resource icon={SearchIcon} name='consultas' list={ConsultasList} key='consultas' />,
					<Resource
						icon={PieChartOutlinedIcon}
						name='relatorios_multiplos_condominios'
						list={RelatoriosMultiplosCondominiosList}
						key='relatorios_multiplos_condominios'
					/>,
					<Resource
						icon={AccountTreeIcon}
						name='plano_condominio'
						list={PlanoCondominioList}
						edit={PlanosCondominioEdit}
						key='plano_condominio'
					/>,
					<Resource icon={HomeWorkIcon} name='administradora' {...escritorio} key='administradora' />,
					<Resource
						icon={SettingsIcon}
						name='configuracoes'
						list={ConfiguracaoPrincipalPage}
						key='configuracoes'
					/>,
					// Manter resources sem list ao final do array
					<Resource name='tipo_logradouro' key='tipo_logradouro' />,
					<Resource name='instituicoes_financeiras' key='instituicoes_financeiras' />,
					<Resource name='instituicoes_financeiras_pagamento' key='instituicoes_financeiras_pagamento' />,
					<Resource name='contas_despesa' key='contas_despesa' />,
					<Resource name='contas_receita' key='contas_receita' />,
					<Resource name='contas_copia' key='contas_copia' />,
					<Resource name='tipo_condominio' key='tipo_condominio' />,
					<Resource name='dashboard' key='dashboard' />,
					<Resource name='tipo_unidade' key='tipo_unidade' />,
					<Resource name='grupo_unidade_condominio' key='grupo_unidade_condominio' />,
					<Resource name='leitura' key='leitura' />,
					<Resource name='config-leitura' key='config-leitura' />,
					<Resource name='pessoa_unidade' key='pessoa_unidade' />,
					<Resource name='historico_pessoa' key='historico_pessoa' />,
					<Resource name='identificacao_arrecadacao' key='identificacao_arrecadacao' />,
					<Resource name='arrecadacao_unidades' key='arrecadacao_unidades' />,
					<Resource name='arrecadacao_recorrente' key='arrecadacao_recorrente' />,
					<Resource name='conferencia_arrecadacao' key='conferencia_arrecadacao' />,
					<Resource name='conta_condominio' key='conta_condominio' />,
					<Resource name='conta_condominio_movimentacoes' key='conta_condominio_movimentacoes' />,
					<Resource name='lancamento_baixa' key='lancamento_baixa' />,
					<Resource name='lancamento_despesa' key='lancamento_despesa' />,
					<Resource name='lancamento_receita' key='lancamento_receita' />,
					<Resource name='cld_recebimento_manual' key='cld_recebimento_manual' />,
					<Resource name='contas_receber_remessas' key='contas_receber_remessas' />,
					<Resource name='contas_receber_boletos' key='contas_receber_boletos' />,
					<Resource name='pagamento_contas_receber' key='pagamento_contas_receber' />,
					<Resource name='remessas' key='remessas' />,
					<Resource name='boletos' key='boletos' />,
					<Resource name='login' key='login' />,
					<Resource name='recebimento_manual' key='recebimento_manual' />,
					<Resource name='tipo_fornecedor' key='tipo_fornecedor' />,
					<Resource name='email_status' key='email_status' />,
					<Resource name='parcela' key='parcela' />,
					<Resource name='parcela_despesa' key='parcela_despesa' />,
					<Resource name='arquivos_retorno' key='arquivos_retorno' />,
					<Resource name='ambiente_reserva' key='ambiente_reserva' />,
					<Resource name='user' key='user' />,
					<Resource name='email_template' key='email_template' />,
					<Resource name='reserva_agendada_syndkos' key='reserva_agendada_syndkos' />,
					<Resource name='consulta_relatorio' key='consulta_relatorio' />,
					<Resource name='visualiza_log' key='visualiza_log' />,
					<Resource name='consulta_arquivo' key='consulta_arquivo' />,
					<Resource name='convites_kondomynos' key='convites_kondomynos' />,
					<Resource name='dados_integracao_api_conta_bancaria' key='dados_integracao_api_conta_bancaria' />,
					<Resource name='correcao_monetaria' key='correcao_monetaria' />,
					<Resource name='historicos_correcao_monetaria' key='historicos_correcao_monetaria' />,
					<Resource name='lancamento_despesa_baixa' key='lancamento_despesa_baixa' />,
					<Resource name='garagens_unidades' key='garagens_unidades' />,
					<Resource name='classificacao_ocorrencia' key='classificacao_ocorrencia' />,
					<Resource name='detalhamento_lancamento_despesa' key='detalhamento_lancamento_despesa' />,
					<Resource name='leituras_cadastradas_aplicativo' key='leituras_cadastradas_aplicativo' />,
					<Resource
						name='lancamentos_leituras_cadastradas_aplicativo'
						key='lancamentos_leituras_cadastradas_aplicativo'
					/>,
					<Resource name='exportacao_contabil' key='exportacao_contabil' />,
					<Resource name='imposto_parcelado_emissao' key='imposto_parcelado_emissao' />,
					<Resource name='imposto_parcelado_pagamento' key='imposto_parcelado_pagamento' />,
					<Resource name='recebimentos_manuais_vencidos' key='recebimentos_manuais_vencidos' />,
				]
			}
		</Admin>
	);
};

const App = () => (
	<UserThemeContextProvider>
		<AdminContextProvider>
			<CondominiosContextProvider>
				<AdminSyndikos />
			</CondominiosContextProvider>
		</AdminContextProvider>
	</UserThemeContextProvider>
);

export default App;
