import React from 'react';

import { Box } from '@material-ui/core';

import ExibeSeEmitirBoletosTrue from '../../commons/ExibeSeEmitirBoletosTrue';
import AgenciaField from './AgenciaField';
import ContaCorrenteField from './ContaCorrenteField';
import LockNumberField from '../../commons/LockNumberField';
import ContaCedenteField from './ContaCedenteField';
import CarteiraField from './CarteiraField';
import EspecieTituloField from './EspecieTituloField';
import AceiteField from './AceiteField';
import MoedaField from './MoedaField';
import EmissaoBoletoField from './EmissaoBoletoField';
import DistribuicaoBoletoField from './DistribuicaoBoletoField';
import LocalPagamentoField from './LocalPagamentoField';
import ImprimirPixField from './ImprimirPixField';

const FirstRowFieldsTabConta = () => (
	<Box flexDirection='row' display='flex'>
		<AgenciaField />
		<Box pl={1} sx={{ 'max-width': '15%' }} flex={1}>
			<ContaCorrenteField />
		</Box>
		<ExibeSeEmitirBoletosTrue>
			{[
				<ContaCedenteField key='ContaCedenteField' />,
				<CarteiraField key='CarteiraField' />,
				<EspecieTituloField key='EspecieTituloField' />,
			]}
		</ExibeSeEmitirBoletosTrue>
	</Box>
);

const NossoNumeroField = () => (
	<LockNumberField
		source='nosso_numero'
		name='nosso_numero'
		id='nosso_numero'
		label='Nosso Número'
		promptMsg='Para editar o campo Nosso Número, forneça a senha do campo'
	/>
);

const SequencialArquivoField = () => (
	<LockNumberField
		source='sequencial_arquivo'
		name='sequencial_arquivo'
		id='sequencial_arquivo'
		label='Sequencial do Arquivo'
		promptMsg='Para editar o campo Sequencial do Arquivo, forneça a senha do campo'
	/>
);

const SecondRowFieldsTabConta = () => (
	<Box flexDirection='row' display='flex'>
		<AceiteField />
		<MoedaField />
		<EmissaoBoletoField />
		<DistribuicaoBoletoField />
		<Box pl={1} flex={1} sx={{ 'max-width': '17%' }}>
			<NossoNumeroField />
		</Box>
		<Box pl={1} flex={1} sx={{ 'max-width': '17%' }}>
			<SequencialArquivoField />
		</Box>
	</Box>
);

const ThirdRowFieldsTabConta = () => (
	<Box flexDirection='row' display='flex'>
		<Box flex={1}>
			<LocalPagamentoField />
		</Box>
	</Box>
);

const TabContaBase = ({ children, ...props }) => <Box {...props}>{children}</Box>;

const TabConta = () => (
	<TabContaBase>
		<FirstRowFieldsTabConta />
		<ExibeSeEmitirBoletosTrue>
			{[
				<SecondRowFieldsTabConta key='SecondRowFieldsTabConta' />,
				<ThirdRowFieldsTabConta key='ThirdRowFieldsTabConta' />,
			]}
		</ExibeSeEmitirBoletosTrue>
	</TabContaBase>
);

export {
	AgenciaField,
	ContaCorrenteField,
	ContaCedenteField,
	CarteiraField,
	EspecieTituloField,
	FirstRowFieldsTabConta,
	AceiteField,
	MoedaField,
	EmissaoBoletoField,
	DistribuicaoBoletoField,
	NossoNumeroField,
	SequencialArquivoField,
	SecondRowFieldsTabConta,
	LocalPagamentoField,
	ThirdRowFieldsTabConta,
	TabContaBase,
	ImprimirPixField,
};

export default TabConta;
