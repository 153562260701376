import React, { useState, useCallback, useEffect, useMemo } from 'react';
import DropZoneImportFornecedoresModal from './DropZoneImportFornecedoresModal';
import ResultadosFornecedoresModal from './ResultadosImportFornecedoresModal';
import ImportFornecedoresContext from './ImportFornecedoresContext';

const ImportFonecedoresContextProvider = () => {
	// Dados do import
	const [files, setFiles] = useState([]);
	const [resultadoImport, setResultadoImport] = useState([]);
	const [errosNoArquivo, SetErrosNoArquivo] = useState([]);

	const [modalStep, setModalStep] = useState('1');
	const [stepComponents] = useState({
		1: <DropZoneImportFornecedoresModal />,
		2: <ResultadosFornecedoresModal />,
	});

	const providerValue = useMemo(
		() => ({
			files,
			setFiles,
			setModalStep,
			resultadoImport,
			setResultadoImport,
			errosNoArquivo,
			SetErrosNoArquivo,
		}),
		[files, setFiles, setModalStep, resultadoImport, setResultadoImport, errosNoArquivo, SetErrosNoArquivo]
	);

	return (
		<ImportFornecedoresContext.Provider value={providerValue}>
			{stepComponents[modalStep]}
		</ImportFornecedoresContext.Provider>
	);
};

export default ImportFonecedoresContextProvider;
