import {
	validacoesFieldsTabInformacao,
	createValidateLinhas,
	validateTipoIntegracao,
	createValidateDadosIntegracaoAPI,
} from '../../../utils/validadoes';
import {
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesLimitePagamento,
	mensagensErrosPadroes,
} from '../../default/ContaCorrenteProps';

const validacoesMulta = getValidacoesMulta({
	valoresIsensao: ['ISE'],
	dias: false,
});

const validacoesJuros = getValidacoesJuros({
	valoresIsensao: ['JIS'],
	dias: false,
});

const validacoesDesconto = getValidacoesDesconto({
	minValor: 0.01,
	msgErroMinValor: 'O valor do desconto à ser aplicado não pode ser menor que 0.01',
});

const validacaoDescontoDias = (props) => {
	const { field, values = {} } = props;
	if (!values[field]) return null;

	return validacoesDesconto.dias(props);
};

const validateLimitePagamentoDias = ({ errors, values }) => {
	const campo = 'limite_pagamento_dias';
	if (errors[campo]) return null;

	const value = values[campo];

	if (typeof value !== 'number' && !value)
		return { [campo]: 'Se há um limite de pagamento, o número de dias até ele ser aplicado é requerido' };

	const OptionJuros = values.juros_tipo?.id;
	const OptionMulta = values.multa_tipo?.id;

	if ((OptionJuros !== 'JIS' || OptionMulta !== 'ISE') && !value) {
		return {
			[campo]:
				'O número de dias para limite de pagamento deve ser igual ou maior que 1, quando multa ou juros estiver habilitado',
		};
	}

	if (typeof value === 'number') {
		if (value < 0) return { [campo]: 'O número de dias para limite de pagamento deve ser igual ou maior que 0' };

		if (value > 60) {
			return {
				[campo]: 'O número de dias até o limite de pagamento ser aplicado não deve ser igual ou maior que 60',
			};
		}
	}

	return null;
};

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	nomeCampoLegivel: 'limite de pagamento',
	valorIsensao: 'NBD',
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamentoComSemLimiteComoOpcao(),
	dias: false,
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,

	validacoesJuros.tipo,
	validacoesJuros.valor,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacaoDescontoDias,

	validacoesLimitePagamento.tipo,
	validateLimitePagamentoDias,
];

const validacoes = [
	...validacoesFieldsTabInformacao,
	getValidateQuandoEmitindoBoletos([
		validateTipoIntegracao,
		createValidateDadosIntegracaoAPI(),
		...validacoesInstrucoesCobrancaRemessa,
		createValidateLinhas({ numeroLinhas: 5, maxValue: 78 }),
	]),
];

export default validacoes;
