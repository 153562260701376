import React, { useContext } from 'react';

import { Field } from 'react-final-form';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import { createGetPropsValidas } from '../utils/functions';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const propNamesField = ['name', 'id'];

const getPropsFieldValidas = createGetPropsValidas(propNamesField);

const EditaContaBancariaRadioGroup = ({ source, disabled: standardDisabled = false, ...standardProps }) => {
	const {
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { options, props } = React.useContext(DadosPorTipoIntegracaoContext);
	const { disabledProps = false, ...sourceProps } = props[source] ?? {};
	const disabled = isInativo || disabledProps || standardDisabled;
	const { choices, objects } = options[source];

	const radioButtons = React.useMemo(
		() =>
			choices.map((op) => (
				<FormControlLabel key={op.id} value={op.id} control={<Radio />} label={op.text} disabled={disabled} />
			)),
		[choices, disabled]
	);

	const onChange = React.useCallback(
		(_, value, input) => {
			input.onChange(objects[value]?.id ?? null);
		},
		[objects]
	);

	return (
		<Field disabled={disabled} {...getPropsFieldValidas({ ...standardProps, ...sourceProps })}>
			{({ input }) => (
				<FormControl component='fieldset'>
					<RadioGroup
						row
						name={input.name}
						value={input.value}
						onChange={(_, value) => {
							onChange(_, value, input);
						}}
					>
						{radioButtons}
					</RadioGroup>
				</FormControl>
			)}
		</Field>
	);
};

export default EditaContaBancariaRadioGroup;
