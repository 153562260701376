import React from 'react';
import NumberFormat from 'react-number-format';
import { sanitizeListNumberFormatBRLProps, NumberFormatBRL } from '../CurrencyInput';
import InputAdornment from '@material-ui/core/InputAdornment';

const ValorDisplayField = ({ id, value, name, ...rest }) => (
	<NumberFormat
		{...sanitizeListNumberFormatBRLProps(rest)}
		displayType='text'
		thousandSeparator='.'
		decimalSeparator=','
		isNumericString
		fixedDecimalScale={true}
		allowNegative={true}
		value={value}
		name={name}
		id={id}
		InputProps={{
			inputComponent: NumberFormatBRL,
			startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
		}}
	/>
);

export default ValorDisplayField;
