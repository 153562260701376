import React, { useContext } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ImportGaragensContext from '../import/ImportGaragensContext';
import ImportUnidadesContext from '../import/ImportUnidadesContext';

const GrupoSelect = ({ context }) => {
	const { grupo, setGrupo, gruposLista, condominio } = useContext(context);
	const isDisabled = !condominio;
	return (
		<Autocomplete
			options={gruposLista}
			renderInput={(params) => <TextField {...params} margin='dense' label='Grupo' />}
			getOptionLabel={(option) => option?.nome || ''}
			noOptionsText='Nenhum resultado encontrado'
			getOptionSelected={(option, v) => v?.id === option.id}
			value={grupo || null}
			onChange={(_, v) => setGrupo(v)}
			clearText='Limpar'
			disableClearable={false}
			disabled={isDisabled}
		/>
	);
};

export const GrupoSelectUnidades = () => <GrupoSelect context={ImportUnidadesContext} />;
export const GrupoSelectGaragens = () => <GrupoSelect context={ImportGaragensContext} />;
