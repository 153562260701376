import React, { useEffect, useState, createContext, useContext, useMemo } from 'react';
import { Edit, useDataProvider, useNotify } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';
import ReorderIcon from '@material-ui/icons/Reorder';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';

import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import './treeTableStyle.css';

import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';

import { ContasContext, ContasContextProvider } from './contexts/ContasContext';
import { ModalContext, ModalContextProvider, CustomDialog, CustomDialogBody } from '../../common/ModalContext';
import { TreeTableContext } from './contexts/TreeTableContext';
import { dataToTree } from './helpers/dataToTree';

import { ContasTable } from './ContasTable';

import { CreateRootForm, DeletePlanoForm, VinculoForm, EditPlanoForm } from './modais';
import ImportPlanoModal from './ImportPlanoModal';
import BotaoGerarRelatiorio from './botaoGerarRelatorio';
import { useTheme } from '@material-ui/core/styles';

const ModalBaixaContext = createContext();

const ReordenarContas = () => {
	const { value, setValue } = useContext(TreeTableContext);

	const [nodeDragged, setNodeDragged] = useState();

	const fixNode = ({ node, nextParentNode }) => {
		if (nextParentNode) {
			node.id_pai = nextParentNode.id;
		} else {
			node.id_pai = null;
		}
		setNodeDragged(node);
	};

	const canDrop = (node, nextParent) => {
		if (node) {
			if (!nextParent && node.classe_conta === 'T') {
				return true;
			}
			if (nextParent && nextParent.classe_conta === 'T' && node.tipo === nextParent.tipo) {
				if (nextParent.children.length > 0) {
					if (nextParent.children[0].classe_conta !== node.classe_conta) {
						return false;
					}
				}
				return true;
			}
		}
		return false;
	};

	return (
		<SortableTree
			rowHeight={48}
			maxDepth={10}
			treeData={value.treeDataReorder}
			onChange={(tree) =>
				setValue({
					...value,
					treeDataReorder: tree,
				})
			}
			getNodeKey={({ node }) => node.id + node.nome}
			canNodeHaveChildren={(node) =>
				node.classe_conta === 'T' &&
				(node.children.length === 0 || (nodeDragged && node.children[0].tipo === nodeDragged.tipo))
			}
			canDrop={({ node, nextParent }) => canDrop(node, nextParent)}
			onMoveNode={fixNode}
			generateNodeProps={({ node }) => {
				return {
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontWeight: node.classe_conta === 'T' ? 'bold' : 'normal',
					},
				};
			}}
		/>
	);
};

const TopBar = () => {
	const { value, setValue } = useContext(TreeTableContext);
	const { contas } = useContext(ContasContext);
	const { setModalValue } = useContext(ModalContext);

	return (
		<div
			style={{
				width: 'calc(100% - 20px)',
				display: 'flex',
				justifyContent: 'space-between',
				margin: '5px',
				alignItems: 'center',
			}}
		>
			<Typography variant='h6'>{value.titulo}</Typography>
			<div style={{ textAlign: 'end' }}>
				{value.reorderOpen ? (
					<>
						<Typography component='span' style={{ marginRight: 10 }}>
							Modo Ordenação
						</Typography>
						<Button
							onClick={() => {
								value.treeDataReorder &&
									contas.contaRequest(
										'reorder',
										{
											data: {
												data: value.treeDataReorder,
												id_plano: value.id,
											},
										},
										value.endpoint
									);
								setValue({
									...value,
									reorderOpen: false,
									treeData: value.treeDataReorder,
								});
							}}
							variant='outlined'
							style={{ margin: 4 }}
						>
							Salvar
						</Button>
						<Button
							onClick={() => setValue({ ...value, reorderOpen: false })}
							variant='outlined'
							style={{ margin: 4 }}
						>
							Cancelar
						</Button>
					</>
				) : (
					<>
						<Button
							startIcon={<AddIcon />}
							variant='outlined'
							style={{ margin: 4 }}
							onClick={() => {
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody: <CreateRootForm value={value} />,
								}));
							}}
						>
							Cadastrar Conta(s) Título
						</Button>
						<Button
							startIcon={<ReorderIcon />}
							variant='outlined'
							style={{ margin: 4 }}
							disabled={value.treeData.length === 0}
							onClick={() => {
								setValue({
									...value,
									reorderOpen: true,
									treeDataReorder: value.treeData,
								});
							}}
						>
							Reordenar
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

const DataView = () => {
	const { value } = useContext(TreeTableContext);

	return (
		<div
			style={{
				margin: '15px 15px 0',
				width: value.tableExpanded ? '100%' : 'calc(50% - 30px)',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				transition: 'all 200ms ease',
			}}
		>
			<TopBar />
			<div
				style={{
					display: 'flex',
					marginTop: '10px',
					width: '100%',
					height: 'calc(100vh - 215px)',
					justifyContent: 'center',
					position: 'relative',
				}}
			>
				<Paper style={{ width: '100%', height: '100%' }}>
					{value.treeData.length !== 0 ? (
						value.reorderOpen ? (
							<ReordenarContas />
						) : (
							<ContasTable />
						)
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								textAlign: 'center',
								width: '100%',
								height: '100%',
							}}
						>
							<Typography variant='h6'>Não há nenhuma conta cadastrada</Typography>
						</div>
					)}
				</Paper>
			</div>
		</div>
	);
};

const ModalBaixaProvider = ({ children }) => {
	const [modalValue, setModalBaixa] = useState({
		open: false,
		dialogBody: <></>,
	});

	const modalProviderValue = useMemo(() => ({ modalValue, setModalBaixa }), [modalValue, setModalBaixa]);

	return <ModalBaixaContext.Provider value={modalProviderValue}>{children}</ModalBaixaContext.Provider>;
};

const ContasView = ({ titulo, endpoint, id }) => {
	const { contas } = useContext(ContasContext);
	const [value, setValue] = useState({
		titulo,
		endpoint,
		tipoConta: endpoint.replace('contas_', ''),
		id,
		reorderOpen: false,
		openChildren: new Array(0),
		treeData: new Array(0),
		treeDataReorder: new Array(0),
	});

	const providerValue = useMemo(() => ({ value, setValue }), [value, setValue]);

	const setTree = (data, property) => {
		if (data) {
			const copyData = JSON.parse(JSON.stringify(data));
			const arrData = Object.keys(copyData).map((key) => copyData[key]);
			setValue((v) => ({
				...v,
				[property]: dataToTree(JSON.parse(JSON.stringify(arrData))),
			}));
		}
	};

	const refContas = contas[value.tipoConta];

	const updateTree = () => {
		setTree(refContas, 'treeData');
	};

	useEffect(updateTree, [contas]);

	return value.treeData ? (
		<TreeTableContext.Provider value={providerValue}>
			<DataView />
		</TreeTableContext.Provider>
	) : (
		<div />
	);
};

const CustomDialogBaixas = () => {
	const { modalValue, setModalBaixa } = useContext(ModalBaixaContext);

	const handleClose = () => {
		setModalBaixa((v) => ({ ...v, open: false }));
	};

	return (
		<Dialog open={modalValue.open} onClose={handleClose} fullWidth>
			{modalValue.dialogBody}
		</Dialog>
	);
};

const ConfiguraBaixas = ({ descricao }) => {
	const { contas } = useContext(ContasContext);
	const { setModalValue } = useContext(ModalContext);
	const { setModalBaixa } = useContext(ModalBaixaContext);
	const [arrecadacao, setArrecadacao] = useState();

	useEffect(() => {
		setArrecadacao(
			Object.keys(contas?.arrecadacao || {}).map((key) => ({
				...contas?.arrecadacao?.[key],
				receita_nome:
					contas?.receita?.[contas?.arrecadacao?.[key]?.id_conta_receita] &&
					contas.receita[contas.arrecadacao[key].id_conta_receita].nome,
				despesa_nome:
					contas?.despesa?.[contas?.arrecadacao?.[key]?.id_conta_despesa] &&
					contas.despesa[contas.arrecadacao[key].id_conta_despesa].nome,
			}))
		);
	}, [contas]);

	const handleClickOk = () => setModalBaixa((v) => ({ ...v, open: false }));

	return descricao ? (
		<CustomDialogBody
			title={`Configurar contas de baixa para contas a receber e contas a pagar do plano "${descricao}"`}
			customActions={
				<Button size='small' onClick={handleClickOk} startIcon={<CloseIcon />}>
					Fechar
				</Button>
			}
			form={{
				valid: true,
				component: arrecadacao && (
					<div>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Conta de Baixa</TableCell>
										<TableCell>Contas a Receber</TableCell>
										<TableCell>Contas a Pagar</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{arrecadacao.map((c) => (
										<TableRow>
											<TableCell>{c.nome}</TableCell>
											<TableCell>
												<Button
													style={{
														textTransform: 'unset',
													}}
													color={c.receita_nome ? 'primary' : 'default'}
													size='small'
													variant='outlined'
													startIcon={<LinkIcon />}
													onClick={() =>
														setModalValue((v) => ({
															...v,
															open: true,
															dialogBody: (
																<VinculoForm
																	conta={c}
																	contaVinculo={{
																		id: c.id_conta_receita,
																		nome: c.receita_nome,
																	}}
																/>
															),
														}))
													}
												>
													<Typography variant='body2' noWrap>
														{c.receita_nome || 'Vincular'}
													</Typography>
												</Button>
											</TableCell>
											<TableCell>
												{!['Descontos', 'Correções Monetárias'].includes(c.nome) && (
													<Button
														style={{
															textTransform: 'unset',
														}}
														color={c.despesa_nome ? 'primary' : 'default'}
														size='small'
														variant='outlined'
														startIcon={<LinkIcon />}
														onClick={() =>
															setModalValue((v) => ({
																...v,
																open: true,
																dialogBody: (
																	<VinculoForm
																		conta={c}
																		contaVinculo={{
																			id: c.id_conta_despesa,
																			nome: c.despesa_nome,
																		}}
																		despesa
																	/>
																),
															}))
														}
													>
														<Typography variant='body2' noWrap>
															{c.despesa_nome || 'Vincular'}
														</Typography>
													</Button>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				),
			}}
		/>
	) : (
		<div />
	);
};

const ImportacaoButton = (props) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	const handleClick = (FormComponent) =>
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <FormComponent {...props} />,
		}));

	return (
		<Button onClick={() => handleClick(ImportPlanoModal)} size='small'>
			Importar Plano
		</Button>
	);
};

const TopPlano = (props) => {
	const { setModalValue } = useContext(ModalContext);
	const { setModalBaixa } = useContext(ModalBaixaContext);
	const {
		palette: { syndikosRed },
	} = useTheme();

	const handleClick = (FormComponent) =>
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <FormComponent {...props} />,
		}));

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Typography variant='h5' style={{ paddingLeft: '20px', fontWeight: '500' }}>
				{props.descricao}
			</Typography>
			<div style={{ display: 'flex', marginRight: 20 }}>
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
					}}
				>
					<ImportacaoButton {...props} />
					<BotaoGerarRelatiorio id={props.id} />
				</ModalSizeEditableContextProvider>
				<Button onClick={() => handleClick(EditPlanoForm)} size='small'>
					Renomear
				</Button>
				<Button
					style={{ color: syndikosRed.main }}
					onClick={() => handleClick(DeletePlanoForm)}
					size='small'
					color='link'
				>
					Remover
				</Button>
				<Button
					onClick={() => {
						setModalBaixa((v) => ({
							...v,
							open: true,
							dialogBody: <ConfiguraBaixas {...props} />,
						}));
					}}
					size='small'
					color='primary'
				>
					Configurar
				</Button>
				<CustomDialogBaixas />
			</div>
		</div>
	);
};

export const PlanosCondominioFormulario = (props) => {
	return (
		<ModalContextProvider contextId='plano'>
			<ContasContextProvider id={props.id}>
				<div>
					<ModalBaixaProvider>
						<TopPlano
							descricao={props.record.descricao}
							condominios={props.record.condominios}
							{...props}
						/>
					</ModalBaixaProvider>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							width: '100%',
						}}
					>
						<ContasView {...props} titulo='Contas de Despesa' endpoint='contas_despesa' />
						<ContasView {...props} titulo='Contas de Receita' endpoint='contas_receita' />
					</div>
					<CustomDialog id='plano' />
				</div>
			</ContasContextProvider>
		</ModalContextProvider>
	);
};

export const PlanosCondominioEdit = (props) => {
	return (
		<Edit {...props} component='div' title='Alterar Plano de Condomínio'>
			<PlanosCondominioFormulario {...props} />
		</Edit>
	);
};
