import React, { useState, useEffect, useMemo, useContext } from 'react';

import {
	Create,
	TextInput,
	NumberInput,
	required as reqFunc,
	SaveButton,
	useDataProvider,
	useInput,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin';

import lodashMemoize from 'lodash/memoize';
import api from '../../../services/api';
import { useFormState } from 'react-final-form';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { numeroParser } from '../../../fieldControl/numero';
import { FormularioBox } from '../../common/Formulario';
import { InputCep } from '../../common/InputCep';
import { TipoModalInput } from '../../common/TipoModalInput';
import { CNPJInput } from '../../common/InputDocumento';
import { useGetEndereco, opcoesUf } from '../../common/Endereco';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { BotaoVoltar } from '../../common/buttons/BotaoVoltar';
import { BotaoProximo } from '../../common/buttons/BotaoProximo';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import { cepFormatacao, cepValidacao, cepParser } from '../../../fieldControl/cep';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import ModalAvisoLicenca from './ModalAvisoLicenca';

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

function getSteps() {
	return ['Dados Cadastrais', 'Dados de Endereçamento', 'Finalizar'];
}
const GridInput = React.memo(({ xs, Input, validate, required, newValue, ...rest }) => {
	const getProps = () => {
		let props = { fullWidth: true, variant: 'outlined', ...rest };
		if (rest.source !== 'cnpj') {
			props.validate = validate ? validate : required && reqFunc();
			props.required = validate && required;
		}
		return props;
	};

	const {
		input: { onChange, ...input },
	} = useInput(rest);
	if (rest.source !== 'cnpj') input['onChange'] = onChange;

	const handleValueChange = () => {
		newValue?.value && onChange(newValue?.value);
	};

	useEffect(handleValueChange, [newValue]);

	return (
		<Grid item xs={xs}>
			<Input {...{ ...getProps(), ...rest, ...input }} />
		</Grid>
	);
});

const TipoLogradouroInput = React.memo((props) => (
	<VeryHighLimitReferenceInput
		source='tipo_logradouro'
		label='Tipo de Logradouro'
		reference='tipo_logradouro'
		allowEmpty
		{...props}
	>
		<SyndikosSelect optionText='descricao' />
	</VeryHighLimitReferenceInput>
));

const CodigoField = ({ defaultCodigo }) => {
	const dataProvider = useDataProvider();
	const [dadosValidacao, setDadosValidacao] = useState({
		value: defaultCodigo,
		errorMessage: null,
	});

	const validateCodigo = React.useCallback(
		memoize(async (value) => {
			if (Number(dadosValidacao.value) === Number(value)) return dadosValidacao.errorMessage;
			const getErrorMessage = () => {
				if (!value) return Promise.resolve(['Obrigatório']);

				if (value <= 0) return Promise.resolve(['Código deve ser um número positivo']);

				return dataProvider
					.getList('condominios', {
						pagination: { perPage: 1, page: 1 },
						sort: {},
						filter: { codigo: value },
					})
					.then((response) => {
						const data = response?.data;
						if (!(data && data[0] && data[0].id === 0)) {
							return ['Código já utilizado'];
						}
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						return null;
					});
			};
			const errorMessage = (await getErrorMessage()) ?? null;
			setDadosValidacao({ value, errorMessage });
			return errorMessage;
		}),
		[dadosValidacao, setDadosValidacao, dataProvider]
	);

	return (
		<GridInput
			Input={NumberInput}
			source='codigo'
			required
			validate={validateCodigo}
			label='Código'
			xs={3}
			defaultValue={defaultCodigo}
		/>
	);
};

const Cadastrais = React.memo(({ defaultCodigo, setTipo }) => {
	const { values: { tipo_condominio } = {} } = useFormState();

	return (
		<Grid container spacing={1}>
			<CodigoField defaultCodigo={defaultCodigo} />
			<GridInput
				Input={TipoModalInput}
				required
				xs={4}
				source='tipo_condominio'
				label='Tipo'
				onChange={(value) => setTipo(value)}
			/>
			<GridInput Input={TextInput} source='apelido' xs={5} />
			<GridInput Input={TextInput} source='nome' required xs={7} />
			<GridInput
				Input={CNPJInput}
				resource='condominios'
				source='cnpj'
				label='CNPJ'
				customQueryValidation={`tipo_condominio=${tipo_condominio}&cnpj`}
				xs={5}
			/>
		</Grid>
	);
});

const Endereco = React.memo((props) => {
	const { tipoLogradouro, endereco, bairro, cidade, uf } = useGetEndereco(props);
	return (
		<Grid container spacing={1}>
			<GridInput
				Input={InputCep}
				format={cepFormatacao}
				parse={cepParser}
				validate={cepValidacao}
				xs={3}
				source='cep'
			/>
			<GridInput Input={TipoLogradouroInput} xs={3} source='tipo_logradouro' newValue={tipoLogradouro} />
			<GridInput Input={TextInput} xs={6} source='endereco' label='Endereço' newValue={endereco} />
			<GridInput Input={TextInput} xs={6} source='bairro' newValue={bairro} />
			<GridInput Input={TextInput} xs={2} source='numero' label='Número' parse={numeroParser} />
			<GridInput Input={TextInput} xs={4} source='cidade' newValue={cidade} />
			<GridInput
				Input={SyndikosSelect}
				xs={8}
				source='uf'
				label='UF'
				choices={opcoesUf}
				allowEmpty={true}
				newValue={uf}
			/>
			<GridInput Input={TextInput} xs={4} source='complemento_endereco' label='Complemento endereço' />
		</Grid>
	);
});

const StepperForm = (props) => {
	const [dadosAdministradora, setDadosAdministradora] = useState(null);
	const [loading, setLoading] = useState(true);

	const get_licencas = async () => {
		const auth = localStorage.getItem('auth');

		const authParsed = JSON.parse(auth || '{}');

		const res = await api.get(`api/v1/administradora/${authParsed.tenant.schema_name}`);
		if (res.status === 200) return res.data;
	};
	useEffect(() => {
		get_licencas().then((data) => {
			setDadosAdministradora(data);
			setLoading(false);
		});
	}, []);

	const { setModalValue } = useContext(ModalSizeEditableContext);
	const onClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	useEffect(() => {
		if (dadosAdministradora && dadosAdministradora.limite_condominio != 0) {
			const diferencaLimite =
				dadosAdministradora.limite_condominio - dadosAdministradora.licencas_condominio_utilizadas_no_mes;
			if (diferencaLimite <= 3) {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalAvisoLicenca
							dadosAdministradora={dadosAdministradora}
							onClose={onClose}
							licencas_faltantes={diferencaLimite}
						/>
					),
				}));
			}
		}
	}, [dadosAdministradora]);

	const { setReload } = useContext(CondominiosContext);
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response) => {
		const data = response?.data;
		if (data) {
			notify(`Condomínio "${data.nome}" cadastrado com sucesso`);
			redirect('list', props.basePath);
			refresh();
			setReload();
		}
	};

	const formState = useFormState({
		subscription: { invalid: true, pristine: true, modified: true },
	});

	const [currStep, setCurrStep] = useState(0);

	const steps = getSteps();

	const handleNext = () => {
		setCurrStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setCurrStep((prevActiveStep) => prevActiveStep - 1);
	};

	const [tipo, setTipo] = useState();
	const [defaultCodigo, setDefaultCodigo] = useState();

	useEffect(() => {
		const setCodInicial = memoize(() =>
			dataProvider
				.getList('condominios', {
					pagination: { perPage: 1, page: 1 },
					sort: { field: 'codigo', order: 'DESC' },
				})
				.then((res) => {
					if (res && res.data) {
						setDefaultCodigo(res.data[Object.keys(res.data)[0]].codigo + 1);
					}
				})
		);
		setCodInicial();
	}, [dataProvider]);

	const memoizedCadastrais = useMemo(
		() => (
			<Cadastrais
				{...{
					defaultCodigo,
					tipo,
					setTipo,
				}}
			/>
		),
		[defaultCodigo, tipo]
	);

	const memoizedEndereco = useMemo(() => <Endereco {...props} />, [props]);

	const getFields = (index) => {
		switch (index) {
			case 0:
				return memoizedCadastrais;
			case 1:
				return memoizedEndereco;
			default:
				return <></>;
		}
	};

	if (loading) {
		return <></>;
	}

	return (
		<Stepper activeStep={currStep} orientation='vertical'>
			{steps.map((label, index) => (
				<Step key={label}>
					<StepLabel>{label}</StepLabel>
					<StepContent>
						<Box display='flex' maxWidth={900}>
							{getFields(index)}
						</Box>
						<div>
							<div>
								<BotaoVoltar disabled={currStep === 0} onClick={handleBack} />
								{currStep < steps.length - 1 ? (
									<BotaoProximo onClick={handleNext} disabled={formState.invalid} />
								) : (
									<SaveButton
										onFailure={(e) => {
											notify(
												Object.values(e?.response?.data || {})[0] || [
													'Erro inesperado, tente recarregar a página',
												],
												'warning'
											);
										}}
										onSuccess={onSuccess}
										size='small'
										variant='text'
										redirect='list'
										{...props}
									/>
								)}
							</div>
						</div>
					</StepContent>
				</Step>
			))}
		</Stepper>
	);
};

export const CondominioCreate = (props) => {
	return (
		<ModalSizeEditableContextProvider
			customDialogProps={{
				minWidth: '61vw',
				position: 'static',
				overflowY: 'auto',
			}}
		>
			<Create {...props} transform={(data) => ({ ...data, situacao: 'A' })}>
				<FormularioBox noActionToolbar validateOnBlur={false}>
					<StepperForm {...props} />
				</FormularioBox>
			</Create>
		</ModalSizeEditableContextProvider>
	);
};
