import React from 'react';

import {
	Data,
	TNome,
	TSetNome,
	TTipoIndice,
	TSetTipoIndice,
	TUltimoValorCalculado,
	TSetUltimoValorCalculado,
	TUltimaDataAtualizacao,
	TSetUltimaDataAtualizacao,
	TFeesOptions,
	TSetFeesOptions,
	TFeesPercentage,
	TSetFeesPercentage,
	TFinePercentage,
	TSetFinePercentage,
	TAllowDuplicateGeneration,
	TSetAllowDuplicateGeneration,
	TDaysLimitAfterExpiration,
	TSetDaysLimitAfterExpiration,
} from '../types';
import { useFormIsValid, useLoadingData } from '../hooks';

type TFormContext = {
	nome: TNome;
	setNome: TSetNome;
	tipoIndice: TTipoIndice;
	setTipoIndice: TSetTipoIndice;
	ultimaDataAtualizacao: TUltimaDataAtualizacao;
	setUltimaDataAtualizacao: TSetUltimaDataAtualizacao;
	ultimoValorCalculado: TUltimoValorCalculado;
	setUltimoValorCalculado: TSetUltimoValorCalculado;
	feesOptions: TFeesOptions;
	setFeesOptions: TSetFeesOptions;
	feesPercentage: TFeesPercentage;
	setFeesPercentage: TSetFeesPercentage;
	finePercentage: TFinePercentage;
	setFinePercentage: TSetFinePercentage;
	allowDuplicateGeneration: TAllowDuplicateGeneration;
	setAllowDuplicateGeneration: TSetAllowDuplicateGeneration;
	daysLimitAfterExpiration: TDaysLimitAfterExpiration;
	setDaysLimitAfterExpiration: TSetDaysLimitAfterExpiration;
	formIsValid: boolean;
	loading: boolean;
	record: Data | null;
	desabilitaInputSeNaoForManual: boolean;
};

export const FormContext = React.createContext<TFormContext>({
	nome: '',
	setNome: (prev) => prev,
	tipoIndice: 'MANUAL',
	setTipoIndice: (prev) => prev,
	ultimoValorCalculado: '0.000000',
	setUltimoValorCalculado: (prev) => prev,
	ultimaDataAtualizacao: new Date(),
	setUltimaDataAtualizacao: (prev) => prev,
	feesOptions: {
		option_monthly_fees: false,
		option_daily_fees: false,
	},
	setFeesOptions: (prev) => prev,
	finePercentage: '00,0000',
	setFeesPercentage: (prev) => prev,
	feesPercentage: '00,0000',
	setFinePercentage: (prev) => prev,
	allowDuplicateGeneration: false,
	setAllowDuplicateGeneration: (prev) => prev,
	daysLimitAfterExpiration: 0,
	setDaysLimitAfterExpiration: (prev) => prev,
	formIsValid: false,
	loading: false,
	record: null,
	desabilitaInputSeNaoForManual: false,
});

type FormContextProvider = {
	children: React.ReactNode;
};

const useStateFields = () => {
	const [nome, setNome] = React.useState<TNome>('');
	const [tipoIndice, setTipoIndice] = React.useState<TTipoIndice>('MANUAL');
	const [ultimaDataAtualizacao, setUltimaDataAtualizacao] = React.useState<TUltimaDataAtualizacao>(new Date());
	const [ultimoValorCalculado, setUltimoValorCalculado] = React.useState<TUltimoValorCalculado>('0.0000');
	const [feesOptions, setFeesOptions] = React.useState<TFeesOptions>({
		option_monthly_fees: false,
		option_daily_fees: false,
	});
	const [feesPercentage, setFeesPercentage] = React.useState<TFeesPercentage>('00,0000');
	const [finePercentage, setFinePercentage] = React.useState<TFinePercentage>('00,0000');
	const [allowDuplicateGeneration, setAllowDuplicateGeneration] = React.useState<TAllowDuplicateGeneration>(false);
	const [daysLimitAfterExpiration, setDaysLimitAfterExpiration] = React.useState<TDaysLimitAfterExpiration>(0);

	return {
		nome,
		setNome,
		tipoIndice,
		setTipoIndice,
		ultimoValorCalculado,
		setUltimoValorCalculado,
		ultimaDataAtualizacao,
		setUltimaDataAtualizacao,
		feesOptions,
		setFeesOptions,
		finePercentage,
		setFeesPercentage,
		feesPercentage,
		setFinePercentage,
		allowDuplicateGeneration,
		setAllowDuplicateGeneration,
		daysLimitAfterExpiration,
		setDaysLimitAfterExpiration,
	};
};

export const FormCreateContextProvider: React.FC<FormContextProvider> = ({ children }) => {
	const {
		nome,
		setNome,
		tipoIndice,
		setTipoIndice,
		ultimoValorCalculado,
		setUltimoValorCalculado,
		ultimaDataAtualizacao,
		setUltimaDataAtualizacao,
		feesOptions,
		setFeesOptions,
		finePercentage,
		setFeesPercentage,
		feesPercentage,
		setFinePercentage,
		allowDuplicateGeneration,
		setAllowDuplicateGeneration,
		daysLimitAfterExpiration,
		setDaysLimitAfterExpiration,
	} = useStateFields();

	const formIsValid = useFormIsValid({
		nome,
		tipoIndice,
		ultimaDataAtualizacao,
		ultimoValorCalculado,
		feesOptions,
		feesPercentage,
		finePercentage,
		allowDuplicateGeneration,
		daysLimitAfterExpiration,
	});

	const contextValue = React.useMemo(
		() => ({
			nome,
			setNome,
			tipoIndice,
			setTipoIndice,
			ultimoValorCalculado,
			setUltimoValorCalculado,
			ultimaDataAtualizacao,
			setUltimaDataAtualizacao,
			feesOptions,
			setFeesOptions,
			finePercentage,
			setFeesPercentage,
			feesPercentage,
			setFinePercentage,
			allowDuplicateGeneration,
			setAllowDuplicateGeneration,
			daysLimitAfterExpiration,
			setDaysLimitAfterExpiration,
			formIsValid,
			loading: false,
			record: null,
			desabilitaInputSeNaoForManual: false,
		}),
		[
			nome,
			setNome,
			tipoIndice,
			setTipoIndice,
			ultimoValorCalculado,
			setUltimoValorCalculado,
			ultimaDataAtualizacao,
			setUltimaDataAtualizacao,
			feesOptions,
			setFeesOptions,
			finePercentage,
			setFeesPercentage,
			feesPercentage,
			setFinePercentage,
			allowDuplicateGeneration,
			setAllowDuplicateGeneration,
			daysLimitAfterExpiration,
			setDaysLimitAfterExpiration,
			formIsValid,
		]
	);
	return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

export const FormEditContextProvider: React.FC<FormContextProvider> = ({ children }) => {
	const {
		nome,
		setNome,
		tipoIndice,
		setTipoIndice,
		ultimoValorCalculado,
		setUltimoValorCalculado,
		ultimaDataAtualizacao,
		setUltimaDataAtualizacao,
		feesOptions,
		setFeesOptions,
		finePercentage,
		setFeesPercentage,
		feesPercentage,
		setFinePercentage,
		allowDuplicateGeneration,
		setAllowDuplicateGeneration,
		daysLimitAfterExpiration,
		setDaysLimitAfterExpiration,
	} = useStateFields();

	const formIsValid = useFormIsValid({
		nome,
		tipoIndice,
		ultimaDataAtualizacao,
		ultimoValorCalculado,
		feesOptions,
		feesPercentage,
		finePercentage,
		allowDuplicateGeneration,
		daysLimitAfterExpiration,
	});

	const { loading, record } = useLoadingData({
		setNome,
		setTipoIndice,
		setUltimoValorCalculado,
		setUltimaDataAtualizacao,
		setFeesOptions,
		setFeesPercentage,
		setFinePercentage,
		setAllowDuplicateGeneration,
		setDaysLimitAfterExpiration,
	});

	const contextValue = React.useMemo(
		() => ({
			nome,
			setNome,
			tipoIndice,
			setTipoIndice,
			ultimoValorCalculado,
			setUltimoValorCalculado,
			ultimaDataAtualizacao,
			setUltimaDataAtualizacao,
			feesOptions,
			setFeesOptions,
			finePercentage,
			setFeesPercentage,
			feesPercentage,
			setFinePercentage,
			allowDuplicateGeneration,
			setAllowDuplicateGeneration,
			daysLimitAfterExpiration,
			setDaysLimitAfterExpiration,
			formIsValid,
			loading,
			record,
			desabilitaInputSeNaoForManual: record?.tipo_indice !== 'MANUAL',
		}),
		[
			nome,
			setNome,
			tipoIndice,
			setTipoIndice,
			ultimoValorCalculado,
			setUltimoValorCalculado,
			ultimaDataAtualizacao,
			setUltimaDataAtualizacao,
			feesOptions,
			setFeesOptions,
			finePercentage,
			setFeesPercentage,
			feesPercentage,
			setFinePercentage,
			allowDuplicateGeneration,
			setAllowDuplicateGeneration,
			daysLimitAfterExpiration,
			setDaysLimitAfterExpiration,
			formIsValid,
			loading,
			record,
		]
	);
	return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

export default FormContext;
