import React from 'react';

import ModalCriarGaragem from './components/modal/create';
import ModalEditarGaragem from './components/modal/edit';
import GaragensList from './components/tableList';

import GaragensUnidadeContextProvider, { GaragensUnidadeContext } from './context';

function CadGaragemScreen(props) {
	const {
		contexto: { openModalEditarGaragem, openModalCreateGaragem },
	} = React.useContext(GaragensUnidadeContext);
	return (
		<>
			<GaragensList {...props} />
			{openModalCreateGaragem && <ModalCriarGaragem {...props} />}
			{openModalEditarGaragem && <ModalEditarGaragem {...props} />}
		</>
	);
}
const CadastroDeGaragensBox = (props) => (
	<GaragensUnidadeContextProvider {...props}>
		<CadGaragemScreen {...props} />
	</GaragensUnidadeContextProvider>
);

export default CadastroDeGaragensBox;
