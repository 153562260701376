import React, { useCallback } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { makeStyles } from '@material-ui/core';

import { DropzoneAreaBase } from '@pandemicode/material-ui-dropzone';

const useStyles = makeStyles({
	gridFileIcons: {
		justifyContent: 'center',
	},
});

const DropZone = ({ filesSelected, setFilesSelected }) => {
	const classes = useStyles();
	const addFiles = useCallback(
		(newFileObjects) => {
			setFilesSelected(newFileObjects);
		},
		[setFilesSelected]
	);
	const deleteFile = useCallback(
		(_, removedFileObjIdx) => {
			setFilesSelected(filesSelected.filter((_, i) => i !== removedFileObjIdx));
		},
		[filesSelected, setFilesSelected]
	);
	return (
		<DropzoneAreaBase
			dropzoneText='Arraste e solte o arquivo no formato .CSV aqui, ou clique'
			fileObjects={filesSelected}
			filesLimit={1}
			getFileLimitExceedMessage={() => 'Número máximo de arquivos excedido, máximo de 1 permitido'}
			previewGridClasses={{ container: classes.gridFileIcons }}
			previewGridProps={{ item: { style: { maxWidth: '10%', padding: '2rem 2.5rem 2rem 2.5rem' } } }}
			getPreviewIcon={(fileObject, classes) => (
				<Tooltip title={fileObject.file.name}>
					<AttachFileIcon className={classes.image} />
				</Tooltip>
			)}
			onAdd={addFiles}
			onDelete={deleteFile}
			showAlerts={['error']}
		/>
	);
};

export default DropZone;
