import React, { createContext, useState, useMemo, useContext, useCallback } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { ProvisionamentoPanelContext } from '../ProvisionamentoPanelContext';

export const ContaAReceberActionsContext = createContext({
	desprovisionar: ({ senha, motivo = null, callbackSuccess } = {}) => {},
	excluir: () => {},
	loading: false,
	provisionar: () => {},
	trocarTipoResponsavel: () => {},
	trocarDataVencimento: () => {},
});

const convercaoTipoResponsavel = {
	P: 'I',
	I: 'P',
};

export const ContaAReceberActionsContextProvider = ({ record = {}, children }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const { setData, refreshContas } = useContext(ProvisionamentoPanelContext);
	const [loading, setLoading] = useState(false);

	const provisionar = useCallback(() => {
		const id = record.id;
		setLoading(true);
		dP.update('cld_recebimento_manual', {
			id: `${id}/provisionar`,
			data: {},
		})
			.then(() => {
				setData((d) => {
					const conta = d[id];
					if (conta) {
						conta.provisionada = true;
						d[id] = conta;
					}
					return { ...d };
				});
				notify('Conta provisionada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			})
			.finally(() => {
				setLoading(false);
			});
	}, [record.id, setLoading, dP, setData, notify]);

	const desprovisionar = useCallback(
		({ senha, motivo, callbackSuccess } = {}) => {
			const id = record.id;
			setLoading(true);
			return dP
				.update('cld_recebimento_manual', {
					id: `${id}/desprovisionar`,
					data: {
						pwd: senha,
						opt_motivo: motivo?.abv,
					},
				})
				.then((response) => {
					const contas_a_receber_removido_nosso_numero_ids =
						response?.data?.contas_a_receber_removido_nosso_numero_ids || [];
					setData((d) => {
						const conta = d[id];
						if (conta) {
							conta.provisionada = false;
							conta.nosso_numero = '';
							d[id] = conta;
						}
						contas_a_receber_removido_nosso_numero_ids.map((c_id) => {
							const conta = d[c_id];
							if (conta) {
								conta.nosso_numero = null;
								d[c_id] = conta;
							}
							return c_id;
						});
						return { ...d };
					});
					notify('Conta desprovisionada com sucesso');
					callbackSuccess();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || {})[0] || [
						'Erro inesperado, tente recarregar a página',
					];
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[record.id, setLoading, dP, setData, notify]
	);

	const trocarTipoResponsavel = useCallback(() => {
		const id = record.id;
		setLoading(true);
		return dP
			.update('cld_recebimento_manual', {
				id: `${id}/trocar_tipo_responsavel`,
				data: {
					tipo_responsavel: convercaoTipoResponsavel[record.tipo_responsavel] || record.tipo_responsavel,
				},
			})
			.then(() => {
				refreshContas();
				notify('Conta alterada com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			})
			.finally(() => {
				setLoading(false);
			});
	}, [record.id, record.tipo_responsavel, setLoading, dP, refreshContas, notify]);

	const trocarDataVencimento = useCallback(
		(novaDataVencimento) => {
			const id = record.id;
			setLoading(true);
			return dP
				.update('cld_recebimento_manual', {
					id: `${id}/trocar_data_vencimento`,
					data: {
						data_vencimento: novaDataVencimento,
					},
				})
				.then(() => {
					refreshContas();
					notify('Conta alterada com sucesso');
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || {})[0] || [
						'Erro inesperado, tente recarregar a página',
					];
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[record.id, setLoading, dP, refreshContas, notify]
	);

	const excluir = useCallback(() => {
		const id = record.id;
		setLoading(true);
		return dP
			.delete('cld_recebimento_manual', {
				id: id,
				data: {},
			})
			.then(() => {
				refreshContas();
				notify('Conta excluída com sucesso');
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			})
			.finally(() => {
				setLoading(false);
			});
	}, [record.id, setLoading, dP, refreshContas, notify]);

	const ContaAReceberActionsProviderValue = useMemo(
		() => ({
			desprovisionar,
			excluir,
			loading,
			provisionar,
			trocarTipoResponsavel,
			trocarDataVencimento,
		}),
		[desprovisionar, excluir, loading, provisionar, trocarTipoResponsavel, trocarDataVencimento]
	);

	return (
		<ContaAReceberActionsContext.Provider value={ContaAReceberActionsProviderValue}>
			{children}
		</ContaAReceberActionsContext.Provider>
	);
};
