import React, { useCallback, useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

const SituacaoFiltro = () => {
	const {
		filtragemRegistros: { situacao },
		setFiltragemRegistros,
	} = useContext(FluxoImportacaoExtratoBancarioContext);

	const handleChange = useCallback(
		(_, newValue) => {
			setFiltragemRegistros((v) => ({ ...v, situacao: newValue }));
		},
		[setFiltragemRegistros]
	);

	return (
		<Autocomplete
			openOnFocus
			value={situacao || null}
			id='situacao-filtro-autocompletar'
			options={[
				{ id: 'C', nome: 'Conciliado' },
				{ id: 'N', nome: 'Lançamento encontrado' },
				{ id: 'I', nome: 'Lançamento não encontrado' },
			]}
			onChange={handleChange}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option.nome}
			getOptionSelected={(option, newValue) => newValue.id === option.id}
			renderInput={(params) => <TextField {...params} label='Filtrar' variant='standard' />}
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						maxWidth: '320px',
						minWidth: '60px',
					}}
				/>
			)}
			fullWidth
			noOptionsText='Nenhum resultado'
			disableListWrap
		/>
	);
};

export default SituacaoFiltro;
