import React from 'react';

import { useListContext, TopToolbar } from 'react-admin';

import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import BoletosContextProvider from '../BoletosContextProvider';

import MenuPadrao from './MenuPadrao';
import MenuGerencianet from './MenuGerencianet';

const MenusPorCodigoInstituicaoETipoIntegracao = {
	'364-0': MenuPadrao,
	'077-0': MenuPadrao,
	'748-0': MenuPadrao,
	'756-0': MenuPadrao,
	'133-0': MenuGerencianet,
	'450-0': MenuPadrao,
	'332-0': MenuPadrao,
	'001-0': MenuPadrao,
};

export const Actions = () => {
	const { data, selectedIds } = useListContext();
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const { identificacaoInstituicaoTipoIntegracao, tem_arrecadacao_provisionada, contaBDestinoIsValid } =
		React.useContext(ArrecadacoesContext);

	const [
		{ registrosSelecionados, idsRegistrosSelecionados, temRegistroSemBoletoSelecionado },
		setDadosRegistrosSelecionados,
	] = React.useState({
		registrosSelecionados: {},
		idsRegistrosSelecionados: [],
		temRegistroSemBoletoSelecionado: false,
	});
	const [anchorEl, setAnchorEl] = React.useState(null);

	const valid = Boolean(tem_arrecadacao_provisionada && contaBDestinoIsValid && condominioSelecionado?.id);
	const temAcessoApp = Boolean(condominioSelecionado?.disponivel_para_kondomynos);
	const disabledDisponibilizar = !!temRegistroSemBoletoSelecionado || !temAcessoApp;

	const handleClick = React.useCallback(
		(e) => {
			setAnchorEl(e.currentTarget);
		},
		[setAnchorEl]
	);
	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);

	const validate = () => {
		setDadosRegistrosSelecionados((dados) => ({
			...dados,
			temRegistroSemBoletoSelecionado: Boolean(
				Object.values(registrosSelecionados).find((r) => r?.situacao_boleto !== 'G')
			),
		}));
	};

	React.useEffect(validate, [tem_arrecadacao_provisionada]);

	React.useEffect(() => {
		setDadosRegistrosSelecionados(({ registrosSelecionados }) => {
			const { registros, ids, temRegistroSemBoletoSelecionado } = selectedIds.reduce(
				({ registros, ids, temRegistroSemBoletoSelecionado }, id) => {
					const registro = data[id] || registrosSelecionados[id];
					if (registro) {
						registros[id] = registro;
						ids.push(id);
						return {
							registros,
							ids,
							temRegistroSemBoletoSelecionado:
								temRegistroSemBoletoSelecionado || registro?.situacao_boleto !== 'G',
						};
					}
					return { registros, ids, temRegistroSemBoletoSelecionado };
				},
				{ registros: {}, ids: [], temRegistroSemBoletoSelecionado: false }
			);
			return {
				registrosSelecionados: registros,
				idsRegistrosSelecionados: ids,
				temRegistroSemBoletoSelecionado: temRegistroSemBoletoSelecionado,
			};
		});
	}, [selectedIds, data, setDadosRegistrosSelecionados]);

	const MenuComponent = React.useMemo(
		() => MenusPorCodigoInstituicaoETipoIntegracao[identificacaoInstituicaoTipoIntegracao] || MenuPadrao,
		[identificacaoInstituicaoTipoIntegracao]
	);

	return (
		<BoletosContextProvider>
			<ModalSizeEditableContextProvider
				customDialogProps={{
					minWidth: '61vw',
					position: 'static',
					overflowY: 'auto',
				}}
			>
				<TopToolbar style={{ padding: 0, alignSelf: 'flex-end', columnGap: 10 }}>
					<Button
						disabled={!valid || !idsRegistrosSelecionados.length}
						onClick={handleClick}
						color='primary'
						startIcon={<SettingsIcon />}
						size='small'
					>
						Opções
					</Button>
					<MenuComponent
						{...{
							anchorEl,
							handleClose,
							idsRegistrosSelecionados,
							disabledDisponibilizar,
							registrosSelecionados,
							temRegistroSemBoletoSelecionado,
						}}
					/>
				</TopToolbar>
			</ModalSizeEditableContextProvider>
		</BoletosContextProvider>
	);
};

export default Actions;
