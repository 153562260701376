import React, { useContext } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../../../common/ModalSizeEditableContext';
import { ButtonRemove } from '../../../../common/buttons/ButtonRemove';
import { ButtonClose } from '../../../../common/buttons/ButtonClose';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import EditaContaBancariaButtonsContext from '../contexts/EditaContaBancariaButtonsContext';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';

const ModalFeedbackBloqueioRemover = ({
	setModalValue,
	usado_em_lancamento,
	usado_em_conta_a_pagar,
	usado_em_arrecadacao,
	usado_em_pagamento_parcela,
}) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBodySizeEditable
			title='Esta Conta Bancária não pode ser removida'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>
							<ul>
								{
									<>
										{usado_em_lancamento && (
											<li>Esta conta bancária está vinculada a ao menos um lançamento</li>
										)}
										{usado_em_conta_a_pagar && (
											<li>Esta conta bancária está vinculada a ao menos uma conta a pagar</li>
										)}
										{usado_em_arrecadacao && (
											<li>Esta conta bancária está vinculada a ao menos uma arrecadação</li>
										)}
										{usado_em_pagamento_parcela && (
											<li>
												Esta conta bancária está vinculada a ao menos uma parcela de uma conta a
												pagar
											</li>
										)}
									</>
								}
							</ul>
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
						}}
						size='small'
					>
						cancelar
					</ButtonClose>
					<ButtonRemove disabled={true} size='small' />
				</>
			}
		/>
	);
};

const ExcluirButton = () => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { handleDelete } = useContext(EditaContaBancariaButtonsContext);
	const {
		condominioRecord,
		initialDados: {
			conta_condominio: {
				usado_em_lancamento,
				usado_em_arrecadacao,
				usado_em_conta_a_pagar,
				usado_em_pagamento_parcela,
			},
		},
	} = useContext(EditaContaBancariaContext);

	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const onClick = () => {
		if (usado_em_lancamento || usado_em_conta_a_pagar || usado_em_arrecadacao || usado_em_pagamento_parcela) {
			setModalValue((v) => ({
				...v,
				open: true,
				dialogBody: (
					<ModalFeedbackBloqueioRemover
						setModalValue={setModalValue}
						usado_em_lancamento={usado_em_lancamento}
						usado_em_conta_a_pagar={usado_em_conta_a_pagar}
						usado_em_arrecadacao={usado_em_arrecadacao}
						usado_em_pagamento_parcela={usado_em_pagamento_parcela}
					/>
				),
			}));
		} else {
			setModalValue((v) => ({
				...v,
				open: true,
				dialogBody: (
					<CustomDialogBodySizeEditable
						title='Tem certeza que deseja remover esta Conta Bancária?'
						text={
							<Box display='flex' alignItems='center' gridGap='20px'>
								<Box>
									<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
								</Box>
								<Box>
									<Typography
										style={{
											fontWeight: 'bold',
											color: syndikosRed.main,
										}}
									>
										Essa ação é irreversível.
										<br />
										Todo o histórico será excluído.
									</Typography>
								</Box>
							</Box>
						}
						customActions={
							<>
								<ButtonCancel onClick={handleClose} />
								<ButtonRemove size='small' onClick={handleDelete} />
							</>
						}
					/>
				),
			}));
		}
	};
	return <ButtonRemove disabled={condominioRecord.situacao !== 'A'} onClick={onClick} />;
};

export default ExcluirButton;
