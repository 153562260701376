import React, { createContext, useCallback, useContext, useEffect } from 'react';

import Box from '@material-ui/core/Box';

import SelectContaBancaria from '../../SelectContaBancaria';
import SelectTipoLancamento from './SelectTipoLancamento';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

export const ModalDetailContext = createContext();

const ConfiguracoesTransferencias = ({ tipoLancamentoFirstRegistro }) => {
	const {
		contaBancariaDestino,
		contaBancariaOrigem,
		contasBancarias,
		contasBancariasData,
		grupo,
		gruposLista,
		setContaBancariaDestino,
		setContaBancariaOrigem,
		setGrupo,
		setUnidade,
		setTipoLancamento,
		unidade,
		unidadesLista,
		tipoLancamento,
	} = useContext(FluxoImportacaoExtratoBancarioContext);

	const init = useCallback(() => {
		if (contaBancariaDestino && contaBancariaDestino?.id === contaBancariaOrigem?.id) setContaBancariaDestino(null);
		if (tipoLancamento === 'U') setTipoLancamento('C');
	}, [contaBancariaDestino, contaBancariaOrigem, setContaBancariaDestino, tipoLancamento, setTipoLancamento]);

	useEffect(init, []);
	return (
		<Box display='flex' flexDirection='column' gridGap='0.5rem'>
			<SelectTipoLancamento
				value={tipoLancamento}
				setValue={setTipoLancamento}
				operacao='transferencia'
				unidade={unidade}
				setUnidade={setUnidade}
				grupo={grupo}
				setGrupo={setGrupo}
				gruposData={gruposLista}
				unidadesData={unidadesLista}
			/>
			<SelectContaBancaria
				value={contaBancariaOrigem}
				setValue={setContaBancariaOrigem}
				disabled={tipoLancamentoFirstRegistro === 'D'}
				disabledConta={contaBancariaDestino}
				label='Conta Origem'
				options={contasBancarias}
				contasData={contasBancariasData}
			/>
			<SelectContaBancaria
				value={contaBancariaDestino}
				setValue={setContaBancariaDestino}
				disabled={tipoLancamentoFirstRegistro === 'R'}
				disabledConta={contaBancariaOrigem}
				label='Conta Destino'
				options={contasBancarias}
				contasData={contasBancariasData}
			/>
		</Box>
	);
};

export default ConfiguracoesTransferencias;
