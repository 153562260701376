import React, { useCallback, useContext } from 'react';

import { PadraoField } from '../../../../../common/filtros/Padrao';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

const HistoricoFiltro = () => {
	const {
		filtragemRegistros: { historico },
		setFiltragemRegistros,
	} = useContext(FluxoImportacaoExtratoBancarioContext);

	const handleChange = useCallback(
		(newValue) => {
			setFiltragemRegistros((v) => ({ ...v, historico: newValue }));
		},
		[setFiltragemRegistros]
	);
	return <PadraoField id='historico-filtro-padrao' handleChange={handleChange} value={historico || ''} />;
};

export default HistoricoFiltro;
