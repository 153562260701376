import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UserMenuItem from '../../common/UserMenuItem';
import { useGetIdentity } from 'react-admin';

const useStyles = makeStyles({
	fake: {
		cursor: 'default',
		opacity: '1',
		'pointer-events': 'none',
	},
});

const MenuItemNomeUser = () => {
	const classes = useStyles();
	const { identity, loading } = useGetIdentity();
	return (
		<UserMenuItem
			extraClasses={[classes.fake]}
			label={loading ? '' : `${identity.nome || ''}`}
			icon={<AccountCircleIcon />}
		/>
	);
};

export default MenuItemNomeUser;
