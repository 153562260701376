import React, { useState } from 'react';
import {
	List,
	TextField,
	ArrayField,
	SingleFieldList,
	ChipField,
	TopToolbar,
	ExportButton,
	Button,
	useNotify,
	useDataProvider,
	useRefresh,
	ReferenceField,
} from 'react-admin';
import { Tabela } from '../../common/Tabela';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextFieldMUI from '@material-ui/core/TextField';
import ButtonMUI from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

const ModalCreate = ({ open, setOpen }) => {
	const [disable, setDisable] = React.useState(false);
	const [nome, setNome] = useState('');
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const handleSubmit = () => {
		dataProvider
			.create('plano_condominio', { data: { ...{ descricao: nome } } })
			.then(() => {
				notify('Plano de condomínio cadastrado com sucesso');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify('Erro ao cadastrar', 'warning');
			});
		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)} fullWidth>
			<DialogTitle>Cadastrar novo Plano de Condomínio</DialogTitle>
			<DialogContent>
				<TextFieldMUI
					label='Nome'
					fullWidth
					variant='outlined'
					value={nome}
					onChange={(e) => {
						setNome(e.target.value);
						setDisable(false);
					}}
					onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
				/>
			</DialogContent>
			<DialogActions>
				<ButtonCancel onClick={() => setOpen(false)} />
				<ButtonMUI
					disabled={disable}
					onClick={() => {
						setDisable(true);
						handleSubmit();
						setNome('');
					}}
					color='primary'
					size='small'
					startIcon={<CheckIcon />}
				>
					Salvar
				</ButtonMUI>
			</DialogActions>
		</Dialog>
	);
};

const TopBar = ({ basePath }) => {
	const [open, setOpen] = useState(false);
	return (
		<TopToolbar>
			<Button basePath={basePath} onClick={() => setOpen(true)} label='Cadastrar'>
				<AddIcon />
			</Button>
			<ExportButton />
			<ModalCreate open={open} setOpen={setOpen} />
		</TopToolbar>
	);
};

export const PlanoCondominioList = (props) => (
	<List {...props} empty={false} bulkActionButtons={false} filter={{ situacao: 'A' }} actions={<TopBar />}>
		<Tabela>
			<TextField source='descricao' label='Nome do Plano' />
			<ArrayField source='condominios' label='Condomínios'>
				<SingleFieldList>
					<ReferenceField reference='condominios' source='id'>
						<ChipField source='nome' variant='outlined' color='primary' />
					</ReferenceField>
				</SingleFieldList>
			</ArrayField>
		</Tabela>
	</List>
);
