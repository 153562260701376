import React from 'react';

import { Title } from 'react-admin';

import Paper from '@material-ui/core/Paper';

import ProvisionamentoForm from './ProvisionamentoForm';
import ContasAReceberParaEditarList from './ContasAReceberParaEditarList';
import { ProvisionamentoPanelContextProvider } from './ProvisionamentoPanelContext';

const ProvisionamentoPanel = ({ title }) => (
	<ProvisionamentoPanelContextProvider>
		<Paper
			style={{
				padding: 15,
				justifyContent: 'space-between',
				height: 'calc(100% - 30px)',
			}}
		>
			{title && <Title title={title} />}
			<ProvisionamentoForm />
			<ContasAReceberParaEditarList />
		</Paper>
	</ProvisionamentoPanelContextProvider>
);

export default ProvisionamentoPanel;
