import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import EditaContaBancariaContext from '../../contexts/EditaContaBancariaContext';
import { nomeContaParser } from '../../utils/formaters_parses';
import EditaContaBancariaTextInput from '../../commons/EditaContaBancariaTextInput';
import EditaContaBancariaSelect from '../../commons/EditaContaBancariaSelect';
import EditaContaBancariaKeyboardDatePicker from '../../commons/EditaContaBancariaKeyboardDatePicker';
import EditaContaBancariaSwitch from '../../commons/EditaContaBancariaSwitch';
import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';
import CurrencyInputSaldoInicial from './CurrencyInputSaldoInicial';

export const SelectTipoConta = () => (
	<EditaContaBancariaSelect
		source='tipo_conta'
		label='Tipo da Conta'
		variant='outlined'
		optionText='text'
		translateChoice={false}
		disabled={true}
	/>
);

export const TextInputNomeConta = () => (
	<EditaContaBancariaTextInput source='nome_conta' name='nome_conta' label='Nome da Conta' parse={nomeContaParser} />
);

export const TextInputApelido = () => (
	<EditaContaBancariaTextInput
		apelido={true}
		source='apelido_conta_bancaria'
		name='apelido_conta_bancaria'
		label='Apelido para Exportação Contábil'
	/>
);

const AutocompleteInstituicaoFinanceira = () => {
	const { TipoContaContext } = useContext(EditaContaBancariaContext);
	const { atualizarDadosInstituicao } = useContext(TipoContaContext);
	return (
		<EditaContaBancariaAutocomplete
			source='id_inst_financeira'
			name='id_inst_financeira'
			id='id_inst_financeira'
			onChange={(e, value, input) => {
				input.onChange(value);
				atualizarDadosInstituicao({ instituicaoCodigo: value.codigo });
			}}
			label='Instituição Financeira'
		/>
	);
};

export const FirstRowFieldsWithInstituicaoFieldTabInformacao = () => (
	<Box display='grid' gridColumnGap='0.5rem' gridTemplateColumns='repeat(3, 33%)'>
		<Box gridColumn='1'>
			<TextInputNomeConta />
		</Box>
		<Box gridColumn='2'>
			<SelectTipoConta />
		</Box>
		<Box gridColumn='3'>
			<AutocompleteInstituicaoFinanceira />
		</Box>
	</Box>
);

export const DatePickerDataSaldoInicial = () => (
	<EditaContaBancariaKeyboardDatePicker
		source='data_saldo_inicial'
		format='dd/MM/yyyy'
		id='dataSaldoInicial'
		name='data_saldo_inicial'
		label='Data Saldo Inicial'
		mask='__/__/____'
		margin='dense'
		variant='inline'
		inputVariant='outlined'
		parse={(value) => {
			try {
				return value.toISOString().split('T')[0];
			} catch {
				return value;
			}
		}}
	/>
);

export { CurrencyInputSaldoInicial };

export const SecondRowFieldsTabInformacao = () => (
	<Box display='grid' gridColumnGap='0.5rem' gridTemplateColumns='repeat(3, 33%)'>
		<Box gridColumn='1'>
			<DatePickerDataSaldoInicial />
		</Box>
		<Box gridColumn='2'>
			<CurrencyInputSaldoInicial />
		</Box>
		<Box gridColumn='3'>
			<TextInputApelido />
		</Box>
	</Box>
);

export const SwitchImprimirSaldoRelatorios = () => (
	<EditaContaBancariaSwitch
		source='imprimir_saldo_relatorios'
		id='imprimir_saldo_relatorios'
		name='imprimir_saldo_relatorios'
		label='Imprimir saldo das contas nos relatórios'
		labelPlacement='end'
	/>
);

export const ThirdRowFieldsTabInformacao = () => (
	<Box flexDirection='row' display='flex'>
		<Box flex={1}>
			<SwitchImprimirSaldoRelatorios />
		</Box>
	</Box>
);

const TabInformacao = () => (
	<Box>
		<FirstRowFieldsWithInstituicaoFieldTabInformacao />
		<SecondRowFieldsTabInformacao />
		<ThirdRowFieldsTabInformacao />
	</Box>
);

export default TabInformacao;
