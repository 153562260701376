import React, { useContext } from 'react';

import ModalConfirmacaoExcluirLote from './ModalConfirmacaoExcluirLote';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import { TooltipIconButtonRemove } from '../../../../common/buttons/ButtonRemove';
import { ModalContext } from '../../../../common/ModalContext';

const BotaoExcluirArrecadacoesLote = ({ ids }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmacaoExcluirLote {...{ ids }} />,
		}));
	};
	return (
		<TooltipIconButtonRemove
			key='key-button-remover-contas-selecionadas-arrecadacoes'
			id='button-remover-contas-selecionadas-arrecadacoes'
			disabled={tem_arrecadacao_provisionada}
			onClick={handleClick}
			size='small'
			title={(ids || []).length > 1 ? 'Excluir contas selecionadas' : 'Excluir conta selecionada'}
		/>
	);
};

export default BotaoExcluirArrecadacoesLote;
