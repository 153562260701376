import React from 'react';

import { CustomDialogBodySizeEditableBase } from 'components/common/ModalSizeEditableV2';
import CadastrarDadosIntegracaoAPIContextProvider from '../../providers/CadastrarDadosIntegracaoAPIContextProvider';
import { Actions as ActionsDefault } from './Actions';

export const ModalCadastrarDadosIntegracaoAPI = ({ Component, Actions = ActionsDefault }) => (
	<CadastrarDadosIntegracaoAPIContextProvider>
		<CustomDialogBodySizeEditableBase
			title='Cadastrar Dados para Integração com API'
			Component={<Component />}
			Actions={<Actions />}
		/>
	</CadastrarDadosIntegracaoAPIContextProvider>
);
