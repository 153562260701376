import React, { useContext, useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
	CreateButton,
	ExportButton,
	TextField,
	TopToolbar,
	List,
	Pagination,
	ReferenceField,
	useListContext,
} from 'react-admin';

import { Autocompletar, SimplesAutocompletar } from '../../common/filtros/Autocompletar';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { Tabela } from '../../common/Tabela';
import { Padrao } from '../../common/filtros/Padrao';

import { SituacaoField } from './fields/SituacaoField';
import { FormatDateField } from './fields/DateField';

import { DateFilter } from './filter/DateFilter';

const TarefasTabela = (props) => {
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const {
		filterValues: { id_condominio, ...rest },
		setFilters,
	} = useListContext();
	const { setCondominioPorId, condominioSelecionado } = useContext(CondominiosContext);

	const initCondominio = () => {
		if (condominioSelecionado?.id && id_condominio !== condominioSelecionado?.id) {
			setFilters({ ...rest, id_condominio: condominioSelecionado?.id });
		}
	};
	useEffect(initCondominio, []);

	const changeCondominio = () => {
		if (id_condominio && id_condominio !== condominioSelecionado?.id) {
			setCondominioPorId(id_condominio);
		}
	};
	useEffect(changeCondominio, [id_condominio]);

	return (
		<div
			style={{
				overflowY: 'hidden',
				overflowX: 'scroll',
				width: 'auto',
				'max-width': `calc(100vw - ${sidebarOpen ? '320px' : '110px'})`,
			}}
		>
			<Tabela rowClick='edit' {...props}>
				<ReferenceField
					source='condominio'
					reference='condominios'
					label='Condomínio'
					link={false}
					sortBy='condominio_nome'
					filtro={
						<Autocompletar
							refName='nome'
							refResource='condominios'
							mostrarInativos={false}
							id='filtro-nome-condominio'
						/>
					}
					minWidth='200px'
				>
					<TextField source='nome' />
				</ReferenceField>
				<TextField
					source='nome_tarefa'
					reference='tarefas_manutencoes'
					label='Tarefa'
					link={false}
					sortBy='nome_tarefa'
					filtro={<Padrao />}
					minWidth='200px'
				/>
				<ReferenceField
					source='principal_responsavel'
					reference='user'
					label='Responsável'
					link={false}
					sortBy='principal_responsavel_nome'
					filtro={<Autocompletar refName='nome' refResource='user' mostrarInativos={false} />}
					minWidth='200px'
				>
					<TextField source='nome' />
				</ReferenceField>
				<SituacaoField
					source='situacao'
					label='Situação'
					filtro={
						<SimplesAutocompletar
							opcoes={[
								{ id: 'A', name: 'Em aberto' },
								{ id: 'C', name: 'Concluídas' },
								{ id: 'V', name: 'Vencidas' },
								{ id: 'EA', name: 'Em Atraso' },
								{ id: 'VH', name: 'Vencendo Hoje' },
							]}
						/>
					}
				/>
				<FormatDateField source='data_entrega' label='Entrega' filtro={<DateFilter />} />
				<FormatDateField source='data_vencimento' label='Vencimento' filtro={<DateFilter />} />
				<FormatDateField source='data_conclusao' label='Conclusão' filtro={<Padrao />} />
			</Tabela>
		</div>
	);
};

const ListActions = (props) => {
	const { className, basePath, total, resource, currentSort, filterValues, exporter } = props;
	return (
		<TopToolbar className={className}>
			<CreateButton basePath={basePath} />
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
		</TopToolbar>
	);
};

export const TarefasManutencoesList = (props) => {
	const { condominioSelecionado } = useContext(CondominiosContext);

	return (
		<List
			{...props}
			actions={<ListActions />}
			empty={false}
			bulkActionButtons={false}
			pagination={
				<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Tarefas por página' />
			}
			filterDefaultValues={{
				id_condominio: condominioSelecionado?.id,
				situacao: 'A',
				situacao_condominio: 'A',
			}}
			sort={{ field: 'condominio_nome', order: 'ASC' }}
		>
			<TarefasTabela {...props} />
		</List>
	);
};
