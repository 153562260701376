import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { useDataProvider, useNotify } from 'react-admin';

import { GoBackButton, SaveButton } from 'components/common/commonComponentsTSX';
import NomeField from '../components/NomeField';
import BoxConfiguracaoMultaEJuros from '../components/BoxConfiguracaoMultaEJuros';
import BoxConfiguracaoIndiceCorrecaoMonetaria from '../components/BoxConfiguracaoIndiceCorrecaoMonetaria';
import { ConfiguracaoDeCorrecaoMonetariaContext, FormContext } from '../contexts';
import { CustomDataProvider } from './types';
import formatRequestPayloadCreate from './formatRequestPayloadCreate';

const Form: React.FC = () => {
	const dp = useDataProvider() as CustomDataProvider;
	const notify = useNotify();
	const { handleSetRedirectContext } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);
	const {
		formIsValid,
		nome,
		setNome,
		tipoIndice,
		ultimaDataAtualizacao,
		ultimoValorCalculado,
		feesOptions,
		feesPercentage,
		finePercentage,
		allowDuplicateGeneration,
		daysLimitAfterExpiration,
	} = React.useContext(FormContext);

	const handleSubmitNewFeesConfig = (e: React.FormEvent) => {
		e.preventDefault();
		if (!formIsValid) return;
		const data = formatRequestPayloadCreate({
			nome,
			tipoIndice,
			ultimoValorCalculado,
			ultimaDataAtualizacao,
			feesOptions,
			feesPercentage,
			finePercentage,
			allowDuplicateGeneration,
			daysLimitAfterExpiration,
		});
		dp.safeCreate('correcao_monetaria', { data })
			.then(() => {
				notify('Configuração criada com sucesso');
				return handleSetRedirectContext('list', null);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao adicionar a configuração!', { type: 'error' });
			});
	};

	const handleGoBack = () => {
		handleSetRedirectContext('list', null);
	};

	return (
		<Box display='flex' flexDirection='column' gridGap='1.25rem' marginTop='1.5rem'>
			<Typography variant='h5' align='left'>
				Cadastrar Nova Configuração de Multa, Juros e Correção Monetária
			</Typography>
			<NomeField nome={nome} setNome={setNome} />
			<BoxConfiguracaoMultaEJuros />
			<BoxConfiguracaoIndiceCorrecaoMonetaria />
			<Box display='flex' justifyContent='flex-start' gridGap='5px'>
				<SaveButton disabled={!formIsValid} onClick={handleSubmitNewFeesConfig}>
					Salvar
				</SaveButton>
				<GoBackButton onClick={handleGoBack}>Voltar</GoBackButton>
			</Box>
		</Box>
	);
};

export default Form;
