import React, { useState, useContext, useMemo, useCallback, useEffect } from 'react';

import ModalContaLeituraNovaLeitura from './componentes/ModalContaLeituraNovaLeitura';
import ModalDecidirConverterFatorNovaLeitura from './componentes/ModalDecidirConverterFatorNovaLeitura';
import ModalFaixaCompletaNovaLeitura from './componentes/ModalFaixaCompletaNovaLeitura';
import ModalFaixaSimplesNovaLeitura from './componentes/ModalFaixaSimplesNovaLeitura';
import ModalFatorConvercaoNovaLeitura from './componentes/ModalFatorConvercaoNovaLeitura';
import ModalLeiturasParaCalculoNovaLeitura from './componentes/ModalLeiturasParaCalculoNovaLeitura';
import ModalMetodoCalculoNovaLeitura from './componentes/ModalMetodoCalculoNovaLeitura';
import ModalNomeNovaLeitura from './componentes/ModalNomeNovaLeitura';
import ModalUnidadesConfiguradas from './componentes/ModalUnidadesConfiguradas';
import ModalPercentualNovaLeitura from './componentes/ModalPercentualNovaLeitura';
import ModalReferenciaNovaLeitura from './componentes/ModalReferenciaNovaLeitura';
import ModalUnidadeMedidaNovaLeitura from './componentes/ModalUnidadeMedidaNovaLeitura';
import NovaLeituraContext from './componentes/NovaLeituraContext';
import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import ModalPercentualLeitura from './componentes/ModalPercentualLeitura';
import FormLeituraPorcentagem from './componentes/ModalConfigLeitura';
import { useDataProvider } from 'react-admin';

const NovaLeituraContextProvider = ({ dadosNovaLeitura: { id_condominio, dados_config } }) => {
	const dP = useDataProvider();
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);

	const [dadosNovaLeitura, setDadosNovaLeitura] = useState({
		id_condominio,
		dados_config,
	});
	const [nomeLeitura, setNomeLeitura] = React.useState('');

	React.useEffect(() => {
		dP.getOne('leitura', {
			id: dadosNovaLeitura.dados_config.leituras_para_calculo_ids,
		}).then((response) => {
			const data = response?.data;
			setNomeLeitura(data.nome);
		});
	}, [dadosNovaLeitura.dados_config.leituras_para_calculo_ids]);

	const [modalStep, setModalStep] = useState('1');

	useEffect(() => {
		if (['1', '2', '3', '4', '5F', '6FK'].includes(modalStep)) setMinWidthModel('40vw');
		if (['5P', '6P', '8FS', '9FC'].includes(modalStep)) setMinWidthModel('61vw');
	}, [setMinWidthModel, modalStep]);

	const [stepComponents] = useState({
		1: <ModalNomeNovaLeitura />,
		2: <ModalContaLeituraNovaLeitura />,
		'1A': <ModalUnidadesConfiguradas condominio={id_condominio} />,
		3: <ModalMetodoCalculoNovaLeitura />,
		4: <ModalReferenciaNovaLeitura />,
		'2RP': <ModalPercentualLeitura />,

		'5P': <ModalLeiturasParaCalculoNovaLeitura />,
		'6P': <ModalPercentualNovaLeitura />,
		'3RP': <FormLeituraPorcentagem />,

		'5F': <ModalUnidadeMedidaNovaLeitura />,

		'6FK': <ModalDecidirConverterFatorNovaLeitura />,
		'7FK': <ModalFatorConvercaoNovaLeitura />,

		'8FS': <ModalFaixaSimplesNovaLeitura />,
		'9FC': <ModalFaixaCompletaNovaLeitura />,
	});

	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false, dialogBody: <></> }));
	}, [setModalValue]);

	const handleCancel = useCallback(() => {
		if (window.confirm('Sair agora irá ocasionar na perda dos dados do cadastro. Deseja sair?')) closeModal();
	}, [closeModal]);

	const novaLeituraProviderValue = useMemo(
		() => ({
			dadosNovaLeitura,
			setDadosNovaLeitura,
			modalStep,
			setModalStep,
			closeModal,
			handleCancel,
			nomeLeitura,
			setNomeLeitura,
		}),
		[
			dadosNovaLeitura,
			setDadosNovaLeitura,
			modalStep,
			setModalStep,
			closeModal,
			handleCancel,
			nomeLeitura,
			setNomeLeitura,
		]
	);

	return (
		<NovaLeituraContext.Provider value={novaLeituraProviderValue}>
			{stepComponents[modalStep]}
		</NovaLeituraContext.Provider>
	);
};

const FluxoModalNovaLeitura = ({ dadosNovaLeitura }) => (
	<NovaLeituraContextProvider dadosNovaLeitura={dadosNovaLeitura} />
);

export default FluxoModalNovaLeitura;
