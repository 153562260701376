import React from 'react';

import { Box, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';
import PeriodoSelect from './SelecionarPeriodo/PeriodoSelect';

const BoxConfigDemonstrativoMensal = () => {
	const {
		opcoesImpressao: {
			imprimir_apenas_acumulado,
			imprimir_devedores_relatorio_mensal,
			imprimir_posicao_demonstrativo_mensal_direita,
		},
		dateRange,
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	const startDate = new Date(dateRange.startDate);
	const endDate = new Date(dateRange.endDate);
	const diffInMonths =
		(endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

	return (
		<Box style={{ marginTop: '0.5em' }}>
			<PeriodoSelect label='Data do Demonstrativo Mensal' />
			<br />
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_posicao_demonstrativo_mensal_direita}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_posicao_demonstrativo_mensal_direita')
							}
							name='imprimir_posicao_demonstrativo_mensal_direita'
						/>
					}
					label='Imprimir o Demonstrativo Mensal à direita'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_apenas_acumulado}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_apenas_acumulado')}
							name='imprimir_apenas_acumulado'
							disabled={diffInMonths > 0 ? false : true}
						/>
					}
					label='Imprimir apenas acumulado dos meses'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_devedores_relatorio_mensal}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_devedores_relatorio_mensal')
							}
							name='imprimir_devedores_relatorio_mensal'
						/>
					}
					label='Imprimir devedores'
				/>
			</FormGroup>
		</Box>
	);
};

export default BoxConfigDemonstrativoMensal;
