import React, { useState, useEffect } from 'react';

import { useDataProvider } from 'react-admin';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { format } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import { RelatorioMonthPicker, TODAY } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';

import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import MultiSelect from 'components/common/Selects';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesImpressao = ({ handleChange, imprimirAssinatura }) => {
	return (
		<>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</FormGroup>
		</>
	);
};

const PlanilhaDeConsumoDivisor = ({ idCondominio, tipoRelatorio }) => {
	const { options: optionsUnidades = [] } = useRequestOptionsUnidades();
	const relacaoUnidadeMedida = {
		m: 'm³',
		k: 'kW/h',
	};
	const dataProvider = useDataProvider();
	const [referencia, setReferencia] = useState(TODAY);
	const [unidades, setUnidades] = useState([]);
	const [leitura, setLeitura] = useState(null);
	const [leiturasData, setLeiturasData] = useState([]);
	const {
		opcoesImpressao: { imprimir_assinatura },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	const requestData = {
		id_condominio: idCondominio,
		referencia: format(referencia, 'MM/yyyy'),
		id_leitura: leitura ? leitura.id : null,
		unidades: unidades.map((u) => u.id),
		imprimir_assinatura: imprimir_assinatura,
		nome_relatorio: tipoRelatorio.action,
	};

	useEffect(() => {
		dataProvider
			.getList('leitura', {
				pagination: { perPage: 1000, page: 1 },
				sort: { field: 'id', order: 'ASC' },
				filter: {
					ignora_metodos: ['Percentual sobre leitura', 'Arrecadação', 'Quantidade de Moradores'],
					id_condominio_id: idCondominio,
					situacao: 'A',
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					setLeitura(null);
					setLeiturasData(data);
				}
			});
	}, [idCondominio, dataProvider]);
	return (
		<BoxDivisor titulo='Planilha de Consumo'>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<RelatorioMonthPicker
						style={{ marginRight: '1em' }}
						label='Referência'
						handleChange={(newValue) => setReferencia(newValue)}
						value={referencia}
						fullWidth
					/>
				</Box>
				<Box width='50%' mr='1em'>
					<Autocomplete
						value={leitura}
						onChange={(_, newValue) => setLeitura(newValue)}
						options={leiturasData}
						getOptionLabel={(o) => {
							const nome = o?.nome || '';
							const unidadeMedida = o?.unidade_medida
								? ` em ${relacaoUnidadeMedida[o.unidade_medida]}`
								: '';
							return `${nome}${unidadeMedida}`;
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Leitura'
								variant='outlined'
								helperText='Este campo é obrigatório'
								error={false}
							/>
						)}
						clearText='Limpar'
						autoHighlight
						noOptionsText='Nenhuma leitura disponível'
						size='small'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<Box width='50%' mr='1em'>
					<MultiSelect
						variant='formatsUnidades'
						options={optionsUnidades}
						value={unidades}
						setValue={setUnidades}
					/>
				</Box>
				<Box>
					<OpcoesImpressao
						handleChange={handleChangeOpcoesImpressao}
						imprimirAssinatura={imprimir_assinatura}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={Boolean(leitura)} />
			</Box>
		</BoxDivisor>
	);
};

export default PlanilhaDeConsumoDivisor;
