import React, { useCallback, useContext, useEffect, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { useTheme } from '@material-ui/core/styles';

import { isAfter, isBefore, format, parse } from 'date-fns';

import { NumberFormatBRL } from '../../../../../common/CurrencyInput';
import FluxoImportacaoExtratoBancarioContext, {
	fuctionsRowIsNotCheckable,
} from '../FluxoImportacaoExtratoBancarioContext';
import DataFiltro from './DataFiltro';
import HistoricoFiltro from './HistoricoFiltro';
import { SituacaoRegistroExtrato } from './Situacao';
import SituacaoFiltro from './SituacaoFiltro';
import ValorFiltro from './ValorFiltro';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';

const parseISOSimples = (string) => parse(string, 'yyyy-MM-dd', new Date());

const columns = [
	{
		align: 'left',
		field: 'data_movimentacao',
		filtro: <DataFiltro />,
		headerName: 'Data',
		width: '20vw',
	},
	{
		align: 'left',
		field: 'historico',
		filtro: <HistoricoFiltro />,
		headerName: 'Descrição',
		width: '30vw',
	},
	{
		align: 'right',
		field: 'valor',
		filtro: <ValorFiltro />,
		headerName: 'Valor',
		width: '15vw',
	},
	{
		align: 'left',
		field: 'situacao',
		filtro: <SituacaoFiltro />,
		headerName: 'Situação',
		width: '15vw',
	},
];

const tabelaValorSituacao = {
	C: 2,
	N: 1,
	I: 3,
};

const TabelaExtratoBancario = () => {
	const theme = useTheme();
	const {
		filtragemRegistros: { data_movimentacao_after, data_movimentacao_before, historico, valor, situacao },
		fRowIsNotCheckable,
		setFRowIsNotCheckable,
		registros,
		registrosConciliaveisSelecionados,
		registrosLancaveisSelecionados,
		setRegistrosConciliaveisSelecionados,
		setRegistrosLancaveisSelecionados,
		ordenacaoRegistros,
		setOrdenacaoRegistros,
	} = useContext(FluxoImportacaoExtratoBancarioContext);
	const [rows, setRows] = useState(Object.values(registros));
	const [total, setTotal] = useState(Object.values(registros).length);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);

	const filtrar = useCallback(
		(lista) => () => {
			return lista.filter((registro) => {
				if (historico && !(registro.historico || '').match(RegExp(historico, 'i'))) return false;
				if (
					valor &&
					(registro.tipo_lancamento === 'R' ? registro.valor : registro.valor * -1) !== parseFloat(valor)
				)
					return false;
				if (situacao && registro.situacao !== situacao.id) return false;
				if (
					data_movimentacao_after &&
					isAfter(registro.data_movimentacao, parseISOSimples(data_movimentacao_after))
				)
					return false;
				if (
					data_movimentacao_before &&
					isBefore(registro.data_movimentacao, parseISOSimples(data_movimentacao_before))
				)
					return false;
				return true;
			});
		},
		[data_movimentacao_after, data_movimentacao_before, historico, valor, situacao]
	);

	const updateRowsFiltradas = useCallback(
		() => setRows(filtrar(Object.values(registros))),
		[setRows, filtrar, registros]
	);

	useEffect(updateRowsFiltradas, [data_movimentacao_after, data_movimentacao_before, historico, valor, situacao]);

	const ordenar = useCallback(
		(lista, field, order) => () => {
			return lista.sort((a, b) => {
				if (field === 'historico') {
					if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
					if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
				}
				if (field === 'valor') {
					const valorA = a.tipo_lancamento === 'D' ? -Number(a[field]) : Number(a[field]);
					const valorB = b.tipo_lancamento === 'D' ? -Number(b[field]) : Number(b[field]);
					if (valorA < valorB) return order === 'asc' ? -1 : 1;
					if (valorA > valorB) return order === 'asc' ? 1 : -1;
				}
				if (field === 'situacao') {
					const valorSituacaoA = tabelaValorSituacao[a[field]] || 0;
					const valorSituacaoB = tabelaValorSituacao[b[field]] || 0;
					if (valorSituacaoA < valorSituacaoB) return order === 'asc' ? -1 : 1;
					if (valorSituacaoA > valorSituacaoB) return order === 'asc' ? 1 : -1;
				}
				if (field === 'data_movimentacao') {
					if (isBefore(a[field], b[field])) return order === 'asc' ? -1 : 1;
					if (isBefore(b[field], a[field])) return order === 'asc' ? 1 : -1;
				}
				return 0;
			});
		},
		[]
	);

	const handleSortClick = useCallback(
		(field) => {
			const newOrder = ordenacaoRegistros.order === 'asc' ? 'desc' : 'asc';
			setOrdenacaoRegistros({ field, order: newOrder });
			setRows((r) => ordenar(r, field, newOrder)());
		},
		[ordenacaoRegistros.order, setOrdenacaoRegistros, setRows, ordenar]
	);

	const reconstroiRowLista = useCallback(
		() =>
			setRows(ordenar(filtrar(Object.values(registros))(), ordenacaoRegistros.field, ordenacaoRegistros.order)()),
		[ordenar, filtrar, registros, ordenacaoRegistros.field, ordenacaoRegistros.order]
	);

	useEffect(reconstroiRowLista, [registros]);

	const handleRowConciliavelClick = useCallback(
		(_, row, isItemSelected) => {
			setRegistrosConciliaveisSelecionados(isItemSelected ? [] : [row]);
		},
		[setRegistrosConciliaveisSelecionados]
	);

	const handleRowLancavelClick = useCallback(
		(_, row, isItemSelected) => {
			if (isItemSelected) {
				setRegistrosLancaveisSelecionados((v) => {
					const newV = v.filter((i) => i.index !== row.index);
					if (!newV.length) setFRowIsNotCheckable(() => fuctionsRowIsNotCheckable.vazio);
					return newV;
				});
			} else {
				setRegistrosLancaveisSelecionados((v) => {
					if (!v.length)
						setFRowIsNotCheckable(() =>
							row.tipo_lancamento === 'D'
								? fuctionsRowIsNotCheckable.negativo
								: fuctionsRowIsNotCheckable.positivo
						);
					if (
						!v.length ||
						(v.find((r) => r.tipo_lancamento === row.tipo_lancamento) &&
							!v.find((r) => r.index === row.index))
					)
						v.push(row);
					return [...v];
				});
			}
		},
		[setRegistrosLancaveisSelecionados, setFRowIsNotCheckable]
	);

	useEffect(() => setTotal(rows.length), [setTotal, rows.length]);

	return (
		<TableContainer>
			<Table size='small'>
				<TableHead>
					<TableRow key='order-row'>
						<TableCell padding='checkbox' />
						{columns.map((c) => (
							<TableCell
								key={c.field}
								sortDirection={ordenacaoRegistros.field === c.field ? ordenacaoRegistros.order : false}
								align={c.align}
							>
								<TableSortLabel
									active={ordenacaoRegistros.field === c.field}
									direction={ordenacaoRegistros.field === c.field ? ordenacaoRegistros.order : 'asc'}
									onClick={() => handleSortClick(c.field)}
								>
									{c.headerName}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
					<TableRow key='filter-row' className='filter-row'>
						<TableCell />
						{columns.map((column) => (
							<TableCell
								key={`${column.field}-filter`}
								style={{ width: column.width }}
								align={column.align}
							>
								{column.filtro && React.cloneElement(column.filtro, {})}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.slice((page - 1) * perPage, page * perPage).map((row) => {
						const isItemSelected =
							row.situacao === 'C'
								? false
								: Boolean(
										(row.situacao === 'I'
											? registrosLancaveisSelecionados
											: registrosConciliaveisSelecionados
										)?.find((r) => r.index === row.index)
								  );
						return (
							<TableRow
								hover={
									!(
										row.situacao === 'C' ||
										(row.situacao === 'I' && !!registrosConciliaveisSelecionados.length) ||
										(row.situacao === 'N' && !!registrosLancaveisSelecionados.length) ||
										fRowIsNotCheckable(row)
									)
								}
								onClick={(e) => {
									if (row.situacao === 'I' && !registrosConciliaveisSelecionados.length)
										handleRowLancavelClick(e, row, isItemSelected);
									if (row.situacao === 'N' && !registrosLancaveisSelecionados.length)
										handleRowConciliavelClick(e, row, isItemSelected);
								}}
								role='checkbox'
								aria-checked={isItemSelected}
								tabIndex={-1}
								key={row.index}
								selected={isItemSelected}
							>
								<TableCell padding='checkbox'>
									<Checkbox
										checked={isItemSelected}
										inputProps={{
											'aria-labelledby': row.index,
										}}
										disabled={
											row.situacao === 'C' ||
											(row.situacao === 'I' && !!registrosConciliaveisSelecionados.length) ||
											(row.situacao === 'N' && !!registrosLancaveisSelecionados.length) ||
											fRowIsNotCheckable(row)
										}
									/>
								</TableCell>
								<TableCell align='left'>{format(row.data_movimentacao, 'dd/MM/yyyy')}</TableCell>
								<TableCell align='left'>{(row.historico || '').slice(0, 90)}</TableCell>
								<TableCell
									align='right'
									style={{
										fontWeight: 'bold',
										color:
											row.tipo_lancamento === 'D'
												? theme.palette.error.dark
												: theme.palette.primary.dark,
									}}
								>
									<NumberFormatBRL
										displayType='text'
										value={row.valor}
										fixedDecimalScale
										onChange={() => {}}
										decimalScale={2}
										prefix={row.tipo_lancamento === 'D' ? '-' : ''}
									/>
								</TableCell>
								<TableCell align='left'>
									<SituacaoRegistroExtrato situacao={row.situacao} />
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<SyndkosTablePagination
				{...{
					handlePageChange,
					page,
					perPage,
					total,
					setPerPage,
				}}
				labelRowsPerPage='Lançamentos do Extrato por página'
			/>
		</TableContainer>
	);
};

export default TabelaExtratoBancario;
