import React, { useContext } from 'react';

import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { ContaAReceberActionsContext } from './ContaAReceberActionsContext';

const ModalConfirmarTrocaResponsavel = () => {
	const { trocarTipoResponsavel, loading } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			{...(loading
				? {
						form: {
							component: (
								<Box display='flex' justifyContent='center'>
									<CircularProgress />
								</Box>
							),
						},
						customActions: <></>,
				  }
				: {
						closeInSubmit: false,
						customActions: (
							<>
								<ButtonCancel onClick={closesModal} />
								<ButtonConfirm
									size='small'
									onClick={() => {
										trocarTipoResponsavel().finally(() => {
											closesModal();
										});
									}}
									variant='text'
								>
									Confirmar
								</ButtonConfirm>
							</>
						),
						text: (
							<Box display='flex' alignItems='center' gridGap='20px'>
								<Box>
									<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
								</Box>
								<Box>
									<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
										Se houver um lançamento de conta a receber compatível com este lançamento
										<br />
										Os valores individuais serão transferidos para ele
										<br />
										Qual vai desprovisiona-lo, fazendo-o perder seu boleto e nosso número
									</Typography>
								</Box>
							</Box>
						),
						title: 'Tem certeza que deseja trocar o responsável desse lançamento de conta a receber?',
				  })}
		/>
	);
};

const BotaoAlterarResponsavel = ({ record }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { loading } = useContext(ContaAReceberActionsContext);
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	const handleClick = () => {
		setMinWidthModel('80vw');
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmarTrocaResponsavel />,
		}));
	};

	return (
		<TooltipIconButton
			disabled={!tem_arrecadacao_provisionada || loading || record?.provisionado}
			size='small'
			title='Trocar Responsável'
			onClick={handleClick}
		>
			<SwapHorizIcon />
		</TooltipIconButton>
	);
};

export default BotaoAlterarResponsavel;
