import React, { useMemo, useReducer } from 'react';

import CadastrarDadosIntegracaoAPIContext from '../contexts/CadastrarDadosIntegracaoAPIContext';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const errorsMsg = {
	client_id: { required: 'O Client ID é requerido' },
	client_secret: { required: 'O Client Secret é requerido' },
	senha_chave_api: { required: 'A Senha da chave de API é requerida' },
	chave_api: { required: 'A Chave da API é requerida' },
	certificado_api: { required: 'O Certificado da API é requerido' },
};

const funcActions = {
	change: (state, { source, value }) => {
		state.values = { ...state.values, [source]: value };
		state.touched[source] = true;
		state.pristine = JSON.stringify(state.values) === JSON.stringify(state.initialValues);

		const newErros = { ...state.errors };
		if (!value) newErros[source] = errorsMsg[source].required || 'Este campo é requerido';
		if (value && newErros[source]) delete newErros[source];

		if (JSON.stringify(newErros) !== JSON.stringify(state.errors)) state.errors = newErros;

		state.valid = !Object.keys(state.errors).length;

		return { ...state };
	},
	save: (state, { isSaving }) => ({ ...state, saving: !!isSaving }),
};

const reducer = (state, action) => (funcActions[action.type] ?? ((state) => state))(state, action);

const getInitial = (callback = (inicialDados) => inicialDados) =>
	callback({
		initialValues: {
			client_id: '',
			client_secret: '',
			senha_chave_api: '',
			chave_api: null,
			certificado_api: null,
		},
		values: {
			client_id: '',
			client_secret: '',
			senha_chave_api: '',
			chave_api: null,
			certificado_api: null,
		},
		errorsMsg,
		errors: {
			client_id: errorsMsg.client_id.required,
			client_secret: errorsMsg.client_secret.required,
			senha_chave_api: errorsMsg.senha_chave_api.required,
			chave_api: errorsMsg.chave_api.required,
			certificado_api: errorsMsg.certificado_api.required,
		},
		touched: {
			client_id: false,
			client_secret: false,
			senha_chave_api: false,
			chave_api: false,
			certificado_api: false,
		},
		pristine: true,
		valid: false,
		saving: false,
	});

const CadastrarDadosIntegracaoAPIContextProvider = ({ children }) => {
	const {
		props: {
			CadastrarDadosIntegracaoAPIButton: { getInicialCallback = (inicialDados) => inicialDados },
		},
	} = React.useContext(DadosPorTipoIntegracaoContext);
	const [{ touched, values, errors, pristine, saving, valid }, updateState] = useReducer(
		reducer,
		getInitial(getInicialCallback)
	);

	const value = useMemo(
		() => ({
			touched,
			values,
			errors,
			valid,
			pristine,
			saving,
			updateValue: ({ source, value }) => updateState({ source, value, type: 'change' }),
			setSaving: (isSaving) => updateState({ isSaving, type: 'save' }),
		}),
		[touched, values, errors, valid, pristine, saving, updateState]
	);

	return (
		<CadastrarDadosIntegracaoAPIContext.Provider value={value}>
			{children}
		</CadastrarDadosIntegracaoAPIContext.Provider>
	);
};

export default CadastrarDadosIntegracaoAPIContextProvider;
