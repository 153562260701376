import React from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { useForm } from 'react-final-form';

import { Box, Typography, useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';
import { ButtonRemove, TooltipIconButtonRemove } from '../../../../../common/buttons/ButtonRemove';
import { ButtonCancel } from '../../../../../common/buttons/ButtonCancel';
import EditaContaBancariaContext from '../../contexts/EditaContaBancariaContext';

export const RemoverCertificadosButton = () => {
	const { red } = useTheme();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { batch, change, getState } = useForm();
	const { initialDados: { conta_condominio: { id } = {} } = {}, isInativo } =
		React.useContext(EditaContaBancariaContext);
	const {
		values: { dados_integracao_api_expires_at },
	} = getState();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	return (
		<TooltipIconButtonRemove
			title='Remover Dados da Integração com API'
			disabled={isInativo || !id || !dados_integracao_api_expires_at}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<CustomDialogBodySizeEditable
							title='Tem certeza que deseja remover o registro dos dados?'
							text={
								<Box display='flex' alignItems='center' gridGap='20px'>
									<Box>
										<WarningIcon style={{ color: red[300] }} fontSize='large' />
									</Box>
									<Box>
										<Typography style={{ fontWeight: 'bold', color: red[300] }}>
											Essa ação é irreversível.
											<br />
											Todos os dados da integração da api serão excluídos.
										</Typography>
									</Box>
								</Box>
							}
							customActions={
								<>
									<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
									<ButtonRemove
										size='small'
										onClick={() => {
											dataProvider
												.delete('dados_integracao_api_conta_bancaria', { id })
												.then(() => {
													batch(() => {
														change('dados_integracao_api_expires_at', null);
														change('emitir_boletos', false);
														change('pagamento_via_sistema', false);
													});
													notify('Dados da Integração da API excluídos com sucesso');
													setModalValue((v) => ({ ...v, open: false }));
												})
												.catch((e) => {
													if ([401, 403].includes(e?.response?.status))
														return Promise.reject(e);

													if ([500].includes(e?.response?.status))
														return notify(
															'Erro inesperado, tente recarregar a página',
															'warning'
														);
													const erroMsg = Object.values(e?.response?.data || {})[0] || [
														'Erro inesperado, tente recarregar a página',
													];
													notify(erroMsg, 'warning');
												});
										}}
									/>
								</>
							}
						/>
					),
				}));
			}}
		/>
	);
};
