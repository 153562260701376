import React from 'react';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';

import { ButtonEnviaEmails } from './ButtonEnviaEmails';

export const CustomDialogNotificacaoBoleto = ({ idsRegistrosSelecionados }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	return (
		<CustomDialogBodySizeEditable
			title={'Boleto publicado ao portal!'}
			text={'Deseja notificar por e-mail que o boleto está disponível?'}
			customActions={
				<>
					<ButtonCancel
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: false,
							}));
						}}
					/>
					<ButtonEnviaEmails idsRegistrosSelecionados={idsRegistrosSelecionados} />
				</>
			}
		/>
	);
};

export default CustomDialogNotificacaoBoleto;
