import {
	iterArrayValidacoes,
	validatesFieldsRequeridosQuandoEmitindoBoletos,
	validateTipoIntegracao,
	validateCarteira,
	validateEspecieTitulo,
	validateMoeda,
	validateEmissaoBoleto,
	validateDistribuicaoBoleto,
	validateNossoNumero,
	validateSequencialArquivo,
	validateLocalPagamento,
	validateSacadorAvalista,
	createValidateLinhas,
	validateTipo,
	validateValor,
	validateDias,
} from '../../../utils/validadoes';

// Funções e utilidades da validação do modal
const listaValidacoesPadroesQuandoEmitindoBoletos = [
	...validatesFieldsRequeridosQuandoEmitindoBoletos,
	validateTipoIntegracao,
	validateCarteira,
	validateEspecieTitulo,
	validateMoeda,
	validateEmissaoBoleto,
	validateEmissaoBoleto,
	validateDistribuicaoBoleto,
	validateDistribuicaoBoleto,
	validateNossoNumero,
	validateSequencialArquivo,
	validateLocalPagamento,
	validateSacadorAvalista,
	createValidateLinhas(),
];

const getValidacoesInstrucoesCobrancaRemessa = ({
	tipo = true,
	valor = true,
	dias = true,
	field,
	valorIsensao,
	nomeCampoLegivel,
	msgTipo,
	msgValor,
	...props
}) => {
	const validacoes = {};
	if (tipo)
		validacoes.tipo = validateTipo({
			field,
			nomeCampoLegivel,
			msg: msgTipo,
			...props,
		});
	if (valor)
		validacoes.valor = validateValor({
			field,
			valorIsensao,
			msg: msgValor,
			...props,
		});
	if (dias)
		validacoes.dias = validateDias({
			field,
			valorIsensao,
			...props,
		});

	return validacoes;
};

const formataNumero = (numero) =>
	({
		[true]: () => numero,
		[numero === 0]: () => 'zero',
	}[true]());

const mensagensErrosPadroes = {
	vazioSemOpcaoIsensao: ({ nomeCampoLegivel, genero = 'o' } = {}) =>
		`O número de dias até ${genero} ${nomeCampoLegivel} ser aplicad${genero} é requerido`,

	maxValueLimitePagamento: ({ nomeCampoLegivel, genero = 'o' } = {}) =>
		`O número de dias até ${genero} ${nomeCampoLegivel} ser aplicad${genero} não deve ser igual ou maior que o número de dias até o limite do pagamento`,
	vazioLimitePagamento: () => 'O número de dias para o limite de pagamento é requerido',
	vazioLimitePagamentoComSemLimiteComoOpcao: () =>
		'Se há um limite de pagamento, o número de dias até ele ser aplicado é requerido',
	vazioLimitePagamentoBaixaDevolver: () =>
		'O número de dias para o limite pagamento é requerido se escolhido baixar/devolver',
	minValueLimitePagamento: ({ nomeCampoLegivel = 'o limite de pagamento', minValue = 0 } = {}) =>
		`O número de dias para ${nomeCampoLegivel} deve ser igual ou maior que ${formataNumero(minValue)}`,
	minValueLimitePagamentoComSemLimiteComoOpcao: ({ minValue = 0 } = {}) =>
		`Se há um limite de pagamento, a quantidade de dias até ele deve ser igual ou maior que ${formataNumero(
			minValue
		)}`,
	minValueLimitePagamentoBaixaDevolver: ({ minValue = 0 } = {}) =>
		`O número de dias para o protesto deve ser igual ou maior que ${formataNumero(
			minValue
		)} se escolhido baixar/devolver`,

	vazioProtesto: ({ nomeCampoLegivel = 'protesto' } = {}) =>
		`O número de dias para o ${nomeCampoLegivel} é requerido se escolhido protestar`,
	minValueProtesto: ({ nomeCampoLegivel = 'protesto', minValue = 0 } = {}) =>
		`O número de dias para o ${nomeCampoLegivel} deve ser igual ou maior que ${formataNumero(
			minValue
		)} se escolhido protestar`,
	maxValueProtesto: ({ nomeCampoLegivel = 'protesto', maxValue = 0 } = {}) =>
		`O número de dias para o ${nomeCampoLegivel} deve ser igual ou menor que ${formataNumero(
			maxValue
		)} se escolhido protestar`,
};

const getValidacoesMulta = ({ tipo = true, valor = true, dias = true, ...props }) =>
	getValidacoesInstrucoesCobrancaRemessa({
		tipo,
		valor,
		dias,
		nomeCampoLegivel: 'multa',
		msgValor: 'Se o boleto não for isento de multa, o valor da multa à ser aplicada é requerido',
		msgErroVazio: 'Se o boleto não for isento de multa, o número de dias até a multa ser aplicada é requerido',
		msgErroMinValue: `O número de dias até a multa ser aplicada deve ser igual ou maior que ${formataNumero(
			props.minValue
		)}`,
		...props,
		field: 'multa',
		valorIsensao: 'ISE',
	});

const getValidacoesJuros = ({ tipo = true, valor = true, dias = true, ...props }) =>
	getValidacoesInstrucoesCobrancaRemessa({
		tipo,
		valor,
		dias,
		nomeCampoLegivel: 'juros',
		msgValor: 'Se o boleto não for isento de juros, o valor dos juros à serem aplicados é requerido',
		msgErroVazio: 'Se o boleto não for isento de juros, o número de dias até os juros serem aplicados é requerido',
		msgErroMinValue: `O número de dias até os juros serem aplicados deve ser igual ou maior que ${formataNumero(
			props.minValue
		)}`,
		msgErroMaxValue:
			'O número de dias até os juros serem aplicados não deve ser igual ou maior que o número de dias até o limite do pagamento',
		...props,
		field: 'juros',
		valorIsensao: 'JIS',
	});

const getValidacoesDesconto = ({ tipo = true, valor = true, dias = true, ...props } = {}) =>
	getValidacoesInstrucoesCobrancaRemessa({
		tipo,
		valor,
		dias,
		nomeCampoLegivel: 'desconto',
		msgValor: 'Se o boleto tiver desconto, o valor do desconto à ser aplicado é requerido',
		msgErroVazio: 'Se o boleto tiver desconto, o número de dias até o desconto deixar de ser aplicado é requerido',
		minValue: 0,
		msgErroMinValue: 'O número de dias até o desconto deixar de ser aplicado deve ser igual ou maior que zero',
		...props,
		valorIsensao: 'DEI',
		field: 'desconto',
	});

const getValidacoesProtesto = ({ tipo = true, valor = false, dias = true, ...props }) =>
	getValidacoesInstrucoesCobrancaRemessa({
		tipo,
		valor,
		dias,
		nomeCampoLegivel: 'protesto/negativação',
		...props,
		field: 'protesto',
		valorIsensao: 'NP',
	});

const getValidacoesLimitePagamento = ({ tipo = true, valor = false, dias = true, ...props }) =>
	getValidacoesInstrucoesCobrancaRemessa({
		tipo,
		valor,
		dias,
		nomeCampoLegivel: 'baixa/devolução',
		valorIsensao: 'SDL',
		msgErroVazio: mensagensErrosPadroes.vazioLimitePagamentoComSemLimiteComoOpcao(),
		minValue: 0,
		msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamento(),
		...props,
		field: 'limite_pagamento',
	});

const getValidacoesInstrucao = ({ tipo = true, valor = false, dias = true, ...props } = {}) =>
	getValidacoesInstrucoesCobrancaRemessa({
		tipo,
		dias,
		valor,
		nomeCampoLegivel: 'instrução',
		minValue: 3,
		msgErroMinValue: 'O número de dias a ser aplicado deve ser igual ou maior que três',
		...props,
		valorIsensao: '00',
		field: 'instrucao_1',
		tipo_field: 'instrucao_1',
		dias_field: 'instrucao_dias',
	});

const getValidateQuandoEmitindoBoletos =
	(validacoes) =>
	({ values, errors, ...props }) =>
		values.emitir_boletos ? iterArrayValidacoes({ ...props, errors, values, validacoes }) : null;

export {
	listaValidacoesPadroesQuandoEmitindoBoletos,
	mensagensErrosPadroes,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	getValidacoesLimitePagamento,
	getValidacoesInstrucao,
	getValidateQuandoEmitindoBoletos,
};

const getValidate =
	({ instituicaoProps: { validacoes = [], ...instituicaoProps }, options, ...props }) =>
	(values) =>
		iterArrayValidacoes({
			...props,
			options: options['DEFAULT'],
			errors: {},
			instituicaoProps,
			values,
			validacoes,
		});

export default getValidate;
