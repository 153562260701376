import React from 'react';

import { useRefresh } from 'react-admin';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';

export const ModalBodyFeedbackBoletosNaoEnviados = ({ boletos_nao_enviados = [] }) => {
	const refresh = useRefresh();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
		refresh();
	};

	return (
		<CustomDialogBodySizeEditable
			title={`${boletos_nao_enviados?.length || 1} dos envios de boletos solicitados já estavam sendo envidos`}
			text='Aguarde os envios serem finalizados para enviar novamente'
			customActions={
				<>
					<ButtonCancel onClick={handleClose}>Fechar</ButtonCancel>
				</>
			}
		/>
	);
};

export default ModalBodyFeedbackBoletosNaoEnviados;
