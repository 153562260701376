import { iterArrayValidacoes } from '../../../utils/validadoes';
const getValidate =
	({
		instituicaoProps: {
			tabContents: tabContentsPorTipoIntegracao,
			validacoes,
			formatadoresPayloadValues: formatadoresPorTipoIntegracao,
			props: propsPorTipoIntegracao,
			...institucaoPropsRest
		},
		options,
		...props
	}: any) =>
	(values: any) => {
		const tipoIntegracaoId = values.tipo_integracao?.id;
		const tabContents = tabContentsPorTipoIntegracao[tipoIntegracaoId];
		const formatadoresPayloadValues = formatadoresPorTipoIntegracao[tipoIntegracaoId];
		const propsDoTipoIntegracao = propsPorTipoIntegracao[tipoIntegracaoId];
		const instituicaoProps = {
			tabContents,
			formatadoresPayloadValues,
			props: propsDoTipoIntegracao,
			...institucaoPropsRest,
		};
		const errors = iterArrayValidacoes({
			...props,
			options: { ...(options[tipoIntegracaoId] ?? {}), ...options['DEFAULT'] },
			errors: {},
			instituicaoProps,
			values,
			validacoes: validacoes[tipoIntegracaoId],
		});
		return errors;
	};

export default getValidate;
