import React from 'react';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'flex',
		flexDirection: 'row',
		gridGap: '0.6em',
		color: theme.font[200],
	},
}));

const UserMenuItem = ({ menuItemProps, label, icon, extraClasses = [] }) => {
	const classes = useStyles();
	return (
		<MenuItem className={clsx(classes.link, [...extraClasses])} {...menuItemProps} component={Link}>
			{icon}
			{label}
		</MenuItem>
	);
};

export default UserMenuItem;
