import React, { useContext } from 'react';
import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import FuncionarioBody from './FuncionarioBody';
import { Box } from '@material-ui/core';
import ListWithDelete from 'components/common/commonComponentsTSX/DeleteList';
import { FuncionarioContext } from './contexts/FuncionarioContext';

const Funcionario: React.FC = () => {
	const { funcionarios, setFuncionarios } = useContext(FuncionarioContext);

	return (
		<Box width={'100%'}>
			<ModalSizeEditableContextProvider
				customDialogProps={{ minWidth: '55vw', overflowY: 'auto', position: 'relative' }}
			>
				<ListWithDelete
					source={'funcionarios'}
					objetoAlterado={funcionarios}
					setObjetoAlterado={setFuncionarios}
				>
					<FuncionarioBody />
				</ListWithDelete>
			</ModalSizeEditableContextProvider>
		</Box>
	);
};

export default Funcionario;
