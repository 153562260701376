import React, { useContext } from 'react';

import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { EnderecoContext } from '../../../../common/EnderecoV2';
import PessoaUtility from './PessoaUtility';

const ModalConfirmarUsoEnderecoDaPessoa = ({ novaSelectedPessoa }) => {
	const { dispatch } = useContext(EnderecoContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);

	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	const handleSubmit = () => {
		const { cep, tipo_logradouro_id, tipo_logradouro, endereco, numero, bairro, cidade, uf, complemento_endereco } =
			novaSelectedPessoa;
		dispatch({
			event: 'changed_values',
			campos_alterados: {
				cep,
				tipo_logradouro_id,
				tipo_logradouro,
				endereco,
				numero,
				bairro,
				cidade,
				uf,
				complemento_endereco,
			},
		});
		closesModal();
	};

	return (
		<CustomDialogBodySizeEditable
			closeInSubmit={false}
			customActions={
				<>
					<ButtonCancel onClick={closesModal} id='button-cancel-endereco-pessoa' />
					<ButtonConfirm
						size='small'
						onClick={handleSubmit}
						variant='text'
						id='button-confirm-endereco-pessoa'
					>
						Confirmar
					</ButtonConfirm>
				</>
			}
			title='Pessoa selecionada alterada, deseja usar o endereço dela no endereço do responsável?'
		/>
	);
};

const BoxPessoaSelect = ({ owner, setValid }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const updateEnderecoDados = (novaSelectedPessoa) => {
		if (novaSelectedPessoa) {
			setModalValue((v) => ({
				...v,
				open: true,
				dialogBody: <ModalConfirmarUsoEnderecoDaPessoa novaSelectedPessoa={novaSelectedPessoa} />,
			}));
		}
	};

	return (
		<PessoaUtility
			{...{
				owner,
				setValid,
				callbackOnChangeSelectedPessoa: updateEnderecoDados,
			}}
		/>
	);
};

export default BoxPessoaSelect;
