/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { cloneElement, ReactElement, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';
import classnames from 'classnames';
import {
	useTranslate,
	useNotify,
	RedirectionSideEffect,
	OnSuccess,
	OnFailure,
	TransformData,
	Record,
	HandleSubmitWithRedirect,
	useSaveContext,
	useFormContext,
} from 'ra-core';

// import { sanitizeButtonRestProps } from 'ra-ui-materialui/src/button/Button';
import { FormRenderProps } from 'react-final-form';
import PessoaSaveModal from './PessoaSaveModal';
import { ModalContext } from 'components/common/ModalContext';
import { useDataProvider } from 'react-admin';
import { AxiosResponse } from 'axios';

const PessoaSaveButton = (props: PessoaSaveButtonProps) => {
	const {
		className,
		classes: classesOverride,
		invalid,
		label = 'ra.action.save',
		disabled,
		redirect,
		saving,
		submitOnEnter,
		variant = 'contained',
		icon = defaultIcon,
		onClick,
		handleSubmitWithRedirect,
		onSave,
		onSuccess,
		onFailure,
		transform,
		...rest
	} = props;
	const classes = useStyles(props);
	const notify = useNotify();
	const translate = useTranslate();
	const formContext = useFormContext();
	const { setOnSuccess, setOnFailure, setTransform } = useSaveContext(props);
	const { initialValues, values } = useFormState();
	const { modalValue, setModalValue } = useContext(ModalContext);
	const dataProvider: CustomDataProvider = useDataProvider();
	const [isActiveInAnyUnidade, setIsActiveInAnyUnidade] = useState(true);

	useEffect(() => {
		dataProvider
			.getOnePkMiddle('pessoa_unidade/enderecamento_unidades_vinculadas', { id: values.id })
			.then((response) => {
				setIsActiveInAnyUnidade(Boolean(response.data.results.length));
			});
	}, [values.unidades]);

	const wasAddressAltered = () => {
		if (
			initialValues.complemento_endereco !== values.complemento_endereco ||
			initialValues.tipo_logradouro !== values.tipo_logradouro ||
			initialValues.endereco !== values.endereco ||
			initialValues.bairro !== values.bairro ||
			initialValues.cidade !== values.cidade ||
			initialValues.numero !== values.numero ||
			initialValues.cep !== values.cep ||
			initialValues.uf !== values.uf
		) {
			return true;
		}
		return false;
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (wasAddressAltered() && !modalValue.open && values.unidades.length > 0 && isActiveInAnyUnidade) {
			setModalValue(() => ({
				dialogBody: <PessoaSaveModal saveCallback={() => save(event)} />,
				open: true,
			}));
		} else {
			save(event);
		}
	};

	const save = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// deprecated: use onSuccess and transform instead of onSave
		if (typeof onSave === 'function') {
			if (process.env.NODE_ENV !== 'production') {
				console.warn('<SaveButton onSave> prop is deprecated, use the onSuccess prop instead.');
				if (!formContext || !formContext.setOnSave) {
					console.warn('Using <SaveButton> outside a FormContext is deprecated.');
				}
			}
			if (formContext && formContext.setOnSave) {
				formContext.setOnSave(onSave);
			}
		} else {
			if (process.env.NODE_ENV !== 'production' && (!formContext || !formContext.setOnSave)) {
				console.warn('Using <SaveButton> outside a FormContext is deprecated.');
			}

			if (formContext && formContext.setOnSave) {
				// we reset to the Form default save function
				formContext.setOnSave();
			}
		}
		if (onSuccess) {
			// @ts-ignore
			setOnSuccess(onSuccess);
		}
		if (onFailure) {
			// @ts-ignore
			setOnFailure(onFailure);
		}
		if (transform) {
			// @ts-ignore
			setTransform(transform);
		}
		if (saving) {
			// prevent double submission
			event.preventDefault();
		} else {
			if (invalid) {
				notify('ra.message.invalid_form', { type: 'warning' });
			}
			// always submit form explicitly regardless of button type
			if (event) {
				event.preventDefault();
			}
			// @ts-ignore
			handleSubmitWithRedirect(redirect);
		}

		if (typeof onClick === 'function') {
			onClick(event);
		}
	};

	const type = submitOnEnter ? 'submit' : 'button';
	const displayedLabel = label && translate(label, { _: label });
	return (
		<>
			<Button
				className={classnames(classes.button, className)}
				variant={variant}
				type={type}
				onClick={handleClick}
				color={saving ? 'default' : 'primary'}
				aria-label={displayedLabel}
				disabled={disabled}
				// {...sanitizeButtonRestProps(rest)}
			>
				{saving ? (
					<CircularProgress size={18} thickness={2} className={classes.leftIcon} />
				) : (
					cloneElement(icon, {
						className: classnames(classes.leftIcon, classes.icon),
					})
				)}
				{displayedLabel}
			</Button>
		</>
	);
};

const defaultIcon = <ContentSave />;

const useStyles = makeStyles(
	(theme) => ({
		button: {
			position: 'relative',
		},
		leftIcon: {
			marginRight: theme.spacing(1),
		},
		icon: {
			fontSize: 18,
		},
	}),
	{ name: 'RaSaveButton' }
);

interface Props {
	classes?: object;
	className?: string;
	handleSubmitWithRedirect?: HandleSubmitWithRedirect | FormRenderProps['handleSubmit'];
	// @deprecated
	onSave?: (values: object, redirect: RedirectionSideEffect) => void;
	onSuccess?: OnSuccess;
	onFailure?: OnFailure;
	transform?: TransformData;
	icon?: ReactElement;
	invalid?: boolean;
	label?: string;
	onClick?: () => void;
	disabled?: boolean;
	redirect?: RedirectionSideEffect;
	saving?: boolean;
	submitOnEnter?: boolean;
	variant?: string;
	// May be injected by Toolbar - sanitized in Button
	basePath?: string;
	handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
	record?: Record;
	resource?: string;
	undoable?: boolean;
}

export type PessoaSaveButtonProps = Props & ButtonProps;

PessoaSaveButton.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object,
	handleSubmitWithRedirect: PropTypes.func,
	// @deprecated
	onSave: PropTypes.func,
	invalid: PropTypes.bool,
	label: PropTypes.string,
	redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
	saving: PropTypes.bool,
	submitOnEnter: PropTypes.bool,
	variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
	icon: PropTypes.element,
};

type CustomDataProvider = {
	getOnePkMiddle: (resource: string, params: { id: number }) => Promise<AxiosResponse>;
} & ReturnType<typeof useDataProvider>;

export default PessoaSaveButton;
