import React, { FormEvent, memo, useContext, useState } from 'react';
import { TextInput, useDataProvider, useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { CustomDataProvider } from 'types/tpyesGlobal';
import { CheckButton, CancelButton2 } from 'components/common/commonComponentsTSX';
import { InputDocumento } from 'components/common/InputDocumento';
import { BoxAddPessoaProps, BoxSelectPessoaProps } from './types';
import { ModalContextProvider } from 'components/common/ModalContext';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { PessoasContext } from 'context/PessoaContextProvider';
import { emailValidacao } from 'fieldControl/email';

export const BoxSelectPessoa: React.FC<BoxSelectPessoaProps> = ({ modoAdd, setModoAdd }) => {
	const { pessoas, pessoaSelecionada, setPessoaSelecionada } = React.useContext(PessoasContext);
	return (
		<Box
			display='flex'
			alignItems='center'
			style={
				modoAdd
					? {
							transition: 'all 200ms ease',
							pointerEvents: 'none',
							opacity: 0.3,
							marginBottom: 16,
					  }
					: { transition: 'all 200ms ease' }
			}
		>
			<Box width='80%' paddingRight='10px'>
				<Autocomplete
					renderInput={(params) => (
						<TextField {...params} fullWidth label='Selecionar Pessoa' variant='outlined' />
					)}
					size='small'
					options={pessoas}
					getOptionLabel={(option) => option.nome}
					onChange={(e, value) => {
						if (value) {
							setPessoaSelecionada(value);
						}
					}}
				/>
			</Box>
			<Button
				color='primary'
				onClick={() => setModoAdd(true)}
				disabled={modoAdd}
				style={{ flex: 1, margin: '8px 0 4px' }}
				variant='outlined'
			>
				Nova Pessoa
			</Button>
		</Box>
	);
};

export const BoxAddPessoa: React.FC<BoxAddPessoaProps> = memo(({ modoAdd, setModoAdd }) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { reloadPessoas } = useContext(PessoasContext);

	return (
		<Box
			flexDirection='column'
			style={
				modoAdd
					? {
							opacity: 1,
							maxHeight: 200,
							transition: 'all 200ms ease',
					  }
					: {
							maxHeight: 0,
							opacity: 0,
							pointerEvents: 'none',
							transition: 'all 200ms ease',
					  }
			}
		>
			<Box flex={1} mr='0.5em'>
				<Form
					onSubmit={(e) => console.log('')}
					render={({ values, form }) => {
						const dp: CustomDataProvider = useDataProvider();
						const notify = useNotify();

						const handleCancel = () => {
							setModoAdd(false);
							form.reset({
								tipo_pessoa: 'F',
							});
						};

						const handleCreate = (e: FormEvent) => {
							e.preventDefault();
							dp.create('pessoas', {
								data: values,
							})
								.then((res) => {
									reloadPessoas();
									notify('Cadastro realizado com sucesso', { type: 'success' });
									setModoAdd(false);
								})
								.catch((e) => {
									if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
									return notify('Ocorreu um erro ao cadastrar a nova pessoa.', { type: 'error' });
								});
						};
						return (
							<>
								<Field
									name='nome'
									render={({ input }) => (
										<Box marginBottom='0.5em'>
											<TextField
												label='Nome'
												name={input.name}
												fullWidth
												margin='dense'
												value={input.value}
												onChange={input.onChange}
											/>
										</Box>
									)}
								/>
								<Box display='flex'>
									<Box paddingRight='5px' width='100%'>
										<InputDocumento
											flexDirection='row'
											resource='pessoas'
											source='documento'
											sourceTipo='tipo_pessoa'
											disabled={condominioSelecionado?.situacao === 'I'}
											variant='outlined'
											fullWidth
										/>
									</Box>
									<Box paddingRight='5px' width='50%'>
										<Field
											name='email_cobranca'
											render={({ input }) => (
												<TextInput
													name={input.name}
													source='email'
													label='Email'
													validate={emailValidacao}
													onChange={input.onChange}
													variant='outlined'
													fullWidth
												/>
											)}
										/>
									</Box>
									<CancelButton2 onClick={handleCancel} />
									<CheckButton onClick={handleCreate} disabled={!values?.nome} />
								</Box>
							</>
						);
					}}
				/>
			</Box>
		</Box>
	);
});

const BoxPessoa: React.FC = () => {
	const [modoAdd, setModoAdd] = useState(false);

	return (
		<>
			<BoxSelectPessoa modoAdd={modoAdd} setModoAdd={setModoAdd} />
			<BoxAddPessoa modoAdd={modoAdd} setModoAdd={setModoAdd} />
		</>
	);
};

export default BoxPessoa;
