import React from 'react';
import { Create } from 'react-admin';

import { ImobiliariaFormulario } from './form';

export const ImobiliariaCreate = (props) => (
	<Create title='Cadastrar Imobiliária' undoable={false} {...props}>
		<ImobiliariaFormulario {...props} />
	</Create>
);
