import React from 'react';

import InstrucoesRemessaFields from '../../../../../commons/InstrucoesRemessaFields';

import { GetOnChangeProps, ChangeProps, IChoiceOption, inputProps } from '../../../../../types';

const emptyProps = {} as any;

const JurosFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaFields
		sourcePrincipal='juros'
		propsTipo={{
			source: 'juros_tipo',
			label: 'Juros',
			...propsTipo,
		}}
		propsValor={{
			source: 'juros_valor',
			decimalScale: 2,
			...propsValor,
		}}
		propsDias={{
			source: 'juros_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoJuros = ({
	sources: { sourceValor } = emptyProps,
	values,
	optionsIsencoesTipo,
}: GetOnChangeProps) => ({
	values: { [sourceValor]: values[sourceValor] },
	sources: { sourceValor },
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoJuros =
	(
		{ sources: { sourceValor, sourceDias } = emptyProps, optionsIsencoesTipo }: GetOnChangeProps,
		change: ChangeProps
	) =>
	(_: any, tipoValue: IChoiceOption, input: inputProps) => {
		input.onChange(tipoValue);
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
			return;
		}
	};

export default JurosFields;
