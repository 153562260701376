import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles(() => ({
	root: {
		'&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
			backgroundColor: 'transparent !important',
		},
	},
}));

const RenderTreeSelectConta = ({ setExpanded, setSelectedConta, selectedConta, ...nodes }) => {
	const classes = useStyles();
	return (
		<MaterialTreeItem
			onClick={() =>
				setExpanded((d) => {
					const newExpanded = d.includes(nodes.id) ? d.filter((i) => i !== nodes.id) : [...d, nodes.id];
					return newExpanded;
				})
			}
			key={nodes.id}
			nodeId={nodes.id}
			onLabelClick={(e) => {
				if (nodes.classe_conta === 'A') {
					e.preventDefault();
					setSelectedConta({ id: nodes.id, nome: nodes.nome });
				}
			}}
			label={
				<>
					<Typography
						style={{
							backgroundColor:
								selectedConta && selectedConta.id === nodes.id ? '#dee3ff' : 'rgba(0,0,0,0)',

							fontWeight:
								(selectedConta && selectedConta.id === nodes.id) || nodes.classe_conta === 'T'
									? 'bold'
									: 'normal',
							'&:hover': {
								backgroundColor: 'rgba(0,0,0,0.2)',
							},
							minHeight: 36,
							display: 'flex',
							alignItems: 'center',
							paddingLeft: '10px',
							borderRadius: '5px',
						}}
						color={selectedConta && selectedConta.id === nodes.id ? 'primary' : 'initial'}
					>
						{nodes?.nome}
					</Typography>
				</>
			}
			classes={{
				root: classes.root,
			}}
		>
			{Array.isArray(nodes.children)
				? nodes.children.map((node) => (
						<RenderTreeSelectConta {...node} {...{ setExpanded, setSelectedConta, selectedConta }} />
				  ))
				: null}
		</MaterialTreeItem>
	);
};

export default RenderTreeSelectConta;
