import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import TabelaRecebimentosPagos from './TabelaRecebimentosPagos';

const ModalDesfazerBaixasParciais = ({ selectedId, recebimentosManuais }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [recebimentosSelecionados, setRecebimentosSelecionados] = useState(
		recebimentosManuais.filter((r) => r.situacao !== 'P')
	);

	const desfazerBaixas = () => {
		dP.update('contas_receber', {
			id: selectedId,
			data: {
				recebimentos_manuais_para_desfazer: recebimentosSelecionados.map((r) => r.id),
			},
		})
			.then(() => {
				const len = recebimentosSelecionados.length;
				const successMsg = len > 1 ? `Desfeito ${len} baixas` : 'Baixa desfeita';
				notify(successMsg + ' com sucesso');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
				refresh();
			});
	};

	const closeModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			title={'Selecionar baixa parcial que deseja desfazer:'}
			customActions={
				<>
					<ButtonCancel onClick={closeModal} />
					<ButtonConfirm onClick={desfazerBaixas} disabled={!recebimentosSelecionados.length}>
						Confirmar
					</ButtonConfirm>
				</>
			}
			form={{
				handleSubmit: () => {},
				component: (
					<TabelaRecebimentosPagos
						recebimentosSelecionados={recebimentosSelecionados}
						setRecebimentosSelecionados={setRecebimentosSelecionados}
						selecionavel={true}
					/>
				),
			}}
		/>
	);
};

export default ModalDesfazerBaixasParciais;
