import React from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { Checkbox, TextField, Box, Tooltip } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export const SelectResponsaveisWithValues = ({ value = [], onChange = () => {} }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const [loading, setLoading] = React.useState(false);
	const [options, setOptions] = React.useState([]);

	const getUserPeople = () => {
		setLoading(true);
		Promise.allSettled([
			dP.getSimple('user').catch(() => {
				notify('Sem usuários cadastradas.', 'warning');
				return [];
			}),
			dP
				.getSimple('pessoas', {
					pagination: {
						perPage: 10000,
						page: 1,
					},
					sort: {
						order: 'ASC',
						field: 'nome',
					},
					filter: {
						situacao: 'A',
					},
				})
				.catch(() => {
					notify('Sem pessoas cadastradas.', 'warning');
					return [];
				}),
		]).then(([users = {}, people = {}]) => {
			const {
				value: {
					data: { results: _users },
				},
			} = users;
			const {
				value: {
					data: { results: _people },
				},
			} = people;

			const _options = changeUserPeopleOption(_users, _people);
			setOptions(_options);
			transformIdToObj(_options);
			setLoading(false);
		});
	};
	React.useEffect(getUserPeople, []);

	const changeUserPeopleOption = (users, people) => [
		...users.map((u) => ({
			...u,
			tipo: 'usuarios',
			render_group_name: 'Usuários',
		})),
		...people.map((u) => ({
			...u,
			tipo: 'pessoas',
			render_group_name: 'Pessoas',
		})),
	];

	const transformIdToObj = (options) => {
		if (value.length) {
			const [_people, _users] = value;
			const selectedPeople = options.filter((o) => _people.includes(o.id));
			const selectedUsers = options.filter((o) => (o.is_active ? _users.includes(o.id) : null));
			const selectedValues = [...selectedPeople, ...selectedUsers];
			onChange(selectedValues);
		}
	};

	return (
		<Box flex={1}>
			<SelectResponsaveis
				value={(options.length && value) || []}
				onChange={onChange}
				options={options}
				loading={loading}
			/>
			<Tooltip title='Selecione todos os Responsáveis'>
				<AddCircleOutlineIcon
					color={'primary'}
					style={{
						marginTop: '10px',
						marginRight: '20px',
						marginLeft: '20px',
					}}
					onClick={() => {
						onChange(options);
					}}
				/>
			</Tooltip>
			<Tooltip title='Remova todos os Responsáveis'>
				<RemoveCircleOutlineIcon
					color={'primary'}
					style={{
						marginTop: '10px',
						marginRight: '20px',
						marginLeft: '20px',
					}}
					onClick={() => {
						onChange([]);
					}}
				/>
			</Tooltip>
		</Box>
	);
};

export const SelectResponsaveis = ({ value = [], onChange = () => {}, loading, options, ...props }) => {
	const formatOptionLabel = (option) => option?.nome;
	const filterOptions = createFilterOptions({
		limit: 30,
		stringify: (option) => option.nome,
	});

	const checkGroup = (group) => {
		const [_groupLength, _selectedGroupLength] = [options, value].map(
			(v) => v.filter((c) => c.render_group_name === group).length
		);
		return _groupLength === _selectedGroupLength;
	};

	const selectGroup = (group) => {
		const [_groupedOptions, _selectedGroupOptions] = [options, value].map((v) =>
			v.filter((c) => c.render_group_name === group)
		);
		const valueFiltered = value.filter((c) => c.render_group_name !== group);
		_selectedGroupOptions.length > 0 ? onChange(valueFiltered) : onChange([...value, ..._groupedOptions]);
	};

	return (
		<Box flex={1}>
			<Autocomplete
				disableCloseOnSelect
				autoHighlight
				fullWidth
				multiple
				disabled={loading}
				size='small'
				value={value}
				filterOptions={filterOptions}
				groupBy={(option) => (option?.tipo === 'usuarios' ? 'Usuários' : 'Pessoas')}
				onChange={(_, v) => {
					onChange(v);
				}}
				options={options}
				getOptionLabel={(option) => formatOptionLabel(option)}
				renderOption={(option, { selected }) => (
					<>
						<Checkbox checked={selected} />
						{formatOptionLabel(options.find((v) => v.id === option)) || formatOptionLabel(option)}
					</>
				)}
				renderInput={(params) => (
					<TextField {...params} variant='outlined' label='Selecionar responsáveis do ambiente:' />
				)}
				renderGroup={(option) => (
					<>
						<Checkbox
							key={option.group}
							checked={checkGroup(option.group)}
							onChange={() => selectGroup(option.group)}
						/>
						<span>{option.group}</span>
						{option.children}
					</>
				)}
				clearText='Limpar'
				noOptionsText='Sem opções cadastradas.'
				{...props}
			/>
		</Box>
	);
};
