import React from 'react';

import { Box, Checkbox, FormLabel, FormGroup, FormControlLabel, Divider, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

import { ConfigRelatorioContext } from '../ConfigRelatorioContextProvider';

const DadosDoEscritorio = React.memo(() => {
	const {
		opcoesImpressao: {
			gerar_relatorio_sem_cabecalho_rodape,
			imprimir_dados_escritorio_no_cabecalho,
			imprimir_dados_escritorio_nome,
			imprimir_dados_escritorio_apelido,
			nao_imprimir_dados_escritorio_nome,
			imprimir_dados_escritorio_cnpj,
			imprimir_dados_escritorio_cep,
			imprimir_dados_escritorio_endereco,
			imprimir_dados_escritorio_tipo_logradouro,
			imprimir_dados_escritorio_numero,
			imprimir_dados_escritorio_bairro,
			imprimir_dados_escritorio_cidade,
			imprimir_dados_escritorio_estado,
			imprimir_dados_escritorio_telefone,
			imprimir_dados_escritorio_email,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções dos Dados do Escritório</FormLabel>
			<RadioGroup row>
				<FormControlLabel
					value='imprimir_dados_escritorio_nome'
					control={
						<Radio
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_nome}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_nome');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_apelido');
								handleChangeOpcoesImpressao(false, 'nao_imprimir_dados_escritorio_nome');
							}}
							name='imprimir_dados_escritorio_nome'
						/>
					}
					label='Imprimir Nome'
				/>
				<FormControlLabel
					value='imprimir_dados_escritorio_apelido'
					control={
						<Radio
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_apelido}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_apelido');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_nome');
								handleChangeOpcoesImpressao(false, 'nao_imprimir_dados_escritorio_nome');
							}}
							name='imprimir_dados_escritorio_apelido'
						/>
					}
					label='Imprimir Apelido'
				/>
				<FormControlLabel
					value='nao_imprimir_dados_escritorio_nome'
					control={
						<Radio
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={nao_imprimir_dados_escritorio_nome}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'nao_imprimir_dados_escritorio_nome');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_nome');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_apelido');
							}}
							name='nao_imprimir_dados_escritorio_nome'
						/>
					}
					label='Não Imprimir Nome'
				/>
			</RadioGroup>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_cnpj}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_cnpj')
							}
							name='imprimir_dados_escritorio_cnpj'
						/>
					}
					label='Imprimir CNPJ'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_cep}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_cep')
							}
							name='imprimir_dados_escritorio_cep'
						/>
					}
					label='Imprimir CEP'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_endereco}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_endereco')
							}
							name='imprimir_dados_escritorio_endereco'
						/>
					}
					label='Imprimir Endereço'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_tipo_logradouro}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_tipo_logradouro')
							}
							name='imprimir_dados_escritorio_tipo_logradouro'
						/>
					}
					label='Imprimir Tipo do Logradouro'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_numero}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_numero')
							}
							name='imprimir_dados_escritorio_numero'
						/>
					}
					label='Imprimir Número'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_bairro}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_bairro')
							}
							name='imprimir_dados_escritorio_bairro'
						/>
					}
					label='Imprimir Bairro'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_cidade}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_cidade')
							}
							name='imprimir_dados_escritorio_cidade'
						/>
					}
					label='Imprimir Cidade'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_estado}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_estado')
							}
							name='imprimir_dados_escritorio_estado'
						/>
					}
					label='Imprimir Estado/UF'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_telefone}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_telefone')
							}
							name='imprimir_dados_escritorio_telefone'
						/>
					}
					label='Imprimir Telefone'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_email}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_email')
							}
							name='imprimir_dados_escritorio_email'
						/>
					}
					label='Imprimir E-mail'
				/>
				{/* <FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_site}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_site')
							}
							name='imprimir_dados_escritorio_site'
						/>
					}
					label='Imprimir site'
				/> */}
			</FormGroup>
		</Box>
	);
});

const OpcoesDoEscritorio = React.memo(() => {
	const {
		opcoesImpressao: {
			gerar_relatorio_sem_cabecalho_rodape,
			imprimir_dados_escritorio_no_cabecalho,
			imprimir_dados_escritorio_apenas_primeira_pagina,
			imprimir_dados_escritorio_alinhado_centralizado,
			imprimir_logotipo_do_escritorio,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_no_cabecalho}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_no_cabecalho');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_apenas_primeira_pagina');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_alinhado_centralizado');
								handleChangeOpcoesImpressao(false, 'imprimir_logotipo_do_escritorio');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_cnpj');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_cep');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_endereco');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_tipo_logradouro');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_numero');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_bairro');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_cidade');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_estado');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_telefone');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_escritorio_email');
							}}
							name='imprimir_dados_escritorio_no_cabecalho'
						/>
					}
					label='Imprimir Dados do Escritório no cabeçalho'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_apenas_primeira_pagina}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(
									checked,
									'imprimir_dados_escritorio_apenas_primeira_pagina'
								);
							}}
							name='imprimir_dados_escritorio_apenas_primeira_pagina'
						/>
					}
					label='Imprimir apenas na primeira página'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_escritorio_alinhado_centralizado}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_escritorio_alinhado_centralizado');
							}}
							name='imprimir_dados_escritorio_alinhado_centralizado'
						/>
					}
					label='Imprimir centralizado'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_escritorio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_logotipo_do_escritorio}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_logotipo_do_escritorio')
							}
							name='imprimir_logotipo_do_escritorio'
						/>
					}
					label='Imprimir logotipo do escritório'
				/>
			</FormGroup>
		</Box>
	);
});

const DadosDoCondominio = React.memo(() => {
	const {
		opcoesImpressao: {
			gerar_relatorio_sem_cabecalho_rodape,
			imprimir_dados_condominio_no_cabecalho,
			imprimir_dados_condominio_codigo,
			imprimir_dados_condominio_nome,
			imprimir_dados_condominio_apelido,
			nao_imprimir_dados_condominio_nome,
			imprimir_dados_condominio_cnpj,
			imprimir_dados_condominio_cep,
			imprimir_dados_condominio_bairro,
			imprimir_dados_condominio_cidade,
			imprimir_dados_condominio_estado,
			imprimir_dados_condominio_endereco,
			imprimir_dados_condominio_numero,
			imprimir_dados_condominio_logradouro,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções dos Dados do Condomínio</FormLabel>
			<RadioGroup row>
				<FormControlLabel
					value='imprimir_dados_condominio_nome'
					control={
						<Radio
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_nome}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_nome');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_apelido');
								handleChangeOpcoesImpressao(false, 'nao_imprimir_dados_condominio_nome');
							}}
							name='imprimir_dados_condominio_nome'
						/>
					}
					label='Imprimir Nome'
				/>
				<FormControlLabel
					value='imprimir_dados_condominio_apelido'
					control={
						<Radio
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_apelido}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_apelido');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_nome');
								handleChangeOpcoesImpressao(false, 'nao_imprimir_dados_condominio_nome');
							}}
							name='imprimir_dados_condominio_apelido'
						/>
					}
					label='Imprimir Apelido'
				/>
				<FormControlLabel
					value='nao_imprimir_dados_condominio_nome'
					control={
						<Radio
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={nao_imprimir_dados_condominio_nome}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'nao_imprimir_dados_condominio_nome');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_nome');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_apelido');
							}}
							name='nao_imprimir_dados_condominio_nome'
						/>
					}
					label='Não Imprimir Nome'
				/>
			</RadioGroup>

			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_cnpj}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_cnpj')
							}
							name='imprimir_dados_condominio_cnpj'
						/>
					}
					label='Imprimir CNPJ'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_cep}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_cep')
							}
							name='imprimir_dados_condominio_cep'
						/>
					}
					label='Imprimir CEP'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_endereco}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_endereco')
							}
							name='imprimir_dados_condominio_endereco'
						/>
					}
					label='Imprimir Endereço'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_logradouro}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_logradouro')
							}
							name='imprimir_dados_condominio_logradouro'
						/>
					}
					label='Imprimir Tipo do Logradouro'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_numero}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_numero')
							}
							name='imprimir_dados_condominio_numero'
						/>
					}
					label='Imprimir Número'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_bairro}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_bairro')
							}
							name='imprimir_dados_condominio_bairro'
						/>
					}
					label='Imprimir Bairro'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_cidade}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_cidade')
							}
							name='imprimir_dados_condominio_cidade'
						/>
					}
					label='Imprimir Cidade'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_estado}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_estado')
							}
							name='imprimir_dados_condominio_estado'
						/>
					}
					label='Imprimir Estado/UF'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_codigo}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_codigo')
							}
							name='imprimir_dados_condominio_codigo'
						/>
					}
					label='Imprimir Código'
				/>
			</FormGroup>
		</Box>
	);
});

const OpcoesDoCondominio = React.memo(() => {
	const {
		opcoesImpressao: {
			gerar_relatorio_sem_cabecalho_rodape,
			imprimir_dados_condominio_no_cabecalho,
			imprimir_dados_condominio_apenas_primeira_pagina,
			imprimir_dados_condominio_alinhado_centralizado,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_no_cabecalho}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_no_cabecalho');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_apenas_primeira_pagina');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_alinhado_centralizado');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_codigo');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_cnpj');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_cep');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_bairro');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_cidade');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_estado');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_endereco');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_numero');
								handleChangeOpcoesImpressao(false, 'imprimir_dados_condominio_logradouro');
							}}
							name='imprimir_dados_condominio_no_cabecalho'
						/>
					}
					label='Imprimir Dados do Condomínio no cabeçalho'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_apenas_primeira_pagina}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(
									checked,
									'imprimir_dados_condominio_apenas_primeira_pagina'
								);
							}}
							name='imprimir_dados_condominio_apenas_primeira_pagina'
						/>
					}
					label='Imprimir apenas na primeira página'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_dados_condominio_no_cabecalho || gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_dados_condominio_alinhado_centralizado}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_dados_condominio_alinhado_centralizado');
							}}
							name='imprimir_dados_condominio_alinhado_centralizado'
						/>
					}
					label='Imprimir centralizado'
				/>
			</FormGroup>
		</Box>
	);
});

const OpcoesDoRelatorio = React.memo(() => {
	const {
		opcoesImpressao: { gerar_relatorio_sem_cabecalho_rodape, imprimir_relatorio_caixa_alta },
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções do Relatório</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={gerar_relatorio_sem_cabecalho_rodape}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'gerar_relatorio_sem_cabecalho_rodape');
								handleChangeOpcoesImpressao(false, 'imprimir_cabecalho_caixa_alta');
								handleChangeOpcoesImpressao(false, 'imprimir_numero_pagina_rodape');
								handleChangeOpcoesImpressao(false, 'alterar_desc_pagina_p_folha');
								handleChangeOpcoesImpressao(false, 'imprimir_data_e_hora');
								handleChangeOpcoesImpressao(false, 'imprimir_nome_usuario_gerou_relatorio');
							}}
							name='gerar_relatorio_sem_cabecalho_rodape'
						/>
					}
					label='Gerar relatório sem cabeçalho e rodapé'
				/>

				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_relatorio_caixa_alta}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_relatorio_caixa_alta')
							}
							name='imprimir_relatorio_caixa_alta'
						/>
					}
					label='Imprimir nome das colunas em caixa alta'
				/>
			</FormGroup>
		</Box>
	);
});

const OpcoesDoCabecalho = React.memo(() => {
	const {
		opcoesImpressao: { gerar_relatorio_sem_cabecalho_rodape, imprimir_cabecalho_caixa_alta },
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções do Cabeçalho</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!!gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_cabecalho_caixa_alta}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_cabecalho_caixa_alta');
								handleChangeOpcoesImpressao(false, 'gerar_relatorio_sem_cabecalho_rodape');
							}}
							name='imprimir_cabecalho_caixa_alta'
						/>
					}
					label='Imprimir em caixa alta'
				/>
			</FormGroup>
		</Box>
	);
});

const OpcoesDePaginacao = React.memo(() => {
	const {
		opcoesImpressao: {
			imprimir_numero_pagina_rodape,
			alterar_desc_pagina_p_folha,
			gerar_relatorio_sem_cabecalho_rodape,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções de Paginação</FormLabel>
			<FormGroup>
				{/* !!! Pode ser usado no futuro para configurações de frente e verso

				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_relatorio_frente_verso}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_relatorio_frente_verso');
								handleChangeOpcoesImpressao(false, 'imprimir_numero_pagina_verso');
								handleChangeOpcoesImpressao(false, 'imprimir_verso_pagina_p_numeracao');
							}}
							name='imprimir_relatorio_frente_verso'
						/>
					}
					label='Imprimir relatório frente e verso'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_relatorio_frente_verso}
							checked={imprimir_numero_pagina_verso}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_numero_pagina_verso');
								handleChangeOpcoesImpressao(true, 'imprimir_relatorio_frente_verso');
							}}
							name='imprimir_numero_pagina_verso'
						/>
					}
					label='Imprimir número da página no verso'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!imprimir_relatorio_frente_verso}
							checked={imprimir_verso_pagina_p_numeracao}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_verso_pagina_p_numeracao');
								handleChangeOpcoesImpressao(true, 'imprimir_relatorio_frente_verso');
							}}
							name='imprimir_verso_pagina_p_numeracao'
						/>
					}
					label='Imprimir verso da página para numeração'
				/> */}
				<FormControlLabel
					control={
						<Checkbox
							disabled={!!gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_numero_pagina_rodape}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'imprimir_numero_pagina_rodape');
								handleChangeOpcoesImpressao(false, 'alterar_desc_pagina_p_folha');
							}}
							name='imprimir_numero_pagina_rodape'
						/>
					}
					label='Imprimir número da página no rodapé'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!!gerar_relatorio_sem_cabecalho_rodape || !imprimir_numero_pagina_rodape}
							checked={alterar_desc_pagina_p_folha}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'alterar_desc_pagina_p_folha')
							}
							name='alterar_desc_pagina_p_folha'
						/>
					}
					label='Alterar descrição de página para folha'
				/>
			</FormGroup>
		</Box>
	);
});

const OpcoesDoRodape = React.memo(() => {
	const {
		opcoesImpressao: {
			imprimir_data_e_hora,
			imprimir_nome_usuario_gerou_relatorio,
			gerar_relatorio_sem_cabecalho_rodape,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções do Rodapé</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!!gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_data_e_hora}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_data_e_hora')}
							name='imprimir_data_e_hora'
						/>
					}
					label='Imprimir data e hora'
				/>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!!gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_nome_usuario_gerou_relatorio}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_nome_usuario_gerou_relatorio')
							}
							name='imprimir_nome_usuario_gerou_relatorio'
						/>
					}
					label='Imprimir nome do usuário que gerou o relatório'
				/>
			</FormGroup>
		</Box>
	);
});

const OpcoesDeImpressao = React.memo(() => {
	const {
		opcoesImpressao: { imprimir_marca_dagua, gerar_relatorio_sem_cabecalho_rodape },
		handleChangeOpcoesImpressao,
	} = React.useContext(ConfigRelatorioContext);

	return (
		<Box flex flexDirection='column' style={{ 'min-width': '300px' }}>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							disabled={!!gerar_relatorio_sem_cabecalho_rodape}
							checked={imprimir_marca_dagua}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_marca_dagua')}
							name='imprimir_marca_dagua'
						/>
					}
					label="Imprimir marca d'água nos Relatórios"
				/>
			</FormGroup>
		</Box>
	);
});

export const OpcoesImpressaoCheckboxGroup = React.memo(() => (
	<Box display='flex' flexDirection='column'>
		<Box style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
			<Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
				<DadosDoEscritorio />
				<OpcoesDoEscritorio />
			</Box>
			<Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
				<DadosDoCondominio />
				<OpcoesDoCondominio />
			</Box>
			<Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
				<OpcoesDoRelatorio />
				<OpcoesDoCabecalho />
				<OpcoesDePaginacao />
				<OpcoesDoRodape />
				<OpcoesDeImpressao />
			</Box>
		</Box>
	</Box>
));
