import memoize from '../../../../common/memoize';
import { formatAgenciaObject } from './formaters_parses';

export const emptyProps = {
	instituicaoCodigo: NaN,
	getDisabledTabs: () => ({}),
	tabContents: {},
	props: {},
	formatarValues: () => ({}),
	validate: () => ({}),
	initialValues: {},
	options: {},
	optionsPorInstituicao: {},
};

export const formatarInstituicoesOptions = (instituicao_financeira, integracoes = {}) => {
	const instituicoesObject = (instituicao_financeira ?? []).reduce((obj, { id, codigo, nome }) => {
		obj[id] = {
			id,
			codigo: codigo,
			text: integracoes[codigo]?.TEM_INTEGRACAO_AUTOMATICA?.possui
				? `${codigo} - ${nome} - Integração automática - via API`
				: `${codigo} - ${nome}`,
		};
		return obj;
	}, {});

	return {
		choices: Object.values(instituicoesObject),
		objects: instituicoesObject,
	};
};

const getExtrasPadrao = ({ props }) => props;

export const getFunctionsFormatarProps = (propsByInstituicao) => {
	const formatProps = (initialDados, { optionsPorInstituicao, initialValues, ...state }, instituicaoCodigo) => {
		const tipoContaProps = propsByInstituicao[instituicaoCodigo];
		if (!tipoContaProps)
			return {
				...emptyProps,
				optionsPorInstituicao,
			};

		const {
			getFormataPayloadValues,
			getValidate,
			formatarInitialValues,
			getOptions,
			getExtras = getExtrasPadrao,
			...props
		} = tipoContaProps;

		const options = getOptions(initialDados, { optionsPorInstituicao, ...state });

		if (!optionsPorInstituicao[instituicaoCodigo]) optionsPorInstituicao[instituicaoCodigo] = options;

		const extras = getExtras({ props, initialDados, instituicaoCodigo });

		const { objects: tipoIntegracaoObjs = {} } = options['DEFAULT']?.tipo_integracao ?? {};

		const tipo_integracao = initialDados?.conta_condominio?.tipo_integracao;

		return {
			...extras,
			instituicaoCodigo,
			optionsPorInstituicao: optionsPorInstituicao,
			formatarValues: getFormataPayloadValues({
				instituicaoProps: props,
				...initialDados,
			}),
			initialValues:
				initialValues ??
				formatarInitialValues({
					...initialDados,
					instituicaoProps: props,
					options: { ...(options[tipoIntegracaoObjs[tipo_integracao]?.id] ?? {}), ...options['DEFAULT'] },
				}),
			options: options,
			validate: getValidate({ options, instituicaoProps: props, extras, ...initialDados }),
		};
	};

	const getInit =
		(initialDados) =>
		({ instituicaoCodigo, ...state }) =>
			formatProps(initialDados, state, instituicaoCodigo);

	const getReducer =
		(initialDados) =>
		(state, { instituicaoCodigo }) => {
			if (instituicaoCodigo === state.instituicaoCodigo) return state;
			return formatProps(initialDados, state, instituicaoCodigo);
		};

	return {
		formatProps,
		getInit,
		getReducer,
	};
};

const reduceArrayInChoicesObject = (array = [], objFormatterFunc = (o) => o) => {
	if (!Array.isArray(array)) {
		array = [array];
	}
	const obj = array?.reduce((dict, op) => {
		op = objFormatterFunc(op);
		dict[op.id] = op;
		return dict;
	}, {});

	return {
		choices: Object.values(obj),
		objects: obj,
	};
};

const getBeneficiarioOptions = () => {
	const obj = {
		BCD: { id: 'BCD', text: 'Condomínio' },
		BCB: { id: 'BCB', text: 'Conta Bancária' },
		BCA: { id: 'BCA', text: 'Administradora' },
	};

	return {
		choices: Object.values(obj),
		objects: obj,
	};
};

const traducaoChaveOpcoesPorTipoIntegracaoParaIdTipoIntegracao = {
	AUTOMATICO: 0,
	CNAB240: 1,
	CNAB400: 2,
};

export const formataOptionsPadrao = ({ state, instituicaoCodigo, opcoesDadosBancarios, optionsDefault, agencia }) => {
	if (state.optionsPorInstituicao[instituicaoCodigo]) return state.optionsPorInstituicao[instituicaoCodigo];

	const opcoesPorTipoIntegracao = opcoesDadosBancarios.integracoes[instituicaoCodigo].options || {};
	const options = Object.entries(opcoesPorTipoIntegracao).reduce(
		(opcoesPorTipoIntegracao, [tipoIntegracao, opcoes]) => {
			const tipoIntegracaoId =
				traducaoChaveOpcoesPorTipoIntegracaoParaIdTipoIntegracao[tipoIntegracao] ?? tipoIntegracao;
			opcoesPorTipoIntegracao[tipoIntegracaoId] = Object.entries(opcoes).reduce((options, [key, array]) => {
				options[key] = reduceArrayInChoicesObject(array);
				return options;
			}, {});
			return opcoesPorTipoIntegracao;
		},
		{}
	);

	options['DEFAULT'] = { ...optionsDefault, ...(options['DEFAULT'] || {}) };
	options['DEFAULT'].agencia = reduceArrayInChoicesObject(opcoesDadosBancarios[agencia], formatAgenciaObject);
	options['DEFAULT'].beneficiario = getBeneficiarioOptions();
	return options;
};

export const getFormatarOptions =
	(instituicaoCodigo, { agencia } = {}) =>
	// instituicaoCodigo, optionsKeywords
	({ opcoesDadosBancarios, optionsDefault = {} }, state) => {
		// initialDados, state
		return formataOptionsPadrao({ state, instituicaoCodigo, opcoesDadosBancarios, optionsDefault, agencia });
	};

export const defaultFormatarValuesAgencia = ({ values: { agencia } }) => {
	const [agencia_number, agencia_cv = ''] = ((agencia?.id ?? agencia) || '-').split('-');
	return {
		agencia: agencia_number,
		agencia_cv,
	};
};

export const defaultFormatarValuesContaCorrente = ({
	instituicaoProps: { props },
	values: { conta_corrente, tipo_integracao },
}) => {
	const propsPorTipoIntegracao = props[tipo_integracao.id];
	const { maxLength, regex1, regex2 } = props.conta_corrente || propsPorTipoIntegracao.conta_corrente;
	const [conta_corrente_number, conta_corrente_cv = ''] = (
		conta_corrente
			? conta_corrente.length < maxLength && !conta_corrente.match(/-/)
				? conta_corrente
				: conta_corrente.replace(regex1, '$1-$3$4').replace(regex2, '$1-$2')
			: '-'
	).split('-');
	return {
		conta_corrente: conta_corrente_number,
		conta_corrente_cv,
	};
};

export const createGetPropsValidas = (propsNames) => (props) =>
	propsNames.reduce((obj, propName) => {
		if (Object.prototype.hasOwnProperty.call(props, propName)) obj[propName] = props[propName];
		return obj;
	}, {});
