import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import SituacaoChip from './SituacaoChip';

export const SyndikosRedChip = ({ value }) => {
	const theme = useTheme();
	return <SituacaoChip value={value} textColor={theme.palette.syndikosRed.dark} />;
};

export default SyndikosRedChip;
