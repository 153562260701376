import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { CondominiosContext } from 'context/CondominioContextProvider';

const MensagemLinkadaCondominioSemPlano = () => {
	const { condominioSelecionado } = useContext(CondominiosContext);

	return (
		<Typography>
			Nenhum plano de condomínio vinculado. Adicione um nas{' '}
			<Link to={`condominios/${condominioSelecionado?.id}`} target='_blank'>
				configurações do condomínio
			</Link>
			.
		</Typography>
	);
};

export default MensagemLinkadaCondominioSemPlano;
