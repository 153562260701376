import React from 'react';

import { useListContext } from 'react-admin';

import { TabelaRowContext } from 'components/common/Tabela';

export const opcoesFiltroDisponibilizacaoBoleto = [
	{ id: 'condominio', name: 'Condominio' },
	{ id: 'correio', name: 'Correio' },
	{ id: 'email', name: 'Email' },
	{ id: 'portal', name: 'Portal' },
	{ id: 'whatsapp', name: 'WhatsApp' },
];

export const MeioEntregaBoletoField = ({ record }) => {
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = React.useContext(TabelaRowContext);

	const update = () => {
		if (data && data[rowRecord.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	React.useEffect(update, [data]);

	if (!record?.meio_de_disponibilizacao_de_boleto) return 'Nenhum';
	const opcoesDoUsuario = record?.meio_de_disponibilizacao_de_boleto.split(', ');
	if (opcoesDoUsuario.length === opcoesFiltroDisponibilizacaoBoleto.length) return 'Todos';
	return record?.meio_de_disponibilizacao_de_boleto;
};

export default MeioEntregaBoletoField;
