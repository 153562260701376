import { CondominiosContext } from 'context/CondominioContextProvider';
import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

export const CheckboxRelatorioContext = React.createContext();

const opcoesOrdenacao = [
	{
		id: 'grupo_unidade',
		nome: 'Grupo/Unidade',
	},
	{ id: 'nome', nome: 'Nome' },
	{ id: 'vencimento', nome: 'Vencimento' },
	{ id: 'nosso_numero', nome: 'Nosso Número' },
];

export const RelatoriosCheckbox = {
	// Balancete
	informar_despesa_coluna_saida: false,

	// Coeficiente da Unidade
	total_grupo: false,
	total_condominio: false,
	coeficiente_garagem: false,

	// Demonstrativo de Arrecadação
	imprimir_total_by_responsavel: true,
	imprimir_total_by_unidade: true,
	imprimir_vencimento_do_boleto: true,
	imprimir_total_by_grupo_demonstrativo_arrecadacao: true,

	// Demonstrativo de Leituras
	imprimir_total_by_unidades: true,
	imprimir_data: true,

	// Correção de Arrecadações Atrasadas
	imprimir_total_by_unidade_correcao: true,
	pular_paginas_entre_unidades: false,

	ordenar_por_data: false,
	ordenar_por_fornecedor: true,

	// Demonstrativo Mensal
	apenas_acumulados: false,
	imprimir_acumulado_demonstrativo_mensal: false,
	imprimir_media_demonstrativo_mensal: false,
	imprimir_devedores: false,
	receita_bruta: false,
	imprimir_pontilhado_dm: false,
	imprimir_zebrado_dm: false,

	// Planilha Arrecadação
	imprimir_media: false,
	imprimir_acumulado: false,

	// Recebimentos e Devedores
	tipo_relatorio_recebimento: true,
	tipo_relatorio_devedores: true,
	order_by_date: false,
	imprimir_total_by_data: false,
	imprimir_total_by_unidade_recebimentos_e_devedores: true,
	imprimir_orientacao_paisagem: false,
	imprimir_origem_baixa: false,
	imprimir_ordem_nome: false,

	// Demonstrativo de Arrecadacao Simplificado
	imprimir_subtotal_por_responsavel_simplificado: true,
	imprimir_total_por_unidade_simplificado: true,

	// Demonstrativo de Arrecadacao Detalhado
	imprimir_subtotal_by_referencia: true,
	imprimir_unidade_por_pagina: false,
	imprimir_valor_do_lancamento: false,
	imprimir_total_geral: false,

	// Fluxo de Movimentações Bancárias
	imprimir_totais_diarios: true,
	imprimir_total_conta: true,
	non_imprimir_saldo_by_movimentacao: false,
	imprimir_resumo_conta: true,
	imprimir_totais_resumo_conta: true,
	seprarar_por_tipo_resumo_contas: false,
	imprimir_resumo_separado: false,
	imprimir_numero_transacao: true,
	imprimir_pontilhado: true,
	pular_pagina_entre_contas: false,

	// Prestação de contas
	imprimir_contas_titulos_receitas: true,
	imprimir_contas_analiticas_receitas: true,
	destacar_negrito_contas_analiticas_receitas: false,
	destacar_italico_contas_analiticas_receitas: false,
	separar_referencia: false,
	imprimir_data_movimentacao_receitas: false,
	imprimir_transacao_movimentacao_receitas: false,
	imprimir_historico_movimentacao_receitas: false,
	imprimir_valor_movimentacao_receitas: false,
	destacar_italico_movimentacao_receitas: false,
	imprimir_contas_titulos_despesas: true,
	imprimir_contas_analiticas_despesas: true,
	imprimir_data_movimentacao_despesas: false,
	imprimir_transacao_movimentacao_despesas: false,
	imprimir_historico_movimentacao_despesas: false,
	imprimir_valor_movimentacao_despesas: false,
	destacar_italico_movimentacao_despesas: false,
	destacar_negrito_contas_analiticas_despesas: false,
	destacar_italico_contas_analiticas_despesas: false,
	imprimir_resumo_contas_bancarias: true,
	imprimir_total_resumo_contas_bancarias: true,
	separar_conta_by_tipo: false,
	pular_pagina_resumo: false,
	imprimir_resumo_prestacao: true,
	destacar_contas_titulo_maiusculo: false,
	imprimir_documentos_anexados: false,
	imprimir_percentual_receita_despesa: false,

	// Contas a Pagar
	filtrar_por_vencimento: true,
	filtrar_por_pagamento: false,
	imprimir_total_by_fornecedor: true,
	imprimir_total_by_data_contas_a_pagar: false,

	// Lista de Assinaturas
	proprietario: true,
	inquilino: false,
	ambos: false,
	responsavelPelaCobranca: false,
	data_base_checkbox: false,
	titulo: null,
	data_base_inadimplente_checkbox: false,

	//Detalhes das Unidades
	imprimir_assinatura: false,
	imprimir_detalhes_garagem: false,
	imprimir_motivo: false,

	//Garagens:
	imprimir_unidades_sem_garagens: false,
	imprimir_veiculos: false,
	tipo_ordenacao: opcoesOrdenacao[0],
};

export const CheckboxRelatorioProvider = ({ children, tipoRelatorio }) => {
	const [opcoesImpressao, setOpcoesImpressao] = React.useState(RelatoriosCheckbox);
	const [tipoOrdenacao, setTipoOrdenacao] = React.useState(opcoesOrdenacao[0]);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const [loading, setLoading] = React.useState(false);

	const dp = useDataProvider();
	const notify = useNotify();

	const handleChangeOpcoesImpressao = React.useCallback(
		(checked, nameOpcao) => {
			setOpcoesImpressao((prevConfig) => ({
				...prevConfig,
				[nameOpcao]: checked,
			}));
		},
		[opcoesImpressao, setOpcoesImpressao]
	);

	const providerValue = React.useMemo(
		() => ({
			handleChangeOpcoesImpressao,
			loading,
			setLoading,
			opcoesImpressao,
			setOpcoesImpressao,
			tipoOrdenacao,
			setTipoOrdenacao,
		}),
		[
			handleChangeOpcoesImpressao,
			loading,
			setLoading,
			opcoesImpressao,
			setOpcoesImpressao,
			tipoOrdenacao,
			setTipoOrdenacao,
		]
	);

	React.useEffect(() => {
		fetchConfigsCondominio();
	}, [condominioSelecionado, tipoRelatorio]);

	function fetchConfigsCondominio() {
		const nomeRelatorio = tipoRelatorio;
		if (!nomeRelatorio && condominioSelecionado?.id) {
			setOpcoesImpressao(RelatoriosCheckbox);
			return;
		}
		setLoading(true);
		dp.getSimple('configuracoes_relatorios', {
			filter: { id_condominio: condominioSelecionado.id, nome_relatorio: nomeRelatorio },
		})
			.then(({ data: { results } }) => {
				if (results.length > 0) {
					const configuracaoResultado = results[0].configuracoes_checkbox;
					const configuracaoRelatorio = { ...RelatoriosCheckbox, ...configuracaoResultado };
					setOpcoesImpressao(configuracaoRelatorio);
					const tipoOrdenacaoId = results[0].configuracoes_checkbox.tipo_ordenacao;
					const tipoOrdenacaoObj =
						opcoesOrdenacao.find((option) => option.id === tipoOrdenacaoId) || opcoesOrdenacao[0];
					setTipoOrdenacao(tipoOrdenacaoObj);
				} else {
					setOpcoesImpressao(RelatoriosCheckbox);
					notify('Ainda não há configurações cadastradas', 'info');
				}
			})
			.catch((e) => notify('Ainda não há configurações cadastradas', 'info'))
			.finally(() => setLoading(false));
	}

	return <CheckboxRelatorioContext.Provider value={providerValue}>{children}</CheckboxRelatorioContext.Provider>;
};
