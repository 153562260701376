import React from 'react';

import { Popover, Typography, ListItemText } from '@material-ui/core';

import { tags } from './Tags';

export const ShowTags: React.FC<ShowTagsProps> = ({ anchor, open, setAnchorEditor }) => {
	return (
		<Popover
			open={open}
			anchorEl={anchor}
			onClose={() => setAnchorEditor(null)}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'center',
			}}
		>
			<div style={{ margin: '10px' }}>
				<Typography variant='h5'>Utilize @ e digite uma das palavras abaixo para utilizar as tags:</Typography>
				<ul
					style={{
						listStyle: 'none',
						padding: 0,
						margin: 0,
					}}
				>
					{tags.map((tag) => {
						return (
							<li>
								<ListItemText
									primaryTypographyProps={{ variant: 'h6' }}
									secondaryTypographyProps={{
										variant: 'subtitle1',
									}}
									primary={tag.keys[0]}
									secondary={tag.content}
								/>
							</li>
						);
					})}
				</ul>
			</div>
		</Popover>
	);
};

type ShowTagsProps = {
	anchor: HTMLElement;
	open: boolean;
	setAnchorEditor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};
