import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Paper from '@material-ui/core/Paper';

const TooltipBar = ({ children, classNameIconButton }) => {
	const [settingsOpen, setSettingsOpen] = useState(false);
	return (
		<div onMouseLeave={() => setSettingsOpen(false)}>
			<span style={{ position: 'relative' }}>
				<IconButton
					onMouseEnter={() => setSettingsOpen(true)}
					{...(classNameIconButton ? { className: classNameIconButton } : {})}
				>
					<SettingsIcon />
				</IconButton>
				<Paper
					elevation={3}
					style={{
						display: settingsOpen ? 'flex' : 'none',
						justifyContent: 'center',
						alignItems: 'center',
						width: 'fit-content',
						position: 'absolute',
						right: 0,
						top: '-10px',
						borderRadius: 10,
						zIndex: 3,
						padding: 2,
					}}
				>
					{children}
				</Paper>
			</span>
		</div>
	);
};

export default TooltipBar;
