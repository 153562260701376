import React, { useContext, useState } from 'react';

import { useGetList } from 'react-admin';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelect, periodoOptions } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const FornecedoresSelect = ({ fornecedores = [], setFornecedores }) => {
	const { data: fornecedoresData } = useGetList(
		'fornecedores',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ situacao: 'A' }
	);
	return (
		<Autocomplete
			value={fornecedores}
			onChange={(_, newValue) => setFornecedores(newValue)}
			options={Object.values(fornecedoresData).filter((v) => v.id !== 0)}
			getOptionLabel={(o) => o.nome}
			renderInput={(params) => (
				<TextField
					{...params}
					label='Fornecedores'
					variant='outlined'
					helperText='Se vazio, todas os fornecedores serão selecionadas'
				/>
			)}
			clearText='Limpar'
			multiple
			autoHighlight
			noOptionsText='Nenhuma conta disponível'
			size='small'
			fullWidth
		/>
	);
};

const OpcoesImpressaoFiltroContasAPagar = ({
	handleChange,
	imprimirTotalFornecedor,
	imprimirTotalData,
	imprimirAssinatura,
	filtrarVencimento,
	filtrarPagamento,
}) => {
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<Box>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirTotalFornecedor}
								onChange={(e, checked) => {
									handleChange(checked, 'imprimir_total_by_fornecedor');
									handleChange(false, 'imprimir_total_by_data_contas_a_pagar');
								}}
								name='imprimir_total_by_fornecedor'
							/>
						}
						label='Ordenar por fornecedor'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirTotalData}
								onChange={(e, checked) => {
									handleChange(checked, 'imprimir_total_by_data_contas_a_pagar');
									handleChange(false, 'imprimir_total_by_fornecedor');
								}}
								name='imprimir_total_by_data_contas_a_pagar'
							/>
						}
						label='Ordenar por data'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirAssinatura}
								onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
								name='imprimir_assinatura'
							/>
						}
						label='Imprimir assinaturas'
					/>
				</Box>
				<Box>
					<FormControlLabel
						control={
							<Checkbox
								checked={filtrarVencimento}
								onChange={(e, checked) => {
									handleChange(checked, 'filtrar_por_vencimento');
									handleChange(false, 'filtrar_por_pagamento');
								}}
								name='filtrar_por_vencimento'
							/>
						}
						label='Filtrar por vencimento'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={filtrarPagamento}
								onChange={(e, checked) => {
									handleChange(checked, 'filtrar_por_pagamento');
									handleChange(false, 'filtrar_por_vencimento');
								}}
								name='filtrar_por_pagamento'
							/>
						}
						label='Filtrar por pagamento'
					/>
				</Box>
			</FormGroup>
		</FormControl>
	);
};

const ContasAPagarDivisor = (props) => {
	const [fornecedores, setFornecedores] = useState([]);
	const [dataInicial, setDataInicial] = useState();
	const [dataFinal, setDataFinal] = useState();
	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const {
		opcoesImpressao: {
			imprimir_total_by_data_contas_a_pagar,
			imprimir_total_by_fornecedor,
			imprimir_assinatura,
			filtrar_por_vencimento,
			filtrar_por_pagamento,
		},
		handleChangeOpcoesImpressao,
	} = useContext(CheckboxRelatorioContext);
	const requestData = {
		id_condominio: props.idCondominio,
		fornecedores_ids: fornecedores.map((f) => f.id),
		data_inicial: dataInicial,
		data_final: dataFinal,
		imprimir_total_by_fornecedor: imprimir_total_by_fornecedor,
		imprimir_total_by_data: imprimir_total_by_data_contas_a_pagar,
		filtrar_por_vencimento: filtrar_por_vencimento,
		filtrar_por_pagamento: filtrar_por_pagamento,
		imprimir_assinatura: imprimir_assinatura,
		nome_relatorio: props.tipoRelatorio.action,
	};

	return (
		<BoxDivisor titulo='Relatório de Contas a Pagar'>
			<Box display='flex' mb='1em'>
				<Box flex={1} display='grid' gridRowGap='10%'>
					<PeriodoSelect
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={dataInicial}
						setDataInicial={setDataInicial}
						setDataFinal={setDataFinal}
						dataFinal={dataFinal}
						label='Selecionar Período'
					/>
					<FornecedoresSelect {...{ fornecedores, setFornecedores }} />
				</Box>
				<Box flex={1} ml='1em'>
					<OpcoesImpressaoFiltroContasAPagar
						handleChange={handleChangeOpcoesImpressao}
						imprimirTotalData={imprimir_total_by_data_contas_a_pagar}
						imprimirTotalFornecedor={imprimir_total_by_fornecedor}
						imprimirAssinatura={imprimir_assinatura}
						filtrarVencimento={filtrar_por_vencimento}
						filtrarPagamento={filtrar_por_pagamento}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={props.tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};

export default ContasAPagarDivisor;
