import React from 'react';

import { Box } from '@material-ui/core';

import { CadastrarDadosIntegracaoAPITextField } from './CadastrarDadosIntegracaoAPITextField';

export const SenhaChaveAPIField = ({ source = 'senha_chave_api', label = 'Senha da Chave da API', inputProps }) => (
	<Box>
		<CadastrarDadosIntegracaoAPITextField label={label} source={source} inputProps={inputProps} />
	</Box>
);
