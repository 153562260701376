import EditaContaCorrenteContextProvider from './EditaContaCorrenteContextProvider';
import EditaContaNaoCorrenteContextProvider from './EditaContaNaoCorrenteContextProvider';
import EditaContaCaixaContextProvider from './EditaContaCaixaContextProvider';

const tipoContaContextProviderDict = {
	Caixa: EditaContaCaixaContextProvider,
	ContaNaoCorrente: EditaContaNaoCorrenteContextProvider,
	ContaCorrente: EditaContaCorrenteContextProvider,
};

export default tipoContaContextProviderDict;
