import React, { useState } from 'react';

import { Title } from 'react-admin';
import { Paper, Box, Typography, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { PagamentosDivisor } from './boxDivisores/PagamentosDivisor';
import { CheckboxRelatorioProvider } from './checkbox/CheckboxRelatorioContextProvider';

const optionsRelatorios = [
	{
		id: 'PG',
		name: 'Pagamentos',
		action: 'pagamentos',
	},
].sort((a, b) => a.name.localeCompare(b.name));

const RelatoriosDivisorSwitch = (props) => {
	switch (props.tipoRelatorio?.id) {
		case 'PG':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<PagamentosDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		default:
			return <></>;
	}
};

const FormularioRelatorios = (props) => {
	const [tipoRelatorio, setTipoRelatorio] = useState();

	return (
		<div>
			<Title title='Relatórios' />

			<Box display='flex' m='1em'>
				<Box flex={1}>
					<Paper style={{ padding: '1em' }}>
						<Box display='flex' alignItems='center'>
							<Typography variant='h6'>Relatórios</Typography>
						</Box>
						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>
						<Box m='1em' />
						<Box display='flex' width='100%' mb='1em'>
							<Box flex={1}>
								<Autocomplete
									options={optionsRelatorios}
									getOptionLabel={(o) => o.name}
									onChange={(_, newValue) => setTipoRelatorio(newValue)}
									renderInput={(params) => (
										<TextField {...params} label='Tipo de Relatório' variant='outlined' />
									)}
									disableClearable
									autoHighlight
									noOptionsText='Nenhum tipo disponível'
									size='small'
									fullWidth
								/>
							</Box>
						</Box>
					</Paper>
					<Box m='1em'></Box>
					{tipoRelatorio?.id ? (
						<Paper style={{ padding: '1em' }}>
							<RelatoriosDivisorSwitch tipoRelatorio={tipoRelatorio} {...props} />
						</Paper>
					) : (
						[]
					)}
				</Box>
			</Box>
		</div>
	);
};

export const RelatoriosMultiplosCondominiosList = () => {
	return <FormularioRelatorios />;
};
