import { Imposto, Fornecedor, ContaDespesa, Condominio, FormValues, HttpError } from '../Interfaces/interaces';
import { UseFormSetValue } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';

export const getImpostos = (
	dp: any,
	setImpostos: Dispatch<SetStateAction<Imposto[]>>,
	condominioSelecionado: string | null
) => {
	const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
	const filter: { condominio_id: string | null } = {
		condominio_id: condominioSelecionado,
	};

	dp.getSimple('template_imposto', {
		pagination: { perPage: 10000, page: 1 },
		sort: { field: 'id', order: 'ASC' },
		filter,
		cancelToken,
	})
		.then((response: { data: { results: Imposto[] } }) => {
			const config_imposto = response.data.results;
			const false_se_nao_tem_impostos =
				Array.isArray(config_imposto) && config_imposto.some((item) => item.id === 0);

			if (!false_se_nao_tem_impostos) {
				setImpostos([...config_imposto]);
			}
		})
		.catch((error: { response: { status: number } }) => {
			if (axios.isCancel(error)) return;
			const err = error as { response: { status: number } };
			if ([401, 403].includes(err?.response?.status)) return Promise.reject(err);
			console.error('Error fetching data:', error);
		});
	return () => {
		cancelRequest();
	};
};

export const getFornecedores = async (dp: any, setFornecedores: Dispatch<SetStateAction<Fornecedor[]>>) => {
	try {
		const { token: cancelToken } = axios.CancelToken.source();
		const response = await dp.getSimple('fornecedores', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'nome', order: 'ASC' },
			filter: {
				situacao: 'A',
			},
			cancelToken,
		});
		const fornecedores: Fornecedor[] = [];
		response.data.results.forEach((fornecedorData: Fornecedor, index: number) => {
			fornecedores.push({ id: fornecedorData.id, name: fornecedorData.nome });
		});
		setFornecedores(fornecedores);
	} catch (error) {
		const err = error as { response: { status: number } };
		if ([401, 403].includes(err?.response?.status)) return Promise.reject(err);
		return console.error('Error fetching data:', error);
	}
};

export const getCondominios = async (dp: any, setCondominios: Dispatch<SetStateAction<Condominio[]>>) => {
	try {
		const { token: cancelToken } = axios.CancelToken.source();
		const response = await dp.getSimple('condominios', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'nome', order: 'ASC' },
			cancelToken,
		});
		const condominios: Condominio[] = [];
		response.data.results.forEach((condominio: Condominio, index: number) => {
			condominios.push({
				id: condominio.id,
				name: condominio.nome,
				id_plano_condominio: condominio.id_plano_condominio,
			});
		});

		setCondominios(condominios);
	} catch (error) {
		const err = error as { response: { status: number } };
		if ([401, 403].includes(err?.response?.status)) return Promise.reject(err);
		return console.error('Error fetching data:', error);
	}
};

export const cleanForm = (
	setValue: UseFormSetValue<FormValues>,
	setContaDespesaSelecionada: Dispatch<SetStateAction<ContaDespesa[]>> | Dispatch<SetStateAction<ContaDespesa>>
) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	setContaDespesaSelecionada({
		id: null,
		nome: null,
	});
	setValue('id', null);
	setValue('nome', '');
	setValue('aliquota', '');
	setValue('dia_vencimento', null);
	setValue('conta_despesa', null);
	setValue('fornecedor', null);
};
