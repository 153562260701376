import React from 'react';

import { ModalSizeEditableContextProvider } from '../../../../../common/ModalSizeEditableContext';
import { CadastrarDadosIntegracaoAPIButton } from './CadastrarDadosIntegracaoAPIButton';
import { StatusTooltipIconDadosItengracaoAPI } from './StatusDadosItengracaoAPIIcon';
import { RemoverCertificadosButton } from './RemoverCertificadosButton';

export { CadastrarDadosIntegracaoAPIButton, StatusTooltipIconDadosItengracaoAPI, RemoverCertificadosButton };

export * from './Actions';
export * from './CadastrarDadosIntegracaoAPIFileField';
export * from './CadastrarDadosIntegracaoAPITextField';
export * from './CertificadoAPIFileField';
export * from './ChaveAPIFileField';
export * from './ClientIDField';
export * from './ClientSecretField';
export * from './SenhaChaveAPIField';
export * from './ModalCadastrarDadosIntegracaoAPI';
export * from './ChaveAPIField';

const CadastrarDadosIntegracaoAPIField = () => (
	<ModalSizeEditableContextProvider
		customDialogProps={{
			disableBackdropClick: true,
			disableEscapeKeyDown: true,
			minWidth: '61vw',
		}}
	>
		<StatusTooltipIconDadosItengracaoAPI />
		<CadastrarDadosIntegracaoAPIButton />
		<RemoverCertificadosButton />
	</ModalSizeEditableContextProvider>
);

export default CadastrarDadosIntegracaoAPIField;
