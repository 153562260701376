import React from 'react';

import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

import { ButtonCancel } from 'components/common/buttons/ButtonCancel';

export const ModalCreateNewTemplateConfig = ({
	openCreateNewTemplate,
	setOpenCreateNewTemplate,
	nomeTemplate,
	setNomeTemplate,
	loading,
	handleSaveNewTemplateConfig,
}) => {
	return (
		<Dialog
			open={openCreateNewTemplate}
			onClose={() => setOpenCreateNewTemplate(false)}
			style={{
				minWidth: '60vw',
			}}
		>
			<DialogTitle>Criar novo modelo de configuração</DialogTitle>
			<DialogContent style={{ overflowY: 'hidden' }}>
				<TextField
					id='input-name-template-config'
					label='Digite um nome para o modelo'
					variant='outlined'
					size='small'
					style={{ width: '100%' }}
					onChange={({ target: { value } }) => setNomeTemplate(value)}
				/>
			</DialogContent>
			<DialogActions>
				<ButtonCancel onClick={() => setOpenCreateNewTemplate(false)} />
				<Button
					color='primary'
					size='small'
					variant='contained'
					startIcon={loading ? <></> : <SaveIcon />}
					disabled={loading || !nomeTemplate}
					onClick={handleSaveNewTemplateConfig}
				>
					{(loading && <CircularProgress size={20} />) || 'Salvar'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
