import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';

import ContaReceitaField from './ContaReceitaField';
import { DialogActionsPadrao } from './dialogActions';
import DialogTitleEditaConfiguracaoLeitura from './DialogTitleEditaConfiguracaoLeitura';
import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';
import InputReferencia from './InputReferencia';
import FatorConversaoField from './FatorConversaoField';
import FormEdicaoConfiguracaoLeitura from './FormEdicaoConfiguracaoLeitura';
import MetodoCalculoField from './MetodoCalculoField';
import UnidadeMedidaSelect from './UnidadeMedidaSelect';
import FaixaLeitura from '../../componentes/FaixaLeitura';
import NomeLeituraField from './InputNome';

const FormPorUnidadeDeMedida = () => {
	const { dadosConfig, situacao } = useContext(EditaConfiguracaoLeituraContext);
	return (
		<FormEdicaoConfiguracaoLeitura
			initialValues={{
				nome_leitura: dadosConfig.nome_leitura,
				faixas_leitura: dadosConfig.faixas_leitura,
				fator_convercao: dadosConfig.fator_convercao,
				leituras_para_calculo: dadosConfig.leituras_para_calculo,
				nao_cobrar_taxa: dadosConfig.nao_cobrar_taxa,
				acesso_app: dadosConfig.acesso_app,
				percentual: dadosConfig.percentual,
				unidade_medida: dadosConfig.unidade_medida,
			}}
			render={({
				invalid,
				values: {
					faixas_leitura,
					fator_convercao,
					leituras_para_calculo,
					nao_cobrar_taxa,
					acesso_app,
					percentual,
					unidade_medida,
					...dadosConfigEdicao
				},
			}) => (
				<>
					<DialogTitleEditaConfiguracaoLeitura />
					<DialogContent>
						<Box style={{ 'margin-top': '1%' }} display='flex'>
							<InputReferencia />
							<NomeLeituraField />
							<ContaReceitaField />
						</Box>
						<Box style={{ 'margin-top': '1%' }}>
							<MetodoCalculoField />
							<UnidadeMedidaSelect {...{ unidade_medida, fator_convercao }} />
							<FatorConversaoField {...{ unidade_medida, fator_convercao }} />
						</Box>
						<FaixaLeitura disabled={situacao !== 'A'} />
					</DialogContent>
					<DialogActionsPadrao
						{...{
							dadosConfigEdicao,
							invalid,
							faixas_leitura,
							leituras_para_calculo,
							nao_cobrar_taxa,
							acesso_app,
							percentual,
						}}
					/>
				</>
			)}
		/>
	);
};

export default FormPorUnidadeDeMedida;
