import React, { useState, useEffect } from 'react';
import { ListControllerProps, useListContext } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';

type TRecord = {
	id: string | number;
	name: string;
	[key: string]: any;
};

interface ISelectAutocompleteFilterProps {
	label: string;
	source: string;
	opcoes: TRecord[];
	variant: 'standard' | 'outlined' | 'filled' | undefined;
	listControllerProps?: Partial<ListControllerProps>;
}

type TValue = TRecord | null;

const SelectAutocompleteFilterBase: React.FC<ISelectAutocompleteFilterProps> = ({
	label,
	source,
	opcoes,
	variant = 'standard',
	listControllerProps,
}) => {
	const [value, setValue] = useState<TValue>(null);
	const { setFilters, filterValues } = useListContext(listControllerProps);
	const [options, setOptions] = useState<TRecord[]>([]);
	const [lengthOption, setLengthOption] = useState(0);

	const init = () => {
		if (options?.length > 0 && lengthOption === 0) {
			let aux = 0;
			options.forEach((v) => {
				if (v.name?.length > aux) aux = v.name?.length;
			});
			setLengthOption(aux);
		}
		if (filterValues[source] && options?.length > 0 && !value) {
			setValue(options.find((o) => o.id === filterValues[source]) ?? null);
		}
	};

	useEffect(init, [filterValues, options, source]);

	useEffect(() => {
		setOptions(opcoes);
	}, [opcoes]);

	return (
		<Autocomplete
			openOnFocus
			value={value || null}
			id={`${source}-filtro-autocompletar`}
			disabled={!options?.length}
			options={options}
			onChange={(e, newValue: TValue) => {
				let id = '';
				if (newValue) {
					id = String(newValue.id);
					setValue(newValue);
				} else {
					setValue(null);
				}
				setFilters({ ...filterValues, [source]: id }, undefined);
			}}
			renderOption={(option) =>
				option.name ? option?.situacao === 'I' ? <i>{option.name} - Inativo</i> : option.name : ''
			}
			getOptionLabel={(option) => (option.name ? option.name : '')}
			getOptionSelected={(option, newValue) => {
				return newValue.name === option.name;
			}}
			renderInput={(params) => <TextField {...params} label={label || 'Filtrar'} variant={variant} />}
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						width: `${lengthOption * 10}pt` || props.style?.width,
						maxWidth: '320px',
						minWidth: '60px',
					}}
				/>
			)}
			fullWidth
			noOptionsText='Nenhum resultado'
			disableListWrap
		/>
	);
};

export const SelectAutocompleteFilter = SelectAutocompleteFilterBase as React.FC<
	Partial<ISelectAutocompleteFilterProps>
>;

export default SelectAutocompleteFilter;
