import React, { useState, useEffect, createContext, useMemo, useCallback } from 'react';
import { useNotify, useDataProvider } from 'react-admin';

import { ITituloRelatorio, ITitulosContextValue, ITitulosContextProviderProps, TTitulosPorId } from '../types';
import { ModalContext } from 'components/common/ModalContext';

export const TitulosContext = createContext<ITitulosContextValue>({
	filter: '',
	setFilter: () => {},
	filteredOptions: [],
	options: [],
	optionsPorId: {},
	create: () => {},
	update: () => {},
	deleteOne: () => {},
	selected: null,
	setSelected: () => {},
	loading: true,
});

const filterOptions = (options: ITituloRelatorio[], filter: string): ITituloRelatorio[] => {
	if (!options?.length) return [];
	if (!filter) return [...options];
	return options.filter((option: ITituloRelatorio) => option.descricao.toLowerCase().includes(filter.toLowerCase()));
};

const TitulosContextProvider: React.FC<ITitulosContextProviderProps> = ({
	setTituloRelatorio,
	tituloRelatorio,
	children,
}) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { modalValue } = React.useContext(ModalContext);
	const [filter, setFilter] = useState('');
	const [filteredOptions, setFiltredOptions] = useState<ITituloRelatorio[]>([]);
	const [{ optionsPorId, options }, setData] = useState<{
		optionsPorId: TTitulosPorId;
		options: ITituloRelatorio[];
	}>({
		optionsPorId: {},
		options: [],
	});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!modalValue?.open) {
			setFilter('');
		}
	}, [modalValue]);

	const create = useCallback(
		(reqData) => {
			if (reqData.descricao.length > 40) {
				notify('O título não pode ter mais do que 40 caracteres', 'warning');
				return;
			}
			dataProvider
				.create('titulo_lista_assinaturas', { data: { ...reqData } })
				.then((response) => {
					if (!response?.data) return;

					const data = response.data as ITituloRelatorio;
					setData(({ optionsPorId, options }) => {
						return {
							optionsPorId: { ...optionsPorId, [data.id]: data },
							options: [...options, data].sort((a, b) => {
								if (a.descricao === b.descricao) return 0;
								return a.descricao < b.descricao ? -1 : 1;
							}),
						};
					});
					if (data.descricao.toLowerCase().includes(filter.toLowerCase()))
						setFiltredOptions((t) =>
							[...t, data].sort((a, b) => {
								if (a.descricao === b.descricao) return 0;
								return a.descricao < b.descricao ? -1 : 1;
							})
						);
					notify('Título cadastrado com sucesso');
				})
				.catch((e) =>
					[401, 403].includes(e?.response?.status)
						? Promise.reject(e)
						: notify('Erro ao cadastrar', 'warning')
				);
		},
		[dataProvider, notify]
	);
	const update = useCallback(
		(id, reqData) => {
			dataProvider
				.update('titulo_lista_assinaturas', { id, data: { ...reqData }, previousData: { id } })
				.then((response) => {
					if (!response?.data) return;

					const data = response.data as ITituloRelatorio;
					setData(({ optionsPorId }) => {
						const novasOptionsPorId = { ...optionsPorId, [data.id as number]: data };
						return {
							optionsPorId: novasOptionsPorId,
							options: Object.values(novasOptionsPorId).sort((a, b) => {
								if (a.descricao === b.descricao) return 0;
								return a.descricao < b.descricao ? -1 : 1;
							}),
						};
					});
					if (data.descricao.toLowerCase().includes(filter.toLowerCase()))
						setFiltredOptions((options) =>
							options
								.map((option) => (option.id === id ? data : option))
								.sort((a, b) => {
									if (a.descricao === b.descricao) return 0;
									return a.descricao < b.descricao ? -1 : 1;
								})
						);
					setTituloRelatorio((selected) => (selected?.id === id ? data : selected));
					notify('Título alterado com sucesso');
				})
				.catch((e) =>
					[401, 403].includes(e?.response?.status)
						? Promise.reject(e)
						: notify('Erro ao atualizar', 'warning')
				);
		},
		[dataProvider, notify]
	);
	const deleteOne = useCallback(
		(id) => {
			dataProvider
				.delete('titulo_lista_assinaturas', { id })
				.then(() => {
					setData(({ optionsPorId, options }) => {
						const { [id]: _, ...rest } = optionsPorId;
						return {
							optionsPorId: rest,
							options: options.filter((option) => option.id !== id),
						};
					});
					setFiltredOptions((t) => {
						return t.filter((option) => option.id !== id);
					});
					notify('Título removido com sucesso');
					setTituloRelatorio((selected) => (selected?.id === id ? null : selected));
				})
				.catch((e) =>
					[401, 403].includes(e?.response?.status)
						? Promise.reject(e)
						: notify('Título em uso, não é possível remover', 'warning')
				);
		},
		[dataProvider, options, notify]
	);

	const init = () => {
		dataProvider
			.getList('titulo_lista_assinaturas', {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: 'descricao', order: 'ASC' },
				filter: { descricao: filter },
			})
			.then((response) => {
				const options = response?.data?.filter?.((item) => item?.id) as ITituloRelatorio[];
				if (!options?.length) {
					setData({ optionsPorId: {}, options: [] });
					setFiltredOptions([]);
					setLoading(false);
					setTituloRelatorio(null);
					return;
				}
				const optionsPorId: TTitulosPorId = options.reduce((titulosPorId, titulo) => {
					titulosPorId[titulo.id] = titulo;
					return titulosPorId;
				}, {} as TTitulosPorId);
				setData({ optionsPorId, options });
				setFiltredOptions(filterOptions(options, filter));
				setLoading(false);
				setTituloRelatorio((selected) => (selected ? optionsPorId[selected.id] ?? null : null));
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			});
	};

	useEffect(init, []);
	useEffect(() => {
		if (!options.length) return;
		setFiltredOptions(filterOptions(options, filter));
	}, [filter]);

	const titulosProviderValue = useMemo(
		() => ({
			filter,
			setFilter,
			filteredOptions,
			options,
			optionsPorId,
			create,
			update,
			deleteOne,
			selected: tituloRelatorio,
			setSelected: setTituloRelatorio,
			loading,
		}),
		[
			filter,
			setFilter,
			filteredOptions,
			options,
			optionsPorId,
			create,
			update,
			deleteOne,
			tituloRelatorio,
			setTituloRelatorio,
			loading,
		]
	);

	return <TitulosContext.Provider value={titulosProviderValue}>{children}</TitulosContext.Provider>;
};

export default TitulosContextProvider;
