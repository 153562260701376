import React, { useContext } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../../../common/ModalSizeEditableContext';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { ContasBancariasListContext } from '../../ContasBancariasListContextProvider';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaBancariaButtonsContext from '../contexts/EditaContaBancariaButtonsContext';

const ReativarButton = () => {
	const notify = useNotify();
	const dP = useDataProvider();
	const refresh = useRefresh();
	const { setReloadFilter } = useContext(ContasBancariasListContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { closeModal } = useContext(EditaContaBancariaButtonsContext);
	const {
		condominioRecord,
		initialDados: {
			conta_condominio: { id },
		},
	} = useContext(EditaContaBancariaContext);
	const handleActive = () => {
		dP.update('conta_condominio', {
			id: id,
			data: { situacao: 'A' },
		})
			.then(() => {
				notify('Conta bancária reativada com sucesso!');
				setReloadFilter((v) => !v);
				refresh();
				closeModal();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(`${e}`, 'warning');
			});
	};

	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const onClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBodySizeEditable
					title={'Tem certeza que deseja ativar essa conta bancária?'}
					customActions={
						<>
							<ButtonCancel onClick={handleClose} />
							<ButtonConfirm size='small' onClick={handleActive}>
								Ativar
							</ButtonConfirm>
						</>
					}
				/>
			),
		}));
	};
	return (
		<Button
			disabled={condominioRecord.situacao !== 'A'}
			onClick={onClick}
			variant='text'
			color='primary'
			size='small'
		>
			Ativar
		</Button>
	);
};

export default ReativarButton;
