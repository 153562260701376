import React from 'react';
import { Create, useNotify, useRedirect, useRefresh } from 'react-admin';

import AssembleiaForm from './form';
import { AssembleiaProps, AssembleiaResponse } from './types';

export const AssembleiaCreate: React.FC<AssembleiaProps> = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response: AssembleiaResponse) => {
		const data = response?.data;
		if (data) {
			notify('Assembleia virtual cadastrada com sucesso');
			redirect('list', props.basePath);
			refresh();
		}
	};

	return (
		<Create component='div' title={'Cadastrar Assembleia Virtual'} onSuccess={onSuccess} {...props}>
			<AssembleiaForm {...props} />
		</Create>
	);
};
