import { Checkbox, DialogTitle, DialogContent, DialogContentText, CircularProgress, Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useFormState } from 'react-final-form';
import { GoBackButton, SaveButton } from 'components/common/commonComponentsTSX';
import { ModalContext } from 'components/common/ModalContext';
import { AxiosResponse } from 'axios';

const PessoaSaveModal: React.FC<PessoaSaveModalProps> = ({ saveCallback }) => {
	const dataProvider: CustomDataProvider = useDataProvider();
	const [groupedUnidades, setGroupedUnidades] = useState<{ [key: string]: UnidadeAddressResponse[] }>({});
	const [loading, setLoading] = useState(true);
	const notify = useNotify();
	const [logradouro, setLogradouro] = useState('');
	const { values } = useFormState();
	const [checkedUnidades, setCheckedUnidades] = useState<number[]>([]);
	const { setModalValue } = useContext(ModalContext);
	const [updating, setUpdating] = useState(false);

	useEffect(() => {
		dataProvider
			.getOnePkMiddle('pessoa_unidade/enderecamento_unidades_vinculadas', { id: values.id })
			.then((response) => {
				const unidadesData = response.data.results as UnidadeAddressResponse[];
				setGroupedUnidades(
					unidadesData.reduce(
						(groups: { [key: string]: UnidadeAddressResponse[] }, unidade: UnidadeAddressResponse) => {
							if (!groups[unidade.condominio]) {
								groups[unidade.condominio] = [];
							}
							groups[unidade.condominio].push(unidade);
							return groups;
						},
						{}
					)
				);
				setLoading(false);
			})
			.catch(() => notify('Ocorreu um erro ao tentar obter as unidades vinculadas'));
	}, [values.unidades]);

	useEffect(() => {
		dataProvider
			.getOne('tipo_logradouro', { id: values.tipo_logradouro })
			.then((response) => setLogradouro(response.data.descricao));
	}, [values.tipo_logradouro]);

	const NewAddress = () => {
		const logradouroEndereco = [logradouro, values.endereco].filter(Boolean).join(' ');
		const numeroBairro = [values.numero, values.bairro].filter(Boolean).join(' - ');
		const cidadeUf = [values.cidade, values.uf].filter(Boolean).join(' - ');
		const cep = values.cep ? values.cep.slice(0, 5) + '-' + values.cep.slice(5) : values.cep;
		const newAddress = [logradouroEndereco, numeroBairro, cidadeUf, cep].filter(Boolean).join(', ');

		return (
			<>
				{newAddress ? (
					<>
						<strong>Novo Endereço:</strong> {newAddress}
					</>
				) : (
					'O endereço está vazio.'
				)}
			</>
		);
	};

	const AddressUnidades = () => (
		<>
			{Object.keys(groupedUnidades).map((key) => (
				<>
					<DialogTitle>{key}</DialogTitle>
					{groupedUnidades[key].map((unidade) => (
						<Box display={'flex'} alignItems={'center'} paddingLeft={'1vw'}>
							<Checkbox
								checked={checkedUnidades.includes(unidade.id)}
								onChange={() => handleCheckboxClick(unidade.id)}
								disabled={updating}
							/>
							<DialogContent>
								<Unidade unidade={unidade} />
								<br></br>
								<Box fontSize='small'>
									<Address unidade={unidade} />
								</Box>
							</DialogContent>
						</Box>
					))}
					<Box>
						<br></br>
					</Box>
				</>
			))}
		</>
	);

	const Unidade = ({ unidade }: { unidade: UnidadeAddressResponse }) => {
		const unidadeInfo = `Unidade ${unidade.unidade} - Proprietário: ${unidade.proprietario}`;
		return (
			<>
				{unidade.bloco ? (
					<>
						{unidade.bloco} - {unidadeInfo}
					</>
				) : (
					<>{unidadeInfo}</>
				)}
			</>
		);
	};

	const Address = ({ unidade }: { unidade: UnidadeAddressResponse }) => {
		const logradouroEndereco = [unidade.tipo_logradouro_abrev, unidade.endereco].filter(Boolean).join('. ');
		const numeroBairro = [unidade.numero, unidade.bairro].filter(Boolean).join(' - ');
		const cidadeUf = [unidade.cidade, unidade.uf].filter(Boolean).join(' - ');
		const cep = unidade.cep ? unidade.cep.slice(0, 5) + '-' + unidade.cep.slice(5) : null;
		const address = [logradouroEndereco, numeroBairro, cidadeUf, cep].filter(Boolean).join(', ');
		return address ? <>Endereço: {address}</> : <></>;
	};

	const handleCheckboxClick = (id: number) => {
		if (checkedUnidades.includes(id)) {
			const updatedUnidades = checkedUnidades.filter((id_unidade) => id_unidade != id);
			setCheckedUnidades(updatedUnidades);
		} else {
			setCheckedUnidades([...checkedUnidades, id]);
		}
	};

	const updateUnidadesAndSave = () => {
		if (updating) {
			return;
		}
		setUpdating(true);
		if (checkedUnidades.length > 0) {
			return dataProvider
				.bulkUpdate('pessoa_unidade/atualizar_unidades_vinculadas', {
					ids: checkedUnidades,
					data: {
						cep: values.cep || '',
						tipo_logradouro_id: values.tipo_logradouro || null,
						endereco: values.endereco || '',
						bairro: values.bairro || '',
						numero: values.numero || '',
						cidade: values.cidade || '',
						uf: values.uf || null,
						complemento_endereco: values.complemento_endereco || '',
					},
				})
				.then(() => {
					notify('Unidades vinculadas atualizadas com sucesso');
					return saveCallback();
				})
				.catch(() => {
					setModalValue(() => ({
						open: false,
					}));
					notify('Ocorreu um erro na atualização das unidades vinculadas');
					setUpdating(false);
				});
		}
		return new Promise((r) => {
			r(saveCallback());
		}).catch(() => {
			setUpdating(false);
		});
	};

	return (
		<Box maxHeight={'70vh'} padding={'1vw'}>
			<DialogTitle>Alteração de Endereço</DialogTitle>
			<DialogContent>Caso desejar, selecione as unidades para atualizar o endereço de Cobrança!</DialogContent>
			<Box paddingLeft={'2.5vw'}>
				<DialogContentText>
					<Box fontSize={'15px'}>
						<NewAddress />
					</Box>
				</DialogContentText>
			</Box>
			{loading ? (
				<Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100%'}>
					<CircularProgress />
				</Box>
			) : (
				<AddressUnidades />
			)}
			<Box paddingBottom={'1vh'} paddingLeft={'1vw'}>
				<SaveButton size={'medium'} onClick={updateUnidadesAndSave} disabled={updating}>
					Salvar
				</SaveButton>
				<GoBackButton
					size={'small'}
					onClick={() =>
						setModalValue(() => ({
							open: false,
						}))
					}
					disabled={updating}
				/>
			</Box>
		</Box>
	);
};

type PessoaSaveModalProps = {
	saveCallback: () => void;
};

type CustomDataProvider = {
	getOnePkMiddle: (resource: string, params: { id: number }) => Promise<AxiosResponse>;
	bulkUpdate: (resource: string, params: { ids: number[]; data: object }) => Promise<AxiosResponse>;
} & ReturnType<typeof useDataProvider>;

type UnidadeAddressResponse = {
	id: number;
	id_unidade: number;
	unidade: string;
	condominio: string;
	bloco: string;
	tipo_logradouro_abrev: string;
	endereco: string;
	numero: string;
	bairro: string;
	cidade: string;
	uf: string;
	cep: string;
	proprietario: string;
};

export default PessoaSaveModal;
