import React, { useState, createContext, useMemo, useEffect } from 'react';
import api from '../../services/api';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

type AdminContextType = {
	midias: any;
	dadosAdministradora: any;
	situacaoIrregular: boolean;
	setSituacaoIrregular: React.Dispatch<React.SetStateAction<boolean>>;
};

type authType = {
	tenant: {
		schema_name: string;
	};
};

export const AdminContext = createContext<AdminContextType>({
	midias: {},
	dadosAdministradora: {},
	situacaoIrregular: false,
	setSituacaoIrregular: () => {},
});

export const AdminContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [midias, setMidias] = useState({});
	const [visualizarBanner, setVisualizarBanner] = useState(false);
	const [dadosAdministradora, setDadosAdministradora] = useState(AdminContext);
	const [situacaoIrregular, setSituacaoIrregular] = useState(false);

	const get_licencas = async () => {
		const auth = localStorage.getItem('auth');
		const authParsed: authType = JSON.parse(auth || '{}');
		const res = await api.get(`api/v1/administradora/${authParsed.tenant.schema_name}`);
		if (res.status === 200) return res.data;
	};

	const get_midias = async () => {
		const res = await api.get('api/v1/midia/get_midias_campanha_ativa');
		if (res.status === 200) return res.data;
	};

	const get_visualizacao_banner = async () => {
		const res = await api.get('api/v1/midia/deve_visualizar_banner');
		if (res.status === 200) return res.data;
	};

	const providerValue = useMemo(
		() => ({ midias, dadosAdministradora, situacaoIrregular, setSituacaoIrregular, visualizarBanner }),
		[midias, dadosAdministradora, situacaoIrregular, visualizarBanner]
	);

	const handleAuthChange = () => {
		const auth = localStorage.getItem('auth');
		if (auth) {
			get_licencas().then((data) => setDadosAdministradora(data));
		}
	};

	useEffect(() => {
		get_midias().then((data) => setMidias(data));
		get_visualizacao_banner().then((data) => setVisualizarBanner(data));
	}, []);

	useEffect(() => {
		window.addEventListener('authStorage', handleAuthChange);
		handleAuthChange();
		return () => {
			window.removeEventListener('authStorage', handleAuthChange);
		};
	}, [situacaoIrregular]);

	return <AdminContext.Provider value={providerValue}>{children}</AdminContext.Provider>;
};
