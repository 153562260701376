import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
	Box,
	Checkbox,
	makeStyles,
	Table,
	TableContainer,
	TableRow,
	TableBody,
	TableCell,
	TableHead,
	TableSortLabel,
	Typography,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContextProvider,
} from 'components/common/ModalSizeEditableContext';
import { NumberFormatBRL } from 'components/common/CurrencyInput';
import { TooltipIconButtonCancel } from 'components/common/buttons/ButtonCancel';
import {
	ModalArrecadacaoUnidadesEditarContext,
	ModalArrecadacaoUnidadesEditarContextProvider,
} from './ModalArrecadacaoUnidadesEditarContext';
import BotaoExcluir from './BotaoExcluir';
// import BotaoAlterarResponsavel from './BotaoAlterarResponsavel'
import BotaoAdicionarArrecadacaoUnidade from './BotaoAdicionarArrecadacaoUnidade';
import BotaoAtualizarValor from './BotaoAtualizarValor';
import BotaoEditarValor from './BotaoEditarValor';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';

const BotaoFecharModal = () => {
	const { closeModal } = useContext(ModalArrecadacaoUnidadesEditarContext);
	return <TooltipIconButtonCancel onClick={closeModal} title='Fechar modal' />;
};

const RowActions = ({ row }) => {
	const [loading, setLoading] = useState(false);
	return (
		<ModalSizeEditableContextProvider
			customDialogProps={{
				minWidth: '50vw',
			}}
		>
			{loading ? (
				<CircularProgress />
			) : row?.tipo_conta === 'L' || row?.tipo_lancamento === 'PRC' ? (
				<BotaoAtualizarValor {...{ loading, setLoading, row }} />
			) : (
				<BotaoEditarValor {...{ loading, setLoading, row }} />
			)}
		</ModalSizeEditableContextProvider>
	);
};

const ModalAcoes = ({ record }) => (
	<ModalSizeEditableContextProvider
		customDialogProps={{
			minWidth: '50vw',
			disableBackdropClick: true,
			disableEscapeKeyDown: true,
		}}
	>
		<BotaoFecharModal />
		<BotaoExcluir />
		{/* <BotaoAlterarResponsavel record={record} /> */}
		<BotaoAdicionarArrecadacaoUnidade />
	</ModalSizeEditableContextProvider>
);

const colunas = [
	{ field: 'nome_conta_pai', headerName: 'Conta Título' },
	{ field: 'complemento', headerName: 'Descrição Boleto' },
	{ field: 'tipo_conta', headerName: 'Tipo da Conta' },
	{ field: 'rateado', headerName: 'Rateado' },
	{ field: 'valor', headerName: 'Valor' },
	{ field: 'rateio', headerName: 'Rateio' },
];

const tiposContaParaFundo = {
	O: 'Contas Ordinárias',
	E: 'Contas Extraordinárias',
};

const get_rateio = (row) =>
	row
		? {
				[true]: () => '',
				[row.tipo_lancamento === 'PRC']: (r) => tiposContaParaFundo[r.tipo_conta_para_fundo] || '',
				[row.rateio === '0']: () => 'Divisão por coeficiente',
				[row.rateio === '1']: () => 'Divisão pela quantidade de unidades',
				[row.rateio === '2']: () => 'Igualmente para todas as unidades',
				[row.rateio === '-1']: () => 'Calculados sobre os consumos individuais',
				[row.rateio === '-2']: (r) => `Para ${(r.tipo_unidade || '').toLowerCase()} ${r.nome_unidade || ''}`,
		  }[true](row)
		: '';

const get_rateio_for_sort = (row) =>
	row
		? {
				[true]: NaN,
				[row.rateio === '0']: 0,
				[row.rateio === '1']: 1,
				[row.rateio === '2']: 2,
				[row.rateio === '-1']: 3,
				[row.rateio === '-2']: 4,
		  }[true]
		: '';

const get_rateado = (row) =>
	row
		? {
				[true]: () => 'Condomínio',
				[row.rateado === '1']: () => 'Grupo',
				[row.rateado === '2']: () => 'Unidade',
				[row.rateado === '4']: () => 'Tipo',
				[row.rateado === '5']: () => 'Para várias unidades',
		  }[true]()
		: '';

const get_rateado_for_sort = (row) =>
	row
		? {
				[true]: () => 0,
				[row.rateado === '1']: 1,
				[row.rateado === '2']: 2,
				[row.rateado === '4']: 4,
				[row.rateado === '5']: 5,
		  }[true]
		: NaN;

const get_tipo_conta = (row) =>
	row
		? {
				[true]: () => '',
				[row.tipo_conta === 'L']: () => 'Leitura',
				[row.tipo_conta === 'O']: () => 'Ordinária',
				[row.tipo_conta === 'E']: () => 'Extraordinária',
				[row.tipo_conta === 'F']: () => 'Fundo',
		  }[true]()
		: '';

const funcSort = (field, order) => (a, b) => {
	if (['nome_conta_pai', 'complemento'].includes(field)) {
		return new Intl.Collator().compare(a[field], b[field]) * (order === 'asc' ? 1 : -1);
	}
	if (field === 'valor') {
		a[field] = Number(a[field]);
		b[field] = Number(b[field]);
	}
	if (field === 'rateio') {
		a[field] = get_rateio_for_sort(a);
		b[field] = get_rateio_for_sort(b);
	}
	if (field === 'rateado') {
		a[field] = get_rateado_for_sort(a);
		b[field] = get_rateado_for_sort(b);
	}
	if (a[field] < b[field]) {
		return order === 'asc' ? -1 : 1;
	}
	if (a[field] > b[field]) {
		return order === 'asc' ? 1 : -1;
	}
	return 0;
};

const ArrecadacaoUnidadesTable = () => {
	const { data, ids, loading, selectedRows, setSelectedRows } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const [rows, setRows] = useState([]);
	const [selectableRows, setSelectableRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [sort, setSort] = useState({
		field: 'complemento',
		order: 'asc',
	});

	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);

	const configRows = useCallback(() => {
		if (!loading) {
			const newRows = (ids || [])
				.map((id) => (data || {})[id])
				.filter((d) => d.id !== 0)
				.sort(funcSort(sort.field, sort.order));

			setRows(newRows);
			setSelectableRows(newRows.filter((row) => !row.acordo_criador_id));
			setTotal(newRows.length);
			setSelectedRows(selectedRows.filter((r) => (ids || []).includes(r.id)));
			if ((!newRows.length && page !== 1) || !(newRows.length > (page - 1) * perPage))
				setPage(Math.ceil(newRows.length / perPage) || 1);
		}
	}, [loading, ids, data, sort.field, sort.order, page, perPage, selectedRows]);

	useEffect(configRows, [loading]);

	const handleSortClick = (field) => {
		const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
		setSort({ field, order: newOrder });
		setRows((r) => r.sort(funcSort(field, newOrder)));
	};

	const handleSelectAll = () => {
		selectedRows?.some((l) => selectableRows.includes(l)) ? setSelectedRows([]) : setSelectedRows(selectableRows);
	};

	const handleRowClick = (e, row, isItemSelected) => {
		if (isItemSelected) {
			setSelectedRows((r) => r.filter((d) => d !== row));
		} else {
			setSelectedRows((r) => [...r, row]);
		}
	};

	return !loading ? (
		<TableContainer>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<TableCell padding='checkbox'>
							<Checkbox
								indeterminate={
									selectedRows?.length !== selectableRows.length && selectedRows?.length !== 0
								}
								checked={selectedRows?.length === selectableRows.length}
								onChange={handleSelectAll}
							/>
						</TableCell>
						{colunas.map((c, i) => (
							<TableCell
								key={c.field}
								sortDirection={sort.field === c.field ? sort.order : false}
								style={i === 0 ? { paddingLeft: 0 } : {}}
							>
								<TableSortLabel
									active={sort.field === c.field}
									direction={sort.field === c.field ? sort.order : 'asc'}
									onClick={() => handleSortClick(c.field)}
								>
									{c.headerName}
								</TableSortLabel>
							</TableCell>
						))}
						<TableCell key='acoes'>Ações</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows
						.slice(
							...(rows.length < (page - 1) * perPage + 1
								? [
										(Math.ceil(rows.length / perPage) - 1) * perPage,
										Math.ceil((rows.length / perPage) * perPage),
								  ]
								: [(page - 1) * perPage, page * perPage])
						)
						.map((row) => {
							const isItemSelected = Boolean(
								selectedRows?.find((l) => l.id === row.id && l.origem === row.origem)
							);
							return (
								<TableRow
									hover
									role='checkbox'
									aria-checked={isItemSelected}
									tabIndex={-1}
									key={row.id}
									selected={isItemSelected}
								>
									<TableCell
										padding='checkbox'
										onClick={(e) => handleRowClick(e, row, isItemSelected)}
									>
										{!row.acordo_criador_id && (
											<Checkbox
												checked={isItemSelected}
												inputProps={{
													'aria-labelledby': row.id,
												}}
											/>
										)}
									</TableCell>
									<TableCell component='th' id={row.id} scope='row' padding='none'>
										{row.nome_conta_pai}
									</TableCell>
									<TableCell>{row.complemento}</TableCell>
									<TableCell>{get_tipo_conta(row)}</TableCell>
									<TableCell>{get_rateado(row)}</TableCell>
									<TableCell>
										<NumberFormatBRL
											displayType='text'
											value={row.valor}
											fixedDecimalScale
											onChange={() => {}}
											decimalScale={2}
											prefix={parseFloat(row.valor) < 0 ? 'R$ -' : 'R$ '}
										/>
									</TableCell>
									<TableCell>{get_rateio(row)}</TableCell>
									<TableCell>{!row.acordo_criador_id && <RowActions row={row} />}</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			<SyndkosTablePagination
				{...{
					handlePageChange,
					page,
					perPage,
					total,
					setPerPage,
				}}
				labelRowsPerPage='Arrecadações da Unidade por página'
			/>
		</TableContainer>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

const tiposResponsaveis = {
	P: 'Proprietário',
	I: 'Inquilino',
};

const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
}));

const NomePagador = ({ nome }) => {
	const classes = useStyles();
	return nome || <span className={classes.warningColor}>Nenhum Responsável Registrado Nessa Data</span>;
};

const ModalArrecadacaoUnidadesDialogBody = () => {
	const { conta } = useContext(ModalArrecadacaoUnidadesEditarContext);
	return (
		<CustomDialogBodySizeEditable
			title={
				<>
					<Typography variant='h6'>{'Editar Arrecadações do Lançamento de Conta a Receber'}</Typography>
					<Typography variant='h6'>
						{`${conta.tipo_unidade} ${conta.nome_unidade} - ${
							tiposResponsaveis[conta.tipo_responsavel] || ''
						} `}
						<NomePagador nome={conta.pagador_nome} />
					</Typography>
				</>
			}
			customActions={<ModalAcoes record={conta} />}
			form={{
				handleSubmit: () => {},
				component: (
					<Box
						css={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						<ArrecadacaoUnidadesTable record={conta} />
					</Box>
				),
			}}
		/>
	);
};

const ModalArrecadacaoUnidadesTable = ({ record }) => (
	<ModalArrecadacaoUnidadesEditarContextProvider {...{ record }}>
		<ModalArrecadacaoUnidadesDialogBody />
	</ModalArrecadacaoUnidadesEditarContextProvider>
);

export default ModalArrecadacaoUnidadesTable;
