import React from 'react';

import { StyledCalendar } from './StyledCalendarSyndkos/StyledCalendar';
import { ConsultaDeReservasContextProvider } from './context/AgendamentoContext';
import SelectCondominioReserva from './inputs/SelectCondominioReserva';
import SelectAmbienteReserva from './inputs/SelectAmbienteReserva';
import SelectUnidadeReserva from './inputs/SelectUnidadeReserva';
import { Box } from '@material-ui/core';

export const ConsultaReservasDeAmbiente = () => (
	<ConsultaDeReservasContextProvider>
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 10,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					gap: 10,
				}}
			>
				<SelectCondominioReserva />
				<SelectAmbienteReserva />
			</Box>
			<SelectUnidadeReserva />
		</Box>
		<Box>
			<StyledCalendar />
		</Box>
	</ConsultaDeReservasContextProvider>
);
