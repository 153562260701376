import React from 'react';

import { ReferenceInput } from 'react-admin';

const VeryHighLimitReferenceInput = ({ children, ...props }) => (
	<ReferenceInput perPage={10000} {...props}>
		{children}
	</ReferenceInput>
);

export default VeryHighLimitReferenceInput;
