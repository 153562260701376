import React from 'react';
import { Box, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { createFormatsOptionsAutoCompleteComponent } from '../Selects/formats';

export default function MultiSelect({ variant, value, setValue, options, withButtonAction = true, ...props }) {
	const { formatGroupBy, formatOption, checkGroup, selectGroup, label } =
		createFormatsOptionsAutoCompleteComponent(variant);
	const disabled = !options.length;

	const setAll = () => {
		setValue(options);
	};
	const setRemoveAll = () => {
		setValue([]);
	};

	return (
		<FormControlLabel
			style={{ display: 'flex', margin: '0px 0px 0px 0px' }}
			control={
				<Box flex={1}>
					<Autocomplete
						disableCloseOnSelect
						options={options}
						{...(formatGroupBy ? { groupBy: (option) => formatGroupBy(option) } : {})}
						renderInput={(params) => <TextField {...params} margin='dense' label={props.label || label} />}
						renderOption={(option, { selected }) => {
							return (
								<span>
									<Checkbox checked={selected} />
									{formatOption(options?.find((v) => v.id === option)) || formatOption(option)}
								</span>
							);
						}}
						getOptionSelected={(option, value) => option.id === value.id}
						{...(selectGroup
							? {
									renderGroup: (params) => {
										return (
											<>
												<Checkbox
													key={params.group}
													checked={checkGroup(options, params.group, value)}
													onChange={() => selectGroup(options, params.group, value, setValue)}
												/>
												<span>{params.group}</span>
												{params.children}
											</>
										);
									},
							  }
							: {})}
						getOptionLabel={(option) => {
							return (
								option && (formatOption(options.find((v) => v.id === option)) || formatOption(option))
							);
						}}
						noOptionsText='Nenhum resultado encontrado'
						onChange={(_, v) => {
							setValue(v);
						}}
						disabled={disabled}
						autoHighlight
						value={value}
						clearText='Limpar'
						multiple
						{...props}
					/>
					{withButtonAction ? (
						<>
							<Tooltip title='Selecionar Tudo'>
								<AddCircleOutlineIcon
									color={'primary'}
									style={{
										marginTop: '10px',
										marginRight: '20px',
										marginLeft: '20px',
									}}
									onClick={setAll}
								/>
							</Tooltip>
							<Tooltip title='Remover Tudo'>
								<RemoveCircleOutlineIcon
									color={'primary'}
									style={{
										marginTop: '10px',
										marginRight: '20px',
										marginLeft: '20px',
									}}
									onClick={setRemoveAll}
								/>
							</Tooltip>
						</>
					) : null}
				</Box>
			}
		/>
	);
}
