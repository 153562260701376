import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';
import { ButtonConfirm } from '../../../../../common/buttons/ButtonConfirm';
import ProvisionamentoContext from './ProvisionamentoContext';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	message_loading: {
		display: 'flex',
		alignItems: 'center',
		color: theme.primary[200],
		marginTop: '-45px',
		position: 'absolute',
		left: '25px',
	},
}));

const ModalConfirmaRecalculo = () => {
	const {
		executaRecalculo,
		closeModal,
		handleProvisionar,
		loadingArrecadacoes,
		realizandoRecalculo,
		realizandoProvisionamento,
	} = useContext(ProvisionamentoContext);
	const classes = useStyles();

	return (
		<CustomDialogBodySizeEditable
			text='Deseja recalcular as arrecadações antes de realizar o provisionamento?'
			title='Recálculo de Arrecadações'
			customActions={
				<>
					{loadingArrecadacoes ? (
						<div className={classes.message_loading}>
							<CircularProgress size='22px' />
							<span style={{ marginLeft: '10px' }}>Aguarde, carregando informações...</span>
						</div>
					) : (
						''
					)}
					<Button
						variant='text'
						onClick={closeModal}
						size='small'
						startIcon={<CloseIcon />}
						disabled={realizandoRecalculo || realizandoProvisionamento}
					>
						Cancelar
					</Button>
					<ButtonConfirm
						loading={realizandoRecalculo}
						disabled={loadingArrecadacoes || realizandoProvisionamento}
						onClick={executaRecalculo}
					>
						Recalcular
					</ButtonConfirm>
					<ButtonConfirm
						loading={realizandoProvisionamento}
						disabled={loadingArrecadacoes || realizandoRecalculo}
						onClick={handleProvisionar}
					>
						Provisionar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

export default ModalConfirmaRecalculo;
