import React, { useState, useContext, useEffect, useRef } from 'react';

import { NumberInput } from 'react-admin';

import { Field } from 'react-final-form';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { createGetPropsValidas } from '../utils/functions';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const propNamesField = ['name', 'id'];

const getPropsValidasField = createGetPropsValidas(propNamesField);

const propNamesInputAdornment = ['position'];

const getPropsValidasInputAdornment = createGetPropsValidas(propNamesInputAdornment);

const propNamesLockNumberField = [
	'source',
	'name',
	'defaultValue',
	'disabled',
	'fullWidth',
	'helperText',
	'label',
	'parse',
	// 'validate',
	// 'resettable',
	'variant',
	'margin',
	'min',
	'step',
	'initialValue',
];

const getPropsValidasLockNumberField = createGetPropsValidas(propNamesLockNumberField);

const validaSenha = (promptMsg, promptPassword) => prompt(promptMsg, promptPassword) === promptPassword;

const LockNumberField = ({
	source,
	promptMsg: standardPromptMsg = '',
	promptPassword: standardPromptPassword = 'SCI',
	helperText: standardHelperText = '',
	disabled: standardDisabled = false,
	onChange: standardOnChange,
	options: standardOptions,
	...standardProps
}) => {
	const fieldRef = useRef(null);
	const [lockField, setLockField] = useState(true);
	useEffect(() => {
		if (!lockField && fieldRef.current) fieldRef.current.focus();
	}, [lockField, fieldRef]);

	const {
		TipoContaContext,
		initialDados: { isInativo },
	} = useContext(EditaContaBancariaContext);
	const { initialValues } = useContext(TipoContaContext);
	const { props } = useContext(DadosPorTipoIntegracaoContext);
	const {
		disabled = false,
		helperText = false,
		promptMsg = standardPromptMsg,
		promptPassword = standardPromptPassword,
		...sourceProps
	} = props[source] || {};

	const initialValue = initialValues[source] ?? 0;

	return (
		<Field {...getPropsValidasField({ ...standardProps, ...sourceProps })}>
			{({ input, meta }) => (
				<NumberInput
					source={input.name}
					name={input.name}
					initialValue={initialValue}
					disabled={lockField || isInativo || disabled || standardDisabled}
					parse={(value) => ((typeof value !== 'number' && !value) || isNaN(value) ? 0 : parseInt(value))}
					error={!!meta.error}
					helperText={(!!meta && (meta.error ? meta.error : false)) || helperText || standardHelperText || ''}
					variant='outlined'
					min={0}
					step={1}
					fullWidth
					{...getPropsValidasLockNumberField({ ...standardProps, ...sourceProps })}
					InputProps={{
						inputRef: fieldRef,
						endAdornment: (
							<InputAdornment
								position='end'
								{...getPropsValidasInputAdornment({ ...standardProps, ...sourceProps })}
							>
								<IconButton
									disabled={isInativo || disabled || standardDisabled}
									aria-label={`toggle ${input.name} disabled`}
									onClick={() => {
										if (!isInativo && validaSenha(promptMsg, promptPassword)) setLockField(false);
									}}
								>
									{lockField ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
								</IconButton>
							</InputAdornment>
						),
					}}
					onBlur={(e) => {
						input.onBlur(e);
						setLockField(true);
					}}
				/>
			)}
		</Field>
	);
};

export default LockNumberField;
