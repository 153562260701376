import React, { useContext, useEffect } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import dataProvider from 'dataProvider';
import { ButtonRemoveV2 } from '../../../common/buttons/ButtonRemoveV2';
import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContext,
	ModalSizeEditableContextProvider,
} from '../../../common/ModalSizeEditableContext';
import {
	ListRetencoesImpostosContext,
	ListRetencoesImpostosContextProvider,
} from '../context/ListRetencoesImpostosContext';
import { Box, Typography, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { TooltipIconButtonCancel } from '../../../common/buttons/ButtonCancelV2';
import WarningIcon from '@material-ui/icons/Warning';
import { ThemePaletteProps } from 'theme/types';

import {
	ModalRemoverRetencaoImpostoProps,
	ButtonRemoverProps,
	ButtonRemoveRetencaoImpostoProps,
	ModalValue,
} from '../types';

const ModalRemoverRetencaoImposto: React.FC<ModalRemoverRetencaoImpostoProps> = ({
	selectedIds,
}: ModalRemoverRetencaoImpostoProps) => {
	const {
		palette: { syndikosRed },
	} = useTheme<ThemePaletteProps>();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const refresh = useRefresh();
	const dP = dataProvider;

	const handleClose = () => {
		setModalValue((v: ModalValue) => ({
			...v,
			open: false,
		}));
	};

	const handleDelete = () => {
		dP.deleteMany('retencoes_impostos', { ids: selectedIds })
			.then(() => {
				notify(
					selectedIds.length > 1
						? 'Retenções de impostos removidas com sucesso'
						: 'Retenção de imposto removida com sucesso',
					{ type: 'info' }
				);
				refresh();
				handleClose();
			})
			.catch(() => {
				notify(
					selectedIds.length > 1
						? 'Erro ao desfazer retenções de impostos'
						: 'Erro ao desfazer retenção de imposto.',
					{ type: 'error' }
				);
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title='Tem certeza que deseja desfazer esta Retenção de Imposto?'
			form={{
				component: (
					<Box display='flex' alignItems='center' gridGap='20px'>
						<Box>
							<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
						</Box>
						<Box>
							<Typography
								style={{
									fontWeight: 'bold',
									color: syndikosRed.main,
								}}
							>
								Essa ação é irreversível.
								<br />
								Todo o histórico será excluído.
							</Typography>
						</Box>
					</Box>
				),
			}}
			customActions={
				<>
					<TooltipIconButtonCancel onClick={handleClose} />
					<ButtonRemoveV2 size='small' onClick={handleDelete} />
				</>
			}
		/>
	);
};

const ButtonRemover: React.FC<ButtonRemoverProps> = ({ selectedIds }: ButtonRemoverProps) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	return (
		<ListRetencoesImpostosContextProvider>
			<Button
				onClick={() => {
					setModalValue((v: ModalValue) => ({
						...v,
						open: true,
						dialogBody: <ModalRemoverRetencaoImposto selectedIds={selectedIds} />,
					}));
				}}
			>
				Desfazer Retenção
			</Button>
		</ListRetencoesImpostosContextProvider>
	);
};

const ButtonRemoverRetencaoImposto: React.FC<ButtonRemoveRetencaoImpostoProps> = () => {
	const { selectedIds }: ButtonRemoverProps = useContext(ListRetencoesImpostosContext);

	useEffect(() => {}, [selectedIds]);

	return (
		<ListRetencoesImpostosContextProvider>
			<ModalSizeEditableContextProvider>
				<ButtonRemover selectedIds={selectedIds} />
			</ModalSizeEditableContextProvider>
		</ListRetencoesImpostosContextProvider>
	);
};

export default ButtonRemoverRetencaoImposto;
