import React from 'react';
import { Edit } from 'react-admin';
import { UnidadeFormulario } from './form';

export const UnidadeEdit = (props) => {
	return (
		<Edit title={'Editar Unidade'} undoable={false} {...props}>
			<UnidadeFormulario edit {...props} />
		</Edit>
	);
};
