import React, { useContext, useEffect, useState } from 'react';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionSummary, AccordionDetails, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CurrencyField } from '../../../common/CurrencyInput';
import { LancamentoContaPagarContext } from '../LancamentoContaPagarContext';
import { ModalSizeEditableContextProvider, ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import { TooltipIconButton } from '../../../common/TooltipIconButton';
import TooltipBar from '../../../common/TooltipBar';

import decimalAdjust from '../../../common/decimalAdjust';

import {
	ModalIncluirNovaDespesa,
	ModalEditarDespesa,
	ModalExcluirDespesa,
	ModalIncluirNovaDespesaImposto,
} from './modaisDespesas';

const TooltipButtonIncluirDespesa = () => {
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);

	return (
		<TooltipIconButton
			size='small'
			title='Incluir Despesa'
			onClick={() => {
				setMinWidthModel('80vw');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalIncluirNovaDespesaImposto context={ModalSizeEditableContext} />,
				}));
			}}
		>
			<AddCircleOutlineIcon />
		</TooltipIconButton>
	);
};

const TooltipButtonEditaDespesa = (record) => {
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);

	return (
		<TooltipIconButton
			size='small'
			title='Editar Despesa'
			onClick={() => {
				setMinWidthModel('80vw');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalEditarDespesa context={ModalSizeEditableContext} record={record} />,
				}));
			}}
		>
			<EditIcon />
		</TooltipIconButton>
	);
};

const TooltipButtonExcluirDespesa = (record) => {
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	return (
		<TooltipIconButton
			size='small'
			title='Excluir Despesa'
			onClick={() => {
				setMinWidthModel('55vw');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalExcluirDespesa record={record} />,
				}));
			}}
		>
			<DeleteOutlineIcon />
		</TooltipIconButton>
	);
};

const tipoLancamentoChips = {
	C: <Chip label='Condomínio' color='primary' size='small' />,
	G: <Chip label='Grupo' color='secondary' size='small' />,
};

const TableCellLeft = (props) => <TableCell align='left' {...props} />;

const TableCellCenter = (props) => <TableCell align='center' {...props} />;

const TableCellRight = (props) => <TableCell align='right' {...props} />;

const InfoDespesa = ({
	id,
	codigo,
	conta_despesa,
	tipo_lancamento,
	condominio,
	grupo,
	quantidade,
	valor_unitario,
	valor_total_bruto,
	anexo_nf,
	anexo_ordem_compra_servico,
	impostos,
}) => {
	const { parcelas, record } = useContext(LancamentoContaPagarContext);

	return (
		<>
			<TableRow>
				<TableCellCenter style={{ paddingLeft: 0, paddingRight: 0, width: '5%', whiteSpace: 'nowrap' }}>
					{codigo}
				</TableCellCenter>
				<TableCellLeft
					style={{
						textTransform: 'none',
						paddingLeft: 0,
						paddingRight: 0,
						width: '20%',
						whiteSpace: 'nowrap',
					}}
				>
					{conta_despesa?.nome}
				</TableCellLeft>
				<TableCellCenter
					align='center'
					style={{
						textTransform: 'none',
						paddingLeft: 0,
						paddingRight: 0,
						width: '1%',
						whiteSpace: 'nowrap',
					}}
				>
					{tipo_lancamento && tipoLancamentoChips[tipo_lancamento.id]}
				</TableCellCenter>
				<TableCellCenter style={{ paddingLeft: 0, paddingRight: 0 }}>{grupo?.nome}</TableCellCenter>
				<TableCellCenter style={{ paddingLeft: 0, paddingRight: 0 }}>{quantidade || '0'}</TableCellCenter>
				<TableCellRight style={{ paddingLeft: 0, paddingRight: 0 }}>
					<CurrencyField
						prefix='R$ '
						minimumFractionDigits={2}
						value={valor_unitario || 0}
						id={`valor_unitario-${codigo}`}
					/>
				</TableCellRight>
				<TableCellRight style={{ paddingLeft: 0, paddingRight: 0 }}>
					<CurrencyField
						prefix='R$ '
						minimumFractionDigits={2}
						value={valor_total_bruto || 0}
						id={`valor_total-${codigo}`}
					/>
				</TableCellRight>
				<TableCellRight style={{ paddingLeft: 0, paddingRight: 0 }}>
					<CurrencyField
						prefix='R$ '
						minimumFractionDigits={2}
						value={
							Array.isArray(impostos)
								? valor_total_bruto -
								  impostos?.reduce((total, imposto) => total + parseFloat(imposto.valor), 0).toFixed(2)
								: valor_total_bruto
						}
						id={`valor_liquido-${codigo}`}
					/>
				</TableCellRight>
				<TableCellCenter style={{ paddingLeft: 0, paddingRight: 0 }}>
					{parcelas.length < 2 &&
						(parcelas[0] ? parcelas[0].situacao === 'P' : true) &&
						!record?.retencao_imposto && (
							<TooltipBar>
								<TooltipButtonEditaDespesa
									{...{
										id,
										codigo,
										tipo_lancamento,
										condominio,
										grupo,
										quantidade,
										valor_unitario,
										valor_total_bruto,
										conta_despesa,
										anexo_nf,
										anexo_ordem_compra_servico,
										impostos,
									}}
									key='edita-button'
								/>
								<TooltipButtonExcluirDespesa
									{...{
										id,
										codigo,
									}}
									key='exclui-button'
								/>
							</TooltipBar>
						)}
				</TableCellCenter>
			</TableRow>
		</>
	);
};

export const TabelaDespesas = () => {
	const { despesas, selectedCondominio, parcelas, erros, setErros, record } = useContext(LancamentoContaPagarContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const validate = () => {
		if (!despesas || !despesas.length) {
			setErros((e) => ({
				...e,
				despesas: 'Inclua ao menos uma despesa',
			}));
		} else if (erros?.despesas) {
			setErros((e) => {
				const { despesas, ...rest } = e;
				return rest;
			});
		}
	};
	const [expanded, setExpanded] = useState(false);

	const handleChange = () => {
		setExpanded((prev) => !prev);
	};

	useEffect(validate, [despesas]);

	const impostos = despesas
		.flatMap((despesa) => despesa.impostos)
		.reduce((acc, imposto) => {
			if (!acc[imposto.nome]) {
				acc[imposto.nome] = {
					...imposto,
					valor: decimalAdjust('round', parseFloat(imposto.valor), -2),
				};
			} else {
				acc[imposto.nome].valor += decimalAdjust('round', parseFloat(imposto.valor), -2);
			}
			return acc;
		}, {});

	const impostosAgrupados = Object.values(impostos);
	return (
		<Box>
			{erros?.despesas && (
				<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>{erros.despesas}</Typography>
			)}
			{selectedCondominio &&
				parcelas.length < 2 &&
				(parcelas[0] ? parcelas[0].situacao === 'P' : true) &&
				!record.retencao_imposto && <TooltipButtonIncluirDespesa record={record} />}
			<Table size='small' padding='default'>
				<TableHead>
					<TableRow>
						<TableCellCenter
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '5%',
								whiteSpace: 'nowrap',
							}}
						>
							#
						</TableCellCenter>
						<TableCellLeft
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '15%',
								whiteSpace: 'nowrap',
							}}
						>
							Despesa
						</TableCellLeft>
						<TableCellCenter
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '10%',
								whiteSpace: 'nowrap',
							}}
						>
							Tipo Lançamento
						</TableCellCenter>
						<TableCellCenter
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '20%',
								whiteSpace: 'nowrap',
							}}
						>
							Grupo
						</TableCellCenter>
						<TableCellCenter
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '5%',
								whiteSpace: 'nowrap',
							}}
						>
							Quantidade
						</TableCellCenter>
						<TableCellRight
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '12%',
								whiteSpace: 'nowrap',
							}}
						>
							Valor Unitário
						</TableCellRight>
						<TableCellRight
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '12%',
								whiteSpace: 'nowrap',
							}}
						>
							Valor Total
						</TableCellRight>
						<TableCellRight
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '12%',
								whiteSpace: 'nowrap',
							}}
						>
							Valor Líquido
						</TableCellRight>
						<TableCellCenter
							style={{
								paddingLeft: 0,
								paddingRight: 0,
								width: '10%',
								whiteSpace: 'nowrap',
							}}
						></TableCellCenter>
					</TableRow>
				</TableHead>

				<TableBody>
					{despesas?.length > 0 &&
						despesas?.map((despesa, index) => <InfoDespesa key={`despesa-${index}`} {...despesa} />)}
				</TableBody>
			</Table>
			<Box
				onClick={handleChange}
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					cursor: impostosAgrupados.length > 0 ? 'pointer' : 'not-allowed',
					pointerEvents: impostosAgrupados.length > 0 ? 'auto' : 'none',
					opacity: impostosAgrupados.length > 0 ? 1 : 0.5,
					marginTop: '20px',
				}}
			>
				<ExpandMoreIcon
					style={{
						transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: 'transform 0.4s ease-in-out',
						opacity: impostosAgrupados.length > 0 && impostosAgrupados.length > 0 ? 1 : 0.5,
					}}
				/>
				<Typography
					style={{
						marginLeft: '10px',
						fontWeight: 'bold',
						color: impostosAgrupados.length > 0 && expanded ? '#3f51b5' : '#000',
						transition: 'color 0.4s ease-in-out',
					}}
				>
					{'Detalhamento das retenções'}
				</Typography>
			</Box>
			<Box
				style={{
					maxHeight: impostosAgrupados.length > 0 && expanded ? '1000px' : '0px',
					overflow: 'hidden',
					transition: 'max-height 2s ease-out, opacity 0.2s ease-out',
					opacity: impostosAgrupados.length > 0 && expanded ? 1 : 0,
				}}
			>
				{impostosAgrupados.length > 0 && expanded && (
					<Accordion expanded={expanded} onChange={handleChange}>
						<AccordionDetails>
							<Table size='small'>
								<TableHead>
									<TableRow>
										<TableCell>
											<b>Imposto</b>
										</TableCell>
										<TableCell>
											<b>Alíquota</b>
										</TableCell>
										<TableCell align='right'>
											<b>Valor do Imposto</b>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{impostosAgrupados?.map((imposto, index) => (
										<TableRow key={`imposto-${index}`}>
											<TableCell>{imposto.nome}</TableCell>
											<TableCell>{imposto.aliquota}%</TableCell>
											<TableCell align='right'>
												<CurrencyField
													prefix='R$ '
													minimumFractionDigits={2}
													value={imposto.valor.toFixed(2)}
													id={'imposto_valor'}
												/>
											</TableCell>
										</TableRow>
									))}
									<TableRow>
										<TableCell style={{ textTransform: 'none' }}>
											<b>Total</b>
										</TableCell>
										<TableCell></TableCell>
										<TableCell align='right' style={{ textTransform: 'none' }}>
											<b>
												<CurrencyField
													prefix='R$ '
													minimumFractionDigits={2}
													value={
														Array.isArray(impostosAgrupados) &&
														impostosAgrupados
															?.reduce(
																(total, imposto) => total + parseFloat(imposto.valor),
																0
															)
															.toFixed(2)
													}
													id={'imposto_valor'}
												/>
											</b>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</AccordionDetails>
					</Accordion>
				)}
			</Box>
		</Box>
	);
};

const ListDespesas = () => (
	<ModalSizeEditableContextProvider
		customDialogProps={{
			disableBackdropClick: true,
			disableEscapeKeyDown: true,
			minWidth: '90vw',
		}}
	>
		<TabelaDespesas />
	</ModalSizeEditableContextProvider>
);

export default ListDespesas;
