import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Send } from '@material-ui/icons';
import { Field } from 'react-final-form';
import { TextInputSyndikos } from 'components/common/Formulario';
import { Form } from 'react-final-form';
import InputFile from 'components/common/InputFile';
import { CustomDataProvider } from '../types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface EnviarMensagemProps {
	occurrenceId: number;
	classes: ClassNameMap<string>;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormValues {
	mensagem: string;
	ocorrencia: number;
	tipo_acao: string;
	write_only_anexo: string;
	preventDefault: () => void;
}

export const OccurrenceSendMessage: React.FC<EnviarMensagemProps> = ({ occurrenceId, classes, setLoading }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const dataProvider: CustomDataProvider = useDataProvider();

	const handleSubmit = ({ mensagem, write_only_anexo }: FormValues) => {
		dataProvider
			.simplePost('acao_ocorrencias', {
				data: {
					mensagem: mensagem,
					ocorrencia: occurrenceId,
					tipo_acao: 'C',
					write_only_anexo: write_only_anexo,
				},
			})
			.then(() => {
				refresh();
				setLoading(false);
			})
			.catch((e: any) => {
				setLoading(false);
				if (mensagem.length == 0) return notify('Digite uma mensagem para enviar!');
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify('Ocorreu um erro ao enviar o comentário');
			});
	};

	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			render={({ values }: { values: FormValues }) => (
				<>
					<Box display={'flex'} alignItems={'flex-start'} ml={'1vh'}>
						<Box width={'100%'}>
							<Field name={'mensagem'}>
								{({ input, meta }) => (
									<TextInputSyndikos
										label='Digite uma mensagem'
										source='mensagem'
										multiline
										rows={3}
										fullWidth
										validate={(value: string) => {
											if (!value) {
												return 'Este campo é obrigatório';
											} else if (value.length > 500) {
												return `Máximo excedido: ${value.length}/500`;
											} else {
												return '';
											}
										}}
										helperText={meta?.error ? meta?.error : `${(input?.value || '').length}/500`}
										defaultValue=''
									/>
								)}
							</Field>
						</Box>
					</Box>
					<Box display='flex' justifyContent={'left'} ml={1} mt={2}>
						<Field name='write_only_anexo' id='write_only_anexo'>
							{({ input }) => (
								<InputFile
									source='write_only_anexo'
									label='Arquivo em anexo'
									variant='outlined'
									onCallBack={(e: any, dataUrl: string) => {
										input.onChange(dataUrl);
									}}
									fileDataUrl={undefined}
								/>
							)}
						</Field>
					</Box>
					<Box display={'flex'} justifyContent={'center'} mt={5}>
						<Button
							onClick={() => handleSubmit(values)}
							variant='contained'
							size='small'
							disabled={!(values?.mensagem?.length <= 500)}
							style={{
								marginRight: '1vh',
								width: '20vh',
								backgroundColor: values?.mensagem?.length <= 500 ? '#FA6D35' : undefined,
							}}
						>
							<span className={classes.ButtonSendMessage}>Enviar</span>
							<Send className={classes.IconSendMessage} />
						</Button>
					</Box>
				</>
			)}
		/>
	);
};
