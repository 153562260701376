import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import { ConfiguracaoDeCorrecaoMonetariaContext } from '../../contexts';

const Empty: React.FC = () => {
	const { handleSetRedirectContext } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);
	function handleClick() {
		handleSetRedirectContext('create', null);
	}

	return (
		<Box textAlign='center' m={1}>
			<Typography variant='h4' paragraph>
				Sem Correções Monetárias cadastradas
			</Typography>
			<Typography variant='h5' style={{ marginBottom: '1rem' }}>
				Crie uma nova Correção Monetária
			</Typography>
			<Button variant='text' startIcon={<AddIcon />} onClick={handleClick} size='small'>
				Cadastrar
			</Button>
		</Box>
	);
};

export default Empty;
