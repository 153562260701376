import React, { createContext, useEffect, useState, useMemo, useContext, useRef, useCallback } from 'react';

import axios from 'axios';

import { useDataProvider } from 'react-admin';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { format } from 'date-fns';
import { ContasBancariasContext } from './ContasBancariasContextProvider';

export const ArrecadacoesContext = createContext();

export const ArrecadaçõesContextProvider = ({ children }) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const dataProvider = useDataProvider();
	const { data: contasBancariasPorId = {}, options: contasBancarias = [] } = useContext(ContasBancariasContext);

	const [identAtivasData, setIdentAtivasData] = useState();
	const [identData, setIdentData] = useState();
	const [id_identificacao_arrecadacao, set_id_identificacao_arrecadacao] = useState();
	const [updateIdent, setUpdateIdent] = useState({});

	const [referencia, set_referencia] = useState(format(new Date().setDate(1), 'yyyy-MM-dd'));

	const condominioRef = useRef(null);

	const [valores, setValores] = useState({});

	const [{ tem_arrecadacao_provisionada, conta_b_destino_id, tem_recebimento_pago }, setDadosProvisionamento] =
		useState({
			tem_arrecadacao_provisionada: false,
			conta_b_destino_id: null,
			tem_recebimento_pago: false,
		});

	const [contaBDestino, setContaBDestino] = useState(null);
	const [contaBDestinoIsValid, setContaBDestinoIsValid] = useState(false);
	const contaBDestinoId = contaBDestino?.id ?? null;
	const { codigo_instituicao_financeira, codigo_tipo_integracao } = contaBDestino || {};
	const identificacaoInstituicaoTipoIntegracao = `${codigo_instituicao_financeira}-${codigo_tipo_integracao}`;

	const condominioSelecionadoId = condominioSelecionado?.id;
	const idPlano = condominioSelecionado?.id_plano_condominio ?? null;

	const fetchIdentificacoes = () => {
		if (!condominioSelecionadoId) {
			setIdentData([]);
			set_id_identificacao_arrecadacao(null);
			condominioRef.current = null;
			return;
		}
		dataProvider
			.getList('identificacao_arrecadacao', {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'nome', order: 'ASC' },
				filter: { id_condominio: condominioSelecionadoId },
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					if (!condominioRef.current || condominioSelecionadoId !== condominioRef.current) {
						set_id_identificacao_arrecadacao(data[0].id);
					}
					setIdentData(data);
					condominioRef.current = condominioSelecionadoId;
				}
			});
	};

	useEffect(fetchIdentificacoes, [condominioSelecionadoId, updateIdent]);

	const updateIdentAtivasData = useCallback(() => {
		if (identData) setIdentAtivasData(identData.filter((i) => i.situacao === 'A'));
	}, [identData, setIdentAtivasData]);
	useEffect(updateIdentAtivasData, [identData]);

	const carregaValores = () => {
		let totalContas = 0;
		let ord = 0;
		let ext = 0;
		let leituras = 0;
		let fundos = 0;
		let total = 0;
		let desconto = 0;
		dataProvider
			.getList('arrecadacao', {
				pagination: { perPage: 1000, page: 1 },
				sort: { field: 'valor', order: 'ASC' },
				filter: {
					id_identificacao_arrecadacao,
					referencia,
					id_condominio: condominioSelecionadoId,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data && data[0]?.id !== 0) {
					totalContas = data.length;
					dataProvider
						.getSimple('arrecadacao_unidades', {
							pagination: { perPage: 1000, page: 1 },
							sort: { field: 'valor', order: 'ASC' },
							filter: {
								identificacao_id: id_identificacao_arrecadacao,
								referencia,
								condominio_id: condominioSelecionadoId,
							},
							id: 'get_valores_totais',
						})
						.then((response) => {
							const data = response?.data;
							if (data) {
								ord = data.valores[0];
								leituras = data.valores[1];
								fundos = data.valores[2];
								ext = data.valores[3];
								total = data.valores[4];
								desconto = data.valores[5];
							}

							setValores((v) => ({
								...v,
								ord,
								ext,
								leituras,
								fundos,
								total,
								desconto,
								totalContas,
							}));
						});
				} else {
					setValores((v) => ({
						...v,
						ord,
						ext,
						leituras,
						fundos,
						total,
						desconto,
						totalContas,
					}));
				}
			});
	};

	const carregaValoresCB = useCallback(carregaValores, [
		condominioSelecionadoId,
		id_identificacao_arrecadacao,
		referencia,
	]);

	useEffect(carregaValores, [condominioSelecionadoId, id_identificacao_arrecadacao, referencia]);

	const fetchDadosProvisionamento = () => {
		const dadosProvisionamentoVazio = {
			tem_arrecadacao_provisionada: false,
			conta_b_destino_id: null,
			tem_recebimento_pago: false,
		};
		if (id_identificacao_arrecadacao && referencia && condominioSelecionadoId) {
			const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
			dataProvider
				.getSimple('cld_recebimento_manual', {
					id: 'get_dados_extras',
					filter: {
						referencia: referencia,
						condominio_id: condominioSelecionadoId,
						identificacao_id: id_identificacao_arrecadacao,
					},
					cancelToken,
				})
				.then((response) => {
					const data = response?.data || dadosProvisionamentoVazio;
					const conta_b_destino_id = data?.conta_b_destino_id || null;
					setContaBDestino(
						(contaBancaria) =>
							contasBancariasPorId[conta_b_destino_id || contaBancaria?.id] ?? contasBancarias[0] ?? null
					);
					setDadosProvisionamento(data);
				})
				.catch((e) => {
					if (axios.isCancel(e)) return;
					setContaBDestino(
						(contaBancaria) => contasBancariasPorId[contaBancaria?.id] ?? contasBancarias[0] ?? null
					);
					setDadosProvisionamento(dadosProvisionamentoVazio);
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				});
			return () => {
				cancelRequest();
			};
		} else {
			setContaBDestino((contaBancaria) => contasBancariasPorId[contaBancaria?.id] ?? contasBancarias[0] ?? null);
			setDadosProvisionamento(dadosProvisionamentoVazio);
		}
	};

	useEffect(fetchDadosProvisionamento, [contasBancariasPorId, id_identificacao_arrecadacao, referencia]);

	const checkIsValidContaBDestino = () => {
		if (!contaBDestinoId || !condominioSelecionadoId) return;
		dataProvider
			.getSimple('remessas/valida_conta_bancaria', {
				filter: {
					conta_bancaria_id: contaBDestinoId,
					condominio_id: condominioSelecionadoId,
				},
			})
			.then(() => {
				setContaBDestinoIsValid(true);
			})
			.catch((e) => {
				setContaBDestinoIsValid(false);
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			});
	};

	useEffect(checkIsValidContaBDestino, [id_identificacao_arrecadacao, referencia, contaBDestinoId]);

	const arrecadacoesProviderValue = useMemo(
		() => ({
			id_identificacao_arrecadacao,
			set_id_identificacao_arrecadacao,
			referencia,
			set_referencia,
			identAtivasData,
			setIdentAtivasData,
			identData,
			setIdentData,
			setUpdateIdent,
			valores,
			setValores,
			contaBDestino,
			contaBDestinoId,
			setContaBDestino,
			tem_arrecadacao_provisionada,
			conta_b_destino_id,
			tem_recebimento_pago,
			setDadosProvisionamento,
			idPlano,
			carregaValoresCB,
			contaBDestinoIsValid,
			identificacaoInstituicaoTipoIntegracao,
		}),
		[
			id_identificacao_arrecadacao,
			set_id_identificacao_arrecadacao,
			referencia,
			set_referencia,
			identAtivasData,
			setIdentAtivasData,
			identData,
			setIdentData,
			setUpdateIdent,
			valores,
			setValores,
			contaBDestino,
			contaBDestinoId,
			setContaBDestino,
			tem_arrecadacao_provisionada,
			conta_b_destino_id,
			tem_recebimento_pago,
			setDadosProvisionamento,
			idPlano,
			carregaValoresCB,
			contaBDestinoIsValid,
			identificacaoInstituicaoTipoIntegracao,
		]
	);

	return <ArrecadacoesContext.Provider value={arrecadacoesProviderValue}>{children}</ArrecadacoesContext.Provider>;
};
