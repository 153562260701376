import { styled } from '@material-ui/core/styles';

export const CalendarioInfo = styled('div')({
	margin: '20px 0px',
	display: 'flex',
	gap: '10px',
	alignItems: 'center',

	'& label': {
		display: 'flex',
		gap: '10px',
		alignItems: 'center',
		width: 'fit-content',
		flexWrap: 'wrap',
	},
});
