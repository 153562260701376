import React, { useContext } from 'react';

import { ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import TooltipBar from '../../../common/TooltipBar';
import { ModalTrocaOwner } from '../modais/proprietario';
import {
	TooltipButtonOpenEdicaoTela,
	TooltipButtonRemoveVinculoResponsavel,
	TooltipButtonEditaProprietario,
	TooltipButtonTrocaProprietario,
	TooltipButtonEditaInquilino,
	TooltipButtonDefinirDataFimInquilino,
} from '../actionsButtonsResponsaveis';

const TooltipBarProprietarioCreate = ({
	id,
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	enderecoDados,
	telefones,
	email_cobranca,
}) => (
	<TooltipBar>
		<TooltipButtonOpenEdicaoTela href={`/admin#/pessoas/${id_pessoa}`} key='action0' />
		<TooltipButtonEditaProprietario
			{...{
				id,
				id_pessoa,
				nome,
				tipo_pessoa,
				documento,
				data_entrada,
				enderecoDados,
				telefones,
				email_cobranca,
			}}
			key='action1'
		/>
	</TooltipBar>
);

const TooltipBarProprietarioEdit = ({ id, id_pessoa, nome, tipo_pessoa, documento, data_entrada, enderecoDados }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	const openTroca = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<ModalTrocaOwner
					{...{
						nome,
						data_entrada,
						documento,
						tipo_pessoa,
						id_pessoa,
						id,
						enderecoDados,
					}}
				/>
			),
		}));
	};

	return (
		<TooltipBar>
			<TooltipButtonOpenEdicaoTela href={`/admin#/pessoas/${id_pessoa}`} key='action0' />
			<TooltipButtonTrocaProprietario openTroca={openTroca} key='action1' />
		</TooltipBar>
	);
};

const TooltipBarProprietario = ({
	edit,
	id,
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	enderecoDados,
	telefones,
	email_cobranca,
}) =>
	edit ? (
		<TooltipBarProprietarioEdit
			{...{
				id,
				id_pessoa,
				nome,
				tipo_pessoa,
				documento,
				data_entrada,
				enderecoDados,
			}}
		/>
	) : (
		<TooltipBarProprietarioCreate
			{...{
				id,
				id_pessoa,
				nome,
				tipo_pessoa,
				documento,
				data_entrada,
				enderecoDados,
				telefones,
				email_cobranca,
			}}
		/>
	);

const TooltipBarInquilinoCreate = ({
	id,
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	enderecoDados,
	telefones,
	email_cobranca,
	data_saida,
	imobiliaria,
	tipo,
}) => (
	<TooltipBar>
		<TooltipButtonOpenEdicaoTela href={`/admin#/pessoas/${id_pessoa}`} key='action0' />
		<TooltipButtonRemoveVinculoResponsavel key='action1' {...{ id_pessoa, nome, id, tipo }} />
		<TooltipButtonEditaInquilino
			key='action2'
			{...{
				id,
				id_pessoa,
				nome,
				tipo_pessoa,
				documento,
				data_entrada,
				enderecoDados,
				data_saida,
				imobiliaria,
				telefones,
				email_cobranca,
			}}
		/>
	</TooltipBar>
);

const TooltipBarInquilinoEdit = ({
	id,
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	enderecoDados,
	telefones,
	email_cobranca,
	data_saida,
	imobiliaria,
}) => (
	<TooltipBar>
		<TooltipButtonOpenEdicaoTela href={`/admin#/pessoas/${id_pessoa}`} key='action0' />
		<TooltipButtonDefinirDataFimInquilino
			key='action1'
			{...{
				id,
				id_pessoa,
				nome,
				tipo_pessoa,
				documento,
				data_entrada,
				data_saida,
				imobiliaria,
				enderecoDados,
				telefones,
				email_cobranca,
			}}
		/>
	</TooltipBar>
);

const TooltipBarInquilino = ({
	edit,
	id,
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	enderecoDados,
	data_saida,
	imobiliaria,
	telefones,
	email_cobranca,
	tipo,
}) =>
	edit ? (
		<TooltipBarInquilinoEdit
			{...{
				id,
				id_pessoa,
				nome,
				tipo_pessoa,
				documento,
				data_entrada,
				enderecoDados,
				data_saida,
				imobiliaria,
				telefones,
				email_cobranca,
			}}
		/>
	) : (
		<TooltipBarInquilinoCreate
			{...{
				id,
				id_pessoa,
				nome,
				tipo_pessoa,
				documento,
				data_entrada,
				enderecoDados,
				data_saida,
				imobiliaria,
				telefones,
				email_cobranca,
				tipo,
			}}
		/>
	);

const actionsPorTipoResponsavel = {
	P: TooltipBarProprietario,
	I: TooltipBarInquilino,
};

const ActionsResponsavel = ({ tipo, ...props }) => {
	const ActionsResponsavel = actionsPorTipoResponsavel[tipo];

	if (!ActionsResponsavel) return null;

	return <ActionsResponsavel tipo={tipo} {...props} />;
};

export default ActionsResponsavel;
