import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import NovaLeituraContext from './NovaLeituraContext';
import FaixasLeitura from '../../componentes/FaixasLeitura';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';

const ModalFaixaCompletaNovaLeitura = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { dadosNovaLeitura, handleCancel, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const [disabledForProcessing, setDisabledForProcessing] = useState(false);
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const handleBack = () => {
		if ('k' === dadosNovaLeitura.dados_config.unidade_medida) setModalStep('5F');
		if ('m' === dadosNovaLeitura.dados_config.unidade_medida) {
			setModalStep(dadosNovaLeitura.dados_config.fator_convercao ? '7FK' : '6FK');
		}
	};

	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			mutators={{ ...arrayMutators }}
			initialValues={{
				faixas_leitura: [
					{
						de: 0,
						ate: 999999999.999999,
						valor: 0,
						taxa_fixa_adicional: 0,
					},
				],
				nao_cobrar_taxa: false,
				acesso_app: false,
			}}
			validate={(values) => {
				const errors = {};
				values.faixas_leitura.forEach((faixa, index) => {
					if (faixa.valor < 0) {
						errors.faixas_leitura = errors.faixas_leitura || [];
						errors.faixas_leitura[index] = errors.faixas_leitura[index] || {};
						errors.faixas_leitura[index].valor = 'Valores negativos não são permitidos';
					}
					if (faixa.taxa_fixa_adicional < 0) {
						errors.faixas_leitura = errors.faixas_leitura || [];
						errors.faixas_leitura[index] = errors.faixas_leitura[index] || {};
						errors.faixas_leitura[index].taxa_fixa_adicional = 'Valores negativos não são permitidos';
					}
				});
				return errors;
			}}
			render={({ values: { faixas_leitura, nao_cobrar_taxa, acesso_app }, invalid }) => (
				<>
					<DialogTitle>Configurar Nova Leitura</DialogTitle>
					<DialogContent>
						<FaixasLeitura
							faixas_leitura={faixas_leitura}
							nao_cobrar_taxa={nao_cobrar_taxa}
							acesso_app={acesso_app}
							disabled={disabledForProcessing}
						/>
					</DialogContent>
					<DialogActions>
						{
							<>
								<Button
									variant='text'
									onClick={handleCancel}
									disabled={disabledForProcessing}
									size='small'
									startIcon={<CloseIcon />}
								>
									Cancelar
								</Button>
								<BotaoVoltar onClick={handleBack} disabled={disabledForProcessing} />
								<Button
									variant='text'
									onClick={() => {
										setDisabledForProcessing(true);
										dP.create('leitura', {
											data: {
												...dadosNovaLeitura,
												dados_config: {
													...dadosNovaLeitura.dados_config,
													faixas_novas: faixas_leitura,
													nao_cobrar_taxa: nao_cobrar_taxa,
													acesso_app: acesso_app,
												},
											},
										})
											.then((response) => {
												const data = response?.data;
												if (data) {
													setDadosNovaLeitura((v) => ({
														...v,
														id: data.id,
													}));
													// setModalStep('10Success');
													refresh();
													notify('Leitura cadastrada com sucesso');
													setModalValue((v) => ({
														...v,
														open: false,
													}));
												}
											})
											.catch((e) => {
												if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
												setDisabledForProcessing(false);
												notify(`Erro ao cadastrar nova leitura: ${e}`, 'warning');
											});
									}}
									disabled={disabledForProcessing || invalid}
									color='primary'
									size='small'
									startIcon={<CheckIcon />}
								>
									Salvar
								</Button>
							</>
						}
					</DialogActions>
				</>
			)}
		></Form>
	);
};

export default ModalFaixaCompletaNovaLeitura;
