import React from 'react';

import { Menu, MenuItem } from '@material-ui/core';

const MenuItemAdicionarArrecadacao = ({ id, text, tipo, handleClickItem }) => (
	<MenuItem id={id} key={'key-' + id} onClick={() => handleClickItem(tipo)}>
		{text}
	</MenuItem>
);

export const menuItems = [
	{
		id: 'menu-item-add-arrecadacao-receita',
		tipo: 'receita',
		text: 'Receita Individual',
	},
	{
		id: 'menu-item-add-arrecadacao-leitura',
		tipo: 'leitura',
		text: 'Leitura',
	},
	{
		id: 'menu-item-add-arrecadacao-despesa',
		tipo: 'despesa',
		text: 'Despesa Lançada',
	},
	{ id: 'menu-item-add-arrecadacao-fundo', tipo: 'fundo', text: 'Fundo' },
	{
		id: 'menu-item-add-arrecadacao-desconto',
		tipo: 'desconto',
		text: 'Desconto',
	},
	{
		id: 'menu-item-add-arrecadacao-contas-a-pagar',
		tipo: 'contas a pagar',
		text: 'Contas a Pagar',
	},
];

const MenuAdicionarArrecadacao = ({
	anchorEl,
	handleClose,
	anchorOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	handleClickItem,
	items = menuItems,
}) => (
	<Menu
		id='menu-item-add-arrecadacao'
		key='key-item-add-arrecadacao'
		anchorEl={anchorEl}
		open={!!anchorEl}
		onClose={handleClose}
		anchorOrigin={anchorOrigin}
		transformOrigin={transformOrigin}
	>
		{items.map((mi) => (
			<MenuItemAdicionarArrecadacao id={mi.id} key={'key-' + mi.id} handleClickItem={handleClickItem} {...mi} />
		))}
	</Menu>
);

export default MenuAdicionarArrecadacao;
