import PublishIcon from '@material-ui/icons/Publish';
import React, { useState, useCallback, useContext, useEffect, SetStateAction, createContext } from 'react';
import { useDataProvider, useGetList, useNotify } from 'react-admin';
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Paper,
	Typography,
	IconButton,
	Collapse,
} from '@material-ui/core';
import { ModalContextProvider, CustomDialog } from 'components/common/ModalContext';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../common/ModalSizeEditableContext';
import { TooltipIconButtonCancel } from '../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../common/buttons/ButtonConfirm';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import DropZone from './DropZone';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { FormlessInputDateReferencia, RawKBDatePicker } from 'components/common/InputDate';
import { BoxSelectContaPlanoV2 } from '../movimentacoes/components/BoxSelectContaPlano';

interface Condominio {
	id: number;
	nome: string;
	id_plano_condominio: number;
}

interface ContaReceita {
	id: number;
	nome: string;
}

interface ContaBancaria {
	id: number;
	nome_conta: string;
}

interface CsvData {
	Bloco: string;
	Unidade: string;
	'Responsável cobrança': string;
	Cobrança: string;
	'Nosso número': string;
	Série: string;
	Referência: string;
	Vencimento: string;
	'Total geral': string;
	Linha: number;
	error?: string[];
}

interface ApiResponse {
	data: {
		result: string;
		erros_no_arquivo: [
			{
				linha: string;
				msg: string[];
			}
		];
	};
}

interface ApiErrorResponse {
	response?: {
		status?: number;
		data?: string | string[];
	};
}

interface DataProvider {
	processarArquivosRetorno: (endpoint: string, params: { data: FormData }) => Promise<ApiResponse>;
}

type FileSelected = {
	file: File;
};

function formatDateToStringMY(date: string) {
	if (!date) return '';

	const [year, month] = date.split(/[-/]/);

	return `${month}/${year}`;
}

export const ModalDetailContext = createContext(undefined);

export const ToolTipImportacaoInadimplentes: React.FC = () => {
	const { setModalValue, modalValue } = useContext(ModalSizeEditableContext);
	return (
		<Box display='flex' alignItems='center' mb='1em'>
			<TooltipIconButton
				title='Importar Inadimplentes'
				id='importar_indadimplentes'
				color='primary'
				onClick={() => {
					setModalValue((v: typeof modalValue) => ({
						...v,
						open: true,
						dialogBody: <ModalImportacaoInadimplentes />,
					}));
				}}
			>
				<PublishIcon />
			</TooltipIconButton>
		</Box>
	);
};

export const ModalImportacaoInadimplentes: React.FC = () => {
	const [optionsCondominio, setOptionsCondominio] = useState<Condominio[]>([]);
	const [condominio, setStateCondominio] = useState<Condominio | null>(null);
	const [optionsContasBancarias, setOptionsContasBancarias] = useState<ContaBancaria[]>([]);
	const [contaReceita, setContaReceita] = useState<ContaReceita | null>(null);
	const [contaBancaria, setContaBancaria] = useState<ContaBancaria | null>(null);
	const [filesSelected, setFilesSelected] = useState<FileSelected[]>([]);
	const [csvData, setCsvData] = useState<CsvData[]>([]);
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);
	const [page, setPage] = useState<number>(1);
	const [errorRows, setErrorRows] = useState<CsvData[]>([]);
	const [showErrorRows, setShowErrorRows] = useState(false);
	const [requested, setRequested] = useState(false);
	const [expandedRow, setExpandedRow] = useState(null);
	const { setModalValue, modalValue } = useContext(ModalSizeEditableContext);
	const dataProvider = useDataProvider() as unknown as DataProvider;
	const notify = useNotify();

	const handleRowClick = (index: SetStateAction<null>) => {
		setExpandedRow(expandedRow === index ? null : index);
	};

	const [filters, setFilters] = useState({
		bloco: '',
		unidade: '',
		responsavel: '',
		cobranca: '',
		nossoNumero: '',
		serie: '',
		referencia: '',
		vencimento: '',
		totalGeral: '',
		linha: '',
	});

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
		setFilters({ ...filters, [key]: e?.target?.value || '' });
	};

	const handleDateFilterChange = (date: Date | null, key: string) => {
		setFilters({ ...filters, [key]: date });
	};

	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
		event && event.stopPropagation();
		setPage(page + 1);
	}, []);

	const { data: condominiosData } = useGetList<Condominio>(
		'condominios',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ situacao: 'A' }
	);

	const { data: contasBancariasData } = useGetList<ContaBancaria>(
		'conta_condominio',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ situacao: 'A', id_condominio: condominio?.id },
		{ enabled: Boolean(condominio) }
	);

	useEffect(() => {
		setOptionsCondominio(Object.values(condominiosData || {}).filter((v) => Boolean(v?.id)));
	}, [condominiosData]);

	useEffect(() => {
		setOptionsContasBancarias(Object.values(contasBancariasData || {}).filter((v) => Boolean(v?.id)));
	}, [contasBancariasData]);

	const calculateTotal = (data: CsvData[]) => {
		return data
			.reduce((total, item) => {
				const value = parseFloat(item['Total geral'].replace('R$', '').replace(',', '.'));

				return total + (isNaN(value) ? 0 : value);
			}, 0)
			.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
	};

	const formatDateToStringYM = (date: Date | null | string) => {
		if (date instanceof Date && !isNaN(date.getTime())) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			return `${year}/${month}`;
		}
		return '';
	};

	const formatDateToStringYMD = (date: Date | null | string) => {
		if (date instanceof Date && !isNaN(date.getTime())) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${day}/${month}/${year}`;
		}
		return '';
	};

	const processarArquivos = useCallback(() => {
		const contaReceitaId = contaReceita?.id?.toString() || '';
		const contaBancariaId = contaBancaria?.id?.toString() || '';
		const condominioId = condominio?.id?.toString() || '';

		if (!filesSelected.length || !contaReceitaId || !contaBancariaId || !condominioId) {
			notify('Todos os campos são obrigatórios.', 'warning');
			return;
		}

		const formData = new FormData();
		formData.append('csv', Array.from(filesSelected)[0].file);
		formData.append('conta_receita_id', contaReceitaId);
		formData.append('conta_bancaria_id', contaBancariaId);
		formData.append('condominio_id', condominioId);
		setRequested(true);
		dataProvider
			.processarArquivosRetorno('contas_receber/importar', { data: formData })
			.then((response: ApiResponse) => {
				const data = response?.data;
				if (data.result) {
					notify('Arquivo importado com sucesso. Realize a conferência dos dados na tabela', 'success');
				}
				if (data?.erros_no_arquivo?.length > 0) {
					const errorRowsData = data.erros_no_arquivo
						.map((error) => {
							const linha = csvData.find((row) => row.Linha === Number(error.linha));
							return linha ? { ...linha, error: error.msg } : null;
						})
						.filter(Boolean) as CsvData[];

					setErrorRows(errorRowsData);
					setCsvData(
						csvData.filter((row) => !errorRowsData.some((errorRow) => errorRow.Linha === row.Linha))
					);
				}
			})
			.catch((e: ApiErrorResponse) => {
				if (e?.response?.status && [401, 403].includes(e.response.status)) {
					return Promise.reject(e);
				}
				notify(
					Object.values((e?.response?.data as string) || {})[0] ||
						'Erro inesperado, tente recarregar a página',

					'warning'
				);
			});
	}, [dataProvider, notify, filesSelected, contaReceita, contaBancaria, condominio, csvData, errorRows]);

	useEffect(() => {
		if (filesSelected.length > 0) {
			const file = filesSelected[0].file;
			const fileType = file.type || file.name.split('.').pop();
			if (file && (fileType === 'text/csv' || fileType === 'csv')) {
				const reader = new FileReader();
				reader.onload = (e: ProgressEvent<FileReader>) => {
					const text = e.target?.result as string;
					const allLines = text.split('\n');
					const filteredLines = allLines
						.filter(
							(line) =>
								!line.startsWith(';;;;;;;;;;;;;;;;') &&
								!line.startsWith('Consulta de rateios personalizada') &&
								line.trim() !== ''
						)
						.slice(1);

					const data = filteredLines.map((line) => {
						const values = line.split(';');
						const originalIndex = allLines.indexOf(line);
						const cobrança = values[5] === 'Propriet?io' ? 'Proprietário' : values[5];

						return {
							Bloco: values[0],
							Unidade: values[1],
							'Responsável cobrança': values[2],
							Cobrança: cobrança,
							'Nosso número': values[16],
							Série: values[4],
							Referência: values[3],
							Vencimento: values[10],
							'Total geral': values[15],
							Linha: originalIndex + 1,
						};
					});
					setCsvData(data);
				};
				reader.readAsText(file);
			} else {
				notify(
					'Arquivo inválido, faça novamente a importação utilizando um arquivo de inadimplência',
					'warning'
				);
				setFilesSelected([]);
			}
		}
	}, [filesSelected]);

	return (
		<ModalContextProvider Context={ModalDetailContext}>
			<CustomDialogBodySizeEditable
				title='Importação de Inadimplentes'
				customActions={[
					<TooltipIconButtonCancel
						onClick={() => setModalValue((v: typeof modalValue) => ({ ...v, open: false }))}
						className={null}
						title='Fechar'
					>
						<CloseIcon />
					</TooltipIconButtonCancel>,

					!requested && (
						<>
							<TooltipIconButtonConfirm
								key='clear'
								onClick={() => {
									setCsvData([]);
									setFilesSelected([]);
									setErrorRows([]);
								}}
								className={null}
								title='Limpar Arquivos'
								id='clear_contas_a_receber'
							>
								<DeleteIcon color='error' />
							</TooltipIconButtonConfirm>
							<TooltipIconButtonConfirm
								key='process'
								onClick={() => {
									processarArquivos();
								}}
								className={null}
								title='Processar Arquivos'
								id='process_contas_a_receber'
							>
								<CheckIcon />
							</TooltipIconButtonConfirm>
						</>
					),
				]}
				form={{
					component: (
						<Box>
							<Box display='flex' style={{ flexDirection: 'row', gap: '1em' }} mb='1em'>
								<Box flexGrow={1}>
									<Autocomplete
										options={optionsCondominio}
										value={condominio}
										onChange={(_, value) => setStateCondominio(value)}
										getOptionLabel={(option) => option.nome || ''}
										renderOption={(option) => option?.nome}
										renderInput={(params) => (
											<TextField {...params} label={'Selecionar Condomínio'} />
										)}
										size='small'
									/>
								</Box>

								{condominio && (
									<>
										<Box flexGrow={1}>
											<BoxSelectContaPlanoV2
												id={condominio.id_plano_condominio}
												selectedConta={contaReceita}
												setSelectedConta={setContaReceita}
												operacao={{ tipo: 'receita' }}
												filtroTipoContaReceita={['O', 'E'] as unknown as null}
												ModalDetailContext={ModalDetailContext}
												disabled={false}
												style={{ margin: 0, padding: '6.5px 0 6.7px 0' } as unknown as null}
											/>
										</Box>
										<CustomDialog Context={ModalDetailContext} />
										<Box flexGrow={1}>
											<Autocomplete
												options={optionsContasBancarias || []}
												value={contaBancaria}
												onChange={(_, value) => setContaBancaria(value)}
												getOptionLabel={(option) => option.nome_conta || ''}
												renderOption={(option) => option?.nome_conta}
												renderInput={(params) => (
													<TextField {...params} label={'Selecionar Conta Bancária'} />
												)}
												size='small'
											/>
										</Box>
									</>
								)}
							</Box>
							{csvData.length <= 0 && errorRows.length <= 0 ? (
								<DropZone {...{ filesSelected, setFilesSelected, setCsvData }} />
							) : (
								<>
									{requested && (
										<>
											<Button
												style={{
													borderBottom: showErrorRows ? 'none' : '2px solid gray',
													borderRadius: showErrorRows ? '0 0 0 0' : '4px 4px 0 0',
													color: '#2F345D',
													transition: 'all 0.3s ease',
												}}
												onClick={() => {
													setShowErrorRows(false);
												}}
											>
												{'Importados com sucesso'}
											</Button>
											<Button
												disabled={!(errorRows.length > 0)}
												style={{
													borderBottom: showErrorRows ? '2px solid gray' : 'none',
													borderRadius: showErrorRows ? '0 0 0 0' : '4px 4px 0 0',
													color: '#C8102E',
													transition: 'all 0.3s ease',
												}}
												onClick={() => {
													setShowErrorRows(true);
												}}
											>
												{'Rejeitados'}
											</Button>
										</>
									)}
									<TableContainer component={Paper}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell style={{ width: '10%', fontWeight: 'bold' }}>
														Grupo
														<TextField
															label='Filtrar'
															value={filters.bloco}
															onChange={(e) => handleFilterChange(e, 'bloco')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
													<TableCell style={{ width: '10%', fontWeight: 'bold' }}>
														Unidade
														<TextField
															label='Filtrar'
															value={filters.unidade}
															onChange={(e) => handleFilterChange(e, 'unidade')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
													<TableCell style={{ width: '15%', fontWeight: 'bold' }}>
														Responsável
														<TextField
															label='Filtrar'
															value={filters.responsavel}
															onChange={(e) => handleFilterChange(e, 'responsavel')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
													<TableCell style={{ width: '10%', fontWeight: 'bold' }}>
														Cobrança
														<TextField
															label='Filtrar'
															value={filters.cobranca}
															onChange={(e) => handleFilterChange(e, 'cobranca')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
													<TableCell style={{ width: '15%', fontWeight: 'bold' }}>
														Nosso número
														<TextField
															label='Filtrar'
															value={filters.nossoNumero}
															onChange={(e) => handleFilterChange(e, 'nossoNumero')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
													<TableCell style={{ width: '10%', fontWeight: 'bold' }}>
														Identificação
														<TextField
															label='Filtrar'
															value={filters.serie}
															onChange={(e) => handleFilterChange(e, 'serie')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
													<TableCell style={{ width: '10%', fontWeight: 'bold' }}>
														Referência
														<FormlessInputDateReferencia
															label='Filtrar'
															value={
																filters.referencia ? new Date(filters.referencia) : null
															}
															onChange={(date: Date | null) =>
																handleDateFilterChange(date, 'referencia')
															}
															fullWidth
															autoOk
															allowEmpty={true}
															inputVariant='standard'
															format='MM/yyyy'
															margin='none'
															variant='standard'
															allowKeyboardControl={false}
															helperText={''}
														/>
													</TableCell>
													<TableCell style={{ width: '10%', fontWeight: 'bold' }}>
														Vencimento
														<RawKBDatePicker
															label='Filtrar'
															value={
																filters.vencimento ? new Date(filters.vencimento) : null
															}
															onChange={(date: Date | null) =>
																handleDateFilterChange(date, 'vencimento')
															}
															autoOk
															fullWidth
															variant='standard'
															format='dd/MM/yyyy'
															inputVariant='standard'
														/>
													</TableCell>
													<TableCell
														style={{
															width: '10%',
															fontWeight: 'bold',
															textAlign: 'right',
														}}
													>
														Valor
														<TextField
															label='Filtrar'
															value={filters.totalGeral}
															onChange={(e) => handleFilterChange(e, 'totalGeral')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
													<TableCell style={{ width: '10%', fontWeight: 'bold' }}>
														Linha
														<TextField
															label='Filtrar'
															value={filters.linha}
															onChange={(e) => handleFilterChange(e, 'linha')}
															variant='standard'
															fullWidth
														/>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody style={{ textTransform: 'none' }}>
												{(showErrorRows ? errorRows : csvData)
													.filter(
														(data) =>
															data['Bloco'].includes(filters.bloco) &&
															data['Unidade'].includes(filters.unidade) &&
															data['Responsável cobrança'].includes(
																filters.responsavel
															) &&
															data['Cobrança'].includes(filters.cobranca) &&
															data['Nosso número'].includes(filters.nossoNumero) &&
															data['Série'].includes(filters.serie) &&
															data['Referência'].includes(
																formatDateToStringYM(filters.referencia)
															) &&
															data['Vencimento'].includes(
																formatDateToStringYMD(filters.vencimento)
															) &&
															data['Total geral'].includes(filters.totalGeral) &&
															data['Linha'].toString().includes(filters.linha)
													)
													.slice((page - 1) * rowsPerPage, page * rowsPerPage)
													.map((data, index) => (
														<React.Fragment key={index}>
															<TableRow>
																<TableCell>
																	<Box
																		display={'flex'}
																		flexDirection={'row'}
																		alignItems={'center'}
																		style={{ textTransform: 'none' }}
																	>
																		{showErrorRows && (
																			<IconButton
																				onClick={() =>
																					handleRowClick(
																						index as unknown as SetStateAction<null>
																					)
																				}
																			>
																				{expandedRow === index ? (
																					<ExpandLessIcon />
																				) : (
																					<ExpandMoreIcon />
																				)}
																			</IconButton>
																		)}
																		{''}
																		{data['Bloco']}
																	</Box>
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{data['Unidade']}
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{data['Responsável cobrança']}
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{data['Cobrança']}
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{data['Nosso número']}
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{data['Série']}
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{formatDateToStringMY(data['Referência'])}
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{data['Vencimento']}
																</TableCell>
																<TableCell
																	style={{ textTransform: 'none' }}
																	align='right'
																>
																	{isNaN(parseFloat(data['Total geral']))
																		? ''
																		: `R$ ${parseFloat(
																				data['Total geral']
																		  ).toLocaleString('pt-BR', {
																				minimumFractionDigits: 2,
																				maximumFractionDigits: 2,
																		  })}`}
																</TableCell>
																<TableCell style={{ textTransform: 'none' }}>
																	{data['Linha']}
																</TableCell>
															</TableRow>
															{showErrorRows && expandedRow === index && (
																<TableRow>
																	<TableCell
																		colSpan={11}
																		style={{
																			paddingBottom: 0,
																			paddingTop: 0,
																			textTransform: 'none',
																		}}
																	>
																		<Collapse
																			in={expandedRow === index}
																			timeout='auto'
																			unmountOnExit
																		>
																			<Box margin={1}>
																				<Typography variant='body2'>
																					{`Erro: ${data.error}`}
																				</Typography>
																			</Box>
																		</Collapse>
																	</TableCell>
																</TableRow>
															)}
														</React.Fragment>
													))}
											</TableBody>
										</Table>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'
											mt={2}
											mb={'0.5em'}
										>
											<Box ml={'1em'}>
												<Box mb={1}>
													<strong>Total de Lançamentos:</strong>{' '}
													{showErrorRows ? errorRows.length : csvData.length}
												</Box>
												<Box display={'flex'} flexDirection={'row'}>
													<Box style={{ marginRight: '0.2em' }}>
														<strong style={{ marginLeft: '0.2em' }}>Valor Total:</strong> R$
													</Box>
													<Box style={{ textAlign: 'right', marginLeft: '0.2em' }}>
														{showErrorRows
															? calculateTotal(errorRows)
															: calculateTotal(csvData)}
													</Box>
												</Box>
											</Box>
											<SyndkosTablePagination
												handlePageChange={(event, page) =>
													handlePageChange(event as React.ChangeEvent<unknown>, page)
												}
												page={page}
												perPage={rowsPerPage}
												total={showErrorRows ? errorRows.length : csvData.length}
												setPerPage={setRowsPerPage}
												labelRowsPerPage='Lançamentos de Inadimplentes por página'
											/>
										</Box>
									</TableContainer>
								</>
							)}
						</Box>
					),
				}}
			/>
		</ModalContextProvider>
	);
};
