import React from 'react';

import { Box } from '@material-ui/core';

import { LinhaBoxBase } from '../../../../../tabs/TabInstrucoesCobrancaBoleto';

const TabInstrucoesCobrancaBoleto = () => (
	<Box>
		<LinhaBoxBase num={1} maxLength={55} />
		<LinhaBoxBase num={2} maxLength={55} />
		<LinhaBoxBase num={3} maxLength={55} />
	</Box>
);

export default TabInstrucoesCobrancaBoleto;
