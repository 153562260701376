import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { LinearProgress } from '@material-ui/core';

//Componente para selecionar multiplos valores de uma Resource
export const SelectMultiResource = ({
	optionText = 'nome',
	label,
	delay = 300,
	value = [],
	required = false,
	stylesSyndikosSelect = { marginBottom: 5 },
	onChange,
	resource,
	...props
}) => {
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);

	const dP = useDataProvider();
	const fetchOptions = () => {
		dP.getSimple(resource, {
			pagination: { perPage: 10000, page: 1 },
			sort: { order: 'ASC', field: 'nome' },
		})
			.then((response) => {
				const options = response?.data?.results;
				setOptions(options);
				if (value?.length) setSelectedOptions(options.filter((op) => value.includes(op.id)));
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			})
			.finally(() => {
				setLoading(true);
			});
	};

	useEffect(fetchOptions, []);

	return loading && (options.length > 0 ? true : false) ? (
		<Autocomplete
			style={stylesSyndikosSelect}
			{...props}
			value={selectedOptions}
			onChange={(_, newValue) => {
				onChange(newValue.map((v) => v.id));
				setSelectedOptions(newValue);
			}}
			options={options}
			filterSelectedOptions
			getOptionLabel={(option) => option?.nome}
			multiple
			renderInput={(params) => (
				<TextField {...params} margin='dense' fullWidth label={label} required={required} />
			)}
			noOptionsText='Nenhum resultado'
		/>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

//Compoente para selecionar um valor de varios valores de uma Resource
export const SelectOneResource = ({
	optionText = 'nome',
	label,
	delay = 300,
	value,
	required = false,
	stylesSyndikosSelect = { marginBottom: 5 },
	onChange,
	resource,
	...props
}) => {
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);

	const dP = useDataProvider();
	const fetchOptions = () => {
		dP.getSimple(resource, {
			pagination: { perPage: 10000, page: 1 },
			sort: { order: 'ASC', field: 'nome' },
		})
			.then((response) => {
				const options = response?.data?.results;
				setOptions(options);
				if (value)
					setSelectedOption(
						options.find((op) => {
							return op.id === value;
						})
					);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			})
			.finally(() => {
				setLoading(true);
			});
	};

	useEffect(fetchOptions, []);

	return loading && (options.length > 0 ? true : false) ? (
		<Autocomplete
			{...props}
			optionText={optionText}
			value={selectedOption}
			style={stylesSyndikosSelect}
			onChange={(_, newValue) => {
				setSelectedOption(newValue);
				onChange(newValue?.id);
			}}
			options={options}
			getOptionLabel={(option) => option?.nome || ''}
			renderInput={(params) => (
				<TextField {...params} margin='dense' fullWidth label={label} required={required} />
			)}
			noOptionsText='Nenhum resultado'
		/>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};
