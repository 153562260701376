import React from 'react';
import { FormWithRedirect, TextInput } from 'react-admin';
import { AcoesForm } from './AcoesForm';
import { Paper, Box, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const dictSituacoes = {
	C: 'Aguardando confirmação do convite',
	A: 'Ativo',
	I: 'Inativo',
	E: 'Excluído',
};

const useStyles = makeStyles((theme) => ({
	ativo: {
		backgroundColor: theme.primary[200],
		color: '#FFFFFF',
		fontWeight: 'bold',
	},
	inativo: {
		backgroundColor: theme.red[200],
		color: '#FFFFFF',
		fontWeight: 'bold',
	},
	concluido: {
		backgroundColor: theme.green[200],
		color: '#FFFFFF',
		fontWeight: 'bold',
	},
	em_atraso: {
		backgroundColor: theme.orange[200],
		color: '#FFFFFF',
		fontWeight: 'bold',
	},
	vencendo_hoje: {
		backgroundColor: theme.orange[200],
		color: '#FFFFFF',
		fontWeight: 'bold',
	},
}));

const FormHeader = ({
	situacao,
	nome,
	getChipClassName = ({ styles, situacao }) => (situacao === 'A' ? styles.ativo : styles.inativo),
	dictLabels = dictSituacoes,
}) => {
	const styles = useStyles();
	return (
		<Box display='flex' padding='16px 0 0 16px' alignItems='center'>
			<Chip
				label={dictLabels[situacao] || 'Inativo'}
				className={getChipClassName({
					styles,
					situacao,
					nome,
					dictLabels,
				})}
			/>
			<Typography style={{ marginLeft: '5px' }} variant='h6'>
				{nome}
			</Typography>
		</Box>
	);
};

export const FormularioBox = ({
	children,
	noActionToolbar,
	nome,
	display = 'flex',
	onClickBotaoRemover,
	customSaveButton,
	customBackButton,
	showFormHeader = (formProps) => !!formProps?.edit,
	dictLabels,
	getChipClassName,
	onlyExcluir,
	onlyInactivate = false,
	...rest
}) => {
	return (
		<FormWithRedirect
			subscription={{ submitting: true, pristine: true, invalid: true }}
			sanitizeEmptyValues
			validateOnBlur
			warnWhenUnsavedChanges
			showFormHeader={(formProps) => !!formProps?.edit}
			render={(formProps) => {
				return (
					<Paper style={{ padding: '0' }}>
						{showFormHeader(formProps) && (
							<FormHeader
								situacao={formProps.record.situacao}
								nome={nome ? nome : formProps.record.nome}
								resource={formProps.resource}
								dictLabels={dictLabels}
								getChipClassName={getChipClassName}
							/>
						)}
						<form>
							<Box p='1em'>
								<Box display={display} flexWrap='wrap'>
									{Array.isArray(children)
										? children.map((child, i) =>
												React.cloneElement(child, {
													...formProps,
													key: i,
												})
										  )
										: React.cloneElement(children, formProps)}
								</Box>
							</Box>
							{!noActionToolbar && (
								<AcoesForm
									onlyExcluir={onlyExcluir}
									onClickBotaoRemover={onClickBotaoRemover}
									customSaveButton={customSaveButton}
									onlyInactivate={onlyInactivate}
									{...formProps}
								/>
							)}
						</form>
					</Paper>
				);
			}}
			{...rest}
		/>
	);
};

export const BoxDivisor = ({ titulo, children, ...rest }) => (
	<Box {...rest}>
		<Typography variant='h5' gutterBottom>
			{titulo}
		</Typography>
		<hr
			style={{
				marginRight: '1em',
				borderTop: '#d3d3d3',
			}}
		></hr>
		{children}
	</Box>
);

export const TextInputSyndikos = ({ flex = 1, mr = '0.5em', childrem = <></>, ...rest }) => (
	<Box {...{ flex, mr }}>
		<TextInput {...rest} variant='outlined' fullWidth />
	</Box>
);
