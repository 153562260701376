import React, { useState, useMemo } from 'react';

import { Box, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MultiSelect from 'components/common/Selects';
import { useRequestOptionsUnidades, useRequestOptionsIdentificacaoArrecadacao } from 'components/common/Selects/hooks';

import { format } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelect, RelatorioInputDate, periodoOptions, TODAY } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const useRDStyle = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateRows: '2',
		rowGap: '1rem',
	},
	row_select_unidades: {
		gridRow: '1',
	},
	row_tipo_relatorio: {
		gridRow: '2',
	},
}));

const OpcoesImpressaoRDCheckboxGroup = ({
	setUnidades,
	unidades,
	optionsUnidades,
	handleChange,
	handleChanges,
	tipoRelatorioRecebimento,
	tipoRelatorioDevedores,
	orderByDate,
	imprimirTotalByData,
	imprimirTotalByUnidade,
	imprimirOrientacaoPaisagem,
	imprimirOrigemBaixa,
	imprimirAssinatura,
	imprimirOrdemNome = false,
}) => {
	const classes = useRDStyle();

	return (
		<Box display='flex' mb='1em'>
			<Box flex={1} mr='1em' className={classes.container}>
				<Box className={classes.row_select_unidades}>
					<MultiSelect
						variant={'formatsUnidades'}
						setValue={setUnidades}
						options={optionsUnidades}
						value={unidades}
					/>
				</Box>
				<Box className={classes.row_tipo_relatorio}>
					<FormLabel component='legend'>Tipo do Relatório</FormLabel>
					<FormGroup fullWidth>
						<FormControlLabel
							control={
								<Checkbox
									checked={tipoRelatorioRecebimento}
									onChange={(e, checked) => {
										e.persist();
										if (tipoRelatorioDevedores) handleChange(checked, 'tipo_relatorio_recebimento');
										handleChanges((o) => ({
											...o,
											tipo_relatorio_recebimento: e.target.checked,
											imprimir_total_by_unidade: true,
											imprimir_origem_baixa: e.target.checked ? o.imprimir_origem_baixa : false,
										}));
									}}
									name='tipo_relatorio_recebimento'
								/>
							}
							label='Recebimentos'
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={tipoRelatorioDevedores}
									onChange={(e, checked) => {
										e.persist();
										if (tipoRelatorioRecebimento) handleChange(checked, 'tipo_relatorio_devedores');

										if (checked)
											handleChanges((o) => ({
												...o,
												order_by_date: false,
												imprimir_total_by_data: false,
												tipo_relatorio_devedores: true,
												imprimir_total_by_unidade_recebimentos_e_devedores: true,
											}));
										else handleChange(checked, 'tipo_relatorio_devedores');
									}}
									name='tipo_relatorio_devedores'
								/>
							}
							label='Devedores'
						/>
					</FormGroup>
				</Box>
			</Box>
			<Box flex={1} mr='1em'>
				<FormLabel component='legend'>Opções Tipo Recebimento</FormLabel>
				<FormGroup fullWidth>
					<FormControlLabel
						control={
							<Checkbox
								checked={orderByDate}
								onChange={(e, checked) => {
									handleChanges((o) => ({
										...o,
										order_by_date: checked,
										imprimir_total_by_data: checked,
										imprimir_total_by_unidade_recebimentos_e_devedores: !checked,
									}));
								}}
								name='order_by_date'
							/>
						}
						label='Ordenar Recebimentos por Data'
						disabled={tipoRelatorioDevedores || imprimirOrdemNome}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirTotalByData}
								onChange={(e, checked) => handleChange(checked, 'imprimir_total_by_data')}
								name='imprimir_total_by_data'
							/>
						}
						label='Imprimir Total da Data'
						disabled={!orderByDate}
					/>
				</FormGroup>
			</Box>
			<Box flex={1} mr='1em'>
				<FormLabel component='legend'>Opções de Impressão</FormLabel>
				<FormGroup fullWidth>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirTotalByUnidade}
								onChange={(e, checked) =>
									handleChange(checked, 'imprimir_total_by_unidade_recebimentos_e_devedores')
								}
								name='imprimir_total_by_unidade_recebimentos_e_devedores'
							/>
						}
						label='Imprimir Total da Unidade'
						disabled={orderByDate}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirOrientacaoPaisagem}
								onChange={(e, checked) => handleChange(checked, 'imprimir_orientacao_paisagem')}
								name='imprimir_orientacao_paisagem'
							/>
						}
						label='Imprimir na Orientação Paisagem'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirOrigemBaixa}
								onChange={(e, checked) => handleChange(checked, 'imprimir_origem_baixa')}
								name='imprimir_origem_baixa'
								disabled={!tipoRelatorioRecebimento}
							/>
						}
						label='Imprimir Origem da Baixa'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirAssinatura}
								onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
								name='imprimir_assinatura'
							/>
						}
						label='Imprimir Assinaturas'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirOrdemNome}
								onChange={(e, checked) => handleChange(checked, 'imprimir_ordem_nome')}
								name='imprimir_ordem_nome'
								disabled={orderByDate}
							/>
						}
						label='Imprimir por Ordem de Nome'
					/>
				</FormGroup>
			</Box>
		</Box>
	);
};

export const RecebimentosEDevedoresDivisor = (props) => {
	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const [unidades, setUnidades] = useState([]);
	const { options: optionsUnidades } = useRequestOptionsUnidades();
	const [dataInicial, setDataInicial] = useState();
	const [dataFinal, setDataFinal] = useState();
	const [dataBase, setDataBase] = useState(format(TODAY, 'dd/MM/yyyy'));
	const idCondominio = props.idCondominio;
	const [identificacao, setIdentificacao] = useState({
		id: 0,
		nome: 'Todas',
	});
	const { options: identificacoes } = useRequestOptionsIdentificacaoArrecadacao(idCondominio);
	const {
		opcoesImpressao: {
			tipo_relatorio_recebimento,
			tipo_relatorio_devedores,
			order_by_date,
			imprimir_total_by_data,
			imprimir_total_by_unidade_recebimentos_e_devedores,
			imprimir_orientacao_paisagem,
			imprimir_origem_baixa,
			imprimir_assinatura,
			imprimir_ordem_nome,
		},
		handleChangeOpcoesImpressao,
		setOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);
	React.useEffect(() => {
		if (unidades?.length) {
			setUnidades([]);
		}
	}, [idCondominio]);

	const requestData = useMemo(
		() => ({
			id_condominio: idCondominio,
			unidades: unidades?.map((u) => u?.id).filter((id) => !!id),
			data_inicial: dataInicial,
			data_final: dataFinal,
			data_base: dataBase,
			nome_relatorio: props.tipoRelatorio.action,
			tipo_relatorio_recebimento: tipo_relatorio_recebimento,
			tipo_relatorio_devedores: tipo_relatorio_devedores,
			order_by_date: order_by_date,
			imprimir_total_by_data: imprimir_total_by_data,
			imprimir_total_by_unidade_recebimentos_e_devedores: imprimir_total_by_unidade_recebimentos_e_devedores,
			imprimir_orientacao_paisagem: imprimir_orientacao_paisagem,
			imprimir_origem_baixa: imprimir_origem_baixa,
			imprimir_assinatura: imprimir_assinatura,
			imprimir_ordem_nome: imprimir_ordem_nome,
			identificacao: identificacao ? identificacao.id : 0,
		}),
		[
			idCondominio,
			unidades,
			dataInicial,
			dataFinal,
			dataBase,
			tipo_relatorio_recebimento,
			tipo_relatorio_devedores,
			order_by_date,
			imprimir_total_by_data,
			imprimir_orientacao_paisagem,
			imprimir_orientacao_paisagem,
			imprimir_origem_baixa,
			imprimir_assinatura,
			imprimir_ordem_nome,
			identificacao,
		]
	);

	React.useEffect(() => {
		if (identificacao) {
			setIdentificacao({
				id: 0,
				nome: 'Todas',
			});
		}
	}, [idCondominio]);

	return (
		<BoxDivisor titulo='Recebimentos e Devedores'>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<PeriodoSelect
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={dataInicial}
						setDataInicial={setDataInicial}
						setDataFinal={setDataFinal}
						dataFinal={dataFinal}
						label='Selecionar Período'
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<RelatorioInputDate
						label='Data Base'
						handleChange={(_, newValue) => setDataBase(newValue)}
						value={dataBase}
						fullWidth
					/>
				</Box>
				<Box flex={1}>
					<Autocomplete
						value={identificacao}
						onChange={(_, newValue) => setIdentificacao(newValue)}
						options={[...Object.values(identificacoes).filter((v) => v.id !== 0), { id: 0, nome: 'Todas' }]}
						getOptionLabel={(o) => o.nome}
						getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
						disabled={identificacoes.length === 1}
						renderInput={(params) => (
							<TextField {...params} label='Identificação de Arrecadação' variant='outlined' />
						)}
						clearText='Limpar'
						autoHighlight
						noOptionsText='Nenhuma identificação disponível'
						size='small'
						fullWidth
					/>
				</Box>
			</Box>
			<OpcoesImpressaoRDCheckboxGroup
				handleChange={handleChangeOpcoesImpressao}
				handleChanges={setOpcoesImpressao}
				tipoRelatorioRecebimento={tipo_relatorio_recebimento}
				tipoRelatorioDevedores={tipo_relatorio_devedores}
				orderByDate={order_by_date}
				imprimirTotalByData={imprimir_total_by_data}
				imprimirTotalByUnidade={imprimir_total_by_unidade_recebimentos_e_devedores}
				imprimirOrientacaoPaisagem={imprimir_orientacao_paisagem}
				imprimirOrigemBaixa={imprimir_origem_baixa}
				imprimirAssinatura={imprimir_assinatura}
				imprimirOrdemNome={imprimir_ordem_nome}
				{...{
					setUnidades,
					unidades,
					optionsUnidades,
				}}
			/>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={props.tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};
