import React, { useContext, useEffect } from 'react';

import { useInput } from 'react-admin';

import { ModalSizeEditableContextProvider } from '../../../common/ModalSizeEditableContext';
import { VinculosContext } from '../VinculosContext';
import VinculosInputBody from './VinculosInputBody';

const VinculosInput = ({ source, validate }) => {
	const { responsaveis, setResponsaveis, proprietario, inquilino, imobData, edit } = useContext(VinculosContext);
	const {
		input: { onChange, ...input },
	} = useInput({ source, validate, proprietario, inquilino, imobData });

	const updateInput = () => {
		edit
			? setResponsaveis(responsaveis)
			: onChange(
					(responsaveis || []).map((responsavel) => {
						responsavel.tipo_logradouro = responsavel.tipo_logradouro_id;
						return responsavel;
					})
			  );
	};
	useEffect(updateInput, [responsaveis]);

	const divProps = edit ? {} : input;

	return (
		<div {...divProps}>
			<ModalSizeEditableContextProvider>
				<VinculosInputBody />
			</ModalSizeEditableContextProvider>
		</div>
	);
};

export default VinculosInput;
