import React from 'react';

import { useNotify } from 'react-admin';

import { Box } from '@material-ui/core';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../ModalSizeEditableContext';
import { CloseButton, DeleteButton } from 'components/common/commonComponentsTSX';

import { useDataProvider } from 'react-admin';
import { CustomDataProvider } from './types';
import { ModalValue } from '../types';

type ModalConfirmacaoProps = {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setReload: React.Dispatch<React.SetStateAction<number>>;
	IDModeloEdit: number;
};

const ModalConfirmacao: React.FC<ModalConfirmacaoProps> = ({ setLoading, setReload, IDModeloEdit }) => {
	const notify = useNotify();
	const dp = useDataProvider() as CustomDataProvider;
	const { setModalValue, setMinWidthModel } = React.useContext(ModalSizeEditableContext);

	// Requisição Delete Template Personalizado:
	const handleDeleteTemplateComunicado = () => {
		setLoading(true);
		dp.delete('comunicado_template', { id: IDModeloEdit })
			.then(() => {
				notify('Modelo de comunicado removido', 'success');
				setReload((v) => v + 1);
				setModalValue((v: ModalValue) => ({ ...v, open: false }));
				setMinWidthModel('61vw');
			})
			.catch(() => {
				notify('Falha ao excluir modelo de comunicado', 'warning');
				setLoading(false);
			});
	};
	return (
		<Box>
			<CustomDialogBodySizeEditable
				text={'Deseja realmente excluir o Comunicado ?'}
				closeInSubmit={false}
				form={null}
				customActions={
					<>
						<CloseButton
							onClick={() => {
								setModalValue({ open: false, dialogBody: <></> });
							}}
							size='small'
						>
							cancelar
						</CloseButton>
						<DeleteButton size='small' onClick={handleDeleteTemplateComunicado}>
							Excluir
						</DeleteButton>
					</>
				}
			/>
		</Box>
	);
};

export default ModalConfirmacao;
