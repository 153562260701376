import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import { BoxDivisor } from '../../../common/Formulario';
import RelatorioToolbar from '../RelatorioToolbar';
import MultiSelect from 'components/common/Selects';
import { useDataProvider, useNotify } from 'react-admin';
import { CustomDataProvider, IFornecedor, ITipoRelatorio } from './types';

const FornecedorDivisor = ({ tipoRelatorio }: { tipoRelatorio: ITipoRelatorio }) => {
	const [fornecedores, setFornecedores] = useState([]);
	const [optionsFornecedores, setOptionsFornecedores] = useState([] as IFornecedor[]);
	const dp = useDataProvider() as CustomDataProvider;
	const notify = useNotify();

	const handleReqOptions = () => {
		dp.getSimple('fornecedores', {
			pagination: { page: 1, perPage: 10000 },
			sort: { field: 'nome', order: 'ASC' },
		})
			.then(({ data: { results } }: { data: { results: IFornecedor[] } }) => {
				setOptionsFornecedores(results);
			})
			.catch(() => {
				notify('Não foi possível coletar os fornecedores', 'warning');
			});
	};
	React.useEffect(handleReqOptions, []);

	const requestData = {
		fornecedores_ids: fornecedores.map((f: { id: number }) => f.id),
	};

	return (
		<BoxDivisor titulo='Relatório de Fornecedores'>
			<Box flex={2}>
				<MultiSelect
					variant='formatsFornecedores'
					options={optionsFornecedores}
					value={fornecedores}
					setValue={setFornecedores}
					size='small'
				/>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar tipoRelatorio={tipoRelatorio} data={requestData} />
			</Box>
		</BoxDivisor>
	);
};

export default FornecedorDivisor;
