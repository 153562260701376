import React from 'react';

import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import { EditorState } from 'draft-js';
import { SelectTemplates } from '../types';

import MUIRichTextEditor from 'mui-rte';

type EditComunicadoTemplateBoxProps = {
	handleSaveEditTemplateValue?: (id: number, nome: string, data: string) => void;
	template?: SelectTemplates;
};

const useStyles = makeStyles({
	editor: {
		maxHeight: '11.1rem',
		overflowY: 'auto',
	},
	root: {
		minHeight: '100px',
	},
});

export const EditComunicadoTemplateBox: React.FC<EditComunicadoTemplateBoxProps> = ({
	handleSaveEditTemplateValue = () => {},
	template,
}) => {
	// Variaveis
	const textRef = React.useRef<any>(null);
	const [textoModeloEdit, setTextoModeloEdit] = React.useState<string>('');
	const [nomeModeloEdit, setNomeModeloEdit] = React.useState<string>('');
	const [IDModeloEdit, setIDModeloEdit] = React.useState<number | null>(null);
	const [contadorCaracteres, setContadorCaracteres] = React.useState<number>(0);
	const classes = useStyles();

	const handleRichTextChange = (state: EditorState) => {
		textRef.current?.save();

		const texto = state.getCurrentContent().getPlainText();
		setContadorCaracteres(texto.length);
	};

	React.useEffect(() => {
		if (!template) return;

		setIDModeloEdit(template.id as number);
		setTextoModeloEdit(JSON.stringify(template.comunicado_json));
		setNomeModeloEdit(template.nome_modelo);
	}, [template]);

	React.useEffect(() => {
		textRef.current?.save();
	}, [nomeModeloEdit, textoModeloEdit]);

	return (
		<Box>
			<TextField
				value={nomeModeloEdit}
				size='small'
				onChange={(event) => {
					textRef.current.save();
					setNomeModeloEdit(event.target.value);
				}}
				onBlur={() => textRef.current.save()}
				onFocus={() => textRef.current.save()}
				label='Nome para o novo modelo:'
				variant='outlined'
				fullWidth
			/>
			<Box
				style={{
					backgroundColor: 'white',
					color: 'black',
					marginTop: '10px',
				}}
				border={1}
				borderRadius='borderRadius'
			>
				<MUIRichTextEditor
					label='Digite algo...'
					defaultValue={textoModeloEdit}
					inlineToolbar
					toolbarButtonSize='small'
					ref={textRef}
					onSave={(data) => handleSaveEditTemplateValue(IDModeloEdit as number, nomeModeloEdit, data)}
					onChange={handleRichTextChange}
					onBlur={() => textRef.current.save()}
					onFocus={() => textRef.current.save()}
					maxLength={500}
					controls={['bold', 'italic', 'underline', 'strikethrough', 'undo', 'redo', 'clear']}
					classes={{
						root: classes.root,
						editor: classes.editor,
					}}
				/>
				<Typography
					variant='caption'
					color={contadorCaracteres > 500 ? 'error' : 'inherit'}
					style={{ paddingLeft: '1em' }}
				>
					{contadorCaracteres}/500 caracteres
				</Typography>
			</Box>
		</Box>
	);
};
