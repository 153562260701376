import React from 'react';

import { InstrucoesRemessaSemDiasFields } from '../../commons/InstrucoesRemessaFields';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	DescontoFields,
	ProtestoFields,
} from '../../tabs/TabInstrucoesCobrancaRemessa';

import {
	formatCedentePattern1,
	getDadosFormattingContaCorrente,
	parseCedentePattern1,
} from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';

import DefaultContaCorrenteProps, {
	defaultComponentesProps,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	formataPayloadValuesInstrucoesComTipoDias,
	formataPayloadValuesDesconto,
	formataPayloadValuesProtesto,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	mensagensErrosPadroes,
} from '../default/ContaCorrenteProps';
import { formataPayloadValuesInstrucoesComTipoValor } from '../default/ContaCorrenteProps/getFormataPayloadValues';

const validacoesMulta = getValidacoesMulta({ dias: false });

const validacoesJuros = getValidacoesJuros({ dias: false });

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	nomeCampoLegivel: 'protesto/negativação',
	msgErroVazio: mensagensErrosPadroes.vazioProtesto({ nomeCampoLegivel: 'protesto/negativação' }),
	minValue: 2,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto({
		nomeCampoLegivel: 'protesto/negativação',
		minValue: 2,
	}),
	maxValue: 90,
	msgErroMaxValue: mensagensErrosPadroes.maxValueProtesto({
		nomeCampoLegivel: 'protesto/negativação',
		maxValue: 90,
	}),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,

	validacoesJuros.tipo,
	validacoesJuros.valor,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesInstrucoesComTipoValor({ values, field: 'multa', valorIsento: 'ISE' }),
	...formataPayloadValuesInstrucoesComTipoValor({ values, field: 'juros', valorIsento: 'JIS' }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesProtesto({ values }),
});

const emptyProps = {};

const MultaFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaSemDiasFields
		sourcePrincipal='multa'
		propsTipo={{
			source: 'multa_tipo',
			label: 'Multa',
			...propsTipo,
		}}
		propsValor={{
			source: 'multa_valor',
			...propsValor,
		}}
	/>
);

const JurosFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaSemDiasFields
		sourcePrincipal='juros'
		propsTipo={{
			source: 'juros_tipo',
			label: 'Juros',
			...propsTipo,
		}}
		propsValor={{
			source: 'juros_valor',
			...propsValor,
		}}
	/>
);

const TabInstrucoesCobrancaRemessa = (props) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<ProtestoFields />]}
	/>
);

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '136',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(10),
		},
		conta_cedente: {
			parse: parseCedentePattern1(10),
			format: formatCedentePattern1(10),
		},
		protesto_dias: { min: 2, max: 90 },
	},
	getOptions: getFormatarOptions('136', { agencia: 'agencias_unicred' }),
	validacoes: [
		...DefaultContaCorrenteProps.validacoes,
		getValidateQuandoEmitindoBoletos(validacoesInstrucoesCobrancaRemessa),
	],
	formatadoresPayloadValues: {
		...defaultFormatadoresPayloadValues,
		cobranca_config: getFormatadorPayloadCobrancaConfig({
			formatadores: [
				...defaultFormatadoresPayloadValuesCobrancaConfig,

				// Dados de Tab Conta
				formataPayloadValuesTabContaCobrancaConfig,

				// Dados de Tab Instruções de Cobrança Remessa
				formataPayloadValuesTabInstrucoesCobrancaRemessa,
			],
		}),
	},
	tabContents: {
		...DefaultContaCorrenteProps.tabContents,
		TabInstrucoesCobrancaRemessa,
	},
};

export default ContaCorrenteProps;
