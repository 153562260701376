import React from 'react';
import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const ProprietarioEInquilino: React.FC = () => {
	const {
		opcoesImpressao: { imprimir_proprietario_e_inquilino_campo_pagador },
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={imprimir_proprietario_e_inquilino_campo_pagador}
					onChange={(e, checked) =>
						handleChangeOpcoesImpressao(checked, 'imprimir_proprietario_e_inquilino_campo_pagador')
					}
					name='imprimir_proprietario_e_inquilino_campo_pagador'
				/>
			}
			label='Imprimir Proprietário e Inquilino no campo Pagador'
		/>
	);
};
export default ProprietarioEInquilino;
