import React from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';

import { GaragensUnidadeContext } from '../../../context';
import { CloseButton, SaveButton } from 'components/common/commonComponentsTSX';
import FormDeGaragens from '../../form';

const generateDefaultErroMessage = () => 'Erro inesperado, tente recarregar a página';

const objectGenerateErrorMessages = {
	numero: () => 'Garagem deve ter um número.',
	nome: () => 'Garagem deve ter um nome.',
	ano_veiculo: () => 'Ano do veículo deve ter 4 dígitos.',
	garagem: (error) => error?.[1] || 'Já existe uma garagem com esse nome em uso',
};

function ModalCriarGaragem({ record: { id_condominio }, id: id_unidade }) {
	const {
		contexto: { openModalCreateGaragem },
		setContexto,
	} = React.useContext(GaragensUnidadeContext);

	const [coeficienteGaragem, setCoeficienteGaragem] = React.useState(0.0);
	const [descricaoGaragem, setDescricaoGaragem] = React.useState('');
	const [modeloVeiculo, setModeloVeiculo] = React.useState('');
	const [numeroGaragem, setNumeroGaragem] = React.useState('');
	const [marcaVeiculo, setMarcaVeiculo] = React.useState('');
	const [placaVeiculo, setPlacaVeiculo] = React.useState('');
	const [anoVeiculo, setAnoVeiculo] = React.useState('');
	const [nomeGaragem, setNomeGaragem] = React.useState('');
	const [corVeiculo, setCorVeiculo] = React.useState('');

	const [loading, setLoading] = React.useState(false);

	const dp = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();

	function onSubmit() {
		if (!String(numeroGaragem || '')) return notify('Garagem deve ter um número.', 'warning');
		if (numeroGaragem.length > 50)
			return notify('Número da garagem não pode ter mais de 50 digitos/caracteres.', 'warning');
		if (!String(nomeGaragem ?? '')) return notify('Garagem deve ter um nome.', 'warning');
		if (nomeGaragem.length > 250) return notify('Nome da garagem não pode ter mais de 250 caracteres.', 'warning');
		if (anoVeiculo.length >= 1 && anoVeiculo.length < 4)
			return notify('Ano do veículo deve ter 4 dígitos', 'warning');

		setLoading(true);
		const createObject = {
			data: {
				numero: numeroGaragem,
				nome: nomeGaragem,
				condominio: id_condominio,
				unidade: id_unidade,
				coeficiente: coeficienteGaragem || 0.0,
				descricao: descricaoGaragem || '',
				modelo_veiculo: modeloVeiculo || '',
				placa_veiculo: placaVeiculo || '',
				ano_veiculo: anoVeiculo || '',
				marca_veiculo: marcaVeiculo || '',
				cor_veiculo: corVeiculo || '',
			},
		};
		dp.create('garagens_unidades', createObject)
			.then(() => {
				notify('Garagem criada com sucesso!', 'success');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const firstError = Object.entries(e.response.data)[0];
				const chaveError = firstError[0];

				const erroMsg = (objectGenerateErrorMessages[chaveError] || generateDefaultErroMessage)(firstError);
				notify(erroMsg, 'warning');
			})
			.finally(() => setLoading(false));
	}

	function handleClose() {
		setContexto({ openModalCreateGaragem: false });
	}

	return (
		<Dialog
			open={openModalCreateGaragem}
			onClose={handleClose}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '45vw',
					position: 'relative',
				},
			}}
		>
			<DialogTitle style={{ paddingBottom: 0 }}>
				<h4 style={{ margin: 0, padding: 0 }}>Cadastrar Garagem</h4>
			</DialogTitle>
			<DialogContent style={{ 'overflow-y': 'hidden' }}>
				<FormDeGaragens
					{...{
						nomeGaragem,
						setNomeGaragem,
						numeroGaragem,
						setNumeroGaragem,
						descricaoGaragem,
						setDescricaoGaragem,
						coeficienteGaragem,
						setCoeficienteGaragem,
						marcaVeiculo,
						setMarcaVeiculo,
						modeloVeiculo,
						setModeloVeiculo,
						anoVeiculo,
						setAnoVeiculo,
						placaVeiculo,
						setPlacaVeiculo,
						corVeiculo,
						setCorVeiculo,
					}}
				/>
			</DialogContent>
			<DialogActions>
				<CloseButton size='small' onClick={handleClose}>
					{(loading && <CircularProgress size={20} />) || 'Cancelar'}
				</CloseButton>
				<SaveButton disabled={loading} size='small' onClick={onSubmit}>
					{(loading && <CircularProgress size={20} />) || 'Salvar'}
				</SaveButton>
			</DialogActions>
		</Dialog>
	);
}

export default ModalCriarGaragem;
