import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

export const DisponivelKondomynosField = ({ record }) => {
	const theme = useTheme();
	return record?.disponivel_kondomynos ? (
		<CheckIcon style={{ color: theme.palette.success.dark }} />
	) : (
		<CloseIcon style={{ color: theme.palette.syndikosRed.dark }} />
	);
};

export default DisponivelKondomynosField;
