import React from 'react';
import { Edit } from 'react-admin';

import { ImobiliariaFormulario } from './form';

export const ImobiliariaEdit = (props) => (
	<Edit title={'Editar Imobiliária'} undoable={false} {...props}>
		<ImobiliariaFormulario edit {...props} />
	</Edit>
);
