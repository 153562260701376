import React from 'react';
import axios from 'axios';
import { TextInput, useNotify } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import {
	cnpjFormatacao,
	cnpjParser,
	cnpjValidacao,
	cpfFormatacao,
	cpfParser,
	cpfValidacao,
} from '../../fieldControl/documento';
import { tipoOpcoes, tipoValidacao } from '../../fieldControl/tipoPessoa';
import { SyndikosSelect } from './SyndikosSelect';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';

export const CPFInput = (props) => {
	const { initialValues } = useFormState();
	return (
		<TextInput
			source={props.sourceDocumento}
			format={cpfFormatacao}
			parse={cpfParser}
			validate={(value) =>
				cpfValidacao(
					value,
					props.resource,
					props.initialValues?.documento || initialValues?.[props.source],
					props.customQueryValidation
				)
			}
			label='CPF'
			{...props}
			onChange={(event) => {
				const { value } = event.target;
				if (value.length >= 11) {
					event.preventDefault();
				}
			}}
		/>
	);
};

export const CNPJInput = ({ ...props }) => {
	const { initialValues, values } = useFormState();
	const [loading, setLoading] = React.useState(false);
	const notify = useNotify();
	const { change, batch } = useForm();

	const loadCnpj = (cnpj) => {
		if (!cnpj || cnpj.length < 14) return;

		setLoading(true);
		const url = `https://minhareceita.org/${cnpj}`;
		axios
			.get(url)
			.then((res) => {
				if (res?.data) {
					values['cep'] = res.data?.cep;
					values['beneficiario_cep'] = res.data?.cep;
					values['beneficiario_numero'] = res.data?.numero;
					values['beneficiario_nome'] = res.data?.razao_social;
					values['numero'] = res.data?.numero;
					batch(() => change('nome', res.data?.razao_social));
				}
				notify('CEP encontrado, continue com o cadastro', 'info');
			})
			.catch((err) => {
				if (err.response.status === 400) {
					notify('Este CNPJ não existe', 'warning');
				}
				if (err.response.status === 404) {
					notify('Este CNPJ não está cadastrado na base de dados.', 'warning');
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<Box flex={1} display='flex' flexDirection='row' alignItems='baseline'>
				<TextInput
					source={props.source}
					format={cnpjFormatacao}
					parse={cnpjParser}
					validate={(value) =>
						cnpjValidacao(
							value,
							props.resource,
							props.initialValues?.documento || initialValues?.[props.source],
							props.customQueryValidation
						)
					}
					label='CNPJ'
					fullWidth
					{...props}
					onChange={(event) => {
						const { value } = event.target;
						if (value.length >= 14) {
							event.preventDefault();
						}
					}}
				/>
				{(values['cnpj']?.length == 14 ||
					values['documento']?.length == 14 ||
					(values['beneficiario_documento']?.length == 14 &&
						values['beneficiario']['text'] != 'Condomínio')) && (
					<Box>
						<Tooltip
							title='Completar os dados de endereço com o CEP cadastrado no CNPJ.'
							onClick={() =>
								loadCnpj(values['cnpj'] || values['documento'] || values['beneficiario_documento'])
							}
						>
							<IconButton aria-label='search' disabled={loading} style={{ color: '#565B82' }}>
								<FindInPageIcon />
							</IconButton>
						</Tooltip>
					</Box>
				)}
			</Box>
		</>
	);
};

export const TipoInput = ({ sourceTipo, label = 'Tipo Pessoa', validate, ...props }) => (
	<SyndikosSelect
		defaultValue={'F'}
		source={sourceTipo}
		choices={tipoOpcoes}
		validate={validate}
		label={label}
		delay={0}
		allow
		disableClearable
		{...props}
	/>
);

export const InputDocumento = ({
	resource,
	variant,
	className = undefined,
	sourceTipo,
	source,
	disabled,
	fullWidth,
	customQueryValidation = undefined,
	validate = tipoValidacao,
	br = true,
	flexDirection = 'column',
	...rest
}) => {
	const { values, initialValues } = useFormState();

	const propsBaseadasEmFlexDirection = flexDirection === 'row' ? { gridColumnGap: '1rem' } : {};

	return (
		<Box display='flex' flexDirection={flexDirection} width='100%' {...propsBaseadasEmFlexDirection}>
			<Box flex={1}>
				<TipoInput
					{...{
						resource,
						variant,
						className,
						sourceTipo,
						disabled,
						fullWidth,
						validate,
					}}
					{...rest}
				/>
			</Box>
			{br && <br />}
			<Box flex={1} display='flex'>
				{values[sourceTipo] === 'F' ? (
					<CPFInput
						{...{
							resource,
							variant,
							className,
							initialValues,
							source,
							disabled,
							fullWidth,
							customQueryValidation,
						}}
					/>
				) : (
					<CNPJInput
						resource='condominios'
						source={source}
						variant='outlined'
						fullWidth
						customQueryValidation={`tipo_condominio=${
							values?.tipo_condominio || initialValues?.tipo_condominio
						}&cnpj`}
					/>
				)}
			</Box>
		</Box>
	);
};
