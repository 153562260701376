import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ModalFeedback from '../../../../arrecadacao/abas/Arrecadacoes/ModalFeedback';

const ModalFeedbackLancamento = ({ fulfilledResponses, rejectedResponses, onClose }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();

	return (
		<ModalFeedback
			component={
				<>
					{fulfilledResponses.length && (
						<Typography>
							{fulfilledResponses.length > 1
								? `${fulfilledResponses.length} lançamentos feitos com sucesso!`
								: rejectedResponses.length
								? '1 lançamento feito com sucesso!'
								: 'Lançamento feito com sucesso!'}
						</Typography>
					)}
					{rejectedResponses.map(({ reason }) => (
						<Typography style={{ color: syndikosRed.main }}>
							{`${reason?.dados_registro?.historico || ''}: ${
								Object.values(reason?.e?.response?.data || {})[0] || ''
							}`}
						</Typography>
					))}
				</>
			}
			onClose={onClose}
			title='Lançamento finalizado'
			closeSizeEditable={false}
		/>
	);
};

export default ModalFeedbackLancamento;
