import React from 'react';

import { isValid } from 'date-fns';

import { TStateData } from '../types';

export const useFormIsValid = ({
	nome,
	tipoIndice,
	ultimaDataAtualizacao,
	ultimoValorCalculado,
}: Readonly<TStateData>) => {
	const formIsValid = React.useMemo(() => {
		if (tipoIndice !== 'MANUAL') {
			return Boolean(nome && tipoIndice);
		}

		return Boolean(
			nome && tipoIndice && ultimaDataAtualizacao && isValid(ultimaDataAtualizacao) && ultimoValorCalculado
		);
	}, [nome, tipoIndice, ultimaDataAtualizacao, ultimoValorCalculado]);

	return formIsValid;
};

export default useFormIsValid;
