import React from 'react';

const SyndkosTabPanel = ({ children, value, index, ...other }) => {
	return (
		<div role='tabpanel' hidden={value !== index} {...other}>
			{children}
		</div>
	);
};

export default SyndkosTabPanel;
