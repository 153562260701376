import React from 'react';
import { useDataProvider } from 'react-admin';
import format from 'date-fns/format';

const createInitialReservasNoMes = (dataDoCalendario) => {
	dataDoCalendario = dataDoCalendario || new Date();
	const year = parseInt(dataDoCalendario.getFullYear());
	const month = parseInt(dataDoCalendario.getMonth()) + 1;
	const numeroDiasNoMes = new Date(year, month, 0).getDate();
	return Array.from(Array(numeroDiasNoMes), (_, i) => i + 1).reduce((reservasNoMes, dia) => {
		reservasNoMes[`${dia}`] = {
			temReservaMinha: false,
			temReservaOutros: false,
			bloqueado_manualmente: false,
			bloqueado_por_configuracao: false,
		};
		return reservasNoMes;
	}, {});
};

const createInitialConsultaState = () => ({
	condominios: [],
	condominioSelecionado: null,
	ambientes: [],
	ambienteSelecionado: null,
	unidades: [],
	unidadesSelecionadas: [],
	dataInicial: new Date(),
	diaSelecionado: null,
	reservasNoMes: createInitialReservasNoMes(),
	consultasNoDia: [],
	bloqueiosNoDia: [],
	showModalConsultaReservas: false,
	temReservaNoMes: false,
	dataDoCalendario: new Date(),
	dadosCard: null,
	dadosCardBloqueio: null,
	showModalEdicaoReservas: false,
	showModalCriarReservas: false,
	showModalBloquearReservas: false,
	showModalEdicaoBloqueio: false,
});

export const ConsultaDeReservasContext = React.createContext({
	consulta: createInitialConsultaState(),
	setConsulta: (prevState) => {},
});

export const ConsultaDeReservasContextProvider = ({ children }) => {
	const [consulta, setConsulta] = React.useState(createInitialConsultaState());
	const dp = useDataProvider();

	const getReservasNoMes = React.useCallback(() => {
		if (!consulta.condominioSelecionado || !consulta.ambienteSelecionado)
			return Promise.resolve({
				reservasNoMes: createInitialConsultaState(),
				temReservaNoMes: false,
			});

		return dp
			.getSimple('reserva_agendada_syndkos/reservas_mes', {
				filter: {
					...(consulta.unidadesSelecionadas.length
						? { unidades_ids: consulta.unidadesSelecionadas.map((u) => u.id) }
						: {}),
					mes_reservas: format(consulta.dataDoCalendario, 'yyyy-MM-dd'),
					condominio_id: consulta.condominioSelecionado.id,
					ambiente_id: consulta.ambienteSelecionado.id,
				},
			})
			.then(({ data }) => data || createInitialReservasNoMes(consulta.dataDoCalendario))
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);

				return createInitialReservasNoMes();
			})
			.then((reservasNoMes) => {
				const temReservaNoMes = Boolean(
					Object.values(reservasNoMes).find(
						({ temReservaMinha, temReservaOutros }) => temReservaMinha || temReservaOutros
					)
				);

				return { reservasNoMes, temReservaNoMes };
			});
	}, [
		dp,
		consulta.unidadesSelecionadas,
		consulta.dataDoCalendario,
		consulta.ambienteSelecionado,
		consulta.condominioSelecionado,
	]);

	const loadReservaMes = () => {
		getReservasNoMes().then(({ reservasNoMes, temReservaNoMes }) => {
			setConsulta((prevState) => ({
				...prevState,
				reservasNoMes,
				temReservaNoMes,
			}));
		});
	};

	React.useEffect(loadReservaMes, [
		consulta.dataDoCalendario,
		consulta.ambienteSelecionado,
		consulta.unidadesSelecionadas,
		consulta.condominioSelecionado,
	]);

	const actionCallBackReloadReserva = React.useCallback(() => {
		getReservasNoMes().then(({ reservasNoMes, temReservaNoMes }) => {
			setConsulta((prevState) => ({
				...prevState,
				showModalConsultaReservas: false,
				showModalEdicaoReservas: false,
				showModalCriarReservas: false,
				showModalBloquearReservas: false,
				showModalEdicaoBloqueio: false,
				dadosCard: null,
				dadosCardBloqueio: null,
				consultasNoDia: [],
				bloqueiosNoDia: [],
				reservasNoMes,
				temReservaNoMes,
			}));
		});
	}, [setConsulta, getReservasNoMes]);

	const setDadoCardAndShowModalEdicao = React.useCallback(
		(dado) => {
			setConsulta((prevState) => ({
				...prevState,
				dadosCard: dado,
				showModalEdicaoReservas: true,
			}));
		},
		[setConsulta]
	);

	const setDadoCardAndShowModalEdicaoBloqueio = React.useCallback(
		(dado) => {
			setConsulta((prevState) => ({
				...prevState,
				dadosCardBloqueio: dado,
				showModalEdicaoBloqueio: true,
			}));
		},
		[setConsulta]
	);

	const value = React.useMemo(
		() => ({
			consulta,
			setConsulta,
			actionCallBackReloadReserva,
			setDadoCardAndShowModalEdicao,
			setDadoCardAndShowModalEdicaoBloqueio,
		}),
		[
			consulta,
			setConsulta,
			actionCallBackReloadReserva,
			setDadoCardAndShowModalEdicao,
			setDadoCardAndShowModalEdicaoBloqueio,
		]
	);

	return <ConsultaDeReservasContext.Provider value={value}>{children}</ConsultaDeReservasContext.Provider>;
};
