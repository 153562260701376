import React from 'react';

import { Field } from 'react-final-form';

import { TextInput, BooleanInput, required, NumberInput } from 'react-admin';

import { Box, Typography } from '@material-ui/core';

import { BoxDivisor, FormularioBox, TextInputSyndikos } from '../../common/Formulario';
import { SelectMultiResource, SelectOneResource } from '../../common/SelectResource';
import { SyndikosKeyboardDatePicker, SyndikosMuiPickersUtilsProvider } from '../../common/InputDate';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import InputFile from '../../common/InputFile';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import MultiSelectDropdown from 'components/common/MultiSelectDropdown';
import {
	CheckBoxEnviarNotificacaoVencimento,
	CheckBoxNotificacaoAposVencimento,
	CheckBoxPrevisaoEntrega,
} from './fields/CheckBoxNotificacao';
import {
	BoxConfirmUpdatePersonalizado,
	BoxEnvioNotificacaoCriacaoTarefa,
} from './fields/SavePersonalizadoTarefaManutencao';

const dictLabels = {
	C: 'Concluído',
	A: 'Em aberto',
	V: 'Vencida',
	EA: 'Em Atraso',
	VH: 'Vencendo Hoje',
};

const getChipClassName = ({ styles, situacao }) =>
	styles[{ C: 'concluido', A: 'ativo', V: 'inativo', EA: 'em_atraso', VH: 'vencendo_hoje' }[situacao] || 'inativo'];

export const TarefasManutencoesFormulario = (props) => {
	const { condominioSelecionado, setCondominioPorId } = React.useContext(CondominiosContext);
	const { options: optionsUnidades } = useRequestOptionsUnidades();
	const responsavelPrincipalOptions = [
		{ id: 'Proprietário', name: 'Proprietário' },
		{ id: 'Inquilino', name: 'Inquilino' },
		{ id: 'Ambos', name: 'Ambos' },
	];
	const [notificarTarefa, setNotificarTarefa] = React.useState(props?.record?.notificar_tarefa_vencida || false);
	const NotificarTarefaVencida = (v) => {
		setNotificarTarefa(v);
	};

	return (
		<FormularioBox
			customSaveButton={
				props?.match.path === '/tarefas_manutencoes/create' ? (
					<BoxEnvioNotificacaoCriacaoTarefa {...props} />
				) : (
					<BoxConfirmUpdatePersonalizado {...props} />
				)
			}
			{...props}
			dictLabels={dictLabels}
			getChipClassName={getChipClassName}
			onlyExcluir={true}
		>
			<Box flex={1} mr='1em'>
				<Box display='flex'>
					<Box flex={1} m='1em'>
						<Typography variant='h5' gutterBottom>
							Informações da Tarefa
						</Typography>

						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>

						<Box display='flex'>
							<Box flex={1} mr='0.5em'>
								<VeryHighLimitReferenceInput
									source='condominio'
									reference='condominios'
									label='Condomínio'
									fullWidth
									variant='outlined'
									validate={required()}
									onChange={(value) => {
										setCondominioPorId(value);
									}}
									filter={{ situacao: 'A' }}
									disabled={props.edit}
									initialValue={!props.edit && condominioSelecionado?.id}
								>
									<SyndikosSelect optionText='nome' disableClearable />
								</VeryHighLimitReferenceInput>
							</Box>
							<Box flex={2} mr='1em'>
								<TextInput source='nome_tarefa' label='Tarefa' variant='outlined' fullWidth required />
							</Box>
						</Box>
						<Box display='flex' mb='1em'>
							<Box flex={1} mr='0.5em'>
								<Field name='principal_responsavel' id='principal_responsavel'>
									{({ input }) => (
										<SelectOneResource
											{...props}
											source='principal_responsavel'
											label='Responsável Principal'
											fullWidth
											variant='outlined'
											resource='user'
											required
											value={input.value}
											onChange={input.onChange}
											choices={responsavelPrincipalOptions}
										/>
									)}
								</Field>
							</Box>
							<Box flex={1} mr='0.5em'>
								<Field name='unidades' id='unidades'>
									{({ input }) => (
										<MultiSelectDropdown
											variant='formatsUnidades'
											options={optionsUnidades}
											value={
												Array.isArray(input.value)
													? optionsUnidades.filter((value) => input.value.includes(value.id))
													: []
											}
											setValue={(value) => {
												input.onChange(value.length ? value.map((value) => value.id) : []);
											}}
											withButtonAction={false}
										/>
									)}
								</Field>
							</Box>
							<Box flex={1} mr='0.5em'>
								<Field name='demais_responsaveis' id='demais_responsaveis'>
									{({ input }) => (
										<SelectMultiResource
											{...props}
											label='Demais Responsáveis'
											fullWidth
											variant='outlined'
											resource='pessoas'
											value={input.value}
											onChange={input.onChange}
										/>
									)}
								</Field>
							</Box>
						</Box>
						<Box flex={1}>
							<Typography variant='h5' gutterBottom>
								Previsão de Entrega
							</Typography>
							<Box display='flex' alignItems='center'>
								<SyndikosMuiPickersUtilsProvider>
									<SyndikosKeyboardDatePicker
										source='data_entrega'
										label='Previsão de Entrega'
										{...props}
										required
										format='dd/MM/yyyy'
										id='date-picker-inline'
										mask='__/__/____'
										parse={(value) => {
											try {
												return value.toISOString().split('T')[0];
											} catch {
												return value;
											}
										}}
									/>
								</SyndikosMuiPickersUtilsProvider>
								<Box display='flex' flexDirection='column' ml={2}>
									<Typography variant='body1' style={{ marginRight: '1em' }}>
										A notificação da previsão de entrega será enviada:
									</Typography>
									<Field
										name='notificacoes_previsao_de_entrega'
										id='notificacoes_previsao_de_entrega'
									>
										{() => (
											<CheckBoxPrevisaoEntrega
												source='notificacoes_previsao_de_entrega'
												props={props}
											/>
										)}
									</Field>
								</Box>
							</Box>
						</Box>
						<Box flex={1} mt='1em'>
							<Typography variant='h5' gutterBottom>
								Vencimento
							</Typography>
							<Box display='flex' alignItems='center'>
								<SyndikosMuiPickersUtilsProvider>
									<SyndikosKeyboardDatePicker
										source='data_vencimento'
										label='Data de Vencimento'
										{...props}
										required
										format='dd/MM/yyyy'
										id='date-picker-inline'
										mask='__/__/____'
										parse={(value) => {
											try {
												return value.toISOString().split('T')[0];
											} catch {
												return value;
											}
										}}
									/>
								</SyndikosMuiPickersUtilsProvider>
								<Box display='flex' flexDirection='column' ml={2}>
									<Typography variant='body1' style={{ textAlign: 'left', marginRight: '1em' }}>
										Enviar notificação de vencimento no prazo de:
									</Typography>
									<Box flex={1}>
										<Field name='notificacoes_vencimento' id='notificacoes_vencimento'>
											{() => (
												<CheckBoxEnviarNotificacaoVencimento
													source='notificacoes_vencimento'
													props={props}
												/>
											)}
										</Field>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box flex={2} mt='0.6em'>
							<BooleanInput
								source='notificar_tarefa_vencida'
								label='Notificar Tarefa Vencida Não Concluída'
								initialValue={props?.record?.notificar_tarefa_vencida || false}
								onChange={NotificarTarefaVencida}
							/>
						</Box>
						{notificarTarefa ? (
							<Box display='flex' mr='0.5em'>
								<Box>
									<Box>
										<Typography variant='body1' style={{ marginRight: '1em' }}>
											Quantos dias após o vencimento começa a notificar?
										</Typography>
									</Box>
									<Box flex={1}>
										<NumberInput
											source='dias_apos_vencimento_para_notificar'
											label='Dias após vencimento'
											margin='dense'
											variant='outlined'
											{...(props.record.id ? {} : { defaultValue: 1 })}
											parse={(value) =>
												(typeof value !== 'number' && !value) ||
												isNaN(value) ||
												parseInt(value) < 1
													? 1
													: parseInt(value)
											}
											fullWidth
										/>
									</Box>
								</Box>
								<Box flex={2} ml={2}>
									<Field name='notificacoes_apos_vencimento' id='notificacoes_apos_vencimento'>
										{() => (
											<CheckBoxNotificacaoAposVencimento
												source='notificacoes_apos_vencimento'
												props={props}
											/>
										)}
									</Field>
								</Box>
							</Box>
						) : (
							<></>
						)}

						<Box display='flex' mb='1em'>
							<Field name='write_only_anexo_tarefa' id='write_only_anexo_tarefa'>
								{({ input }) => (
									<InputFile
										source='write_only_anexo_tarefa'
										label='Arquivo em anexo'
										variant='outlined'
										PermitedFileExtension={[
											'doc',
											'docx',
											'pdf',
											'xls',
											'xlsx',
											'jpg',
											'jpeg',
											'png',
										]}
										fullWidth
										onCallBack={(e, dataUrl) => {
											input.onChange(dataUrl);
										}}
										fileDataUrl={props.record.write_only_anexo_tarefa}
									/>
								)}
							</Field>
						</Box>
						<Box display='flex'>
							<BoxDivisor titulo='Observações' flex={2}>
								<TextInputSyndikos
									source='observacao'
									multiline
									rows={8}
									label='Faça suas anotações nesse espaço'
									defaultValue=''
								/>
							</BoxDivisor>
						</Box>
						<Box display='flex'>
							<BoxDivisor titulo='Conclusão' flex={2}>
								<TextInputSyndikos
									multiline
									rows={8}
									source='conclusao'
									label='Faça suas anotações nesse espaço'
									defaultValue=''
								/>
							</BoxDivisor>
						</Box>
						<Box display='inline-flex'>
							<Box flex={1}>
								<SyndikosMuiPickersUtilsProvider>
									<SyndikosKeyboardDatePicker
										source='data_conclusao'
										label='Data de Conclusão'
										{...props}
										format='dd/MM/yyyy'
										id='date-picker-inline'
										mask='__/__/____'
										parse={(value) => {
											try {
												return value.toISOString().split('T')[0];
											} catch {
												return value;
											}
										}}
										defaultValue={null}
									/>
								</SyndikosMuiPickersUtilsProvider>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</FormularioBox>
	);
};
