import React, { useState, useEffect, useContext } from 'react';

import { useUpdate } from 'react-admin';

import { Typography, Box, Chip } from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Field } from 'react-final-form';

import { TooltipIconButton } from '../../../../common/TooltipIconButton';
import { CustomDialogBody } from '../../../../common/ModalContext';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import ModalUnidadesDisponiveis from './ModalUnidadesDisponiveis';
import { UnidadePrincipalContext } from './contextsUnidadesSubordinadas';

const ChipUnidade = (props) => {
	const { setUnidadesSubordinadas, setModalValue } = useContext(UnidadePrincipalContext);
	const [desvincular, { data }] = useUpdate('unidades', `${props.id}/desvincular_subordinada`, {});
	const [display, setDisplay] = useState('none');
	const handleDelete = () => {
		desvincular();
		handleClose();
	};
	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};
	const openDesvincular = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBody
					title='Desvincular unidade'
					text='Tem certeza que deseja desvincular esta unidade?'
					customActions={
						<>
							<ButtonCancel onClick={handleClose} size='small' />
							<ButtonConfirm onClick={handleDelete} size='small'>
								Confirmar
							</ButtonConfirm>
						</>
					}
				/>
			),
		}));
	};
	const updateUnidadesSubordinadas = () => {
		if (data) setUnidadesSubordinadas((unidades) => unidades.filter((value) => value.id !== data.id));
	};
	useEffect(updateUnidadesSubordinadas, [data]);
	return (
		<Tooltip
			title={`Unidade ${props.unidade} - Proprietário: ${props.proprietario} ${
				props.inquilino ? ' - Inquilino: ' + props.inquilino : ''
			}`}
		>
			<Chip
				variant='outlined'
				onDelete={openDesvincular}
				deleteIcon={<HighlightOffIcon style={{ display: display }} />}
				onMouseEnter={() => setDisplay('block')}
				onMouseLeave={() => setDisplay('none')}
				style={{
					marginRight: '0.5em',
					marginBottom: '0.5em',
					width: '90px',
				}}
				label={props.unidade}
				color='primary'
			/>
		</Tooltip>
	);
};

const ActionButtonsHover = () => {
	const { setModalValue } = useContext(UnidadePrincipalContext);
	const openAdd = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalUnidadesDisponiveis />,
		}));
	};

	return (
		<TooltipIconButton title='Vincular Unidades Subordinadas' onClick={openAdd} ml='0.5em' color='primary'>
			<HomeOutlinedIcon />
		</TooltipIconButton>
	);
};

const TooltipDadosUnidadesSubordinadas = ({ quantidadeUnidadesSubordinadas, showUnidades, setShowUnidades }) => {
	const handleExpand = () => setShowUnidades((v) => !v);

	if (!quantidadeUnidadesSubordinadas) return <></>;

	return (
		<TooltipIconButton title={!showUnidades ? 'Mostrar Unidades' : 'Ocultar Unidades'} onClick={handleExpand}>
			<ArrowForwardIosIcon
				fontSize='medium'
				style={{
					transition: 'all 200ms !important',
					transform: showUnidades ? 'rotate(90deg)' : 'rotate(0deg)',
				}}
			/>
		</TooltipIconButton>
	);
};

const BoxDadosUnidadesSubordinadas = ({ showUnidades, unidadesSubordinadas }) => {
	if (!showUnidades) return <></>;
	return (
		<>
			<Box display='flex'>
				<Box flexGrow={1} mr='1em'>
					{(unidadesSubordinadas || []).map((u) => (
						<ChipUnidade {...u} key={u.id} />
					))}
				</Box>
			</Box>
		</>
	);
};

const SwitchUnificarCobrancasDasUnidadesSubordinadas = () => {
	return (
		<Field id='unificar_cobrancas' name='unificar_cobrancas' type='checkbox'>
			{({ input }) => (
				<FormControlLabel
					control={
						<Switch
							color='primary'
							checked={input.checked}
							onChange={(e) => {
								input.onChange(e);
							}}
						/>
					}
					label={<Typography variant='h6'>Unificar Cobranças</Typography>}
					labelPlacement='end'
				/>
			)}
		</Field>
	);
};

const BoxUnidadesSubordinadas = () => {
	const { unidadesSubordinadas } = useContext(UnidadePrincipalContext);
	const [showUnidades, setShowUnidades] = useState(false);

	const quantidadeUnidadesSubordinadas = unidadesSubordinadas?.length ?? 0;

	return (
		<>
			<Box display='flex' alignItems='center' gridColumnGap='0.5em'>
				<TooltipDadosUnidadesSubordinadas
					{...{ quantidadeUnidadesSubordinadas, showUnidades, setShowUnidades }}
				/>
				<Typography variant='h6'>Quantidade: {quantidadeUnidadesSubordinadas}</Typography>
				<ActionButtonsHover />
				<SwitchUnificarCobrancasDasUnidadesSubordinadas />
			</Box>
			<BoxDadosUnidadesSubordinadas showUnidades={showUnidades} unidadesSubordinadas={unidadesSubordinadas} />
			<hr
				style={{
					marginRight: '1em',
					borderTop: '#d3d3d3',
				}}
			></hr>
		</>
	);
};

export default BoxUnidadesSubordinadas;
