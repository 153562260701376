import React from 'react';

type PainelExpandivelResponsavelProps = {
	record?: any;
	motivo_saida: string;
};

const PainelExpandivelObservacaoResponsavel: React.FC<PainelExpandivelResponsavelProps> = ({ record }) => {
	if (!record) return null;

	const { motivo_saida } = record || {};

	return <span>Motivo de saída: {motivo_saida}</span>;
};

export default PainelExpandivelObservacaoResponsavel;
