import { getDadosFormattingContaCorrente } from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';
import DefaultContaNaoCorrenteProps, { defaultComponentesProps } from '../default/ContaNaoCorrenteProps';

const ContaNaoCorrenteProps = {
	...DefaultContaNaoCorrenteProps,
	instituicaoCodigo: '133',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(13),
		},
	},
	getOptions: getFormatarOptions('133', { agencia: 'agencias_cresol' }),
};

export default ContaNaoCorrenteProps;
