import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { format } from 'date-fns';
import MultiSelect from 'components/common/Selects';
import { BoxDivisor } from '../../../common/Formulario';
import { RelatorioMonthPicker, TODAY } from '../dateUtils';
import RelatorioToolbar from '../RelatorioToolbar';

import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const opcaoTodasIdentificacoes = { id: 0, nome: 'Todas' };

const opcoesOrdenacao = [
	{
		id: 'grupo_unidade',
		nome: 'Grupo/Unidade',
	},
	{ id: 'nome', nome: 'Nome' },
	{ id: 'vencimento', nome: 'Vencimento' },
	{ id: 'nosso_numero', nome: 'Nosso Número' },
];

const OpcoesImpressaoDemonstrativoCheckboxGrupo = ({ handleChange, imprimirAssinatura }) => {
	return (
		<>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirAssinatura}
						onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
						name='imprimir_assinatura'
					/>
				}
				label='Imprimir assinaturas'
			/>
		</>
	);
};

export default function DemonstrativoContasReceber({ idCondominio, tipoRelatorio }) {
	const dataProvider = useDataProvider();
	const [dataInicial, setDataInicial] = useState(TODAY);
	const [dataFinal, setDataFinal] = useState(TODAY);
	const [identificacoes, setIdentificacoes] = useState([opcaoTodasIdentificacoes]);
	const [identificacao, setIdentificacao] = useState(opcaoTodasIdentificacoes);

	const {
		opcoesImpressao: { imprimir_assinatura },
		handleChangeOpcoesImpressao,
		tipoOrdenacao,
		setTipoOrdenacao,
	} = React.useContext(CheckboxRelatorioContext);

	const [unidades, setUnidades] = useState([]);
	const [unidadesData, setUnidadesData] = useState([]);
	const requestData = {
		condominio_id: idCondominio,
		mes_inicial: format(dataInicial, 'yyyy-MM-dd'),
		mes_final: format(dataFinal, 'yyyy-MM-dd'),
		identificacao_id: identificacao ? identificacao.id : '0',
		unidades_ids: unidades.map((v) => v.id),
		imprimir_assinatura: imprimir_assinatura,
		tipo_ordenacao: tipoOrdenacao?.id,
		nome_relatorio: tipoRelatorio.action,
	};

	useEffect(() => {
		dataProvider
			.getList('identificacao_arrecadacao', {
				pagination: { perPage: 1000, page: 1 },
				sort: { field: 'id', order: 'ASC' },
				filter: {
					id_condominio: idCondominio,
				},
			})
			.then((response) => {
				const data = (response?.data || []).filter((identificacao) => identificacao?.id);
				data.push({ id: 0, nome: 'Todas' });
				setIdentificacoes(data);
			})
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				setIdentificacoes([opcaoTodasIdentificacoes]);
			})
			.finally(() => {
				setIdentificacao(opcaoTodasIdentificacoes);
			});
	}, [idCondominio, dataProvider]);

	useEffect(() => {
		dataProvider
			.getList('unidades', {
				pagination: { perPage: 1000, page: 1 },
				sort: { field: 'unidade', order: 'ASC' },
				filter: {
					id_condominio: idCondominio,
					situacao: 'A',
				},
			})
			.then((response) => {
				const data = response?.data || [];
				setUnidades([]);
				setUnidadesData(data);
			})
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				setUnidades([]);
				setUnidadesData([]);
			});
	}, [idCondominio, dataProvider]);

	return (
		<BoxDivisor titulo={'Demonstrativo de Contas a Receber'}>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<RelatorioMonthPicker
						label='Mês Inicial'
						handleChange={(newValue) => setDataInicial(newValue)}
						value={dataInicial}
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<RelatorioMonthPicker
						label='Mês Final'
						handleChange={(newValue) => setDataFinal(newValue)}
						value={dataFinal}
						fullWidth
					/>
				</Box>
				<Box flex={2} mr='1em'>
					<Autocomplete
						value={identificacao}
						onChange={(_, newValue) => setIdentificacao(newValue)}
						options={identificacoes}
						getOptionLabel={(o) => o.nome}
						getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
						renderInput={(params) => (
							<TextField {...params} label='Identificação de Arrecadação' variant='outlined' />
						)}
						disabled={identificacoes.length === 1}
						clearText='Limpar'
						autoHighlight
						noOptionsText='Nenhuma identificação disponível'
						size='small'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<Box width='50%' mr='1em'>
					<MultiSelect
						variant='formatsUnidades'
						setValue={setUnidades}
						value={unidades}
						options={unidadesData}
						getOptionSelected={(option, value) => option.id === value.id}
						renderInput={(params) => (
							<TextField {...params} label='Selecionar Unidade' variant='outlined' />
						)}
						disabled={!unidadesData.length}
						withButtonAction={true}
						size='small'
					/>
				</Box>
				<Box width='50%'>
					<Box mb='1em' mr='1em'>
						<Autocomplete
							value={tipoOrdenacao || opcoesOrdenacao[0]}
							options={opcoesOrdenacao}
							getOptionLabel={(o) => o.nome}
							onChange={(_, newValue) => setTipoOrdenacao(newValue)}
							renderInput={(params) => <TextField {...params} label='Ordenação' variant='outlined' />}
							autoHighlight
							disableClearable={true}
							size='small'
							fullWidth
						/>
					</Box>
					<OpcoesImpressaoDemonstrativoCheckboxGrupo
						handleChange={handleChangeOpcoesImpressao}
						imprimirAssinatura={imprimir_assinatura}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
}
