import * as React from 'react';
import { Create, useNotify, useRedirect, useRefresh } from 'react-admin';

import { PessoaFormulario } from './form';

export const PessoaCreate = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	// Função para substituir onSuccess do <Create /> caso seja escolhida mensagem customizada
	// eslint-disable-next-line
	const onSuccess = (response) => {
		const data = response?.data;
		if (data) {
			notify(`Pessoa "${data.nome}" cadastrada com sucesso`);
			redirect('list', props.basePath);
			refresh();
		}
	};

	return (
		<Create component='div' title={'Cadastrar Pessoa'} undoable={false} onSuccess={onSuccess} {...props}>
			<PessoaFormulario {...props} />
		</Create>
	);
};
