import React from 'react';
import { Create } from 'react-admin';
import { UnidadeFormulario } from './form';

export const UnidadeCreate = (props) => {
	return (
		<Create
			title={'Cadastrar Unidade'}
			undoable={false}
			transform={(data) => ({ ...data, situacao: 'A', coeficiente: data.coeficiente || 0 })}
			{...props}
		>
			<UnidadeFormulario {...props} />
		</Create>
	);
};
