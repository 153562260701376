import React, { useContext } from 'react';

import IconButton from '@material-ui/core/IconButton';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import RowContext from '../contexts/RowContext';

const ExpandIconButton = ({ children, level }) => {
	const { handleExpand, openChildren } = useContext(RowContext);

	if (!children?.length) return <></>;

	return (
		<IconButton
			className='tree-icon-table'
			onClick={() => handleExpand()}
			style={{
				padding: 10,
				position: 'absolute',
				left: level * 16 + 5,
				top: 'calc(50% - 20px)',
			}}
		>
			<ArrowForwardIosIcon
				style={{
					height: 18,
					width: 18,
					transition: 'all 200ms !important',
					transform: openChildren ? 'rotate(90deg)' : 'rotate(0deg)',
				}}
			/>
		</IconButton>
	);
};

export default ExpandIconButton;
