import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

interface LoadingPageBoletoProps {
	loading: boolean;
}

const useStyles = makeStyles(() => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '1rem',
	},
	text: { color: 'white' },
	groupText: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
}));

const LoadingPageBoletos: React.FC<LoadingPageBoletoProps> = (props) => {
	const classes = useStyles();
	const open = props.loading;
	if (props.loading == true) {
		return (
			<>
				<Modal
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
					className={classes.modal}
					open={open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<>
						<CircularProgress />
						<h2 className={classes.groupText}>
							<div className={classes.text}>Por favor, aguarde alguns instantes!</div>
						</h2>
					</>
				</Modal>
			</>
		);
	}
	return <></>;
};

export default LoadingPageBoletos;
