import React, { useState, useContext, useEffect } from 'react';

import { format } from 'date-fns';

import { Edit, List, Pagination, ReferenceField, TextField, useDataProvider, useNotify } from 'react-admin';

import { Padrao } from 'components/common/filtros/Padrao';
import { Autocompletar } from 'components/common/filtros/Autocompletar';
import { Tabela } from 'components/common/Tabela';
import { Table, TableBody, TableCell, TableRow, Typography, Box, Button, Dialog } from '@material-ui/core';
import { StatusLicencasContext } from './StatusLicencasProvider';

import { ConfirmButton, CloseButton } from 'components/common/commonComponentsTSX';
import { TCondominio } from 'types/condominio';

import {
	TRecord,
	TProps,
	SearchFieldsProps,
	AssembleiaListProps,
	ReferenceFieldV2Props,
	BotaoFieldProps,
	TextFieldProps,
	CustomDataProvider,
} from './types';
import { AdminContext } from 'components/common/AdminContextProvider';
import { situacaoOpcoes } from 'fieldControl/situacao';
import { OutlinedLicencaIcon } from 'components/layout/LicencaIcon';

const DateFieldCondominio: React.FC<SearchFieldsProps> = ({ record }: TRecord) => {
	const { statusCondominios } = useContext(StatusLicencasContext);

	if (statusCondominios[record.id]?.situacao === 'A') return <span></span>;

	const date = new Date();
	date.setDate(1);
	date.setMonth(date.getMonth() + 1);

	return <span>{format(date, 'dd/MM/yyyy')}</span>;
};

const DateFieldApp: React.FC<SearchFieldsProps> = ({ record }: TRecord) => {
	const { statusCondominios } = useContext(StatusLicencasContext);

	if (statusCondominios[record.id]?.disponivelParaKondomynos) return <span></span>;

	return <span>Imediatamente</span>;
};

const BotaoStatusCondominio: React.FC<BotaoFieldProps> = ({ record }) => {
	const { statusCondominios, atualizarDadosCondominio } = useContext(StatusLicencasContext);
	const { situacao = 'I' } = statusCondominios[record.id] || {};

	const handleStatusCondominio = () => {
		atualizarDadosCondominio(record.id, situacao === 'A' ? 'I' : 'A', false);
	};

	return (
		<Button
			variant='contained'
			size='small'
			title={situacao == 'A' ? 'Inativar' : 'Ativar'}
			onClick={handleStatusCondominio}
			style={{ backgroundColor: situacao === 'A' ? '#28a741' : '#CA3433' }}
		>
			{situacao === 'A' ? 'Ativo' : 'Inativo'}
		</Button>
	);
};

const BotaoStatusApp: React.FC<BotaoFieldProps> = ({ record }) => {
	const [loading] = useState(false);
	const { statusCondominios, atualizarDadosCondominio } = useContext(StatusLicencasContext);
	const { situacao = 'I', disponivelParaKondomynos = false } = statusCondominios[record.id] || {};

	const handleStatusApp = () => {
		atualizarDadosCondominio(record.id, situacao, !disponivelParaKondomynos);
	};

	const style =
		situacao == 'I'
			? {}
			: {
					backgroundColor: disponivelParaKondomynos ? '#28a741' : '#CA3433',
			  };

	return (
		<Button
			variant='contained'
			disabled={situacao == 'I' || loading}
			size='small'
			title={disponivelParaKondomynos && situacao === 'A' ? 'Inativar' : 'Ativar'}
			onClick={handleStatusApp}
			style={style}
		>
			{disponivelParaKondomynos && situacao === 'A' ? 'Ativo' : 'Inativo'}
		</Button>
	);
};

const ReferenceFieldV2: React.FC<ReferenceFieldV2Props> = ({ children, ...rest }) => {
	return <ReferenceField {...rest}>{children}</ReferenceField>;
};

const CustomTextField = (props: TextFieldProps) => <TextField {...props} />;

const TabelaConsultaLicencas = (record: TCondominio) => {
	return (
		<>
			<Box borderBottom={'1px rgb(0, 0, 0, 0.1) solid'} width={'100%'} />
			<Tabela disableClickRow>
				<CustomTextField
					maxWidth='10px'
					source='codigo'
					label='Código'
					filtro={<Padrao />}
					record={record}
					align='right'
				/>
				<ReferenceFieldV2
					id='reference-field-condominio'
					source='codigo'
					reference='condominios'
					label='Condomínio'
					link={false}
					sortBy='nome'
					filtro={
						<Autocompletar
							refName='nome'
							refResource='condominios'
							mostrarInativos={true}
							id='filtro-nome-condominio'
							label={undefined}
							source={undefined}
							opcoes={undefined}
						/>
					}
					minWidth='200px'
				>
					<TextField source='nome' />
				</ReferenceFieldV2>
				<BotaoStatusCondominio
					source='situacao'
					filtro={
						<Autocompletar
							opcoes={situacaoOpcoes}
							id='filtro-situacao-condominio'
							label={undefined}
							source='situacao'
							refResource='condominios'
							refName='situacao'
							mostrarInativos={undefined}
						/>
					}
					record={record}
					label='Status'
				/>
				<DateFieldCondominio label='Libera Licença Em' record={undefined} />
				<BotaoStatusApp
					source='disponivel_para_kondomynos'
					filtro={
						<Autocompletar
							opcoes={[
								{ id: true, name: 'Ativo' },
								{ id: false, name: 'Inativo' },
							]}
							id='filtro-app-disponivel-condominio'
							label={undefined}
							source='disponivel_para_kondomynos'
							refResource='condominios'
							refName='disponivel_para_kondomynos'
							mostrarInativos={undefined}
						/>
					}
					record={record}
					label='APP'
				/>
				<DateFieldApp label='Libera Licença Em' record={undefined} />
			</Tabela>
		</>
	);
};

const NestedTables = ({
	headerText,
	cell1Text,
	cell2Text,
	marginLeft,
	marginRight,
}: {
	headerText: string;
	cell1Text: string | number;
	cell2Text: string | number;
	marginLeft: string;
	marginRight: string;
}) => {
	return (
		<Table
			style={{
				marginLeft: marginLeft,
				marginRight: marginRight,
			}}
		>
			<TableBody>
				<TableRow>
					<TableCell style={{ fontSize: '20px', textAlign: 'center', borderBottom: 'none' }}>
						{headerText}
					</TableCell>
					<TableCell style={{ borderBottom: 'none' }}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										<Typography style={{ textTransform: 'none' }}>Contratadas</Typography>
									</TableCell>
									<TableCell>
										<Typography style={{ textTransform: 'none' }}>{cell1Text}</Typography>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell style={{ borderBottom: 'none' }}>
										<Typography style={{ textTransform: 'none' }}>Utilizadas</Typography>
									</TableCell>
									<TableCell style={{ borderBottom: 'none' }}>
										<Typography style={{ textTransform: 'none' }}>{cell2Text}</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

const SideBySideNestedTables = (props: TProps) => {
	const { condominiosAtivos, appsAtivos, limiteCondominios, limiteApps } = useContext(StatusLicencasContext);

	return (
		<Box display='flex' flexDirection='row' borderBottom={'1px 1px'}>
			<NestedTables
				headerText='Licenças dos Condomínios'
				cell1Text={limiteCondominios === Infinity ? 'Ilimitado' : limiteCondominios}
				cell2Text={condominiosAtivos}
				marginLeft='30px'
				marginRight='50px'
			/>
			<NestedTables
				headerText='Licenças dos Aplicativos'
				cell1Text={limiteApps === Infinity ? 'Ilimitado' : limiteApps}
				cell2Text={appsAtivos}
				marginLeft='50px'
				marginRight='200px'
			/>
		</Box>
	);
};

function ConsultaLicencasList(props: any) {
	const { statusCondominios, condominiosAtivos, appsAtivos, limiteCondominios, limiteApps } =
		useContext(StatusLicencasContext);
	const { situacaoIrregular, setSituacaoIrregular } = useContext(AdminContext);
	const dP = useDataProvider() as CustomDataProvider;
	const notify = useNotify();
	const { abreControleLicencas, setAbreControleLicencas } = props;
	const [loading, setLoading] = useState(false);

	const onSave = () => {
		window.location.reload();
		setLoading(true);
		const data = [];
		const ids = [];
		for (const condominio in statusCondominios) {
			ids.push(condominio);
			data.push({
				id: condominio,
				data: {
					situacao: statusCondominios[condominio].situacao,
					disponivel_para_kondomynos: statusCondominios[condominio].disponivelParaKondomynos,
				},
			});
		}
		dP.updateMany('condominios', data)
			.then(() => {
				setAbreControleLicencas(false);
				setSituacaoIrregular(false);
			})
			.catch(() => notify('Erro na atualização das licenças'));
	};

	const auth = JSON.parse(localStorage.getItem('auth') || '');

	const CustomPagination = (props: AssembleiaListProps) => (
		<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
	);
	return (
		<Dialog open={abreControleLicencas} maxWidth='xl'>
			<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Typography variant='h4' align='left' style={{ padding: '20px' }}>
					Controle de Licenças
				</Typography>
				<OutlinedLicencaIcon
					style={{ width: '21rem', height: '2.5rem', margin: '12px 35px 0 0' }}
					url={props.url}
				/>
			</Box>

			<Edit title={' '} basePath='/administradora' resource='administradora' id={auth.tenant.schema_name}>
				<SideBySideNestedTables edit {...props} />
			</Edit>
			<List
				actions={false}
				title={' '}
				basePath='/condominios'
				resource='condominios'
				sort={{ field: 'nome', order: 'ASC' }}
				pagination={<CustomPagination labelRowsPerPage='Condomínios por página' />}
			>
				<TabelaConsultaLicencas {...props} />
			</List>
			<Box display='flex' flexDirection='row' justifyContent='flex-end' padding='10px'>
				<ConfirmButton
					onClick={onSave}
					disabled={condominiosAtivos > limiteCondominios || appsAtivos > limiteApps || loading}
				/>
				<CloseButton
					onClick={() => setAbreControleLicencas(false)}
					size={undefined}
					disabled={situacaoIrregular}
				/>
			</Box>
		</Dialog>
	);
}

export default ConsultaLicencasList;
