import React from 'react';
import api from 'services/api';
import type { TCondominio } from '../types/condominio';

const defaultCondominioContextValue = {
	condominioSelecionado: null,
	condominios: [],
	setCondominio: () => {
		return;
	},
	setCondominioPorId: () => {
		return;
	},
};

export type TCondominioContext = {
	condominioSelecionado: TCondominio | null;
	condominios: TCondominio[] | never[];
	setCondominio: React.Dispatch<React.SetStateAction<TCondominioContext>>;
	setCondominioPorId: (id_condominio_id: number) => void;
};

export const CondominiosContext = React.createContext<TCondominioContext>(defaultCondominioContextValue);

const setCondominioInLocalStorage = (condominioSelecionado: TCondominio | null) => {
	try {
		localStorage.setItem('condominio', JSON.stringify(condominioSelecionado) || '');
	} catch {
		localStorage.removeItem('condominio');
	}
};

export const CondominiosContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [condominio, setCondominio] = React.useState<TCondominioContext>(defaultCondominioContextValue);
	const [reload, setReload] = React.useState({});
	const currentPath = window.location.href;

	const reloadCondominios = React.useCallback(() => {
		if (currentPath.includes('login') || currentPath === 'http://admin.syndkos/') return;
		const storedCondominioSelecionado: string = localStorage.getItem('condominio') || '{}';
		const parsedCondominioSelecionado: TCondominio = JSON.parse(storedCondominioSelecionado);
		api.get('api/v1/condominios', {
			params: {
				limit: 10000,
				offset: 0,
				ordering: 'nome',
				situacao: 'A',
			},
		}).then(({ data: { results = [] } = {} }) => {
			const existStoredCondominio =
				parsedCondominioSelecionado &&
				results.find((result: TCondominio) => result.id === parsedCondominioSelecionado?.id);
			const condominioSelecionado = existStoredCondominio || results[0] || null;
			setCondominioInLocalStorage(condominioSelecionado);
			setCondominio((_condominio) => ({
				..._condominio,
				condominios: results as unknown as TCondominio[],
				condominioSelecionado: condominioSelecionado,
			}));
		});
	}, [currentPath, setCondominio]);

	const setCondominioContextValue = React.useCallback(
		(condominioChanged: any) => {
			setCondominioInLocalStorage(condominioChanged ?? null);
			setCondominio((prevState) => ({
				...prevState,
				condominioSelecionado: condominioChanged ?? null,
			}));
		},
		[setCondominio]
	);

	const setCondominioPorId = React.useCallback(
		(id_condominio_id: number) => {
			setCondominioContextValue(condominio.condominios.find((condominio) => condominio.id === id_condominio_id));
		},
		[setCondominioContextValue, condominio.condominios]
	);

	const setReloadObjVazio = React.useCallback(() => {
		setReload({});
	}, [setReload]);

	const providerValue = React.useMemo(
		() => ({
			condominios: condominio.condominios,
			condominioSelecionado: condominio.condominioSelecionado,
			setCondominioPorId: setCondominioPorId,
			setCondominio: setCondominioContextValue,
			setReload: setReloadObjVazio,
		}),
		[condominio, setReloadObjVazio, setCondominioContextValue, setCondominioPorId]
	);

	React.useEffect(() => {
		const storedCondominioSelecionado = localStorage.getItem('condominio');
		if (!storedCondominioSelecionado) return;
		try {
			const condominioSelecionado = JSON.parse(storedCondominioSelecionado);
			return setCondominio((_condominio) => ({
				..._condominio,
				condominioSelecionado: condominioSelecionado,
			}));
		} catch {
			return;
		}
	}, []);

	React.useEffect(reloadCondominios, [reload]);

	return <CondominiosContext.Provider value={providerValue}>{children}</CondominiosContext.Provider>;
};
