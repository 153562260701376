import React from 'react';

import { ModalContext, CustomDialogBody } from 'components/common/ModalContext';
import { ButtonClose } from 'components/common/buttons/ButtonClose';
import ListHistMail from './ListHistMail';

export const ModalHistMail = ({ idContaAReceber }) => {
	const { setModalValue } = React.useContext(ModalContext);
	return (
		<CustomDialogBody
			title='Histórico de Envio de E-mails'
			customActions={<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))} />}
			form={{
				component: <ListHistMail {...{ idContaAReceber }} />,
				valid: true,
			}}
		/>
	);
};

export default ModalHistMail;
