import React from 'react';

import { Box } from '@material-ui/core';

import { CadastrarDadosIntegracaoAPIFileField } from './CadastrarDadosIntegracaoAPIFileField';

const permitedFileExtensionChave = ['pem', 'p12', 'key'];

export const ChaveAPIFileField = ({ PermitedFileExtension = permitedFileExtensionChave }) => (
	<Box>
		<CadastrarDadosIntegracaoAPIFileField
			label='Chave da API'
			source='chave_api'
			PermitedFileExtension={PermitedFileExtension}
		/>
	</Box>
);
