import React, { useContext } from 'react';

import { BotaoSalvar } from '../../common/buttons/BotaoSalvar';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { CustomDialogBodySizeEditable } from '../../common/ModalSizeEditableContext';

import { LancamentoContaPagarContext, LancamentoContaPagarContextProvider } from './LancamentoContaPagarContext';
import FormNovaContaAPagar from './FormNovaContaAPagar';

const CustomActions = () => {
	const { closeModal, handleSave } = useContext(LancamentoContaPagarContext);
	const [loading, setLoading] = React.useState(false);

	return (
		<>
			<ButtonCancel onClick={closeModal} />
			<BotaoSalvar disabled={loading} onClick={() => handleSave(loading, setLoading)} />
		</>
	);
};

const ModalLancamentoContaAPagar = ({ context }) => {
	return (
		<LancamentoContaPagarContextProvider context={context}>
			<CustomDialogBodySizeEditable
				title='Nova Conta a Pagar'
				form={{ component: <FormNovaContaAPagar /> }}
				customActions={<CustomActions />}
			/>
		</LancamentoContaPagarContextProvider>
	);
};

export default ModalLancamentoContaAPagar;
