import React from 'react';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';
import { NumberFormatBRL } from 'components/common/CurrencyInput';
import { BotaoProximo } from 'components/common/buttons/BotaoProximo';
import NovaLeituraContext from './NovaLeituraContext';
import SelectLeiturasParaCalculo from './SelectLeiturasParaCalculo';

const sanitizeListNumberFormatBRLProps: SanitizeListNumberFormatBRLProps = {
	decimalScale: 2,
	suffix: '%',
};

const ModalPercentualLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setModalStep, setDadosNovaLeitura } = React.useContext(NovaLeituraContext);
	const [percentual, setPercentual] = React.useState(dadosNovaLeitura.dados_config.percentual || 0);
	const [leituraParaCalculoId, setLeituraParaCalculoId] = React.useState(
		(dadosNovaLeitura.dados_config.leituras_para_calculo_ids || [])[0] ?? null
	);

	const handleBack = React.useCallback(() => {
		setModalStep('4');
	}, []);
	const handleNext = React.useCallback(() => {
		setDadosNovaLeitura((v: TDadosNovaLeituraConfig): TDadosNovaLeituraConfig => {
			v.dados_config['leituras_para_calculo_ids'] = [leituraParaCalculoId];
			v.dados_config['percentual'] = percentual;
			return v;
		});
		setModalStep('3RP');
	}, [leituraParaCalculoId, percentual]);

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Percentual e Conta'
			form={{
				component: (
					<>
						<TextField
							fullWidth
							name='percentual'
							id='percentual'
							label='Percentual %'
							value={percentual}
							style={{ marginTop: '8px' }}
							margin='dense'
							onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
								let parsedValue = Number(value);
								if (!parsedValue) parsedValue = 0;
								if (parsedValue > 999.99) parsedValue = 999.99;
								setPercentual(parsedValue);
							}}
							inputProps={sanitizeListNumberFormatBRLProps}
							InputProps={{
								inputComponent: NumberFormatBRL,
							}}
						/>
						<SelectLeiturasParaCalculo {...{ leituraParaCalculoId, setLeituraParaCalculoId }} />
					</>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo onClick={handleNext} disabled={!leituraParaCalculoId} icon={<NavigateNextIcon />} />
				</>
			}
		/>
	);
};
export default ModalPercentualLeitura;
