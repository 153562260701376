import React from 'react';

import { Box, Button } from '@material-ui/core';

const BoxButton = ({
	text,
	icon,
	disabled,
	display,
	displayButton = 'flex',
	flexDirection = 'column',
	alignItems = 'center',
	justifyContent = 'center',
	height = '100px',
	width = '100px',
	gridGap = '10px',
	...rest
}) => (
	<Button variant='outlined' color='primary' disabled={disabled} style={{ display }} {...rest}>
		<Box
			display={displayButton}
			flexDirection={flexDirection}
			alignItems={alignItems}
			justifyContent={justifyContent}
			height={height}
			width={width}
			gridGap={gridGap}
		>
			{icon}
			<span>{text}</span>
		</Box>
	</Button>
);

export default BoxButton;
