import React, { useCallback, useContext, useEffect } from 'react';

import { parse } from 'date-fns';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { SimplesSyndikosSelect } from '../../../common/SyndikosSelect';
import { LancamentoContaPagarContext } from '../LancamentoContaPagarContext';
import { FormParcelamentoContext } from './FormParcelamentoContext';

const SmallRadio = (props) => <Radio {...props} size='small' />;

const CustomTypography = ({ disabled, ...props }) => (
	<Typography {...props} {...(disabled ? { color: 'textSecondary' } : {})} />
);

const SmallTypography = ({ style, ...props }) => (
	<CustomTypography {...props} style={{ ...style, 'font-size': 'small' }} />
);

const LargeTypography = ({ style, ...props }) => (
	<CustomTypography {...props} style={{ ...style, 'font-size': 'large' }} />
);

const BoxLeftCenter = (props) => <Box {...props} align='left' alignSelf='center' />;

const BoxRightCenter = (props) => <Box {...props} align='right' alignSelf='center' />;

const NumberTextField = (props) => <TextField size='small' margin='dense' {...props} type='number' />;

const QuantidadeParcelasField = ({ disabled }) => {
	const { parcelasPagas } = useContext(LancamentoContaPagarContext);
	const { quantidadeParcelasToken, setQuantidadeParcelasToken, maxQuantidadeParcelas, erros, setErros } =
		useContext(FormParcelamentoContext);

	const validate = () => {
		if (typeof quantidadeParcelasToken !== 'number' && !quantidadeParcelasToken) {
			setErros((e) => ({
				...e,
				quantidade_parcelas: 'Forneça a quantidade de parcelas',
			}));
		} else {
			if (quantidadeParcelasToken > maxQuantidadeParcelas) {
				setErros((e) => ({
					...e,
					quantidade_parcelas_msg: 'O valor mínimo de cada parcela não pode se inferior a R$ 0,01',
				}));
			} else if (erros?.quantidade_parcelas_msg) {
				setErros((e) => {
					const { quantidade_parcelas_msg, ...rest } = e;
					return rest;
				});
			}
			if (quantidadeParcelasToken > 120) {
				setErros((e) => ({
					...e,
					quantidade_parcelas: 'A quantidade de parcelas não pode exceder 120',
				}));
			} else if (erros?.quantidade_parcelas) {
				setErros((e) => {
					const { quantidade_parcelas, ...rest } = e;
					return rest;
				});
			}
		}
	};
	useEffect(validate, [quantidadeParcelasToken]);
	return (
		<NumberTextField
			value={quantidadeParcelasToken || 1}
			onChange={(event) => {
				let newValue = parseInt(event?.target?.value);
				if (!parcelasPagas.length || parcelasPagas.length + 1 <= newValue)
					setQuantidadeParcelasToken(isNaN(newValue) || newValue < 1 ? 1 : newValue);
			}}
			inputProps={{
				style: { fontSize: 'small' },
			}}
			error={!!(!disabled && erros?.quantidade_parcelas)}
			helperText={!disabled ? erros?.quantidade_parcelas : undefined}
			disabled={disabled}
		/>
	);
};

const FormControlLabelParcelamento = ({
	textoDiretaVencimentoEm = '',
	flexBoxDiretaVencimentoEm = 1,
	terTextoEsquerdaVencimentoEm = true,
	textoEsquerdaVencimentoEm = '',
	VencimentoEmField = <></>,
	textoDiretaQuantidadeParcelas = '',
	textoEsquerdaQuantidadeParcelas = '',
	disabled = true,
	ml,
	...props
}) => (
	<Box {...(ml ? { ml } : {})}>
		<Box display='flex'>
			<FormControlLabel {...props} />
		</Box>
		<Box display='flex'>
			<BoxRightCenter flex={flexBoxDiretaVencimentoEm} mr='1em'>
				<SmallTypography disabled={disabled}>{textoDiretaVencimentoEm}</SmallTypography>
			</BoxRightCenter>
			<BoxRightCenter flex={1} mr='1em'>
				{VencimentoEmField}
			</BoxRightCenter>
			{terTextoEsquerdaVencimentoEm && (
				<BoxLeftCenter flex={1}>
					<SmallTypography disabled={disabled}>{textoEsquerdaVencimentoEm}</SmallTypography>
				</BoxLeftCenter>
			)}
		</Box>
		<Box display='flex'>
			<BoxRightCenter flex={1} mr='1em'>
				<SmallTypography disabled={disabled}>{textoDiretaQuantidadeParcelas}</SmallTypography>
			</BoxRightCenter>
			<BoxRightCenter flex={1} mr='1em'>
				<QuantidadeParcelasField disabled={disabled} />
			</BoxRightCenter>
			<BoxLeftCenter flex={1}>
				<SmallTypography disabled={disabled}>{textoEsquerdaQuantidadeParcelas}</SmallTypography>
			</BoxLeftCenter>
		</Box>
	</Box>
);

const ParcelamentoMesDiasField = ({ disabled, value }) => {
	const { dataVencimentoToken, setDadosCalculoVencimentoToken } = useContext(FormParcelamentoContext);

	const updateDia = useCallback(() => {
		const dateVencimentoToken = parse(dataVencimentoToken, 'dd/MM/yyyy', new Date());
		if (dateVencimentoToken.toString() !== 'Invalid Date') {
			if (dateVencimentoToken.getDate() !== value)
				setDadosCalculoVencimentoToken((v) => ({
					...v,
					dia: dateVencimentoToken.getDate(),
				}));
		}
	}, [dataVencimentoToken, value, setDadosCalculoVencimentoToken]);
	useEffect(updateDia, [dataVencimentoToken]);
	return (
		<NumberTextField
			size='small'
			inputProps={{
				style: { fontSize: 'small' },
			}}
			{...{ disabled, value }}
			onChange={(event) => {
				let newValue = parseInt(event?.target?.value);
				newValue = isNaN(newValue) || newValue < 1 ? 1 : newValue;
				newValue = newValue > 31 ? 31 : newValue;
				setDadosCalculoVencimentoToken((v) => ({
					...v,
					dia: newValue,
				}));
			}}
		/>
	);
};

const choicesDiasSemana = [
	{ id: 1, nome: 'Domingo' },
	{ id: 2, nome: 'Segunda-feira' },
	{ id: 3, nome: 'Terça-feira' },
	{ id: 4, nome: 'Quarta-feira' },
	{ id: 5, nome: 'Quinta-feira' },
	{ id: 6, nome: 'Sexta-feira' },
	{ id: 7, nome: 'Sábado' },
];

const TipoParcelamentoField = () => {
	const {
		tipoParcelamentoToken,
		setTipoParcelamentoToken,
		dadosCalculoVencimentoToken,
		setDadosCalculoVencimentoToken,
		quantidadeParcelasToken,
	} = useContext(FormParcelamentoContext);
	return (
		<FormControl component='fieldset' fullWidth>
			<RadioGroup
				aria-label='tipoParcelamento'
				name='tipoParcelamento'
				value={tipoParcelamentoToken}
				onChange={(_, newValue) => {
					setTipoParcelamentoToken(newValue);
				}}
				row
			>
				<Box display='flex' width='100%'>
					<Box flex={1}>
						<FormControlLabelParcelamento
							value='M'
							control={<SmallRadio />}
							label={<LargeTypography>Parcelas Mensais</LargeTypography>}
							textoDiretaVencimentoEm='Vencimento todo dia'
							VencimentoEmField={
								<ParcelamentoMesDiasField
									value={dadosCalculoVencimentoToken?.dia || 1}
									disabled={tipoParcelamentoToken !== 'M'}
								/>
							}
							textoDiretaQuantidadeParcelas='por'
							textoEsquerdaQuantidadeParcelas={quantidadeParcelasToken > 1 ? 'meses' : 'mês'}
							disabled={tipoParcelamentoToken !== 'M'}
						/>
					</Box>
					<Box flex={1} borderLeft={1} borderColor='grey.500'>
						<FormControlLabelParcelamento
							value='S'
							control={<SmallRadio />}
							label={<LargeTypography>Parcelas Semanais</LargeTypography>}
							textoDiretaVencimentoEm='Repetir sempre'
							VencimentoEmField={
								<SimplesSyndikosSelect
									variant='outlined'
									size='small'
									optionText='nome'
									value={
										choicesDiasSemana.find(
											(c) => c.id === dadosCalculoVencimentoToken?.dia_semana
										) || choicesDiasSemana[0]
									}
									choices={choicesDiasSemana}
									getOptionSelected={(option, newValue) => option.id === newValue?.id}
									onChange={(newValueID) => {
										setDadosCalculoVencimentoToken((v) => ({
											...v,
											dia_semana: newValueID,
										}));
									}}
									disableClearable={true}
									style={{ marginTop: '0px' }}
									inputProps={{
										style: { fontSize: 'small' },
									}}
									touched={true}
									disabled={tipoParcelamentoToken !== 'S'}
									fullWidth
								/>
							}
							textoDiretaQuantidadeParcelas='por'
							textoEsquerdaQuantidadeParcelas={quantidadeParcelasToken > 1 ? 'semanas' : 'semana'}
							disabled={tipoParcelamentoToken !== 'S'}
							ml='1em'
						/>
					</Box>
					<Box flex={1} borderLeft={1} borderColor='grey.500'>
						<FormControlLabelParcelamento
							value='E'
							control={<SmallRadio />}
							label={<LargeTypography>Parcelas por um Período Específico</LargeTypography>}
							textoDiretaVencimentoEm='Repetir a cada'
							VencimentoEmField={
								<NumberTextField
									size='small'
									value={dadosCalculoVencimentoToken?.dias || 1}
									onChange={(event) => {
										let newValue = parseInt(event?.target?.value);
										setDadosCalculoVencimentoToken((v) => ({
											...v,
											dias: isNaN(newValue) || newValue < 1 ? 1 : newValue,
										}));
									}}
									inputProps={{
										style: { fontSize: 'small' },
									}}
									disabled={tipoParcelamentoToken !== 'E'}
								/>
							}
							textoEsquerdaVencimentoEm={dadosCalculoVencimentoToken?.dias > 1 ? 'dias' : 'dia'}
							textoDiretaQuantidadeParcelas='por'
							textoEsquerdaQuantidadeParcelas={quantidadeParcelasToken > 1 ? 'vezes' : 'vez'}
							disabled={tipoParcelamentoToken !== 'E'}
							ml='1em'
						/>
					</Box>
				</Box>
			</RadioGroup>
		</FormControl>
	);
};

const BoxDadosCalculoVencimento = () => (
	<Box display='flex' marginTop='1em'>
		<TipoParcelamentoField />
	</Box>
);

export default BoxDadosCalculoVencimento;
