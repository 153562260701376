import React from 'react';

import { Box } from '@material-ui/core';

import { CadastrarDadosIntegracaoAPITextField } from './CadastrarDadosIntegracaoAPITextField';

interface ChaveAPIFieldProps {
	source?: string;
	label?: string;
	inputProps?: Record<string, any>;
}

export const ChaveAPIField: React.FC<ChaveAPIFieldProps> = ({
	source = 'chave_api',
	label = 'Senha da Chave da API',
	inputProps,
}) => (
	<Box>
		<CadastrarDadosIntegracaoAPITextField label={label} source={source} inputProps={inputProps} />
	</Box>
);
