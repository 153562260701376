import {
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	getFormatadorPayloadCobrancaConfig,
} from '../../../default/ContaCorrenteProps';

import formatadaPayloadDependenteDoTipoIntegracaoId from './formatadaPayloadDependenteDoTipoIntegracaoId';
import formataPayloadValuesTabInstrucoesCobrancaRemessaPadrao from './formataPayloadValuesTabInstrucoesCobrancaRemessaPadrao';

const formatadoresPayloadValues = {
	...defaultFormatadoresPayloadValues,
	cobranca_config: getFormatadorPayloadCobrancaConfig({
		formatadores: [
			...defaultFormatadoresPayloadValuesCobrancaConfig,

			// Dados de Tab Conta
			formataPayloadValuesTabContaCobrancaConfig,

			// Dados de Tab Instruções de Cobrança Remessa
			formataPayloadValuesTabInstrucoesCobrancaRemessaPadrao,
			formatadaPayloadDependenteDoTipoIntegracaoId,
		],
	}),
};

export default formatadoresPayloadValues;
