import { createTheme } from '@material-ui/core/styles';

export const COLORS_LIGHT = {
	primary: {
		100: '#565B82',
		150: '#414562',
		200: '#2F345D',
		300: '#191C36',
	},
	secondary: {
		100: '#727AA8',
		200: '#464f83',
		300: '#2c335c',
	},
	fundo: {
		100: '#E6E7E8',
	},
	fundoMenu: {
		100: '#FFFFFF',
		200: '#B6BCC2',
		300: '#8a939c',
	},
	labelSelectGestao: {
		100: '#eaecf7',
	},
	border: {
		50: '#c4c4c4',
		100: '#dedede',
		200: '#c4c4c4',
	},
	font: { 100: '#262b2b', 200: '#585e6b', 300: '#BDBDBD', 400: '#fff', 500: '#26282B' },
	yellow: {
		100: '#FFEB3B',
		200: '#FFC107',
		300: '#FF9800',
	},
	blue: {
		50: '#FFF0EB',
		100: '#4aafff',
		200: '#2196f3',
		300: '#127acc',
		400: '#0f69ae',
		500: '#3f51b5',
	},
	red: {
		100: '#f06160',
		200: '#CA3433',
		300: '#a32221',
	},
	green: {
		100: '#50cc68',
		200: '#28a741',
		300: '#19902d',
	},
	orange: {
		50: '#EBF5FC',
		100: '#ff9a1a',
		200: '#ec7806',
		300: '#d35900',
	},
	inputComunicado: {
		100: '#fff',
	},
	black: { 500: ' #000000' },
};

export const LIGHT_THEME = createTheme({
	...COLORS_LIGHT,
	palette: {
		syndikosRed: {
			contrastText: '#fff',
			main: '#CA3433',
			dark: '#a33231',
		},
	},
	props: {
		MuiTextField: {
			variant: 'outlined',
		},
		MuiButtonBase: {
			disableRipple: false,
		},
	},
	overrides: {
		//React Admin
		RaLayout: {
			content: {
				zIndex: 1,
				paddingLeft: '18px !important',
				padding: 18,
				marginLeft: '6px',
				backgroundColor: COLORS_LIGHT.fundo[100],
				boxShadow:
					'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
			},
			contentWithSidebar: {
				display: 'flex',
				flexGrow: 1,
				backgroundColor: COLORS_LIGHT.fundo[100],
			},
		},
		RaAppBar: {
			toolbar: {
				backgroundColor: COLORS_LIGHT.primary[200],
			},
		},
		RaDatagrid: {
			headerCell: {
				backgroundColor: COLORS_LIGHT.fundoMenu[100],
			},
		},
		RaSidebar: {
			drawerPaper: {
				backgroundColor: COLORS_LIGHT.fundoMenu[100],
			},
		},
		RaMenuItemLink: {
			root: {
				color: COLORS_LIGHT.font[100],
			},
			active: {
				color: COLORS_LIGHT.secondary[200],
				'& svg': {
					fill: `${COLORS_LIGHT.secondary[200]}!important`,
				},
			},
		},
		RaToolbar: { toolbar: { backgroundColor: COLORS_LIGHT.fundoMenu[100] } },
		// ! React Admin

		// Material UI
		MuiDropzoneArea: {
			root: {
				backgroundColor: COLORS_LIGHT.fundoMenu[100],
			},
			icon: {
				color: COLORS_LIGHT.font[100],
			},
		},
		MuiDropzonePreviewList: {
			image: {
				color: COLORS_LIGHT.font[100],
			},
		},
		MuiOutlinedInput: {
			root: {
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					borderColor: COLORS_LIGHT.primary[300],
				},
				'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
					borderColor: COLORS_LIGHT.font[300],
				},
			},
			notchedOutline: {
				borderColor: 'rgb(192, 192, 192)',
			},
		},
		MuiPickersDay: { day: { color: 'rgba(0, 0, 0, 0.87)' } },
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: 'rgba(0, 0, 0, 0.38)',
			},
			iconButton: {
				backgroundColor: '#fff',
			},
		},
		MuiChip: {
			outlinedPrimary: {
				color: COLORS_LIGHT.primary[200],
				border: `1px solid ${COLORS_LIGHT.primary[200]}`,
			},
		},
		MuiRadio: {
			root: {
				color: 'inherit',
			},
			colorPrimary: {
				'&.Mui-disabled': {
					color: COLORS_LIGHT.font[300],
				},
			},
			colorSecondary: {
				'&.Mui-checked': {
					color: COLORS_LIGHT.primary[200],
				},
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				'&.Mui-checked': {
					color: COLORS_LIGHT.primary[200],
				},
				'& span': {
					color: COLORS_LIGHT.font[100],
				},
			},
			colorPrimary: {
				'&.Mui-checked': {
					color: COLORS_LIGHT.primary[200],
				},
				'& span': {
					color: COLORS_LIGHT.font[100],
				},
			},
		},
		MuiFormControlLabel: {
			label: {
				'&.Mui-disabled': {
					color: COLORS_LIGHT.font[300],
				},
			},
		},
		MuiFormHelperText: {
			root: {
				color: COLORS_LIGHT.font[200],
			},
		},
		MuiListItemIcon: {
			root: {
				color: COLORS_LIGHT.font[200],
			},
		},
		MuiTypography: {
			root: {
				color: COLORS_LIGHT.font[100],
			},
			colorPrimary: {
				color: COLORS_LIGHT.primary[100],
			},
			colorTextPrimary: {
				color: COLORS_LIGHT.font[100],
			},
			colorTextSecondary: {
				color: COLORS_LIGHT.primary[100],
			},
		},
		MuiTablePagination: {
			root: {
				color: COLORS_LIGHT.font[100],
			},
			selectIcon: {
				color: COLORS_LIGHT.font[100],
			},
		},
		MuiTableCell: {
			root: {
				backgroundColor: `${COLORS_LIGHT.fundoMenu[100]}!important`,
				borderBottom: `1px solid ${COLORS_LIGHT.border[100]}`,
			},
			head: {
				color: COLORS_LIGHT.font[100],
			},
			body: {
				color: COLORS_LIGHT.font[100],
			},
		},
		MuiStepLabel: {
			label: {
				color: COLORS_LIGHT.font[200],
			},
			active: {
				color: `${COLORS_LIGHT.primary[100]}!important`,
			},
			completed: {
				color: `${COLORS_LIGHT.primary[100]}!important`,
			},
		},
		MuiStepIcon: {
			root: { color: COLORS_LIGHT.font[300] },
			completed: {
				color: `${COLORS_LIGHT.primary[100]}!important`,
			},
			active: {
				color: `${COLORS_LIGHT.primary[100]}!important`,
			},
		},
		MuiTableSortLabel: {
			root: {
				'&:hover': {
					color: COLORS_LIGHT.font[200],
				},
				'&.MuiTableSortLabel-active': {
					color: COLORS_LIGHT.font[200],
				},
			},
			icon: { color: `${COLORS_LIGHT.font[200]}!important` },
		},
		MuiInputBase: {
			root: {
				color: COLORS_LIGHT.font[100],
				'&.Mui-disabled': { color: COLORS_LIGHT.font[300] },
			},
		},
		MuiIconButton: {
			root: {
				color: COLORS_LIGHT.font[200],
			},
			colorSecondary: {
				'&:hover': { backgroundColor: 'rgba(255, 255, 255, .2)' },
			},
			sizeSmall: {
				padding: '7px 7px 12px 7px',
			},
		},
		MuiButton: {
			textPrimary: {
				color: COLORS_LIGHT.primary[100],
			},
			root: {
				color: COLORS_LIGHT.primary[100],
				'&.Mui-disabled': {
					color: COLORS_LIGHT.font[300],
				},
			},
			containedPrimary: {
				backgroundColor: COLORS_LIGHT.primary[100],
				'&:hover': {
					backgroundColor: COLORS_LIGHT.primary[150],
				},
			},
			containedSecondary: {
				backgroundColor: COLORS_LIGHT.blue[300],
				'&:hover': {
					backgroundColor: COLORS_LIGHT.blue[400],
				},
			},
			outlinedPrimary: {
				color: COLORS_LIGHT.blue[500],
			},
		},
		MuiFormLabel: {
			root: {
				color: COLORS_LIGHT.font[200],
				'&.Mui-focused': {
					color: COLORS_LIGHT.primary[300],
				},
				'&.Mui-disabled': { color: COLORS_LIGHT.font[300] },
			},
		},
		MuiPaper: {
			root: {
				backgroundColor: COLORS_LIGHT.fundoMenu[100],
				color: COLORS_LIGHT.font[100],
			},
		},
		MUIRichTextEditor: {
			root: {
				flexWrap: 'wrap',
				backgroundColor: COLORS_LIGHT.fundo[200],
				color: COLORS_LIGHT.font[100],
				'& blockquote span': {
					color: COLORS_LIGHT.font[100],
				},
				'& div[class^="CodeBlock"]': {
					'background-color': COLORS_LIGHT.fundoMenu[300],
				},
				'& pre span': {
					color: COLORS_LIGHT.font[100],
				},
			},
			editor: {
				'font-size': '19px',
				'line-height': '1.4',
			},
			toolbar: {
				'border-bottom': '1px solid black',
			},
			container: {
				margin: '0px',
				padding: '8px 10px 0px 10px',
			},
			editorContainer: {
				margin: '0px 0px 0px 0px',
				padding: '35px 0px 35px 0px',
			},
			placeHolder: {
				position: 'initial',
			},
			labelInputEmail: {
				backgroundColor: `${COLORS_LIGHT.fundoMenu[100]}`,
			},
		},
		// ! Material UI
		PrivateTabIndicator: {
			colorSecondary: { backgroundColor: COLORS_LIGHT.primary[100] },
		},
	},
});
