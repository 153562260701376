import React from 'react';
import { TextInput, ArrayInput, BooleanInput, required } from 'react-admin';

import { nomeParser, nomeValidacao } from '../../../fieldControl/nome';
import { validacaoEmailObrigatorio, emailValidacao } from '../../../fieldControl/email';
import { telefoneValidacao, telefoneFormatacao, telefoneParser } from '../../../fieldControl/telefone';
import { Warning, Help } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { FormularioBox, BoxDivisor } from '../../common/Formulario';
import { EnderecoBox } from '../../common/Endereco';
import { Box, Typography, Tooltip, TextField } from '@material-ui/core';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { CustomRemoveButton, ModalConfirmacao } from '../../common/buttons/BotaoRemover';
import { CustomDialogBody } from '../../common/ModalContext';
import { ButtonClose } from '../../common/buttons/ButtonClose';
import { ButtonRemove } from '../../common/buttons/ButtonRemove';
import { Field, useForm, useFormState } from 'react-final-form';
import { CustomFormIterator } from 'components/common/CustomFormIterator';
import { tipoValidacao } from 'fieldControl/tipoPessoa';
import { TipoInput, CPFInput, CNPJInput } from '../../common/InputDocumento';

const opcoesTipoTelefone = [
	{ id: 'M', name: 'Celular' },
	{ id: 'R', name: 'Residencial' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'P', name: 'Principal' },
	{ id: 'O', name: 'Outros' },
];

const opcoesTipoEmail = [
	{ id: 'P', name: 'Pessoal' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'CB', name: 'Cobrança' },
	{ id: 'O', name: 'Outros' },
];

const ModalFeedbackBloqueioRemover = ({ setModalValue }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBody
			title='Esta Imobiliária não pode ser removida'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<Warning style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>
							<ul>
								<>
									<li>Esta Imobiliária está vinculada a ao mínimo uma unidade</li>
								</>
							</ul>
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
						}}
						size='small'
					>
						cancelar
					</ButtonClose>
					<ButtonRemove disabled={true} size='small' />
				</>
			}
		/>
	);
};

const BoxObservacoes = (props) => {
	return (
		<BoxDivisor titulo='Observação'>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<Field name='observacao' id='observacao' disabled={props.record.situacao === 'I'}>
						{({ input, meta }) => (
							<TextInput
								source='observacao'
								label='Anotações'
								placeholder='Use este espaço para anotações referentes a esta imobiliária'
								variant='outlined'
								validate={(value) =>
									value ? (value.length > 500 ? [`Máximo excedido: ${value.length}/500`] : '') : ''
								}
								helperText={meta?.error ? meta?.error : `${(input?.value || '').length}/500`}
								rows={8}
								multiline
								fullWidth
							/>
						)}
					</Field>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

const composeValidators =
	(...validators) =>
	(value) =>
		validators.reduce((error, validator) => error || validator(value), undefined);

const EmailInput = ({ name, disabled, label, fullWidth = false, validate = [] }) => {
	const { change } = useForm();
	return (
		<Field name={name} disabled={disabled} fullWidth={fullWidth} validate={composeValidators(...validate)}>
			{({ input, meta }) => (
				<TextField
					value={input.value}
					name={input.name}
					label={label}
					error={!!meta.error}
					helperText={meta.error?.message}
					onChange={(event) => {
						input.onChange(event);
						if (!event.target.value) change('acesso_app', false);
					}}
					disabled={disabled}
					fullWidth={fullWidth}
					size='small'
				/>
			)}
		</Field>
	);
};

const InputDocumentoCustom = ({
	resource,
	variant,
	className = undefined,
	sourceTipo,
	source,
	disabled,
	fullWidth,
	customQueryValidation = undefined,
	validate = tipoValidacao,
	br = true,
	flexDirection = 'column',
	...rest
}) => {
	const { values, initialValues } = useFormState();
	const { change } = useForm();

	const propsBaseadasEmFlexDirection = flexDirection === 'row' ? { gridColumnGap: '1rem' } : {};

	return (
		<Field name={source}>
			{({ input }) => (
				<Box display='flex' flexDirection={flexDirection} width='100%' {...propsBaseadasEmFlexDirection}>
					<Box flex={1}>
						<TipoInput
							{...{
								resource,
								variant,
								className,
								sourceTipo,
								disabled,
								fullWidth,
								validate,
							}}
							{...rest}
						/>
					</Box>
					{br && <br />}
					<Box flex={1} display='flex'>
						{values[sourceTipo] === 'F' ? (
							<CPFInput
								{...{
									resource,
									variant,
									className,
									initialValues,
									source,
									disabled,
									fullWidth,
									customQueryValidation,
								}}
								onChange={(event) => {
									input.onChange(event);
									if (!event.target.value) change('acesso_app', false);
								}}
							/>
						) : (
							<CNPJInput
								resource='condominios'
								source={source}
								variant='outlined'
								fullWidth
								customQueryValidation={`tipo_condominio=${
									values?.tipo_condominio || initialValues?.tipo_condominio
								}&cnpj`}
								onChange={(event) => {
									input.onChange(event);
									if (!event.target.value) change('acesso_app', false);
								}}
							/>
						)}
					</Box>
				</Box>
			)}
		</Field>
	);
};

const AcessoAppField = (props) => {
	const {
		values: { email_principal, documento },
	} = useFormState();
	return (
		<Box display='flex'>
			<BooleanInput
				source='acesso_app'
				label='Liberar acesso da Imobiliária no APP'
				disabled={props.record.situacao === 'I' || !email_principal || !documento}
			/>
			<Tooltip title={'Esta opção estará desabilitada caso não tenha informado CPF/CNPJ e E-mail Principal'}>
				<Help style={{ color: 'grey', marginTop: '0.8%', marginLeft: '-1%' }} />
			</Tooltip>
		</Box>
	);
};

export const ImobiliariaFormulario = (props) => {
	return (
		<FormularioBox
			{...props}
			onClickBotaoRemover={(_, setModalValue) => {
				if (props.record?.usado_em_unidades) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <ModalFeedbackBloqueioRemover setModalValue={setModalValue} />,
					}));
				} else {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalConfirmacao
								{...props}
								record={props.record}
								confirmTitle={`Tem certeza que deseja remover ${props.record.nome}?`}
								setModalValue={setModalValue}
							/>
						),
					}));
				}
			}}
		>
			<Box flex={1} mr='1em'>
				<Box display='flex'>
					<Box flex={1} m='1em'>
						<Typography variant='h5' gutterBottom>
							Dados Cadastrais
						</Typography>

						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>

						<Box display='flex'>
							<Box flex={1} mr='0.5em'>
								<TextInput
									source='nome'
									validate={nomeValidacao}
									parse={nomeParser}
									disabled={props.record.situacao === 'I'}
									variant='outlined'
									fullWidth
								/>
							</Box>
						</Box>
						<Box display='flex' mr='0.5em' gridGap='0.5em'>
							<InputDocumentoCustom
								source='documento'
								sourceTipo='tipo_imobiliaria'
								disabled={props.record.situacao === 'I'}
								variant='outlined'
								fullWidth
							/>
						</Box>
						<Box display='flex' mr='0.5em' gridGap='0.5em' marginBottom={3}>
							<EmailInput
								name='email_principal'
								disabled={props.record.situacao === 'I'}
								label='E-mail Principal'
								fullWidth
								validate={emailValidacao}
							/>
						</Box>
						<Box>
							<BooleanInput
								source='enviar_boleto_imobiliaria'
								label='Enviar cópia de boletos de inquilinos vinculados'
								disabled={props.record.situacao === 'I'}
							/>
						</Box>
						<Box>
							<AcessoAppField {...props} />
						</Box>
					</Box>
				</Box>
				<Box mt='1em' />
				<Box m='1em'>
					<EnderecoBox labelTitle='Dados de Endereçamento' {...props} />
				</Box>
			</Box>

			<Box flex={1}>
				<Box display='flex'>
					<Box flex={1} m='1em'>
						<Typography variant='h5' gutterBottom>
							Contatos
						</Typography>

						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						/>
						<Box display='flex'>
							<Box flex={0.5} ml='1em'>
								<ArrayInput source='telefoneimobiliaria_set' label='Telefones' variant='outlined'>
									<CustomFormIterator removeButton={<CustomRemoveButton />}>
										<SyndikosSelect
											source='tipo'
											label='Tipo de Telefone'
											choices={opcoesTipoTelefone}
											disabled={props.record.situacao === 'I'}
											validate={required()}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='numero'
											format={telefoneFormatacao}
											parse={telefoneParser}
											validate={[required(), telefoneValidacao]}
											label='Número'
											disabled={props.record.situacao === 'I'}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
							</Box>
							<Box flex={0.5} ml='1em'>
								<ArrayInput source='emailimobiliaria_set' label='Emails adicionais' variant='outlined'>
									<CustomFormIterator removeButton={<CustomRemoveButton />}>
										<SyndikosSelect
											source='tipo'
											label='Tipo de Email'
											choices={opcoesTipoEmail}
											validate={required()}
											disabled={props.record.situacao === 'I'}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='email'
											label='Email'
											validate={validacaoEmailObrigatorio}
											disabled={props.record.situacao === 'I'}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
							</Box>
						</Box>
					</Box>
				</Box>
				<BoxObservacoes {...props} />
			</Box>
		</FormularioBox>
	);
};
