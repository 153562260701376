import React from 'react';
import ConsultaConvitesEnviadosList from './ConsultaConvitesEnviados';
import ConsultaRelatorioList from './ConsultaRelatorios';
import { ConsultaReservasDeAmbiente } from './ConsultaReservasDeAmbiente/ConsultaReservasDeAmbiente';
import ConsultaExportacaoContabil from './ConsultaExportacaoContabil';
import { ExportacaoContabilContextProvider } from './ConsultaExportacaoContabil/context/ExportacaoContabilContext';

export const consultasDisponiveis = [
	{ id: 'arquivos', name: 'Boletos e Relatórios Disponibilizados' },
	{ id: 'reservas', name: 'Reservas de Ambiente' },
	{ id: 'convites_kondominos', name: 'Convites do Condômino' },
	{ id: 'movimentacao_contabil', name: 'Movimentação Contábil - Exportação' },
];

const mapComponentConsulta = {
	reservas: <ConsultaReservasDeAmbiente />,
	arquivos: <ConsultaRelatorioList />,
	convites_kondominos: <ConsultaConvitesEnviadosList />,
	movimentacao_contabil: (
		<ExportacaoContabilContextProvider>
			<ConsultaExportacaoContabil />
		</ExportacaoContabilContextProvider>
	),
};

export const getConsultaComponent = (key) => mapComponentConsulta[key] || <></>;
