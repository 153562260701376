import React, { useState, useContext, useEffect } from 'react';
import { CloseButton } from 'components/common/commonComponentsTSX';
import { useGetList } from 'react-admin';
import { FormControlLabel, Checkbox, Box, CircularProgress, Grid } from '@material-ui/core';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RawKBDatePicker } from 'components/common/InputDate';
import { BotaoProximo } from 'components/common/buttons/BotaoProximo';
import { AcordosParcelasFluxoModaisContext } from '../contexts/AcordosParcelasFluxoModaisContext';

const AcordosConfiguracaoModal = ({ setStep }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [condominiosOptions, setCondominiosOptions] = useState([]);
	const [unidadesOptions, setUnidadesOptions] = useState([]);
	const [loadingUnidades, setLoadingUnidades] = useState(false);
	const [unidadeNull, setUnidadeNull] = useState(false);
	const {
		condominioSelecionado,
		setCondominioSelecionado,
		unidadeSelecionada,
		setUnidadeSelecionada,
		dataAcordo,
		setDataAcordo,
		dataEncargos,
		setDataEncargos,
		juros,
		setJuros,
		multa,
		setMulta,
		correcaoMonetaria,
		setCorrecaoMonetaria,
		resetAllStates,
	} = useContext(AcordosParcelasFluxoModaisContext);

	const { data: condominiosData } = useGetList(
		'condominios',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ situacao: 'A' }
	);

	useEffect(() => {
		setCondominiosOptions(Object.values(condominiosData || {}).filter((v) => Boolean(v?.id)));
	}, [condominiosData]);

	useEffect(() => {
		if (condominioSelecionado) {
			setLoadingUnidades(true);
		}
	}, [condominioSelecionado]);

	const {
		data: unidadesData,
		ids: unidadesIds,
		loading: unidadesLoading,
	} = useGetList(
		'unidades',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'unidade' },
		{ id_condominio: condominioSelecionado?.id, apenas_inadimplentes: true },
		{ enabled: !!condominioSelecionado }
	);
	useEffect(() => {
		if (unidadesData) {
			setUnidadesOptions(
				unidadesIds
					.filter((id) => !!id)
					.map((id) => unidadesData[id])
					.sort((a, b) => a?.nome_grupo?.localeCompare(b?.nome_grupo))
			);
			setLoadingUnidades(false);
		}
	}, [unidadesData]);

	useEffect(() => {
		if (unidadesLoading && unidadeNull) {
			setLoadingUnidades(true);
			setUnidadeSelecionada(null);
			setUnidadeNull(true);
		} else {
			setLoadingUnidades(false);
			setUnidadeNull(true);
		}
	}, [unidadesLoading, condominioSelecionado]);

	const handleNextStep = () => {
		setStep('1B');
	};

	const valid = () => {
		if (!unidadeSelecionada || !condominioSelecionado || !dataAcordo) return false;

		return true;
	};
	return (
		<CustomDialogBodySizeEditable
			title={'Configuração Inicial'}
			closeInSubmit={false}
			form={{
				component: (
					<Box px={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Autocomplete
									variant='outlined'
									style={{ marginBottom: '1em' }}
									size='small'
									options={condominiosOptions}
									noOptionsText='Nenhum Condomínio encontrado'
									getOptionLabel={(option) => option.nome || ''}
									value={condominioSelecionado}
									onChange={(e, newValue) => setCondominioSelecionado(newValue)}
									renderInput={(params) => (
										<TextField
											{...params}
											variant='outlined'
											label='Selecionar Condomínio'
											fullWidth
											required
										/>
									)}
									disableClearable
								/>
							</Grid>

							<Grid item xs={12}>
								<Autocomplete
									variant='outlined'
									style={{ marginBottom: '1em' }}
									size='small'
									options={unidadesOptions}
									noOptionsText='Nenhuma Unidade encontrada'
									getOptionLabel={(option) =>
										`${option?.nome_grupo ? `${option?.nome_grupo} - ` : ''}Unidade ${
											option.unidade
										}`
									}
									value={unidadeSelecionada}
									onChange={(e, newValue) => setUnidadeSelecionada(newValue)}
									renderInput={(params) => (
										<TextField
											{...params}
											variant='outlined'
											label='Selecionar Unidade'
											fullWidth
											required
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{loadingUnidades ? (
															<CircularProgress color='inherit' size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									)}
									disableClearable
									disabled={!condominioSelecionado || loadingUnidades}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<RawKBDatePicker
									format='dd/MM/yyyy'
									fullWidth
									margin='dense'
									variant='inline'
									autoOk
									label='Data do Acordo *'
									value={dataAcordo}
									onChange={setDataAcordo}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<RawKBDatePicker
									format='dd/MM/yyyy'
									fullWidth
									margin='dense'
									variant='inline'
									autoOk
									label='Corrigir Encargos Até'
									value={dataEncargos}
									onChange={setDataEncargos}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											checked={correcaoMonetaria}
											onChange={(e) => setCorrecaoMonetaria(e.target.checked)}
										/>
									}
									label='Correção Monetária'
								/>
								<FormControlLabel
									control={<Checkbox checked={juros} onChange={(e) => setJuros(e.target.checked)} />}
									label='Juros'
								/>
								<FormControlLabel
									control={<Checkbox checked={multa} onChange={(e) => setMulta(e.target.checked)} />}
									label='Multa'
								/>
							</Grid>
						</Grid>
					</Box>
				),
			}}
			customActions={
				<Box display='flex' justifyContent='flex-end' gridGap={'1em'} mt={2}>
					<CloseButton
						onClick={() => {
							setModalValue((v) => ({ ...v, open: false }));
							resetAllStates();
							return;
						}}
						size='small'
					>
						Voltar
					</CloseButton>

					<BotaoProximo disabled={!valid()} onClick={handleNextStep} />
				</Box>
			}
		/>
	);
};

export default AcordosConfiguracaoModal;
