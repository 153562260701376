import React from 'react';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { Close, Check } from '@material-ui/icons';
import { Button, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { List, Pagination, TextField, useListContext, useNotify, Confirm, useRefresh } from 'react-admin';
import { Tabela } from 'components/common/Tabela';
import dataProvider from 'dataProvider';
import ModalLancamentosLeiturasAplicativo from './ModalLancamentosLeiturasAplicativo';
import { UserThemeContext } from 'theme/context';
import { DataReferencia } from 'components/common/filtros/Data';
import { Padrao } from 'components/common/filtros/Padrao';

const DateTextFieldReferencia = ({ source, record }) => (
	<span>
		{record[source]
			? new Date(`${record[source]}T10:00`).toLocaleDateString('pt-br', {
					year: 'numeric',
					month: '2-digit',
			  })
			: ''}
	</span>
);

const useAprovar = () => {
	const { data, selectedIds, onUnselectItems } = useListContext();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const refresh = useRefresh();

	const handleAprovar = React.useCallback(() => {
		const idsLancamentosLeiturasSelecionados = selectedIds
			.filter((key) => Object.prototype.hasOwnProperty.call(data, key))
			.map((key) => data[key].ids_lancamentos_leituras)
			.reduce((acc, curr) => acc.concat(curr), []);

		dataProvider
			.updateMany('aprova_leituras_cadastradas_aplicativo', { data: { ids: idsLancamentosLeiturasSelecionados } })
			.then(() => {
				setModalValue((v) => ({ ...v, open: false }));
				notify('Lancamentos de Leituras aprovadas com sucesso', { type: 'success' });
				onUnselectItems();
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao realizar o cadastro.', { type: 'error' });
			});
	}, [data, selectedIds]);

	return handleAprovar;
};

const useReprovar = () => {
	const { data, selectedIds, onUnselectItems } = useListContext();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const refresh = useRefresh();

	const handleReprovar = React.useCallback(() => {
		const idsLancamentosLeiturasSelecionados = selectedIds
			.filter((key) => Object.prototype.hasOwnProperty.call(data, key))
			.map((key) => data[key].ids_lancamentos_leituras)
			.reduce((acc, curr) => acc.concat(curr), []);

		dataProvider
			.updateMany('reprova_leituras_cadastradas_aplicativo', {
				data: { ids: idsLancamentosLeiturasSelecionados },
			})
			.then(() => {
				setModalValue((v) => ({ ...v, open: false }));
				notify('Lancamentos de Leituras reprovadas com sucesso', { type: 'success' });
				onUnselectItems();
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao realizar o cadastro.', { type: 'error' });
			});
	}, [data, selectedIds]);

	return handleReprovar;
};

const BulkActions = () => {
	const [open, setOpen] = React.useState(false);
	const [acao, setAcao] = React.useState(null);

	const handleOpenConfirm = (acao) => {
		setAcao(acao);
		setOpen(true);
	};

	return (
		<div>
			<Button onClick={() => handleOpenConfirm('aprovar')} size='small' variant='text'>
				APROVAR
			</Button>
			<Button onClick={() => handleOpenConfirm('reprovar')} size='small' variant='text'>
				REPROVAR
			</Button>
			<ConfirmarAcao open={open} onOpen={setOpen} acao={acao} />
		</div>
	);
};

const ConfirmarAcao = ({ open, onOpen, acao }) => {
	const { theme } = React.useContext(UserThemeContext);
	const handleAprovar = useAprovar();
	const handleReprovar = useReprovar();

	const handleDialogClose = () => onOpen(false);
	const handleConfirm = () => {
		onOpen(false);
		if (acao === 'aprovar') {
			handleAprovar();
		} else {
			handleReprovar();
		}
	};

	return (
		<Confirm
			isOpen={open}
			title={`Confirmação de ${acao === 'aprovar' ? 'Aprovação' : 'Reprovação'}`}
			content={
				<span style={{ color: theme == 'light' ? '#000000' : '#FFFFFF' }}>
					Esta ação irá {acao} todas as leituras selecionadas
				</span>
			}
			onConfirm={handleConfirm}
			ConfirmIcon={Check}
			onClose={handleDialogClose}
			CancelIcon={Close}
		/>
	);
};

const TabelaLeituras = (props) => {
	const { onUnselectItems } = useListContext();
	const { setModalValue, setMinWidthModel } = React.useContext(ModalSizeEditableContext);

	const handleClose = React.useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);

	React.useEffect(onUnselectItems, [handleClose]);

	return (
		<Tabela
			{...props}
			clickRowCustom={({ record }) =>
				() => {
					setMinWidthModel('80vw');
					setModalValue((v) => ({
						...v,
						dialogBody: <ModalLancamentosLeiturasAplicativo record={record} />,
					}));
				}}
		>
			<TextField source='nome' label='Leituras' filtro={<Padrao />} />
			<DateTextFieldReferencia source='referencia' label='Referência' filtro={<DataReferencia />} />
			<TextField source='situacao_geral' label='Situação' />
		</Tabela>
	);
};

const ModalLeiturasAplicativo = () => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	const handleClose = React.useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);

	return (
		<>
			<DialogTitle>{condominioSelecionado?.apelido} - Leituras Lançadas pelo Aplicativo</DialogTitle>
			<DialogContent>
				<List
					basePath='/leituras_cadastradas_aplicativo'
					resource='leituras_cadastradas_aplicativo'
					title='Leituras'
					sort={{ field: 'referencia', order: 'DESC' }}
					filter={{
						id_condominio: condominioSelecionado.id,
					}}
					bulkActionButtons={<BulkActions />}
					perPage={10}
					exporter={false}
					empty={false}
					pagination={
						<Pagination
							rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
							labelRowsPerPage='Leituras por página'
						/>
					}
					syncWithLocation={false}
				>
					<TabelaLeituras />
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} size='small' startIcon={<Close />} variant='text'>
					SAIR
				</Button>
			</DialogActions>
		</>
	);
};

export default ModalLeiturasAplicativo;
