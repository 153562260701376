import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const TooltipIcon = ({ children, title, ...rest }) => (
	<Tooltip title={title}>
		<span>{children}</span>
	</Tooltip>
);

export default TooltipIcon;
