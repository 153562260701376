import React, { useContext } from 'react';

import TextField from '@material-ui/core/TextField';

import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';

const MetodoCalculoField = () => {
	const { dadosConfig } = useContext(EditaConfiguracaoLeituraContext);
	return (
		<TextField
			disabled
			fullWidth
			label='Método utilizado para o cálculo'
			margin='dense'
			style={{ marginRight: '0.5em' }}
			value={dadosConfig.nome_metodo_calculo}
		/>
	);
};

export default MetodoCalculoField;
