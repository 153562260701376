import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import Box from '@material-ui/core/Box';

import { ThemeProvider, useTheme } from '@material-ui/core/styles';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import { ArrayInput, FormDataConsumer } from 'react-admin';
import SimpleFormIteratorV1 from 'components/common/SimpleFormIteratorV1';
import BoxSelectContaPlano from '../../BoxSelectContaPlano';
import { sanitizeListNumberFormatBRLPropsV2 } from 'components/common/filtros/DecimalV2';
import { NumberFormatBRL } from 'components/common/CurrencyInput';

export const BoxDetalhamentosLancamentoDespesa = ({
	origem,
	valorTotal,
	detalhamentosLancamentosDespesa,
	setDetalhamentosLancamentosDespesa,
	ModalDetailContext,
	disabled = false,
}) => {
	const {
		change,
		mutators: { push },
	} = useForm();

	const {
		palette: { syndikosRed },
	} = useTheme();

	const { values } = useFormState();
	const { detalhamentos_lancamentos_despesa, lancamentoDados } = values;
	const [somaValores, setSomaValores] = React.useState(0);

	React.useEffect(() => {
		setDetalhamentosLancamentosDespesa(detalhamentos_lancamentos_despesa);
	}, [detalhamentos_lancamentos_despesa]);

	React.useEffect(() => {
		const somaDosValores = detalhamentosLancamentosDespesa?.reduce((acum, obj) => {
			return acum + (obj?.valor || 0);
		}, 0);
		setSomaValores(somaDosValores?.toFixed(2));
	}, [detalhamentosLancamentosDespesa]);

	return (
		<ThemeProvider
			theme={(outerTheme) => ({
				...outerTheme,
				overrides: {
					...outerTheme.overrides,
					RaSimpleFormIterator: {
						indexContainer: { display: 'none' },
					},
				},
			})}
		>
			<Box display='flex' flexDirection='column' flex={1}>
				<ArrayInput source='detalhamentos_lancamentos_despesa' label='' style={{ marginTop: '-8px' }}>
					<SimpleFormIteratorV1 disableReordering={true} disableAdd={true} disableRemove={disabled}>
						<FormDataConsumer>
							{({ scopedFormData, getSource }) => (
								<>
									<BoxSelectContaPlano
										style={{ width: '68%' }}
										selectedConta={scopedFormData?.conta_despesa}
										setSelectedConta={(selectedConta) => {
											change(getSource('conta_despesa'), selectedConta);
										}}
										operacao={{ tipo: 'despesa' }}
										disabled={disabled}
										ModalDetailContext={ModalDetailContext}
										fullWidth
									/>
									<TextField
										label='Valor'
										variant='outlined'
										size='small'
										style={{ width: '10.7rem', marginLeft: '6px', marginTop: '6px' }}
										value={scopedFormData?.valor}
										fullWidth
										onChange={(e) => change(getSource('valor'), e.target.value)}
										prefix='R$ '
										decimalScale={2}
										inputProps={sanitizeListNumberFormatBRLPropsV2({})}
										InputProps={{
											inputComponent: NumberFormatBRL,
											startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
										}}
										disabled={disabled}
									/>
								</>
							)}
						</FormDataConsumer>
					</SimpleFormIteratorV1>
				</ArrayInput>
				<div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '36.3rem' }}>
					<Button
						onClick={(e) => {
							e.preventDefault();
							push('detalhamentos_lancamentos_despesa', {
								conta_despesa: undefined,
								valor: undefined,
							});
						}}
						disabled={disabled}
					>
						Adicionar nova Conta
					</Button>
					{detalhamentosLancamentosDespesa?.length > 1 &&
					parseFloat(valorTotal) !== parseFloat(somaValores) &&
					origem === 'EB' ? (
						<p style={{ fontSize: '12px', color: syndikosRed.main, marginTop: '-5px' }}>
							A soma do(s) Valor(es) deve ser igual ao Total.
						</p>
					) : (
						<></>
					)}
				</div>
			</Box>
		</ThemeProvider>
	);
};
