import React, { useState, memo, useEffect } from 'react';

import { Box, TextField } from '@material-ui/core';

import { format, sub, add, parseISO } from 'date-fns';

import { DateRangePicker } from 'materialui-daterange-picker-pt';

const TODAY = new Date().setHours(10, 0, 0);

const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const formatDate = (date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

export const periodoOptions = [
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const PeriodoPicker = memo(({ open, setOpen, setDateRange, minDate = MINDATE }) => {
	const toggle = () => setOpen(!open);

	return (
		<Box
			className='periodo-date-range-picker'
			position='absolute'
			css={
				open
					? {
							zIndex: 3,
							maxHeight: 'calc(90vh - 200px)',
							left: 'calc(50% - 300px)',
					  }
					: { zIndex: -1, opacity: 0, pointerEvents: 'none', display: 'none' }
			}
		>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => setDateRange(range)}
				initialDateRange={periodoOptions[0]}
				definedRanges={periodoOptions}
				minDate={minDate}
			/>
		</Box>
	);
});

export const PeriodoSelect = memo(
	({ dateRange, setDateRange, dataInicial, setDataInicial, dataFinal, setDataFinal, label }) => {
		const [open, setOpen] = useState(false);

		const handleRangeChange = () => {
			setDataInicial(format(dateRange.startDate, 'yyyy-MM-dd'));
			setDataFinal(format(dateRange.endDate, 'yyyy-MM-dd'));
		};

		const init = () => {
			if (dataInicial && dataFinal) {
				setDateRange({
					startDate: parseISO(dataInicial),
					endDate: parseISO(dataFinal),
				});
			}
		};

		useEffect(init, [dataInicial, dataFinal]);
		useEffect(handleRangeChange, [dateRange]);

		return (
			<>
				<TextField
					label={label}
					onClick={() => setOpen(true)}
					fullWidth
					inputProps={{
						value: `De ${formatDate(dateRange?.startDate)} até ${formatDate(dateRange?.endDate)}`,
					}}
					size='small'
				/>
				<PeriodoPicker {...{ open, setOpen, setDateRange }} />
			</>
		);
	}
);
