import { EnqueteList } from './list';
import { EnqueteCreate } from './create';
import { EnqueteEdit } from './edit';

const resource = {
	list: EnqueteList,
	create: EnqueteCreate,
	edit: EnqueteEdit,
};

export default resource;
