import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';

import { Autocomplete } from '@material-ui/lab';

const tiposLancamentosFinanceiros = [
	{ id: 'LR', nome: 'Lançamentos de Receitas' },
	{ id: 'LD', nome: 'Lançamentos de Despesas' },
	{ id: 'T', nome: 'Transferências' },
];

const TiposLancamentosFinanceirosSelect = ({
	tipoLancamentoFinanceiro,
	setTipoLancamentoFinanceiro,
	tipoLancamentoFirstRegistro,
}) => {
	const [options] = useState(
		(tipoLancamentoFirstRegistro === 'R' && tiposLancamentosFinanceiros.filter((t) => t.id !== 'LD')) ||
			(tipoLancamentoFirstRegistro === 'D' && tiposLancamentosFinanceiros.filter((t) => t.id !== 'LR')) ||
			tiposLancamentosFinanceiros
	);
	const [option, setOption] = useState(
		tiposLancamentosFinanceiros.find((tipo) => tipo.id === tipoLancamentoFinanceiro) ||
			tiposLancamentosFinanceiros[0]
	);

	return (
		<Autocomplete
			options={options}
			renderInput={(params) => <TextField {...params} margin='dense' fullWidth />}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option.nome}
			onChange={(_, option) => {
				setOption(option);
				setTipoLancamentoFinanceiro(option.id);
			}}
			value={option}
			noOptionsText='Nenhum resultado'
			fullWidth
			disableClearable
		/>
	);
};

export default TiposLancamentosFinanceirosSelect;
