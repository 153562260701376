import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FilterButton, FilterForm, sanitizeListRestProps, useListContext } from 'react-admin';

const useStyles = makeStyles(
	{
		button: {},
		form: {},
		clearFix: { display: 'none' },
	},
	{ name: 'RaFilter' }
);

const FilterSyndikos = (props) => {
	const classes = useStyles(props);
	const { resource, showFilter, hideFilter, setFilters, displayedFilters, filterValues } = useListContext(props);
	const renderButton = () => {
		const { classes: classesOverride, context, children, variant, ...rest } = props;

		return (
			<FilterButton
				classes={{ ...classes, ...classesOverride }}
				className={classesOverride?.button || classes.button}
				resource={resource}
				filters={React.Children.toArray(children)}
				showFilter={showFilter}
				displayedFilters={displayedFilters}
				filterValues={filterValues}
				{...sanitizeListRestProps(rest)}
			/>
		);
	};

	const renderForm = () => {
		const { classes: classesOverride, context, children, ...rest } = props;

		return (
			<FilterForm
				classes={{ ...classes, ...classesOverride }}
				className={classesOverride?.form || classes.form}
				resource={resource}
				filters={React.Children.toArray(children)}
				hideFilter={hideFilter}
				displayedFilters={displayedFilters}
				initialValues={filterValues}
				setFilters={setFilters}
				{...sanitizeListRestProps(rest)}
			/>
		);
	};

	return props.context === 'button' ? renderButton() : renderForm();
};

export default FilterSyndikos;
