import React, { memo, useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Radio, RadioGroup, FormLabel, FormControlLabel, Box, TextField, Dialog, Typography } from '@material-ui/core';
import { DateRangePicker, DateRange } from 'materialui-daterange-picker-pt';
import { format } from 'date-fns';
import MultiSelect from 'components/common/Selects';
import { BoxFiltraRetencaoProps, PeriodoPickerProps, PeriodoSelectProps, CondominioProps } from '../types';

const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const PeriodoPicker: React.FC<PeriodoPickerProps> = memo(({ open, setOpen, setDateRange }: PeriodoPickerProps) => {
	const toggle = () => setOpen(!open);
	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range: DateRange) => {
					setDateRange({
						label: 'Selecione o período',
						startDate: range.startDate ?? new Date(),
						endDate: range.endDate ?? new Date(),
					});
					toggle();
				}}
				minDate={new Date(MINDATE)}
				initialDateRange={{
					startDate: new Date(),
					endDate: new Date(),
				}}
				definedRanges={[]}
			/>
		</Dialog>
	);
});

const PeriodoSelect: React.FC<PeriodoSelectProps> = ({ onChange, periodo }: PeriodoSelectProps) => {
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState(periodo);

	useEffect(() => {
		onChange(dateRange);
	}, [dateRange, onChange]);

	return (
		<>
			<TextField
				onClick={() => setOpen(!open)}
				fullWidth
				margin='dense'
				label='Selecionar Período'
				inputProps={{
					value: `de ${format(dateRange?.startDate, 'dd/MM/yyyy')} até ${format(
						dateRange?.endDate,
						'dd/MM/yyyy'
					)}`,
				}}
			/>
			<PeriodoPicker {...{ open, setOpen, setDateRange }} />
		</>
	);
};

const BoxFiltraRetencao: React.FC<BoxFiltraRetencaoProps> = ({
	setCondominios,
	setPeriodo,
	setTipo,
	onValidationChange,
	condominios,
	periodo,
	tipo,
}: BoxFiltraRetencaoProps) => {
	const [stateCondominios, setStateCondominio] = useState(condominios);
	const [optionsCondominio, setOptionsCondominio] = useState<CondominioProps[]>([]);
	const [selectedTipo, setSelectedTipo] = useState(tipo);

	const { data: condominiosData } = useGetList<CondominioProps>(
		'condominios',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ situacao: 'A' }
	);

	useEffect(() => {
		setOptionsCondominio(Object.values(condominiosData || {}).filter((v) => Boolean(v?.id)));
	}, [condominiosData]);

	useEffect(() => {
		const isValid = Boolean(stateCondominios.length > 0 && selectedTipo && periodo.startDate && periodo.endDate);
		onValidationChange(isValid);
	}, [stateCondominios, selectedTipo, periodo, onValidationChange]);

	const handleCondominiosChange = (value: CondominioProps) => {
		setStateCondominio(value);
		setCondominios(value);
	};

	const handleTipoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedTipo(event.target.value);
		setTipo(event.target.value);
	};

	const handlePeriodoChange = (dateRange: DateRange) => {
		setPeriodo(dateRange);
	};

	return (
		<>
			<Typography style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
				Selecione um ou mais condomínios para realizar o recolhimentos dos impostos
			</Typography>
			<Box flex={1} mt='10px'>
				<MultiSelect
					label='Selecionar Condomínios'
					variant='formatsCondominios'
					options={optionsCondominio || []}
					value={stateCondominios}
					setValue={handleCondominiosChange}
				/>
			</Box>
			<Box mt='15px'>
				<FormLabel style={{ fontSize: '1rem', fontWeight: 'bold', color: 'black' }}>
					Período do recolhimento
				</FormLabel>
				<RadioGroup row value={selectedTipo} onChange={handleTipoChange}>
					<FormControlLabel value='Emissão' control={<Radio color='primary' />} label='Emissão' />
					<FormControlLabel value='Pagamento' control={<Radio color='primary' />} label='Pagamento' />
				</RadioGroup>
			</Box>
			<Box mt='15px'>
				<PeriodoSelect
					onChange={handlePeriodoChange}
					periodo={periodo as { label: string; startDate: Date; endDate: Date }}
				/>
			</Box>
		</>
	);
};

export default BoxFiltraRetencao;
