import React from 'react';

import { InstrucoesRemessaSemValorFields } from '../../../../../commons/InstrucoesRemessaFields';
import { IChoiceOption, inputProps } from '../../../../../types';

const emptyProps = {};

const InstrucaoField = ({ propsTipo = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaSemValorFields
		sourcePrincipal='instrucao_1'
		propsTipo={{
			source: 'instrucao_1',
			label: 'Instrução 1',
			...propsTipo,
		}}
		propsDias={{
			source: 'instrucao_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoInstrucao = ({
	sources: { sourceTipo, sourceDias } = emptyProps,
	values,
	optionsIsencoesTipo,
}: any) => ({
	sources: { sourceTipo, sourceDias },
	values,
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoInstrucao =
	({ sources: { sourceValor, sourceDias } = emptyProps, optionsIsencoesTipo }: any, change: any) =>
	(_: any, tipoValue: IChoiceOption, input: inputProps) => {
		input.onChange(tipoValue);
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
		}
	};

export default InstrucaoField;
