import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const BoxButtonDecision = ({ children, onclick }) => (
	<Box mt={1} mr={1} width='80px' height='80px' display='flex' justifyContent='center'>
		<Button variant='text' style={{ width: '100%' }} onClick={onclick}>
			{children}
		</Button>
	</Box>
);

export default BoxButtonDecision;
