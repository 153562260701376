import React from 'react';

import { Tooltip } from '@material-ui/core';

import NumberInputForm from 'components/common/NumberInput';

import { LimiteDiasInputFormProps } from '../../types';

export const LimiteDiasInputForm: React.FC<LimiteDiasInputFormProps> = ({
	allowDuplicateGeneration,
	daysLimitAfterExpiration,
	setDaysLimitAfterExpiration,
}) => {
	return (
		<Tooltip title={'Para controlar quanto tempo após o vencimento o boleto pode ser gerado novamente.'}>
			<div>
				<NumberInputForm
					value={allowDuplicateGeneration ? daysLimitAfterExpiration : 0}
					label='Limite de Dias'
					setValue={setDaysLimitAfterExpiration}
					disabled={!allowDuplicateGeneration}
				/>
			</div>
		</Tooltip>
	);
};

export default LimiteDiasInputForm;
