import React from 'react';

import { TextField } from '@material-ui/core';

import NumberFormat from 'react-number-format';

import { TNumberFormatPercentProps } from '../../types';

export const NumberFormatPercent: React.FC<TNumberFormatPercentProps> = ({ label, value, onValueChange, ...rest }) => (
	<NumberFormat
		{...rest}
		decimalSeparator=','
		decimalScale={4}
		fixedDecimalScale={true}
		allowNegative={false}
		allowEmptyFormatting={true}
		defaultValue={'00'}
		customInput={TextField}
		label={label}
		value={value}
		size='small'
		onValueChange={onValueChange}
	/>
);

export default NumberFormatPercent;
