import React from 'react';

import { Box, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, IconButton } from '@material-ui/core';

// icons
import SettingsIcon from '@material-ui/icons/Settings';

import { InfoGestaoProps } from './types';
import { useGestaoContext } from './contexts/GestaoContext';
import useGestao from './hooks';
import useDeleteListContext, {
	DeleteListContext,
} from 'components/common/commonComponentsTSX/DeleteList/DeleteListContext';
import { CreateButton } from 'components/common/commonComponentsTSX';

const TableRowGestao: React.FC<InfoGestaoProps> = ({ gestao, ...rest }) => {
	const { id, nome, inicio_mandato, fim_mandato } = gestao;
	const { handleEdit } = useGestao();
	const { idsSelecionadas, setIdSelecionadas } = useDeleteListContext();
	const isChecked = idsSelecionadas.includes(id);
	const handleSelectId = (id: number) => {
		setIdSelecionadas((prev) => {
			if (idsSelecionadas.includes(id)) {
				return prev?.filter((item) => item !== id);
			}

			return [...prev, id];
		});
	};

	return (
		<TableRow {...rest} key={id}>
			<TableCell style={{ paddingLeft: 0, whiteSpace: 'nowrap', width: '5%' }}>
				<Checkbox checked={isChecked} onClick={() => handleSelectId(id)} />
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textAlign: 'start', width: '60%' }}>{nome}</TableCell>

			<TableCell style={{ paddingRight: 0, textAlign: 'start', width: '20%' }}>{inicio_mandato}</TableCell>
			<TableCell style={{ paddingRight: 0, textAlign: 'start', width: '10%' }}>{fim_mandato}</TableCell>
			<TableCell style={{ paddingRight: 0, width: '5%' }}>
				<IconButton onClick={() => handleEdit(id)} disabled={isChecked}>
					<SettingsIcon />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

const TableGestao: React.FC = () => {
	const { gestoes, getGestoes, condominioRecord } = useGestaoContext();

	const { idsSelecionadas, setIdSelecionadas, requesting } = React.useContext(DeleteListContext);

	React.useEffect(() => {
		getGestoes({
			pagination: { page: 1, perPage: 100000 },
			sort: { field: 'nome', order: 'ASC' },
			filter: { condominio: condominioRecord?.id },
		});
	}, [requesting]);

	const handleClickCheked = () => {
		idsSelecionadas.length ? setIdSelecionadas([]) : setIdSelecionadas(gestoes.map((item) => item.id));
	};

	return (
		<Box>
			<Table size='small' padding='default'>
				<TableHead>
					<TableRow>
						<TableCell style={{ paddingLeft: 0, whiteSpace: 'nowrap', width: '5%' }}>
							<Checkbox
								checked={!!gestoes.length && idsSelecionadas.length === gestoes.length}
								onClick={handleClickCheked}
							/>
						</TableCell>
						<TableCell style={{ paddingLeft: 0, fontWeight: 500, lineHeight: '1.5rem', width: '60%' }}>
							Nome
						</TableCell>
						<TableCell
							style={{
								paddingRight: 0,
								textAlign: 'start',
								width: '20%',
								fontWeight: 500,
								lineHeight: '1.5rem',
							}}
						>
							Início do Mandato
						</TableCell>
						<TableCell
							style={{
								paddingRight: 0,
								textAlign: 'start',
								width: '10%',
								fontWeight: 500,
								lineHeight: '1.5rem',
							}}
						>
							Fim do Mandato
						</TableCell>
						<TableCell style={{ width: '5%' }}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{gestoes?.map((gestao) => (
						<TableRowGestao key={gestao.id} gestao={gestao} />
					))}
					{gestoes.length ? (
						<TableRow>
							<TableCell style={{ paddingLeft: 0, whiteSpace: 'nowrap', width: '5%' }}>
								<Checkbox
									checked={!!gestoes.length && idsSelecionadas.length === gestoes.length}
									onClick={handleClickCheked}
								/>
							</TableCell>
							<TableCell style={{ paddingLeft: 0, fontWeight: 500, lineHeight: '1.5rem', width: '60%' }}>
								Nome
							</TableCell>
							<TableCell
								style={{
									paddingRight: 0,
									textAlign: 'start',
									width: '20%',
									fontWeight: 500,
									lineHeight: '1.5rem',
								}}
							>
								Início do Mandato
							</TableCell>
							<TableCell
								style={{
									paddingRight: 0,
									textAlign: 'start',
									width: '10%',
									fontWeight: 500,
									lineHeight: '1.5rem',
								}}
							>
								Fim do Mandato
							</TableCell>
							<TableCell style={{ width: '5%' }}></TableCell>
						</TableRow>
					) : (
						<></>
					)}
				</TableBody>
			</Table>
		</Box>
	);
};

const GestaoBody: React.FC = () => {
	const { handleIncluir } = useGestao();
	return (
		<>
			<Box mt={2}>
				<Box display={'flex'} justifyContent={'flex-end'}>
					<CreateButton onClick={handleIncluir} circleIcon={true}>
						Cadastrar
					</CreateButton>
				</Box>
				<Box mt={2}>
					<TableGestao />
				</Box>
			</Box>
		</>
	);
};

export default GestaoBody;
