import React from 'react';

import TextField from '@material-ui/core/TextField';

import { TextInput } from 'react-admin';

import { cepFormatacao, cepValidacao, cepParser } from '../../fieldControl/cep';

export const InputCep = (props) => (
	<TextInput
		source={props.sourceCep}
		format={cepFormatacao}
		parse={cepParser}
		validate={cepValidacao}
		label='CEP'
		{...props}
	/>
);

export const FieldCep = ({ error, ...props }) => (
	<TextField
		id={props.id}
		label='CEP'
		margin='dense'
		error={Boolean(error)}
		helperText={error}
		fullWidth
		{...props}
	/>
);
