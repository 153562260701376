import React from 'react';
import { isValid, parse } from 'date-fns';
import Box from '@material-ui/core/Box';
import RelatorioToolbar from '../RelatorioToolbar';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import MultiSelect from 'components/common/Selects';
import { BoxDivisor } from '../../../common/Formulario';
import { RelatorioInputDate, TODAY } from '../dateUtils';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const reducer = (state, action) => {
	switch (action.state) {
		case 'separarIdsUnidadesPorGrupo':
			state.lista_ids_unidades_por_grupo = action.value.map(({ id }) => id);
			break;
		case 'handleChangeDataInicial':
			state.data_entrada = action.value;
			break;
		case 'handleChangeDataFinal':
			state.data_saida = action.value;
			break;
		default:
			break;
	}
	return { ...state };
};

const OpcoesImpressaoDemonstrativoCheckboxGrupo = ({
	handleChange,
	imprimirAssinatura,
	imprimirDetalhesGaragem,
	imprimirMotivo,
}) => {
	return (
		<>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirAssinatura}
						onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
						name='imprimir_assinatura'
					/>
				}
				label='Imprimir assinaturas'
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirDetalhesGaragem}
						onChange={(e, checked) => handleChange(checked, 'imprimir_detalhes_garagem')}
						name='imprimir_detalhes_garagem'
					/>
				}
				label='Imprimir detalhes da garagem'
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirMotivo}
						onChange={(e, checked) => handleChange(checked, 'imprimir_motivo')}
						name='imprimir_motivo'
					/>
				}
				label='Imprimir motivo de saída'
			/>
		</>
	);
};

const DetalhesUnidadeDivisor = ({ idCondominio, tipoRelatorio }) => {
	const [state, dispatch] = React.useReducer(reducer, {
		data_entrada: null,
		data_saida: null,
		lista_ids_unidades_por_grupo: [],
	});
	const [unidades, setUnidades] = React.useState([]);

	const {
		opcoesImpressao: { imprimir_assinatura, imprimir_detalhes_garagem, imprimir_motivo },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	const requestData = {
		id_condominio: idCondominio,
		data_entrada: state.data_entrada,
		data_saida: state.data_saida,
		lista_ids_unidades_por_grupo: state.lista_ids_unidades_por_grupo,
		imprimir_assinatura: imprimir_assinatura,
		imprimir_detalhes_garagem: imprimir_detalhes_garagem,
		imprimir_motivo: imprimir_motivo,
		nome_relatorio: tipoRelatorio.action,
	};

	const isDateValid = checkDates(state.data_entrada, state.data_saida);
	const { options: optionsUnidades } = useRequestOptionsUnidades();
	React.useEffect(() => {
		if (!state.data_entrada) {
			dispatch({
				state: 'handleChangeDataFinal',
				value: null,
			});
		}
	}, [state.data_entrada]);

	React.useEffect(() => {
		dispatch({
			state: 'separarIdsUnidadesPorGrupo',
			value: unidades,
		});
	}, [unidades]);

	React.useEffect(() => {
		setUnidades([]);
	}, [idCondominio]);

	return (
		<BoxDivisor titulo={'Detalhes das Unidades'}>
			<Box display='flex' mb='1em' gridGap='1rem'>
				<RelatorioInputDate
					value={state.data_entrada}
					handleChange={(_, newValue) => {
						dispatch({
							state: 'handleChangeDataInicial',
							value: newValue,
						});
					}}
					label='Data de Entrada Responsável'
					fullWidth
					maxDate={TODAY}
				/>
				<RelatorioInputDate
					value={state.data_saida}
					handleChange={(_, newValue) => {
						dispatch({
							state: 'handleChangeDataFinal',
							value: newValue,
						});
					}}
					label='Data de Saída Responsável'
					fullWidth
					disabled={!state.data_entrada || !isValid(parse(state.data_entrada, 'dd/MM/yyyy', new Date()))}
					maxDate={TODAY}
					minDate={parse(state.data_entrada, 'dd/MM/yyyy', new Date()) || null}
				/>
			</Box>
			<Box display='flex' mb='1em'>
				<Box width='50%' mr='1em'>
					<MultiSelect
						variant='formatsUnidades'
						options={optionsUnidades}
						value={unidades}
						setValue={setUnidades}
					/>
				</Box>
				<Box>
					<OpcoesImpressaoDemonstrativoCheckboxGrupo
						handleChange={handleChangeOpcoesImpressao}
						imprimirAssinatura={imprimir_assinatura}
						imprimirDetalhesGaragem={imprimir_detalhes_garagem}
						imprimirMotivo={imprimir_motivo}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={isDateValid} />
			</Box>
		</BoxDivisor>
	);
};

export default DetalhesUnidadeDivisor;

function checkDates(data_entrada, data_saida) {
	if (!data_entrada && !data_saida) {
		return true;
	}
	const parseddata_entrada = parse(data_entrada, 'dd/MM/yyyy', new Date());
	const parseddata_saida = parse(data_saida, 'dd/MM/yyyy', new Date());
	if (data_entrada && !data_saida) {
		if (isValid(parseddata_entrada)) {
			return true;
		} else {
			return false;
		}
	}
	if (data_entrada && data_saida) {
		if (isValid(parseddata_entrada) && isValid(parseddata_saida)) {
			if (parseddata_entrada > parseddata_saida) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}
	return true;
}
