import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';

import { useGetList } from 'react-admin';

import ImportGaragensContext from './ImportGaragensContext';
import DropZoneImportGaragensModal from './DropZoneImportGaragensModal';
import ResultadosImportGaragensModal from './ResultadosImportGaragensModal';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

const minWidthDosModais = {
	OpcoesImportacao: '95vw',
};

const ImportGaragensContextProvider = () => {
	const [opcoesModal] = useState('OpcoesImportacao');
	const { setMinWidthModel } = useContext(ModalSizeEditableContext);
	const updateMinWidhtModal = () => {
		setMinWidthModel(minWidthDosModais[opcoesModal]);
	};
	useEffect(updateMinWidhtModal, [opcoesModal]);

	// Configurações e dados gerais
	const [condominios, setCondominios] = useState([]);
	const [condominio, setCondominio] = useState(null);
	const [gruposLista, setGruposLista] = useState([]);
	const [grupo, setGrupo] = useState(null);
	const [unidadesLista, setUnidadesLista] = useState([]);
	const [unidade, setUnidade] = useState([]);

	// Dados do import
	const [files, setFiles] = useState([]);
	const [resultadoImport, setResultadoImport] = useState([]);
	const [errosNoArquivo, SetErrosNoArquivo] = useState([]);

	const {
		data: condominiosData,
		ids: condominiosIds,
		loading: condominiosLoading,
	} = useGetList('condominios', { perPage: 10000, page: 1 }, { field: 'nome', order: 'ASC' }, { situacao: 'A' });

	const {
		data: gruposData,
		ids: gruposIds,
		loading: gruposLoading,
	} = useGetList(
		'grupo_unidade_condominio',
		{ perPage: 10000, page: 1 },
		{ field: 'nome', order: 'ASC' },
		{ id_condominio: condominio?.id }
	);

	const {
		data: unidadesData,
		ids: unidadesIds,
		loading: unidadesLoading,
	} = useGetList(
		'unidades',
		{ perPage: 10000, page: 1 },
		{ field: 'unidade', order: 'ASC' },
		{ id_condominio: condominio?.id, id_grupo: grupo?.id }
	);

	const updateCondominios = useCallback(() => {
		if (!condominiosLoading) {
			const options = (condominiosIds || []).filter((id) => !!id).map((id) => condominiosData[id]);
			setCondominios(options);
			if (!condominio || !condominiosIds.includes(parseInt(condominio?.id)));
		}
	}, [condominiosLoading, condominiosData, condominiosIds, setCondominios, condominio, setCondominio]);
	useEffect(updateCondominios, [condominiosLoading]);

	const changeCondominio = useCallback(() => {
		setGrupo(null);
		setGruposLista([]);
		setUnidade(null);
		setUnidadesLista([]);
	}, [setGrupo, setGruposLista, setUnidade, setUnidadesLista]);
	useEffect(changeCondominio, [condominio]);

	const changeGrupo = useCallback(() => {
		setUnidade(null);
		setUnidadesLista([]);
		if (grupo?.nome) {
			updateUnidades();
		}
	}, [setUnidade, setUnidadesLista]);
	useEffect(changeGrupo, [grupo]);

	const updateGrupos = useCallback(() => {
		if (!gruposLoading) {
			setGruposLista((gruposIds || []).map((id) => gruposData?.[id]).filter((g) => !!g));
		}
	}, [gruposData, gruposIds, setGruposLista, gruposLoading]);
	useEffect(updateGrupos, [gruposLoading]);

	const updateUnidades = useCallback(() => {
		if (!unidadesLoading) {
			setUnidadesLista(
				(unidadesIds || [])
					.map((id) => unidadesData?.[id])
					.filter((g) => g?.nome_grupo == grupo?.nome && g !== undefined)
			);
		}
	}, [unidadesData, unidadesIds, setUnidadesLista, unidadesLoading]);
	useEffect(updateUnidades, [unidadesLoading]);

	const [modalStep, setModalStep] = useState('1');
	const [stepComponents] = useState({
		1: <DropZoneImportGaragensModal />,
		2: <ResultadosImportGaragensModal />,
	});

	const providerValue = useMemo(
		() => ({
			condominio,
			condominios,
			files,
			grupo,
			gruposLista,
			unidade,
			unidadesLista,
			setCondominio,
			setFiles,
			setGrupo,
			setUnidade,
			setModalStep,
			resultadoImport,
			setResultadoImport,
			errosNoArquivo,
			SetErrosNoArquivo,
		}),
		[
			condominio,
			condominios,
			files,
			grupo,
			gruposLista,
			unidade,
			unidadesLista,
			setCondominio,
			setFiles,
			setGrupo,
			setUnidade,
			setModalStep,
			resultadoImport,
			setResultadoImport,
			errosNoArquivo,
			SetErrosNoArquivo,
		]
	);

	return (
		<ImportGaragensContext.Provider value={providerValue}>
			{stepComponents[modalStep]}
		</ImportGaragensContext.Provider>
	);
};

export default ImportGaragensContextProvider;
