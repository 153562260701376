import React from 'react';
import axios from 'axios';
import { useDataProvider, useNotify } from 'react-admin';

const alterarFiltroContasBancarias = (state, action) => {
	const { filtros } = state;
	const filtro = action?.filtro;
	const valor = action?.valor;
	if (!filtro) return state;

	state.filtros = { ...filtros, [filtro]: valor };
	return state;
};

const alterarFiltrosContasBancarias = (state, action) => {
	const { filtros = {} } = action ?? {};
	state.filtros = { ...filtros };
	return state;
};

const sinalizarColetaOptionsContasBancarias = (state) => {
	state.loading = true;
	return state;
};

const armazenarOptionsColetadasContasBancarias = (state, action) => {
	const { options = [] } = action;
	const data = options.reduce((optionsPorId, option) => {
		if (option?.id) optionsPorId[option.id] = option;
		return optionsPorId;
	}, {});
	const ids = Object.keys(data);
	Object.assign(state, { options, data, ids, loading: false, loaded: true });
	return state;
};

const invalidActionContasBancarias = (state) => state;

const actionsContasBancarias = {
	alterarFiltro: alterarFiltroContasBancarias,
	alterarFiltros: alterarFiltrosContasBancarias,
	sinalizarColetaOptions: sinalizarColetaOptionsContasBancarias,
	armazenarOptionsColetadas: armazenarOptionsColetadasContasBancarias,
};

const reducerRequestOptionsContasBancarias = (state, action) => {
	const changedState = (actionsContasBancarias[action?.action] ?? invalidActionContasBancarias)(state, action);
	return { ...changedState };
};

const filtrosIniciaisContasBancarias = {};

export const useRequestOptionsContasBancarias = (filtrosIniciais = filtrosIniciaisContasBancarias) => {
	const dp = useDataProvider();
	const notify = useNotify();
	const [{ options, data, ids, loading, loaded, filtros }, dispatch] = React.useReducer(
		reducerRequestOptionsContasBancarias,
		{
			options: [],
			data: {},
			ids: [],
			loading: false,
			loaded: false,
			filtros: filtrosIniciais,
		}
	);
	const handleReqOptions = (cancelToken) => {
		dispatch({ action: 'sinalizarColetaOptions' });
		return dp
			.getSimple('conta_condominio', {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: 'id', order: 'ASC' },
				filter: filtros,
				cancelToken,
			})
			.then((response) => {
				const options = response?.data?.results ?? [];
				dispatch({ action: 'armazenarOptionsColetadas', options });
			})
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				if (axios.isCancel(e)) return;
				notify('Não foi possível coletar as contas bancárias', 'warning');
				dispatch({ action: 'armazenarOptionsColetadas', options: [] });
			});
	};

	const getOptions = () => {
		const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
		handleReqOptions(cancelToken);
		return () => {
			cancelRequest();
		};
	};

	React.useEffect(getOptions, [filtros]);

	const alterarFiltro = React.useCallback(
		(filtro, valor) => dispatch({ action: 'alterarFiltro', filtro, valor }),
		[dispatch]
	);
	const alterarFiltros = React.useCallback(
		(filtros = {}) => dispatch({ action: 'alterarFiltros', filtros }),
		[dispatch]
	);
	const recoletaOptions = React.useCallback(
		() => dispatch({ action: 'alterarFiltros', filtros: filtros }),
		[dispatch, filtros]
	);

	return {
		options,
		data,
		ids,
		loading,
		loaded,
		alterarFiltro,
		alterarFiltros,
		recoletaOptions,
	};
};

export default useRequestOptionsContasBancarias;
