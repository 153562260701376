import React, { useContext } from 'react';

import { useListContext } from 'react-admin';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import FluxoModalNovaLeitura from '../../modais/fluxoModalNovaLeitura/FluxoModalNovaLeitura';
import { TooltipIconButton } from '../../../../common/TooltipIconButton';

const CriarNovaLeituraButton = () => {
	const { filterValues } = useListContext();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipIconButton
			color='primary'
			id='criar_nova_leitura'
			disabled={!filterValues.id_condominio_id}
			onClick={() => {
				if (filterValues.id_condominio_id) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<FluxoModalNovaLeitura
								dadosNovaLeitura={{
									id_condominio: filterValues.id_condominio_id,
									dados_config: {
										id_condominio: filterValues.id_condominio_id,
									},
								}}
							/>
						),
					}));
				}
			}}
			size='small'
			title='Criar Nova Leitura'
		>
			<AddCircleOutlineIcon />
		</TooltipIconButton>
	);
};

export default CriarNovaLeituraButton;
