import { GetOnChangeProps, ChangeProps, IChoiceOption, inputProps } from '../../../../../types';

const emptyProps = {} as any;

export const getPropsOnChangeTipoPadraoDesconto = ({
	sources: { sourceValor, sourceDias } = emptyProps,
	values,
	optionsIsencoesTipo,
}: GetOnChangeProps) => ({
	sources: { sourceValor, sourceDias },
	values,
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoDesconto =
	(
		{ sources: { sourceValor, sourceDias } = emptyProps, values, optionsIsencoesTipo }: GetOnChangeProps,
		change: ChangeProps
	) =>
	(_: any, tipoValue: IChoiceOption, input: inputProps) => {
		input.onChange(tipoValue);

		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
			return;
		}

		const valorDesconto = values[sourceValor];
		if (!valorDesconto || valorDesconto < 0.01) change(sourceValor, 0.01);
	};
