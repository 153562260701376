import React from 'react';

import { Box } from '@material-ui/core';

import { BoxMainGrid } from '../../../../../tabs/TabInstrucoesCobrancaRemessa';
import MultaFields from './MultaFields';
import JurosFields from './JurosFields';
import { DescontoFields } from '../../../../../tabs/TabInstrucoesCobrancaRemessa';

const TabInstrucoesCobrancaRemessa = () => (
	<Box>
		<BoxMainGrid>
			<Box>
				<MultaFields />
				<JurosFields />
				<DescontoFields />
			</Box>
		</BoxMainGrid>
	</Box>
);

export default TabInstrucoesCobrancaRemessa;
