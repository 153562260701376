export const tags = [
	{
		keys: ['unidade'],
		content: 'Código do bloco + unidade + tipo de unidade',
		value: '{{grupo_unidade}} - {{nome_unidade}} - {{tipo_unidade}}',
	},
	{
		keys: ['vencimento'],
		content: 'Dia/Mês/Ano do vencimento',
		value: '{{data_vencimento}}',
	},
	{
		keys: ['referencia'],
		content: 'Mês/Ano da referencia',
		value: '{{data_referencia}}',
	},
	{
		keys: ['identificacao'],
		content: 'Nome da identificação',
		value: '{{nome_identificacao}}',
	},
	{
		keys: ['responsavel'],
		content: 'Responsável da cobrança',
		value: '{{responsavel_nome}}',
	},
];
