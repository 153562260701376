import React, { useContext, useState } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import EditIcon from '@material-ui/icons/Edit';

import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from 'components/common/CurrencyInput';
import { ModalArrecadacaoUnidadesEditarContext } from './ModalArrecadacaoUnidadesEditarContext';

const ModalEditarValor = ({ row }) => {
	const { editarValor, requesting } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [valor, setValor] = useState((row?.valor ? `${row?.valor}` : '0').replace('-', ''));
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			{...(requesting
				? {
						form: {
							component: (
								<Box display='flex' justifyContent='center'>
									<CircularProgress />
								</Box>
							),
						},
						customActions: <></>,
				  }
				: {
						closeInSubmit: false,
						customActions: (
							<>
								<ButtonCancel onClick={closesModal} />
								<ButtonConfirm
									size='small'
									onClick={() => {
										editarValor(row?.id, valor, closesModal);
									}}
									disabled={isNaN(parseFloat(valor))}
									variant='text'
								>
									Salvar
								</ButtonConfirm>
							</>
						),
						form: {
							component: (
								<Box
									css={{ transition: 'all 200ms ease' }}
									minHeight='60px'
									display='grid'
									alignItems='center'
									width='100%'
								>
									<Box display='grid' gridGap='15px'>
										<TextField
											error={isNaN(parseFloat(valor))}
											helperText={
												isNaN(parseFloat(valor))
													? 'Forneça um valor para atualizar a arrecadação da unidade'
													: false
											}
											label='Valor'
											margin='dense'
											fullWidth
											value={valor}
											onChange={(e) => setValor(e.target.value)}
											isNumericString={true}
											inputProps={sanitizeListNumberFormatBRLProps({
												prefix: row?.valor < 0 ? 'R$ -' : 'R$ ',
												allowNegative: false,
											})}
											InputProps={{
												inputComponent: NumberFormatBRL,
											}}
										/>
									</Box>
								</Box>
							),
						},
						title: `Editar Valor ${row?.complemento ? 'de ' + row.complemento : ''}`,
				  })}
		/>
	);
};

const BotaoEditarValor = ({ row }) => {
	const { requesting } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	const handleClick = () => {
		setMinWidthModel('80vw');
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalEditarValor row={row} />,
		}));
	};

	return (
		!!row && (
			<TooltipIconButton disabled={requesting} size='small' title='Editar Valor' onClick={handleClick}>
				<EditIcon />
			</TooltipIconButton>
		)
	);
};

export default BotaoEditarValor;
