import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

export const ConfigRelatorioContext = React.createContext(null);

export const configRelatorioCheckboxes = {
	// Check template_padro
	template_padrao: true,

	// id para verificar na hora de salvar se é o template Padrão ou não
	id: 1,

	// ids condominios selecionados
	condominios: [],

	// Nome padrão do template de configuração
	nome: 'Template Padrão',

	// Define o tamanho da fonte
	tamanho_fonte: 'N',

	// imprimir dados do escritorio
	imprimir_dados_escritorio_nome: true,
	imprimir_dados_escritorio_apelido: false,
	nao_imprimir_dados_escritorio_nome: false,
	imprimir_dados_escritorio_cnpj: false,
	imprimir_dados_escritorio_cep: false,
	imprimir_dados_escritorio_endereco: false,
	imprimir_dados_escritorio_tipo_logradouro: false,
	imprimir_dados_escritorio_numero: false,
	imprimir_dados_escritorio_bairro: false,
	imprimir_dados_escritorio_cidade: false,
	imprimir_dados_escritorio_estado: false,
	imprimir_dados_escritorio_telefone: false,
	imprimir_dados_escritorio_email: false,

	// opções do escritorio
	imprimir_dados_escritorio_no_cabecalho: false,
	imprimir_dados_escritorio_apenas_primeira_pagina: false,
	imprimir_dados_escritorio_alinhado_centralizado: false,

	// imprimir dados do escritorio
	imprimir_dados_condominio_codigo: false,
	imprimir_dados_condominio_nome: true,
	imprimir_dados_condominio_apelido: false,
	nao_imprimir_dados_condominio_nome: false,
	imprimir_dados_condominio_cnpj: false,
	imprimir_dados_condominio_cep: false,
	imprimir_dados_condominio_bairro: false,
	imprimir_dados_condominio_cidade: false,
	imprimir_dados_condominio_estado: false,
	imprimir_dados_condominio_endereco: false,
	imprimir_dados_condominio_numero: false,
	imprimir_dados_condominio_logradouro: false,

	// opções do condominio
	imprimir_dados_condominio_no_cabecalho: false,
	imprimir_dados_condominio_apenas_primeira_pagina: false,
	imprimir_dados_condominio_alinhado_centralizado: false,

	// opções do rodapé
	imprimir_data_e_hora: false,
	imprimir_nome_usuario_gerou_relatorio: false,

	// opções de paginação
	imprimir_numero_pagina_rodape: false,
	alterar_desc_pagina_p_folha: false,

	// opções do relatório
	gerar_relatorio_sem_cabecalho_rodape: false,
	imprimir_relatorio_caixa_alta: false,
	imprimir_logotipo_do_escritorio: false,
	// opções do cabeçalho
	imprimir_cabecalho_caixa_alta: false,

	// opções de impressão
	imprimir_marca_dagua: false,
};

export const ConfigRelatorioProvider = ({ children }) => {
	const [openCreateNewTemplate, setOpenCreateNewTemplate] = React.useState(false);
	const [templates, setTemplates] = React.useState([configRelatorioCheckboxes]);
	const [condominios, setCondominios] = React.useState([]);
	const [opcoesImpressao, setOpcoesImpressao] = React.useState(null);
	const [condominiosDestinados, setCondominiosDestinados] = React.useState([]);
	const [tamanhoFonte, setTamanhoFonte] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const dp = useDataProvider();
	const notify = useNotify();

	const handleChangeOpcoesImpressao = React.useCallback(
		(checked, nameOpcao) => {
			setOpcoesImpressao((prevConfig) => ({
				...prevConfig,
				[nameOpcao]: checked,
			}));
		},
		[opcoesImpressao, setOpcoesImpressao]
	);

	const providerValue = React.useMemo(
		() => ({
			condominios,
			handleChangeOpcoesImpressao,
			opcoesImpressao,
			setOpcoesImpressao,
			tamanhoFonte,
			setTamanhoFonte,
			condominiosDestinados,
			setCondominiosDestinados,
			templates,
			setTemplates,
			loading,
			setLoading,
			fetchConfigs,
			openCreateNewTemplate,
			setOpenCreateNewTemplate,
		}),
		[
			condominios,
			handleChangeOpcoesImpressao,
			opcoesImpressao,
			setOpcoesImpressao,
			tamanhoFonte,
			setTamanhoFonte,
			condominiosDestinados,
			setCondominiosDestinados,
			templates,
			setTemplates,
			loading,
			setLoading,
			fetchConfigs,
			openCreateNewTemplate,
			setOpenCreateNewTemplate,
		]
	);

	function fetchCondominios() {
		setLoading(true);
		dp.getSimple('condominios', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: { situacao: 'A' },
		})
			.then(({ data: { results } }) => {
				setLoading(false);
				setCondominios(results);
			})
			.catch(() => notify('Sem condominios cadastrados.', 'warning'))
			.finally(() => setLoading(false));
	}

	React.useEffect(fetchCondominios, []);

	function fetchConfigs() {
		setLoading(true);
		setOpcoesImpressao(null);
		setCondominiosDestinados([]);
		setTamanhoFonte(null);
		dp.getSimple('template_relatorios', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'nome', order: 'ASC' },
		})
			.then(({ data: { results } }) => {
				setLoading(false);
				if (!results.length) setTemplates([configRelatorioCheckboxes]);
				if (results.length) setTemplates(results);
			})
			.catch(() => notify('Sem templates cadastrados', 'warning'))
			.finally(() => setLoading(false));
	}

	React.useEffect(fetchConfigs, []);

	return <ConfigRelatorioContext.Provider value={providerValue}>{children}</ConfigRelatorioContext.Provider>;
};
