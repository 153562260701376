import { useDataProvider } from 'ra-core';
import React, { useState, createContext, useMemo, useEffect } from 'react';

export const VinculosContext = createContext(null);

export const emptyDadosEndereco = {
	cep: '',
	tipo_logradouro_id: null,
	tipo_logradouro: null,
	endereco: '',
	numero: '',
	bairro: '',
	cidade: '',
	uf: null,
	complemento_endereco: '',
};

export const VinculosProvider = ({
	value,
	setValue,
	proprietario,
	inquilino,
	imobData,
	disabledCondition,
	edit,
	recordValue,
	setSelectingAmb,
	children,
}) => {
	const [selectedPessoa, setSelectedPessoa] = useState(null);
	const [reload, setReload] = useState(0);
	const dataProvider = useDataProvider();
	const [choices, setChoices] = useState([]);
	const [imobiliarias, setImobiliarias] = useState([]);

	const init = () => {
		dataProvider
			.getSimple('pessoas', {
				pagination: { page: 1, perPage: 100000 },
				sort: { field: 'nome', order: 'ASC' },
				filter: { situacao: 'A' },
			})
			.then((response) => {
				const data = response?.data;
				if (data) setChoices(data.results || []);
			});
		dataProvider
			.getSimple('imobiliarias', {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: 'nome', order: 'ASC' },
				filter: { situacao: 'A' },
			})
			.then((response) => {
				const data = response?.data;
				if (data) setImobiliarias(data.results || []);
			});
	};

	useEffect(init, []);

	const providerValue = useMemo(
		() => ({
			selectedPessoa,
			setSelectedPessoa,
			choices,
			setChoices,
			dataProvider,
			imobiliarias,
			responsaveis: value,
			setResponsaveis: setValue,
			proprietario,
			inquilino,
			imobData,
			disabledCondition,
			edit,
			recordValue,
			setSelectingAmb,
			reload,
			setReload,
		}),
		[
			selectedPessoa,
			setSelectedPessoa,
			choices,
			setChoices,
			dataProvider,
			imobiliarias,
			value,
			setValue,
			proprietario,
			inquilino,
			imobData,
			disabledCondition,
			edit,
			recordValue,
			setSelectingAmb,
			reload,
			setReload,
		]
	);

	return <VinculosContext.Provider value={providerValue}>{children}</VinculosContext.Provider>;
};
