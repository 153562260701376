import React from 'react';

import { format, parseISO } from 'date-fns';

const formatDate = (date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

export const FormatDateField = ({ source, record }) => {
	const date = record?.[source];
	if (!date) return <span>Não concluído</span>;
	const parsedDate = parseISO(date);
	const dateFormat = formatDate(parsedDate);
	return <span>{dateFormat}</span>;
};
