import React from 'react';
import ButtonMUI from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const defaultIcon = <NavigateNextIcon />;

export const BotaoProximo = (props) => {
	const { icon = defaultIcon, disabled = false, ...rest } = props;

	return (
		<>
			<ButtonMUI
				color='primary'
				variant='text'
				key='button'
				size='small'
				endIcon={icon}
				{...rest}
				disabled={disabled}
			>
				Próximo
			</ButtonMUI>
		</>
	);
};

export const TooltipProximoIconButton = ({ title, ...rest }) => {
	return (
		<Tooltip title={title}>
			<span>
				<IconButton {...rest}>
					<NavigateNextIcon />
				</IconButton>
			</span>
		</Tooltip>
	);
};
