import React, { useEffect, useMemo, useState } from 'react';

import { useListContext } from 'react-admin';

import { ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import BoletosContextProvider from '../../ContasReceberBoletos/BoletosContextProvider';
import { RemessasContext } from '../contextos';
import { ButtonConfigurarRemessasBoletos } from '../botoes';
import ButtonGerarSegundaViaRemessas from '../botoes/ButtonGerarSegundaViaRemessas';
import { SegundaViaContextProvider } from '../contextos';

export const RemessasContextProvider = () => {
	const { data, selectedIds } = useListContext();
	const [{ idsParaGeracaoRemessa, registrosSelecionados }, setRegistrosSelecionados] = useState({
		registrosSelecionados: {},
		idsRegistrosSelecionados: [],
		idsParaGeracaoRemessa: [],
	});

	useEffect(() => {
		setRegistrosSelecionados((v) => {
			const { registros, idsParaGeracaoRemessa } = selectedIds.reduce(
				({ registros, idsParaGeracaoRemessa }, id) => {
					const registro = data[id] || v[id];
					if (registro) {
						registros[id] = registro;
						idsParaGeracaoRemessa.push(...(registro.ids || []));
					}
					return { registros, idsParaGeracaoRemessa };
				},
				{ registros: {}, idsParaGeracaoRemessa: [] }
			);
			const ids = Object.keys(registros);
			return { registrosSelecionados: registros, idsRegistrosSelecionados: ids, idsParaGeracaoRemessa };
		});
	}, [selectedIds, data, setRegistrosSelecionados]);

	const providerValue = useMemo(
		() => ({ idsRegistrosSelecionados: idsParaGeracaoRemessa, registrosSelecionados: registrosSelecionados }),
		[idsParaGeracaoRemessa]
	);

	return (
		<RemessasContext.Provider value={providerValue}>
			<SegundaViaContextProvider>
				<BoletosContextProvider>
					<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '61vw' }}>
						<ButtonGerarSegundaViaRemessas />
						<ButtonConfigurarRemessasBoletos />
					</ModalSizeEditableContextProvider>
				</BoletosContextProvider>
			</SegundaViaContextProvider>
		</RemessasContext.Provider>
	);
};

export default RemessasContextProvider;
