import React from 'react';

import { List, Pagination, TextField, useDataProvider, useListContext, useNotify, useRefresh } from 'react-admin';

import { format, parseISO } from 'date-fns';

import SendIcon from '@material-ui/icons/Send';

import brExporter from 'utils/exporter';
import { Data } from 'components/common/filtros/DataV2';
import { Padrao } from 'components/common/filtros/Padrao';
import { Tabela } from 'components/common/Tabela';
import { Autocompletar } from 'components/common/filtros/Autocompletar';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { SuccessChip, SyndikosRedChip, WarningChip } from 'components/common/chips';
import { cnpjFormatacao, cpfFormatacao } from '../../../../fieldControl/documento';

const DateField = ({ record }) => {
	const { data } = useListContext();
	const { data_envio } = data[`${record.id}`];

	return <span>{format(parseISO(data_envio), 'dd/MM/yyyy')}</span>;
};

const SituacaoConvite = ({ record }) => {
	const { data } = useListContext();
	const { situacao } = data[`${record.id}`];

	if (situacao === 'ENV') {
		return <WarningChip value='Pendente' />;
	}

	if (situacao === 'EXP') {
		return <SyndikosRedChip value='Expirado' />;
	}

	return <SuccessChip value='Ativo' />;
};

const DocumentoField = ({ record }) =>
	record.user_documento && record.user_documento.length === 11 ? (
		<span>{cpfFormatacao(record.user_documento)}</span>
	) : (
		<span>{cnpjFormatacao(record.user_documento)}</span>
	);

const TabelaConsultaRelatorio = (props) => (
	<Tabela {...props} hasBulkActions disableClickRow>
		<TextField source='user_nome' label='NOME' filtro={<Padrao />} />
		<DocumentoField source='user_documento' label='CPF/CNPJ' filtro={<Padrao />} />
		<TextField source='user_email' label='E-MAIL' filtro={<Padrao />} />
		<DateField source='data_envio' label='DATA DO ENVIO' filtro={<Data />} />
		<SituacaoConvite
			source='situacao'
			label='SITUAÇÃO CONVITE'
			filtro={
				<Autocompletar
					opcoes={[
						{ id: 'ENV', name: 'Pendente' },
						{ id: 'EXP', name: 'Expirado' },
						{ id: 'UTI', name: 'Ativo' },
					]}
				/>
			}
		/>
	</Tabela>
);

const Actions = () => {
	const dp = useDataProvider();
	const refresh = useRefresh();
	const { data, selectedIds, onUnselectItems } = useListContext();
	const notify = useNotify();
	const [loading, setLoading] = React.useState('');
	const selectedRows = Object.keys(data).reduce((acc, curr) => {
		const id = parseInt(curr);
		if (selectedIds.includes(id)) {
			return [...acc, data[curr]];
		}
		return acc;
	}, []);

	const handleNotificar = () => {
		setLoading('Notificar');
		const ids = selectedRows
			.filter(({ situacao }) => situacao !== 'UTI') //situacao Utilizado
			.reduce((acc, curr) => [...acc, curr.id], []);

		dp.simplePost('convites_kondomynos/reenviar_convites', { data: ids })
			.then(() => notify('Convites reenviados'))
			.catch(() => notify('Falha ao reenviar convites'))
			.finally(() => {
				setLoading('');
				onUnselectItems();
				refresh(true);
			});
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
			<ButtonConfirm
				loading={loading === 'Notificar'}
				color='primary'
				startIcon={<SendIcon />}
				size='small'
				onClick={handleNotificar}
			>
				Reenviar selecionados
			</ButtonConfirm>
		</div>
	);
};

function ConsultaConvitesEnviadosList(props) {
	return (
		<List
			{...props}
			basePath='/convites_kondomynos'
			resource='convites_kondomynos'
			title=' '
			exporter={brExporter}
			empty={false}
			bulkActionButtons={<Actions />}
			sort={{ field: 'nome', order: 'ASC' }}
			perPage={10}
			pagination={
				<Pagination
					rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
					labelRowsPerPage='Relatórios por página'
				/>
			}
		>
			<TabelaConsultaRelatorio {...props} />
		</List>
	);
}

export default ConsultaConvitesEnviadosList;
