import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { TooltipIconButton } from '../TooltipIconButton';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import { OnClick } from '../commonComponentsTSX/types';

const useStyles = makeStyles(
	(theme) => ({
		cancelButton: {
			'&:hover': {
				backgroundColor: alpha(theme.palette.primary.main, 0.05),

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'SyndikosCancelButton' }
);

interface ITooltipIconButtonCancelProps {
	title?: string;
	style?: React.CSSProperties;
	className?: string;
	onClick?: OnClick;
}

export const TooltipIconButtonCancel: React.FC<ITooltipIconButtonCancelProps> = ({
	title,
	style = {},
	children,
	className,
	...rest
}) => {
	const classes = useStyles();
	return (
		<TooltipIconButton
			{...rest}
			title={title || 'Cancelar'}
			className={classnames(classes.cancelButton, className)}
		>
			{children || <CloseIcon />}
		</TooltipIconButton>
	);
};

interface IButtonCancelProps {
	id?: string;
	style?: React.CSSProperties;
	className?: string;
	onClick?: OnClick;
}

export const ButtonCancelV2: React.FC<IButtonCancelProps> = ({ id, style = {}, className, ...rest }) => {
	const classes = useStyles();
	return (
		<Button
			{...rest}
			startIcon={<CloseIcon />}
			size='small'
			variant='text'
			className={classnames(classes.cancelButton, className)}
			id={id}
		>
			{'Cancelar'}
		</Button>
	);
};
