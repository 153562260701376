import React, { useContext } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ImportUnidadesContext from '../import/ImportUnidadesContext';
import ImportGaragensContext from '../import/ImportGaragensContext';

const CondominioSelect = ({ disabled, context }) => {
	const { condominio, setCondominio, condominios } = useContext(context);
	return (
		<Autocomplete
			options={condominios}
			renderInput={(params) => <TextField {...params} margin='dense' label='Condomínio' />}
			getOptionLabel={(option) => option?.nome || ''}
			noOptionsText='Nenhum resultado encontrado'
			getOptionSelected={(option, v) => v?.id === option.id}
			value={condominio || null}
			onChange={(_, v) => {
				setCondominio(v);
			}}
			clearText='Limpar'
			disableClearable={true}
			disabled={!!disabled}
		/>
	);
};

export const CondominioSelectUnidades = () => <CondominioSelect context={ImportUnidadesContext} />;
export const CondominioSelectGaragens = () => <CondominioSelect context={ImportGaragensContext} />;
