import React from 'react';
import { Edit } from 'react-admin';

import { FornecedorFormulario } from './form';

export const FornecedorEdit = (props) => (
	<Edit title={'Editar Fornecedor'} undoable={false} {...props}>
		<FornecedorFormulario edit {...props} />
	</Edit>
);
