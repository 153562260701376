import React from 'react';
import Button from '@material-ui/core/Button';
import { TooltipIconButton } from '../TooltipIconButton';
import CloseIcon from '@material-ui/icons/Close';

export const ButtonClose = ({ children, ...rest }) => {
	return (
		<Button {...rest} color='default' startIcon={<CloseIcon />}>
			{children || 'Fechar'}
		</Button>
	);
};

export const TooltipIconButtonClose = ({ title, children, ...rest }) => {
	return (
		<TooltipIconButton {...rest} title={title || 'Fechar'} color='default'>
			{children || <CloseIcon />}
		</TooltipIconButton>
	);
};
