import React from 'react';

import { useNotify } from 'react-admin';

import { Button, Box } from '@material-ui/core';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../ModalSizeEditableContext';
import { CloseButton, DeleteButton } from 'components/common/commonComponentsTSX';

import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

import { useDataProvider } from 'react-admin';
import { CustomDataProvider } from './types';
import { SelectTemplates, ModalValue } from '../types';

import { EditComunicadoTemplateBox } from './EditComunicadoTemplateBox';

import { ModalConfirmacao } from './index';

type ModalEditTemplateBoxProps = {
	template: SelectTemplates;
	setReload: React.Dispatch<React.SetStateAction<number>>;
};

const ModalEditTemplateBox: React.FC<ModalEditTemplateBoxProps> = ({ template, setReload }) => {
	// Variaveis
	const { setModalValue, setMinWidthModel } = React.useContext(ModalSizeEditableContext);

	const [textoModeloEdit, setTextoModeloEdit] = React.useState('');
	const [nomeModeloEdit, setNomeModeloEdit] = React.useState('');
	const [IDModeloEdit, setIDModeloEdit] = React.useState(0);

	const [loading, setLoading] = React.useState(false);
	const dp = useDataProvider() as CustomDataProvider;
	const notify = useNotify();

	// Funções Handle

	const handleSaveEditTemplateValue = (IDModeloEdit: number, nomeModeloEdit: string, textoModeloEdit: string) => {
		setIDModeloEdit(IDModeloEdit);
		setTextoModeloEdit(textoModeloEdit);
		setNomeModeloEdit(nomeModeloEdit);
	};

	// Requisição Post Edit Template Personalizado:
	const handleEditTemplateComunicado = () => {
		setLoading(true);

		dp.update('comunicado_template', {
			id: IDModeloEdit,
			data: {
				comunicado_json: JSON.parse(textoModeloEdit),
				nome_modelo: nomeModeloEdit,
			},
			previousData: null,
		})
			.then(() => {
				notify('Modelo de comunicado alterado com sucesso', 'success');
				setReload((v) => v + 1);
				setModalValue((v: ModalValue) => ({ ...v, open: false }));
			})
			.catch(() => {
				notify('Falha ao alterar modelo de comunicado', 'warning');
				setLoading(false);
			});
	};
	return (
		<CustomDialogBodySizeEditable
			title={'Editar Modelo Personalizado'}
			closeInSubmit={false}
			form={{
				component: (
					<>
						<EditComunicadoTemplateBox
							handleSaveEditTemplateValue={handleSaveEditTemplateValue}
							template={template}
						/>
					</>
				),
			}}
			customActions={
				<Box display='flex' gridGap={'0.5em'}>
					<Box>
						<CloseButton
							onClick={() => setModalValue((v: ModalValue) => ({ ...v, open: false }))}
							size={'small'}
						>
							Cancelar
						</CloseButton>
					</Box>
					<Box>
						<DeleteButton
							size='small'
							onClick={() => {
								setMinWidthModel('30vw');
								setModalValue((v: ModalValue) => ({
									...v,
									open: true,
									dialogBody: (
										<ModalConfirmacao
											IDModeloEdit={IDModeloEdit}
											setLoading={setLoading}
											setReload={setReload}
										/>
									),
								}));
							}}
						>
							{(loading && <CircularProgress size={20} />) || 'Excluir'}
						</DeleteButton>
					</Box>
					<Box>
						<Button
							color='primary'
							size='small'
							variant='contained'
							startIcon={loading ? <></> : <SaveIcon />}
							disabled={loading}
							onClick={handleEditTemplateComunicado}
						>
							{(loading && <CircularProgress size={20} />) || 'Salvar'}
						</Button>
					</Box>
				</Box>
			}
		/>
	);
};

export default ModalEditTemplateBox;
