import axios from 'axios';

const api = axios.create({
	baseURL: process.env.NODE_ENV == 'development' ? '/' : window.location.origin.replace('admin', 'admin.api'),
});

if (window.location.origin.includes('hml')) {
	api.defaults.xsrfCookieName = 'csrftoken_api_hml';
} else {
	api.defaults.xsrfCookieName = 'csrftoken_api';
}

api.defaults.xsrfHeaderName = 'X-CSRFToken';
api.defaults.withCredentials = true;

export default api;
