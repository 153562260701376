import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useListContext } from 'react-admin';

import ListActionsContext from './ListActionsContext';
import LeiturasListContext from '../../LeiturasListContext';

const ListActionsContextProvider = (props) => {
	const { dataLeituraParaLancamentoLeitura, leituraSituacao } = useContext(LeiturasListContext);
	const { className, exporter, filters, maxResults, children, ...rest } = props;
	const { filterValues, total, data } = useListContext();
	const [dataArray, setDataArray] = useState(Object.values(data));
	const [datafiltred, setDatafiltred] = useState(dataArray.filter((item) => item.data_leitura));
	const [dataLeitura, setDataLeitura] = useState(
		datafiltred[0] ? new Date(`${datafiltred[0].data_leitura}T10:00`) : new Date()
	);
	const [disabledSalvarDataLeituras, setDisabledSalvarDataLeituras] = useState(
		!total || !dataLeitura || dataLeitura.toString() === 'Invalid Date'
	);

	const updateDataEffect = useCallback(() => {
		const arrayData = Object.values(data);
		const filtredData = arrayData.filter((item) => item.data_leitura);
		setDataArray(arrayData);
		setDatafiltred(filtredData);
		if (filtredData[0]?.data_leitura) {
			if (
				(
					(dataLeitura?.toString &&
						dataLeitura?.toString() !== 'Invalid Date' &&
						dataLeitura?.toISOString()) ||
					''
				).split('T')[0] !== filtredData[0]?.data_leitura
			) {
				setDataLeitura(filtredData[0] ? new Date(`${filtredData[0].data_leitura}T10:00`) : new Date());
			}
		} else {
			const hoje = new Date();
			hoje.setHours(0, 0, 0);
			setDataLeitura(hoje);
		}
	}, [data, setDataArray, setDatafiltred, setDataLeitura, dataLeitura]);
	useEffect(updateDataEffect, [data]);

	const updateDisabledSalvarDataLeituras = useCallback(() => {
		setDisabledSalvarDataLeituras(
			leituraSituacao !== 'A' ||
				!(filterValues.leitura_id && filterValues.referencia) ||
				!(dataArray[0] && dataArray[0].id) ||
				!dataLeitura ||
				dataLeitura.toString() === 'Invalid Date'
		);
		dataLeituraParaLancamentoLeitura.current = dataLeitura;
	}, [
		setDisabledSalvarDataLeituras,
		leituraSituacao,
		filterValues,
		dataArray,
		dataLeitura,
		dataLeituraParaLancamentoLeitura,
	]);
	useEffect(updateDisabledSalvarDataLeituras, [
		dataArray,
		leituraSituacao,
		filterValues.leitura_id,
		filterValues.referencia,
		dataLeitura,
	]);

	const providerValue = useMemo(
		() => ({
			className,
			dataArray,
			dataLeitura,
			disabledSalvarDataLeituras,
			filters,
			rest,
			setDataLeitura,
		}),
		[className, dataArray, dataLeitura, disabledSalvarDataLeituras, filters, rest, setDataLeitura]
	);

	return <ListActionsContext.Provider value={providerValue}>{children}</ListActionsContext.Provider>;
};

export default ListActionsContextProvider;
