import React from 'react';

import { SituacaoChip } from 'components/resources/movimentacoes/Situacao';
import { Data, TTipoIndice } from '../../types';
import { optionsTipoIndice } from '../../components/SelectTipoIndice';

type TTipoIndiceChipComponentesPorTipoIndice = {
	[key in TTipoIndice]: React.ReactElement;
};

const TipoIndiceChipComponentesPorTipoIndice = optionsTipoIndice.reduce((tipoIndiceComponentes, { id, name }) => {
	tipoIndiceComponentes[id] = <SituacaoChip value={name} textColor={'#040505'} />;
	return tipoIndiceComponentes;
}, {} as TTipoIndiceChipComponentesPorTipoIndice);

const TipoIndiceChipDefault = TipoIndiceChipComponentesPorTipoIndice['MANUAL'] || <></>;

type TipoIndiceFieldProps =
	| { record: Data }
	| Partial<{
			source: string;
			name: string;
			label: string;
			filtro: React.ReactElement;
	  }>;

const TipoIndiceFieldBase: React.FC<{ record: Data }> = ({ record }) => {
	return TipoIndiceChipComponentesPorTipoIndice[record?.tipo_indice] || TipoIndiceChipDefault;
};

const TipoIndiceField = TipoIndiceFieldBase as React.FC<TipoIndiceFieldProps>;

export default TipoIndiceField;
