import React, { useContext } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { useForm } from 'react-final-form';

import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

import { ContasBancariasListContext } from '../../ContasBancariasListContextProvider';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaBancariaButtonsContext from '../contexts/EditaContaBancariaButtonsContext';

const SalvarButton = () => {
	const notify = useNotify();
	const dP = useDataProvider();
	const { setReloadFilter } = useContext(ContasBancariasListContext);
	const { TipoContaContext, condominioRecord } = useContext(EditaContaBancariaContext);
	const { formatarValues } = useContext(TipoContaContext);
	const { getState } = useForm();
	const { pristine, valid, values, errors } = getState();
	const { closeModal } = useContext(EditaContaBancariaButtonsContext);
	return (
		<Button
			disabled={condominioRecord.situacao !== 'A' || pristine}
			onClick={() => {
				if (valid) {
					const dadosFormatados = formatarValues(values);
					dP.fullUpdate('conta_condominio', {
						id: dadosFormatados.id,
						data: dadosFormatados,
					})
						.then(() => {
							setReloadFilter((v) => !v);
							closeModal();
							notify('Conta bancária alterada com sucesso!');
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							const warningMsg =
								e?.response?.status === 500
									? 'Erro ao alterar a conta bancária'
									: Object.values(e?.response?.data || {})[0] || ['Erro ao alterar a conta bancária'];

							notify(warningMsg, 'warning');
						});
				} else {
					notify(`${Object.values(errors)[0]}`, 'warning');
				}
			}}
			variant='text'
			color='primary'
			size='small'
			startIcon={<CheckIcon />}
		>
			Salvar
		</Button>
	);
};

export default SalvarButton;
