import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import {
	IConta,
	IContaNode,
	IModeloPrevisaoOrcamentaria,
	IMontagemPrevisaoOrcamentariaWithDadosContaNode,
	IPrevisaoOrcamentaria,
	IPrevisaoOrcamentariaFormatada,
	IPrevisaoOrcamentariaWithDadosContaNode,
	IRegistrosPrevisoesOrcamentariasPorIdConcatenado,
} from '../types';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { CustomDataProvider } from '../types';
interface ProviderProps<T> {
	listaModelos: Array<IModeloPrevisaoOrcamentaria>;
	setListaModelos: React.Dispatch<React.SetStateAction<Array<IModeloPrevisaoOrcamentaria>>>;
	modeloSelecionado: IModeloPrevisaoOrcamentaria;
	setModeloSelecionado: React.Dispatch<React.SetStateAction<IModeloPrevisaoOrcamentaria>>;
	periodo: number;
	setPeriodo: React.Dispatch<React.SetStateAction<number>>;
	referenciaInicial: Date;
	setReferenciaInicial: React.Dispatch<React.SetStateAction<Date>>;
	referenciaFinal: Date;
	setReferenciaFinal: React.Dispatch<React.SetStateAction<Date>>;
	getModelosPrevisoesOrcamentarias: () => void;
	previsaoOrcamentariaTree: IPrevisaoOrcamentariaWithDadosContaNode[];
	setPrevisaoOrcamentariaTree: React.Dispatch<
		React.SetStateAction<IPrevisaoOrcamentariaWithDadosContaNode[]> | IPrevisaoOrcamentariaWithDadosContaNode[]
	>;
	valoresTotais: {
		valorMensal: number;
		valorPeriodo: number;
		percentual: number;
	};
	setValoresTotais: React.Dispatch<
		React.SetStateAction<{
			valorMensal: number;
			valorPeriodo: number;
			percentual: number;
		}>
	>;

	openChildren: string[];
	setOpenChildren: React.Dispatch<React.SetStateAction<string[]>>;
	loading: boolean;
}

interface Props {
	children: React.ReactNode;
}

type PrevisaoOrcamentariaFinalContext = ProviderProps<IModeloPrevisaoOrcamentaria> &
	ProviderProps<IPrevisaoOrcamentaria>;

export const PrevisaoOrcamentariaContext = React.createContext<ProviderProps<PrevisaoOrcamentariaFinalContext>>(
	{} as ProviderProps<PrevisaoOrcamentariaFinalContext>
);

type ValoresTotais = {
	valorMensal: number;
	valorPeriodo: number;
	percentual: number;
};

const dataToTree: (data: IConta[]) => IContaNode[] = (data) => {
	const list = data.sort((a, b) => {
		if (a.tree_id !== b.tree_id) {
			return a.tree_id - b.tree_id;
		}
		return a.lft - b.lft;
	});

	const map = {} as { [key: string]: IContaNode };

	const formattedList = list.map((conta: Omit<IContaNode, 'children'>): IContaNode => {
		const { lft, rght, tree_id, ...rest } = conta;
		const contaNode = rest as IContaNode;
		map[contaNode.id] = contaNode;
		contaNode.children = [];
		contaNode.title = contaNode.nome;
		contaNode.valorPeriodo = 0;
		contaNode.valorMensal = 0;
		contaNode.percentual = 100;
		contaNode.level += 1;
		contaNode.modelo_previsao_orcamentaria = null;
		contaNode.previsao_orcamentaria_id = null;
		return contaNode as IContaNode;
	}) as IContaNode[];
	return formattedList.reduce((roots, node) => {
		if (!node.id_pai || !map[node.id_pai]) {
			roots.push(node);
			return roots;
		}
		const nodePai = map[node.id_pai];
		nodePai.children.push(node);
		return roots;
	}, [] as IContaNode[]);
};

export const PrevisoesOrcamentariasContextProvider = ({ children }: Props) => {
	const dataProvider = useDataProvider() as CustomDataProvider;
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const [previsaoOrcamentariaTree, setPrevisaoOrcamentariaTree] = useState<IPrevisaoOrcamentariaWithDadosContaNode[]>(
		[]
	);
	const [valoresTotais, setValoresTotais] = useState<ValoresTotais>({
		valorMensal: 0,
		valorPeriodo: 0,
		percentual: 0,
	});

	const condominioSelecionadoId = condominioSelecionado?.id;
	const [loading, setLoading] = useState(false);
	const notify = useNotify();

	const [openChildren, setOpenChildren] = useState<string[]>([]);

	const initialModeloSelecionadoState = {
		id: null,
		nome: '',
		condominio: null,
		plano_condominio: null,
		periodo: null,
		inicio: null,
		termino: null,
	};

	const [listaModelos, setListaModelos] = React.useState<Array<IModeloPrevisaoOrcamentaria>>([]);
	const [modeloSelecionado, setModeloSelecionado] =
		React.useState<IModeloPrevisaoOrcamentaria>(initialModeloSelecionadoState);
	const [periodo, setPeriodo] = React.useState(modeloSelecionado?.periodo || 1);

	const [referenciaInicial, setReferenciaInicial] = useState<Date>(modeloSelecionado?.inicio || new Date());
	referenciaInicial.setUTCHours(10, 0, 0, 0);

	const [referenciaFinal, setReferenciaFinal] = useState<Date>(modeloSelecionado?.termino || new Date());

	const modeloSelecionadoId = modeloSelecionado?.id;

	const getModelosPrevisoesOrcamentarias = React.useCallback(() => {
		dataProvider
			.getSimple('modelo_previsao_orcamentaria', {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'nome', order: 'ASC' },
				filter: { condominio_id: condominioSelecionadoId },
			})
			.then((response: any) => {
				setListaModelos(response.data.results);
			})
			.catch(() => {});
	}, [condominioSelecionadoId]);

	const getContasReceitaDespesa = async () => {
		setLoading(true);
		if (!modeloSelecionado?.id) return;
		Promise.all([
			dataProvider.getList('contas_receita', {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: 'lft,tree_id', order: 'ASC' },
				filter: { id_plano_condominio: modeloSelecionado.plano_condominio },
			}),
			dataProvider.getList('contas_despesa', {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: 'lft,tree_id', order: 'ASC' },
				filter: { id_plano_condominio: modeloSelecionado.plano_condominio },
			}),
			dataProvider.getList('previsao_orcamentaria', {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: 'id', order: 'ASC' },
				filter: { modelo_previsao_orcamentaria_id: modeloSelecionado.id },
			}),
		])
			.then((response) => {
				const listaContasReceita = response[0].data as IConta[];
				const listaContasDespesa = response[1].data as IConta[];
				const listaContasReceitaNode: IContaNode[] = dataToTree(listaContasReceita);
				for (const contaReceitaNode of listaContasReceitaNode) {
					contaReceitaNode.id_pai = -1;
				}
				const listaReceitas: IContaNode[] = [
					{
						id: -1,
						nome: 'RECEITAS',
						id_pai: null,
						classe_conta: 'T',
						tree_id: 0,
						tipo: '',
						level: 0,
						lft: 0,
						rght: 0,
						depth: 0,
						children: [...listaContasReceitaNode],
						id_concatenado: 'CR',
						title: 'RECEITAS',
						valorMensal: 0,
						valorPeriodo: 0,
						percentual: 100,
						modelo_previsao_orcamentaria: null,
						previsao_orcamentaria_id: null,
					},
				];
				const listaContasDespesaNode: IContaNode[] = dataToTree(listaContasDespesa);
				for (const contaDespesaNode of listaContasDespesaNode) {
					contaDespesaNode.id_pai = -1;
				}
				const listaDespesas: IContaNode[] = [
					{
						id: -1,
						nome: 'DESPESAS',
						id_pai: null,
						classe_conta: 'T',
						tree_id: 0,
						tipo: '',
						level: 0,
						lft: 0,
						rght: 0,
						depth: 0,
						children: [...listaContasDespesaNode],
						id_concatenado: 'CD',
						title: 'DESPESAS',
						valorMensal: 0,
						valorPeriodo: 0,
						percentual: 100,
						modelo_previsao_orcamentaria: null,
						previsao_orcamentaria_id: null,
					},
				];

				const arvoreContasMolde: IContaNode[] = listaReceitas.concat(listaDespesas);

				const listaPrevisoesOrcamentarias = response[2].data as IPrevisaoOrcamentaria[];
				const registrosPrevisoesOrcamentariasPorIdConcatenado: IRegistrosPrevisoesOrcamentariasPorIdConcatenado =
					listaPrevisoesOrcamentarias.reduce(
						(PrevisaoOrcamentariaPorIdConcatenado, previsao: IPrevisaoOrcamentaria) => {
							if (!previsao) return PrevisaoOrcamentariaPorIdConcatenado;
							const { valor_mensal, valor_periodo, id, ...rest } = previsao;
							const valorMensal = valor_mensal * 100;
							const valorPeriodo = valor_periodo * 100;
							const previsao_id = id;
							const id_concatenado = previsao.conta_despesa
								? `CD-${previsao.conta_despesa}`
								: `CR-${previsao.conta_receita}`;
							const previsaoOrcamentariaFormatada = {
								...rest,
								previsao_orcamentaria_id: previsao_id,
								valorMensal,
								valorPeriodo,
								id_concatenado,
								percentual: 100,
							} as unknown as IPrevisaoOrcamentariaFormatada;
							PrevisaoOrcamentariaPorIdConcatenado[previsaoOrcamentariaFormatada.id_concatenado] =
								previsaoOrcamentariaFormatada;
							return PrevisaoOrcamentariaPorIdConcatenado;
						},
						{} as { [key: string]: IPrevisaoOrcamentariaFormatada }
					);
				const previsoesOrcamentariasContasAnaliticas = [] as IPrevisaoOrcamentariaWithDadosContaNode[];
				const previsoesOrcamentariasPorIdConcatenado = {} as {
					[key: string]: IPrevisaoOrcamentariaWithDadosContaNode;
				};
				const periodo = modeloSelecionado.periodo || 1;
				const setaValoresParaPrevisaoOrcamentaria = (
					previsaoOrcamentariaNode: IPrevisaoOrcamentariaWithDadosContaNode
				) => {
					previsaoOrcamentariaNode.valorPeriodo = previsaoOrcamentariaNode.children.reduce(
						(
							valorPeriodo: number,
							previsaoOrcamentariaNodeFilho: IPrevisaoOrcamentariaWithDadosContaNode
						) => {
							previsaoOrcamentariaNodeFilho =
								setaValoresParaPrevisaoOrcamentaria(previsaoOrcamentariaNodeFilho);
							valorPeriodo += previsaoOrcamentariaNodeFilho.valorPeriodo;
							return valorPeriodo;
						},
						previsaoOrcamentariaNode.valorPeriodo
					);
					previsaoOrcamentariaNode.valorMensal = previsaoOrcamentariaNode.valorPeriodo / periodo;
					previsaoOrcamentariaNode.children.forEach((previsaoOrcamentariaNodeFilho) => {
						valoresTotais.valorMensal += previsaoOrcamentariaNodeFilho.valorMensal;
						valoresTotais.valorPeriodo += previsaoOrcamentariaNodeFilho.valorPeriodo;
						previsaoOrcamentariaNodeFilho.percentual = previsaoOrcamentariaNode.valorPeriodo
							? (previsaoOrcamentariaNodeFilho.valorPeriodo / previsaoOrcamentariaNode.valorPeriodo) * 100
							: 0;
					});

					return previsaoOrcamentariaNode;
				};

				const construirChildren = (children: IContaNode[]): IPrevisaoOrcamentariaWithDadosContaNode[] => {
					if (!children || !children.length) return [];
					if (children.length) return children.map(construirPrevisaoOrcamentariaNode);
					return [];
				};

				const construirPrevisaoOrcamentariaNode = (
					contaNode: IContaNode
				): IPrevisaoOrcamentariaWithDadosContaNode => {
					const previsaoOrcamentaria: IPrevisaoOrcamentariaFormatada =
						registrosPrevisoesOrcamentariasPorIdConcatenado[contaNode.id_concatenado] || {};
					const previsaoOrcamentariaNode: IMontagemPrevisaoOrcamentariaWithDadosContaNode = {
						...contaNode,
						...previsaoOrcamentaria,
						previsao_orcamentaria_id: previsaoOrcamentaria.previsao_orcamentaria_id,
					};
					previsaoOrcamentariaNode.children = construirChildren(
						previsaoOrcamentariaNode.children as IContaNode[]
					);
					if (previsaoOrcamentariaNode?.classe_conta === 'A')
						previsoesOrcamentariasContasAnaliticas.push(
							previsaoOrcamentariaNode as IPrevisaoOrcamentariaWithDadosContaNode
						);
					previsoesOrcamentariasPorIdConcatenado[previsaoOrcamentariaNode.id_concatenado] =
						previsaoOrcamentariaNode as IPrevisaoOrcamentariaWithDadosContaNode;

					return previsaoOrcamentariaNode as IPrevisaoOrcamentariaWithDadosContaNode;
				};

				const previsaoOrcamentariaTree = arvoreContasMolde
					.map(construirPrevisaoOrcamentariaNode)
					.map(setaValoresParaPrevisaoOrcamentaria);
				setPrevisaoOrcamentariaTree(previsaoOrcamentariaTree);
				setValoresTotais((v) => ({
					...v,
					...previsaoOrcamentariaTree.reduce(
						(
							{ valorMensal, valorPeriodo },
							previsaoOrcamentariaNode: IPrevisaoOrcamentariaWithDadosContaNode
						) => {
							valorMensal += previsaoOrcamentariaNode.valorMensal;
							valorPeriodo += previsaoOrcamentariaNode.valorPeriodo;
							return { valorMensal, valorPeriodo };
						},
						{ valorMensal: 0, valorPeriodo: 0 }
					),
				}));
			})
			.catch((e) => notify('Erro ao buscar configurações cadastradas', 'info'))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (modeloSelecionado && referenciaInicial && referenciaFinal) {
			const periodo = Math.round(
				(referenciaFinal.getTime() - referenciaInicial.getTime()) / (1000 * 60 * 60 * 24 * 30)
			);
			setPeriodo(periodo);
			(prev: IModeloPrevisaoOrcamentaria) => {
				return {
					periodo: periodo,
					inicio: referenciaInicial,
					termino: referenciaFinal,
					id: prev?.id,
					nome: prev?.nome,
					condominio: prev?.condominio,
					plano_condominio: prev?.plano_condominio,
				};
			};
			setModeloSelecionado((prev: any) => {
				return { ...prev, periodo: periodo, inicio: referenciaInicial, termino: referenciaFinal };
			});
		}
	}, [referenciaInicial, referenciaFinal]);

	useEffect(() => {
		setModeloSelecionado(initialModeloSelecionadoState);
	}, [condominioSelecionadoId]);

	useEffect(() => {
		getModelosPrevisoesOrcamentarias();
		getContasReceitaDespesa();
	}, [condominioSelecionadoId, modeloSelecionadoId]);

	const providerValue = React.useMemo(
		() => ({
			listaModelos,
			setListaModelos,
			modeloSelecionado,
			setModeloSelecionado,
			getModelosPrevisoesOrcamentarias,
			periodo,
			setPeriodo,
			referenciaInicial,
			referenciaFinal,
			setReferenciaInicial,
			setReferenciaFinal,
			previsaoOrcamentariaTree,
			setPrevisaoOrcamentariaTree,
			valoresTotais,
			setValoresTotais,
			openChildren,
			setOpenChildren,
			loading,
			setLoading,
		}),
		[
			listaModelos,
			setListaModelos,
			modeloSelecionado,
			setModeloSelecionado,
			getModelosPrevisoesOrcamentarias,
			periodo,
			setPeriodo,
			referenciaInicial,
			referenciaFinal,
			setReferenciaInicial,
			setReferenciaFinal,
			previsaoOrcamentariaTree,
			setPrevisaoOrcamentariaTree,
			valoresTotais,
			setValoresTotais,
			openChildren,
			setOpenChildren,
			loading,
			setLoading,
		]
	);

	return providerValue !== null ? (
		<PrevisaoOrcamentariaContext.Provider value={providerValue}>{children}</PrevisaoOrcamentariaContext.Provider>
	) : null;
};
