import React, { useContext, useMemo, useCallback } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { ContasBancariasListContext } from '../../ContasBancariasListContextProvider';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaBancariaButtonsContext from '../contexts/EditaContaBancariaButtonsContext';

const EditaContaBancariaButtonsContextProvider = ({ children }) => {
	const notify = useNotify();
	const dP = useDataProvider();
	const { setReloadFilter } = useContext(ContasBancariasListContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		condominioRecord,
		initialDados: {
			conta_condominio: { id },
		},
	} = useContext(EditaContaBancariaContext);

	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false, dialogBody: <></> }));
	}, [setModalValue]);

	const handleCancel = useCallback(() => {
		if (window.confirm('Sair agora irá ocasionar na perda dos dados da edição. Deseja sair?')) closeModal();
	}, [closeModal]);

	const handleDelete = useCallback(() => {
		dP.update('conta_condominio', {
			id: id,
			data: { situacao: 'E' },
		})
			.then(() => {
				notify('Conta bancária excluída com sucesso!');
				setReloadFilter((v) => !v);
				closeModal();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(`${e}`, 'warning');
			});
	}, [dP, id, notify, setReloadFilter, closeModal]);

	const value = useMemo(
		() => ({
			closeModal,
			handleCancel,
			handleDelete,
			condominio_situacao: condominioRecord.situacao,
		}),
		[closeModal, handleCancel, handleDelete, condominioRecord.situacao]
	);

	return (
		<EditaContaBancariaButtonsContext.Provider value={value}>{children}</EditaContaBancariaButtonsContext.Provider>
	);
};

export default EditaContaBancariaButtonsContextProvider;
