import React from 'react';
import { Autocomplete } from '@material-ui/lab';

import { consultasDisponiveis } from './consultaDisponiveis';
import { TextField } from '@material-ui/core';

function SelectConsulta({ onChange, ...props }) {
	return (
		<Autocomplete
			options={consultasDisponiveis}
			getOptionLabel={(o) => o?.name || ''}
			onChange={(_, newValue) => onChange(newValue)}
			renderInput={(params) => <TextField {...params} label='Selecionar Consulta' variant='outlined' />}
			clearText='Limpar'
			autoHighlight
			size='small'
			fullWidth
			defaultValue={''}
			{...props}
		/>
	);
}

export default SelectConsulta;
