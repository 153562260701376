import React, { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoProximo } from '../../../../../common/buttons/BotaoProximo';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../../../common/CurrencyInput';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';

const ModalFatorConvercaoNovaLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const [fatorConvercao, setFatorConvercao] = useState(dadosNovaLeitura.dados_config.fator_convercao || 2.25);
	const handleBack = () => {
		setDadosNovaLeitura((v) => {
			const { fator_convercao, ...dados_config } = v.dados_config;
			v['dados_config'] = dados_config;
			return v;
		});
		setModalStep('6FK');
	};
	const handleNext = () => {
		setDadosNovaLeitura((v) => {
			v.dados_config['fator_convercao'] = fatorConvercao;
			return v;
		});
		if ('2' === dadosNovaLeitura.dados_config.metodo_calculo) setModalStep('8FS');
		if (['1', '3'].includes(dadosNovaLeitura.dados_config.metodo_calculo)) setModalStep('9FC');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Informe o fator de conversão'
			form={{
				component: (
					<TextField
						fullWidth
						name='valorFatorConvecao'
						id='valorFatorConvecaoID'
						label='Fator de conversão'
						value={fatorConvercao}
						onChange={(event) => {
							if (!event.target.value) event.target.value = 0;
							if (event.target.value > 999.99) event.target.value = 999.99;
							setFatorConvercao(event.target.value);
						}}
						inputProps={sanitizeListNumberFormatBRLProps({
							decimalScale: 2,
						})}
						InputProps={{
							inputComponent: NumberFormatBRL,
						}}
						margin='dense'
						style={{ marginTop: '8px' }}
					/>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo onClick={handleNext} />
				</>
			}
		/>
	);
};

export default ModalFatorConvercaoNovaLeitura;
