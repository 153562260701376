import React from 'react';

import { Box } from '@material-ui/core';

import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const MoedaField = () => (
	<Box pl={1} flex={1} sx={{ 'max-width': '17%' }}>
		<EditaContaBancariaAutocomplete source='moeda' name='moeda' id='moeda' label='Moeda' translateChoice={false} />
	</Box>
);

export default MoedaField;
