import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

interface ITooltipIconButtonProps extends React.ComponentProps<typeof IconButton> {
	id?: string;
	key?: string;
	title: string;
}

export const TooltipIconButton: React.FC<ITooltipIconButtonProps> = ({ id, key, children, title, ...rest }) => (
	<Tooltip title={title} id={id} key={key} itemID={`${id}-item`}>
		<span>
			<IconButton {...rest}>{children}</IconButton>
		</span>
	</Tooltip>
);
