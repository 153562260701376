import React, { useContext, useState } from 'react';
import InputDateReferencia from '../../componentes/InputDateReferencia';
import NovaLeituraContext from './NovaLeituraContext';

const CampoReferencia = () => {
	const {
		dadosNovaLeitura: {
			dados_config: { referencia },
		},
	} = useContext(NovaLeituraContext);
	const [value] = useState<Date>(referencia ? new Date(`${referencia}T10:00`) : new Date());

	return (
		<InputDateReferencia
			disabled
			initialFocusedDate={value}
			label='Referência'
			value={value}
			style={{ marginRight: '0.5em' }}
		/>
	);
};

export default CampoReferencia;
