import { makeStyles, alpha } from '@material-ui/core';

export const useStylesFormsButton = makeStyles((theme) => ({
	saveButton: {
		color: theme.palette.primary.main,
		transition: 'all 100ms ease',
		'&:hover': {
			backgroundColor: alpha(theme.palette.primary.main, 0.95),
			color: '#f5f5f5',

			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
	},
	deleteButton: {
		color: '#CA3433',
		transition: 'all 200ms ease',
		'&:hover': {
			backgroundColor: alpha('#CA3433', 0.95),
			color: '#f5f5f5',

			// Reset on mouse devices
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
	},
}));
