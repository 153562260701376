import React, { useState } from 'react';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { format, isValid } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelect, RelatorioInputDate, periodoOptions, TODAY } from '../dateUtils';
import RelatorioToolbar from '../RelatorioToolbar';
import MultiSelect from 'components/common/Selects';
import { useRequestOptionsUnidades, useRequestOptionsIdentificacaoArrecadacao } from 'components/common/Selects/hooks';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesImpressaoCheckboxGroup = ({
	handleChange,
	imprimirTotalUnidade,
	pularPaginaUnidade,
	imprimirAssinatura,
}) => {
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTotalUnidade}
							onChange={(e, checked) => handleChange(checked, 'imprimir_total_by_unidade_correcao')}
							name='imprimir_total_by_unidade_correcao'
						/>
					}
					label='Imprimir total por unidade'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={pularPaginaUnidade}
							onChange={(e, checked) => handleChange(checked, 'pular_paginas_entre_unidades')}
							name='pular_paginas_entre_unidades'
						/>
					}
					label='Pular página entre unidades'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</FormGroup>
		</FormControl>
	);
};

const AutocompleteIdentificacao = ({ identificacao, setIdentificacao, optionsIdentificacoes }) =>
	(optionsIdentificacoes || null) && (
		<Autocomplete
			value={identificacao}
			onChange={(_, newValue) => setIdentificacao(newValue)}
			options={[...Object.values(optionsIdentificacoes).filter((v) => v.id !== 0), { id: 0, nome: 'Todas' }]}
			getOptionLabel={(o) => o.nome}
			getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
			renderInput={(params) => <TextField {...params} label='Identificação de Arrecadação' variant='outlined' />}
			disabled={optionsIdentificacoes.length <= 1}
			clearText='Limpar'
			autoHighlight
			noOptionsText='Nenhuma identificação disponível'
			size='small'
			fullWidth
		/>
	);

const CorrecaoArrecadacoesAtrasadasDivisor = ({ idCondominio, tipoRelatorio }) => {
	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const [dataInicial, setDataInicial] = useState();
	const [dataFinal, setDataFinal] = useState();
	const [dataPagamento, setDataPagamento] = useState(format(TODAY, 'dd/MM/yyyy'));
	const [dataBase, setDataBase] = useState(format(TODAY, 'dd/MM/yyyy'));
	const [unidades, setUnidades] = React.useState([]);
	const { options: optionsUnidades } = useRequestOptionsUnidades();
	const { options: optionsIdentificacoes } = useRequestOptionsIdentificacaoArrecadacao(idCondominio);
	const {
		opcoesImpressao: { imprimir_total_by_unidade_correcao, pular_paginas_entre_unidades, imprimir_assinatura },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);
	const [identificacao, setIdentificacao] = useState({
		id: 0,
		nome: 'Todas',
	});
	const requestData = {
		id_condominio: idCondominio,
		identificacao_id: identificacao?.id,
		data_inicial: dataInicial,
		data_final: dataFinal,
		unidades: unidades?.map((v) => v.id),
		data_base: dataBase,
		data_pagamento: dataPagamento,
		nome_relatorio: tipoRelatorio.action,
		imprimir_total_by_unidade_correcao: imprimir_total_by_unidade_correcao,
		pular_paginas_entre_unidades: pular_paginas_entre_unidades,
		imprimir_assinatura: imprimir_assinatura,
	};

	React.useEffect(() => {
		setUnidades([]);
	}, [idCondominio]);

	return (
		<BoxDivisor titulo='Correção de Arrecadações Atrasadas'>
			<Box display='flex' mb='1em' gridGap={'1rem'} alignItems={'center'}>
				<Box flex={1}>
					<PeriodoSelect
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={dataInicial}
						setDataInicial={setDataInicial}
						setDataFinal={setDataFinal}
						dataFinal={dataFinal}
						label='Selecionar Período'
					/>
				</Box>
				<Box flex={1}>
					<AutocompleteIdentificacao
						{...{
							identificacao,
							setIdentificacao,
							optionsIdentificacoes,
						}}
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em' gridGap={'1rem'}>
				<Box flex={1}>
					<MultiSelect
						variant='formatsUnidades'
						setValue={setUnidades}
						options={optionsUnidades}
						value={unidades}
						size='small'
					/>
				</Box>
				<Box flex={1} mt={'0.5rem'}>
					<Box display='flex'>
						<RelatorioInputDate
							style={{ marginRight: '1em' }}
							label='Pagamentos até'
							handleChange={(_, newValue) => setDataPagamento(newValue)}
							onBlur={() => {
								if (!isValid(dataPagamento)) setDataPagamento(format(TODAY, 'dd/MM/yyyy'));
							}}
							value={dataPagamento}
							fullWidth
						/>
						<RelatorioInputDate
							label='Data Base'
							handleChange={(_, newValue) => setDataBase(newValue)}
							onBlur={() => {
								if (!isValid(dataBase)) setDataBase(format(TODAY, 'dd/MM/yyyy'));
							}}
							value={dataBase}
							fullWidth
						/>
					</Box>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<OpcoesImpressaoCheckboxGroup
					handleChange={handleChangeOpcoesImpressao}
					imprimirTotalUnidade={imprimir_total_by_unidade_correcao}
					pularPaginaUnidade={pular_paginas_entre_unidades}
					imprimirAssinatura={imprimir_assinatura}
				/>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};

export default CorrecaoArrecadacoesAtrasadasDivisor;
