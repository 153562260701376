import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ModalFeedback from '../../Arrecadacoes/ModalFeedback';

const ModalFeedbackArrecadacoesRecorrentes = ({ fulfilledResponses, rejectedResponses }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<ModalFeedback
			component={
				<>
					{fulfilledResponses.length ? (
						<Typography>
							{fulfilledResponses.length > 1
								? `${fulfilledResponses.length} arrecadações geradas com sucesso!`
								: rejectedResponses.length
								? '1 arrecadação gerada com sucesso!'
								: 'Arrecadação gerada com sucesso!'}
						</Typography>
					) : null}
					{rejectedResponses.map(({ reason }) => (
						<Typography style={{ color: syndikosRed.main }}>
							{reason.data
								? `${reason?.data?.complemento || ''}: ${
										Object.values(reason?.e?.response?.data || {})[0] || ''
								  }`
								: ''}
						</Typography>
					))}
				</>
			}
			title='Geração de arrecadações recorrentes finalizadas'
		/>
	);
};
export default ModalFeedbackArrecadacoesRecorrentes;
