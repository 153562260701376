import React from 'react';

import { Field, useFormState, useForm } from 'react-final-form';

import { RichUtils, EditorState, Modifier } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';

import { Box } from '@material-ui/core';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';

import { TextInput, maxLength, required } from 'react-admin';

import { BlockAlinhamentoProps, ComunicadoGeralFormProps, TypePropsBlock } from './types';
import { BoxDivisor, FormularioBox } from 'components/common/Formulario';
import { CondominiosContext } from 'context/CondominioContextProvider';
import InputFile from 'components/common/InputFile';
import { SyndikosSelect } from 'components/common/SyndikosSelect';
import VeryHighLimitReferenceInput from 'components/common/VeryHighLimitReferenceInput';
import FieldSelectUnidades from './modal/selectUnidades';
import ModalConfirmEnvioDeEmail from './modal/modalCreateComunicadoPersonalizado';
import ConfirmUpdatePersonalizado from './modal/modalUpdateComunicadoPersonalizado';

const opcoesParticipantes = [
	{ id: 'P', name: 'Proprietário' },
	{ id: 'I', name: 'Inquilino' },
	{ id: 'A', name: 'Ambos' },
	{ id: 'R', name: 'Responsável pela Cobrança' },
];

const BlockAlinhamento: React.FC<BlockAlinhamentoProps> = ({ alinhamento, ...props }) => {
	return (
		<div
			style={{
				textAlign: alinhamento,
				padding: 10,
			}}
		>
			{props.children}
		</div>
	);
};

const createCallbackParaEstilizacaoDeFontSizes = (inlineStyle: string) => {
	const inlineStylesParaRemover = ['FONT-SIZE-32', 'FONT-SIZE-24', 'FONT-SIZE-18', 'FONT-SIZE-16'].filter(
		(inlineStyleParaRemover) => inlineStyle != inlineStyleParaRemover
	);
	return (editorState: EditorState, name: string, anchor: null | Element) => {
		const editorStateWithNewInlineStyle = RichUtils.toggleInlineStyle(editorState, name);
		const inlineStyleApplyed = editorStateWithNewInlineStyle.getCurrentInlineStyle().has(name);
		const contentState = editorStateWithNewInlineStyle.getCurrentContent();
		if (!inlineStyleApplyed) {
			const finalEditorState = EditorState.push(
				editorStateWithNewInlineStyle,
				contentState,
				'change-inline-style'
			);
			return EditorState.forceSelection(finalEditorState, finalEditorState.getSelection());
		}

		const selectionState = editorStateWithNewInlineStyle.getSelection();
		const contentStateCleaned = inlineStylesParaRemover.reduce((contentState, inlineStyle) => {
			return Modifier.removeInlineStyle(contentState, selectionState, inlineStyle);
		}, contentState);

		const finalEditorState = EditorState.push(
			editorStateWithNewInlineStyle,
			contentStateCleaned,
			'change-inline-style'
		);
		return EditorState.forceSelection(finalEditorState, finalEditorState.getSelection());
	};
};

const callbackFontSize32 = createCallbackParaEstilizacaoDeFontSizes('FONT-SIZE-32');
const callbackFontSize28 = createCallbackParaEstilizacaoDeFontSizes('FONT-SIZE-24');
const callbackFontSize18 = createCallbackParaEstilizacaoDeFontSizes('FONT-SIZE-18');
const callbackFontSize16 = createCallbackParaEstilizacaoDeFontSizes('FONT-SIZE-16');

const RichTextComunicado = ({ setTextAreaEditor }: any) => {
	const { change } = useForm();
	const { initialValues } = useFormState();
	const textRef = React.useRef<any>();

	const handleSaveEmail = (data: string) => {
		const { blocks } = JSON.parse(data);
		if (blocks[0].text !== '') {
			const lines = blocks.map((item: TypePropsBlock) => {
				const inlineStyleRanges = item.inlineStyleRanges.sort((a, b) => {
					const limitA = a.offset + a.length;
					const limitB = b.offset + b.length;

					if (limitA === limitB) {
						return a.offset <= b.offset ? -1 : 1;
					}
					return limitA <= limitB ? 1 : -1;
				});
				return { ...item, inlineStyleRanges };
			});
			change('mensagem_json', data);
			change('mensagem', JSON.stringify(lines));
		}
	};

	return (
		<Box width='100%' maxWidth='81vw'>
			<MUIRichTextEditor
				label='Digite aqui o comunicado...'
				defaultValue={initialValues['mensagem_json'] ?? ''}
				inlineToolbar
				toolbarButtonSize='small'
				ref={textRef}
				onSave={(data) => handleSaveEmail(data)}
				onBlur={() => textRef.current.save()}
				onChange={(e: any) => setTextAreaEditor(e.getCurrentContent().getPlainText())}
				maxLength={1000}
				controls={[
					'FONT-SIZE-16',
					'FONT-SIZE-18',
					'FONT-SIZE-24',
					'FONT-SIZE-32',
					'bold',
					'italic',
					'underline',
					'strikethrough',
					'undo',
					'redo',
					'clear',
					'numberList',
					'bulletList',
					'align-left',
					'align-center',
					'align-right',
				]}
				customControls={[
					{
						name: 'align-left',
						icon: <FormatAlignLeftIcon />,
						type: 'block',
						blockWrapper: <BlockAlinhamento alinhamento={'left'} />,
					},
					{
						name: 'align-center',
						icon: <FormatAlignCenterIcon />,
						type: 'block',
						blockWrapper: <BlockAlinhamento alinhamento={'center'} />,
					},
					{
						name: 'align-right',
						icon: <FormatAlignRightIcon />,
						type: 'block',
						blockWrapper: <BlockAlinhamento alinhamento={'right'} />,
					},
					{
						name: 'FONT-SIZE-16',
						icon: <span>16pt</span>,
						type: 'callback',
						onClick: callbackFontSize16,
					},
					{
						name: 'FONT-SIZE-18',
						icon: <span>18pt</span>,
						type: 'callback',
						onClick: callbackFontSize18,
					},
					{
						name: 'FONT-SIZE-24',
						icon: <span>24pt</span>,
						type: 'callback',
						onClick: callbackFontSize28,
					},
					{
						name: 'FONT-SIZE-32',
						icon: <span>32pt</span>,
						type: 'callback',
						onClick: callbackFontSize32,
					},
				]}
				draftEditorProps={
					{
						customStyleMap: {
							'FONT-SIZE-16': {
								fontSize: '16pt',
							},
							'FONT-SIZE-18': {
								fontSize: '18pt',
							},
							'FONT-SIZE-24': {
								fontSize: '24pt',
							},
							'FONT-SIZE-32': {
								fontSize: '32pt',
							},
						},
					} as any
				}
			/>
		</Box>
	);
};

const ComunicadoGeralForm: React.FC<ComunicadoGeralFormProps> = ({ condominio, ...props }: any) => {
	const { condominioSelecionado, setCondominioPorId } = React.useContext(CondominiosContext);
	const [textAreaEditor, setTextAreaEditor] = React.useState();

	return (
		<FormularioBox
			customSaveButton={
				props?.match.path === `${props?.basePath}/create` ? (
					<ModalConfirmEnvioDeEmail text={textAreaEditor} {...props} />
				) : (
					<ConfirmUpdatePersonalizado text={textAreaEditor} {...props} />
				)
			}
			{...props}
			nome={undefined}
			onClickBotaoRemover={undefined}
			dictLabels={undefined}
			getChipClassName={undefined}
			onlyExcluir={undefined}
			noActionToolbar={undefined}
		>
			<Box flex={1} mr='1em'>
				<BoxDivisor titulo={'Novo Comunicado'}>
					<Box display='flex' flex={1} gridGap='2em' ml={1}>
						<Box display='flex' flex={1}>
							<VeryHighLimitReferenceInput
								source='condominio'
								reference='condominios'
								label='Condomínio'
								fullWidth
								variant='outlined'
								validate={required()}
								onChange={(value: number) => {
									setCondominioPorId(value);
								}}
								filter={{ situacao: 'A' }}
								initialValue={condominioSelecionado?.id}
							>
								<SyndikosSelect optionText='nome' style={{ marginBottom: 24 }} disableClearable />
							</VeryHighLimitReferenceInput>
						</Box>
						<Box display='flex' flex={1}>
							<SyndikosSelect
								required
								source='participantes'
								label='Enviar Para'
								validate={required()}
								choices={opcoesParticipantes}
								delay={0}
								fullWidth
							/>
						</Box>
					</Box>
				</BoxDivisor>
				<Box flex={1} gridGap='1em' ml={1}>
					<FieldSelectUnidades props={props} />
				</Box>
				<BoxDivisor titulo='Descrição' mt={2}>
					<Box display='flex' flex={1} ml={1} mt={2}>
						<TextInput
							label='Assunto'
							source='titulo'
							variant='outlined'
							size='small'
							fullWidth
							validate={[required(), maxLength(100, 'Máximo de 100 caractéres')]}
						/>
					</Box>
					<Box
						display='flex'
						flex={1}
						ml={1}
						mb={2}
						style={{
							backgroundColor: 'white',
							color: 'black',
							marginTop: '10px',
						}}
						border={1}
						borderRadius='borderRadius'
					>
						<Field name='mensagem_json' id='mensagem_json'>
							{() => <RichTextComunicado setTextAreaEditor={setTextAreaEditor} />}
						</Field>
					</Box>
					<Box display='flex' flex={0.5} ml={1}>
						<Field name='write_only_anexo_comunicado_geral' id='write_only_anexo_comunicado_geral'>
							{({ input }) => (
								<InputFile
									source='write_only_anexo_comunicado_geral'
									label='Arquivo em anexo'
									variant='outlined'
									onCallBack={(e: any, dataUrl: string) => {
										input.onChange(dataUrl);
									}}
									fileDataUrl={props.record?.write_only_anexo_comunicado_geral}
								/>
							)}
						</Field>
					</Box>
				</BoxDivisor>
			</Box>
		</FormularioBox>
	);
};
export default ComunicadoGeralForm;
