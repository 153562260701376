import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

export const BoxUploadMultipleFiles = ({ setAnexosDados, anexosValidos, setLoadingRequest }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();

	const getBase64FromFile = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const onChangeFiles = async (files) => {
		if (files) {
			setLoadingRequest?.(true);
			let novos_arquivos = [];
			let soma_tamanho_total = 0;

			files.map((file) => (soma_tamanho_total += file.size));

			await files.map(async (file) => {
				return await getBase64FromFile(file).then((base64_file) =>
					novos_arquivos.push({
						name: file.name,
						type: file.type,
						size: file.size,
						base64_file: base64_file.split(',')[1],
					})
				);
			});

			setAnexosDados({
				anexosValidos: files.length <= 3 && soma_tamanho_total <= 5242880,
				anexos: novos_arquivos,
			});
			setLoadingRequest?.(false);
		}
	};

	return (
		<>
			<Typography variant='body2' gutterBottom>
				Selecionar arquivo para enviar em anexo ao e-mail do boleto:
			</Typography>
			<Box display='flex' mb='1em'>
				<FormGroup>
					<FormControlLabel
						control={
							<input
								accept='.pdf,.xls,.doc'
								type='file'
								multiple
								onChange={(e) => onChangeFiles(Array.from(e.target.files))}
							/>
						}
						style={{ marginLeft: 0 }}
					/>
				</FormGroup>
			</Box>
			<Typography variant='caption' display='block' gutterBottom>
				Aceita arquivo PDF, XLS e DOC. Com tamanho de até 5MB no total.
			</Typography>
			{!anexosValidos && (
				<Typography variant='caption' display='block' gutterBottom style={{ color: syndikosRed.main }}>
					Se passar os 5MB adicionados é bloqueado o envio de e-mail.
				</Typography>
			)}
		</>
	);
};

export default BoxUploadMultipleFiles;
