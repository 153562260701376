import React from 'react';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { useDataProvider, useNotify } from 'react-admin';

export const useRequestOptionsUnidades = (filterRequisicao = null) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const [options, setOptions] = React.useState([]);
	const notify = useNotify();
	const dp = useDataProvider();

	const handleReqOptions = () => {
		dp.getSimple('unidades', {
			pagination: { page: 1, perPage: 10000 },
			filter: filterRequisicao
				? { ...filterRequisicao }
				: {
						id_condominio: condominioSelecionadoId,
						situacao: 'A',
				  },
			sort: { field: 'nome_grupo,unidade', order: 'ASC' },
		})
			.then(({ data: { results } }) => {
				setOptions(results);
			})
			.catch(() => {
				notify('Não foi possível coletar as unidades', 'warning');
			});
	};
	React.useEffect(handleReqOptions, [condominioSelecionadoId]);

	return {
		options,
	};
};

export const useRequestOptionsContasComFiltros = (filterRequisicao = null) => {
	const notify = useNotify();
	const dp = useDataProvider();
	const [options, setOptions] = React.useState([]);
	const hasFilterChanges = JSON.stringify(filterRequisicao);

	const handleReqContas = () => {
		if (!filterRequisicao) return;
		Promise.allSettled([
			dp
				.getSimple('contas_receita', {
					pagination: { page: 1, perPage: 10000 },
					sort: { field: 'tree_id, lft', order: 'ASC' },
					filter: filterRequisicao,
				})
				.catch(() => {
					notify('Não foi possível coletar as contas receitas', 'warning');
				}),
			dp
				.getSimple('contas_despesa', {
					pagination: { page: 1, perPage: 10000 },
					sort: { field: 'tree_id, lft', order: 'ASC' },
					filter: filterRequisicao,
				})
				.catch(() => {
					notify('Não foi possível coletar as contas', 'warning');
				}),
		]).then(
			([
				{
					value: {
						data: { results: receitas },
					},
				},
				{
					value: {
						data: { results: despesas },
					},
				},
			]) => {
				setOptions([
					...receitas.map((conta) => ({
						...conta,
						tipoConta: 'Receita',
					})),
					...despesas.map((conta) => ({
						...conta,
						tipoConta: 'Despesa',
					})),
				]);
			}
		);
	};

	React.useEffect(handleReqContas, [hasFilterChanges]);

	return {
		options,
	};
};

export const useRequestOptionsContas = (condominioSelecionadoId) => {
	const notify = useNotify();
	const dp = useDataProvider();
	const [options, setOptions] = React.useState([]);

	const handleReqContas = () => {
		Promise.allSettled([
			dp
				.getSimple('contas_receita', {
					pagination: { page: 1, perPage: 10000 },
					sort: { field: 'tree_id, lft', order: 'ASC' },
					filter: {
						id_plano_condominio__condominios: condominioSelecionadoId,
						id_condominio_id: condominioSelecionadoId,
						classe_conta: 'A',
					},
				})
				.catch(() => {
					notify('Não foi possível coletar as contas receitas', 'warning');
				}),
			dp
				.getSimple('contas_despesa', {
					pagination: { page: 1, perPage: 10000 },
					sort: { field: 'tree_id, lft', order: 'ASC' },
					filter: {
						id_plano_condominio__condominios: condominioSelecionadoId,
						id_condominio_id: condominioSelecionadoId,
						classe_conta: 'A',
					},
				})
				.catch(() => {
					notify('Não foi possível coletar as contas', 'warning');
				}),
		]).then(
			([
				{
					value: {
						data: { results: receitas },
					},
				},
				{
					value: {
						data: { results: despesas },
					},
				},
			]) => {
				setOptions([
					...receitas.map((conta) => ({
						...conta,
						tipoConta: 'Receita',
					})),
					...despesas.map((conta) => ({
						...conta,
						tipoConta: 'Despesa',
					})),
				]);
			}
		);
	};

	React.useEffect(handleReqContas, [condominioSelecionadoId]);

	return {
		options,
	};
};

export const useRequestOptionsLeituras = (condominioSelecionadoId) => {
	const [options, setOptions] = React.useState([]);
	const notify = useNotify();
	const dp = useDataProvider();

	const handleReqOptions = () => {
		dp.getSimple('relatorios/get_leituras_for_demonstrativo', {
			pagination: { page: 1, perPage: 10000 },
			sort: { field: 'id', order: 'ASC' },
			filter: {
				id_condominio: condominioSelecionadoId,
			},
		})
			.then(({ data: { results } }) => {
				setOptions(results);
			})
			.catch(() => {
				notify('Não foi possível coletar as contas', 'warning');
			});
	};
	React.useEffect(handleReqOptions, [condominioSelecionadoId]);

	return {
		options,
	};
};
export const useRequestOptionsIdentificacaoArrecadacao = (condominioSelecionadoId) => {
	const [options, setOptions] = React.useState([]);
	const notify = useNotify();
	const dp = useDataProvider();

	const handleReqOptions = () => {
		dp.getSimple('identificacao_arrecadacao', {
			pagination: { page: 1, perPage: 10000 },
			sort: { field: 'id', order: 'ASC' },
			filter: {
				id_condominio: condominioSelecionadoId,
			},
		})
			.then(({ data: { results } }) => {
				setOptions(results);
			})
			.catch(() => {
				notify('Não foi possível coletar as identificações', 'warning');
			});
	};
	React.useEffect(handleReqOptions, [condominioSelecionadoId]);

	return {
		options,
	};
};
