import { Box, TextField, Button } from '@material-ui/core';
import { CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import SimpleFormIteratorV1 from 'components/common/SimpleFormIteratorV1';
import NumberFormat from 'react-number-format';
import { sanitizeListNumberFormatBRLProps } from 'components/common/CurrencyInput';
import Form from 'components/resources/assembleia_virtual/form';
import React, { createContext, useContext, useCallback } from 'react';
import { ArrayInput, FormDataConsumer, useNotify } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';
import { BoxSelectContaPlanoV2 } from 'components/resources/movimentacoes/components/BoxSelectContaPlano';
import { CustomDialog, ModalContextProvider } from 'components/common/ModalContext';
import { ButtonCancel } from 'components/common/commonComponentsTSX';
import { AcordosParcelasFluxoModaisContext } from '../contexts/AcordosParcelasFluxoModaisContext';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

function NumberFormatBRL(props) {
	const { inputRef, onChange, allowNegative = false, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.floatValue,
					},
				});
			}}
			thousandSeparator='.'
			decimalSeparator=','
			isNumericString
			fixedDecimalScale={true}
			allowNegative={allowNegative}
		/>
	);
}

export const ModalDetailContext = createContext(undefined);

const AcrescimosEDescontosForm = React.memo(
	({ setDetalhamentoAcrescimosEDescontos, setStep, condominioSelecionado }) => {
		const { values } = useFormState();
		const { change } = useForm();
		const notify = useNotify();
		const theme = useTheme();
		const handleChange = useCallback(
			(field, value) => {
				change(field, value);
			},
			[change]
		);

		const confirmAndNextStep = (values) => {
			setDetalhamentoAcrescimosEDescontos(values);
			setStep('1B');
		};

		const closeModalAndBackStep = () => {
			setStep('1B');
		};

		const handleConfirm = () => {
			const filteredValues = filterEmptyObjects(values);

			if (!validateForm(filteredValues)) {
				notify('Preencha todos os campos vazios antes de lançar descontos e acréscimos', 'error');
				return;
			}

			confirmAndNextStep(filteredValues);
		};

		const filterEmptyObjects = (data) => {
			const filteredData = { ...data };

			if (Array.isArray(filteredData.acrescimos_e_descontos)) {
				filteredData.acrescimos_e_descontos = filteredData.acrescimos_e_descontos.filter((item) => {
					return item && Object.values(item).some((value) => value !== '');
				});
			}

			return filteredData;
		};

		const validateForm = useCallback((data) => {
			const isValid = (array) =>
				Array.isArray(array) &&
				array.every((item) => {
					if (item.tipo === 'desconto') {
						return item?.descricao && item?.valor && item?.conta_receita;
					} else if (item.tipo === 'acrescimo') {
						return item?.descricao && item?.valor && item?.conta_receita;
					}
					return false;
				});

			return isValid(data.acrescimos_e_descontos);
		}, []);

		return (
			<Box flex={1} display='flex' flexDirection='column' minWidth={'100%'}>
				<Box flex={1} display='flex' flexDirection='row'>
					<Box flexDirection={'column'} justifyContent={'flex-start'}>
						<ArrayInput source='acrescimos_e_descontos' label=''>
							<SimpleFormIteratorV1
								addButton={
									<Button variant='filled' color='primary' style={{ color: theme.blue[500] }}>
										Adicionar novos valores
									</Button>
								}
								disableReordering={true}
							>
								<FormDataConsumer>
									{({ scopedFormData, getSource }) => (
										<Box
											display='flex'
											flexDirection='row'
											mb='15px'
											mt='5px'
											alignContent={'center'}
										>
											<Autocomplete
												style={{
													marginRight: '10px',
												}}
												options={['desconto', 'acrescimo']}
												getOptionLabel={(option) => {
													if (option === 'acrescimo') return 'Acréscimos';
													if (option === 'desconto') return 'Descontos';
													return '';
												}}
												onChange={(event, newValue) =>
													handleChange(getSource('tipo'), newValue)
												}
												value={scopedFormData?.tipo || ''}
												renderInput={(params) => (
													<TextField
														{...params}
														style={{
															minWidth: '17vw',
														}}
														label='Tipo de operação'
														variant='outlined'
														size='small'
													/>
												)}
											/>

											<TextField
												size='small'
												value={scopedFormData?.descricao || ''}
												label='Descrição'
												fullWidth
												onChange={(e) => handleChange(getSource('descricao'), e.target.value)}
												style={{
													minWidth: '17vw',
													padding: 0,
													background: 'none',
												}}
												inputProps={{ maxLength: 60 }}
											/>
											<BoxSelectContaPlanoV2
												id={condominioSelecionado.id_plano_condominio}
												selectedConta={scopedFormData?.conta_receita}
												setSelectedConta={(selectedConta) => {
													handleChange(getSource('conta_receita'), selectedConta);
												}}
												filtroTipoContaReceita={['O', 'E']}
												operacao={{ tipo: 'receita' }}
												ModalDetailContext={ModalDetailContext}
												disabled={false}
												style={{
													minWidth: '20vw',
													margin: '0 10px 0 10px',
													height: '40px',
													fontSize: '0.8rem',
												}}
											/>
											<CustomDialog Context={ModalDetailContext} />
											<TextField
												label='Valor'
												variant='outlined'
												size='small'
												style={{
													minWidth: '17vw',
													height: '45px',
												}}
												value={scopedFormData?.valor || ''}
												fullWidth
												onChange={(e) => handleChange(getSource('valor'), e.target.value)}
												inputProps={{
													...sanitizeListNumberFormatBRLProps({
														prefix: 'R$ ',
													}),
													style: { textAlign: 'right' },
												}}
												InputProps={{
													inputComponent: NumberFormatBRL,
												}}
												align='right'
											/>
										</Box>
									)}
								</FormDataConsumer>
							</SimpleFormIteratorV1>
						</ArrayInput>
					</Box>
				</Box>

				<Box display='flex' justifyContent='flex-end' mt={2}>
					<ButtonCancel onClick={closeModalAndBackStep} />
					<ButtonConfirmV2 onClick={handleConfirm} style={{ marginLeft: '5px' }}>
						<>Lançar</>
					</ButtonConfirmV2>
				</Box>
			</Box>
		);
	}
);

const AcrescimosEDescontosModal = ({ setStep }) => {
	const { detalhamentoAcrescimosEDescontos, setDetalhamentoAcrescimosEDescontos, condominioSelecionado } = useContext(
		AcordosParcelasFluxoModaisContext
	);
	const initialValues = () => {
		if (detalhamentoAcrescimosEDescontos) {
			const { acrescimos_e_descontos } = detalhamentoAcrescimosEDescontos;

			const hasItens =
				acrescimos_e_descontos?.filter((item) => item?.tipo == 'acrescimo' || item?.tipo == 'desconto').length >
				0;

			if (hasItens) {
				return {
					acrescimos_e_descontos: acrescimos_e_descontos,
				};
			}
		}

		return {
			acrescimos_e_descontos: [
				{
					descricao: '',
					valor: '',
				},
			],
		};
	};

	return (
		<ModalContextProvider Context={ModalDetailContext}>
			<CustomDialogBodySizeEditable
				DialogContentCustomStyleActions={{ justifyContent: 'flex-start', padding: '0 8px 8px 8px' }}
				title={'Acréscimos e Descontos'}
				customActions={
					<Form
						onSubmit={(e) => {
							e.preventDefault();
						}}
						initialValues={initialValues}
						mutators={{ ...arrayMutators }}
						render={() => (
							<ThemeProvider
								theme={(outerTheme) => ({
									...outerTheme,
									overrides: {
										...outerTheme.overrides,
										RaSimpleFormIterator: {
											indexContainer: { display: 'none' },
										},
									},
								})}
							>
								<Box display='flex' flexDirection='column' height='100%' margin={'0 16px 16px 16px'}>
									<AcrescimosEDescontosForm
										condominioSelecionado={condominioSelecionado}
										setDetalhamentoAcrescimosEDescontos={setDetalhamentoAcrescimosEDescontos}
										setStep={setStep}
									/>
								</Box>
							</ThemeProvider>
						)}
					/>
				}
			></CustomDialogBodySizeEditable>
		</ModalContextProvider>
	);
};

export default AcrescimosEDescontosModal;
