import {
	Box,
	Paper,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	Checkbox,
	Typography,
	Button,
} from '@material-ui/core';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';
import { BotaoProximo } from 'components/common/buttons/BotaoProximo';
import AddIcon from '@material-ui/icons/Add';
import { NumberFormatBRL } from 'components/common/CurrencyInput';
import { AcordosParcelasFluxoModaisContext } from '../contexts/AcordosParcelasFluxoModaisContext';
import { useDataProvider, useNotify, TopToolbar } from 'react-admin';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';
import { ButtonCancel } from 'components/common/commonComponentsTSX';

function calcularDiasAposVencimento(dataInicial, dataFinal) {
	let diasAposVencimento = 0;

	let currentDate = new Date(dataInicial);
	currentDate.setDate(currentDate.getDate() + 1);

	while (currentDate <= dataFinal) {
		diasAposVencimento++;
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return diasAposVencimento;
}

const calcularTotaisAcrescimosEDescontos = (dados) => {
	const totalDescontos = (dados?.acrescimos_e_descontos || []).reduce((total, item) => {
		return item.tipo === 'desconto' ? total + item.valor : total;
	}, 0);

	const totalAcrescimos = (dados?.acrescimos_e_descontos || []).reduce((total, item) => {
		return item.tipo === 'acrescimo' ? total + item.valor : total;
	}, 0);

	return {
		totalAcrescimos,
		totalDescontos,
	};
};

const CurrencyFormat = ({ value }) => (
	<NumberFormatBRL
		displayType='text'
		value={value}
		fixedDecimalScale
		onChange={() => {}}
		decimalScale={2}
		allowNegative={true}
	/>
);

const BoxInfo = ({ title, number, currency = true, color = 'rgba(33, 150, 243, 0.1)' }) => {
	return (
		<Box mb='10px'>
			<Paper
				style={{
					padding: '5px 10px',
					backgroundColor: color,
				}}
			>
				<Typography variant='overline' color='textSecondary' style={{ fontWeight: 'bold' }}>
					{title}
				</Typography>
				<Typography
					variant='h5'
					color='textSecondary'
					style={{
						fontWeight: 'bold',
						fontSize: '15pt',
					}}
				>
					{currency ? (
						<div style={{ textAlign: 'right' }}>
							R$ <CurrencyFormat value={number} />
						</div>
					) : (
						number || 0
					)}
				</Typography>
			</Paper>
		</Box>
	);
};

const formataVencimento = (date) => {
	const [year, month, day] = date.split('-');
	const formattedDate = `${day}/${month}/${year}`;
	return formattedDate;
};

function formatarValor(numero) {
	return Number(numero)
		.toFixed(2)
		.replace('.', ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const FormataResponvavel = (tipo_responsavel) => {
	const dict_responsavel = { I: 'Inquilino', P: 'Proprietário' };
	return dict_responsavel[tipo_responsavel];
};

function getValorMulta(valorPendente, fine_percentage) {
	const multa = valorPendente * (fine_percentage / 100);
	return parseFloat(multa.toFixed(2));
}

function getValorCorrecao(valorPendente, dataCorrecao, historicos) {
	let indiceAcumulado = historicos
		.filter((historico) => {
			let dataAtualizacao = new Date(historico.data_atualizacao);
			dataAtualizacao.setDate(dataAtualizacao.getDate() + 1);
			return dataAtualizacao <= dataCorrecao;
		})
		.reduce((sum, historico) => Number(sum) + Number(historico.valor_calculo), 0);

	const valorCorrecao = valorPendente * (indiceAcumulado / 100);

	if (valorCorrecao < 0) {
		return 0.0;
	}
	return parseFloat(valorCorrecao.toFixed(2));
}

function getValorJuros(valorPendente, correcaoMonetaria, dataVencimento, dataCorrecao) {
	if (correcaoMonetaria.fees_option === null) {
		return 0;
	}

	const feesPercentage = Number(correcaoMonetaria.fees_percentage) / 100;
	let juros = 0;

	if (correcaoMonetaria.feesOption === 'JD') {
		juros = valorPendente * feesPercentage;
	} else {
		juros = (valorPendente * feesPercentage) / 30;
	}

	const diasEmAtraso = calcularDiasAposVencimento(dataVencimento, dataCorrecao);

	const jurosFinal = juros * diasEmAtraso;

	return parseFloat(jurosFinal.toFixed(2));
}

const AcordoValoresConfiguracaoModal = ({ setStep }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [recebimentosManuais, setRecebimentosManuais] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const notify = useNotify();
	const {
		condominioSelecionado,
		unidadeSelecionada,
		selectedArrecadacoesVencidas,
		setSelectedArrecadacoesVencidas,
		multa,
		juros,
		correcaoMonetaria,
		valoresTotais,
		setValoresTotais,
		selectedRow,
		setSelectedRow,
		setTipoJuros,
		detalhamentoAcrescimosEDescontos,
		dataEncargos,
		resetAllStates,
		setValorIndice,
	} = useContext(AcordosParcelasFluxoModaisContext);
	const dataProvider = useDataProvider();
	const [totalAcresEDesc, setTotalAcresEDesc] = useState({
		acrescimo: 0,
		desconto: 0,
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);
	const fetchModalData = async () => {
		try {
			const promises = [
				dataProvider.getList('recebimentos_manuais_vencidos', {
					filter: { unidade_id: unidadeSelecionada.id, condominio_id: condominioSelecionado.id },
					pagination: { page: 1, perPage: 100 },
					sort: { field: 'id', order: 'ASC' },
				}),
			];
			if (condominioSelecionado?.configuracao_correcao_monetaria)
				if (multa || juros || correcaoMonetaria) {
					promises.push(
						dataProvider.getOne('correcao_monetaria', {
							id: condominioSelecionado?.configuracao_correcao_monetaria,
						}),
						dataProvider.getList('historicos_correcao_monetaria', {
							filter: { correcao_monetaria: condominioSelecionado?.configuracao_correcao_monetaria },
							pagination: { page: 1, perPage: 100000 },
							sort: { field: 'data_atualizacao', order: 'ASC' },
						})
					);
				}
			const results = await Promise.allSettled(promises);

			const recebimentosManuaisVencidos = results[0]?.value.data;
			const correcaoMonetariaTabela = results[1]?.value.data || {};
			const historicos = results[2]?.value.data || [];

			const result = recebimentosManuaisVencidos.map((recebimento_manual) => {
				let multaValor = 0;
				let jurosValor = 0;
				let correcaoMonetariaValor = 0;

				if (multa && condominioSelecionado?.configuracao_correcao_monetaria) {
					multaValor = getValorMulta(
						recebimento_manual.valor_pendente,
						correcaoMonetariaTabela.fine_percentage
					);
				}
				if (juros && condominioSelecionado?.configuracao_correcao_monetaria) {
					jurosValor = getValorJuros(
						recebimento_manual.valor_pendente,
						correcaoMonetariaTabela,
						recebimento_manual.data_vencimento,
						dataEncargos
					);
				}
				if (correcaoMonetaria && condominioSelecionado?.configuracao_correcao_monetaria) {
					const historicoCorrecoesPorDataVencimento = historicos.filter(
						(historico) => historico?.data_atualizacao >= recebimento_manual.data_vencimento
					);
					correcaoMonetariaValor = getValorCorrecao(
						recebimento_manual.valor_pendente,
						dataEncargos,
						historicoCorrecoesPorDataVencimento
					);
				}

				const valor_total =
					parseFloat(recebimento_manual.valor_pendente) + jurosValor + multaValor + correcaoMonetariaValor;

				return {
					recebimento_manual_id: recebimento_manual.id,
					identificacao: recebimento_manual.nome_identificacao,
					cobranca: recebimento_manual.tipo_responsavel,
					referencia: recebimento_manual.referencia,
					vencimento: recebimento_manual.data_vencimento,
					nossoNumero: recebimento_manual.nosso_numero,
					valor: recebimento_manual.valor_pendente,
					juros: jurosValor,
					multa: multaValor,
					correcaoMonetaria: correcaoMonetariaValor,
					total: parseFloat(valor_total).toFixed(2),
				};
			});

			correcaoMonetariaTabela?.fees_option && juros
				? setTipoJuros(correcaoMonetariaTabela.fees_option)
				: setTipoJuros(null);

			if (correcaoMonetariaTabela && condominioSelecionado?.configuracao_correcao_monetaria) {
				setValorIndice(correcaoMonetariaTabela.ultimo_valor_calculo);
			}
			setTotal(result.length);
			setRecebimentosManuais(result);
		} catch (error) {
			notify('Erro ao coletar dados', 'warning');
		}
	};

	useEffect(() => {
		if (recebimentosManuais.length == 0) {
			fetchModalData();
		}
	}, [unidadeSelecionada, multa, juros, correcaoMonetaria]);

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const allIndices = recebimentosManuais.map((_, index) => index);
			setSelectedRow(allIndices);
			setSelectAll(true);
			setSelectedArrecadacoesVencidas(recebimentosManuais);
		} else {
			setSelectedRow([]);
			setSelectAll(false);
			setSelectedArrecadacoesVencidas([]);
		}
	};

	const handleSelectRowClick = (index) => {
		const newSelectedRows = selectedRow.includes(index)
			? selectedRow.filter((rowIndex) => rowIndex !== index)
			: [...selectedRow, index];

		setSelectedRow(newSelectedRows);

		const selectedData = newSelectedRows.map((i) => recebimentosManuais[i]);
		setSelectedArrecadacoesVencidas(selectedData);
	};

	useEffect(() => {
		const selectedData = selectedRow.map((i) => recebimentosManuais[i]);
		setSelectedArrecadacoesVencidas(selectedData);

		const novosTotais = {
			arrecadacoes: 0,
			correcaoMonetaria: 0,
			juros: 0,
			multa: 0,
			total: 0,
		};

		selectedData?.forEach((item) => {
			novosTotais.arrecadacoes += Number(item?.valor) || 0;
			novosTotais.correcaoMonetaria += item?.correcaoMonetaria || 0;
			novosTotais.juros += item?.juros || 0;
			novosTotais.multa += item?.multa || 0;
			novosTotais.total += Number(item?.total) || 0;
		});

		setValoresTotais(novosTotais);

		if (selectedRow.length === recebimentosManuais.length && selectedRow.length != 0) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [selectedRow, recebimentosManuais, setSelectedRow]);

	useEffect(() => {
		const { totalAcrescimos, totalDescontos } = calcularTotaisAcrescimosEDescontos(
			detalhamentoAcrescimosEDescontos
		);
		setTotalAcresEDesc({ acrescimo: totalAcrescimos, desconto: totalDescontos });
	}, [detalhamentoAcrescimosEDescontos]);

	const handleModalStep = () => {
		setStep('1C');
	};

	const handleNextStep = () => {
		setStep('1D');
	};

	const handleLastStep = () => {
		setStep('1A');
	};

	return (
		<CustomDialogBodySizeEditable
			title={`Arrecadações: Condominio ${condominioSelecionado?.nome}${
				unidadeSelecionada?.nome_grupo ? ` - ${unidadeSelecionada.nome_grupo}` : ''
			} - Unidade ${unidadeSelecionada?.unidade}`}
			closeInSubmit={false}
			form={{
				component: (
					<Box>
						<Box display='grid' gridTemplateColumns='repeat(7, 1fr)' gridGap='15px'>
							<BoxInfo title='Arrecadações' number={valoresTotais.arrecadacoes} />
							<BoxInfo title='C. Monetária' number={valoresTotais.correcaoMonetaria} />
							<BoxInfo title='Juros' number={valoresTotais.juros} />
							<BoxInfo title='Multa' number={valoresTotais.multa} />
							<BoxInfo title='Acréscimos' number={totalAcresEDesc.acrescimo} />
							<BoxInfo title='Descontos' number={totalAcresEDesc.desconto} />
							<BoxInfo
								title='Totais'
								number={valoresTotais.total + totalAcresEDesc.acrescimo - totalAcresEDesc.desconto}
							/>
						</Box>
						<Box
							display={'flex'}
							alignItems={'center'}
							flexDirection={'row'}
							justifyContent={'space-between'}
						>
							<Typography variant='h6' style={{ fontWeight: 'bold' }}>
								Selecione abaixo as Arrecadações vencidas para inclusão do Acordo:
							</Typography>
							<TopToolbar>
								<Button
									label='Acréscimos e Descontos'
									onClick={handleModalStep}
									startIcon={<AddIcon />}
								>
									Acréscimos e Descontos
								</Button>
							</TopToolbar>
						</Box>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell padding='checkbox'>
											<Checkbox checked={selectAll} onChange={handleSelectAllClick} />
										</TableCell>
										<TableCell style={{ width: '8%', fontWeight: 'bold' }}>Identificação</TableCell>
										<TableCell style={{ width: '8%', fontWeight: 'bold' }}>Cobrança</TableCell>
										<TableCell style={{ width: '8%', fontWeight: 'bold' }}>Referência</TableCell>
										<TableCell style={{ width: '8%', fontWeight: 'bold' }}>Vencimento</TableCell>
										<TableCell style={{ width: '10%', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
											Nosso Número
										</TableCell>
										<TableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'right' }}>
											Valor
										</TableCell>
										<TableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'right' }}>
											Juros
										</TableCell>
										<TableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'right' }}>
											Multa
										</TableCell>
										<TableCell style={{ width: '16%', fontWeight: 'bold', textAlign: 'right' }}>
											Correção Monetária
										</TableCell>
										<TableCell style={{ width: '10%', fontWeight: 'bold', textAlign: 'right' }}>
											Total
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{recebimentosManuais
										.slice((page - 1) * perPage, page * perPage)
										.map((data, index) => (
											<TableRow key={index} selected={selectedRow.includes(index)}>
												<TableCell padding='checkbox'>
													<Checkbox
														checked={selectedRow.includes(index)}
														onChange={() => handleSelectRowClick(index, data)}
													/>
												</TableCell>
												<TableCell style={{ textTransform: 'None' }}>
													{data.identificacao}
												</TableCell>
												<TableCell style={{ textTransform: 'None' }}>
													{FormataResponvavel(data.cobranca)}
												</TableCell>
												<TableCell style={{ textTransform: 'None' }}>
													{data.referencia}
												</TableCell>
												<TableCell style={{ textTransform: 'None' }}>
													{formataVencimento(data.vencimento)}
												</TableCell>
												<TableCell style={{ textTransform: 'None' }}>
													{data.nossoNumero}
												</TableCell>
												<TableCell
													style={{ textAlign: 'right', textTransform: 'None' }}
												>{`R$ ${formatarValor(data.valor)}`}</TableCell>
												<TableCell
													style={{ textAlign: 'right', textTransform: 'None' }}
												>{`R$ ${formatarValor(data.juros)}`}</TableCell>
												<TableCell
													style={{ textAlign: 'right', textTransform: 'None' }}
												>{`R$ ${formatarValor(data.multa)}`}</TableCell>
												<TableCell
													style={{ textAlign: 'right', textTransform: 'None' }}
												>{`R$ ${formatarValor(data.correcaoMonetaria)}`}</TableCell>
												<TableCell
													style={{ textAlign: 'right', textTransform: 'None' }}
												>{`R$ ${formatarValor(data.total)}`}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
							<SyndkosTablePagination
								{...{
									handlePageChange,
									page,
									perPage,
									total,
									setPerPage,
								}}
								labelRowsPerPage='Arrecadações vencidas por página'
							/>
						</TableContainer>
					</Box>
				),
			}}
			customActions={
				<Box display='flex' justifyContent='flex-end' gridGap={'1em'} mt={2}>
					<ButtonCancel
						onClick={() => {
							setModalValue((v) => ({ ...v, open: false }));
							resetAllStates();
							return;
						}}
						size='small'
					/>
					<BotaoVoltar onClick={handleLastStep} />
					<BotaoProximo
						disabled={
							selectedArrecadacoesVencidas.length < 1 ||
							valoresTotais.total + totalAcresEDesc.acrescimo - totalAcresEDesc.desconto < 0
						}
						onClick={handleNextStep}
					/>
				</Box>
			}
		/>
	);
};

export default AcordoValoresConfiguracaoModal;
