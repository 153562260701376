import React from 'react';
import { useNotify } from 'react-admin';

import { format } from 'date-fns';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { ReservaWrapper } from './components/ReservaWrapper';
import { ReservaIcon } from './components/ReservaIcon';
import { KDayPicker } from './components/KDayPicker';

export const RenderDay = ({ day, dayInCurrentMonth, onDayClick }) => {
	const {
		consulta: { reservasNoMes },
	} = React.useContext(ConsultaDeReservasContext);

	const dia = format(day || new Date(), 'dd');
	const {
		temReservaMinha: filtradas,
		temReservaOutros: realizadas,
		bloqueado_manualmente,
		bloqueado_por_configuracao,
	} = reservasNoMes[parseInt(dia)] || {};

	const notify = useNotify();

	const fireOnDayClickCallback = () => {
		if (dayInCurrentMonth) {
			const existeReserva = filtradas || realizadas;
			if (!existeReserva && bloqueado_por_configuracao) {
				return notify('Dia bloqueado para reservas.', 'warning');
			}
			return onDayClick(day);
		}
	};

	return (
		<KDayPicker $dayInCurrentMonth={dayInCurrentMonth} onClick={fireOnDayClickCallback}>
			<span>{dia}</span>
			{dayInCurrentMonth && (
				<ReservaWrapper>
					{!!filtradas && <ReservaIcon tipoReserva='filtradas' />}
					{!!realizadas && <ReservaIcon tipoReserva='realizadas' />}
					{(!!bloqueado_por_configuracao || !!bloqueado_manualmente) && (
						<ReservaIcon tipoReserva='bloqueado' />
					)}
				</ReservaWrapper>
			)}
		</KDayPicker>
	);
};
