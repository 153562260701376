import {
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesProtesto,
} from '../../../default/ContaCorrenteProps';

const formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB240 = ({ values }) => ({
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesLimitePagamento({ values }),
	...formataPayloadValuesProtesto({ values }),
});

export default formataPayloadValuesTabInstrucoesCobrancaRemessaExlusivoCNAB240;
