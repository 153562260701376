import React from 'react';

const createInitialValueStateContexto = () => ({
	dadosEditGaragem: {},
	openModalCreateGaragem: false,
	openModalEditarGaragem: false,
});

export const GaragensUnidadeContext = React.createContext({
	contexto: createInitialValueStateContexto(),
	setContexto: (newContexto = {}) => newContexto,
});

const funcaoRedutora = (contextoAntigo, contextoNovo) => {
	return {
		...contextoAntigo,
		...contextoNovo,
	};
};

function GaragensUnidadeContextProvider({ children }) {
	const [contexto, setContexto] = React.useReducer(funcaoRedutora, createInitialValueStateContexto());

	const value = React.useMemo(
		() => ({
			contexto,
			setContexto,
		}),
		[contexto, setContexto]
	);

	return <GaragensUnidadeContext.Provider value={value}>{children}</GaragensUnidadeContext.Provider>;
}

export default GaragensUnidadeContextProvider;
