import React, { useState, useEffect } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import parse from 'html-react-parser';
import api from '../../services/api';
import { Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useModalStyles = makeStyles((theme) => ({
	modalStyle: {
		float: 'right',
		width: '25%',
		height: '100%',
		backgroundColor: theme.fundo[100],
		fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
		outline: 'none',
		color: theme.primary[200],
	},
}));

const CustomNotificationIcon = () => {
	const { modalStyle } = useModalStyles();
	const [open, setOpen] = useState(false);
	const [hasNoticiaNaoLida, setHasNoticiaNaoLida] = useState(false);
	const [noticias, setNoticias] = useState([]);

	useEffect(() => {
		api.get('api/v1/noticias/mais_recentes').then((res) => {
			setNoticias(res.data['noticias']);
		});
	}, []);

	useEffect(() => {
		noticias.length &&
			(() => {
				const ultimaLeitura = localStorage.getItem('ultimaLeitura') || null;
				if (!ultimaLeitura) return setHasNoticiaNaoLida(true);
				return setHasNoticiaNaoLida(new Date(noticias[0].data_criacao).toISOString() > ultimaLeitura);
			})();
	}, [noticias]);

	return (
		<>
			<Tooltip title={'Notícias'}>
				<IconButton onClick={() => setOpen(!open)}>
					{hasNoticiaNaoLida ? (
						<Badge badgeContent=' ' variant='dot' color='primary'>
							<NotificationsIcon style={{ fill: '#FFFFFF' }} />
						</Badge>
					) : (
						<NotificationsIcon style={{ fill: '#FFFFFF' }} />
					)}
				</IconButton>
			</Tooltip>

			<Modal
				open={open}
				onClose={() => {
					setOpen(!open);
					setHasNoticiaNaoLida(false);
					localStorage.setItem('ultimaLeitura', new Date().toISOString());
				}}
			>
				<Slide in={open} direction='left' timeout={500} style={{ overflow: 'scroll' }}>
					<div className={modalStyle}>
						<h1 style={{ padding: '16px' }}>Novidades</h1>
						<hr style={{ marginRight: '16px', marginLeft: '16px' }}></hr>
						{noticias.map((noticia, index) => (
							<div
								style={{
									backgroundColor: 'white',
									padding: '0px 16px 0px 16px',
								}}
							>
								<h3>
									{noticia.titulo} - {noticia.chamada}
								</h3>
								<p>
									{Intl.DateTimeFormat('pt-BR', {
										dateStyle: 'long',
									}).format(new Date(noticia.data_noticia + 'GMT-0300'))}
								</p>
								<div style={{ whiteSpace: 'pre-line' }}>{parse(noticia.conteudo)}</div>
								{index < noticias.length - 1 ? <hr></hr> : <></>}
							</div>
						))}
					</div>
				</Slide>
			</Modal>
		</>
	);
};

export default CustomNotificationIcon;
