import React, { useEffect, useState } from 'react';
import {
	Create,
	TextInput,
	maxLength,
	required,
	useDataProvider,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin';

import { CustomDataProvider, OccurrenceCreateFormProps, OccurrenceProps, OccurrenceResponse, Unity } from './types';
import { BoxDivisor, FormularioBox, TextInputSyndikos } from 'components/common/Formulario';
import { Box, Grid, TextField } from '@material-ui/core';
import VeryHighLimitReferenceInput from 'components/common/VeryHighLimitReferenceInput';
import { SyndikosSelect } from 'components/common/SyndikosSelect';
import { SyndikosKeyboardDatePicker, SyndikosMuiPickersUtilsProvider } from 'components/common/InputDate';
import { CondominiosContext } from 'context/CondominioContextProvider';
import InputFile from 'components/common/InputFile';
import { TipoModalInput } from 'components/common/TipoModalInput';
import { Field } from 'react-final-form';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-final-form';
import { Pessoa } from 'types/tpyesGlobal';

const tipoOptions = [
	{ id: 'OC', name: 'Ocorrência no Condomínio' },
	{ id: 'DN', name: 'Denúncia' },
];

const dictLabels = {
	A: 'Em Andamento',
	F: 'Finalizada',
	C: 'Cancelada',
	E: 'Excluída',
	I: 'Inativa',
};

const UnityField = ({ setSelectedUnidadeId }: { setSelectedUnidadeId: (id: number | null) => void }) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	const { change, getState } = useForm();

	const {
		values: { unidade },
	} = getState();

	const [{ unidades, unidadesPorId }, setUnidades] = useState<any>({ unidades: [], unidadesPorId: {} });

	const dataProvider: CustomDataProvider = useDataProvider();

	useEffect(() => {
		if (!condominioSelecionado?.id) {
			change('unidade', null);
			setSelectedUnidadeId(null);
			return setUnidades({ unidades: [], unidadesPorId: {} });
		}
		if (unidade && condominioSelecionado?.id !== unidadesPorId[unidade].condominio_id) {
			change('unidade', null);
			setSelectedUnidadeId(null);
		}
		const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
		dataProvider
			.getSimple('unidades', {
				pagination: { perPage: 100000, page: 1 },
				sort: { field: 'nome_unidade_com_extras', order: 'ASC' },
				filter: { id_condominio: condominioSelecionado?.id },
				cancelToken,
			})

			.then((response: any) => {
				response.data.results.forEach((unidade: Unity) => {
					unidade.unidade = unidade.nome_grupo
						? `${unidade.nome_grupo} - ${unidade.tipo_unidade} ${unidade.unidade}`
						: `${unidade.tipo_unidade} ${unidade.unidade}`;
				});
				setUnidades({
					unidades: response.data.results,
					unidadesPorId: response.data.results.reduce(
						(unidadesPorId: { [key: number]: Unity }, unidade: Unity) => {
							unidadesPorId[unidade.id] = unidade;
							return unidadesPorId;
						},
						{}
					),
				});
			})
			.catch((e: any) => {
				if (axios.isCancel(e)) return;
			});
		return () => {
			cancelRequest();
		};
	}, [condominioSelecionado?.id]);

	return (
		<Field name='unidade' validate={required()}>
			{({ input, meta: { touched, error } }) => (
				<Autocomplete
					fullWidth
					style={{ marginBottom: 5 }}
					options={unidades}
					value={unidadesPorId[input.value] || null}
					onChange={(_, newValue) => {
						input.onChange(newValue.id);
						setSelectedUnidadeId(newValue.id);
					}}
					renderOption={(option) => option.unidade}
					getOptionLabel={(option) => option.unidade}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='dense'
							fullWidth
							label={'Unidade'}
							required={true}
							error={!!(touched && error)}
							helperText={touched && error}
						/>
					)}
					disableClearable
					noOptionsText='Nenhum resultado'
					disableListWrap
				/>
			)}
		</Field>
	);
};

const tipoResponsavel: { [key: string]: string } = {
	P: 'Proprietário(a)',
	I: 'Inquilino(a)',
	D: 'Dependente',
};

const ResponsibleField = ({ selectedUnidadeId }: { selectedUnidadeId: number | null }) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	const { change, getState } = useForm();

	const {
		values: { responsavel },
	} = getState();

	const [{ pessoas, pessoasPorId }, setPessoas] = useState<any>({ pessoas: [], pessoasPorId: {} });

	const dataProvider: CustomDataProvider = useDataProvider();

	useEffect(() => {
		if (!condominioSelecionado?.id || !selectedUnidadeId) {
			change('responsavel', null);
			return setPessoas({ pessoas: [], pessoasPorId: {} });
		}
		if (responsavel && selectedUnidadeId !== pessoasPorId[responsavel].id_unidade) {
			change('responsavel', null);
		}
		const { token: cancelToken, cancel: cancelRequest } = axios.CancelToken.source();
		dataProvider
			.getSimple('pessoa_unidade', {
				pagination: { perPage: 100000, page: 1 },
				filter: { id_unidade: selectedUnidadeId, data_saida_after_today: true, situacao_pessoa_ativa: true },
				cancelToken,
			})
			.then((response: any) => {
				setPessoas({
					pessoas: response.data.results,
					pessoasPorId: response.data.results.reduce(
						(pessoasPorId: { [key: number]: Pessoa }, pessoa: Pessoa) => {
							pessoasPorId[pessoa.id] = pessoa;
							return pessoasPorId;
						},
						{}
					),
				});
			})
			.catch((e: any) => {
				if (axios.isCancel(e)) return;
			});
		return () => {
			cancelRequest();
		};
	}, [selectedUnidadeId]);

	return (
		<Field name='responsavel' validate={required()}>
			{({ input, meta: { touched, error } }) => (
				<Autocomplete
					fullWidth
					style={{ marginBottom: 5 }}
					options={pessoas}
					value={pessoasPorId[input.value] || null}
					onChange={(_, newValue) => {
						input.onChange(newValue.id);
					}}
					renderOption={(option: { tipo: string; nome_responsavel: string; id: number }) =>
						`${tipoResponsavel[option.tipo]} - ${option.nome_responsavel}`
					}
					getOptionLabel={(option) => `${tipoResponsavel[option.tipo]} - ${option.nome_responsavel}`}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='dense'
							fullWidth
							label={'Responsável'}
							required={true}
							error={!!(touched && error)}
							helperText={touched && error}
						/>
					)}
					disabled={!selectedUnidadeId}
					disableClearable
					noOptionsText='Nenhum resultado'
					disableListWrap
				/>
			)}
		</Field>
	);
};

const OccurrenceCreateForm: React.FC<OccurrenceCreateFormProps> = ({ visualizar, ...props }) => {
	const { condominioSelecionado, setCondominioPorId } = React.useContext(CondominiosContext);
	const [selectedUnidadeId, setSelectedUnidadeId] = useState<number | null>(null);

	return (
		<FormularioBox
			customBackButton={undefined}
			customSaveButton={undefined}
			{...props}
			nome={undefined}
			onClickBotaoRemover={undefined}
			dictLabels={dictLabels}
			getChipClassName={undefined}
			onlyExcluir={true}
			noActionToolbar={visualizar}
		>
			<Box flex={1} mr='1em'>
				<BoxDivisor titulo={'Dados Cadastrais'}>
					<Box display='flex' flex={1} gridGap='2em' ml={1}>
						<VeryHighLimitReferenceInput
							source='condominio'
							reference='condominios'
							label='Condomínio'
							fullWidth
							variant='outlined'
							filter={{ situacao: 'A' }}
							validate={required()}
							onChange={(value: number) => {
								setCondominioPorId(value);
								setSelectedUnidadeId(null);
							}}
							initialValue={condominioSelecionado?.id}
							disabled={visualizar}
						>
							<SyndikosSelect optionText='nome' style={{ marginBottom: 24 }} disableClearable />
						</VeryHighLimitReferenceInput>
						<UnityField setSelectedUnidadeId={setSelectedUnidadeId} />
					</Box>
				</BoxDivisor>
				<Box display='flex' flex={1} gridGap='2em' ml={1}>
					<ResponsibleField selectedUnidadeId={selectedUnidadeId} />
					<SyndikosMuiPickersUtilsProvider>
						<SyndikosKeyboardDatePicker
							fullWidth
							source='data'
							label='Data'
							required
							format='dd/MM/yyyy'
							id='date-picker-inline'
							mask='__/__/____'
							parse={(value: Date) => {
								try {
									return value.toISOString().split('T')[0];
								} catch {
									return value;
								}
							}}
							validate={required('Obrigatório')}
							disabled={visualizar}
							maxDate={undefined}
							maxDateMessage={undefined}
							handleChange={undefined}
							{...props}
						/>
					</SyndikosMuiPickersUtilsProvider>
				</Box>
				<Box display='flex' flex={1} ml={1} mt={2}>
					<TextInput
						label='Assunto'
						source='assunto'
						variant='outlined'
						size='small'
						fullWidth
						disabled={visualizar}
						validate={[required(), maxLength(100, 'Máximo de 100 caractéres')]}
					/>
				</Box>
				<Box display='flex' flex={1} ml={1} mb={1.5}>
					<Field name={'descricao'}>
						{({ input, meta }) => (
							<TextInputSyndikos
								label='Descrição'
								source='descricao'
								variant='outlined'
								multiline
								fullWidth
								rows={8}
								validate={[required(), maxLength(500, 'Máximo de 500 caractéres')]}
								helperText={meta?.error ? meta?.error : `${(input?.value || '').length}/500`}
								defaultValue=''
							/>
						)}
					</Field>
				</Box>
				<Box display='flex' flex={0.5} ml={1}>
					<Field name='write_only_anexo' id='write_only_anexo'>
						{({ input }) => (
							<InputFile
								source='write_only_anexo'
								label='Arquivo em anexo'
								variant='outlined'
								onCallBack={(e: any, dataUrl: string) => {
									input.onChange(dataUrl);
								}}
								fileDataUrl={props.record?.write_only_anexo}
								disabled={visualizar}
								desativateButtonAlterarAnexo={visualizar}
								desativateButtonRemoverAnexo={visualizar}
							/>
						)}
					</Field>
				</Box>
				<BoxDivisor titulo='Configuração' mt={5}>
					<Grid container spacing={4} alignItems='flex-start'>
						<Grid item xs={6}>
							<Box height='100%' marginLeft={2}>
								<SyndikosSelect
									source='tipo'
									label='Tipo'
									choices={tipoOptions}
									validate={required()}
									fullWidth
									delay={0}
								/>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box height='calc(100% + 20px)' marginRight={1}>
								<TipoModalInput
									source='classificacao'
									resource='classificacao_ocorrencia'
									label='Classificação'
									variant='outlined'
									fullWidth
									tipoRegistro='classificacao'
									validate={required()}
								/>
							</Box>
						</Grid>
					</Grid>
				</BoxDivisor>
			</Box>
		</FormularioBox>
	);
};

const OccurrenceCreate: React.FC<OccurrenceProps> = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response: OccurrenceResponse) => {
		const data = response?.data;
		if (data) {
			notify('Ocorrência cadastrada com sucesso');
			redirect('list', props.basePath);
			refresh();
		}
	};

	return (
		<Create component='div' title={'Cadastrar Ocorrência'} onSuccess={onSuccess} {...props}>
			<OccurrenceCreateForm edit={false} {...props} />
		</Create>
	);
};

export default OccurrenceCreate;
