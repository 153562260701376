import React from 'react';
import { TRecordHistorico } from '../types';
import ValorField from './ValorField';

interface IValorCalculoField {
	record: TRecordHistorico;
	source: 'valor_calculo';
}

interface IValorFieldColumn extends Omit<IValorCalculoField, 'record'> {
	name: string;
	label: string;
	filtro: React.ReactElement;
}

const ValorCalculoField: React.FC<IValorCalculoField> = ({ record, source }) => {
	return <ValorField value={record?.[source] || 0.0} />;
};

export default ValorCalculoField as React.FC<IValorFieldColumn | IValorCalculoField>;
