import React from 'react';
import { Create, useNotify, useRedirect, useRefresh } from 'react-admin';

import { EnqueteFormulario } from './form';

export const EnqueteCreate = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response) => {
		const data = response?.data;
		if (data) {
			notify(`Enquete "${data.titulo}" cadastrada com sucesso`);
			redirect('list', props.basePath);
			refresh();
		}
	};

	return (
		<Create component='div' title={'Cadastrar Enquete'} undoable={false} onSuccess={onSuccess} {...props}>
			<EnqueteFormulario {...props} />
		</Create>
	);
};
