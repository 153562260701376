import React, { useCallback, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { NumberFormatBRL } from 'components/common/CurrencyInput';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';

const TipoContaField = ({ record }) => {
	switch (record?.tipo_conta) {
		case 'L':
			return <>Leitura</>;
		case 'O':
			return <>Ordinária</>;
		case 'E':
			return <>Extraordinária</>;
		case 'F':
			return <>Fundo</>;
		default:
			return <> </>;
	}
};

const RateadoField = ({ record }) => {
	switch (record?.rateado) {
		case '1':
			return <>Grupo</>;
		case '2':
			return <>Unidade</>;
		case '4':
			return <>Tipo</>;
		case '5':
			return <>Para várias unidades</>;
		default:
			return <>Condomínio</>;
	}
};

const ValorField = ({ record }) => (
	<NumberFormatBRL
		decimalScale={2}
		displayType='text'
		fixedDecimalScale
		onChange={() => {}}
		prefix={`${record?.tipo_lancamento === 'PRC' ? '' : 'R$ '}${(parseFloat(record?.valor) || 0) < 0 ? '-' : ''}`}
		suffix={record?.tipo_lancamento === 'PRC' ? '%' : ''}
		value={record?.valor || 0}
	/>
);

const RateioField = ({ record }) => {
	switch (record?.rateio) {
		case '0':
			return <>Divisão por coeficiente</>;
		case '1':
			return <>Divisão pela quantidade de unidades</>;
		case '2':
			return <>Igualmente para todas as unidades</>;
		case '-1':
			return <>Calculados sobre os consumos individuais</>;
		case '-2':
			return (
				<>
					Para {(record?.tipo_unidade || '').toLowerCase()} {record?.nome_unidade || ''}
				</>
			);
		default:
			return <></>;
	}
};

const ListConfirmacaoArrecadacoesRecorrentes = ({ arrecadacoesRecorrentes }) => {
	const [rows, setRows] = useState([]);
	const [total] = useState(Object.values(arrecadacoesRecorrentes).length);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);
	const configRows = useCallback(() => {
		if (arrecadacoesRecorrentes) {
			const list = Object.values(arrecadacoesRecorrentes);
			const realPage = list.length < (page - 1) * perPage + 1 ? Math.ceil(list.length / perPage) || 1 : page;
			const newRows = list.slice((realPage - 1) * perPage, realPage * perPage);
			setRows(newRows);
		}
	}, [arrecadacoesRecorrentes, page, perPage, setRows]);
	useEffect(configRows, [arrecadacoesRecorrentes, page, perPage]);

	return rows.length ? (
		<TableContainer>
			<Table size='small'>
				<TableHead>
					<TableRow>
						{[
							{ field: 'nome_conta_pai', headerName: 'Conta Título' },
							{ field: 'complemento', headerName: 'Descrição Boleto' },
							{ field: 'tipo_conta', headerName: 'Tipo da Conta' },
							{ field: 'rateado', headerName: 'Rateado' },
							{ field: 'valor', headerName: 'Valor' },
							{ field: 'rateio', headerName: 'Rateio' },
							{ field: 'periodicidade', headerName: 'Tipo Arrecadação' },
						].map((c) => (
							<TableCell key={c.field} align={c.field === 'valor' ? 'right' : undefined}>
								{c.headerName}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow hover tabIndex={-1} key={row.codigo_tag_arrecadacao}>
							<TableCell component='th' id={`${row?.codigo_tag_arrecadacao}-nome_conta_pai`} scope='row'>
								{row.nome_conta_pai}
							</TableCell>
							<TableCell component='th' id={`${row?.codigo_tag_arrecadacao}-complemento`} scope='row'>
								{row.complemento}
							</TableCell>
							<TableCell component='th' id={`${row?.codigo_tag_arrecadacao}-tipo_conta`} scope='row'>
								<TipoContaField record={row} />
							</TableCell>
							<TableCell component='th' id={`${row?.codigo_tag_arrecadacao}-rateado`} scope='row'>
								<RateadoField record={row} />
							</TableCell>
							<TableCell
								component='th'
								id={`${row?.codigo_tag_arrecadacao}-valor`}
								scope='row'
								align='right'
							>
								<ValorField record={row} />
							</TableCell>
							<TableCell component='th' id={`${row?.codigo_tag_arrecadacao}-rateio`} scope='row'>
								<RateioField record={row} />
							</TableCell>
							<TableCell component='th' id={`${row?.codigo_tag_arrecadacao}-periodicidade`} scope='row'>
								{row.periodicidade ? `${row?.parcela_atual || 2}/${row?.parcelas || 2}` : 'Recorrente'}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<SyndkosTablePagination
				{...{
					handlePageChange,
					page,
					perPage,
					total,
					setPerPage,
				}}
				labelRowsPerPage='Arrecadações por página'
			/>
		</TableContainer>
	) : (
		<Box display='flex' justifyContent='center'>
			<CircularProgress />
		</Box>
	);
};

export default ListConfirmacaoArrecadacoesRecorrentes;
