import React from 'react';

import { Box } from '@material-ui/core';
import CadastrarDadosIntegracaoAPIField from '../../../../../commons/CadastrarDadosIntegracaoAPIField';

const TabConta = () => (
	<Box>
		<Box display='grid' gridTemplateColumns='100%'>
			<Box gridColumnGap='0.5rem' display='flex' alignSelf='baseline' marginTop='6px' marginBottom='4px'>
				<CadastrarDadosIntegracaoAPIField />
			</Box>
		</Box>
	</Box>
);

export default TabConta;
