import { DateType } from '@date-io/type';
import React from 'react';
import { useGestaoContext } from '../contexts/GestaoContext';
import ModalGestao, { SelectMembro } from '../modals/ModalGestao';
import { SelectsMembrosType } from '../modals/types';
import { FiliacaoData, GestaoFiliacaoData } from '../types';
import { CustomDataProvider } from 'types/tpyesGlobal';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { useDataProvider, useNotify } from 'react-admin';
import { useSelectsMembrosContext } from '../contexts/SelectsMembrosContex';
import { useFiliacoesContext } from '../contexts/FiliacoesContext';
import { formatISO } from 'date-fns';

const useGestao = () => {
	const dataProvider: CustomDataProvider = useDataProvider();
	const { getGestoes, condominioRecord, modGestao, setModGestao, setGestao } = useGestaoContext();
	const { setModalValue, modalValue } = React.useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const [loading, setLoading] = React.useState(false);

	const createGestao = React.useCallback((data) => {
		if (loading) return;
		setLoading(true);
		dataProvider
			.safeCreate('gestao', {
				data: data,
			})
			.then(() => {
				notify('Gestão criada com sucesso', { type: 'success' });
				setModalValue((v: typeof modalValue) => ({
					...v,
					open: false,
				}));
				setLoading(false);
				getGestoes({
					pagination: { page: 1, perPage: 100000 },
					sort: { field: 'nome', order: 'ASC' },
					filter: { condominio: condominioRecord?.id },
				});
			})
			.catch((e) => {
				setLoading(false);
				const erroMessageDefault = 'Ocorreu um problema ao adicionar a Gestão';
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				if ([500].includes(e?.response?.status)) return notify(erroMessageDefault, { type: 'error' });
				const error = Object.values(e?.response?.data || {})[0] as { [key: string]: string }[] | string[];
				if (typeof error === 'string') return notify(error, { type: 'error' });
				const erroMsg =
					(typeof error?.[0] === 'object'
						? Object.values((error as { [key: string]: string }[])[0])[0]
						: error?.[0]) || erroMessageDefault;
				return notify(erroMsg, { type: 'error' });
			});
	}, []);

	const getOne = React.useCallback((id: number) => {
		if (loading) return;
		setLoading(true);
		dataProvider
			.getOne('gestao', {
				id: id,
			})
			.then(async (res) => {
				setLoading(false);
				const data = res?.data;
				if (data) {
					setGestao(data as GestaoFiliacaoData);
					setModGestao('edit');
					setModalValue((v: typeof modalValue) => ({
						...v,
						open: true,
						dialogBody: <ModalGestao />,
					}));
				} else {
					return notify('Ocorreu um por favor, tente recaregar a página', { type: 'error' });
				}
			})
			.catch(() => {
				setLoading(false);
				return notify('Não foi possível Editar a Gestão!', { type: 'error' });
			});
	}, []);

	const update = React.useCallback((data) => {
		if (loading) return;
		setLoading(true);
		dataProvider
			.update('gestao', {
				id: data?.id,
				data: data,
			})
			.then(() => {
				setLoading(false);
				notify('Gestão editada com sucesso!', { type: 'success' });
				setModalValue((v: typeof modalValue) => ({
					...v,
					open: false,
				}));
				getGestoes({
					pagination: { page: 1, perPage: 100000 },
					sort: { field: 'nome', order: 'ASC' },
					filter: { condominio: condominioRecord?.id },
				});
			})
			.catch((e) => {
				setLoading(false);
				if (e?.response?.data?.documento == 'Pessoa sem documento') {
					return notify(`A Pessoa ${e?.response?.data?.nome_pessoa} não possui um documento cadastrado.`, {
						type: 'error',
					});
				}
				return notify('Não foi possível editar a Gestão!', { type: 'error' });
			});
	}, []);

	const handleIncluir = () => {
		if (modGestao === 'edit') {
			setGestao({});
			setModGestao('create');
		}
		setModalValue((v: typeof modalValue) => ({
			...v,
			open: true,
			dialogBody: <ModalGestao />,
		}));
	};
	const handleEdit = (id: number) => {
		getOne(id);
	};
	return {
		create: createGestao,
		getOne: getOne,
		update: update,
		handleIncluir: handleIncluir,
		handleEdit: handleEdit,
	};
};

function parseDate(dateString: string) {
	const parts = dateString.split('/');
	const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
	const date = new Date(formattedDate);

	return date;
}
export const useGestaoModal = () => {
	const [nome, setNome] = React.useState('');
	const [inicioMandato, setInicioMandato] = React.useState<DateType>(new Date());
	const [fimMandato, setFimMandato] = React.useState<DateType | null>(null);
	const [valid, setValid] = React.useState(false);
	const { condominioRecord, modGestao, gestao } = useGestaoContext();
	const { selectsMembros, setSelectsMembros } = useSelectsMembrosContext();
	const { setFiliacoes, filiacoes } = useFiliacoesContext();
	const { create, update } = useGestao();

	const handleValidateSelect = React.useCallback(
		(valid: boolean, indice: number) => {
			setSelectsMembros((prev) => {
				const SelectsMembros: SelectsMembrosType[] = [...(prev as SelectsMembrosType[])];
				SelectsMembros[indice].valid = valid;
				return SelectsMembros;
			});
		},
		[selectsMembros]
	);

	const handleRemoveBoxSelect = React.useMemo(
		() => (indice: number) => {
			setFiliacoes((prevFiliacoes) => {
				const filiacoesData = [...prevFiliacoes];
				filiacoesData.splice(indice, 1);

				setSelectsMembros((prevSelectsMembros) => {
					const newSelectsMembros = [...prevSelectsMembros];
					newSelectsMembros.splice(indice, 1);
					const allocatedIndex = newSelectsMembros.map((item, index) => {
						const filiacaoData = filiacoesData[index];
						const modifiedComponent = React.cloneElement(item.component as JSX.Element, {
							indice: index,
							defaultValue: filiacaoData,
						});

						return { component: modifiedComponent, valid: true };
					});

					return allocatedIndex;
				});

				return filiacoesData;
			});
		},
		[filiacoes, selectsMembros]
	);
	const handleAddSelectsMembro = React.useCallback(
		(pessoa?: number) => {
			if (pessoa) {
				setSelectsMembros((prev) => {
					const allocatedIndex = prev.map((item, index) => {
						if (item.valid) return item;
						else {
							const modifiedComponent = React.cloneElement(item.component as JSX.Element, {
								indice: index,
								defaultValue: { pessoa: pessoa, funcao: null, assinar_relatorio: false },
							});
							return Object.assign(item, { omcponent: modifiedComponent });
						}
					});
					return allocatedIndex;
				});
			} else {
				setSelectsMembros((prev) => {
					return [
						...prev,
						{
							component: (
								<SelectMembro
									indice={prev?.length as number}
									{...{ setFiliacoes }}
									setValidP={handleValidateSelect}
									handleRemoveBoxSelect={handleRemoveBoxSelect}
									defaultValue={{
										pessoa: null,
										funcao: null,
										assinar_relatorio: false,
										gestao_app: false,
									}}
								/>
							),
							valid: false,
						},
					];
				});
			}
		},
		[selectsMembros]
	);

	// const reordenarFilicoes = React.useCallback(
	// 	(filiacoes) => {
	// 		setFiliacoes(filiacoes as FiliacaoData[]);
	// 		setSelectsMembros((prev) =>
	// 			filiacoes.map((filiacao: FiliacaoData, index: number) => {
	// 				return {
	// 					component: (
	// 						<SelectMembro
	// 							indice={index}
	// 							{...{ setFiliacoes }}
	// 							setValidP={handleValidateSelect}
	// 							handleRemoveBoxSelect={handleRemoveBoxSelect}
	// 							defaultValue={filiacao}
	// 						/>
	// 					),
	// 					valid: true,
	// 				};
	// 			})
	// 		);
	// 	},
	// 	[selectsMembros, filiacoes]
	// );

	React.useEffect(() => {
		if (modGestao === 'edit' && gestao) {
			const { nome, inicio_mandato, fim_mandato, filiacoes } = gestao;
			setNome(nome);
			setInicioMandato(parseDate(inicio_mandato));
			setFimMandato(parseDate(fim_mandato));
			setFiliacoes(filiacoes);
			const selectsMembrosEdits: SelectsMembrosType[] = filiacoes.map((filiacao: FiliacaoData, index: number) => {
				return {
					component: (
						<SelectMembro
							indice={filiacao.posicao_na_lista as number}
							{...{ setFiliacoes }}
							setValidP={handleValidateSelect}
							handleRemoveBoxSelect={handleRemoveBoxSelect}
							defaultValue={filiacao}
						/>
					),
					valid: true,
				};
			});
			setSelectsMembros(selectsMembrosEdits);
		} else {
			setSelectsMembros([
				{
					component: (
						<SelectMembro
							indice={0}
							{...{ setFiliacoes }}
							setValidP={handleValidateSelect}
							handleRemoveBoxSelect={handleRemoveBoxSelect}
							defaultValue={{ pessoa: null, funcao: null, assinar_relatorio: false, gestao_app: false }}
						/>
					),
					valid: false,
				},
			]);
		}
	}, [modGestao]);

	const handleSetNome = React.useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			e.preventDefault();
			setNome(e.target.value);
		},
		[nome]
	);

	const handleSetValid = React.useCallback(() => true, []);

	React.useEffect(() => {
		if (inicioMandato && fimMandato && nome) setValid(true);
		else setValid(false);
	}, [inicioMandato, fimMandato, nome, handleSetValid]);

	const handleSubmitNewGestion = React.useCallback(() => {
		create({
			nome: nome,
			inicio_mandato: formatISO(inicioMandato).substring(0, 10),
			fim_mandato: formatISO(fimMandato as Date).substring(0, 10),
			condominio: condominioRecord.id,
			filiacoes: filiacoes || [],
		});
	}, [nome, inicioMandato, fimMandato, condominioRecord.id, filiacoes]);

	const handleEditGestion = React.useCallback(() => {
		if (gestao) {
			update({
				id: gestao?.id,
				nome: nome,
				inicio_mandato: inicioMandato?.toISOString().substring(0, 10),
				fim_mandato: fimMandato?.toISOString().substring(0, 10),
				condominio: condominioRecord.id,
				filiacoes:
					[
						...filiacoes.map((filiacao) => {
							delete filiacao.title;
							return filiacao;
						}),
					] || [],
			});
		}
	}, [nome, inicioMandato, fimMandato, condominioRecord.id, filiacoes]);

	const useGestaoModalValues = React.useMemo(
		() => ({
			nome,
			handleSubmitNewGestion,
			handleEditGestion,
			selectsMembros,
			handleAddSelectsMembro,
			handleSetNome,
			inicioMandato,
			setInicioMandato,
			fimMandato,
			setFimMandato,
			handleSetValid,
			valid,
			validSelectsMembro: selectsMembros?.every((selectMembro) => selectMembro.valid),
			modGestao,
			gestao,
			// reordenarFilicoes,
		}),
		[
			nome,
			handleSubmitNewGestion,
			handleEditGestion,
			selectsMembros,
			handleAddSelectsMembro,
			handleSetNome,
			inicioMandato,
			setInicioMandato,
			fimMandato,
			setFimMandato,
			handleSetValid,
			valid,
			modGestao,
			gestao,
			// reordenarFilicoes,
		]
	);

	return useGestaoModalValues;
};

export default useGestao;

// myfunc(func, ...rt)
