import React from 'react';

import { Box } from '@material-ui/core';

import {
	ModalCadastrarDadosIntegracaoAPI as ModalCadastrarDadosIntegracaoAPIBase,
	CertificadoAPIFileField,
	ChaveAPIFileField,
	ClientIDField,
	ClientSecretField,
} from '../../../../../commons/CadastrarDadosIntegracaoAPIField';

const ModalBody = () => (
	<Box display='flex' flexDirection='column' gridRowGap='1rem'>
		<Box display='grid' gridTemplateColumns='repeat(2, 50%)' gridColumnGap='0.5rem'>
			<ClientIDField label='Client ID' source='client_id' />
			<ClientSecretField label='Client Secret' source='client_secret' />
		</Box>
		<Box>
			<CertificadoAPIFileField />
		</Box>
		<Box>
			<ChaveAPIFileField />
		</Box>
	</Box>
);

const ModalCadastrarDadosIntegracaoAPI = () => <ModalCadastrarDadosIntegracaoAPIBase Component={ModalBody} />;

export default ModalCadastrarDadosIntegracaoAPI;
