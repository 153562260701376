import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { CancelButton, ConfirmButton } from 'components/common/commonComponentsTSX';
import { KSurveyEndVotingModalProps } from './types';

const SurveyEndVotingModal: React.FC<KSurveyEndVotingModalProps> = ({ surveyId, surveyAttachment }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const dp = useDataProvider();
	const [loading, setLoading] = React.useState(false);
	const { setModalValue } = React.useContext(ModalContext);

	function handleEndVoting() {
		setLoading(true);
		dp.update('enquetes', {
			id: surveyId,
			data: {
				situacao: 'F',
				write_only_anexo_enquete: surveyAttachment,
				anexo_enquete: surveyAttachment,
			},
			previousData: { id: surveyId },
		})
			.then(() => {
				notify('Enquete encerrada com sucesso!', 'success');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(e, 'warning');
			})
			.finally(() => setLoading(false));
	}

	const actions = (
		<>
			<CancelButton onClick={() => setModalValue((v: any) => ({ ...v, open: false }))} />
			<ConfirmButton onClick={handleEndVoting} size='small' disabled={loading}>
				{(loading && <CircularProgress size={20} />) || 'Confirmar'}
			</ConfirmButton>
		</>
	);

	return (
		<CustomDialogBody
			title={'Encerrar Votação'}
			customActions={actions}
			form={{
				component: <>Deseja realmente encerrar esta enquete?</>,
			}}
		/>
	);
};

export default SurveyEndVotingModal;
