import React, { useState, useEffect, useContext } from 'react';
import { useNotify, useEditContext } from 'react-admin';
import { add, parseISO, isSameDay, isAfter, sub } from 'date-fns';

import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';

import {
	ModalSizeEditableContext,
	CustomDialogBodySizeEditable,
	ModalSizeEditableContextProvider,
} from '../../../common/ModalSizeEditableContext';
import { VinculosContext } from './../VinculosContext';
import BoxInfo from './common/BoxInfo';
import formatDateISO from './common/formatDateISO';
import VinculosDatePicker from './common/VinculosDatePicker';
import { EnderecoBoxV2, EnderecoContext, EnderecoContextProvider } from '../../../common/EnderecoV2';
import BoxPessoaSelect from './common/BoxPessoaSelect';

const ModalBodyTrocaOwner = ({
	nome,
	tipo_pessoa,
	documento,
	data_entrada,
	id_pessoa,
	id,
	enderecoDados: enderecoDadosDoAtualProprietario,
}) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [valid, setValid] = useState(false);
	const [saidaPrev, setSaidaPrev] = useState(null);
	const [motivoSaida, setMotivoSaida] = useState('');
	const [entradaProx, setEntradaProx] = useState(null);
	const notify = useNotify();
	const { selectedPessoa, setSelectedPessoa, dataProvider, setResponsaveis, edit } = useContext(VinculosContext);
	const { values: enderecoDados } = useContext(EnderecoContext);
	const { record } = useEditContext();
	const [processando, setProcessando] = useState(false);

	useEffect(() => {
		setSelectedPessoa(undefined);
	}, [setSelectedPessoa]);

	useEffect(() => {
		if (!saidaPrev) return setMotivoSaida('');
	}, [saidaPrev]);

	const handleDateChange = (v) => {
		setSaidaPrev(v);
		setEntradaProx(add(v, { days: 1 }));
	};

	const handleSubmit = () => {
		setProcessando(true);
		const { id: id_pessoa, nome, email_cobranca, documento, tipo_pessoa, telefones } = selectedPessoa || {};

		if (!edit) {
			setResponsaveis((v) =>
				v.map((p) => {
					if (p.tipo === 'P') {
						return {
							nome,
							tipo: 'P',
							data_entrada: formatDateISO(entradaProx),
							id_pessoa,
							email_cobranca,
							documento,
							tipo_pessoa,
							telefones,
							...enderecoDados,
						};
					}
					return { ...p };
				})
			);
			setModalValue((v) => ({ ...v, open: false }));
			return;
		}

		if (!record) {
			setProcessando(false);
			return;
		}

		dataProvider
			.update('pessoa_unidade', {
				id,
				data: {
					data_saida: formatDateISO(saidaPrev),
					motivo_saida: motivoSaida,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					dataProvider
						.create('pessoa_unidade', {
							data: {
								id_unidade: data.id_unidade,
								id_pessoa,
								tipo: 'P',
								data_entrada: formatDateISO(entradaProx),
								...enderecoDados,
							},
						})
						.then((response) => {
							const data = response?.data;
							if (data) {
								notify('Proprietário trocado com sucesso', 'info');
								setResponsaveis((v) =>
									v.map((p) => {
										if (p.tipo === 'P') {
											return {
												...data,
												nome,
												email_cobranca,
												documento,
												tipo_pessoa,
												telefones,
												...enderecoDados,
											};
										}
										return { ...p };
									})
								);
								setModalValue((v) => ({ ...v, open: false }));
							}
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							notify(
								Object.values(e?.response?.data || {})[0] || [
									'Erro inesperado, tente recarregar a página',
								],
								'warning'
							);
						});
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
				setProcessando(false);
			});
	};

	useEffect(() => {
		if (
			enderecoDados?.cep &&
			enderecoDados?.bairro &&
			enderecoDados?.cidade &&
			enderecoDados?.endereco &&
			enderecoDados?.tipo_logradouro &&
			enderecoDados?.tipo_logradouro_id &&
			enderecoDados?.uf &&
			selectedPessoa &&
			selectedPessoa.nome &&
			saidaPrev &&
			saidaPrev.toString() !== 'Invalid Date' &&
			isAfter(new Date(), saidaPrev)
		) {
			setValid(true);
		} else {
			setValid(false);
		}
	}, [selectedPessoa, saidaPrev, enderecoDados]);

	return (
		<CustomDialogBodySizeEditable
			title='Trocar Proprietário da Unidade'
			closeInSubmit={false}
			form={{
				valid: !processando && valid,
				handleSubmit,
				component: (
					<>
						<Chip label='Proprietário Atual' size='large' style={{ margin: '8px 0' }} />
						<VinculosDatePicker
							label='Data de Saída'
							maxDate={sub(new Date(), { days: 1 })}
							maxDateMessage='Data de saida deve ser anterior ao dia de hoje'
							minDate={parseISO(data_entrada + 'T10:00')}
							minDateMessage='A saída não pode ser anterior  à data de entrada'
							value={saidaPrev}
							onChange={handleDateChange}
						/>
						<BoxInfo
							selectedPessoa={{ tipo_pessoa, nome, documento }}
							entrada={data_entrada && new Date(Date.parse(data_entrada + 'T10:00'))}
							saida={saidaPrev}
							enderecoDados={enderecoDadosDoAtualProprietario}
						/>
						<TextField
							flex={1}
							mr='0.5em'
							fullWidth
							size='small'
							variant='outlined'
							label='Motivo de saída'
							value={motivoSaida}
							onChange={(event) => setMotivoSaida(event.target.value)}
							disabled={!saidaPrev}
							inputProps={{ maxLength: 50 }}
						/>
						<hr />
						<Chip label='Novo Proprietário' size='large' color='primary' style={{ margin: '8px 0' }} />
						<ModalSizeEditableContextProvider>
							<BoxPessoaSelect owner={{ id_pessoa }} setValid={setValid} />
						</ModalSizeEditableContextProvider>
						<EnderecoBoxV2 />
					</>
				),
			}}
		/>
	);
};

export const ModalTrocaOwner = ({ enderecoDados, ...props }) => (
	<EnderecoContextProvider {...enderecoDados}>
		<ModalBodyTrocaOwner enderecoDados={enderecoDados} {...props} />
	</EnderecoContextProvider>
);

const ModalBodyEditaProprietario = ({
	id_pessoa,
	nome,
	tipo_pessoa,
	documento,
	telefones,
	email_cobranca,
	data_entrada,
	id,
	reloadTable,
}) => {
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { record } = useEditContext();
	const { selectedPessoa, setSelectedPessoa, dataProvider, responsaveis, setResponsaveis, edit, setReload } =
		useContext(VinculosContext);
	const { values: enderecoDados } = useContext(EnderecoContext);
	const [processando, setProcessando] = useState(false);
	const [valid, setValid] = useState(false);
	const [entradaData, setEntradaData] = useState(parseISO(data_entrada + 'T10:00'));
	const [maxDateProp, setMaxDateProp] = useState({});

	const setMaxDate = () => {
		if (!edit) {
			if (responsaveis && responsaveis[1]?.data_entrada) {
				setMaxDateProp({
					maxDate: parseISO(responsaveis[1]?.data_entrada),
					maxDateMessage:
						'A data de entrada do primeiro proprietário não pode ser posterior à data de entrada do primeiro inquilino.',
				});
			}
		} else {
			if (responsaveis && responsaveis[0]?.is_first && responsaveis[1]?.is_first) {
				setMaxDateProp({
					maxDate: parseISO(responsaveis[1]?.data_entrada),
					maxDateMessage:
						'A data de entrada do primeiro proprietário não pode ser posterior à data de entrada do primeiro inquilino.',
				});
			}
		}
	};

	useEffect(setMaxDate, [responsaveis, edit]);

	useEffect(() => {
		// reseta o selectedPessoa, para minimizar a chance de bugs com os componentes
		setSelectedPessoa({
			id: id_pessoa,
			nome,
			tipo_pessoa,
			documento,
			telefones,
			email_cobranca,
		});
	}, [setSelectedPessoa, id_pessoa, nome, tipo_pessoa, documento, telefones, email_cobranca]);

	useEffect(() => {
		const inquilino = responsaveis[1];
		const data_entrada_inquilino = inquilino ? parseISO(inquilino.data_entrada) : undefined;
		let isValid =
			enderecoDados?.cep &&
			enderecoDados?.bairro &&
			enderecoDados?.cidade &&
			enderecoDados?.endereco &&
			enderecoDados?.tipo_logradouro &&
			enderecoDados?.tipo_logradouro_id &&
			enderecoDados?.uf &&
			selectedPessoa?.nome &&
			entradaData &&
			entradaData.toString() !== 'Invalid Date' &&
			(isSameDay(new Date(), entradaData) || isAfter(new Date(), entradaData)) &&
			(!edit && data_entrada_inquilino
				? isSameDay(data_entrada_inquilino, entradaData) || isAfter(data_entrada_inquilino, entradaData)
				: true);
		setValid(isValid);
	}, [entradaData, selectedPessoa, edit, responsaveis, enderecoDados]);

	const handleSubmit = () => {
		setProcessando(true);

		const { id: id_pessoa, nome, email_cobranca, documento, tipo_pessoa, telefones } = selectedPessoa || {};

		if (!edit) {
			setResponsaveis((v) =>
				v.map((r) => {
					if (r.tipo === 'P') {
						return {
							nome,
							tipo: 'P',
							data_entrada: formatDateISO(entradaData),
							id_pessoa,
							email_cobranca,
							documento,
							tipo_pessoa,
							telefones,
							...enderecoDados,
							is_first: true,
						};
					}
					return r;
				})
			);
			setModalValue((v) => ({ ...v, open: false }));
		} else {
			if (!record) {
				setProcessando(false);
				return;
			}
			dataProvider
				.update('pessoa_unidade', {
					id,
					data: {
						id_pessoa: id_pessoa,
						data_entrada: formatDateISO(entradaData),
						...enderecoDados,
					},
				})
				.then((response) => {
					const data = response?.data;
					if (!data) {
						setProcessando(false);
						return;
					}

					notify('Proprietário alterado com sucesso', 'info');
					setResponsaveis((v) =>
						v.map((r) => {
							if (r.tipo === 'P') {
								return {
									...data,
									nome,
									email_cobranca,
									documento,
									tipo_pessoa,
									telefones,
									...enderecoDados,
								};
							}
							return r;
						})
					);
					if (reloadTable) setReload((v) => v + 1);
					setModalValue((v) => ({ ...v, open: false }));
				})
				.catch((e) => {
					notify(
						Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
						'warning'
					);
					setProcessando(false);
				});
		}
	};

	const handleEntradaDataChange = (v) => {
		setEntradaData(v);
	};

	return (
		<CustomDialogBodySizeEditable
			title='Editar Proprietário da Unidade'
			closeInSubmit={false}
			form={{
				valid: !processando && valid,
				handleSubmit,
				component: (
					<>
						<VinculosDatePicker
							label='Data de Entrada*'
							value={entradaData}
							onChange={handleEntradaDataChange}
							disableFuture
							maxDateMessage='Data de entrada não pode ser posterior ao dia de hoje'
							{...maxDateProp}
						/>
						<ModalSizeEditableContextProvider>
							<BoxPessoaSelect setValid={setValid} />
						</ModalSizeEditableContextProvider>
						<EnderecoBoxV2 />
					</>
				),
			}}
		/>
	);
};

export const ModalEditaProprietario = ({ enderecoDados, ...props }) => (
	<EnderecoContextProvider {...enderecoDados}>
		<ModalBodyEditaProprietario {...props} />
	</EnderecoContextProvider>
);
