import React from 'react';
import { TextField } from '@material-ui/core';
import MaterialTreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import lodashMemoize from 'lodash/memoize';

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

const searchTreeData = memoize((array, search) => {
	const newSearch = removeAcento(search);
	return array.reduce((acc, item) => {
		const chaveNome = removeAcento(item.nome);

		if (chaveNome.includes(newSearch) && item.classe_conta !== 'T') {
			acc.push(item);
		}
		if (item.children.length) {
			const listReturned = searchTreeData(item.children, search);
			if (listReturned.length) {
				acc.push(...listReturned);
			}
		}
		return acc;
	}, []);
});

function removeAcento(text) {
	let textSemAcento = text.toLowerCase();
	textSemAcento = textSemAcento.normalize('NFD').replace(/\p{Diacritic}/gu, '');
	return textSemAcento;
}

const TreeView = ({ treeData, RenderTree, ...props }) => {
	const [expanded, setExpanded] = React.useState(['root']);
	const [filter, setFilter] = React.useState('');
	const filteredTreeData = filter ? searchTreeData(treeData, filter) : treeData;
	const hasItems = !!filteredTreeData.length;

	return (
		<>
			<TextField
				id='filtrar-contas-analiticas'
				margin='dense'
				label='Filtrar contas analíticas'
				fullWidth
				onChange={(e) => {
					setFilter(e.target.value);
				}}
				value={filter}
			/>
			<MaterialTreeView
				id='tree-view-contas'
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpanded={['root']}
				expanded={expanded}
				defaultExpandIcon={<ChevronRightIcon />}
			>
				{(hasItems &&
					filteredTreeData.map((n, index) => (
						<RenderTree
							id={`tree-view-item-${index}`}
							key={`key-tree-view-item-${index}`}
							{...{ ...n, index: index }}
							{...props}
							setExpanded={setExpanded}
						/>
					))) || <div>A pesquisa não encontrou nenhum resultado...</div>}
			</MaterialTreeView>
		</>
	);
};

export default TreeView;
