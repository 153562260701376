import { useForm } from 'react-final-form';

const ExibeSeEmitirBoletosTrue = ({ children = null }) => {
	const { getState } = useForm();
	const {
		values: { emitir_boletos },
	} = getState();
	return !emitir_boletos ? null : children;
};

export default ExibeSeEmitirBoletosTrue;
