import React from 'react';
import { useGetIdentity } from 'react-admin';
import TimelineIcon from '@material-ui/icons/Timeline';
import UserMenuItem from '../../common/UserMenuItem';

const MenuItemVersion = ({ url }) => {
	const { identity, loading } = useGetIdentity();
	return (
		<UserMenuItem
			menuItemProps={{
				href: url,
				rel: 'noopener',
				target: '_blank',
			}}
			label={loading ? '' : `${identity.system.version || ''} (${identity.system.version_date || ''})`}
			icon={<TimelineIcon />}
		/>
	);
};

export default MenuItemVersion;
