export function getByteLength(contents, options) {
	if (typeof Buffer !== 'undefined') {
		return Buffer.byteLength(contents, options.encoding);
	}
	return encodeURIComponent(contents).replace(/%[A-F\d]{2}/g, 'U').length;
}

// isInteger = is NOT a float but still a number
export function isInteger(value) {
	return typeof value === 'number' && parseInt(value.toString(), 10) === value;
}
