import React from 'react';

import { Box } from '@material-ui/core';

import memoize from '../../../../../common/memoize';
import { validacoesFieldsTabInformacao, iterArrayValidacoes } from '../../utils/validadoes';
import {
	TextInputNomeConta,
	SelectTipoConta,
	SecondRowFieldsTabInformacao,
	ThirdRowFieldsTabInformacao,
} from '../../tabs/TabInformacao';

const getValidate = (props) => (values) =>
	iterArrayValidacoes({
		...props,
		values,
		errors: {},
		validacoes: validacoesFieldsTabInformacao,
	});

const formatarInitialValues = ({ conta_condominio }) => conta_condominio;

const getFormataPayloadValues =
	({ conta_condominio }) =>
	({ imprimir_saldo_relatorios, apelido_conta_bancaria, nome_conta, data_saldo_inicial, saldo_inicial }) => ({
		nome_conta,
		apelido_conta_bancaria,
		data_saldo_inicial: data_saldo_inicial,
		saldo_inicial,
		imprimir_saldo_relatorios,
		id: conta_condominio.id,
		id_condominio: conta_condominio.id_condominio,
		situacao: conta_condominio.situacao,
		tipo_conta: conta_condominio.tipo_conta,
		tipo_conta_bancaria: conta_condominio.tipo_conta_bancaria,
	});

const FirstRowFields = () => (
	<Box display='grid' gridColumnGap='0.5rem' gridTemplateColumns='repeat(3, 33%)'>
		<Box gridColumn='1'>
			<TextInputNomeConta />
		</Box>
		<Box gridColumn='2'>
			<SelectTipoConta />
		</Box>
	</Box>
);

const TabInformacao = () => (
	<Box>
		<FirstRowFields />
		<SecondRowFieldsTabInformacao />
		<ThirdRowFieldsTabInformacao />
	</Box>
);

const ContaCaixaProps = {
	instituicaoCodigo: 'default',
	getDisabledTabs: memoize(() => ({ disabled0: false })),
	tabContents: {
		TabInformacao,
	},
	props: {
		tipo_conta: {
			source: 'tipo_conta',
			name: 'tipo_conta',
			id: 'tipo_conta',
			choices: [
				{
					id: 'C',
					text: 'Caixa',
				},
			],
		},
	},
	getFormataPayloadValues,
	getValidate,
	formatarInitialValues,
	getOptions: () => ({ DEFAULT: { agencia: { choices: [], objects: {} } } }),
};

export default ContaCaixaProps;
