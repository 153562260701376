import React, { memo } from 'react';
import { XMLParser as XMLP } from 'fast-xml-parser';

const XMLParser = new XMLP({
	ignoreAttributes: false,
	preserveOrder: true,
	processEntities: true,
	htmlEntities: true,
	trimValues: false,
});

const elementMap = {
	p: (children, key) => <p key={key}>{children}</p>,
	em: (children, key) => <em key={key}>{children}</em>,
	u: (children, key) => <u key={key}>{children}</u>,
	strong: (children, key) => <strong key={key}>{children}</strong>,
	ul: (children, key) => (
		<ul key={key} style={{ marginLeft: '21px' }}>
			{children}
		</ul>
	),
	li: (children, key) => <li key={key}>{children}</li>,
	ol: (children, key) => (
		<ol key={key} style={{ marginLeft: '23px' }}>
			{children}
		</ol>
	),
	s: (children, key) => <s key={key}>{children}</s>,
	h2: (children, key) => (
		<h2 style={{ fontSize: '1.5rem' }} key={key}>
			{children}
		</h2>
	),
};

const createJSTree = (XMLParsedData) =>
	XMLParsedData.reduce((acc, curr, i) => {
		const [key] = Object.keys(curr);
		const element = key;
		const value = curr[key];
		const reactElementKey = Math.random() * (i || 1) * 1024;

		if (Array.isArray(value)) {
			const nestedElement = createJSTree(value);
			const elementFormatter = elementMap[element] ?? elementMap['p'];
			acc.push(elementFormatter(nestedElement, reactElementKey));
			return acc;
		}
		acc.push(value);
		return acc;
	}, []);

const JsTreeFromXML = ({ XMLString }) => {
	return <>{createJSTree(XMLParser.parse(XMLString))}</>;
};

export default memo(JsTreeFromXML);
