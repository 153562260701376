import React from 'react';

import { Box } from '@material-ui/core';

import EditaContaBancariaTextInput from '../../commons/EditaContaBancariaTextInput';
import { parseCedentePattern1, formatCedentePattern1 } from '../../utils/formaters_parses';

const ContaCedenteField = () => (
	<Box pl={1} sx={{ 'max-width': '17%' }} flex={1}>
		<EditaContaBancariaTextInput
			source='conta_cedente'
			name='conta_cedente'
			id='conta_cedente'
			label='Conta Cedente/Beneficiário'
			parse={parseCedentePattern1(0)}
			format={formatCedentePattern1(0)}
		/>
	</Box>
);

export default ContaCedenteField;
