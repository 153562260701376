import React, { useContext } from 'react';

import { useNotify, useRefresh, useDataProvider } from 'react-admin';

import { CustomDialogBody, ModalContext } from '../../common/ModalContext';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';

const ModalDesfazerPagamentos = ({ selectedIds }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue } = useContext(ModalContext);
	const [loading, setLoading] = React.useState(false);

	const handleSim = () => {
		if (loading) return;
		setLoading(true);
		dataProvider
			.updateOnAction('parcela', {
				data: { detail: false, ids: selectedIds.map((v) => ({ id: v.id })), action: 'desfazer_pagamento' },
			})
			.then((response) => {
				const data = response?.data;

				data ? notify(data.status) : notify('Erro inesperado, tente recarregar a página', 'warning');
				setLoading(false);
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setLoading(false);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
				refresh();
			});
	};

	const handleNao = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBody
			title={
				selectedIds.length > 1
					? 'Tem certeza que deseja desfazer esses pagamentos?'
					: 'Tem certeza que deseja desfazer esse pagamento?'
			}
			text={
				selectedIds.length > 1
					? 'Os lançamentos de despesa provenientes destes pagamentos serão excluídos'
					: 'O lançamento de despesa proveniente deste pagamento será excluído'
			}
			customActions={
				<>
					<ButtonCancel onClick={handleNao} />
					<ButtonConfirm disabled={loading} onClick={handleSim}>
						Confirmar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

export default ModalDesfazerPagamentos;
