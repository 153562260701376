import React, { useContext } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';

const useStyles = makeStyles((theme) => {
	return {
		cancelButton: {
			color: theme.palette.syndikosRed.main,
			'&:hover': {
				backgroundColor: alpha(theme.palette.syndikosRed.main, 0.05),

				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	};
});

const InativarLeituraButton = ({ leituraId, isProvisionado }) => {
	// TODO: Refatorar componente para ficar parecido com a inativação do AcoesForm.js
	const classes = useStyles();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dP = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const handleInative = () => {
		dP.update('leitura', {
			id: leituraId,
			data: { situacao: 'I' },
		})
			.then(() => {
				notify('Leitura inativada com sucesso!');
				setModalValue((v) => ({ ...v, open: false }));
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(
					Object.values(e?.response?.data || {})[0] || ['Erro inesperado, tente recarregar a página'],
					'warning'
				);
			});
	};

	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const onClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBodySizeEditable
					title={'Tem certeza que deseja inativar essa leitura?'}
					customActions={
						<>
							<ButtonCancel onClick={handleClose} />
							<ButtonConfirm size='small' onClick={handleInative}>
								Inativar
							</ButtonConfirm>
						</>
					}
				/>
			),
		}));
	};
	return (
		<Tooltip title={isProvisionado ? 'Leitura possui arrecadações' : ''}>
			<span>
				<Button
					onClick={onClick}
					variant='text'
					className={classes.cancelButton}
					size='small'
					disabled={isProvisionado}
				>
					Inativar
				</Button>
			</span>
		</Tooltip>
	);
};

export default InativarLeituraButton;
