import { createContext } from 'react';

export const fuctionsRowIsNotCheckable = {
	vazio: () => false,
	positivo: (row) => row.tipo_lancamento === 'D',
	negativo: (row) => row.tipo_lancamento !== 'D',
};

const FluxoImportacaoExtratoBancarioContext = createContext(null);

export default FluxoImportacaoExtratoBancarioContext;
