import React, { useEffect, useState } from 'react';

import { AppBar, Layout, useGetIdentity } from 'react-admin';

import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import api from '../../services/api';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import { AdminContext } from 'components/common/AdminContextProvider';

import MenuItemAreaCliente from './UserMenuItems/MenuItemAreaCliente';
import MenuItemNomeUser from './UserMenuItems/MenuItemNomeUser';
import MenuItemVersion from './UserMenuItems/MenuItemVersion';
import UserMenu from './UserMenu';
import Sidebar from './Sidebar';
import MenuItemAdministradora from './UserMenuItems/MenuItemAdministradora';
import phpSerialize from '../../utils/php_serializer/phpSerialize';
import CustomNotificationIcon from './CustomNotificationIcon';
import MenuItemToggleTheme from './UserMenuItems/MenuItemToggleTheme';
import MenuTvSciSyndkos from './UserMenuItems/MenuTvSciSyndkos';
import AtendimentoIcon from './AtendimentoIcon';
import MenuControleLicencas from './UserMenuItems/MenuControleLicencas';
import MenuFAQSyndkos from './UserMenuItems/MenuItemFAQ';
import FAQIcon from './FaqIcon';
import LicencaIcon from './LicencaIcon';

const useStyles = makeStyles({
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
});

const CustomAppBar = (props) => {
	const classes = useStyles();
	const { identity } = useGetIdentity();
	const [loginurl] = useState('https://areadocliente.sci10.com.br/loginFormDo.php?aDado=');
	const [urls, setUrls] = useState({
		areaCliente: loginurl,
		version: loginurl,
		tvSci: loginurl,
		faq: loginurl,
		licenca: loginurl,
	});
	const localStorageAuth = JSON.parse(localStorage.getItem('auth')) || {};
	const [apelidoAdministradora, setApelidoAdministradora] = useState(localStorageAuth.tenant?.apelido || '');
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { visualizarBanner, midias } = React.useContext(AdminContext);
	useEffect(() => {
		if (!visualizarBanner || !midias?.imagem_modal_banner_semanal) return;
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: midias?.url_callback_para_imagem_modal_banner_semanal ? (
				<a
					href={midias.url_callback_para_imagem_modal_banner_semanal}
					target='_blank'
					rel='noreferrer'
					style={{ overflow: 'hidden' }}
				>
					<img
						src={midias?.imagem_modal_banner_semanal}
						alt='Logo'
						style={{ display: 'block', width: '100%', height: '100%' }}
					/>
				</a>
			) : (
				<img src={midias?.imagem_modal_banner_semanal} alt='Logo' style={{ width: '100%', height: '100%' }} />
			),
		}));
	}, [visualizarBanner, midias]);

	useEffect(() => {
		const handleAuthChange = () => {
			const localStorageAuth = JSON.parse(localStorage.getItem('auth')) || {};
			setApelidoAdministradora(localStorageAuth.tenant.apelido);
		};

		window.addEventListener('storage', handleAuthChange);

		return () => {
			window.removeEventListener('storage', handleAuthChange);
		};
	}, []);

	useEffect(() => {
		if (identity)
			api.get('/api/v1/pwd').then((res) => {
				if (res?.data) {
					let dados = {
						login: identity.username,
						senha: res.data.pwd,
					};
					setUrls((state) => ({
						areaCliente:
							state.areaCliente +
							btoa(
								phpSerialize({
									...dados,
									pagina: 'modulo/usuarioAdicional/index.php',
								})
							),
						version:
							state.version +
							btoa(
								phpSerialize({
									...dados,
									pagina: 'modulo/noticia/index.php?sistema=Syndkos',
								})
							),
						tvSci:
							state.tvSci +
							btoa(
								phpSerialize({
									...dados,
									pagina: 'modulo/tv/produto.php?id=202&tipo=visual',
								})
							),
						faq:
							state.faq +
							btoa(
								phpSerialize({
									...dados,
									pagina: 'modulo/faq/view.php?linha=e&sistemaId=202',
								})
							),
						licenca:
							state.licenca +
							btoa(
								phpSerialize({
									...dados,
									pagina: 'modulo/loja/form.php?sistema=syndkos',
								})
							),
					}));
				}
			});
	}, [identity]);
	return (
		<AppBar
			{...props}
			style={{
				transform: 'none !important',
				visibility: 'visible !important',
			}}
			userMenu={
				<UserMenu>
					<MenuItemNomeUser />
					<MenuItemAdministradora />
					<MenuItemAreaCliente url={urls.areaCliente} />
					<MenuItemVersion url={urls.version} />
					<MenuTvSciSyndkos url={urls.tvSci} />
					<MenuControleLicencas url={urls.licenca} />
					{/* <MenuItemToggleTheme /> */}
					<MenuFAQSyndkos url={urls.faq} />
				</UserMenu>
			}
		>
			<Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
				<Box>
					<Typography
						variant='h6'
						style={{ color: '#FAFAFA' }}
						className={classes.title}
						id='react-admin-title'
					/>
				</Box>
				<Box ml={'9vw'}>
					<Typography variant='h5' style={{ color: '#FAFAFA' }}>
						{apelidoAdministradora}
					</Typography>
				</Box>
				<Box display='flex'>
					<FAQIcon url={urls.faq} />
					<LicencaIcon url={urls.licenca} />
					<CustomNotificationIcon />
					<AtendimentoIcon />
				</Box>
			</Box>
		</AppBar>
	);
};

const CustomAppBarComModal = (props) => (
	<ModalSizeEditableContextProvider>
		<CustomAppBar {...props} />
	</ModalSizeEditableContextProvider>
);

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBarComModal} sidebar={Sidebar} />;

export default CustomLayout;
