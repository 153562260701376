import React from 'react';
import { LinearProgress, Tooltip, Box } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { UserThemeContext } from '../../../../theme/context';
import { KSurveyResultModalProps, KSurvey } from './types';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { GoBackButton } from 'components/common/commonComponentsTSX';

const InitialSelectedSurvey = {
	id: null,
	titulo: '',
	questions: [],
	remaining_votes_number: 0,
	situation: '',
};

const useStyles = makeStyles(() => ({
	progressBar: {
		backgroundColor: '#2F345D',
	},
	emptyBar: {
		backgroundColor: '#7481a5',
	},

	progressLabel: {
		padding: '30px 0',
		zIndex: 1,
		color: 'black',
		display: 'flex',
		gap: '430px',
	},
}));

const SurveyResultModal: React.FC<KSurveyResultModalProps> = ({ surveyId }) => {
	const { theme } = React.useContext(UserThemeContext);
	const { setModalValue } = React.useContext(ModalContext);
	const [selectedSurvey, setSelectedSurvey] = React.useState<KSurvey>(InitialSelectedSurvey);
	const notify = useNotify();
	const dp = useDataProvider() as any;

	React.useEffect(() => {
		if (!selectedSurvey) return;
		dp.getSimple(`enquetes/${surveyId}`)
			.then((response: any) => {
				const survey: KSurvey = response.data;
				if (survey) {
					return setSelectedSurvey(survey);
				}
				return Promise.reject('Não foi encontrado enquete');
			})
			.catch((e: any) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify('Não foi encontrado enquete', { type: 'warning' });
				return setModalValue((v: any) => ({ ...v, open: false }));
			});
	}, []);

	const classes = useStyles();

	const actions = (
		<>
			<GoBackButton onClick={() => setModalValue((v: any) => ({ ...v, open: false }))} />
		</>
	);

	return (
		<CustomDialogBody
			title={
				<Box display='flex' justifyContent={'center'} alignItems={'center'}>
					<span>Resultado da Enquete</span>
					<Tooltip
						style={{ marginLeft: 10 }}
						title={
							<>
								<p style={{ textAlign: 'center' }}>Entendendo o Gráfico</p>
								<p>1 - O resultado dos votos é baseado somente nas pessoas que votaram.</p>
								<p>
									2 - As pessoas autorizadas para votar e que não votaram, serão contabilizadas na
									legenda abaixo do gráfico.
								</p>
								<p>3 - As pessoas não autorizadas a votar, não serão computadas.</p>
							</>
						}
					>
						<InfoOutlined />
					</Tooltip>
				</Box>
			}
			customActions={actions}
			form={{
				component: (
					<Box
						style={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						<Box style={{ height: '1px', backgroundColor: theme === 'dark' ? 'white' : 'black' }}></Box>
						<div
							style={{
								marginTop: '12px',
								fontSize: '18px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<span style={{ fontWeight: 'bold', paddingRight: '5px' }}>Título:</span>
							<span>{selectedSurvey.titulo}</span>
						</div>
						{selectedSurvey &&
							selectedSurvey.questions.length &&
							selectedSurvey.questions.map((question) => {
								const totalVotes = question?.question_answer_options?.reduce(
									(acc, answer) => acc + answer.answer_votes,
									0
								);
								return (
									<Box key={`question_${question.id}`}>
										<Box style={{ marginRight: '50px', marginTop: '20px', marginBottom: '5px' }}>
											<strong>{question.question}</strong>
										</Box>

										{question.question_answer_options.map((answer) => {
											const percentage =
												totalVotes > 0
													? ((answer.answer_votes / totalVotes) * 100).toFixed(2)
													: '0';
											return (
												<Box key={`answer_${answer.id}`}>
													{
														<Box
															style={{
																marginRight: '50px',
																marginTop: '20px',
																marginBottom: '5px',
																width: '100%',
																position: 'relative',
															}}
														>
															<span>{answer.descricao}</span>
															<Box
																className={classes.progressLabel}
																style={{ position: 'absolute', right: 0, top: 0 }}
															>
																<span
																	style={{
																		color: 'white',
																		marginLeft: '5px',
																		width: '20px',
																	}}
																>
																	{percentage}%
																</span>
																<span style={{ color: 'white', marginRight: '10px' }}>
																	{answer.answer_votes}
																	{answer.answer_votes === 1 ? ' voto' : ' votos'}
																</span>
															</Box>
															<Box>
																<LinearProgress
																	classes={{
																		bar: classes.progressBar,
																		root: classes.emptyBar,
																	}}
																	variant='determinate'
																	value={parseFloat(percentage)}
																	style={{
																		height: '40px',
																		border: '1px solid black',
																		borderRadius: '3px',
																	}}
																/>
															</Box>
														</Box>
													}
												</Box>
											);
										})}
									</Box>
								);
							})}
						{selectedSurvey && selectedSurvey.situation == 'E' ? (
							<div style={{ marginTop: '8px' }}>
								<span>
									<strong>* Total de pessoas que restam votar: </strong>
									{selectedSurvey.remaining_votes_number}
								</span>
							</div>
						) : null}
					</Box>
				),
			}}
		/>
	);
};

export default SurveyResultModal;
