import getDay from 'date-fns/getDay';

const weekDays = {
	0: 'DOM',
	1: 'SEG',
	2: 'TER',
	3: 'QUA',
	4: 'QUI',
	5: 'SEX',
	6: 'SAB',
};

const weekDayToString = (date) => weekDays[getDay(date)];

export default weekDayToString;

const weekDaysLong = {
	0: 'Domingo',
	1: 'Segunda',
	2: 'Terça',
	3: 'Quarta',
	4: 'Quinta',
	5: 'Sexta',
	6: 'Sábado',
};

export const weekDayToLongString = (date) => weekDaysLong[getDay(date)];
