import React, { useState, useEffect, useCallback } from 'react';

import { useListContext, useGetList, useEditContext } from 'react-admin';

import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

const CondominioSelectFilter = ({ source }) => {
	const [value, setValue] = useState(null);
	const {
		setFilters,
		filterValues: { condominio, ...restFilterValues },
	} = useListContext();
	const [options, setOptions] = useState([]);
	const [lengthOption, setLengthOption] = useState(0);
	const { record } = useEditContext();
	const { data, loading } = useGetList(
		'condominios',
		{ perPage: 10000, page: 1 },
		{ field: 'nome', order: 'ASC' },
		{
			situacao: 'A',
			tem_conta_receber: true,
			tem_conta_receber_pessoa_id: record.id,
		}
	);

	const init = useCallback(() => {
		if (condominio && options.length > 0 && value?.id !== condominio) {
			setValue(options.find((o) => o.id === condominio));
		}
	}, [condominio, options, value, setValue]);
	useEffect(init, [condominio, options]);

	const updateCondominio = useCallback(() => {
		if (condominio && data && !loading && !data[condominio]) {
			setValue(null);
			setFilters({
				...restFilterValues,
			});
		}
	}, [data, loading, setValue, setFilters, restFilterValues, condominio]);
	useEffect(updateCondominio, [loading]);

	const updatelengthOption = useCallback(() => {
		let aux = 0;
		options.forEach((v) => {
			if (v.name.length > aux) aux = v.name.length;
		});
		setLengthOption(aux);
	}, [options, setLengthOption]);

	useEffect(updatelengthOption, [options]);

	const updateOptions = () => {
		if (data) {
			setOptions(
				Object.values(data)
					.filter((v) => v.id !== 0)
					.map((item) => ({
						id: item.id,
						name: item.nome,
					}))
			);
		}
	};

	useEffect(updateOptions, [data]);

	return (
		<Autocomplete
			openOnFocus
			value={value || null}
			id={`${source}-filtro`}
			options={options}
			onChange={(e, newValue) => {
				const id = parseInt(newValue?.id);
				if (!newValue || !id) setValue(null);
				setFilters(id ? { ...restFilterValues, condominio: id } : { ...restFilterValues });
			}}
			renderOption={(option) => option.name}
			getOptionLabel={(option) => (option.name ? option.name : '')}
			getOptionSelected={(option, newValue) => newValue.id === option.id}
			renderInput={(params) => <TextField {...params} label='Filtrar' variant='standard' />}
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						width: `${lengthOption * 10}pt`,
						maxWidth: '320px',
						minWidth: '60px',
					}}
				/>
			)}
			fullWidth
			noOptionsText='Nenhum resultado'
			disableListWrap
		/>
	);
};

export default CondominioSelectFilter;
