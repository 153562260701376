import React from 'react';

import { useNotify } from 'react-admin';

import { Button } from '@material-ui/core';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../../ModalSizeEditableContext';
import { ButtonCancel } from '../../../buttons/ButtonCancel';

import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { useDataProvider } from 'react-admin';

import { EditEmailTemplateBox } from './EditEmailTemplateBox';
import { CreateEmailTemplateBox } from './CreateEmailTemplateBox';

const tituloModalEditorTexto = {
	create: 'Criar Novo Modelo Personalizado',
	edit: 'Editar Modelo Personalizado',
};

export const ModalCreateEditTemplateBox = ({ action, template, setReload }) => {
	// Variaveis
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	const [assuntoModeloNovo, setAssuntoModeloNovo] = React.useState('');
	const [textoModeloNovo, setTextoModeloNovo] = React.useState('');
	const [nomeModeloNovo, setNomeModeloNovo] = React.useState('');

	const [assuntoModeloEdit, setAssuntoModeloEdit] = React.useState('');
	const [textoModeloEdit, setTextoModeloEdit] = React.useState('');
	const [nomeModeloEdit, setNomeModeloEdit] = React.useState('');
	const [IDModeloEdit, setIDModeloEdit] = React.useState(0);

	const [loading, setLoading] = React.useState(false);
	const dP = useDataProvider();
	const notify = useNotify();

	// Funções Handle
	const handleSaveValueNewTemplate = (data, assuntoEmailTemplate, nomeModeloTemplate) => {
		setNomeModeloNovo(nomeModeloTemplate);
		setAssuntoModeloNovo(assuntoEmailTemplate);
		setTextoModeloNovo(data);
	};

	const handleSaveEditTemplateValue = (IDModeloEdit, assuntoModeloEdit, nomeModeloEdit, textoModeloEdit) => {
		setIDModeloEdit(IDModeloEdit);
		setTextoModeloEdit(textoModeloEdit);
		setAssuntoModeloEdit(assuntoModeloEdit);
		setNomeModeloEdit(nomeModeloEdit);
	};

	// Requisição Post Save Template Personalizado
	const handleSaveTemplateEmail = () => {
		setLoading(true);

		dP.simplePost('email_template', {
			data: {
				mensagem_json: JSON.parse(textoModeloNovo),
				assunto: assuntoModeloNovo,
				nome: nomeModeloNovo,
			},
		})
			.then(() => {
				notify('Modelo de e-mail cadastrado com sucesso', 'success');
				setReload((v) => v + 1);
				setModalValue((v) => ({ ...v, open: false }));
			})
			.catch(() => {
				notify('Falha ao salvar modelo de e-mail, preencha ao menos o campo de nome do modelo.', 'warning');
				setLoading(false);
			});
	};

	// Requisição Delete Template Personalizado:
	const handleDeleteTemplateEmail = () => {
		setLoading(true);

		dP.delete('email_template', { id: IDModeloEdit })
			.then(() => {
				notify('Modelo de e-mail removido', 'success');
				setReload((v) => v + 1);
				setModalValue((v) => ({ ...v, open: false }));
			})
			.catch(() => {
				notify('Falha ao excluir modelo de e-mail', 'warning');
				setLoading(false);
			});
	};

	// Requisição Post Edit Template Personalizado:
	const handleEditTemplateEmail = () => {
		setLoading(true);

		dP.update('email_template', {
			id: IDModeloEdit,
			data: {
				mensagem_json: JSON.parse(textoModeloEdit),
				assunto: assuntoModeloEdit,
				nome: nomeModeloEdit,
			},
		})
			.then(() => {
				notify('Modelo de e-mail alterado com sucesso', 'success');
				setReload((v) => v + 1);
				setModalValue((v) => ({ ...v, open: false }));
			})
			.catch(() => {
				notify('Falha ao alterar modelo de e-mail', 'warning');
				setLoading(false);
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title={tituloModalEditorTexto[action]}
			form={{
				component: (
					<>
						{action === 'create' && <CreateEmailTemplateBox handleSave={handleSaveValueNewTemplate} />}
						{action === 'edit' && (
							<EditEmailTemplateBox handleEdit={handleSaveEditTemplateValue} template={template} />
						)}
					</>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
					{action === 'create' && (
						<Button
							color='primary'
							size='small'
							variant='contained'
							startIcon={loading ? <></> : <SaveIcon />}
							disabled={loading}
							onClick={handleSaveTemplateEmail}
						>
							{(loading && <CircularProgress size={20} />) || 'Salvar'}
						</Button>
					)}
					{action === 'edit' && (
						<>
							<Button
								color='primary'
								size='small'
								variant='contained'
								startIcon={loading ? <></> : <DeleteIcon />}
								disabled={loading}
								onClick={handleDeleteTemplateEmail}
							>
								{(loading && <CircularProgress size={20} />) || 'Excluir'}
							</Button>{' '}
							<Button
								color='primary'
								size='small'
								variant='contained'
								startIcon={loading ? <></> : <SaveIcon />}
								disabled={loading}
								onClick={handleEditTemplateEmail}
							>
								{(loading && <CircularProgress size={20} />) || 'Salvar'}
							</Button>
						</>
					)}
				</>
			}
		/>
	);
};
