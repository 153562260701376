import React, { useState, useEffect } from 'react';
import { useListContext } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import { RawKBDatePicker } from '../InputDate';

interface IDataProps {
	source?: string;
	custom?: string;
	mask?: string;
	format?: string;
	views?: string[];
	listControllerProps?: ReturnType<typeof useListContext>;
}

export const Data: React.FC<IDataProps> = ({
	source,
	custom,
	mask = '__/__/____',
	format = 'dd/MM/yyyy',
	views = undefined,
	listControllerProps,
}) => {
	const [value, setValue] = useState<null | Date>(null);
	const { setFilters, filterValues } = useListContext(listControllerProps);
	useEffect(() => {
		const index = custom || source;
		if (!index) return;
		const oldValue = filterValues[index];
		if (oldValue) setValue(new Date(`${oldValue}T10:00`));
	}, [filterValues, custom, source]);

	const handleChange = (newValue: null | Date) => {
		const index = custom || source;
		if (!index) return;
		setValue(newValue);
		let newValueFilter =
			newValue && newValue.toString && newValue.toString() !== 'Invalid Date'
				? newValue.toISOString().split('T')[0]
				: undefined;
		if (views?.length === 2 && newValueFilter) {
			newValueFilter = newValueFilter.substr(0, 8) + '01';
		}
		setFilters({ ...filterValues, [index]: newValueFilter }, undefined);
	};

	return (
		<RawKBDatePicker
			cancelLabel='Cancelar'
			clearable={true}
			clearLabel={<CloseIcon />}
			inputVariant='standard'
			id={`${custom || source}-filtro-padrao`}
			onChange={handleChange}
			initialFocusedDate={value}
			value={value}
			label='Filtrar'
			style={{ width: '100%' }}
			error={false}
			helperText={null}
			mask={mask}
			format={format}
			{...{ views }}
		/>
	);
};

export const DataReferencia: React.FC<IDataProps> = ({
	source,
	custom,
	mask = '__/____',
	format = 'MM/yyyy',
	views = ['year', 'month'],
	listControllerProps,
}) => {
	const [value, setValue] = useState<null | Date>(null);
	const { setFilters, filterValues } = useListContext(listControllerProps);
	useEffect(() => {
		const index = custom || source;
		if (!index) return;
		const oldValue = filterValues[index];
		if (oldValue) setValue(new Date(`${oldValue}T10:00`));
	}, [filterValues, custom, source]);

	const handleChange = (newValue: null | Date) => {
		const index = custom || source;
		if (!index) return;
		newValue = filterValues[index];
		setValue(newValue);
		let newValueFilter =
			newValue && newValue.toString && newValue.toString() !== 'Invalid Date'
				? newValue.toISOString().split('T')[0]
				: undefined;
		if (views?.length === 2 && newValueFilter) {
			newValueFilter = newValueFilter.substr(0, 8) + '01';
		}
		setFilters({ ...filterValues, [index]: newValueFilter }, undefined);
	};

	return (
		<RawKBDatePicker
			cancelLabel='Cancelar'
			clearable={true}
			clearLabel={<CloseIcon />}
			inputVariant='standard'
			id={`${custom || source}-filtro-padrao`}
			onChange={handleChange}
			initialFocusedDate={value}
			value={value}
			label='Filtrar'
			style={{ width: '100%' }}
			error={false}
			helperText={null}
			mask={mask}
			format={format}
			{...{ views }}
		/>
	);
};
