import React, { useContext } from 'react';

import PublishIcon from '@material-ui/icons/Publish';

import { ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';
import { TooltipIconButton } from '../../../../../common/TooltipIconButton';
import FluxoImportacaoExtratoBancarioContextProvider from '../FluxoImportacaoExtratoBancarioContextProvider';

const TooltipImportarExtratoBancarioButton = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<TooltipIconButton
			title='Importar Extratos Bancários'
			size='small'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <FluxoImportacaoExtratoBancarioContextProvider />,
				}));
			}}
			pl='5px'
		>
			<PublishIcon />
		</TooltipIconButton>
	);
};

export default TooltipImportarExtratoBancarioButton;
