import React, { useContext } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';

const SalvarConfigButton = ({ dadosConfigEdicao, dadosConfig, disabled }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const onClick = () => {
		const {
			nome_leitura,
			referencia,
			faixas_leitura,
			nao_cobrar_taxa,
			acesso_app,
			leituras_para_calculo,
			percentual,
		} = dadosConfigEdicao;
		const dadosRequest = {
			nome_leitura: nome_leitura,
			id_leitura: dadosConfig.id_leitura,
			conta_receita: dadosConfig.conta_receita,
			id_condominio: dadosConfig.id_condominio,
			metodo_calculo: dadosConfig.metodo_calculo,
			unidades_ids: dadosConfig.unidades,
			grupos_ids: dadosConfig.grupos,
			unidade_medida: dadosConfig.unidade_medida,
			fator_convercao: dadosConfig.fator_convercao,
			acesso_app: acesso_app,
		};
		if (dadosConfig.referencia === referencia) {
			if (dadosConfig.nome_metodo_calculo === 'Percentual sobre leitura') {
				dadosRequest['leituras_para_calculo_ids'] = leituras_para_calculo.filter(
					(leitura_escolhida) => !dadosConfig.leituras_para_calculo.includes(leitura_escolhida)
				);
				dadosRequest['leituras_para_calculo_ids_delete'] = dadosConfig.leituras_para_calculo.filter(
					(leitura_ant_escolhida) => !leituras_para_calculo.includes(leitura_ant_escolhida)
				);
				dadosRequest['percentual'] = percentual;
			} else if (
				['Distribuição por faixa', 'Valor fixo por faixa', 'Valor fixo por m³ ou Kw/h'].includes(
					dadosConfig.nome_metodo_calculo
				)
			) {
				if (['Distribuição por faixa', 'Valor fixo por faixa'].includes(dadosConfig.nome_metodo_calculo))
					dadosRequest['nao_cobrar_taxa'] = nao_cobrar_taxa;
				dadosRequest['faixas_id_a_deletar'] = dadosConfig.faixas_leitura
					.slice(faixas_leitura.length)
					.map((faixa) => faixa.id);
				dadosRequest['faixas_novas'] = faixas_leitura.slice(dadosConfig.faixas_leitura.length).map((faixa) =>
					Object({
						ate: faixa.ate,
						valor: faixa.valor,
						taxa_fixa_adicional: faixa.taxa_fixa_adicional,
					})
				);
				dadosRequest['faixas_para_atualizar_instance'] = [];
				dadosRequest['faixas_para_atualizar_dados'] = faixas_leitura
					.slice(0, dadosConfig.faixas_leitura.length)
					.filter((nF, i) => {
						const oF = dadosConfig.faixas_leitura[i];
						const faixa_antiga_foi_alterada =
							nF.de !== oF.de ||
							nF.ate !== oF.ate ||
							nF.valor !== oF.valor ||
							nF.taxa_fixa_adicional !== oF.taxa_fixa_adicional;
						if (faixa_antiga_foi_alterada) dadosRequest['faixas_para_atualizar_instance'].push(oF.id);
						return faixa_antiga_foi_alterada;
					})
					.map((faixa, i) =>
						Object({
							id: dadosRequest['faixas_para_atualizar_instance'][i],
							ate: faixa.ate,
							valor: faixa.valor,
							taxa_fixa_adicional: faixa.taxa_fixa_adicional || 0,
						})
					);
			}
			dP.update('config-leitura', {
				id: dadosConfig.id,
				data: dadosRequest,
			})
				.then((resultado) => {
					setModalValue((v) => ({ ...v, open: false }));
					notify('Configuração de leitura alterada com sucesso!');
					refresh();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					notify('Erro ao alterar a configuração da leitura', 'warning');
				});
		} else {
			if (dadosConfig.nome_metodo_calculo === 'Percentual sobre leitura') {
				dadosRequest['leituras_para_calculo_ids'] = leituras_para_calculo;
				dadosRequest['percentual'] = percentual;
			} else if (
				['Distribuição por faixa', 'Valor fixo por faixa', 'Valor fixo por m³ ou Kw/h'].includes(
					dadosConfig.nome_metodo_calculo
				)
			) {
				if (['Distribuição por faixa', 'Valor fixo por faixa'].includes(dadosConfig.nome_metodo_calculo))
					dadosRequest['nao_cobrar_taxa'] = nao_cobrar_taxa;
				dadosRequest['faixas_novas'] = faixas_leitura.map((faixa) => ({
					ate: faixa.ate,
					valor: faixa.valor,
					taxa_fixa_adicional: faixa.taxa_fixa_adicional || 0,
				}));
			}

			dP.create('config-leitura', {
				data: { ...dadosRequest, referencia: referencia },
			})
				.then(() => {
					setModalValue((v) => ({ ...v, open: false }));
					notify('Nova configuração da leitura cadastrada com sucesso!');
					refresh();
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					notify('Erro ao cadastrar nova configuração para a leitura', 'warning');
				});
		}
	};

	return (
		<Button
			onClick={onClick}
			size='small'
			disabled={disabled}
			variant='text'
			color='primary'
			startIcon={<CheckIcon />}
		>
			Salvar
		</Button>
	);
};

export default SalvarConfigButton;
