import React from 'react';

import { Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { CancelButton } from 'components/common/commonComponentsTSX';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import ModalConfiguracaoImpressao from './ModalConfiguracaoImpressao';
import BoxConfigSegundaVia from '../../../ConfiguracoesBoleto/BoxConfigSegundaVia';
import BoletosContextProvider from '../../ContasReceberBoletos/BoletosContextProvider';

import { IModalValue } from 'types/tpyesGlobal';

export const ModalConfiguracaoImpressaoSegundaVia = () => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	return (
		<BoletosContextProvider>
			<CustomDialogBodySizeEditable
				title='Configuração da Segunda via do Boleto'
				form={{
					component: <BoxConfigSegundaVia />,
				}}
				customActions={
					<>
						<CancelButton
							size='small'
							onClick={() => setModalValue((v: IModalValue) => ({ ...v, open: false, body: <></> }))}
						/>
						<Button
							endIcon={<NavigateNextIcon />}
							size='small'
							onClick={() =>
								setModalValue((v: IModalValue) => ({
									...v,
									open: true,
									dialogBody: <ModalConfiguracaoImpressao segundaVia={true} />,
								}))
							}
						>
							Próximo
						</Button>
					</>
				}
			/>
		</BoletosContextProvider>
	);
};

export default ModalConfiguracaoImpressaoSegundaVia;
