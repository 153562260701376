import React, { useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import { Toolbar, Button } from '@material-ui/core';
import { CustomDialog, ModalContextProvider } from '../../common/ModalContext';
import DisponibilizarRelatorioCondomino from './ButtonDisponibilizarCondomino';
import LoadingPageRelatorio from './LoadingRelatorio';

const RELATORIOS_QUE_PODEM_SER_DISPONIBILIZADO_AO_CONDOMINO = [
	'Balancete',
	'Demonstrativo de Contas a Receber',
	'Prestação de Contas',
	'Razão de Lançamentos',
	'Fluxo de Movimentações Bancárias',
	'Demonstrativo de Arrecadação',
	'Demonstrativo de Arrecadação Simplificado',
	'Demonstrativo de Arrecadação Detalhado',
	'Planilha de Arrecadação',
	'Recebimentos e Devedores',
	'Contas a Pagar',
	'Demonstrativo Mensal',
];

const RelatorioToolbar = ({ data, tipoRelatorio, valid = true, buttons = [] }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const showDisponibilizarCondominio = RELATORIOS_QUE_PODEM_SER_DISPONIBILIZADO_AO_CONDOMINO.includes(
		tipoRelatorio.name
	);
	const [loading, setLoading] = useState(false);

	const handleClick = (e, tipoSaida) => {
		setLoading(true);
		if (valid) {
			dataProvider
				.gerarRelatorio('relatorios', {
					...data,
					tipo_saida: tipoSaida,
					tipoRelatorio: tipoRelatorio.action,
				})
				.then(() => notify('Relatório gerado com sucesso!'))
				.catch(async (e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					let errorMsg = 'Erro inesperado, tente recarregar a página.';
					if (e?.response?.data) {
						try {
							const data = JSON.parse(await e.response.data.text());
							errorMsg = data?.error?.message || Object.values(data)[0] || errorMsg;
						} catch {
							errorMsg = 'Erro inesperado, tente recarregar a página.';
						}
					}
					return notify(errorMsg, 'warning');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<Toolbar>
			{buttons.length ? (
				buttons.map((v, i) => React.cloneElement(v, { key: i }))
			) : (
				<>
					<Button
						color='primary'
						variant='outlined'
						style={{ marginRight: '1em' }}
						onClick={(e) => handleClick(e, 'visualizar')}
						disabled={!valid}
					>
						Visualizar
					</Button>
					<Button
						color='primary'
						variant='outlined'
						style={{ marginRight: '1em' }}
						onClick={(e) => handleClick(e, 'pdf')}
						disabled={!valid}
					>
						Baixar PDF
					</Button>
					{showDisponibilizarCondominio && valid && (
						<ModalContextProvider>
							<DisponibilizarRelatorioCondomino {...{ data, tipoRelatorio }} />
							<CustomDialog />
						</ModalContextProvider>
					)}
					<LoadingPageRelatorio loading={loading} />
				</>
			)}
		</Toolbar>
	);
};

export default RelatorioToolbar;
