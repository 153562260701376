import React from 'react';

import { useForm } from 'react-final-form';

import { Box, useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';

import { isAfter, parseISO } from 'date-fns';

import TooltipIcon from '../../../../../common/TooltipIcon';

const ToolTipIconStatusBase = ({ children, color, title }) => (
	<Box color={color} alignSelf='center'>
		<TooltipIcon title={title}>{children}</TooltipIcon>
	</Box>
);

const StatusIconDadosNaoRegistrados = () => {
	const theme = useTheme();
	return (
		<ToolTipIconStatusBase title='Dados para Integração com API não Registrados' color={theme.red[300]}>
			<WarningIcon />
		</ToolTipIconStatusBase>
	);
};

const StatusIconDadosExpirados = () => {
	const theme = useTheme();
	return (
		<ToolTipIconStatusBase title='Dados para Integração com API Expiraram' color={theme.orange[300]}>
			<WarningIcon />
		</ToolTipIconStatusBase>
	);
};

const StatusIconDadosRegistrados = () => {
	const theme = useTheme();
	return (
		<ToolTipIconStatusBase title='Dados para Integração com API Registrados' color={theme.green[300]}>
			<CheckIcon />
		</ToolTipIconStatusBase>
	);
};

export const StatusTooltipIconDadosItengracaoAPI = () => {
	const { getState } = useForm();
	const {
		values: { dados_integracao_api_expires_at },
	} = getState();

	if (!dados_integracao_api_expires_at) return <StatusIconDadosNaoRegistrados />;

	const agora = new Date();
	if (!isAfter(parseISO(dados_integracao_api_expires_at), agora)) return <StatusIconDadosExpirados />;

	return <StatusIconDadosRegistrados />;
};
