import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { format, isMatch, sub, parseISO, startOfMonth, endOfMonth } from 'date-fns';

import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import { CondominiosContext } from 'context/CondominioContextProvider';

export const BoletosContext = createContext();

const TODAY = new Date().setHours(10, 0, 0);

const optionsModeloDemonstrativo = [
	{ id: 'DA', name: 'Demonstrativo de Arrecadação' },
	{ id: 'PC', name: 'Prestação de Contas' },
	{ id: 'DP', name: 'Demonstrativo de Arrecadação e Prestação de Contas' },
	{ id: 'DM', name: 'Demonstrativo Mensal' },
	{ id: 'AM', name: 'Demonstrativo de Arrecadação e Demonstrativo Mensal' },
];

const ConfigGeracaoBoletoOptions = {
	modelo_demonstrativo: optionsModeloDemonstrativo[0],
	// Demonstrativo Arrecadação
	imprimir_data_leituras: false,
	imprimir_coeficiente: false,
	imprimir_valor_unidade_subordinada: false,
	imprimir_resumo_rateio: false,
	imprimir_valor_total: true,
	imprimir_devedores: false,
	tipo_impressao_devedores_da: false,
	ocultar_valor_m3_e_taxa: false,
	imprimir_posicao_prestacao_contas_direita: false,
	imprimir_posicao_demonstrativo_mensal_direita: false,

	// Prestação de Contas
	imprimir_contas_analiticas: true,
	imprimir_resumo_contas_bancarias: true,

	//Demonstrativo Mensal
	imprimir_apenas_acumulado: false,
	imprimir_devedores_relatorio_mensal: false,

	// Comunicado
	imprimir_comunicado: false,
	comunicado_id: null,

	// Marca Dagua
	imprimir_marca_dagua: false,
	imprimir_proprietario_e_inquilino_campo_pagador: false,
};

const BoletosContextProvider = ({ children }) => {
	const dp = useDataProvider();
	const notify = useNotify();
	const { referencia } = useContext(ArrecadacoesContext);
	const [modeloDemonstrativo, setModeloDemonstrativo] = useState(optionsModeloDemonstrativo[0]);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const [loading, setLoading] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: startOfMonth(sub(parseISO(referencia), { months: 1 })),
		endDate: endOfMonth(sub(parseISO(referencia), { months: 1 })),
	});
	const [datasDemonstrativoMensal, setDatasDemonstrativoMensal] = useState({
		data_inicial: undefined,
		data_final: undefined,
	});
	const [datasPrestacao, setDatasPrestacao] = useState({
		data_inicial: undefined,
		data_final: undefined,
	});
	const [opcoesImpressao, setOpcoesImpressao] = useState(ConfigGeracaoBoletoOptions);
	const [comunicado, setComunicado] = useState('');
	const [comunicadoId, setComunicadoId] = useState(ConfigGeracaoBoletoOptions.comunicado_id);
	const [dataDevedores, setDataDevedores] = useState(format(TODAY, 'dd/MM/yyyy'));
	const [dataEnvioBackendDevedores, setEnvioBackendDevedores] = useState({ data_final_devedores: undefined });
	const [boletoConfigIsValid, setBoletoConfigIsValid] = useState(false);
	const [senha, setSenha] = useState('');

	const handleChangeOpcoesImpressao = useCallback(
		(checked, nameOpcao) => {
			const checked_imprimir_devedores =
				nameOpcao == 'imprimir_devedores' && !checked ? { tipo_impressao_devedores_da: false } : null;

			setOpcoesImpressao((prevConfig) => ({
				...prevConfig,
				tipo_impressao_devedores_da: checked_imprimir_devedores
					? false
					: opcoesImpressao.tipo_impressao_devedores_da,
				[nameOpcao]: checked,
			}));
		},
		[opcoesImpressao, setOpcoesImpressao]
	);

	const init = useCallback(() => {
		setModeloDemonstrativo(ConfigGeracaoBoletoOptions.modelo_demonstrativo);
		setDateRange({
			startDate: startOfMonth(sub(parseISO(referencia), { months: 1 })),
			endDate: endOfMonth(sub(parseISO(referencia), { months: 1 })),
		});
		setDatasDemonstrativoMensal({
			data_inicial: undefined,
			data_final: undefined,
		});
		setDatasPrestacao({
			data_inicial: undefined,
			data_final: undefined,
		});
		setOpcoesImpressao(ConfigGeracaoBoletoOptions);
		setDataDevedores(format(TODAY, 'dd/MM/yyyy'));
		setSenha('');
		setComunicadoId(ConfigGeracaoBoletoOptions.comunicado_id);
	}, [
		setModeloDemonstrativo,
		setDateRange,
		referencia,
		setDatasPrestacao,
		setOpcoesImpressao,
		setDataDevedores,
		setDatasDemonstrativoMensal,
		handleChangeOpcoesImpressao,
		setComunicadoId,
	]);

	const handleDevedores = useCallback(() => {
		setEnvioBackendDevedores({
			data_final_devedores: dataDevedores,
		});
	}, [setEnvioBackendDevedores, dataDevedores]);

	useEffect(handleDevedores, [dataDevedores]);

	const updateBoletoConfigIsValid = useCallback(
		() =>
			setBoletoConfigIsValid(
				!!modeloDemonstrativo?.id &&
					(opcoesImpressao?.imprimir_devedores
						? !!(
								dataEnvioBackendDevedores?.data_final_devedores &&
								isMatch(dataEnvioBackendDevedores.data_final_devedores, 'dd/MM/yyyy')
						  )
						: true)
			),
		[setBoletoConfigIsValid, modeloDemonstrativo, opcoesImpressao, dataEnvioBackendDevedores]
	);
	useEffect(updateBoletoConfigIsValid, [modeloDemonstrativo, opcoesImpressao, dataEnvioBackendDevedores]);

	const providerValue = useMemo(
		() => ({
			boletoConfigIsValid,
			init,
			modeloDemonstrativo,
			setModeloDemonstrativo,
			dateRange,
			setDateRange,
			datasPrestacao,
			setDatasPrestacao,
			opcoesImpressao,
			setOpcoesImpressao,
			dataDevedores,
			setDataDevedores,
			dataEnvioBackendDevedores,
			senha,
			setSenha,
			comunicado,
			setComunicado,
			datasDemonstrativoMensal,
			setDatasDemonstrativoMensal,
			handleChangeOpcoesImpressao,
			loading,
			setLoading,
			comunicadoId,
			setComunicadoId,
		}),
		[
			boletoConfigIsValid,
			init,
			modeloDemonstrativo,
			setModeloDemonstrativo,
			dateRange,
			setDateRange,
			datasPrestacao,
			setDatasPrestacao,
			opcoesImpressao,
			setOpcoesImpressao,
			dataDevedores,
			setDataDevedores,
			dataEnvioBackendDevedores,
			senha,
			setSenha,
			comunicado,
			setComunicado,
			datasDemonstrativoMensal,
			setDatasDemonstrativoMensal,
			handleChangeOpcoesImpressao,
			loading,
			setLoading,
			comunicadoId,
			setComunicadoId,
		]
	);

	useEffect(() => {
		fetchConfigsCondominio();
	}, [condominioSelecionado]);

	function fetchConfigsCondominio() {
		if (!condominioSelecionado?.id) {
			setOpcoesImpressao({ ...ConfigGeracaoBoletoOptions });
			return;
		}
		setLoading(true);
		dp.getSimple('configuracoes_relatorios', {
			filter: { id_condominio: condominioSelecionado?.id, nome_relatorio: 'Boleto' },
		})
			.then(({ data: { results } }) => {
				if (results.length > 0) {
					const configuracaoResultado = results[0].configuracoes_checkbox;
					configuracaoResultado.imprimir_devedores == false
						? (configuracaoResultado['tipo_impressao_devedores_da'] = false)
						: null;

					const configuracaoRelatorio = { ...ConfigGeracaoBoletoOptions, ...configuracaoResultado };
					setOpcoesImpressao(configuracaoRelatorio);
					setComunicadoId(results[0].configuracoes_checkbox.comunicado_id);
					setModeloDemonstrativo(
						results[0].configuracoes_checkbox.modelo_demonstrativo ?? optionsModeloDemonstrativo[0]
					);
				} else {
					setOpcoesImpressao({ ...ConfigGeracaoBoletoOptions });
				}
			})
			.catch((e) => notify('Erro ao buscar configurações cadastradas', 'info'))
			.finally(() => setLoading(false));
	}

	return <BoletosContext.Provider value={providerValue}>{children}</BoletosContext.Provider>;
};

export default BoletosContextProvider;
