import React from 'react';
import Button from '@material-ui/core/Button';
import { TooltipIconButton } from '../TooltipIconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const defaultIcon = <AutorenewIcon />;

export const BotaoAtualizar = ({ startIcon = defaultIcon, children, ...rest }) => {
	return (
		<Button {...rest} color='primary' startIcon={startIcon}>
			{children || 'Atualizar'}
		</Button>
	);
};

export const TooltipIconButtonConfirm = ({ title, children, ...rest }) => {
	return (
		<TooltipIconButton {...rest} title={title || 'Atualizar'} color='primary'>
			{children || <AutorenewIcon />}
		</TooltipIconButton>
	);
};
