import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const brExporter = (data, _, __, resource) => {
	jsonExport(
		data,
		{
			rowDelimiter: ';',
		},
		(err, csv) => {
			downloadCSV(csv, resource);
		}
	);
};

export default brExporter;
