export const cepValidacao = (value) => {
	if (value) {
		if (!value.match(/[0-9]{8}/)) return ['CEP inválido'];
	}
};

export const cepFormatacao = (value) => {
	if (!value) return null;
	if (!value.match(/[0-9]+/)) return null;
	return value.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export const cepParser = (value) => {
	if (value) {
		return value.replace(/[^\d]/g, '').substring(0, 8);
	}
};
