import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CustomDialogBodySizeEditable } from '../../../../common/ModalSizeEditableContext';
import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { BotaoSalvar } from '../../../../common/buttons/BotaoSalvar';

import AmbienteReservaForm from './form';
import { AmbientesContext, valoresHoras } from './context';

function sortRichTextJson(richTextData) {
	const { blocks, ...rest } = JSON.parse(richTextData);
	if (!blocks[0].text) return richTextData;

	const sortedBlocks = blocks.map((item) => {
		const inlineStyleRanges = item.inlineStyleRanges.sort((a, b) => {
			const limitA = a.offset + a.length;
			const limitB = b.offset + b.length;

			if (limitA === limitB) {
				return a.offset <= b.offset ? -1 : 1;
			}
			return limitA <= limitB ? 1 : -1;
		});
		return { ...item, inlineStyleRanges };
	});

	return { blocks: sortedBlocks, ...rest };
}

const generateValidationSchemaDia = ({ valor }) =>
	yup.array().of(
		yup.object().shape({
			dia: yup.string(),
			fracao: yup.string(),
			habilitado: yup.boolean(),
			hora_1: yup.mixed().when('habilitado', {
				is: true,
				then: yup.string().typeError('HH:mm').required('Campo obrigatório'),
				otherwise: yup.mixed().nullable(),
			}),
			hora_2: yup.mixed().when('habilitado', {
				is: true,
				then: yup.string().typeError('HH:mm').required('Campo obrigatório'),
				otherwise: yup.mixed().nullable(),
			}),
			hora_3: yup
				.string()
				.oneOf([...valoresHoras, null])
				.typeError('HH:mm')
				.nullable(),
			hora_4: yup
				.string()
				.oneOf([...valoresHoras, null])
				.typeError('HH:mm')
				.nullable(),
			valor,
		})
	);

const AmbienteReservaModal = ({ grupos, ambiente, callbackActions, contaReceitaInicial }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { idCondominio } = useContext(AmbientesContext);
	const dp = useDataProvider();
	const notify = useNotify();
	const [saving, setSaving] = useState(false);

	const validationSchema = yup.object({
		id_condominio: yup.number().typeError('Precisa ser um número').required('Campo obrigatório'),

		// Tab: Configuração de Horário
		taxa_por_uso: yup.boolean().required('Seleção obrigatória'),
		conta_receita: yup.mixed().when('taxa_por_uso', {
			is: true,
			then: yup.object().typeError('Selecione uma conta').required('Selecione uma conta'),
			otherwise: yup.mixed().nullable(),
		}),
		reserva_inadimplente: yup.boolean().required('Seleção obrigatória'),
		nome: yup
			.string()
			.typeError('Precisa ser um texto')
			.min(3, 'O nome é muito pequeno, precisa ter ao menos 3 caracteres')
			.max(100, 'O nome não pode exceder 100 caracteres')
			.required('Preencha o nome do ambiente'),
		capacidade_pessoas: yup
			.number()
			.typeError('Precisa ser um número')
			.integer('A capacidade precisa ser um número inteiro')
			.moreThan(-1, 'A capacidade não deve ser menor que zero')
			.lessThan(32768, 'A capacidade não deve ultrapassar 32767')
			.nullable(),

		// Tab: Configuração de Horário (dias/periodos)
		dias: yup.mixed().when('taxa_por_uso', {
			is: true,
			then: generateValidationSchemaDia({
				valor: yup.mixed().when('habilitado', {
					is: true,
					then: yup
						.number()
						.typeError('O valor precisa ser um número positivo')
						.positive('É obrigatório preencher um valor positivo')
						.required('É obrigatório preencher um valor'),
					otherwise: yup.mixed().nullable(),
				}),
			}),
			otherwise: generateValidationSchemaDia({ valor: yup.mixed().nullable() }),
		}),

		// Tab: Configuração de Reserva
		reserva_horas_antes: yup
			.number()
			.typeError('Precisa ser um número')
			.integer('Precisa ser um número inteiro')
			.moreThan(-1, 'Não deve ser menor que zero')
			.lessThan(97, 'Não deve ultrapassar 96')
			.required('Campo obrigatório'),
		reserva_dias_antes: yup
			.number()
			.typeError('Precisa ser um número')
			.integer('Precisa ser um número inteiro')
			.moreThan(-1, 'Não deve ser menor que zero')
			.lessThan(366, 'Não deve ultrapassar 365')
			.required('Campo obrigatório'),
		cancelamento_horas_antes: yup
			.number()
			.typeError('Precisa ser um número')
			.integer('Precisa ser um número inteiro')
			.moreThan(-1, 'Não deve ser menor que zero')
			.lessThan(97, 'Não deve ultrapassar 96')
			.required('Campo obrigatório'),
		bloquear_domingo: yup.boolean().required('Seleção obrigatória'),
		bloquear_sabado: yup.boolean().required('Seleção obrigatória'),
		cancelar_cobranca: yup.boolean().required('Seleção obrigatória'),
		limitar_numero_reservas_por_unidade: yup.boolean().required('Seleção obrigatória'),
		quantidade_maxima_de_reservas: yup.mixed().when('limitar_numero_reservas_por_unidade', {
			is: true,
			then: yup
				.number()
				.typeError('O valor precisa ser um número positivo')
				.positive('O valor precisa ser um número positivo')
				.required('É obrigatório preencher um valor'),
			otherwise: yup.mixed().nullable(),
		}),
		tipo_periodo_maximo_reservas: yup.mixed().when('limitar_numero_reservas_por_unidade', {
			is: true,
			then: yup.string().typeError('Selecione um período').required('Selecione um período'),
			otherwise: yup.mixed().nullable(),
		}),
		maxima_quantidade_unidades_por_periodo: yup
			.number()
			.typeError('Precisa ser um número')
			.integer('Precisa ser um número inteiro')
			.moreThan(0, 'Não deve ser menor que zero')
			.lessThan(9999, 'Não deve ultrapassar valor máximo')
			.required('Campo obrigatório'),
	});

	const resultado = React.useMemo(() => {
		return [
			{
				id_condominio: idCondominio,

				write_only_anexo_pdf: ambiente?.anexo_pdf,

				// Tab: Configuração de Horário
				taxa_por_uso: ambiente?.taxa_por_uso || false,
				conta_receita: contaReceitaInicial || null,
				reserva_inadimplente: ambiente?.reserva_inadimplente === false ? false : true,
				nome: ambiente?.nome || '',
				grupos: grupos.filter((grupo) => (ambiente?.grupos || []).includes(grupo.id)),
				capacidade_pessoas: ambiente?.capacidade_pessoas || null,

				// Tab: Configuração de Horário (dias/periodos)
				dias: ambiente?.dias || [
					{
						dia: 'DOM',
						fracao: 'MP',
						habilitado: false,
						hora_1: null,
						hora_2: null,
						hora_3: null,
						hora_4: null,
						valor: 0,
					},
					{
						dia: 'SEG',
						fracao: 'MP',
						habilitado: false,
						hora_1: null,
						hora_2: null,
						hora_3: null,
						hora_4: null,
						valor: 0,
					},
					{
						dia: 'TER',
						fracao: 'MP',
						habilitado: false,
						hora_1: null,
						hora_2: null,
						hora_3: null,
						hora_4: null,
						valor: 0,
					},
					{
						dia: 'QUA',
						fracao: 'MP',
						habilitado: false,
						hora_1: null,
						hora_2: null,
						hora_3: null,
						hora_4: null,
						valor: 0,
					},
					{
						dia: 'QUI',
						fracao: 'MP',
						habilitado: false,
						hora_1: null,
						hora_2: null,
						hora_3: null,
						hora_4: null,
						valor: 0,
					},
					{
						dia: 'SEX',
						fracao: 'MP',
						habilitado: false,
						hora_1: null,
						hora_2: null,
						hora_3: null,
						hora_4: null,
						valor: 0,
					},
					{
						dia: 'SAB',
						fracao: 'MP',
						habilitado: false,
						hora_1: null,
						hora_2: null,
						hora_3: null,
						hora_4: null,
						valor: 0,
					},
				],

				// Tab: Configuração de Reserva
				reserva_horas_antes: ambiente?.reserva_horas_antes || null,
				reserva_dias_antes: ambiente?.reserva_dias_antes || null,
				cancelamento_horas_antes: ambiente?.cancelamento_horas_antes || null,
				bloquear_domingo: ambiente?.bloquear_domingo || false,
				bloquear_sabado: ambiente?.bloquear_sabado || false,
				cancelar_cobranca: ambiente?.cancelar_cobranca ?? true,
				limitar_numero_reservas_por_unidade: ambiente?.limitar_numero_reservas_por_unidade || false,
				quantidade_maxima_de_reservas: ambiente?.quantidade_maxima_de_reservas || null,
				tipo_periodo_maximo_reservas: ambiente?.tipo_periodo_maximo_reservas || null,
				responsaveis:
					ambiente?.responsaveis_pessoas && ambiente?.responsaveis_usuarios_ids
						? [ambiente?.responsaveis_pessoas, ambiente?.responsaveis_usuarios_ids]
						: [],

				// Tab: Regulamentos
				descricao_json: ambiente?.descricao_json ? JSON.stringify(ambiente.descricao_json) : null,
				regulamento_json: ambiente?.regulamento_json ? JSON.stringify(ambiente.regulamento_json) : null,
				maxima_quantidade_unidades_por_periodo: ambiente?.maxima_quantidade_unidades_por_periodo || 1,
			},
			grupos,
		];
	});
	const [defaultValues] = resultado;

	const methods = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues,
	});

	const onFormSubmit = (data) => {
		setSaving(true);
		let request;
		const responsaveisOrdened = data.responsaveis.reduce(
			(accumulator, { tipo, id }) => ({
				...accumulator,
				[tipo]: [...new Set([...(accumulator[tipo] || []), id])],
			}),
			{
				usuarios: [],
				pessoas: [],
			}
		);

		data['regulamento_json'] = sortRichTextJson(data['regulamento_json']);
		data['descricao_json'] = sortRichTextJson(data['descricao_json']);
		data['conta_receita'] = data['conta_receita']?.id || null;

		if (ambiente?.id) {
			request = dp.update('ambiente_reserva', {
				id: ambiente?.id,
				data: {
					...data,
					grupos: data.grupos.map((grupo) => grupo.id),
					responsaveis_pessoas: responsaveisOrdened.pessoas,
					responsaveis_usuarios_ids: responsaveisOrdened.usuarios,
				},
			});
		} else {
			request = dp.create('ambiente_reserva', {
				data: {
					...data,
					grupos: data.grupos.map((grupo) => grupo.id),
					responsaveis_pessoas: responsaveisOrdened.pessoas,
					responsaveis_usuarios_ids: responsaveisOrdened.usuarios,
				},
			});
		}

		request
			.then(({ data }) => {
				notify(`Ambiente de reserva ${ambiente?.id ? 'editado' : 'criado'} com sucesso`);
				callbackActions && callbackActions(data);
				handleClose();
			})
			.catch((err) => {
				const errors = Object.entries(err.response.data);
				errors.map((error) => {
					methods.setError(error[0], { message: error[1] });
					return true;
				});
				notify(errors[0][1][0], 'error');
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const handleSubmit = () => methods.handleSubmit(onFormSubmit)();
	const handleClose = () => setModalValue((v) => ({ ...v, open: false }));

	return (
		<CustomDialogBodySizeEditable
			title={ambiente?.id ? 'Editar Ambiente de Reserva' : 'Cadastrar Ambiente de Reserva'}
			form={{
				component: (
					<AmbienteReservaForm
						grupos={grupos}
						methods={methods}
						ambiente={ambiente}
						conta_receita={contaReceitaInicial}
					/>
				),
			}}
			customActions={
				<>
					<ButtonCancel disabled={saving} onClick={handleClose} />
					<BotaoSalvar disabled={saving} onClick={handleSubmit} />
				</>
			}
		/>
	);
};

export default AmbienteReservaModal;
