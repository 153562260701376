import React from 'react';
import ConfiguracaoRelatorio from './configuracaoDosRelatorios';

import ConfiguracoesDeMultaJurosCorrecaoMonetaria from './ConfiguracoesDeMultaJurosCorrecaoMonetaria';

import ConfiguracaoDeImpostos from './ConfiguracaoDosImpostos/index';

export const configuracoesDisponiveis = [
	{ id: 'config_relatorio', name: 'Configuração de Cabeçalho e Rodapé para Relatórios' },
	{ id: 'correcao_monetaria', name: 'Configuração de Multa, Juros e Correção Monetária' },
	{ id: 'config_impostos', name: 'Configuração de Impostos' },
];

const mapComponentConfiguracoes = {
	config_relatorio: <ConfiguracaoRelatorio />,
	correcao_monetaria: <ConfiguracoesDeMultaJurosCorrecaoMonetaria />,
	config_impostos: <ConfiguracaoDeImpostos />,
};

export const getConfiguracoesComponent = (key) => mapComponentConfiguracoes[key] || <></>;
