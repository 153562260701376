import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import { TooltipVoltarIconButton } from '../../../common/buttons/BotaoVoltar';
import { TooltipIconButtonClose } from '../../../common/buttons/ButtonClose';
import ImportCondominiosContext from './ImportCondominiosContext';

const ResultadosImportCondominiosModal = () => {
	const { resultadoImport, errosNoArquivo, setModalStep } = useContext(ImportCondominiosContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const handleVoltar = () => {
		setModalStep('1');
	};
	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			display='flex'
			justifyContent='center'
			title='Resultados da Importação'
			form={{
				component: (
					<Box display='flex'>
						{!!resultadoImport.length && (
							<Box flex={1} mr='1em'>
								<Typography variant='h6'>Itens cadastrados:</Typography>
								<br />
								{resultadoImport.map((texto) => (
									<Typography variant='subtitle1'>{texto}</Typography>
								))}
							</Box>
						)}
						{!!errosNoArquivo?.length && (
							<Box flex={1} mr='1em'>
								<Typography variant='h6'>Erros no arquivo</Typography>
								<br />
								{errosNoArquivo.map(({ linha, msg }) => (
									<Typography
										variant='subtitle1'
										style={{
											color: syndikosRed.main,
										}}
									>
										{linha}: {msg}
									</Typography>
								))}
							</Box>
						)}
					</Box>
				),
			}}
			customActions={
				<>
					<TooltipVoltarIconButton onClick={handleVoltar} />
					<TooltipIconButtonClose onClick={handleClose} />
				</>
			}
		/>
	);
};

export default ResultadosImportCondominiosModal;
