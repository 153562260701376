import React from 'react';

import TextField from '@material-ui/core/TextField';

function InputDescricaoGaragem({ value, setValue, ...props }) {
	function handleChangeInput(e) {
		setValue(e.target.value);
	}
	return (
		<TextField
			value={value}
			onChange={handleChangeInput}
			label='Digite uma descrição para a vaga:'
			size='small'
			margin='normal'
			variant='outlined'
			fullWidth
			type='number'
			name='descricao-garagem'
			id='descricao-garagem-id'
			multiline
			minRows={4}
			{...props}
		/>
	);
}

export default InputDescricaoGaragem;
