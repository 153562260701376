import React, { useContext } from 'react';

import { useDataProvider } from 'react-admin';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ButtonRemove } from 'components/common/buttons/ButtonRemove';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { ArrecadacoesContext } from '../../../../../ArrecadacoesContext';

import { useDesprovisionarReferenciaPadrao, useModalConfirmaDesprovisionamento } from './hooks';

export const ButtonDesprovisionar = () => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const dP = useDataProvider();
	const { id_identificacao_arrecadacao, referencia, contaBDestinoId, tem_recebimento_pago } =
		useContext(ArrecadacoesContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const desprovisionar = useDesprovisionarReferenciaPadrao();
	const ModalConfirmaDesprovisionamento = useModalConfirmaDesprovisionamento();
	const [loading, setLoading] = React.useState(false);

	return (
		<ButtonRemove
			disabled={tem_recebimento_pago || !contaBDestinoId || loading}
			onClick={() => {
				setLoading(true);
				dP.getList('contas_receber', {
					pagination: { perPage: 10000, page: 1 },
					sort: { field: 'nome_unidade', order: 'ASC' },
					filter: {
						condominio: condominioSelecionado?.id,
						referencia: referencia,
						identificacao: id_identificacao_arrecadacao,
						possui_nosso_numero: true,
					},
				})
					.then((res) => {
						if (res?.data) {
							res.data = res.data.filter((v) => v.id !== 0);
							res.data?.length
								? setModalValue((v) => ({
										...v,
										open: true,
										dialogBody: (
											<ModalConfirmaDesprovisionamento
												recebimentosNossoNumero={res.data}
												setLoading={setLoading}
											/>
										),
								  }))
								: desprovisionar({
										callbackSuccess: () => setLoading(false),
										callbackFail: () => setLoading(false),
								  });
						} else {
							setLoading(false);
						}
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						setLoading(false);
					});
			}}
		>
			{tem_recebimento_pago ? 'Uma das contas desta referência já foi paga' : 'Desprovisionar'}
		</ButtonRemove>
	);
};
