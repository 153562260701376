import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { ContaAReceberActionsContext } from './ContaAReceberActionsContext';
import { SyndikosMuiPickersUtilsProvider } from 'components/common/InputDate';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, parseISO, isValid } from 'date-fns';

const ModalAlterarVencimento = ({ data_vencimento }) => {
	const { trocarDataVencimento, loading } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};
	const dataHoje = new Date().setHours(0, 0, 0, 0);
	const [novaDataVencimento, setNovaDataVencimento] = useState(parseISO(data_vencimento));
	const [dataInvalida, setDataInvalida] = useState(false);
	const [dataAnteriorHoje, setDataAnteriorHoje] = useState(false);

	const handleDateChange = (data) => {
		const dataValida = isValid(data);
		const dataAnteriorHoje = dataValida && data < dataHoje;

		setDataInvalida(!dataValida);
		setDataAnteriorHoje(dataAnteriorHoje);

		if (dataValida) {
			setNovaDataVencimento(data);
		}
	};

	const DataVencimentoInput = () => (
		<SyndikosMuiPickersUtilsProvider>
			<KeyboardDatePicker
				label='Nova data de vencimento'
				format='dd/MM/yyyy'
				mask='__/__/____'
				margin='dense'
				variant='inline'
				inputVariant='outlined'
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				minDate={dataHoje}
				value={novaDataVencimento}
				onChange={handleDateChange}
				fullWidth
				disableToolbar
				error={dataInvalida || dataAnteriorHoje}
				invalidDateMessage='Data inválida'
				helperText={
					dataInvalida
						? 'Data inválida.'
						: dataAnteriorHoje
						? 'Data inválida: não pode ser anterior à data de hoje.'
						: ''
				}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);

	const formatDate = (dateString) => {
		if (!dateString) return '';
		const date = parseISO(dateString);
		if (isNaN(date)) return '';
		return format(date, 'dd/MM/yyyy');
	};

	return (
		<Box>
			<CustomDialogBodySizeEditable
				{...(loading
					? {
							form: {
								component: (
									<Box display='flex' justifyContent='center'>
										<CircularProgress />
									</Box>
								),
							},
							customActions: <></>,
					  }
					: {
							closeInSubmit: false,
							customActions: (
								<>
									<ButtonCancel onClick={closesModal} />
									<ButtonConfirm
										size='small'
										onClick={() => {
											if (!dataInvalida && !dataAnteriorHoje) {
												trocarDataVencimento(format(novaDataVencimento, 'yyyy-MM-dd')).finally(
													() => {
														closesModal();
													}
												);
											}
										}}
										variant='text'
										disabled={dataInvalida || dataAnteriorHoje}
									>
										Salvar
									</ButtonConfirm>
								</>
							),
							text: (
								<Box display='flex' flexWrap='wrap' className='box-content' alignItems='center'>
									<span>
										<strong>Data de vencimento atual: </strong>
										{formatDate(data_vencimento)}
									</span>
									<Box mt={1} width={'17.9em'}>
										<DataVencimentoInput />
									</Box>
								</Box>
							),
							title: 'Alterar data de vencimento',
					  })}
			/>
		</Box>
	);
};

const BotaoAlterarVencimento = ({ record }) => {
	const { tem_arrecadacao_provisionada } = useContext(ArrecadacoesContext);
	const { loading } = useContext(ContaAReceberActionsContext);
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	const handleClick = () => {
		setMinWidthModel('21em');
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalAlterarVencimento data_vencimento={record.data_vencimento} />,
		}));
	};

	return (
		<TooltipIconButton
			disabled={!tem_arrecadacao_provisionada || loading || record?.provisionado}
			size='small'
			title='Alterar Vencimento'
			onClick={handleClick}
		>
			<DateRangeIcon />
		</TooltipIconButton>
	);
};

export default BotaoAlterarVencimento;
