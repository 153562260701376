import React, { useContext, useEffect } from 'react';

import { parse } from 'date-fns';

import { Box, TextField } from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { SimplesSyndikosSelect } from '../../common/SyndikosSelect';
import { SyndikosMuiPickersUtilsProvider } from '../../common/InputDate';
import { LancamentoContaPagarContext } from './LancamentoContaPagarContext';
import InputFile from '../../common/InputFile';
const DataEmissaoField = () => {
	const { dataEmissao, setDataEmissao, erros, setErros, record } = useContext(LancamentoContaPagarContext);
	const validate = () => {
		if (!dataEmissao) {
			setErros((e) => ({
				...e,
				emissao: 'Forneça a data de emissão',
			}));
		} else if (parse(dataEmissao, 'dd/MM/yyyy', new Date()).toString() === 'Invalid Date') {
			setErros((e) => ({
				...e,
				emissao: 'Forneça uma data válida para a emissão',
			}));
		} else if (erros?.emissao) {
			setErros((e) => {
				const { emissao, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [dataEmissao]);

	return (
		<SyndikosMuiPickersUtilsProvider>
			<KeyboardDatePicker
				autoOk={true}
				format='dd/MM/yyyy'
				label='Data de Emissão'
				mask='__/__/____'
				size='small'
				margin='dense'
				variant='outlined'
				inputVariant='outlined'
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				onChange={(_, newValue) => setDataEmissao(newValue)}
				value={dataEmissao}
				inputValue={dataEmissao}
				initialFocusedDate={dataEmissao}
				required={true}
				error={!!erros?.emissao}
				helperText={erros?.emissao}
				fullWidth
				disabled={record?.retencao_imposto}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};

const NotaFiscalField = () => {
	const { nf, setNf, record } = useContext(LancamentoContaPagarContext);
	return (
		<TextField
			label='Nota Fiscal'
			size='small'
			margin='dense'
			value={nf}
			onChange={(e) => setNf((e.target.value || '').replace(/[^A-Za-z0-9]/, '').slice(0, 16))}
			error={false}
			helperText={undefined}
			InputLabelProps={{
				shrink: true,
			}}
			fullWidth
			disabled={record?.retencao_imposto}
		/>
	);
};

const ContaBancariaSelect = () => {
	const {
		contasBancariasData,
		selectedContaBancaria,
		setSelectedContaBancaria,
		erros,
		setErros,
		selectedCondominio,
	} = useContext(LancamentoContaPagarContext);
	const validate = () => {
		if (!selectedContaBancaria) {
			setErros((e) => ({
				...e,
				conta_bancaria: 'Selecione uma conta bancária',
			}));
		} else if (selectedCondominio && selectedContaBancaria?.id_condominio !== selectedCondominio.id) {
			setErros((e) => ({
				...e,
				conta_bancaria: 'Selecione uma conta bancária que pertença ao condomínio selecionado',
			}));
		} else if (erros?.conta_bancaria) {
			setErros((e) => {
				const { conta_bancaria, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [selectedContaBancaria]);

	return (
		<SimplesSyndikosSelect
			variant='outlined'
			size='small'
			optionText='nome_conta'
			label='Conta Bancária'
			isRequired={true}
			value={selectedContaBancaria}
			choices={contasBancariasData}
			getOptionSelected={(option, newValue) => option.id === newValue?.id}
			onChange={(newValueID) => {
				setSelectedContaBancaria((contasBancariasData || []).find((cb) => cb.id === newValueID) || null);
			}}
			style={{ marginTop: '0px' }}
			touched={true}
			error={erros?.conta_bancaria}
			disableClearable
			fullWidth
		/>
	);
};

const BoxInformacoesAdicionaisFields = () => {
	const { setAnexoNF, anexoNF, record } = useContext(LancamentoContaPagarContext);
	const { setAnexoOrdemCompraServico, anexoOrdemCompraServico } = useContext(LancamentoContaPagarContext);

	return (
		<Box>
			<Box display='flex' style={{ marginTop: '0px' }}>
				<Box flex={1} mr='1em'>
					<DataEmissaoField />
				</Box>
				<Box flex={1} mr='1em'>
					<NotaFiscalField />
				</Box>
				<Box flex={1} mr='1em'>
					<ContaBancariaSelect />
				</Box>
			</Box>
			<Box display='flex' mt='10px'>
				<Box flex={1} mr='1em'>
					<InputFile
						label='Ordem de Compra/Serviço'
						onCallBack={(e, dataUrl) => setAnexoOrdemCompraServico(dataUrl)}
						fileDataUrl={anexoOrdemCompraServico}
						disabled={record?.retencao_imposto}
					/>
				</Box>
			</Box>
			<Box display='flex' mt='10px'>
				<Box flex={1} mr='1em'>
					<InputFile
						label='Nota Fiscal'
						onCallBack={(e, dataUrl) => setAnexoNF(dataUrl)}
						fileDataUrl={anexoNF}
						disabled={record?.retencao_imposto}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default BoxInformacoesAdicionaisFields;
