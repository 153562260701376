import React, { useContext } from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';

import EditaConfiguracaoLeituraContext from './EditaConfiguracaoLeituraContext';

const DialogTitleEditaConfiguracaoLeitura = () => {
	const { dadosConfig } = useContext(EditaConfiguracaoLeituraContext);
	return <DialogTitle>Configurações da Leitura {dadosConfig.nome_leitura}</DialogTitle>;
};

export default DialogTitleEditaConfiguracaoLeitura;
