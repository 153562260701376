import React from 'react';
import { Box } from '@material-ui/core';
import { Announcement, AccountCircle, AttachFile } from '@material-ui/icons';
import { OccurrenceActions } from '../types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

export function OccurrenceChat({
	actionsOccurrence,
	scrollRef,
	classes,
	loading,
}: {
	actionsOccurrence: OccurrenceActions[];
	scrollRef: React.RefObject<HTMLDivElement>;
	classes: ClassNameMap<string>;
	loading: boolean;
}) {
	return (
		<Box display={'flex'} alignItems={'flex-start'} pb={'2vh'} maxWidth={'100%'}>
			<Box width={'100%'}>
				<div className={classes.BoxChat}>
					{loading ? (
						<Box
							display={'flex'}
							flexDirection={'column'}
							justifyContent={'center'}
							alignItems={'center'}
							height={'30vh'}
						>
							<CircularProgress size={72.5} />
						</Box>
					) : (
						<>
							<Box mt={2} mb={2} style={{ display: 'flex', justifyContent: 'center' }}>
								<Announcement className={classes.AnnouncementIcon} />
								<strong style={{ marginLeft: 7, marginTop: 3 }}>Ocorrência criada</strong>
							</Box>
							<Box style={{ display: 'flex', justifyContent: 'center', marginTop: -10, marginBottom: 5 }}>
								<Box className={classes.Line} />
							</Box>
							{Array.isArray(actionsOccurrence) &&
								actionsOccurrence.map((actionOccurrence, index) => {
									const color =
										actionOccurrence.tipo_acesso !== 'Administrador' ? '#FFF0EB' : '#EBF5FC';
									const alignRight = actionOccurrence.tipo_acesso === 'Administrador';
									const isFirstMessageOfUser =
										index === 1 ||
										index === 0 ||
										actionsOccurrence[index - 1].nome_usuario !== actionOccurrence.nome_usuario;
									const showDate =
										index === 0 || actionsOccurrence[index - 1].data !== actionOccurrence.data;
									return (
										<>
											{showDate && (
												<Box
													mt={1}
													mb={2}
													style={{
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													<strong>{actionOccurrence.data}</strong>
												</Box>
											)}
											{(actionOccurrence.tipo_acao === 'C' ||
												actionOccurrence.tipo_acao === 'F') && (
												<Box
													ml={alignRight ? 'auto' : '0'}
													mr={alignRight ? '2' : 'auto'}
													mt={isFirstMessageOfUser ? 2 : 0}
													mb={2}
												>
													<Box flexGrow={1} ml={2} mr={2}>
														{isFirstMessageOfUser && (
															<Box
																display={'flex'}
																justifyContent={'flex-end'}
																flexDirection={alignRight ? 'row' : 'row-reverse'}
															>
																<div>
																	<p
																		style={{
																			fontWeight: '600',
																			fontSize: 14,
																			marginRight: 8,
																			marginLeft: 8,
																		}}
																	>
																		{actionOccurrence.nome_usuario} {' - '}
																		{actionOccurrence.tipo_acesso}(a)
																	</p>
																	<p
																		style={{
																			fontWeight: '500',
																			fontSize: 12,
																			marginRight: 8,
																			marginLeft: 8,
																		}}
																	>
																		{actionOccurrence.relacao_secundario}
																	</p>
																</div>
																<AccountCircle
																	style={{ fontSize: 22, marginTop: 10 }}
																/>
															</Box>
														)}
														<div
															ref={scrollRef}
															style={{
																backgroundColor: color,
																borderRadius: 5,
																display: 'flex',
																flexDirection: 'column',
																marginTop: 1,
															}}
														>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'flex-start',
																}}
															>
																{actionOccurrence.tipo_acao === 'F' ? (
																	<p
																		style={{
																			marginLeft: 10,
																			marginTop: 10,
																			maxWidth: '35vw',
																			fontSize: 14,
																			fontWeight: 700,
																		}}
																	>
																		{actionOccurrence.nome_usuario} finalizou a
																		ocorrência
																	</p>
																) : (
																	<p
																		style={{
																			marginLeft: 10,
																			marginTop: 10,
																			maxWidth: '35vw',
																			fontSize: 14,
																		}}
																	>
																		{actionOccurrence.mensagem}
																	</p>
																)}
																<p
																	style={{
																		fontSize: 12,
																		marginLeft: 10,
																		marginRight: 5,
																		alignSelf: 'flex-end',
																		marginTop: 25,
																	}}
																>
																	{actionOccurrence.hora}
																</p>
															</div>
														</div>
													</Box>
												</Box>
											)}
											{actionOccurrence.write_only_anexo && (
												<Box
													ml={alignRight ? 'auto' : 2}
													mr={alignRight ? 2 : 'auto'}
													mb={2}
													display='flex'
													alignItems='flex-start'
												>
													<Box
														style={{
															backgroundColor: color,
															borderRadius: 5,
															display: 'flex',
														}}
														mt={1}
													>
														<AttachFile className={classes.AttachFileIcon} />
														<a
															className={classes.LinkAttachment}
															href={actionOccurrence.write_only_anexo}
															onClick={(e) => {
																e.preventDefault();
																return window.open(
																	actionOccurrence.write_only_anexo,
																	'_blank'
																);
															}}
														>
															Ver anexo
														</a>
														<p
															style={{
																fontSize: 12,
																marginLeft: 10,
																marginRight: 5,
																marginTop: 25,
															}}
														>
															{actionOccurrence.hora}
														</p>
													</Box>
												</Box>
											)}
										</>
									);
								})}
						</>
					)}
				</div>
			</Box>
		</Box>
	);
}
