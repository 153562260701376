import React from 'react';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ITipoRelatorio } from './types';
import MultiSelect from 'components/common/Selects';
import { BoxDivisor } from '../../../common/Formulario';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';
import RelatorioToolbar from '../RelatorioToolbar';

const OpcoesImpressaoGaragens = ({
	imprimirAssinatura,
	imprimirUnidadesSemGaragens,
	imprimirVeiculos,
	handleChange,
}: {
	imprimirAssinatura: boolean;
	imprimirUnidadesSemGaragens: boolean;
	imprimirVeiculos: boolean;
	handleChange: any;
}) => {
	return (
		<>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirAssinatura}
						onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
						name='imprimir_assinatura'
					/>
				}
				label='Imprimir assinaturas'
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirUnidadesSemGaragens}
						onChange={(e, checked) => handleChange(checked, 'imprimir_unidades_sem_garagens')}
						name='imprimir_unidades_sem_garagens'
					/>
				}
				label='Imprimir Unidades sem Garagens'
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={imprimirVeiculos}
						onChange={(e, checked) => handleChange(checked, 'imprimir_veiculos')}
						name='imprimir_veiculos'
					/>
				}
				label='Imprimir veículos'
			/>
		</>
	);
};

const GaragensDivisor = ({ tipoRelatorio, idCondominio }: { tipoRelatorio: ITipoRelatorio; idCondominio: number }) => {
	const [unidades, setUnidades] = React.useState([]);
	const {
		opcoesImpressao: {
			imprimir_assinatura,
			imprimir_unidades_sem_garagens,
			imprimir_veiculos,
			lista_ids_unidades_por_grupo,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	const requestData = {
		id_condominio: idCondominio,
		unidades_selecionadas: unidades.map((v: { id: number }) => v.id),
		imprimir_assinatura: imprimir_assinatura,
		imprimir_unidades_sem_garagens: imprimir_unidades_sem_garagens,
		imprimir_veiculos: imprimir_veiculos,
		lista_ids_unidades_por_grupo: lista_ids_unidades_por_grupo,
	};

	const { options: optionsUnidades } = useRequestOptionsUnidades();

	React.useEffect(() => {
		setUnidades([]);
	}, [idCondominio]);

	return (
		<BoxDivisor titulo={'Detalhes das Garagens'}>
			<Box display='flex' mb='1em'>
				<Box width='50%' mr='1em'>
					<MultiSelect
						variant='formatsUnidades'
						options={optionsUnidades}
						value={unidades}
						setValue={setUnidades}
					/>
				</Box>
				<Box>
					<OpcoesImpressaoGaragens
						handleChange={handleChangeOpcoesImpressao}
						imprimirAssinatura={imprimir_assinatura}
						imprimirUnidadesSemGaragens={imprimir_unidades_sem_garagens}
						imprimirVeiculos={imprimir_veiculos}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar tipoRelatorio={tipoRelatorio} data={requestData} valid={true} />
			</Box>
		</BoxDivisor>
	);
};

export default GaragensDivisor;
