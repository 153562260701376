import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const SelectCondominioReserva = () => {
	const {
		consulta: { condominios, condominioSelecionado },
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);

	const dp = useDataProvider();
	const notify = useNotify();

	const handleChangeCondominio = React.useCallback(
		(e, value) => {
			setConsulta((_C) => ({
				..._C,
				condominioSelecionado: value,
				ambientes: [],
				ambienteSelecionado: null,
				unidades: [],
				unidadesSelecionadas: [],
				reservasNoMes: {},
			}));
		},
		[setConsulta]
	);

	const updateCondominios = () => {
		dp.getSimple('condominios', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: { situacao: 'A' },
		})
			.then(({ data: { results = [] } = {} }) =>
				results?.length ? results : Promise.reject('Sem condominios cadastrados.')
			)
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				notify('Sem condominios cadastrados.', 'warning');
				return [];
			})
			.then((condominiosColetados) => {
				setConsulta((_C) => ({
					..._C,
					condominios: condominiosColetados,
					condominioSelecionado: condominiosColetados[0] ?? null,
					ambientes: [],
					ambienteSelecionado: null,
					unidades: [],
					unidadesSelecionadas: [],
				}));
			});
	};

	React.useEffect(updateCondominios, []);

	return (
		<Autocomplete
			id='select-condominio-reserva'
			itemID='select-condominio-reserva-item'
			value={condominioSelecionado}
			options={condominios}
			onChange={handleChangeCondominio}
			loading={!condominios.length}
			disabled={!condominios.length}
			title={'Selecionar Condomínio'}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Condomínio' />}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option.nome}
			noOptionsText='Nenhum resultado encontrado'
			clearText='Limpar'
			fullWidth
			size='small'
		/>
	);
};

export default SelectCondominioReserva;
