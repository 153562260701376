import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaNaoCorrenteContext from '../contexts/EditaContaNaoCorrenteContext';
import { ContaNaoCorrentePropsByInstituicao } from '../bancos';
import { getFunctionsFormatarProps, formatarInstituicoesOptions } from '../utils/functions';
import memoize from '../../../../common/memoize';

const { getInit, getReducer } = getFunctionsFormatarProps(ContaNaoCorrentePropsByInstituicao);

const getinitial = memoize(({ instituicaoCodigo }) => ({
	instituicaoCodigo,
	optionsPorInstituicao: {},
}));

const EditaContaNaoCorrenteContextProvider = ({ children }) => {
	const { initialDados } = useContext(EditaContaBancariaContext);
	const [instituicoesOptions] = useState(
		formatarInstituicoesOptions(initialDados.opcoesDadosBancarios.instituicao_financeira)
	);

	const reducer = useCallback(
		getReducer({
			...initialDados,
			optionsDefault: {
				id_inst_financeira: instituicoesOptions,
			},
		}),
		[initialDados, instituicoesOptions]
	);
	const init = useCallback(
		getInit({
			...initialDados,
			optionsDefault: {
				id_inst_financeira: instituicoesOptions,
			},
		}),
		[initialDados, instituicoesOptions]
	);

	const [
		{ instituicaoCodigo, getDisabledTabs, tabContents, formatarValues, validate, initialValues, options, props },
		atualizarDadosInstituicao,
	] = useReducer(
		reducer,
		getinitial({
			instituicaoCodigo: instituicoesOptions.objects[initialDados.conta_condominio.id_inst_financeira]?.codigo,
		}),
		init
	);

	const value = useMemo(
		() => ({
			getDisabledTabs,
			tabContents,
			formatarValues,
			validate,
			initialValues,
			options,
			props,
			atualizarDadosInstituicao,
			instituicaoCodigo,
		}),
		[
			getDisabledTabs,
			tabContents,
			formatarValues,
			validate,
			initialValues,
			options,
			props,
			atualizarDadosInstituicao,
			instituicaoCodigo,
		]
	);

	return <EditaContaNaoCorrenteContext.Provider value={value}>{children}</EditaContaNaoCorrenteContext.Provider>;
};

export default EditaContaNaoCorrenteContextProvider;
