import React from 'react';

import { Box, TextField } from '@material-ui/core';

import MUIRichTextEditor from 'mui-rte';

import InfoIcon from '@material-ui/icons/Info';

import { ShowTags } from '../ShowTags/ShowTags';
import { tagsEditorText } from '../../mentions/mentions';
import TextInputMention from '../TextInputMention/TextInputMention';

export const CreateEmailTemplateBox = ({ handleSave = () => {} }) => {
	// Variaveis
	const textRef = React.useRef();
	const [anchorEditor, setAnchorEditor] = React.useState(null);
	const [assuntoEmail, setAssuntoEmail] = React.useState('');
	const [newTemplate, setNewTemplate] = React.useState('');

	// Funções Handle
	const handleSaveAssunto = (data) => {
		const { blocks } = JSON.parse(data);
		const assuntoText = blocks[0]?.text;
		setAssuntoEmail(assuntoText);
	};

	return (
		<Box>
			<TextField
				value={newTemplate}
				size='small'
				onChange={(event) => {
					setNewTemplate(event.target.value);
					textRef.current.save();
				}}
				onBlur={() => textRef.current.save()}
				label='Nome para o novo modelo:'
				variant='outlined'
				fullWidth
			/>
			<TextInputMention handleSaveAssunto={handleSaveAssunto} />
			<Box
				style={{
					backgroundColor: 'white',
					color: 'black',
				}}
				mb={3}
				border={1}
				borderRadius='borderRadius'
			>
				{anchorEditor && (
					<ShowTags open={Boolean(anchorEditor)} anchor={anchorEditor} setAnchorEditor={setAnchorEditor} />
				)}
				<MUIRichTextEditor
					label="Digite '@' para utilizar as tags..."
					inlineToolbar
					toolbarButtonSize='small'
					ref={textRef}
					onSave={(data) => handleSave(data, assuntoEmail, newTemplate)}
					onChange={() => textRef.current.save()}
					onBlur={() => textRef.current.save()}
					maxLength={1600}
					controls={[
						'title',
						'bold',
						'italic',
						'underline',
						'strikethrough',
						'bulletList',
						'numberList',
						'undo',
						'redo',
						'quote',
						'code',
						'clear',
						'tags',
					]}
					customControls={[
						{
							name: 'tags',
							icon: <InfoIcon />,
							type: 'callback',
							onClick: (editorState, name, anchor) => setAnchorEditor(anchor),
						},
					]}
					autocomplete={{
						strategies: [
							{
								items: tagsEditorText,
								triggerChar: '@',
							},
						],
					}}
				/>
			</Box>
		</Box>
	);
};
