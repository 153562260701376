import React, { useContext } from 'react';

import AvTimerIcon from '@material-ui/icons/AvTimer';

import ModalLeiturasAplicativo from '../../modais/ModalLeiturasAplicativo';
import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { TooltipIconButton } from '../../../../common/TooltipIconButton';

const AbrirLeiturasAplicativoButton = () => {
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);

	return (
		<TooltipIconButton
			color='white'
			onClick={() => {
				setMinWidthModel('50vw');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalLeiturasAplicativo />,
				}));
			}}
			size='small'
			title='Leituras do Aplicativo'
		>
			<AvTimerIcon />
		</TooltipIconButton>
	);
};

export default AbrirLeiturasAplicativoButton;
