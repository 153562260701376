import React, { useEffect, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

// eslint-disable-next-line
// @ts-ignore
import SortableTree from 'react-sortable-tree';

import { Box, Typography } from '@material-ui/core';

import { SaveButton } from 'components/common/commonComponentsTSX';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';

import Bloco from './types';

interface ReordenarBlocosProps {
	setReordering: (reordering: boolean) => void;
	data: Bloco[];
	setData: (data: Bloco[]) => void;
}

type TreeBloco = Bloco & {
	title: string;
};

const ReordenarBlocos: React.FC<ReordenarBlocosProps> = ({ setReordering, data, setData }) => {
	const [blocos, setBlocos] = useState<TreeBloco[]>(data.map((bloco) => ({ title: bloco.nome, ...bloco })));
	const [changed, setChanged] = useState(false);
	const [saving, setSaving] = useState(false);

	const dataProvider = useDataProvider();

	const notify = useNotify();

	useEffect(() => {
		if (data.length > blocos.length) {
			const ultimoBloco = { title: data[data.length - 1].nome, ...data[data.length - 1] };
			setBlocos((prevBlocos) => [...prevBlocos, ultimoBloco]);
		}
	}, [data]);

	const wasChangeMade = (blocos: TreeBloco[]) => {
		for (let i = 0; i < blocos.length; i++) {
			if (blocos[i].posicao_reordem !== i + 1) {
				return true;
			}
		}
		return false;
	};

	const saveOrder = () => {
		setSaving(true);

		const payload: any = [];

		for (let i = 0; i < blocos.length; i++) {
			if (blocos[i].posicao_reordem !== i + 1) {
				payload.push({ id: blocos[i].id, data: { posicao_reordem: i + 1 } });
				blocos[i].posicao_reordem = i + 1;
			}
		}

		dataProvider
			.updateMany('grupo_unidade_condominio', payload)
			.then((response) => {
				if ((response.data || []).find((r: any) => r?.status === 'rejected')) {
					return Promise.reject();
				}
				notify('Ordenação alterada com sucesso');
				setData(blocos);
				setChanged(false);
				setSaving(false);
				setReordering(false);
			})
			.catch(() => {
				notify('Ocorreu um erro ao alterar a ordenação');
				setSaving(false);
				setReordering(false);
			});
	};

	return (
		<Box height={blocos.length > 10 ? '70vh' : `${blocos.length * 7}vh`}>
			<SortableTree
				rowHeight={48}
				maxDepth={1}
				treeData={blocos}
				onChange={(blocos: TreeBloco[]) => {
					wasChangeMade(blocos) ? setChanged(true) : setChanged(false);
					setBlocos(blocos);
				}}
				generateNodeProps={() => ({
					style: {
						fontFamily: 'Roboto, sans-serif',
						fontWeight: 'bold',
					},
				})}
			/>
			<hr
				style={{
					marginRight: '1em',
					borderTop: '#d3d3d3',
				}}
			></hr>
			<Typography component='span' style={{ marginRight: 10 }}>
				Modo Ordenação
			</Typography>
			<SaveButton onClick={saveOrder} size='small' disabled={saving || !changed}>
				Salvar
			</SaveButton>
			<BotaoVoltar
				onClick={() => {
					setReordering(false);
				}}
			></BotaoVoltar>
		</Box>
	);
};

export default ReordenarBlocos;
