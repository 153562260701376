import React from 'react';

import NumberFormat from 'react-number-format';

const ValorField = ({ record, source, valueDecimal = 0.0, ...props }) => (
	<NumberFormat
		thousandSeparator='.'
		decimalSeparator=','
		decimalScale={8}
		isNumericString
		displayType='text'
		value={record?.[source] || valueDecimal}
		fixedDecimalScale
		onChange={() => {}}
	/>
);

export default ValorField;
