import React, { useContext } from 'react';

import { TooltipIconButtonRemove } from '../../common/buttons/ButtonRemove';
import { ModalContext } from '../../common/ModalContext';
import { ModalConfirmacaoExcluirLote } from './modais';

const BotaoExcluirMovimentacoesLote = ({ ids, disabled = false }) => {
	const { setModalValue } = useContext(ModalContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmacaoExcluirLote {...{ ids }} />,
		}));
	};
	return (
		<TooltipIconButtonRemove
			disabled={disabled}
			onClick={handleClick}
			size='small'
			title={(ids || []).length > 1 ? 'Excluir contas selecionadas' : 'Excluir conta selecionada'}
		/>
	);
};

export default BotaoExcluirMovimentacoesLote;
