import React, { useState } from 'react';
import EditaConfiguracaoLeituraContextProvider from './componentes/EditaConfiguracaoLeituraContextProvider';
import FormPorUnidadeDeMedida from './componentes/FormPorUnidadeDeMedida';
import FormPercentual from './componentes/FormPercentual';
import FormPorFaixa from './componentes/FormPorFaixa';
import FormSimples from './componentes/FormSimples';

const formsDosMetodosDeCalculo = {
	1: () => <FormPorFaixa />,
	2: () => <FormPorUnidadeDeMedida />,
	3: () => <FormPorFaixa />,
	4: () => <FormPercentual />,
	[undefined]: () => <FormSimples />,
};

const ModalEditaConfiguracaoLeitura = ({ config_leitura }) => {
	const [dadosConfig] = useState(config_leitura);
	const [FormConfiguracaoLeitura] = useState(
		(formsDosMetodosDeCalculo[dadosConfig.metodo_calculo] || formsDosMetodosDeCalculo[undefined])()
	);
	return (
		<EditaConfiguracaoLeituraContextProvider config_leitura={config_leitura}>
			{FormConfiguracaoLeitura}
		</EditaConfiguracaoLeituraContextProvider>
	);
};

export default ModalEditaConfiguracaoLeitura;
