import React, { useContext } from 'react';
import {
	TextField,
	EmailField,
	List,
	Pagination,
	ReferenceField,
	CreateButton,
	ExportButton,
	TopToolbar,
	Button as RAButton,
} from 'react-admin';

import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';

import brExporter from '../../../utils/exporter';
import { telefoneFormatacao } from '../../../fieldControl/telefone';
import { tipoOpcoes } from '../../../fieldControl/tipoPessoa';
import { situacaoOpcoes } from '../../../fieldControl/situacao';

import { Tabela } from '../../common/Tabela';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Padrao } from '../../common/filtros/Padrao';
import { cpfFormatacao, cnpjFormatacao } from '../../../fieldControl/documento';

import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from 'components/common/ModalSizeEditableContext';
import ImportFornecedoresContextProvider from './modais/import/ImportFornecedoresContextProvider';

const TipoField = ({ record }) => <span>{record.tipo_fornecedor === 'J' ? 'Jurídica' : 'Física'}</span>;

const DocumentoField = ({ record }) =>
	record.documento && record.documento.length === 11 ? (
		<span>{cpfFormatacao(record.documento)}</span>
	) : (
		<span>{cnpjFormatacao(record.documento)}</span>
	);

const TelefoneField = ({ record }) => <span>{telefoneFormatacao(record?.tel_princ)}</span>;

const SituacaoField = ({ record }) => <span>{record.situacao === 'A' ? 'Ativo' : 'Inativo'}</span>;

const TabelaFornecedores = (props) => {
	return (
		<Tabela rowClick='edit' {...props} updateRowRecord>
			<TipoField source='tipo_fornecedor' label='Tipo' filtro={<Autocompletar opcoes={tipoOpcoes} />} />
			<TextField source='nome' label='Nome' filtro={<Padrao />} />
			<ReferenceField
				source='tipo_ramo_fornecedor'
				label='Ramo'
				reference='tipo_fornecedor'
				link={false}
				filtro={<Autocompletar refResource='tipo_fornecedor' refName='descricao' />}
			>
				<TextField source='descricao' />
			</ReferenceField>
			<DocumentoField source='documento' label='CNPJ/CPF' filtro={<Padrao />} />
			<TelefoneField source='tel_princ' label='Telefone' filtro={<Padrao />} />
			<EmailField source='email_princ' label='E-mail' filtro={<Padrao />} />
			<SituacaoField source='situacao' label='Situação' filtro={<Autocompletar opcoes={situacaoOpcoes} />} />
		</Tabela>
	);
};

const RAButtonImport = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	return (
		<RAButton
			label='Importar'
			id='importar_fornecedores'
			color='primary'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ImportFornecedoresContextProvider />,
				}));
			}}
		>
			<SwapVerticalCircleOutlinedIcon />
		</RAButton>
	);
};

const ListActions = (props) => {
	const { className, basePath, total, resource, currentSort, filterValues, exporter } = props;
	return (
		<TopToolbar className={className}>
			<CreateButton basePath={basePath} />
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
			<RAButtonImport {...props} />
		</TopToolbar>
	);
};

export const FornecedoresList = (props) => (
	<ModalSizeEditableContextProvider
		customDialogProps={{
			disableBackdropClick: true,
			disableEscapeKeyDown: true,
			minWidth: '95vw',
		}}
	>
		<List
			{...props}
			actions={<ListActions />}
			exporter={brExporter}
			empty={false}
			bulkActionButtons={false}
			title='Fornecedores'
			filterDefaultValues={{ situacao: 'A' }}
			sort={{ field: 'nome', order: 'ASC' }}
			perPage={10}
			pagination={
				<Pagination
					rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
					labelRowsPerPage='Fornecedores por página'
				/>
			}
		>
			<TabelaFornecedores />
		</List>
	</ModalSizeEditableContextProvider>
);
