import { getDadosFormattingContaCorrente } from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';
import DefaultContaNaoCorrenteProps, { defaultComponentesProps } from '../default/ContaNaoCorrenteProps';

const ContaNaoCorrenteProps = {
	...DefaultContaNaoCorrenteProps,
	instituicaoCodigo: '341',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(6),
		},
	},
	getOptions: getFormatarOptions('341', { agencia: 'agencias_itau' }),
};

export default ContaNaoCorrenteProps;
