import React, { useContext, useState } from 'react';

import TextField from '@material-ui/core/TextField';

import { Autocomplete } from '@material-ui/lab';

import { MovimentacoesContext } from '../../../MovimentacoesContext';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

const CondominioSelect = ({ disabled = false, label = 'Selecionar Condomínio' }) => {
	const { condominiosData } = useContext(MovimentacoesContext);
	const { condominio, condominios, setCondominio } = useContext(FluxoImportacaoExtratoBancarioContext);
	const [option, setOption] = useState(condominio ? (condominiosData || {})[condominio] : condominios[0]);

	return (
		<Autocomplete
			options={condominios}
			renderInput={(params) => <TextField {...params} margin='dense' label={label} fullWidth />}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option?.nome}
			getOptionSelected={(option, v) => v?.id === option?.id}
			onChange={(_, option) => {
				setOption(option);
				setCondominio(option.id);
			}}
			value={option}
			noOptionsText='Nenhum resultado'
			fullWidth
			disabled={disabled}
			disableClearable
		/>
	);
};

export default CondominioSelect;
