import React from 'react';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { BoletosContext } from '../../ContasReceberBoletos/BoletosContextProvider';
import { RemessasContext } from '../contextos';
import { useGerarBoletosSemRelatorios } from '../hooks';

export const ButtonGerarBoletosSemRelatorios = ({ text = 'Gerar' }) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados } = React.useContext(RemessasContext);
	const { boletoConfigIsValid } = React.useContext(BoletosContext);

	const condominioSelecionadoId = condominioSelecionado?.id;

	const [loadingRequest, setLoadingRequest] = React.useState(false);
	const [valid, setValid] = React.useState(true);

	const gerarBoletos = useGerarBoletosSemRelatorios({
		condominioSelecionadoId,
		idsRegistrosSelecionados,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		boletoConfigIsValid,
		setValid,
		ButtonGerarRemessas: ButtonGerarBoletosSemRelatorios,
	});

	const handleClick = React.useCallback(async () => {
		setLoadingRequest(true);
		await gerarBoletos();
		setLoadingRequest(false);
	}, [setLoadingRequest, gerarBoletos]);

	return (
		<ButtonConfirm onClick={handleClick} disabled={loadingRequest || !valid}>
			{text}
		</ButtonConfirm>
	);
};

export default ButtonGerarBoletosSemRelatorios;
