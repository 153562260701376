import React from 'react';

import { useNotify, useDataProvider } from 'react-admin';

import { CustomDataProvider } from 'types/tpyesGlobal';
import { useBaixarSegundaViaProps } from './types';

const onSuccessCallbackDefault = (response: any) => {};

export const useBaixarSegundaVia = ({
	idsRegistrosSelecionados,
	referencia,
	id_identificacao_arrecadacao,
	contaBDestinoId,
	condominioSelecionadoId,
	onSuccessCallback = onSuccessCallbackDefault,
	parametrosPayloadExtras,
}: useBaixarSegundaViaProps) => {
	const notify = useNotify();
	const dataProvider: CustomDataProvider = useDataProvider();

	const baixarRemessas = React.useCallback(() => {
		return dataProvider
			.safeCreate('remessas/segunda_via', {
				data: {
					lista_contas_a_receber_id: idsRegistrosSelecionados,
					referencia: referencia,
					identificacao_arrecadacao_id: id_identificacao_arrecadacao,
					conta_bancaria_id: contaBDestinoId,
					condominio_id: condominioSelecionadoId,
					...parametrosPayloadExtras,
				},
			})
			.then(onSuccessCallback)
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg as string, 'warning');
			});
	}, [
		notify,
		dataProvider,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		idsRegistrosSelecionados,
		parametrosPayloadExtras,
	]);

	return baixarRemessas;
};

export default useBaixarSegundaVia;
