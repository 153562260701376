import React from 'react';

import { BooleanInput } from 'components/common/commonComponentsTSX';

import { PermtirSegundaViaInputProps } from '../../types';

export const PermitirSegundaViaInput: React.FC<PermtirSegundaViaInputProps> = ({
	allowDuplicateGeneration,
	setAllowDuplicateGeneration,
}) => {
	return (
		<BooleanInput
			label='Permitir Segunda Via'
			setState={() => setAllowDuplicateGeneration((prevState) => !prevState)}
			value={allowDuplicateGeneration}
			title={'Permitir que o condômino gere uma segunda via do boleto disponibilizado ou não'}
		/>
	);
};

export default PermitirSegundaViaInput;
