import React from 'react';
import { Edit, useNotify, useRedirect, useRefresh } from 'react-admin';

import { EnqueteFormulario } from './form';

export const EnqueteEdit = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response) => {
		const data = response?.data;
		if (data) {
			notify(`Enquete "${data.titulo}" alterada com sucesso`);
			redirect('list', props.basePath);
			refresh();
		}
	};
	return (
		<Edit component='div' title={'Editar Enquete'} undoable={false} onSuccess={onSuccess} {...props}>
			<EnqueteFormulario edit {...props} />
		</Edit>
	);
};
