import React from 'react';

import { parseISO } from 'date-fns';

import { useDataProvider, useNotify } from 'react-admin';

import { ConfiguracaoDeCorrecaoMonetariaContext } from '../contexts';
import {
	Data,
	TSetNome,
	TSetTipoIndice,
	TSetUltimaDataAtualizacao,
	TSetUltimoValorCalculado,
	TSetFeesOptions,
	TSetFeesPercentage,
	TSetFinePercentage,
	TSetAllowDuplicateGeneration,
	TSetDaysLimitAfterExpiration,
} from '../types';

type stateSets = {
	setNome: TSetNome;
	setTipoIndice: TSetTipoIndice;
	setUltimaDataAtualizacao: TSetUltimaDataAtualizacao;
	setUltimoValorCalculado: TSetUltimoValorCalculado;
	setFeesOptions: TSetFeesOptions;
	setFeesPercentage: TSetFeesPercentage;
	setFinePercentage: TSetFinePercentage;
	setAllowDuplicateGeneration: TSetAllowDuplicateGeneration;
	setDaysLimitAfterExpiration: TSetDaysLimitAfterExpiration;
};

export const useLoadingData = ({
	setNome,
	setTipoIndice,
	setUltimaDataAtualizacao,
	setUltimoValorCalculado,
	setFeesOptions,
	setFeesPercentage,
	setFinePercentage,
	setAllowDuplicateGeneration,
	setDaysLimitAfterExpiration,
}: stateSets): { loading: boolean; record: Data | null } => {
	const notify = useNotify();
	const dp = useDataProvider() as ReturnType<typeof useDataProvider>;
	const { screen } = React.useContext(ConfiguracaoDeCorrecaoMonetariaContext);

	const [loading, setLoading] = React.useState<boolean>(true);
	const [record, setRecord] = React.useState<Data | null>(null);

	React.useEffect(() => {
		dp.getOne('correcao_monetaria', {
			id: screen.editId as number,
		})
			.then((response) => {
				const data = response.data as Data;
				setRecord(data as Data);
				setNome(data.nome);
				setTipoIndice(data.tipo_indice);
				const ultimaDataAtualizacao = parseISO(data.ultima_data_atualizacao);
				setUltimaDataAtualizacao(
					ultimaDataAtualizacao.toString() === 'Invalid Date' ? null : ultimaDataAtualizacao
				);
				setUltimoValorCalculado(data.ultimo_valor_calculo);
				setFeesOptions({
					option_daily_fees: data.fees_option === 'JD',
					option_monthly_fees: data.fees_option === 'JM',
				});
				setFeesPercentage(String(data.fees_percentage).replace('.', ','));
				setFinePercentage(String(data.fine_percentage).replace('.', ','));
				setAllowDuplicateGeneration(data.allow_duplicate_generation === 'S' ? true : false);
				setDaysLimitAfterExpiration(data.days_limit_after_expiration);
				setLoading(false);
			})
			.catch(() => {
				return notify('Ocorreu um problema ao carregar as configurações!', { type: 'error' });
			});
	}, []);
	return { loading, record };
};

export default useLoadingData;
