import React from 'react';

import SearchIcon from '@material-ui/icons/Search';

import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { ModalContext } from 'components/common/ModalContext';
import ModalHistMail from './ModalHistMail';

export const VisualizarField = ({ record }) => {
	const { setModalValue } = React.useContext(ModalContext);
	return (
		<TooltipIconButton
			size='small'
			title='Visualizar'
			onClick={(e) => {
				e.stopPropagation();
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalHistMail idContaAReceber={record?.id} />,
				}));
			}}
		>
			<SearchIcon />
		</TooltipIconButton>
	);
};

export default VisualizarField;
