import React from 'react';

import { Box, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';

const MarcaDAgua: React.FC = () => {
	const {
		opcoesImpressao: { imprimir_marca_dagua },
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	return (
		<Box>
			<h3>Impressões</h3>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_marca_dagua}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_marca_dagua')}
							name='imprimir_marca_dagua'
						/>
					}
					label="Imprimir marca d'água no boleto"
				/>
			</FormGroup>
		</Box>
	);
};
export default MarcaDAgua;
