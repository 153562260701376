import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { FiltersBoxProps } from './types.d';

// Novo filterBox pra ser usado  em typescipt
const FilterBox: React.FC<FiltersBoxProps> = React.memo(({ titulo, children, boxProps = {}, paperStyle = {} }) => (
	<Box {...boxProps}>
		<Paper
			style={{
				padding: 15,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: 'calc(100% - 30px)',
				...paperStyle,
			}}
		>
			<Typography variant='h6'>{titulo}</Typography>
			<div>{children}</div>
		</Paper>
	</Box>
));

export default FilterBox;
