import React, { useContext } from 'react';

import { BotaoSalvar } from '../../common/buttons/BotaoSalvar';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { CustomDialogBodySizeEditable } from '../../common/ModalSizeEditableContext';

import ButtonRemoverContaAPagar from './ButtonRemoverContaAPagar';
import { LancamentoContaPagarContext, LancamentoContaPagarContextProvider } from './LancamentoContaPagarContext';
import FormNovaContaAPagar from './FormNovaContaAPagar';

import ModalConfirmaEdicaoContaAPagar from './ModalConfirmaEdicaoContaAPagar';

const CustomActions = () => {
	const { closeModal, handleSave } = useContext(LancamentoContaPagarContext);
	const [isOpen, setModalOpen] = React.useState(false);
	const handleSaveWithConfirmation = () => {
		setModalOpen(true);
	};

	return (
		<>
			<ButtonCancel onClick={closeModal} />
			<ButtonRemoverContaAPagar />
			<BotaoSalvar onClick={handleSaveWithConfirmation} />
			{isOpen && (
				<ModalConfirmaEdicaoContaAPagar
					open={true}
					onClose={() => setModalOpen(false)}
					onConfirm={handleSave}
				/>
			)}
		</>
	);
};

const ModalEditarContaAPagar = ({ context, record }) => {
	return (
		<LancamentoContaPagarContextProvider context={context} record={record}>
			<CustomDialogBodySizeEditable
				title='Editar Conta a Pagar'
				form={{ component: <FormNovaContaAPagar /> }}
				customActions={<CustomActions />}
			/>
		</LancamentoContaPagarContextProvider>
	);
};

export default ModalEditarContaAPagar;
