import React, { useContext, useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField } from '@material-ui/core';

import { PrevisaoOrcamentariaContext } from './contexts/PrevisoesOrcamentariasContextProvider';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { BotaoSalvar, ButtonCancel } from 'components/common/commonComponentsTSX';
import { IPrevisaoOrcamentariaWithDadosContaNode } from './types';
import { MonthPicker } from './periodoPicker';

export const ModalCreatePrevisao = () => {
	const dp = useDataProvider();
	const notify = useNotify();
	const [loading, setLoading] = useState(false);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [nomeModelo, setNomeModelo] = useState<string>('');
	const { modeloSelecionado, getModelosPrevisoesOrcamentarias } = useContext(PrevisaoOrcamentariaContext);
	const [referenciaInicial, setReferenciaInicial] = useState<Date>(new Date());
	const [referenciaFinal, setReferenciaFinal] = useState<Date>(new Date());
	const { condominioSelecionado } = useContext(CondominiosContext);
	const contasAnaliticas = [] as any[];

	const processTreeBottomToTop = (node: IPrevisaoOrcamentariaWithDadosContaNode) => {
		if (node.children && node.children.length > 0)
			for (const childNode of node.children) processTreeBottomToTop(childNode);

		if (node.classe_conta === 'A') {
			const tipoConta = node.id_concatenado.includes('CR')
				? { conta_receita_id: node.id }
				: { conta_despesa_id: node.id };
			contasAnaliticas.push({
				valor_periodo: node.valorPeriodo,
				valor_mensal: node.valorMensal,
				modelo_previsao_orcamentaria_id: modeloSelecionado?.id,
				...tipoConta,
			});
		}
	};

	const handleSubmit = () => {
		setLoading(true);
		referenciaInicial.setDate(1);
		referenciaFinal.setDate(new Date(referenciaFinal.getFullYear(), referenciaFinal.getMonth() + 1, 0).getDate());
		referenciaInicial.setHours(0, 0, 0, 0);
		referenciaFinal.setHours(0, 0, 0, 0);
		const deltaPeriodo = Math.round(
			(referenciaFinal.getTime() - referenciaInicial.getTime()) / (1000 * 60 * 60 * 24 * 30)
		);
		const novoModelo = {
			nome: nomeModelo,
			condominio: condominioSelecionado?.id,
			inicio: referenciaInicial.toISOString().split('T')[0],
			termino: referenciaFinal.toISOString().split('T')[0],
			periodo: deltaPeriodo,
			plano_condominio: condominioSelecionado?.id_plano_condominio,
		};

		dp.create('modelo_previsao_orcamentaria', {
			data: novoModelo,
		})
			.then(() => {
				setModalValue((v: object) => ({ ...v, open: false }));
				getModelosPrevisoesOrcamentarias();
				notify('Previsão orçamentária criada com sucesso', 'success');
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				const errorData: Array<string> = Object.values(e?.response?.data);
				notify(errorData[0][0] as string, 'error');
			});
	};

	return (
		<Dialog
			open={true}
			style={{
				minWidth: '60vw',
			}}
		>
			<DialogTitle>Criar nova Previsão Orçamentária</DialogTitle>
			<DialogContent style={{ overflowY: 'hidden' }}>
				<Box>
					<TextField
						id='input-name-Previsao-config'
						label='Digite um nome para o modelo'
						variant='outlined'
						size='small'
						style={{ width: '100%' }}
						onChange={({ target: { value } }) => {
							setNomeModelo(value);
						}}
					/>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							gap: '1em',
						}}
					>
						<MonthPicker
							label='Início'
							handleChange={(newValue: any) => {
								setReferenciaInicial(newValue);
							}}
							value={
								referenciaInicial && referenciaInicial.toString() !== 'Invalid Date'
									? referenciaInicial.toLocaleDateString('pt-BR').slice(3)
									: undefined
							}
						></MonthPicker>
						<MonthPicker
							label='Término'
							handleChange={(newValue: any) => {
								setReferenciaFinal(newValue);
							}}
							value={
								referenciaFinal && referenciaFinal.toString() !== 'Invalid Date'
									? referenciaFinal.toLocaleDateString('pt-BR').slice(3)
									: undefined
							}
						></MonthPicker>
					</Box>
				</Box>
			</DialogContent>

			<DialogActions>
				<ButtonCancel
					onClick={() => setModalValue((v: object) => ({ ...v, open: false }))}
					id={undefined}
					className={undefined}
				/>
				<BotaoSalvar
					disabled={
						loading ||
						!nomeModelo ||
						!referenciaInicial ||
						!referenciaFinal ||
						referenciaInicial > referenciaFinal
					}
					onClick={handleSubmit}
				></BotaoSalvar>
			</DialogActions>
		</Dialog>
	);
};
