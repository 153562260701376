import React, { useContext } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../../../common/ModalSizeEditableContext';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { ContasBancariasListContext } from '../../ContasBancariasListContextProvider';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import EditaContaBancariaButtonsContext from '../contexts/EditaContaBancariaButtonsContext';

const useStyles = makeStyles((theme) => ({
	inativarButton: {
		color: theme.palette.syndikosRed?.main || '#CA3433',
	},
}));

const InativarButton = () => {
	const notify = useNotify();
	const dP = useDataProvider();
	const classes = useStyles();
	const refresh = useRefresh();
	const { setReloadFilter } = useContext(ContasBancariasListContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { closeModal } = useContext(EditaContaBancariaButtonsContext);
	const {
		condominioRecord,
		initialDados: {
			conta_condominio: { id },
		},
	} = useContext(EditaContaBancariaContext);

	const handleInative = () => {
		dP.update('conta_condominio', {
			id: id,
			data: { situacao: 'I' },
		})
			.then(() => {
				notify('Conta bancária inativada com sucesso!');
				setReloadFilter((v) => !v);
				refresh();
				closeModal();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				if (e?.body?.non_field_errors) {
					notify(e.body.non_field_errors.join('\n'), 'warning');
				} else {
					notify(e?.response?.data[0], 'warning');
				}
			});
	};

	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const onClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBodySizeEditable
					title={'Tem certeza que deseja inativar essa conta bancária?'}
					customActions={
						<>
							<ButtonCancel onClick={handleClose} />
							<ButtonConfirm size='small' onClick={handleInative}>
								Inativar
							</ButtonConfirm>
						</>
					}
				/>
			),
		}));
	};
	return (
		<Button
			disabled={condominioRecord.situacao !== 'A'}
			onClick={onClick}
			variant='text'
			className={classes.inativarButton}
			size='small'
		>
			Inativar
		</Button>
	);
};

export default InativarButton;
