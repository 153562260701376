import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { CloseButton, ConfirmButton } from 'components/common/commonComponentsTSX';
import React, { useState } from 'react';
import { CustomDataProvider } from '../types';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useForm } from 'react-final-form';

interface FinalizarOcorrenciaModalProps {
	closeFinalizarOcorrenciaModal: () => void;
	ocorrenciaId: number | null;
}

const FinalizarOcorrenciaModal: React.FC<FinalizarOcorrenciaModalProps> = ({
	closeFinalizarOcorrenciaModal,
	ocorrenciaId,
}) => {
	const [finalizando, setFinalizando] = useState(false);

	const dataProvider: CustomDataProvider = useDataProvider();

	const { getState } = useForm();
	const {
		values: { tipo, classificacao },
	} = getState();

	const notify = useNotify();
	const refresh = useRefresh();

	const finalizarOcorrencia = () => {
		setFinalizando(true);
		dataProvider
			.simplePost(`ocorrencias/${ocorrenciaId}/finalizar`, { data: { tipo: tipo, classificacao: classificacao } })
			.then(() => {
				notify('Ocorrência finalizada com sucesso');
				closeFinalizarOcorrenciaModal();
				refresh();
			})
			.catch((e: any) => {
				notify(e?.response.data.error);
				setFinalizando(false);
			});
	};

	return (
		<Dialog open={true} fullWidth>
			<DialogTitle>Finalizar Ocorrência</DialogTitle>
			<DialogContent>Tem certeza que deseja finalizar a ocorrência?</DialogContent>
			<DialogActions>
				<CloseButton onClick={closeFinalizarOcorrenciaModal} size='small'>
					Voltar
				</CloseButton>
				<ConfirmButton onClick={finalizarOcorrencia} size='small' disabled={finalizando}>
					{(finalizando && <CircularProgress size={20} />) || 'Confirmar'}
				</ConfirmButton>
			</DialogActions>
		</Dialog>
	);
};

export default FinalizarOcorrenciaModal;
