import React, { FC } from 'react';

import DocumentsProvider from './contexts/DocumentsContext';
import { ModalContextProvider, CustomDialog } from 'components/common/ModalContext';
import DocumentsBody from './DocumentsBody';
import { Title } from 'react-admin';

const DocumentsPrincipalPage: FC = () => {
	return (
		<DocumentsProvider>
			<Title title={'Documentos'} />
			<ModalContextProvider>
				<DocumentsBody />
				<CustomDialog />
			</ModalContextProvider>
		</DocumentsProvider>
	);
};

export default DocumentsPrincipalPage;
