import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import { NumberFormatBRL } from 'components/common/CurrencyInput';
import NovaLeituraContext from './NovaLeituraContext';

const sanitizeListNumberFormatBRLProps: SanitizeListNumberFormatBRLProps = {
	decimalScale: 2,
	suffix: '%',
};

const MetodoPercentual = () => {
	const { dadosNovaLeitura, setDadosNovaLeitura } = React.useContext(NovaLeituraContext);
	const percentual = dadosNovaLeitura.dados_config.percentual || 0;

	return (
		<TextField
			name='valorPercentual'
			id='valorPercentualID'
			label='Percentual %'
			value={percentual}
			style={{ marginTop: '8px', marginRight: '0.5em' }}
			margin='dense'
			onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
				let parsedValue = Number(value);
				if (!parsedValue) parsedValue = 0;
				if (parsedValue > 999.99) parsedValue = 999.99;
				setDadosNovaLeitura((v: TDadosNovaLeituraConfig) => {
					v.dados_config['percentual'] = parsedValue || 0;
					return v;
				});
			}}
			inputProps={sanitizeListNumberFormatBRLProps}
			InputProps={{
				inputComponent: NumberFormatBRL,
			}}
		/>
	);
};

export default MetodoPercentual;
