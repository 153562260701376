import React, { useCallback } from 'react';

import { Box, TextField, FormControlLabel, Checkbox } from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const tipos_conta_por_extenso = {
	O: 'Ordinária',
	E: 'Extraordinária',
	L: 'Leituras',
	F: 'Fundos',
};

export const formatOptionConta = (option) => {
	if (!option?.tipoConta) return;
	return option.nome_grupo
		? `${option.nome_grupo} - ${option.tipoConta} - ${option.nome}`
		: `${option.tipoConta} - ${option.nome} ( ${tipos_conta_por_extenso[option.tipo] || '?'} )`;
};

export const SelectMultiContas = ({ onChange, values, options = [], ...props }) => (
	<Autocomplete
		id='select-multi-contas'
		disableCloseOnSelect={true}
		options={options}
		renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Conta' />}
		renderOption={(option, { selected }) => (
			<span>
				<Checkbox checked={selected} />
				{formatOptionConta(option)}
			</span>
		)}
		groupBy={(option) => option.tipoConta}
		getOptionSelected={(op, val) => op.tipoConta === val.tipoConta && op.id === val.id}
		getOptionLabel={(option) =>
			option && (formatOptionConta(option) || formatOptionConta(options.find((v) => v.id === option)))
		}
		noOptionsText='Nenhum resultado encontrado'
		onChange={onChange}
		disabled={!options.length}
		autoHighlight
		value={values}
		clearText='Limpar'
		multiple
		{...props}
	/>
);

export const SelectMultiContasParaCalculoWithActions = ({
	values,
	setValues,
	options = [],
	disabled,
	tooltipAddTitle = 'Selecione todas as Contas',
	tooltipRemoveTitle = 'Remover todas as Contas',
	setContasDespCalc,
	setContasRecCalc,
	...props
}) => {
	[setContasDespCalc, setContasRecCalc] = setValues;

	const setConfigTdsContas = () => {
		const { Despesa: contasDespCalc, Receita: contasRecCalc } = options.reduce(
			(contas, conta) => {
				const dict = contas[conta.tipoConta];
				if (dict) {
					dict[conta.id] = conta;
					contas[conta.tipoConta] = dict;
				}
				return contas;
			},
			{ Despesa: {}, Receita: {} }
		);

		setContasDespCalc(Object.values(contasDespCalc));
		setContasRecCalc(Object.values(contasRecCalc));
	};

	const setConfigRemoveContas = useCallback(() => {
		setContasDespCalc([]);
		setContasRecCalc([]);
	}, [setContasDespCalc, setContasRecCalc]);

	const onChange = (_, v) => {
		const funcReduce = (dict, conta) => {
			dict[conta.id] = conta;
			return dict;
		};
		const contasDesp = v.filter((option) => option.tipoConta === 'Despesa').reduce(funcReduce, {});
		const contasRec = v.filter((o) => o.tipoConta === 'Receita').reduce(funcReduce, {});
		setContasDespCalc(Object.values(contasDesp));
		setContasRecCalc(Object.values(contasRec));
	};

	return (
		<FormControlLabel
			disabled={!options.length}
			control={
				<Box flex={1} mr='1em'>
					<SelectMultiContas
						{...{
							onChange,
							values,
							options,
							disabled,
							setContasDespCalc,
							setContasRecCalc,
						}}
						{...props}
					/>
					<Tooltip title={tooltipAddTitle}>
						<AddCircleOutlineIcon
							id='add-all-contas'
							color={'primary'}
							style={{
								marginTop: '10px',
								marginRight: '20px',
								marginLeft: '20px',
							}}
							onClick={() => setConfigTdsContas()}
						/>
					</Tooltip>
					<Tooltip title={tooltipRemoveTitle}>
						<RemoveCircleOutlineIcon
							id='remove-all-contas'
							color={'primary'}
							style={{
								marginTop: '10px',
								marginRight: '20px',
								marginLeft: '20px',
							}}
							onClick={() => setConfigRemoveContas()}
						/>
					</Tooltip>
				</Box>
			}
		/>
	);
};
