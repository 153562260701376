import React from 'react';

import { useListContext } from 'react-admin';

import { TabelaRowContext } from 'components/common/Tabela';
import { SuccessChip } from 'components/common/chips';

export const SituacaoField = ({ chips, DefaultChip = <SuccessChip value='??' />, record, source }) => {
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = React.useContext(TabelaRowContext);

	const update = () => {
		if (data && data[rowRecord.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	React.useEffect(update, [data]);

	return chips?.[record?.[source]] || DefaultChip;
};

export default SituacaoField;
