import React from 'react';

import { Box, Divider } from '@material-ui/core';

import InputAnoVeiculo from './Inputs/InputAno';
import InputCoeficienteGaragem from './Inputs/InputCoeficiente';
import InputDescricaoGaragem from './Inputs/InputDescricao';
import InputNumeroGaragem from './Inputs/InputNumero';
import InputModeloVeiculo from './Inputs/InputModelo';
import InputPlacaVeiculo from './Inputs/InputPlaca';
import InputNomeGaragem from './Inputs/InputNome';
import InputCorVeiculo from './Inputs/InputCor';
import InputMarcaVeiculo from './Inputs/InputMarca';

function FormDeGaragens({
	nomeGaragem,
	setNomeGaragem,
	numeroGaragem,
	setNumeroGaragem,
	descricaoGaragem,
	setDescricaoGaragem,
	coeficienteGaragem,
	setCoeficienteGaragem,
	marcaVeiculo,
	setMarcaVeiculo,
	modeloVeiculo,
	setModeloVeiculo,
	anoVeiculo,
	setAnoVeiculo,
	placaVeiculo,
	setPlacaVeiculo,
	corVeiculo,
	setCorVeiculo,
}) {
	return (
		<Box display='grid'>
			<Box display='flex' gridGap='0.5rem'>
				<InputNomeGaragem value={nomeGaragem} setValue={setNomeGaragem} />
				<InputNumeroGaragem value={numeroGaragem} setValue={setNumeroGaragem} />
			</Box>
			<InputDescricaoGaragem value={descricaoGaragem} setValue={setDescricaoGaragem} />
			<Divider />
			<Box display='flex' gridGap='0.5rem'>
				<InputCoeficienteGaragem value={coeficienteGaragem} setValue={setCoeficienteGaragem} />
				<InputMarcaVeiculo value={marcaVeiculo} setValue={setMarcaVeiculo} />
			</Box>
			<Box display='flex' gridGap='0.5rem'>
				<InputAnoVeiculo value={anoVeiculo} setValue={setAnoVeiculo} />
				<InputModeloVeiculo value={modeloVeiculo} setValue={setModeloVeiculo} />
			</Box>
			<Box display='flex' gridGap='0.5rem'>
				<InputPlacaVeiculo value={placaVeiculo} setValue={setPlacaVeiculo} />
				<InputCorVeiculo value={corVeiculo} setValue={setCorVeiculo} />
			</Box>
		</Box>
	);
}

export default FormDeGaragens;
