import React from 'react';

import { Box } from '@material-ui/core';

import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const EmissaoBoletoField = () => (
	<Box pl={1} flex={1} sx={{ 'max-width': '17%' }}>
		<EditaContaBancariaAutocomplete
			source='emissao_boleto'
			name='emissao_boleto'
			id='emissao_boleto'
			label='Emissão do Boleto'
			translateChoice={false}
		/>
	</Box>
);

export default EmissaoBoletoField;
