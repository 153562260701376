import React from 'react';
import { TextInput } from 'react-admin';

type InputTimeProps = {
	label: string;
	defaultValue: string;
	source: string;
	size: 'small' | 'medium';
	variant: 'standard' | 'filled' | 'outlined' | undefined;
	fullWidth: any;
	validate: any;
	margin: 'dense' | 'none' | 'normal';
	disabled?: boolean;
};

export const InputTime = ({ label, source, size = 'small', margin, ...rest }: InputTimeProps) => {
	return <TextInput id='time' label={label} source={source} type='time' size={size} margin={margin} {...rest} />;
};
