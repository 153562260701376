import * as React from 'react';
import { List, Pagination, TextField, EmailField } from 'react-admin';
import brExporter from '../../../utils/exporter';
import { tipoOpcoes } from '../../../fieldControl/tipoPessoa';
import { situacaoOpcoes } from '../../../fieldControl/situacao';
import { Tabela } from '../../common/Tabela';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Padrao } from '../../common/filtros/Padrao';
import { cpfFormatacao, cnpjFormatacao } from '../../../fieldControl/documento';

const TipoField = ({ record }) => <span>{record.tipo_pessoa === 'J' ? 'Jurídica' : 'Física'}</span>;
const SituacaoField = ({ record }) => <span>{record.situacao === 'A' ? 'Ativo' : 'Inativo'}</span>;
const DocumentoField = ({ record }) =>
	record.documento && record.documento.length === 11 ? (
		<span>{cpfFormatacao(record.documento)}</span>
	) : (
		<span>{cnpjFormatacao(record.documento)}</span>
	);

const TabelaPessoas = (props) => (
	<Tabela rowClick='edit' {...props}>
		<TipoField source='tipo_pessoa' label='Tipo' filtro={<Autocompletar opcoes={tipoOpcoes} />} />
		<TextField source='nome' label='Nome' filtro={<Padrao />} />
		<DocumentoField source='documento' label='CNPJ/CPF' filtro={<Padrao />} style={{ width: 100 }} />
		<EmailField source='email_cobranca' label='E-mail' filtro={<Padrao />} />
		<SituacaoField source='situacao' label='Situação' filtro={<Autocompletar opcoes={situacaoOpcoes} />} />
	</Tabela>
);

export const PessoaList = (props) => (
	<List
		{...props}
		exporter={brExporter}
		empty={false}
		bulkActionButtons={false}
		title='Pessoas'
		filterDefaultValues={{ situacao: 'A' }}
		sort={{ field: 'nome', order: 'ASC' }}
		perPage={10}
		pagination={
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Pessoas por página' />
		}
	>
		<TabelaPessoas />
	</List>
);
