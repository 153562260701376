const optionsEmpty = {
	choices: [],
	objects: {},
};

// Funções para formatar os dados para popular o formulário na inicialização do modal
const getBeneficiarioDadosDoCondominio = ({
	condominioRecord,
	options: { beneficiario: optionsBeneficiario = optionsEmpty },
}) => ({
	beneficiario: optionsBeneficiario.objects['BCD'],
	beneficiario_nome: condominioRecord?.nome || '',
	beneficiario_tipo_documento: 'J',
	beneficiario_documento: condominioRecord?.cnpj || '',
	beneficiario_cep: condominioRecord?.cep || '',
	beneficiario_tipo_logradouro: condominioRecord?.tipo_logradouro || null,
	beneficiario_endereco: condominioRecord?.endereco || '',
	beneficiario_numero: condominioRecord?.numero || '',
	beneficiario_bairro: condominioRecord?.bairro || '',
	beneficiario_cidade: condominioRecord?.cidade || '',
	beneficiario_uf: condominioRecord?.uf || null,
	beneficiario_complemento_endereco: condominioRecord?.complemento_endereco || '',
});

const getBeneficiarioDadosDaContaBancaria = ({
	cobranca_config,
	options: { beneficiario: optionsBeneficiario = optionsEmpty },
}) => ({
	beneficiario: optionsBeneficiario.objects['BCB'],
	beneficiario_nome: cobranca_config?.beneficiario_nome || '',
	beneficiario_tipo_documento: cobranca_config?.beneficiario_tipo_documento || 'J',
	beneficiario_documento: cobranca_config?.beneficiario_documento || '',
	beneficiario_cep: cobranca_config?.beneficiario_cep || '',
	beneficiario_tipo_logradouro: cobranca_config?.beneficiario_tipo_logradouro || null,
	beneficiario_endereco: cobranca_config?.beneficiario_endereco || '',
	beneficiario_numero: cobranca_config?.beneficiario_numero || '',
	beneficiario_bairro: cobranca_config?.beneficiario_bairro || '',
	beneficiario_cidade: cobranca_config?.beneficiario_cidade || '',
	beneficiario_uf: cobranca_config?.beneficiario_uf || null,
	beneficiario_complemento_endereco: cobranca_config?.beneficiario_complemento_endereco || '',
});

const getBeneficiarioDadosDaAdministradora = ({
	dadosAdministradora,
	options: { beneficiario: optionsBeneficiario = optionsEmpty },
}) => ({
	beneficiario: optionsBeneficiario.objects['BCA'],
	beneficiario_nome: dadosAdministradora.razao_administradora,
	beneficiario_tipo_documento: dadosAdministradora?.tipo_administradora || '',
	beneficiario_documento: dadosAdministradora?.documento || '',
	beneficiario_cep: dadosAdministradora?.cep || '',
	beneficiario_tipo_logradouro: dadosAdministradora?.tipo_logradouro || null,
	beneficiario_endereco: dadosAdministradora?.endereco || '',
	beneficiario_numero: dadosAdministradora?.numero || '',
	beneficiario_bairro: dadosAdministradora?.bairro || '',
	beneficiario_cidade: dadosAdministradora?.cidade || '',
	beneficiario_uf: dadosAdministradora?.uf || null,
	beneficiario_complemento_endereco: dadosAdministradora?.complemento_endereco || '',
});

export const getBeneficiarioDados = ({ cobranca_config, condominioRecord, options, dadosAdministradora }) =>
	({
		[true]: getBeneficiarioDadosDoCondominio,
		[cobranca_config.beneficiario === 'BCD']: getBeneficiarioDadosDoCondominio,
		[cobranca_config.beneficiario === 'BCB']: getBeneficiarioDadosDaContaBancaria,
		[cobranca_config.beneficiario === 'BCA']: getBeneficiarioDadosDaAdministradora,
	}[true]({ cobranca_config, condominioRecord, options, dadosAdministradora }));

export const formataCampoComOption = ({ field, options, values }) => {
	const { choices = [], objects = {} } = options[field] || optionsEmpty;
	const value = values[field];
	return objects[value] ?? choices[0] ?? null;
};

export const formataCampoAceite = ({ field, options, values }) => {
	const { choices = [], objects = {} } = options[field] || optionsEmpty;
	const value = values[field] ? '1' : '0';
	return objects[value] ?? choices[0] ?? null;
};

export const formataCampoRadioButtonComOption = ({ field, options, values }) => {
	const { choices = [], objects = {} } = options[field] || optionsEmpty;
	const value = values[field];
	return (objects[value] ?? choices[0] ?? {}).id || null;
};

const formatarInitialValues = ({
	conta_condominio,
	cobranca_config,
	options: {
		agencia: optionsAgencia = optionsEmpty,
		id_inst_financeira: { objects: instituicoesObjs },
		...options
	},
	condominioRecord,
	dadosAdministradora,
}) => {
	const agenciaCodigo = conta_condominio.agencia_cv
		? `${conta_condominio.agencia}-${conta_condominio.agencia_cv}`
		: conta_condominio.agencia;

	const conta_corrente = conta_condominio.conta_corrente_cv
		? `${conta_condominio.conta_corrente}-${conta_condominio.conta_corrente_cv}`
		: conta_condominio.conta_corrente;

	const values = { ...conta_condominio, ...cobranca_config };

	if (cobranca_config.beneficiario === 'BCA') {
		cobranca_config.nosso_numero = dadosAdministradora.nosso_numero;
		cobranca_config.sequencial_arquivo = dadosAdministradora.sequencial_arquivo;
	}

	return {
		// Tab Informações
		...conta_condominio,
		id_inst_financeira: instituicoesObjs[conta_condominio.id_inst_financeira],

		// Tab Emissão de Boletos
		tipo_integracao: formataCampoComOption({
			field: 'tipo_integracao',
			options,
			values,
		}),
		...getBeneficiarioDados({ cobranca_config, condominioRecord, options, dadosAdministradora }),

		// Tab Conta
		agencia: optionsAgencia.objects[agenciaCodigo] ?? null,
		conta_corrente: conta_corrente,
		carteira: formataCampoComOption({
			field: 'carteira',
			options,
			values,
		}),
		especie_titulo: formataCampoComOption({
			field: 'especie_titulo',
			options,
			values,
		}),
		aceite: formataCampoAceite({
			field: 'aceite',
			options,
			values,
		}),
		moeda: formataCampoComOption({
			field: 'moeda',
			options,
			values,
		}),
		conta_cedente: cobranca_config.conta_cedente,
		emissao_boleto: formataCampoComOption({
			field: 'emissao_boleto',
			options,
			values,
		}),
		distribuicao_boleto: formataCampoComOption({
			field: 'distribuicao_boleto',
			options,
			values,
		}),
		nosso_numero: cobranca_config.nosso_numero || 0,
		sequencial_arquivo: cobranca_config.sequencial_arquivo || 0,

		local_pagamento: cobranca_config.local_pagamento || '',

		// Tab de Instruções de Cobrança Remessa
		multa_tipo: formataCampoComOption({
			field: 'multa_tipo',
			options,
			values,
		}),
		multa_valor: cobranca_config.multa_valor,
		multa_dias: cobranca_config.multa_dias,

		juros_tipo: formataCampoComOption({
			field: 'juros_tipo',
			options,
			values,
		}),
		juros_valor: cobranca_config.juros_valor,
		juros_dias: cobranca_config.juros_dias,

		desconto_tipo: formataCampoComOption({
			field: 'desconto_tipo',
			options,
			values,
		}),
		desconto_valor: cobranca_config.desconto_valor,
		desconto_dias: cobranca_config.desconto_dias,

		limite_pagamento_tipo: formataCampoComOption({
			field: 'limite_pagamento_tipo',
			options,
			values,
		}),
		limite_pagamento_dias: cobranca_config.limite_pagamento_dias,

		protesto_tipo: formataCampoComOption({
			field: 'protesto_tipo',
			options,
			values,
		}),
		protesto_dias: cobranca_config.protesto_dias,

		sacador_avalista: formataCampoRadioButtonComOption({
			field: 'sacador_avalista',
			options,
			values,
		}),

		instrucao_1: (options.instrucao_1 || optionsEmpty).objects[values.instrucao_1] ?? null,
		instrucao_2: (options.instrucao_2 || optionsEmpty).objects[values.instrucao_2] ?? null,
		instrucao_dias: cobranca_config.instrucao_dias ?? null,

		// Tab de Instruções de Cobrança Boleto
		linha_1: cobranca_config.linha_1 || '',
		linha_2: cobranca_config.linha_2 || '',
		linha_3: cobranca_config.linha_3 || '',
		linha_4: cobranca_config.linha_4 || '',
		linha_5: cobranca_config.linha_5 || '',
	};
};

export default formatarInitialValues;
