import React from 'react';

import { Box } from '@material-ui/core';

import {
	ModalCadastrarDadosIntegracaoAPI as ModalCadastrarDadosIntegracaoAPIBase,
	ClientIDField,
	ClientSecretField,
	SenhaChaveAPIField,
} from '../../../../../commons/CadastrarDadosIntegracaoAPIField';

const ModalBody = () => (
	<Box display='flex' flexDirection='column' gridRowGap='1rem'>
		<Box display='grid' gridTemplateColumns='repeat(2, 50%)' gridColumnGap='0.5rem'>
			<ClientIDField label='Conta Corrente + Código Cooperativa' source='client_id' />
			<ClientSecretField label='Token' source='client_secret' inputProps={{ maxLength: 36 }} />
		</Box>
		<Box>
			<SenhaChaveAPIField label='Código de Acesso' inputProps={{ maxLength: 64 }} />
		</Box>
	</Box>
);

const ModalCadastrarDadosIntegracaoAPI = () => <ModalCadastrarDadosIntegracaoAPIBase Component={ModalBody} />;

export default ModalCadastrarDadosIntegracaoAPI;
