import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';

const SelectAmbienteReserva = () => {
	const {
		consulta: { ambienteSelecionado, ambientes, condominioSelecionado },
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);

	const dp = useDataProvider();
	const notify = useNotify();

	const handleChangeAmbiente = React.useCallback(
		(e, value) => {
			setConsulta((_C) => ({
				..._C,
				ambienteSelecionado: value,
				unidadesSelecionadas: [],
				reservasNoMes: {},
			}));
		},
		[setConsulta]
	);

	const updateAmbientes = () => {
		if (!condominioSelecionado)
			return setConsulta((_C) => ({
				..._C,
				ambientes: [],
				ambienteSelecionado: null,
				unidadesSelecionadas: [],
				reservasNoMes: {},
			}));

		dp.getSimple('ambiente_reserva', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: {
				id_condominio: condominioSelecionado.id,
			},
		})
			.then(({ data: { results = [] } = {} }) =>
				results?.length ? results : Promise.reject('Sem ambientes cadastrados.')
			)
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				notify('Sem ambientes cadastrados.', 'warning');
				return [];
			})
			.then((ambientesColetados) => {
				setConsulta((_C) => ({
					..._C,
					ambientes: ambientesColetados,
					ambienteSelecionado: ambientesColetados[0] ?? null,
					unidadesSelecionadas: [],
					reservasNoMes: {},
				}));
			});
	};

	React.useEffect(updateAmbientes, [condominioSelecionado]);

	if (!condominioSelecionado) return <></>;

	return (
		<Autocomplete
			id='select-ambiente-reserva'
			itemID='select-ambiente-reserva-item'
			value={ambienteSelecionado}
			options={ambientes}
			onChange={handleChangeAmbiente}
			loading={!ambientes.length}
			disabled={!ambientes.length}
			title={'Selecionar Ambiente'}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Ambiente' />}
			renderOption={(option) => option.nome}
			getOptionLabel={(option) => option.nome}
			noOptionsText='Nenhum resultado encontrado'
			clearText='Limpar'
			fullWidth
			size='small'
		/>
	);
};

export default SelectAmbienteReserva;
