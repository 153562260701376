import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import EditaConfiguracaoLeituraContext from '../modalEditaConfiguracaoLeitura/componentes/EditaConfiguracaoLeituraContext';

const LeituraField: React.FC = () => {
	const { dadosConfig } = useContext(EditaConfiguracaoLeituraContext);

	return (
		<TextField
			disabled
			label='Leitura utilizada para o cálculo'
			margin='dense'
			style={{ width: '76%' }}
			value={dadosConfig.leituras_para_calculo[0].nome}
		/>
	);
};

export default LeituraField;
