import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as Icon } from '../../layout/svg/AreaClienteIcon.svg';

const AreaClienteIcon = () => (
	<SvgIcon>
		<Icon />
	</SvgIcon>
);

export default AreaClienteIcon;
