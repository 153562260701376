import React, { useCallback, useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import ListConfirmacaoArrecadacoesRecorrentes from './ListConfirmacaoArrecadacoesRecorrentes';
import ModalFeedbackArrecadacoesRecorrentes from './ModalFeedbackArrecadacoesRecorrentes';

const separarArrecadacoes = (arrecadacoes) => {
	return Object.values(arrecadacoes).reduce(
		(
			{ arrecadacoesSimples, arrecadacoesFundoPercentualPrimeiroNivel, arrecadacoesFundoPercentualSegundoNivel },
			arrecadacao
		) => {
			if (arrecadacao.tipo_lancamento !== 'PRC') {
				arrecadacoesSimples.push(arrecadacao);
			} else if (!arrecadacao.is_arrecadacao_fundo_percentual_secundaria) {
				arrecadacoesFundoPercentualPrimeiroNivel.push(arrecadacao);
			} else if (arrecadacao.is_arrecadacao_fundo_percentual_secundaria) {
				arrecadacoesFundoPercentualSegundoNivel.push(arrecadacao);
			}
			return {
				arrecadacoesSimples,
				arrecadacoesFundoPercentualPrimeiroNivel,
				arrecadacoesFundoPercentualSegundoNivel,
			};
		},
		{
			arrecadacoesSimples: [],
			arrecadacoesFundoPercentualPrimeiroNivel: [],
			arrecadacoesFundoPercentualSegundoNivel: [],
		}
	);
};

const emitirRequestsRecalculoArrecadacoes = ({ arrecadacoes = [], dP }) => {
	return arrecadacoes.map((arrecadacao) =>
		dP.create('arrecadacao/criar_recorrente', { data: arrecadacao }).catch((e) => {
			return Promise.reject({ e, dados_arrecadacao: arrecadacao });
		})
	);
};

const emitirRequestRecalculoArrecadacoes = (arrecadacoes, dP) => {
	return emitirRequestsRecalculoArrecadacoes({ arrecadacoes, dP });
};

const ModalConfirmacaoArrecadacoesRecorrentes = ({ arrecadacoesRecorrentes, provisionar }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [processing, setProcessing] = useState(false);

	const handleSubmit = useCallback(() => {
		if (processing) return Promise.resolve();
		setProcessing(true);
		provisionar();
		const {
			arrecadacoesSimples,
			arrecadacoesFundoPercentualPrimeiroNivel,
			arrecadacoesFundoPercentualSegundoNivel,
		} = separarArrecadacoes(arrecadacoesRecorrentes);

		const gruposDeArrecadacoes = [
			arrecadacoesSimples,
			arrecadacoesFundoPercentualPrimeiroNivel,
			arrecadacoesFundoPercentualSegundoNivel,
		].filter((arrecadacoes) => Boolean(arrecadacoes.length));
		if (!gruposDeArrecadacoes.length) return setProcessing(false);

		gruposDeArrecadacoes
			.reduce(async (correnteDePromises, conjuntoArrecadacoes) => {
				const responses = await correnteDePromises;
				const novasResponses = await Promise.allSettled(
					emitirRequestRecalculoArrecadacoes(conjuntoArrecadacoes, dataProvider)
				);
				return Promise.resolve([...responses, ...novasResponses]);
			}, Promise.resolve([]))
			.then((responses) => {
				const RejectedLoggoutResponse = responses.find(
					(r) => r?.status === 'rejected' && [401, 403].includes(r.reason?.e?.response?.status)
				);
				if (RejectedLoggoutResponse) return Promise.reject(RejectedLoggoutResponse.reason?.e);
				const fulfilledResponses = responses.filter((r) => r?.status === 'fulfilled');
				const rejectedResponses = responses.filter((r) => r?.status === 'rejected');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalFeedbackArrecadacoesRecorrentes
							fulfilledResponses={fulfilledResponses}
							rejectedResponses={rejectedResponses}
						/>
					),
				}));
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setProcessing(false);
				notify('Erro inesperado, tente recarregar a página', 'warning');
				setModalValue((v) => ({ ...v, open: false }));
			});
	}, [setProcessing, provisionar, arrecadacoesRecorrentes, dataProvider, notify, setModalValue]);

	return (
		<CustomDialogBodySizeEditable
			{...(processing
				? {
						form: {
							component: (
								<Box display='flex' justifyContent='center'>
									<CircularProgress />
								</Box>
							),
						},
						customActions: <></>,
						title: 'Carregando...',
				  }
				: {
						closeInSubmit: false,
						customActions: (
							<>
								<ButtonCancel
									onClick={() => {
										setModalValue((v) => ({ ...v, open: false }));
									}}
								/>
								<ButtonConfirm size='small' onClick={handleSubmit} variant='text'>
									Confirmar
								</ButtonConfirm>
							</>
						),
						form: {
							component: (
								<Box display='flex' alignItems='center' gridGap='20px'>
									<ListConfirmacaoArrecadacoesRecorrentes
										arrecadacoesRecorrentes={arrecadacoesRecorrentes}
									/>
								</Box>
							),
						},
						title: 'Arrecadações Recorrentes e Parceladas',
				  })}
		/>
	);
};

export default ModalConfirmacaoArrecadacoesRecorrentes;
