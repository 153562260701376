import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';

import { isMatch } from 'date-fns';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { BoletosContext } from '../../ContasReceberBoletos/BoletosContextProvider';
import { RemessasContext } from '../contextos';
import { useBaixarRemessas, useTratarResposta } from '../hooks';
import LoadingPageBoletos from './LoadingBoletos';

export const ButtonEmitirCobrancas = ({
	text = 'Gerar',
	erroSemRecebimentoTitle = 'Boletos só podem ser emitidos pela API do Gerencianet caso eles não estejam já vencidos',
	errorsValidacaoTitle = 'Inconsistências na Geração de Boletos',
}) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados } = useContext(RemessasContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { senha } = React.useContext(BoletosContext);

	const [loadingRequest, setLoadingRequest] = useState(false);
	const [valid, setValid] = useState(true);
	const parametrosPayloadExtras = React.useMemo(() => ({ pwd: senha }), [senha]);
	const tratarResposta = useTratarResposta({
		setModalValue,
		ButtonGerarRemessas: ButtonEmitirCobrancas,
		erroSemRecebimentoTitle,
		errorsValidacaoTitle,
	});
	const condominioSelecionadoId = condominioSelecionado?.id;

	const baixarRemessas = useBaixarRemessas({
		idsRegistrosSelecionados,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		onSuccessCallback: tratarResposta,
		parametrosPayloadExtras,
	});

	useEffect(() => {
		setValid(
			Boolean(
				idsRegistrosSelecionados &&
					idsRegistrosSelecionados.length &&
					id_identificacao_arrecadacao &&
					contaBDestinoId &&
					condominioSelecionadoId &&
					referencia &&
					isMatch(referencia, 'yyyy-MM-dd') &&
					senha
			)
		);
	}, [
		setValid,
		idsRegistrosSelecionados,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		referencia,
		senha,
	]);

	const handleClick = useCallback(async () => {
		setLoadingRequest(true);
		await baixarRemessas();
		setLoadingRequest(false);
	}, [setLoadingRequest, baixarRemessas]);

	return (
		<ButtonConfirm onClick={handleClick} disabled={loadingRequest || !valid}>
			{text}
		</ButtonConfirm>
	);
};

export const ButtonGerarRemessas = ({ text = 'Gerar' }) => {
	const notify = useNotify();
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados } = useContext(RemessasContext);
	const {
		boletoConfigIsValid,
		opcoesImpressao,
		datasPrestacao,
		dataEnvioBackendDevedores,
		modeloDemonstrativo,
		comunicado,
		datasDemonstrativoMensal,
		dateRange,
		comunicadoId,
	} = useContext(BoletosContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [loadingRequest, setLoadingRequest] = useState(false);
	const [valid, setValid] = useState(true);
	const modeloDemonstrativoId = modeloDemonstrativo;
	const parametrosPayloadExtras = React.useMemo(
		() => ({
			...opcoesImpressao,
			...datasPrestacao,
			...dataEnvioBackendDevedores,
			...datasDemonstrativoMensal,
			modelo_demonstrativo: modeloDemonstrativoId,
			comunicado_text: comunicado,
			comunicado_id: comunicadoId,
		}),
		[
			modeloDemonstrativoId,
			opcoesImpressao,
			datasPrestacao,
			dataEnvioBackendDevedores,
			comunicado,
			datasDemonstrativoMensal,
			comunicadoId,
		]
	);
	const tratarResposta = useTratarResposta({
		setModalValue,
		ButtonGerarRemessas: ButtonGerarRemessas,
	});
	const condominioSelecionadoId = condominioSelecionado?.id;

	const baixarRemessas = useBaixarRemessas({
		idsRegistrosSelecionados,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		onSuccessCallback: tratarResposta,
		parametrosPayloadExtras,
	});

	useEffect(() => {
		setValid(
			idsRegistrosSelecionados &&
				idsRegistrosSelecionados.length &&
				id_identificacao_arrecadacao &&
				contaBDestinoId &&
				condominioSelecionadoId &&
				referencia &&
				isMatch(referencia, 'yyyy-MM-dd') &&
				boletoConfigIsValid
		);
	}, [
		setValid,
		idsRegistrosSelecionados,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		referencia,
		boletoConfigIsValid,
	]);

	const handleClick = useCallback(async () => {
		setLoadingRequest(true);
		await baixarRemessas();
		setLoadingRequest(false);
	}, [setLoadingRequest, baixarRemessas]);

	const handleVerifyGeraRemessa = () => {
		if (comunicado && !opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou um Comunicado mas não selecionou Apresentar Comunicado', 'warning');
			return;
		}
		if (!comunicado && opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou Apresentar Comunicado mas não selecionou um Comunicado', 'warning');
			return;
		}
		if (modeloDemonstrativo?.id == 'DM' || modeloDemonstrativo?.id == 'AM') {
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);
			const diffInMonths =
				(endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
			if (diffInMonths > 2) {
				notify('Você não pode gerar o Demonstrativo Mensal com um período maior que 3 meses', 'warning');
				return;
			}
		}
		handleClick();
	};
	return (
		<>
			<ButtonConfirmV2 onClick={handleVerifyGeraRemessa} disabled={loadingRequest || !valid}>
				{text}
			</ButtonConfirmV2>
			<LoadingPageBoletos loading={loadingRequest} />
		</>
	);
};

export default ButtonGerarRemessas;
