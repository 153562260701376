import React, { useState, useContext, useEffect } from 'react';

import { Title, useGetList } from 'react-admin';
import { Paper, Box, Typography, TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { BalanceteDivisor } from './boxDivisores/BalanceteDivisor';
import CertidaoNegativaDebitosDivisor from './boxDivisores/CertidaoNegativaDebitosDivisor';
import CorrecaoArrecadacoesAtrasadasDivisor from './boxDivisores/CorrecaoArrecadacoesAtrasadasDivisor';
import { DemonstrativoArrecadacaoDivisor } from './boxDivisores/DemonstrativoArrecadacaoDivisor';
import { DemonstrativoLeituraDivisor } from './boxDivisores/DemonstrativoLeituraDivisor';
import DemonstrativoArrecadacaoSimplificadoDivisor from './boxDivisores/DemonstrativoArrecadacaoSimplificadoDivisor';
import FluxoMovimentacoesBancariasDivisor from './boxDivisores/FluxoMovimentacoesBancariasDivisor';
import { DemonstrativoMensalDivisor } from './boxDivisores/DemonstrativoMensalDivisor';
import PlanilhaDeConsumoDivisor from './boxDivisores/PlanilhaDeConsumoDivisor';
import PrestacaoDeContasDivisor from './boxDivisores/PrestacaoDeContasDivisor';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { CustomDialog, ModalContextProvider } from '../../common/ModalContext';
import RazaoLancamentoDivisor from './boxDivisores/RazaoLancamentoDivisor';
import ContasAPagarDivisor from './boxDivisores/ContasAPagarDivisor';
import ListaAssinaturasDivisor from './boxDivisores/ListaAssinaturasDivisor';
import CoeficienteDaUnidadeDivisor from './boxDivisores/CoeficienteDaUnidadeDivisor.js';
import DetalhesUnidadesDivisor from './boxDivisores/DetalhesUnidadesDivisor';
import DemonstrativoContasReceber from './boxDivisores/DemonstrativoContasReceber';
import DemonstrativoArrecadacaoDetalhadoDivisor from './boxDivisores/DemonstrativoArrecadacaoDetalhado';
import { PlanilhaArrecadacaoDivisor } from './boxDivisores/PlanilhaArrecadacaoDivisor';
import { RecebimentosEDevedoresDivisor } from './boxDivisores/RecebimentosEDevedoresDivisor';
import PrevisoesOrcamentariasDivisor from './boxDivisores/PrevisoesOrcamentariasDivisor';
import { CheckboxRelatorioProvider } from './checkbox/CheckboxRelatorioContextProvider';
import FornecedorDivisor from './boxDivisores/FornecedorDivisor';
import GaragensDivisor from './boxDivisores/GaragensDivisor';

const optionsRelatorios = [
	{ id: 'B', name: 'Balancete', action: 'balancete' },
	{ id: 'DCR', name: 'Demonstrativo de Contas a Receber', action: 'demonstrativo_contas_receber' },
	{
		id: 'CND',
		name: 'Certidão Negativa de Débitos',
		action: 'certidao_negativa_debitos',
	},
	{
		id: 'CAA',
		name: 'Correção de Arrecadações Atrasadas',
		action: 'correcao_arrecadacoes_atrasadas',
	},
	{
		id: 'DA',
		name: 'Demonstrativo de Arrecadação',
		action: 'demonstrativo_arrecadacao',
	},
	{
		id: 'DAS',
		name: 'Demonstrativo de Arrecadação Simplificado',
		action: 'demonstrativo_arrecadacao_simplificado',
	},
	{
		id: 'DAD',
		name: 'Demonstrativo de Arrecadação Detalhado',
		action: 'demonstrativo_arrecadacao_detalhado',
	},
	{
		id: 'DL',
		name: 'Demonstrativo de Leituras',
		action: 'demonstrativo_leituras',
	},
	{ id: 'DM', name: 'Demonstrativo Mensal', action: 'demonstrativo_mensal' },
	{
		id: 'FMB',
		name: 'Fluxo de Movimentações Bancárias',
		action: 'fluxo_movimentacoes_bancarias',
	},
	{
		id: 'PA',
		name: 'Planilha de Arrecadação',
		action: 'planilha_arrecadacao',
	},
	{ id: 'PC', name: 'Prestação de Contas', action: 'prestacao_de_contas' },
	{
		id: 'RD',
		name: 'Recebimentos e Devedores',
		action: 'recebimentos_e_devedores',
	},
	{
		id: 'PLC',
		name: 'Planilha de Consumo',
		action: 'planilha_consumo',
	},
	{
		id: 'RL',
		name: 'Razão de Lançamentos',
		action: 'razao_lancamentos',
	},
	{
		id: 'CAP',
		name: 'Contas a Pagar',
		action: 'contas_a_pagar',
	},
	{
		id: 'LA',
		name: 'Lista de Assinaturas',
		action: 'lista_de_assinaturas',
	},
	{
		id: 'CU',
		name: 'Coeficiente da Unidade',
		action: 'coeficiente_da_unidade',
	},
	{
		id: 'DU',
		name: 'Detalhes das Unidades',
		action: 'detalhes_unidades',
	},
	{
		id: 'PO',
		name: 'Previsões Orçamentárias',
		action: 'previsoes_orcamentarias',
	},
	{
		id: 'FO',
		name: 'Fornecedores',
		action: 'fornecedores',
	},
	{
		id: 'GA',
		name: 'Garagens',
		action: 'garagens',
	},
].sort((a, b) => a.name.localeCompare(b.name));

const RelatoriosDivisorSwitch = (props) => {
	switch (props.tipoRelatorio?.id) {
		case 'B':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<BalanceteDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'DCR':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<DemonstrativoContasReceber {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'CND':
			return (
				<ModalContextProvider>
					<CertidaoNegativaDebitosDivisor {...props} />
					<CustomDialog />
				</ModalContextProvider>
			);
		case 'CAA':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<CorrecaoArrecadacoesAtrasadasDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'DA':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<DemonstrativoArrecadacaoDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'DAS':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<DemonstrativoArrecadacaoSimplificadoDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'DAD':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<DemonstrativoArrecadacaoDetalhadoDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'DL':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<DemonstrativoLeituraDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'DM':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<DemonstrativoMensalDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'FMB':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<FluxoMovimentacoesBancariasDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'PA':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<PlanilhaArrecadacaoDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'PC':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<PrestacaoDeContasDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'RD':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<RecebimentosEDevedoresDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'PLC':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<PlanilhaDeConsumoDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'RL':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<RazaoLancamentoDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'CAP':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<ContasAPagarDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'LA':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<ListaAssinaturasDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'DU':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<DetalhesUnidadesDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'CU':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<CoeficienteDaUnidadeDivisor {...props} />
				</CheckboxRelatorioProvider>
			);
		case 'PO':
			return <PrevisoesOrcamentariasDivisor {...props} />;
		case 'FO':
			return <FornecedorDivisor {...props} />;
		case 'GA':
			return (
				<CheckboxRelatorioProvider tipoRelatorio={props.tipoRelatorio?.action}>
					<GaragensDivisor {...props} />
				</CheckboxRelatorioProvider>
			);

		default:
			return <></>;
	}
};

const FormularioRelatorios = (props) => {
	const [tipoRelatorio, setTipoRelatorio] = useState();
	const { condominioSelecionado, condominios, setCondominioPorId } = useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const [condominio, setStateCondominio] = useState(null);
	const [optionsCondominio, setOptionsCondominio] = useState([]);
	const { data: condominiosData } = useGetList(
		'condominios',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ situacao: 'A' }
	);
	useEffect(
		() => setOptionsCondominio(Object.values(condominiosData).filter((v) => Boolean(v?.id))),
		[setOptionsCondominio, condominiosData]
	);
	useEffect(() => {
		if (condominioSelecionadoId && optionsCondominio.length) {
			const condId =
				typeof condominioSelecionadoId === 'string'
					? parseInt(condominioSelecionadoId)
					: condominioSelecionadoId;
			setStateCondominio(optionsCondominio.find((x) => x.id === condId));
		} else if (optionsCondominio.length) {
			const primeiroCondo = optionsCondominio[0];
			setCondominioPorId(primeiroCondo.id);
			setStateCondominio(primeiroCondo);
		}
	}, [condominioSelecionadoId, optionsCondominio, setCondominioPorId, condominios]);
	return (
		<div>
			<Title title='Relatórios' />
			{optionsCondominio?.length ? (
				<Box display='flex' m='1em'>
					<Box flex={1}>
						<Paper style={{ padding: '1em' }}>
							<Box display='flex' alignItems='center'>
								<Typography variant='h6'>Relatórios</Typography>
							</Box>
							<hr
								style={{
									marginRight: '1em',
									borderTop: '#d3d3d3',
								}}
							/>
							<Box m='1em' />
							<Box display='flex' width='100%' mb='1em'>
								<Box flex={1} mr='1em'>
									{
										<Autocomplete
											disabled={!optionsCondominio.length}
											options={optionsCondominio}
											getOptionLabel={(o) => o.nome}
											onChange={(_, newValue) => {
												setCondominioPorId(newValue?.id);
												setStateCondominio(newValue);
											}}
											renderInput={(params) => (
												<TextField {...params} label='Condomínio' variant='outlined' />
											)}
											value={condominio}
											disableClearable
											autoHighlight
											noOptionsText='Nenhum condomínio disponível'
											size='small'
											fullWidth
										/>
									}
								</Box>
								<Box flex={1}>
									<Autocomplete
										options={optionsRelatorios}
										getOptionLabel={(o) => o.name}
										onChange={(_, newValue) => setTipoRelatorio(newValue)}
										renderInput={(params) => (
											<TextField {...params} label='Tipo de Relatório' variant='outlined' />
										)}
										disableClearable
										autoHighlight
										noOptionsText='Nenhum tipo disponível'
										size='small'
										fullWidth
									/>
								</Box>
							</Box>
						</Paper>
						<Box m='1em'></Box>
						{tipoRelatorio?.id && condominio?.id ? (
							<Paper style={{ padding: '1em' }}>
								<RelatoriosDivisorSwitch
									tipoRelatorio={tipoRelatorio}
									idCondominio={condominio.id}
									{...props}
								/>
							</Paper>
						) : (
							[]
						)}
					</Box>
				</Box>
			) : (
				<div
					style={{
						width: '100%',
						height: '600px',
						display: 'grid',
						placeItems: 'center',
					}}
				>
					<div style={{ display: 'grid', placeItems: 'center' }}>
						<CircularProgress size={72.5} />
						<Typography style={{ marginTop: '25px' }}>Carregando os relatórios...</Typography>
					</div>
				</div>
			)}
		</div>
	);
};

export const RelatoriosList = () => {
	return <FormularioRelatorios />;
};
