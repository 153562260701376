import React from 'react';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const BoxModeloImpressaoModeloCarta = ({ modeloImpressaoCarta, setModeloImpressaoCarta }) => (
	<Box display='flex'>
		<FormControlLabel
			control={
				<Checkbox
					checked={modeloImpressaoCarta}
					onChange={(e) => setModeloImpressaoCarta(e.target.checked ? 1 : 0)}
					name='imprimir_modelo_carta'
				/>
			}
			label='Imprimir em modelo carta'
		/>
	</Box>
);

export default BoxModeloImpressaoModeloCarta;
