import React, { useContext } from 'react';

import { useDataProvider, useListContext } from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';

import ListActionsContext from './ListActionsContext';
import ModalEditaConfiguracaoLeitura from '../../modais/modalEditaConfiguracaoLeitura/ModalEditaConfiguracaoLeitura';
import { ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { TooltipIconButton } from '../../../../common/TooltipIconButton';

const EditarConfiguracaoButton = () => {
	const { filterValues } = useListContext();
	const dataProvider = useDataProvider();
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	const { dataArray } = useContext(ListActionsContext);
	return (
		<TooltipIconButton
			color='primary'
			disabled={!(filterValues.leitura_id && filterValues.referencia) || !(dataArray[0] && dataArray[0].id)}
			onClick={() => {
				dataProvider
					.getList('config-leitura', {
						pagination: {
							perPage: 1,
							page: 1,
						},
						sort: { order: 'DESC', field: 'referencia' },
						filter: {
							id_leitura: filterValues.leitura_id,
							referencia_config: filterValues.referencia.split('T')[0],
						},
					})
					.then((response) => {
						const data = response?.data;
						if (data.length && data[0].id) {
							setMinWidthModel('55vw');
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <ModalEditaConfiguracaoLeitura config_leitura={data[0]} />,
							}));
						}
					});
			}}
			size='small'
			title='Editar Configuração'
		>
			<SettingsIcon />
		</TooltipIconButton>
	);
};

export default EditarConfiguracaoButton;
