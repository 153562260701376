import React, { useContext } from 'react';

import { ButtonCancel } from '../../../../../common/buttons/ButtonCancel';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';
import { ButtonConfirm } from '../../../../../common/buttons/ButtonConfirm';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import ProvisionamentoContext from './ProvisionamentoContext';

const ModalFeedbackProvisionamentoRecalculo = () => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	const {
		responsesRecalculosSucedidos: fulfilledResponses,
		responsesRecalculosFalhados: rejectedResponses,
		closeModal,
		handleProvisionar,
		realizandoProvisionamento,
	} = useContext(ProvisionamentoContext);

	return (
		<CustomDialogBodySizeEditable
			customActions={
				<>
					<ButtonCancel disabled={realizandoProvisionamento} onClick={closeModal}>
						Fechar
					</ButtonCancel>
					<ButtonConfirm
						loading={realizandoProvisionamento}
						disabled={realizandoProvisionamento}
						onClick={handleProvisionar}
					>
						Provisionar
					</ButtonConfirm>
				</>
			}
			form={{
				component: (
					<>
						{!!(fulfilledResponses || []).length && (
							<Typography>
								{(fulfilledResponses || []).length > 1
									? `${(fulfilledResponses || []).length} arrecadações recalculadas com sucesso!`
									: (rejectedResponses || []).length
									? '1 arrecadação recalculada com sucesso!'
									: 'Arrecadação recalculada com sucesso!'}
							</Typography>
						)}
						{(rejectedResponses || []).map(({ reason }) => (
							<Typography style={{ color: syndikosRed.main }}>
								{`${reason?.dados_arrecadacao?.complemento || ''}: ${
									Object.values(reason?.e?.response?.data || {})[0] || ''
								}`}
							</Typography>
						))}
					</>
				),
			}}
			title='Recálculo finalizado'
		/>
	);
};

export default ModalFeedbackProvisionamentoRecalculo;
