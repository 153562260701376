import React from 'react';

import { TextField } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { format } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';

export const SelectOneUser = ({ setValue, value, style = {}, unidadeSelecionada }) => {
	const {
		consulta: { diaSelecionado },
	} = React.useContext(ConsultaDeReservasContext);

	const [pessoas, setPessoas] = React.useState([]);

	const dp = useDataProvider();
	const notify = useNotify();

	function fetchPessoasUnidade() {
		if (!unidadeSelecionada) return;
		dp.getSimple('pessoa_unidade', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'tipo', order: 'ASC' },
			filter: {
				responsavel_de_data: format(diaSelecionado, 'yyyy-MM-dd'),
				id_unidade: unidadeSelecionada.id,
				situacao_pessoa_ativa: true,
				para_reservas_ambientes: true,
			},
		})
			.then(({ data: { results = [] } = {} }) => {
				if (!results.length) notify('Sem pessoas cadastradas', 'warning');
				setPessoas(results);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setPessoas([]);
				notify('Sem pessoas cadastradas.', 'warning');
			});
	}

	React.useEffect(fetchPessoasUnidade, [unidadeSelecionada]);

	if (!unidadeSelecionada) return <></>;
	return (
		<Autocomplete
			id='select-one-user-criar-reserva'
			itemID='select-one-user-criar-reserva-item'
			aria-required
			value={value}
			options={pessoas}
			onChange={(_, value) => setValue(value)}
			loading={!pessoas.length}
			disabled={!pessoas.length}
			title={'Selecionar Pessoa'}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Pessoa' />}
			renderOption={(option) =>
				`${option.nome_pessoa} - ${option.tipo_pessoa === 'P' ? 'Proprietário' : 'Inquilino'}`
			}
			getOptionLabel={(option) =>
				`${option.nome_pessoa} - ${option.tipo_pessoa === 'P' ? 'Proprietário' : 'Inquilino'}`
			}
			noOptionsText='Nenhum resultado encontrado'
			clearText='Limpar'
			fullWidth
			size='small'
			style={style}
		/>
	);
};
