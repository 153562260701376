import React from 'react';
import { Tab } from '@material-ui/core';

const style = {
	minWidth: 0,
	padding: '6px 20px',
	zIndex: 5,
	fontSize: '9pt',
};

export const TabListComponentArrecadacao = ({ label, value, ...props }) => (
	<Tab {...props} wrapped={true} label={label} value={value} style={style} />
);

export default TabListComponentArrecadacao;
