import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useNotify } from 'react-admin';

import { LancamentoContaPagarContext } from '../LancamentoContaPagarContext';
import decimalAdjust from '../../../common/decimalAdjust';

const decimalAdjustRound = (value, roundDigitNumber = -2, type = 'round') =>
	decimalAdjust(type, value, roundDigitNumber);
const decimalAdjustFloor = (value, roundDigitNumber = -2) => decimalAdjustRound(value, roundDigitNumber, 'floor');

export const ListParcelasContext = createContext();

export const ListParcelasContextProvider = ({ children }) => {
	const { valorTotal, parcelas, setParcelas, erros, setErros, setQuantidadeParcelas } =
		useContext(LancamentoContaPagarContext);
	const notify = useNotify();
	const [inicializado, setInicializado] = useState(false);
	const UpdateValorParcelasByValorTotal = useCallback(() => {
		if (inicializado) {
			setErros((v) => ({
				...v,
				parcelas: 'Parcelas sendo recalculadas, por favor aguarde',
			}));
			let antigoTotalParcelas = decimalAdjustFloor(parcelas.reduce((total, p) => total + p.valor_pendente, 0));
			if (antigoTotalParcelas !== valorTotal) {
				setParcelas((parcelas) => {
					let sobrasTotal = decimalAdjustFloor(
						parcelas.reduce(
							(sobrasTotal, parcela) =>
								decimalAdjustFloor(sobrasTotal - valorTotal * (parcela.porcentagem / 100), -3),
							valorTotal
						)
					);
					const p = parcelas.map(
						!(sobrasTotal < 0)
							? (parcela) => {
									if (parcela.situacao !== 'P') return parcela;
									sobrasTotal = decimalAdjustFloor(sobrasTotal - 0.01, -3);
									return {
										...parcela,
										valor_pendente: decimalAdjustRound(
											valorTotal * (parcela.porcentagem / 100) + (sobrasTotal > 0 ? 0.01 : 0)
										),
									};
							  }
							: (parcela) => ({
									...parcela,
									valor_pendente:
										parcela.situacao !== 'P'
											? parcela.valor_pendente
											: valorTotal * (parcela.porcentagem / 100),
							  })
					);
					setErros((v) => {
						const { parcelas, ...rest } = v;
						return rest;
					});
					return p;
				});
			} else {
				setErros((v) => {
					const { parcelas, ...rest } = v;
					return rest;
				});
			}
		}
	}, [inicializado, setErros, parcelas, valorTotal, setParcelas]);

	const recalcularParcelas = useCallback(
		(parcelas) => {
			const parcelasPrevistas = parcelas.filter((p) => p.situacao === 'P' && !p.editada);
			const valorTotalNaoAmarrado = decimalAdjustRound(
				decimalAdjustFloor(
					parcelas.reduce((sobrasTotal, parcela) => {
						if (parcela.situacao === 'P' && !parcela.editada) return sobrasTotal;
						return sobrasTotal - parcela.valor_pendente;
					}, valorTotal),
					-3
				)
			);
			const valorPorParcela = decimalAdjustFloor(
				decimalAdjustFloor(valorTotalNaoAmarrado / parcelasPrevistas.length, -3)
			);
			let sobrasTotal = decimalAdjustFloor(
				parcelasPrevistas.reduce((sobrasTotal) => sobrasTotal - valorPorParcela, valorTotalNaoAmarrado)
			);
			const updatedParcelas = parcelas
				.sort((p) => (p.editada ? 1 : -1))
				.map((parcela) => {
					if (parcela.situacao !== 'P') return parcela;
					sobrasTotal = decimalAdjustRound(sobrasTotal - 0.01);
					const valorPendente = decimalAdjustRound(
						(parcela.editada ? parcela.valor_pendente : valorPorParcela) + (sobrasTotal < 0 ? 0 : 0.01)
					);
					return {
						...parcela,
						porcentagem: decimalAdjustRound((valorPendente / valorTotal) * 100),
						valor_pendente: valorPendente,
					};
				})
				.sort((pA, pP) => pA.numero_parcela - pP.numero_parcela)
				.map((p, i) => ({
					...p,
					numero_parcela: i + 1,
				}));
			const valorTotalParcelas = updatedParcelas.reduce((total, p) => total + p.valor_pendente, 0);
			const diff = valorTotal - valorTotalParcelas;
			if (diff !== 0) {
				const lastParcelaIndex = updatedParcelas.length - 1;
				updatedParcelas[lastParcelaIndex].valor_pendente = decimalAdjustRound(
					updatedParcelas[lastParcelaIndex].valor_pendente + diff
				);
			}

			return updatedParcelas;
		},
		[valorTotal]
	);

	const UpdateValorParcelasByExclusao = useCallback(
		(numero_parcela, setModalValue) => {
			if (parcelas.filter((p) => p.situacao === 'P').length > 1) {
				setErros((e) => ({
					...e,
					parcelas: 'Parcelas sendo recalculadas, por favor aguarde',
				}));
				const filtredParcelas = parcelas.filter((p) => p.numero_parcela !== numero_parcela);
				let atualTotalParcelas = decimalAdjustRound(
					filtredParcelas.reduce((total, p) => total + p.valor_pendente, 0)
				);
				setParcelas(atualTotalParcelas !== valorTotal ? recalcularParcelas(filtredParcelas) : filtredParcelas);
				setQuantidadeParcelas((q) => q - 1);
				setErros((e) => {
					const { parcelas, ...rest } = e;
					return rest;
				});
				setModalValue((v) => ({
					...v,
					open: false,
				}));
				notify('Parcela excluída da tabela');
			}
		},
		[parcelas, valorTotal, recalcularParcelas, setParcelas, setErros, notify, setQuantidadeParcelas]
	);

	useEffect(UpdateValorParcelasByValorTotal, [valorTotal]);
	useEffect(() => {
		setInicializado(true);
	}, [setInicializado]);
	const validate = useCallback(() => {
		if (!parcelas || !parcelas.length) {
			setErros((e) => ({
				...e,
				lista_parcelas: 'A conta a pagar deve ter ao menos uma parcela',
			}));
		} else if (parcelas.find((p) => !p.valor_pendente)) {
			setErros((e) => ({
				...e,
				lista_parcelas: 'Parcelas com valor pendente zerado não são válidas',
			}));
		} else if (erros?.lista_parcelas) {
			setErros((e) => {
				const { lista_parcelas, ...rest } = e;
				return rest;
			});
		}
	}, [parcelas, setErros, erros]);
	useEffect(validate, [parcelas]);

	const ListParcelasProviderValue = useMemo(
		() => ({
			recalcularParcelas,
			UpdateValorParcelasByExclusao,
		}),
		[recalcularParcelas, UpdateValorParcelasByExclusao]
	);

	return <ListParcelasContext.Provider value={ListParcelasProviderValue}>{children}</ListParcelasContext.Provider>;
};
