import { CondominiosList } from './list';
import { CondominioEdit } from './edit';
import { CondominioCreate } from './create';

const resource = {
	list: CondominiosList,
	edit: CondominioEdit,
	create: CondominioCreate,
};

export default resource;
