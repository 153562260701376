import React from 'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { TSelectAutoComplete } from './types';

const SelectAutoComplete: React.FC<TSelectAutoComplete> = ({
	options,
	value,
	setValue,
	label,
	noOptionsText,
	getOptionLabel,
}) => {
	return (
		<Autocomplete
			disabled={!options.length}
			options={options}
			getOptionLabel={getOptionLabel}
			onChange={(_, newValue) => {
				setValue(newValue);
			}}
			renderInput={(params) => <TextField {...params} label={label} variant='outlined' />}
			value={value}
			disableClearable
			autoHighlight
			noOptionsText={noOptionsText}
			size='small'
			fullWidth
		/>
	);
};

export default SelectAutoComplete;
