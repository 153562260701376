import React from 'react';

import { InstrucoesRemessaSemDiasFields } from '../../../../../commons/InstrucoesRemessaFields';

const emptyProps = {};

const MultaFields = ({ propsTipo = emptyProps, propsValor = emptyProps }) => (
	<InstrucoesRemessaSemDiasFields
		sourcePrincipal='multa'
		propsTipo={{
			source: 'multa_tipo',
			label: 'Multa',
			...propsTipo,
		}}
		propsValor={{
			source: 'multa_valor',
			...propsValor,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoMulta = ({
	sources: { sourceValor } = emptyProps,
	values,
	optionsIsencoesTipo,
}) => ({
	values: { [sourceValor]: values[sourceValor] },
	sources: { sourceValor },
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoMulta =
	({ sources: { sourceValor } = emptyProps, values, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			return;
		}

		const valorValue = values[sourceValor];
		if (tipoValue.id === 'PRC') {
			(!valorValue || valorValue > 10) && change(sourceValor, 10);
			return;
		}

		change(sourceValor, undefined);
	};

export default MultaFields;
