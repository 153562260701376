import memoize from '../../../../../../common/memoize';

import { validacoesFieldsTabInformacao, validateAgencia } from '../../../utils/validadoes';

import TabInformacao from '../../../tabs/TabInformacao';

import TabConta from '../../../tabs/TabConta';

import TabEmissaoBoletos from '../../../tabs/TabEmissaoBoletos';

import {
	getPropsOnChangeTipoPadraoMulta,
	createOnChangeTipoPadraoMulta,
	getPropsOnChangeTipoPadraoJuros,
	createOnChangeTipoPadraoJuros,
	getPropsOnChangeTipoPadraoDesconto,
	createOnChangeTipoPadraoDesconto,
	getPropsOnChangeTipoPadraoLimitePagamento,
	createOnChangeTipoPadraoLimitePagamento,
	getPropsOnChangeTipoPadraoProtesto,
	createOnChangeTipoPadraoProtesto,
} from '../../../tabs/TabInstrucoesCobrancaRemessa';

import TabInstrucoesCobrancaBoleto from '../../../tabs/TabInstrucoesCobrancaBoleto';

import getFormataPayloadValues, {
	formataPayloadValuesInstrucoesComTipoDias,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesProtesto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesNegativacao,
	getPadraoPayloadValuesInstrucoesRemessa,
	getFormatadorPayloadCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
} from './getFormataPayloadValues';

import formatarInitialValues from './formatarInitialValues';

import getValidate, {
	listaValidacoesPadroesQuandoEmitindoBoletos,
	mensagensErrosPadroes,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	getValidacoesLimitePagamento,
	getValidateQuandoEmitindoBoletos,
} from './getValidate';

const getExtras = ({
	props,
	instituicaoCodigo,
	initialDados: {
		opcoesDadosBancarios: { integracoes },
	},
}) => ({
	optionsIsencoes: integracoes[instituicaoCodigo].optionsIsencoes,
	...props,
});

// Propriedades dos componentes/campos usados no modal
export const defaultComponentesProps = {
	tipo_conta: {
		source: 'tipo_conta_bancaria',
		name: 'tipo_conta_bancaria',
		id: 'tipo_conta_bancaria',
		choices: [{ id: 'CC', text: 'Conta Corrente' }],
	},
	multa: {
		getPropsOnChangeTipo: getPropsOnChangeTipoPadraoMulta,
		createOnChangeTipo: createOnChangeTipoPadraoMulta,
	},
	juros: {
		getPropsOnChangeTipo: getPropsOnChangeTipoPadraoJuros,
		createOnChangeTipo: createOnChangeTipoPadraoJuros,
	},
	desconto: {
		getPropsOnChangeTipo: getPropsOnChangeTipoPadraoDesconto,
		createOnChangeTipo: createOnChangeTipoPadraoDesconto,
	},
	limite_pagamento: {
		getPropsOnChangeTipo: getPropsOnChangeTipoPadraoLimitePagamento,
		createOnChangeTipo: createOnChangeTipoPadraoLimitePagamento,
	},
	protesto: {
		getPropsOnChangeTipo: getPropsOnChangeTipoPadraoProtesto,
		createOnChangeTipo: createOnChangeTipoPadraoProtesto,
	},
};

export {
	getValidate,
	mensagensErrosPadroes,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	getValidacoesLimitePagamento,
	getValidateQuandoEmitindoBoletos,
};

export {
	getFormataPayloadValues,
	formataPayloadValuesInstrucoesComTipoDias,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesProtesto,
	formataPayloadValuesLimitePagamento,
	formataPayloadValuesNegativacao,
	getPadraoPayloadValuesInstrucoesRemessa,
	getFormatadorPayloadCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
};

// Objeto contendo a configuração padrão/default para
// o modal de edição de conta corrente
const ContaCorrenteProps = {
	instituicaoCodigo: 'default',
	getDisabledTabs: memoize(({ values: { emitir_boletos } }) => ({
		disabled0: false,
		disabled1: false,
		disabled2: false,
		disabled3: !emitir_boletos,
		disabled4: !emitir_boletos,
	})),
	tabContents: {
		TabInformacao,
		TabEmissaoBoletos,
		TabConta,
		TabInstrucoesCobrancaBoleto,
	},
	getFormataPayloadValues,
	getValidate,
	formatarInitialValues,
	validacoes: [
		...validacoesFieldsTabInformacao,
		validateAgencia,
		getValidateQuandoEmitindoBoletos(listaValidacoesPadroesQuandoEmitindoBoletos),
	],
	formatadoresPayloadValues: defaultFormatadoresPayloadValues,
	getExtras,
};

export default ContaCorrenteProps;
