import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	CreateButton,
	ExportButton,
	TextField,
	TopToolbar,
	List,
	Pagination,
	ReferenceField,
	useListContext,
	Button as RAButton,
} from 'react-admin';

import SwapVerticalCircleOutlinedIcon from '@material-ui/icons/SwapVerticalCircleOutlined';

import brExporter from '../../../utils/exporter';
import { situacaoOpcoes } from '../../../fieldControl/situacao';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { Padrao } from '../../common/filtros/Padrao';

import { Tabela, TabelaRowContext } from '../../common/Tabela';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';

import OpcoesImportacaoUnidadesGaragensModal from './modais/import/OpcoesImportacaoUnidadesGaragensModal';
import NumberFormat from 'react-number-format';

const opcoesResponsavel = [
	{ id: 'Inq', name: 'Inquilino' },
	{ id: 'Prop', name: 'Proprietário' },
	{ id: 'Amb', name: 'Ambos' },
];

const RespCobranca = React.memo(function RespCobranca_({ record }) {
	switch (record?.boleto_para) {
		case 'Inq':
			return <span>Inquilino</span>;
		case 'Prop':
			return <span>Proprietário</span>;
		case 'Amb':
			return <span>Ambos</span>;
		default:
			return <span></span>;
	}
});

const SituacaoField = React.memo(function SituacaoField_({ record, condominio }) {
	return condominio ? (
		record?.situacao_condominio === 'A' ? (
			<span>Ativo</span>
		) : (
			<span>Inativo</span>
		)
	) : record?.situacao === 'A' ? (
		<span>Ativo</span>
	) : (
		<span>Inativo</span>
	);
});

const RespField = ({ source, ...rest }) => {
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);

	const update = () => {
		if (data && data[rowRecord?.id]) {
			setRowRecord(data[rowRecord.id]);
		}
	};

	useEffect(update, [data]);

	return <TextField source={source} {...rest} />;
};

const CoefNumberField = ({ record, source, ...props }) => (
	<NumberFormat
		displayType='text'
		thousandSeparator='.'
		decimalSeparator=','
		isNumericString
		fixedDecimalScale={true}
		allowNegative={false}
		value={record?.[source]}
		id={record?.id}
	/>
);

const UnidadesTabela = (props) => {
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const {
		filterValues: { id_condominio, ...rest },
		setFilters,
	} = useListContext();
	const { setCondominioPorId, condominioSelecionado } = useContext(CondominiosContext);

	const initCondominio = () => {
		if (condominioSelecionado?.id && id_condominio !== condominioSelecionado?.id) {
			setFilters({ ...rest, id_condominio: condominioSelecionado?.id });
		}
	};
	useEffect(initCondominio, []);

	const changeCondominio = () => {
		if (id_condominio && id_condominio !== condominioSelecionado?.id) {
			setCondominioPorId(id_condominio);
		}
	};
	useEffect(changeCondominio, [id_condominio]);

	return (
		<div
			style={{
				overflowY: 'hidden',
				overflowX: 'scroll',
				width: 'auto',
				'max-width': `calc(100vw - ${sidebarOpen ? '320px' : '110px'})`,
			}}
		>
			<Tabela rowClick='edit' {...props} id='tabela-unidades'>
				<ReferenceField
					id='reference-field-condominio'
					source='id_condominio'
					reference='condominios'
					label='Condomínio'
					link={false}
					sortBy='nome_condominio'
					filtro={
						<Autocompletar
							refName='nome'
							refResource='condominios'
							mostrarInativos={false}
							id='filtro-nome-condominio'
						/>
					}
					minWidth='200px'
				>
					<TextField source='nome' />
				</ReferenceField>
				<ReferenceField
					id='reference-field-tipo-unidade'
					source='tipo'
					reference='tipo_unidade'
					link={false}
					filtro={<Autocompletar refResource='tipo_unidade' refName='descricao' />}
				>
					<TextField source='descricao' />
				</ReferenceField>
				<TextField source='nome_grupo' filtro={<Padrao />} id='reference-field-nome-grupo' />
				<TextField source='unidade' filtro={<Padrao />} id='reference-field-unidade' />
				<RespField source='proprietario' filtro={<Padrao />} id='reference-field-proprietario' />
				<RespField
					source='proprietario_email_cobranca'
					label='E-mail Proprietário'
					filtro={<Padrao />}
					id='reference-field-proprietario-email-cobranca'
				/>
				<RespField source='inquilino' filtro={<Padrao />} id='reference-field-inquilino' />
				<RespField
					source='inquilino_email_cobranca'
					label='E-mail Inquilino'
					filtro={<Padrao />}
					id='reference-field-inquilino-email-cobranca'
				/>
				<RespCobranca
					source='boleto_para'
					label='Cobrança'
					filtro={<Autocompletar opcoes={opcoesResponsavel} />}
					id='reference-field-boleto-para'
				/>
				<CoefNumberField source='coeficiente' filtro={<Padrao />} id='reference-field-coeficiente' />
				<SituacaoField
					source='situacao'
					filtro={<Autocompletar opcoes={situacaoOpcoes} />}
					id='reference-field-situacao'
				/>
				<SituacaoField
					source='situacao_condominio'
					label='Situação Condomínio'
					condominio
					filtro={<Autocompletar opcoes={situacaoOpcoes} />}
					id='reference-field-situacao-condominio'
				/>
			</Tabela>
		</div>
	);
};

const ImportacaoButton = (props) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const handleClick = (FormComponent) =>
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <FormComponent {...props} />,
		}));

	return (
		<RAButton
			label='Importar'
			id='importar_unidades_garagens'
			color='primary'
			onClick={() => handleClick(OpcoesImportacaoUnidadesGaragensModal)}
		>
			<SwapVerticalCircleOutlinedIcon />
		</RAButton>
	);
};

const ListActions = (props) => {
	const { className, basePath, total, resource, currentSort, filterValues, exporter } = props;
	return (
		<TopToolbar className={className}>
			<CreateButton basePath={basePath} />
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
			<ImportacaoButton {...props} />
		</TopToolbar>
	);
};

export const UnidadesList = (props) => {
	const { condominioSelecionado } = useContext(CondominiosContext);

	return (
		<ModalSizeEditableContextProvider
			customDialogProps={{
				disableBackdropClick: true,
				disableEscapeKeyDown: true,
				minWidth: '95vw',
			}}
		>
			<List
				{...props}
				actions={<ListActions />}
				exporter={brExporter}
				empty={false}
				bulkActionButtons={false}
				pagination={
					<Pagination
						rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
						labelRowsPerPage='Unidades por página'
					/>
				}
				filterDefaultValues={{
					id_condominio: condominioSelecionado?.id,
					situacao: 'A',
					situacao_condominio: 'A',
				}}
				sort={{ field: 'unidade', order: 'ASC' }}
			>
				<UnidadesTabela {...props} />
			</List>
		</ModalSizeEditableContextProvider>
	);
};
