import { TooltipIconButton } from 'components/common/TooltipIconButton';
import React, { useContext } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsModal from './SettingsModal';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

const ToolTipSettingsButton = () => {
	const { setModalValue } = useContext(ModalSizeEditableContext);

	return (
		<>
			<TooltipIconButton
				title='Configurar'
				color='primary'
				onClick={() => {
					setModalValue(() => ({ dialogBody: <SettingsModal />, open: true }));
				}}
			>
				<SettingsIcon />
			</TooltipIconButton>
		</>
	);
};

export default ToolTipSettingsButton;
