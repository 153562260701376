import React, { useState, useEffect } from 'react';
import { useGetList, useListContext } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';

export const Autocompletar = ({
	id,
	label,
	source,
	opcoes,
	refResource,
	refName,
	mostrarInativos,
	filtroOpcoes = {},
	variant = 'standard',
	...props
}) => {
	const [value, setValue] = useState('');
	const { setFilters, filterValues } = useListContext();
	const [options, setOptions] = useState([]);
	const [lengthOption, setLengthOption] = useState(0);
	const situacao = mostrarInativos ? {} : { situacao: 'A' };
	const { data } = useGetList(
		refResource,
		{ perPage: 10000, page: 1 },
		{ field: refName, order: 'ASC' },
		{ ...situacao, ...filtroOpcoes }
	);

	const init = () => {
		if (options?.length > 0 && lengthOption === 0) {
			let aux = 0;
			options.forEach((v) => {
				if (v.name?.length > aux) aux = v.name?.length;
			});
			setLengthOption(aux);
		}
		if (filterValues[source] && options?.length > 0 && !value) {
			setValue(
				options.find(
					(o) =>
						o.id ===
						(source === 'id_condominio' || source === 'condominio_id'
							? parseInt(filterValues[source])
							: filterValues[source])
				)
			);
		}
	};

	useEffect(init, [filterValues, options, source]);

	const setOpcoes = () => {
		if (!opcoes && refResource && refName && data) {
			setOptions(
				Object.keys(data)
					.map((key) => ({
						id: data[key].id,
						name: data[key][refName],
						situacao: data[key].situacao,
					}))
					.filter((v) => v.id !== 0)
					.sort((a, b) => (a.situacao === 'A' && b.situacao === 'I' ? -1 : 1))
			);
		} else {
			setOptions(opcoes);
		}
	};

	useEffect(setOpcoes, [opcoes, refResource, refName, data]);

	return (
		<Autocomplete
			openOnFocus
			value={value || null}
			disabled={!options?.length}
			id={id}
			options={options}
			onChange={(e, newValue) => {
				newValue ? setValue(newValue) : setValue(null);
				setFilters({
					...filterValues,
					[source]: newValue ? newValue.id : '',
				});
			}}
			renderOption={(option) =>
				option.name ? option?.situacao === 'I' ? <i>{option.name} - Inativo</i> : option.name : ''
			}
			getOptionLabel={(option) => (option.name ? option.name : '')}
			getOptionSelected={(option, newValue) => {
				return newValue.name === option.name;
			}}
			renderInput={(params) => <TextField {...params} label={label || 'Filtrar'} variant={variant} />}
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						width: `${lengthOption * 10}pt` || props.style.width,
						maxWidth: '320px',
						minWidth: '60px',
					}}
				/>
			)}
			fullWidth
			noOptionsText='Nenhum resultado'
			disableListWrap
		/>
	);
};

export const SimplesAutocompletar = ({ label, source, opcoes, variant = 'standard', listControllerProps }) => {
	const [value, setValue] = useState('');
	const { setFilters, filterValues } = useListContext(listControllerProps);
	const [options, setOptions] = useState([]);
	const [lengthOption, setLengthOption] = useState(0);

	const init = () => {
		if (options?.length > 0 && lengthOption === 0) {
			let aux = 0;
			options.forEach((v) => {
				if (v.name?.length > aux) aux = v.name?.length;
			});
			setLengthOption(aux);
		}
		if (filterValues[source] && options?.length > 0 && !value) {
			setValue(options.find((o) => o.id === filterValues[source]));
		}
	};

	useEffect(init, [filterValues, options, source]);

	const setOpcoes = () => {
		setOptions(opcoes);
	};

	useEffect(setOpcoes, [opcoes]);

	return (
		<Autocomplete
			openOnFocus
			value={value || null}
			id={`${source}-filtro-autocompletar`}
			disabled={!options?.length}
			options={options}
			onChange={(e, newValue) => {
				let id = '';
				if (newValue) {
					id = newValue.id;
					setValue(newValue);
				} else {
					setValue(null);
				}
				setFilters({ ...filterValues, [source]: id });
			}}
			renderOption={(option) =>
				option.name ? option?.situacao === 'I' ? <i>{option.name} - Inativo</i> : option.name : ''
			}
			getOptionLabel={(option) => (option.name ? option.name : '')}
			getOptionSelected={(option, newValue) => {
				return newValue.name === option.name;
			}}
			renderInput={(params) => <TextField {...params} label={label || 'Filtrar'} variant={variant} />}
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						width: `${lengthOption * 10}pt` || props.style.width,
						maxWidth: '320px',
						minWidth: '60px',
					}}
				/>
			)}
			fullWidth
			noOptionsText='Nenhum resultado'
			disableListWrap
		/>
	);
};
