import * as React from 'react';
import { List, Pagination, TextField, ReferenceField } from 'react-admin';
import { Tabela } from '../../common/Tabela';
import { Padrao } from '../../common/filtros/Padrao';
import { CurrencyField } from '../../common/CurrencyInput';
import { opcoesSituacao, SituacaoField } from '../contasReceber/list';
import { Decimal } from '../../common/filtros/Decimal';
import { Data } from '../../common/filtros/DataV2';
import { format, parseISO } from 'date-fns';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import CondominioSelectFilter from './CondominioSelectFilter';

const ValorField = ({ record, source, ...props }) => (
	<CurrencyField
		{...props}
		prefix='R$ '
		minimumFractionDigits={2}
		value={record[source]}
		id={`${source}${record.id}`}
	/>
);

const ReferenciaField = ({ record }) => {
	return record?.referencia ? format(parseISO(record?.referencia), 'MM/yyyy') : '';
};

const TipoField = ({ record }) => {
	switch (record?.tipo_responsavel) {
		case 'P':
			return <span>Proprietário</span>;
		case 'I':
			return <span>Inquilino</span>;
		default:
			return <span>Não Informado</span>;
	}
};

const DateTextField = (props) => (
	<span>{props?.record[props?.source] ? format(parseISO(props?.record[props?.source]), 'dd/MM/yyyy') : ''}</span>
);

const TabelaHistorico = ({ condominio, pessoa, ...rest }) => (
	<Tabela disableClickRow {...rest}>
		<ReferenceField
			source='condominio'
			reference='condominios'
			label='Condomínio'
			link={false}
			sortBy='condominio'
			filter={{ situacao: 'A' }}
			filtro={<CondominioSelectFilter source='condominio' />}
			minWidth='200px'
		>
			<TextField source='nome' />
		</ReferenceField>
		<TipoField
			source='tipo_responsavel'
			label='Vínculo'
			filtro={
				<Autocompletar
					opcoes={[
						{ id: 'I', name: 'Inquilino' },
						{ id: 'P', name: 'Proprietário' },
					]}
				/>
			}
		/>
		<TextField source='nome_unidade' label='Unidade' filtro={<Padrao />} />
		<ReferenciaField
			source='referencia'
			label='Referência'
			filtro={<Data format='MM/yyyy' mask='__/____' views={['year', 'month']} />}
		/>
		<ReferenceField
			link={false}
			source='identificacao'
			label='Identificação'
			filtro={
				<Autocompletar
					refName='nome'
					refResource='identificacao_arrecadacao'
					filtroOpcoes={{ id_condominio: condominio }}
				/>
			}
			reference='identificacao_arrecadacao'
		>
			<TextField source='nome' />
		</ReferenceField>
		<SituacaoField source='situacao' label='Situação' filtro={<Autocompletar opcoes={opcoesSituacao} />} />
		<DateTextField source='data_vencimento' label='Vencimento' filtro={<Data />} />
		<DateTextField source='data_pagamento' label='Pagamento' filtro={<Data />} />
		<ValorField source='valor_base' label='Valor' filtro={<Decimal />} align='right' />
		<ValorField source='valor_recebido' label='Pago' filtro={<Decimal />} align='right' />
	</Tabela>
);

export const HistoricoCobrancaPessoaList = ({ condominio, pessoa, ...rest }) => (
	<List
		{...rest}
		empty={false}
		bulkActionButtons={false}
		actions={false}
		title=' '
		sort={{ field: 'referencia', order: 'DESC' }}
		perPage={10}
		pagination={
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Cobranças por página' />
		}
	>
		<TabelaHistorico {...{ condominio, pessoa }} />
	</List>
);
