import React from 'react';

import { Box } from '@material-ui/core';

import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const AceiteField = () => (
	<Box flex={1} sx={{ 'max-width': '17%' }}>
		<EditaContaBancariaAutocomplete
			source='aceite'
			name='aceite'
			id='aceite'
			label='Aceite'
			translateChoice={false}
		/>
	</Box>
);

export default AceiteField;
