import React from 'react';

import { useDataProvider, useListContext, useNotify, useRefresh } from 'react-admin';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { ButtonRemoveV2 } from 'components/common/buttons/ButtonRemoveV2';

const ActionsBulkList: React.FC = () => {
	const dp = useDataProvider();
	const { selectedIds, onUnselectItems } = useListContext();
	const notify = useNotify();
	const refresh = useRefresh();
	const [loading, setLoading] = React.useState(false);

	const handleDelete = () => {
		setLoading(true);
		dp.deleteMany('correcao_monetaria', { ids: selectedIds })
			.then(() => notify('Correções monetárias deletadas com sucesso.', 'success'))
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify('Inconsistencia na exclusão das correções monetárias');
			})
			.finally(() => {
				setLoading(false);
				refresh(true);
				onUnselectItems();
			});
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
			<ButtonRemoveV2
				loading={loading}
				color='secondary'
				startIcon={<DeleteForeverIcon />}
				size='small'
				onClick={handleDelete}
			>
				Excluir selecionados
			</ButtonRemoveV2>
		</div>
	);
};

export default ActionsBulkList;
