import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { TooltipIconButton } from 'components/common/TooltipIconButton';
import { TooltipIconButtonCancel } from 'components/common/buttons/ButtonCancelV2';
import { TitulosContext } from './TituloModalContextProvider';
import { ITituloRelatorio } from '../types';

interface ITituloRowProps {
	option: ITituloRelatorio;
}

const useStyles = makeStyles(() =>
	createStyles({
		option: ({ selected }: { selected: boolean }) => ({
			cursor: 'pointer',
			borderRadius: 5,
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,.275)',
			},
			backgroundColor: selected ? 'rgba(0,0,0,.275)' : 'transparent',
			marginBottom: '5px',
		}),
	})
);

const TituloRow: React.FC<ITituloRowProps> = ({ option }) => {
	const { update, deleteOne, selected, setSelected } = React.useContext(TitulosContext);
	const classes = useStyles({ selected: selected?.id === option.id });

	const inputRef = React.useRef<null | { value: string; focus: () => void }>(null);
	const [descricao, setDescricao] = React.useState<string>('');
	const [editMode, setEditMode] = React.useState<boolean>(false);
	const [visibility, setVisibility] = React.useState<'visible' | 'hidden'>('hidden');

	useEffect(() => {
		if (inputRef.current && editMode) inputRef.current.focus();
	}, [editMode]);

	useEffect(() => {
		if (option) setDescricao(option.descricao);
	}, [option]);

	return (
		<Box
			display='flex'
			justifyContent='space-between'
			width='calc(100% - 4px)'
			alignItems='center'
			className={classes.option}
			onClick={() => {
				setSelected(option);
			}}
			onMouseEnter={() => setVisibility('visible')}
			onMouseLeave={() => setVisibility('hidden')}
		>
			{editMode ? (
				<>
					<TextField
						inputRef={inputRef}
						value={descricao}
						onChange={(e) => setDescricao(e.target.value)}
						fullWidth
						margin='dense'
					/>

					<Box display='flex'>
						<TooltipIconButton
							color='primary'
							title='Salvar'
							onClick={() => {
								!descricao ? setDescricao(option.descricao) : update(option.id, { descricao });
								setEditMode(false);
							}}
						>
							<CheckIcon />
						</TooltipIconButton>
						<TooltipIconButtonCancel
							onClick={() => {
								setDescricao(option.descricao);
								setEditMode(false);
							}}
						/>
					</Box>
				</>
			) : (
				<>
					<Typography style={{ padding: 14 }}>{descricao}</Typography>
					<Box
						display='flex'
						style={{
							visibility: visibility,
						}}
					>
						<TooltipIconButton
							title='Renomear'
							onClick={() => {
								setEditMode(true);
							}}
						>
							<EditIcon />
						</TooltipIconButton>
						<TooltipIconButtonCancel
							title='Remover'
							onClick={() => {
								deleteOne(option.id);
							}}
						>
							<DeleteOutlineIcon />
						</TooltipIconButtonCancel>
					</Box>
				</>
			)}
		</Box>
	);
};

export default TituloRow;
