import React from 'react';

import { FormGroup, Checkbox, FormControlLabel } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';

const BoxConfigValorUnidadeSubordinada = () => {
	const {
		opcoesImpressao: { imprimir_valor_unidade_subordinada },
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	return (
		<>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_valor_unidade_subordinada}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_valor_unidade_subordinada')
							}
							name='imprimir_valor_unidade_subordinada'
						/>
					}
					label='Imprimir valor unidade subordinada'
				/>
			</FormGroup>
		</>
	);
};

export default BoxConfigValorUnidadeSubordinada;
