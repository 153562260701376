import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { Autocomplete } from '@material-ui/lab';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';

import { createFormatsOptionsAutoCompleteComponent } from 'components/common/Selects/formats';

const formatsParaUnidades = createFormatsOptionsAutoCompleteComponent('formatsUnidades');

const SelectUnidadeReserva = () => {
	const {
		consulta: { unidades, unidadesSelecionadas, condominioSelecionado, ambienteSelecionado },
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);

	const dp = useDataProvider();
	const notify = useNotify();

	const handleChangeUnidade = React.useCallback(
		(_, values) => {
			setConsulta((_C) => ({
				..._C,
				unidadesSelecionadas: values,
			}));
		},
		[setConsulta]
	);

	const updateUnidades = () => {
		if (!ambienteSelecionado || !condominioSelecionado)
			return setConsulta((_consulta) => ({
				..._consulta,
				unidades: [],
				unidadesSelecionadas: [],
			}));
		dp.getSimple('unidades', {
			pagination: { perPage: 10000, page: 1 },
			sort: { field: 'unidade', order: 'ASC' },
			filter: {
				id_condominio: condominioSelecionado.id,
				...(ambienteSelecionado?.grupos.length ? { id_grupo_unidade__in: ambienteSelecionado.grupos } : {}),
				situacao: 'A',
			},
		})
			.then(({ data: { results = [] } = {} } = {}) =>
				results?.length ? results : Promise.reject('Sem unidades cadastradas.')
			)
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				notify('Sem unidades cadastradas.', 'warning');
				return [];
			})
			.then((unidadesColetadas) => {
				setConsulta((_C) => ({
					..._C,
					unidades: unidadesColetadas,
					unidadesSelecionadas: [],
				}));
			});
	};

	React.useEffect(updateUnidades, [ambienteSelecionado]);

	const checkGroup = (group) => {
		const [_group, _selected] = [unidades, unidadesSelecionadas].map((v) =>
			v.filter((c) => (c['nome_grupo'] || c['tipo_unidade']) === group)
		);
		return _group.length === _selected.length;
	};

	const selectGroup = React.useCallback(
		(group) => {
			const [_group, _selected] = [unidades, unidadesSelecionadas].map((v) =>
				v.filter((c) => (c['nome_grupo'] || c['tipo_unidade']) === group)
			);
			_selected.length > 0
				? setConsulta((_C) => ({
						..._C,
						unidadesSelecionadas: unidadesSelecionadas.filter(
							(c) => (c['nome_grupo'] || c['tipo_unidade']) !== group
						),
				  }))
				: setConsulta((_C) => ({
						..._C,
						unidadesSelecionadas: [...unidadesSelecionadas, ..._group],
				  }));
		},
		[setConsulta, unidades, unidadesSelecionadas]
	);

	if (!condominioSelecionado || !ambienteSelecionado) return <></>;

	return (
		<Autocomplete
			id='select-unidades-reserva'
			itemID='select-unidades-reserva-item'
			disabled={!unidades.length}
			loading={!unidades.length}
			multiple
			value={unidadesSelecionadas}
			options={unidades}
			onChange={handleChangeUnidade}
			groupBy={(option) => formatsParaUnidades.formatGroupBy(option)}
			getOptionLabel={(option) =>
				option &&
				(formatsParaUnidades.formatOption(unidades.find((v) => v.id === option)) ||
					formatsParaUnidades.formatOption(option))
			}
			renderInput={(params) => (
				<TextField
					{...params}
					variant='outlined'
					placeholder='Selecionar uma ou várias unidades...'
					label='Selecionar Unidades'
				/>
			)}
			renderOption={(option, { selected }) => (
				<span>
					<Checkbox checked={selected} />
					{formatsParaUnidades.formatOption(unidades.find((v) => v.id === option)) ||
						formatsParaUnidades.formatOption(option)}
				</span>
			)}
			renderGroup={(params) => (
				<>
					<Checkbox
						key={params.group}
						checked={checkGroup(params.group)}
						onChange={() => selectGroup(params.group)}
					/>
					<span>{params.group}</span>
					{params.children}
				</>
			)}
			disableCloseOnSelect
			noOptionsText='Nenhum resultado encontrado'
			autoHighlight
			clearText='Limpar'
			size='small'
			fullWidth
		/>
	);
};

export default SelectUnidadeReserva;
