import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { Field } from 'react-final-form';
import { useInput } from 'ra-core';

export function NumberFormatBRL(props) {
	const { inputRef, onChange, allowNegative = false, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.floatValue ?? '',
					},
				});
			}}
			thousandSeparator='.'
			decimalSeparator=','
			isNumericString
			fixedDecimalScale={true}
			allowNegative={allowNegative}
		/>
	);
}

NumberFormatBRL.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export function NumberFormatAlignRightBRL(props) {
	const { inputRef, onChange, allowNegative = false, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.floatValue ?? '',
					},
				});
			}}
			thousandSeparator='.'
			decimalSeparator=','
			isNumericString
			fixedDecimalScale={true}
			allowNegative={allowNegative}
			style={{ textAlign: 'right' }}
		/>
	);
}

NumberFormatAlignRightBRL.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export const sanitizeListNumberFormatBRLProps = ({
	thousandsGroupStyle = 'thousand',
	decimalScale = 2,
	fixedDecimalScale = false,
	allowNegative = true,
	allowEmptyFormatting = false,
	allowLeadingZeros = false,
	prefix,
	suffix,
	defaultValue = null,
	isNumericString = false,
	displayType = 'input',
	type = 'text',
	format,
	removeFormatting,
	mask,
	customInput,
	onValueChange,
	isAllowed,
	renderText = null,
	getInputRef = null,
	allowedDecimalSeparators = [','],
	onChange,
}) => {
	return {
		thousandsGroupStyle,
		decimalScale,
		fixedDecimalScale,
		allowNegative,
		allowEmptyFormatting,
		allowLeadingZeros,
		prefix,
		suffix,
		defaultValue,
		isNumericString,
		displayType,
		type,
		format,
		removeFormatting,
		mask,
		customInput,
		onValueChange,
		isAllowed,
		renderText,
		getInputRef,
		allowedDecimalSeparators,
		onChange,
	};
};

export default function CurrencyInput({ record, source, label, events = {}, style = {}, disabled = false, ...rest }) {
	const [values, setValues] = React.useState({
		[source]: record && record[source],
	});
	const updateValue = () => {
		if (record && values && values[source] !== record[source]) setValues({ [source]: record[source] });
	};
	React.useEffect(updateValue, [record]);

	const handleChange = (event) => {
		setValues({
			[source]: parseFloat(event.target.value),
		});
	};

	const eventos_computados = {};
	for (const evento in events) {
		eventos_computados[evento] = events[evento]({
			values,
			record,
			source,
			disabled,
			...rest,
		});
	}

	return (
		<TextField
			margin='dense'
			{...eventos_computados}
			value={values[source]}
			onChange={handleChange}
			name={source}
			id={source + (record?.id || '0')}
			inputProps={sanitizeListNumberFormatBRLProps(rest)}
			InputProps={{
				inputComponent: NumberFormatBRL,
			}}
			style={style}
			disabled={disabled}
		/>
	);
}

export const CurrencyField = ({ id, value, name, ...rest }) => (
	<NumberFormat
		{...sanitizeListNumberFormatBRLProps(rest)}
		displayType='text'
		thousandSeparator='.'
		decimalSeparator=','
		isNumericString
		fixedDecimalScale={true}
		allowNegative={false}
		value={value}
		name={name}
		id={id}
	/>
);

const composeValidators =
	(...validators) =>
	(value) =>
		validators.reduce((error, validator) => error || validator(value), undefined);

export const FinalFormCurrencyInput = ({
	id,
	name,
	disabled,
	onChange,
	label,
	validate = [],
	style,
	margin,
	fullWidth = false,
	...props
}) => (
	<Field name={name} id={id} disabled={disabled} validate={composeValidators(...validate)} fullWidth={fullWidth}>
		{({ input, meta }) => (
			<TextField
				style={style}
				error={!!meta.error}
				helperText={meta.error}
				value={input.value}
				name={input.name}
				id={id}
				label={label}
				onChange={(event) => {
					if (onChange) onChange(event);
					input.onChange(event);
				}}
				disabled={disabled}
				margin={margin}
				inputProps={sanitizeListNumberFormatBRLProps(props)}
				InputProps={{
					inputComponent: NumberFormatBRL,
				}}
				fullWidth={fullWidth}
			/>
		)}
	</Field>
);

export const CoeficienteField = (props) => {
	return (
		<TextField
			{...props}
			inputProps={sanitizeListNumberFormatBRLProps(props)}
			InputProps={{
				inputComponent: NumberFormatBRL,
			}}
		/>
	);
};

export const CoeficienteInput = (props) => {
	const { input } = useInput(props);
	return <CoeficienteField {...props} {...input} />;
};
