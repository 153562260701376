import React from 'react';

import { InstrucoesRemessaSemValorFields } from '../../commons/InstrucoesRemessaFields';

const emptyProps = {};

const LimitePagamentoFields = ({ propsTipo = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaSemValorFields
		sourcePrincipal='limite_pagamento'
		propsTipo={{
			source: 'limite_pagamento_tipo',
			label: 'Data Baixa/Devolução',
			...propsTipo,
		}}
		propsDias={{
			source: 'limite_pagamento_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoLimitePagamento = ({
	sources: { sourceDias } = emptyProps,
	values,
	optionsIsencoesTipo,
	props,
}) => ({
	sources: { sourceDias },
	values: { [sourceDias]: values[sourceDias] },
	optionsIsencoesTipo,
	props,
});

export const createOnChangeTipoPadraoLimitePagamento =
	(
		{
			sources: { sourceDias } = {},
			values,
			optionsIsencoesTipo,
			props: {
				limite_pagamento: { initialDias = 30 },
			},
		},
		change
	) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);

		const diasValue = values[sourceDias];
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceDias && change(sourceDias, undefined);
		} else {
			!diasValue && change(sourceDias, initialDias);
		}
	};

export default LimitePagamentoFields;
