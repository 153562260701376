import React from 'react';

import { useForm } from 'react-final-form';
import EditaContaBancariaContext from '../contexts/EditaContaBancariaContext';
import DadosPorTipoIntegracaoContext from '../contexts/DadosPorTipoIntegracaoContext';

const gerarOpcoes = (gruposDeOpcoes: any, tipoIntegracaoIdAtual: any) => {
	const opcoesDefault = gruposDeOpcoes['DEFAULT'] ?? {};
	const opcoesDoTipoIntegracao = gruposDeOpcoes?.[tipoIntegracaoIdAtual] ?? {};
	return { ...opcoesDefault, ...opcoesDoTipoIntegracao };
};

const createReducer =
	({ change }: any) =>
	(state: any, action: any) => {
		const { values, instituicaoCodigo } = action;
		const tipo_integracao_id = values?.tipo_integracao?.id;
		if (state.instituicaoCodigo === instituicaoCodigo && state.tipoIntegracaoIdAtual === tipo_integracao_id)
			return state;

		if (action.alteradoInstituicao) {
			const { gruposDeOpcoes, tabContentsPorTipoIntegracao, propsPorTipoIntegracao } = action;
			const { choices, objects } = gruposDeOpcoes['DEFAULT']?.tipo_integracao ?? {};
			if (objects && !objects[tipo_integracao_id]) {
				state.tipoIntegracaoIdAtual = choices[0]?.id;
				change('tipo_integracao', choices[0]);
			}
			state.instituicaoCodigo = instituicaoCodigo;
			state.options = gerarOpcoes(gruposDeOpcoes, state.tipoIntegracaoIdAtual);
			state.tabContents =
				tabContentsPorTipoIntegracao[state.tipoIntegracaoIdAtual] ??
				tabContentsPorTipoIntegracao['DEFAULT'] ??
				tabContentsPorTipoIntegracao;
			state.props = propsPorTipoIntegracao?.[state.tipoIntegracaoIdAtual] ?? propsPorTipoIntegracao;
		}
		if (action.alteradoTipoIntegracao) {
			const { gruposDeOpcoes, tabContentsPorTipoIntegracao, propsPorTipoIntegracao } = action;
			if (tipo_integracao_id === state.tipoIntegracaoIdAtual) return state;

			state.tipoIntegracaoIdAtual = tipo_integracao_id;
			state.options = gerarOpcoes(gruposDeOpcoes, state.tipoIntegracaoIdAtual);
			state.tabContents =
				tabContentsPorTipoIntegracao[state.tipoIntegracaoIdAtual] ??
				tabContentsPorTipoIntegracao['DEFAULT'] ??
				tabContentsPorTipoIntegracao;
			state.props = propsPorTipoIntegracao?.[state.tipoIntegracaoIdAtual] ?? propsPorTipoIntegracao;
		}

		return { ...state };
	};

const initReducer = ({
	tabContentsPorTipoIntegracao,
	gruposDeOpcoes,
	values,
	instituicaoCodigo,
	propsPorTipoIntegracao,
}: any) => {
	const tipoIntegracaoIdAtual = values.tipo_integracao?.id;
	const options = gerarOpcoes(gruposDeOpcoes, tipoIntegracaoIdAtual);
	const tabContents =
		tabContentsPorTipoIntegracao[tipoIntegracaoIdAtual] ??
		tabContentsPorTipoIntegracao['DEFAULT'] ??
		tabContentsPorTipoIntegracao;
	const props = propsPorTipoIntegracao?.[tipoIntegracaoIdAtual] ?? propsPorTipoIntegracao;
	return {
		options,
		tabContents,
		tipoIntegracaoIdAtual,
		instituicaoCodigo,
		props,
	};
};

const DadosPorTipoIntegracaoContextProvider: React.FC = ({ children }) => {
	const { TipoContaContext } = React.useContext(EditaContaBancariaContext);
	const {
		tabContents: tabContentsPorTipoIntegracao,
		instituicaoCodigo,
		options: gruposDeOpcoes,
		props: propsPorTipoIntegracao,
	} = React.useContext(TipoContaContext) as any;

	const { getState, change, pauseValidation, resumeValidation } = useForm();
	const state = getState();
	const { values } = state ?? {};

	const reducer = React.useMemo(() => createReducer({ change }), [change]);
	const [{ options, tabContents, props }, dispatch] = React.useReducer(
		reducer,
		{ tabContentsPorTipoIntegracao, gruposDeOpcoes, values, instituicaoCodigo, propsPorTipoIntegracao },
		initReducer
	);
	const tipoIntegracaoId = values?.tipo_integracao?.id;

	React.useEffect(() => {
		dispatch({
			alteradoTipoIntegracao: true,
			gruposDeOpcoes,
			values,
			tabContentsPorTipoIntegracao,
			instituicaoCodigo,
			propsPorTipoIntegracao,
		});
	}, [tipoIntegracaoId]);

	React.useEffect(() => {
		dispatch({
			alteradoInstituicao: true,
			gruposDeOpcoes,
			values,
			tabContentsPorTipoIntegracao,
			instituicaoCodigo,
			propsPorTipoIntegracao,
		});
	}, [instituicaoCodigo]);

	const atualizaOpcoesSelecionadas = () => {
		pauseValidation();
		Object.entries(options).forEach(([campo, { objects }]: any) => {
			const value = state.values[campo];
			const optionObject = objects[value?.id];
			if (value && optionObject && value.text !== optionObject.text) change(campo, optionObject);
		});
		resumeValidation();
	};

	React.useEffect(atualizaOpcoesSelecionadas, [options]);

	const value = React.useMemo(() => ({ options, tabContents, props }), [options, tabContents, props]);

	return <DadosPorTipoIntegracaoContext.Provider value={value}>{children}</DadosPorTipoIntegracaoContext.Provider>;
};

export default DadosPorTipoIntegracaoContextProvider;
