import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelect, periodoOptions } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

export const BalanceteDivisor = (props) => {
	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const [dataInicial, setDataInicial] = useState();
	const [dataFinal, setDataFinal] = useState();
	const {
		opcoesImpressao: { informar_despesa_coluna_saida, imprimir_assinatura },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	const requestData = {
		id_condominio: props.idCondominio,
		data_inicial: dataInicial,
		data_final: dataFinal,
		informar_despesa_coluna_saida: informar_despesa_coluna_saida,
		imprimir_assinatura: imprimir_assinatura,
		nome_relatorio: props.tipoRelatorio.action,
	};

	return (
		<BoxDivisor titulo='Balancete'>
			<Box display='flex'>
				<Box flex={1}>
					<PeriodoSelect
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={dataInicial}
						setDataInicial={setDataInicial}
						setDataFinal={setDataFinal}
						dataFinal={dataFinal}
						label='Selecionar Período'
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent={'flex-start'}>
				<FormControlLabel
					control={
						<Checkbox
							checked={informar_despesa_coluna_saida}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'informar_despesa_coluna_saida')
							}
							name='informar_despesa_coluna_saida'
						/>
					}
					label='Informar Despesa na coluna saída'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_assinatura}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={props.tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};
