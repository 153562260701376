import { add, format, sub } from 'date-fns';

const dictFeriados = {
	2020: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['15', '16'], // Carnaval
		'03': [],
		'04': ['02', '21'],
		'05': ['01'],
		'06': ['03'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['15'],
		12: ['25'],
	},
	2021: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['15', '16'], // Carnaval
		'03': [],
		'04': ['21'],
		'05': ['01'],
		'06': ['03'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['15'],
		12: ['25'],
	},
	2022: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['28'],
		'03': ['01'],
		'04': ['15', '21'],
		'05': ['01'],
		'06': ['16'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2023: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['20', '21'],
		'03': [],
		'04': ['07', '21'],
		'05': ['01'],
		'06': ['08'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2024: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['12', '13'],
		'03': ['29'],
		'04': ['21'],
		'05': ['01', '30'],
		'06': [],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2025: {
		//   Mês     Feriados
		'01': ['01'],
		'02': [],
		'03': ['03', '04'],
		'04': ['18', '21'],
		'05': ['01'],
		'06': ['19'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2026: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['16', '17'],
		'03': [],
		'04': ['03', '21'],
		'05': ['01'],
		'06': ['04'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2027: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['08', '09'],
		'03': ['26'],
		'04': ['21'],
		'05': ['01', '27'],
		'06': [],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2028: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['28', '29'],
		'03': [],
		'04': ['14', '21'],
		'05': ['01'],
		'06': ['15'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2029: {
		//   Mês     Feriados
		'01': ['01'],
		'02': ['12', '13'],
		'03': ['30'],
		'04': ['21'],
		'05': ['01', '31'],
		'06': [],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
	2030: {
		//   Mês     Feriados
		'01': ['01'],
		'02': [],
		'03': ['04', '05'],
		'04': ['19', '21'],
		'05': ['01'],
		'06': ['20'],
		'07': [],
		'08': [],
		'09': ['07'],
		10: ['12'],
		11: ['02', '15'],
		12: ['25'],
	},
};

const isDataValida = (data) => {
	return !(!data?.toString || data.toString() === 'Invalid Date');
};

export const isFeriado = (data) => {
	const [year, month, day] = format(data, 'yyyy-MM-dd').split('-');
	try {
		return dictFeriados[year][month].includes(day);
	} catch {
		return false;
	}
};

export const isDomingo = (data) => data?.getDay && data.getDay() === 0;

export const isSabado = (data) => data?.getDay && data.getDay() === 6;

const anteciparSabadosDomingosEFeriados = (data) => {
	if (isDataValida(data) && (isSabado(data) || isDomingo(data) || isFeriado(data))) {
		return anteciparSabadosDomingosEFeriados(sub(data, { days: 1 }));
	} else {
		return data;
	}
};

const postergarSabadosDomingosEFeriados = (data) => {
	if (isDataValida(data) && (isSabado(data) || isDomingo(data) || isFeriado(data))) {
		return postergarSabadosDomingosEFeriados(add(data, { days: 1 }));
	} else {
		return data;
	}
};

export const dictFunctionsVencimentoPrevisto = {
	M: (data) => data,
	A: anteciparSabadosDomingosEFeriados,
	P: postergarSabadosDomingosEFeriados,
};
