import React from 'react';
import NumberFormat from 'react-number-format';

interface IValorField {
	value?: number;
}

const ValorField: React.FC<IValorField> = ({ value = 0.0 }) => {
	return (
		<NumberFormat
			thousandSeparator='.'
			decimalSeparator=','
			decimalScale={6}
			isNumericString
			displayType='text'
			value={value}
			fixedDecimalScale
			onChange={() => {}}
		/>
	);
};

export default ValorField;
