import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useDataProvider } from 'react-admin';

import { format, isAfter, isBefore, parse } from 'date-fns';

import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../../../../common/buttons/ButtonCancel';
import { BotaoProximo } from '../../../../../common/buttons/BotaoProximo';
import parseOFX from '../../../../../common/OFXParser';
import CondominioSelect from '../common/CondominioSelect';
import ContaBancariaSelect from '../common/ContaBancariaSelect';
import DropZone from '../common/DropZone';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';
import { useNotify } from 'react-admin';

const useStylesGridTemplate = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: '1rem',
	},
}));

function verificarStringDate(string) {
	return string.includes('LMT');
}

const replaceFn = (valor) => {
	const verificarDataTipoLMT = verificarStringDate(valor);

	if (verificarDataTipoLMT) {
		return valor.replace(/^([0-9]{8})([0-9]{6})\.\d+\[.*\]$/, '$1$2');
	} else {
		return valor.replace(/^([0-9]{8})([0-9]*)(\.[0-9]+)?(\[([+-])?(\d*):(\w*)\])?(\.[0-9]+)?/, '$1030000');
	}
};
const parseDateFn = (date) => parse(replaceFn(date), 'yyyyMMddHHmmss', new Date());

const getNewDate = (oldDate, newDate, fnComparation) => {
	const date = newDate ? parseDateFn(newDate?.toString()) : undefined;
	return date && (!oldDate || fnComparation(date, oldDate)) ? date : oldDate;
};

const readAsTextASCIIorUTF = (file) =>
	new Promise((resolve) => {
		const readerRaw = new FileReader();
		const readerText = new FileReader();
		readerRaw.addEventListener(
			'loadend',
			() => {
				readerText.readAsText(
					file,
					new RegExp('^[\\\x00-\\\x7F]*$', 'g').test(readerRaw.result) ? 'ASCII' : 'UTF-8'
				);
			},
			false
		);
		readerText.addEventListener(
			'load',
			() => {
				resolve(readerText.result);
			},
			false
		);
		readerRaw.readAsArrayBuffer(file);
	});

const mapSTMTTRN = (STMTTRN, i) => {
	const data_movimentacao = parseDateFn(STMTTRN.DTPOSTED);
	data_movimentacao.setHours(0, 0, 0, 0);
	const TRNAMTFormatado = (STMTTRN.TRNAMT || '').replace(',', '.');
	return {
		index: i,
		tipo: STMTTRN.TRNTYPE,
		tipo_lancamento: parseFloat(TRNAMTFormatado) < 0 ? 'D' : 'R',
		valor: Math.abs(parseFloat(TRNAMTFormatado)),
		data_movimentacao: data_movimentacao,
		historico: STMTTRN.MEMO,
		situacao: 'I',
		movimentacoesCompativeis: {
			compatibilidadeTotalConciliado: {},
			compatibilidadeTotal: {},
		},
	};
};

const testeCompatibilidadeTotal = (registro, movimentacao, id_conta_bancaria) =>
	(movimentacao.conta_b_destino === id_conta_bancaria || movimentacao.conta_b_origem === id_conta_bancaria) &&
	registro.valor === movimentacao.valor &&
	registro.data_movimentacao === movimentacao.data;

const get_compatibilidade = (registro, movimentacao, id_conta_bancaria) => {
	let [integerValor, centsValor] = `${registro.valor}`.split('.');
	centsValor = centsValor || '00';
	centsValor += centsValor.length > 1 ? '' : '0';
	const registroValor = `${integerValor}.${centsValor}`;
	const registroDataMovimentacao = format(registro.data_movimentacao, 'yyyy-MM-dd');
	const compativel = testeCompatibilidadeTotal(
		{ valor: registroValor, data_movimentacao: registroDataMovimentacao },
		movimentacao,
		id_conta_bancaria
	);
	if (compativel) {
		return movimentacao.situacao === 'C' ? 'compatibilidadeTotalConciliado' : 'compatibilidadeTotal';
	}
};

export const ModalDropZoneExtratoBancaria = () => {
	const dp = useDataProvider();
	const notify = useNotify();
	const classes = useStylesGridTemplate();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		condominio,
		condominios,
		contaBancaria,
		contasBancarias,
		files,
		setDatas_after,
		setDatas_before,
		setModalStep,
		setMovimentacoesParaConciliacao,
		setRegistros,
	} = useContext(FluxoImportacaoExtratoBancarioContext);
	const [valid, setValid] = useState(false);
	const [processing, setProcessing] = useState(false);

	const validate = useCallback(() => {
		setValid(
			condominio &&
				(condominios || []).find((c) => c?.id === parseInt(condominio)) &&
				contaBancaria?.id &&
				(contasBancarias || []).find(
					(c) => c?.id === contaBancaria.id && c?.id_condominio === parseInt(condominio)
				) &&
				!!files.length
		);
	}, [setValid, condominio, condominios, contaBancaria, contasBancarias, files]);

	useEffect(validate, [condominios, contaBancaria, contasBancarias, files.length]);

	const handleOnClickProximo = useCallback(() => {
		setProcessing(true);
		Promise.allSettled(
			files.map((fileObject) =>
				readAsTextASCIIorUTF(fileObject.file)
					.then((textFile) => parseOFX(textFile))
					.then((dados) => dados?.OFX?.BANKMSGSRSV1?.STMTTRNRS?.STMTRS?.BANKTRANLIST)
			)
		)
			.then((promiseResults) => {
				const dadosFiles = promiseResults.reduce(
					(dadosFiles, promiseResult) => {
						if (promiseResult.status === 'fulfilled' && promiseResult.value) {
							const value = promiseResult.value;
							dadosFiles.files = [].concat(dadosFiles.files, value.STMTTRN || []);
							dadosFiles.data_inicio = getNewDate(dadosFiles.data_inicio, value.DTSTART, isBefore);
							dadosFiles.data_fim = getNewDate(dadosFiles.data_fim, value.DTEND, isAfter);
						}
						return dadosFiles;
					},
					{ files: [] }
				);
				const datas_before = format(dadosFiles.data_fim, 'yyyy-MM-dd');
				const datas_after = format(dadosFiles.data_inicio, 'yyyy-MM-dd');
				dp.getSimple('movimentacao_bancaria', {
					pagination: { page: 1, perPage: 10000 },
					filter: {
						condominio,
						datas_after: datas_after,
						datas_before: datas_before,
						id_conta_bancaria: contaBancaria?.id,
						ocultar_previsoes: true,
					},
					sort: {
						order: 'ASC',
						field: 'data_movimentacao',
					},
				})
					.then((response) => {
						const movimentacoes = response?.data?.movimentacoes || [];

						const fileObjects = dadosFiles.files.reduce((fileObjects, dadosFile, i) => {
							const fileObject = mapSTMTTRN(dadosFile, i);
							fileObject.movimentacoesCompativeis = movimentacoes
								.slice(0)
								.reduce((movCompativeis, mov, i, list) => {
									if (mov.registro_extrato !== undefined) return movCompativeis;
									const compatibilidade = get_compatibilidade(fileObject, mov, contaBancaria?.id);
									if (compatibilidade) {
										fileObject.situacao =
											compatibilidade === 'compatibilidadeTotalConciliado' ? 'C' : 'N';
										movCompativeis[compatibilidade][mov.id] = mov;
										movimentacoes[i].registro_extrato = fileObject.index;
										if (fileObject.situacao === 'C') list.splice(0);
									}
									return movCompativeis;
								}, fileObject.movimentacoesCompativeis);
							fileObjects[i] = fileObject;
							return fileObjects;
						}, {});

						setMovimentacoesParaConciliacao(
							movimentacoes.reduce((movimentacoesDict, mov) => {
								if (mov.registro_extrato !== undefined) movimentacoesDict[mov.id] = mov;
								return movimentacoesDict;
							}, {})
						);

						return fileObjects;
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						const fileObjects = dadosFiles.files.reduce((fileObjects, dadosFile, i) => {
							fileObjects[i] = mapSTMTTRN(dadosFile, i);
							return fileObjects;
						}, {});
						return fileObjects;
					})
					.then((fileObjects) => {
						setRegistros(fileObjects);
					})
					.finally(() => {
						setDatas_after(datas_after);
						setDatas_before(datas_before);
						setModalStep('2');
					});
			})
			.catch(() => {
				setProcessing(false);
				notify('Erro ao realizar a importação, verifique seu arquivo e tente novamente', { type: 'error' });
			});
	}, [
		condominio,
		contaBancaria,
		dp,
		setMovimentacoesParaConciliacao,
		files,
		setDatas_after,
		setDatas_before,
		setModalStep,
		setRegistros,
		setProcessing,
	]);

	return (
		<CustomDialogBodySizeEditable
			title='Importação de Extrato Bancário'
			customActions={[
				<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Cancelar</ButtonCancel>,
				<BotaoProximo disabled={!valid || processing} onClick={handleOnClickProximo} />,
			]}
			form={{
				component: (
					<>
						<Box className={classes.container}>
							<CondominioSelect />
							<ContaBancariaSelect />
						</Box>
						<DropZone />
					</>
				),
			}}
		/>
	);
};

export default ModalDropZoneExtratoBancaria;
