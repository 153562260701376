import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ButtonClose } from 'components/common/buttons/ButtonClose';
import { ButtonRemove } from 'components/common/buttons/ButtonRemove';
import TabelaDadosNossoNumero from '../TabelaDadosNossoNumero';
import { useDesprovisionarReferenciaPadrao } from '../hooks';

export const ModalConfirmaDesprovisionamentoPadrao = ({ recebimentosNossoNumero, setLoading }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const desprovisionar = useDesprovisionarReferenciaPadrao();
	const [desprovisionando, setDesprovisionando] = React.useState(false);

	return (
		<CustomDialogBodySizeEditable
			title='Deseja realmente excluir o provisionamento das arrecadações?'
			text={
				<Box display='flex' alignItems='flex-start' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Já existem unidades com a cobrança gerada, ao confirmar a exclusão essa cobrança será
							cancelada!
						</Typography>
						<TabelaDadosNossoNumero recebimentosNossoNumero={recebimentosNossoNumero} />
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setLoading?.(false);
							setModalValue({ open: false });
						}}
						size='small'
						disabled={desprovisionando}
					>
						cancelar
					</ButtonClose>
					<ButtonRemove
						onClick={() => {
							setDesprovisionando(true);
							desprovisionar({
								callbackSuccess: () => {
									setLoading?.(false);
									setModalValue({ open: false });
									setDesprovisionando(false);
								},
								callbackFail: () => {
									setDesprovisionando(false);
								},
							});
						}}
						size='small'
						disabled={desprovisionando}
					/>
				</>
			}
		/>
	);
};

export default ModalConfirmaDesprovisionamentoPadrao;
