import React from 'react';
import { useTheme, Chip, Typography } from '@material-ui/core';

import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';

import TooltipIcon from '../../../../../common/TooltipIcon';

const SituacaoChip = ({ value, textColor, ...rest }) => (
	<Chip
		label={
			<Typography variant='caption' style={{ color: textColor }}>
				{value}
			</Typography>
		}
		size='small'
		clickable={false}
		style={{ background: '#f1f1f1' }}
		{...rest}
	/>
);

export const SituacaoRegistroExtrato = ({ situacao }) => {
	const theme = useTheme();
	switch (situacao) {
		case 'C':
			return <SituacaoChip value='Conciliado' textColor={theme.palette.primary.dark} />;
		case 'N':
			return (
				<TooltipIcon title='Lançamento encontrado'>
					<SignalCellular4BarIcon />
				</TooltipIcon>
			);
		case 'I':
			return (
				<TooltipIcon title='Lançamento não encontrado'>
					<SignalCellular0BarIcon />
				</TooltipIcon>
			);
		default:
			return <SituacaoChip value={situacao} />;
	}
};
