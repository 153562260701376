import React from 'react';

import { useNotify } from 'react-admin';

import { Box, TextField, MenuItem, Button } from '@material-ui/core';

import { ModalSizeEditableContext, ModalSizeEditableContextProvider } from '../ModalSizeEditableContext';

import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

import MUIRichTextEditor from 'mui-rte';
import { useDataProvider } from 'react-admin';

import { ModalCreateEditTemplateBox } from './components/Modal/ModalCreateEditTemplateBox';
import TextInputMention from './components/TextInputMention/TextInputMention';
import { ShowTags } from './components/ShowTags/ShowTags';
import { tagsEditorText } from './mentions/mentions';

const MenuItemAddNewTemplate = ({ setReload }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	return (
		<MenuItem
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalCreateEditTemplateBox action='create' setReload={setReload} />,
				}));
			}}
		>
			Criar novo modelo de e-mail
		</MenuItem>
	);
};

const ButtonEditTemplate = ({ optionSelected, setReload }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	return (
		<Button
			style={{ marginLeft: '10px' }}
			color='primary'
			size='small'
			variant='contained'
			startIcon={<EditIcon />}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalCreateEditTemplateBox action='edit' template={optionSelected} setReload={setReload} />
					),
				}));
			}}
		>
			Editar
		</Button>
	);
};

const EmailTemplate = ({ handleSave = () => {} }) => {
	// Variaveis
	const textRef = React.useRef();
	const [optionSelected, setOptionSelected] = React.useState('');
	const [anchorEditor, setAnchorEditor] = React.useState(null);
	const [assuntoEmail, setAssuntoEmail] = React.useState('');
	const [assuntoEmailParse, setAssuntoEmailParse] = React.useState('');
	const [textoAssunto, setTextoAssunto] = React.useState('');
	const [templates, setTemplates] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [reload, setReload] = React.useState(0);
	const dP = useDataProvider();
	const notify = useNotify();

	// Funções Handle
	const handleChangeSelectedTemplate = (event) => {
		const selectedTemplate = event.target.value;
		if (!selectedTemplate) return;
		const assuntoParse = {
			blocks: [
				{
					data: {},
					depth: 0,
					entityRanges: [],
					inlineStyleRanges: [],
					key: '2u8pb',
					text: selectedTemplate.assunto,
					type: 'unstyled',
				},
			],
			entityMap: {},
		};
		setOptionSelected(selectedTemplate);
		setAssuntoEmail(JSON.stringify(assuntoParse));
		setTextoAssunto(JSON.stringify(selectedTemplate.mensagem_json));
	};

	const handleSaveAssunto = (data) => {
		const { blocks } = JSON.parse(data);
		const assuntoText = blocks[0]?.text;
		setAssuntoEmailParse(assuntoText);
	};

	React.useEffect(() => {
		// eslint-disable-next-line
		textRef.current?.save();
	}, [optionSelected, assuntoEmailParse]);

	// useEffect para coletar os templates do DB
	const getTemplatesDB = () => {
		setLoading(true);
		dP.getSimple('email_template')
			.then((res) => {
				const { results } = res?.data ?? { results: [] };
				const verifyResult = {
					id: -1,
					tipo: 'B',
					nome: 'Modelo de E-mail Padrão.',
					assunto: 'Boleto Condominio {{condominio_nome}} ref.{{data_referencia}}',
					mensagem_json: {
						blocks: [
							{
								key: 'e0bdt',
								data: {},
								text: 'Prezado(a) proprietário(a) {{responsavel_nome}}, Segue em anexo seu boleto com vencimento em {{data_vencimento}} referente a {{data_referencia}}, {{grupo_nome}} - {{unidade_nome}} - {{unidade_tipo}}.',
								type: 'unstyled',
								depth: 0,
								entityRanges: [],
								inlineStyleRanges: [],
							},
						],
						entityMap: {},
					},
				};
				results.unshift(verifyResult);
				setTemplates(results);
				setTextoAssunto('');
				setAssuntoEmail('');
				setOptionSelected('');
			})
			.catch(() => {
				notify('Erro ao coletar templates do servidor', 'warning');
			})
			.finally(() => setLoading(false));
	};
	React.useEffect(getTemplatesDB, [reload]);

	return (
		<ModalSizeEditableContextProvider
			customDialogProps={{
				minWidth: '61vw',
				position: 'static',
				overflowY: 'auto',
			}}
		>
			<Box>
				<Box
					style={{
						marginBottom: '15px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box flex={1}>
						<TextField
							id='outlined-select-currency'
							size='small'
							select
							label={loading ? 'Carregando...' : 'Selecionar modelo de e-mail personalizado:'}
							value={optionSelected}
							disabled={loading}
							onChange={handleChangeSelectedTemplate}
							variant='outlined'
							style={{ width: '100%' }}
						>
							<MenuItemAddNewTemplate setReload={setReload} />
							{templates.map((option) => (
								<MenuItem key={option.id} value={option}>
									{option.nome}
								</MenuItem>
							))}
						</TextField>
					</Box>
					{optionSelected.id > 0 && (
						<ButtonEditTemplate optionSelected={optionSelected} setReload={setReload} />
					)}
				</Box>
				{optionSelected && !loading && (
					<>
						<TextInputMention assuntoText={assuntoEmail} handleSaveAssunto={handleSaveAssunto} />
						<Box
							style={{
								backgroundColor: 'white',
								color: 'black',
							}}
							border={1}
							borderRadius='borderRadius'
						>
							{anchorEditor && (
								<ShowTags
									open={Boolean(anchorEditor)}
									anchor={anchorEditor}
									setAnchorEditor={setAnchorEditor}
								/>
							)}
							<MUIRichTextEditor
								label="Digite '@' para utilizar as tags..."
								defaultValue={textoAssunto ?? ''}
								inlineToolbar
								toolbarButtonSize='small'
								ref={textRef}
								onSave={(data) => handleSave(data, assuntoEmailParse)}
								onChange={() => textRef.current.save()}
								maxLength={1500}
								controls={[
									'title',
									'bold',
									'italic',
									'underline',
									'strikethrough',
									'bulletList',
									'numberList',
									'undo',
									'redo',
									'quote',
									'code',
									'clear',
									'tags',
								]}
								customControls={[
									{
										name: 'tags',
										icon: <InfoIcon />,
										type: 'callback',
										onClick: (editorState, name, anchor) => setAnchorEditor(anchor),
									},
								]}
								autocomplete={{
									strategies: [
										{
											items: tagsEditorText,
											triggerChar: '@',
										},
									],
								}}
							/>
						</Box>
					</>
				)}
			</Box>
		</ModalSizeEditableContextProvider>
	);
};

export default EmailTemplate;
