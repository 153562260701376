import React from 'react';

import { Chip, Typography, styled } from '@material-ui/core';

const TypographyLabelChip = styled(Typography)(({ theme, $temValor }) => ({
	color: $temValor ? theme.palette.primary.main : theme.palette.error.dark,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
	background: theme.fundo[100],
}));

const LabelChip = ({ value, $temValor, ...rest }) => {
	return (
		<StyledChip
			label={
				<TypographyLabelChip variant='caption' $temValor={$temValor}>
					{value}
				</TypographyLabelChip>
			}
			size='small'
			clickable={false}
			{...rest}
		/>
	);
};

const LabelField = ({ record, source, noValueMessage = '' }) => {
	const value = record?.[source];
	if (!(value || noValueMessage?.length || noValueMessage)) return <></>;
	return <LabelChip value={value ? value : noValueMessage} $temValor={Boolean(value)} />;
};

export default LabelField;
