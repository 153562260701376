import React, { useState, useEffect } from 'react';
import { FormlessInputDateReferencia } from 'components/common/InputDate';
import { TextField, Paper, Typography, Box } from '@material-ui/core';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from 'components/common/CurrencyInput';
import { ButtonCancel } from 'components/common/commonComponentsTSX';
import { useCallback } from 'react';
import decimalAdjust from 'components/common/decimalAdjust';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';
import { useNotify } from 'react-admin';

const decimalAdjustRound = (value, roundDigitNumber = -2, type = 'round') =>
	decimalAdjust(type, value, roundDigitNumber);
const decimalAdjustFloor = (value, roundDigitNumber = -2) => decimalAdjustRound(value, roundDigitNumber, 'floor');

const formatReferenciaToDisplay = (referencia) => {
	if (!referencia) return '';
	const [month, year] = referencia.split('/');
	return `${year}/${month}/01`;
};

const ValorField = ({
	label = 'Valor',
	value = 0,
	onChange = () => {},
	disabled = false,
	isRequired = false,
	inputProps = { prefix: 'R$ ' },
}) => (
	<TextField
		size='small'
		margin='dense'
		{...{
			label,
			value,
			disabled,
			onChange,
			isRequired,
		}}
		inputProps={{
			...sanitizeListNumberFormatBRLProps(inputProps),
			style: { textAlign: 'right' },
		}}
		InputProps={{
			inputComponent: NumberFormatBRL,
		}}
	/>
);
const ValorPendenteField = ({ valorPendente, setValorPendente, valorDisponivel }) => {
	return (
		<ValorField
			label='Valor'
			value={valorPendente}
			onChange={(event) => {
				const newValue = event?.target?.value || 0;
				setValorPendente(valorDisponivel > newValue ? newValue : valorDisponivel);
			}}
			isRequired={true}
		/>
	);
};

const ModalEdicaoAcordoParcelas = ({
	parcelaSelecionada,
	acordoParcelas,
	valorTotal,
	setAcordoParcelas,
	setModalValue,
}) => {
	const notify = useNotify();
	const [novoValor, setNovoValor] = useState(parcelaSelecionada ? parcelaSelecionada.valor : 0);
	const [novaReferencia, setNovaReferencia] = useState(null);
	const [novoPercentual, setNovoPercentual] = useState(0);

	useEffect(() => {
		if (valorTotal > 0) {
			const novoPercentual = (novoValor / valorTotal) * 100;
			setNovoPercentual(novoPercentual.toFixed(2));
		}
	}, [novoValor, valorTotal]);

	useEffect(() => {
		const referenciaDate = formatReferenciaToDisplay(parcelaSelecionada.referencia);
		setNovaReferencia(referenciaDate);
	}, [parcelaSelecionada.referencia]);

	// Por favor nao exclua os comentários
	const recalcularParcelas = useCallback(() => {
		// Atualiza a parcela selecionada
		const parcelasAtualizadas = acordoParcelas.map((parcela) => {
			if (parcela.numero_parcela !== parcelaSelecionada.numero_parcela) {
				return parcela;
			}
			return {
				...parcela,
				editada: true,
				valor: parseFloat(novoValor.toFixed(2)), // Garantindo que o valor seja um número
				percentual: novoPercentual, // O percentual da parcela editada pode ser mantido como o novo percentual.
			};
		});

		// Filtra as parcelas já editadas
		const parcelasEditadas = parcelasAtualizadas.filter((parcela) => parcela.editada);

		// Calcula o valor total das parcelas editadas
		const valorParcelasEditadas = parcelasEditadas.reduce((acc, parcela) => acc + parseFloat(parcela.valor), 0);

		// Calcula o valor restante a ser distribuído entre as parcelas não editadas
		let valorRestante = valorTotal - valorParcelasEditadas;

		// Seleciona as parcelas não editadas
		const parcelasNaoEditadas = parcelasAtualizadas.filter(
			(parcela) => !parcela.editada && parcela.numero_parcela !== parcelaSelecionada.numero_parcela
		);

		// Se não há parcelas restantes, retorna as parcelas já atualizadas
		if (parcelasNaoEditadas.length <= 0) {
			return parcelasAtualizadas;
		}

		// Calcula o valor por parcela para as parcelas restantes
		let valorPorParcelaRestante = decimalAdjustFloor(valorRestante / parcelasNaoEditadas.length, -2);

		// Atualiza todas as parcelas não editadas com o novo valor e recalcula a porcentagem
		const parcelasFinalizadas = parcelasAtualizadas.map((parcela) => {
			// Mantém as parcelas já editadas ou a selecionada com seus valores originais
			if (parcela.editada || parcela.numero_parcela === parcelaSelecionada.numero_parcela) {
				return parcela;
			}

			// Para parcelas não editadas, adicionamos o valor distribuído
			let valorParcelaAjustado = parseFloat(valorPorParcelaRestante.toFixed(2));

			return {
				...parcela,
				valor: valorParcelaAjustado,
				percentual: decimalAdjustRound((valorParcelaAjustado / valorTotal) * 100, -2), // Recalcula a porcentagem com o novo valor
			};
		});

		// Ajusta a última parcela que ainda não foi editada e não é a selecionada
		const valorTotalParcelasFinalizadas = parcelasFinalizadas.reduce(
			(acc, parcela) => acc + parseFloat(parcela.valor),
			0
		);
		const diferencaCentavos = parseFloat((valorTotal - valorTotalParcelasFinalizadas).toFixed(2));

		// Se há diferença em centavos, aplicamos na última parcela não editada e que não é a selecionada
		if (diferencaCentavos !== 0) {
			// Encontrar a última parcela não editada e que não seja a selecionada
			const parcelasNaoEditadas = parcelasFinalizadas
				.filter((parcela) => !parcela.editada && parcela.numero_parcela !== parcelaSelecionada.numero_parcela)
				.pop(); // Seleciona a última parcela não editada válida

			// Se encontramos uma parcela válida, aplicamos o ajuste
			if (parcelasNaoEditadas) {
				parcelasNaoEditadas.valor = parseFloat(
					(parseFloat(parcelasNaoEditadas.valor) + diferencaCentavos).toFixed(2)
				);

				// Recalcula a porcentagem da última parcela ajustada
				parcelasNaoEditadas.percentual = decimalAdjustRound((parcelasNaoEditadas.valor / valorTotal) * 100, -2);
			}
		}

		return parcelasFinalizadas;
	}, [valorTotal, novoValor, novaReferencia, novoPercentual, parcelaSelecionada, acordoParcelas]);

	const parcelasEditadas = acordoParcelas.filter((parcela) => parcela.editada);
	const valorParcelasEditadas = parcelasEditadas.reduce((acc, parcela) => acc + parseFloat(parcela.valor), 0);
	const valorDisponivel =
		parcelaSelecionada.editada == true
			? parseFloat(valorTotal - valorParcelasEditadas + parcelaSelecionada.valor)
			: parseFloat(valorTotal - valorParcelasEditadas);

	const handleProximo = () => {
		const novasParcelas = recalcularParcelas(acordoParcelas);
		const somaParcelas = novasParcelas?.reduce((acc, parcela) => acc + parcela?.valor, 0);

		if (novasParcelas.length > 0) {
			if (somaParcelas.toFixed(2) == valorTotal.toFixed(2)) {
				setAcordoParcelas(novasParcelas);
				setModalValue((v) => ({
					...v,
					open: false,
				}));
				return notify('Parcela editada com sucesso', {
					type: 'success',
				});
			} else {
				return notify(
					`A soma das parcelas R$ ${somaParcelas
						.toFixed(2)
						.replace('.', ',')
						.replace(
							/\B(?=(\d{3})+(?!\d))/g,
							'.'
						)} não corresponde ao valor total do acordo: R$ ${valorTotal
						.toFixed(2)
						.replace('.', ',')
						.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}.`,
					{ type: 'error' }
				);
			}
		} else {
			setModalValue((v) => ({
				...v,
				open: false,
			}));
			return notify('Falha ao editar parcela', {
				type: 'error',
			});
		}
	};

	const closeModalAndBackStep = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	return (
		<Paper style={{ padding: 25 }}>
			<Typography variant='h6' gutterBottom>
				Editar Parcela
			</Typography>
			<Box display={'flex'} alignItems={'center'} flexDirection={'row'} style={{ gap: '20px' }}>
				<FormlessInputDateReferencia
					label='Referência'
					value={novaReferencia}
					disabled={true}
					format='MM/yyyy'
					helperText={''}
					size='small'
				/>
				<ValorPendenteField
					label='Valor da Parcela'
					valorPendente={novoValor}
					setValorPendente={setNovoValor}
					valorDisponivel={valorDisponivel}
				/>
				<TextField label='Percentual' size='small' value={`${novoPercentual}%`} disabled margin='dense' />
			</Box>
			<Box display='flex' justifyContent='flex-end' gridGap={'1em'} mt={'40px'}>
				<ButtonCancel onClick={closeModalAndBackStep} />
				<ButtonConfirmV2 onClick={handleProximo} />
			</Box>
		</Paper>
	);
};

export default ModalEdicaoAcordoParcelas;
