import React, { createContext } from 'react';
import { FiliacoesContextValue } from './type';
import { FiliacaoData } from '../types';

const FiliacoesContext = createContext<FiliacoesContextValue>({
	filiacoes: [],
	setFiliacoes: () => {},
});

const FiliacoesProvider: React.FC = React.memo(({ children }) => {
	const [filiacoes, setFiliacoes] = React.useState<FiliacaoData[]>([]);

	const providerValue = React.useMemo(
		() => ({
			filiacoes,
			setFiliacoes,
		}),
		[filiacoes, setFiliacoes]
	);

	return <FiliacoesContext.Provider value={providerValue}>{children}</FiliacoesContext.Provider>;
});

export const useFiliacoesContext = () => React.useContext(FiliacoesContext);
export default FiliacoesProvider;
