import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { SaveButton } from 'components/common/commonComponentsTSX';
import React from 'react';
import { useFormState } from 'react-final-form';

interface BoxPersonalizadoProps {
	handleSubmitWithRedirect: (basePath?: string) => void;
	basePath?: string;
	[key: string]: any;
}

export const BoxConfirmUpdatePersonalizado: React.FC<BoxPersonalizadoProps> = ({
	handleSubmitWithRedirect,
	...props
}) => {
	const handleClickOpen = () => {
		handleSubmitWithRedirect(props.basePath);
	};

	return (
		<SaveButton onClick={handleClickOpen} size='small' disabled={false}>
			Salvar
		</SaveButton>
	);
};

export const BoxEnvioNotificacaoCriacaoTarefa: React.FC<BoxPersonalizadoProps> = ({
	handleSubmitWithRedirect,
	...props
}) => {
	const [handleSave, setHandleSave] = React.useState(true);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const { values } = useFormState();

	const handleClickOpen = () => {
		return setOpen(true);
	};
	const handleClose = () => {
		return setOpen(false);
	};
	const handleCreateTarefaManutencao = (sendEmail = true) => {
		if (loading) return;
		values['enviar_notificacao_na_criacao'] = sendEmail;
		setLoading(true);
		handleSubmitWithRedirect(props.basePath);
	};

	React.useEffect(() => {
		if (
			values.condominio &&
			values.nome_tarefa &&
			values.principal_responsavel &&
			values.data_entrega &&
			values.data_vencimento &&
			values.observacao != ''
		) {
			setHandleSave(false);
		} else {
			setHandleSave(true);
		}
	}, [values]);

	return (
		<>
			<SaveButton onClick={handleClickOpen} disabled={handleSave} size='small'>
				Salvar
			</SaveButton>
			{open === true ? (
				<>
					<Dialog open={open} disableBackdropClick={true}>
						<DialogTitle id='alert-dialog-title'>
							{'Envio da notificação via e-mail e aplicativo'}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>
								Deseja enviar uma notificação de criação desta Tarefa e Manutenção por e-mail e
								aplicativo ?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancelar</Button>
							<Button onClick={() => handleCreateTarefaManutencao(false)} color='primary'>
								Não Enviar e Salvar
							</Button>
							<Button onClick={() => handleCreateTarefaManutencao(true)} color='primary' autoFocus>
								Enviar
							</Button>
						</DialogActions>
					</Dialog>
				</>
			) : (
				<></>
			)}
		</>
	);
};
