import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import { FinalFormCurrencyInput } from '../../../../common/CurrencyInput';

const FaixaLeitura = ({ disabled }) => {
	return (
		<>
			<FieldArray name='faixas_leitura'>
				{({ fields }) =>
					fields.value.map((faixa, index) => (
						<div style={{ 'margin-top': '1%' }}>
							<FinalFormCurrencyInput
								name={`faixas_leitura[${index}].valor`}
								id={`faixa_valor_${index}`}
								value={faixa.valor}
								label='Valor'
								prefix='R$'
								disabled={disabled}
								onChange={(event) => {
									if (!event.target.value) event.target.value = 0;
									if (event.target.value > 999999999.999999) event.target.value = 999999999.999999;
								}}
								decimalScale={6}
								style={{ 'margin-right': '0.5%' }}
								margin='dense'
							/>
							<FinalFormCurrencyInput
								name={`faixas_leitura[${index}].taxa_fixa_adicional`}
								id={`faixa_taxa_fixa_adicional_${index}`}
								value={faixa.taxa_fixa_adicional}
								label='Taxa fixa adicional'
								prefix='R$'
								disabled={disabled}
								onChange={(event) => {
									if (!event.target.value) event.target.value = 0;
									if (event.target.value > 999999999.999999) event.target.value = 999999999.999999;
								}}
								decimalScale={6}
								style={{ 'margin-left': '0.5%' }}
								margin='dense'
							/>
						</div>
					))
				}
			</FieldArray>
		</>
	);
};

export default FaixaLeitura;
