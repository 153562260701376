import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useGetList } from 'react-admin';

import { TextField, Table, TableContainer, TableRow, TableBody, TableCell, TableHead } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { CondominiosContext } from '../../../../../context/CondominioContextProvider';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../../common/CurrencyInput';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from '../../../../common/ModalContext';
import SyndkosTablePagination from 'components/common/Table/SyndkosTablePagination';

const ValorUnidadeInput = ({ unidade_id, index, valor, handleChangeValor, ...props }) => {
	const inputRef = useRef();
	return (
		<TextField
			key={`key_valor_unidade_${unidade_id}`}
			inputRef={inputRef}
			id={`valor_unidade_${unidade_id}`}
			margin='dense'
			name='valor_unidade'
			onChange={(event) => {
				handleChangeValor(`${event.target.value}`, index);
			}}
			inputProps={sanitizeListNumberFormatBRLProps({
				prefix: 'R$ ',
				fixedDecimalScale: true,
				allowNegative: false,
				defaultValue: valor,
				...props,
			})}
			InputProps={{
				inputComponent: NumberFormatBRL,
			}}
			fullWidth={true}
		/>
	);
};

const useStylesRow = makeStyles((theme) => ({
	cellInvalid: {
		color: theme.red[200],
	},
}));

const ListaUnidadeRow = ({ key, row, handleChangeValor }) => {
	const classes = useStylesRow();
	const contentCellRow = [row?.grupo, row?.tipo, row?.nome].filter((p) => p).join(' - ');
	return (
		<TableRow tabIndex={-1} key={key}>
			<TableCell
				key='unidade'
				id={`nome-${key}`}
				{...(!parseFloat(row.valor) ? { className: classes.cellInvalid } : {})}
			>
				{contentCellRow}
			</TableCell>
			<TableCell
				id={`valor-${key}`}
				key='valor'
				{...(!parseFloat(row.valor) ? { className: classes.cellInvalid } : {})}
			>
				<ValorUnidadeInput
					unidade_id={row.unidade}
					valor={row.valor}
					index={row.index}
					handleChangeValor={handleChangeValor}
				/>
			</TableCell>
		</TableRow>
	);
};

const TableListaUnidade = ({ configUnidadesArrecadacao = [], setConfigUnidadesArrecadacao, data, ids }) => {
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);

	const configRows = () => {
		const getValor = (unidade_id) =>
			(configUnidadesArrecadacao || []).find((config) => config.unidade === unidade_id)?.valor;
		const unidades = (ids || [])
			.filter((id) => !!(data || {})[id])
			.map((id, index) => {
				const unidade = (data || {})[id];
				return {
					unidade: unidade.id,
					valor: getValor(unidade.id) || '0.00',
					nome: unidade.unidade,
					index: index,
					grupo: unidade.nome_grupo,
					tipo: unidade.tipo_unidade,
				};
			});
		setTotal(unidades.length);
		setRows(unidades);
		setConfigUnidadesArrecadacao(unidades.filter((u) => parseFloat(u.valor)));
	};
	useEffect(configRows, []);

	const handleChangeValor = (valor, index) => {
		rows[index].valor = valor;
		setRows(rows);
		setConfigUnidadesArrecadacao(rows.filter((sr) => !!parseFloat(sr?.valor)));
	};

	return (
		<TableContainer>
			<Table size='small'>
				<TableHead>
					<TableRow key='lista_unidade_row_nome_valor'>
						<TableCell key='nome' style={{ paddingLeft: 0 }}>
							Unidade
						</TableCell>
						<TableCell key='valor'>Valor</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.slice((page - 1) * perPage, page * perPage).map((row, index) => (
						<ListaUnidadeRow
							{...{
								key: `lista_unidade_row_${index}`,
								row,
								handleChangeValor,
							}}
						/>
					))}
					<TableRow key='lista_unidade_row_nome_valor'>
						<TableCell
							key='nome'
							style={{ paddingLeft: 0, color: '#0b0d0d', fontWeight: 500, lineHeight: '1.5rem' }}
						>
							Unidade
						</TableCell>
						<TableCell key='valor' style={{ color: '#0b0d0d', fontWeight: 500, lineHeight: '1.5rem' }}>
							Valor
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<SyndkosTablePagination
				{...{
					handlePageChange,
					page,
					perPage,
					total,
					setPerPage,
				}}
				labelRowsPerPage='Unidades por página'
			/>
		</TableContainer>
	);
};

export const BoxListaUnidade = (props) => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { data, ids, loading } = useGetList(
		'unidades',
		{ page: 1, perPage: 10000 },
		{ field: 'grupo_e_unidade', order: 'ASC' },
		{
			id_condominio: condominioSelecionado?.id,
			situacao: 'A',
		}
	);

	return !loading ? (
		<TableListaUnidade {...{ ...props, data, ids }} />
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

const ModalListaUnidade = ({ setValor, configUnidadesArrecadacao: value, setConfigUnidadesArrecadacao: setValue }) => {
	const { setModalValue } = useContext(ModalContext);
	const [configUnidadesArrecadacao, setConfigUnidadesArrecadacao] = useState(value || []);
	const handleSubmit = () => {
		setValue(configUnidadesArrecadacao);
		const valorSomado = configUnidadesArrecadacao.reduce((total, c) => total + parseFloat(c.valor), 0);
		setValor(parseFloat(valorSomado.toFixed('2')));
		setModalValue((v) => ({ ...v, open: false }));
	};
	return (
		<CustomDialogBody
			title='Alterar Valores'
			form={{
				handleSubmit,
				valid: configUnidadesArrecadacao?.length,
				component: (
					<Box>
						<BoxListaUnidade
							{...{
								configUnidadesArrecadacao,
								setConfigUnidadesArrecadacao,
							}}
						/>
					</Box>
				),
			}}
		/>
	);
};

const BotaoEditarArrec = (props) => {
	const { setModalValue } = useContext(ModalContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalListaUnidade {...props} />,
		}));
	};
	return (
		<Button color='default' onClick={handleClick}>
			Alterar Valores
		</Button>
	);
};

export const BoxButtonModalListaUnidade = (props) => {
	return (
		<ModalContextProvider>
			<BotaoEditarArrec {...props} />
			<CustomDialog onClose={() => {}} />
		</ModalContextProvider>
	);
};
