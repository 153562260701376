import React from 'react';

import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';

const BoxConfigDemonstrativoArrecadacao = () => {
	const {
		opcoesImpressao: { imprimir_data_leituras, imprimir_coeficiente, ocultar_valor_m3_e_taxa },
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	return (
		<>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_data_leituras}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_data_leituras')}
							name='imprimir_data_leituras'
						/>
					}
					label='Imprimir data das leituras'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_coeficiente}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_coeficiente')}
							name='imprimir_coeficiente'
						/>
					}
					label='Imprimir coeficiente das arrecadações'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={ocultar_valor_m3_e_taxa}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'ocultar_valor_m3_e_taxa')}
							name='ocultar_valor_m3_e_taxa'
						/>
					}
					label='Ocultar valor por m³ e taxa'
				/>
			</FormGroup>
		</>
	);
};

export default BoxConfigDemonstrativoArrecadacao;
