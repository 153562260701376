import React from 'react';
import { CondominiosContext } from '../../../../../../context/CondominioContextProvider';

import { useDataProvider, useNotify } from 'react-admin';

import {
	Box,
	TextField,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	LinearProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MultiSelect from 'components/common/Selects';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';
import { createFormatsOptionsAutoCompleteComponent } from 'components/common/Selects/formats';

const formatsParaUnidades = createFormatsOptionsAutoCompleteComponent('formatsUnidades');

const TIPO_ARRECADACAO_PARA = {
	condominio: '0',
	grupos: '1',
	unidades: '2',
	tipos: '4',
	lista: '5',
};

const ArrecadacaoPara = ({
	arrecadacaoPara,
	gruposIds,
	setArrecadacaoPara,
	unidadesOptions,
	tipoArrecadacao,
	tiposIds,
}) => (
	<Box flexDirection='row' display='flex'>
		<FormControl color='primary'>
			<FormLabel>Ratear entre</FormLabel>
			<RadioGroup row={true} onChange={(e) => setArrecadacaoPara(e.target.value)} value={arrecadacaoPara}>
				<FormControlLabel value='0' control={<Radio color='primary' />} label='Condomínio' />
				<FormControlLabel
					value='2'
					control={<Radio color='primary' />}
					disabled={!((unidadesOptions?.filter((id) => !!id) || [])?.length > 0)}
					label='Unidades'
				/>
				{tipoArrecadacao !== 'DESPESA' && (
					<FormControlLabel
						value='5'
						control={<Radio color='primary' />}
						disabled={!((unidadesOptions?.filter((id) => !!id) || [])?.length > 0)}
						label='Lista'
					/>
				)}
				<FormControlLabel
					value='1'
					control={<Radio color='primary' />}
					disabled={!((gruposIds?.filter((id) => !!id) || [])?.length > 0)}
					label='Grupos'
				/>
				<FormControlLabel
					value='4'
					control={<Radio color='primary' />}
					disabled={!((tiposIds?.filter((id) => !!id) || [])?.length > 1)}
					label='Tipos'
				/>
			</RadioGroup>
		</FormControl>
	</Box>
);

const TipoDivisao = ({ arrecadacaoPara, idsUnidades, setTipoDivisao, tipoDivisao }) => (
	<Box flexDirection='row' display='flex'>
		<FormControl color='primary'>
			<FormLabel>Selecione a forma de rateio</FormLabel>
			<RadioGroup onChange={(e) => setTipoDivisao(e.target.value)} value={tipoDivisao}>
				<FormControlLabel
					value='0'
					control={<Radio color='primary' />}
					label='Dividir conforme coeficiente de cada unidade'
					disabled={arrecadacaoPara === '2' && (idsUnidades || [])?.length === 1}
				/>
				<FormControlLabel
					value='1'
					control={<Radio color='primary' />}
					label='Dividir pelo número de unidades'
					disabled={arrecadacaoPara === '2' && (idsUnidades || [])?.length === 1}
				/>
				<FormControlLabel
					value='2'
					control={<Radio color='primary' />}
					label='Lançar valor igual para todas as unidades'
				/>
			</RadioGroup>
		</FormControl>
	</Box>
);

export const BoxArrecadacaoParaTipoDivisao = ({
	arrecadacaoPara,
	gruposUnidade,
	idsUnidades,
	record,
	setArrecadacaoPara,
	setIdsUnidades,
	setTipoDivisao,
	setTiposUnidades,
	setGruposUnidade,
	tipoArrecadacao,
	tipoDivisao,
	tiposUnidades,
}) => {
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const { options: unidadesOptions } = useRequestOptionsUnidades();
	const [unidadesSelecionadas, setUnidadesSelecionadas] = React.useState([]);
	const [gruposIds, setGruposIds] = React.useState([]);
	const [grupos, setGrupos] = React.useState([]);
	const [tiposIds, setTiposIds] = React.useState([]);
	const [tipos, setTipos] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [optionsGrupoTipo, setOptionsGrupoTipo] = React.useState([]);
	const dp = useDataProvider();
	const notify = useNotify();
	const naoDeveRenderizarSelectGrupoOuTipo = [
		TIPO_ARRECADACAO_PARA.condominio,
		TIPO_ARRECADACAO_PARA.unidades,
		TIPO_ARRECADACAO_PARA.lista,
	].includes(arrecadacaoPara);

	const getOptions = () => {
		setLoading(true);
		Promise.allSettled([
			dp
				.getList('grupo_unidade_condominio', {
					pagination: {
						perPage: 10000,
						page: 1,
					},
					sort: {
						order: 'ASC',
						field: 'id',
					},
					filter: {
						id_condominio: condominioSelecionado?.id,
					},
				})
				.catch(() => {
					notify('Sem grupos de unidades de condominios cadastradas.', 'warning');
					return [];
				}),
			dp
				.getList('tipo_unidade', {
					pagination: {
						perPage: 10000,
						page: 1,
					},
					sort: {
						order: 'ASC',
						field: 'descricao',
					},
					filter: {
						unidades__id_condominio: condominioSelecionado?.id,
					},
				})
				.catch(() => {
					notify('Sem tipos de unidades cadastradas.', 'warning');
					return [];
				}),
		]).then(([grupos, tipos]) => {
			setGrupos(grupos.value.data);
			setGruposIds(grupos.value.data.map((v) => v.id));
			setTipos(tipos.value.data);
			setTiposIds(tipos.value.data.map((v) => v.id));
			setLoading(false);
		});
	};
	React.useEffect(getOptions, [arrecadacaoPara]);

	const updateOptions = () => {
		if (['1', '2', '4'].includes(arrecadacaoPara)) {
			if (arrecadacaoPara === TIPO_ARRECADACAO_PARA.grupos && grupos) setOptionsGrupoTipo(grupos);
			if (arrecadacaoPara === TIPO_ARRECADACAO_PARA.unidades && unidadesOptions) {
				setUnidadesSelecionadas(unidadesOptions?.filter((v) => idsUnidades.includes(v.id)));
			}
			if (arrecadacaoPara === TIPO_ARRECADACAO_PARA.tipos && tipos) setOptionsGrupoTipo(tipos);
		}
	};

	const handleSetUnidadesSelecionadas = () => {
		const idsUnidadesFiltered = unidadesOptions?.filter((u) => idsUnidades.includes(u.id));
		setUnidadesSelecionadas(idsUnidadesFiltered);
	};

	const handleSetIdsSelecionados = () => {
		if (!unidadesSelecionadas.length) return;
		setIdsUnidades(unidadesSelecionadas.map(({ id }) => id));
	};
	React.useEffect(handleSetIdsSelecionados, [unidadesSelecionadas]);

	React.useEffect(handleSetUnidadesSelecionadas, [unidadesOptions]);

	React.useEffect(updateOptions, [unidadesOptions, grupos, tipos, arrecadacaoPara]);

	const casoApenasUmaUnidadeSelecionada = () => {
		if (arrecadacaoPara === TIPO_ARRECADACAO_PARA.unidades && !(idsUnidades.length > 1))
			setTipoDivisao(TIPO_ARRECADACAO_PARA.unidades);
	};

	React.useEffect(casoApenasUmaUnidadeSelecionada, [arrecadacaoPara, idsUnidades.length]);

	const casoApenasUmTipo = () => {
		if (!loading && arrecadacaoPara === TIPO_ARRECADACAO_PARA.tipos && tiposIds && !(tiposIds.length > 1))
			setArrecadacaoPara(TIPO_ARRECADACAO_PARA.condominio);
	};
	React.useEffect(casoApenasUmTipo, [arrecadacaoPara, tiposIds]);

	return (
		<>
			{!loading ? (
				<>
					<ArrecadacaoPara
						{...{
							arrecadacaoPara,
							gruposIds,
							setArrecadacaoPara,
							unidadesOptions,
							tipoArrecadacao,
							tiposIds,
						}}
					/>
					{record.tipo_lancamento !== 'PRC' && arrecadacaoPara !== TIPO_ARRECADACAO_PARA.lista && (
						<TipoDivisao
							{...{
								arrecadacaoPara,
								idsUnidades,
								setTipoDivisao,
								tipoDivisao,
							}}
						/>
					)}

					{arrecadacaoPara === TIPO_ARRECADACAO_PARA.unidades && (
						<Box>
							<MultiSelect
								variant={'formatsUnidades'}
								options={unidadesOptions}
								value={unidadesSelecionadas}
								setValue={setUnidadesSelecionadas}
							/>
						</Box>
					)}
					{!naoDeveRenderizarSelectGrupoOuTipo && (
						<Autocomplete
							multiple
							filterSelectedOptions
							options={optionsGrupoTipo || []}
							renderInput={(params) => (
								<TextField
									{...params}
									label={
										{
											1: 'Selecionar Grupos para o cálculo',
											4: 'Selecionar Tipos para o cálculo',
										}[arrecadacaoPara] || ''
									}
									margin='dense'
								/>
							)}
							renderOption={(option) => {
								const op =
									option?.nome || option?.descricao || option?.unidade
										? option
										: optionsGrupoTipo.find((v) => v.id === option);
								return (
									(op && (op?.nome || op?.descricao || formatsParaUnidades.formatOption(op))) || null
								);
							}}
							getOptionLabel={(option) => {
								const op = optionsGrupoTipo.find((v) => v.id === option);
								return (
									(op && (op?.nome || op?.descricao || formatsParaUnidades.formatOption(op))) || ''
								);
							}}
							noOptionsText='Nenhum resultado encontrado'
							getOptionSelected={(option, value) => value === option.id}
							value={
								{
									1: gruposUnidade,
									4: tiposUnidades,
								}[arrecadacaoPara] || []
							}
							onChange={(_, v) => {
								const funcReduce = (list, item) => {
									list.push(item?.id || item);
									return list;
								};
								const itens = (v || []).reduce(funcReduce, []);
								if (arrecadacaoPara === TIPO_ARRECADACAO_PARA.grupos) setGruposUnidade(itens);
								if (arrecadacaoPara === TIPO_ARRECADACAO_PARA.tipos) setTiposUnidades(itens);
							}}
							clearText='Limpar'
						/>
					)}
				</>
			) : (
				<LinearProgress />
			)}
		</>
	);
};
