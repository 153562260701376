import React, { useState, useRef, createContext, useContext, useMemo, useCallback, useEffect } from 'react';

import { useDataProvider, useNotify, useGetList } from 'react-admin';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { BotaoVoltar } from '../../common/buttons/BotaoVoltar';
import { BotaoProximo } from '../../common/buttons/BotaoProximo';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import CheckIcon from '@material-ui/icons/Check';
import { CurrencyTextFieldWithAdornment } from 'components/common/CurrencyInputWithAdornment';
import { Form } from 'react-final-form';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from '../../common/ModalSizeEditableContext';
import SavingOutlined from '../../common/icons/SavingOutlined';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../common/CurrencyInput';
import { SyndikosMuiPickersUtilsProvider } from '../../common/InputDate';
import { ContasBancariasListContext } from './ContasBancariasListContextProvider';

const NovaContaBancariaContext = createContext();

const NovaContaBancariaContextProvider = ({ id_condominio }) => {
	const { setModalValue, setMinWidthModel } = useContext(ModalSizeEditableContext);
	const [dadosNovaConta, setDadosNovaConta] = useState({
		id_condominio,
		imprimir_saldo_relatorios: true,
	});
	const [modalStep, setModalStep] = useState('1');
	useEffect(() => {
		if (['1', '2'].includes(modalStep)) setMinWidthModel('40vw');
		if (['CB'].includes(modalStep)) setMinWidthModel('45vw');
	}, [setMinWidthModel, modalStep]);
	const [stepComponents] = useState({
		1: <ModalNomeConta />,
		2: <ModalTipoConta />,
		CB: <ModalTipoContaBancaria />,
		INST: <ModalInstituicaoFinanceira />,
		SI: <ModalSaldoInicial />,
	});
	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false, dialogBody: <></> }));
	}, [setModalValue]);

	const handleCancel = useCallback(() => {
		if (window.confirm('Sair agora irá ocasionar na perda dos dados do cadastro. Deseja sair?')) closeModal();
	}, [closeModal]);

	const { data, loaded, ids } = useGetList(
		'instituicoes_financeiras',
		{ perPage: 10000, page: 1 },
		{ field: 'codigo', order: 'ASC' }
	);
	const [choicesInstituicoes, setChoicesInstituicoes] = useState([]);
	useEffect(() => {
		if (loaded) {
			setChoicesInstituicoes(
				ids.map((id) => {
					const { codigo, nome, has_integracao_api } = data[id];
					return {
						id,
						nome: `${codigo} - ${nome}`,
						has_integracao_api,
					};
				})
			);
		}
	}, [setChoicesInstituicoes, data, loaded]);

	const novaContaBancariaProviderValue = useMemo(
		() => ({
			dadosNovaConta,
			setDadosNovaConta,
			modalStep,
			setModalStep,
			closeModal,
			handleCancel,
			choicesInstituicoes,
		}),
		[dadosNovaConta, setDadosNovaConta, modalStep, setModalStep, closeModal, handleCancel, choicesInstituicoes]
	);

	return (
		<NovaContaBancariaContext.Provider value={novaContaBancariaProviderValue}>
			{stepComponents[modalStep]}
		</NovaContaBancariaContext.Provider>
	);
};

const ModalNomeConta = () => {
	const { setModalStep, setDadosNovaConta, dadosNovaConta, closeModal } = useContext(NovaContaBancariaContext);
	const [nomeConta, setNomeConta] = useState(dadosNovaConta.nome_conta || '');
	const handleNext = () => {
		setDadosNovaConta((v) => ({ ...v, nome_conta: nomeConta }));
		setModalStep('2');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Cadastrar Conta Bancária'
			text='Informe o nome da conta bancária'
			form={{
				component: (
					<TextField
						fullWidth
						label='Nome da Conta'
						value={nomeConta}
						onChange={(e) => setNomeConta((e.target.value || '').substr(0, 60))}
						style={{ marginTop: '8px' }}
						margin='dense'
					/>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={closeModal} />
					<BotaoProximo onClick={handleNext} disabled={!nomeConta.length || nomeConta.length > 60} />
				</>
			}
		/>
	);
};

const BoxButtonDecision = React.forwardRef(
	(
		{ children, onclick, onBlur, onFocus, onMouseLeave, onMouseOver, onTouchEnd, onTouchStart, title, placement },
		ref
	) => (
		<Box
			mt={1}
			mr={1}
			onBlur={onBlur}
			onFocus={onFocus}
			onMouseLeave={onMouseLeave}
			onMouseOver={onMouseOver}
			onTouchEnd={onTouchEnd}
			onTouchStart={onTouchStart}
			title={title}
			placement={placement}
			width='80px'
			height='80px'
			display='flex'
			justifyContent='center'
			ref={ref}
		>
			<Button variant='text' style={{ width: '100%', display: 'block' }} onClick={onclick}>
				{children}
			</Button>
		</Box>
	)
);

const ModalTipoConta = () => {
	const { setModalStep, setDadosNovaConta, handleCancel } = useContext(NovaContaBancariaContext);
	const handleBack = () => {
		setDadosNovaConta((v) => {
			const { tipo_conta, ...restante } = v;
			return restante;
		});
		setModalStep('1');
	};
	const handleContacaixa = () => {
		setDadosNovaConta((v) => {
			v['tipo_conta'] = 'C';
			return v;
		});
		setModalStep('SI');
	};
	const handleContaBancaria = () => {
		setDadosNovaConta((v) => {
			v['tipo_conta'] = 'CB';
			return v;
		});
		setModalStep('CB');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Cadastrar Conta Bancária'
			text='Selecione o tipo da Conta'
			form={{
				component: (
					<Box display='flex' justifyContent='center'>
						<Tooltip title='Caixa' placement='top'>
							<BoxButtonDecision onclick={handleContacaixa}>
								<AttachMoneyIcon style={{ 'font-size': '2.5em' }} />
							</BoxButtonDecision>
						</Tooltip>
						<Tooltip title='Conta Bancária' placement='top'>
							<BoxButtonDecision onclick={handleContaBancaria}>
								<SavingOutlined style={{ 'font-size': '2.5em' }} />
							</BoxButtonDecision>
						</Tooltip>
					</Box>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={handleCancel} />
					<BotaoVoltar onClick={handleBack} />
				</>
			}
		/>
	);
};

const ModalTipoContaBancaria = () => {
	const { setModalStep, setDadosNovaConta, dadosNovaConta, handleCancel } = useContext(NovaContaBancariaContext);
	const [tipoContaBancaria, setTipoContaBancaria] = useState(dadosNovaConta.tipo_conta_bancaria || 'CC');
	const handleBack = () => {
		setDadosNovaConta((v) => {
			const { tipo_conta_bancaria, ...restante } = v;
			return restante;
		});
		setModalStep('2');
	};
	const handleNext = () => {
		setDadosNovaConta((v) => {
			v['tipo_conta_bancaria'] = tipoContaBancaria;
			return v;
		});
		setModalStep('INST');
	};

	const tiposContaBancaria = [
		{ value: 'CC', text: 'Conta Corrente' },
		{ value: 'P', text: 'Poupança' },
		{ value: 'F', text: 'Fundo' },
		{ value: 'A', text: 'Aplicação' },
		{ value: 'CT', text: 'Cotas' },
	];

	return (
		<CustomDialogBodySizeEditable
			title='Cadastrar Conta Bancária'
			text='Selecione o tipo da Conta'
			form={{
				component: (
					<Box display='flex' justifyContent='center'>
						<FormControl component='fieldset'>
							<RadioGroup
								row
								name='tipos_conta_bancaria'
								value={tipoContaBancaria}
								onChange={(e) => setTipoContaBancaria(e.target.value)}
							>
								{tiposContaBancaria.map((tipo) => (
									<FormControlLabel
										key={tipo.value}
										value={tipo.value}
										control={<Radio />}
										label={tipo.text}
									/>
								))}
							</RadioGroup>
						</FormControl>
					</Box>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={handleCancel} />
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo
						onClick={handleNext}
						disabled={!tipoContaBancaria || !['CC', 'P', 'F', 'A', 'CT'].includes(tipoContaBancaria)}
					/>
				</>
			}
		/>
	);
};

const filterOptions = createFilterOptions({
	limit: 20,
	stringify: (option) =>
		option.has_integracao_api ? `${option.nome} - Integração automática - via API` : option.nome,
});
const ModalInstituicaoFinanceira = () => {
	const { setModalStep, setDadosNovaConta, dadosNovaConta, handleCancel, choicesInstituicoes } =
		useContext(NovaContaBancariaContext);
	const [idInstFinanceira, setIdInstFinanceira] = useState(dadosNovaConta.id_inst_financeira || null);
	const instituicaoFinanceiraRef = useRef(choicesInstituicoes.find((c) => c.id === idInstFinanceira));

	const handleBack = () => {
		setDadosNovaConta((v) => {
			const { id_inst_financeira, ...restante } = v;
			return restante;
		});
		setModalStep('CB');
	};
	const handleNext = () => {
		setDadosNovaConta((v) => {
			v['id_inst_financeira'] = idInstFinanceira;
			return v;
		});
		setModalStep('SI');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Cadastrar Conta Bancária'
			text='Selecione a Instituição Financeira'
			form={{
				component: (
					<Form
						onSubmit={(e) => {
							e.preventDefault();
						}}
						initialValues={{
							id_inst_financeira: dadosNovaConta.id_inst_financeira,
						}}
						render={() => (
							<Autocomplete
								disableClearable
								value={instituicaoFinanceiraRef.current}
								name='id_inst_financeira'
								onChange={(e, value) => {
									instituicaoFinanceiraRef.current = value;
									setIdInstFinanceira(instituicaoFinanceiraRef.current.id);
								}}
								options={choicesInstituicoes}
								filterOptions={filterOptions}
								renderOption={(option) => {
									return option.has_integracao_api
										? `${option.nome} - Integração automática - via API`
										: option.nome;
								}}
								getOptionLabel={(option) => {
									return option.has_integracao_api
										? `${option.nome} - Integração automática - via API`
										: option.nome;
								}}
								noOptionsText='Nenhum resultado'
								getOptionSelected={(option, newValue) => {
									return newValue.id === option.id;
								}}
								style={{ marginTop: '8px' }}
								renderInput={({ options, ...params }) => (
									<TextField
										{...params}
										label='Instituição Financeira'
										variant='outlined'
										margin='dense'
										helperText={false}
										fullWidth
									/>
								)}
							/>
						)}
					/>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={handleCancel} />
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo onClick={handleNext} disabled={!idInstFinanceira} />
				</>
			}
		/>
	);
};

const ModalSaldoInicial = () => {
	const dP = useDataProvider();
	const { setModalStep, setDadosNovaConta, dadosNovaConta, handleCancel, closeModal } =
		useContext(NovaContaBancariaContext);
	const { setReloadFilter } = useContext(ContasBancariasListContext);
	const notify = useNotify();
	const [disabledForProcessing, setDisabledForProcessing] = useState(false);
	const [saldoInicial, setSaldoInicial] = useState(dadosNovaConta.saldo_inicial || 0);
	const [dataSaldoInicial, setDataSaldoInicial] = useState(
		dadosNovaConta.data_saldo_inicial ? new Date(`${dadosNovaConta.data_saldo_inicial}T10:00`) : new Date()
	);
	const handleBack = () => {
		setDadosNovaConta((v) => {
			const { saldo_inicial, data_saldo_inicial, ...restante } = v;
			return restante;
		});
		if (dadosNovaConta.tipo_conta === 'C') setModalStep('2');
		if (dadosNovaConta.tipo_conta === 'CB') setModalStep('INST');
	};
	const handleNext = () => {
		setDisabledForProcessing(true);
		dP.create('conta_condominio', {
			data: {
				...dadosNovaConta,
				saldo_inicial: saldoInicial,
				data_saldo_inicial: dataSaldoInicial.toISOString().split('T')[0],
			},
		})
			.then(() => {
				setReloadFilter((v) => !v);
				notify('Conta bancária cadastrada com sucesso!');
				closeModal();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setDisabledForProcessing(false);
				notify(`Erro ao cadastrar nova conta bancária: ${e}`, 'warning');
			});
	};

	const isDateValid = (date) =>
		date && typeof date === 'object' && typeof date.toString === 'function' && date.toString() !== 'Invalid Date';

	return (
		<CustomDialogBodySizeEditable
			title='Cadastrar Conta Bancária'
			form={{
				component: (
					<>
						<Box display='flex' justifyContent='center'>
							<CurrencyTextFieldWithAdornment
								fullWidth
								name='saldo_inicial'
								id='saldo_inicial'
								label='Qual o saldo inicial desta conta?'
								value={saldoInicial}
								onChange={(event) => {
									if (!event.target.value) event.target.value = 0;
									if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
									if (event.target.value < -999999999999.99) event.target.value = -999999999999.99;
									setSaldoInicial(event.target.value);
								}}
								margin='dense'
								style={{ width: '50%' }}
							/>
						</Box>
						<Box display='flex' justifyContent='center'>
							<SyndikosMuiPickersUtilsProvider>
								<KeyboardDatePicker
									format='dd/MM/yyyy'
									id='dataSaldoInicial'
									name='keyboardModal'
									label='Qual é o dia do saldo inicial?'
									margin='dense'
									variant='inline'
									inputVariant='outlined'
									mask='__/__/____'
									initialFocusedDate={dataSaldoInicial}
									onChange={setDataSaldoInicial}
									value={dataSaldoInicial}
									error={false}
									helperText={null}
									disableToolbar={true}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									style={{ width: '50%' }}
								/>
							</SyndikosMuiPickersUtilsProvider>
						</Box>
					</>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={handleCancel} disabled={disabledForProcessing} />
					<BotaoVoltar onClick={handleBack} disabled={disabledForProcessing} />
					<Button
						variant='text'
						color='primary'
						onClick={handleNext}
						disabled={
							disabledForProcessing || !isDateValid(dataSaldoInicial) || typeof saldoInicial !== 'number'
						}
						size='small'
						startIcon={<CheckIcon />}
					>
						Salvar
					</Button>
				</>
			}
		/>
	);
};

const FluxoModalNovaContaBancaria = ({ id_condominio }) => (
	<NovaContaBancariaContextProvider id_condominio={id_condominio} />
);

export default FluxoModalNovaContaBancaria;
