import React, { createContext, useMemo, useState } from 'react';

export const ListContasPagarContext = createContext();

export const ListContasPagarContextProvider = ({ children }) => {
	const [parcelasSelecionadas, setParcelasSelecionadas] = useState([]);
	const [parcelasPagas, setParcelasPagas] = useState([]);
	const [parcelasEmAberto, setParcelasEmAberto] = useState([]);
	const [lastCondominioId, setLastCondominioId] = useState();

	const ListContasPagarProviderValue = useMemo(
		() => ({
			parcelasSelecionadas,
			setParcelasSelecionadas,
			parcelasPagas,
			setParcelasPagas,
			parcelasEmAberto,
			setParcelasEmAberto,
			lastCondominioId,
			setLastCondominioId,
		}),
		[
			parcelasSelecionadas,
			setParcelasSelecionadas,
			parcelasPagas,
			setParcelasPagas,
			parcelasEmAberto,
			setParcelasEmAberto,
			lastCondominioId,
			setLastCondominioId,
		]
	);

	return (
		<ListContasPagarContext.Provider value={ListContasPagarProviderValue}>
			{children}
		</ListContasPagarContext.Provider>
	);
};
