import React from 'react';

import EditaContaBancariaTextInput from '../../commons/EditaContaBancariaTextInput';
import { getDadosFormattingContaCorrente } from '../../utils/formaters_parses';

const defaultDadosFormattingContaCorrente = getDadosFormattingContaCorrente(6);

const ContaCorrenteField = () => (
	<EditaContaBancariaTextInput
		source='conta_corrente'
		name='conta_corrente'
		label='Conta Corrente'
		{...defaultDadosFormattingContaCorrente}
	/>
);

export default ContaCorrenteField;
