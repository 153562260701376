import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';

type NumberInputProps = {
	label?: string;
	disabled?: boolean;
	value: number;
	setValue: React.Dispatch<React.SetStateAction<number>>;
};

const NumberInputForm: React.FC<NumberInputProps> = ({ label, disabled, setValue, value }) => {
	return (
		<TextField
			type='number'
			label={label}
			disabled={disabled}
			size='small'
			fullWidth
			value={value}
			onChange={(e) => {
				if (Number(e.target.value) < 0) return setValue(0);
				return setValue(Number(e.target.value));
			}}
		/>
	);
};

export default NumberInputForm;
