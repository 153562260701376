export const tagsEditorText = [
	{
		keys: ['referencia'],
		content: 'Mês/Ano da referencia',
		value: '{{data_referencia}}',
	},
	{
		keys: ['vencimento'],
		content: 'Dia/Mês/Ano do vencimento',
		value: '{{data_vencimento}}',
	},
	{
		keys: ['responsavel'],
		content: 'Responsável da cobrança',
		value: '{{responsavel_nome}}',
	},
	{
		keys: ['tipo'],
		content: 'Tipo do responsável da cobrança',
		value: '{{tipo_responsavel}}',
	},
	{
		keys: ['condominio'],
		content: 'Nome do condomínio',
		value: '{{condominio_nome}}',
	},
	{
		keys: ['valor'],
		content: 'Valor da conta',
		value: '{{valor}}',
	},
	{
		keys: ['unidade'],
		content: 'Código do bloco + unidade + tipo de unidade',
		value: '{{grupo_nome}} - {{unidade_nome}} - {{unidade_tipo}}',
	},
];
