import React, { useContext, useCallback, useEffect, useRef, useState } from 'react';

import { useListContext, useGetList } from 'react-admin';

import { format as formatFns } from 'date-fns';

import { Box, Paper, Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

import LeiturasListContext from './LeiturasListContext';
import { InputDateReferencia } from '../../common/InputDate';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import FilterSyndikos from '../../common/FilterSyndkos';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import CriarNovaLeituraButton from './listActions/components/CriarNovaLeituraButton';
import DateDataLeiturasSaveButton from './listActions/components/DateDataLeiturasSaveButton';
import EditarConfiguracaoButton from './listActions/components/EditarConfiguracaoButton';
import AbrirLeiturasAplicativoButton from './listActions/components/AbrirLeiturasAplicativoButton';
import NotificacaoQuantidadePendenteLeiturasAPP from './listActions/components/NotificacaoQuantidadePendenteLeiturasAPP';
import InputDateDataLeituras from './listActions/components/InputDateDataLeituras';
import LancarLeiturasButton from './listActions/components/LancarLeiturasButton';
import ValorConsumoTotalField from './listActions/components/ValorConsumoTotalField';
import { useNotify } from 'react-admin';

const useStyle = makeStyles(() => ({
	form: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		'margin-bottom': '15px',
		'margin-top': '5px',
		position: 'relative',
		width: '100%',
	},
}));

const SelectInputWithoutEmpty = ({ allowEmpty, ...props }) => (
	<SyndikosSelect optionText='nome' translateChoice={false} {...props} fullWidth delay={500} />
);

const OptionLeitura = ({ option }) => {
	if (option.sem_leituras) {
		return <b onClick={() => document.querySelector('#criar_nova_leitura').click()}>{option.nome}</b>;
	} else {
		return option.situacao === 'A' ? <span>{option.nome}</span> : <i>{`${option.nome} - Inativo`}</i>;
	}
};

const SelectLeitura = ({ leitura_id, id_condominio_idState, choices, ...props }) => {
	const { setLeituraSituacao, setLeituraMetodoCalculo } = useContext(LeiturasListContext);
	const choicedLeitura = useCallback(() => {
		const choicedLeitura = leitura_id ? choices.filter((leitura) => leitura.id === leitura_id)[0] : undefined;
		setLeituraSituacao(choicedLeitura?.situacao);
		setLeituraMetodoCalculo(choicedLeitura?.metodo_calculo_id);
	}, [choices, leitura_id, setLeituraMetodoCalculo, setLeituraSituacao]);
	useEffect(choicedLeitura, [choices, leitura_id]);

	return (
		<SelectInputWithoutEmpty
			{...props}
			choices={
				!choices && id_condominio_idState
					? [
							{
								sem_leituras: true,
								nome: 'Sem leituras - Criar uma?',
							},
					  ]
					: choices
			}
			disabled={!choices && !id_condominio_idState}
		/>
	);
};

const FilterBox = ({ titulo, children, boxProps = {}, paperStyle = {}, divStyle = {} }) => (
	<Box flex={1} {...boxProps}>
		<Paper
			style={{
				padding: 15,
				flexDirection: 'column',
				...paperStyle,
			}}
		>
			<Typography variant='h6'>{titulo}</Typography>
			<div style={{ ...divStyle }}>{children}</div>
		</Paper>
	</Box>
);

const CondominioBox = () => {
	const {
		filterValues: { id_condominio_id, leitura_id, referencia },
		setFilters,
	} = useListContext();
	const condominioId = useRef(id_condominio_id);
	const { setCondominio, condominioSelecionado, condominios, setCondominioPorId } = useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const idsCondominios = condominios.map((cond) => cond?.id);

	const handleDataChange = () => {
		if (!condominioSelecionadoId) {
			setCondominioPorId(Object.values(condominios)[0]?.id);
		}
	};

	useEffect(handleDataChange, [condominios]);

	const handleCondominioChange = (_, option) => {
		if (option) {
			setCondominio(option);
		}
	};

	useEffect(() => {
		if (id_condominio_id && id_condominio_id !== condominioId.current) {
			condominioId.current = id_condominio_id;
			setFilters({ id_condominio_id, referencia });
			setCondominioPorId(id_condominio_id);
		}
	}, [setFilters, id_condominio_id, condominioId, referencia, condominios, setCondominioPorId]);

	const updateReferencia = useCallback(() => {
		if (
			referencia &&
			typeof referencia === 'object' &&
			referencia.toDateString &&
			referencia.toDateString() !== 'Invalid Date'
		) {
			setFilters({
				id_condominio_id,
				leitura_id,
				referencia: formatFns(referencia, 'yyyy-MM-dd') + 'T10:00',
			});
		}
	}, [id_condominio_id, leitura_id, referencia, setFilters]);

	useEffect(updateReferencia, [referencia]);

	const updateIdCondominioId = useCallback(() => {
		if (id_condominio_id !== condominioSelecionadoId)
			setFilters({
				id_condominio_id: condominioSelecionadoId,
				referencia,
			});
	}, [id_condominio_id, condominioSelecionadoId, setFilters, referencia]);
	useEffect(updateIdCondominioId, [condominioSelecionadoId]);

	const displaySelect = Boolean(
		!!idsCondominios?.length &&
			!!Object.keys(condominios)?.length &&
			!!condominios?.length &&
			!!condominioSelecionado?.id
	);

	if (!displaySelect) return <FilterBox titulo='Selecionar Condomínio'></FilterBox>;

	return (
		<FilterBox
			titulo={
				<Box display='flex' justifyContent='space-between' whiteSpace={'nowrap'}>
					<span>Selecionar Condomínio</span>
				</Box>
			}
			paperStyle={{ 'row-gap': '0.65em', display: 'flex' }}
		>
			<Autocomplete
				id='id_condominio_id'
				options={condominios}
				getOptionSelected={(option, value) => option?.id === value?.id}
				renderInput={(params) => <TextField {...params} fullWidth margin='dense' />}
				renderOption={(option) => option?.nome}
				getOptionLabel={(option) =>
					(option?.nome ? option.nome : condominios[condominioSelecionado?.id]?.nome) || ''
				}
				onChange={handleCondominioChange}
				defaultValue={condominios[condominioSelecionado?.id]}
				noOptionsText='Nenhum resultado'
				fullWidth
				disableClearable
			/>
		</FilterBox>
	);
};

const LeituraEReferenciaBox = () => {
	const {
		filterValues: { id_condominio_id, leitura_id },
	} = useListContext();
	const notify = useNotify();

	const handleChange = (newValue, onChange) => {
		onChange(newValue);
		if (newValue == null) return notify('Digite uma data.', 'warning');

		if (!(newValue instanceof Date) || isNaN(newValue)) return;

		const threeMonthsLater = new Date();
		threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
		if (newValue.getTime() > threeMonthsLater.getTime())
			return notify('Não é possível lançar leituras 3 meses após a data atual.', 'warning');
	};

	return (
		<FilterBox
			divStyle={{
				display: 'grid',
				gridColumnGap: '10px',
				gridTemplateColumns: 'repeat(2, 1fr)',
			}}
			titulo={
				<Box display='flex' justifyContent='space-between' whiteSpace={'nowrap'}>
					<span>Selecionar Leitura e Referência</span>
					<CriarNovaLeituraButton />
				</Box>
			}
		>
			<VeryHighLimitReferenceInput
				reference='leitura'
				label='Leitura'
				source='leitura_id'
				filter={{
					id_condominio_id: id_condominio_id,
					exclui_situacao: 'E',
				}}
				alwaysOn
				variant='outlined'
				OptionComponent={OptionLeitura}
			>
				<SelectLeitura
					leitura_id={leitura_id}
					id_condominio_idState={id_condominio_id}
					stylesSyndikosSelect={{}}
				/>
			</VeryHighLimitReferenceInput>
			<InputDateReferencia
				handleChange={handleChange}
				label='Referência'
				source='referencia'
				fullWidth
				alwaysOn
			/>
		</FilterBox>
	);
};

const DataLeituraEConfigsBox = () => {
	const { leituraMetodoCalculo } = useContext(LeiturasListContext);
	return (
		<FilterBox
			divStyle={{
				display: 'grid',
				gridColumnGap: '5px',
				gridTemplateColumns: leituraMetodoCalculo === 6 ? '40% 40% 20%' : '80% 20%',
			}}
			paperStyle={{ width: 'calc(100% - 14px)' }}
			titulo={
				<Box display='flex' justifyContent='space-between' whiteSpace={'nowrap'}>
					<span>Data da leitura e Configurações</span>
					<Box>
						<AbrirLeiturasAplicativoButton />
						<NotificacaoQuantidadePendenteLeiturasAPP />
						<EditarConfiguracaoButton />
					</Box>
				</Box>
			}
		>
			<InputDateDataLeituras />
			{leituraMetodoCalculo === 6
				? [
						<ValorConsumoTotalField key='ValorConsumoTotalField' />,
						<LancarLeiturasButton key='LancarLeiturasButton' />,
				  ]
				: [<DateDataLeiturasSaveButton key='DateDataLeiturasSaveButton' />]}
		</FilterBox>
	);
};

const TabelaFilters = (props) => {
	const classes = useStyle();

	return (
		<FilterSyndikos {...props} classes={classes}>
			<CondominioBox alwaysOn={true} key='box1' />
			<LeituraEReferenciaBox alwaysOn={true} key='box2' />
			<DataLeituraEConfigsBox alwaysOn={true} key='box3' />
		</FilterSyndikos>
	);
};

export default TabelaFilters;
