import React from 'react';

import { Box } from '@material-ui/core';

import { BoxMainGrid } from '../../../../../tabs/TabInstrucoesCobrancaRemessa';
import PadraoTabInstrucoesCobrancaRemessaBase, {
	LimitePagamentoFields,
} from '../../../../../tabs/TabInstrucoesCobrancaRemessa';

import { DescontoFields } from '../../../../../tabs/TabInstrucoesCobrancaRemessa';
import MultaFields from './MultaFields';
import JurosFields from './JurosFields';

const TabInstrucoesCobrancaRemessa = (props) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<LimitePagamentoFields />]}
	/>
);

export default TabInstrucoesCobrancaRemessa;
