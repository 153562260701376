import React from 'react';

import { PrimaryChip, SyndikosRedChip, SuccessChip } from 'components/common/chips';
import SituacaoField from './SituacaoField';

export const SituacaoBoletoField = (props) => (
	<SituacaoField
		{...props}
		chips={{
			SG: <PrimaryChip value='Processando' />,
			G: <PrimaryChip value='Gerado' />,
			I: <SyndikosRedChip value='Indisponível' />,
		}}
		DefaultChip={<SuccessChip value='Não Gerado' />}
	/>
);

export default SituacaoBoletoField;
