import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, useMediaQuery, Box } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import lodashGet from 'lodash/get';
import { setSidebarVisibility, useLocale } from 'ra-core';

import Menu from './Menu/Menu';
import { AdminContext } from '../common/AdminContextProvider';
import { UserThemeContext } from '../../theme/context';

export const DRAWER_WIDTH = 260;
export const CLOSED_DRAWER_WIDTH = 55;

const Sidebar = (props) => {
	const { children, closedSize, size, classes: classesOverride, ...rest } = props;
	const dispatch = useDispatch();
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const open = useSelector((state) => state.admin.ui.sidebarOpen);
	useLocale(); // force redraw on locale change
	const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
	const { drawerPaper, ...classes } = useStyles({ ...props, open });
	const { midias } = useContext(AdminContext);
	const { theme } = useContext(UserThemeContext);

	return isXSmall ? (
		<Drawer
			variant='temporary'
			open={open}
			PaperProps={{
				className: drawerPaper,
			}}
			onClose={toggleSidebar}
			classes={classes}
			{...rest}
		>
			{children}
		</Drawer>
	) : isSmall ? (
		<Drawer
			variant='permanent'
			open={open}
			PaperProps={{
				className: drawerPaper,
			}}
			onClose={toggleSidebar}
			classes={classes}
			{...rest}
		>
			<Menu />
		</Drawer>
	) : (
		<Drawer
			variant='permanent'
			open={open}
			PaperProps={{
				className: drawerPaper,
			}}
			onClose={toggleSidebar}
			classes={classes}
			{...rest}
		>
			<Box
				display='flex'
				flexDirection='column'
				justifyContent='space-between'
				height='calc(100vh - 30px)'
				position='fixed'
			>
				<Menu />
				{open && (
					<Box display='flex' style={{ margin: 'auto auto 2em auto' }}>
						<img
							src={midias[`logo_horizontal_${theme == 'light' ? 'light' : 'dark'}`]}
							alt='logo'
							style={{
								opacity: 0.5,
								padding: 2,
								maxHeight: theme == 'light' ? '50px' : '60px',
								width: 'auto',
								height: 'auto',
								pointerEvents: 'none',
								userSelect: 'none',
							}}
						/>
					</Box>
				)}
			</Box>
		</Drawer>
	);
};

const useStyles = makeStyles(
	(theme) => ({
		root: {},
		docked: {},
		paper: {},
		paperAnchorLeft: {},
		paperAnchorRight: {},
		paperAnchorTop: {},
		paperAnchorBottom: {},
		paperAnchorDockedLeft: {},
		paperAnchorDockedTop: {},
		paperAnchorDockedRight: {},
		paperAnchorDockedBottom: {},
		modal: {},
		drawerPaper: {
			position: 'relative',
			height: '100%',
			overflowX: 'hidden',
			width: (props) =>
				props.open ? DRAWER_WIDTH : lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			backgroundColor: alpha(theme.palette.secondary.light, 0.02),
			borderRight: 'none',
			[theme.breakpoints.only('xs')]: {
				marginTop: 0,
				height: '100vh',
				position: 'inherit',
				backgroundColor: theme.palette.background.default,
			},
			[theme.breakpoints.up('md')]: {
				border: 'none',
			},
			zIndex: 'inherit',
		},
	}),
	{ name: 'RaSidebar' }
);

export default Sidebar;
