import React from 'react';

import { Box, Card, CardContent, Typography } from '@material-ui/core';

import { Title } from 'react-admin';

import SelectConfiguracao from './SelectConfiguracao';
import { getConfiguracoesComponent } from './configuracoesDisponiveis';

function ConfiguracaoPrincipalPage() {
	const [selectedConfiguracao, setSelectedConfiguracao] = React.useState(null);
	const { id: componentId } = selectedConfiguracao ?? {};
	const ConfiguracaoComponent = getConfiguracoesComponent(componentId);
	return (
		<Card>
			<Title title='Configurações' />
			<CardContent display='flex' m='1em'>
				<Box flex={1} style={{ padding: '1em' }}>
					<Box flexDirection='column' display='flex' style={{ gap: 10 }}>
						<Typography variant='h4'>Configurações</Typography>
						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
								width: '100%',
							}}
						/>
						<SelectConfiguracao onChange={setSelectedConfiguracao} />
						{ConfiguracaoComponent}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
}

export default ConfiguracaoPrincipalPage;
