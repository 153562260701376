import { createContext } from 'react';

const ImportFornecedoresContext = createContext({
	files: [],
	setFiles: (files = []) => files,
	setModalStep: (step = '') => step,
	resultadoImport: [],
	setResultadoImport: (resultado = []) => resultado,
	errosNoArquivo: [],
	setErrosNoArquivo: (erros = []) => erros,
});

export default ImportFornecedoresContext;
