import React, { useState, useEffect, useContext } from 'react';

import { useNotify, useCreate } from 'react-admin';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import CheckIcon from '@material-ui/icons/Check';

import { VinculosContext } from '../../VinculosContext';
import { TooltipIconButton } from '../../../../common/TooltipIconButton';
import { TooltipIconButtonCancel } from '../../../../common/buttons/ButtonCancel';

const getChoices = (owner, choices) => {
	if (owner) {
		return choices.filter((p) => p.id !== owner?.id_pessoa);
	}
	return choices;
};

const BoxSelectPessoa = ({ modoAdd, setModoAdd, owner, callbackOnChangeSelectedPessoa }) => {
	const { choices, selectedPessoa, setSelectedPessoa } = useContext(VinculosContext);

	return (
		<Box
			display='flex'
			alignItems='center'
			css={
				modoAdd
					? {
							transition: 'all 200ms ease',
							pointerEvents: 'none',
							opacity: 0.3,
							marginBottom: 16,
					  }
					: { transition: 'all 200ms ease' }
			}
		>
			<Autocomplete
				noOptionsText='Nenhum resultado encontrado'
				clearText='Limpar'
				id='pessoas-autocomplete'
				value={
					selectedPessoa?.id && (owner ? owner.id_pessoa !== selectedPessoa.id : true) ? selectedPessoa : null
				}
				options={getChoices(owner, choices)}
				getOptionLabel={(o) => o.nome}
				getOptionSelected={(option, v) => v?.id === option.id}
				renderInput={(params) => <TextField {...params} label='Selecionar Pessoa Existente' margin='dense' />}
				fullWidth
				onChange={(_, v) => {
					setSelectedPessoa((p) => {
						const novaSelectedPessoa = {
							imobiliaria: p?.imobiliaria,
							id_imobiliaria: p?.id_imobiliaria,
							...v,
						};
						if (callbackOnChangeSelectedPessoa) callbackOnChangeSelectedPessoa(novaSelectedPessoa);
						return novaSelectedPessoa;
					});
				}}
				style={{ flex: 4, marginRight: '0.5em' }}
			/>
			<div>
				<Button
					color='primary'
					onClick={() => setModoAdd(true)}
					disabled={Boolean((selectedPessoa && selectedPessoa.nome) || modoAdd)}
					style={{ flex: 1, margin: '8px 0 4px' }}
					variant='outlined'
				>
					Nova Pessoa
				</Button>
			</div>
		</Box>
	);
};

const BoxAddPessoa = ({ modoAdd, setModoAdd, callbackOnChangeSelectedPessoa }) => {
	const notify = useNotify();
	const [nome, setNome] = useState('');
	const [added, setAdded] = useState(false);
	const [tipoPessoa, setTipoPessoa] = useState('F');

	const [create, { data }] = useCreate('pessoas', {
		nome,
		tipo_pessoa: tipoPessoa,
	});

	const { setChoices, selectedPessoa, setSelectedPessoa } = useContext(VinculosContext);

	const handleCreate = () => {
		if (data) {
			notify('Pessoa cadastrada com sucesso');
			setSelectedPessoa((p) => {
				const novaSelectedPessoa = {
					...p,
					nome,
					tipo_pessoa: tipoPessoa,
					id: data.id,
				};
				if (callbackOnChangeSelectedPessoa) callbackOnChangeSelectedPessoa(novaSelectedPessoa);
				return novaSelectedPessoa;
			});
			setChoices((c) => [...c, { ...data }]);
			setAdded(true);
		}
	};

	useEffect(handleCreate, [data]);

	return (
		<Box
			display='flex'
			alignItems='center'
			css={
				modoAdd
					? {
							opacity: 1,
							maxHeight: 200,
							transition: 'all 200ms ease',
					  }
					: {
							maxHeight: 0,
							opacity: 0,
							pointerEvents: 'none',
							transition: 'all 200ms ease',
					  }
			}
		>
			<FormControl margin='dense' style={{ width: 150, marginRight: '0.5em' }} disabled={selectedPessoa && data}>
				<InputLabel variant='outlined'>Tipo</InputLabel>
				<Select
					defaultValue='F'
					initialValue='F'
					variant='outlined'
					notched
					labelWidth={35}
					onChange={(e) => setTipoPessoa(e.target.value)}
				>
					<MenuItem value='F'>Física</MenuItem>
					<MenuItem value='J'>Jurídica</MenuItem>
				</Select>
			</FormControl>
			<TextField
				label='Nome'
				fullWidth
				margin='dense'
				value={nome}
				onChange={(e) => setNome(e.target.value)}
				disabled={selectedPessoa && data}
			/>
			<TooltipIconButtonCancel
				onClick={() => {
					setModoAdd(false);
					setSelectedPessoa();
					setNome('');
					setAdded(false);
				}}
			/>
			<TooltipIconButton
				title='Cadastrar'
				onClick={create}
				style={{ display: added ? 'none' : 'inline' }}
				disabled={selectedPessoa && !nome}
			>
				<CheckIcon />
			</TooltipIconButton>
		</Box>
	);
};

const PessoaUtility = ({ owner, setValid, callbackOnChangeSelectedPessoa }) => {
	const [modoAdd, setModoAdd] = useState(false);

	useEffect(() => {
		if (modoAdd) {
			setValid(false);
		}
	}, [modoAdd, setValid]);

	return (
		<Box mb={2}>
			<BoxSelectPessoa
				{...{
					modoAdd,
					setModoAdd,
					owner,
					callbackOnChangeSelectedPessoa,
				}}
			/>
			<BoxAddPessoa
				{...{
					modoAdd,
					setModoAdd,
					callbackOnChangeSelectedPessoa,
				}}
			/>
		</Box>
	);
};

export default PessoaUtility;
