import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { ContentConsultaReserva } from './ContentConsultaReserva';
import { ModalCriarAgendamento } from './ModalCriarAgendamento';
import { ModalEdicaoAgendamento } from './ModalEdicaoAgendamento';
import ModalBloquearReserva from './ModalBloquearReserva';
import { ModalEdicaoBloqueio } from './ModalEdicaoBloqueio';
import weekDayToString from './CardReservasEBloqueios/utils/weekDaysToString';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
	voltarButton: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.main,
			color: '#fff',
		},
	},
	criarButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
	},
}));

export const ModalConsultaReserva = () => {
	const {
		consulta: { showModalConsultaReservas, diaSelecionado, reservasNoMes, ambienteSelecionado },
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);
	const [criarReservaBloqueado, setBloquearCriarReserva] = React.useState(false);

	const classes = useStyles();
	const atualizaCriarReservaBloqueado = () => {
		const { bloqueado } = reservasNoMes[diaSelecionado ? parseInt(format(diaSelecionado, 'dd')) : 0] || {
			bloqueado_por_configuracao: false,
		};
		setBloquearCriarReserva(bloqueado);
	};

	const diaDaSemana = weekDayToString(diaSelecionado);
	const dia = ambienteSelecionado.dias.find((dia) => dia.dia === diaDaSemana);

	React.useEffect(atualizaCriarReservaBloqueado, [diaSelecionado, reservasNoMes]);

	return (
		<Dialog
			open={showModalConsultaReservas}
			onClose={() =>
				setConsulta((prevState) => ({
					...prevState,
					showModalConsultaReservas: false,
				}))
			}
			fullWidth
			PaperProps={{
				style: {
					minWidth: '61vw',
					position: 'relative',
				},
			}}
		>
			<DialogTitle>Consulta de Reserva de Ambiente</DialogTitle>
			<DialogContent>
				<ContentConsultaReserva />
				<ModalEdicaoAgendamento />
				<ModalCriarAgendamento />
				<ModalBloquearReserva />
				<ModalEdicaoBloqueio />
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() =>
						setConsulta((prevState) => ({
							...prevState,
							showModalCriarReservas: true,
						}))
					}
					className={classes.criarButton}
					startIcon={<AddCircleOutlineIcon />}
					variant='contained'
					disabled={criarReservaBloqueado || !(dia && dia.habilitado)}
				>
					Criar nova reserva
				</Button>
				<Button
					onClick={() =>
						setConsulta((prevState) => ({
							...prevState,
							showModalBloquearReservas: true,
						}))
					}
					className={classes.criarButton}
					startIcon={<LockIcon />}
					variant='contained'
				>
					Bloquear reservas
				</Button>
				<Button
					onClick={() =>
						setConsulta((prevState) => ({
							...prevState,
							showModalConsultaReservas: false,
						}))
					}
					className={classes.voltarButton}
					variant='contained'
				>
					Voltar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
