import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import UserMenuItem from '../../common/UserMenuItem';
import { useGetIdentity } from 'react-admin';

const useStyles = makeStyles({
	fake: {
		cursor: 'default',
		opacity: '1',
		'pointer-events': 'none',
	},
});

const MenuItemAdministradora = () => {
	const classes = useStyles();
	const { identity, loaded } = useGetIdentity();
	return (
		<UserMenuItem
			extraClasses={[classes.fake]}
			label={
				loaded && identity.tenant ? `${identity.tenant.codigo_cliente_sci} - ${identity.tenant.apelido}` : ''
			}
			icon={<HomeWorkIcon />}
		/>
	);
};

export default MenuItemAdministradora;
