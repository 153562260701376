import { styled } from '@material-ui/core/styles';

export const KDayPicker = styled('div')(({ theme, $dayInCurrentMonth }) => ({
	flexDirection: 'column',
	borderLeft: `1px solid ${theme.border[50]}`,
	borderRight: `1px solid ${theme.border[50]}`,
	borderBottom: `1px solid ${theme.border[50]}`,
	height: '7vh',
	padding: '7px',
	width: '100%',
	cursor: 'pointer',
	textAlign: 'right',
	backgroundColor: `${$dayInCurrentMonth ? theme.fundo[100] : theme.fundoMenu[100]}`,

	'& span': {
		alignSelf: 'flex-end',
		color: `${$dayInCurrentMonth ? theme.font[500] : 'rgba(0, 0, 0, 0.3)'}`,
	},

	'&:hover': {
		cursor: 'pointer',
		filter: 'brightness(90%)',
	},
}));
