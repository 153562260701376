import React from 'react';

import { formatDate } from 'components/resources/relatorios/dateUtils';
import { parseISO } from 'date-fns';

import {
	TDateISODate,
	IFormatDateFieldProps,
	IUltimaDataAtualizacaoFieldProps,
	IUltimaDataAtualizacaoColumnProps,
	IDataAtualizacaoFieldProps,
	IDataAtualizacaoColumnProps,
} from './types';

const FormatDateField: React.FC<IFormatDateFieldProps> = ({ date }) => {
	if (!date) return <span>Não atualizado</span>;
	const parsedDate = parseISO(date);
	const dateFormat = formatDate(parsedDate);
	return <span>{dateFormat}</span>;
};

const UltimaDataAtualizacaoFieldBase: React.FC<IUltimaDataAtualizacaoFieldProps> = ({ source, record }) => {
	return <FormatDateField date={record?.[source] as TDateISODate} />;
};

export const UltimaDataAtualizacaoField = UltimaDataAtualizacaoFieldBase as unknown as React.FC<
	IUltimaDataAtualizacaoFieldProps | Partial<IUltimaDataAtualizacaoColumnProps>
>;

const DataAtualizacaoFieldBase: React.FC<IDataAtualizacaoFieldProps> = ({ source, record }) => {
	return <FormatDateField date={record?.[source] as TDateISODate} />;
};

export const DataAtualizacaoField = DataAtualizacaoFieldBase as unknown as React.FC<
	IUltimaDataAtualizacaoFieldProps | Partial<IDataAtualizacaoColumnProps>
>;

export default FormatDateField;
