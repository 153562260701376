import React from 'react';

import { ListControllerProps } from 'react-admin';

import { Padrao as PadraoAntigo } from './Padrao';

interface IPadraoProps {
	source?: string;
	custom?: string;
	listControllerProps?: Partial<ListControllerProps>;
}

export const Padrao = PadraoAntigo as React.FC<IPadraoProps>;
