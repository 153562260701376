import React, { useState, useContext, useEffect } from 'react';

import { useDataProvider } from 'react-admin';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoProximo } from '../../../../../common/buttons/BotaoProximo';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import MultiSelect from '../../../../../common/Selects/index';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';

const ModalUnidadesConfiguradas = (props) => {
	const condominio = props.condominio;
	const { closeModal, setDadosNovaLeitura, setModalStep, dadosNovaLeitura } = useContext(NovaLeituraContext);
	const dp = useDataProvider();
	const [unidades, setUnidades] = useState([]);
	const { options } = useRequestOptionsUnidades();

	useEffect(() => {
		if (dadosNovaLeitura.dados_config.unidades_ids) {
			dp.getList('unidades', {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'unidade', order: 'ASC' },
				filter: {
					id_condominio: condominio,
					situacao: 'A',
				},
			}).then((response) => {
				const data = response?.data;
				if (data) {
					const dataDict = data.reduce((dict, item) => {
						dict[item.id] = item;
						return dict;
					}, {});
					setUnidades(dadosNovaLeitura.dados_config.unidades_ids.map((item) => dataDict[item]));
				}
			});
		}
	}, [condominio, dp, dadosNovaLeitura.dados_config.unidades_ids]);

	const handleNext = () => {
		setDadosNovaLeitura((v) => {
			v.dados_config['unidades_ids'] = (unidades || []).map((i) => i.id);
			return v;
		});
		setModalStep('3');
	};
	const handleBack = () => {
		setDadosNovaLeitura((v) => {
			const { ...dados_config } = v.dados_config;
			v['dados_config'] = dados_config;
			return v;
		});
		setModalStep('2');
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Quais serão as unidades que receberão o calculo?'
			form={{
				component: (
					<MultiSelect
						variant={'formatsUnidades'}
						options={options}
						setValue={setUnidades}
						value={unidades}
					/>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={closeModal} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<BotaoProximo onClick={handleNext} disabled={!unidades.length} />
				</>
			}
		/>
	);
};

export default ModalUnidadesConfiguradas;
