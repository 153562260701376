import React from 'react';

import ContasReceberArrecadacoesList from '../../ContasReceberArrecadacoesList';

import TabelaContasReceberBoletos from './TabelaContasReceberBoletos';
import Actions from './Actions';

const ContasReceberBoletosList = ({ filter = {}, ...props }) => (
	<ContasReceberArrecadacoesList
		resource='contas_receber_boletos'
		basepath='/contas_receber_boletos'
		{...props}
		sort={{ field: 'nome_unidade', order: 'ASC' }}
		filter={{ ...filter, for: 'boletos' }}
		actions={<Actions />}
		Table={<TabelaContasReceberBoletos />}
		labelRowsPerPage='Boletos por página'
		style={{ marginTop: '-63px' }}
	/>
);

export default ContasReceberBoletosList;
