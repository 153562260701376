import React from 'react';

import { Box } from '@material-ui/core';

import { CadastrarDadosIntegracaoAPITextField } from './CadastrarDadosIntegracaoAPITextField';

export const ClientIDField = ({ label, source }) => (
	<Box>
		<CadastrarDadosIntegracaoAPITextField label={label} source={source} />
	</Box>
);
