import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import NovaLeituraContext from './NovaLeituraContext';
import CampoReferencia from './ReferenciaConfigLeitura';
import CampoReceitaField from './FieldReceitaConfigLeitura';
import MetodoCalculoField from './NomeCalculoConfigLeitura';
import LeituraField from './LeituraFieldConfigLeitura';
import MetodoPercentual from './PercentualConfigLeitura';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';

const styles: CSSProperties = {
	marginTop: '1%',
};

const FormLeituraPorcentagem: React.FC = () => {
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { dadosNovaLeitura, handleCancel, setModalStep, setDadosNovaLeitura } = React.useContext(NovaLeituraContext);

	const [disabledForProcessing, setDisabledForProcessing] = React.useState(false);

	const handleBack = () => {
		setModalStep('2RP');
	};

	const handleNext = () => {
		setDisabledForProcessing(true);
		dP.create('leitura', {
			data: {
				...dadosNovaLeitura,
				dados_config: {
					...dadosNovaLeitura.dados_config,
				},
			},
		})
			.then((response) => {
				const data = response?.data;
				if (data) {
					setDadosNovaLeitura((v: object) => ({ ...v, id: data.id }));
					refresh();
					notify('Leitura cadastrada com sucesso');
					setModalStep('10Success');
					setModalValue((v: object) => ({ ...v, open: false }));
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setDisabledForProcessing(false);
				notify(`Erro ao cadastrar nova leitura: ${e}`, 'warning');
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configuração da Leitura Esgoto'
			form={{
				component: (
					<>
						<Box display='flex'>
							<CampoReferencia />
							<CampoReceitaField />
						</Box>
						<Box style={styles}>
							<MetodoCalculoField />
						</Box>
						<Box display='flex' style={styles}>
							<MetodoPercentual />
							<LeituraField />
						</Box>
					</>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<Button
						variant='text'
						onClick={handleNext}
						color='primary'
						disabled={disabledForProcessing}
						size='small'
						startIcon={<CheckIcon />}
					>
						Salvar
					</Button>
				</>
			}
		/>
	);
};

export default FormLeituraPorcentagem;
