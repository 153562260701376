import React, { useContext, useState, useEffect, useRef } from 'react';

import { BooleanInput, useInput, NumberInput, required, useGetOne, useGetList, useDataProvider } from 'react-admin';

import { Field, useForm, useFormState } from 'react-final-form';

import {
	Box,
	FormControl,
	FormLabel,
	FormControlLabel,
	Radio,
	RadioGroup,
	Tab,
	LinearProgress,
	Checkbox,
} from '@material-ui/core';

import { TabPanel, TabContext, TabList } from '@material-ui/lab';

import { parseISO, isSameDay, isAfter } from 'date-fns';

import { ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import { BoxDivisor, FormularioBox, TextInputSyndikos } from '../../common/Formulario';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import { CondominiosContext } from '../../../context/CondominioContextProvider';
import { CustomDialog, ModalContextProvider } from '../../common/ModalContext';
import { ModalConfirmacao } from '../../common/buttons/BotaoRemover';
import { CoeficienteInput, CoeficienteField } from '../../common/CurrencyInput';
import { TipoModalInput } from '../../common/TipoModalInput';
import { SyndikosSelect } from '../../common/SyndikosSelect';

import ModalFeedbackBloqueioRemover from './modais/common/ModalFeedbackBloqueioRemover';
import BoxUnidadesSubordinadas from './modais/import/BoxUnidadesSubordinadas';
import {
	UnidadePrincipalContextProvider,
	UnidadesSubordinadasContextProvider,
} from './modais/import/contextsUnidadesSubordinadas';

import CadastroDeGaragensBox from './Tabs/CadastroDeGaragens';

import { HistoricoCobrancaList } from './HistoricoCobrancaList';
import ListaPessoaUnidade from './ListaPessoaUnidade';
import { VinculosProvider } from './VinculosContext';
import VinculosInput from './VinculosInput';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const getDias = () => {
	let dias = [];
	dias.push({ id: 99, name: 'Padrão do Condomínio' });
	for (let i = 1; i <= 31; i++) {
		dias.push({ id: i, name: `${i}` });
	}
	return dias;
};

export const UnidadeFormulario = (props) => {
	const [value, setValue] = useState(new Array(0));
	const [boletoParaValue, setBoletoParaValue] = useState(false);
	const [selectingAmb, setSelectingAmb] = useState(false);
	const [hasTenant, setHasTenant] = useState(false);
	const [dias, setDias] = useState([]);
	const { condominioSelecionado, setCondominioPorId } = useContext(CondominiosContext);
	const [idCondominio, setIdCondominio] = useState(condominioSelecionado?.id || undefined);
	const [aba, setAba] = useState('0');
	const [proprietario, setProprietario] = useState();
	const [inquilino, setInquilino] = useState();
	const [imobData, setImobData] = useState();
	const dataProvider = useDataProvider();
	const nomeGrupoUnidade = props.record.nome_grupo_unidade ? `- ${props.record.nome_grupo_unidade}` : '';
	const nomeCondominio = props.record.nome_condominio;
	const tipoUnidadeDescricao = props.record.tipo_descricao;
	const headerText = `${tipoUnidadeDescricao} ${props.record.unidade} ${nomeGrupoUnidade} - ${nomeCondominio}`;
	const { data: condominioData, loading } = useGetOne('condominios', idCondominio || condominioSelecionado?.id);

	const handleChange = (_, newValue) => {
		setAba(newValue);
	};

	const {
		loaded: loadedUltimosProprietarios,
		ids: idsUltimosProprietarios,
		data: ultimos_proprietarios,
	} = useGetList(
		'pessoa_unidade',
		{ perPage: 1, page: 1 },
		{ field: 'data_entrada', order: 'DESC' },
		{
			...(props.edit ? { id_unidade: props.record.id } : { data_vigencia_before: '1900-01-01' }),
			tipo: 'P',
			data_saida_after_today: true,
		}
	);
	const {
		loaded: UltimosInquilinos,
		ids: idsUltimosInquilinos,
		data: ultimos_inquilinos,
	} = useGetList(
		'pessoa_unidade',
		{ perPage: 1, page: 1 },
		{ field: 'data_entrada', order: 'DESC' },
		{
			...(props.edit ? { id_unidade: props.record.id } : { data_vigencia_before: '1900-01-01' }),
			tipo: 'I',
			data_saida_after_today: true,
		}
	);

	const fillDadosProprietario = () => {
		if (
			loadedUltimosProprietarios &&
			idsUltimosProprietarios &&
			idsUltimosProprietarios.length &&
			ultimos_proprietarios &&
			ultimos_proprietarios[idsUltimosProprietarios[0]]
		) {
			if (ultimos_proprietarios[idsUltimosProprietarios[0]].id_pessoa)
				dataProvider
					.getOne('pessoas', {
						id: ultimos_proprietarios[idsUltimosProprietarios[0]].id_pessoa,
					})
					.then((response) => {
						const data = response?.data;
						if (data)
							setProprietario({
								...data,
								telefones: (data?.telefonepessoa_set || []).map((t) => t?.numero || ''),
							});
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					});
		}
	};
	const fillDadosInquilino = () => {
		if (
			UltimosInquilinos &&
			idsUltimosInquilinos &&
			idsUltimosInquilinos.length &&
			ultimos_inquilinos &&
			ultimos_inquilinos[idsUltimosInquilinos[0]]
		) {
			const ultimo_inquilino = ultimos_inquilinos[idsUltimosInquilinos[0]];
			if (ultimo_inquilino.id_pessoa)
				dataProvider
					.getOne('pessoas', { id: ultimo_inquilino.id_pessoa })
					.then((response) => {
						const data = response?.data;
						if (data)
							setInquilino({
								...data,
								telefones: (data?.telefonepessoa_set || []).map((t) => t?.numero || ''),
							});
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					});
			if (ultimo_inquilino.id_imobiliaria)
				dataProvider
					.getOne('imobiliarias', {
						id: ultimo_inquilino.id_imobiliaria,
					})
					.then((response) => {
						const data = response?.data;
						if (data) setImobData(data);
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					});
		}
	};
	useEffect(fillDadosProprietario, [loadedUltimosProprietarios, ultimos_proprietarios]);
	useEffect(fillDadosInquilino, [UltimosInquilinos, ultimos_inquilinos]);

	const setTenant = () => {
		if (
			value &&
			value.filter(
				(v) =>
					v.tipo === 'I' &&
					(v.data_saida
						? isSameDay(parseISO(v.data_saida), new Date()) || isAfter(parseISO(v.data_saida), new Date())
						: true)
			).length > 0
		) {
			setHasTenant(true);
		} else {
			setHasTenant(false);
		}
	};

	useEffect(setTenant, [value]);

	const fillResponsaveis = () => {
		if (
			props.edit &&
			props.record &&
			idsUltimosProprietarios &&
			idsUltimosProprietarios.length &&
			ultimos_proprietarios &&
			ultimos_proprietarios[idsUltimosProprietarios[0]]
		) {
			let auxValue = [];
			const ultimo_proprietario = ultimos_proprietarios[idsUltimosProprietarios[0]];
			const ultimo_inquilino = (ultimos_inquilinos || {})[(idsUltimosInquilinos || [])[0]];
			proprietario &&
				auxValue.push({
					...ultimo_proprietario,
					id_pessoa: ultimo_proprietario.id_pessoa,
					id_pessoa_id: ultimo_proprietario.id_pessoa,
					nome: proprietario.nome,
					telefones: proprietario.telefones,
					email_cobranca: proprietario.email_cobranca,
					documento: proprietario.documento,
					tipo_pessoa: proprietario.tipo_pessoa,
				});
			inquilino &&
				ultimo_inquilino &&
				auxValue.push({
					...ultimo_inquilino,
					id_pessoa: ultimo_inquilino.id_pessoa,
					id_pessoa_id: ultimo_inquilino.id_pessoa,
					id_imobiliaria: ultimo_inquilino.id_imobiliaria,
					id_imobiliaria_id: ultimo_inquilino.id_imobiliaria,
					nome: inquilino.nome,
					telefones: inquilino.telefones,
					email_cobranca: inquilino.email_cobranca,
					documento: inquilino.documento,
					tipo_pessoa: inquilino.tipo_pessoa,
					imobiliaria: ultimo_inquilino.id_imobiliaria && imobData,
				});
			setValue(auxValue);

			setIdCondominio(props.record.id_condominio);
		}
	};
	useEffect(fillResponsaveis, [props, ultimos_proprietarios, ultimos_inquilinos, proprietario, inquilino, imobData]);

	useEffect(() => setDias(getDias()), []);

	const disabledCondition =
		(!props.edit && !(idCondominio || condominioSelecionado?.id)) || props.record.situacao === 'I';

	if (props.edit && !proprietario?.nome) {
		return <div></div>;
	}
	return (
		<FormularioBox
			{...props}
			validateOnBlur={false}
			nome={headerText}
			onClickBotaoRemover={(_, setModalValue) => {
				if (props.record?.usado_em_arrecadacao || props.record?.usado_em_lancamento) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalFeedbackBloqueioRemover
								setModalValue={setModalValue}
								usado_em_arrecadacao={props.record?.usado_em_arrecadacao}
								usado_em_lancamento={props.record?.usado_em_lancamento}
							/>
						),
					}));
				} else {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<ModalConfirmacao
								{...props}
								record={props.record}
								confirmTitle={`Tem certeza que deseja remover ${props.record.tipo_descricao} ${props.record.unidade}?`}
								setModalValue={setModalValue}
							/>
						),
					}));
				}
			}}
		>
			<TabContext value={aba}>
				<Box width={1}>
					<Box display='flex'>
						<TabList value={aba} onChange={handleChange} aria-label='simple tabs example'>
							<Tab label='Informações da Unidade' {...a11yProps(0)} value='0' />
							<Tab
								label='Histórico da Unidade'
								{...a11yProps(1)}
								value='1'
								style={!props.edit ? { display: 'none' } : {}}
							/>
							<Tab
								label='Histórico de Cobranças'
								{...a11yProps(2)}
								value='2'
								style={!props.edit ? { display: 'none' } : {}}
							/>
							<Tab
								label='Unidades Subordinadas'
								{...a11yProps(2)}
								value='3'
								style={!props.edit ? { display: 'none' } : {}}
							/>
							<Tab
								label='Cadastro de Garagens'
								{...a11yProps(4)}
								value='4'
								style={!props.edit ? { display: 'none' } : {}}
							/>
						</TabList>
					</Box>
					<TabPanel value={aba} index={aba}>
						<VinculosProvider
							{...{
								value,
								setValue,
								proprietario,
								inquilino,
								imobData,
								disabledCondition,
								edit: props.edit,
								disabled: disabledCondition,
								recordValue: props.record,
								setSelectingAmb,
							}}
						>
							<Box
								width='100%'
								sx={
									(aba !== '0' && {
										width: 0,
										height: 0,
										position: 'absolute',
										display: 'none',
										top: 0,
										opacity: 0,
										userSelect: 'none',
										zIndex: -1000,
									}) ||
									{}
								}
							>
								<Box width='100%' display='flex'>
									<BoxDivisor titulo='Identificação da Unidade' mr={4} flex={3}>
										<Box mr={1}>
											<VeryHighLimitReferenceInput
												source='id_condominio'
												reference='condominios'
												label='Condomínio'
												fullWidth
												variant='outlined'
												validate={required()}
												onChange={(value) => {
													setIdCondominio(value);
													setCondominioPorId(value);
												}}
												filter={{ situacao: 'A' }}
												disabled={props.edit}
												initialValue={!props.edit && condominioSelecionado?.id}
											>
												<SyndikosSelect
													optionText='nome'
													style={{ marginBottom: 24 }}
													disableClearable
												/>
											</VeryHighLimitReferenceInput>
										</Box>
										<Box display='flex' flex={1}>
											<Box flex={1} mr='0.5em'>
												{!loading ? (
													<TipoModalInput
														source='tipo'
														resource='tipo_unidade'
														label='Tipo de Unidade'
														variant='outlined'
														fullWidth
														validate={required()}
														watchDefaultValue={
															!props.edit && condominioSelecionado?.id === idCondominio
														}
														defaultValue={
															!props.edit && condominioData?.tipo_padrao_unidade
														}
														disabled={disabledCondition}
													/>
												) : (
													<div
														style={{
															marginTop: 25,
														}}
													>
														<LinearProgress />
													</div>
												)}
											</Box>
											<TextInputSyndikos
												source='unidade'
												ml='0.5em'
												validate={required()}
												parse={(v) => v && v.substr(0, 12)}
												disabled={disabledCondition}
											/>
										</Box>
										<Box display='flex' flex={1}>
											<Box flex={2} mr='0.5em' display='flex' gridGap='0.5rem'>
												<Box flex={1} mr='0.5em'>
													<TextInputSyndikos
														source='matricula'
														mr={0}
														label='Matrícula do Imóvel'
														parse={(v) => v && v.substr(0, 25)}
														disabled={disabledCondition}
													/>
												</Box>
												<CoeficienteInput
													source='coeficiente'
													label='Coef. de Arrecadação'
													margin='dense'
													variant='outlined'
													fieldProps={{
														label: 'Coef. de Arrecadação',
													}}
													initialValue={0}
													defaultValue={0}
													decimalScale={8}
													disabled={disabledCondition}
												/>
												<CoeficienteField
													id='coeficiente_total_unidade'
													name='coeficiente_total_unidade'
													label='Coef. Total da Unidade'
													margin='dense'
													variant='outlined'
													fieldProps={{
														label: 'Coef. Total da Unidade',
													}}
													initialValue={0}
													defaultValue={0}
													decimalScale={8}
													value={
														parseFloat(props.record.coeficiente) +
														parseFloat(props.record.coeficiente_garagens)
													}
													disabled
												/>
											</Box>
										</Box>
										<Box>
											<Box display='flex' flex={1}>
												<Box mr={2}>
													<NumberInput
														source='quantidade_moradores'
														name='quantidade_moradores'
														label='Quantidade de Moradores'
														margin='dense'
														variant='outlined'
														{...(props.record.id ? {} : { defaultValue: 0 })}
														parse={(value) =>
															(typeof value !== 'number' && !value) ||
															isNaN(value) ||
															parseInt(value) < 0
																? 0
																: parseInt(value)
														}
														disabled={disabledCondition}
														// step={1}
													/>
												</Box>
												<Box mr={2} width={'15em'}>
													<SyndikosSelect
														source='dia_vencimento'
														label='Dia do Vencimento'
														choices={dias}
														initialValue={99}
														variant='outlined'
														disabled={disabledCondition}
														disableClearable
														style={{ marginBottom: 16 }}
													/>
												</Box>
												<TextInputSyndikos
													maxWidth={'5px'}
													source='apelido'
													label='Apelido para Exportação Contábil'
													inputProps={{ maxLength: 22 }}
												/>
											</Box>
										</Box>
										<Box>
											<span>Entrega de boletos</span>
											<Box display='flex' flex={1}>
												<Field
													name='recebe_boleto_por_correio'
													id='recebe_boleto_por_correio'
													disabled={false}
													defaultValue={true}
													type='checkbox'
												>
													{({ input }) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={input.checked === false ? false : true}
																	onChange={(e) => {
																		input.onChange(e);
																	}}
																	disabled={disabledCondition}
																	name={input.name}
																/>
															}
															label='Correio'
															labelPlacement='end'
														/>
													)}
												</Field>
												<Field
													name='recebe_boleto_por_email'
													id='recebe_boleto_por_email'
													disabled={false}
													defaultValue={true}
													type='checkbox'
												>
													{({ input }) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={input.checked === false ? false : true}
																	onChange={(e) => {
																		input.onChange(e);
																	}}
																	disabled={disabledCondition}
																	name={input.name}
																/>
															}
															label='E-mail'
															labelPlacement='end'
														/>
													)}
												</Field>
												<Field
													name='recebe_boleto_por_condominio'
													id='recebe_boleto_por_condominio'
													disabled={false}
													defaultValue={true}
													type='checkbox'
												>
													{({ input }) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={input.checked === false ? false : true}
																	onChange={(e) => {
																		input.onChange(e);
																	}}
																	disabled={disabledCondition}
																	name={input.name}
																/>
															}
															label='Condominio'
															labelPlacement='end'
														/>
													)}
												</Field>
												<Field
													name='recebe_boleto_por_portal'
													id='recebe_boleto_por_portal'
													disabled={false}
													defaultValue={true}
													type='checkbox'
												>
													{({ input }) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={input.checked === false ? false : true}
																	onChange={(e) => {
																		input.onChange(e);
																	}}
																	disabled={disabledCondition}
																	name={input.name}
																/>
															}
															label='Portal'
															labelPlacement='end'
														/>
													)}
												</Field>
												<Field
													name='recebe_boleto_por_whatsapp'
													id='recebe_boleto_por_whatsapp'
													disabled={false}
													defaultValue={true}
													type='checkbox'
												>
													{({ input }) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={input.checked === false ? false : true}
																	onChange={(e) => {
																		input.onChange(e);
																	}}
																	disabled={disabledCondition}
																	name={input.name}
																/>
															}
															label='WhatsApp'
															labelPlacement='end'
														/>
													)}
												</Field>
											</Box>
										</Box>
									</BoxDivisor>
									<BoxDivisor titulo='Responsável pela Cobrança' flex={2}>
										<BoletoParaInput
											source='boleto_para'
											initialValue='Prop'
											{...{
												pessoasValue: value,
												hasTenant,
												setBoletoParaValue,
												selectingAmb,
												setSelectingAmb,
												recordValue: props?.record?.boleto_para,
												disabledCondition,
											}}
										/>
										<SepararArrecInput
											{...{
												boletoParaValue,
												idCondominio,
												disabledCondition,
												props,
											}}
											source='separar_arrecadao_ext_ord'
										/>
										<ProprietarioRecebeBoletoInquilinoInput
											{...{
												boletoParaValue,
												idCondominio,
												disabledCondition,
												props,
											}}
											source='proprietario_recebe_boleto_inquilino'
										/>
										<InquilinoRecebeBoletoProprietarioInput
											{...{
												boletoParaValue,
												idCondominio,
												disabledCondition,
												hasTenant,
												props,
											}}
											source='inquilino_recebe_boleto_proprietario'
										/>
									</BoxDivisor>
								</Box>
								<Box display='flex'>
									<Box flex={3} mr={4}>
										<BoxDivisor titulo='Grupo de Unidades'>
											<VeryHighLimitReferenceInput
												label='Selecionar Grupo'
												source='id_grupo_unidade'
												reference='grupo_unidade_condominio'
												variant='outlined'
												fullWidth
												filter={{
													id_condominio: idCondominio || condominioSelecionado?.id,
												}}
												disabled={disabledCondition}
											>
												<SyndikosSelect optionText='nome' style={{ marginBottom: 16 }} />
											</VeryHighLimitReferenceInput>
										</BoxDivisor>
										<BoxDivisor titulo='Pessoas Vinculadas'>
											{!loading && (
												<VinculosInput
													source={props.edit ? '_' : 'pessoas_unidades'}
													validate={
														!props.edit && [
															required(),
															(v) =>
																(v?.length < 1 ||
																	(v?.length === 1 && v[0]?.tipo === 'I')) && [
																	'A unidade precisa de um proprietário',
																],
														]
													}
												/>
											)}
										</BoxDivisor>
									</Box>
									<BoxDivisor titulo='Observações' flex={2}>
										<TextInputSyndikos
											multiline
											rows={8}
											source='observacao'
											label='Faça suas anotações nesse espaço'
											disabled={disabledCondition}
										/>
									</BoxDivisor>
								</Box>
							</Box>
							<Box
								display={aba === '1' ? 'flex' : 'none'}
								sx={
									(aba !== '1' && {
										width: 0,
										height: 0,
										position: 'absolute',
										top: 0,
										opacity: 0,
										userSelect: 'none',
										zIndex: -1000,
									}) || { width: '100%' }
								}
							>
								<BoxDivisor titulo='Histórico de Pessoas Vinculadas' width='100%'>
									{aba !== '1' ? (
										<></>
									) : (
										<ModalSizeEditableContextProvider
											customDialogProps={{
												minWidth: '61vw',
											}}
										>
											<ListaPessoaUnidade {...props} />
										</ModalSizeEditableContextProvider>
									)}
								</BoxDivisor>
							</Box>
							<Box
								display={aba === '2' ? 'flex' : 'none'}
								sx={
									(aba !== '2' && {
										width: 0,
										height: 0,
										position: 'absolute',
										top: 0,
										opacity: 0,
										userSelect: 'none',
										zIndex: -1000,
									}) || { width: '100%' }
								}
							>
								<BoxDivisor titulo='Histórico de Cobranças' width='100%'>
									{aba !== '2' ? (
										<></>
									) : (
										<HistoricoCobrancaList
											basePath='/contas_receber'
											resource='contas_receber'
											condominio={idCondominio}
											filter={{
												unidade: props.record.id,
											}}
										/>
									)}
								</BoxDivisor>
							</Box>
							<Box
								display={aba === '3' ? 'flex' : 'none'}
								sx={
									(aba !== '3' && {
										width: 0,
										height: 0,
										position: 'absolute',
										top: 0,
										opacity: 0,
										userSelect: 'none',
										zIndex: -1000,
									}) || { width: '100%' }
								}
							>
								{props.edit && props.record && (
									<BoxDivisor titulo='Unidades Subordinadas' width='100%'>
										<ModalContextProvider>
											<UnidadePrincipalContextProvider {...props}>
												{aba !== '3' ? (
													<></>
												) : (
													<UnidadesSubordinadasContextProvider {...props}>
														<CustomDialog disableBackdropClick={true} />
														<BoxUnidadesSubordinadas />
													</UnidadesSubordinadasContextProvider>
												)}
											</UnidadePrincipalContextProvider>
										</ModalContextProvider>
									</BoxDivisor>
								)}
							</Box>
							<Box
								display={aba === '4' ? 'flex' : 'none'}
								css={
									(aba !== '4' && {
										width: 0,
										height: 0,
										position: 'absolute',
										top: 0,
										opacity: 0,
										userSelect: 'none',
										zIndex: -1000,
									}) || { width: '100%' }
								}
							>
								<BoxDivisor titulo='Cadastro de Garagens' width='100%'>
									<CadastroDeGaragensBox {...props} />
								</BoxDivisor>
							</Box>
						</VinculosProvider>
					</TabPanel>
				</Box>
			</TabContext>
		</FormularioBox>
	);
};

const BoletoParaInput = ({
	pessoasValue,
	hasTenant,
	setBoletoParaValue,
	selectingAmb,
	setSelectingAmb,
	recordValue,
	disabledCondition,
	...rest
}) => {
	const { input } = useInput(rest);
	const selectingAmbRef = useRef(selectingAmb);
	const recordValueRef = useRef(recordValue);

	useEffect(() => {
		selectingAmbRef.current = selectingAmb;
	}, [selectingAmb]);
	useEffect(() => {
		recordValueRef.current = recordValue;
	}, [recordValue]);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const [idCondominio] = useState(condominioSelecionado?.id || undefined);

	const { data: condominioData } = useGetOne('condominios', idCondominio || condominioSelecionado?.id);

	const handleChange = () => {
		if (
			pessoasValue.length > 1 &&
			(pessoasValue[1].data_saida
				? isSameDay(parseISO(pessoasValue[1].data_saida), new Date()) ||
				  isAfter(parseISO(pessoasValue[1].data_saida), new Date())
				: true)
		) {
			if (selectingAmbRef.current) {
				input.onChange(condominioData?.opcao_cobranca_padrao_inquilino);
				setSelectingAmb(false);
			} else if (recordValue) {
				input.onChange(recordValue);
			} else {
				input.onChange(condominioData?.opcao_cobranca_padrao_inquilino);
			}
		}
	};
	useEffect(handleChange, [pessoasValue]);

	const updateBoletoParaValue = () => setBoletoParaValue(input.value);
	useEffect(updateBoletoParaValue, [input.value]);

	return (
		<FormControl {...input} style={{ margin: '10px 0 10px' }} disabled={disabledCondition}>
			<FormLabel>Emitir o boleto para</FormLabel>
			<RadioGroup aria-label='boleto_para' name='boleto_para' value={input.value}>
				<FormControlLabel
					value='Inq'
					control={<Radio color='primary' />}
					disabled={!hasTenant || disabledCondition}
					label='Inquilino'
				/>
				<FormControlLabel
					value='Amb'
					control={<Radio color='primary' />}
					disabled={!hasTenant || disabledCondition}
					label='Inquilino (Ordinárias) e Proprietário (Extraordinárias)'
				/>
				<FormControlLabel value='Prop' control={<Radio color='primary' />} label='Proprietário' />
			</RadioGroup>
		</FormControl>
	);
};

const BooleanInputResponsavelCobrancaBase = ({
	disablingOptionSelected,
	idCondominio,
	disabledCondition,
	label = '',
	...rest
}) => {
	const { change, batch } = useForm(rest);
	const { initialValues } = useFormState();
	const { input } = useInput(rest);

	function handleChange() {
		if (disablingOptionSelected) {
			input.onChange(false);
		}
	}
	useEffect(handleChange, [disablingOptionSelected]);

	function handleChangeInitialValue() {
		batch(() => change(rest?.source, initialValues[rest?.source]));
		input.onChange(initialValues[rest?.source]);
	}
	useEffect(handleChangeInitialValue, [initialValues]);

	function handleChangeValue(value) {
		if (value) {
			batch(() => change(rest?.source, value));
			input.onChange(value);
		}
	}

	return (
		<BooleanInput
			{...input}
			options={{ checked: input.value }}
			onChange={handleChangeValue}
			initialValue={false}
			label={label}
			disabled={disablingOptionSelected || !idCondominio || disabledCondition}
		/>
	);
};

const SepararArrecInput = ({ boletoParaValue, idCondominio, disabledCondition, ...rest }) => (
	<BooleanInputResponsavelCobrancaBase
		{...rest}
		disablingOptionSelected={boletoParaValue === 'Amb'}
		idCondominio={idCondominio}
		disabledCondition={disabledCondition}
		label='Separar Arrecadações Ordinárias e Extraordinárias'
	/>
);

const ProprietarioRecebeBoletoInquilinoInput = ({ boletoParaValue, idCondominio, disabledCondition, ...rest }) => (
	<BooleanInputResponsavelCobrancaBase
		{...rest}
		disablingOptionSelected={boletoParaValue === 'Prop'}
		idCondominio={idCondominio}
		disabledCondition={disabledCondition}
		label='Proprietário Recebe Boleto Inquilino'
	/>
);

const InquilinoRecebeBoletoProprietarioInput = ({
	boletoParaValue,
	idCondominio,
	disabledCondition,
	hasTenant,
	...rest
}) => (
	<BooleanInputResponsavelCobrancaBase
		{...rest}
		disablingOptionSelected={boletoParaValue === 'Inq' || !hasTenant}
		idCondominio={idCondominio}
		disabledCondition={disabledCondition || !hasTenant}
		label='Inquilino Recebe Boleto Proprietário'
	/>
);
