import React, { useContext } from 'react';
import Chip from '@material-ui/core/Chip';

import ChipInput from 'material-ui-chip-input';

import { ModalContext } from '../../common/ModalContext';

export const ContasInput = ({ handleSubmit, setNomes }) => {
	const { setModalValue } = useContext(ModalContext);
	return (
		<ChipInput
			style={{ marginTop: 15 }}
			variant='outlined'
			fullWidth
			onChange={setNomes}
			label='Nome'
			blurBehavior='add'
			onKeyPress={(e) => {
				if (e.key === 'Enter') {
					e.stopPropagation();
					handleSubmit();
					setModalValue((v) => ({ ...v, open: false }));
				}
			}}
			onBeforeAdd={(v) => {
				return v.length > 1 && v.length <= 40;
			}}
			chipRenderer={({ value, className, handleDelete }, key) => (
				<Chip key={key} className={className} onDelete={handleDelete} label={value} />
			)}
		/>
	);
};
