import * as React from 'react';
import { Edit, useNotify, useRedirect, useRefresh } from 'react-admin';

import { PessoaFormulario } from './form';

export const PessoaEdit = (props) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	// Função para substituir onSuccess do <Edit /> caso seja escolhida mensagem customizada
	// eslint-disable-next-line
	const onSuccess = (response) => {
		const data = response?.data;
		if (data) {
			notify(`Pessoa "${data.nome}" alterada com sucesso`);
			redirect('list', props.basePath);
			refresh();
		}
	};
	return (
		<Edit component='div' title={'Editar Pessoa'} undoable={false} {...props}>
			<PessoaFormulario edit {...props} />
		</Edit>
	);
};
