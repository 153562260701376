import React, { useContext } from 'react';

import TextField from '@material-ui/core/TextField';

import NovaLeituraContext from './NovaLeituraContext';

const LeituraField = () => {
	const { nomeLeitura } = useContext(NovaLeituraContext);
	return (
		<TextField
			disabled
			label='Leitura utilizada para o cálculo'
			margin='dense'
			style={{ width: '75%' }}
			value={nomeLeitura}
		/>
	);
};

export default LeituraField;
