import React from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import MenuItem from '@material-ui/core/MenuItem';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

import CustomDialogNotificacaoBoleto from './CustomDialogNotificacaoBoleto';

export * from './ButtonEnviaEmails';

export { CustomDialogNotificacaoBoleto };

export const ButtonDisponibilizar = ({ idsRegistrosSelecionados, disabled }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const dp = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const enviarDisponibilizarRequest = React.useCallback(() => {
		dp.simplePost('contas_receber_boletos/disponibilizar_boletos', {
			data: { list_ids: idsRegistrosSelecionados },
		})
			.then(() => {
				notify('Boletos disponibilizados');
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <CustomDialogNotificacaoBoleto idsRegistrosSelecionados={idsRegistrosSelecionados} />,
				}));
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Não foi possível disponibilizar os boletos, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			})
			.finally(() => {
				refresh();
			});
	}, [dp, idsRegistrosSelecionados, notify, refresh, setModalValue]);
	return (
		<div>
			<MenuItem disabled={!idsRegistrosSelecionados.length || !!disabled} onClick={enviarDisponibilizarRequest}>
				Disponibilizar APP
			</MenuItem>
		</div>
	);
};

export default ButtonDisponibilizar;
