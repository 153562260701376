// import React, { useContext, useRef } from 'react';
import React from 'react';

import { BoxComMargemDireita } from '../../commons/BeneficiarioFields';
import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const TipoIntegracoesField = () => (
	<BoxComMargemDireita sx={{ 'max-width': '50%' }}>
		<EditaContaBancariaAutocomplete
			source='tipo_integracao'
			name='tipo_integracao'
			id='tipo_integracao'
			label='Tipo de integração'
		/>
	</BoxComMargemDireita>
);

export default TipoIntegracoesField;
