import React from 'react';
import { Field } from 'react-final-form';

import MultiSelect from 'components/common/Selects';
import { useRequestOptionsUnidades } from 'components/common/Selects/hooks';

type TypePropsSelectUnidades = {
	record?: {
		unidades?: number[];
	};
};

type TypePropsFieldSelectUnidades = {
	props: TypePropsSelectUnidades;
};

const FieldSelectUnidades = ({ props }: TypePropsFieldSelectUnidades) => {
	const { options } = useRequestOptionsUnidades();
	const [unidadesSelecionadas, setUnidadesSelecionadas] = React.useState([]);

	React.useEffect(() => {
		setUnidadesSelecionadas(options.filter((o: { id: number }) => props?.record?.unidades?.includes(o.id)));
	}, [options]);

	return (
		<Field name='unidades' id='unidades'>
			{({ input }) => {
				React.useEffect(() => {
					input.onChange(unidadesSelecionadas.map((u: { id: number }) => u.id));
				}, [unidadesSelecionadas]);
				return (
					<>
						<MultiSelect
							variant='formatsUnidades'
							value={unidadesSelecionadas}
							setValue={setUnidadesSelecionadas}
							options={options}
							size='small'
						/>
					</>
				);
			}}
		</Field>
	);
};

export default FieldSelectUnidades;
