import React from 'react';

import { TextField } from '@material-ui/core';

import { TNomeFieldProps } from '../types';

export const NomeField: React.FC<TNomeFieldProps> = ({ label = 'Digite o nome da Configuração', nome, setNome }) => {
	return (
		<TextField
			label={label}
			value={nome}
			size='small'
			onChange={(e) => setNome(e.target.value.slice(0, 100))}
			fullWidth
		/>
	);
};

export default NomeField;
