import React, { useEffect, useState } from 'react';
import { BoxDivisor } from 'components/common/Formulario';
import { OccurrenceActions, CustomDataProvider, Occurrence } from '../types';
import { OccurrenceChat } from './OccurrenceChat';
import { useDataProvider } from 'react-admin';
import { OccurrenceStyles } from '../styles';
import { OccurrenceSendMessage } from './OccurrenceSendMessage';

export interface MessagesHistoryProps {
	ocorrencia: Occurrence;
}

export const MessagesHistory: React.FC<MessagesHistoryProps> = ({ ocorrencia }) => {
	const [actionsOccurrence, setActionsOccurrence] = useState<OccurrenceActions[]>([]);
	const dataProvider: CustomDataProvider = useDataProvider();
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const [loading, setLoading] = React.useState(true);

	const classes = OccurrenceStyles();

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [actionsOccurrence, loading]);

	useEffect(() => {
		dataProvider
			.getOnePkMiddle('acao_ocorrencias/buscar_ocorrencias', { id: ocorrencia.id })
			.then((response: any) => {
				setActionsOccurrence(response.data);
				setLoading(false);
			});
	}, []);

	return (
		<BoxDivisor titulo='Histórico de Mensagens' width='100%'>
			<OccurrenceChat
				actionsOccurrence={actionsOccurrence}
				scrollRef={scrollRef}
				classes={classes}
				loading={loading}
			/>
			{ocorrencia.situacao == 'A' && (
				<OccurrenceSendMessage occurrenceId={ocorrencia.id} classes={classes} setLoading={setLoading} />
			)}
		</BoxDivisor>
	);
};

export default MessagesHistoryProps;
