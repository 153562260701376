import React, { useState } from 'react';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MultiSelect from 'components/common/Selects';
import {
	useRequestOptionsUnidades,
	useRequestOptionsLeituras,
	useRequestOptionsIdentificacaoArrecadacao,
} from 'components/common/Selects/hooks';

import { format } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import { RelatorioMonthPicker, TODAY } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesImpressaoLeiturasCheckboxGroup = ({
	imprimirTotalUnidade,
	imprimirData,
	handleChange,
	imprimirAssinatura,
}) => {
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTotalUnidade}
							onChange={(e, checked) => handleChange(checked, 'imprimir_total_by_unidade')}
							name='imprimir_total_by_unidade'
						/>
					}
					label='Imprimir Total por Unidade'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirData}
							onChange={(e, checked) => handleChange(checked, 'imprimir_data')}
							name='imprimir_data'
						/>
					}
					label='Imprimir Data'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</FormGroup>
		</FormControl>
	);
};

export const DemonstrativoLeituraDivisor = ({ idCondominio, tipoRelatorio }) => {
	const { options: optionsUnidades = [] } = useRequestOptionsUnidades();
	const relacaoUnidadeMedida = {
		m: 'm³',
		k: 'kW/h',
	};
	const [dataInicial, setDataInicial] = useState(TODAY);
	const [unidades, setUnidades] = useState([]);
	const [dataFinal, setDataFinal] = useState(TODAY);
	const [identificacao, setIdentificacao] = useState({
		id: 0,
		nome: 'Todas',
	});
	const [leitura, setLeitura] = useState(null);
	const { options: leituras } = useRequestOptionsLeituras(idCondominio);
	const { options: identificacoes } = useRequestOptionsIdentificacaoArrecadacao(idCondominio);

	const {
		opcoesImpressao: { imprimir_total_by_unidade, imprimir_data, imprimir_assinatura },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	React.useEffect(() => {
		if (identificacao || leitura) {
			setLeitura(null);
			setIdentificacao({
				id: 0,
				nome: 'Todas',
			});
		}
	}, [idCondominio]);

	const requestData = {
		id_condominio: idCondominio,
		mes_inicial: format(dataInicial, 'MM/yyyy'),
		mes_final: format(dataFinal, 'MM/yyyy'),
		identificacao: identificacao ? identificacao.id : 0,
		id_leitura: leitura ? leitura.id : null,
		unidades: unidades.map((u) => u.id),
		nome_relatorio: tipoRelatorio.action,
		imprimir_total_by_unidade: imprimir_total_by_unidade,
		imprimir_data: imprimir_data,
		imprimir_assinatura: imprimir_assinatura,
	};

	return (
		<BoxDivisor titulo='Demonstrativo de Leituras'>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<RelatorioMonthPicker
						style={{ marginRight: '1em' }}
						label='Mês Inicial'
						handleChange={(newValue) => setDataInicial(newValue)}
						value={dataInicial}
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<RelatorioMonthPicker
						label='Mês Final'
						handleChange={(newValue) => setDataFinal(newValue)}
						value={dataFinal}
						fullWidth
					/>
				</Box>
				<Box flex={2}>
					<Autocomplete
						value={identificacao}
						onChange={(_, newValue) => setIdentificacao(newValue)}
						options={[...Object.values(identificacoes).filter((v) => v.id !== 0), { id: 0, nome: 'Todas' }]}
						getOptionLabel={(o) => o.nome}
						getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
						renderInput={(params) => (
							<TextField {...params} label='Identificação de Arrecadação' variant='outlined' />
						)}
						disabled={identificacoes.length === 1}
						clearText='Limpar'
						autoHighlight
						noOptionsText='Nenhuma identificação disponível'
						size='small'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<Box width='50%' mr='1em'>
					<Autocomplete
						value={leitura}
						onChange={(_, newValue) => setLeitura(newValue)}
						options={leituras}
						getOptionLabel={(o) => {
							const nome = o?.nome || '';
							const unidadeMedida = o?.unidade_medida
								? ` em ${relacaoUnidadeMedida[o.unidade_medida]}`
								: '';
							return `${nome}${unidadeMedida}`;
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Leitura'
								variant='outlined'
								helperText='Este campo é obrigatório'
								error={false}
							/>
						)}
						clearText='Limpar'
						autoHighlight
						noOptionsText='Nenhuma leitura disponível'
						size='small'
						fullWidth
					/>
				</Box>
				<Box>
					<OpcoesImpressaoLeiturasCheckboxGroup
						handleChange={handleChangeOpcoesImpressao}
						imprimirTotalUnidade={imprimir_total_by_unidade}
						imprimirData={imprimir_data}
						imprimirAssinatura={imprimir_assinatura}
					/>
				</Box>
			</Box>
			<Box>
				<MultiSelect
					variant='formatsUnidades'
					options={optionsUnidades}
					setValue={setUnidades}
					value={unidades}
				/>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={Boolean(leitura)} />
			</Box>
		</BoxDivisor>
	);
};
