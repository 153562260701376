import React, { useState, useContext } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { TextField, Button, makeStyles, Link } from '@material-ui/core';
import { ArrowForward, Visibility, VisibilityOff } from '@material-ui/icons';

import { AdminContext } from '../common/AdminContextProvider';
import { CondominiosContext } from 'context/CondominioContextProvider';

const useLoginStyles = makeStyles((theme) => ({
	main: {
		width: '100vw',
		height: '100vh',
	},
	mainContainer: {
		display: 'flex',
		[theme.breakpoints.down(800)]: {
			flexDirection: 'column',
			height: 'auto',
		},
		position: 'absolute',
		top: '50%',
		left: '50%',
		borderRadius: '10px',
		boxShadow: 'rgba(14, 30, 37, 0.5) 0px 3px 4px 0px, rgba(14, 30, 37, 0.7) 0px 3px 16px 0px',
		transform: 'translate(-50%, -50%)',
		overflow: 'hidden',
		height: '535px',
	},
	formContainer: {
		backgroundColor: '#FFFFFF',
		width: '320px',
		padding: '0 1.5rem',
		boxSizing: 'border-box',
	},
	formLogo: {
		margin: '4em auto auto',
		width: '225px',
	},
	form: {
		height: '60%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gridGap: '4rem',
	},
	formSuporte: {
		height: '60%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	formBanner: {
		[theme.breakpoints.down(800)]: {
			borderRadius: '10px',
			width: '100%',
		},
		'& > *': {
			height: '100%',
		},
	},
	formGroup: {
		display: 'flex',
		flexDirection: 'column',
		gridGap: '2em',
		margin: '2em 0',
		'& > *': {
			display: 'flex',
			width: '100%',
		},
	},
	formFooter: {
		display: 'flex',
		flexDirection: 'column',
		gridGap: '2em',
		margin: '2em auto',
		'& > *': {
			justifyContent: 'center',
			display: 'flex',
			width: '80%',
			margin: 'auto',
		},
	},
	formFooterSuporte: {
		display: 'flex',
		flexDirection: 'column',
		gridGap: '1em',
		'& > *': {
			justifyContent: 'center',
			display: 'flex',
			width: '80%',
			margin: 'auto',
		},
	},
}));

const ImagemBanner = () => {
	const style = useLoginStyles();
	const { midias } = useContext(AdminContext);
	const PictureComponent = (
		<picture className={style.formBanner}>
			<source srcSet={midias.banner_g} media='(min-width: 1555px)' />
			<source srcSet={midias.banner_m} media='(min-width: 1200px)' />
			<source srcSet={midias.banner_p} media='(min-width: 900px)' />
			<source srcSet={midias.banner_pp} media='(min-width: 800px)' />
			<img src={midias.banner_mobile} alt='Banner' />
		</picture>
	);
	if (midias.url_callback_para_banner_login) {
		return (
			<a href={midias.url_callback_para_banner_login} target='_blank' rel='noreferrer'>
				{PictureComponent}
			</a>
		);
	}
	return PictureComponent;
};

const SyndikosLoginPage = ({ isSuporte = false }) => {
	const style = useLoginStyles();
	const notify = useNotify();
	const [password, setPassword] = useState('');
	const [username, setUsername] = useState('');
	const [senhaDoDia, setSenhaDoDia] = useState('');
	const [schemaName, setSchemaName] = useState('');
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isDailyPasswordVisible, setIsDailyPasswordVisible] = useState(false);
	const [messageError, setMessageError] = useState(false);
	const login = useLogin();
	const { midias } = useContext(AdminContext);
	const { setReload } = useContext(CondominiosContext);

	const submit = (e) => {
		e.preventDefault();
		login({
			username,
			password,
			is_suporte: isSuporte,
			senha_do_dia: senhaDoDia,
			schema_name: schemaName || null,
		})
			.then((data) => {
				setReload();
				if (data && Object.keys(data) && data.msg) notify(data.msg, data.msg_type);
			})
			.catch((e) => {
				setMessageError(e.response.data.error || 'Usuário ou senha inválidos!');
			});
	};

	if (!midias || !Object.keys(midias).length) return <></>;
	return (
		<div
			className={style.main}
			style={{
				backgroundImage: `linear-gradient(170deg, ${midias.fundo_top} 0%,  ${midias.fundo_bottom} 100%)`,
			}}
		>
			<main className={style.mainContainer}>
				<div className={style.formContainer}>
					<div className={style.formLogo}>
						<img src={midias.logo_horizontal} alt='Logo' style={{ width: '100%' }} />
					</div>
					<form onSubmit={submit} className={isSuporte ? style.formSuporte : style.form}>
						<div className={style.formGroup}>
							<div>
								<TextField
									name='username'
									inputProps={{
										className: 'on_login',
									}}
									fullWidth
									type='text'
									value={username}
									label='Usuário'
									autoFocus={true}
									variant='outlined'
									error={messageError}
									helperText={
										messageError &&
										(isSuporte ? 'Usuário, senha ou schema inválidos!' : messageError)
									}
									onChange={(e) => setUsername(e.target.value)}
									size='small'
								/>
							</div>
							<div>
								<TextField
									name='password'
									inputProps={{
										className: 'on_login',
									}}
									fullWidth
									type={isPasswordVisible ? 'text' : 'password'}
									label='Senha'
									value={password}
									variant='outlined'
									error={messageError}
									onChange={(e) => setPassword(e.target.value)}
									size='small'
									InputProps={{
										endAdornment: (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												{isPasswordVisible ? (
													<VisibilityOff
														style={{
															cursor: 'pointer',
															color: 'black',
														}}
														onClick={() => setIsPasswordVisible(false)}
													/>
												) : (
													<Visibility
														style={{
															cursor: 'pointer',
															color: 'black',
														}}
														onClick={() => setIsPasswordVisible(true)}
													/>
												)}
											</div>
										),
									}}
								/>
							</div>

							{isSuporte && (
								<div>
									<TextField
										name='senha_do_dia'
										fullWidth
										type={isDailyPasswordVisible ? 'text' : 'password'}
										label='Senha do dia'
										value={senhaDoDia}
										variant='outlined'
										error={messageError}
										onChange={(e) => setSenhaDoDia(e.target.value)}
										size='small'
										InputProps={{
											endAdornment: (
												<div style={{ display: 'flex', alignItems: 'center' }}>
													{isDailyPasswordVisible ? (
														<VisibilityOff
															style={{
																cursor: 'pointer',
																color: 'black',
															}}
															onClick={() => setIsDailyPasswordVisible(false)}
														/>
													) : (
														<Visibility
															style={{
																cursor: 'pointer',
																color: 'black',
															}}
															onClick={() => setIsDailyPasswordVisible(true)}
														/>
													)}
												</div>
											),
										}}
									/>
								</div>
							)}
							{isSuporte && (
								<div>
									<TextField
										name='schema_name'
										fullWidth
										type='text'
										label='Nome do schema'
										value={schemaName}
										variant='outlined'
										error={messageError}
										onChange={(e) => setSchemaName(e.target.value)}
										size='small'
									/>
								</div>
							)}
						</div>
						<div>
							<div className={isSuporte ? style.formFooterSuporte : style.formFooter}>
								<Button type='submit' variant='contained' color='primary' endIcon={<ArrowForward />}>
									Acessar
								</Button>
								<Link
									variant='body2'
									color='primary'
									component='button'
									type='reset'
									onClick={() =>
										window.open('https://areadocliente.sci10.com.br/loginForm.php?recuperarSenha=1')
									}
								>
									Esqueci minha senha
								</Link>
							</div>
						</div>
					</form>
				</div>
				<ImagemBanner />
			</main>
		</div>
	);
};

export default SyndikosLoginPage;
