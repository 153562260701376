import {
	createformataPayloadValuesLinhas,
	formataPayloadValuesBeneficiario,
	formataPayloadValuesDesconto,
	formataPayloadValuesIdContaBancaria,
	formataPayloadValuesInstrucoesComTipoValor,
	formataPayloadValuesTabEmissaoBoletos,
	formataPayloadValuesTabInformacao,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	formataPayloadValuesLimitePagamento,
} from '../../default/ContaCorrenteProps/getFormataPayloadValues';

const formataPayloadValuesMulta = (props) =>
	formataPayloadValuesInstrucoesComTipoValor({
		...props,
		field: 'multa',
		valorIsensao: 'ISE',
	});

const formataPayloadValuesJuros = (props) =>
	formataPayloadValuesInstrucoesComTipoValor({
		...props,
		field: 'juros',
		valorIsensao: 'JIS',
	});

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }) => {
	const payload = {
		...getPadraoPayloadValuesInstrucoesRemessa(),
		...formataPayloadValuesMulta({ values }),
		...formataPayloadValuesJuros({ values }),
		...formataPayloadValuesDesconto({ values }),
		...formataPayloadValuesLimitePagamento({ values, valorIsensao: 'NBD' }),
	};
	if (!payload.desconto_dias) payload.desconto_dias = undefined;

	return payload;
};

const formatadoresPayloadValues = {
	// Dados de Tab Informação
	tab_informacao: formataPayloadValuesTabInformacao,

	// Dados de Tab Emissão Boletos
	tab_emissao_boletos: formataPayloadValuesTabEmissaoBoletos,

	cobranca_config: getFormatadorPayloadCobrancaConfig({
		formatadores: [
			// Dados Fixos
			formataPayloadValuesIdContaBancaria,

			// Dados de Tab Emissão Boletos
			formataPayloadValuesBeneficiario,

			// Dados de Tab Instruções de Cobrança Remessa
			formataPayloadValuesTabInstrucoesCobrancaRemessa,

			// Dados de Tab Instruções de Cobrança Boleto
			createformataPayloadValuesLinhas({ numeroLinhas: 5 }),
		],
	}),
};

export default formatadoresPayloadValues;
