import React, { useContext, useEffect } from 'react';

import { useListContext } from 'react-admin';

import { TabelaRowContext } from '../../../common/Tabela';

const VinculoField = ({ record }) => {
	const { data } = useListContext();
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);

	const update = () => {
		if (data && data[rowRecord?.id]) {
			setRowRecord(data[rowRecord?.id]);
		}
	};

	useEffect(update, [data]);

	return <span>{record ? (record?.tipo === 'P' ? 'Proprietário' : 'Inquilino') : ''}</span>;
};

export default VinculoField;
