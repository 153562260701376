import React from 'react';
import { Create, useNotify, useRedirect, useRefresh } from 'react-admin';
import ComunicadoGeralForm from './form';
import { ComunicadoResponse } from './types';

export const ComunicadoCreate = (props: any) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (response: ComunicadoResponse) => {
		const data = response?.data;
		if (data) {
			notify('Comunicado criado com sucesso');
			redirect('list', props.basePath);
			refresh();
		}
	};
	return (
		<>
			<Create component='div' title={'Cadastrar Comunicado Geral'} onSuccess={onSuccess} {...props}>
				<ComunicadoGeralForm {...props} />
			</Create>
		</>
	);
};
