import React, { useState, useContext } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ContasContext } from '../contexts/ContasContext';
import { CustomDialogBodySizeEditable } from '../../../common/ModalSizeEditableContext';
import FluxoImportacaoPlanoContext from './FluxoImportacaoPlanoContext';
import { TooltipVoltarIconButton } from '../../../common/buttons/BotaoVoltar';
import { TooltipIconButtonCancel } from '../../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../../common/buttons/ButtonConfirm';

const Botoes = ({ valid }) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { contas } = useContext(ContasContext);
	const {
		planoParaImportacaoSelecionado: plano,
		planoImportando: { id },
		setStepComponent,
		handleClose,
	} = useContext(FluxoImportacaoPlanoContext);

	const handleSubmit = () => {
		dataProvider
			.copyPlan('importar_contas', {
				data: {
					id_plano_origem: plano.id,
					id_plano_destino: id,
				},
			})
			.then(() => {
				contas.contaRequest('refresh', {}, 'contas_despesa');
				contas.contaRequest('refresh', {}, 'contas_receita');
				handleClose();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify('Erro ao realizar a importação', 'warning');
			});
	};

	return (
		<>
			<TooltipVoltarIconButton
				onClick={() => {
					setStepComponent('OpcoesImportacao');
				}}
			/>
			<TooltipIconButtonCancel onClick={handleClose} />
			<TooltipIconButtonConfirm disabled={!valid} onClick={handleSubmit} />
		</>
	);
};

const ImportPlanoDePlanoCadastradoModalBody = () => {
	const {
		planosDisponiveisParaImportacao,
		setPlanoParaImportacaoSelecionado: setPlano,
		planoParaImportacaoSelecionado: plano,
		planoImportando: { descricao },
	} = useContext(FluxoImportacaoPlanoContext);
	const [valid, setValid] = useState(Boolean(plano?.id));

	const handleChange = (_, newValue) => {
		if (newValue) {
			setValid(newValue.id > 0);
			setPlano(newValue);
		} else {
			setValid(false);
			setPlano(null);
		}
	};

	return (
		<CustomDialogBodySizeEditable
			title={valid ? `Copiar outro plano para "${descricao}"` : 'Selecione um plano para importar as contas'}
			customActions={<Botoes {...{ valid }} />}
			form={{
				component: (
					<Box margin='0.5rem'>
						<Autocomplete
							options={planosDisponiveisParaImportacao}
							value={plano}
							onChange={handleChange}
							getOptionLabel={(option) => option.descricao}
							getOptionSelected={(option, value) => option.id === value.id}
							renderInput={(params) => (
								<TextField {...params} label='Selecione um plano de condomínio' variant='outlined' />
							)}
						/>
					</Box>
				),
			}}
		/>
	);
};

export default ImportPlanoDePlanoCadastradoModalBody;
