import React, { useContext } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBody } from '../../../../common/ModalContext';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';

const ModalConfirmacaoExcluirLote = ({ ids }) => {
	const { carregaValoresCB } = useContext(ArrecadacoesContext);
	const dataProvider = useDataProvider();
	const notifty = useNotify();
	const refresh = useRefresh();
	const {
		palette: { syndikosRed },
	} = useTheme();
	const handleSubmit = () => {
		dataProvider
			.deleteMany('arrecadacao', { ids: ids || [] })
			.then((responsesData) => {
				const responses = responsesData?.data || [];
				const firstRejectedResponse = responses.find((r) => r?.status === 'rejected');
				if (firstRejectedResponse) return Promise.reject(firstRejectedResponse.reason);
				const ExclusoesSucedidas = responses.filter((r) => r?.status === 'fulfilled');
				notifty(
					ExclusoesSucedidas.length > 1
						? `${ExclusoesSucedidas.length} arrecadações excluídas com sucesso`
						: '1 arrecadação excluída com sucesso'
				);
				refresh();
				try {
					carregaValoresCB()();
				} catch (e) {}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notifty(
					Object.values(e?.response?.data || {})[0] || 'Erro inesperado, tente recarregar a página',
					'warning'
				);
			});
	};
	return (
		<CustomDialogBody
			customSubmitLabel='Excluir'
			form={{ handleSubmit, valid: true }}
			remove
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
							{`${(ids || []).length} ${
								(ids || []).length > 1 ? 'arrecadações' : 'arrecadação'
							} selecionada`}
							<br />
							Essa ação é irreversível.
							<br />
							Todo o histórico será excluído.
						</Typography>
					</Box>
				</Box>
			}
			title={`Tem certeza que deseja excluir ${(ids || []).length > 1 ? 'as arrecadações' : 'a arrecadação'}?`}
		/>
	);
};

export default ModalConfirmacaoExcluirLote;
