import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../../../common/CurrencyInput';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';

const ModalPercentualNovaLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const dP = useDataProvider();
	const notify = useNotify();
	const [disabledForProcessing, setDisabledForProcessing] = useState(false);
	const [percentual, setPercentual] = useState(dadosNovaLeitura.dados_config.percentual || 0);
	const handleBack = () => {
		setModalStep('5P');
	};
	const handleNext = () => {
		setDisabledForProcessing(true);
		dP.create('leitura', {
			data: {
				...dadosNovaLeitura,
				dados_config: {
					...dadosNovaLeitura.dados_config,
					percentual: percentual,
				},
			},
		})
			.then((response) => {
				const data = response?.data;
				if (data) setDadosNovaLeitura((v) => ({ ...v, id: data.id }));
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setDisabledForProcessing(false);
				notify(`Erro ao cadastrar nova leitura: ${e}`, 'warning');
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Informe o valor do percentual'
			form={{
				component: (
					<TextField
						fullWidth
						name='valorPercentual'
						id='valorPercentualID'
						value={percentual}
						onChange={(event) => {
							if (!event.target.value) event.target.value = 0;
							if (event.target.value > 100) event.target.value = 100;
							setPercentual(event.target.value);
						}}
						inputProps={sanitizeListNumberFormatBRLProps({
							decimalScale: 2,
							suffix: '%',
						})}
						InputProps={{
							inputComponent: NumberFormatBRL,
						}}
					/>
				),
			}}
			customActions={
				<>
					<Button variant='text' onClick={handleCancel} size='small' startIcon={<CloseIcon />}>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} />
					<Button
						variant='contained'
						onClick={handleNext}
						color='primary'
						disabled={disabledForProcessing}
						size='small'
						startIcon={<CheckIcon />}
					>
						Salvar
					</Button>
				</>
			}
		/>
	);
};

export default ModalPercentualNovaLeitura;
