import React from 'react';

import InstrucoesRemessaFields from '../../commons/InstrucoesRemessaFields';

const emptyProps = {};

const DescontoFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaFields
		sourcePrincipal='desconto'
		propsTipo={{
			source: 'desconto_tipo',
			label: 'Desconto',
			...propsTipo,
		}}
		propsValor={{
			source: 'desconto_valor',
			...propsValor,
		}}
		propsDias={{
			source: 'desconto_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoDesconto = ({
	sources: { sourceValor, sourceDias } = emptyProps,
	optionsIsencoesTipo,
}) => ({
	sources: {
		sourceValor,
		sourceDias,
	},
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoDesconto =
	({ sources: { sourceValor, sourceDias } = emptyProps, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);

		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
		}
	};

export default DescontoFields;
