import { Box, Typography, Button } from '@material-ui/core';
import { CloseButton } from 'components/common/commonComponentsTSX';
import { useGetIdentity } from 'react-admin';
import phpSerialize from '../../../utils/php_serializer/phpSerialize';
import api from '../../../services/api';
import React from 'react';

const cria_texto_licenca = (limite_condominio: number, licencas_faltantes: number): string => {
	const baseInitText = `Seu contrato para utilização do sistema Syndkos possui ${limite_condominio} licenças, atualmente você `;
	const baseEndText = ' Para aderir novas licenças, clique no botão abaixo';

	const textos: { [key: number]: string } = {
		0: 'atingiu o seu limite.',
		1: `está utilizando ${limite_condominio - 1}, com isso, esta é a sua última.`,
		2: `está utilizando  ${limite_condominio - 2}, com isso restam apenas 2 licenças para cadastro de Condomínio.`,
		3: `está utilizando 
        ${limite_condominio - 3}, com isso restam apenas 3 licenças para cadastro de Condomínio.`,
	};

	const texto: string = textos[licencas_faltantes];

	const finalText = baseInitText + texto + baseEndText;
	return finalText;
};

type ModalAvisoLicencaProps = {
	dadosAdministradora: {
		limite_condominio: number;
		codigo_cliente_sci: number;
		razao_administradora: string;
	};
	onClose: void;
	licencas_faltantes: number;
};

const ModalAvisoLicenca = ({ dadosAdministradora, onClose, licencas_faltantes }: ModalAvisoLicencaProps) => {
	const { identity } = useGetIdentity();
	const [loginurl] = React.useState('https://areadocliente.sci10.com.br/loginFormDo.php?aDado=');
	const [urls, setUrls] = React.useState({
		licenca: loginurl,
	});

	React.useEffect(() => {
		if (identity)
			api.get('/api/v1/pwd').then((res) => {
				if (res?.data) {
					const dados = {
						login: identity.username,
						senha: res.data.pwd,
					};
					setUrls((state) => ({
						licenca:
							state.licenca +
							btoa(
								phpSerialize({
									...dados,
									pagina: 'modulo/loja/form.php?sistema=syndkos',
								})
							),
					}));
				}
			});
	}, [identity]);

	const aviso_licenca = cria_texto_licenca(dadosAdministradora.limite_condominio, licencas_faltantes);
	const titulo_licenca = `Prezado(a) cliente: ${dadosAdministradora.codigo_cliente_sci} - ${dadosAdministradora.razao_administradora}`;

	return (
		<Box
			style={{
				background: 'white',
				maxWidth: '65vw',
				maxHeight: '30vh',
				padding: '5vh 3vw',
				display: 'flex',
				flexDirection: 'column',
				gap: '5vh',
				alignItems: 'center',
				position: 'relative',
			}}
		>
			<Box style={{ display: 'flex', gap: '3vh', flexDirection: 'column' }}>
				<Typography variant='h4'>{titulo_licenca}</Typography>
				<Typography variant='h5'>{aviso_licenca}</Typography>
			</Box>
			<Button
				onClick={() => window.open(urls.licenca)}
				style={{ background: '#2F345D', color: '#fff', width: '25vw', height: '5vh' }}
				variant='contained'
			>
				Contratar mais Licenças
			</Button>
			<Box
				style={{
					position: 'absolute',
					bottom: '1vh',
					right: '1vw',
				}}
			>
				<CloseButton onClick={onClose} size={'small'} />
			</Box>
		</Box>
	);
};

export default ModalAvisoLicenca;
