import React, { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';

export const SimplesSyndikosSelect = ({
	optionText = 'name',
	choices,
	label,
	delay = 300,
	endAdornment = null,
	OptionComponent = null,
	onChange = () => {},
	value = null,
	touched = false,
	error = undefined,
	isRequired = false,
	getOptionSelected = (option, newValue) => option.id === newValue,
	InputLabelProps,
	inputProps,
	stylesSyndikosSelect = { marginBottom: 5 },
	multiple = false,
	...rest
}) => {
	const [renderReady, setRenderReady] = useState();
	const [options, setOptions] = useState([]);
	const [objOptions, setObjOptions] = useState();
	const [lengthOption, setLengthOption] = useState(0);

	const setChoices = () => {
		if (choices) {
			let optArr = choices.filter((c) => (c?.id || 0) !== 0);
			setOptions(optArr);
			let optObj = {};
			let maxLength = 0;
			for (const opt of optArr) {
				optObj[opt.id] = opt;
				if (opt[optionText]?.length > maxLength) {
					maxLength = opt[optionText]?.length;
				}
			}
			setLengthOption(maxLength);
			setObjOptions(optObj);
		}
		if (multiple && choices) {
			const newChoices = choices.filter((c) => !value.includes(c.id));
			setOptions(newChoices);
		}
	};

	useEffect(setChoices, [choices]);

	const delayRender = () => {
		setTimeout(() => setRenderReady(true), delay);
	};

	useEffect(delayRender, []);

	return renderReady && objOptions ? (
		<Autocomplete
			style={stylesSyndikosSelect}
			{...rest}
			options={options}
			value={value && value !== 'undefined' ? value : rest?.multiple ? [] : null}
			onChange={(_, newValue) => {
				if (multiple) {
					onChange(newValue);
					return;
				}
				onChange(newValue ? newValue.id : null);
			}}
			renderOption={(option) =>
				(OptionComponent && <OptionComponent {...{ option }} />) || (option && option[optionText]) || null
			}
			getOptionLabel={(option) => {
				const optionLabel = option[optionText] || objOptions[option]?.[optionText];
				if (optionLabel) return optionLabel;
				setRenderReady(false);
				delayRender();
				return '';
			}}
			multiple={multiple}
			getOptionSelected={getOptionSelected}
			renderInput={(params) => (
				<TextField
					{...params}
					margin='dense'
					fullWidth
					label={label}
					required={isRequired}
					error={!!(touched && error)}
					helperText={touched && error}
					InputLabelProps={InputLabelProps}
					inputProps={{
						...params.inputProps,
						...inputProps,
						...(endAdornment ? { endAdornment: endAdornment } : {}),
					}}
				/>
			)}
			noOptionsText='Nenhum resultado'
			disableListWrap
			PaperComponent={(props) => (
				<Paper
					{...props}
					style={{
						...props.style,
						width: `${lengthOption * 8}pt` || props.style.width,
						maxWidth: '320px',
						minWidth: '180px',
					}}
				/>
			)}
		/>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

export const SyndikosSelect = ({
	optionText = 'name',
	choices = [],
	source = '',
	label = '',
	delay = 300,
	OptionComponent = null,
	multiple = false,
	...rest
}) => {
	const {
		input: { onChange, value },
		meta: { touched, error },
		isRequired,
	} = useInput({ source, ...rest });

	return (
		<SimplesSyndikosSelect
			{...rest}
			{...{
				optionText,
				source,
				choices,
				label,
				delay,
				OptionComponent,
				onChange,
				value,
				touched: rest?.touched || touched,
				error: rest?.error || error,
				isRequired,
				multiple,
			}}
		/>
	);
};

export const SyndikosSelectRHF = ({
	optionText = 'name',
	choices,
	source,
	label,
	delay = 300,
	OptionComponent = null,
	onChange,
	touched,
	error,
	isRequired,
	value,
	...rest
}) => {
	return (
		<SimplesSyndikosSelect
			{...rest}
			{...{
				optionText,
				choices,
				source,
				label,
				delay,
				OptionComponent,
				onChange,
				value,
				touched: rest?.touched || touched,
				error: rest?.error || error,
				isRequired,
			}}
		/>
	);
};
