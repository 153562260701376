import React from 'react';
import { Box, TableSortLabel } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import {
	SyndkosTableProps,
	RowFixedSizeListProps,
	SyndkosTableHeadProps,
	SyndkosTableFooterProps,
	SyndkosFiltersTableProps,
	SyndkosTableBodyProps,
} from './types';
import useGetMinHeightTableBodyHook from './useGetMinHeightTableBodyHook';
import { UserThemeContext } from 'theme/context';

const FiltersTable: React.FC<SyndkosFiltersTableProps> = React.memo(({ filterColumns, dataLength = 0 }) => {
	if (dataLength <= 10) {
		return (
			<Box
				display={'flex'}
				height={'60px'}
				key={'filter_row'}
				alignItems={'center'}
				justifyContent={'space-between'}
				borderBottom={'1px rgb(0, 0, 0, 0.3) solid'}
			>
				{filterColumns.map((column, index) => (
					<Box width={column?.width} key={`filter ${index}`} padding={'12px'}>
						{column.component}
					</Box>
				))}
			</Box>
		);
	}
	return (
		<Box borderBottom={'1px rgb(0, 0, 0, 0.3) solid'} width={'100%'}>
			<Box
				display={'flex'}
				height={'60px'}
				width={'99%'}
				key={'filter_row'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				{filterColumns.map((column, index) => (
					<Box width={column?.width} key={`filter ${index}`} padding={'12px'}>
						{column.component}
					</Box>
				))}
			</Box>
			{/*NAO RETIRE ESSE BOX.
			É NECESSÁRIO PARA O ALINHAMENTO BOX QUE REPRESENTA OS FILTROS DO TABLE HEAD
			EM RELAÇÃO AO BOX QUE REPRESENTA O TABLE BODY*/}
			<Box width={'1%'} key={'box_ajusta_alinhamento'}></Box>
		</Box>
	);
});

const TableFooter: React.FC<SyndkosTableFooterProps> = React.memo(({ columnsTableHead, dataLength, ordering }) => {
	return (
		<TableHead columnsTableHead={columnsTableHead} dataLength={dataLength} origin='footer' ordering={ordering} />
	);
});

const TableHead: React.FC<SyndkosTableHeadProps> = React.memo(
	({ columnsTableHead, origin, dataLength = 0, ordering }) => {
		const { theme } = React.useContext(UserThemeContext);
		const orderingColor = theme === 'light' ? '#585e6b' : '#c2c2c2';
		if (dataLength <= 10) {
			return (
				<Box
					display={'flex'}
					height={'52px'}
					alignItems={'center'}
					key={origin === 'header' ? 'header_row' : 'footer_row'}
					justifyContent={'space-between'}
					{...(origin === 'header' ? { borderBottom: '1px rgb(0, 0, 0, 0.3) solid' } : {})}
					{...(origin === 'footer' ? { borderTop: '1px rgb(0, 0, 0, 0.3) solid' } : {})}
				>
					{columnsTableHead.map((column) => (
						<Box width={column?.width} textAlign={column.alignment} padding={'12px'} key={column.field}>
							<TableSortLabel
								{...(origin === 'header' ? { onClick: () => column.orderFunction() } : {})}
								style={{
									cursor: origin === 'header' ? 'pointer' : 'inherit',
									textTransform: 'uppercase',
									lineHeight: '1.5rem',
									fontSize: '0.875rem',
									letterSpacing: '0.01071em',
									fontWeight: 'bold',
									color: ordering?.field === column.field ? orderingColor : 'inherit',
									display: 'flex',
									flexDirection: column.alignment === 'start' ? 'row' : 'row-reverse',
								}}
								active={ordering?.field === column.field}
								direction={column.field === ordering.field ? ordering.ordering : 'asc'}
							>
								{column.headerName}
							</TableSortLabel>
						</Box>
					))}
				</Box>
			);
		}
		return (
			<Box
				{...(origin === 'header' ? { borderBottom: '1px rgb(0, 0, 0, 0.3) solid' } : {})}
				{...(origin === 'footer' ? { borderTop: '1px rgb(0, 0, 0, 0.3) solid' } : {})}
				width={'100%'}
			>
				<Box
					display={'flex'}
					height={'52px'}
					width={'99%'}
					alignItems={'center'}
					key={origin === 'header' ? 'header_row' : 'footer_row'}
					justifyContent={'space-between'}
				>
					{columnsTableHead.map((column) => (
						<Box width={column?.width} padding={'12px'} key={column.field}>
							<TableSortLabel
								{...(origin === 'header' ? { onClick: () => column.orderFunction() } : {})}
								style={{
									cursor: origin === 'header' ? 'pointer' : 'inherit',
									textTransform: 'uppercase',
									lineHeight: '1.5rem',
									fontSize: '0.875rem',
									letterSpacing: '0.01071em',
									fontWeight: 'bold',
									color: ordering?.field === column.field ? orderingColor : 'inherit',
								}}
								active={ordering?.field === column.field}
								direction={column.field === ordering.field ? ordering.ordering : 'asc'}
							>
								{column.headerName}
							</TableSortLabel>
						</Box>
					))}
				</Box>
				{/*NAO RETIRE ESSE BOX.
				É NECESSÁRIO PARA O ALINHAMENTO DO BOX QUE REPRESENTA O TABLE HEAD
				EM RELAÇÃO AO BOX QUE REPRESENTA O TABLE BODY*/}
				<Box width={'1%'} key={'box_ajusta_alinhamento'}></Box>
			</Box>
		);
	}
);

const TableBody: React.FC<SyndkosTableBodyProps> = React.memo(({ data, columnsTableHead, handleClickEditRow }) => {
	const height = useGetMinHeightTableBodyHook(data.length);
	const Row: React.FC<RowFixedSizeListProps> = ({ index, style }) => {
		const item = data[index];

		return (
			<Box
				onClick={() => handleClickEditRow(item.id)}
				display={'flex'}
				alignItems={'center'}
				justifyContent={'space-between'}
				borderBottom={'1px rgb(0, 0, 0, 0.3) solid'}
				key={item.id}
				style={{ ...style, cursor: 'pointer' }}
			>
				{columnsTableHead.map((respectiveColumn) => {
					const widthColumn = respectiveColumn?.width;
					return (
						<Box
							width={widthColumn}
							key={respectiveColumn.field}
							padding={'12px'}
							style={{ overflowWrap: 'break-word' }}
							textAlign={respectiveColumn.alignment}
						>
							{item[respectiveColumn.field]}
						</Box>
					);
				})}
			</Box>
		);
	};
	return (
		<Box height={height} id='box_antes_do_auto_sizer' key={'box_pai_auto_sizer'}>
			<AutoSizer id='auto_sizer' key={'auto_sizer'}>
				{(size) => {
					return (
						<List
							style={{ overflow: data.length <= 10 ? 'hidden' : 'auto' }}
							key={'fixed_size_list'}
							height={size.height}
							itemCount={data.length}
							itemSize={40}
							width={size?.width}
						>
							{Row}
						</List>
					);
				}}
			</AutoSizer>
		</Box>
	);
});

//A TABELA PRECISA RECEBER UM DATA QUE CONTENHA O ID DA ROW PARA QUE SEJA POSSÍVEL NAVEGAR ATÉ A TELA DE EDIT
const SyndkosTable: React.FC<SyndkosTableProps> = ({
	columnsTableHead,
	filterColumns,
	data,
	ordering,
	handleClickEditRow,
}) => {
	return (
		<Box key={'box_table'} borderRadius={'4px'}>
			<TableHead
				columnsTableHead={columnsTableHead}
				origin='header'
				dataLength={data?.length}
				ordering={ordering}
			/>
			<FiltersTable columnsTableHead={columnsTableHead} filterColumns={filterColumns} dataLength={data?.length} />
			<TableBody columnsTableHead={columnsTableHead} data={data} handleClickEditRow={handleClickEditRow} />
			<TableFooter columnsTableHead={columnsTableHead} dataLength={data?.length} ordering={ordering} />
		</Box>
	);
};

export default SyndkosTable;
