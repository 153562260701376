import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';

import {
	Box,
	FormControl,
	TextField,
	Tooltip,
	Typography,
	Checkbox,
	Select,
	MenuItem,
	Collapse,
} from '@material-ui/core';
import MultiSelect from 'components/common/Selects';

import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { ProviderValueContext } from '../folder/type';
import { DocFolderProps, DocumentNameProps, DocumentsFileProps, StepComponentsType, NextPrev } from './types';
import InputFile from 'components/common/InputFile';
import {
	CustomDataProviderDocuments,
	Pasta,
	RequestDataCreateFolder,
	DynamicPermissionsOptions,
	PermissionsOptions,
} from '../types';
import { CondominiosContext } from 'context/CondominioContextProvider';
import {
	BoxButtonDecision,
	CancelButton,
	GoBackButton,
	NextButton,
	SaveButton,
} from 'components/common/commonComponentsTSX';
import { DocumentsContext } from '../contexts/DocumentsContext';
import { FileType, FileInfoType } from '../types';
import ImportFileModal from './ImportFile';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const BoxShowFile: React.FC<{ id: number }> = ({ id }) => {
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const notify = useNotify();

	const handleSubmitShowFile = () => {
		dp.getOne('documento_arquivo', { id: id })
			.then((response) => {
				return window.open(response?.data?.file, '_blank');
			})
			.catch(() => {
				notify('Ocorreu um erro interno!', { type: 'error' });
			});
	};

	return (
		<CustomDialogBody
			title={'Baixar Arquivo'}
			customActions={
				<>
					<CancelButton onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
					<SaveButton disabled={false} onClick={handleSubmitShowFile}>
						Baixar
					</SaveButton>
				</>
			}
			form={{
				component: (
					<Box
						style={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						Deseja realizar o download do arquivo?
					</Box>
				),
			}}
		/>
	);
};

export const BoxConfigNomePasta: React.FC<DocumentNameProps> = ({ setFolderName, value, title }) => {
	return (
		<>
			<Typography style={{ fontSize: '1.2rem' }}>{title}</Typography>

			<FormControl fullWidth>
				<TextField
					margin={'normal'}
					autoFocus
					size={'small'}
					id='nome-pasta'
					label=''
					variant='outlined'
					fullWidth={true}
					onChange={(e) => setFolderName(e.target.value)}
					value={value}
				/>
			</FormControl>
		</>
	);
};

const BoxDocumento: React.FC<DocFolderProps> = ({
	setInsertDocInThisFolder,
	setCurrentStep,
	handleSubmitNewFolder,
	loading,
}) => {
	const handClickYes = (): void => {
		setInsertDocInThisFolder(true);
		setCurrentStep(2);
	};

	return (
		<Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
			<Box>
				<Typography>Desejar inserir um documento nesta pasta ?</Typography>
			</Box>
			<Box display={'flex'} gridGap={30}>
				<BoxButtonDecision onClick={handClickYes}>sim</BoxButtonDecision>
				<BoxButtonDecision disabled={loading} onClick={handleSubmitNewFolder}>
					não
				</BoxButtonDecision>
			</Box>
		</Box>
	);
};

const AdicionarPasta: React.FC = () => {
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const [currentStep, setCurrentStep] = React.useState<number>(0);
	const [folderName, setFolderName] = React.useState('');
	const [loading, setLoading] = React.useState<boolean>(false);
	const [insertDocInThisFolder, setInsertDocInThisFolder] = React.useState(false);
	const [file, setFile] = React.useState<FileType>({} as FileType);
	const [customFileName, setCustomFileName] = React.useState('');
	const [hasFile, setHasFile] = React.useState(false);
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const notify = useNotify();
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const { handleCollectFolders, setFolderName: setFilterFolderName } = React.useContext(DocumentsContext);

	const folderRequest: RequestDataCreateFolder = {
		condominio: condominioSelecionadoId as number,
		nome: folderName,
	};

	const handleSubmitNewFolder = (e: React.FormEvent) => {
		e.preventDefault();
		if (file.size > 15000000) {
			return notify('O arquivo não pode ultrapassar 15 MB de tamanho!', 'warning');
		}
		setLoading(true);
		dp.safeCreate('documento_pasta', {
			data: folderRequest,
		})
			.then(async (res) => {
				if (res?.data && hasFile) {
					const data: Pasta = res?.data;

					await dp
						.safeCreate('documento_arquivo', {
							data: {
								pasta: data.id,
								custom_file_name: customFileName,
								...file,
							},
						})
						.then(() => {
							notify('Arquivo importado com sucesso');
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							return notify('Ocorreu um erro ao importar o arquivo!', { type: 'error' });
						});
				}
				setLoading(false);
				notify('Pasta criada com sucesso');
				setModalValue((v) => ({ ...v, open: false }));
				setFilterFolderName('');
				return handleCollectFolders({
					filter: { condominio: condominioSelecionado?.id },
					sort: { order: 'ASC', field: 'nome' },
					pagination: {
						perPage: 10000,
						page: 1,
					},
				});
			})
			.catch((e) => {
				setLoading(false);
				if ([400].includes(e?.response?.status)) {
					return notify(e?.response?.data.error, { type: 'error' });
				}
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao criar a pasta!', { type: 'error' });
			});
	};

	const stepComponents = (): StepComponentsType => {
		return {
			0: {
				nextStep: 1,
				component: (
					<BoxConfigNomePasta title='Qual o nome da pasta?' {...{ setFolderName }} value={folderName} />
				),
				valid: !!folderName,
			},
			1: {
				prevStep: 0,
				component: (
					<BoxDocumento {...{ setCurrentStep, setInsertDocInThisFolder, handleSubmitNewFolder, loading }} />
				),
				valid: !!insertDocInThisFolder,
			},
			2: {
				prevStep: 1,
				component: <ImportFileModal {...{ setFile, setHasFile, setCustomFileName, customFileName }} />,
				valid: !!hasFile && !!customFileName,
				end: true,
			},
		};
	};

	const stepPrevSetp: NextPrev = stepComponents()[currentStep].prevStep;
	const stepNextStep: NextPrev = stepComponents()[currentStep].nextStep;

	const acoes = (
		<>
			<CancelButton onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
			<GoBackButton
				disabled={currentStep === 0}
				onClick={() => stepPrevSetp !== undefined && setCurrentStep(stepPrevSetp)}
			/>
			{stepComponents()[currentStep].end ? (
				<SaveButton disabled={!stepComponents()[currentStep].valid || loading} onClick={handleSubmitNewFolder}>
					Salvar
				</SaveButton>
			) : (
				stepComponents()[currentStep].nextStep && (
					<NextButton
						disabled={!stepComponents()[currentStep].valid}
						onClick={() => stepNextStep !== undefined && setCurrentStep(stepNextStep)}
					/>
				)
			)}
		</>
	);

	return (
		<CustomDialogBody
			title={currentStep === 0 ? 'Configurar nome da Pasta' : 'Importar Documento'}
			customActions={acoes}
			form={{
				component: (
					<Box
						style={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						{stepComponents()[currentStep].component}
					</Box>
				),
			}}
		/>
	);
};
export default AdicionarPasta;

type FunctionsOptions = {
	id: number;
	descricao: string;
};

export const Permissions: React.FC<{
	idFolder: number;
	funcoes: FunctionsOptions[];
	permissoes: { [key: string]: boolean };
	ocupacoes_gestao_com_acesso_liberado: number[];
}> = ({ idFolder, funcoes, permissoes, ocupacoes_gestao_com_acesso_liberado }) => {
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const { handleCollectFolders } = React.useContext(DocumentsContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const [loading, setLoading] = React.useState<boolean>(false);
	const notify = useNotify();
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const [expandedMembrosGestao, setExpandedMembrosGestao] = React.useState(false);

	const permissionsReducer = (state: PermissionsOptions, action: { name: string; payload: any }) => {
		if (action.name.startsWith('ocupacoes_gestao_com_acesso_liberado_dict.')) {
			const funcaoLimpa = action.name.substring('ocupacoes_gestao_com_acesso_liberado_dict.'.length);
			return {
				...state,
				ocupacoes_gestao_com_acesso_liberado_dict: {
					...state.ocupacoes_gestao_com_acesso_liberado_dict,
					[funcaoLimpa]: action.payload[action.name],
				},
			};
		} else {
			return {
				...state,
				[action.name]: action.payload[action.name],
			};
		}
	};

	const initialPermissionsOptions: PermissionsOptions = {
		acesso_liberado_para_proprietario: permissoes.proprietario || false,
		acesso_liberado_para_inquilino: permissoes.inquilino || false,
		acesso_liberado_para_responsavel_cobranca: permissoes.responsavel_cobranca || false,
		acesso_liberado_para_membros_gestao: permissoes.membros_gestao || false,
		ocupacoes_gestao_com_acesso_liberado_dict: funcoes.reduce((acc, funcao) => {
			acc[funcao.id] = ocupacoes_gestao_com_acesso_liberado.includes(funcao.id);
			return acc;
		}, {} as DynamicPermissionsOptions),
		ocupacoes_gestao_com_acesso_liberado: [],
	};

	const [permissionsOptions, dispatch] = React.useReducer(permissionsReducer, initialPermissionsOptions);

	React.useEffect(() => {
		if (!permissionsOptions.acesso_liberado_para_membros_gestao) {
			const newValue = permissionsOptions.acesso_liberado_para_membros_gestao;
			const updatedOcupacoesGestaoDict = funcoes.reduce((acc, funcao) => {
				acc[funcao.id] = newValue;
				return acc;
			}, {} as DynamicPermissionsOptions);

			return dispatch({
				name: 'ocupacoes_gestao_com_acesso_liberado_dict',
				payload: { ocupacoes_gestao_com_acesso_liberado_dict: updatedOcupacoesGestaoDict },
			});
		}
	}, [permissionsOptions.acesso_liberado_para_membros_gestao]);

	const handleCheckboxChange = (path: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({
			name: path,
			payload: { [path]: event.target.checked },
		});
	};

	const handleSubmitEditFolderPermissions = () => {
		setLoading(true);
		permissionsOptions.ocupacoes_gestao_com_acesso_liberado = Object.keys(
			permissionsOptions.ocupacoes_gestao_com_acesso_liberado_dict
		)
			.filter((key) => permissionsOptions.ocupacoes_gestao_com_acesso_liberado_dict[+key] === true)
			.map((key) => +key);
		dp.updatePermissions('documento_pasta', {
			id: idFolder,
			data: permissionsOptions,
		})
			.then(() => {
				setLoading(false);
				notify('Permissões da pasta editada com sucesso');
				setModalValue((v) => ({ ...v, open: false }));
				return handleCollectFolders({
					filter: { condominio: condominioSelecionado?.id },
					sort: { order: 'ASC', field: 'nome' },
					pagination: { perPage: 10000, page: 1 },
				});
			})
			.catch((e) => {
				setLoading(false);
				if ([400].includes(e?.response?.status)) {
					return notify(e?.response?.data.error, { type: 'error' });
				}
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao editar as permissões da pasta!', { type: 'error' });
			});
	};

	const acoes = (
		<>
			<CancelButton onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
			<SaveButton disabled={loading} onClick={handleSubmitEditFolderPermissions}>
				Salvar
			</SaveButton>
		</>
	);

	return (
		<CustomDialogBody
			title={'Permissões de Acesso'}
			customActions={acoes}
			form={{
				component: (
					<>
						<Box display='flex' alignItems='center'>
							<Checkbox
								checked={permissionsOptions.acesso_liberado_para_proprietario}
								onChange={handleCheckboxChange('acesso_liberado_para_proprietario')}
							/>
							<Typography>Proprietário</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<Checkbox
								checked={permissionsOptions.acesso_liberado_para_inquilino}
								onChange={handleCheckboxChange('acesso_liberado_para_inquilino')}
							/>
							<Typography>Inquilino</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<Checkbox
								checked={permissionsOptions.acesso_liberado_para_responsavel_cobranca}
								onChange={handleCheckboxChange('acesso_liberado_para_responsavel_cobranca')}
							/>
							<Typography>Responsável pela Cobrança</Typography>
						</Box>
						{funcoes.length > 0 ? (
							<>
								<Box display='flex' alignItems='center'>
									<Checkbox
										checked={permissionsOptions.acesso_liberado_para_membros_gestao}
										onChange={handleCheckboxChange('acesso_liberado_para_membros_gestao')}
									/>
									<Typography>Membros da Gestão</Typography>
									{expandedMembrosGestao ? (
										<Tooltip title='Colapsar'>
											<ExpandMoreIcon
												onClick={() => {
													setExpandedMembrosGestao(false);
												}}
												style={{ cursor: 'pointer' }}
											/>
										</Tooltip>
									) : (
										<Tooltip title='Expandir'>
											<ChevronRightIcon
												onClick={() => {
													setExpandedMembrosGestao(true);
												}}
												style={{ cursor: 'pointer' }}
											/>
										</Tooltip>
									)}
								</Box>
								<Collapse in={expandedMembrosGestao} style={{ margin: '0.5em' }}>
									{funcoes.map((funcao, index) => (
										<Box key={index} display='flex' alignItems='center'>
											<Checkbox
												checked={
													permissionsOptions.ocupacoes_gestao_com_acesso_liberado_dict[
														funcao.id
													]
												}
												onChange={handleCheckboxChange(
													`ocupacoes_gestao_com_acesso_liberado_dict.${funcao.id}`
												)}
												disabled={!permissionsOptions.acesso_liberado_para_membros_gestao}
												style={
													permissionsOptions.acesso_liberado_para_membros_gestao
														? {}
														: { color: '#888' }
												}
											/>
											<Typography
												style={
													permissionsOptions.acesso_liberado_para_membros_gestao
														? {}
														: { color: '#888' }
												}
											>
												{funcao.descricao}
											</Typography>
										</Box>
									))}
								</Collapse>
							</>
						) : null}
					</>
				),
			}}
		/>
	);
};
