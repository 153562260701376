import React, { useEffect, useState, memo } from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';

import { sub, add, format, parseISO } from 'date-fns';

import { DateRangePicker } from 'materialui-daterange-picker-pt';

import './css/datePickerFix.css';

const TODAY = new Date().setHours(10, 0, 0);

const formatDate = (date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

export const periodoOptions = [
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const PeriodoPicker = memo(({ open, setOpen, setDateRange }) => (
	<DateRangePicker
		open={true}
		toggle={() => setOpen(!open)}
		onChange={(range) => setDateRange(range)}
		initialDateRange={periodoOptions[0]}
		definedRanges={periodoOptions}
	/>
));

const TabelaPeriodoPickerWrapper = ({ children, open }) => (
	<Box
		className='periodo-date-range-picker'
		position='absolute'
		css={
			open
				? {
						zIndex: 3,
						maxHeight: 'calc(90vh - 200px)',
						left: 'calc(50% - 300px)',
				  }
				: { zIndex: -1, opacity: 0, pointerEvents: 'none' }
		}
	>
		{children}
	</Box>
);

export const ModalPeriodoPickerWrapper = ({ children, open, setOpen }) => (
	<Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ style: { minWidth: '45vw', maxWidth: '50vw' } }}>
		{children}
	</Dialog>
);

const PeriodoSelect = memo(
	({
		datas_after,
		datas_before,
		dateRangeInitial,
		openDatePicker = false,
		setDatas_after,
		setDatas_before,
		Wrapper = TabelaPeriodoPickerWrapper,
	}) => {
		const [open, setOpen] = useState(openDatePicker);
		const [dateRange, setDateRange] = useState(dateRangeInitial);

		const handleRangeChange = () => {
			setDatas_after(format(dateRange?.startDate, 'yyyy-MM-dd'));
			setDatas_before(format(dateRange?.endDate, 'yyyy-MM-dd'));
		};

		const init = () => {
			if (datas_after && datas_before) {
				setDateRange({
					startDate: parseISO(datas_after),
					endDate: parseISO(datas_before),
				});
			}
		};

		useEffect(init, []);
		useEffect(handleRangeChange, [dateRange]);

		return [
			<TextField
				onClick={() => setOpen(true)}
				fullWidth
				margin='dense'
				inputProps={{
					value: `de ${formatDate(dateRange?.startDate)} até ${formatDate(dateRange?.endDate)}`,
				}}
			/>,
			<Wrapper {...{ open, setOpen }}>
				<PeriodoPicker {...{ open, setOpen, setDateRange }} />
			</Wrapper>,
		];
	}
);

export default PeriodoSelect;
