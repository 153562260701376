import React, { useContext } from 'react';

import { useFormState } from 'react-final-form';

import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { ModalSizeEditableContextProvider } from '../../../../common/ModalSizeEditableContext';
import EditaContaBancariaButtonsContext from '../contexts/EditaContaBancariaButtonsContext';
import SalvarButton from './SalvarButton';
import ReativarButton from './ReativarButton';
import InativarButton from './InativarButton';
import ExcluirButton from './ExcluirButton';

const ActionButtons = () => {
	const { closeModal, condominio_situacao, handleCancel } = useContext(EditaContaBancariaButtonsContext);
	const {
		pristine,
		values: { situacao },
	} = useFormState();

	return (
		<>
			<ButtonCancel
				onClick={condominio_situacao !== 'A' || situacao !== 'A' || pristine ? closeModal : handleCancel}
			>
				Cancelar
			</ButtonCancel>
			{situacao === 'A' ? (
				<SalvarButton />
			) : (
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
						minWidth: '61vw',
					}}
				>
					<ExcluirButton />
				</ModalSizeEditableContextProvider>
			)}
			{situacao === 'A' ? (
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
						minWidth: '25vw',
					}}
				>
					<InativarButton />
				</ModalSizeEditableContextProvider>
			) : (
				<ModalSizeEditableContextProvider
					customDialogProps={{
						disableBackdropClick: true,
						disableEscapeKeyDown: true,
						minWidth: '25vw',
					}}
				>
					<ReativarButton />
				</ModalSizeEditableContextProvider>
			)}
		</>
	);
};

export default ActionButtons;
