import React from 'react';
import { useTheme, makeStyles, alpha } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import classnames from 'classnames';

import { TooltipIconButton } from '../TooltipIconButton';

export const useRemoveButtonStyle = makeStyles(
	(theme) => ({
		removeButton: {
			color: theme.palette.syndikosRed.main,
			transition: 'all 200ms ease',
			'&:hover': {
				backgroundColor: alpha(theme.palette.syndikosRed.main, 0.95),
				color: '#f5f5f5',
				// Reset on mouse devices
				'@media (hover: none)': {
					backgroundColor: 'transparent',
				},
			},
		},
	}),
	{ name: 'SyndikosRemove' }
);

export const ButtonRemove = ({ children, className, ...rest }) => {
	const classes = useRemoveButtonStyle();
	return (
		<Button
			{...rest}
			variant='text'
			startIcon={<DeleteOutlineIcon />}
			size='small'
			className={classnames(classes.removeButton, className)}
		>
			{children || 'Remover'}
		</Button>
	);
};

export const TooltipIconButtonRemove = ({
	id = undefined,
	key = undefined,
	title = '',
	style = {},
	children = undefined,
	...rest
}) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<TooltipIconButton
			{...rest}
			id={id}
			key={key}
			title={title || 'Remover'}
			style={{
				...style,
				color: rest.disabled ? '#DDDDDD' : syndikosRed.main,
			}}
		>
			{children || <DeleteOutlineIcon />}
		</TooltipIconButton>
	);
};
