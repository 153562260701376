import React, { useState } from 'react';

import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
	useRequestOptionsIdentificacaoArrecadacao,
	useRequestOptionsContasComFiltros,
} from 'components/common/Selects/hooks';

import { format } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import { RelatorioMonthPicker, TODAY } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesImpressaoDemonstrativoCheckboxGrupo = ({
	handleChange,
	imprimirTotalResp,
	imprimirTotalUnidade,
	imprimirAssinatura,
	imprimirTotalGrupo,
	imprimirVencimentoBoleto,
}) => {
	return (
		<>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTotalResp}
							onChange={(e, checked) => handleChange(checked, 'imprimir_total_by_responsavel')}
							name='imprimir_total_by_responsavel'
						/>
					}
					label='Imprimir subtotal por responsável'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTotalGrupo}
							onChange={(e, checked) =>
								handleChange(checked, 'imprimir_total_by_grupo_demonstrativo_arrecadacao')
							}
							name='imprimir_total_by_grupo_demonstrativo_arrecadacao'
						/>
					}
					label='Imprimir total por grupo'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirTotalUnidade}
							onChange={(e, checked) => handleChange(checked, 'imprimir_total_by_unidade')}
							name='imprimir_total_by_unidade'
						/>
					}
					label='Imprimir total por unidade'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirVencimentoBoleto}
							onChange={(e, checked) => handleChange(checked, 'imprimir_vencimento_do_boleto')}
							name='imprimir_vencimento_do_boleto'
						/>
					}
					label='Imprimir vencimento do boleto'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
			</FormGroup>
		</>
	);
};

export const DemonstrativoArrecadacaoDivisor = ({ idCondominio, tipoRelatorio }) => {
	const relacaoTipoConta = {
		L: 'Leitura',
		O: 'Ordinária',
		E: 'Extraordinária',
		F: 'Fundo',
	};
	const [dataInicial, setDataInicial] = useState(TODAY);
	const [dataFinal, setDataFinal] = useState(TODAY);
	const [identificacao, setIdentificacao] = useState({
		id: 0,
		nome: 'Todas',
	});
	const [contas, setContas] = useState([]);
	const {
		opcoesImpressao: {
			imprimir_total_by_responsavel,
			imprimir_total_by_unidade,
			imprimir_assinatura,
			imprimir_total_by_grupo_demonstrativo_arrecadacao,
			imprimir_vencimento_do_boleto,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	const { options: identificacoes } = useRequestOptionsIdentificacaoArrecadacao(idCondominio);
	const requestData = {
		id_condominio: idCondominio,
		mes_inicial: format(dataInicial, 'MM/yyyy'),
		mes_final: format(dataFinal, 'MM/yyyy'),
		identificacao_id: identificacao ? identificacao.id : '0',
		colunas_contas: contas.map((v) => v.id_concatenado),
		nome_relatorio: tipoRelatorio.action,
		imprimir_total_by_responsavel: imprimir_total_by_responsavel,
		imprimir_total_by_unidade: imprimir_total_by_unidade,
		imprimir_assinatura: imprimir_assinatura,
		imprimir_total_by_grupo_demonstrativo_arrecadacao: imprimir_total_by_grupo_demonstrativo_arrecadacao,
		imprimir_vencimento_do_boleto: imprimir_vencimento_do_boleto,
	};
	const [filterRequisicao, setFilterRequisicao] = React.useState(null);
	const { options: contasOptions } = useRequestOptionsContasComFiltros(filterRequisicao);
	React.useEffect(() => {
		setFilterRequisicao({
			id_condominio_id: idCondominio,
			tem_arrecadacao_valida: true,
			mes_inicial: format(dataInicial, 'MM/yyyy'),
			mes_final: format(dataFinal, 'MM/yyyy'),
			id_identificacao_arrecadacao_id: identificacao ? identificacao.id : '0',
		});
	}, [identificacao, dataInicial, dataFinal, idCondominio]);
	React.useEffect(() => {
		if (contas.length || identificacao) {
			setContas([]);
			setIdentificacao({
				id: 0,
				nome: 'Todas',
			});
		}
	}, [JSON.stringify(identificacoes)]);
	React.useEffect(() => {
		if (contas.length) {
			const hasInOptions = contas.filter((conta) =>
				contasOptions.find((contaOption) => contaOption.id === conta.id)
			);
			if (hasInOptions.length) {
				return setContas(hasInOptions);
			}
			return setContas([]);
		}
	}, [contasOptions.length]);
	const disableSelectContas = !contasOptions?.length;
	return (
		<BoxDivisor titulo='Demonstrativo de Arrecadação'>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<RelatorioMonthPicker
						label='Mês Inicial'
						handleChange={(newValue) => setDataInicial(newValue)}
						value={dataInicial}
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<RelatorioMonthPicker
						label='Mês Final'
						handleChange={(newValue) => setDataFinal(newValue)}
						value={dataFinal}
						fullWidth
					/>
				</Box>
				<Box flex={2}>
					<Autocomplete
						value={identificacao}
						onChange={(_, newValue) => setIdentificacao(newValue)}
						options={[...Object.values(identificacoes).filter((v) => v.id !== 0), { id: 0, nome: 'Todas' }]}
						getOptionLabel={(o) => o.nome}
						getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
						renderInput={(params) => (
							<TextField {...params} label='Identificação de Arrecadação' variant='outlined' />
						)}
						disabled={identificacoes.length === 1}
						clearText='Limpar'
						autoHighlight
						noOptionsText='Nenhuma identificação disponível'
						size='small'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex' mb='1em'>
				<Box width='50%' mr='1em'>
					<Autocomplete
						disableCloseOnSelect
						value={contas}
						onChange={(_, newValue) => {
							if (!newValue || newValue?.length <= 12) setContas(newValue);
						}}
						options={contasOptions}
						getOptionLabel={(o) => `${o.nome} - ${relacaoTipoConta[o.tipo]}`}
						getOptionSelected={(option, value) => option.id_concatenado === value.id_concatenado}
						renderInput={(params) => <TextField {...params} label='Contas' variant='outlined' />}
						renderOption={(option, { selected }) => (
							<span>
								<Checkbox checked={selected} disabled={!selected && contas?.length > 11} />
								{option.nome}
							</span>
						)}
						clearText='Limpar'
						disabled={disableSelectContas}
						multiple
						autoHighlight
						noOptionsText='Nenhuma conta disponível'
						size='small'
						fullWidth
					/>
				</Box>
				<Box>
					<OpcoesImpressaoDemonstrativoCheckboxGrupo
						handleChange={handleChangeOpcoesImpressao}
						imprimirTotalResp={imprimir_total_by_responsavel}
						imprimirTotalUnidade={imprimir_total_by_unidade}
						imprimirAssinatura={imprimir_assinatura}
						imprimirTotalGrupo={imprimir_total_by_grupo_demonstrativo_arrecadacao}
						imprimirVencimentoBoleto={imprimir_vencimento_do_boleto}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};
