import React from 'react';

import { Box } from '@material-ui/core';

import { FormContext } from '../../contexts';
import CampoFeesPercentage from './CampoFeesPercentage';
import CampoFinePercentage from './CampoFinePercentage';
import OpcoesJurosCheckboxGroup from './OpcoesJurosCheckboxGroup';
import PermitirSegundaViaInput from './PermitirSegundaViaInput';
import LimiteDiasInputForm from './LimiteDiasInputForm';

export const BoxConfiguracaoMultaEJuros = () => {
	const {
		feesOptions,
		setFeesOptions,
		feesPercentage,
		setFeesPercentage,
		finePercentage,
		setFinePercentage,
		allowDuplicateGeneration,
		setAllowDuplicateGeneration,
		daysLimitAfterExpiration,
		setDaysLimitAfterExpiration,
	} = React.useContext(FormContext);
	return (
		<Box display={'flex'} flexDirection='column' gridGap='1rem'>
			<Box display={'flex'} alignItems={'center'} gridGap='1rem'>
				<Box>
					<OpcoesJurosCheckboxGroup feesOptions={feesOptions} setFeesOptions={setFeesOptions} />
				</Box>
				<Box flex={1}>
					<CampoFeesPercentage
						feesOptions={feesOptions}
						feesPercentage={feesPercentage}
						setFeesPercentage={setFeesPercentage}
					/>
				</Box>
				<Box flex={1}>
					<CampoFinePercentage finePercentage={finePercentage} setFinePercentage={setFinePercentage} />
				</Box>
			</Box>
			<Box display={'flex'} alignItems={'center'} gridGap='1rem'>
				<Box flex={1}>
					<PermitirSegundaViaInput
						setAllowDuplicateGeneration={setAllowDuplicateGeneration}
						allowDuplicateGeneration={allowDuplicateGeneration}
					/>
				</Box>
				<Box flex={1}>
					<LimiteDiasInputForm
						allowDuplicateGeneration={allowDuplicateGeneration}
						daysLimitAfterExpiration={daysLimitAfterExpiration}
						setDaysLimitAfterExpiration={setDaysLimitAfterExpiration}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default BoxConfiguracaoMultaEJuros;
