import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputBaseComponentProps } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { NumberFormatProps } from 'react-number-format';

import { useListContext } from 'react-admin';

import { NumberFormatBRL, NumberFormatAlignRightBRL, sanitizeListNumberFormatBRLProps } from '../CurrencyInput';

interface sanitizeListNumberFormatBRLPropsParam
	extends Partial<
		Pick<
			NumberFormatProps,
			| 'thousandsGroupStyle'
			| 'decimalScale'
			| 'fixedDecimalScale'
			| 'allowNegative'
			| 'allowEmptyFormatting'
			| 'allowLeadingZeros'
			| 'prefix'
			| 'suffix'
			| 'isNumericString'
			| 'displayType'
			| 'type'
			| 'format'
			| 'removeFormatting'
			| 'mask'
			| 'customInput'
			| 'onValueChange'
			| 'isAllowed'
			| 'allowedDecimalSeparators'
			| 'onChange'
		>
	> {
	defaultValue?: NumberFormatProps['defaultValue'] | null;
	renderText?: NumberFormatProps['renderText'] | null;
	getInputRef?: NumberFormatProps['getInputRef'] | null;
}

export const sanitizeListNumberFormatBRLPropsV2 = sanitizeListNumberFormatBRLProps as (
	props: sanitizeListNumberFormatBRLPropsParam
) => sanitizeListNumberFormatBRLPropsParam;

type THandleChange = (newValue: string | undefined) => void;

interface IDecimalFieldProps extends sanitizeListNumberFormatBRLPropsParam {
	id: number | string;
	handleChange: THandleChange;
	value: string | number | undefined;
	inputComponent: React.FC;
}

export const DecimalField: React.FC<IDecimalFieldProps> = ({ id, handleChange, value, inputComponent, ...props }) => (
	<TextField
		variant='standard'
		id={String(id)}
		onChange={(e) => handleChange(e.target.value)}
		value={value}
		label='Filtrar'
		style={{ width: '100%' }}
		InputProps={{
			endAdornment: (
				<InputAdornment position='end' style={{ position: 'absolute', right: 0 }}>
					<IconButton
						aria-label='toggle password visibility'
						onClick={() => handleChange(undefined)}
						edge='end'
					>
						{value !== undefined && <CloseIcon />}
					</IconButton>
				</InputAdornment>
			),
			inputComponent: inputComponent || NumberFormatBRL,
		}}
		inputProps={sanitizeListNumberFormatBRLPropsV2(props) as InputBaseComponentProps}
	/>
);

interface IDecimalProps extends IDecimalFieldProps {
	source: string;
	custom: string;
	listControllerProps: ReturnType<typeof useListContext>;
}

export const DecimalBase: React.FC<IDecimalProps> = ({ source, custom, listControllerProps, ...props }) => {
	const [value, setValue] = useState<Parameters<THandleChange>[0]>(undefined);
	const { setFilters, filterValues } = useListContext(listControllerProps);
	const [requestTimeout, setRequestTimeout] = useState<null | ReturnType<typeof setTimeout>>(null);

	const updateValue = () => {
		setValue(filterValues[custom || source]);
	};
	useEffect(updateValue, []);

	const handleChange: THandleChange = (newValue) => {
		setValue(newValue);

		if (requestTimeout) clearTimeout(requestTimeout);

		setRequestTimeout(
			setTimeout(() => {
				setFilters({ ...filterValues, [custom || source]: newValue }, undefined);
			}, 1250)
		);
	};

	return (
		<DecimalField {...props} id={`${custom || source}-filtro-padrao`} handleChange={handleChange} value={value} />
	);
};

export const Decimal = DecimalBase as React.FC<Partial<IDecimalProps>>;

type TInputMonetarioProps = {
	value: number;
	onChange: (value: any) => void;
};

export const InputMonetario = ({ value, onChange }: TInputMonetarioProps) => {
	const formatNumber = (valor: number) => {
		return (valor / 100).toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	};

	const handleKeyPress = (e: any) => {
		const digit = e.key;
		if (/^[0-9]$/.test(digit) || e.key === 'Backspace') {
			let newValue = value;
			if (/^[0-9]$/.test(digit)) newValue = value * 10 + parseInt(digit, 10);
			else if (e.key === 'Backspace') newValue = Math.floor(value / 10);

			if (onChange) onChange(newValue);
		}
	};

	return (
		<TextField
			variant='outlined'
			size='small'
			value={formatNumber(value).toString()}
			InputProps={{
				readOnly: true,
				inputMode: 'numeric',
				inputProps: {
					style: { textAlign: 'right' },
				},
			}}
			onKeyDown={handleKeyPress}
		/>
	);
};

export default Decimal;
