import { formataPropsMaxValuePorLimitePagamentoDias } from '../../../../utils/formaters_parses';

import {
	getValidacoesLimitePagamento,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	mensagensErrosPadroes,
} from '../../../default/ContaCorrenteProps';

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	nomeCampoLegivel: 'limite de pagamento',
	msgErroVazio: mensagensErrosPadroes.vazioLimitePagamentoBaixaDevolver(),
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamentoBaixaDevolver(),
});

const validacoesMulta = getValidacoesMulta({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
	msgErroMaxValue: mensagensErrosPadroes.maxValueLimitePagamento({ nomeCampoLegivel: 'multa', genero: 'a' }),
});

const validacoesJuros = getValidacoesJuros({
	minValue: 0,
	funcFormataPropsDias: formataPropsMaxValuePorLimitePagamentoDias, // maxValue
});

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	msgErroVazio: mensagensErrosPadroes.vazioProtesto(),
	minValue: 2,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto({ minValue: 2 }),
	maxValue: 15,
	msgErroMaxValue: mensagensErrosPadroes.maxValueProtesto({ maxValue: 15 }),
});

const validacoesInstrucoesCobrancaRemessaCNAB240 = [
	validacoesLimitePagamento.tipo,
	validacoesLimitePagamento.dias,

	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

export default validacoesInstrucoesCobrancaRemessaCNAB240;
