import React from 'react';

import { Box } from '@material-ui/core';
import EditaContaBancariaAutocomplete from '../../commons/EditaContaBancariaAutocomplete';

const CarteiraField = () => (
	<Box pl={1} flex={1} sx={{ 'max-width': '17%' }}>
		<EditaContaBancariaAutocomplete
			source='carteira'
			name='carteira'
			id='carteira'
			label='Carteira'
			translateChoice={false}
		/>
	</Box>
);

export default CarteiraField;
