import React from 'react';

import InstrucoesRemessaFields from '../../commons/InstrucoesRemessaFields';

const emptyProps = {};

const JurosFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaFields
		sourcePrincipal='juros'
		propsTipo={{
			source: 'juros_tipo',
			label: 'Juros',
			...propsTipo,
		}}
		propsValor={{
			source: 'juros_valor',
			...propsValor,
		}}
		propsDias={{
			source: 'juros_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoJuros = ({
	sources: { sourceValor, sourceDias } = emptyProps,
	values,
	pristine,
	optionsIsencoesTipo,
}) => ({
	values: {
		[sourceValor]: values[sourceValor],
		[sourceDias]: values[sourceDias],
	},
	sources: {
		sourceValor,
		sourceDias,
	},
	pristine,
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoJuros =
	({ sources: { sourceValor, sourceDias } = emptyProps, values, pristine, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);

		const valorValue = values[sourceValor],
			diasValue = values[sourceDias];
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
			sourceDias && change(sourceDias, undefined);
		} else {
			if (tipoValue.id === 'JTX') {
				!valorValue && change(sourceValor, 1);
			} else {
				!pristine && !valorValue && change(sourceValor, undefined);
			}
			if (sourceDias && !diasValue) change(sourceDias, 1);
		}
	};

export default JurosFields;
