import React from 'react';
import { DeleteListProvider, ToolbarDeleteList } from './DeleteListContext';
import { DeleteListProviderProps } from './types';
import { CustomDialog, ModalContextProvider } from 'components/common/ModalContext';

const ListWithDelete: React.FC<DeleteListProviderProps> = ({ children, source, objetoAlterado, setObjetoAlterado }) => {
	return (
		<>
			<ModalContextProvider>
				<DeleteListProvider {...{ source, objetoAlterado, setObjetoAlterado }}>
					<ToolbarDeleteList />
					<CustomDialog />
					{children}
				</DeleteListProvider>
			</ModalContextProvider>
		</>
	);
};

export default ListWithDelete;
