import { PessoaList } from './list';
import { PessoaEdit } from './edit';
import { PessoaCreate } from './create';

const resource = {
	list: PessoaList,
	edit: PessoaEdit,
	create: PessoaCreate,
};

export default resource;
