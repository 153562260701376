import {
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	getPadraoPayloadValuesInstrucoesRemessa,
} from '../../../default/ContaCorrenteProps';

const formataPayloadValuesTabInstrucoesCobrancaRemessaPadrao = ({ values }) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
});

export default formataPayloadValuesTabInstrucoesCobrancaRemessaPadrao;
