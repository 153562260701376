import React from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { SyndikosMuiPickersUtilsProvider } from '../../../../common/InputDate';

const InputDateReferencia = ({ initialFocusedDate, handleChange = () => {}, value, ...props }) => {
	if (initialFocusedDate) initialFocusedDate.setDate(1);
	return (
		<SyndikosMuiPickersUtilsProvider>
			<KeyboardDatePicker
				{...props}
				format='MM/yyyy'
				id='referenciaConfig'
				name='keyboardModal'
				label='Referência'
				views={['year', 'month']}
				margin='dense'
				variant='inline'
				inputVariant='outlined'
				mask='__/____'
				initialFocusedDate={initialFocusedDate}
				onChange={handleChange}
				value={value}
				required={true}
				disableToolbar={true}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};

export default InputDateReferencia;
