import React, { useContext } from 'react';
import { TextField, MenuItem } from '@material-ui/core';

import { ModalCreatePrevisao } from './ModalCreatePrevisao';
import { IModeloPrevisaoOrcamentaria } from './types';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { PrevisaoOrcamentariaContext } from './contexts/PrevisoesOrcamentariasContextProvider';

export const ModeloPrevisaoOrcamentariaSelect: React.FC = () => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { listaModelos, setModeloSelecionado, setReferenciaInicial, setReferenciaFinal } =
		useContext(PrevisaoOrcamentariaContext);

	const handleOptionSelect = (option: IModeloPrevisaoOrcamentaria) => {
		const dataInicial = new Date(option?.inicio || '');
		const dataFinal = new Date(option?.termino || '');
		setModeloSelecionado(option);
		setReferenciaInicial(dataInicial);
		setReferenciaFinal(dataFinal);
	};

	return (
		<TextField
			id='select-config-Previsao'
			size='small'
			select
			variant='outlined'
			fullWidth
			disabled={!condominioSelecionado}
			label='Criar / Selecionar Previsão Orçamentária'
		>
			<MenuItem
				key='menu-item-select-config-Previsao-create'
				onClick={() => {
					setModalValue((v: object) => ({
						...v,
						open: true,
						maxWidth: 'sm',
						dialogBody: <ModalCreatePrevisao />,
					}));
					setModeloSelecionado({
						id: null,
						nome: '',
						condominio: null,
						plano_condominio: null,
						periodo: null,
						inicio: null,
						termino: null,
					});
				}}
			>
				Criar nova Previsão Orçamentária
			</MenuItem>
			{listaModelos.map((option: IModeloPrevisaoOrcamentaria) => (
				<MenuItem
					key={`menu-item-select-config-Previsao-${option?.id}`}
					value={option?.nome || ''}
					onClick={() => {
						handleOptionSelect(option);
					}}
				>
					{option?.nome}
				</MenuItem>
			))}
		</TextField>
	);
};
