import React from 'react';

import { useDataProvider, useGetList, useNotify } from 'react-admin';

import { Box, TextField } from '@material-ui/core';

import { cepFormatacao, cepParser, cepValidacao } from '../../fieldControl/cep';
import { numeroParser } from '../../fieldControl/numero';
import { BoxDivisor } from './Formulario';
import { FieldCep } from './InputCep';
import { SyndikosSelectRHF } from './SyndikosSelect';

export const camposEndereco = [
	'cep',
	'tipo_logradouro_id',
	'tipo_logradouro',
	'endereco',
	'numero',
	'bairro',
	'cidade',
	'uf',
	'complemento_endereco',
];

export const opcoesUf = [
	{ id: 'AC', name: 'Acre' },
	{ id: 'AL', name: 'Alagoas' },
	{ id: 'AP', name: 'Amapá' },
	{ id: 'AM', name: 'Amazonas' },
	{ id: 'BA', name: 'Bahia' },
	{ id: 'CE', name: 'Ceará' },
	{ id: 'DF', name: 'Distrito Federal' },
	{ id: 'ES', name: 'Espírito Santo' },
	{ id: 'GO', name: 'Goiás' },
	{ id: 'MA', name: 'Maranhão' },
	{ id: 'MT', name: 'Mato Grosso' },
	{ id: 'MS', name: 'Mato Grosso do Sul' },
	{ id: 'MG', name: 'Minas Gerais' },
	{ id: 'PA', name: 'Pará' },
	{ id: 'PB', name: 'Paraíba' },
	{ id: 'PR', name: 'Paraná' },
	{ id: 'PE', name: 'Pernambuco' },
	{ id: 'PI', name: 'Piauí' },
	{ id: 'RJ', name: 'Rio de Janeiro' },
	{ id: 'RN', name: 'Rio Grande do Norte' },
	{ id: 'RS', name: 'Rio Grande do Sul' },
	{ id: 'RO', name: 'Rondônia' },
	{ id: 'RR', name: 'Roraima' },
	{ id: 'SC', name: 'Santa Catarina' },
	{ id: 'SP', name: 'São Paulo' },
	{ id: 'SE', name: 'Sergipe' },
	{ id: 'TO', name: 'Tocantins' },
];

export const idToNameUf = {
	AC: 'Acre',
	AL: 'Alagoas',
	AP: 'Amapá',
	AM: 'Amazonas',
	BA: 'Bahia',
	CE: 'Ceará',
	DF: 'Distrito Federal',
	ES: 'Espírito Santo',
	GO: 'Goiás',
	MA: 'Maranhão',
	MT: 'Mato Grosso',
	MS: 'Mato Grosso do Sul',
	MG: 'Minas Gerais',
	PA: 'Pará',
	PB: 'Paraíba',
	PR: 'Paraná',
	PE: 'Pernambuco',
	PI: 'Piauí',
	RJ: 'Rio de Janeiro',
	RN: 'Rio Grande do Norte',
	RS: 'Rio Grande do Sul',
	RO: 'Rondônia',
	RR: 'Roraima',
	SC: 'Santa Catarina',
	SP: 'São Paulo',
	SE: 'Sergipe',
	TO: 'Tocantins',
};

const valoresVaziosParaCampos = {
	cep: '',
	tipo_logradouro_id: null,
	tipo_logradouro: null,
	endereco: '',
	numero: '',
	bairro: '',
	cidade: '',
	uf: null,
	complemento_endereco: '',
};

const ordenaTipos = (tiposLog) => {
	if (!tiposLog) return [];

	const idsOrdenados = Object.keys(tiposLog).sort((a, b) => {
		if (tiposLog[a].descricao.length > tiposLog[b].descricao.length) {
			return -1;
		}
		if (tiposLog[a].descricao.length < tiposLog[b].descricao.length) {
			return 1;
		}
		return 0;
	});
	return idsOrdenados.map((id) => tiposLog[id]);
};

function* executarValidators(validators, value) {
	for (const validator of validators) {
		const error = validator(value);
		if (error) yield error;
	}
}

const atualizarTipoLogradouroAPartirDoId = (state) => {
	state.visited.tipo_logradouro = true;
	state.errors.tipo_logradouro = null;
	state.values.tipo_logradouro = state.tiposObjects?.[state.values.tipo_logradouro_id] || null;

	if (!state.values.tipo_logradouro) state.values.tipo_logradouro_id = null;

	return state;
};

const reducer = (state, action) => {
	switch (action.event) {
		case 'setTiposOrdenados':
			state.tiposObjects = action.value || {};
			state.tiposOrdenados = ordenaTipos(action.value);
			break;
		case 'changed_values':
			for (const [campo, valor] of Object.entries(action.campos_alterados || {})) {
				state.visited[campo] = true;
				state.errors[campo] = null;
				state.values[campo] = valor ?? valoresVaziosParaCampos[campo];
			}
			state.valid = !Object.values(state.errors).filter((e) => Boolean(e)).length;
			state.pesquisarDadosEnderecoPorCep = false;
			break;
		case 'changed_value': {
			const campo = action.campo;
			if (campo === 'cep') state.pesquisarDadosEnderecoPorCep = true;
			state.errors[campo] = executarValidators(state.validators[campo] || [], action.value).next().value ?? null;
			state.visited[campo] = true;
			state.values[campo] = action.value ?? valoresVaziosParaCampos[campo];
			if ('tipo_logradouro_id' === campo) state = atualizarTipoLogradouroAPartirDoId(state);
			state.valid = !Object.values(state.errors).filter((e) => Boolean(e)).length;
			break;
		}
		case 'pesquisar_dados_endereco_por_cep':
			state.pesquisarDadosEnderecoPorCep = true;
			break;
		case 'cancelar_pesquisar_dados_endereco_por_cep':
			state.pesquisarDadosEnderecoPorCep = false;
			break;
		default:
			break;
	}

	return { ...state };
};

const initializador = (dadosIniciais) => {
	const pesquisarDadosEnderecoPorCep = false;

	const errors = {
		cep: null,
		numero: null,
		tipo_logradouro_id: null,
		tipo_logradouro: null,
		endereco: null,
		bairro: null,
		cidade: null,
		uf: null,
		complemento_endereco: null,
	};

	const valid = true;

	const validators = {
		cep: [(value) => cepValidacao(value)],
	};

	const tiposOrdenados = [];

	const visited = {};

	const initialValues = {
		cep: dadosIniciais.cep ?? valoresVaziosParaCampos.cep,
		numero: dadosIniciais.numero || valoresVaziosParaCampos.numero,
		tipo_logradouro_id: dadosIniciais.tipo_logradouro_id ?? valoresVaziosParaCampos.tipo_logradouro_id,
		tipo_logradouro: dadosIniciais.tipo_logradouro ?? valoresVaziosParaCampos.tipo_logradouro,
		endereco: dadosIniciais.endereco ?? valoresVaziosParaCampos.endereco,
		bairro: dadosIniciais.bairro ?? valoresVaziosParaCampos.bairro,
		cidade: dadosIniciais.cidade ?? valoresVaziosParaCampos.cidade,
		uf: opcoesUf.some((opcao) => opcao.id === dadosIniciais.uf) ? dadosIniciais.uf : valoresVaziosParaCampos.uf,
		complemento_endereco: dadosIniciais.complemento_endereco ?? valoresVaziosParaCampos.complemento_endereco,
	};

	const values = { ...initialValues };

	return {
		values,
		initialValues,
		visited,
		tiposOrdenados,
		validators,
		valid,
		errors,
		pesquisarDadosEnderecoPorCep,
	};
};

export const EnderecoContext = React.createContext();

export const EnderecoContextProvider = ({ children, ...props } = {}) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();

	const [
		{
			initialValues,
			visited,
			tiposObjects,
			tiposOrdenados,
			valid,
			errors,
			pesquisarDadosEnderecoPorCep,
			values: {
				cep,
				tipo_logradouro_id,
				tipo_logradouro,
				endereco,
				numero,
				bairro,
				cidade,
				uf,
				complemento_endereco,
			},
		},
		dispatch,
	] = React.useReducer(reducer, props, initializador);

	const { data: tiposLog } = useGetList(
		'tipo_logradouro',
		{ perPage: 10000, page: 1 },
		{ field: 'descricao', order: 'ASC' },
		{}
	);

	const setTiposLog = () => {
		dispatch({
			event: 'setTiposOrdenados',
			value: tiposLog,
		});
	};

	React.useEffect(setTiposLog, [tiposLog]);

	const handleCepChange = () => {
		if (!cep?.match(/[0-9]{8}/)) {
			dispatch({
				event: 'cancelar_pesquisar_dados_endereco_por_cep',
			});
			return;
		}

		if (pesquisarDadosEnderecoPorCep && tiposOrdenados && (visited || {}).cep) {
			dataProvider
				.getEndereco('', { cep })
				.then(({ data }) => {
					const resBairro = data.bairro || data.neighborhood;
					const resCidade = data.localidade || data.city;
					const resEndereco = data.logradouro || data.street;
					const resUf = data.uf || data.state;

					const camposAlterados = {};

					if (cep !== initialValues.cep) {
						if (!resBairro || !resEndereco) {
							notify('Este CEP não possui todos os dados de endereço');
						} else {
							notify('Endereço encontrado');
						}
					}

					if (resEndereco) {
						for (const tipo_logradouro of tiposOrdenados) {
							if (resEndereco.toLowerCase().includes(tipo_logradouro.descricao.toLowerCase())) {
								camposAlterados['tipo_logradouro_id'] = tipo_logradouro.id;
								camposAlterados['tipo_logradouro'] = tipo_logradouro;
								camposAlterados['endereco'] = resEndereco.replace(`${tipo_logradouro.descricao} `, '');
								break;
							}
						}
					}

					if (resBairro) camposAlterados['bairro'] = resBairro;
					if (resCidade) camposAlterados['cidade'] = resCidade;
					if (resUf) camposAlterados['uf'] = resUf;
					dispatch({
						event: 'changed_values',
						campos_alterados: camposAlterados,
					});
				})
				.catch(() => {
					notify('CEP não encontrado', 'warning');
					dispatch({
						event: 'cancelar_pesquisar_dados_endereco_por_cep',
					});
				});
		}
	};

	React.useEffect(handleCepChange, [pesquisarDadosEnderecoPorCep, tiposOrdenados]);

	const providerValue = React.useMemo(
		() => ({
			dispatch,
			initialValues,
			visited,
			tiposObjects,
			tiposOrdenados,
			errors,
			valid,
			values: {
				cep,
				tipo_logradouro_id,
				tipo_logradouro,
				endereco,
				numero,
				bairro,
				cidade,
				uf,
				complemento_endereco,
			},
		}),
		[
			dispatch,
			initialValues,
			visited,
			tiposObjects,
			tiposOrdenados,
			errors,
			valid,
			cep,
			tipo_logradouro_id,
			tipo_logradouro,
			endereco,
			numero,
			bairro,
			cidade,
			uf,
			complemento_endereco,
		]
	);

	return <EnderecoContext.Provider value={providerValue}>{children}</EnderecoContext.Provider>;
};

const GenericField = ({
	Component,
	field,
	format = (value) => value,
	parse = (value) => value,
	getValueFromOnChange = (event) => event.target.value,
	...rest
}) => {
	const { dispatch, values, errors } = React.useContext(EnderecoContext);

	const onChange = (event) => {
		const value = getValueFromOnChange(event);
		dispatch({ event: 'changed_value', campo: field, value: parse(value) });
	};

	return (
		<Component
			value={format(values[field])}
			error={errors[field]}
			margin='dense'
			variant='outlined'
			{...rest}
			onChange={onChange}
		/>
	);
};

const TipoLogradouroSelect = () => {
	const { tiposOrdenados } = React.useContext(EnderecoContext);

	return (
		<GenericField
			Component={SyndikosSelectRHF}
			id='tipo_logradouro_id'
			field='tipo_logradouro_id'
			label='Tipo de Logradouro'
			optionText='descricao'
			choices={tiposOrdenados}
			touched={true}
			allowEmpty
			fullWidth
			defaultValue={null}
			getValueFromOnChange={(value) => value}
			format={(value) => value || null}
			parse={(value) => value || null}
		/>
	);
};

const UFSelect = () => (
	<GenericField
		Component={SyndikosSelectRHF}
		id='uf'
		field='uf'
		label='UF'
		choices={opcoesUf}
		allowEmpty={true}
		fullWidth
		// source="uf"
		defaultValue={null}
		touched={true}
		disableClearable
		getValueFromOnChange={(value) => value}
	/>
);

export const EnderecoBoxV2 = ({ labelTitle = 'Endereçamento', ...props }) => (
	<BoxDivisor titulo={labelTitle} flex={1} className={props.className}>
		<Box display='flex'>
			<Box flex={1} mr='1em'>
				<GenericField
					Component={FieldCep}
					id='cep'
					field='cep'
					label='Cep'
					format={(value) => cepFormatacao(value) ?? ''}
					parse={(value) => cepParser(value) ?? ''}
					fullWidth
				/>
			</Box>
			<Box flex={1} mr='1em'>
				<TipoLogradouroSelect />
			</Box>
			<Box flex={1.5} mr='1em'>
				<GenericField Component={TextField} id='endereco' field='endereco' label='Endereço' fullWidth />
			</Box>
		</Box>
		<Box display='flex'>
			<Box flex={3} mr='1em'>
				<GenericField Component={TextField} id='bairro' field='bairro' label='Bairro' fullWidth />
			</Box>
			<Box flex={1} mr='1em'>
				<GenericField
					Component={TextField}
					id='numero'
					field='numero'
					label='Número'
					parse={numeroParser}
					fullWidth
				/>
			</Box>
		</Box>
		<Box display='flex'>
			<Box flex={1} mr='1em'>
				<GenericField Component={TextField} id='cidade' field='cidade' label='Cidade' fullWidth />
			</Box>
			<Box flex={1} mr='1em'>
				<UFSelect />
			</Box>
		</Box>
		<Box display='flex'>
			<Box flex={1} mr='1em'>
				<GenericField
					Component={TextField}
					id='complemento_endereco'
					field='complemento_endereco'
					label='Complemento'
					variant='outlined'
					fullWidth
				/>
			</Box>
		</Box>
	</BoxDivisor>
);
