import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { TextField, useListContext, useDataProvider, useNotify } from 'react-admin';

import Typography from '@material-ui/core/Typography';

import LeiturasListContext from './LeiturasListContext';
import { BRLNumberField } from '../../common/BRLNumberField';
import CurrencyInput from '../../common/CurrencyInput';
import { Padrao } from '../../common/filtros/Padrao';
import { ModalContextProvider, CustomDialog } from '../../common/ModalContext';
import { Tabela, TabelaRowContext } from '../../common/Tabela';

const LancamentoLeituraNumberField = (props) => {
	return (
		<BRLNumberField
			options={{
				minimumFractionDigits: props.source === 'valor_da_leitura' ? 2 : 0,
			}}
			{...props}
		/>
	);
};

const QuantidadeMoradoresInput = (props) => {
	const dataProvider = useDataProvider();
	const { ids, perPage, setPage, page, data, loading, total } = useListContext();
	const notify = useNotify();
	const { leituraSituacao } = useContext(LeiturasListContext);
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);
	const reloadedRef = useRef(loading);

	useEffect(() => {
		reloadedRef.current = !loading ? true : reloadedRef.current;
	}, [loading]);

	const update = () => {
		if (reloadedRef.current && data && rowRecord && data[rowRecord?.id]) {
			const newRowRecord = data[rowRecord.id];
			setRowRecord(newRowRecord);
			reloadedRef.current = false;
		}
	};

	useEffect(update, [data]);

	const handleChange = (e, values, record, source, ids, perPage, total, setPage) => {
		e.preventDefault();
		const target = e.target;
		dataProvider
			.update('unidades', {
				id: record?.id_unidade,
				data: {
					quantidade_moradores: values[source] || 0,
					update_qtd_moradores: true,
				},
			})
			.then((response) => {
				const data = response?.data;
				if (data) {
					setRowRecord((RowRecord) => ({
						...RowRecord,
						quantidade_atual: data.quantidade_moradores,
					}));
					notify('Quantidade de moradores da unidade alterada com sucesso!');
					const positionTarget = ids.findIndex((item) => item === target.id.replace(source, ''));
					const input = document.querySelector(`input#${source}${record.id}`);
					const focoAutomatico = input.getAttribute('focoautomatico');
					if (focoAutomatico) {
						input.removeAttribute('focoautomatico');
						if (ids.length === positionTarget + 1) {
							// Se é a última row da página
							if (ids.length === total - (perPage * page - perPage)) {
								// Se a página contêm todas as últimas rows disponíveis
								const firstInput = document.querySelector(`input#${source}${ids[0]}`);
								firstInput.focus();
							} else {
								setPage(page + 1);
							}
						} else {
							const nextInput = document.querySelector(`input#${source}${ids[positionTarget + 1]}`);
							if (nextInput) nextInput.focus();
						}
					}
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || 'Erro inesperado';
				notify(erroMsg, 'warning');
			});
	};

	const onBlur =
		({ values, record, source, ids, perPage, total, setPage }) =>
		(e) => {
			if (values.quantidade_atual > 0 && record?.quantidade_atual !== values.quantidade_atual) {
				handleChange(e, values, record, source, ids, perPage, total, setPage);
			}
		};

	const onKeyDown =
		({ values, record, source, ids, perPage, total, setPage }) =>
		(e) => {
			if (e.key === 'Enter') {
				e.target.setAttribute('focoautomatico', 'true');
				handleChange(e, values, record, source, ids, perPage, total, setPage);
			}
			if (e.key === 'Tab') {
				e.target.setAttribute('focoautomatico', 'true');
			}
		};

	return leituraSituacao === 'A' ? (
		<CurrencyInput
			{...props}
			record={rowRecord}
			ids={ids}
			perPage={perPage}
			total={total}
			setPage={setPage}
			decimalScale={0}
			events={{ onBlur, onKeyDown }}
			isAllowed={({ floatValue }) => {
				return String(floatValue).split('.')[0].length < 10;
			}}
			style={{ maxWidth: '50%' }}
		/>
	) : (
		<LancamentoLeituraNumberField {...props} source='quantidade_atual' label='Quantidade Atual' />
	);
};

const TotalizadorMoradores = () => {
	const { qtdMoradoresTotal } = useContext(LeiturasListContext);
	return (
		<Typography variant='body2'>
			<strong>Total de moradores:</strong> {qtdMoradoresTotal}
		</Typography>
	);
};

const TabelaLancamentosLeituraQTDMoradores = (props) => {
	const dataProvider = useDataProvider();
	const {
		ids,
		filterValues: { leitura_id, referencia },
	} = useListContext();
	const { setQtdMoradoresTotal, setValorConsumoTotal } = useContext(LeiturasListContext);
	const [focusFirstInputPage, setFocusFirstInputPage] = useState();
	useEffect(() => {
		setFocusFirstInputPage(document.querySelector(`input#quantidade_atual${ids[0]}`));
	}, [ids]);
	useEffect(() => {
		if (focusFirstInputPage) focusFirstInputPage.focus();
	}, [focusFirstInputPage]);

	const updateDadosQTDMoradores = useCallback(() => {
		if (leitura_id && referencia) {
			const isoStringReferencia = (
				typeof referencia === 'object'
					? referencia.toString && referencia.toString() !== 'Invalid Date'
						? referencia.toISOString()
						: ''
					: referencia || ''
			).split('T')[0];
			dataProvider
				.getSimple('lancamento_leitura', {
					id: 'get_dados_qtd_moradores',
					filter: {
						referencia: isoStringReferencia,
						id_leitura: leitura_id,
					},
				})
				.then((response) => {
					const data = response?.data || {};
					setQtdMoradoresTotal(data?.total_moradores || 0);
					setValorConsumoTotal(data?.valor_total_consumo || 0);
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					setQtdMoradoresTotal(0);
					setValorConsumoTotal(0);
				});
		} else {
			setQtdMoradoresTotal(0);
			setValorConsumoTotal(0);
		}
	}, [dataProvider, leitura_id, referencia, setQtdMoradoresTotal, setValorConsumoTotal]);
	useEffect(updateDadosQTDMoradores, [leitura_id, referencia]);

	return (
		<ModalContextProvider>
			<Tabela disableClickRow {...props}>
				<TextField source='descricao_tipo' label='Descrição' filtro={<Padrao />} />
				<TextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} />
				<TextField source='unidade' label='Unidade' filtro={<Padrao />} />
				<LancamentoLeituraNumberField source='quantidade_anterior' label='Quantidade Anterior' />
				<LancamentoLeituraNumberField source='quantidade' label='Quantidade' />
				<QuantidadeMoradoresInput
					source='quantidade_atual'
					label='Quantidade Atual'
					filtro={<TotalizadorMoradores />}
				/>
				<LancamentoLeituraNumberField source='valor_da_leitura' label='Valor' align='right' />
			</Tabela>
			<CustomDialog />
		</ModalContextProvider>
	);
};

export default TabelaLancamentosLeituraQTDMoradores;
