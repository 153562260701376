import React from 'react';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { Tooltip, IconButton } from '@material-ui/core';

interface FAQIconProps {
	url: string;
}

const FAQIcon: React.FC<FAQIconProps> = ({ url }) => {
	return (
		<>
			<Tooltip title={'FAQ'}>
				<IconButton onClick={() => window.open(url)}>
					<QuestionAnswerIcon style={{ fill: '#FFFFFF' }} />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default FAQIcon;
