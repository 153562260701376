import React, { useState, useMemo, useEffect } from 'react';

import { Box } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MultiSelect from 'components/common/Selects';
import { useGetList } from 'react-admin';

import { BoxDivisor } from '../../../common/Formulario';
import { PeriodoSelect, periodoOptions, TODAY } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesImpressaoCheckboxGroup = ({ handleChange, imprimirTotalGeral, imprimirTotalByUnidade }) => {
	return (
		<Box display='flex' mb='1em'>
			<Box flex={1} mr='1em'>
				<FormLabel component='legend'>Opções de Impressão</FormLabel>
				<FormGroup fullWidth>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirTotalByUnidade}
								onChange={(e, checked) => handleChange(checked, 'imprimir_total_by_unidade')}
								name='imprimir_total_by_unidade'
							/>
						}
						label='Imprimir Total do Condomínio'
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={imprimirTotalGeral}
								onChange={(e, checked) => handleChange(checked, 'imprimir_total_geral')}
								name='imprimir_ordem_nome'
							/>
						}
						label='Imprimir Total Geral'
					/>
				</FormGroup>
			</Box>
		</Box>
	);
};

export const PagamentosDivisor = (props) => {
	const [condominios, setStateCondominio] = useState([]);
	const [optionsCondominio, setOptionsCondominio] = useState([]);
	const { data: condominiosData } = useGetList(
		'condominios',
		{ perPage: 10000, page: 1 },
		{ order: 'ASC', field: 'id' },
		{ situacao: 'A' }
	);

	const [dateRange, setDateRange] = useState(periodoOptions[0]);
	const [dataInicial, setDataInicial] = useState();
	const [dataFinal, setDataFinal] = useState();
	const {
		opcoesImpressao: { imprimir_total_geral, imprimir_total_by_unidade },
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);

	const requestData = useMemo(
		() => ({
			condominios_ids: condominios.map((condominio) => {
				return condominio.id;
			}),
			data_inicial: dataInicial,
			data_final: dataFinal,
			imprimir_total_by_unidade: imprimir_total_by_unidade,
			imprimir_total_geral: imprimir_total_geral,
		}),
		[condominios, dataInicial, dataFinal, imprimir_total_geral, imprimir_total_by_unidade]
	);

	useEffect(
		() => setOptionsCondominio(Object.values(condominiosData).filter((v) => Boolean(v?.id))),
		[setOptionsCondominio, condominiosData]
	);
	return (
		<BoxDivisor titulo='Pagamentos'>
			<Box display='flex' mb='1em'>
				<Box flex={1} mr='1em'>
					<MultiSelect
						variant='formatsCondominios'
						options={optionsCondominio || []}
						value={condominios}
						setValue={setStateCondominio}
					/>
				</Box>
			</Box>
			<Box display='flex' mb='2em'>
				<Box flex={2} mr='1em'>
					<PeriodoSelect
						dateRange={dateRange}
						setDateRange={setDateRange}
						dataInicial={dataInicial}
						setDataInicial={setDataInicial}
						setDataFinal={setDataFinal}
						dataFinal={dataFinal}
						label='Selecionar Período'
					/>
				</Box>
			</Box>
			<OpcoesImpressaoCheckboxGroup
				handleChange={handleChangeOpcoesImpressao}
				imprimirTotalGeral={imprimir_total_geral}
				imprimirTotalByUnidade={imprimir_total_by_unidade}
			/>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar data={requestData} tipoRelatorio={props.tipoRelatorio} valid={true} />
			</Box>
		</BoxDivisor>
	);
};
