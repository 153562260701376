import React from 'react';

import { Box } from '@material-ui/core';
import { BoxMainGrid } from '../../../../../tabs/TabInstrucoesCobrancaRemessa';

import DescontoFields from '../../../../../tabs/TabInstrucoesCobrancaRemessa/DescontoFields';
import MultaFields from './MultaFields';
import JurosFields from './JurosFields';

const TabInstrucoesCobrancaRemessaAutomatico = () => (
	<Box>
		<BoxMainGrid>
			<Box>
				<MultaFields />
				<JurosFields />
				<DescontoFields />
			</Box>
		</BoxMainGrid>
	</Box>
);

export default TabInstrucoesCobrancaRemessaAutomatico;
