import React from 'react';

import { TableRow, TableCell, Chip } from '@material-ui/core';

import { cnpjFormatacao, cpfFormatacao } from '../../../../fieldControl/documento';
import { telefoneFormatacao } from '../../../../fieldControl/telefone';
import TooltipBar from '../../../common/TooltipBar';
import {
	TooltipButtonOpenEdicaoTela,
	TooltipButtonRemoveVinculoImobiliaria,
	TooltipButtonTrocaImobiliaria,
} from '../actionsButtonsResponsaveis';

const InfoImobiliaria = ({ id, id_pessoa, nome, imobiliaria }) => {
	return (
		<TableRow hover>
			<TableCell
				align='center'
				style={{
					paddingLeft: 0,
					width: '1%',
					whiteSpace: 'nowrap',
					textTransform: 'none',
				}}
			>
				<Chip label='Imobiliária' size='small' variant='outlined' color='secondary' />
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>{imobiliaria.nome}</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>
				{imobiliaria.tipo_imobiliaria === 'F' ? 'Física' : 'Jurídica'}
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>
				{imobiliaria.documento &&
					(imobiliaria.tipo_imobiliaria === 'F'
						? cpfFormatacao(imobiliaria.documento)
						: cnpjFormatacao(imobiliaria.documento))}
			</TableCell>
			<TableCell style={{ paddingLeft: 0, textTransform: 'none' }}>
				{imobiliaria.telefone && telefoneFormatacao(imobiliaria.telefone)}
				<br />
				{imobiliaria.email && imobiliaria.email}
			</TableCell>
			<TableCell style={{ textTransform: 'none' }}></TableCell>
			<TableCell align='right' style={{ padding: 0, textTransform: 'none' }}>
				<TooltipBar>
					{[
						<TooltipButtonOpenEdicaoTela
							href={`/admin#/imobiliarias/${imobiliaria.id}`}
							title='Editar Imobiliária'
							key='action0'
						/>,
						<TooltipButtonRemoveVinculoImobiliaria
							key='action1'
							{...{ id_pessoa, nome, imobiliaria, id }}
						/>,
						<TooltipButtonTrocaImobiliaria key='action2' {...{ imobiliaria, id }} />,
					]}
				</TooltipBar>
			</TableCell>
		</TableRow>
	);
};

export default InfoImobiliaria;
