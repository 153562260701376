import React from 'react';

import { Padrao } from 'components/common/filtros/PadraoV2';
import { Decimal } from 'components/common/filtros/DecimalV2';
import { SelectAutocompleteFilter } from 'components/common/filtros/AutocompletarV2';
import { Tabela } from 'components/common/Tabela';

import { optionsTipoIndice } from '../../components/SelectTipoIndice';
import AcoesField from './AcoesField';
import NomeFieldColumn from './NomeFieldColumn';
import TipoIndiceField from './TipoIndiceField';
import { UltimaDataAtualizacaoField } from './FormatDateField';
import DateFilter from './DateFilter';
import UltimoValorCalculoField from './UltimoValorCalculoField';

const TabelaCorrecaoMonetaria: React.FC = (props) => (
	<Tabela {...props} disableClickRow>
		<AcoesField sortable={false} selectable={false} label='Ações' />
		<NomeFieldColumn
			source='nome'
			name='nome'
			reference='correcao_monetaria'
			label='Nome da Correção Monetária'
			link={false}
			sortBy='nome'
			filtro={<Padrao />}
			minWidth='200px'
		/>
		<TipoIndiceField
			source='tipo_indice'
			name='tipo_indice'
			label='Tipo de Índice da Correção Monetária'
			filtro={<SelectAutocompleteFilter opcoes={optionsTipoIndice} />}
		/>
		<UltimoValorCalculoField
			source='ultimo_valor_calculo'
			name='ultimo_valor_calculo'
			label='Valor do Último Cálculo'
			filtro={<Decimal decimalScale={6} />}
		/>
		<UltimaDataAtualizacaoField
			source='ultima_data_atualizacao'
			name='ultima_data_atualizacao'
			label='Data da Última Atualização'
			filtro={<DateFilter />}
		/>
	</Tabela>
);

export default TabelaCorrecaoMonetaria;
