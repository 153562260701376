import React, { createContext, useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { useDataProvider } from 'react-admin';

import { CondominiosContext } from '../../../../../context/CondominioContextProvider';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';

export const ProvisionamentoPanelContext = createContext();

export const ProvisionamentoPanelContextProvider = ({ children }) => {
	const dP = useDataProvider();
	const { condominioSelecionado } = useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const {
		id_identificacao_arrecadacao: identificacao_id,
		referencia,
		tem_arrecadacao_provisionada,
	} = useContext(ArrecadacoesContext);

	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(true);
	const [data, setData] = useState({ 0: { id: 0 } });
	const [ids, setIds] = useState([0]);
	const [total, setTotal] = useState(1);
	const [selectedIds, setSelectedIds] = useState([]);

	const [currentSort, setSort] = useState({
		field: 'nome_unidade',
		order: 'ASC',
	});
	const [filters, setFilters] = useState({});

	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(5);
	const [requestTimeout, setRequestTimeout] = useState(null);

	const fetchContasAReceber = useCallback(() => {
		setLoading(true);
		setLoaded(false);
		setSelectedIds([]);
		if (tem_arrecadacao_provisionada && condominioSelecionadoId && identificacao_id && referencia) {
			dP.getList('cld_recebimento_manual', {
				pagination: { perPage, page },
				sort: currentSort,
				filter: {
					...filters,
					condominio_id: condominioSelecionadoId,
					identificacao_id,
					referencia,
				},
			})
				.then((response) => {
					const data = response?.data || [];
					const ids = data.map((c) => c.id);
					const results = data.reduce((results, item) => {
						results[item?.id] = item;
						return results;
					}, {});
					setData(results);
					setIds(ids);
					const t = response?.total || 1;
					setTotal(t);
					if (t < perPage * (page - 1) + 1) return setPage(1);
					setLoaded(true);
					setLoading(false);
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					setData({ 0: { id: 0 } });
					setIds([0]);
					setTotal(1);
					if (page !== 1) return setPage(1);
					setLoaded(true);
					setLoading(false);
				});
		} else {
			setData({ 0: { id: 0 } });
			setIds([0]);
			setTotal(1);
			if (page !== 1) return setPage(1);
			setLoaded(true);
			setLoading(false);
		}
	}, [
		currentSort,
		filters,
		page,
		perPage,
		tem_arrecadacao_provisionada,
		condominioSelecionadoId,
		identificacao_id,
		referencia,
		dP,
	]);

	const onSelect = useCallback(
		(ids) => {
			setSelectedIds(ids || []);
		},
		[setSelectedIds]
	);
	const onToggleItem = useCallback(
		(id) => {
			setSelectedIds(selectedIds.includes(id) ? [...selectedIds.filter((i) => i === id)] : [...selectedIds, id]);
		},
		[setSelectedIds, selectedIds]
	);
	const onUnselectItems = useCallback(() => {
		setSelectedIds([]);
	}, [setSelectedIds]);
	const isRowSelectable = useCallback((record) => record?.id && !record?.provisionada, []);

	const handlePageChange = useCallback(
		(event, page) => {
			event && event.stopPropagation();
			setPage(page + 1);
		},
		[setPage]
	);

	const handleFetchContasAReceber = () => {
		if (requestTimeout) clearTimeout(requestTimeout);

		setRequestTimeout(
			setTimeout(() => {
				fetchContasAReceber();
			}, 100)
		);
	};

	const resetGetListParams = () => {
		setPage(1);
		handleFetchContasAReceber();
	};
	useEffect(resetGetListParams, [
		condominioSelecionadoId,
		identificacao_id,
		referencia,
		tem_arrecadacao_provisionada,
	]);

	const handleChangePerPage = () => {
		const realPage = total < (page - 1) * perPage + 1 ? Math.ceil(total / perPage) : page;
		if (realPage !== page) return setPage(realPage);
		return handleFetchContasAReceber();
	};

	useEffect(handleChangePerPage, [perPage]);

	useEffect(handleFetchContasAReceber, [currentSort, filters, page]);

	const ProvisionamentoPanelProviderValue = useMemo(
		() => ({
			currentSort,
			data,
			ids,
			filters,
			handlePageChange,
			isRowSelectable,
			loading,
			loaded,
			onSelect,
			onUnselectItems,
			onToggleItem,
			page,
			perPage,
			refreshContas: fetchContasAReceber,
			selectedIds,
			setData,
			setFilters,
			setPage,
			setPerPage,
			setSort,
			total,
		}),
		[
			currentSort,
			data,
			ids,
			filters,
			fetchContasAReceber,
			handlePageChange,
			isRowSelectable,
			loading,
			loaded,
			onSelect,
			onUnselectItems,
			onToggleItem,
			page,
			perPage,
			selectedIds,
			setData,
			setFilters,
			setPage,
			setPerPage,
			setSort,
			total,
		]
	);

	return (
		<ProvisionamentoPanelContext.Provider value={ProvisionamentoPanelProviderValue}>
			{children}
		</ProvisionamentoPanelContext.Provider>
	);
};
