import { ComunicadoGeralList } from './list';
import { ComunicadoCreate } from './create';
import { ComunicadoGeralEdit } from './edit';

const resource = {
	list: ComunicadoGeralList,
	edit: ComunicadoGeralEdit,
	create: ComunicadoCreate,
};

export default resource;
