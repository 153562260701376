import React from 'react';
import { useDataProvider } from 'react-admin';
import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { ReservasWrapper } from './CardReservasEBloqueios/components/StyledCard';
import { CardReserva } from './CardReservasEBloqueios/CardReserva';
import { format } from 'date-fns';
import { CardBloqueio } from './CardReservasEBloqueios/CardBloqueio';
import { Box, CircularProgress } from '@material-ui/core';

const SemContentConsultaReserva = () => (
	<ReservasWrapper>
		<h1>Sem reservas disponíveis.</h1>
	</ReservasWrapper>
);

export const ContentConsultaReserva = () => {
	const {
		consulta: {
			diaSelecionado,
			unidadesSelecionadas,
			ambienteSelecionado,
			condominioSelecionado,
			consultasNoDia,
			bloqueiosNoDia,
		},
		setConsulta,
	} = React.useContext(ConsultaDeReservasContext);
	const [loadingInfos, setLoadingInfos] = React.useState(false);
	const dp = useDataProvider();

	const getConsultasNoDia = () => {
		if (!diaSelecionado) return;
		setLoadingInfos(true);
		Promise.allSettled([
			dp.getSimple('reserva_agendada_syndkos', {
				filter: {
					...(unidadesSelecionadas.length ? { unidades_ids: unidadesSelecionadas.map((u) => u.id) } : {}),
					dia_reservado: format(diaSelecionado, 'yyyy-MM-dd'),
					ambiente_id: ambienteSelecionado.id,
					condominio_id: condominioSelecionado.id,
				},
			}),
			dp.getSimple('bloqueio_reservas_syndkos', {
				filter: {
					dia_bloqueado: format(diaSelecionado, 'yyyy-MM-dd'),
					ambiente_id: ambienteSelecionado.id,
					condominio_id: condominioSelecionado.id,
				},
			}),
		])
			.then(([responseReservas, responseBloqueios]) => {
				const response401Or403 = [responseReservas, responseBloqueios].find(
					(r) => r?.status === 'rejected' && [401, 403].includes(r.reason?.e?.response?.status)
				);
				if (response401Or403) return Promise.reject(response401Or403.reason?.e);
				const reservasResponse =
					responseReservas.status === 'fulfilled' &&
					responseReservas.value.status === 200 &&
					![401, 403].includes(responseReservas.value.data.status)
						? responseReservas.value
						: null;

				const bloqueiosResponse =
					responseBloqueios.status === 'fulfilled' &&
					responseBloqueios.value.status === 200 &&
					![401, 403].includes(responseBloqueios.value.data.status)
						? responseBloqueios.value
						: null;

				const reservasColetas = Array.isArray(reservasResponse?.data?.results)
					? reservasResponse.data.results
					: [];

				const bloqueiosColetas = Array.isArray(bloqueiosResponse?.data?.results)
					? bloqueiosResponse.data.results
					: [];

				setConsulta((prevState) => ({
					...prevState,
					consultasNoDia: reservasColetas,
					bloqueiosNoDia: bloqueiosColetas,
				}));
				setLoadingInfos(false);
			})
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				return [];
			});
	};

	React.useEffect(getConsultasNoDia, [diaSelecionado]);

	if (loadingInfos.length)
		return (
			<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<CircularProgress size={40} />
			</Box>
		);

	if (!consultasNoDia.length && !bloqueiosNoDia.length) return <SemContentConsultaReserva />;

	return (
		<ReservasWrapper>
			{(Boolean(consultasNoDia.length) || Boolean(bloqueiosNoDia.length)) && (
				<>
					{consultasNoDia.map((reserva) => (
						<CardReserva data={reserva} key={reserva.id} />
					))}
					{bloqueiosNoDia.map((bloqueio) => (
						<CardBloqueio data={bloqueio} key={bloqueio.id} />
					))}
				</>
			)}
		</ReservasWrapper>
	);
};
