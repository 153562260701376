import React, { useContext } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';

import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';

const ReativarLeituraButton = ({ leituraId }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dP = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const handleActive = () => {
		dP.update('leitura', {
			id: leituraId,
			data: { situacao: 'A' },
		})
			.then(() => {
				notify('Leitura reativada com sucesso!');
				setModalValue((v) => ({ ...v, open: false }));
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
			});
	};

	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const onClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBodySizeEditable
					title={'Tem certeza que deseja ativar essa leitura?'}
					customActions={
						<>
							<ButtonCancel onClick={handleClose} />
							<ButtonConfirm size='small' onClick={handleActive}>
								Ativar
							</ButtonConfirm>
						</>
					}
				/>
			),
		}));
	};
	return (
		<Button onClick={onClick} variant='text' color='primary' size='small'>
			Ativar
		</Button>
	);
};

export default ReativarLeituraButton;
