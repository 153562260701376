import React, { useContext } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { useTheme } from '@material-ui/core/styles';

import { Typography, Box } from '@material-ui/core';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Tooltip from '@material-ui/core/Tooltip';

import { ButtonRemove } from '../../../../common/buttons/ButtonRemove';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';

const ExcluirLeituraButton = ({ leituraId, isProvisionado }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const dP = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const {
		palette: { syndikosRed },
	} = useTheme();

	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};

	const handleDelete = () => {
		dP.update('leitura', {
			id: leituraId,
			data: { situacao: 'E' },
		})
			.then(() => {
				notify('Leitura excluída com sucesso!');
				setModalValue((v) => ({ ...v, open: false }));
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(Object.values(e?.response?.data || {})[0] || ['Não foi possível excluir a leitura'], 'warning');
			});
	};

	const onClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBodySizeEditable
					title='Tem certeza que deseja remover esta Leitura?'
					text={
						<Box display='flex' alignItems='center' gridGap='20px'>
							<Box>
								<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
							</Box>
							<Box>
								<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
									Essa ação é irreversível.
									<br />
									Todo o histórico será excluído.
								</Typography>
							</Box>
						</Box>
					}
					customActions={
						<>
							<ButtonCancel onClick={handleClose} />
							<ButtonRemove size='small' onClick={handleDelete} />
						</>
					}
				/>
			),
		}));
	};
	return (
		<Tooltip title={isProvisionado ? 'Leitura possui arrecadações' : ''}>
			<span>
				<ButtonRemove onClick={onClick} disabled={isProvisionado} size='small' />
			</span>
		</Tooltip>
	);
};

export default ExcluirLeituraButton;
