import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useNotify } from 'react-admin';

import Tooltip from '@material-ui/core/Tooltip';
import { Box, makeStyles, TextField } from '@material-ui/core';

import AttachFileIcon from '@material-ui/icons/AttachFile';

import { DropzoneAreaBase } from '@pandemicode/material-ui-dropzone';

import { TooltipIconButtonCancel } from '../../../common/buttons/ButtonCancel';
import { TooltipIconButtonConfirm } from '../../../common/buttons/ButtonConfirm';
import { TooltipVoltarIconButton } from '../../../common/buttons/BotaoVoltar';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from '../../../common/ModalSizeEditableContext';
import FluxoImportacaoPlanoContext from './FluxoImportacaoPlanoContext';

const useStyles = makeStyles({
	gridFileIcons: {
		justifyContent: 'center',
	},
});

const DropZone = ({ filesSelected, setFilesSelected }) => {
	const classes = useStyles();
	const addFiles = useCallback(
		(newFileObjects) => {
			setFilesSelected(newFileObjects.filter((nFO) => !!(nFO.file?.name || '').match(/\.csv$/)));
		},
		[setFilesSelected]
	);
	const deleteFile = useCallback(
		(_, removedFileObjIdx) => {
			setFilesSelected(filesSelected.filter((_, i) => i !== removedFileObjIdx));
		},
		[filesSelected, setFilesSelected]
	);

	return (
		<DropzoneAreaBase
			dropzoneText='Arraste e solte o arquivo de retorno aqui, ou clique'
			fileObjects={filesSelected}
			filesLimit={1}
			getFileLimitExceedMessage={() => 'Número máximo de arquivos excedido, máximo de 1 arquivo'}
			previewGridClasses={{ container: classes.gridFileIcons }}
			previewGridProps={{
				item: {
					style: {
						maxWidth: '20%',
						padding: '2rem 2.5rem 2rem 2.5rem',
					},
				},
			}}
			getPreviewIcon={(fileObject, classes) => (
				<Tooltip title={fileObject.file.name}>
					<AttachFileIcon className={classes.image} />
				</Tooltip>
			)}
			onAdd={addFiles}
			onDelete={deleteFile}
			showAlerts={['error']}
		/>
	);
};

const createCabecalhosValidos = () => ({
	Classificação: {
		cabecalho: 'Classificação',
		campo: 'classificacao',
		index: 0,
	},
	'Tipo conta': { cabecalho: 'Tipo conta', campo: 'classe_conta', index: 1 },
	'Nome conta': { cabecalho: 'Nome conta', campo: 'nome', index: 2 },
});

const coletaCabecalho = (linhas = [], delimitador = ';') => {
	if (!linhas || !linhas?.length) throw new Error('Arquivo vazio');

	const cabecalhosValidos = createCabecalhosValidos();
	while (linhas.length) {
		const linha = linhas.shift();
		if (!linha) continue;

		const colunas = linha.split(delimitador);
		if (colunas.length < 3) continue;

		const cabecalhos = colunas
			.map((cabecalho, index) => {
				const cabecalhoValido = cabecalhosValidos[cabecalho];
				if (!cabecalhoValido) return { cabecalho };

				cabecalhoValido.encontrado = true;
				return {
					cabecalho,
					indexOriginal: index,
					indexIntendido: cabecalhoValido.index,
					campo: cabecalhoValido.campo,
				};
			})
			.filter((obj) => Boolean(cabecalhosValidos[obj.cabecalho]))
			.sort(({ indexIntendidoA }, { indexIntendidoB }) => indexIntendidoA < indexIntendidoB);

		const colunasFaltando = Object.values(cabecalhosValidos).filter(({ encontrado }) => !encontrado);

		if (colunasFaltando.length) {
			throw new Error(
				colunasFaltando.length === 1
					? `A seguinte coluna está faltando: ${colunasFaltando[0].cabecalho.toLowerCase()}`
					: `As seguintes colunas estão faltando: ${colunasFaltando
							.map((c) => c.cabecalho.toLowerCase())
							.join(', ')}`
			);
		}

		return cabecalhos;
	}
};

const corrigirMPTTDevidoAdicaoDeConta = (conta, contasPorClassificacaoObj) => {
	let contaPai = contasPorClassificacaoObj[conta.id_pai?.classificacao];
	while (contaPai) {
		contaPai.rght += 1;
		contaPai = contasPorClassificacaoObj[contaPai.id_pai?.classificacao];
	}
};

const atualizarTreeIdEmRelacaoAContaPai = (conta) => {
	const { children, ...contaPai } = conta.id_pai;
	conta.id_pai = contaPai;
	conta.tree_id = conta.id_pai.tree_id;
	conta.children.map(atualizarTreeIdEmRelacaoAContaPai);
	return conta;
};

const coletaContasRaizesEFormataTreeIdBaseadoNoIndex = async (dadosContasReceita, dadosContasDespesa) => {
	return [
		Object.values(dadosContasReceita.contasPorClassificacaoObj),
		Object.values(dadosContasDespesa.contasPorClassificacaoObj),
	].map((contasPorClassificacaoObj) =>
		contasPorClassificacaoObj
			.filter((c) => c.raiz)
			.map((c, index) => {
				c.tree_id = index;
				c.children.map(atualizarTreeIdEmRelacaoAContaPai);
				return c;
			})
	);
};

const scvParaArrays = async ({
	str = '',
	classificacaoReceitas = '2',
	classificacaoDespesas = '3',
	delimitador = ';',
}) => {
	const [cabecalhos, linhas = []] = await new Promise((resolve) => {
		const linhas = str
			.replace(/(\n\r)|((\r)(?!\n)|(\r\n))/g, '\n')
			.replace('\r', '')
			.split('\n');
		resolve([coletaCabecalho(linhas, delimitador), linhas]);
	});

	try {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			const { [classificacaoReceitas]: dadosContasReceita, [classificacaoDespesas]: dadosContasDespesa } =
				linhas.reduce(
					(dadosReceitasDespesas, linha) => {
						try {
							if (!linha) return dadosReceitasDespesas;

							const colunas = linha.split(delimitador);
							if (colunas.length < cabecalhos.length) return dadosReceitasDespesas;

							const conta = cabecalhos.reduce(
								(conta_1, { campo, indexOriginal }) =>
									Object.assign(conta_1, {
										[campo]: colunas[indexOriginal],
									}),
								{
									tipo: 'O',
									children: [],
									classeContaChildren: null,
									raiz: false,
									tree_id: null,
									level: 0,
									lft: 1,
									rght: 2,
									id_pai: null,
								}
							);

							conta.id = conta.classificacao;
							const classificacao = conta.classificacao.split('.');
							if (classificacao.length === 1) return dadosReceitasDespesas;

							const { contasArray, contasPorClassificacaoObj } =
								dadosReceitasDespesas[classificacao[0]] || {};
							if (!contasArray) return dadosReceitasDespesas;

							conta.nome = conta.nome.replace(/^\s*/g, '');

							if (classificacao.length === 2) {
								if (conta.classe_conta !== 'T') return dadosReceitasDespesas;
								conta.raiz = true;
								contasPorClassificacaoObj[conta.classificacao] = conta;
								contasArray.push(conta);
								return dadosReceitasDespesas;
							}

							classificacao.pop();
							const contaPai = contasPorClassificacaoObj[classificacao.join('.')];
							if (!contaPai) return dadosReceitasDespesas;

							conta.classe_conta =
								conta.classe_conta.replace(/^\s*/, '') || contaPai.classeContaChildren || 'A';

							if ((contaPai.classeContaChildren ?? conta.classe_conta) !== conta.classe_conta)
								return dadosReceitasDespesas;

							const { children } = contaPai;
							contaPai.classeContaChildren = conta.classe_conta;

							const lastChild = children[children.length - 1];
							conta.id_pai = contaPai;
							conta.level = contaPai.level + 1;
							conta.lft = (lastChild ? lastChild?.rght : contaPai.lft) + 1;
							conta.rght = conta.lft + 1;
							contaPai.rght = conta.rght + 1;
							corrigirMPTTDevidoAdicaoDeConta(contaPai, contasPorClassificacaoObj);
							children.push(conta);

							if (conta.classe_conta === 'T') contasPorClassificacaoObj[conta.classificacao] = conta;
							contasArray.push(conta);

							return dadosReceitasDespesas;
						} catch {
							return dadosReceitasDespesas;
						}
					},
					{
						[classificacaoReceitas]: {
							contasArray: [],
							contasPorClassificacaoObj: {},
						},
						[classificacaoDespesas]: {
							contasArray: [],
							contasPorClassificacaoObj: {},
						},
					}
				);

			[dadosContasReceita.contasRaizes, dadosContasDespesa.contasRaizes] =
				await coletaContasRaizesEFormataTreeIdBaseadoNoIndex(dadosContasReceita, dadosContasDespesa);

			resolve({ dadosContasReceita, dadosContasDespesa });
		});
	} catch (e) {
		console.error(e);
		return Promise.reject('Falha na leitura do csv, verifique que o arquivo esteja formatado corretamente');
	}
};

const ImportPlanoDeCSVDropZoneModalBody = () => {
	const notify = useNotify();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { setStepComponent, setDadosContasReceitasDespesas } = useContext(FluxoImportacaoPlanoContext);
	const [processing, setProcessing] = useState(false);
	const [filesSelected, setFilesSelected] = useState([]);
	const [valid, setValid] = useState(false);
	const [classificacaoReceitas, setClassificacaoReceitas] = useState('2');
	const [classificacaoDespesas, setClassificacaoDespesas] = useState('3');

	const validate = useCallback(() => {
		setValid(!!filesSelected.length);
	}, [filesSelected]);

	useEffect(validate, [filesSelected.length]);

	const processarArquivo = useCallback(() => {
		const [arquivo] = filesSelected;
		if (arquivo?.file) {
			setProcessing(true);
			const reader = new FileReader();
			reader.onload = async (e) => {
				scvParaArrays({
					str: e.target.result,
					classificacaoReceitas,
					classificacaoDespesas,
				})
					.then(({ dadosContasReceita, dadosContasDespesa }) => {
						setDadosContasReceitasDespesas({
							receitas: dadosContasReceita,
							despesas: dadosContasDespesa,
						});
						setStepComponent('ImportacaoPorCSV');
						setProcessing(false);
					})
					.catch((e) => {
						console.error(e);
						notify(e.message, 'warning');
						setProcessing(false);
					});
			};
			reader.readAsText(arquivo.file, 'iso-8859-1');
		}
	}, [
		filesSelected,
		notify,
		classificacaoReceitas,
		classificacaoDespesas,
		setStepComponent,
		setDadosContasReceitasDespesas,
	]);

	return (
		<CustomDialogBodySizeEditable
			title='Importação de Plano de Contas'
			text={
				<>
					O arquivo .csv precisará das colunas Classificação, Tipo e Nome, bem como uma linha reservada no
					inicio para os títulos da colunas
				</>
			}
			customActions={[
				<TooltipVoltarIconButton
					onClick={() => {
						setStepComponent('OpcoesImportacao');
					}}
				/>,
				<TooltipIconButtonConfirm
					title='Processar Arquivos'
					disabled={!valid || processing}
					onClick={processarArquivo}
				/>,
				<TooltipIconButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />,
			]}
			form={{
				component: (
					<Box display='grid' gridGap='0.5rem'>
						<Box display='flex' gridGap='0.5rem'>
							<TextField
								value={classificacaoReceitas}
								size='small'
								variant='outlined'
								margin='dense'
								onChange={(event) => {
									setClassificacaoReceitas(event.target.value);
								}}
								label='Classificação base das contas de receita'
								fullWidth
							/>
							<TextField
								value={classificacaoDespesas}
								size='small'
								variant='outlined'
								margin='dense'
								onChange={(event) => {
									setClassificacaoDespesas(event.target.value);
								}}
								label='Classificação base das contas de despesa'
								fullWidth
							/>
						</Box>
						<DropZone {...{ filesSelected, setFilesSelected }} />
					</Box>
				),
			}}
		/>
	);
};

export default ImportPlanoDeCSVDropZoneModalBody;
