import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';

import lodashMemoize from 'lodash/memoize';

import api from '../../../services/api';

import {
	TextInput,
	NumberInput,
	RadioButtonGroupInput,
	Button as RAButton,
	required,
	useDataProvider,
	useNotify,
	useUpdate,
	useDelete,
	useAuthProvider,
	useRefresh,
} from 'react-admin';

import { Link } from 'react-router-dom';

import { useFormState, Field } from 'react-final-form';

import { nomeParser, nomeValidacao } from '../../../fieldControl/nome';
import { CNPJInput } from '../../common/InputDocumento';
import { FormularioBox, BoxDivisor } from '../../common/Formulario';
import { TipoModalInput } from '../../common/TipoModalInput';
import { TooltipRemoveIconButton } from '../../common/buttons/BotaoRemover';
import { TooltipIconButton } from '../../common/TooltipIconButton';
import { CustomDialogBody, CustomDialog, ModalContextProvider, ModalContext } from '../../common/ModalContext';
import { ButtonCancel, TooltipIconButtonCancel } from '../../common/buttons/ButtonCancel';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import {
	GruposContext,
	GruposContextProvider,
	GrupoUnidadeContext,
	GrupoUnidadeContextProvider,
} from './contextsGrupoUnidade';
import { ModalCadastrarGrupo, ModalUnidadesDisponiveis } from './modaisGrupoUnidade';
import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContext,
	ModalSizeEditableContextProvider,
} from '../../common/ModalSizeEditableContext';
import ContasBancariasListContextProvider from './ContasBancariasListContextProvider';

import { Typography, Box, Tab, SvgIcon, Chip, IconButton } from '@material-ui/core';

import { TabPanel, TabContext, TabList } from '@material-ui/lab';

import { styled, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';

import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ReorderIcon from '@material-ui/icons/Reorder';
import SendIcon from '@material-ui/icons/Send';

import { EnderecoBox } from '../../common/Endereco';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { ButtonRemove } from '../../common/buttons/ButtonRemove';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import { ButtonClose } from '../../common/buttons/ButtonClose';

import AmbienteReservaBox from './Tabs/AmbienteReserva';
import GestaoProvider from './gestao/contexts/GestaoContext';
import Gestao from './gestao';
import ReordenarBlocos from './Tabs/Blocos/ReordenarBlocos';

import Funcionario from './funcionario';
import { FuncionarioProvider } from './funcionario/contexts/FuncionarioContext';
import { PessoasContextProvider } from 'context/PessoaContextProvider';
import { FuncaoProvider } from './funcionario/contexts/FuncoesContext';
import { CoeficienteInput } from 'components/common/CurrencyInput';
const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

const opcoesDiaVencimento = [
	{ id: 1, name: '1' },
	{ id: 2, name: '2' },
	{ id: 3, name: '3' },
	{ id: 4, name: '4' },
	{ id: 5, name: '5' },
	{ id: 6, name: '6' },
	{ id: 7, name: '7' },
	{ id: 8, name: '8' },
	{ id: 9, name: '9' },
	{ id: 10, name: '10' },
	{ id: 11, name: '11' },
	{ id: 12, name: '12' },
	{ id: 13, name: '13' },
	{ id: 14, name: '14' },
	{ id: 15, name: '15' },
	{ id: 16, name: '16' },
	{ id: 17, name: '17' },
	{ id: 18, name: '18' },
	{ id: 19, name: '19' },
	{ id: 20, name: '20' },
	{ id: 21, name: '21' },
	{ id: 22, name: '22' },
	{ id: 23, name: '23' },
	{ id: 24, name: '24' },
	{ id: 25, name: '25' },
	{ id: 26, name: '26' },
	{ id: 27, name: '27' },
	{ id: 28, name: '28' },
	{ id: 29, name: '29' },
	{ id: 30, name: '30' },
	{ id: 31, name: '31' },
];

const opcoesVencimento = [
	{ id: 'MV', name: 'Manter Vencimento' },
	{ id: 'ADF', name: 'Antecipar Domingos e Feriados' },
	{ id: 'PDF', name: 'Postergar Domingos e Feriados' },
];

const opcoesCobrancaPadraoInquilino = [
	{ id: 'Inq', name: 'Inquilino' },
	{
		id: 'Amb',
		name: 'Inquilino (Ordinárias) e Proprietário (Extraordinárias)',
	},
];

const opcoesReferencia = [
	{ id: 'R', name: 'Referência' },
	{ id: 'R1', name: 'Referência + 1' },
	{ id: 'R2', name: 'Referência + 2' },
];

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const verificaCodigo = memoize(async (value, resource, record) => {
	if (typeof value === 'number' && value <= 0) return ['Código deve ser um número positivo'];
	if (value) {
		try {
			const res = await api.get(`api/v1/${resource}?codigo=${value}`);
			if (res.data.results[0].id !== record.id) return ['Este código já está cadastrado'];
		} catch {}
	} else {
		return ['Obrigatório'];
	}
});

const getContasUsadas = async (idCondominio) => {
	try {
		const res = await api.get(`api/v1/condominios/${idCondominio}/utiliza_contas_plano`).then((v) => v);
		if (res?.data) return res.data.contas_usadas;
	} catch {}
	return {};
};

const ModalShowContasUsadas = ({ contasUsadas: { receita, despesa, arrecadacao } }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBody
			title='Não é possível alterar o plano de contas'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>
							Contas:
							<ul>
								{receita.map((c) => (
									<li>{c?.nome}</li>
								))}
								{despesa.map((c) => (
									<li>{c?.nome}</li>
								))}
								{arrecadacao.map((c) => (
									<li>{c?.nome}</li>
								))}
							</ul>
							Possuem lançamentos, arrecadações e/ou leituras
						</Typography>
					</Box>
				</Box>
			}
		/>
	);
};

const BotaoShowContasUsadas = ({ contasUsadas }) => {
	const { setModalValue } = useContext(ModalContext);
	const openShowContasUsadas = () =>
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalShowContasUsadas contasUsadas={contasUsadas} />,
		}));
	return (
		<IconButton color='primary' size='large' edge='end' onClick={openShowContasUsadas}>
			<HelpOutlineOutlinedIcon />
		</IconButton>
	);
};

const BoxInformacoesDoCondominio = (props) => {
	const { values, initialValues } = useFormState();
	return (
		<BoxDivisor titulo='Informações do Condomínio' flex={1}>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<NumberInput
						source='codigo'
						label='Código'
						validate={(value, record) => verificaCodigo(value, props.resource, record)}
						required
						disabled={props.record.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<TipoModalInput
						source='tipo_condominio'
						label='Tipo'
						resource='tipo_condominio'
						disabled={props.record.situacao === 'I'}
						allowEmpty={false}
						variant='outlined'
						fullWidth
						required
						validate={required()}
						record={props.record}
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<TextInput source='apelido' disabled={props.record.situacao === 'I'} variant='outlined' fullWidth />
				</Box>
			</Box>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<TextInput
						source='nome'
						validate={nomeValidacao}
						parse={nomeParser}
						disabled={props.record.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex'>
				<Box flex={1} mr='1em' display='flex' flexDirection='row'>
					<CNPJInput
						resource='condominios'
						source='cnpj'
						variant='outlined'
						fullWidth
						customQueryValidation={`tipo_condominio=${
							values?.tipo_condominio || initialValues?.tipo_condominio
						}&cnpj`}
						disabled={props.record.situacao === 'I'}
					/>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

const BoxCoeficienteTotal = (props) => {
	const { green, red, font } = useTheme();
	const somaCoeficientesFormatada =
		props.record && props.record.soma_coeficientes && props.record.soma_coeficientes.coeficiente__sum;
	const somaUnidades =
		props.record && props.record.quantidade_unidades && props.record.quantidade_unidades.unidade__count;
	const { coeficiente } = props.record;
	const somaCoeficientesFloat = parseFloat((somaCoeficientesFormatada || '').replace('.', '').replace(',', '.'));
	return (
		<BoxDivisor titulo='Coeficiente Total'>
			<Box display='flex'>
				{somaCoeficientesFloat === coeficiente ? (
					<Box flex={1}>
						<Box display='flex' justifyContent='center'>
							<Box>
								<SvgIcon
									style={{
										color: green[200],
									}}
									fontSize='large'
								>
									<CheckCircleOutlineIcon />
								</SvgIcon>
							</Box>
						</Box>
						<Box display='flex' justifyContent='center'>
							<Box>
								<Typography
									id='soma-coeficiente-unidades'
									variant='h4'
									style={{
										color: green[200],
									}}
								>
									{somaCoeficientesFormatada ? somaCoeficientesFormatada : 0}
								</Typography>
							</Box>
						</Box>
						<Box display='flex' justifyContent='center'>
							<Box>
								<Typography
									id='coeficiente-unidades-correta'
									variant='caption'
									style={{
										color: green[200],
									}}
								>
									Somatória de coeficientes das unidades correta
								</Typography>
							</Box>
						</Box>
					</Box>
				) : (
					<Box flex={1}>
						<Box display='flex' justifyContent='center'>
							<Box>
								<SvgIcon
									style={{
										color: red[200],
									}}
									fontSize='large'
								>
									<CancelOutlinedIcon />
								</SvgIcon>
							</Box>
						</Box>
						<Box display='flex' justifyContent='center'>
							<Box>
								<Typography
									id='soma-coeficiente-unidades'
									variant='h4'
									style={{
										color: red[200],
									}}
								>
									{somaCoeficientesFormatada ? somaCoeficientesFormatada : 0}
								</Typography>
							</Box>
						</Box>
						<Box display='flex' justifyContent='center'>
							<Box>
								<Typography
									id='coeficiente-unidades-incorreta'
									variant='caption'
									style={{
										color: red[200],
									}}
								>
									Somatória de coeficientes das unidades incorreta
								</Typography>
							</Box>
						</Box>
					</Box>
				)}

				<Box flex={1}>
					<Box display='flex' justifyContent='center'>
						<Box>
							<SvgIcon
								style={{
									color: font[200],
								}}
								fontSize='large'
							>
								<HomeOutlinedIcon />
							</SvgIcon>
						</Box>
					</Box>
					<Box display='flex' justifyContent='center'>
						<Box>
							<Typography
								variant='h4'
								style={{
									color: font[200],
								}}
							>
								{somaUnidades ? somaUnidades : 0}
							</Typography>
						</Box>
					</Box>
					<Box display='flex' justifyContent='center'>
						<Box>
							<Typography
								variant='caption'
								style={{
									color: font[200],
								}}
							>
								Somatório de unidades
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

const BoxObservacoes = (props) => {
	return (
		<BoxDivisor titulo='Observação'>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<Field name='observacao' id='observacao' disabled={props.record.situacao === 'I'}>
						{({ input, meta }) => (
							<TextInput
								source='observacao'
								label='Anotações'
								placeholder='Use este espaço para anotações referentes a este condomínio'
								variant='outlined'
								validate={(value) =>
									value ? (value.length > 500 ? [`Máximo excedido: ${value.length}/500`] : '') : ''
								}
								helperText={meta?.error ? meta?.error : `${(input?.value || '').length}/500`}
								rows={8}
								multiline
								fullWidth
							/>
						)}
					</Field>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

const CheckBoxReguaCobranca = ({ record }) => (
	<Field name='regua_cobranca' id='regua_cobranca' disabled={record.situacao === 'I'} type='checkbox'>
		{({ input }) => (
			<FormControlLabel
				control={
					<Tooltip
						title={
							<>
								O sistema enviará três lembretes de pagamento por e-mail e celular caso a arrecadação da
								unidade ainda não tenha sido paga na data de envio do lembrete. Avisos:
								<ul>
									<li>5 dias antes do vencimento,</li>
									<li>1 dia antes do vencimento; e</li>
									<li>No dia de vencimento.</li>
								</ul>
							</>
						}
					>
						<Switch
							color='primary'
							checked={input.checked}
							onChange={(e) => {
								input.onChange(e);
							}}
							disabled={record.situacao === 'I'}
							name={input.name}
						/>
					</Tooltip>
				}
				label='Lembrete de Pagamento'
				labelPlacement='start'
				style={{ margin: 'auto' }}
			/>
		)}
	</Field>
);

const CheckBoxReguaLembreteAtrasados = ({ record }) => (
	<Field
		name='regua_lembrete_atrasados'
		id='regua_lembrete_atrasados'
		disabled={record.situacao === 'I'}
		type='checkbox'
	>
		{({ input }) => (
			<FormControlLabel
				control={
					<Tooltip
						title={
							<>
								O sistema enviará toda segunda-feira por e-mail e celular avisos de boletos atrasados
								caso a arrecadação da unidade esteja vencida há mais de 5 dias.
							</>
						}
					>
						<Switch
							color='primary'
							checked={input.checked}
							onChange={(e) => {
								input.onChange(e);
							}}
							disabled={record.situacao === 'I'}
							name={input.name}
						/>
					</Tooltip>
				}
				label='Lembrete de Atrasados'
				labelPlacement='start'
				style={{ margin: 'auto' }}
			/>
		)}
	</Field>
);

const CheckBoxesConfiguracaoDoCodominioSemAcessoApp = ({ record }) => (
	<Box flex={1} display='flex' alignItems='center'>
		<CheckBoxReguaCobranca record={record} />
		<CheckBoxReguaLembreteAtrasados record={record} />
	</Box>
);

const CheckBoxesConfiguracaoDoCodominioDisponivelParaKondomynos = ({ record }) => (
	<Field
		name='disponivel_para_kondomynos'
		id='disponivel_para_kondomynos'
		disabled={record.situacao === 'I'}
		type='checkbox'
	>
		{({ input }) => (
			<FormControlLabel
				control={
					<Tooltip
						title={
							<>
								Os dados de boletos das unidades deste condomínio estarão disponíveis para os usuários
								do App que tiverem permissão desta administradora.
							</>
						}
					>
						<Switch
							color='primary'
							checked={input.checked}
							onChange={(e) => {
								input.onChange(e);
							}}
							disabled={record.situacao === 'I'}
							name={input.name}
						/>
					</Tooltip>
				}
				label='Disponibilizar acesso no aplicativo aos condôminos'
				labelPlacement='end'
				style={{ margin: 'auto' }}
			/>
		)}
	</Field>
);

const CheckBoxesConfiguracaoDoCodominioDisponivelCDDParaKondomynos = ({ record }) => (
	<Field
		name='disponibilizado_cdd_para_kondomynos'
		id='disponibilizado_cdd_para_kondomynos'
		disabled={record.situacao === 'I'}
		type='checkbox'
	>
		{({ input }) => (
			<FormControlLabel
				control={
					<Tooltip
						title={
							<>
								Com esta opção os moradores poderão gerar a certidão de débitos pelo App afim de
								verificar a regularidade da sua unidade.
							</>
						}
					>
						<Switch
							color='primary'
							checked={input.checked}
							onChange={(e) => {
								input.onChange(e);
							}}
							disabled={!record.disponivel_para_kondomynos || record.situacao === 'I'}
							name={input.name}
						/>
					</Tooltip>
				}
				label='Habilitar Impressão da Certidão de Débitos no Aplicativo'
				labelPlacement='end'
				style={{ margin: 'auto' }}
			/>
		)}
	</Field>
);

const DialogUsuariosSemConvite = ({ pessoas }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			title={'Algumas pessoas não puderam receber convites'}
			text={
				'Verificamos que os cadastros abaixo encontram-se incompletos, verifique os campos CPF/CNPJ ou e-mail'
			}
			form={{
				component: (
					<>
						{pessoas.map((pessoa) => (
							<Typography style={{ marginTop: '15px' }}>
								A pessoa{' '}
								<Link to={`/pessoas/${pessoa.id_pessoa_id}/`} target={'_blank'}>
									{pessoa.id_pessoa__nome}
								</Link>{' '}
								não possui CPF/CNPJ ou e-mail
							</Typography>
						))}
					</>
				),
			}}
			customActions={
				<>
					<ButtonCancel onClick={handleClose} />
				</>
			}
		/>
	);
};

const TooltipIconButtonEnviarConvitesEmMassa = ({ record }) => {
	const [enviandoPermissoesConvites, setEnviandoPermissoesConvites] = useState(false);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { values } = useFormState();
	const notify = useNotify();
	const dp = useDataProvider();

	return (
		<Box>
			<TooltipIconButton
				disabled={!record.disponivel_para_kondomynos || record.situacao === 'I' || enviandoPermissoesConvites}
				title={
					'Enviar  convites para os condôminos, lembre-se de cadastrar o documento dos responsáveis das unidades e o e-mail!'
				}
				onClick={() => {
					setEnviandoPermissoesConvites(true);
					dp.simplePost(`condominios/${values.id}/enviar_permissoes_kondomynos`, {
						data: {},
					})
						.then((res) => {
							if (res?.data?.mensagens_de_erro) {
								const mensagensDeErro = res.data.mensagens_de_erro;
								if (mensagensDeErro.length > 0) {
									const convitesEnviados = res?.data?.usuarios_enviados.length;
									const mensagensConcatenadas = res?.data?.mensagens_de_erro.join(', ');
									notify(
										`${convitesEnviados} convites enviados com sucesso. \nOcorreu um erro ao tentar enviar o convite para: \n${mensagensConcatenadas}`,
										{ multiLine: true }
									);
								} else {
									notify('Permissões enviadas com sucesso!');
								}
							}

							if ((res?.data?.usuarios_sem_convite || []).length >= 1) {
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody: <DialogUsuariosSemConvite pessoas={res.data.usuarios_sem_convite} />,
								}));
							}
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							notify('Erro inesperado, tente recarregar a página', 'warning');
						})
						.finally(() => {
							setEnviandoPermissoesConvites(false);
						});
				}}
				style={{ margin: 'auto' }}
			>
				<SendIcon />
			</TooltipIconButton>
		</Box>
	);
};

const CheckBoxesConfiguracaoDoCodominioComAcessoApp = ({ record }) => {
	const formState = useFormState();

	return (
		<Box flex={1} display='block'>
			<Box flex={1} alignItems='center'>
				<CheckBoxReguaCobranca record={record} />
				<br></br>
				<Box display={'flex'}>
					<CheckBoxReguaLembreteAtrasados record={record} />
					<Box flex={1} mr='1em'>
						<NumberInput
							source='limite_dias_duracao_lembrete'
							label='Limite de dias do lembrete'
							variant='outlined'
							helperText={false}
							min={0}
							disabled={!formState?.values?.regua_lembrete_atrasados}
							fullWidth
						/>
					</Box>
				</Box>
				<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '61vw' }}>
					<Box flex={1} alignItems='center'>
						<CheckBoxesConfiguracaoDoCodominioDisponivelParaKondomynos record={record} />
						<CheckBoxesConfiguracaoDoCodominioDisponivelCDDParaKondomynos record={record} />
						<TooltipIconButtonEnviarConvitesEmMassa record={record} />
					</Box>
				</ModalSizeEditableContextProvider>
			</Box>
		</Box>
	);
};

const BoxConfiguracaoDoCondominio = (props) => {
	const aP = useAuthProvider();
	const dp = useDataProvider();
	const [identity, setIdentity] = useState({});
	const [contasUsadas, setContasUsadas] = useState({});
	const disableSelectPlano = props.record.situacao === 'I' || Object.values(contasUsadas)?.some((v) => !!v?.length);
	const [templates, setTemplates] = useState('');
	const [contaBancaria, setContaBancaria] = useState('');

	const { id: idCondominio } = props.record;
	useEffect(() => {
		getContasUsadas(props.id).then((v) => setContasUsadas(v));
	}, [props.id]);

	const getTemplates = async () => {
		try {
			const {
				data: { results },
			} = await dp.getSimple('template_relatorios', {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'nome', order: 'ASC' },
			});
			return results.map((template) => ({
				id: template.id,
				name: template.nome,
			}));
		} catch (error) {
			return [];
		}
	};

	const getContaBancaria = async () => {
		try {
			const {
				data: { results },
			} = await dp.getSimple('conta_condominio', {
				pagination: { perPage: 10000, page: 1 },
				sort: { field: 'nome', order: 'ASC' },
				filter: {
					id_condominio: idCondominio,
					situacao: 'A',
				},
			});
			return results.map((contaBancaria) => ({
				id: contaBancaria.id,
				name: contaBancaria.nome_conta,
			}));
		} catch (error) {
			return [];
		}
	};

	useEffect(() => {
		getTemplates(props.id).then((v) => setTemplates(v));
	}, []);

	useEffect(() => {
		getContaBancaria(props.id).then((v) => setContaBancaria(v));
	}, []);

	const getIdentity = useCallback(() => {
		aP.reGetIdentity()
			.catch(() => ({}))
			.then((response) => {
				setIdentity(response?.data || {});
			});
	}, [aP, setIdentity]);
	useEffect(getIdentity, []);

	return (
		<BoxDivisor titulo='Configurações do Condomínio'>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<TipoModalInput
						condominio={props?.id}
						source='tipo_padrao_unidade'
						label='Tipo padrão das unidades'
						validate={required()}
						resource='tipo_unidade'
						disabled={props.record.situacao === 'I'}
						allowEmpty={false}
						variant='outlined'
						fullWidth
						record={props.record}
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<SyndikosSelect
						source='dia_padrao_vencimento'
						label='Dia padrão do vencimento'
						allowEmpty={false}
						choices={opcoesDiaVencimento}
						defaultValue={1}
						disabled={props.record.situacao === 'I'}
						variant='outlined'
						disableClearable
						fullWidth
					/>
				</Box>
				<Box flex={1} mr='1em'>
					<SyndikosSelect
						source='opcao_vencimento'
						label='Dia do Vencimento'
						allowEmpty={false}
						choices={opcoesVencimento}
						defaultValue={'MV'}
						disabled={props.record.situacao === 'I'}
						variant='outlined'
						disableClearable
						fullWidth
					/>
				</Box>
			</Box>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<RadioButtonGroupInput
						source='opcao_referencia'
						label='Vencimento no mês de: '
						allowEmpty={false}
						choices={opcoesReferencia}
						defaultValue={'R1'}
						disabled={props.record.situacao === 'I'}
						variant='outlined'
						fullWidth
					/>
				</Box>
				{identity?.tenant?.acesso_app ? (
					<CheckBoxesConfiguracaoDoCodominioComAcessoApp {...props} />
				) : (
					<CheckBoxesConfiguracaoDoCodominioSemAcessoApp {...props} />
				)}
			</Box>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<VeryHighLimitReferenceInput
						source='id_plano_condominio'
						label='Plano de Condomínio'
						reference='plano_condominio'
						disabled={disableSelectPlano}
						filter={{ situacao: 'A' }}
						allowEmpty
						helperText={disableSelectPlano ? 'O condomínio utiliza contas deste plano' : ''}
						variant='outlined'
						fullWidth
					>
						<SyndikosSelect
							optionText='descricao'
							endAdornment={
								disableSelectPlano ? (
									<InputAdornment position='end'>
										<ModalContextProvider>
											<CustomDialog disableBackdropClick={true} />
											<BotaoShowContasUsadas {...{ contasUsadas }} />
										</ModalContextProvider>
									</InputAdornment>
								) : null
							}
						/>
					</VeryHighLimitReferenceInput>
				</Box>
				<Box flex={1} mr='1em'>
					<CoeficienteInput
						source='coeficiente'
						label='Coeficiente de Arrecadação do Condomínio'
						disabled={props.record.situacao === 'I'}
						variant='outlined'
						margin='dense'
						fullWidth
						fieldProps={{
							label: 'Coef. de Arrecadação',
						}}
						initialValue={0}
						defaultValue={0}
						decimalScale={8}
					/>
				</Box>
			</Box>
			<Box display='flex'>
				<Box mr='1em' flex={1}>
					<SyndikosSelect
						source='opcao_cobranca_padrao_inquilino'
						label='Cobrança Padrão com Inquilino'
						allowEmpty={false}
						choices={opcoesCobrancaPadraoInquilino}
						defaultValue={'Amb'}
						variant='outlined'
						disableClearable
					/>
				</Box>
				<Box mr='1em' flex={1}>
					<SyndikosSelect
						source='template_relatorio'
						label='Template de Relatório Padrão'
						allowEmpty={false}
						choices={templates}
						variant='outlined'
						disableClearable
					/>
				</Box>
			</Box>
			<Box display='flex'>
				<Box mr='1em' flex={1}>
					<VeryHighLimitReferenceInput
						source='configuracao_correcao_monetaria'
						label='Configuração de Multa, Juros e Correção Monetária'
						reference='correcao_monetaria'
						allowEmpty
						variant='outlined'
						fullWidth
					>
						<SyndikosSelect optionText='nome' />
					</VeryHighLimitReferenceInput>
				</Box>
				<Box mr='1em' flex={1}>
					<SyndikosSelect
						source='conta_bancaria_principal'
						label='Conta Bancária Principal'
						allowEmpty={true}
						choices={contaBancaria}
						variant='outlined'
					/>
				</Box>
			</Box>
		</BoxDivisor>
	);
};

const ChipUnidade = (props) => {
	const { setGrupo, setModalValue } = useContext(GrupoUnidadeContext);
	const [desvincular, { data }] = useUpdate('unidades', props.id, {
		id_grupo_unidade: null,
	});
	const [display, setDisplay] = useState('none');
	const handleDelete = () => {
		desvincular();
		handleClose();
	};
	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};
	const openDesvincular = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: (
				<CustomDialogBody
					title='Desvincular unidade'
					text='Tem certeza que deseja desvincular esta unidade?'
					customActions={
						<>
							<ButtonCancel onClick={handleClose} size='small' />
							<ButtonConfirm onClick={handleDelete} size='small'>
								Confirmar
							</ButtonConfirm>
						</>
					}
				/>
			),
		}));
	};
	useEffect(() => {
		if (data)
			setGrupo((grupo) => ({
				...grupo,
				soma_coeficientes: grupo.soma_coeficientes - data.coeficiente,
				unidades: grupo.unidades.filter((value) => value.id !== data.id),
			}));
	}, [data, setGrupo]);
	return (
		<Tooltip
			title={`Unidade ${props.unidade} - Proprietário: ${props.proprietario} ${
				props.inquilino ? ' - Inquilino: ' + props.inquilino : ''
			}`}
		>
			<Chip
				variant='outlined'
				onDelete={openDesvincular}
				deleteIcon={<HighlightOffIcon style={{ display: display }} />}
				onMouseEnter={() => setDisplay('block')}
				onMouseLeave={() => setDisplay('none')}
				style={{
					marginRight: '0.5em',
					marginBottom: '0.5em',
					width: '90px',
				}}
				label={props.unidade}
				color='primary'
			/>
		</Tooltip>
	);
};

const ModalFeedbackBloqueioRemover = ({ setModalValue, usado_em_arrecadacao, usado_em_lancamento }) => {
	const {
		palette: { syndikosRed },
	} = useTheme();
	return (
		<CustomDialogBody
			title='Este grupo não pode ser removido'
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold' }}>
							<ul>
								{
									<>
										{<li>Este grupo está vinculado a ao menos uma unidade</li>}
										{usado_em_arrecadacao && (
											<li>Este grupo está vinculado a ao menos uma arrecadação</li>
										)}
										{usado_em_lancamento && (
											<li>Este grupo está vinculado a ao menos um lançamento</li>
										)}
									</>
								}
							</ul>
						</Typography>
					</Box>
				</Box>
			}
			customActions={
				<>
					<ButtonClose
						onClick={() => {
							setModalValue({ open: false });
						}}
						size='small'
					>
						cancelar
					</ButtonClose>
					<ButtonRemove disabled={true} size='small' />
				</>
			}
		/>
	);
};

const ActionButtonsHover = () => {
	const { setData } = useContext(GruposContext);
	const { grupo, setRenameMode, setModalValue } = useContext(GrupoUnidadeContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const [settingsOpen, setSettingsOpen] = useState(false);
	const openAdd = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalUnidadesDisponiveis />,
		}));
	};
	const [deleteGrupo] = useDelete('grupo_unidade_condominio', grupo.id, grupo);
	const handleDelete = () => {
		deleteGrupo();
		setData((data) => data.filter((v) => v.id !== grupo.id));
		handleClose();
	};
	const handleClose = () => {
		setModalValue((v) => ({
			...v,
			open: false,
		}));
	};
	const Actions = () => (
		<>
			<TooltipIconButton
				title='Renomear'
				onClick={() => {
					setRenameMode(true);
				}}
				ml='0.5em'
			>
				<EditIcon />
			</TooltipIconButton>
			<TooltipRemoveIconButton
				title='Remover'
				onClick={() => {
					if (grupo.unidades?.length > 0 || grupo.usado_em_arrecadacao || grupo.usado_em_lancamento) {
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: (
								<ModalFeedbackBloqueioRemover
									setModalValue={setModalValue}
									usado_em_arrecadacao={grupo.usado_em_arrecadacao}
									usado_em_lancamento={grupo.usado_em_lancamento}
								/>
							),
						}));
					} else {
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: (
								<CustomDialogBody
									title='Tem certeza que deseja remover este Grupo?'
									text={
										<Box display='flex' alignItems='center' gridGap='20px'>
											<Box>
												<WarningIcon
													style={{
														color: syndikosRed.main,
													}}
													fontSize='large'
												/>
											</Box>
											<Box>
												<Typography
													style={{
														fontWeight: 'bold',
														color: syndikosRed.main,
													}}
												>
													Essa ação é irreversível.
													<br />
													Todo o histórico será excluído.
												</Typography>
											</Box>
										</Box>
									}
									customActions={
										<>
											<ButtonCancel onClick={handleClose} />
											<ButtonRemove size='small' onClick={handleDelete} />
										</>
									}
								/>
							),
						}));
					}
				}}
			/>
			<TooltipIconButton title='Vincular Unidades' onClick={openAdd} ml='0.5em' color='primary'>
				<HomeOutlinedIcon />
			</TooltipIconButton>
		</>
	);

	return (
		<div onMouseLeave={() => setSettingsOpen(false)}>
			<span style={{ position: 'relative' }}>
				<TooltipIconButton title='Opções' onMouseEnter={() => setSettingsOpen(true)}>
					<SettingsIcon />
				</TooltipIconButton>
				<Paper
					style={{
						display: settingsOpen ? 'flex' : 'none',
						justifyContent: 'center',
						alignItems: 'center',
						width: 150,
						position: 'absolute',
						top: '-18px',
						borderRadius: 10,
					}}
				>
					<Actions onClick={() => setSettingsOpen(false)} />
				</Paper>
			</span>
		</div>
	);
};

const BoxGrupoUnidade = () => {
	const { grupo, setGrupo, renameMode, setRenameMode } = useContext(GrupoUnidadeContext);

	const refresh = useRefresh();
	const inputRef = useRef(null);
	const [nome, setNome] = useState(grupo.nome);
	const [showUnidades, setShowUnidades] = useState(false);
	const [valid, setValid] = useState();
	const [rename, { data }] = useUpdate('grupo_unidade_condominio', grupo.id, {
		nome: nome,
	});
	useEffect(() => {
		if (renameMode) inputRef.current.focus();
	}, [renameMode]);
	const handleSubmit = () => {
		if (valid) {
			rename();
			setGrupo((v) => ({ ...v, nome }));
			setRenameMode(false);
			refresh();
		}
	};
	useEffect(() => {
		setValid(grupo.nome === nome ? false : nome.length > 0);
	}, [nome, grupo.nome]);
	const handleExpand = () => setShowUnidades((v) => !v);
	return (
		<>
			<Box display='flex'>
				<Box>
					<Box display='flex' alignItems='center'>
						{grupo.unidades.length > 0 ? (
							<TooltipIconButton
								title={!showUnidades ? 'Mostrar Unidades' : 'Ocultar Unidades'}
								onClick={handleExpand}
							>
								<ArrowForwardIosIcon
									fontSize='medium'
									style={{
										transition: 'all 200ms !important',
										transform: showUnidades ? 'rotate(90deg)' : 'rotate(0deg)',
									}}
								/>
							</TooltipIconButton>
						) : (
							<></>
						)}
						{renameMode ? (
							<>
								<TextField
									inputRef={inputRef}
									value={nome}
									onChange={(e) => setNome(e.target.value)}
									margin='dense'
									onKeyPress={(e) => {
										if (e.key === 'Enter') {
											e.stopPropagation();
											handleSubmit();
										}
									}}
								/>
								<TooltipIconButtonCancel
									onClick={() => {
										setRenameMode(false);
										setNome(data?.nome || grupo.nome);
									}}
								/>
								<TooltipIconButton
									title='Salvar'
									onClick={handleSubmit}
									disabled={!valid}
									color='primary'
								>
									<CheckIcon />
								</TooltipIconButton>
							</>
						) : (
							<>
								<Typography variant='h6' style={{ 'margin-right': '10px' }}>
									{nome}
								</Typography>
								<ActionButtonsHover />
							</>
						)}
						<Typography>
							Unidades cadastradas: {grupo.unidades.length} / Coeficiente de Arrecadação:{' '}
							{grupo.soma_coeficientes ? grupo.soma_coeficientes : 0}
						</Typography>
					</Box>
				</Box>
			</Box>
			{showUnidades ? (
				<>
					<Box display='flex'>
						<Box flexGrow={1} mr='1em'>
							{grupo.unidades.map((u) => (
								<ChipUnidade {...u} key={u.id} />
							))}
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			<hr
				style={{
					marginRight: '1em',
					borderTop: '#d3d3d3',
				}}
			></hr>
		</>
	);
};

const GrupoBoxDivisor = () => {
	const [reordering, setReordering] = useState(false);

	const { data, setModalValue } = useContext(GruposContext);

	const [localData, setLocalData] = useState(data);

	useEffect(() => {
		setLocalData(data);
	}, [data]);

	const openAdd = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalCadastrarGrupo />,
		}));
	};

	return (
		<Box>
			<Box display='flex'>
				<Box flexGrow={1} mr='1em'>
					<Typography variant='h5' gutterBottom>
						Grupos de Unidades
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<RAButton
						label='Reordenar'
						startIcon={<ReorderIcon />}
						onClick={() => {
							setReordering(!reordering);
						}}
					></RAButton>
					<Box mr={2} />
					<RAButton label='Novo grupo de unidades' variant='text' color='primary' onClick={openAdd}>
						<AddCircleOutlineIcon />
					</RAButton>
				</Box>
			</Box>
			<hr
				style={{
					marginRight: '1em',
					borderTop: '#d3d3d3',
				}}
			></hr>

			{!reordering ? (
				localData.map((grupo) => (
					<ModalContextProvider key={grupo.id}>
						<GrupoUnidadeContextProvider grupo={grupo}>
							<BoxGrupoUnidade />
							<CustomDialog disableBackdropClick={true} />
						</GrupoUnidadeContextProvider>
					</ModalContextProvider>
				))
			) : (
				<ReordenarBlocos setReordering={setReordering} data={localData} setData={setLocalData} />
			)}
		</Box>
	);
};

const StyledTabList = styled(TabList)({
	'& div': {
		overflow: 'auto !important',
		'& div': {
			overflow: 'unset !important',
		},
	},
});

export const CondominioFormulario = (props) => {
	const [value, setValue] = React.useState('0');
	const handleChange = (_, newValue) => {
		setValue(newValue);
	};
	return (
		<FormularioBox {...props}>
			<TabContext value={value}>
				<Box width={1}>
					<Box display='flex'>
						<StyledTabList value={value} onChange={handleChange} aria-label='simple tabs example'>
							<Tab label='Informações do Condomínio' {...a11yProps(0)} value='0' />
							<Tab label='Configurações do Condomínio' {...a11yProps(1)} value='1' />
							<Tab label='Grupos de Unidades' {...a11yProps(2)} value='2' />
							<Tab label='Contas bancárias' {...a11yProps(3)} value='3' />
							<Tab label='Ambientes de reserva' {...a11yProps(4)} value='4' />
							<Tab label='Gestão' {...a11yProps(5)} value='5' />
							<Tab label='Funcionários' {...a11yProps(6)} value='6' />
						</StyledTabList>
					</Box>
					<TabPanel value={value} index={value}>
						<Box
							display={value === '0' ? 'flex' : 'none'}
							css={
								(value !== '0' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}) ||
								{}
							}
						>
							<Box flex={1} mr='1em'>
								<Box display='flex'>
									<BoxInformacoesDoCondominio {...props} />
								</Box>
								<Box mt='1em' />
								<Box>
									<EnderecoBox {...props} />
								</Box>
							</Box>
							<Box flex={1} mr='1em'>
								<BoxCoeficienteTotal {...props} />
								<Box mt='1em' />
								<BoxObservacoes {...props} />
							</Box>
						</Box>
						<Box
							display={value === '1' ? 'flex' : 'none'}
							css={
								(value !== '1' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}) ||
								{}
							}
						>
							<Box flex={2}>
								<BoxConfiguracaoDoCondominio {...props} />
							</Box>
							<Box flex={1}></Box>
						</Box>
						<Box
							display={value === '2' ? 'flex' : 'none'}
							css={
								value !== '2' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}
							}
						>
							<Box width={1}>
								<ModalContextProvider>
									<GruposContextProvider {...props}>
										<CustomDialog disableBackdropClick={true} />
										<GrupoBoxDivisor />
									</GruposContextProvider>
								</ModalContextProvider>
							</Box>
						</Box>
						<Box
							display={value === '3' ? 'flex' : 'none'}
							css={
								(value !== '3' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}) ||
								{}
							}
						>
							<Box flex={2}>
								<ContasBancariasListContextProvider condominioRecord={props.record} props={props} />
							</Box>
						</Box>
						<Box
							display={value === '4' ? 'flex' : 'none'}
							css={
								value !== '4' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}
							}
						>
							<AmbienteReservaBox {...props} />
						</Box>
						<Box
							display={value === '5' ? 'flex' : 'none'}
							css={
								value !== '5' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}
							}
						>
							<GestaoProvider condominioRecord={props.record}>
								<Gestao />
							</GestaoProvider>
						</Box>
						<Box
							display={value === '6' ? 'flex' : 'none'}
							css={
								value !== '6' && {
									width: 0,
									height: 0,
									position: 'absolute',
									top: 0,
									opacity: 0,
									userSelect: 'none',
									zIndex: -1000,
								}
							}
						>
							<PessoasContextProvider>
								<FuncaoProvider>
									<FuncionarioProvider condominioRecord={props.record}>
										<Funcionario condominioRecord={props.record} />
									</FuncionarioProvider>
								</FuncaoProvider>
							</PessoasContextProvider>
						</Box>
					</TabPanel>
				</Box>
			</TabContext>
		</FormularioBox>
	);
};
