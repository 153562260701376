import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoProximo } from 'components/common/buttons/BotaoProximo';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';

const ModalMetodoCalculoNovaLeitura = () => {
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const { dadosNovaLeitura, handleCancel, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const [disabledForProcessing, setDisabledForProcessing] = useState(false);
	const [metodoCalculo, setMetodoCalculo] = useState(dadosNovaLeitura.dados_config.metodo_calculo || '1');
	const handleBack = () => {
		setDadosNovaLeitura((v) => {
			const { metodo_calculo, ...dados_config } = v.dados_config;
			v['dados_config'] = dados_config;
			return v;
		});
		setModalStep('1A');
	};
	const handleNext = () => {
		if (['1', '2', '3', '4'].includes(metodoCalculo)) {
			setDadosNovaLeitura((v) => {
				v.dados_config['metodo_calculo'] = metodoCalculo;
				return v;
			});
			setModalStep('4');
		} else if (['5', '6'].includes(metodoCalculo)) {
			setDisabledForProcessing(true);
			dP.create('leitura', {
				data: {
					...dadosNovaLeitura,
					dados_config: {
						...dadosNovaLeitura.dados_config,
						metodo_calculo: metodoCalculo,
						referencia: '1939-07-01',
					},
				},
			})
				.then((response) => {
					const data = response?.data;
					if (data) {
						setDadosNovaLeitura((v) => ({ ...v, id: data.id }));
						refresh();
						notify('Leitura cadastrada com sucesso');
						setModalValue((v) => ({ ...v, open: false }));
					} else {
						setDisabledForProcessing(false);
					}
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					setDisabledForProcessing(false);
					notify(`Erro ao cadastrar nova leitura: ${e}`, 'warning');
				});
		}
	};

	const metodos_calculo = [
		{ id: '1', descricao: 'Distribuição por faixa de consumo' },
		{ id: '3', descricao: 'Valor fixo por faixa de consumo' },
		{ id: '6', descricao: 'Quantidade de Moradores' },
		{ id: '4', descricao: 'Percentual' },
	];

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Qual será o método de cálculo utilizado?'
			form={{
				component: (
					<FormControl component='fieldset'>
						<RadioGroup
							name='metodo_calculo'
							value={metodoCalculo}
							onChange={(e) => setMetodoCalculo(e.target.value)}
						>
							{metodos_calculo.map((metodo) => (
								<FormControlLabel
									key={metodo.id}
									value={metodo.id}
									control={<Radio />}
									label={metodo.descricao}
								/>
							))}
						</RadioGroup>
					</FormControl>
				),
			}}
			customActions={
				<>
					<Button
						variant='text'
						onClick={handleCancel}
						disabled={disabledForProcessing}
						size='small'
						startIcon={<CloseIcon />}
					>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} disabled={disabledForProcessing} />
					{['5', '6'].includes(metodoCalculo) ? (
						<Button
							color='primary'
							disabled={disabledForProcessing}
							size='small'
							startIcon={<CheckIcon />}
							onClick={handleNext}
							variant='text'
						>
							Salvar
						</Button>
					) : (
						<BotaoProximo
							onClick={handleNext}
							disabled={disabledForProcessing}
							icon={<NavigateNextIcon />}
						/>
					)}
				</>
			}
		/>
	);
};

export default ModalMetodoCalculoNovaLeitura;
