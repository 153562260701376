import React from 'react';
import { CustomDataProvider, KSurveyDetailsModalProps } from './types';
import { CloseButton, ConfirmButton } from 'components/common/commonComponentsTSX';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import { useDataProvider, useNotify } from 'react-admin';
import { AxiosError } from 'axios';
import { Box, Checkbox, makeStyles } from '@material-ui/core';
import { UserThemeContext } from 'theme/context';

const useStyles = makeStyles(() => ({
	progressBar: {
		backgroundColor: '#2F345D',
	},
	emptyBar: {
		backgroundColor: '#7481a5',
	},

	progressLabel: {
		position: 'absolute',
		padding: '12px 0',
		zIndex: 1,
		display: 'flex',
		gap: '410px',
	},
	checkboxLabel: {
		display: 'block',
		cursor: 'pointer',
	},
}));

const SurveyDetailsModal: React.FC<KSurveyDetailsModalProps> = ({ surveyId }) => {
	const { setModalValue } = React.useContext(ModalContext);
	const notify = useNotify();

	const [printGroupByPerson, setPrintGroupByPerson] = React.useState(false);
	const [printDefaulter, setPrintDefaulter] = React.useState(false);
	const [printUnitsThatDidntVote, setPrintUnitsThatDidntVote] = React.useState(false);
	const [printResidentVote, setPrintResidentVote] = React.useState(false);
	const dataProvider = useDataProvider() as CustomDataProvider;
	const { theme } = React.useContext(UserThemeContext);

	const classes = useStyles();

	const handleGenerateReport = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, exitType: string) => {
		dataProvider
			.gerarRelatorio('relatorios', {
				survey_id: surveyId,
				tipo_saida: exitType,
				print_options: {
					printGroupByPerson: printGroupByPerson,
					printDefaulter: printDefaulter,
					printUnitsThatDidntVote: printUnitsThatDidntVote,
					printResidentVote: printResidentVote,
				},
				tipoRelatorio: 'survey_details',
			})
			.then(() => notify('Relatório gerado com sucesso!'))
			.catch((error: AxiosError) => {
				if ([401, 403].includes(error.response?.status as number)) return Promise.reject(error);
				return notify('Ocorreu um erro interno, contate o suporte!');
			});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.id === 'printGroupByPerson') {
			setPrintGroupByPerson(e.target.checked);
		}
		if (e.target.id === 'printDefaulter') {
			setPrintDefaulter(e.target.checked);
		}
		if (e.target.id === 'printUnitsThatDidntVote') {
			setPrintUnitsThatDidntVote(e.target.checked);
		}
		if (e.target.id === 'printResidentVote') {
			setPrintResidentVote(e.target.checked);
		}
	};

	const actions = (
		<>
			<CloseButton onClick={() => setModalValue((v: any) => ({ ...v, open: false }))} size='small'>
				Voltar
			</CloseButton>
			<ConfirmButton
				disabled={false}
				onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleGenerateReport(e, 'visualizar')}
			>
				Visualizar
			</ConfirmButton>
			<ConfirmButton
				disabled={false}
				onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleGenerateReport(e, 'pdf')}
			>
				Baixar PDF
			</ConfirmButton>
		</>
	);
	return (
		<CustomDialogBody
			title={'Detalhes da Enquete'}
			customActions={actions}
			form={{
				component: (
					<Box>
						<div style={{ height: '1px', backgroundColor: theme === 'dark' ? 'white' : 'black' }}></div>
						<Box style={{ marginTop: '12px', fontSize: '18px' }}>
							<span style={{ paddingRight: '5px' }}>Opções de Impressão</span>
						</Box>
						<Box style={{ marginTop: '10px' }}>
							<label htmlFor='printGroupByPerson' className={classes.checkboxLabel}>
								<Checkbox
									id='printGroupByPerson'
									checked={printGroupByPerson}
									onChange={handleChange}
								/>
								Agrupar por pessoa
							</label>
							<label htmlFor='printDefaulter' className={classes.checkboxLabel}>
								<Checkbox
									id='printDefaulter'
									checked={printDefaulter}
									onChange={handleChange}
									title='teste'
								/>
								Imprimir inadimplência
							</label>
							<label htmlFor='printUnitsThatDidntVote' className={classes.checkboxLabel}>
								<Checkbox
									id='printUnitsThatDidntVote'
									checked={printUnitsThatDidntVote}
									onChange={handleChange}
								/>
								Imprimir unidades que não votaram
							</label>
							<label htmlFor='printResidentVote' className={classes.checkboxLabel}>
								<Checkbox id='printResidentVote' checked={printResidentVote} onChange={handleChange} />
								Imprimir respostas dos moradores
							</label>
						</Box>
					</Box>
				),
			}}
		/>
	);
};

export default SurveyDetailsModal;
