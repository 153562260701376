import React from 'react';
import AddFeesConfigForm from './Form';
import { FormCreateContextProvider } from '../contexts';

const ConfigCorrecaoMonetariaCreate: React.FC = () => {
	return (
		<FormCreateContextProvider>
			<AddFeesConfigForm />
		</FormCreateContextProvider>
	);
};

export default ConfigCorrecaoMonetariaCreate;
