import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { TooltipIconButtonRemove } from 'components/common/buttons/ButtonRemove';
import { ModalArrecadacaoUnidadesEditarContext } from './ModalArrecadacaoUnidadesEditarContext';

const ModalConfirmarExclusao = () => {
	const { excluir, requesting } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			{...(requesting
				? {
						form: {
							component: (
								<Box display='flex' justifyContent='center'>
									<CircularProgress />
								</Box>
							),
						},
						customActions: <></>,
				  }
				: {
						closeInSubmit: false,
						customActions: (
							<>
								<ButtonCancel onClick={closesModal} />
								<ButtonConfirm
									size='small'
									onClick={() => {
										excluir().finally(() => {
											closesModal();
										});
									}}
									variant='text'
								>
									Confirmar
								</ButtonConfirm>
							</>
						),
						text: (
							<Box display='flex' alignItems='center' gridGap='20px'>
								<Box>
									<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
								</Box>
								<Box>
									<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
										Essa ação é irreversível!
										<br />
										E poderá excluír o lançamento da conta a receber
										<br />
									</Typography>
								</Box>
							</Box>
						),
						title: 'Tem certeza que deseja REMOVER essa arrecadação da unidade?',
				  })}
		/>
	);
};

const BotaoExcluir = () => {
	const { selectedRows, loading } = useContext(ModalArrecadacaoUnidadesEditarContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const handleClick = () => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmarExclusao />,
		}));
	};

	return (
		<TooltipIconButtonRemove
			disabled={!selectedRows.length || loading}
			size='small'
			title='Remover'
			onClick={handleClick}
		/>
	);
};

export default BotaoExcluir;
