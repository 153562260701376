import React from 'react';

import { TextField } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';

import { ConsultaDeReservasContext } from '../context/AgendamentoContext';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';

const formatarNomeUnidade = (option, ambienteSelecionado) => {
	const partesNomeUnidade = [option.tipo_unidade, option.unidade, option.proprietario];
	if (option.nome_grupo) partesNomeUnidade.unshift(option.nome_grupo);
	const nomeUnidade = partesNomeUnidade.join(' - ');

	const limiteReservasAtingido =
		Boolean(ambienteSelecionado.limitar_numero_reservas_por_unidade) &&
		option.quantidade_reservas >= ambienteSelecionado.quantidade_maxima_de_reservas;

	if (limiteReservasAtingido) return `${nomeUnidade} | Limite de Reservas Atingido`;
	return nomeUnidade;
};

export const SelectOneUnidade = ({ setValue, value, style = {} }) => {
	const {
		consulta: { condominioSelecionado, ambienteSelecionado, diaSelecionado },
	} = React.useContext(ConsultaDeReservasContext);

	const [unidades, setUnidades] = React.useState([]);

	const dp = useDataProvider();
	const notify = useNotify();

	function fetchUnidades() {
		dp.getSimple('unidades/limite_reserva_unidades', {
			pagination: { perPage: 10000, page: 1 },
			filterExtras: (ambienteSelecionado?.grupos || []).map((grupo_id) => ['id_grupo_unidade', grupo_id]),
			sort: { field: 'unidade', order: 'ASC' },
			filter: {
				situacao: 'A',
				id_condominio: condominioSelecionado.id,
				id_ambiente: ambienteSelecionado.id,
				dia_selecionado: format(diaSelecionado, 'yyyy-MM-dd'),
			},
		})
			.then((data) => (data.data?.length ? data.data : Promise.reject('Sem unidades cadastradas.')))
			.catch((e) => {
				if (['401', '403'].includes(e?.response?.status)) return Promise.reject(e);
				notify('Sem unidades cadastradas.', 'warning');
				return [];
			})
			.then((unidadesColetadas) => {
				setUnidades(unidadesColetadas);
			});
	}

	React.useEffect(fetchUnidades, []);

	return (
		<Autocomplete
			id='select-one-unidade-criar-reserva'
			itemID='select-one-unidade-criar-reserva-item'
			aria-required
			value={value}
			options={unidades}
			onChange={(_, value) => setValue(value)}
			loading={!unidades.length}
			disabled={!unidades.length}
			title={'Selecionar Unidade'}
			renderInput={(params) => <TextField {...params} margin='dense' label='Selecionar Unidade' />}
			renderOption={(option) => formatarNomeUnidade(option, ambienteSelecionado)}
			getOptionLabel={(option) => formatarNomeUnidade(option, ambienteSelecionado)}
			getOptionDisabled={(option) =>
				Boolean(ambienteSelecionado.limitar_numero_reservas_por_unidade) &&
				option.quantidade_reservas >= ambienteSelecionado.quantidade_maxima_de_reservas
			}
			noOptionsText='Nenhum resultado encontrado'
			clearText='Limpar'
			fullWidth
			size='small'
			style={style}
		/>
	);
};
