import React from 'react';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
} from '../../../../tabs/TabInstrucoesCobrancaRemessa';

import InstrucaoField from './InstrucaoField';

const ColunaDireitaComponentes = () => (
	<>
		<MultaFields />
		<JurosFields />
		<DescontoFields />
	</>
);
const ColunaEsquerdaComponentes = () => (
	<>
		<InstrucaoField />
	</>
);

const TabInstrucoesCobrancaRemessaCNAB400 = () => {
	return (
		<PadraoTabInstrucoesCobrancaRemessaBase
			ColunaDireitaComponentes={<ColunaDireitaComponentes />}
			ColunaEsquerdaComponentes={<ColunaEsquerdaComponentes />}
		/>
	);
};

export default TabInstrucoesCobrancaRemessaCNAB400;
