import { createStyles, makeStyles } from '@material-ui/core';
import { stlyeItemBoxProps } from './types';

const useStyles = makeStyles((theme) =>
	createStyles({
		stlyeItemBox: ({ selected, dataId }: stlyeItemBoxProps) => ({
			cursor: 'pointer',
			borderRadius: 5,
			'&:hover': {
				backgroundColor: selected === dataId ? 'rgba(0,0,0,.275)' : 'rgba(0,0,0,.275)',
			},
			backgroundColor: selected === dataId ? 'rgba(0,0,0,.275)' : 'transparent',
			marginBottom: '5px',
		}),
	})
);
export default useStyles;
