import React from 'react';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';

export const ModalErrosReportRemessas = ({ title, text, form, ButtonGerarRemessas }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<CustomDialogBodySizeEditable
			title={title}
			text={text}
			form={form}
			customActions={
				<>
					<ButtonGerarRemessas text='Gerar Novamente' />
					<ButtonCancel onClick={handleClose} />
				</>
			}
		/>
	);
};

export default ModalErrosReportRemessas;
