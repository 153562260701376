import React from 'react';
import NumberFormat from 'react-number-format';

interface IValorField {
	value: number;
	prefix?: string;
	suffix?: string;
}

const ValorField: React.FC<IValorField> = ({ value = 0.0, prefix, suffix }) => {
	return (
		<NumberFormat
			thousandSeparator='.'
			decimalSeparator=','
			decimalScale={2}
			prefix={prefix || ''}
			suffix={suffix || ''}
			isNumericString
			displayType='text'
			value={value}
			fixedDecimalScale
			onChange={() => {}}
		/>
	);
};

export default ValorField;
