import React from 'react';

import { Box, TextField, styled } from '@material-ui/core/';
import { useTheme } from '@material-ui/core/styles';

import { OrderingState, FiltersActionToReducer, ColumnsHeader } from '../types';
import { CustomDialogBody, ModalContext } from 'components/common/ModalContext';
import DeleteIcon from '@material-ui/icons/Delete';

import { ProviderValueContext } from '../folder/type';
import { DateRangePicker, DefinedRange } from 'materialui-daterange-picker-pt';
import { ModalPeriodoPickerWrapper } from 'components/common/PeriodoSelect';
import { FiltersFileStateToReducer } from './type';
import {
	GoBackButton,
	IconButton,
	CustomTitle,
	CancelButton,
	SaveButton,
	CreateButton,
} from 'components/common/commonComponentsTSX';

import { DocumentsContext } from '../contexts/DocumentsContext';
import { Arquivo, CustomDataProviderDocuments, FileType } from '../types';
import { useDataProvider, useNotify } from 'react-admin';
import ImportFileModal from '../modals/ImportFile';
import SyndkosTable from 'components/common/Table/SyndkosTable';
import { format, sub, add, set, parse } from 'date-fns';
import { UserThemeContext } from 'theme/context';
import { ThemeHandler } from 'types/tpyesGlobal';

const useChangeFilterValuesHook = (filters: FiltersFileStateToReducer | object) => {
	const filteredFilters = Object.fromEntries(
		Object.entries(filters).filter(
			([_, value]) =>
				value !== null &&
				value !== '' &&
				value.toString() !== 'Invalid Date' &&
				(
					value as {
						startDate: Date | null;
						endDate: Date | null;
					}
				).startDate !== null &&
				(
					value as {
						startDate: Date | null;
						endDate: Date | null;
					}
				).endDate !== null
		)
	);

	const updatedObject = { ...filteredFilters };
	if (JSON.stringify(filteredFilters?.alterado) === '{}') {
		delete updatedObject['alterado'];
	}

	if (
		filteredFilters?.alterado?.startDate !== null &&
		filteredFilters?.alterado?.startDate !== undefined &&
		filteredFilters?.alterado?.endDate !== null &&
		filteredFilters?.alterado?.endDate !== undefined
	) {
		delete updatedObject['alterado'];

		updatedObject['start_date'] = format(filteredFilters?.alterado?.startDate, 'yyyy-MM-dd');
		updatedObject['end_date'] = format(filteredFilters?.alterado?.endDate, 'yyyy-MM-dd');
	}
	return {
		filteredFilters: updatedObject,
	};
};

const TODAY = new Date();
TODAY.setHours(10, 0, 0);

const CustomTextField = styled(TextField)({
	alignItems: 'flex-end',
	'& label': {
		left: 'auto !important',
	},
});

const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0) as unknown as Date;

const initialStateFilters: FiltersFileStateToReducer = {
	custom_file_name: '',
	usuario: '',
	alterado: {
		startDate: null,
		endDate: null,
	},
	size: null,
};

const initialStateOrdering: OrderingState = {
	field: 'file_name',
	ordering: 'asc',
};

const FIELDS = ['action', 'custom_file_name', 'usuario', 'alterado', 'size'];
function filterReducer(state: FiltersFileStateToReducer, action: FiltersActionToReducer) {
	if (Object.prototype.hasOwnProperty.call(state, action.field)) {
		Object.assign(state, { [action.field]: action.value });
	}

	return { ...state };
}
const periodoOptions = [
	{
		label: 'Limpar',
		startDate: null,
		endDate: null,
	},
	{
		label: 'Mês atual',
		startDate: set(TODAY, { date: 1 }),
		endDate: sub(add(set(TODAY, { date: 1 }), { months: 1 }), { days: 1 }),
	},
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const useFormatDataFileHook = (data: Arquivo[]) => {
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const notify = useNotify();
	const { handleCollectFiles, screen } = React.useContext(DocumentsContext);
	const formattedData = data.map((item: Arquivo, _, __) => {
		const dateObject = parse(item.alterado, 'yyyy/MM/dd HH:mm:ss', new Date());
		const formattedDate = format(dateObject, 'dd/MM/yyyy HH:mm:ss');
		return {
			id: item.id,
			action: (
				<IconButton
					onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
						e.stopPropagation();
						dp.delete('documento_arquivo', {
							id: item.id,
						})
							.then(() => {
								notify('Arquivo exluído com sucesso!', { type: 'success' });
								handleCollectFiles({
									pagination: { page: 1, perPage: 10000 },
									sort: { field: 'nome', order: 'ASC' },
									filter: { pasta: screen.folderId },
								});
							})
							.catch((e) => {
								if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
								notify('Ocorreu um erro interno!', { type: 'error' });
							});
					}}
				>
					<DeleteIcon />
				</IconButton>
			),
			custom_file_name: item.custom_file_name,
			usuario: item.usuario,
			alterado: formattedDate,
			size: item.size,
		};
	});
	return {
		formattedData: formattedData,
	};
};

const formatDate = (date: Date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const PeriodoPicker: React.FC<{
	filters: FiltersFileStateToReducer;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	filterDispatch: React.Dispatch<FiltersActionToReducer>;
}> = React.memo(({ filters, open, setOpen, filterDispatch }) => {
	const toggle = () => setOpen(!open);
	return (
		<ModalPeriodoPickerWrapper {...{ open, setOpen }}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => {
					filterDispatch({
						field: FIELDS[3],
						value: range,
					});
				}}
				initialDateRange={
					filters.alterado?.startDate &&
					typeof filters.alterado.startDate !== 'number' &&
					filters.alterado.startDate.toString() === 'Invalid Date'
						? {
								startDate:
									(filters.alterado.startDate.setHours(10, 0, 0) as unknown as Date) &&
									(filters.alterado.startDate as Date | undefined),
								endDate:
									(filters.alterado.endDate?.setHours(10, 0, 0) as unknown as Date) &&
									(filters.alterado.endDate as Date | undefined),
						  }
						: (filters.alterado as {
								endDate: Date | undefined;
								startDate: Date | undefined;
						  })
				}
				definedRanges={periodoOptions as DefinedRange[]}
				minDate={MINDATE}
			/>
		</ModalPeriodoPickerWrapper>
	);
});

const useFilterAndDataHook = () => {
	const [filters, filterDispatch] = React.useReducer(filterReducer, initialStateFilters);
	const [ordering, setOrdering] = React.useState(initialStateOrdering);
	const stringfyedOrdering = JSON.stringify(ordering);
	const { handleCollectFiles, screen } = React.useContext(DocumentsContext);
	const [open, setOpen] = React.useState<boolean>(false);

	const columnsDoc = React.useMemo<ColumnsHeader[]>(
		() => [
			{
				field: FIELDS[1],
				headerName: 'Nome',
				width: 500,
				alignment: 'start',
				orderFunction: () => {
					setOrdering((prevState) => ({
						field: FIELDS[1],
						ordering: prevState.field !== FIELDS[1] || prevState.ordering === 'desc' ? 'asc' : 'desc',
					}));
				},
			},
			{
				field: FIELDS[2],
				headerName: 'Usuário',
				width: 150,
				alignment: 'start',
				orderFunction: () => {
					setOrdering((prevState) => ({
						field: FIELDS[2],
						ordering: prevState.field !== FIELDS[2] || prevState.ordering === 'desc' ? 'asc' : 'desc',
					}));
				},
			},
			{
				field: FIELDS[3],
				headerName: 'Última Modificação',
				width: 150,
				alignment: 'end',
				orderFunction: () => {
					setOrdering((prevState) => ({
						field: FIELDS[3],
						ordering: prevState.field !== FIELDS[3] || prevState.ordering === 'desc' ? 'asc' : 'desc',
					}));
				},
			},
			{
				field: FIELDS[4],
				headerName: 'Tamanho',
				width: 150,
				alignment: 'end',
				orderFunction: () => {
					setOrdering((prevState) => ({
						field: FIELDS[4],
						ordering: prevState.field !== FIELDS[4] || prevState.ordering === 'desc' ? 'asc' : 'desc',
					}));
				},
			},
			{
				field: FIELDS[0],
				headerName: 'Ações',
				width: 50,
				alignment: 'start',
				orderFunction: () => {},
			},
		],
		[]
	);
	const filterColumnsFolders = React.useMemo(
		() => [
			{
				component: (
					<TextField
						variant='standard'
						label='Filtrar'
						value={filters.custom_file_name}
						onChange={(e) =>
							filterDispatch({
								field: FIELDS[1],
								value: e.target.value,
							})
						}
					/>
				),
				width: 500,
			},
			{
				component: (
					<TextField
						variant='standard'
						label='Filtrar'
						value={filters.usuario}
						onChange={(e) =>
							filterDispatch({
								field: FIELDS[2],
								value: e.target.value,
							})
						}
					/>
				),
				width: 150,
			},
			{
				component: (
					<>
						<CustomTextField
							variant='standard'
							label='Filtrar'
							size='small'
							onClick={() => setOpen(true)}
							inputProps={{
								value: `${
									filters.alterado?.endDate
										? `de ${formatDate(filters.alterado?.startDate as Date)} `
										: ''
								}${filters.alterado?.endDate ? `até ${formatDate(filters.alterado?.endDate)}` : ''}`,
							}}
						/>
						<PeriodoPicker {...{ filters, open, setOpen, filterDispatch }} />
					</>
				),
				width: 150,
			},
			{
				component: (
					<CustomTextField
						variant='standard'
						label='Filtrar'
						value={filters.size}
						onChange={(e) =>
							filterDispatch({
								field: FIELDS[4],
								value: e.target.value,
							})
						}
					/>
				),
				width: 150,
			},
			{
				component: null,
				width: 50,
			},
		],
		[filters.alterado, filters.custom_file_name, filters.size, filters.usuario, open]
	);

	React.useEffect(() => {
		const idTimeout = setTimeout(() => {
			const { filteredFilters } = useChangeFilterValuesHook(filters);
			const order = ordering.ordering === 'asc' ? 'ASC' : 'DESC';
			handleCollectFiles({
				filter: { ...filteredFilters, pasta: screen.folderId },
				sort: { order: order, field: ordering.field },
				pagination: {
					perPage: 10000,
					page: 1,
				},
			});
		}, 1200);

		return () => {
			clearTimeout(idTimeout as NodeJS.Timeout);
		};
	}, [filters.alterado, filters.custom_file_name, filters.size, filters.usuario, stringfyedOrdering]);

	const { files } = React.useContext(DocumentsContext);
	const { formattedData } = useFormatDataFileHook(files);

	return {
		columnsTableHead: columnsDoc,
		data: formattedData,
		filterColumns: filterColumnsFolders,
		ordering: ordering,
	};
};

const AdicionarArquivo: React.FC = () => {
	const [file, setFile] = React.useState<FileType>({} as FileType);
	const [hasFile, setHasFile] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [customFileName, setCustomFileName] = React.useState('');
	const { screen, handleCollectFiles } = React.useContext(DocumentsContext);
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const notify = useNotify();

	const handleSubmitFile = async (e: React.FormEvent) => {
		e.preventDefault();
		if (file.size > 15000000) {
			return notify('O arquivo não pode ultrapassar 15 MB de tamanho!', 'warning');
		}
		setLoading(true);
		if (screen?.folderId) {
			await dp
				.safeCreate('documento_arquivo', {
					data: {
						pasta: screen.folderId,
						custom_file_name: customFileName,
						...file,
					},
				})
				.then(() => {
					notify('Arquivo adicionado com sucesso');
					setModalValue((v) => ({ ...v, open: false }));
					setLoading(false);
					handleCollectFiles({
						pagination: { page: 1, perPage: 10000 },
						sort: { field: 'nome', order: 'ASC' },
						filter: { pasta: screen.folderId },
					});
				})
				.catch((e) => {
					setLoading(false);
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					if ([413].includes(e?.response?.status))
						return notify('O arquivo é grande demais!', { type: 'error' });
					return notify('Ocorreu um problema ao tentar adicionar o arquivo!', { type: 'error' });
				});
		}
	};

	const acoes = (
		<>
			<CancelButton onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
			<SaveButton disabled={!hasFile || loading || !customFileName} onClick={handleSubmitFile}>
				Salvar
			</SaveButton>
		</>
	);

	return (
		<CustomDialogBody
			title={'Importar Documento'}
			customActions={acoes}
			form={{
				valid: hasFile,
				handleSubmit: handleSubmitFile,
				component: (
					<Box
						style={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						<ImportFileModal {...{ setFile, setHasFile, setCustomFileName, customFileName }} />
					</Box>
				),
			}}
		/>
	);
};

const DocumentsFileList: React.FC = React.memo(() => {
	const { theme } = React.useContext(UserThemeContext);
	const { setModalValue } = React.useContext<ProviderValueContext>(ModalContext);
	const { data, columnsTableHead, filterColumns, ordering } = useFilterAndDataHook();
	const dp = useDataProvider() as CustomDataProviderDocuments;
	const notify = useNotify();
	const {
		handleSetRedirectContext,
		screen: { selectedFolderName },
	} = React.useContext(DocumentsContext);
	const colours = useTheme<ThemeHandler<typeof theme>>();

	const handleClickDownloadFile = React.useCallback(
		(id: number) => {
			dp.getOne('documento_arquivo', { id: id })
				.then((response) => {
					return window.open(response?.data?.file, '_blank');
				})
				.catch(() => {
					notify('Ocorreu um erro interno!', { type: 'error' });
				});
		},
		[setModalValue]
	);

	const handleImportFile = () => {
		return setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <AdicionarArquivo />,
		}));
	};

	return (
		<Box
			mt={2}
			style={{ backgroundColor: colours.fundoMenu[100] }}
			boxShadow={
				'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
			}
			borderRadius={'4px'}
			boxSizing={'border-box'}
			padding={'1em'}
		>
			<Box>
				<CustomTitle
					style={{
						fontWeight: 500,
						fontSize: '1.25rem',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						borderBottom: 'unset',
						marginBottom: 'unset',
					}}
				>
					Meus Documentos &gt; {selectedFolderName}
					<CreateButton onClick={handleImportFile}>Adicionar</CreateButton>
				</CustomTitle>
			</Box>
			<Box
				style={{
					backgroundColor: colours.fundoMenu[100],
					color: colours.font[100],
				}}
				border={'rgb(0 0 0 / 10%) solid 1px'}
				borderRadius={'4px'}
			>
				<SyndkosTable
					columnsTableHead={columnsTableHead}
					data={data}
					filterColumns={filterColumns}
					handleClickEditRow={handleClickDownloadFile}
					ordering={ordering}
				/>
			</Box>
			<Box mt={1}>
				<GoBackButton onClick={() => handleSetRedirectContext('folder', null)}>Voltar</GoBackButton>
			</Box>
		</Box>
	);
});

export default DocumentsFileList;
