import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { TreeTableContext } from '../contexts/TreeTableContext';

import ContasTable from './ContasTable';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
	() => ({
		mainPainelDataView: {
			margin: '15px 15px 0',
			width: '100%',
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			transition: 'all 200ms ease',
		},
		secondaryPainelDataView: {
			display: 'flex',
			marginTop: '10px',
			width: '100%',
			height: 'fit-content',
			minHeight: '55vh',
			maxHeight: '55vh',
			justifyContent: 'center',
			position: 'relative',
			overflowY: 'auto',
		},
	}),
	{ name: 'mainPainelDataView' }
);

const DataView = () => {
	const {
		value: { treeData },
	} = useContext(TreeTableContext);
	const classes = useStyles();

	return (
		<div name='mainPainelDataView' className={classes.mainPainelDataView}>
			<div className={classes.secondaryPainelDataView}>
				<Paper style={{ width: '100%', height: '100%' }}>
					{treeData.length ? (
						<ContasTable />
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								textAlign: 'center',
								width: '100%',
								height: '100%',
							}}
						>
							<Typography variant='h6'>Não há nenhuma conta para importar</Typography>
						</div>
					)}
				</Paper>
			</div>
		</div>
	);
};

export default DataView;
