// eslint-disable-next-line @typescript-eslint/no-var-requires
const XmlParser = require('xml2js/lib/parser').Parser;

const clearString = (str) =>
	str
		.replace(/[\r\n\t]+/g, '')
		.replace(/\s+</g, '<')
		.replace(/>\s+/g, '>');

// Verifica se a string começa com uma tag, espaços em branco opcionais
const regexWithTag = /^(?:[\s\r\n\t]*)<[\w\d]+>/gu;
const NaoTemTag = (c) => !c.match(regexWithTag);

// regex string para espaços em branco
// Para convênciencia
const regStrOptionalWhitesSpaces = '(?:[\\s\\r\\n\\t]*)';

// RegExp para coletar itens cuja tag se repete logo depois dela
// e cujo conteúdo não terá
const regexStringWithClosingTagUnicode = [
	'^',
	'<([\\w\\d]+)>',
	'([-\\s\\p{Nd}\\p{L}<>.,:;=+|!@#$%¨&*()_?\'"*\\[\\]\\\\]*)',
	'</\\1>',
	'',
].join(regStrOptionalWhitesSpaces);
const regexWithClosingTagInStartUnicode = RegExp(`${regexStringWithClosingTagUnicode}`, 'gu');

// Regex string para coletar o primeiro item com tag de fechamento
const regexStringWithClosingTag = ['^', '<([\\w\\d]+)>', '([\\s\\S]+)', '</\\1>', ''].join(regStrOptionalWhitesSpaces);
// Verificar e coletar se a tag do primeiro item está embrulhando toda a string
const regexWithAllWrappingClosingTag = RegExp(`${regexStringWithClosingTag}$`, 'gu');
const tagIsAllWrapping = (c) => !!c.match(regexWithAllWrappingClosingTag);

// RegExp para coletar se o primeiro item tiver tag de fechamento
const regexWithClosingTagInStart = RegExp(`${regexStringWithClosingTag}`, 'gu');

// RegExp para coletar se o primeiro item tiver tag de abertura, mas não de fechamento
const regexWithoutClosingTag = /(?:[\s\r\n\t]*)<([\w\d]+)>([^<]*)/gu;

// Iterador para coletar sequencialmente os itens dentro de alguma tag
function* makeTagsIterator(content) {
	while (clearString(content).length) {
		const [firstMatch, tag, conteudo] = content.matchAll(regexWithClosingTagInStartUnicode).next().value ||
			content.matchAll(regexWithClosingTagInStart).next().value ||
			content.matchAll(regexWithoutClosingTag).next().value || [content, '', ''];

		if (tag) yield `<${tag}>${formatContent(conteudo)}</${tag}>`;
		content = content.replace(firstMatch, '');
	}
	return '';
}

const formatAllWrappingClosingTag = (content) =>
	Array.from(
		content.matchAll(regexWithAllWrappingClosingTag),
		(m) => `<${m[1]}>${formatContent(m[2])}</${m[1]}>`
	).join('');

const formatContent = (content) => {
	if (NaoTemTag(content)) return content;
	if (tagIsAllWrapping(content)) return formatAllWrappingClosingTag(content);
	const iter = makeTagsIterator(content);
	return [...iter].join('');
};

/**
 * Given an XML string, parse it and return it as a JSON-friendly Javascript object
 * @param {string} xml The SML to format and clear
 * @returns {string} A string that could be in correct XML format
 */
const sgml2XmlV2 = (sgml) => clearString(formatContent(sgml));

/**
 * Given an XML string, parse it and return it as a JSON-friendly Javascript object
 * @param {string} xml The SML to clear and format
 * @returns {string} A string that could be in correct XML format
 */
function sgml2Xml(sgml) {
	return (
		sgml
			.replace(/>\s+</g, '><') // remove whitespace inbetween tag close/open
			.replace(/\s+</g, '<') // remove whitespace before a close tag
			.replace(/>\s+/g, '>') // remove whitespace after a close tag
			//eslint-disable-next-line
			.replace(/<([A-Z0-9_]*)+\.+([A-Z0-9_]*)>([^<]+)/g, '<$1$2>$3')
			//eslint-disable-next-line
			.replace(/<(\w+?)>([^<]+)/g, '<$1>$2</$1>')
	);
}

/**
 * Given an XML string, parse it and return it as a JSON-friendly Javascript object
 * @param {string} xml The XML to parse
 * @returns {Promise} A promise that will resolve to the parsed XML as a JSON-style object
 */
function parseXml(xml) {
	const xmlParser = new XmlParser({ explicitArray: false });
	return new Promise((resolve, reject) => {
		xmlParser.parseString(xml, (err, result) => {
			if (err) {
				reject(err);
			} else {
				resolve(result);
			}
		});
	});
}

/**
 * Given a string of OFX data, parse it.
 * @param {string} data The OFX data to parse
 * @returns {Promise} A promise that will resolve to the parsed data.
 */
function parse(data) {
	// firstly, split into the header attributes and the footer sgml
	const ofx = data.split('<OFX>', 2);

	// firstly, parse the headers
	const headerString = ofx[0].split(/\r?\n/);
	let header = {};
	headerString.forEach((attrs) => {
		const headAttr = attrs.split(/:/, 2);
		header[headAttr[0]] = headAttr[1];
	});

	// make the SGML and the XML
	const content = ('<OFX>' + ofx[1])
		.replace(/&(?![#\w\d]*;)/g, '&amp;')
		.replace(/'(?=[^<]*<\/)/g, '&apos;')
		.replace(/"(?=[^<]*<\/)/g, '&quot;')
		.replace(/([^<])\//g, '$1-');
	// Parse the XML/SGML portion of the file into an object
	// Try as XML first, and if that fails do the SGML->XML mangling
	return parseXml(content)
		.catch(() => {
			// XML parse failed.
			// Do the SGML->XML Manging and try again.
			return parseXml(sgml2Xml(content));
		})
		.catch(() => {
			// SGML->XML Manging failed
			// Do other SGML->XML Manging and try again
			return parseXml(sgml2XmlV2(content));
		})
		.then((data) => {
			// Put the headers into the returned data
			data.header = header;
			return data;
		});
}

export default parse;
