import React, { useCallback, useContext } from 'react';

import NumberFormat from 'react-number-format';

import { DecimalField } from '../../../../../common/filtros/Decimal';
import FluxoImportacaoExtratoBancarioContext from '../FluxoImportacaoExtratoBancarioContext';

function AllowNegativeNumberFormatBRL(props) {
	const { inputRef, onChange, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.floatValue,
					},
				});
			}}
			thousandSeparator='.'
			decimalSeparator=','
			isNumericString
			fixedDecimalScale={true}
		/>
	);
}

const ValorFiltro = () => {
	const {
		filtragemRegistros: { valor },
		setFiltragemRegistros,
	} = useContext(FluxoImportacaoExtratoBancarioContext);

	const handleChange = useCallback(
		(newValue) => {
			setFiltragemRegistros((v) => ({ ...v, valor: newValue }));
		},
		[setFiltragemRegistros]
	);
	return (
		<DecimalField
			id='valor-filtro-decimal'
			handleChange={handleChange}
			valor={valor}
			inputComponent={AllowNegativeNumberFormatBRL}
		/>
	);
};

export default ValorFiltro;
