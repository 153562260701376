import React, { useContext, useState } from 'react';

import { useDataProvider, useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import NovaLeituraContext from './NovaLeituraContext';
import { BotaoVoltar } from '../../../../../common/buttons/BotaoVoltar';
import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../../../common/CurrencyInput';
import { CustomDialogBodySizeEditable } from '../../../../../common/ModalSizeEditableContext';

const ModalFaixaSimplesNovaLeitura = () => {
	const { dadosNovaLeitura, handleCancel, setDadosNovaLeitura, setModalStep } = useContext(NovaLeituraContext);
	const dP = useDataProvider();
	const notify = useNotify();
	const [disabledForProcessing, setDisabledForProcessing] = useState(false);
	const handleBack = () => {
		if ('k' === dadosNovaLeitura.dados_config.unidade_medida) setModalStep('5F');
		if ('m' === dadosNovaLeitura.dados_config.unidade_medida) {
			setModalStep(dadosNovaLeitura.dados_config.fator_convercao ? '7FK' : '6FK');
		}
	};

	const [valorFaixa, setValorFaixa] = useState(0);
	const [taxaFixaAdicionalFaixa, setTaxaFixaAdicionalFaixa] = useState(0);
	const handleNext = () => {
		setDisabledForProcessing(true);
		dP.create('leitura', {
			data: {
				...dadosNovaLeitura,
				dados_config: {
					...dadosNovaLeitura.dados_config,
					faixas_novas: [
						{
							de: 0,
							ate: 999999999.999999,
							valor: valorFaixa,
							taxa_fixa_adicional: taxaFixaAdicionalFaixa,
						},
					],
				},
			},
		})
			.then((response) => {
				const data = response?.data;
				if (data) {
					setDadosNovaLeitura((v) => ({ ...v, id: data.id }));
					setModalStep('10Success');
				} else {
					setDisabledForProcessing(false);
				}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				setDisabledForProcessing(false);
				notify(`Erro ao cadastrar nova leitura: ${e}`, 'warning');
			});
	};

	return (
		<CustomDialogBodySizeEditable
			title='Configurar Nova Leitura'
			text='Configurar Faixa'
			form={{
				component: (
					<div>
						<TextField
							disabled={disabledForProcessing}
							label='Valor'
							name='valorFaixa'
							id='valorFaixaID'
							value={valorFaixa}
							onChange={(event) => {
								if (!event.target.value) event.target.value = 0;
								if (event.target.value > 999999999.999999) event.target.value = 999999999.999999;
								setValorFaixa(event.target.value);
							}}
							inputProps={sanitizeListNumberFormatBRLProps({
								decimalScale: 6,
								prefix: 'R$',
							})}
							InputProps={{
								inputComponent: NumberFormatBRL,
							}}
						/>
						<TextField
							disabled={disabledForProcessing}
							label='Taxa fixa adicional'
							name='taxaFixaAdicionalFaixa'
							id='taxaFixaAdicionalFaixaID'
							value={taxaFixaAdicionalFaixa}
							onChange={(event) => {
								if (!event.target.value) event.target.value = 0;
								if (event.target.value > 999999999.999999) event.target.value = 999999999.999999;
								setTaxaFixaAdicionalFaixa(event.target.value);
							}}
							inputProps={sanitizeListNumberFormatBRLProps({
								decimalScale: 6,
								prefix: 'R$',
							})}
							InputProps={{
								inputComponent: NumberFormatBRL,
							}}
						/>
					</div>
				),
			}}
			customActions={
				<>
					<Button
						variant='text'
						onClick={handleCancel}
						disabled={disabledForProcessing}
						size='small'
						startIcon={<CloseIcon />}
					>
						Cancelar
					</Button>
					<BotaoVoltar onClick={handleBack} disabled={disabledForProcessing} />
					<Button
						variant='text'
						onClick={handleNext}
						color='primary'
						disabled={disabledForProcessing}
						size='small'
						startIcon={<CheckIcon />}
					>
						Salvar
					</Button>
				</>
			}
		/>
	);
};

export default ModalFaixaSimplesNovaLeitura;
