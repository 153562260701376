import React from 'react';

import { Box, FormLabel } from '@material-ui/core';

import { FormContext } from '../../contexts';
import UltimaDataAtualizacaoInput from './UltimaDataAtualizacaoInput';
import UltimoValorCalculadoInput from './UltimoValorCalculadoInput';
import SelectTipoIndice from '../SelectTipoIndice';

export const BoxConfiguracaoIndiceCorrecaoMonetaria = () => {
	const {
		tipoIndice,
		setTipoIndice,
		ultimaDataAtualizacao,
		setUltimaDataAtualizacao,
		ultimoValorCalculado,
		setUltimoValorCalculado,
		desabilitaInputSeNaoForManual,
	} = React.useContext(FormContext);
	return (
		<Box display={'flex'} flexDirection='column' gridGap='1.5rem'>
			<FormLabel component='legend'>Opções de Correção Monetária</FormLabel>
			<Box display='flex' gridGap='1rem'>
				<SelectTipoIndice
					{...{
						tipoIndice,
						setTipoIndice,
						desabilitaInputSeNaoForManual,
					}}
				/>

				<UltimaDataAtualizacaoInput {...{ ultimaDataAtualizacao, setUltimaDataAtualizacao, tipoIndice }} />

				<UltimoValorCalculadoInput {...{ ultimoValorCalculado, setUltimoValorCalculado, tipoIndice }} />
			</Box>
		</Box>
	);
};

export default BoxConfiguracaoIndiceCorrecaoMonetaria;
